<div class="associated-gate-container">
  <forms-dropdown-list
    (ngModelChange)="onSortingGateChanged($event)"
    [allowClear]="false"
    [items]="gateItems"
    [ngModel]="currentGateId"
    [translateListItem]="false"
    [emitChangeEventWhenSetValue]="false"
    class="system-configuration-form-control"
    style="flex: 1 1 50%"
    required
  ></forms-dropdown-list>
  <div class="direction-buttons system-configuration-form-control" [ngClass]="{ 'direction-buttons-invalid': !currentDirection }">
    <button (click)="this.onDirectionChanged('left')"
            [class.active]="this.currentDirection === 'left'"
            [disabled]="!this.availableDirections.left || isDirectionsDisable"
            class="direction-button"
            translation-id="SYSTEM.SORTING_PENS.PEN_DIRECTION_LEFT">{{'SYSTEM.SORTING_PENS.PEN_DIRECTION_LEFT' | translate}}</button>
    <button (click)="this.onDirectionChanged('right')"
            [class.active]="this.currentDirection === 'right'"
            [disabled]="!this.availableDirections.right || isDirectionsDisable"
            class="direction-button"
            translation-id="SYSTEM.SORTING_PENS.PEN_DIRECTION_RIGHT">{{'SYSTEM.SORTING_PENS.PEN_DIRECTION_RIGHT' | translate}}</button>
  </div>
</div>
