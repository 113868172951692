<div class="herd-management-container-wrapper">
  <div class="work-mode-container">
    <div class="work-mode-text" [attr.translation-id]="'SYSTEM.NAVIGATION.WORK_MODE'">{{ 'SYSTEM.NAVIGATION.WORK_MODE' | translate }}</div>
    <div class="supports-text" [attr.translation-id]="'REGISTRATION.THIRD_PARTY.THIRD_PARTY_NOT_SUPPORTED'" *ngIf="registrationService.registrationModel.herdManagement && !registrationService.registrationModel.herdManagement.linkedStatus">{{ 'REGISTRATION.THIRD_PARTY.THIRD_PARTY_NOT_SUPPORTED' | translate }}</div>
    <div class="supports-text" [attr.translation-id]="'REGISTRATION.THIRD_PARTY.THIRD_PARTY_SUPPORTED'" *ngIf="registrationService.registrationModel.herdManagement && registrationService.registrationModel.herdManagement.linkedStatus">{{ 'REGISTRATION.THIRD_PARTY.THIRD_PARTY_SUPPORTED' | translate }}</div>
    <div class="integration-mode-container">
      <bindable-toggle [theme]="bindableToggleTheme.registrationGreen" [attr.sh-id]="'bindable_toggle_enabled_integration_mode'" [isChecked]="registrationService.registrationModel.herdManagement && registrationService.registrationModel.herdManagement.linkedStatus" (onToggle)="toggleIntegrationMode()"></bindable-toggle>
      <div class="toggle-title" [attr.translation-id]="'SYSTEM.MANAGEMENT.ENABLE_INTEGRATION_TOGGLE'">{{ 'SYSTEM.MANAGEMENT.ENABLE_INTEGRATION_TOGGLE' | translate }}</div>
    </div>
    <div class="herd-management-active" *ngIf="registrationService.registrationModel.herdManagement && registrationService.registrationModel.herdManagement.linkedStatus">
      <div class="select-primary-herd-management-text" translation-id="SYSTEM.MANAGEMENT.SELECT_PRIMARY_SOFTWARE">
        {{'SYSTEM.MANAGEMENT.SELECT_PRIMARY_SOFTWARE' | translate}}
      </div>
      <div class="dropdown-title" [attr.translation-id]="'SYSTEM.MANAGEMENT.SYSTEM_TYPE_TITLE'">{{ 'SYSTEM.MANAGEMENT.SYSTEM_TYPE_TITLE' | translate }}</div>
      <div class="dropdown-container">
        <regular-dropdown-list [isFieldError]="registrationService.registrationModel.error && registrationService.registrationModel.error.fieldName === 'thirdParty'"
                               [colorScheme]="dropdownColorScheme.greyGreen"
                               [items]="thirdPartyOptionItems"
                               [selectedValue]="selectedThirdParty"
                               (onItemSelected)="onThirdPartySelected($event); clearErrors();"></regular-dropdown-list>
      </div>
    </div>
  </div>
  <div class="third-party-container" [ngSwitch]="displayedThirdPartyContainerType">
    <!-- Validation Mode Page -->
    <div class="validation-mode-wrapper" *ngSwitchCase="thirdPartyContainerType.validationMode">
      <div class="validation-mode-title-text" [attr.translation-id]="'REGISTRATION.FLOW.SENSETIME_MODE_OF_OPERATION'">{{ 'REGISTRATION.FLOW.SENSETIME_MODE_OF_OPERATION' | translate }}</div>
      <div class="validation-mode-toggle">
        <div class="radio-button-linked" (click)="toggleValidationMode()" [attr.translation-id]="'REGISTRATION.FLOW.VALIDATION'" [attr.sh-id]="'validation_mode_button'" [ngClass]="{ 'selected': registrationService.registrationModel.herdManagement && registrationService.registrationModel.herdManagement.validationMode }">{{ 'REGISTRATION.FLOW.VALIDATION' | translate }}</div>
        <div class="radio-button-linked" (click)="toggleValidationMode()" [attr.translation-id]="'REGISTRATION.FLOW.NO_VALIDATION'" [attr.sh-id]="'no_validation_mode_button'" [ngClass]="{ 'selected': registrationService.registrationModel.herdManagement && !registrationService.registrationModel.herdManagement.validationMode }">{{ 'REGISTRATION.FLOW.NO_VALIDATION' | translate }}</div>
      </div>
      <div class="no-validation-description" *ngIf="registrationService.registrationModel.herdManagement && !registrationService.registrationModel.herdManagement.validationMode" [attr.translation-id]="'REGISTRATION.FLOW.SYSTEM_WILL_NOT_BE_ABLE_TO_PROVIDE_CAPABILITIES'">{{ 'REGISTRATION.FLOW.SYSTEM_WILL_NOT_BE_ABLE_TO_PROVIDE_CAPABILITIES' | translate }}</div>
    </div>
    <!--No Page Displayed-->
    <div class="none" *ngSwitchCase="thirdPartyContainerType.none">
    </div>
    <!-- Third Party - Input Output Folder Page -->
    <div class="input-output-folder-container" *ngSwitchCase="thirdPartyContainerType.folderInputOutput">
      <div class="title-text"  *ngIf="isInputFolderInputShown" [attr.translation-id]="'SYSTEM.MANAGEMENT.INPUT_FILE_PATH_TITLE'">{{ 'SYSTEM.MANAGEMENT.INPUT_FILE_PATH_TITLE' | translate }}</div>
      <input class="input-output-folder-class"
             (ngModelChange)="clearErrors()"
             [ngClass]="{ error: registrationService.registrationModel.error && registrationService.registrationModel.error.fieldName === 'inputFolder' }"
             *ngIf="isInputFolderInputShown"
             placeholder="{{ 'Enter File Path' | translate }}"
             [(ngModel)]="registrationService.registrationModel.herdManagement.inputFolder"
             [attr.sh-id]="'input_folder_text_input'"/>
      <div class="title-text" *ngIf="isOutputFolderInputShown" [attr.translation-id]="'SYSTEM.MANAGEMENT.OUTPUT_FILE_PATH_TITLE'">{{ 'SYSTEM.MANAGEMENT.OUTPUT_FILE_PATH_TITLE' | translate }}</div>
      <input class="input-output-folder-class"
             (ngModelChange)="clearErrors()"
             [ngClass]="{ error: registrationService.registrationModel.error && registrationService.registrationModel.error.fieldName === 'outputFolder' }"
             *ngIf="isOutputFolderInputShown"
             placeholder="{{ 'Enter File Path' | translate }}"
             [(ngModel)]="registrationService.registrationModel.herdManagement.outputFolder"
             [attr.sh-id]="'output_folder_text_input'"/>
    </div>
    <!-- Third Party - Registration Key Page -->
    <div class="registration-key-container" [ngClass]="{disabled: disabled}" *ngSwitchCase="thirdPartyContainerType.registrationKeyFromCloud">
      <div class="title-text" [attr.translation-id]="'SYSTEM.MANAGEMENT.REGISTRATION_KEY_TITLE'">{{ 'SYSTEM.MANAGEMENT.REGISTRATION_KEY_TITLE' | translate }}</div>
      <div class="copy-key-text" [attr.translation-id]="'SYSTEM.MANAGEMENT.COPY_KEY_TO_YOUR_SOFTWARE_ TEXT'">{{ 'SYSTEM.MANAGEMENT.COPY_KEY_TO_YOUR_SOFTWARE_ TEXT' | translate }}</div>
      <div class="registration-key-button" *ngIf="!registrationService.registrationModel.herdManagement.registrationKey" [attr.translation-id]="'SYSTEM.MANAGEMENT.REGISTRATION_KEY_TITLE'" (click)="getRegistrationKey()">{{ 'SYSTEM.MANAGEMENT.REGISTRATION_KEY_TITLE' | translate }}</div>
      <input class="registration-key-input"
             *ngIf="registrationService.registrationModel.herdManagement.registrationKey"
             disabled="disabled"
             [value]="registrationService.registrationModel.herdManagement.registrationKey" />
    </div>
    <!-- Third Party - Insert FarmID Manually-->
    <div class="insert-registration-key-manually-container" *ngSwitchCase="thirdPartyContainerType.insertFarmIDManually">
      <div class="title-text" [attr.translation-id]="'SYSTEM.MANAGEMENT.TITLE.CRV_FARM_ID'">{{ 'SYSTEM.MANAGEMENT.TITLE.CRV_FARM_ID' | translate }}</div>
      <div class="registration-key-input-wrapper">
        <input class="registration-key-input"
               [ngClass]="{ 'error': registrationService.registrationModel.error && registrationService.registrationModel.error.fieldName === 'CRVRegistrationKeyInput'}"
               (ngModelChange)="isCRVApproved(); clearErrors();"
               type="text" inputFilter [inputFilterMode]="InputFilterMode.Numeric"
               [attr.translation-id]="'SYSTEM.MANAGEMENT.CONTENT.CRV_FARM_ID_PLACEHOLDER'"
               placeholder="{{ 'SYSTEM.MANAGEMENT.CONTENT.CRV_FARM_ID_PLACEHOLDER' | translate }}"
               [(ngModel)]="registrationService.registrationModel.herdManagement.CRVRegistrationKey" />
        <div class="apply-button" *ngIf="!isCRVValueApproved" [ngClass]="{ 'disabled': !registrationService.registrationModel.herdManagement.CRVRegistrationKey }" [attr.translation-id]="'COMMON.BUTTON.APPLY'" (click)="applyCRVRegistrationKey()">{{ 'COMMON.BUTTON.APPLY' | translate }}</div>
        <div class="apply-button" *ngIf="isCRVValueApproved"><div class="apply-icon"></div></div>
      </div>
    </div>
    <div class="errors-div" *ngIf="registrationService.registrationModel.error">
      <div class="error-text" [attr.translation-id]="'SYSTEM.MANAGEMENT.ERROR_BOLD: ' + registrationService.registrationModel.error.error"><b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}: </b> {{ registrationService.registrationModel.error.error | translate }}</div>
    </div>
  </div>
</div>
