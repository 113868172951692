import {Injectable} from '@angular/core';
import {AnimalTimelineFlowModel, AnimalTimelineHeader} from '../model/animal-timeline-model';
import {HttpResponseResult, RestApiService} from '../../rest-api/rest-api.service';
import {AuthService} from '../../auth/auth.service';
import {ConfigService} from '../../config/config.service';
import {DateTimeFormatEnum, EpochDateTimePipe} from '../../../common/pipes/epoch-date-time.pipe';
import {IFinishingAnimalEventsTimelineData} from './model/animal-timeline-finishing-model';
import {AnimalBadge} from '../../animals/model/animal.model';
import {AnimalLactationStatus} from '../breeding/model/animal-timeline-breeding-model';
import {AnimalTimelineUtils} from '../utils/animal-timeline-utils';
import {TranslationService} from '../../translations/translation.service';

enum FinishingAnimalTimeLinEventsTypeEnum {
  HerdEntry,
  Birth,
  Castration,
  Background,
  Finishing
}

class FinishingAnimalTimeLineEvent {
  public dateTimeServer: number;
  public localDate: number;
  public eventType: FinishingAnimalTimeLinEventsTypeEnum;
}

@Injectable({
  providedIn: 'root'
})
export class AnimalTimelineFinishingService {
  
  private readonly finishingTimelineColorsByStatus: Map<AnimalLactationStatus, string[]> = new Map([
    [AnimalLactationStatus.Steer, ['#df9226', '#e6b45a', '#F2D9AC']],
    [AnimalLactationStatus.BullCalfFinishing, ['#6e6ea0', '#9b95c9', '#f4e6f4']],
    [AnimalLactationStatus.HeiferFinishing, ['#58595c', '#a8aaad', '#c5c6c6']]
  ]);
  
  private readonly finishingIconByEvent: Map<FinishingAnimalTimeLinEventsTypeEnum, string> = new Map([
    [FinishingAnimalTimeLinEventsTypeEnum.Castration, 'icon-castration'],
    [FinishingAnimalTimeLinEventsTypeEnum.Background, 'icon-backgrounding-start'],
    [FinishingAnimalTimeLinEventsTypeEnum.Finishing, 'icon-finishing-start'],
    [FinishingAnimalTimeLinEventsTypeEnum.HerdEntry, 'icon-herd-entry-finishing'],
    [FinishingAnimalTimeLinEventsTypeEnum.Birth, 'icon-birth']
  ])
  
  private static readonly AnimalEventsTimelineRoute = '/rest/api/finishing/animals/{animalId}/details?projection=eventsTimeline';
  
  public constructor(private readonly restApiService: RestApiService,
                     private readonly authService: AuthService,
                     private readonly configService: ConfigService,
                     private readonly epochDateTimePipe: EpochDateTimePipe,
                     private readonly translationService: TranslationService) { }
  
  public async getAnimalTimeline(animalId: number): Promise<AnimalTimelineFlowModel>
  {
    let response = await this.getAnimalEventsTimeline(animalId);
    if(response.responseBody == null) {
      return null;
    }
    return this.transformToFlowModel(response.responseBody);
  }
  
  private getAnimalEventsTimeline(animalId: number) : Promise<HttpResponseResult<IFinishingAnimalEventsTimelineData>> {
    let route = AnimalTimelineFinishingService.AnimalEventsTimelineRoute.replace('{animalId}', animalId.toString());
    return this.restApiService.sendGetMethod<IFinishingAnimalEventsTimelineData>(route, this.authService.currentUserAuthState.authDetails);
  }
  
  private transformToFlowModel(animalEventsTimelineData: IFinishingAnimalEventsTimelineData) : AnimalTimelineFlowModel {
    let timelineFlow = new AnimalTimelineFlowModel();
    
    timelineFlow.header = this.getAnimalTimelineHeader(animalEventsTimelineData);
  
    let animalTimeLineEvents : FinishingAnimalTimeLineEvent[] = [];
  
    if(animalEventsTimelineData.castrationDate != null) {
      animalTimeLineEvents.push({
        dateTimeServer: animalEventsTimelineData.castrationDate,
        localDate: AnimalTimelineUtils.convertDateTimeToLocalDate(animalEventsTimelineData.castrationDate),
        eventType: FinishingAnimalTimeLinEventsTypeEnum.Castration
      });
    }
  
    if(animalEventsTimelineData.startBackgroundDate != null) {
      animalTimeLineEvents.push({
        dateTimeServer: animalEventsTimelineData.startBackgroundDate,
        localDate: AnimalTimelineUtils.convertDateTimeToLocalDate(animalEventsTimelineData.startBackgroundDate),
        eventType: FinishingAnimalTimeLinEventsTypeEnum.Background
      });
    }
  
    if(animalEventsTimelineData.startFinishingDate != null) {
      animalTimeLineEvents.push({
        dateTimeServer: animalEventsTimelineData.startFinishingDate,
        localDate: AnimalTimelineUtils.convertDateTimeToLocalDate(animalEventsTimelineData.startFinishingDate),
        eventType: FinishingAnimalTimeLinEventsTypeEnum.Finishing
      });
    }
  
    animalTimeLineEvents.sort((a, b) => {
      return a.dateTimeServer < b.dateTimeServer ? -1 : 1;
    });
    
    if(animalEventsTimelineData.birthDate != null) {
      animalTimeLineEvents.splice(0, 0, {
        eventType: FinishingAnimalTimeLinEventsTypeEnum.Birth,
        localDate: AnimalTimelineUtils.convertDateTimeToLocalDate(animalEventsTimelineData.birthDate),
        dateTimeServer: animalEventsTimelineData.birthDate
      });
    } else {
      if(animalTimeLineEvents.length == 0 ||
         animalTimeLineEvents[0].dateTimeServer > animalEventsTimelineData.herdEntryDate) {
        animalTimeLineEvents.splice(0, 0, {
          eventType: FinishingAnimalTimeLinEventsTypeEnum.HerdEntry,
          localDate: AnimalTimelineUtils.convertDateTimeToLocalDate(animalEventsTimelineData.herdEntryDate),
          dateTimeServer: animalEventsTimelineData.herdEntryDate
        });
      }
    }
    
    for (let i=0; i < animalTimeLineEvents.length; i++) {
      if(i > 0) {
        timelineFlow.legs.push({
          legParts: [{
            backgroundColor: this.finishingTimelineColorsByStatus.get(animalEventsTimelineData.lifeCycleStatus)[1],
            legPartSizePercent: 100
          }],
          legSizePercent: 100
        });
      }
      timelineFlow.nodes.push({
        nodeBackground : this.finishingTimelineColorsByStatus.get(animalEventsTimelineData.lifeCycleStatus)[1],
        detailsText: this.epochDateTimePipe.transform(animalTimeLineEvents[i].localDate, DateTimeFormatEnum.Date),
        iconClass: this.finishingIconByEvent.get(animalTimeLineEvents[i].eventType)
      });
    }
  
    timelineFlow.legs.push({
      legParts: [{
        backgroundColor: this.finishingTimelineColorsByStatus.get(animalEventsTimelineData.lifeCycleStatus)[1],
        legPartSizePercent: 100
      }],
      legSizePercent: 100
    });
    timelineFlow.nodes.push({
      nodeBackground : this.finishingTimelineColorsByStatus.get(animalEventsTimelineData.lifeCycleStatus)[2],
      bottomText: this.translationService.translate('ANIMAL.TIME_LINE.TODAY'),
      detailsText: this.translationService.translate('ANIMAL.TIME_LINE.TODAY')
    });
    return timelineFlow;
  }
  
  private getAnimalTimelineHeader(animalEventsTimelineData: IFinishingAnimalEventsTimelineData) : AnimalTimelineHeader {
    let badge: AnimalBadge = AnimalBadge.None;
    if (animalEventsTimelineData.lifeCycleStatus == AnimalLactationStatus.BullCalfFinishing ||
      animalEventsTimelineData.lifeCycleStatus == AnimalLactationStatus.Steer) {
      badge = AnimalBadge.Male;
    }
    let caption = animalEventsTimelineData.birthDate != null ? 'AGE' : 'HERD_ENTRY';
    return  {
      animalStatus: animalEventsTimelineData.lifeCycleStatus,
      badge: badge,
      color: this.finishingTimelineColorsByStatus.get(animalEventsTimelineData.lifeCycleStatus)[0],
      animalAgeValue: animalEventsTimelineData.ageOrEntryInDays,
      animalAgeCaption: caption
    };
  }
}
