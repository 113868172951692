import {Component, OnDestroy, OnInit} from '@angular/core';
import {IDialog} from '../../../../services/dialogs/model/dialog.model';
import {Subscription} from 'rxjs';
import {EmailNotProvidedDialogService} from './email-not-provided-dialog.service';
import {NotificationDialogTheme} from '../notification-dialog-template/notification-dialog-template.component';
import {Router} from '@angular/router';

@Component({
  selector: 'email-not-provided-dialog',
  styleUrls: ['./email-not-provided-dialog.component.scss'],
  templateUrl: './email-not-provided-dialog.component.html'
})
export class EmailNotProvidedDialogComponent implements IDialog, OnInit, OnDestroy {

  public subscription: Subscription;

  public isOpen;

  public NotificationDialogTheme = NotificationDialogTheme;

  constructor(private readonly emailNotProvidedDialogService: EmailNotProvidedDialogService,
              private router: Router) {
  }

  public ngOnInit(): void {
    this.subscription = this.emailNotProvidedDialogService.popupShown.subscribe(value => {
      if (value) {
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
    })
  }

  public async onRightButtonClick(): Promise<void> {
    this.close();
    await this.router.navigate(['/system/account/users']);
  }

  public close(): void {
    this.emailNotProvidedDialogService.hide();
  }

  public showDialog() {
    this.isOpen = true;
  }

  public ngOnDestroy(): void {
    this.close();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
