import { Injectable } from '@angular/core';
import {ConfigService} from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  // tslint:disable-next-line:no-any
  private ga : any = window[window['GoogleAnalyticsObject'] || 'ga'];

  constructor(public configService:ConfigService) {
    // disable GA
    // this.ga('create', configService.configuration.googleAnalyticsProd ? 'UA-100086976-1' : 'UA-98336149-1', 'auto'); //Dev
  }

  public send(category: string, action: string, label: string): void {
    return; // disable GA

    const serverConfig = this.configService.serverConfig;
    if (serverConfig && serverConfig.user) {
      // tslint:disable-next-line:no-any
      this.ga((tracker: any) => {
        tracker.set({
          dimension1: serverConfig.user.farmRole,
          dimension2: serverConfig.bizModeParameters.applicationBundle,
          dimension3: serverConfig.farmId,
          dimension4: serverConfig.farmMode,
          dimension6: serverConfig.bizModeParameters.youngStockApplicationBundle,
          dimension7: serverConfig.thirdPartyName ? serverConfig.thirdPartyName : 'None'
        });
      });
    }

    this.ga('send', 'event', category, action, label);
  }

  public sendSortGateCategory(action: string, label: string): void {
    this.send('sort_gate', action, label);
  }

  public sendAnimalBatchEvent(eventType: string, numberOfAnimals: number): void {
    this.send('EventBatch', eventType, numberOfAnimals.toString());
  }

  public sendAnimalEvent(eventType: string): void {
    this.send('Add Animal Event', 'Save', eventType);
  }
}
