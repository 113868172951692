<div outsideClickHandler (onOutsideClick)="closePopup()">
  <div class="translation-picker-button" [ngClass]="{ open: isPickerOpen }" (click)="onTranslationPickerButtonClicked()" [attr.sh-id]="'lang_select_toggle'">
    <div class="selected-language">
      <div class="lang-icon" [ngClass]="'lang-icon-' + selectedLanguage" [attr.sh-id]="'selected-lang-' + selectedLanguage"></div>
      <span class="selected-language" [ngClass]="{ whiteColor: (theme ==='White' && !isPickerOpen)}">{{ 'LOCALES.' + selectedLanguage | translate }}</span>
    </div>
    <div class="toggle" [ngClass]="{ 'toggle_white': theme === TranslationPickerThemeEnum.White, 'toggle_black': theme === TranslationPickerThemeEnum.Black }">
      <div class="icon-up" *ngIf="!isPickerOpen"></div>
      <div class="icon-down" *ngIf="isPickerOpen"></div>
    </div>
  </div>
  <div class="translation-picker-dialog" *ngIf="isPickerOpen" @picker-transition sh-id="lang_select_dialog">
    <div class="shadow-top-flipped-strong"></div>
    <ul class="languages-list-section" *ngFor="let languageSection of languageSections">
      <li *ngFor="let language of languageSection" (click)="onTranslationChanged(language)" [attr.sh-id]="'lang-' + language">
        <span class="selected-language" [attr.translation-id]="'LOCALES.' + language" [attr.sh-id]="'lang-' + language + '-text'">{{ 'LOCALES.' + language | translate }}</span>
        <div class="lang-icon" [ngClass]="'lang-icon-' + language"></div>
      </li>
    </ul>
  </div>
</div>
