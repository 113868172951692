<ng-container *ngFor="let legPart of legDetails.legParts; index as legPartIndex">
  <div class="leg-part" [ngStyle]="{'width': legPart.legPartSizePercent + '%'}">
    <div class="leg-part-fill-area" [ngStyle]="{'background': legPart.backgroundColor}">
      <span class="leg-part-inner-text" *ngIf="legPart.innerText" [ngStyle]="{'color': legPart.innerTextColor}">{{legPart.innerText}}</span>
    </div>
  </div>
  <div *ngIf="legPart.bottomText" class="leg-part-bottom">
    <div class="leg-part-bottom-text">{{legPart.bottomText | translate}}</div>
  </div>
</ng-container>
