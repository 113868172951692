<description-cell-details-template [hasRowDetails]="row.effectiveInseminationDate || row.embryoAge"
                                   [isRowExpanded]="isExpanded"
                                   [row]="row"
                                   [rowShId]="row.eventId"
                                   (toggleExpanded)="toggleExpanded()">
  <ng-container key>
    <span [attr.translation-id]="'ANIMAL.EVENTS.EVENT_DESCRIPTION.RESULT'">
      {{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.RESULT' | translate}}:&nbsp;
    </span>
  </ng-container>
  <ng-container value>
    <span [attr.translation-id]="'ANIMAL.EVENTS.EVENT_TYPE.negativePregnancyCheck'">
      {{"ANIMAL.EVENTS.EVENT_TYPE.negativePregnancyCheck" | translate}}
    </span>
  </ng-container>
  <ng-container content>
    <p class="description-item" *ngIf="row.effectiveInseminationDate">
      <span [attr.translation-id]="'ANIMAL.EVENTS.EVENT_DESCRIPTION.EFFECTIVE_INSEMINATION_DATE'">
        {{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.EFFECTIVE_INSEMINATION_DATE' | translate}}:&nbsp;
      </span>
      <span class="value">{{row.effectiveInseminationDate | epochDateTime:DateTimeFormatEnum.Date}}</span>
    </p>
    <p class="description-item" *ngIf="row.embryoAge">
      <span [attr.translation-id]="'ANIMAL.EVENTS.EVENT_DESCRIPTION.EMBRYO_AGE'">
        {{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.EMBRYO_AGE' | translate}}:&nbsp;
      </span>
      <span class="value">
        {{row.embryoAge}}
        <span [attr.translation-id]="'ANIMAL.EVENTS.EVENT_DESCRIPTION.DAYS'">
          &nbsp;{{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.DAYS' | translate}}
        </span>
      </span>
    </p>
  </ng-container>
</description-cell-details-template>
