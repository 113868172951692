import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {CreateSendToSortingGateDialogModel} from '../../../../services/dialogs/model/dialog-actions-model';
import {DialogActionsService} from '../../../../services/dialogs/dialog-actions.service';
import {DropdownListItem} from '../../dropdown-list/dropdown-list-model';
import {
  ScheduleAnimalsRepeatModel,
  ScheduleMetaType,
  ScheduleRepeatModel,
  SchedulerShiftModel,
  ScheduleSingleModel
} from '../../../../services/sorting-gate/model/scheduler-model';
import {SortingGateService} from '../../../../services/sorting-gate/sorting-gate.service';
import * as moment from 'moment';
import {IDay} from '../../../../services/sorting-gate/model/sorting-gate-model';
import {ConcreteShiftDetails, ShiftModel} from '../../../../services/sorting-gate/model/shifts-model';
import {DropdownColorScheme, DropdownCustomIcon} from '../../dropdown-list/regular-dropdown-list/regular-dropdown-list.component';
import {SuccessDialogService} from '../success-dialog/success-dialog.service';
import {ErrorDialogService} from '../error-dialog/error-dialog.service';
import {Failure} from '../../../../services/registration/model/registration.model';
import { AutoCompleteColorScheme } from '../../auto-complete/auto-complete-model';

@Component({
  selector: 'send-to-sorting-gate-dialog',
  templateUrl: './send-to-sorting-gate-dialog.component.html',
  styleUrls: ['./send-to-sorting-gate-dialog.component.scss']
})
export class SendToSortingGateDialogComponent implements OnInit, OnDestroy {

  private dialogActionsSubscription: Subscription;

  public isOpen: boolean;

  public model: CreateSendToSortingGateDialogModel;

  public daysDropdownListItems: DropdownListItem[];

  public activeDayDropdownListItem: DropdownListItem;

  public repeatDaysDropdownListItems: DropdownListItem[];

  public activeRepeatDayDropdownListItem: DropdownListItem;

  public concreteShiftsDropdownListItems: DropdownListItem<ConcreteShiftDetails>[];

  public selectedConcreteShiftDropdownListItem: DropdownListItem<ConcreteShiftDetails>;

  public pensDropdownListItems: DropdownListItem[];

  public activePenDropdownListItem: DropdownListItem;

  public isRepeatActive: boolean = false;

  public DropdownColorScheme = DropdownColorScheme;

  public DropdownCustomIcon = DropdownCustomIcon;

  public shifts: ShiftModel[] = [];

  public comments : string[] = [];

  public AutoCompleteColorScheme = AutoCompleteColorScheme;

  private selectedShiftId : number;

  constructor(private dialogActionsService: DialogActionsService, private sortingGateService: SortingGateService,
              private successDialogService: SuccessDialogService, private errorDialogService: ErrorDialogService) { }

  public ngOnInit() {
    this.dialogActionsSubscription = this.dialogActionsService.sendToSortingGateDialogSubject.subscribe(async (model: CreateSendToSortingGateDialogModel) => {
      this.isRepeatActive = false;
      this.comments = await this.sortingGateService.getSortingTasksComments();
      this.daysDropdownListItems = model.days.map(value => {
        return {
          value: value.day,
          displayValue: value.formatted,
          disabled: false,
          icon: null
        }});
      if (model.activeDay) {
        this.activeDayDropdownListItem = this.daysDropdownListItems.find((item: DropdownListItem) => {
          return item.value === model.activeDay.day;
        });
      }
      this.repeatDaysDropdownListItems = model.repeatDays.map(value => {
        return {
          value: value.value,
          displayValue: value.label,
          disabled: false,
          icon: null
        }});
      if (model.activeRepeatDay) {
        this.activeRepeatDayDropdownListItem = this.repeatDaysDropdownListItems.find((item: DropdownListItem) => {
          return item.value === model.activeRepeatDay.value;
        })
      }
      this.concreteShiftsDropdownListItems = model.concreteShifts.map(value => {
        return {
          value: value,
          displayValue: value.shift.name,
          disabled: false,
          icon: null
        }});
      if (model.activeShift) {
        this.selectedConcreteShiftDropdownListItem = this.concreteShiftsDropdownListItems.find((item: DropdownListItem) => {
          return item.value === model.activeShift;
        });
        this.selectedShiftId = this.selectedConcreteShiftDropdownListItem.value.shift.shiftId;
      }
      this.pensDropdownListItems = model.pens.map(value => {
        return {
          value: value.penId,
          displayValue: value.name,
          disabled: false,
          icon: null
        }});
      this.shifts = model.schedulerModel.shifts;
      this.model = model;
      if (model.activePen) {
        const activePen = this.pensDropdownListItems.find((item: DropdownListItem) => item.value === model.activePen.penId);
        if (activePen) {
          this.activePenDropdownListItem = activePen;
          this.onPenSelected(activePen);
        }
      } else if (this.pensDropdownListItems.length > 0) {
        this.activePenDropdownListItem = this.pensDropdownListItems[0];
        this.onPenSelected(this.pensDropdownListItems[0]);
      }
      this.isOpen = true;
    });
  }

  public get isApplyDisabled () {
    return !(this.model.sortingTask && this.model.sortingTask.penId && this.isShiftSetToModel());
  }

  public closeClick() {
    if(this.model) {
      this.isOpen = false;
    }
  }

  public async applyClick(): Promise<void> {
    this.isOpen = false;
    const createSortingTaskResponse = await this.sortingGateService.createSortingTask(this.model.sortingTask);

    if (createSortingTaskResponse.status == 200) {
      this.successDialogService.show(null, null);
    } else if (createSortingTaskResponse.errorResponseBody && createSortingTaskResponse.errorResponseBody && createSortingTaskResponse.errorResponseBody.result && createSortingTaskResponse.errorResponseBody.result.failures) {
      // tslint:disable-next-line:no-any
      const failures = createSortingTaskResponse.errorResponseBody.result.failures.map((failureData: any) => new Failure(failureData.key, failureData.fieldName));
      setTimeout(() => {
        this.errorDialogService.show('MANAGE.EVENTS.SORT_EVENT_FAILED', failures, 'SORTING_GATE.SERVER_ERRORS', null);
      }, 200);
    }
  }

  public onDaySelected(item: DropdownListItem) {
    let concreteShifts: ConcreteShiftDetails[];
    this.model.sortingTask.date = moment(item.value).clone().utc().startOf('day').unix();
    concreteShifts = this.sortingGateService.generateConcreteShifts(this.model.shifts, true, this.model.sortingTask.date);
    this.concreteShiftsDropdownListItems = concreteShifts.map(value => {
      return {
        value: value,
        displayValue: value.shift.name,
        disabled: false,
        icon: null
      }});
    if (concreteShifts.length === 1) {
      this.selectedConcreteShiftDropdownListItem = this.concreteShiftsDropdownListItems[0];
      this.setSelectedShiftIdToModel(this.selectedConcreteShiftDropdownListItem.value.shift.shiftId);
      this.model.sortingTask.date = moment.unix(this.selectedConcreteShiftDropdownListItem.value.startDateTime).utc().startOf('day').unix();
    }
  }

  public onShiftSelected(item: DropdownListItem<ConcreteShiftDetails>) {
    this.setSelectedShiftIdToModel(item.value.shift.shiftId);
    this.model.sortingTask.date = moment.unix(item.value.startDateTime).utc().startOf('day').unix();
  }

  public onTaskNameChange(taskName: string): void {
    this.model.sortingTask.comment = taskName;
  }

  public onPenSelected(item: DropdownListItem) {
    this.model.sortingTask.penId = item.value;
  }

  public onRepeatDaySelected(item: DropdownListItem) {
    (<ScheduleAnimalsRepeatModel>this.model.sortingTask.schedule).repeatDays = item.value;
  }

  public toggleRepeatsForField() {
    if (!this.isRepeatActive) {
      let scheduleAnimalsRepeatModel = new ScheduleAnimalsRepeatModel();
      scheduleAnimalsRepeatModel.repeatDays = SortingGateService.MinRepeatDays;
      this.model.sortingTask.schedule = scheduleAnimalsRepeatModel;
      this.setSelectedShiftIdToModel(this.selectedShiftId);
      this.isRepeatActive = true;
    } else {
      this.model.sortingTask.schedule = new ScheduleSingleModel();
      this.setSelectedShiftIdToModel(this.selectedShiftId);
      this.isRepeatActive = false;
    }
  }

  public clickShift(shiftId: number): void {
    if (!this.isActiveShift(shiftId)) {
      (this.model.sortingTask.schedule as ScheduleAnimalsRepeatModel).shiftIds.push(shiftId);
    } else {
      (this.model.sortingTask.schedule as ScheduleAnimalsRepeatModel).shiftIds = (this.model.sortingTask.schedule as ScheduleRepeatModel).shiftIds
        .filter((s: number) => s !== shiftId);
    }
  }

  public clickAllShifts(): void {
    if (this.isActiveAllShifts()) {
      (this.model.sortingTask.schedule as ScheduleAnimalsRepeatModel).shiftIds = [];
    } else {
      (this.model.sortingTask.schedule as ScheduleAnimalsRepeatModel).shiftIds = this.shifts.map((shift: SchedulerShiftModel) => shift.shiftId);
    }
  }

  private isActiveAllShifts(): boolean {
    if ((this.model.sortingTask.schedule as ScheduleAnimalsRepeatModel).shiftIds == null) {
      return false;
    }

    return this.shifts.map((shift: SchedulerShiftModel) => shift.shiftId).every((id: number) => {
      return (this.model.sortingTask.schedule as ScheduleAnimalsRepeatModel).shiftIds.indexOf(id) >= 0;
    });
  }

  private isActiveShift(shiftId: number): boolean {
    return (this.model.sortingTask.schedule as ScheduleAnimalsRepeatModel).shiftIds != null &&
      (this.model.sortingTask.schedule as ScheduleAnimalsRepeatModel).shiftIds.indexOf(shiftId) !== -1;
  }

  private setSelectedShiftIdToModel(shiftId: number) {
    this.selectedShiftId = shiftId;
    if(this.model.sortingTask.schedule.type == ScheduleMetaType.single) {
      (this.model.sortingTask.schedule as ScheduleSingleModel).shiftId = this.selectedShiftId;
    } else {
      if(this.selectedShiftId == null) {
        (this.model.sortingTask.schedule as ScheduleAnimalsRepeatModel).shiftIds = [];
      } else {
        (this.model.sortingTask.schedule as ScheduleAnimalsRepeatModel).shiftIds = [this.selectedShiftId];
      }
    }
  }

  private isShiftSetToModel() : boolean {
    if(this.model.sortingTask.schedule.type == ScheduleMetaType.single) {
      return (this.model.sortingTask.schedule as ScheduleSingleModel).shiftId != null;
    } else {
      return  (this.model.sortingTask.schedule as ScheduleAnimalsRepeatModel).shiftIds == null ||
              (this.model.sortingTask.schedule as ScheduleAnimalsRepeatModel).shiftIds.length > 0;
    }
  }
  public get animalIds(): string[] {
    if (this.model) {
      return this.model.sortingTask.animals;
    }
  }

  public ngOnDestroy(): void {
    this.activePenDropdownListItem = null;
    if (this.dialogActionsSubscription) {
      this.dialogActionsSubscription.unsubscribe();
    }
  }
}
