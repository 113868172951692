<div *ngIf="isOpen" outsideClickHandler (onOutsideClick)="close()" [outsideClickIgnoreListComponents]="['menuItemSortingGate']" class="sorting-menu" @sgMenuTransition>
  <div class="sorting-gate-menu-header">
    <span class="sorting-gate-menu-title" [attr.translation-id]="'SYSTEM.NAVIGATION.SORTING_MENU_ITEM'">
      {{ 'SYSTEM.NAVIGATION.SORTING_MENU_ITEM' | translate }}
    </span>
    <a class="close-sorting-gate-menu" (click)="close()">
      <div class="icon-close"></div>
    </a>
    <div class="shadow"></div>
  </div>
  <div class="sorting-gate-menu-section">
    <ul class="navs">
      <li class="nav-item">
        <div class="report-link-wrapper" [routerLink]="'/sorting-gate/report/'" (click)="close()">
          <a class="nav-link">
            <span class="nav-link-text">{{'SORTING_GATE.MENU.SORTING_REPORT'|translate}}</span>
          </a>
        </div>
      </li>
      <li class="nav-item">
        <div class="report-link-wrapper" [routerLink]="'/sorting-gate/scheduler/'" (click)="close()" [attr.sh-id]="'menu-item-sorting-scheduler'">
          <a class="nav-link">
            <span class="nav-link-text">{{'SORTING_GATE.MENU.SORTING_SCHEDULER'|translate}}</span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</div>
