<div class="farm-registration-wrapper">
  <div class="farm-registration-container">
    <div class="inner-farm-registration-container">
      <div class="steps-wizard-container">
        <div class="gradient-and-steps-container">
          <div class="gradient-wrapper">
            <div class="gradient"></div>
          </div>
          <div class="circles-wrapper">
            <div class="circle left-circle">
              <div class="farm-details" *ngIf="!isStepGreaterThan(FarmRegistrationStepsList.AccountDetails)"></div>
              <div class="success" *ngIf="isStepGreaterThan(FarmRegistrationStepsList.AccountDetails)"></div>
            </div>
            <div class="circle middle-circle" *ngIf="!isFinishingStandalone()">
              <div class="herd-management" *ngIf="!isStepGreaterThan(FarmRegistrationStepsList.HerdManagement)"></div>
              <div class="success" *ngIf="isStepGreaterThan(FarmRegistrationStepsList.HerdManagement)"></div>
            </div>
            <div class="circle right-circle">
              <div class="farm-topology"></div>
            </div>
          </div>
          <div class="text-containers-wrapper">
            <div class="text left-text" [attr.translation-id]="'REGISTRATION.FLOW.ACCOUNT_DETAILS'">{{ 'REGISTRATION.FLOW.ACCOUNT_DETAILS' | translate }}</div>
            <div class="text center-text" *ngIf="!isFinishingStandalone()" [attr.translation-id]="'REGISTRATION.TIME_LINE.HERD_MANAGEMENT_SOFTWARE'">{{ 'REGISTRATION.TIME_LINE.HERD_MANAGEMENT_SOFTWARE' | translate }}</div>
            <div class="text right-text" [attr.translation-id]="'REGISTRATION.TIME_LINE.FARM_TOPOLOGY'">{{ 'REGISTRATION.TIME_LINE.FARM_TOPOLOGY' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="form-body-container">
        <div class="arrow-section" [ngClass]="getTriangleLocationClass()">
          <div class="triangle"></div>
        </div>
        <div class="body-section">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div class="buttons-section">
        <div class="trial-mode-text-container">
          <div class="trial-mode-text" (click)="goToTrialMode()">
            <div class="link-icon"></div>
            <div class="text" [attr.translation-id]="'REGISTRATION.FLOW.WORK_IN_TRIAL_MODE'">{{ 'REGISTRATION.FLOW.WORK_IN_TRIAL_MODE' | translate }}</div>
          </div>
        </div>
        <div class="buttons-div-wrapper">
          <a class="button back" [attr.translation-id]="'REGISTRATION.FLOW.BACK'" (click)="navigateBack()">{{ 'REGISTRATION.FLOW.BACK' | translate }}</a>
          <a class="button next" [attr.sh-id]="'next_button'" [ngClass]="{ disabled: isNextDisabled() }" *ngIf="currentStep !== FarmRegistrationStepsList.FarmTopology" [attr.translation-id]="'REGISTRATION.FLOW.NEXT'" (click)="navigateNext()">{{ 'REGISTRATION.FLOW.NEXT' | translate}}</a>
          <a class="button finish" [attr.sh-id]="'finish_button'" [ngClass]="{ 'disabled': !isSelectedCollectors() }" *ngIf="currentStep === FarmRegistrationStepsList.FarmTopology" [attr.translation-id]="'REGISTRATION.FLOW.NEXT'" (click)="finishRegistration()">{{ 'REGISTRATION.FLOW.FINISH' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="language-picker-wrapper">
    <translation-picker [theme]="TranslationPickerThemeEnum.White"></translation-picker>
  </div>
</div>
<div class="loading" *ngIf="registrationService.isLoading">
  <div class="loading-icon"></div>
</div>
