<div class="header-fill-area"
     [ngStyle]="{'background': header.color}">
  <div class="header-badge none" *ngIf="header.badge == null || header.badge ==AnimalBadge.None">

  </div>
  <div class="header-badge male" *ngIf="header.badge == AnimalBadge.Male" translation-id="ANIMAL.TIME_LINE.MALE">
    {{ 'ANIMAL.TIME_LINE.MALE' | translate}}
  </div>
  <div class="header-badge dnb" *ngIf="header.badge == AnimalBadge.DNB" translation-id="ANIMAL.TIME_LINE.DNB">
    {{ 'ANIMAL.TIME_LINE.DNB' | translate}}
  </div>
  <div class="header-caption">
    <span class="animal-status"  [attr.sh-id]='"animal-status"' [attr.translation-id]="'ANIMAL.TIME_LINE.STATUS.' + header.animalStatus">{{'ANIMAL.TIME_LINE.STATUS.'+ header.animalStatus| translate}}</span>
    <span class="animal-age" [attr.translation-id]="'ANIMAL.TIME_LINE.' + header.animalAgeCaption">{{ 'ANIMAL.TIME_LINE.' + header.animalAgeCaption | translate}} : {{header.animalAgeValue}}</span>
  </div>
</div>
<div class="first-node-background"></div>
