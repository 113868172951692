import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ApplicationBundleRoutingMode, RoutingService} from '../../../../services/routing/routing.service';
import {FarmMode} from '../../../../services/config/model/server-config';
import {ActivatedRoute} from '@angular/router';
import {ConfigService} from '../../../../services/config/config.service';
import {Subscription} from 'rxjs';
import {ErrorModel, NoteNames} from '../../../../services/model/common-model';
import {AutoCompleteColorScheme} from '../../../../common/components/auto-complete/auto-complete-model';
import {ChipsItem, ChipsItemBackgroundColor} from '../../../../common/components/chips/chips-item.component';
import {NotesService} from '../../../../services/notes/notes.service';
import {TranslationService} from '../../../../services/translations/translation.service';
import {EntityType, SearchService} from '../../../../services/search/search.service';
import {OperationType, SearchEntry} from '../../../../services/search/model/search.model';
import {RegularAutoCompleteComponent} from '../../../../common/components/auto-complete/regular-auto-complete/regular-auto-complete.component';
import {NoteObject, NoteRow, NotesMetadataResult} from '../../../../services/notes/model/notes.model';
import {HttpResponseResult} from '../../../../services/rest-api/rest-api.service';
import * as moment from 'moment';
import {LoadingIconService} from '../../../../services/loading-icon/loading-icon.service';
import {SuccessDialogService} from '../../../../common/components/dialogs/success-dialog/success-dialog.service';
import {UserPermissionsService} from '../../../../services/auth/user-permissions.service';
import {SaveDataViewStateService} from '../../../../services/ui/view-state/save-data-view-state.service';
import {EditNoteModel} from './models/edit-note.model';
import {EditorMode} from './models/editor.mode';
import * as _ from 'lodash';
import {cloneDeep} from 'lodash';
import {CornerColor, CornerContainerBodyBackground} from 'src/app/common/components/containers/corner-container/corner-container.component';

export enum AddNoteFields {
  noteName = 'name',
  date = 'startDateTime'
}

export enum PickerColor {
  blue = '#1999d5',
  white = '#FFFFFF'
}

@Component({
  selector: 'note-editor',
  templateUrl: './note-editor.component.html',
  styleUrls: ['./note-editor.component.scss']
})
export class NoteEditorComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public editorMode: EditorMode;

  @Input()
  public editNoteModel: EditNoteModel;

  public Error: ErrorModel;

  public notesList: string[] = [];

  public noteSearchQuery: string;

  public groupsList: string[] = [];

  public applicationBundleRoutingMode: ApplicationBundleRoutingMode;

  public ApplicationBundleRoutingMode = ApplicationBundleRoutingMode;

  public AutoCompleteColorScheme = AutoCompleteColorScheme;

  public queryParamsSubscription: Subscription;

  private notesMetadataSubscription: Subscription;

  private searchEntities: SearchEntry[] = [];

  public AddNoteFields = AddNoteFields;

  public pickerColor = PickerColor;

  public readonly NoteEditorAreaName: string = 'noteEditor';

  public CornerColor = CornerColor;

  public CornerContainerBodyBackground = CornerContainerBodyBackground;

  public ChipsItemBackgroundColor = ChipsItemBackgroundColor;

  @ViewChild('effectiveGroupsAutoComplete')
  public effectiveGroupsAutoComplete: RegularAutoCompleteComponent;

  @ViewChild('noteNameAutoComplete')
  public noteNameAutoComplete: RegularAutoCompleteComponent;

  @Output()
  public onAddNoteClicked: EventEmitter<EditorMode> = new EventEmitter<EditorMode>();

  @Output()
  public onNoteListChanged: EventEmitter<void> = new EventEmitter<void>();

  private onApproveDataLossClickSubscription: Subscription;

  private originalEditNoteModel: EditNoteModel;

  constructor(private route: ActivatedRoute,
              private configService: ConfigService,
              private notesService: NotesService,
              private readonly loadingIconService: LoadingIconService,
              private readonly userPermissionsService: UserPermissionsService,
              private readonly successDialogService: SuccessDialogService,
              private readonly saveDataViewStateService:SaveDataViewStateService,
              private searchService: SearchService,
              private translationService: TranslationService,
              private routingService: RoutingService) { }

  public async ngOnInit() {
    this.queryParamsSubscription = this.route.queryParams.subscribe(queryParams => {
      if (this.configService.serverConfig.farmMode === FarmMode.Dairy) {
        this.applicationBundleRoutingMode = null;
      } else {
        this.applicationBundleRoutingMode = this.routingService.getApplicationBundleRoutingMode(queryParams);
      }
    });
    await this.initAddNote();
    this.notesMetadataSubscription = this.notesService.getNotesMetadataSubject.subscribe(async () => {
      if (this.editorMode) {
        await this.getNewNotesMetadata();
      }
    });
    this.onApproveDataLossClickSubscription = this.saveDataViewStateService.onApproveDataLossClick.subscribe(() => {
      this.toggleNote();
    });
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if ((changes.editorMode && !changes.editorMode.previousValue && changes.editorMode.currentValue == EditorMode.edit)
      || (this.editorMode == EditorMode.edit && changes.editNoteModel.previousValue != changes.editNoteModel.currentValue)) {
      await this.getNewNotesMetadata();
      this.originalEditNoteModel = cloneDeep(this.editNoteModel);
      this.saveDataViewStateService.activeEditArea = null;
      this.clearErrors();
    }
  }

  private async getNewNotesMetadata() {
    this.groupsList = [];
    this.searchEntities = await this.searchService.listOfAvailableGroups();
    this.searchEntities.forEach((group: SearchEntry) => {
      if (this.applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing) {
        if (group.operationType === OperationType.BeefFinishing) {
          this.groupsList.push(group.entityName);
        }
      } else {
        if (group.operationType !== OperationType.BeefFinishing) {
          this.groupsList.push(group.entityName);
        }
      }
    });
    this.notesList = [];
    let response: HttpResponseResult<NotesMetadataResult>;
    if (this.applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing) {
      response = await this.notesService.getAddNewNoteFinishingMetadata();
    } else {
      response = await this.notesService.getAddNewNoteBreedingMetadata();
    }
    if (response.status === 200) {
      response.responseBody.names.forEach((existingNoteName: NoteNames) => {
        this.notesList.push(existingNoteName.value);
      });
    }
  }

  public onEpochChanged(epoch) {
    this.editNoteModel.date = epoch;
    this.clearErrors();
  }

  public ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
    if (this.notesMetadataSubscription) {
      this.notesMetadataSubscription.unsubscribe();
    }
    if (this.onApproveDataLossClickSubscription) {
      this.onApproveDataLossClickSubscription.unsubscribe();
    }
    this.loadingIconService.hide();
  }

  public async toggleNote() {
    if (this.editorMode) {
      this.onAddNoteClicked.emit(null);
      this.saveDataViewStateService.activeEditArea = null;
    } else {
      await this.initAddNote();
      this.saveDataViewStateService.activeEditArea = this.NoteEditorAreaName;
      this.onAddNoteClicked.emit(EditorMode.create);
    }
  }

  public async initAddNote() {
    await this.getNewNotesMetadata();
    this.editNoteModel = new EditNoteModel();
    this.originalEditNoteModel = new EditNoteModel();
    if (this.noteNameAutoComplete) {
      this.noteNameAutoComplete.clearValue();
    }
    this.clearErrors();
  }

  public isAllGroupsShown() {
    if (this.editNoteModel && this.editNoteModel.selectedGroups.length === 0) {
      return this.translationService.translate('LED_TASKS.TASK_EDITOR.ALL_GROUPS');
    } else {
      return this.translationService.translate('LED_TASKS.TASK_EDITOR.SELECT_GROUP');
    }
  }

  public isPlaceholderShouldBeBlack() {
    return this.editNoteModel && this.editNoteModel.selectedGroups.length === 0;
  }

  public onNoteSelected(selectedNote: string) {
    if (selectedNote == null) {
      this.clearErrors();
      return;
    }
    this.editNoteModel.noteName = selectedNote;
    this.clearErrors();
  }

  public onGroupSelected(selectedGroup: string) {
    if (selectedGroup == null) {
      return;
    }
    let groupSearchEntity = this.searchEntities.find((group: SearchEntry) => group.entityName === selectedGroup && group.entityType === EntityType.group);
    let groupItem: ChipsItem = new ChipsItem();
    groupItem.chipId = groupSearchEntity.entityId;
    groupItem.chipName = groupSearchEntity.entityName;
    const isExist = this.editNoteModel.selectedGroups.find((group: ChipsItem) => group.chipId === groupItem.chipId);
    if (isExist) {
      return;
    } else {
      this.clearErrors();
      this.editNoteModel.selectedGroups.push(groupItem);
      let index = this.groupsList.findIndex(group => group === groupItem.chipName);
      this.groupsList.splice(index, 1);
      this.noteSearchQuery = null;
      this.effectiveGroupsAutoComplete.clearValue();
    }
  }

  public clearErrors() {
    this.Error = null;
  }

  public isFieldError(errorField: AddNoteFields): boolean {
    return this.Error && this.Error.fieldName === errorField;
  }

  public removeSelectedGroup(groupToRemove: ChipsItem) {
    const isExist = this.groupsList.find((groupString: string) => groupString === groupToRemove.chipName);
    if (!isExist) {
      this.groupsList.push(groupToRemove.chipName);
    }
    const index = this.editNoteModel.selectedGroups.findIndex((groupItem: ChipsItem) => groupItem.chipId === groupToRemove.chipId);
    this.editNoteModel.selectedGroups.splice(index, 1);
  }

  public async saveNote() {
    if (this.isRequestValid()) {
      this.loadingIconService.show();
      let response = await this.proceedSave();
      if (response.status === 201 || response.status === 200) {
        // reload notes list
        await this.initAddNote();
        this.onNoteListChanged.emit();
        this.loadingIconService.hide();
        this.successDialogService.show();

      } else {
        if (response && response.errorResponseBody) {
          this.Error = new ErrorModel();
          if (response.status == 401) {
            this.Error.key = response.status.toString();
            this.Error.fieldName = response.statusText;
          } else if (response.errorResponseBody.result.failures) {
            this.Error.key = response.errorResponseBody.result.failures[0].key;
            this.Error.fieldName = response.errorResponseBody.result.failures[0].fieldName;
          }

        }
        this.loadingIconService.hide();
      }
    }
  }

  private proceedSave(): Promise<HttpResponseResult<NoteRow[]>> {
    switch (this.editorMode) {
      case EditorMode.create: return this.createNote();
      case EditorMode.edit: return this.updateNote();
    }
  }

  private createNote(): Promise<HttpResponseResult<NoteRow[]>> {
    let response: Promise<HttpResponseResult<NoteRow[]>>;
    if (this.applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing) {
      response = this.notesService.createFinishingNote(this.getRequest());
    } else {
      response = this.notesService.createBreedingNote(this.getRequest());
    }
    return response;
  }

  private updateNote(): Promise<HttpResponseResult<NoteRow[]>> {
    let response: Promise<HttpResponseResult<NoteRow[]>>;
    if (this.applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing) {
      response = this.notesService.updateFinishingNote(this.editNoteModel.id, this.getRequest());
    } else {
      response = this.notesService.updateBreedingNote(this.editNoteModel.id, this.getRequest());
    }
    return response;
  }

  private getRequest() {
    const updateNoteRequest: NoteObject = new NoteObject();
    updateNoteRequest.name = this.editNoteModel.noteName;
    updateNoteRequest.startDateTime = this.editNoteModel.date.epoch + (moment().utcOffset() * 60);
    updateNoteRequest.groupIds = this.getGroupIdsFromSelectedGroups();
    return updateNoteRequest;
  }

  private getGroupIdsFromSelectedGroups(): number[] {
    let selectedGroupIdsToReturn: number[] = [];
    this.editNoteModel.selectedGroups.forEach((selectedGroup: ChipsItem) => {
      selectedGroupIdsToReturn.push(selectedGroup.chipId);
    });
    return selectedGroupIdsToReturn;
  }

  public isRequestValid(): boolean {
    if (this.editNoteModel.noteName == null || this.editNoteModel.noteName === '') {
      this.Error = new ErrorModel();
      this.Error.fieldName = AddNoteFields.noteName.toString();
      this.Error.key = 'NoteNameIsMissing';
      return false;
    }
    if (!this.editNoteModel.date.epoch) {
      this.Error = new ErrorModel();
      this.Error.fieldName = AddNoteFields.date.toString();
      this.Error.key = 'StartDateTimeIsMissing';
      return false;
    }
    return true;
  }

  public get isSaveButtonActive(): boolean {
    return this.isModified && !!this.editNoteModel.date.epoch && !!this.editNoteModel.noteName;
  }

  public get hasPermissionsForNotes() {
    return this.userPermissionsService.hasPermissionsForNotes();
  }

  public get translationId(): string {
    switch (this.editorMode) {
      case EditorMode.create: return 'MANAGE.NOTES.HEADER.ADD_NEW_NOTE';
      case EditorMode.edit: return 'MANAGE.NOTES.HEADER.UPDATE_NOTE';
    }
  }

  public get isModified(): boolean {
    let isModified = !_.isEqual(this.editNoteModel, this.originalEditNoteModel);
    if(isModified) {
      this.saveDataViewStateService.activeEditArea = this.NoteEditorAreaName;
    } else {
      this.saveDataViewStateService.activeEditArea = null;
    }
    return isModified;
  }
}
