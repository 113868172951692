import {Component, OnDestroy, OnInit} from '@angular/core';
import {ANIMAL_ALERTS_TRANSITIONS} from '../../../common/animations/animations';
import {AlertNavigation, EntityAlertType, IEntityAlert} from '../../../services/alerts/model/alerts';
import {Subscription} from 'rxjs';
import {ConfigService} from '../../../services/config/config.service';
import {AlertsService} from '../../../services/alerts/alerts.service';
import {TranslationService} from '../../../services/translations/translation.service';
import {GoogleAnalyticsService} from '../../../services/google-analytics/google-analytics.service';
import {AnimalCardTabSection} from '../../card-popup/animal-card/animal-card-model';
import {GroupCardTabSection} from '../../card-popup/group-card/group-card-model';
import {DateTimeFormatEnum} from 'src/app/common/pipes/epoch-date-time.pipe';
import {OperationType} from '../../../services/search/model/search.model';
import {FarmMode} from '../../../services/config/model/server-config';
import {CardViewStateService} from '../../../services/ui/view-state/card-view-state.service';

@Component({
  selector: 'animal-alerts',
  templateUrl: './animal-alerts.component.html',
  styleUrls: ['./animal-alerts.component.scss'],
  animations: [ANIMAL_ALERTS_TRANSITIONS]
})
export class AnimalAlertsComponent implements OnInit, OnDestroy {

  public entitiesListSubscription: Subscription;

  public isPopupOpen: boolean = false;

  public cowAlerts: IEntityAlert[] = [];
  public groupAlerts: IEntityAlert[] = [];

  public DateTimeFormatEnum = DateTimeFormatEnum;

  constructor(public configService: ConfigService,
              private alertsService: AlertsService,
              private translationService: TranslationService,
              private cardViewStateService: CardViewStateService,
              private googleAnalyticsService: GoogleAnalyticsService) { }

  public async ngOnInit() {
      this.entitiesListSubscription = this.alertsService.entityAlertsSubject.subscribe((data) => {
          this.initAlertsData(data);
      });
      let entityAlertsResponse = await this.alertsService.getEntityAlerts();
      this.initAlertsData(entityAlertsResponse.responseBody);
  }

  public ngOnDestroy(): void {
      this.entitiesListSubscription.unsubscribe();
  }

  private initAlertsData(data:IEntityAlert[]) {
      this.cowAlerts = [];
      this.groupAlerts = [];

      if (data) {
        data.forEach((entity: IEntityAlert) => {
          if (entity.entityType.toLowerCase() == EntityAlertType.Cow) {
            this.cowAlerts.push(entity);
          } else if (entity.entityType.toLowerCase() == EntityAlertType.Group) {
            this.groupAlerts.push(entity);
          }
        });
      }
  }

  public get alertCount() {
    return (this.cowAlerts || []).length + (this.groupAlerts || []).length;
  }

  public goToAnimal(entity: IEntityAlert) {
    let navigation: AnimalCardTabSection = AnimalCardTabSection.Details;
    if (entity.alertType === 'Distress' || entity.alertType === 'PreCalving' || entity.alertType === 'PostCalving') {
      navigation = AnimalCardTabSection.GraphsHealth;
    }
    this.cardViewStateService.openAnimalCard(entity.entityId,
                                            this.configService.serverConfig.farmMode == FarmMode.Beef ? OperationType.BeefBreeding : OperationType.Dairy,
                                             this.cowAlerts.map(alert => alert.entityId),
                                            ['GROUP.NAVIGATION.COW_IN', 'GROUP.REPORTS.SPECIAL_TITLE.NAVIGATION.MANAGE'],
                                             navigation,
                                            null);
    this.isPopupOpen = false;
  }

  public goToGroup(entity: IEntityAlert) {
    const navigation: GroupCardTabSection = AnimalAlertsComponent.chooseGroupCardTab(entity);
    this.cardViewStateService.openGroupCard(entity.entityId,
                                           this.configService.serverConfig.farmMode == FarmMode.Beef ? OperationType.BeefBreeding : OperationType.Dairy,
                                            this.groupAlerts.map(alert => alert.entityId),
                                           ['GROUP.NAVIGATION.GROUP_IN', 'GROUP.NAVIGATION.GROUP_IN_FARM'],
                                            navigation,
                                           null);
    this.isPopupOpen = false;
  }

  public closeAlertsDialog() {
    this.isPopupOpen = false;
  }

  public toggleAlertsDialog() {
    if (this.isPopupOpen) {
      this.closeAlertsDialog();
    } else {
      this.isPopupOpen = true;
      this.googleAnalyticsService.send('TopMenu', 'Open', 'AnimalAlerts');
    }
  }

  private static chooseGroupCardTab(entity: IEntityAlert): GroupCardTabSection {
    switch (entity.navigation) {
      case AlertNavigation.groupRoutineActivityGraph: return GroupCardTabSection.GraphsActivity;
      case AlertNavigation.groupRoutineRuminationGraph: return GroupCardTabSection.GraphsActivityRumination;
    }
  }
}
