<div class="initialize-trial-mode-page-class">
  <div class="inner-container">
    <div class="dairy-icon" *ngIf="isDairy"></div>
    <div class="breeding-icon" *ngIf="isBreeding"></div>
    <div class="finishing-icon" *ngIf="isFinishing"></div>
    <div class="welcome-text"><span *ngIf="!isTrialOver" [attr.translation-id]="'REGISTRATION.FLOW.WELCOME_TO'">{{ 'REGISTRATION.FLOW.WELCOME_TO' | translate }} </span><span class="farm-type-text" [attr.translation-id]="'REGISTRATION.FLOW.SENSE_TIME ' + getTitleAccordingToFarmMode()">{{ 'REGISTRATION.FLOW.SENSE_TIME' | translate }} {{ getTitleAccordingToFarmMode() | translate }}</span></div>
    <div class="information-text bold margin-top"
         [ngClass]="{ dairyColor: isDairy, breedingColor: isBreeding, finishingColor: isFinishing }"
         *ngIf="isStartedTrialModeForTheFirstTime"
         [attr.translation-id]="'REGISTRATION.FLOW.YOU_CHOSE_TO_WORK_IN_A_TRIAL_MODE'">{{ 'REGISTRATION.FLOW.YOU_CHOSE_TO_WORK_IN_A_TRIAL_MODE' | translate }}</div>
    <div class="information-text bold margin-top" *ngIf="!isStartedTrialModeForTheFirstTime && !isTrialOver"
         [ngClass]="{ dairyColor: isDairy, breedingColor: isBreeding, finishingColor: isFinishing }"
         [attr.translation-id]="'REGISTRATION.FLOW.YOU_ARE_IN_A_TRIAL_MODE_YOU_HAVE ' + configService.anonymousAuthResult.trialMode?.daysToExpiration + ' REGISTRATION.FLOW.DAYS_LEFT'">{{ 'REGISTRATION.FLOW.YOU_ARE_IN_A_TRIAL_MODE_YOU_HAVE' | translate }} {{ configService.anonymousAuthResult.trialMode?.daysToExpiration }} {{ 'REGISTRATION.FLOW.DAYS_LEFT' | translate }}</div>
    <div class="information-text" *ngIf="!isTrialOver" [attr.translation-id]="'REGISTRATION.FLOW.SOME_OF_THE_SENSETIME_FEATURES_ARE_NOT_ENABLE_FOR_TRIAL_MODE_USERS'">{{ 'REGISTRATION.FLOW.SOME_OF_THE_SENSETIME_FEATURES_ARE_NOT_ENABLE_FOR_TRIAL_MODE_USERS' | translate }}</div>
    <div class="information-text bold margin-top"
         *ngIf="isTrialOver" [ngClass]="{ dairyColor: isDairy, breedingColor: isBreeding, finishingColor: isFinishing }"
         [attr.translation-id]="'REGISTRATION.FLOW.YOUR_TRIAL_MODE_IS_OVER'">{{ 'REGISTRATION.FLOW.YOUR_TRIAL_MODE_IS_OVER' | translate }}</div>
    <div class="buttons-div">
      <div class="button" (click)="navigateToRegistration()"
           [ngClass]="{ registerNowDairyColor: isDairy, registerNowBreedingColor: isBreeding, registerNowFinishingColor: isFinishing }"
           [attr.translation-id]="'REGISTRATION.FLOW.REGISTER_NOW'">{{ 'REGISTRATION.FLOW.REGISTER_NOW' | translate }}</div>
      <div class="button"
           *ngIf="!isTrialOver"
           (click)="loginAsTrial()"
           [ngClass]="{ goToDashboardDairyColor: isDairy, goToDashboardBreedingColor: isBreeding, goToDashboardFinishingColor: isFinishing }"
           [attr.translation-id]="'REGISTRATION.FLOW.TAKE_ME_TO_DASHBOARD'">{{ 'REGISTRATION.FLOW.TAKE_ME_TO_DASHBOARD' | translate }}</div>
    </div>
  </div>
  <div class="language-picker-wrapper">
    <translation-picker [theme]="TranslationPickerThemeEnum.White"></translation-picker>
  </div>
</div>
