import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {AuthLocalValidatorService, PasswordValidationResult} from '../../../services/auth/auth-local-validator.service';
import {
  ForgotPasswordService,
  UserResetPasswordModel,
  UserSecurityQuestionsModel,
  UserTempCredentialsModel
} from '../../../services/auth/forgot-password.service';

enum ForgotPasswordStep {
  ValidateUser = 'ValidateUser',
  SecurityQuestions = 'SecurityQuestions',
  ResetPassword = 'ResetPassword'
}

class ValidateUsernameStepModel {
  public userName:string
}

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public validateUsernameStepDetails:ValidateUsernameStepModel = new ValidateUsernameStepModel();

  public userSecurityQuestions:UserSecurityQuestionsModel;

  public userTempCredentials: UserTempCredentialsModel;

  public userResetPasswordModel: UserResetPasswordModel;

  public forgotPasswordStep:ForgotPasswordStep = ForgotPasswordStep.ValidateUser;

  public passwordValidationResult:PasswordValidationResult = new PasswordValidationResult();

  public openPasswordValidationPopup: boolean;

  public isLoading:boolean;

  public errorMessage: string;

  constructor(private router:Router,
              private authService:AuthService,
              private authLocalValidator: AuthLocalValidatorService,
              private forgotPasswordService: ForgotPasswordService) { }

  public ngOnInit() {
  }

  public get securityQuestion1():string{
    return ForgotPasswordService.securityQuestionsTranslations.find(value => value.key === this.userSecurityQuestions.question1.key).value;
  }

  public get securityQuestion2():string{
    return ForgotPasswordService.securityQuestionsTranslations.find(value => value.key === this.userSecurityQuestions.question2.key).value;
  }

  public get userNameExist():boolean{
    return this.validateUsernameStepDetails.userName && this.validateUsernameStepDetails.userName.length > 0;
  }

  public get securityQuestionsExist() : boolean{
    return this.userSecurityQuestions &&
           this.userSecurityQuestions.question1.value && this.userSecurityQuestions.question1.value.length > 0  &&
           this.userSecurityQuestions.question2.value && this.userSecurityQuestions.question2.value.length > 0;
  }

  public get newPasswordConfirmed() : boolean {
    return this.userResetPasswordModel &&
           this.userResetPasswordModel.newPassword.length > 0 &&
           this.userResetPasswordModel.newPassword === this.userResetPasswordModel.confirmPassword;
  }

  public validateUserName() {
    this.resetErrorMessage();
    this.validateUsernameStepDetails.userName = this.authLocalValidator.validateUserNameCharacters(this.validateUsernameStepDetails.userName);
  }

  public navigateToLogin(){
    this.router.navigate(['/']);
  }

  public onNewPasswordValueChanged(){
    this.openPasswordValidationPopup = true;
    this.passwordValidationResult = this.authLocalValidator.validatePassword(this.userResetPasswordModel.newPassword);
  }

  public onNewPasswordBlur() {
    this.openPasswordValidationPopup = false;
  }

  public onValidateUserSubmit() {
    this.isLoading = true;
    this.forgotPasswordService.getUserSecurityQuestionsDetails(this.validateUsernameStepDetails.userName).then(response => {
      this.isLoading = false;
      if(response.responseBody &&
         response.status === 200){
        this.userSecurityQuestions =  response.responseBody;
        this.forgotPasswordStep = ForgotPasswordStep.SecurityQuestions;
      } else {
        if (response.status === 500) {
          this.errorMessage = "SERVER_ERROR"
        } else {
          this.errorMessage = response.errorResponseBody && response.errorResponseBody.result;
        }
      }
    });
  }

  public async onSecurityQuestionSubmit() {
    this.isLoading = true;
    let response = await this.forgotPasswordService.validateUserSecurityQuestionsDetails(this.userSecurityQuestions);
    if(response.responseBody &&
       response.status === 200) {
      this.userTempCredentials = response.responseBody;
      this.userResetPasswordModel = {
        username: response.responseBody.username,
        newPassword: '',
        confirmPassword: ''
      };
      this.forgotPasswordStep = ForgotPasswordStep.ResetPassword;
    } else {
      this.errorMessage = response.errorResponseBody && response.errorResponseBody.result;
    }
    this.isLoading = false;
  }

  public onResetPasswordSubmit() {
    this.isLoading = true;
    this.forgotPasswordService.resetPassword(this.userResetPasswordModel).then(response => {
      this.isLoading = false;
      if(response.status === 200){
        this.router.navigate(['/login']);
      } else {
        this.errorMessage = response.errorResponseBody && response.errorResponseBody.result;
      }
    });
  }

  public resetErrorMessage() {
    this.errorMessage = null;
  }
}
