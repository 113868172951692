import {IChartEventPoint, IChartPoint} from '../../model/charts/chart.interface';
import {IChartSeriesSetupRecord} from '../../charts/model/chart.interface';
import {ApplicationBundle, FinishingApplicationBundle} from '../../config/model/server-config';
import {BranchLocalizationModel} from '../../branches/branches.service';

export interface IGroup {
  id?: number;
  groupNumber?: number;
  groupName?: string;
  branchName?: string;
  heatIndexThreshold?: number;
  _originalIdx?: number;
  numberOfAnimals?: number;
  isRemovable?: boolean;
  GroupNumberCalculation?: number;
  GroupNameCalculation?: string;
  GroupBranchCalculation?: string;
  GroupBranchDynamicCalculation?: BranchLocalizationModel;
  GroupHeatIndexThresholdCalculation?: number;
  GroupDatabaseIDCalculation?: number;
}

export interface IGroupMetadata {
  id: number,
  number: number,
  name: string,
  branch: string,
  groupType: string,
  isDefault: boolean
}

export interface IGroupDetails {
  averageLactationNumber?: string,
  averageDaysPregnant?: string,
  percentagePregnant?: string,
  averageDIMAge?: string,
  averageDIMAgeCalculationName?: string,
  totalScrTag?: string,
  id?: number,
  groupNumber?: number,
  groupName?: string,
  // tslint:disable-next-line:no-any
  branchName?: any,
  branchId?: number,
  heatIndexThreshold?: number,
  numberOfAnimals?: number,
  isCulled?: boolean,
  isDefaultGroup?: boolean,
  isRemovable?: boolean
}

export interface IGroupAnimalDetails {
  animalName: string;
  assignedScrTagEnabled: boolean;
  daysPregnant: string;
  daysSinceLastHeat: string;
  dimAge: number;
  id: number;
  lactationNumber: number;
  lactationStatus: string;
  scrTagNumber: string;
  changeGroupEnabled: boolean;
  culledAnimalEnabled: boolean;
}

export interface IFinishingGroupAnimalDetails {
  id: number;
  animalName: string;
  ageOrDaysSinceEntry: number;
  scrTagNumber: number;
  lifeCycleStatus: string;
  assignedScrTagEnabled: boolean;
  changeGroupEnabled: boolean;
  culledAnimalEnabled: boolean;
}

export interface IGroupAnimals {
  animals: IGroupAnimalDetails[];
  averageDIMAge: string
  averageDIMAgeCalculationName: string
  averageDaysPregnant: string
  averageLactationNumber: string
  numberOfAnimals: number
  percentagePregnant: string
  totalScrTag: string
}

export interface IFinishingGroupAnimals {
  animals: IFinishingGroupAnimalDetails[];
  totalScrTag: number;
  numberOfAnimals: number;
  averageAgeOrEntry: number;
  averageAgeOrEntryCalculationName: string;
}

export interface IGroupChartData {
  consistencySeverity: string
  lastDaysAverage: number
  lastDaysConsistency: number
  eventsSeries: IChartEventPoint[]
}

export interface IGroupGraphDetails {
  numberOfAnimals: number
  numberOfAnimalsWithTags: number
}

export interface IGroupChartActivityDataPoint extends IChartPoint {
  averageActivity?: number
  currentActivity?: number
  stdActivityLower?: number
  stdActivityUpper?: number
}

export interface IGroupChartActivityData extends IGroupChartData {
  series: IGroupChartActivityDataPoint[]
}

export interface IGroupChartRuminationDataPoint extends IChartPoint {
  averageRumination: number
  currentRumination: number
  stdRuminationLower: number
  stdRuminationUpper: number
}

export interface IGroupChartRuminationData extends IGroupChartData {
  series: IGroupChartRuminationDataPoint[]
}

export interface IGroupChartConsistencyDataPoint extends IChartPoint {
  averageDailyRumination: number;
  variabilityOfDailyRumination: number;
  lastDaysAverageDailyRumination?: number|null;
}

export interface IGroupChartConsistencyData extends IGroupChartData {
  series: IGroupChartConsistencyDataPoint[]
}

export interface IGroupChartHeatStressDataPoint extends IChartPoint {
  averageDailyEating: number
  averageDailyPanting: number
  averageDailyRumination: number
}

export interface IGroupChartHeatStressData extends IGroupChartData {
  series: IGroupChartHeatStressDataPoint[]
}

export interface IGroupChartHeatSetupSeries {
  currentEating: IChartSeriesSetupRecord
  currentPanting: IChartSeriesSetupRecord
  currentRumination: IChartSeriesSetupRecord
}

export interface IGroupChartTrendHeatSetupSeries {
  averageDailyEating: IChartSeriesSetupRecord
  averageDailyPanting: IChartSeriesSetupRecord
  averageDailyRumination: IChartSeriesSetupRecord
}

export interface IFinishingGroupChartTrendHeatSetupSeries {
  averageDailyRumination: IChartSeriesSetupRecord
  variabilityOfDailyRumination: IChartSeriesSetupRecord
}

export class AddGroupModel {
  public branchName: string;
  public groupName: string;
  public groupNumber: number;
  public heatIndexThreshold: number;
  public id: number;
}
export class AddFinishingGroupModel {
  public branchName: string;
  public groupName: string;
  public groupNumber: number;
  public id: number;
}

export class GroupInfo {
  public isActivityAlert: boolean;
  public isRuminationAlert: boolean;
  public animalId: string;
  public animalName: string;
  public groupName: string;
  public groupNumber: number;
  public groupId: number;
  public status: string;
  public isCulled: boolean;
  public plan: ApplicationBundle|FinishingApplicationBundle;
}
