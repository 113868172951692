import {Component, OnDestroy, OnInit} from '@angular/core';
import {AnimalEventsService} from '../../../../services/animals/animal-events.service';
import {DialogActionsService} from '../../../../services/dialogs/dialog-actions.service';
import {Subscription} from 'rxjs';
import {CreateBatchEventDialogModel} from '../../../../services/dialogs/model/dialog-actions-model';
import * as moment from 'moment';
import {
  AbortionType,
  CreateAbortionEventDetails,
  EventTypeEnum
} from 'src/app/services/animals/model/animal-events';
import {SuccessDialogService} from "../success-dialog/success-dialog.service";
import {SearchEntry} from '../../../../services/search/model/search.model';
import {SearchService} from '../../../../services/search/search.service';

@Component({
  selector: 'create-batch-event-dialog',
  templateUrl: './create-batch-event-dialog.component.html',
  styleUrls: ['./create-batch-event-dialog.component.scss']
})
export class CreateBatchEventDialogComponent implements OnInit, OnDestroy {

  private dialogActionsSubscription: Subscription;

  public isOpen: boolean;

  public model: CreateBatchEventDialogModel;

  public EventTypeEnum = EventTypeEnum;

  private isApply: boolean;

  public animalIds: string[] = [];

  constructor(private readonly animalEventsService:AnimalEventsService,
              private readonly dialogActionsService:DialogActionsService,
              private searchService: SearchService,
              private successDialogService: SuccessDialogService) { }

  public ngOnInit() {
    this.dialogActionsSubscription = this.dialogActionsService.createBatchEventDialogSubject.subscribe(model => {
      this.model = model;
      this.isOpen = true;
      this.fillUpAnimalIdList();
    });
  }

  public ngOnDestroy(): void {
    this.dialogActionsSubscription.unsubscribe();
  }

  private async fillUpAnimalIdList(): Promise<void> {
    if (this.model) {
      const availableAnimalsSearchEntities: SearchEntry[] = await this.searchService.listOfAvailableAnimals();
      this.animalIds = availableAnimalsSearchEntities
        .filter((searchAnimal: SearchEntry) => this.model.animalIds.some(selectedAnimalId => searchAnimal.entityId == selectedAnimalId))
        .map((animal: SearchEntry) => animal.entityName);
    }
  }

  public isEventModelValid(): boolean {
    return this.animalEventsService.validateBatchEventModel(this.model.eventDetails);
  }

  public get isApplyClickDisabled(): boolean {
    if (this.model.meta.type === EventTypeEnum.Abortion) {
      const abortionEventDetails = this.model.eventDetails as CreateAbortionEventDetails;
      if (abortionEventDetails.type === AbortionType.Unselected || !abortionEventDetails.destinationGroup) {
        return true;
      }
    }
    return false;
  }

  private async applyClick() {
    if(this.isApply) {
      return;
    }
    this.isApply = true;
    let response = await this.animalEventsService.createBatchEvent(this.model.eventDetails,
                                                           {startDateTime: moment().unix(),
                                                                    animalIds: this.model.animalIds}).toPromise();
    if(!response.succeeded ||
      (response.failures != null &&
       response.failures.length > 0 &&
       response.failures[0].errors != null &&
       response.failures[0].errors.length > 0)) {
      this.model.errors = response.failures[0].errors;
    } else {
      this.isOpen = false;
      setTimeout(async () => {
        await this.model.actionsContext.reloadDefault();
        await this.successDialogService.show();
      });
    }
    this.isApply = false;
  }

  private async cancelClick() {
    if(this.model) {
      this.isOpen = false;
    }
  }
}
