<div class="add-animal-container-wrapper">
  <corner-container *ngIf="true"
                    [attr.sh-id]="'add_male_dialog'"
                    [cornerColor]="CornerColor.DeepBlue"
                    (onCornerButtonClick)="finishingStep === AddMaleFinishingSteps.Entry ? goToStep(AddMaleFinishingSteps.Farm) : addFinishingMale()"
                    [footerHeight]="50"
                    [bodyBackground]="CornerContainerBodyBackground.LightGray">
    <ng-container container-body>
      <div class="add-animal-finishing-container">
        <ng-container [ngSwitch]="finishingStep">

          <!-- Entry/Birthday Tab -->
          <add-male-finishing-entry-tab
            *ngSwitchCase="AddMaleFinishingSteps.Entry"
            [finishingAddMaleModel]="finishingAddMaleModel"
            [addMaleFinishingMetadata]="addMaleFinishingMetadata"
            [groupsDropdownListItems]="groupsDropdownListItems"
            [scrTagsNumbers]="scrTagsNumbers"
            [rfidsTagsNumbers]="rfidsTagsNumbers"
            [errors]="errors"
            (onClearErrors)="clearErrors()">
          </add-male-finishing-entry-tab>

          <!-- Farm Tab -->
          <add-male-finishing-farm-tab
            *ngSwitchCase="AddMaleFinishingSteps.Farm"
            [finishingAddMaleModel]="finishingAddMaleModel"
            [groupsDropdownListItems]="groupsDropdownListItems"
            [isKgValueDisplayed]="isKgValueDisplayed()"
            [errors]="errors"
            (onClearErrors)="clearErrors()">
          </add-male-finishing-farm-tab>

          <!-- Finishing Tab -->
          <add-male-finishing-finishing-tab
            *ngSwitchCase="AddMaleFinishingSteps.Finishing"
            [finishingAddMaleModel]="finishingAddMaleModel"
            [groupsDropdownListItems]="groupsDropdownListItems"
            [errors]="errors"
            (onClearErrors)="clearErrors()">
          </add-male-finishing-finishing-tab>

        </ng-container>
      </div>
    </ng-container>
    <ng-container container-footer>
      <!-- Error container to display all errors for form -->
      <div class="errors-container">
        <div class="error-text"  *ngIf="errors && errors[0]" [attr.sh-id]="'error-text-' + errors[0].key">
          <b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}: </b>{{ 'MANAGE.COWS.ACTIVE.WIZARD.ERRORS.' + errors[0].key | translate }}
        </div>
      </div>
      <!-- Buttons Container to contain all the actions of the container -->
      <div class="navigation-buttons">
        <div class="container-buttons">
          <div class="check-button" [attr.sh-id]="'apply_add_female_finishing'" *ngIf="finishingStep !== AddMaleFinishingSteps.Entry" (click)="addFinishingMale()">
            <div class="check-icon"></div>
          </div>
          <div class="check-button" [attr.sh-id]="'button_next'" *ngIf="finishingStep === AddMaleFinishingSteps.Entry" (click)="goToStep(AddMaleFinishingSteps.Farm)">
            <div class="next-icon"></div>
          </div>
        </div>
        <div class="seperator" *ngIf="finishingStep !== AddMaleFinishingSteps.Entry"></div>
        <div class="navigation-button" [attr.sh-id]="'button_next'" *ngIf="finishingStep !== AddMaleFinishingSteps.Entry && finishingStep !== AddMaleFinishingSteps.Finishing" (click)="goToStep(finishingStep + 1)">
          <div class="next-icon"></div>
        </div>
        <div class="navigation-button" [attr.sh-id]="'button_previous'" *ngIf="finishingStep !== AddMaleFinishingSteps.Entry" (click)="performNavigationToStep(finishingStep - 1)">
          <div class="previous-icon"></div>
        </div>
      </div>

    </ng-container>
  </corner-container>
  <div class="steps-wizard">
    <div class="summary-div">
      <div class="step-div-text">
        <div [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER'" *ngIf="isCurrentSummaryDisplayed(AddMaleFinishingSteps.Entry)"><b>{{ 'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER' | translate }}:</b> {{ finishingAddMaleModel?.animalNumber }}</div>
        <div [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'" *ngIf="isCurrentSummaryDisplayed(AddMaleFinishingSteps.Entry)"><b>{{ 'MANAGE.COWS.ACTIVE.WIZARD.GROUP' | translate }}:</b> {{ finishingAddMaleModel?.group?.displayValue }}</div>
      </div>
      <div class="step-div-text">
        <div [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE'" *ngIf="isCurrentSummaryDisplayed(AddMaleFinishingSteps.Farm) && finishingAddMaleModel.birthDate.epochDate"><b>{{ 'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE' | translate }}:</b> {{ finishingAddMaleModel?.birthDate.epochDate | epochDateTime:dateTimeFormatEnum.Date  }}</div>
        <div [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE'" *ngIf="isCurrentSummaryDisplayed(AddMaleFinishingSteps.Farm) && !finishingAddMaleModel.birthDate.epochDate"><b>{{ 'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE' | translate }}:</b> -</div>
        <div [attr.translation-id]="'MANAGE.FINISHING.ACTIVE.WIZARD.WEIGHT'" *ngIf="isCurrentSummaryDisplayed(AddMaleFinishingSteps.Farm)"><b>{{ 'MANAGE.FINISHING.ACTIVE.WIZARD.WEIGHT' | translate }}:</b> {{ getWeightValueAccordingToLocalization() }} {{ isWeightValueExists() ? utilsService.getUnitType() : '' }}</div>
      </div>
      <div class="step-div-text"></div>
    </div>
    <div class="steps-graph-div">
      <div class="step-icon"
           (click)="checkIfCanNavigateToStep(AddMaleFinishingSteps.Entry)"
           [ngClass]="{ CurrentFinishingStep: finishingStep === AddMaleFinishingSteps.Entry, ActiveFinishingStep: AddMaleFinishingSteps.Entry < finishingStep }">
        <div class="finishing-male-icon" [ngClass]="{ CurrentFinishingStep: finishingStep === AddMaleFinishingSteps.Entry, ActiveFinishingStep: AddMaleFinishingSteps.Entry < finishingStep }"></div>
      </div>
      <div class="seperator" [ngClass]="{ active: finishingStep >= AddMaleFinishingSteps.Farm }"></div>
      <div class="step-icon"
           (click)="checkIfCanNavigateToStep(AddMaleFinishingSteps.Farm)"
           [ngClass]="{ CurrentFinishingStep: finishingStep === AddMaleFinishingSteps.Farm, ActiveFinishingStep: AddMaleFinishingSteps.Farm < finishingStep }">
        <div class="finishing-farm-icon" [ngClass]="{ CurrentFinishingStep: finishingStep === AddMaleFinishingSteps.Farm, ActiveFinishingStep: AddMaleFinishingSteps.Farm < finishingStep }"></div>
      </div>
      <div class="seperator" [ngClass]="{ active: finishingStep >= AddMaleFinishingSteps.Finishing }"></div>
      <div class="step-icon"
           (click)="checkIfCanNavigateToStep(AddMaleFinishingSteps.Finishing)"
           [ngClass]="{ CurrentFinishingStep: finishingStep === AddMaleFinishingSteps.Finishing }">
        <div class="finishing-icon" [ngClass]="{ CurrentFinishingStep: finishingStep === AddMaleFinishingSteps.Finishing }"></div>
      </div>
    </div>
  </div>
</div>
