import {Injectable} from '@angular/core';
import {PollingService} from '../rest-api/polling.service';
import {ServerSetup, VersionStatus} from './model/server-setup';
import {ConfigService} from '../config/config.service';
import {HttpResponseResult, RestApiService} from '../rest-api/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class SetupService {

  private serrverSetup: ServerSetup;

  private readonly setupRoute: string = '/rest/api/server/setup';

  constructor(private pollingService: PollingService,
              private restApiService: RestApiService,
              public configService: ConfigService) {

  }

  public async loadServerSetup() {
    if(this.configService.configuration.cloud) {
      this.pollingService.initPolling();
    } else {
      let response = await this.restApiService.sendGetMethodAny<ServerSetup>(this.setupRoute, ConfigService.anonymousAuthUnknownFarmDetails);
      if (response == null ||
        response.responseBody == null) {
        this.serrverSetup = null;
      } else {
        this.serrverSetup = response.responseBody;
        if (this.isServerSetupValid) {
          this.pollingService.initPolling();
        }
      }
    }
  }

  public startInstallUpgradeForMinimalVersion(): Promise<HttpResponseResult<ServerSetup>> {
    return this.restApiService.sendPostMethod<ServerSetup>(this.setupRoute, ConfigService.anonymousAuthUnknownFarmDetails, {});
  }

  public get isServerSetupValid(): boolean {
    return this.configService.configuration.cloud ||
          (this.serrverSetup != null &&
          !this.serrverSetup.acknowledgeInitialSetup &&
          (this.serrverSetup.status == null ||
           this.serrverSetup.status === VersionStatus.NoVersionFound) &&
          !this.serrverSetup.setupInProgress);
  }

  public get serverSetup(): ServerSetup {
    return this.serrverSetup;
  }

  public setServerSetup(serverSetup: ServerSetup) {
    this.serrverSetup = serverSetup;
  }
}
