import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'dialog-scrollable-body',
  templateUrl: './dialog-scrollable-body.component.html',
  styleUrls: ['./dialog-scrollable-body.component.scss']
})
export class DialogScrollableBodyComponent implements OnInit {

  @Input()
  public bodyHeight: number | string = 234;

  constructor() { }

  public ngOnInit() {
  }

  public get height(): string {
    if(this.bodyHeight === 'auto') {
      return this.bodyHeight;
    }
    let h: string;
    if (typeof this.bodyHeight === 'string') {
      h = this.bodyHeight;
    }
    if (typeof this.bodyHeight === 'number') {
      h = this.bodyHeight + 'px';
    }
    return h;
  }
}
