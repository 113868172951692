import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'bindable-toggle',
  templateUrl: './bindable-toggle.component.html',
  styleUrls: ['./bindable-toggle.component.scss']
})
export class BindableToggleComponent implements OnInit {

  @Input()
  public isDisabled?: boolean;

  @Input()
  public isReadonly?: boolean;

  @Input()
  public isChecked?: boolean;

  @Input()
  public theme?: BindableToggleTheme = BindableToggleTheme.regular;

  @Output()
  public onToggle = new EventEmitter();

  public bindableToggleTheme = BindableToggleTheme;

  constructor() { }

  public ngOnInit() {
  }

  public onClick(): void {
    if (!this.isDisabled && !this.isReadonly) {
      this.onToggle.emit();
    }
  }

}
export enum BindableToggleTheme {
  regular = 'regular',
  registrationGreen = 'registration-green'
}
