import { Component } from '@angular/core';
import {ReportDataRow} from '../../../../../../services/reports/model/report.interface';
import {DescriptionCellComponent} from '../../../../data-grid/description-cell-template/description-cell.component';

export class AnimalsToInspectReportDataRow extends ReportDataRow {
  public AnimalsToInspectReasonsCalculation: AnimalsToInspectReasonsCalculation
}

export class AnimalsToInspectReasonsCalculation {
  public mostImportantReport: string;
  public restOfReports: string[];
}

@Component({
  selector: 'animals-to-inspect-reasons-cell',
  templateUrl: './animals-to-inspect-reasons-cell.component.html',
  styleUrls: ['./animals-to-inspect-reasons-cell.component.scss']
})
export class AnimalsToInspectReasonsCellComponent extends DescriptionCellComponent<AnimalsToInspectReportDataRow>{

  public get hasRowDetails() : boolean {
    return this.row.AnimalsToInspectReasonsCalculation != null
      && this.row.AnimalsToInspectReasonsCalculation.restOfReports != null
      && this.row.AnimalsToInspectReasonsCalculation.restOfReports.length > 0;
  }
}
