<regular-edit-dialog-template [modalWidth]="555"
                              [isOpen]="isOpen"
                              [enableRemoveButton]="false"
                              [rightButtonDisabled]="isApplyDisabled"
                              (onCloseClick)="closeClick()"
                              (onLeftButtonClick)="closeClick()"
                              (onEnterButtonClick)="applyClick()"
                              (onRightButtonClick)="applyClick()">
  <ng-container header-caption>
    {{'MANAGE.COWS.ACTIVE.ASSIGN_TAG.SCR_TAG_TITLE' | translate}}
  </ng-container>
  <ng-container body>
    <dialog-scrollable-body>
      <ng-container content>
        <animal-ids [animalIds]="[animalId]"></animal-ids>
        <div class="form-group" *ngIf="model">
          <span class="form-group-label">{{'MANAGE.COWS.ACTIVE.ASSIGN_TAG.SCR_TAG_TITLE_DESCRIPTION' | translate}}</span>
          <div class="content">
            <span class="form-group-label">{{'MANAGE.COWS.ACTIVE.ASSIGN_TAG.TAG_NUMBER' | translate}}</span>
            <regular-auto-complete #tagSearchAutocomplete
                                   [isAllowUnmatchedSelection]="true"
                                   [items]="unassignedTagsNumbers"
                                   (searchQueryChanged)="onMatchingItem($event)"
                                   [isFieldError]="error"
                                   [isEnterHasDifferentFunctionality]="true"
                                   (onEnterClicked)="onTagSelected()"
                                   [autoFocus]="true"></regular-auto-complete>
            <div class="error" *ngIf="error">
              <b>{{ "SYSTEM.MANAGEMENT.ERROR_BOLD" | translate }}:</b> {{ "MANAGE.COWS.ACTIVE.ASSIGN_TAG." + error | translate }}
            </div>
          </div>
        </div>
      </ng-container>
    </dialog-scrollable-body>
  </ng-container>
  <ng-container left-button>
    <span>{{ 'COMMON.BUTTON.CANCEL' | translate}}</span>
  </ng-container>
  <ng-container right-button>
    <span>{{ 'COMMON.BUTTON.APPLY' | translate}}</span>
  </ng-container>
</regular-edit-dialog-template>
