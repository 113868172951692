import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DIALOG_TRANSITIONS} from '../../../../animations/animations';
import {OperationType, SearchEntry} from '../../../../../services/search/model/search.model';
import {AutoCompleteColorScheme} from '../../../auto-complete/auto-complete-model';
import {RegularAutoCompleteComponent} from '../../../auto-complete/regular-auto-complete/regular-auto-complete.component';
import {SearchService} from '../../../../../services/search/search.service';
import {ReportsService} from '../../../../../services/reports/reports.service';
import {AddAnimalToReportDialogModel, ReportActionsService} from '../../../../../services/reports/report-actions.service';
import {Subscription} from 'rxjs';
import {ReportMetaPackage} from '../../../../../services/reports/model/report.interface';
import {LoadingIconService} from "../../../../../services/loading-icon/loading-icon.service";

@Component({
  selector: 'add-animal-to-report-dialog',
  templateUrl: './add-animal-to-report-dialog.component.html',
  styleUrls: ['./add-animal-to-report-dialog.component.scss'],
  animations: [DIALOG_TRANSITIONS]
})
export class AddAnimalToReportDialogComponent implements OnInit, OnDestroy, OnChanges {

  public items: string[] = [];

  public searchItem: SearchEntry;

  public autoCompleteColorScheme = AutoCompleteColorScheme;

  @ViewChild('autoCompleteAddAnimal')
  public autoCompleteAddAnimal: RegularAutoCompleteComponent;

  private reportActionsSubscription: Subscription;

  public isPopupOpen: boolean;

  public model: AddAnimalToReportDialogModel;

  constructor(private readonly reportActionsService: ReportActionsService,
              private readonly searchService: SearchService,
              private readonly loadingIconService: LoadingIconService,
              private readonly reportsService: ReportsService) { }

  public ngOnInit() {
    this.reportActionsSubscription = this.reportActionsService.addAnimalToReportDialogSubject.subscribe(value => {
      this.model = value;
      this.isPopupOpen = true;
      this.getSearchEntities();
    });
  }

  public ngOnDestroy(): void {
    if (this.reportActionsSubscription) {
      this.reportActionsSubscription.unsubscribe();
    }
  }

  public outsidePopupModalClicked() {
    this.isPopupOpen = false;
  }

  public async getSearchEntities() {
    const animalIdFieldName = this.reportsService.getAnimalIdFieldNameForReport(this.model.report.meta.reportName);
    const animalIds = this.model.report.rows.map(x => x[animalIdFieldName]);
    let availableSearchEntities = this.searchService.availableSearchEntities.filter(value => !animalIds.includes(value.entityName) && (value.entityType == 'cow' || value.entityType == 'male'));
    if (this.model.report.meta.package) {
      if (this.model.report.meta.package != ReportMetaPackage.Finishing) {
        availableSearchEntities = availableSearchEntities.filter(value => [OperationType.BeefBreeding, OperationType.Dairy].includes(value.operationType));
      } else {
        availableSearchEntities = availableSearchEntities.filter(value => value.operationType == OperationType.BeefFinishing);
      }
    }
    this.items = availableSearchEntities.map(value => value.entityName);
  }

  public async selectAnimal(query: string): Promise<void> {
    if(query == null ){
      this.searchItem = null;
      return;
    }
    this.searchItem = this.searchService.availableSearchEntities.find(value => value.entityName === query);
  }

  public onMatchingItemChanged(matchingItem: string) {
    if(matchingItem == null) {
      this.searchItem = null;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isPopupOpen) {
      if (changes.isPopupOpen.currentValue === true) {
        setTimeout(() => {
          if (this.autoCompleteAddAnimal) {
            this.autoCompleteAddAnimal.focusOnAutocomplete();
          }
        });

      }
    }
  }

  public async onEnterKeyClickedForSelection(query: string) {
    await this.selectAnimal(query);
    await this.addAnimal();
    this.autoCompleteAddAnimal.clearValue();
  }

  public async onAddAnimalButtonClick(event:Event) {
    await this.addAnimal();
    this.autoCompleteAddAnimal.onRemoveSelection(event);
    this.autoCompleteAddAnimal.focusOnAutocomplete();

  }

  private async addAnimal(): Promise<void> {
    this.loadingIconService.show();
    let searchEntry = this.searchItem.entityName;
    await this.reportActionsService.addAnimalToReport(this.model, searchEntry);
    this.items = this.items.filter(value => value !== searchEntry);
    this.loadingIconService.hide();
  }

}
