import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ImportFromCsvService, ImportFromCsvState} from '../../../services/import/import-from-csv.service';
import {IMPORT_FROM_CSV_TRANSITIONS} from '../../../common/animations/animations';
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {ApplicationBundleRoutingMode, RoutingService} from "../../../services/routing/routing.service";
import {HttpResponseResult} from "../../../services/rest-api/rest-api.service";
import {
  NotificationDialogTemplateComponent,
  NotificationDialogTheme
} from 'src/app/common/components/dialogs/notification-dialog-template/notification-dialog-template.component';
import {NotificationDialogComponent} from '../../notification-dialogs/notification-dialog.component';

@Component({
  selector: 'import-from-csv',
  templateUrl: './import-from-csv.component.html',
  styleUrls: ['./import-from-csv.component.scss'],
  animations: [IMPORT_FROM_CSV_TRANSITIONS]
})
export class ImportFromCsvComponent implements OnInit, OnDestroy {

  public isOpen : boolean;

  public uploadError : string;

  private routingMode: ApplicationBundleRoutingMode;

  public queryParamsSubscription: Subscription;

  @ViewChild('importFromCsvFileInput')
  public importFromCsvFileInput: ElementRef;

  @ViewChild('uploadErrorDialogRef', {static: true})
  public uploadErrorDialogRef: NotificationDialogTemplateComponent;

  public NotificationDialogTheme = NotificationDialogTheme;

  public get noRejectedRecords(): boolean {
    return this.importFromCsvService.ImportAnimalsFromCsvStatusModel &&
      this.importFromCsvService.ImportAnimalsFromCsvStatusModel.totalRecords &&
      this.importFromCsvService.ImportAnimalsFromCsvStatusModel.successfullyImported &&
      this.importFromCsvService.ImportAnimalsFromCsvStatusModel.totalRecords === this.importFromCsvService.ImportAnimalsFromCsvStatusModel.successfullyImported;
  }

  public ImportFromCsvState = ImportFromCsvState;

  private onImportFromFileSubscription: Subscription;

  constructor(private importFromCsvService: ImportFromCsvService,
              private routingService: RoutingService,
              private activatedRoute: ActivatedRoute) {
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(value => {
      this.routingMode = this.routingService.getApplicationBundleRoutingMode(value);
    });
  }

  public async ngOnInit() {
    this.onImportFromFileSubscription = this.importFromCsvService.onImportFromFile.subscribe(() => {
      this.importFromCsvFileInput.nativeElement.click();
    });
    await this.importFromCsvService.getImportAnimalsFromCsvStatus();
    if(this.importFromCsvService.isImportRunning) {
      this.importFromCsvService.startGettingImportAnimalsStatus();
    }
  }

  public ngOnDestroy(): void {
    this.onImportFromFileSubscription.unsubscribe();
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

  public toggleImportFromCsvDialog() {
    this.isOpen = !this.isOpen;
  }

  public get isImportAnimalsFromFileModuleShown() : boolean {
    return this.importFromCsvService.ImportAnimalsFromCsvStatusModel &&
           this.importFromCsvService.ImportAnimalsFromCsvStatusModel.state &&
           this.importFromCsvService.ImportAnimalsFromCsvStatusModel.state !== ImportFromCsvState.None;
  }

  public calculateProgressWidth(): string {
    let number: number = 21 + (((this.importFromCsvService.ImportAnimalsFromCsvStatusModel.successfullyImported + this.importFromCsvService.ImportAnimalsFromCsvStatusModel.duplicatedRecords + this.importFromCsvService.ImportAnimalsFromCsvStatusModel.recordsWithErrors) / this.importFromCsvService.ImportAnimalsFromCsvStatusModel.totalRecords) * 170);
    return `${number}px`;
  }

  public async downloadImportErrors() {
    this.isOpen = false;
    await this.importFromCsvService.exportRejectedAnimalsFromCsv();
  }

  public async doneImportFromCSV() {
    await this.importFromCsvService.doneImportAnimalsFromCsv();
    this.isOpen = false;
  }

  // tslint:disable-next-line:no-any
  public async onFileChange(fileInput: any){
    if(this.validateFile(fileInput.target.files[0])) {
      let base64EncodedFile = await this.getBase64(fileInput.target.files[0]);
      base64EncodedFile = base64EncodedFile.replace('data:application/vnd.ms-excel;base64,','');
      // check application bundle - for finishing / breeding / dairy
      let response;
      if (this.routingMode === ApplicationBundleRoutingMode.Finishing) {
        response = await this.importFromCsvService.importFinishingAnimalFromFile(base64EncodedFile, fileInput.target.files[0].name);
      } else {
        response =  await this.importFromCsvService.importAnimalFromFile(base64EncodedFile, fileInput.target.files[0].name);
      }
      if(response.status == 200 ||
         response.status == 202){
        this.importFromCsvService.startGettingImportAnimalsStatus();
      }
      else {
        this.uploadError = response.errorResponseBody.errors[0];
        this.uploadErrorDialogRef.isOpen = true;
      }
    } else {
      // show dialog that the file is too big or not an CSV type file
      this.uploadError = "File size Exceeds the limits of 500Kb or not a CSV file";
      this.uploadErrorDialogRef.isOpen = true;
    }
    this.importFromCsvFileInput.nativeElement.value = '';
  }

  private getBase64(file: File) : Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }

  private validateFile(file: File): boolean {
    const filename: string[] = file.name.split('.');
    if (filename[filename.length - 1] !== 'csv') {
      return false;
    } else  {
      return file.size <= 500000;
    }
  }
}
