import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {RegistrationService} from '../../registration/registration.service';
import {AuthService, LoginStatus} from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationSignUpGuard implements CanActivateChild, CanActivate {

  constructor(public registrationService: RegistrationService, private router: Router, private authService: AuthService) { }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.registrationService.isClickedOnSignUp) {
      return true;
    } else {
      this.router.navigate(['/initialize-sign-up']);
      return false;
    }
  }

 public  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let loginResult: LoginStatus = await this.authService.getLoginStatus();
    switch (loginResult) {
      case LoginStatus.NotSignedUp: {
        return true;
      }
      case LoginStatus.NotRegistered: {
        await this.router.navigate(['/registration/initialize-registration']);
        return false;
      }
      case LoginStatus.NotLoggedIn: {
        await this.router.navigate(['/login']);
        return false;
      }
      case LoginStatus.EulaRequired: {
        await this.router.navigate(['/sign-eula']);
        return false;
      }
      case LoginStatus.ServerError: {
        await this.router.navigate(['/setup-progress']);
        return false;
      }
      case LoginStatus.LoggedIn: {
        await this.router.navigate(['/']);
        return false;
      }
    }
  }
}
