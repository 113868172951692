import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FinishingAddFemaleModel} from "../add-female-finishing.component";
import {
  IErrors,
  AddAnimalFieldsErrorsEnum,
  TagTypeEnum, IFemalesMetadata
} from "../../../../../../../services/animals/model/animal.model";
import {AnimalsService} from "../../../../../../../services/animals/animals.service";
import {
  DropdownListAutoSelectionMode,
  DropdownListItem
} from "../../../../../../../common/components/dropdown-list/dropdown-list-model";
import {AutoCompleteColorScheme} from "../../../../../../../common/components/auto-complete/auto-complete-model";

@Component({
  selector: 'add-female-finishing-entry-tab',
  templateUrl: './add-female-finishing-entry-tab.component.html',
  styleUrls: ['./add-female-finishing-entry-tab.component.scss']
})
export class AddFemaleFinishingEntryTabComponent implements OnInit {

  public AddAnimalFieldsErrorsEnum = AddAnimalFieldsErrorsEnum;

  public dropdownListAutoSelectionMode = DropdownListAutoSelectionMode;

  public autoCompleteColorScheme = AutoCompleteColorScheme;

  @Input()
  public addFinishingFemaleModel: FinishingAddFemaleModel;

  @Input()
  public errors: IErrors[];

  @Input()
  public groupsDropdownListItems: DropdownListItem[];

  @Input()
  public scrTagsNumbers: string[];

  @Input()
  public rfidsTagsNumbers: string[];

  @Input()
  public addFemaleFinishingMetadata: IFemalesMetadata;

  @ViewChild('registrationNumberInput')
  public registrationNumberInput: ElementRef;

  @Output()
  public onClearErrors: EventEmitter<void> = new EventEmitter<void>();

  constructor(public animalsService: AnimalsService) { }

  public ngOnInit() {
  }

  public clearErrors() {
    this.onClearErrors.emit();
  }

  public onGroupSelected(selectedGroup: DropdownListItem) {
    if (!this.addFinishingFemaleModel.group) {
      this.clearErrors();
    }
    this.addFinishingFemaleModel.group = selectedGroup;
  }

  public onScrTagChanged(tagNumber: string) {
    if(tagNumber == null ||
      tagNumber === '') {
      this.addFinishingFemaleModel.tagNumber = null;
    } else {
      if (this.addFemaleFinishingMetadata.scrTagList) {
        this.addFinishingFemaleModel.tagNumber = this.addFemaleFinishingMetadata.scrTagList.find(value => value.tagNumber === tagNumber);
      }
      if(this.addFinishingFemaleModel.tagNumber == null) {
        this.addFinishingFemaleModel.tagNumber = {
          tagNumber: tagNumber,
          id: null,
          tagType: TagTypeEnum.Scr
        };
      }
    }
    this.clearErrors();
  }

  public onRfIdTagChanged(tagNumber: string) {
    if(tagNumber == null ||
      tagNumber === '') {
      this.addFinishingFemaleModel.EID = null;
    } else {
      if (this.addFemaleFinishingMetadata.rfidTagList) {
        this.addFinishingFemaleModel.EID = this.addFemaleFinishingMetadata.rfidTagList.find(value => value.tagNumber === tagNumber);
      }
      if(this.addFinishingFemaleModel.EID == null) {
        this.addFinishingFemaleModel.EID = {
          tagNumber: tagNumber,
          id: null,
          tagType: TagTypeEnum.RfId
        };
      }
    }
    this.clearErrors();
  }

  public onClearRegistrationNumber(): void {
    this.addFinishingFemaleModel.registrationNumber = null;
    this.registrationNumberInput.nativeElement.focus();
    this.clearErrors();
  }
}
