export class FarmStatisticsBeefWidget {
  public females: IFarmStatisticsBeefFemales;
  public calves: IFarmStatisticsBeefCalves;
}

export interface IFarmStatisticsBeefFemales {
  totalFemales: ITotalPregnantPossible;
  femalesVwp: ITotalPregnant;
  femalesCalvingCount0: ITotalPregnant;
  femalesCalvingCount1: ITotalPregnant;
  femalesCalvingCount2: ITotalPregnant;
  femalesCalvingCount3: ITotalPregnant;
}

export interface IFarmStatisticsBeefCalves {
  totalCalves: IMaleFemale;
  zeroToTwoMonths: IMaleFemale;
  threeToFourMonths: IMaleFemale;
  fivePlusMonths: IMaleFemale;
}

export interface IMaleFemale {
  female: number;
  male: number;
}

export interface ITotalPregnantPossible {
  total: number;
  pregnant: number;
  pregnantPossible: number;
}

export interface ITotalPregnant {
  total: number;
  pregnant: number;
}
