import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { NgbButtonLabelDirective } from './label';

const NGB_RADIO_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NgbRadioGroupDirective),
  multi: true
};

let nextId = 0;

/**
 * Allows to easily create Bootstrap-style radio buttons.
 *
 * Integrates with forms, so the value of a checked button is bound to the underlying form control
 * either in a reactive or template-driven way.
 */
@Directive({
  selector: '[ngbRadioGroup]',
  host: { role: 'radiogroup' },
  providers: [NGB_RADIO_VALUE_ACCESSOR]
})
export class NgbRadioGroupDirective implements ControlValueAccessor {

  private radios: Set<NgbRadioDirective> = new Set<NgbRadioDirective>();

  private value = null;

  private isDisabled: boolean;

  public get disabled() {
    return this.isDisabled;
  }
  public set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  /**
   * Name of the radio group applied to radio input elements.
   *
   * Will be applied to all radio input elements inside the group,
   * unless [`NgbRadio`](#/components/buttons/api#NgbRadio)'s specify names themselves.
   *
   * If not provided, will be generated in the `ngb-radio-xx` format.
   */
  @Input() public name = `ngb-radio-${nextId++}`;

  // tslint:disable-next-line:no-any
  public onChange = (changes: any) => {};
  public onTouched = () => {};

  public onRadioChange(radio: NgbRadioDirective) {
    this.writeValue(radio.value);
    this.onChange(radio.value);
  }

  public onRadioValueUpdate() {
    this.updateRadiosValue();
  }

  public register(radio: NgbRadioDirective) {
    this.radios.add(radio);
  }

  // tslint:disable-next-line:no-any
  public registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  // tslint:disable-next-line:no-any
  public registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.updateRadiosDisabled();
  }

  public unregister(radio: NgbRadioDirective) {
    this.radios.delete(radio);
  }

  public writeValue(value) {
    this.value = value;
    this.updateRadiosValue();
  }

  private updateRadiosValue() {
    this.radios.forEach((radio) => radio.updateValue(this.value));
  }

  private updateRadiosDisabled() {
    this.radios.forEach((radio) => radio.updateDisabled());
  }
}

/**
 * A directive that marks an input of type "radio" as a part of the
 * [`NgbRadioGroup`](#/components/buttons/api#NgbRadioGroup).
 */
@Directive({
  selector: '[ngbButton][type=radio]',
  host: {
    '[checked]': 'checked',
    '[disabled]': 'disabled',
    '[name]': 'nameAttr',
    '(change)': 'onChange()',
    '(focus)': 'focused = true',
    '(blur)': 'focused = false'
  }
})
export class NgbRadioDirective implements OnDestroy {
  private isChecked: boolean;
  private isDisabled: boolean;
  // tslint:disable-next-line:no-any
  private val: any = null;

  /**
   * The value for the 'name' property of the input element.
   *
   * All inputs of the radio group should have the same name. If not specified,
   * the name of the enclosing group is used.
   */
  @Input() public name: string;

  /**
   * The form control value when current radio button is checked.
   */
  @Input('value')
  // tslint:disable-next-line:no-any
  public set value(value: any) {
    this.val = value;
    const stringValue = value ? value.toString() : '';
    this.renderer.setProperty(
      this.element.nativeElement,
      'value',
      stringValue
    );
    this.group.onRadioValueUpdate();
  }

  /**
   * If `true`, current radio button will be disabled.
   */
  @Input('disabled')
  public set disabled(isDisabled: boolean) {
    this.isDisabled = isDisabled !== false;
    this.updateDisabled();
  }

  public set focused(isFocused: boolean) {
    if (this.label) {
      this.label.focused = isFocused;
    }
    if (!isFocused) {
      this.group.onTouched();
    }
  }

  public get checked() {
    return this.isChecked;
  }

  public get disabled() {
    return this.group.disabled || this.isDisabled;
  }

  public get value() {
    return this.val;
  }

  public get nameAttr() {
    return this.name || this.group.name;
  }

  constructor(
    private group: NgbRadioGroupDirective,
    private label: NgbButtonLabelDirective,
    private renderer: Renderer2,
    private element: ElementRef<HTMLInputElement>,
    private cd: ChangeDetectorRef
  ) {
    this.group.register(this);
    this.updateDisabled();
  }

  public ngOnDestroy() {
    this.group.unregister(this);
  }

  public onChange() {
    this.group.onRadioChange(this);
  }

  public updateValue(value) {
    // label won't be updated, if it is inside the OnPush component when [ngModel] changes
    if (this.value !== value) {
      this.cd.markForCheck();
    }

    this.isChecked = this.value === value;
    this.label.active = this.isChecked;
  }

  public updateDisabled() {
    this.label.disabled = this.disabled;
  }
}
