import { Injectable } from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {RestApiService} from './rest-api.service';
import {ConfigService} from '../config/config.service';
import {Subject} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import * as moment from 'moment';

export class ServerResponsibilityState {
  public isResponsible : boolean;
}

export interface ISyncResponse {
  serverDateTime?: number;
  polls: IPollingKeys;
}
export interface IPollingKeys {
  tagsSwUpdateCompleted: string;
  entityAlerts: string;
  scrTags: string;
  deviceInventory: string;
  systemAlerts: string;
  sireList: string;
  reproductionGraphSetup: string;
  finishingDailyHeatStressGraphSetup: string;
  importFromFileUpdated: string;
  deviceInventoryActions: string;
  tagsSwUpdate: string;
  statistics: string;
  healthGraphSetup: string;
  premiumTrialStarts: string;
  notes: string;
  login: string;
  reportsDashboard: string;
  tagsKpi: string;
  heatStressGraphSetup: string;
  groupsMeta: string;
  search: string;
  reportsBrowse: string;
  tagsSwUpdateError: string;
  dailyHeatStressGraphSetup: string;
  rfIdTags: string;
  tagMaintenanceCallsDashboard: string;
  appLogout: string;
  groups: string;
  shiftUpdated: string;
  systemKpis: string;
  sgRtsDevicesUpdated: string;
  ledOperationActiviation: string;
  dashboardKpiRecalculated: string;
}
export enum PollingKeyNames {
  tagsSwUpdateCompleted = 'tagsSwUpdateCompleted',
  entityAlerts = 'entityAlerts',
  scrTags = 'scrTags',
  deviceInventory = 'deviceInventory',
  systemAlerts = 'systemAlerts',
  sireList = 'sireList',
  reproductionGraphSetup = 'reproductionGraphSetup',
  finishingDailyHeatStressGraphSetup = 'finishingDailyHeatStressGraphSetup',
  importFromFileUpdated = 'importFromFileUpdated',
  deviceInventoryActions = 'deviceInventoryActions',
  tagsSwUpdate = 'tagsSwUpdate',
  statistics = 'statistics',
  healthGraphSetup = 'healthGraphSetup',
  premiumTrialStarts = 'premiumTrialStarts',
  notes = 'notes',
  login = 'login',
  reportsDashboard = 'reportsDashboard',
  tagsKpi = 'tagsKpi',
  heatStressGraphSetup = 'heatStressGraphSetup',
  groupsMeta = 'groupsMeta',
  search = 'search',
  reportsBrowse = 'reportsBrowse',
  tagsSwUpdateError = 'tagsSwUpdateError',
  dailyHeatStressGraphSetup = 'dailyHeatStressGraphSetup',
  rfIdTags = 'rfIdTags',
  tagMaintenanceCallsDashboard = 'tagMaintenanceCallsDashboard',
  appLogout = 'appLogout',
  groups = 'groups',
  shiftUpdated = 'shiftUpdated',
  systemKpis = 'systemKpis',
  sgRtsDevicesUpdated = 'sgRtsDevicesUpdated',
  ledOperationActiviation = 'ledOperationActiviation',
  dashboardKpiRecalculated = 'dashboardKpiRecalculated'
}

@Injectable({
  providedIn: 'root'
})
export class PollingService {

  public serverResponsibility: Subject<ServerResponsibilityState> = new Subject();

  public pollingChangesState: Subject<string[]> = new Subject();

  private static readonly PollsStateStorageKey: string = 'handleResponsePolls';

  private static readonly PingRoute: string = '/rest/api/server/ping';

  private static readonly SyncRoute: string = '/rest/api/v3/server/sync';

  constructor(private restApiService: RestApiService,
              public configService: ConfigService,
              private authService: AuthService) {

  }

  public initPolling() {
    this.sendPingRequest();
    this.sendSyncRequest();
  }

  private async sendSyncRequest() {
    if(this.authService.currentUserAuthState != null &&
       this.authService.currentUserAuthState.authDetails) {
      let httpParams = new HttpParams();
      let date: Date = new Date();
      httpParams = httpParams.append('lastUpdateTime', moment().unix().toString());
      let response = await this.restApiService.sendGetMethodAny<ISyncResponse>(PollingService.SyncRoute, this.authService.currentUserAuthState.authDetails, httpParams);
      if (response.status == 200) {
        let actualPolls = JSON.parse(window.sessionStorage.getItem(PollingService.PollsStateStorageKey));
        let pollsChanges: string[] = [];
        if(actualPolls != null) {
          for (let pollName of Object.getOwnPropertyNames(response.responseBody.polls)) {
            if (actualPolls.hasOwnProperty(pollName) &&
              response.responseBody.polls[pollName] != actualPolls[pollName]) {
              pollsChanges.push(pollName);
            }
          }
        }
        window.sessionStorage.setItem(PollingService.PollsStateStorageKey, JSON.stringify(response.responseBody.polls));
        if (pollsChanges.length > 0) {
          this.pollingChangesState.next(pollsChanges);
        }
      }
    }
    if(!this.configService.configuration.useMockHttp) {
      setTimeout(() => {
        this.sendSyncRequest();
      }, this.configService.configuration.pingInterval);
    }
  }

  private async sendPingRequest() {
    if(this.configService.configuration.cloud &&
      (this.authService.currentUserAuthState == null ||
      (this.authService.currentUserAuthState.authDetails) &&
      !this.configService.configuration.useMockHttp)) {
      setTimeout(() => {
        this.sendPingRequest();
      }, this.configService.configuration.pingInterval);
      return;
    }

    // tslint:disable-next-line:no-any
    let response = await this.restApiService.sendGetMethodAny<any>(PollingService.PingRoute,
                  this.authService.currentUserAuthState != null ? this.authService.currentUserAuthState.authDetails : null);
    if(response.status == 200){
      this.serverResponsibility.next({isResponsible: true});
      if(!this.configService.configuration.useMockHttp) {
        setTimeout(() => {
          this.sendPingRequest();
        }, this.configService.configuration.pingInterval);
      }
    } else {
      this.serverResponsibility.next({isResponsible: false});
      if(!this.configService.configuration.useMockHttp) {
        setTimeout(() => {
          this.sendPingRequest();
        });
      }
    }
  }
}

