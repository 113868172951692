import {Injectable} from "@angular/core";
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuccessDialogService {
  public popupShown: Subject<ISuccessDialogData> = new Subject();

  // tslint:disable-next-line:naming-convention
  private readonly SuccessDialogDefaultMessageTextKey: string = 'REPORTS.GRID.DIALOG.DONE_TITLE';

  public show(customMessage?: string, onClose?: () => void): void {
    this.popupShown.next({ operation: SuccessDialogDataOperation.Open, messageText: customMessage || this.SuccessDialogDefaultMessageTextKey, onClose } as ISuccessDialogData);
  }

  public hide(): void {
    this.popupShown.next({ operation: SuccessDialogDataOperation.Close } as ISuccessDialogData);
  }
}

export enum SuccessDialogDataOperation {
  Open = 'Open',
  Close = 'Close'
}

export interface ISuccessDialogData {
  operation: SuccessDialogDataOperation;
  messageText: string;
  onClose: () => void | null;
}
