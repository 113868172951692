export class PlaybackTestResult {
  public suiteName : string;
  public fileName : string;
  public testCases : PlaybackTestCase[] = [];
}

export class PlaybackTestCase {
  public name: string;
  public success: boolean;
  public failureReason: string;
}
