import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataRow} from '../../../../../services/model/common-model';

@Component({
  selector: 'description-cell-details-template',
  templateUrl: './description-cell-details-template.component.html',
  styleUrls: ['./description-cell-details-template.component.scss']
})
export class DescriptionCellDetailsTemplateComponent implements OnInit {

  @Input()
  public isRowExpanded: boolean;

  @Input()
  public row: DataRow;

  @Input()
  public rowShId: string;

  @Input()
  public hasRowDetails: boolean;

  @Output()
  public toggleExpanded: EventEmitter<void> = new EventEmitter<void>()

  constructor() {

  }

  public ngOnInit() {
  }
}
