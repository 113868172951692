import { NgModule, ModuleWithProviders } from '@angular/core';
import { NgbButtonLabelDirective } from './label';
import { NgbCheckBoxDirective } from './checkbox';
import { NgbRadioDirective, NgbRadioGroupDirective } from './radio';

export { NgbButtonLabelDirective } from './label';
export { NgbCheckBoxDirective } from './checkbox';
export { NgbRadioDirective, NgbRadioGroupDirective } from './radio';

const NGB_BUTTON_DIRECTIVES = [
  NgbButtonLabelDirective,
  NgbCheckBoxDirective,
  NgbRadioGroupDirective,
  NgbRadioDirective
];

@NgModule({
  declarations: NGB_BUTTON_DIRECTIVES,
  exports: NGB_BUTTON_DIRECTIVES
})
export class NgbButtonsModule {}
