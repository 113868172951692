<ng-container [ngSwitch]="column">
  <div class="reason-cell"
       [attr.sh-id]="'reason-cell-' + row.id"
       *ngSwitchCase="LedTasksColumns.reason">
    <ellipsisable-text class="reason-cell-value">{{ row.name }}</ellipsisable-text>
  </div>
  <div class="time-cell" *ngSwitchCase="LedTasksColumns.time">
    {{ row.startTime | epochDateTime:dateTimeFormatEnum.Time:true }}-{{ row.endTime | epochDateTime:dateTimeFormatEnum.Time:true }}
  </div>
  <div class="effective-group-cell" [ngClass]="{ disabled: !row.isEnabled }" *ngSwitchCase="LedTasksColumns.effectiveGroupsAnimals">
    <description-cell-entities [noEntitiesText]="''"
                               [row]="row"
                               [entities]="animalDescriptionEntities"
                               (onToggleExpanded)="toggleExpandRow($event)"></description-cell-entities>
  </div>
  <div class="repeats-cell" *ngSwitchCase="LedTasksColumns.repeats">
    <span [attr.translation-id]="'LED_TASKS.TASK_EDITOR.START_AT'">{{ 'LED_TASKS.TASK_EDITOR.START_AT' | translate }}: {{row.startDate | epochDateTime:dateTimeFormatEnum.Date }}
      <span *ngIf="row.repeatDays" [attr.translation-id]="'SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.REPEATS_FOR_CHECKBOX_CAPTION - ANIMAL.EVENTS.EVENT_DESCRIPTION.DAYS'">| {{ 'SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.REPEATS_FOR_CHECKBOX_CAPTION' | translate }} {{ row.repeatDays }} {{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.DAYS' | translate }}</span></span>
  </div>
  <div class="actions-cell" *ngSwitchCase="LedTasksColumns.actions">
    <row-actions-popup [rowActions]="rowActionsMap.get(row)"
                       [shId]="row.id.toString()"
                       (onPopupOpen)="onRowActionsOpen()"
                       (onRowActionClick)="handleRowAction($event.rowActionPopupItem.action, row)">
    </row-actions-popup>
  </div>
</ng-container>
