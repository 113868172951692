import {Pipe, PipeTransform} from '@angular/core';
import {TranslationService} from '../../services/translations/translation.service';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';
import 'moment/locale/bg';
import 'moment/locale/cs';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/el';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/lt';
import 'moment/locale/lv';
import 'moment/locale/nb';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ro';
import 'moment/locale/ru';
import 'moment/locale/sk';
import 'moment/locale/sl';
import 'moment/locale/sv';
import 'moment/locale/tr';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import 'moment/locale/th';

export enum DateTimeFormatEnum {
  DateTime = 'DateTime',
  DateTime24Hours = 'DateTime24Hours',
  Date = 'Date',
  Time = 'Time',
  Time24Hours = 'Time24Hours',
  MonthAbbreviated = 'MonthAbbreviated',
  DayOfMonth = 'DayOfMonth',
  TimeAgo = 'TimeAgo',
  DayOfWeek = 'DayOfWeek',
  MediumDate = 'MediumDate',
  SyncStatusDateTime = 'SyncStatusDateTime',
  DateDayMonth = 'DateDayMonth',
  Year = 'Year'
}

@Pipe({
  name: 'epochDateTime',
  pure: false
})
export class EpochDateTimePipe implements PipeTransform {

  constructor(private translationService: TranslationService,
              private datePipe: DatePipe){

  }

  // tslint:disable-next-line:no-any
  public transform(value: number, dateTimeFormat: DateTimeFormatEnum | DateTimeFormatEnum[], asUtc?: boolean, asMilliseconds?: boolean): any {
    let timeMs;
    if(asMilliseconds) {
      timeMs = value;
    } else {
      timeMs = moment.unix(value).valueOf();
    }
    if(Array.isArray(dateTimeFormat)) {
      let dateStr = '';
      for (let format of dateTimeFormat) {
        dateStr += this.transformItem(format, timeMs, asUtc) + ' ';
      }
      return dateStr.slice(0, dateStr.length - 1);
    }
    else {
      return this.transformItem(dateTimeFormat, timeMs, asUtc);
    }
  }

  private transformItem(dateTimeFormat: DateTimeFormatEnum, timeMs: number, asUtc?: boolean) {
    if (dateTimeFormat === DateTimeFormatEnum.DateTime) {
      if(asUtc) {
        return moment.utc(timeMs).format(this.translationService.localeShortDateTimeFormat);
      } else {
        return moment(timeMs).format(this.translationService.localeShortDateTimeFormat);
      }
    } else if (dateTimeFormat === DateTimeFormatEnum.SyncStatusDateTime) {
      const format = (this.translationService.selectedLanguage === 'en-us' ? 'MM/DD' : 'DD/MM') + " HH:mm";
      if(asUtc) {
        return moment.utc(timeMs).format(format);
      } else {
        return moment(timeMs).format(format);
      }
    } else if(dateTimeFormat === DateTimeFormatEnum.DateDayMonth) {
      const format = (this.translationService.selectedLanguage === 'en-us' ? 'MM/DD' : 'DD/MM')
      if(asUtc) {
        return moment.utc(timeMs).format(format);
      } else {
        return moment(timeMs).format(format);
      }
    } else if(dateTimeFormat === DateTimeFormatEnum.Year) {
      const format = 'YYYY';
      if(asUtc) {
        return moment.utc(timeMs).format(format);
      } else {
        return moment(timeMs).format(format);
      }
    } else if (dateTimeFormat === DateTimeFormatEnum.Date) {
      if(asUtc) {
        return moment.utc(timeMs).format(this.translationService.shortDateFormatFullYear);
      } else {
        return moment(timeMs).format(this.translationService.shortDateFormatFullYear);
      }
    } else if (dateTimeFormat === DateTimeFormatEnum.DateTime24Hours) {
      if(asUtc) {
        return moment.utc(timeMs).format(this.translationService.shortDateFormatFullYear + ' | HH:mm');
      } else {
        return moment(timeMs).format(this.translationService.shortDateFormatFullYear + ' | HH:mm');
      }
    }else if(dateTimeFormat === DateTimeFormatEnum.MediumDate) {
      return this.datePipe.transform(timeMs, 'mediumDate', asUtc ? 'UTC' : null, this.translationService.selectedLanguage);
    } else if (dateTimeFormat === DateTimeFormatEnum.Time) {
      if(asUtc) {
        return moment.utc(timeMs).format(this.translationService.shortTimeFormat);
      } else {
        return moment(timeMs).format(this.translationService.shortTimeFormat);
      }
    } else if (dateTimeFormat === DateTimeFormatEnum.MonthAbbreviated) {
      return this.datePipe.transform(timeMs, 'MMM', asUtc ? 'UTC' : null, this.translationService.selectedLanguage);
    } else if (dateTimeFormat === DateTimeFormatEnum.DayOfMonth) {
      return this.datePipe.transform(timeMs, 'dd', asUtc ? 'UTC' : null, this.translationService.selectedLanguage);
    } else if (dateTimeFormat === DateTimeFormatEnum.TimeAgo) {
      if(asUtc) {
        return moment.utc(timeMs).locale(this.translationService.selectedLanguage).fromNow();
      } else {
        return moment(timeMs).locale(this.translationService.selectedLanguage).fromNow();
      }
    } else if (dateTimeFormat === DateTimeFormatEnum.DayOfWeek) {
      return this.datePipe.transform(timeMs, 'EEEE', asUtc ? 'UTC' : null, this.translationService.selectedLanguage);
    } else if (dateTimeFormat === DateTimeFormatEnum.Time24Hours) {
      return moment.utc(timeMs).format('HH:mm');
    }
  }

}
