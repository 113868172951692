<div class="summary-title" *ngIf="summaryTitle" [attr.sh-id]='"summary_title_"+summaryTitle'>{{summaryTitle | translate }}</div>
<div class="summary-count" *ngIf="showOfPart">
  <span class="bold"  [attr.sh-id]='"row-count-"+summaryTitle'>{{rowCount}}</span>
  <span class="icon-cow" sh-id="grid-summary-icon-cow" *ngIf="icon === GridSummaryIcon.Cow"></span>
  <span class="icon-group" sh-id="grid-summary-icon-cow" *ngIf="icon === GridSummaryIcon.Group"></span>
  <span class="icon-farm" sh-id="grid-summary-icon-cow" *ngIf="icon === GridSummaryIcon.Farm"></span>
  <span class="icon-notes" sh-id="grid-summary-icon-note" *ngIf="icon === GridSummaryIcon.Notes"></span>
  <span class="small">{{'DIALOG.COMMON.UPDATE_BATCH.OUT_OF' | translate}}</span>
  <span class="small">{{rowTotal}}</span>
</div>
<div class="summary-count" *ngIf="!showOfPart">
  <span class="bold"  [attr.sh-id]='"row-count-"+summaryTitle'>{{rowTotal}}</span>
  <span class="icon-cow" sh-id="grid-summary-icon-cow" *ngIf="icon === GridSummaryIcon.Cow"></span>
  <span class="icon-group" sh-id="grid-summary-icon-cow" *ngIf="icon === GridSummaryIcon.Group"></span>
  <span class="icon-farm" sh-id="grid-summary-icon-cow" *ngIf="icon === GridSummaryIcon.Farm"></span>
  <span class="icon-notes" sh-id="grid-summary-icon-note" *ngIf="icon === GridSummaryIcon.Notes"></span>
</div>
