import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export enum DropdownPopupColorScheme {
  WhiteBlack= 'WhiteBlack',
  BlueWhite= 'BlueWhite',
  WhiteBlue= 'WhiteBlue'
}

@Component({
  selector: 'dropdown-popup',
  templateUrl: './dropdown-popup.component.html',
  styleUrls: ['./dropdown-popup.component.scss']
})
export class DropdownPopupComponent implements OnInit {

  @Input()
  public colorScheme : DropdownPopupColorScheme = DropdownPopupColorScheme.WhiteBlack;

  @Input()
  public contentWidth : string;

  @Input()
  public manualOpenCloseControl : boolean;

  @Output()
  public onOpenStateChanged = new EventEmitter<boolean>();

  public isOpen:boolean;

  public DropdownPopupColorScheme = DropdownPopupColorScheme;

  constructor() { }

  public ngOnInit() {

  }

  public closePopup() {
    this.isOpen = false;
  }

  public openPopup() {
    this.isOpen = true;
  }

  public onHeaderClick() {
    if(!this.manualOpenCloseControl) {
      this.toggleOpen();
    }
  }

  public onOutsideClick() {
    this.closePopup();
    this.onOpenStateChanged.emit(this.isOpen);
  }

  private toggleOpen() {
    this.isOpen = !this.isOpen;
    this.onOpenStateChanged.emit(this.isOpen);
  }
}
