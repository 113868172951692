<div class="account-details-container">
  <div class="account-details-body-wrapper">
    <div class="account-details-text" [attr.translation-id]="'REGISTRATION.FLOW.ACCOUNT_DETAILS'">{{ 'REGISTRATION.FLOW.ACCOUNT_DETAILS' | translate }}</div>
    <div class="form-group">
      <div class="title-text" [attr.translation-id]="'REGISTRATION.FLOW.USERNAME'">{{ 'REGISTRATION.FLOW.USERNAME' | translate }}</div>
      <input class="input-class"
             [class.error]="hasErrorByFieldName(SignUpAccountDetailsFieldName.username)"
             [(ngModel)]="username"
             (keyup)="onUsernameChange()"
             (keydown)="onUsernameChange()"
             [attr.sh-id]="'enter_username_input'"
             [attr.translation-id]="'MANAGE.SETTINGS.USERS.USER_EDIT.USERNAME_PLACEHOLDER'"
             [attr.placeholder]="'MANAGE.SETTINGS.USERS.USER_EDIT.USERNAME_PLACEHOLDER' | translate" />
    </div>
    <div class="form-group">
      <div class="title-text" [attr.translation-id]="'REGISTRATION.FLOW.PASSWORD'">{{ 'REGISTRATION.FLOW.PASSWORD' | translate }}</div>
      <input class="input-class"
             required
             type="password"
             [attr.sh-id]="'enter_password_input'"
             [ngClass]="{ error: hasErrorByFieldName(SignUpAccountDetailsFieldName.password)}"
             [(ngModel)]="password"
             (blur)="onNewPasswordBlur()"
             (keyup)="onNewPasswordValueChanged()"
             (keydown)="onNewPasswordValueChanged()"
             (ngModelChange)="clearErrors()"
             [attr.translation-id]="'REGISTRATION.FLOW.PLACEHOLDER.ENTER_PASSWORD'"
             [attr.placeholder]="'REGISTRATION.FLOW.PLACEHOLDER.ENTER_PASSWORD' | translate" />
      <password-validate *ngIf="openPasswordValidationPopup" [passwordValidationResult]="passwordValidationResult"></password-validate>
    </div>
    <div class="form-group">
      <div class="title-text" [attr.translation-id]="'REGISTRATION.FLOW.CONFIRM_PASSWORD'">{{ 'REGISTRATION.FLOW.CONFIRM_PASSWORD' | translate }}</div>
      <input class="input-class"
             required
             type="password"
             [attr.sh-id]="'re_enter_password_input'"
             [ngClass]="{ error: hasErrorByFieldName(SignUpAccountDetailsFieldName.confirmPassword)}"
             [(ngModel)]="confirmPassword"
             (blur)="onConfirmPasswordBlur()"
             (keyup)="onConfirmPasswordValueChanged()"
             (keydown)="onConfirmPasswordValueChanged()"
             (ngModelChange)="clearErrors()"
             [attr.translation-id]="'REGISTRATION.FLOW.PLACEHOLDER.RE_ENTER_PASSWORD'"
             [attr.placeholder]="'REGISTRATION.FLOW.PLACEHOLDER.RE_ENTER_PASSWORD' | translate" />
    </div>
    <div class="errors-div" *ngIf="registrationService.registrationModel.error">
      <div class="error-text" [attr.translation-id]="'SYSTEM.MANAGEMENT.ERROR_BOLD: REGISTRATION.FLOW.ERROR.' + registrationService.registrationModel.error.error"><b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}:</b> {{ 'REGISTRATION.FLOW.ERROR.' + registrationService.registrationModel.error.error | translate }}</div>
    </div>
  </div>
  <div class="account-details-buttons-wrapper">
    <button class="back-button" [attr.sh-id]="'back_button'" [attr.translation-id]="'REGISTRATION.FLOW.BACK'" (click)="navigateBack()">{{ 'REGISTRATION.FLOW.BACK' | translate }}</button>
    <button class="next-button" [attr.sh-id]="'next_button'" [attr.translation-id]="'REGISTRATION.FLOW.NEXT'" (click)="navigateNext()">{{ 'REGISTRATION.FLOW.NEXT' | translate }}</button>
  </div>
</div>
