import { Component, OnInit } from '@angular/core';
import {WeightEventRow} from '../../../../../services/animals/model/animal-events';
import {UtilsService} from '../../../../../services/utils/utils.service';
import {DescriptionCellComponent} from '../../../../../common/components/data-grid/description-cell-template/description-cell.component';

@Component({
  selector: 'weight-event-description',
  templateUrl: './weight-event-description.component.html',
  styleUrls: ['./weight-event-description.component.scss']
})
export class WeightEventDescriptionComponent extends DescriptionCellComponent<WeightEventRow> implements OnInit {

  constructor(public readonly utilsService:UtilsService) {
    super();
  }

  public ngOnInit() {
  }

}
