import {Component, OnInit, ViewChild} from '@angular/core';
import {FarmMode} from '../../../services/config/model/server-config';
import {Router} from '@angular/router';
import {AuthService, ITrialModeStatus} from '../../../services/auth/auth.service';
import {RegistrationService} from '../../../services/registration/registration.service';
import {GoogleAnalyticsService} from '../../../services/google-analytics/google-analytics.service';
import {HeaderAuthorizationDetails} from '../../../services/rest-api/models/header-authorization-details';
import {ConfirmTrialFarmModeDialogComponent} from "../../../common/components/dialogs/confirm-dialog/confirm-trial-farm-mode-dialog/confirm-trial-farm-mode-dialog.component";
import {TrialOperation} from "../../../services/config/model/anonymous-auth-config";

@Component({
  selector: 'trial-farm-mode-selection',
  templateUrl: './trial-farm-mode-selection.component.html',
  styleUrls: ['./trial-farm-mode-selection.component.scss']
})
export class TrialFarmModeSelectionComponent implements OnInit {

  @ViewChild(ConfirmTrialFarmModeDialogComponent, {static: false})
  public trialFarmSelectionDialogContainer: ConfirmTrialFarmModeDialogComponent;

  public selectedFarmMode: FarmMode = null;

  public farmModes = FarmMode;

  public isDialogShown: boolean = false;

  constructor(private router: Router, private authService: AuthService, public registrationService: RegistrationService, private googleAnalyticsService: GoogleAnalyticsService) { }

  public ngOnInit() {
  }

  public apply() {
    if (this.selectedFarmMode === FarmMode.Dairy) {
      this.trialFarmSelectionDialogContainer.showDialog();
    } else {
      this.router.navigate(['/trial-operation-type-selection']);
    }
  }

  public onCancel() {
    this.authService.isPressedOnRegister = true;
    this.router.navigate(['/registration/initialize-registration']);
  }

  public async continueToDashboard() {
    await this.authService.setIsStartedTrialModeForTheFirstTime(false);
    this.trialFarmSelectionDialogContainer.closeDialog();
    // tslint:disable-next-line:no-any
    const request: any = {
      type: 'trialMode',
      farmMode: this.selectedFarmMode,
      operation: TrialOperation.breeding
    };
    // tslint:disable-next-line:no-any
    const response: any = await this.registrationService.postTrialMode(request);
    if (response.status === 201) {
      let farmMode: FarmMode = response.responseBody.farmMode;
      // call anonymous login to update the trial farm mode status.
      await this.authService.performAnonymousLogin();
      this.googleAnalyticsService.send('Registration', 'start trial', farmMode);
      const trialModeStatus: ITrialModeStatus = this.authService.getTrialModeStatus();
      if (!trialModeStatus) {
          this.authService.setIsStartedTrialModeForTheFirstTime(true);
      } else {
          this.authService.setIsStartedTrialModeForTheFirstTime(false);
      }
      await this.registrationService.loginAsTrialUser();
    }
  }

  public closeDialog() {
    this.trialFarmSelectionDialogContainer.closeDialog();
  }
}
