<div class="trial-mode-selection-wrapper">
  <div class="trial-mode-content">
    <div class="title-text" [attr.translation-id]="'REGISTRATION.FLOW.TRIAL_MODE'">{{ 'REGISTRATION.FLOW.TRIAL_MODE' | translate }}</div>
    <div class="sub-title-text" [attr.translation-id]="'REGISTRATION.FLOW.SELECT_YOUR_OPERATION_TYPE'">{{ 'REGISTRATION.FLOW.SELECT_YOUR_OPERATION_TYPE' | translate }}</div>
    <div class="trial-mode-selection-buttons-div">
      <div class="trial-mode-toggle dairy" [ngClass]="{ selected: selectedFarmMode === farmModes.Dairy }" (click)="selectedFarmMode = farmModes.Dairy">
        <div class="dairy-icon"></div>
        <div class="dairy-text" [attr.translation-id]="'REGISTRATION.FLOW.SENSE_TIME'">{{ 'REGISTRATION.FLOW.SENSE_TIME' | translate }}</div>
        <div class="dairy-text" [attr.translation-id]="'REGISTRATION.FLOW.DAIRY'"><b>{{ 'REGISTRATION.FLOW.DAIRY' | translate }}</b></div>
      </div>
      <div class="trial-mode-toggle beef" [ngClass]="{ selected: selectedFarmMode === farmModes.Beef }" (click)="selectedFarmMode = farmModes.Beef">
        <div class="beef-icon"></div>
        <div class="beef-text" [attr.translation-id]="'REGISTRATION.FLOW.SENSE_TIME'">{{ 'REGISTRATION.FLOW.SENSE_TIME' | translate }}</div>
        <div class="beef-text" [attr.translation-id]="'REGISTRATION.FLOW.BEEF'"><b>{{ 'REGISTRATION.FLOW.BEEF' | translate }}</b></div>
      </div>
    </div>
  </div>
  <div class="buttons-div">
    <button class="button-class back" [attr.translation-id]="'COMMON.BUTTON.CANCEL'" (click)="onCancel()">{{ 'COMMON.BUTTON.CANCEL' | translate }}</button>
    <button class="button-class apply" [attr.translation-id]="'COMMON.BUTTON.APPLY'" [disabled]="!selectedFarmMode" (click)="apply()">{{ 'COMMON.BUTTON.APPLY' | translate }}</button>
  </div>
</div>
<farm-version></farm-version>
<confirm-trial-farm-mode-dialog #trialFarmSelectionDialogContainer
                                [farmMode]="farmModes.Dairy"
                                [isOpen]="isDialogShown"
                                (onCloseClicked)="closeDialog()"
                                (onGoToDashboardClicked)="continueToDashboard()"></confirm-trial-farm-mode-dialog>
