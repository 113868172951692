import {Injectable} from '@angular/core';
import {HttpResponseResult, HttpResponseResultNoBody, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from '../auth/auth.service';
import {
  IGroupAnimals,
  IGroupDetails,
  IGroupChartActivityData,
  IGroupChartRuminationData,
  IGroupGraphDetails,
  IGroupChartHeatStressData,
  IGroupChartConsistencyData,
  AddGroupModel,
  IGroup,
  AddFinishingGroupModel,
  GroupInfo,
  IFinishingGroupAnimals
} from './model/group.interface';
import {HttpParams} from '@angular/common/http';
import {ChartResolution} from '../model/charts/chart.interface';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  private static readonly GetGroupsMetaDataRouteV3 = '/rest/api/v3/groups?projection=groupsMetaData';

  private static readonly GetFinishingGroupsMetadataRouteV3 = '/rest/api/v3/finishing/groups?projection=groupsMetaData';

  private static readonly CreateGroupRouteV3 = '/rest/api/v3/groups';

  private static readonly CreateFinishingGroupRouteV3 = '/rest/api/v3/finishing/groups';

  private static readonly GetGroupDetailsRouteV3 = '/rest/api/v3/groups/{groupId}';

  private static readonly GetFinishingGroupDetailsRouteV3 = '/rest/api/v3/finishing/groups/{groupId}';

  private static readonly GetGroupAnimalsRouteV3 = '/rest/api/v3/groups/{groupId}/animals';

  private static readonly GetFinishingGroupAnimalsRouteV3 = '/rest/api/v3/finishing/groups/{groupId}/animals';

  private static readonly GetGroupGraphRoute = '/rest/api/groups/{groupId}/graphs/{chartId}';

  private static readonly GroupEventRoute = '/rest/api/v3/groups/{groupId}';

  private static readonly GroupFinishingEventRoute = '/rest/api/v3/finishing/groups/{groupId}';

  private static readonly GroupInfoRoute = '/rest/api/v3/groups/{groupId}?projection=groupInfo';

  private static readonly GetFinishingGroupGraphRoute = '/rest/api/finishing/groups/{groupId}/graphs/{chartId}';

  public GroupsMetadata: IGroupBranchesAndHealthIndexDefault;

  public editGroupSubscription: Subject<IGroup> = new Subject<IGroup>();

  public onGroupsListChanged: Subject<boolean> = new Subject<boolean>();

  public groupGraphDetailsSubscription: Subject<void> = new Subject<void>();

  constructor(private restApiService: RestApiService, private authService: AuthService) {
  }

  public getGroupsMeta(): Promise<HttpResponseResult<IGroupsMetaResponse>> {
    return this.restApiService.sendGetMethod<IGroupsMetaResponse>(GroupsService.GetGroupsMetaDataRouteV3, this.authService.currentUserAuthState.authDetails);
  }

  public getFinishingGroupsMeta(): Promise<HttpResponseResult<IGroupsMetaResponse>> {
    return this.restApiService.sendGetMethod<IGroupsMetaResponse>(GroupsService.GetFinishingGroupsMetadataRouteV3, this.authService.currentUserAuthState.authDetails);
  }

  public createGroup(newGroup: AddGroupModel): Promise<HttpResponseResultNoBody> {
    return this.restApiService.sendPostMethod<void>(GroupsService.CreateGroupRouteV3, this.authService.currentUserAuthState.authDetails, newGroup);
  }

  public createFinishingGroup(newFinishingGroup: AddFinishingGroupModel): Promise<HttpResponseResultNoBody> {
    return this.restApiService.sendPostMethod<void>(GroupsService.CreateFinishingGroupRouteV3, this.authService.currentUserAuthState.authDetails, newFinishingGroup);
  }

  public editFinishingGroup(groupId: number, editedFinishingGroup: AddFinishingGroupModel): Promise<HttpResponseResultNoBody> {
    let route = GroupsService.GetFinishingGroupDetailsRouteV3.replace('{groupId}', groupId.toString());
    return this.restApiService.sendPutMethod<void>(route, this.authService.currentUserAuthState.authDetails, editedFinishingGroup);
  }

  public getGroupDetails(groupId: number): Promise<HttpResponseResult<IGroupDetails>> {
    let route = GroupsService.GetGroupDetailsRouteV3.replace('{groupId}', groupId.toString());
    return this.restApiService.sendGetMethod<IGroupDetails>(route, this.authService.currentUserAuthState.authDetails);
  }

  public getGroupDetailsForFinishing(groupId: number): Promise<HttpResponseResult<IGroupDetails>> {
    let route = GroupsService.GetFinishingGroupDetailsRouteV3.replace('{groupId}', groupId.toString());
    return this.restApiService.sendGetMethod<IGroupDetails>(route, this.authService.currentUserAuthState.authDetails);
  }

  public getFinishingGroupDetails(finishingGroupId: number) {
    let route = GroupsService.GetFinishingGroupDetailsRouteV3.replace('{groupId}', finishingGroupId.toString());
    return this.restApiService.sendGetMethod<IGroupDetails>(route, this.authService.currentUserAuthState.authDetails);
  }

  public getGroupAnimals(groupId: number, offset: number, limit: number, sort: string, culled: boolean, projection: string): Promise<HttpResponseResult<IGroupAnimals>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('offset', offset.toString());
    httpParams = httpParams.append('limit', limit.toString());
    httpParams = httpParams.append('culled', culled.toString());
    httpParams = httpParams.append('sort', sort.toString());
    httpParams = httpParams.append('projection', projection.toString());
    let route = GroupsService.GetGroupAnimalsRouteV3.replace('{groupId}', groupId.toString());
    return this.restApiService.sendGetMethod<IGroupAnimals>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getFinishingGroupAnimals(groupId: number, offset: number, limit: number, sort: string, culled: boolean, projection: string): Promise<HttpResponseResult<IFinishingGroupAnimals>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('offset', offset.toString());
    httpParams = httpParams.append('limit', limit.toString());
    httpParams = httpParams.append('culled', culled.toString());
    httpParams = httpParams.append('sort', sort.toString());
    httpParams = httpParams.append('projection', projection.toString());
    let route = GroupsService.GetFinishingGroupAnimalsRouteV3.replace('{groupId}',groupId.toString());
    return this.restApiService.sendGetMethod<IFinishingGroupAnimals>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getGroupGraphDetails(groupId: number): Promise<HttpResponseResult<IGroupGraphDetails>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('projection', 'graph');
    let route = GroupsService.GetGroupDetailsRouteV3.replace('{groupId}', groupId.toString());
    return this.restApiService.sendGetMethod<IGroupGraphDetails>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getGroupChartActivity(groupId: number, resolution: ChartResolution): Promise<HttpResponseResult<IGroupChartActivityData>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('projection', 'flat');
    httpParams = httpParams.append('resolution', resolution);
    let route = GroupsService.GetGroupGraphRoute.replace('{groupId}', groupId.toString()).replace('{chartId}', '3');
    return this.restApiService.sendGetMethod<IGroupChartActivityData>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getGroupChartRumination(groupId: number, resolution: ChartResolution): Promise<HttpResponseResult<IGroupChartRuminationData>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('projection', 'flat');
    httpParams = httpParams.append('resolution', resolution);
    let route = GroupsService.GetGroupGraphRoute.replace('{groupId}', groupId.toString()).replace('{chartId}', '4');
    return this.restApiService.sendGetMethod<IGroupChartRuminationData>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getBreedingGroupChartHeatStress(groupId: number, chartId: number, resolution: ChartResolution, series: string[]): Promise<HttpResponseResult<IGroupChartHeatStressData>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('projection', 'flat');
    httpParams = httpParams.append('resolution', resolution);
    if (series) {
      httpParams = httpParams.append('series', series.join(','));
    }
    let route = GroupsService.GetGroupGraphRoute.replace('{groupId}', groupId.toString()).replace('{chartId}', chartId.toString());
    return this.restApiService.sendGetMethod<IGroupChartHeatStressData>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getFinishingGroupChartHeatStress(groupId: number, chartId: number, resolution: ChartResolution, series: string[]): Promise<HttpResponseResult<IGroupChartHeatStressData>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('projection', 'flat');
    httpParams = httpParams.append('resolution', resolution);
    if (series) {
      httpParams = httpParams.append('series', series.join(','));
    }
    let route = GroupsService.GetFinishingGroupGraphRoute.replace('{groupId}', groupId.toString()).replace('{chartId}', chartId.toString());
    return this.restApiService.sendGetMethod<IGroupChartHeatStressData>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getBreedingGroupConsistencyChart(groupId: number, chartId: number, healthAndDimFilterEnabled: boolean): Promise<HttpResponseResult<IGroupChartConsistencyData>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('projection', 'flat');
    if (!healthAndDimFilterEnabled) {
      httpParams = httpParams.append('filter', 'healthAndDim');
    }
    let route = GroupsService.GetGroupGraphRoute.replace('{groupId}', groupId.toString()).replace('{chartId}', chartId.toString());
    return this.restApiService.sendGetMethod<IGroupChartConsistencyData>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getFinishingGroupConsistencyChart(groupId: number, chartId: number): Promise<HttpResponseResult<IGroupChartConsistencyData>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('projection', 'flat');
    let route = GroupsService.GetFinishingGroupGraphRoute.replace('{groupId}', groupId.toString()).replace('{chartId}', chartId.toString());
    return this.restApiService.sendGetMethod<IGroupChartConsistencyData>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public deleteGroup(groupId: number): Promise<HttpResponseResultNoBody> {
    let route = GroupsService.GroupEventRoute.replace('{groupId}', groupId.toString());
    return this.restApiService.sendDeleteMethod(route, this.authService.currentUserAuthState.authDetails);
  }

  public deleteFinishingGroup(groupId: number): Promise<HttpResponseResultNoBody> {
    let route = GroupsService.GroupFinishingEventRoute.replace('{groupId}', groupId.toString());
    return this.restApiService.sendDeleteMethod(route, this.authService.currentUserAuthState.authDetails);
  }

  // tslint:disable-next-line:no-any
  public editGroup(groupId: number, groupInfo:any): Promise<HttpResponseResult<AddGroupModel>> {
    let route = GroupsService.GetGroupDetailsRouteV3.replace('{groupId}', groupId.toString());
    return this.restApiService.sendPutMethodAny<AddGroupModel>(route, this.authService.currentUserAuthState.authDetails, groupInfo);
  }

  public getGroupInfo(groupId: number) : Promise<HttpResponseResult<GroupInfo>> {
    let route = GroupsService.GroupInfoRoute.replace('{groupId}', groupId.toString());
    return this.restApiService.sendGetMethod<GroupInfo>(route, this.authService.currentUserAuthState.authDetails);
  }
}

export interface IGroupsMetaResponse {
  groupMetadata: IGroupBranchesAndHealthIndexDefault;
}


export interface IGroupBranchesAndHealthIndexDefault {
  healthIndexDefaultThreshold: number;
  nextGroupNumber: number;
  // tslint:disable-next-line:no-any
  branches: any;
}

