import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IHardwareForEdit} from '../../farm-topology.component';
import {TranslationService} from '../../../../../services/translations/translation.service';

@Component({
  selector: 'edit-hardware-dialog',
  templateUrl: './edit-hardware-dialog.component.html',
  styleUrls: ['./edit-hardware-dialog.component.scss']
})
export class EditHardwareDialogComponent implements OnInit {

  constructor(public readonly translationService: TranslationService) { }

  public errors: IError[] = [];

  @Input()
  public isPopupOpen: boolean = false;

  @Input()
  public HardwareDetails: IHardwareForEdit = { id: null, hardwareDesc: null, hardwareType: null, hardwareVersion: null, isDefault: false, ip: null, serialNumber: null, ddVersion: null, mac: null };

  @Input()
  public HardwareRows: IHardwareForEdit[] = [];

  @Output()
  // tslint:disable-next-line:no-any
  public onCloseDialogClicked: EventEmitter<any> = new EventEmitter<any>();

  public ngOnInit() {
  }

  public saveChanges() {
    let hardwareResult = this.HardwareRows.filter((hardware: IHardwareForEdit) => hardware.hardwareDesc === this.HardwareDetails.hardwareDesc);
    if (hardwareResult.length === 0) {
      let hardwareToUpdate = this.HardwareRows.find((hardware: IHardwareForEdit) => hardware.id === this.HardwareDetails.id);
      hardwareToUpdate.hardwareDesc = this.HardwareDetails.hardwareDesc;
      this.closeDialog();
    } else {
      this.errors.push({
        fieldName: 'hardwareDesc',
        key: 'MULTIPLE_DESC'
      });
    }
  }

  public clearErrors() {
    this.errors = [];
  }

  public closeDialog() {
    this.onCloseDialogClicked.emit();
  }

}
export interface IError {
  fieldName: string;
  key: string;
}
