import {Directive, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {SaveDataViewStateService} from '../../../services/ui/view-state/save-data-view-state.service';

@Directive({
  selector: '[saveDataWarningIgnore]'
})
export class SaveDataWarningIgnoreDirective implements OnInit, OnDestroy{

  constructor(private warningIgnoreElement: ElementRef,
              private saveDataViewStateService:SaveDataViewStateService) {

  }

  public ngOnInit(): void {
    this.saveDataViewStateService.addWarningIgnoreElement(this.warningIgnoreElement.nativeElement);
  }

  public ngOnDestroy(): void {
    this.saveDataViewStateService.removeWarningIgnoreElement(this.warningIgnoreElement.nativeElement);
  }
}
