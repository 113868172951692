export class ReportRowSelectionState {
  
  private selectedRowsIds:Set<number> = new Set<number>();
  
  public performingSelectAllRows: boolean;
  
  constructor() {
  
  }
  
  public getSelectedRows() : number[] {
    return Array.from(this.selectedRowsIds.keys())
  }
  
  public isAllRowsSelected(rowIds:number[]) {
    return rowIds.every(rowId => this.selectedRowsIds.has(rowId));
  }
  
  public isRowSelected(rowId:number) {
    return this.selectedRowsIds.has(rowId);
  }
  
  public get selectedRowsCount() : number {
    return this.selectedRowsIds.size;
  }
  
  public toggleRowSelection(rowId:number) {
    if(this.selectedRowsIds.has(rowId)) {
      this.selectedRowsIds.delete(rowId);
    } else {
      this.selectedRowsIds.add(rowId);
    }
  }
  
  public clearSelection() {
    this.selectedRowsIds.clear();
  }
  
  public selectRows(rowIds: number[]) {
    rowIds.forEach(rowId => {
      this.selectedRowsIds.add(rowId);
    });
  }
  
  public unselectRows(rowIds: number[]) {
    rowIds.forEach(rowId => {
      this.selectedRowsIds.delete(rowId);
    })
  }
}
