import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '../../utils/window-utils';
import {ConfigService} from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class PrintHtmlService {

  constructor(@Inject(WINDOW) private _window: Window,
              public configService:ConfigService) { }

  public printHTML (htmlToPrint: string): void {
    if (this.configService.printContentInPopup) {
      const win = window.open('');
      win.document.body.innerHTML = htmlToPrint;
    } else {
      const currentDocument = this._window.document;

      const frame = currentDocument.createElement('iframe');
      frame.setAttribute('sh-id', 'print-iframe');
      currentDocument.body.appendChild(frame);

      const win = frame.contentWindow;
      const doc = frame.contentDocument || win.document;

      doc.body.innerHTML = htmlToPrint;

      win.focus();
      win.print();

      frame.remove();
    }
  }
}
