<div class="form-group">
  <div class="form-group-label">{{ "ANIMAL.EVENTS.ADD_EVENT.OPEN_NEW_LACTATION" | translate}}</div>
  <div class="open-new-lactation create-batch-event-content-control">
    <input id="openNewLactationTrue"
           type="radio"
           name="type"
           [value]="AbortionType.AbortionNewLactation"
           [(ngModel)]="abortionEventDetails.type"
           [ngClass]="{'invalid': hasTypeErrorFieldName}"/>
    <label for="openNewLactationTrue">{{ "ANIMAL.EVENTS.ADD_EVENT.OPEN_NEW_LACTATION_YES" | translate}}</label>
    <input id="openNewLactationFalse"
           type="radio"
           name="type"
           [value]="AbortionType.AbortionSameLactation"
           [(ngModel)]="abortionEventDetails.type"
           [ngClass]="{'invalid': hasTypeErrorFieldName}"/>
    <label for="openNewLactationFalse">{{ "ANIMAL.EVENTS.ADD_EVENT.OPEN_NEW_LACTATION_NO" | translate}}</label>
    <input id="openNewLactationUnselected"
           type="radio"
           name="type"
           [value]="AbortionType.Unselected"
           [(ngModel)]="abortionEventDetails.type"
           [style.display]="'none'"/>
  </div>
</div>
<div class="form-group">
  <div class="form-group-label">{{ "ANIMAL.EVENTS.ADD_EVENT.DESTINATION_GROUP" | translate}}</div>
  <div class="select-groups">
    <regular-dropdown-list [items]="cowsGroupsNewLactationDropdownListItems"
                           [selectedValue]="selectedCowGroup"
                           (onItemSelected)="onGroupSelected($event)"
                           [placeholder]="'Select Group'"></regular-dropdown-list>
  </div>
</div>
