import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {

  @Input()
  public isChecked: boolean;
  @Input()
  public radioButtonSize?: string = '28px';
  @Input()
  public label: string;
  @Input()
  public colorScheme: RadioButtonColorTheme = RadioButtonColorTheme.Blue;
  @Output()
  // tslint:disable-next-line:no-any
  public change: EventEmitter<any> = new EventEmitter<any>();

  public RadioButtonColorTheme = RadioButtonColorTheme;

  constructor() { }

  public ngOnInit() {}

  public onRadioButtonClicked() {
    this.change.emit();
  }

}

export enum RadioButtonColorTheme {
  Blue = 'Blue',
  BlueMarine = 'BlueMarine',
  Green = 'Green'
}
