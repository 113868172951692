import { Component, OnInit } from '@angular/core';
import {DescriptionCellComponent} from '../../../../../common/components/data-grid/description-cell-template/description-cell.component';
import { GeneralEventRow} from '../../../../../services/animals/model/animal-events';

@Component({
  selector: 'general-event-description',
  templateUrl: './general-event-description.component.html',
  styleUrls: ['./general-event-description.component.scss']
})
export class GeneralEventDescriptionComponent extends DescriptionCellComponent<GeneralEventRow> {

  public constructor() {
    super();
  }
}
