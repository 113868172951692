import { Component, OnInit } from '@angular/core';
import {AbortionNewLactationEventRow} from '../../../../../services/animals/model/animal-events';
import {DescriptionCellComponent} from '../../../../../common/components/data-grid/description-cell-template/description-cell.component';

@Component({
  selector: 'abortion-new-lactation-event-description',
  templateUrl: './abortion-new-lactation-event-description.component.html',
  styleUrls: ['./abortion-new-lactation-event-description.component.scss']
})
export class AbortionNewLactationEventDescriptionComponent extends DescriptionCellComponent<AbortionNewLactationEventRow> implements OnInit {

  constructor() {
    super();
  }

  public ngOnInit() {
  }

  public get hasDetails() : boolean {
    return this.row.daysInPregnancy != null ||
      this.row.destinationGroupName != null  ||
      (this.row.newborns != null &&
        this.row.newborns.length > 0);
  }
}
