import {EntitiesDataRow, Group, NoteNames} from '../../model/common-model';

export class NotesResult {
  public offset: number;
  public limit: number;
  public total: number;
  public result: NoteRow[];
}

export class NotesMetadataResult {
  public groups: Group[];
  public names: NoteNames[];
}

export class NoteObject {
  public name: string;
  public startDateTime: number;
  public groupIds: number[];

  constructor() {}
}

export class NoteRow extends EntitiesDataRow {
  public id: number;
  public name: string;
  public startDateTime: number;
  public groups: Group[];
}
