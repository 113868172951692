import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AnimalsService} from 'src/app/services/animals/animals.service';
import {AnimalSex} from 'src/app/services/animals/model/animal.model';
import {AnimalCardTabSection, AnimalCardViewState} from './animal-card-model';
import {AnimalTimelineFlowModel} from '../../../services/animal-timeline/model/animal-timeline-model';
import {AnimalTimelineBreedingService,} from '../../../services/animal-timeline/breeding/animal-timeline-breeding.service';
import {OperationType} from '../../../services/search/model/search.model';
import {AnimalTimelineFinishingService} from '../../../services/animal-timeline/finishing/animal-timeline-finishing.service';

@Component({
  selector: 'animal-card',
  templateUrl: './animal-card.component.html',
  styleUrls: ['./animal-card.component.scss']
})
export class AnimalCardComponent implements OnChanges {

  @Input()
  public viewState: AnimalCardViewState;
  
  @Input()
  public operationType: OperationType;
  
  public animalTimelineFlowModel:AnimalTimelineFlowModel;

  public AnimalCardTabSection = AnimalCardTabSection;

  public AnimalSex = AnimalSex;

  constructor(public readonly animalsService:AnimalsService,
              public readonly animalTimelineBreedingService:AnimalTimelineBreedingService,
              public readonly animalTimelineFinishingService:AnimalTimelineFinishingService) {

  }

  public async ngOnChanges(changes: SimpleChanges) {
    let viewStateChange = changes['viewState'];
    if(viewStateChange.previousValue == viewStateChange.currentValue){
      return;
    } else if(this.viewState != null) {
      if((this.viewState.selectedTab == AnimalCardTabSection.GraphsHealth &&
          !this.isHealthGraphSectionVisible) ||
        (this.viewState.selectedTab == AnimalCardTabSection.GraphsHeat &&
         !this.isHeatGraphSectionVisible)) {
        this.viewState.selectedTab = AnimalCardTabSection.Events;
      }
      if (viewStateChange.currentValue.operationType) {
        if (viewStateChange.currentValue.operationType !== viewStateChange.previousValue.operationType) {
          this.operationType = viewStateChange.currentValue.operationType;
        }
      }
    }
    this.animalTimelineFlowModel = null;
    if(this.viewState.animalInfo != null){
      await this.getAnimalEventsTimeline();
    }
  }

  public get isHeatGraphSectionVisible() : boolean {
    return (this.viewState.animalInfo.sex == AnimalSex.Female &&
            this.operationType !== OperationType.BeefFinishing);
  }

  public get isHealthGraphSectionVisible() : boolean {
    return this.viewState.animalInfo.plan !== 'Starter' && this.viewState.animalInfo.plan !== 'None';
  }

  public selectTab(tab:AnimalCardTabSection){
    this.viewState.selectedTab = tab;
  }

  private async getAnimalEventsTimeline() {
    if(this.operationType == OperationType.Dairy ||
       this.operationType == OperationType.BeefBreeding) {
      this.animalTimelineFlowModel = await this.animalTimelineBreedingService.getAnimalTimeline(this.viewState.animalInfo.animalId);
    } else {
      this.animalTimelineFlowModel = await this.animalTimelineFinishingService.getAnimalTimeline(this.viewState.animalInfo.animalId);
    }
  }
}
