import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {DIALOG_TRANSITIONS} from '../../../animations/animations';
import {SaveDataViewStateService} from '../../../../services/ui/view-state/save-data-view-state.service';

@Component({
  selector: 'save-data-warning-dialog',
  templateUrl: './save-data-warning-dialog.component.html',
  styleUrls: ['./save-data-warning-dialog.component.scss'],
  animations: [DIALOG_TRANSITIONS]
})
export class SaveDataWarningDialogComponent implements OnInit, OnDestroy  {

  public isOpenModal: boolean = false;

  @Output()
  public onProceedApproved = new EventEmitter<void>();

  private showSaveDataWarningModalSubscription: Subscription;

  private dialogState: ISaveDataWarningDialogState;

  constructor(private saveDataViewStateService:SaveDataViewStateService) {

  }

  public ngOnInit() {
    this.showSaveDataWarningModalSubscription = this.saveDataViewStateService.showSaveDataWarningModalSubject.subscribe((data: ISaveDataWarningDialogState) => {
      this.dialogState = data;
      this.isOpenModal = true;
    });
  }

  public ngOnDestroy(): void {
    this.showSaveDataWarningModalSubscription.unsubscribe();
  }

  public onApprove($event: Event) {
    $event.stopPropagation();
    this.proceedToNextArea();
  }

  public proceedToNextArea(): void {
    this.isOpenModal = false;
    if (this.dialogState != null) {
      this.dialogState.isCancelClickedRecently = true;
      this.dialogState = null;
    }
    this.saveDataViewStateService.approveDataLossAndContinue();
  }

  public closeModal() {
    this.isOpenModal = false;
    if (this.dialogState != null) {
      this.dialogState.isCancelClickedRecently = false;
      this.dialogState = null;
    }
    this.saveDataViewStateService.isWarningModalPresent = false;
  }
}

export interface ISaveDataWarningDialogState {
  isCancelClickedRecently: boolean;
}
