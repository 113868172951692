import {Component, Input, OnInit} from '@angular/core';
import {IReportMetaFilterValueColumn, IReportMetaFilterValueItem} from '../../../../../../services/reports/model/report.interface';
import {FilterChecklistQuery} from '../../../../../../services/reports/model/report-query.interface';
import {ReportValueType} from '../../report-grid.component';

@Component({
  selector: 'filter-check-list',
  templateUrl: './filter-check-list.component.html',
  styleUrls: ['./filter-check-list.component.scss']
})
export class FilterCheckListComponent implements OnInit {

  @Input()
  public reportMetaFilterValueColumn : IReportMetaFilterValueColumn;

  @Input()
  public columnType: ReportValueType;

  @Input()
  public filterChecklistQuery : FilterChecklistQuery;

  private isBranchDynamicMeta: boolean = false;

  constructor() { }

  public ngOnInit() {
    this.isBranchDynamicMeta = this.columnType === ReportValueType.DynamicLocalization;
  }

  public isValueChecked(key:string) {
    return this.filterChecklistQuery.selectedItems.has(key);
  }

  public get reportMetaFilterValueColumnValues(): IReportMetaFilterValueItem[] {
    return this.reportMetaFilterValueColumn.values;
  }

  public toggleValue(key:string){
    if(this.filterChecklistQuery.selectedItems.has(key)){
      this.filterChecklistQuery.selectedItems.delete(key);
    } else {
      this.filterChecklistQuery.selectedItems.add(key);
    }
  }

}
