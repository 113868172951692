import {
  AfterViewChecked,
  Component,
  ContentChildren,
  ElementRef, OnDestroy,
  QueryList, ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {ChipsItemComponent} from '../chips/chips-item.component';
import {ClickPressEventsService} from '../../../services/click-press-events/click-press-events.service';

@Component({
  selector: 'chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
})
export class ChipListComponent implements AfterViewChecked, OnDestroy {

  @ViewChild('container')
  private container: ElementRef<HTMLElement>;

  @ContentChildren(ChipsItemComponent)
  public chipComponents: QueryList<ChipsItemComponent>;

  private subscription: Subscription;

  private wasUpdatedFor: number;

  constructor(private readonly clickPressEventsService: ClickPressEventsService, private elementRef: ElementRef) {
    this.subscription = this.clickPressEventsService.windowResize.subscribe(() => this.updateFlexGrow());
  }

  public ngAfterViewChecked(): void {
    if (!!this.chipsArray && this.wasUpdatedFor !== this.chipsArray?.length) {
      this.updateFlexGrow();
      this.wasUpdatedFor = this.chipsArray.length;
    }
  }

  private get hostWidth(): number {
    return this.container?.nativeElement.clientWidth - this.containerPadding;
  }

  private get containerPadding(): number {
    if (!!this.container) {
      const paddingLeft = Number(getComputedStyle(this.container.nativeElement, null).paddingLeft.replace('px', ''));
      const paddingRight = Number(getComputedStyle(this.container.nativeElement, null).paddingRight.replace('px', ''));
      const hostPaddingLeft = Number(getComputedStyle(this.elementRef.nativeElement, null).paddingLeft.replace('px', ''));
      const hostPaddingRight = Number(getComputedStyle(this.elementRef.nativeElement, null).paddingRight.replace('px', ''));
      return  paddingLeft + paddingRight + hostPaddingLeft + hostPaddingRight;
    } else {
      return 0;
    }
  }

  private get chipsArray(): ChipsItemComponent[] {
    return this.chipComponents?.map(chip => chip);
  }

  private updateFlexGrow(): void {
    const chips: ChipsItemComponent[] = this.chipsArray;
    let cumulativeTotal = 0;
    for (const i of Object.keys(chips)) {
      cumulativeTotal += chips[i].initialWidth;
      const nextChip: ChipsItemComponent = chips[Number(i) + 1];
      if (!!nextChip && ((cumulativeTotal + nextChip?.initialWidth) > this.hostWidth)) {
        chips[i].flexGrow = 1;
        cumulativeTotal = 0;
      } else {
        chips[i].flexGrow = 0;
      }
    }

    setTimeout(() => {
      this.container.nativeElement.click();
    });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
