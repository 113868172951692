import {Injectable} from '@angular/core';
import {SystemEditBarComponent} from "../../logged-in-layout/system/system-edit-bar/system-edit-bar.component";
import {SaveDataViewStateService} from '../ui/view-state/save-data-view-state.service';

@Injectable({
  providedIn: 'root'
})
export class EditBarService {

  private registeredEditBar: SystemEditBarComponent|null = null;

  constructor(private readonly saveDataViewStateService: SaveDataViewStateService) { }

  public registerEditBarInstance(systemEditBarComponentInstance: SystemEditBarComponent): void {
    if (!(this.registeredEditBar && systemEditBarComponentInstance === this.registeredEditBar)) {
      this.registeredEditBar = systemEditBarComponentInstance;
    }
  }

  public unregisterEditBarInstance(systemEditBarComponentInstance: SystemEditBarComponent): void {
    if (this.registeredEditBar && this.registeredEditBar === systemEditBarComponentInstance) {
      this.registeredEditBar = null;
      this.saveDataViewStateService.activeEditArea = null;
    }
  }

  public registerOnSaveFunction(onSaveFn: (() => void)|null, activeEditArea: string = null): void {
    if (!this.registeredEditBar) {
      return;
    }
    if (!onSaveFn) {
      this.registeredEditBar.removeOnSaveClickCallback();
      this.saveDataViewStateService.activeEditArea = null;
      return;
    }
    this.registeredEditBar.attachOnSaveClickCallback(onSaveFn);
    this.saveDataViewStateService.activeEditArea = activeEditArea;
  }

  public registerOnResetFunction(onResetFn: (() => void)|null): void {
    if (!this.registeredEditBar) {
      return;
    }
    if (!onResetFn) {
      this.registeredEditBar.removeOnResetClickCallback();
      return;
    }
    this.registeredEditBar.attachOnResetClickCallback(onResetFn);
  }

  public resetEditBar(): void {
    if (!this.registeredEditBar) {
      return;
    }
    this.saveDataViewStateService.activeEditArea = null;
    this.registeredEditBar.resetEditBar();
  }

  public isDirty(): boolean {
    if (!this.registeredEditBar) {
      return false;
    }
    return this.registeredEditBar.editBarVisibility;
  }
}
