<div class="branch-card-details-header" *ngIf="branchDetails">
  <span>
    <span [attr.translation-id]="'REPORTS.GRID.GROUP_BRANCH_NUMBER'" [attr.sh-id]="'branch_number_title'">
      {{ "REPORTS.GRID.GROUP_BRANCH_NUMBER" | translate }}:
    </span>
    <span class="value">{{ branchDetails.groupBranchNumber }}</span>
  </span>
  <report-actions-dropdown *ngIf="branchGroupsReport"
                           [ShIdPrefix]="'branch_card_'"
                           [reportContext]="this"
                           [report]="branchGroupsReport"
                           [reportQuery]="branchGroupQuery"
                           [reportActions]="branchGroupsReport.meta.reportActions"
                           [isActionsOnLeftSide]="true"></report-actions-dropdown>
</div>
<report-grid
  #branchGroupsReportGrid
  [attr.sh-id]="'branch_group_reports_grid'"
  [rowHeight]="64"
  [isReportGridShownInCardPopup]="true"
  [reportQuery]="branchGroupQuery"
  [report]="branchGroupsReport"
  (onReportQueryChanged)="onReportQueryChanged()"
  (onReportGridValueCellClick)="onReportGridValueCellClick($event)"
  [reportContext]="this"></report-grid>
