<div class="time-selection">
  <div class="time-part">
    <div class="button-plus" (click)="addHour()"></div>
    <div class="value" [ngStyle]="{'background':valueBackgroundColor}">{{hours}}</div>
    <div class="button-minus" (click)="subtractHour()"></div>
  </div>
  <div class="time-part-separator">:</div>
  <div class="time-part">
    <div class="button-plus" (click)="addMinute()"></div>
    <div class="value" [ngStyle]="{'background':valueBackgroundColor}">{{minutes}}</div>
    <div class="button-minus" (click)="subtractMinute()"></div>
  </div>
</div>
