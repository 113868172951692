import { Pipe, PipeTransform } from '@angular/core';
import {DateTimeFormatEnum} from "./epoch-date-time.pipe";

export enum DurationInput {
  Minutes = 'Minutes',
  Milliseconds = 'Milliseconds',
  HoursMinutesText = 'HoursMinutesText',
  HoursMinutesNoLeadingZeroText = 'HoursMinutesNoLeadingZeroText'
}

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  public transform(value: number, durationInput?: DurationInput): string {
    if(durationInput == null ||
       durationInput == DurationInput.Minutes) {
      let hours = Math.floor(value / 60);
      let minutes = value % 60;
      return this.formatHoursMinutes(hours, minutes);
    } else if (durationInput == DurationInput.HoursMinutesText) {
      let hours = Math.floor(value / 60);
      let minutes = value % 60;
      return this.formatHoursMinutesText(hours, minutes);
    } else if (durationInput == DurationInput.HoursMinutesNoLeadingZeroText) {
      let hours = Math.floor(value / 60);
      let minutes = value % 60;
      return this.formatHoursMinutesNoLeadingZeroText(hours, minutes);
    } else {
      let durationSec = value / 1000;
      let hours = durationSec / 3600;
      let minutes = (durationSec / 60) % 60;
      return this.formatHoursMinutes(hours, minutes);
    }
  }

  private formatHoursMinutes(hours:number, minutes:number) : string {
    return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
  }

  private formatHoursMinutesText(hours: number, minutes: number): string {
    return (hours < 10 ? '0' + hours : hours) + 'h ' + (minutes < 10 ? '0' + Math.floor(minutes) : Math.floor(minutes)) + 'm';
  }

  private formatHoursMinutesNoLeadingZeroText(hours: number, minutes: number): string {
    return (hours ? hours + 'h ': '') + (minutes ? Math.floor(minutes) + 'm' : '');
  }
}
