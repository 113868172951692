import {ICountryItem} from "../../system/model/system-account";
import {IKeyValuePair} from "../../model/key-value-pair";
import {DropdownListItem} from "../../../common/components/dropdown-list/dropdown-list-model";
import {ISecurityQuestionObject} from "../../../logged-out-layout/welcome-layout/registration/sign-up/security-questions/security-questions.component";
import {BizModel, FarmMode} from "../../config/model/server-config";
import {OperationType} from "../../search/model/search.model";
import {TrialOperation} from "../../config/model/anonymous-auth-config";
import {OrderedPopulationPlanModel} from "../../system/model/about-model";

export interface IFarmVersion {
  farmVersion: string;
}

export interface IRegistrationResponse {
  // tslint:disable-next-line:no-any
  failures: any[];
  farmId: string;
  farmMode: FarmMode;
  operation: TrialOperation;
  // tslint:disable-next-line:no-any
  field: any;
  succeeded: boolean;
}
export interface IFarmDetailsResponse {
  address: string;
  applicationBundle: string;
  bizModelMode: string;
  city: string;
  country: string;
  countryCode: string;
  farmHousing: string;
  farmId: string;
  farmName: string;
  farmType: string;
  link: boolean;
  metadata: IFarmDetailsMetadata;
  populationPlan: IPopulationPlan;
  timeZone: string;
  orderedPopulationPlan: OrderedPopulationPlanModel,
  type: string;
}
export interface IPopulationPlan {
  cows: string;
  heifers: string;
  youngStock: string;
  finishing: string;
}
export interface IFarmDetailsMetadata {
  countries: Array<ICountryItem>;
  farmHousing: Array<IKeyValuePair>;
  farmTypes: Array<IKeyValuePair>;
  timeZones: Array<IKeyValuePair>;
}
export interface IFarmThirdPartyResponse {
  deviceType: string;
  registrationKey: string;
  thirdParty: Array<IThirdPartyItem>;
  type: string;
  validationMode: boolean;
  linkedStatus: boolean;
  inputFolder: string;
  outputFolder: string;
  CRVRegistrationKey: string;
}
export interface IThirdPartyItem {
  deviceType: string;
  registrationKeyGeneratedByUser: boolean;
  supportAddHerds: boolean;
  supportByCloud: boolean;
  supportStatusFileWithInputFolder: boolean;
  supportStatusFileWithOutputFolder: boolean;
  supportSyncNowRequest: boolean;
}
export class FarmRegistrationModel {
  public farmMode: FarmMode;
  public operation: TrialOperation;
  public farmDetails: IFarmDetailsResponse;
  public herdManagement: IFarmThirdPartyResponse;
  public farmTopology: IFarmTopology;
  public createAccount: ICreateAccountResponse;
  public error: IError;

  constructor() {
    this.clearEntireRegistrationForm();
  }

  public clearFarmDetails() {
    this.farmDetails = {
      address: undefined,
      city: undefined,
      farmId: undefined,
      farmName: undefined,
      link: undefined,
      populationPlan: undefined,
      timeZone: undefined,
      farmHousing: undefined,
      country: undefined,
      countryCode: undefined,
      bizModelMode: undefined,
      applicationBundle: undefined,
      metadata: undefined,
      type: undefined,
      orderedPopulationPlan: undefined,
      farmType: undefined
    }
  }

  public clearHerdManagement() {
    this.herdManagement = {
      deviceType: undefined,
      thirdParty: undefined,
      registrationKey: undefined,
      type: undefined,
      linkedStatus: undefined,
      validationMode: undefined,
      CRVRegistrationKey: undefined,
      inputFolder: undefined,
      outputFolder: undefined
    };
  }

  public clearFarmTopology() {
    this.farmTopology = {
      rfChannel: undefined,
      total: undefined,
      totalAntenna: undefined,
      totalCollector: undefined,
      totalStandalonePC: undefined,
      hardware: undefined,
      type: undefined
    }
  }

  public clearEntireRegistrationForm() {
    this.clearFarmDetails();
    this.clearHerdManagement();
    this.clearFarmTopology();
    this.error = undefined;
  }
}
export interface IFarmDetails {
  farmId: string;
  link: boolean;
  farmName: string;
  address: string;
  city: string;
  selectedFarmHousing: DropdownListItem;
  selectedCountry: DropdownListItem;
  selectedTimeZone: DropdownListItem;
  populationPlan: IPopulationPlan;
}
export interface IError {
  fieldName: string;
  error?: string;
}
export class Failure {
  constructor(public errorKey: string, public fieldName: string) {
  }
}
export interface IHerdManagement {
  // tslint:disable-next-line:no-any
  thirdParty: any;
  // tslint:disable-next-line:no-any
  linkedType: any;
  // tslint:disable-next-line:no-any
  validationMode: any;
  // tslint:disable-next-line:no-any
  thirdParties: any[];
  originalThirdPartiesResponse: IThirdPartyItem[];
  // tslint:disable-next-line:no-any
  inputFolder?: any;
  // tslint:disable-next-line:no-any
  outputFolder?: any;
  // tslint:disable-next-line:no-any
  deviceRegistrationKey?: any;
  // tslint:disable-next-line:no-any
  CRVRegistrationKey?: any;
}
export interface IFarmTopology {
  rfChannel: number;
  total: number;
  totalAntenna: number;
  totalCollector: number;
  totalStandalonePC: number;
  hardware: ITopologyHardware[];
  type: string;
}
export interface IRegistrationKeyResponse {
  deviceType: string;
  // tslint:disable-next-line:no-any
  failures: any[];
  field: string;
  registrationKey: string;
  succeeded: boolean;
}
export interface ICRVRegistrationKeyRequest {
  deviceType: string;
  registrationKey: string;
}
export interface ICRVRegistrationKeyResponse {
  deviceType: string,
  // tslint:disable-next-line:no-any
  failures: any[];
  field: string;
  registrationKey: string;
  succeeded: boolean;
}
export interface IFarmTopologyResponse {
  hardware: ITopologyHardware[];
  rfChannel: number;
  total: number;
  totalAntenna: number;
  totalCollector: number;
  totalStandalonePC: number;
  type: string;
}
export interface ITopologyHardware {
  ddVersion: string;
  hardwareDesc: string;
  hardwareType: string;
  hardwareVersion: string;
  id: number;
  ip: string;
  isDefault: boolean;
  mac: string;
  serialNumber: string;
  isEnable?: boolean;
}
export interface IPostDetailsResponse {
  // tslint:disable-next-line:no-any
  failures: any[];
  field: string;
  succeeded: boolean;
}
export interface IRegistrationPostDetailsResponse extends IPostDetailsResponse {
  correlationId: string;
}
export interface ICreateAccountResponse {
  account: IRegistrationAccount;
  language: IRegistrationLanguage[];
}
export interface IRegistrationAccount {
  confirmPassword: string;
  customerName: string;
  email: string;
  eula: IEula;
  language: string;
  password: string;
  phone: string;
  questionOne: ISecurityQuestionObject;
  questionTwo: ISecurityQuestionObject;
  type: string;
  username: string;
}
export interface IRegistrationLanguage {
  key: string;
  value: string;
}
export interface IEula {
  approved: boolean;
  bizModelMode: BizModel;
  farmMode: FarmMode;
  name: string;
  type: string;
  version: string;
}

export enum RegistrationServiceProjection {
  createAccount = 'createAccount',
  topology = 'topology',
  thirdParty = 'thirdParty',
  farmDetails = 'farmDetails',
  farmVersion = 'farmVersion',
}

export interface IPostTrialModeRequest {
  type: string;
  farmMode?: FarmMode
}
