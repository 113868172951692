import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AnimalsService} from '../../../services/animals/animals.service';
import {HttpResponseResult} from '../../../services/rest-api/rest-api.service';
import {
  AnimalEventRow,
  AnimalsEventsResult,
  EventTypeEnum,
} from '../../../services/animals/model/animal-events';
import {DateTimeFormatEnum, EpochDateTimePipe} from '../../../common/pipes/epoch-date-time.pipe';
import {DataGridComponent} from '../../../common/components/data-grid/data-grid.component';
import {DataGridQuery, DataGridQuerySortDetails} from '../../../common/components/data-grid/model/data-grid-query';
import {DataGridColumnMap} from '../../../common/components/data-grid/model/data-grid-column-map';
import {WINDOW} from '../../../utils/window-utils';
import {AddEventPhase, AnimalEventsService} from '../../../services/animals/animal-events.service';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';
import {FarmMode} from '../../../services/config/model/server-config';
import {ApplicationBundleRoutingMode, RoutingService} from '../../../services/routing/routing.service';
import {ActivatedRoute} from '@angular/router';
import {ConfigService} from '../../../services/config/config.service';
import {
  ExportedColumnDescription,
  IPrintExportDataSource
} from '../../../common/components/grid-actions-dropdown/grid-actions-dropdown.component';
import {AnimalCardTabSection} from '../../card-popup/animal-card/animal-card-model';
import {IDataGridViewContext} from '../../../services/model/common-model';
import {RowActionsPopupItem} from '../../../common/components/row-actions-popup/row-actions-popup.component';
import {SystemService} from '../../../services/system/system.service';
import {OperationType} from '../../../services/search/model/search.model';
import {SuccessDialogService} from '../../../common/components/dialogs/success-dialog/success-dialog.service';
import {EntityType, SearchService} from '../../../services/search/search.service';
import {IconCssClass} from '../../../services/reports/model/report.interface';
import {TranslationService} from '../../../services/translations/translation.service';
import {LoadingIconService} from '../../../services/loading-icon/loading-icon.service';
import {GridViewState} from '../../../services/ui/view-state/grid-row/grid-view-state';
import {CardViewStateService} from '../../../services/ui/view-state/card-view-state.service';
import {FarmRole} from '../../../services/auth/model/farm-role.enum';
import * as moment from 'moment';
import {ManageCreateEventComponent} from "./create-event/manage-create-event.component";

enum ManageEventsRowActionType {
  delete = 'delete',
  stop = 'stop'
}

@Component({
  selector: 'manage-events',
  templateUrl: './manage-events.component.html',
  styleUrls: ['./manage-events.component.scss']
})
export class ManageEventsComponent implements OnInit, OnDestroy, IDataGridViewContext, IPrintExportDataSource {

  @ViewChild('sortedGrid')
  public sortedGrid: DataGridComponent;

  @ViewChild('manageCreateEvent')
  private manageCreateEventComponent: ManageCreateEventComponent;

  private batchEventPhaseSubscription : Subscription;

  private eventsListChangedSubscription : Subscription;

  private queryParamsSubscription : Subscription;

  public routingMode: ApplicationBundleRoutingMode;

  public applicationBundleRoutingModes = ApplicationBundleRoutingMode;

  public DateTimeFormatEnum = DateTimeFormatEnum;

  public eventsSortedGridQuery: DataGridQuery;

  public readonly columnsMappings: Map<string, DataGridColumnMap> = new Map();

  public readonly rowActionsMap: Map<AnimalEventRow, RowActionsPopupItem[]> = new Map<AnimalEventRow, RowActionsPopupItem[]>();

  public eventsResult: AnimalsEventsResult;

  public EventTypeEnum = EventTypeEnum;

  public isAddEventOpen: boolean = false;

  private gridViewState : GridViewState = new GridViewState();

  constructor(public animalsService: AnimalsService,
              private translationService: TranslationService,
              private epochDateTimePipe: EpochDateTimePipe,
              @Inject(WINDOW) private window: Window,
              private systemService: SystemService,
              private cardViewStateService: CardViewStateService,
              private route: ActivatedRoute,
              public configService: ConfigService,
              private routingService: RoutingService,
              private readonly searchService: SearchService,
              private readonly animalEventsService: AnimalEventsService,
              private readonly loadingIconService: LoadingIconService,
              private readonly successDialogService: SuccessDialogService) {

    this.columnsMappings.set('animalName',
    {
      enableSorting: true,
      flexGrow: 0.5
    });
    this.columnsMappings.set('startDateTime',
    {
      enableSorting: true,
      flexGrow: 1
    });
    this.columnsMappings.set('type',
    {
      enableSorting: true,
      flexGrow: 1.2
    });
    this.columnsMappings.set('daysInLactation',
    {
      enableSorting: true,
      flexGrow: 0.8
    });
    this.columnsMappings.set('description',
    {
      enableSorting: false,
      flexGrow: 1.7
    });
    this.columnsMappings.set('actions',
    {
      enableSorting: false,
      flexGrow: 0.2
    });
  }

  public onRowActionsOpen() {
    this.gridViewState.clearExpandedRows();
    this.sortedGrid.refreshRowsHeight();
  }

  public async handleRowAction(action:string, row:AnimalEventRow) {
    switch (action) {
      case ManageEventsRowActionType.delete: {
        await this.deleteAnimalEvent(row);
        break;
      }
      case ManageEventsRowActionType.stop: {
        await this.stopAnimalEvent(row);
        break;
      }
    }
  }

  private async deleteAnimalEvent(row: AnimalEventRow) {
    let response = await this.animalEventsService.deleteAnimalEvent(row.animalId, row.eventId);
    if(response.status == 204) {
      this.successDialogService.show();
    }
  }

  private async stopAnimalEvent(row: AnimalEventRow) {
    row.endDate = moment().unix();
    let response = await this.animalEventsService.updateAnimalEvent(row.animalId, row.eventId, row);
    if(response.status == 200) {
      this.successDialogService.show();
    }
  }

  public async ngOnInit(): Promise<void> {

    this.queryParamsSubscription = this.route.queryParams.subscribe(async queryParams => {
      if (this.configService.serverConfig.farmMode === FarmMode.Dairy) {
        this.routingMode = ApplicationBundleRoutingMode.Breeding;
      } else {
        this.routingMode = this.routingService.getApplicationBundleRoutingMode(queryParams);
      }
      this.setDefaultEventsSortedGridQuery();
      await this.getEvents();
    });



    this.batchEventPhaseSubscription = this.animalEventsService.addEventPhaseSubject.subscribe(async value => {
      if(value == AddEventPhase.completed) {
        this.sortedGrid.reloadGrid();
      }
    });

    this.eventsListChangedSubscription = this.animalEventsService.eventsListChanged.subscribe(value => {
      this.sortedGrid.reloadGrid();
    });
  }

  public ngOnDestroy(): void {
    this.queryParamsSubscription.unsubscribe();
    this.batchEventPhaseSubscription.unsubscribe();
    this.eventsListChangedSubscription.unsubscribe();
  }

  public getGridViewState(): GridViewState {
    return this.gridViewState;
  }

  public async reloadDefault() {
    this.rowActionsMap.clear();
    await this.getEvents();
  }

  public getEventNameClass(event: string) {
    let prefix = 'event-icon-';
    if (event === 'positivePregnancyCheck' || event === 'negativePregnancyCheck' || event === 'uncertainPregnancyCheck') {
      prefix += 'pregnancy-check';
    } else if (event === 'abortionSameLactation' || event === 'abortionNewLactation') {
      prefix += 'abortion';
    } else if (event === 'removeScrTag' || event === 'removeRfIdTag') {
      prefix += 'remove-tag';
    } else if (event === 'changeScrTag' || event === 'changeRfIdTag') {
      prefix += 'change-tag';
    } else if (event === 'assignScrTag' || event === 'assignRfIdTag') {
      prefix += 'assign-tag';
    } else if (event === 'tagSwUpdate') {
      prefix += 'tag-sw-update';
    } else if (event === 'weanedCalf') {
      prefix += 'weaned-calf';
    } else {
      prefix += event;
    }
    return prefix;
  }


  public get exportedColumns(): ExportedColumnDescription[] {
    return [{
      name: 'animalName',
      widthCss: '65px'
    },{
      name: 'startDateTime',
      widthCss: '20%'
    },{
      name: 'type',
      widthCss: '20%'
    },{
      name: 'daysInLactation',
      widthCss: '15%'
    },{
      name: 'description',
      widthCss: 'auto'
    }]
  }

  // tslint:disable-next-line:no-any
  public async getDataToExport(): Promise<any[]> {
    let animalEventsResult = null;
    if (this.routingMode === ApplicationBundleRoutingMode.Finishing) {
      animalEventsResult = await this.animalEventsService.getAnimalsFinishingEvents(0, 0 ,this.eventsSortedGridQuery.sortedColumn);
      if (animalEventsResult.responseBody) {
        return animalEventsResult.responseBody.result;
      }
    } else {
      animalEventsResult = await this.animalEventsService.getAnimalsEvents(0,0,this.eventsSortedGridQuery.sortedColumn);
      if (animalEventsResult.responseBody) {
        return animalEventsResult.responseBody.result;
      }
    }
    return null;
  }

  public transformColumnToPresentable(column: string): string {
    switch (column) {
      case 'animalName':
        return this.translationService.translate('MANAGE.EVENTS.ID');
      case 'startDateTime':
        return this.translationService.translate('MANAGE.EVENTS.DATE');
      case 'type':
        return this.translationService.translate('MANAGE.EVENTS.EVENT_NAME');
      case 'daysInLactation':
        return this.translationService.translate('MANAGE.EVENTS.DIM');
      case 'description':
        return this.translationService.translate('MANAGE.EVENTS.DESCRIPTION');
    }
  }

  // tslint:disable-next-line:no-any
  public transformFieldToPresentable(column: string, row: any): string {
    switch (column) {
      case 'animalName':
        return row[column];
      case 'startDateTime':
        return this.epochDateTimePipe.transform(row.startDateTime, DateTimeFormatEnum.DateTime);
      case 'type':
        return this.translationService.translate(`ANIMAL.EVENTS.EVENT_TYPE.${row.type}`);
      case 'daysInLactation':
        return row.daysInLactation || row.ageInDays;
      case 'description':
        return this.animalEventsService.getAnimalEventDescription(row);
    }
  }

  private async getEvents(getEventsFromScrolling?: boolean) {
    let animalsEventsResult: HttpResponseResult<AnimalsEventsResult>;
    if (!getEventsFromScrolling) {
      this.loadingIconService.show();
    }
    if (this.routingMode === ApplicationBundleRoutingMode.Finishing) {
      animalsEventsResult = await this.animalEventsService.getAnimalsFinishingEvents(this.eventsSortedGridQuery.page,
        this.eventsSortedGridQuery.limit,
        this.eventsSortedGridQuery.sortedColumn);
    } else {
      animalsEventsResult = await this.animalEventsService.getAnimalsEvents(this.eventsSortedGridQuery.page,
        this.eventsSortedGridQuery.limit,
        this.eventsSortedGridQuery.sortedColumn);
    }

    if(animalsEventsResult.responseBody) {
      // if events exists in events list
      this.eventsResult = animalsEventsResult.responseBody;
      this.eventsResult.result.forEach(value => {
        this.rowActionsMap.set(value, this.getRowActions(value));
      });
      if (!getEventsFromScrolling) {
        this.loadingIconService.hide();
      }
    } else {
      // if no events exists in events list
      this.eventsResult = {
        limit: 0,
        offset: 0,
        total: 0,
        result: []
      };
      if (!getEventsFromScrolling) {
        this.loadingIconService.hide();
      }
    }
  }

  private getRowActions(row:AnimalEventRow) : RowActionsPopupItem[] {
    let rowActions: RowActionsPopupItem[] = [];
    if (this.configService.serverConfig.user.farmRole != FarmRole.ExternalUser) {
      if(row.deletable) {
        rowActions.push({action: ManageEventsRowActionType.delete,
          displayValue: 'ANIMAL.EVENTS.TABLE.REMOVE_EVENT', iconCssClass: IconCssClass.remove,
          shIdRowIdentifier: row.eventId.toString()});
      }
      if (row.active && row.type == EventTypeEnum.DoNotBreed) {
        rowActions.push({
          action: ManageEventsRowActionType.stop,
          displayValue: 'ANIMAL.EVENTS.TABLE.STOP_EVENT', iconCssClass: IconCssClass.disable,
          shIdRowIdentifier: row.eventId.toString()
        });
      }
    }

    return rowActions;
  }

  private setDefaultEventsSortedGridQuery() {
    this.eventsSortedGridQuery = new DataGridQuery();
    this.eventsSortedGridQuery.offset = 0;
    this.eventsSortedGridQuery.limit = 100;
    this.eventsSortedGridQuery.sortDetails = new DataGridQuerySortDetails();
    this.eventsSortedGridQuery.sortDetails.column = 'startDateTime';
  }

  public toggleExpandRow(row) {
    this.sortedGrid.refreshRowsHeight();
  }

  public isRowExpanded(row) : boolean {
    if(this.sortedGrid == null){
      return false;
    }
    return this.gridViewState.getRowExpandedState(row) != null;
  }

  public hasDetails(row) {
     let elm  = [
      'abortionNewLactation',
      'abortionSameLactation',
      //'systemHeat',
      'removeScrTag',
      'removeRdIfTag',
      'assignScrTag',
      'assignRfIdTag',
      'changeScrTag',
      'changeRfIdTag',
      'breeding',
      'calving',
      'changeGroup',
      'positivePregnancyCheck',
      'distress'
    ].indexOf(row.type) !== -1;


    if (elm && (
      _.has(row.tag, 'tagType') || _.has(row, 'sire') || _.has(row,'interval') || _.has(row,'daysInPregnancy') || _.has(row,'calvingEase') || _.has(row,'destinationGroupName') || _.has(row,'newborns') || _.has(row,'effectiveInseminationDate') ||  _.has(row,'effectiveInseminationDate') || _.has(row,'embryoAge') || _.has(row,'alertType') || _.has(row,'oldVersion') )) {

      if (row.effectiveInseminationDate === null && _.has(row,'effectiveInseminationDate') && elm && row.type === 'positivePregnancyCheck' ) {
        return false;
      } else if  ( (!row.sire || !row.interval) && elm && row.type === 'breeding') {
        return false;
      }else {
        return true;
      }




    } else {
      return  false;
    }
  }

  private needToShowAgeInDaysOrDaysInLactation(row: AnimalEventRow) {
    if (this.routingMode === ApplicationBundleRoutingMode.Finishing) {
      return row.ageInDays == null ? 'N/A' : row.ageInDays;
    } else {
      return row.daysInLactation;
    }
  }

  public toggleAddEvent(isOpen: boolean) {
    this.isAddEventOpen = isOpen;
    this.windowResizeMade();
  }

  public async openAnimalCard(animalEvent: AnimalEventRow) {
    let operationType:OperationType;
    operationType = await this.searchService.findOperationTypeByEntityId(animalEvent.animalId, EntityType.cow);
    if (this.manageCreateEventComponent.isOpen) {
      this.manageCreateEventComponent.toggleOpen();
    }
    this.cardViewStateService.openAnimalCard(animalEvent.animalId,
                                             operationType,
                                            null,
                                            null,
                                             AnimalCardTabSection.Events);
  }

  public windowResizeMade() {
    setTimeout(() => {
      this.window.dispatchEvent(new Event('resize'));
    });
  }

  public onAdditionalActionClick(actionKey: string) {
  }

  public get exportTitle(): string {
    return 'Events';
  }

  public get filePartName(): string {
    return 'events';
  }
}
