import {SchedulerShiftModel} from './scheduler-model';

export class ShiftModel {
  public startTime: number;
  public endTime: number;
  public name: string;
  public shiftId: number;
  public isCurrent: boolean;
}

export interface IShiftHistory {
  id: number;
  startDateTime: number;
  endDateTime: number;
  isCurrent: boolean;
  shiftConfigId: number;
  name: string;
}

export interface IShiftsHistoryResponse {
  date: number;
  shifts: IShiftHistory[]
}

export interface IShiftsResponse {
  shifts: ShiftModel[];
}

export class ConcreteShiftDetails {
  public shift: ShiftModel;
  public isCurrent: boolean;
  public startDateTime: number;
  public endDateTime: number;
}
