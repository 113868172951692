<confirm-dialog [primaryText]="confirmDeleteUserDialogPrimaryText"
                [headerText]="userToDelete?.username"
                [isOpen]="isOpen"
                [iconClass]="ConfirmDialogIcons.farmerIcon"
                [modalHeight]="279"
                [containerShId]="'confirmUserDeleteDialogComponent'"
                [leftButtonShId]="'MANAGE.SETTINGS.USERS.USERS_LIST.NO, MANAGE.SETTINGS.USERS.USERS_LIST.KEEP_THIS_USER'"
                [rightButtonShId]="'MANAGE.SETTINGS.USERS.USERS_LIST.YES'"
                [leftButtonText]="confirmDeleteUserDialogLeftButtonText"
                [rightButtonText]="'MANAGE.SETTINGS.USERS.USERS_LIST.YES' | translate"
                (onCloseClick)="onCloseDialog()"
                (onLeftButtonClick)="onCloseDialog()"
                (onRightButtonClick)="onDeleteButtonClicked()">
</confirm-dialog>
