import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ClickPressEventsService} from '../../../../services/click-press-events/click-press-events.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'modal-dialog-template',
  templateUrl: './modal-dialog-template.component.html',
  styleUrls: ['./modal-dialog-template.component.scss']
})
export class ModalDialogTemplateComponent implements OnInit, OnDestroy {

  @Input()
  public isOpen: boolean = false;

  @Input()
  public modalWidth?: number | string = 555;

  @Input()
  public modalHeight?: number | string = 555;

  @Input()
  public overlayColor?: string = '';

  @Output()
  public outsideModalClick: EventEmitter<void> = new EventEmitter();

  @Output()
  public onEscapeButtonClick: EventEmitter<void> = new EventEmitter();

  @Output()
  public onEnterButtonClick: EventEmitter<void> = new EventEmitter();

  private subscriptions: Subscription = new Subscription();

  constructor(private element: ElementRef,
              private readonly clickPressEventsService: ClickPressEventsService) { }

  public ngOnInit() {
    document.body.appendChild(this.element.nativeElement);

    this.subscriptions.add(
      this.clickPressEventsService.keyupEnter.subscribe(() => this.enterClick())
    );
    this.subscriptions.add(
      this.clickPressEventsService.keydownEscape.subscribe(() => this.escapeClick())
    )
  }

  public ngOnDestroy(): void {
    if (this.element.nativeElement && this.element.nativeElement.parentNode == document.body) {
      document.body.removeChild(this.element.nativeElement);
    }
    this.subscriptions.unsubscribe();
  }

  public escapeClick() {
    if (this.isOpen) {
      this.onEscapeButtonClick.emit();
    }
  }

  public enterClick() {
    if (this.isOpen) {
      this.onEnterButtonClick.emit();
    }
  }

  public get width(): string {
    let w: string;
    if (typeof this.modalWidth === 'string') {
      w = this.modalWidth;
    }
    if (typeof this.modalWidth === 'number') {
      w = this.modalWidth + 'px';
    }
    return w;
  }

  public get height(): string {
    if(this.modalHeight === 'auto') {
      return this.modalHeight;
    }
    let h: string;
    if (typeof this.modalHeight === 'string') {
      h = this.modalHeight;
    }
    if (typeof this.modalHeight === 'number') {
      h = this.modalHeight + 'px';
    }
    return h;
  }

  public onOutsideModalClick() {
    this.outsideModalClick.emit();
  }

}
