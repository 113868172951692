import {Component} from '@angular/core';
import {SEARCH_TEXT_TRANSITIONS} from '../../../common/animations/animations';
import {EntityType, SearchResult, SearchService} from '../../../services/search/search.service';
import {OperationType, SearchEntry} from '../../../services/search/model/search.model';
import {GoogleAnalyticsService} from '../../../services/google-analytics/google-analytics.service';
import {AnimalsService} from '../../../services/animals/animals.service';
import {GroupsService} from '../../../services/groups/groups.service';
import {GroupCardTabSection} from '../../card-popup/group-card/group-card-model';
import {AnimalCardTabSection} from '../../card-popup/animal-card/animal-card-model';
import {SystemService} from '../../../services/system/system.service';
import {BranchCardTabSection} from '../../../services/branches/model/branch.interface';
import {ConfigService} from '../../../services/config/config.service';
import {FinishingApplicationBundle} from '../../../services/config/model/server-config';
import {TranslationService} from '../../../services/translations/translation.service';
import {CardViewStateService} from '../../../services/ui/view-state/card-view-state.service';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [SEARCH_TEXT_TRANSITIONS]
})
export class SearchComponent {

  public isSearchOpen: boolean;

  public searchQuery: string;

  public EntityType = EntityType;

  public lastSearchResult: SearchResult;

  private focusedSearchEntry: SearchEntry = null;

  private isSupportBranches: boolean = false;

  constructor(private readonly searchService:SearchService,
              private readonly systemService: SystemService,
              private readonly animalsService:AnimalsService,
              private readonly configService: ConfigService,
              private readonly translationService: TranslationService,
              private readonly groupsService:GroupsService,
              private readonly cardViewStateService: CardViewStateService,
              private readonly googleAnalyticsService: GoogleAnalyticsService) {
  }

  public ngAfterViewInit() {
    this.isSupportBranches = (this.configService.serverConfig.bizModeParameters.finishingApplicationBundle === FinishingApplicationBundle.Finishing);
  }

  public toggleSearch() {
    this.isSearchOpen = !this.isSearchOpen;
  }

  public closeSearch() {
    this.searchQuery = null;
    this.isSearchOpen = false;
  }

  // tslint:disable-next-line:no-any
  public onSearchQueryChange(event: any) {
    if (event.key === EventKey.ArrowLeft || event.key === EventKey.ArrowRight) {
      return;
    }
    if (this.lastSearchResult != null &&
       (this.lastSearchResult.matchingAnimals.length > 0 || this.lastSearchResult.matchingGroups.length > 0 || this.lastSearchResult.matchingBranches.length > 0) &&
        (event.key === EventKey.ArrowDown || event.key === EventKey.ArrowUp || event.key === EventKey.Enter)) {
      switch (event.key) {
        case EventKey.ArrowDown: {
          this.handleArrowDown();
          break;
        }
        case EventKey.ArrowUp: {
          this.handleArrowUp();
          break;
        }
        case EventKey.Enter: {
          this.handleEnter();
          break;
        }
      }
    } else {
      this.isSupportBranches = (this.configService.serverConfig.bizModeParameters.finishingApplicationBundle === FinishingApplicationBundle.Finishing);
      this.lastSearchResult = this.searchService.search(this.searchQuery, true, this.isSupportBranches);

      // focus on the first search entity
      if (this.lastSearchResult.matchingAnimals.length > 0) {
        this.focusedSearchEntry = this.lastSearchResult.matchingAnimals[0];
      } else if (this.lastSearchResult.matchingGroups.length > 0) {
        this.focusedSearchEntry = this.lastSearchResult.matchingGroups[0];
      } else if (this.lastSearchResult.matchingBranches.length > 0) {
        this.focusedSearchEntry = this.lastSearchResult.matchingBranches[0];
      } else {
        this.focusedSearchEntry = null;
      }
    }
  }

  private handleArrowDown() {
    // if already focused on search entry
    if (this.focusedSearchEntry != null) {
      let focusedSearchEntryIndex: number = null;
      focusedSearchEntryIndex = this.lastSearchResult.matchingAnimals.findIndex(
        animal => animal.entityId === this.focusedSearchEntry.entityId && animal.entityType === this.focusedSearchEntry.entityType);
      // check if currently focused on item in animals list
      if (focusedSearchEntryIndex >= 0) {
        this.handleAnimalIndexIncrement(focusedSearchEntryIndex);
      } else {
        focusedSearchEntryIndex = this.lastSearchResult.matchingGroups.findIndex(
          group => group.entityId === this.focusedSearchEntry.entityId && group.entityType === this.focusedSearchEntry.entityType);
        // check if currently focused on item in groups list
        if (focusedSearchEntryIndex >= 0) {
          this.handleGroupIndexIncrement(focusedSearchEntryIndex);
        } else {
          focusedSearchEntryIndex = this.lastSearchResult.matchingBranches.findIndex(
            branch => branch.entityId === this.focusedSearchEntry.entityId && branch.entityType === this.focusedSearchEntry.entityType);
          // check if currently focused on item in branches list
          if (focusedSearchEntryIndex >= 0) {
            this.handleBranchIndexIncrement(focusedSearchEntryIndex);
          }
        }
      }
    } else {
      // not focused on search entry yet
      if (this.lastSearchResult.matchingAnimals.length > 0) {
        this.focusedSearchEntry = this.lastSearchResult.matchingAnimals[0];
      } else if (this.lastSearchResult.matchingGroups.length > 0) {
        this.focusedSearchEntry = this.lastSearchResult.matchingGroups[0];
      } else if (this.lastSearchResult.matchingBranches.length > 0) {
        this.focusedSearchEntry = this.lastSearchResult.matchingBranches[0];
      }
    }
  }

  private handleAnimalIndexIncrement(animalIndex: number) {
    // index is in animal list
    if (animalIndex === (this.lastSearchResult.matchingAnimals.length - 1)) {
      if (this.lastSearchResult.matchingGroups.length > 0) {
        // if there is also search results of groups - focus on the first option
        this.focusedSearchEntry = this.lastSearchResult.matchingGroups[0];
      } else if (this.lastSearchResult.matchingBranches.length > 0) {
        // if there is also search results of branches - focus on the first option
        this.focusedSearchEntry = this.lastSearchResult.matchingBranches[0];
      } else {
        // focus on the first option in the animal list
        this.focusedSearchEntry = this.lastSearchResult.matchingAnimals[0];
      }
    } else {
      this.focusedSearchEntry = this.lastSearchResult.matchingAnimals[animalIndex + 1];
    }
  }

  private handleGroupIndexIncrement(groupIndex: number) {
    // index is in group list
    if (groupIndex === (this.lastSearchResult.matchingGroups.length - 1)) {
      if (this.lastSearchResult.matchingBranches.length > 0) {
        // if there is also search results of branches - focus on the first option
        this.focusedSearchEntry = this.lastSearchResult.matchingBranches[0];
      } else if (this.lastSearchResult.matchingAnimals.length > 0) {
        // if there is also search results of animals - focus on the first option
        this.focusedSearchEntry = this.lastSearchResult.matchingAnimals[0];
      } else {
        // focus on the first group option
        this.focusedSearchEntry = this.lastSearchResult.matchingGroups[0];
      }
    } else {
      this.focusedSearchEntry = this.lastSearchResult.matchingGroups[groupIndex + 1];
    }
  }

  private handleBranchIndexIncrement(branchIndex: number) {
    // index is in branch list
    if (branchIndex === (this.lastSearchResult.matchingBranches.length - 1)) {
      if (this.lastSearchResult.matchingAnimals.length > 0) {
        // if there is also search results of animals - focus on the first option
        this.focusedSearchEntry = this.lastSearchResult.matchingAnimals[0];
      } else if (this.lastSearchResult.matchingGroups.length > 0) {
        // if there is also search results of groups - focus on the first option
        this.focusedSearchEntry = this.lastSearchResult.matchingGroups[0];
      } else {
        // focus on the first branch option
        this.focusedSearchEntry = this.lastSearchResult.matchingBranches[0];
      }
    } else {
      this.focusedSearchEntry = this.lastSearchResult.matchingBranches[branchIndex + 1];
    }
  }

  private handleArrowUp() {
    // check if already focused on search entry
    if (this.focusedSearchEntry != null) {
      // if already focused on search entry
      let focusedSearchEntryIndex: number = null;
      focusedSearchEntryIndex = this.lastSearchResult.matchingBranches.findIndex(
        branch => branch.entityId === this.focusedSearchEntry.entityId && branch.entityType === this.focusedSearchEntry.entityType);
      // check if currently focused on item in branches list
      if (focusedSearchEntryIndex >= 0) {
        this.handleBranchIndexDecrement(focusedSearchEntryIndex);
      } else {
        focusedSearchEntryIndex = this.lastSearchResult.matchingGroups.findIndex(
          group => group.entityId === this.focusedSearchEntry.entityId && group.entityType === this.focusedSearchEntry.entityType);
        // check if currently focused on item in groups list
        if (focusedSearchEntryIndex >= 0) {
          this.handleGroupIndexDecrement(focusedSearchEntryIndex);
        } else {
          focusedSearchEntryIndex = this.lastSearchResult.matchingAnimals.findIndex(
            animal => animal.entityId === this.focusedSearchEntry.entityId && animal.entityType === this.focusedSearchEntry.entityType);
          // check if currently focused on item in animals list
          if (focusedSearchEntryIndex >= 0) {
            this.handleAnimalIndexDecrement(focusedSearchEntryIndex);
          }
        }
      }
    } else {
      // if not focused on search entry yet
      if (this.lastSearchResult.matchingGroups.length > 0) {
        this.focusedSearchEntry = this.lastSearchResult.matchingGroups[this.lastSearchResult.matchingGroups.length - 1];
      } else if (this.lastSearchResult.matchingAnimals.length > 0) {
        this.focusedSearchEntry = this.lastSearchResult.matchingAnimals[this.lastSearchResult.matchingAnimals.length - 1];
      } else if (this.lastSearchResult.matchingBranches.length > 0) {
        this.focusedSearchEntry = this.lastSearchResult.matchingBranches[this.lastSearchResult.matchingBranches.length - 1];
      }
    }
  }

  private handleAnimalIndexDecrement(animalIndex: number) {
    if (animalIndex === 0) {
      // if currently focused item is the first at matching animals list - check if matching branches list exists and focus on the last search entity
      if (this.lastSearchResult.matchingBranches.length > 0) {
        // focus on the last matching branch entity
        this.focusedSearchEntry = this.lastSearchResult.matchingBranches[this.lastSearchResult.matchingBranches.length - 1];
      } else if (this.lastSearchResult.matchingGroups.length > 0) {
        // matching branches is empty - focus on the last matching group search entity
        this.focusedSearchEntry = this.lastSearchResult.matchingGroups[this.lastSearchResult.matchingGroups.length - 1];
      } else {
        // if groups is empty as well - focus on the last matching animal search entity
        this.focusedSearchEntry = this.lastSearchResult.matchingAnimals[this.lastSearchResult.matchingAnimals.length - 1];
      }
    } else {
      this.focusedSearchEntry = this.lastSearchResult.matchingAnimals[animalIndex - 1];
    }
  }

  private handleGroupIndexDecrement(groupIndex: number) {
    // if currently focused item is the first at matching group list - check if matching animals list exists and focus on the last search entity
    if (groupIndex === 0) {
      if (this.lastSearchResult.matchingAnimals.length > 0) {
        // focus on the last matching animal entity
        this.focusedSearchEntry = this.lastSearchResult.matchingAnimals[this.lastSearchResult.matchingAnimals.length - 1];
      } else if (this.lastSearchResult.matchingBranches.length > 0) {
        // matching animals is empty - focus on the last matching branch search entity
        this.focusedSearchEntry = this.lastSearchResult.matchingBranches[this.lastSearchResult.matchingBranches.length - 1];
      } else {
        // branch list is empty - focus on the last matching group entity
        this.focusedSearchEntry = this.lastSearchResult.matchingGroups[this.lastSearchResult.matchingGroups.length - 1];
      }
    } else {
      this.focusedSearchEntry = this.lastSearchResult.matchingGroups[groupIndex - 1];
    }
  }

  private handleBranchIndexDecrement(branchIndex: number) {
    // if currently focused item is the first at matching branches list - check if matching groups list exists and focus on the last search entity
    if (branchIndex === 0) {
      if (this.lastSearchResult.matchingGroups.length > 0) {
        // focus on the last matching groups entity
        this.focusedSearchEntry = this.lastSearchResult.matchingGroups[this.lastSearchResult.matchingGroups.length - 1];
      } else if (this.lastSearchResult.matchingAnimals.length > 0) {
        // matching groups is empty - focus on the last matching animals search entity
        this.focusedSearchEntry = this.lastSearchResult.matchingAnimals[this.lastSearchResult.matchingAnimals.length - 1];
      } else {
        // matching animals is empty - focus on the last matching branchs search entity
        this.focusedSearchEntry = this.lastSearchResult.matchingBranches[this.lastSearchResult.matchingBranches.length - 1];
      }
    } else {
      this.focusedSearchEntry = this.lastSearchResult.matchingBranches[branchIndex - 1];
    }
  }

  private handleEnter() {
    // check if focused on search entry
    if (this.focusedSearchEntry) {
      // according to the search entry type - open animal card / group card / branch card
      if (this.focusedSearchEntry.entityType === EntityType.cow || this.focusedSearchEntry.entityType === EntityType.male) {
        this.openAnimalCard(this.focusedSearchEntry.entityId, this.focusedSearchEntry.operationType);
      } else if (this.focusedSearchEntry.entityType === EntityType.group) {
        this.openGroupCard(this.focusedSearchEntry.entityId, this.focusedSearchEntry.operationType);
      } else if (this.focusedSearchEntry.entityType === EntityType.branch) {
        this.openBranchCard(this.focusedSearchEntry.entityId, this.focusedSearchEntry.operationType);
      }
    }
  }

  public get isPopupOpen() : boolean {
    return this.isSearchOpen &&
      this.searchQuery != null &&
      this.searchQuery.trim().length > 0;
  }

  public get hasMatchingResults() : boolean {
    return this.lastSearchResult != null &&
      (this.lastSearchResult.matchingAnimals.length > 0 ||
      this.lastSearchResult.matchingGroups.length > 0 ||
      this.lastSearchResult.matchingBranches.length > 0);
  }

  public openAnimalCard(animalId:number, operationType:OperationType) {
    this.googleAnalyticsService.send('AnimalSearch', 'Click', 'Go to Animal');
    this.closeSearch();
    this.cardViewStateService.openAnimalCard(animalId,
                                             operationType,
                                             null,
                                             null,
                                              AnimalCardTabSection.Events);
  }

  public openGroupCard(groupId:number, operationType:OperationType) {
    this.googleAnalyticsService.send('AnimalSearch', 'Click', 'Go to Group');
    this.closeSearch();
    this.cardViewStateService.openGroupCard(groupId,
                                            operationType,
                                           null,
                                           null,
                                            GroupCardTabSection.Details);
  }

  public openBranchCard(branchId: number, operationType: OperationType) {
    this.googleAnalyticsService.send('AnimalSearch', 'Click', 'Go to Branch');
    this.closeSearch();
    this.cardViewStateService.openBranchCard(branchId,
                                            null,
                                            null,
                                            BranchCardTabSection.Details);
  }

  public getSearchPlaceholderText() {
    if (this.isSupportBranches) {
      return this.translationService.translate('HEADER.SEARCH.PLEASE_SELECT');
    } else {
      return this.translationService.translate('HEADER.SEARCH.PLEASE_SELECT_DAIRY');
    }
  }

  public getSearchPopupTitleText() {
    if (this.isSupportBranches) {
      return this.translationService.translate('HEADER.SEARCH.FIND');
    } else {
      return this.translationService.translate('HEADER.SEARCH.FIND_DAIRY');
    }
  }

  public getNoDataText() {
    if (this.isSupportBranches) {
      return this.translationService.translate('HEADER.SEARCH.NO_DATA');
    } else {
      return this.translationService.translate('HEADER.SEARCH.NO_DATA_DAIRY');
    }
  }
}

export enum EventKey {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
  Enter = 'Enter'
}
