import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[weightValueLimit]'
})
export class WeightValueLimitDirective {

  // tslint:disable-next-line:no-any
  @Input() public weightValueLimit: any;

  private specialKeys: Array<string> = [ 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Backspace', 'Tab', 'End', 'Home' ];

  constructor(private el: ElementRef) { }

  @HostListener('keydown',['$event'])
  public onKeyUp(event: KeyboardEvent) {
    if (this.specialKeys.includes(event.key)) {
      // handle delete button
      if (event.key === 'Delete') {
        let currentValue = this.el.nativeElement.value;
        let caretPosition: number = this.el.nativeElement.selectionStart;
        let splitedNextValue = currentValue.split('.');
        if (splitedNextValue.length === 2) {
          if (splitedNextValue[1] !== '') {
            let char = currentValue.charAt(caretPosition);
            if (char === '.') {
              event.preventDefault();
              return;
            } else {
              return;
            }
          } else {
            return;
          }
        } else {
          return;
        }
      }
      // handle backspace button
      if (event.key === 'Backspace') {
        let currentValue = this.el.nativeElement.value;
        let caretPosition: number = this.el.nativeElement.selectionStart;
        let splitedNextValue = currentValue.split('.');
        if (splitedNextValue.length === 2) {
          if (splitedNextValue[1] !== '') {
            let char = currentValue.charAt(caretPosition - 1);
            if (char === '.') {
              event.preventDefault();
              return;
            } else {
              return;
            }
          } else {
            return;
          }
        } else {
          return;
        }
      } else {
        return;
      }
    }
    if ((event.key === 'c' && event.ctrlKey === true) ||
      (event.key === 'v' && event.ctrlKey === true) ||
      (event.key === 'x' && event.ctrlKey === true)) {
      return;
    }
    let currentValue = this.el.nativeElement.value;
    let caretPosition: number = this.el.nativeElement.selectionStart;
    let nextValue = this.getStringToCheck(currentValue, event.key, caretPosition);
    let wholeNumbersRegex = /^[0-9]{1,4}$/;
    let decimalNumbersRegex = /^[0-9]{0,1}$/;
    let splitedNextValue = nextValue.split('.');
    if (splitedNextValue.length === 2) {
      if (splitedNextValue && !String(splitedNextValue[0]).match(wholeNumbersRegex) || !String(splitedNextValue[1]).match(decimalNumbersRegex)) {
        event.preventDefault();
      }
    } else if (splitedNextValue.length === 1) {
        if (splitedNextValue && !String(splitedNextValue[0]).match(wholeNumbersRegex)) {
          event.preventDefault();
        }
    } else {
      event.preventDefault();
    }
  }

  @HostListener('paste',['$event'])
  // tslint:disable-next-line:no-any
  public onPaste(e: any) {
    let pastedValue = e.clipboardData.getData('text/plain');
    let wholeNumbersRegex = /^[0-9]{1,4}$/;
    let decimalNumbersRegex = /^[0-9]{0,1}$/;
    let splitedPastedValue = pastedValue.split('.');
    if (splitedPastedValue.length === 2) {
      if (splitedPastedValue && !String(splitedPastedValue[0]).match(wholeNumbersRegex) || !String(splitedPastedValue[1]).match(decimalNumbersRegex)) {
        e.preventDefault();
      }
    } else if (splitedPastedValue.length === 1) {
      if (splitedPastedValue && !String(splitedPastedValue[0]).match(wholeNumbersRegex)) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  }

  private getStringToCheck(string: string, char: string, position: number): string {
    return string.substr(0, position) + char + string.substr(position, string.length);
  }
}
