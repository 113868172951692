<div class="initialize-registration-wrapper">
  <div class="loading-wrapper" *ngIf="isLoading">
    <div class="loading"></div>
  </div>
  <div class="body-div">
    <div class="title" [attr.translation-id]="'REGISTRATION.FLOW.DEVICE_REGISTRATION'">{{ 'REGISTRATION.FLOW.DEVICE_REGISTRATION' | translate }}</div>
    <p class="text-body" *ngIf='!isLoading' [attr.translation-id]="'REGISTRATION.FLOW.IN_ORDER_TO_SET_THE_FARM_PLEASE_REGISTER_YOUR_DEVICE'">{{ 'REGISTRATION.FLOW.IN_ORDER_TO_SET_THE_FARM_PLEASE_REGISTER_YOUR_DEVICE' | translate }}</p>
    <p class="text-body" *ngIf="isLoading" [attr.translation-id]="'REGISTRATION.FLOW.CHECKING_ACCOUNT_DATA'">{{ 'REGISTRATION.FLOW.CHECKING_ACCOUNT_DATA' | translate }}...</p>
    <p class="error-text-body" *ngIf="isError" [attr.translation-id]="'REGISTRATION.FLOW.ERROR.' + isError">{{ 'REGISTRATION.FLOW.ERROR.' + isError | translate }}</p>
  </div>
  <div class="buttons-div">
    <a class="button-class register" [ngClass]="{ disabled: isLoading }" [attr.translation-id]="'REGISTRATION.TITLE.REGISTER'" [attr.sh-id]="'Register-button'" (click)="startRegistration()">{{ 'REGISTRATION.TITLE.REGISTER' | translate }}</a>
    <a class="button-class trial-mode" *ngIf="isTrialModeExpired()" [ngClass]="{ disabled: isLoading }" [attr.translation-id]="'REGISTRATION.FLOW.TRIAL_MODE'" [attr.sh-id]="'Trial-mode-button'" (click)="startTrialMode()">{{ 'REGISTRATION.FLOW.TRIAL_MODE' | translate }}</a>
  </div>
</div>

