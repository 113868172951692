import { Injectable } from '@angular/core';
import {HttpResponseResult, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PremiumTrialService {

  private readonly premiumTrialURL: string = '/rest/api/v2/auth/premiumTrial';

  constructor(private restApiService: RestApiService, private authService: AuthService) { }

  // tslint:disable-next-line:no-any
  public startPremiumTrial(): Promise<HttpResponseResult<any>> {
    const request: IPremiumTrialStartedResponse = { status: 'Active', remainingDays: null };
    return this.restApiService.sendPutMethod(this.premiumTrialURL , this.authService.currentUserAuthState.authDetails, request);
  }

  public checkIfPremiumTrialHasStarted(): Promise<HttpResponseResult<IPremiumTrialStartedResponse>> {
    return this.restApiService.sendGetMethod(this.premiumTrialURL, this.authService.currentUserAuthState.authDetails);
  }
}
export interface IPremiumTrialStartedResponse {
  remainingDays: number;
  status: PremiumTrialStartedStatus;
}
export type PremiumTrialStartedStatus = 'AwaitingApproval' | 'Active' | null ;
