import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ConfigService} from '../../../../services/config/config.service';
import {FarmMode} from '../../../../services/config/model/server-config';
import {GroupsService} from '../../../../services/groups/groups.service';
import {DropdownListItem} from '../../../../common/components/dropdown-list/dropdown-list-model';
import {GoogleAnalyticsService} from '../../../../services/google-analytics/google-analytics.service';
import {Subject, Subscription} from 'rxjs';
import {ErrorModel} from '../../../../services/model/common-model';
import {ActivatedRoute} from '@angular/router';
import {ApplicationBundleRoutingMode, RoutingService} from '../../../../services/routing/routing.service';
import {IGroup} from '../../../../services/groups/model/group.interface';
import {TranslationService} from '../../../../services/translations/translation.service';

export interface IGroupBranch {
  selectedGroupBranch: DropdownListItem;
  items: DropdownListItem[];
}

export interface INumericUpDownModel {
  selectedValue: number;
  min: number;
  max: number;
}

@Component({
  selector: 'group-editor',
  templateUrl: './group-editor.component.html',
  styleUrls: ['./group-editor.component.scss']
})
export class GroupEditorComponent implements OnInit, OnDestroy {

  public isOpen: boolean = false;

  public Error: ErrorModel;

  public applicationBundleRoutingMode: ApplicationBundleRoutingMode;

  public applicationBundleRoutingModes = ApplicationBundleRoutingMode;

  private queryParamsSubscription: Subscription;

  private editGroupSubscription: Subscription;

  @Output()
  public addGroupClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  public ApplicationBundleRoutingMode = ApplicationBundleRoutingMode;

  constructor(private route: ActivatedRoute,
              public configService: ConfigService,
              private groupsService: GroupsService,
              private googleAnalyticsService: GoogleAnalyticsService,
              private routingService: RoutingService) {

  }

  public async ngOnInit() {

    this.queryParamsSubscription = this.route.queryParams.subscribe(queryParams => {
      if (this.configService.serverConfig.farmMode === FarmMode.Dairy) {
        this.applicationBundleRoutingMode = null;
      } else {
        this.applicationBundleRoutingMode = this.routingService.getApplicationBundleRoutingMode(queryParams);
      }
    });

    this.editGroupSubscription = this.groupsService.editGroupSubscription.subscribe((group: IGroup) => {
      this.isOpen = true;
      if (group != null) {
        this.googleAnalyticsService.send('Manage', 'Open', 'Edit Group');
      }
      this.addGroupClicked.emit(this.isOpen);
    });
  }

  public ngOnDestroy(): void {
    this.editGroupSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
  }

  public async toggleGroup() {
    this.isOpen = !this.isOpen;
    if (!this.isOpen) {
      this.googleAnalyticsService.send('Manage', 'Open', 'Add Group');
    }
    if (this.isOpen) {
      // if add group dialog is open - close
      this.groupsService.editGroupSubscription.next(null);
    }
    this.addGroupClicked.emit(this.isOpen);
  }

  public async onGroupAddedOrChanged(isGroupWizardOpen: boolean) {
    this.isOpen = isGroupWizardOpen;
    this.groupsService.onGroupsListChanged.next(this.isOpen);
  }
}
export enum GroupFields {
  groupName = 'groupName',
  groupNumber = 'groupNumber',
  branchName = 'branchName',
  heatIndexThreshold = 'heatIndexThreshold'
}
