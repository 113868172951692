<dropdown-popup #eventsDropdownPopup [colorScheme]="DropdownPopupColorScheme.WhiteBlue" [contentWidth]="'auto'">
  <ng-container dropdown-header>
    <div class="events-menu-header">
      <div *ngIf="selectedEvent != null" class="selected-event-icon-{{ toggleTagsClass(selectedEvent.type) }}"></div>
      <div class="header-text" [attr.translation-id]="selectedEvent == null ? 'ANIMAL.EVENTS.PLACEHOLDER.SELECT_EVENT' : ('ANIMAL.EVENTS.EVENT_TYPE.' + selectedEvent.type)">
        {{ selectedEvent == null ? ('ANIMAL.EVENTS.PLACEHOLDER.SELECT_EVENT' | translate) : ('ANIMAL.EVENTS.EVENT_TYPE.' + selectedEvent.type | translate) }}
      </div>
    </div>
  </ng-container>
  <ng-container dropdown-content>
    <div class="events-popup">
      <div class="events-group" *ngFor="let category of eventCategories">
        <div class="events-group-header" [attr.translation-id]="category.title">
          {{ category.title | translate }}
        </div>
        <div class="events-group-content">
          <div class="events-group-content-section" *ngFor="let page of category.events">
            <ng-container *ngFor="let event of page">
              <div [attr.sh-id]="'event_selection_' + event.type"
                class="events-group-menu-event"
                [ngClass]="{ disabled: !event.isEnabled }"
                *ngIf="event.isEnabled || event.isVisible"
                (click)="selectEvent(event)">
                <i class="event-icon event-icon-{{ toggleTagsClass(event.type) }}"></i>
                <div class="event-text" [attr.translation-id]="'ANIMAL.EVENTS.EVENT_TYPE.' + event.type">
                  {{ 'ANIMAL.EVENTS.EVENT_TYPE.' + event.type | translate }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</dropdown-popup>
