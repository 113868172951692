import {Component, Input, OnInit} from '@angular/core';
import {ManageSettingsScreenType} from '../../model/manage-settings.model';

@Component({
  selector: 'manage-settings-health',
  templateUrl: './manage-settings-health.component.html',
  styleUrls: ['./manage-settings-health.component.scss']
})
export class ManageSettingsHealthComponent implements OnInit {

  @Input()
  public isFinishing: boolean;

  @Input()
  public currentSettingsScreenType: ManageSettingsScreenType;

  constructor() {}

  public ngOnInit(): void {

  }

}
