import {Component, ViewChild} from "@angular/core";
import {NotificationDialogTemplateComponent, NotificationDialogTheme} from "../../../../common/components/dialogs/notification-dialog-template/notification-dialog-template.component";
import {IDialog} from '../../../../services/dialogs/model/dialog.model';

@Component({
  selector: 'sync-now-modal',
  templateUrl: './sync-now-modal.component.html',
  styleUrls: ['./sync-now-modal.component.scss']
})
export class SyncNowModalComponent implements IDialog{

  @ViewChild('dialogRef', {static: true})
  public dialogRef: NotificationDialogTemplateComponent;
  
  public NotificationDialogTheme = NotificationDialogTheme;
  
  public showDialog() {
    this.dialogRef.isOpen = true;
  }
  
  public closeDialog() {
    this.dialogRef.isOpen = false;
  }
}
