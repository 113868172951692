import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {SMALL_SCREEN_MAX_WIDTH} from '../../utils/screen-calculation-utils';
import {WINDOW} from '../../utils/window-utils';
import {GoogleAnalyticsService} from '../../services/google-analytics/google-analytics.service';
import {SortingGateService} from '../../services/sorting-gate/sorting-gate.service';
import {ConfigService} from '../../services/config/config.service';
import {AllowedManageAppBundle, ServerConfig} from '../../services/config/model/server-config';
import {Router} from '@angular/router';
import {ApplicationBundleRoutingMode} from '../../services/routing/routing.service';
import {UserPermissionsService} from '../../services/auth/user-permissions.service';
import {Subscription} from 'rxjs';
import {PollingKeyNames, PollingService} from '../../services/rest-api/polling.service';
import {IESenseV2TagsData, TagsProvisioningService} from '../../services/tags-provisioning/tags-provisioning.service';
import {HttpResponseResult} from '../../services/rest-api/rest-api.service';
import {AuthService} from '../../services/auth/auth.service';
import {PopupViewStateService} from '../../services/ui/view-state/popup-view-state.service';

export enum MenuItemType {
  MenuHeader = 'MenuHeader',
  Default = 'Default',
  Reports = 'Reports',
  Manage = 'Manage',
  SortingGate = 'SortingGate'
}

@Component({
  selector: 'left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit, OnDestroy {

  public MenuItemType = MenuItemType;

  @Input()
  @HostBinding('class.menu-open')
  public isOpenMenu: boolean = false;

  @Output()
  public openMenuChange: EventEmitter<boolean> = new EventEmitter();

  public isReportsMenuOpen : boolean;

  public isSortingGateMenuOpen: boolean;

  public isManageMenuOpen: boolean;

  public isSortingGateMenuVisible: boolean;

  private pollingServiceSubscription: Subscription;

  @ViewChild('leftMenuHeader')
  public leftMenuHeader : ElementRef;

  constructor(@Inject(WINDOW) private _window: Window,
              private readonly leftMenuElementRef: ElementRef,
              private readonly googleAnalyticsService: GoogleAnalyticsService,
              private readonly popupViewStateService : PopupViewStateService,
              private readonly sortingGateService: SortingGateService,
              private readonly tagsProvisioningService: TagsProvisioningService,
              private readonly router: Router,
              private readonly pollingService: PollingService,
              public readonly configService: ConfigService,
              private readonly authService: AuthService,
              private readonly userPermissionsService: UserPermissionsService) {

  }

  public async ngOnInit() {
    this.isSortingGateMenuVisible = await this.sortingGateService.sortingIsActive();
    setTimeout(() =>{
      this.openMenuChange.emit(this.leftMenuHeader.nativeElement.clientWidth != 83);
    });
    this.registerToLedTaskModuleChanges();
  }

  public ngOnDestroy() {
    if (this.pollingServiceSubscription) {
      this.pollingServiceSubscription.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    this.isReportsMenuOpen = false;
    this.isSortingGateMenuOpen = false;
    this.isManageMenuOpen = false;
  }

  public toggleMenu(menuItemType: MenuItemType) {
    if(menuItemType === MenuItemType.MenuHeader) {
      this.isReportsMenuOpen = false;
      this.isSortingGateMenuOpen = false;
      this.isManageMenuOpen = false;
      this.isOpenMenu = !this.isOpenMenu;
    } else if(menuItemType === MenuItemType.Reports) {
      this.isReportsMenuOpen = !this.isReportsMenuOpen;
      this.isSortingGateMenuOpen = false;
      if (this._window.innerWidth > SMALL_SCREEN_MAX_WIDTH) {
        this.isOpenMenu = false;
      }
      this.isManageMenuOpen = false;
    } else if(menuItemType === MenuItemType.SortingGate) {
      this.isReportsMenuOpen = false;
      if (this._window.innerWidth > SMALL_SCREEN_MAX_WIDTH) {
        this.isOpenMenu = false;
      }
      this.isManageMenuOpen = false;
      this.isSortingGateMenuOpen = !this.isSortingGateMenuOpen;
    } else if (menuItemType === MenuItemType.Manage) {
      // check if manage is coexist - if not - open the type directly.
      this.isManageCoexist();
      this.isReportsMenuOpen = false;
      if (this._window.innerWidth > SMALL_SCREEN_MAX_WIDTH) {
        this.isOpenMenu = false;
      }
      this.isSortingGateMenuOpen = false;
    } else {
      this.isReportsMenuOpen = false;
      this.isSortingGateMenuOpen = false;
      this.isManageMenuOpen = false;
      if (this._window.innerWidth < SMALL_SCREEN_MAX_WIDTH || this.isOpenMenu) {
        this.isOpenMenu = !this.isOpenMenu;
      }
    }
    this.openMenuChange.emit(this.isOpenMenu);
  }

  public onCloseSortingMenu() {
    this.isSortingGateMenuOpen = false;
    this.isOpenMenu = false;
    this.openMenuChange.emit(this.isOpenMenu);
  }

  private registerToLedTaskModuleChanges() {
    this.pollingServiceSubscription = this.pollingService.pollingChangesState.subscribe(async (pollingName) => {
      if(pollingName.includes(PollingKeyNames.ledOperationActiviation)) {
        await this.authService.getServerConfig();
      }
    });
  }

  public sendGoogleAnalyticsData(menuItemType: string) {
    this.googleAnalyticsService.send('nav-menu', 'click', menuItemType);
  }

  public async isManageCoexist() {
    switch (this.configService.allowedManageAppBundle) {
      case AllowedManageAppBundle.Breeding:{
        await this.router.navigate(['/manage'], { queryParams: { 'appBoundle': ApplicationBundleRoutingMode.Breeding }});
        break;
      }
      case AllowedManageAppBundle.Finishing:{
        await this.router.navigate(['/manage'], { queryParams: { 'appBoundle': ApplicationBundleRoutingMode.Finishing }});
        break;
      }
      case AllowedManageAppBundle.BreedingAndFinishing:{
        this.isManageMenuOpen = !this.isManageMenuOpen;
        break;
      }
    }
  }

  public get hasPermissionsToSystem() : boolean {
    return this.userPermissionsService.hasPermissionsForRoute('/system/');
  }

  public get isLedTasksMenuVisible() {
    return (this.userPermissionsService.hasPermissionsForLedTasksRoute() && this.configService.serverConfig.isLedOperationEnabled && !this.userPermissionsService.isSuspended());
  }
}
