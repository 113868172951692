import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ChartDurationSelectionLabel, XyChartComponent} from '../../../../common/components/xy-chart/xy-chart.component';
import {GroupsService} from '../../../../services/groups/groups.service';
import {SeriesModel} from '@syncfusion/ej2-charts';
import {IGroupChartActivityData, IGroupChartRuminationData, IGroupGraphDetails} from '../../../../services/groups/model/group.interface';
import {ChartResolution, IChartPoint} from '../../../../services/model/charts/chart.interface';
import {ChartConfiguration, GridLineIntervalEnum} from '../../../../services/charts/xy-chart-calculation.service';
import {GroupActivityRuminationMode, GroupCardTabSection, GroupCardViewState} from '../group-card-model';
import {DateTimeFormatEnum} from '../../../../common/pipes/epoch-date-time.pipe';

@Component({
  selector: 'group-card-graphs-activity-rumination',
  templateUrl: './group-card-graphs-activity-rumination.component.html',
  styleUrls: ['./group-card-graphs-activity-rumination.component.scss']
})
export class GroupCardGraphsActivityRuminationComponent implements OnChanges {

  @ViewChild('groupActivityRuminationChart')
  public groupActivityRuminationChart: XyChartComponent;

  @Input()
  public groupId: number;

  @Input()
  public groupGraphDetails: IGroupGraphDetails;

  @Input()
  public viewState: GroupCardViewState;

  public chartViewMode: GroupActivityRuminationMode = GroupActivityRuminationMode.Activity;

  // tslint:disable-next-line:no-any
  public selectedChartPoint: any;

  private activityChartData: IGroupChartActivityData;

  private ruminationChartData: IGroupChartRuminationData;

  public selectedDurationLabel: ChartDurationSelectionLabel;

  private optionsDurationLabels: ChartDurationSelectionLabel[] = [];

  private activityChartConfigurations: ChartConfiguration[] = [];

  private ruminationChartConfiguration: ChartConfiguration[] = [];

  private isChangeViewModeAvailable: boolean = true;

  public GroupActivityRuminationMode = GroupActivityRuminationMode;

  public dateTimeFormat: DateTimeFormatEnum = DateTimeFormatEnum.Time;

  constructor(private groupsService: GroupsService) {

  }

  public async ngOnChanges(changes: SimpleChanges){
    if(changes.viewState != null &&
       changes.viewState.previousValue != changes.viewState.currentValue &&
       this.viewState != null) {
      this.setViewModeForState(this.chooseSubTab());
      this.initConfig();
      this.initChart();
    }
  }

  private setViewModeForState(chartViewMode: GroupActivityRuminationMode): void {
    this.viewState.activityRuminationMode = chartViewMode;
  }

  public async changeViewMode(chartViewMode: GroupActivityRuminationMode): Promise<void> {
    if (this.isChangeViewModeAvailable == false) {
      return;
    }
    this.setViewModeForState(chartViewMode);
    if(chartViewMode == this.chartViewMode){
      return;
    }
    this.isChangeViewModeAvailable = false;
    this.chartViewMode = chartViewMode;
    this.groupActivityRuminationChart.resetChart();
    await this.initChart();
    this.isChangeViewModeAvailable = true;
  }

  public async onDurationSelected(duration: ChartDurationSelectionLabel) {
    this.selectedDurationLabel = duration;
    await this.initChart();
  }

  public onChartPointMouseOver(chartPoint: IChartPoint) {
    this.selectedChartPoint = chartPoint;
  }

  private initializeChartViewMode() {
    this.chartViewMode = this.viewState.activityRuminationMode || this.chartViewMode;
  }

  private async initChart() {
    this.initializeChartViewMode();
    this.viewState.activityRuminationChartSelectedDuration = this.optionsDurationLabels.indexOf(this.selectedDurationLabel);

    if(this.chartViewMode === GroupActivityRuminationMode.Activity) {

      if(this.activityChartData == null) {
        let response = await this.groupsService.getGroupChartActivity(this.groupId, ChartResolution.Hour);
        if(response.status == 200){
          this.activityChartData = response.responseBody;
        }
      }

      this.groupActivityRuminationChart.initChart(this.getActivitySeries(),
                                                  this.activityChartData.series,
                                                  this.activityChartConfigurations[this.viewState.activityRuminationChartSelectedDuration],
                                                 true,
                                                  this.selectedDurationLabel,
                                                  this.optionsDurationLabels,
                                                  this.activityChartData.eventsSeries);

    } else if(this.chartViewMode === GroupActivityRuminationMode.Rumination) {

      if(this.ruminationChartData == null) {
        let response = await this.groupsService.getGroupChartRumination(this.groupId, ChartResolution.Hour);
        if(response.status == 200){
          this.ruminationChartData = response.responseBody;
        }
      }

      this.groupActivityRuminationChart.initChart(this.getRuminationSeries(),
                                                  this.ruminationChartData.series,
                                                  this.ruminationChartConfiguration[this.viewState.activityRuminationChartSelectedDuration],
                                                  true,
                                                  this.selectedDurationLabel,
                                                  this.optionsDurationLabels,
                                                  this.ruminationChartData.eventsSeries);

    }
  }

  private getActivitySeries() : SeriesModel[] {
    let seriesModel : SeriesModel[] = [];
    seriesModel.push({
      type: 'RangeArea',
      xName: 'x',
      high: 'stdActivityUpper',
      low: 'stdActivityLower',
      dataSource: this.activityChartData.series,
      opacity: 0.1,
      fill: '#000',
      animation: {
        enable: false
      }
    });
    seriesModel.push({
      type: 'Line',
      xName: 'x',
      yName: 'averageActivity',
      dataSource: this.activityChartData.series,
      opacity: 0.5,
      fill: '#000',
      animation: {
        enable: false
      }
    });
    seriesModel.push({
      type: 'Line',
      xName: 'x',
      yName: 'currentActivity',
      width: 2,
      fill: '#56c3b8',
      animation: {
        enable: false
      },
      dataSource: this.activityChartData.series
    });
    return seriesModel;
  }

  private getRuminationSeries() : SeriesModel[] {
    let seriesModel : SeriesModel[] = [];
    seriesModel.push({
      type: 'RangeArea',
      xName: 'x',
      high: 'stdRuminationUpper',
      low: 'stdRuminationLower',
      dataSource: this.ruminationChartData.series,
      opacity: 0.1,
      fill: '#000',
      animation: {
        enable: false
      }
    });
    seriesModel.push({
      type: 'Line',
      xName: 'x',
      yName: 'averageRumination',
      dataSource: this.ruminationChartData.series,
      opacity: 0.5,
      fill: '#000',
      animation: {
        enable: false
      }
    });
    seriesModel.push({
      type: 'Line',
      xName: 'x',
      yName: 'currentRumination',
      width: 2,
      fill: '#aa64aa',
      animation: {
        enable: false
      },
      dataSource: this.ruminationChartData.series
    });
    return seriesModel;
  }

  private initConfig() {
    this.optionsDurationLabels.push({
      selectedDisplayText: '24 HOURS',
      optionDisplayText: '24'
    });
    this.activityChartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 0.5,
      gridLineInterval: GridLineIntervalEnum.ThreeHours,
      visiblePeriodHours: 24,
      primaryYAxisMaxValue: 120,
      secondaryYAxisMaxValue: 120
    }));
    this.ruminationChartConfiguration.push(new ChartConfiguration({
      scrollStepHours: 0.5,
      gridLineInterval: GridLineIntervalEnum.ThreeHours,
      visiblePeriodHours: 24,
      primaryYAxisMaxValue: 100,
      secondaryYAxisMaxValue: 100
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '48 HOURS',
      optionDisplayText: '48'
    });
    this.activityChartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 0.5,
      gridLineInterval: GridLineIntervalEnum.ThreeHours,
      visiblePeriodHours: 48,
      primaryYAxisMaxValue: 120,
      secondaryYAxisMaxValue: 120
    }));
    this.ruminationChartConfiguration.push(new ChartConfiguration({
      scrollStepHours: 0.5,
      gridLineInterval: GridLineIntervalEnum.ThreeHours,
      visiblePeriodHours: 48,
      primaryYAxisMaxValue: 100,
      secondaryYAxisMaxValue: 100
    }));

    this.selectedDurationLabel = this.optionsDurationLabels[this.viewState.activityRuminationChartSelectedDuration];
  }

  private chooseSubTab(): GroupActivityRuminationMode {
    switch (this.viewState.selectedTab) {
      case GroupCardTabSection.GraphsActivity: return GroupActivityRuminationMode.Activity;
      case GroupCardTabSection.GraphsActivityRumination: return GroupActivityRuminationMode.Rumination;
    }
  }
}
