import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IUser} from '../../../../../services/config/model/user.interface';
import {IDialog} from '../../../../../services/dialogs/model/dialog.model';
import {TranslationService} from '../../../../../services/translations/translation.service';
import { ConfirmDialogIcons } from 'src/app/common/components/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'confirm-user-delete-dialog',
  templateUrl: './confirm-user-delete-dialog.component.html',
  styleUrls: ['./confirm-user-delete-dialog.component.scss']
})
export class ConfirmUserDeleteDialogComponent implements IDialog, OnInit {

  @Input()
  public userToDelete: IUser;

  @Output()
  public onCloseModalDialog: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onDeleteClicked: EventEmitter<void> = new EventEmitter<void>();

  public isOpen = false;

  public readonly ConfirmDialogIcons = ConfirmDialogIcons;

  constructor(private readonly translationService: TranslationService) {
  }

  public ngOnInit() {
  }

  public onCloseDialog() {
    this.onCloseModalDialog.emit();
  }

  public onDeleteButtonClicked() {
    this.onDeleteClicked.emit();
  }

  public showDialog() {
    this.isOpen = true;
  }

  public closeDialog() {
    this.isOpen = false;
  }

  public get confirmDeleteUserDialogPrimaryText(): string {
    return this.translationService.translate('MANAGE.SETTINGS.USERS.USERS_LIST.CONFIRM_DELETE_TITLE_1')
      + ' '
      + this.translationService.translate('MANAGE.SETTINGS.USERS.USERS_LIST.CONFIRM_DELETE_TITLE_2').toLocaleLowerCase();
  }

  public get confirmDeleteUserDialogLeftButtonText(): string {
    return this.translationService.translate('MANAGE.SETTINGS.USERS.USERS_LIST.NO')
      + ', '
      + this.translationService.translate('MANAGE.SETTINGS.USERS.USERS_LIST.KEEP_THIS_USER');
  }
}
