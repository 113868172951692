import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {RadioButtonColorTheme} from '../../../../../common/components/radio-button/radio-button.component';
import {LedMode, LedModeSettings, LedTaskFields} from '../../../../../services/led-tasks/models/led-tasks.interface';
import {LedTasksService} from '../../../../../services/led-tasks/led-tasks.service';
import {DurationInput} from 'src/app/common/pipes/duration.pipe';
import * as _ from "lodash";

@Component({
  selector: 'led-task-editor-right-reports-container',
  templateUrl: './led-task-editor-right-reports-container.component.html',
  styleUrls: ['./led-task-editor-right-reports-container.component.scss']
})
export class LedTaskEditorRightReportsContainerComponent implements OnInit {

  public RadioButtonColorTheme = RadioButtonColorTheme;

  public LedMode = LedMode;

  public LedTaskFields = LedTaskFields;

  public DurationInput = DurationInput;

  public days: number[] = [1,2,3,4,5,6,7];

  constructor(public ledTasksService: LedTasksService) { }

  public ngOnInit() {
  }

  public isLedModeChecked(currentLedMode: LedMode): boolean {
    return this.ledTasksService.taskModel.ledMode === currentLedMode;
  }

  public onLedModeSelected(selectedMode: LedMode) {
    this.ledTasksService.taskModel.ledMode = selectedMode;
    switch (selectedMode) {
      case LedMode.Fast: {
        this.ledTasksService.taskModel.selectedDuration = this.ledTasksService.ledModeSettings.changeToFastModeSettings(this.ledTasksService.taskModel.selectedDuration);
        break;
      }
      case LedMode.Slow: {
        this.ledTasksService.taskModel.selectedDuration = this.ledTasksService.ledModeSettings.changeToSlowModeSettings(this.ledTasksService.taskModel.selectedDuration);
      }
    }
  }

  public isAllDaysChecked() {
    let isEqual: boolean = true;
    for (let i = 0; i < this.days.length ; i++ ) {
      if (this.ledTasksService.taskModel.selectedDays[i] != this.days[i]) {
        isEqual = false;
      }
    }
    return isEqual;
  }

  public toggleRepeatAllDays() {
    if (this.isAllDaysChecked()) {
      this.ledTasksService.taskModel.selectedDays = [];
    } else {
      this.ledTasksService.taskModel.selectedDays = _.cloneDeep(this.days);
    }
    this.ledTasksService.clearErrors();
  }

  public convertDayNumberToText(dayNumber: number) {
    switch (dayNumber) {
      case 1: {
        return 'Sun';
      }
      case 2: {
        return 'Mon';
      }
      case 3: {
        return 'Tue';
      }
      case 4: {
        return 'Wed';
      }
      case 5: {
        return 'Thu';
      }
      case 6: {
        return 'Fri';
      }
      case 7: {
        return 'Sat';
      }
    }
  }

  public isDaySelected(dayNumber: number) {
    const isExists: number = this.ledTasksService.taskModel.selectedDays.findIndex(day => day === dayNumber);
    return (isExists > -1);
  }

  public toggleDay(dayNumber: number) {
    const index: number = this.ledTasksService.taskModel.selectedDays.findIndex(day => day === dayNumber);
    if (index > -1) {
      this.ledTasksService.taskModel.selectedDays.splice(index, 1);
    } else {
      this.ledTasksService.taskModel.selectedDays.push(dayNumber);
      this.ledTasksService.taskModel.selectedDays.sort((a,b) => a - b);
    }
    this.ledTasksService.clearErrors();
  }

  public onSelectedDurationChanged(duration: number) {
    this.ledTasksService.taskModel.selectedDuration = Math.round(duration);
  }
}
