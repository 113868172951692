import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LedTasksService} from "../../../../../../services/led-tasks/led-tasks.service";
import {DropdownColorScheme} from '../../../../../../common/components/dropdown-list/regular-dropdown-list/regular-dropdown-list.component';
import {LedTaskFields, ReportModel} from "../../../../../../services/led-tasks/models/led-tasks.interface";
import {DropdownListItem} from "../../../../../../common/components/dropdown-list/dropdown-list-model";
import {AutoCompleteColorScheme} from "../../../../../../common/components/auto-complete/auto-complete-model";
import {SearchEntry} from "../../../../../../services/search/model/search.model";
import {RegularAutoCompleteComponent} from "../../../../../../common/components/auto-complete/regular-auto-complete/regular-auto-complete.component";
import {EntityType} from "../../../../../../services/search/search.service";
import {TranslationService} from "../../../../../../services/translations/translation.service";
import {ChipsItem} from '../../../../../../common/components/chips/chips-item.component';

@Component({
  selector: 'reports-container',
  templateUrl: './reports-container.component.html',
  styleUrls: ['./reports-container.component.scss']
})
export class ReportsContainerComponent implements OnInit {

  public DropdownColorScheme = DropdownColorScheme;

  public LedTaskFields = LedTaskFields;

  public AutoCompleteColorScheme = AutoCompleteColorScheme;

  public groupSearchQuery: string;

  @ViewChild('effectiveGroupsAutoComplete')
  public effectiveGroupsAutoComplete: RegularAutoCompleteComponent;

  @Input()
  public reportsList: ReportModel[] = [];

  @Input()
  public groupsList: string[] = [];

  @Input()
  public searchEntities: SearchEntry[] = [];

  constructor(public ledTasksService: LedTasksService, private translationService: TranslationService) { }

  public ngOnInit() {
  }

  public selectReportId(reportItem: DropdownListItem) {
    if (reportItem == null) {
      this.ledTasksService.taskModel.isQuickFilter = null;
      this.ledTasksService.taskModel.selectedReportItem = null;
    } else {
      this.ledTasksService.taskModel.selectedReportItem = reportItem;
      let reportModel: ReportModel = this.reportsList.find((report: ReportModel) => report.id === reportItem.value);
      if (reportModel) {
        this.ledTasksService.taskModel.isQuickFilter = reportModel.quickFilter;
      } else {
        this.ledTasksService.taskModel.isQuickFilter = null;
      }
    }
    this.ledTasksService.clearErrors();
  }

  public toggleCheckbox() {
    this.ledTasksService.taskModel.isQuickFilter.defaultValue = !this.ledTasksService.taskModel.isQuickFilter.defaultValue;
  }

  public get isAllGroupsShown(): boolean {
    return this.ledTasksService.taskModel.selectedGroups && this.ledTasksService.taskModel.selectedGroups.length === 0;
  }

  public get groupListPlaceholder(): string {
    if (this.isAllGroupsShown) {
      return this.translationService.translate('LED_TASKS.TASK_EDITOR.ALL_GROUPS');
    } else {
      return this.translationService.translate('LED_TASKS.TASK_EDITOR.SELECT_GROUP');
    }
  }

  public onGroupSelected(selectedGroup: string) {
    if (selectedGroup == null) {
      return;
    }
    let groupSearchEntity = this.searchEntities.find((group: SearchEntry) => group.entityName === selectedGroup && group.entityType === EntityType.group);
    let groupItem: ChipsItem = new ChipsItem();
    groupItem.chipId = groupSearchEntity.entityId;
    groupItem.chipName = groupSearchEntity.entityName;
    const isExist = this.ledTasksService.taskModel.selectedGroups.find((group: ChipsItem) => group.chipId === groupItem.chipId);
    if (isExist) {
      return;
    } else {
      this.ledTasksService.clearErrors();
      this.ledTasksService.taskModel.selectedGroups.push(groupItem);
      let index = this.groupsList.findIndex(group => group === groupItem.chipName);
      this.groupsList.splice(index, 1);
      this.groupSearchQuery = null;
      this.effectiveGroupsAutoComplete.clearValue();
    }
  }

  public removeSelectedGroup(group: ChipsItem) {
    const isExist = this.groupsList.find((groupString: string) => groupString === group.chipName);
    if (!isExist) {
      this.groupsList.push(group.chipName);
    }
    const index = this.ledTasksService.taskModel.selectedGroups.findIndex((groupItem: ChipsItem) => groupItem.chipId === group.chipId);
    this.ledTasksService.taskModel.selectedGroups.splice(index, 1);
  }

}
