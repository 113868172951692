<regular-edit-dialog-template [modalWidth]="555"
                              [isOpen]="isModalOpen"
                              [enableRemoveButton]="false"
                              (onCloseClick)="closeDialog()"
                              (onLeftButtonClick)="closeDialog()"
                              (onRightButtonClick)="apply()">
  <ng-container header-caption>
    <span translation-id="DIALOG.UPDATE_SOFTWARE.HEADER">{{ 'DIALOG.UPDATE_SOFTWARE.HEADER' | translate }}</span>
  </ng-container>
  <ng-container body>
    <div class="form-group">
      <span class="form-group-label" translation-id="DIALOG.UPDATE_SOFTWARE.TEXT">
        {{ 'DIALOG.UPDATE_SOFTWARE.TEXT' | translate }}
      </span>
    </div>
  </ng-container>
  <ng-container left-button>
    <span>{{ 'COMMON.BUTTON.CANCEL' | translate}}</span>
  </ng-container>
  <ng-container right-button>
    <span translation-id="DIALOG.UPDATE_SOFTWARE.UPDATE_BUTTON_TEXT">
      {{ 'DIALOG.UPDATE_SOFTWARE.UPDATE_BUTTON_TEXT' | translate }}
    </span>
  </ng-container>
</regular-edit-dialog-template>
