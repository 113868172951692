<description-cell-details-template [hasRowDetails]="hasDetails"
                                   [isRowExpanded]="isExpanded"
                                   [row]="row"
                                   [rowShId]="row.eventId"
                                   (toggleExpanded)="toggleExpanded()">
  <ng-container key>{{"ANIMAL.EVENTS.EVENT_DESCRIPTION.NEW_LACTATION_NUMBER" | translate}}:&nbsp;</ng-container>
  <ng-container value>{{row.lactationNumber}}</ng-container>
  <ng-container content>
    <p class="description-item" *ngIf="row.daysInPregnancy != null">
      <span>{{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.DAYS_PREGNANT' | translate}}:&nbsp;</span>
      <span class="value">{{row.daysInPregnancy}}</span>
    </p>
    <p class="description-item" *ngIf="row.calvingEase != null">
      <span>{{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.CALVING_EASE' | translate}}:&nbsp;</span>
      <span class="value">{{row.calvingEase.value}}</span>
    </p>
    <p class="description-item" *ngIf="row.destinationGroupName != null">
      <span>{{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.DESTINATION_GROUP' | translate}}:&nbsp;</span>
      <ellipsisable-text class="value">{{row.destinationGroupName}}</ellipsisable-text>
    </p>
    <div class="wrapper-born">
      <p class="description-item" *ngFor="let born of row.newborns">
              <span class="newborn-section" *ngIf="born.condition === 'alive' && born.animalName">
                <label class="born-animal"><i class="ico-animal-handrail-accessories" [ngClass]="{'animal-sex-male' : born.sex == 'male' , 'animal-sex-female' : born.sex == 'female'}"></i>{{born.animalName}}</label>
              </span>

        <span class="newborn-section" *ngIf="born.condition !== 'alive' || !born.animalName">
                <b>{{born.sex | uppercase | textLimit:1}}</b>
                <span>|</span>
                <b translation-id="COMMON.TEXT.STATUS">{{ 'COMMON.TEXT.STATUS' | translate }}:</b>
                <label [attr.translation-id]="'ANIMAL.NEWBORN.CONDITION.' + born.condition">{{'ANIMAL.NEWBORN.CONDITION.' + born.condition | translate | uppercase}}</label>
              </span>
      </p>
    </div>
  </ng-container>
</description-cell-details-template>
