import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoggedOutLayoutRoutingModule } from './logged-out-layout-routing.module';
import {LoggedOutLayoutComponent} from './logged-out-layout.component';
import {WelcomeLayoutComponent} from './welcome-layout/welcome-layout.component';
import {LoginComponent} from './welcome-layout/login/login.component';
import {ForgotPasswordComponent} from './welcome-layout/forgot-password/forgot-password.component';
import {SignEulaComponent} from './welcome-layout/sign-eula/sign-eula.component';
import {RegistrationComponent} from './welcome-layout/registration/registration.component';
import {InitializeRegistrationComponent} from './welcome-layout/registration/initialize-registration/initialize-registration.component';
import {SignUpComponent} from './welcome-layout/registration/sign-up/sign-up.component';
import {CreateAdminAccountComponent} from './welcome-layout/registration/sign-up/create-admin-account/create-admin-account.component';
import {SignUpAccountDetailsComponent} from './welcome-layout/registration/sign-up/sign-up-account-details/sign-up-account-details.component';
import {SecurityQuestionsComponent} from './welcome-layout/registration/sign-up/security-questions/security-questions.component';
import {TermsAndConditionsComponent} from './welcome-layout/registration/sign-up/terms-and-conditions/terms-and-conditions.component';
import {TrialFarmModeSelectionComponent} from './welcome-layout/trial-farm-mode-selection/trial-farm-mode-selection.component';
import {FarmRegistrationComponent} from './farm-registration/farm-registration.component';
import {AccountDetailsComponent} from './farm-registration/account-details/account-details.component';
import {HerdManagementComponent} from './farm-registration/herd-management/herd-management.component';
import {FarmTopologyComponent} from './farm-registration/farm-topology/farm-topology.component';
import {InitializeSignUpComponent} from './initialize-sign-up/initialize-sign-up.component';
import {InitializeTrialModeComponent} from './initialize-trial-mode/initialize-trial-mode.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {ShSharedModule} from '../common/sh-shared.module';
import {FarmVersionComponent} from "./welcome-layout/farm-version/farm-version.component";
import {PasswordValidateComponent} from './password-validate/password-validate.component';
import {EditHardwareDialogComponent} from "./farm-registration/farm-topology/dialogs/edit-hardware-dialog/edit-hardware-dialog.component";
import {TrialFarmSelectionModalComponent} from "./welcome-layout/trial-farm-mode-selection/trial-farm-selection-modal/trial-farm-selection-modal.component";
import { TrialOperationTypeSelectionComponent } from './welcome-layout/trial-operation-type-selection/trial-operation-type-selection.component';

@NgModule({
  declarations: [
    LoggedOutLayoutComponent,
    InitializeRegistrationComponent,
    SignUpComponent,
    SignUpAccountDetailsComponent,
    CreateAdminAccountComponent,
    WelcomeLayoutComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SignEulaComponent,
    RegistrationComponent,
    SecurityQuestionsComponent,
    TermsAndConditionsComponent,
    TrialFarmModeSelectionComponent,
    FarmRegistrationComponent,
    AccountDetailsComponent,
    HerdManagementComponent,
    FarmTopologyComponent,
    InitializeSignUpComponent,
    InitializeTrialModeComponent,
    FarmVersionComponent,
    PasswordValidateComponent,
    EditHardwareDialogComponent,
    TrialFarmSelectionModalComponent,
    TrialOperationTypeSelectionComponent
  ],
  exports: [
    PasswordValidateComponent,
    TrialFarmSelectionModalComponent
  ],
  imports: [
    CommonModule,
    LoggedOutLayoutRoutingModule,
    FormsModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    ShSharedModule
  ]
})
export class LoggedOutLayoutModule { }
