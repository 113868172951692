<div class="create-event-form">
  <div class="form-section-title">
    {{ 'ANIMAL.EVENTS.ADD_EVENT.RESULT' | translate }}
  </div>
  <div class="form-section-item">
    <div class="pregnancy-check-results form__control" *ngIf="meta">
      <input  id="radio-positive-pregnancy-check"
              type="radio"
              name="type"
              value="positivePregnancyCheck"
              [(ngModel)]="pregnancyCheckType"
              [disabled]="!meta.allowedPositive"/>
      <label for="radio-positive-pregnancy-check" [attr.sh-id]="'radio-positive-pregnancy-check'">+</label>
      <input id="radio-negative-pregnancy-check"
             type="radio"
             name="type"
             value="negativePregnancyCheck"
             [(ngModel)]="pregnancyCheckType"
             [disabled]="!meta.allowedNegative"/>
      <label for="radio-negative-pregnancy-check" [attr.sh-id]="'radio-negative-pregnancy-check'">-</label>
      <input id="radio-uncertain-pregnancy-check"
             type="radio"
             name="type"
             value="uncertainPregnancyCheck"
             [(ngModel)]="pregnancyCheckType"
             [disabled]="!meta.allowedUncertain"/>
      <label for="radio-uncertain-pregnancy-check" [attr.sh-id]="'radio-uncertain-pregnancy-check'" translation-id="COMMON.TEXT.MAYBE">
        {{ 'COMMON.TEXT.MAYBE' | translate }}
      </label>
    </div>
  </div>
</div>
<div class="create-event-form" *ngIf="isEffectiveInseminationAvailable">
  <div class="form-section-title">
    {{ 'ANIMAL.EVENTS.ADD_EVENT.EFFECTIVE_INSEMINATION' | translate }}
  </div>
  <div class="form-section-item">
    <forms-dropdown-list [(ngModel)]="effectiveInsemination"
                         [items]="inseminations"
                         [iconTheme]="formsDLIconsThemes.Blue"
                         [translateListItem]="false"
                         [emitChangeEventWhenSetValue]="false">
    </forms-dropdown-list>
  </div>
</div>
