<div outsideClickHandler (onOutsideClick)="onOutsideClick()">
  <div class="dropdown-header" [ngClass]="{'blue-white' : colorScheme === DropdownPopupColorScheme.BlueWhite, 'white-blue': colorScheme === DropdownPopupColorScheme.WhiteBlue }" (click)="onHeaderClick()">
    <ng-content select="[dropdown-header]"></ng-content>
    <div class="icon-section">
      <div class="icon-drop-down" *ngIf="!isOpen"></div>
      <div class="icon-fold-up" *ngIf="isOpen"></div>
    </div>
  </div>
  <div class="dropdown-content" *ngIf="isOpen" [ngStyle]="{'width' : contentWidth}">
    <ng-content select="[dropdown-content]"></ng-content>
  </div>
</div>
