import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {IReport, ReportDataRow, ReportDisplayType} from '../../../services/reports/model/report.interface';
import {GridSummaryIcon} from '../../../common/components/grid-summary/grid-summary.component';
import {BranchEditorComponent} from './branch-editor/branch-editor.component';
import {DataGridColumnMap} from '../../../common/components/data-grid/model/data-grid-column-map';
import {DataRow} from '../../../services/model/common-model';
import {ReportQuery} from '../../../services/reports/model/report-query.interface';
import {ReportRowSelectionState} from '../../../services/reports/report-row-selection-state';
import {ReportsService} from '../../../services/reports/reports.service';
import {HttpResponseResult} from '../../../services/rest-api/rest-api.service';
import {ReportGridComponent, ReportGridValueCellClickDetails} from '../../../common/components/reports/report-grid/report-grid.component';
import {WINDOW} from '../../../utils/window-utils';
import {BranchesService} from '../../../services/branches/branches.service';
import * as moment from 'moment';
import {TranslationService} from '../../../services/translations/translation.service';
import {BranchCardTabSection, GroupBranchModel} from '../../../services/branches/model/branch.interface';
import {GridViewState} from '../../../services/ui/view-state/grid-row/grid-view-state';
import {CardViewStateService} from '../../../services/ui/view-state/card-view-state.service';

@Component({
  selector: 'manage-branches',
  templateUrl: './manage-branches.component.html',
  styleUrls: ['./manage-branches.component.scss']
})
export class ManageBranchesComponent implements OnInit {

  public gridViewState : GridViewState = new GridViewState();

  public branchesRows: BranchItem[] = [];

  public branchesReportGridQuery: ReportQuery;

  public branchesReport: IReport;

  public readonly columnsMappings: Map<string, DataGridColumnMap> = new Map();

  @ViewChild('branchEditor')
  public branchEditor: BranchEditorComponent;

  @ViewChild('branchesSortGrid')
  public branchesReportGrid: ReportGridComponent;

  public GridSummaryIcon = GridSummaryIcon;

  constructor(private reportsService: ReportsService,
              private translationService: TranslationService,
              private readonly cardViewStateService: CardViewStateService,
              private branchesService: BranchesService,
              @Inject(WINDOW) private window: Window) {}

  public async ngOnInit() {
    await this.setDefaultBranchesReportQuery();
    await this.getBranches();
  }

  private setDefaultBranchesReportQuery() {
    this.branchesReportGridQuery = new ReportQuery();
    this.branchesReportGridQuery.offset = 0;
    this.branchesReportGridQuery.limit = 30;
    this.branchesReportGridQuery.type = ReportDisplayType.full;
    this.branchesReportGridQuery.includeFilterMetaData = true;
  }

  public async getBranches() {
    let response: HttpResponseResult<IReport> = await this.reportsService.getFinishingBranches(this.branchesReportGridQuery);
    if (response && response.status == 200) {
      this.branchesReport = response.responseBody;
    }
  }

  public getGridViewState(): GridViewState {
    return this.gridViewState;
  }

  public async reload(isRefresh:boolean, pastReportTime:number): Promise<void> {
    this.windowResizeMade();
    await this.branchesReportGrid.reloadGrid(isRefresh, pastReportTime, true);
  }

  public async reloadBranchesAfterChange(isOpen: boolean) {
    // Branch Created
    if (isOpen) {
      await this.branchesReportGrid.reloadGrid(false, moment().unix(), true);
    }
    // Branch Edited
    else {
      await this.branchesReportGrid.reloadGrid(true, moment().unix(), true);
    }
    this.windowResizeMade();
  }

  public windowResizeMade() {
    setTimeout(() => {
      this.window.dispatchEvent(new Event('resize'));
    });
  }

  public getRowSelectionState(): ReportRowSelectionState {
    return undefined;
  }

  public async getReportByQuery(reportQuery: ReportQuery) {
    let response: HttpResponseResult<IReport> = await this.reportsService.getFinishingBranches(reportQuery);
    if (response && response.status == 200) {
      return response.responseBody;
    }
  }

  public async editBranch(row: ReportDataRow) {
    this.branchEditor.isOpen = true;
    const groupBranchToEdit: GroupBranchModel = ManageBranchesComponent.createGroupBranchModelToEdit(row);
    this.branchesService.editBranchesSubscription.next(groupBranchToEdit);
  }

  private static createGroupBranchModelToEdit(row: ReportDataRow): GroupBranchModel {
    // Edit branch available only for user created branches!
    const groupBranchToEdit: GroupBranchModel = new GroupBranchModel();
    groupBranchToEdit.id = row['BranchDatabaseIDCalculation'];
    groupBranchToEdit.groupBranchName = row['GroupBranchNameDynamicCalculation'].value;
    groupBranchToEdit.groupBranchNumber = row['GroupBranchNumberCalculation'];
    return groupBranchToEdit;
  }

  public async onReportGridValueCellClick(reportGridValueCellClickDetails: ReportGridValueCellClickDetails) {
    let relatedBranches: number[] = [];
    let relatedBranchesReportQuery = this.branchesReportGridQuery.getIdProjectionReportQuery('BranchDatabaseIDCalculation');
    let relatedBranchesReportResult = await this.reportsService.getBranchesIds(relatedBranchesReportQuery);
    if (relatedBranchesReportResult.status === 200) {
      relatedBranches = relatedBranchesReportResult.responseBody.result.map(value => value['BranchDatabaseIDCalculation']);
    }
    let selectedBranchIdCalculation = reportGridValueCellClickDetails.row['BranchDatabaseIDCalculation'];
    this.cardViewStateService.openBranchCard(selectedBranchIdCalculation,
                                             relatedBranches,
                                  ['BRANCH.NAVIGATION.BRANCH_IN','GROUP.NAVIGATION.GROUP_IN_FARM'],
                                             BranchCardTabSection.Details);
  }

  public getPrintReportHeader() {
    return this.translationService.translate('MANAGE.TITLE.BRANCHES');
  }

  public getPrintIncludeDateInHeader(): boolean {
    return false;
  }
}
export class BranchItem extends DataRow {
  public branchName: string;
  public branchNumber: number;
  public groups: number;
  public animals: number;
  public tags: number;
}
