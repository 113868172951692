import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {UtilsService} from '../../../../../../services/utils/utils.service';

@Component({
  selector: 'cell-entity',
  templateUrl: './cell-entity.component.html',
  styleUrls: ['./cell-entity.component.scss']
})
export class CellEntityComponent implements OnInit {

  @Input()
  public entityText : string;

  @Input()
  public isFirstElement : boolean;

  @Input()
  public isLastElement : boolean;

  @Input()
  public lineHeight : number;

  @Input()
  public maxWidth : number;

  public isVisible : boolean = true;

  public displayThreeDots : boolean = false;

  private elementWidth : number;

  private elementHeight : number;

  constructor(public elementRef: ElementRef<HTMLElement>,
              private utilsService: UtilsService) {
  }

  public ngOnInit() {
    setTimeout(() => this.resolveElementSize());
  }

  public get elementRect() : ClientRect {
    if(this.elementWidth == null ||
       this.elementHeight == null) {
      return {
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: 0,
        height: 0
      }
    }
    let clientRect = this.elementRef.nativeElement.getBoundingClientRect();
    return {
      left: clientRect.left,
      top: clientRect.top,
      bottom: clientRect.top + this.elementHeight,
      right: clientRect.left + this.elementWidth,
      width: this.elementWidth,
      height: this.elementHeight
    }
  }

  private resolveElementSize() {
    if(this.elementRef == null ||
       this.elementRef.nativeElement == null) {
      setTimeout(() => this.resolveElementSize);
      return;
    }
    let elementRect = this.elementRef.nativeElement.getBoundingClientRect();
    if(!this.utilsService.isVisibleDomElement(elementRect)) {
      setTimeout(() => this.resolveElementSize);
      return;
    }
    this.elementWidth = elementRect.width;
    this.elementHeight = elementRect.height;
  }
}
