import {Component, Input, OnInit} from '@angular/core';
import {IReport} from '../../../../../../services/reports/model/report.interface';
import {IDashboardWidget} from '../../../../../../services/dashboard/model/dashboard-widgets.interface';

@Component({
  selector: 'accordion-report-widget-header',
  templateUrl: './accordion-report-widget-header.component.html',
  styleUrls: ['./accordion-report-widget-header.component.scss']
})
export class AccordionReportWidgetHeaderComponent implements OnInit {

  @Input()
  public report:IReport;
  
  @Input()
  public reportWidget:IDashboardWidget;
  
  constructor() { }

  public ngOnInit() {
  
  }
}
