import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnimalsService} from "../../../../../../../services/animals/animals.service";
import {FinishingAddFemaleModel} from "../add-female-finishing.component";
import {AddAnimalFieldsErrorsEnum, IErrors} from "../../../../../../../services/animals/model/animal.model";
import {DropdownListItem} from "../../../../../../../common/components/dropdown-list/dropdown-list-model";
import {EpochStateModel} from "../../../../../../../common/components/calendar/model/epoch-state-model";

@Component({
  selector: 'add-female-finishing-farm-tab',
  templateUrl: './add-female-finishing-farm-tab.component.html',
  styleUrls: ['./add-female-finishing-farm-tab.component.scss']
})
export class AddFemaleFinishingFarmTabComponent implements OnInit {

  public AddAnimalFieldsErrorsEnum = AddAnimalFieldsErrorsEnum;

  @Input()
  public addFinishingFemaleModel: FinishingAddFemaleModel;

  @Input()
  public groupsDropdownListItems: DropdownListItem[];

  @Input()
  public errors: IErrors[];

  @Input()
  public isKgValueDisplayed: boolean;

  @Output()
  public onClearErrors: EventEmitter<void> = new EventEmitter<void>();

  constructor(public animalsService: AnimalsService) { }

  public ngOnInit() {
  }

  public clearErrors() {
    this.onClearErrors.emit();
  }

  public onGroupSelected(selectedGroup: DropdownListItem) {
    if (!this.addFinishingFemaleModel.group) {
      this.clearErrors();
    }
    this.addFinishingFemaleModel.group = selectedGroup;
  }

  public get hasErrorForBirthdateField() {
    return this.animalsService.hasErrorForField(this.errors, this.AddAnimalFieldsErrorsEnum.birthDate) || (this.errors && this.errors[0] && this.errors[0].key === 'HerdEntryAndBirthDateAreMissing');
  }

  public onEntryDateChanged(entryDate: EpochStateModel) {
    this.addFinishingFemaleModel.entryDate = entryDate;
    this.addFinishingFemaleModel.updateDatesRanges();
    this.clearErrors();
  }

  public onbirthDateChanged(birthDate: EpochStateModel) {
    this.addFinishingFemaleModel.birthDate = birthDate;
    this.addFinishingFemaleModel.updateDatesRanges();
    this.clearErrors();
  }

  public isBirthdateAndEntryDateNotDefined() {
    return (this.addFinishingFemaleModel.birthDate.epochDate == null && this.addFinishingFemaleModel.entryDate.epochDate == null);
  }

  public onWeighingChanged(weighingDate: EpochStateModel) {
    this.addFinishingFemaleModel.weighing = weighingDate;
    this.addFinishingFemaleModel.updateDatesRanges();
    this.clearErrors();
  }
}
