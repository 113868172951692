import * as _ from 'lodash';
import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {NotificationTimingDialogService} from '../notification-timing-dialog/notification-timing-dialog.service';
import {
  FarmAlertConfig,
  FarmAlertConfigData,
  IBreedingFarmAlerts,
  IFarmAlertError,
  ManageSettingsService
} from '../../../../../../services/manage/manage-settings.service';
import {GoogleAnalyticsService} from '../../../../../../services/google-analytics/google-analytics.service';
import {EditBarService} from '../../../../../../services/edit-bar/edit-bar.service';
import {UserPermissionsService} from '../../../../../../services/auth/user-permissions.service';

@Component({
  selector: 'manage-settings-notifications-alerts-breeding',
  templateUrl: './alerts-breeding.component.html',
  styleUrls: ['./alerts-breeding.component.scss']
})
export class ManageSettingsNotificationsAlertsBreedingComponent implements OnInit, OnDestroy {

  private masterAlerts: IBreedingFarmAlerts;

  public canEdit!: boolean;

  public model: IBreedingFarmAlerts;

  public modelData: FarmAlertConfigData[];

  public error: IFarmAlertError;

  public readonly editAreaName: string = 'alerts';

  constructor(private manageSettingsService: ManageSettingsService,
              private googleAnalyticsService: GoogleAnalyticsService,
              private readonly editBarService: EditBarService,
              private notificationTimingDialogService: NotificationTimingDialogService,
              private userPermissionsService: UserPermissionsService) {}

  public async ngOnInit() {
    this.canEdit = this.userPermissionsService.hasManageNotificationsChangePermissions();
    this.modelData = [];
    this.getFarmAlerts();
  }

  private async getFarmAlerts(): Promise<void> {
    const farmAlertsResponse = await this.manageSettingsService.getBreedingFarmAlerts();
    // TODO: Create custom Lodash lightweight build with lodash CLI https://lodash.com/custom-builds
    this.masterAlerts = _.cloneDeep(farmAlertsResponse.responseBody);
    this.model = _.cloneDeep(farmAlertsResponse.responseBody);
    this.editBarService.registerOnSaveFunction(null);
    this.editBarService.registerOnResetFunction(null);
    this.updateModelData();
  }

  public ngOnDestroy() {
    this.reset();
  }

  public checkAlertErrorsForField(fieldName: string): boolean {
    return this.error && this.error.fieldName === fieldName;
  }

  public changeCategoryAlert(category: string, alertType: string): void {
    this.model[category][alertType] = !this.model[category][alertType];
    this.updateCategoryAlert(category, alertType);
    this.dirtyCheck();
    this.clearError();
  }

  private updateCategoryAlert(category: string, alertType: string): void {
    const farmAlertConfigData = this.modelData.find((modelDataItem: FarmAlertConfigData) => modelDataItem.key === category);
    if (farmAlertConfigData) {
      farmAlertConfigData.value[alertType] = !farmAlertConfigData.value[alertType];
    }
  }

  public clearError(): void {
    this.error = null;
  }

  public async save() {
    const farmAlertsResponse = await this.manageSettingsService.sendBreedingFarmAlerts(this.model);
    if (farmAlertsResponse.status !== 400) {
      this.masterAlerts = _.cloneDeep(this.model);
      this.googleAnalyticsService.send("Manage Settings","Save", "Alerts");
      this.editBarService.registerOnResetFunction(null);
      this.clearError();
      this.dirtyCheck();
    } else {
      this.error = <IFarmAlertError>farmAlertsResponse.errorResponseBody.result;
    }
  }

  public reset(): void {
    this.model = _.cloneDeep(this.masterAlerts);
    this.updateModelData();
    this.editBarService.resetEditBar();
    this.clearError();
  }

  private dirtyCheck(): void {
    const noChangesDetected = _.isEqual(this.masterAlerts, this.model);
    this.editBarService.registerOnSaveFunction(noChangesDetected ? null : this.save.bind(this), this.editAreaName);
    this.editBarService.registerOnResetFunction(noChangesDetected ? null : this.reset.bind(this));
  }

  public isFieldEditable(): boolean {
    return this.userPermissionsService && !this.userPermissionsService.hasUserPermissionsForEditManageSettings();
  }

  private scrollTo(element: ElementRef) {
    if (element) {
      element.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  public onNotificationTimingPlateClick(farmAlertConfigData: FarmAlertConfigData): void {
    if (this.canEdit) {
      this.notificationTimingDialogService.show(_.cloneDeep(farmAlertConfigData));
    }
  }

  public updateModelData(): FarmAlertConfigData[] {
    if (!this.model) {
      this.modelData = [];
      return;
    }
    this.modelData = Object.keys(this.model).map((k: string) => {
      const farmAlertConfigData = this.model[k];

      if(farmAlertConfigData == null) {
        return null;
      }

      return new FarmAlertConfigData(k, new FarmAlertConfig(
        farmAlertConfigData.email,
        farmAlertConfigData.mobile,
        farmAlertConfigData.sms,
        farmAlertConfigData.isAlways,
        farmAlertConfigData.startTime,
        farmAlertConfigData.endTime
      ));
    }).filter(value => value != null);;
  }

  public onDialogApply(farmAlertConfigData: FarmAlertConfigData): void {
    this.model[farmAlertConfigData.key] = farmAlertConfigData.value.getValues();
    this.updateModelData();
    this.notificationTimingDialogService.hide();
    this.dirtyCheck();
  }

  public onDialogCancel(): void {
    this.notificationTimingDialogService.hide();
  }
}
