<modal-dialog-template [isOpen]="isOpen"
                       [modalWidth]="600"
                       [modalHeight]="300"
                       (onEscapeButtonClick)="close()"
                       (onEnterButtonClick)="proceed()">
  <div class="notification-dialog remove-data-warning-dialog">
    <div class="dialog-close">
      <div class="close-icon" (click)="close()"></div>
    </div>
    <div class="dialog-header">
      <div class="title-wrapper">
        <div class="icon-alerts"></div>
        <div class="title">
          {{dialogTitle | translate}}
        </div>
      </div>
    </div>
    <div class="dialog-body">
      <div class="message">
        {{message | translate}}
      </div>
      <div class="message additional-description">
        {{messageAdditional | translate}}
      </div>
      <div class="message additional-description2">
        {{messageAdditional2 | translate}}
      </div>
      <div class="actions">
        <div class="btn-cancel" (click)="close()">
          {{buttonCloseText | translate}}
        </div>
        <div class="btn-confirm" (click)="proceed()">
          {{buttonProceedText | translate}}
          <div class="icon-remove"></div>
        </div>
      </div>
    </div>
  </div>
</modal-dialog-template>
