import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NotificationTimingDialogService} from './notification-timing-dialog.service';
import {Subscription} from 'rxjs';
import {FarmAlertConfigData} from '../../../../../../services/manage/manage-settings.service';
import { RadioButtonColorTheme } from 'src/app/common/components/radio-button/radio-button.component';
import {TranslationService} from '../../../../../../services/translations/translation.service';

export enum AlertTimingFrequency {
  always = 'always',
  byHours = 'byHours'
}

@Component({
  selector: 'notification-timing-dialog',
  templateUrl: './notification-timing-dialog.component.html',
  styleUrls: ['./notification-timing-dialog.component.scss']
})
export class NotificationTimingDialogComponent implements OnInit, OnDestroy {

  public farmAlertConfigData: FarmAlertConfigData;

  private subscription: Subscription;

  public isOpen: boolean = false;

  public labelAlways!: string;

  public labelByHours!: string;

  @Output()
  public onApply: EventEmitter<FarmAlertConfigData> = new EventEmitter<FarmAlertConfigData>();

  @Output()
  public onCancel: EventEmitter<void> = new EventEmitter<void>();

  public RadioButtonColorTheme = RadioButtonColorTheme;

  public AlertTimingFrequency = AlertTimingFrequency;

  public get isAlways(): boolean {
    return this.farmAlertConfigData && this.farmAlertConfigData.value && this.farmAlertConfigData.value.isAlways;
  }

  public get isInvalid(): boolean {
    if (this.isAlways) {
      return false;
    }
    return this.farmAlertConfigData && this.farmAlertConfigData.value
      && ((this.farmAlertConfigData.value.startTime.epochTime == null && this.farmAlertConfigData.value.startTime.epochTime !== 0)
      || (this.farmAlertConfigData.value.endTime.epochTime == null && this.farmAlertConfigData.value.endTime.epochTime !== 0));
  }

  constructor(private notificationTimingService: NotificationTimingDialogService, private translationService: TranslationService) {
    this.labelAlways = this.translationService.translate('MANAGE.SETTINGS.ALERTS.TIMING.ALWAYS');
    this.labelByHours = this.translationService.translate('MANAGE.SETTINGS.ALERTS.TIMING.BY_HOURS');
  }

  public ngOnInit(): void {
    this.subscription = this.notificationTimingService.popupShown.subscribe((farmAlertConfigData: FarmAlertConfigData) => {
      if (farmAlertConfigData != null) {
        this.farmAlertConfigData = farmAlertConfigData;
        this.isOpen = true;
      } else {
        this.isOpen = false;
        this.farmAlertConfigData = null;
      }
    });
  }

  public handleClose(): void {
    this.onCancel.emit();
  }

  public handleApply(): void {
    this.onApply.emit(this.farmAlertConfigData);
  }

  public onFrequencySelect(frequency: AlertTimingFrequency): void {
    this.farmAlertConfigData.value.isAlways = frequency == AlertTimingFrequency.always;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.isOpen = false;
  }
}

