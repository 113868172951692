import { Component, OnInit } from '@angular/core';
import {CalvingEventRow} from '../../../../../services/animals/model/animal-events';
import {DescriptionCellComponent} from '../../../../../common/components/data-grid/description-cell-template/description-cell.component';

@Component({
  selector: 'calving-event-description',
  templateUrl: './calving-event-description.component.html',
  styleUrls: ['./calving-event-description.component.scss']
})
export class CalvingEventDescriptionComponent extends DescriptionCellComponent<CalvingEventRow> implements OnInit {

  constructor() {
    super();
  }

  public ngOnInit() {
  }

  public get hasDetails() : boolean {
    return this.row.daysInPregnancy != null ||
           this.row.calvingEase != null ||
           this.row.destinationGroupName != null  ||
          (this.row.newborns != null &&
           this.row.newborns.length > 0);
  }

}
