import {Injectable} from '@angular/core';
import {ConfigService} from '../config/config.service';
import {HttpResponseResult, RestApiService} from '../rest-api/rest-api.service';
import {ServerConfig} from '../config/model/server-config';
import {AnonymousAuthDetailsResult, SystemStateEnum} from '../config/model/anonymous-auth-config';
import {HeaderAuthorizationDetails} from '../rest-api/models/header-authorization-details';
import {ISignEulaModel} from './model/eula/sign-eula-model.interface';

export class LoginResult {
  public authorized: boolean;
  public isEulaRequired: boolean;
}

class UserAuthState {
  public authDetails: HeaderAuthorizationDetails;
  public loginResult: LoginResult;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private static readonly AnonymousAuthDetailsRouteV2 = '/rest/api/v2/auth';

  private static readonly LoginRouteV2 = '/rest/api/v2/auth/login';
  private static readonly ConfigRouteV3 = '/rest/api/v3/auth/configuration';
  private static readonly EulaRouteV2 = '/rest/api/v2/auth/eula';

  private static readonly AuthDetailsStorageKey = 'uc';

  public currentUserAuthState : UserAuthState;
  public isTrialModeDashboardNavigationAllowed: boolean = false;
  public isPressedOnRegister: boolean = false;

  public anonymousAuthKnownFarmDetails: HeaderAuthorizationDetails;

  private isRememberUserToggledOn: boolean = false;

  constructor(private restApiService: RestApiService,
              public configService: ConfigService) {

  }

  public async getLoginStatus(): Promise<LoginStatus> {
    let sessionStoredAuthDetails = this.getAuthDetailsFromSessionStorage();
    let localStorageAuthDetails = this.getAuthDetailsFromLocalStorage();
    if(!this.configService.configuration.cloud) {
      await this.performAnonymousLogin();
    }
    // check if the system is active or suspended
    if (this.configService.configuration.cloud ||
        (this.configService.anonymousAuthResult &&
          (this.configService.anonymousAuthResult.systemState === SystemStateEnum.active ||
           this.configService.anonymousAuthResult.systemState === SystemStateEnum.suspended))) {
      if(sessionStoredAuthDetails != null) {
        let response = await this.performLogin(sessionStoredAuthDetails, localStorageAuthDetails != null);
        if(response.responseBody &&
          response.responseBody.authorized &&
          !response.responseBody.isEulaRequired){
          return LoginStatus.LoggedIn;
        } else {
          if (response.responseBody &&
              response.responseBody.isEulaRequired) {
            return LoginStatus.EulaRequired;
          } else {
            return LoginStatus.NotLoggedIn;
          }
        }
      } else {
        return LoginStatus.NotLoggedIn;
      }
    }
    // check if system is not active
    else if (this.configService.anonymousAuthResult) {
      if (this.configService.anonymousAuthResult.flowStep === 'signUp') {
        return LoginStatus.NotSignedUp;
      } else {
        return LoginStatus.NotRegistered;
      }
    }
    // system is in error state (wildfly is down/server error)
    else {
      return LoginStatus.ServerError;
    }

  }

  public async performAnonymousLogin() {
    let responseResult:  HttpResponseResult<AnonymousAuthDetailsResult> = await this.restApiService.sendGetMethod<AnonymousAuthDetailsResult>(AuthService.AnonymousAuthDetailsRouteV2,
                                                                                                                                              ConfigService.anonymousAuthUnknownFarmDetails);
    if(responseResult.responseBody) {
      this.configService.anonymousAuthResult = responseResult.responseBody;
      this.anonymousAuthKnownFarmDetails = {
        userName: ConfigService.anonymousAuthUnknownFarmDetails.userName,
        password: ConfigService.anonymousAuthUnknownFarmDetails.password,
        farmId: responseResult.responseBody.farmId
      };
    } else {
      this.configService.anonymousAuthResult = null;
      this.anonymousAuthKnownFarmDetails = null;
    }
  }

  public performLogin(authDetails: HeaderAuthorizationDetails,
                      rememberUser: boolean): Promise<HttpResponseResult<LoginResult>> {
    return new Promise(resolve => {
      this.restApiService.sendGetMethod<LoginResult>(AuthService.LoginRouteV2, authDetails).then((loginResponse: HttpResponseResult<LoginResult>) => {
        if(loginResponse.responseBody &&
           loginResponse.responseBody.authorized) {
           this.storeAuthDetails(authDetails, loginResponse.responseBody, rememberUser);
           if(!loginResponse.responseBody.isEulaRequired) {
             this.getServerConfig().then(configResponse => {
               resolve(loginResponse);
             });
           }
           else {
             resolve(loginResponse);
           }
        }
        else {
          resolve(loginResponse);
        }
      });
    });
  }

  public getServerConfig() : Promise<HttpResponseResult<ServerConfig>> {
    return this.restApiService.sendGetMethod<ServerConfig>(AuthService.ConfigRouteV3, this.getAuthDetailsFromSessionStorage())
                       .then((response: HttpResponseResult<ServerConfig>) => {
      this.configService.serverConfig = response.responseBody;
      return response;
    });
  }

  public performLogout() {
    window.sessionStorage.removeItem(AuthService.AuthDetailsStorageKey);
    this.currentUserAuthState = null;
    this.configService.serverConfig = null;
  }

  public getAuthDetailsFromLocalStorage() : HeaderAuthorizationDetails {
    const authDetailsBase64 = window.localStorage.getItem(AuthService.AuthDetailsStorageKey);
    if (authDetailsBase64 === null){
      return null;
    }
    const authDetails = window.atob(authDetailsBase64);
    if (authDetails) {
      try {
        return JSON.parse(authDetails);
      } catch (e) {
        return null;
      }
    }
    return null;
  }

  private getAuthDetailsFromSessionStorage(): HeaderAuthorizationDetails {
    const authDetailsBase64 = window.sessionStorage.getItem(AuthService.AuthDetailsStorageKey);
    if (authDetailsBase64 === null) {
      return null;
    }
    const authDetails = window.atob(authDetailsBase64);
    if (authDetails) {
      try {
        return JSON.parse(authDetails);
      } catch (e) {
        return null;
      }
    }
    return null;
  }

  public getEulaDetails(): Promise<HttpResponseResult<ISignEulaModel>> {
    return this.restApiService.sendGetMethod<ISignEulaModel>(AuthService.EulaRouteV2, this.currentUserAuthState.authDetails);
  }

  // tslint:disable-next-line:no-any
  public sendUserEulaResponse(request: ISignEulaModel): Promise<HttpResponseResult<any>> {
    return new Promise((resolve, reject) => {
      this.restApiService.sendPostMethod<ISignEulaModel>(AuthService.EulaRouteV2,this.currentUserAuthState.authDetails,request)
        .then(response => {
          if (response.status === 201) {
            this.performLogin(this.currentUserAuthState.authDetails, this.getAuthDetailsFromLocalStorage() != null).then(value => {
              resolve(response);
            });
          } else {
            reject(response);
          }
        }).catch((error) => {
          reject(error);
      });
    });
  }

  public isRegistered(): boolean {
    return (this.configService.anonymousAuthResult.systemState === SystemStateEnum.active);
  }

  private storeAuthDetails(authDetails: HeaderAuthorizationDetails, loginResult: LoginResult, rememberUser: boolean) {
    this.currentUserAuthState = new UserAuthState();
    this.currentUserAuthState.authDetails = authDetails;
    this.currentUserAuthState.loginResult = loginResult;
    this.isRememberUserToggledOn = rememberUser;
    const authDetailsEncoded = window.btoa(JSON.stringify(authDetails));
    window.sessionStorage.setItem(AuthService.AuthDetailsStorageKey, authDetailsEncoded);
    if (rememberUser) {
      window.localStorage.setItem(AuthService.AuthDetailsStorageKey, authDetailsEncoded);
    } else {
      window.localStorage.removeItem(AuthService.AuthDetailsStorageKey);
    }
  }

  public setIsStartedTrialModeForTheFirstTime(isActivatedTrialModeForTheFirstTime: boolean) {
    const trialModeStatus: ITrialModeStatus = {
      isStartedTrialModeForTheFirstTime: isActivatedTrialModeForTheFirstTime
    };
    window.localStorage.setItem('trialModeStatus', btoa(JSON.stringify(trialModeStatus)));
  }

  public getTrialModeStatus(): ITrialModeStatus {
    const item = window.localStorage.getItem('trialModeStatus');
    if (item) {
      const trialModeStatus: ITrialModeStatus = JSON.parse(atob(item));
      return trialModeStatus;
    } else {
      return null;
    }
  }

  public updateCurrentUserPassword(password: string): void {
    this.storeAuthDetails(Object.assign({}, this.currentUserAuthState.authDetails, { password }) as HeaderAuthorizationDetails, this.currentUserAuthState.loginResult, this.isRememberUserToggledOn);
  }
}

export enum LoginStatus {
  LoggedIn = 'LoggedIn',
  NotLoggedIn = 'NotLoggedIn',
  EulaRequired = 'EulaRequired',
  NotSignedUp = 'NotSignedUp',
  NotRegistered = 'NotRegistered',
  ServerError = 'ServerError'
}
export interface ITrialModeStatus {
  isStartedTrialModeForTheFirstTime: boolean;
}
