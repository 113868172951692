<ng-container *ngIf="farmStatistics">
  <div class="farm-statistics-finishing-wrapper">
    <div class="inner-left">
      <div class="inner-left-wrapper">
        <div class="text-animals" [attr.translation-id]="'DASHBOARD.CHART.COWS'">
          {{ 'DASHBOARD.CHART.COWS' | translate }}
        </div>
        <div class="value">
          {{ farmStatistics.totalFemales + farmStatistics.totalMales }}
        </div>
        <div class="icon-text-counters">
          <div class="icon-text-item">
            <div class="text">{{ farmStatistics.totalFemales }}</div>
            <div class="finishing-icon female"></div>
          </div>
          <div class="icon-text-item">
            <div class="text">{{ farmStatistics.totalMales }}</div>
            <div class="finishing-icon male"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner-center">
      <div class="bar-charts-wrapper">
        <farm-statistics-finishing-bar-chart *ngFor="let data of chartData; last as isLast"
                                             [data]="data"
                                             [isLast]="isLast">
        </farm-statistics-finishing-bar-chart></div>
    </div>
  </div>
</ng-container>
