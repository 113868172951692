import {AnimalDetailsField, IAnimalInfoMetadata} from '../../../../../services/animals/model/animal.model';
import {EpochStateModel} from '../../../../../common/components/calendar/model/epoch-state-model';
import * as _ from 'lodash';

export class AnimalDetailsSectionViewState {
  public category: string;

  public isExpanded : boolean;

  public isEditMode : boolean;

  public errorKey: string;

  public errorField: string;

  public fieldsViewStates: AnimalDetailsFieldViewState[];
}

export abstract class AnimalDetailsFieldViewState {

  private readonly initialFieldDetails:AnimalDetailsField;

  constructor(public readonly fieldDetails:AnimalDetailsField) {
    this.initialFieldDetails = _.cloneDeep(fieldDetails);
  }

  // tslint:disable-next-line:no-any
  public abstract get editValue() : any;

  // tslint:disable-next-line:no-any
  public abstract updateValue(value:any);

  public get isFieldValueEqual() : boolean {
    return _.isEqual(this.fieldDetails, this.initialFieldDetails);
  }
}

export class AnimalDetailsValueFieldViewState extends AnimalDetailsFieldViewState {
  // tslint:disable-next-line:no-any
  public updateValue(value: any) {
    this.fieldDetails.value = value;
  }

  // tslint:disable-next-line:no-any
  public get editValue(): any {
    return this.fieldDetails.value;
  }
}

export class AnimalDetailsDamFieldViewState extends AnimalDetailsFieldViewState{
  constructor(private readonly animalInfoMetadata: IAnimalInfoMetadata, fieldDetails:AnimalDetailsField) {
    super(fieldDetails);
  }

  // tslint:disable-next-line:no-any
  public updateValue(value: any) {
    let dam = this.animalInfoMetadata.damList.find(dam => dam.id == value);
    if(this.fieldDetails.value != null ||
                           dam != null) {
      this.fieldDetails.value = dam;
    }
  }

  // tslint:disable-next-line:no-any
  public get editValue(): any {
    return this.fieldDetails.value;
  }
}

export class AnimalDetailsGroupFieldViewState extends AnimalDetailsFieldViewState{
  constructor(private readonly animalInfoMetadata: IAnimalInfoMetadata, fieldDetails:AnimalDetailsField) {
    super(fieldDetails);
  }

  // tslint:disable-next-line:no-any
  public updateValue(value: any) {
    let group = this.animalInfoMetadata.groups.find(group => group.id == value);
    if(this.fieldDetails.value != null ||
      group != null) {
      this.fieldDetails.value = group;
    }
  }

  // tslint:disable-next-line:no-any
  public get editValue(): any {
    return this.fieldDetails.value;
  }
}

export class AnimalDetailsDateFieldViewState extends AnimalDetailsFieldViewState{

  constructor(public readonly isDateOnly: boolean,
              public readonly epochState: EpochStateModel,
              fieldDetails:AnimalDetailsField) {
    super(fieldDetails);
  }

  public updateValue() {
    if(this.isDateOnly) {
      this.fieldDetails.value = this.epochState.epochDate;
    } else {
      this.fieldDetails.value = this.epochState.epoch;
    }
  }

  public get editValue(): EpochStateModel {
    return this.epochState;
  }
}

export class AnimalDetailsTimeFieldViewStateModel extends AnimalDetailsFieldViewState{

  constructor(public readonly epochState: EpochStateModel,
              fieldDetails:AnimalDetailsField) {
    super(fieldDetails);
  }

  public updateValue() {
    this.fieldDetails.value = this.epochState.epoch;
  }

  public get editValue(): EpochStateModel {
    return this.epochState;
  }
}
