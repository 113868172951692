<div class="section-field name-field">
  <div class="title-div"
       [attr.sh-id]="'led-task-name-title'"
       [ngClass]="{'disabled': ledTasksService.isFieldError(LedTaskFields.animalTaskName)}"
       [attr.translation-id]="'LED_TASKS.TASK_EDITOR.NAME'">{{ 'LED_TASKS.TASK_EDITOR.NAME' | translate }}</div>
  <input class="name-input"
         [maxLength]="50"
         [(ngModel)]="ledTasksService.taskModel.selectedTaskName"
         (ngModelChange)="ledTasksService.clearErrors()"
         [ngClass]="{ disabled: ledTasksService.isFieldError(LedTaskFields.animalTaskName) }"
         [placeholder]="'Enter Name...'"/>
</div>
<div class="section-field animals-field">
  <div class="title-div" [attr.sh-id]="'led-task-animals-title'" [attr.translation-id]="'MANAGE.TITLE.ANIMALS'">{{ 'MANAGE.TITLE.ANIMALS' | translate }}</div>
  <div class="animals-autocomplete-div">
    <regular-auto-complete
      #animalsAutoComplete
      [isFieldError]="ledTasksService.isFieldError(LedTaskFields.animalsSelection)"
      [hasIcon]="true"
      [shIdPostfix]="'animals-led-tasks'"
      [colorScheme]="AutoCompleteColorScheme.GreyBlue"
      [placeholder]="'Select Animal...'"
      [isAllowUnmatchedSelection]="false"
      [caseInvariant]="true"
      [items]="animalsList"
      [maxResults]="6"
      (editingCompleted)="onAnimalsSelected($event)"
      [searchQuery]="animalSearchQuery"
    ></regular-auto-complete>
  </div>
  <chip-list class="animals-list-div">
    <ng-container *ngFor="let animal of ledTasksService.taskModel.selectedAnimals">
      <chips-item [chipItem]="animal" (onRemoveItemClicked)="removeSelectedAnimal(animal)"></chips-item>
    </ng-container>
  </chip-list>
</div>
