import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {AnimalDetailsField, IAnimalInfoMetadata} from '../../../../../services/animals/model/animal.model';
import {BullColorTheme, BullListItem} from '../../../../../common/components/bull-choose-dropdown-list/bull-choose-dropdown-list.component';
import {DropdownListItem} from '../../../../../common/components/dropdown-list/dropdown-list-model';
import {Subscription} from 'rxjs';
import {UtilsService, WeightUnitType} from '../../../../../services/utils/utils.service';
import {TranslationService} from '../../../../../services/translations/translation.service';
import {
  AnimalDetailsDateFieldViewState,
  AnimalDetailsFieldViewState,
  AnimalDetailsSectionViewState,
  AnimalDetailsTimeFieldViewStateModel
} from '../model/animal-card-details-view-state';
import {AlignPopupType} from '../../../../../common/components/popups/popup-template/popup-template.component';
import {SaveDataViewStateService} from '../../../../../services/ui/view-state/save-data-view-state.service';
import {ConfigService} from '../../../../../services/config/config.service';
import {FarmRole} from '../../../../../services/auth/model/farm-role.enum';
import {FormsDLIconsThemes} from '../../../../../common/components/dropdown-list/forms-dropdown-list/forms-dropdown-list.component';

@Component({
  selector: 'animal-card-details-edit-section',
  templateUrl: './animal-card-details-edit-section.component.html',
  styleUrls: ['./animal-card-details-edit-section.component.scss']
})
export class AnimalCardDetailsEditSectionComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public viewState: AnimalDetailsSectionViewState;

  @Input()
  public animalInfoMetadata:IAnimalInfoMetadata;

  @Input()
  public performingSave: boolean;

  @Output()
  public onRevertChanges: EventEmitter<void> = new EventEmitter();

  @Output()
  public onSaveChanges: EventEmitter<AnimalDetailsSectionViewState> = new EventEmitter();

  @Output()
  public onRequestEdit: EventEmitter<string> = new EventEmitter();

  @Output()
  public onSectionExpanded: EventEmitter<string> = new EventEmitter();

  public editableBullItemList: BullListItem[];

  public groupsDropdownListItems: DropdownListItem[] = [];

  public damDropdownListItems: DropdownListItem[] = [];

  private onApproveDataLossClickSubscription: Subscription;

  public alignEnd: AlignPopupType = AlignPopupType.end;

  public bullColorTheme = BullColorTheme;

  public formsDLIconsThemes = FormsDLIconsThemes;

  constructor(private saveDataViewStateService: SaveDataViewStateService,
              private utilsService: UtilsService,
              private configService: ConfigService,
              private translationService: TranslationService) { }

  public ngOnInit() {
    this.onApproveDataLossClickSubscription = this.saveDataViewStateService.onApproveDataLossClick.subscribe(() => {
      if(this.saveDataViewStateService.activeEditArea == this.viewState.category) {
        this.undo();
      }
    })
  }

  public ngOnDestroy(): void {
    this.onApproveDataLossClickSubscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.animalInfoMetadata &&
        changes.animalInfoMetadata.previousValue != changes.animalInfoMetadata.currentValue) {
      this.groupsDropdownListItems = [];
      this.damDropdownListItems = [];
      this.editableBullItemList = [];
      if(this.animalInfoMetadata != null) {

        if(this.animalInfoMetadata.groups) {
          this.groupsDropdownListItems = this.animalInfoMetadata.groups.map(group => {
            return {
              disabled: false,
              icon: null,
              displayValue: group.name,
              value: group.id
            }
          });
        }

        if(this.animalInfoMetadata.damList) {
          this.damDropdownListItems = this.animalInfoMetadata.damList.map(dam => {
            return {
              disabled: false,
              icon: null,
              displayValue: dam.name,
              value: dam.id
            }
          });
        }

        if(this.animalInfoMetadata.sireList) {
          this.editableBullItemList = this.animalInfoMetadata.sireList.map(value => {
            return {
              id: value.id,
              number: value.number,
              name: value.name
            };
          });
        }
      }
    }
    if(changes.performingSave.previousValue == true &&
       changes.performingSave.currentValue == false) {
      if(this.viewState.isEditMode &&
         this.viewState.errorKey == null) {
        this.viewState.isEditMode = false;
        this.saveDataViewStateService.activeEditArea = null;
      }
    }
  }

  public toggleSectionExpansion() {
    this.viewState.isExpanded = !this.viewState.isExpanded;
    if(this.viewState.isExpanded) {
      this.onSectionExpanded.emit(this.viewState.category);
    }
  }

  public edit() {
    this.onRequestEdit.emit(this.viewState.category);
    this.saveDataViewStateService.activeEditArea = null;
  }

  public save() {
    this.onSaveChanges.emit(this.viewState);
  }

  public undo() {
    this.viewState.isEditMode = false;
    this.viewState.errorKey = null;
    this.viewState.errorField = null;
    this.onRevertChanges.emit();
    this.saveDataViewStateService.activeEditArea = null;
  }

  public get visibleFields() : AnimalDetailsFieldViewState[] {
    if(this.viewState.isExpanded)
      return this.viewState.fieldsViewStates;
    else {
      return this.viewState.fieldsViewStates.slice(0, 2);
    }
  }

  public get isEditControlsVisible(): boolean {
    return !this.performingSave && this.viewState.fieldsViewStates.some(value => value.fieldDetails.isEditable);
  }

  public isAnimalNameField(field: AnimalDetailsField) : boolean {
    return field.name === 'AnimalName';
  }

  public isBranchField(field: AnimalDetailsField): boolean {
    return field.name === 'Branch';
  }

  public isInputTextField(field: AnimalDetailsField) : boolean {
    return (field.name !== 'AnimalName' &&
            field.name !== 'Branch' &&
          (field.editType == null ||
           field.editType == 'Number' ||
           field.editType == 'Text'));
  }

  public isInputDropdownList(field: AnimalDetailsField) : boolean {
    return field.name === 'Group' ||
           field.name === 'Dam';
  }

  public isInputBullChooseList(field: AnimalDetailsField): boolean {
    return field.name === 'SireChoice1' ||
           field.name === 'SireChoice2' ||
           field.name === 'SireChoice3' ||
           field.name === 'Sire';
  }

  public isInputAutocomplete(field: AnimalDetailsField) : boolean {
    return field.name === 'RfidTag' ||
      field.name === 'ScrTag';
  }

  public isInputWeightKG(field: AnimalDetailsField): boolean {
    return (field.name === 'Weight' && this.isKgValueDisplayed());
  }

  public isInputWeightLBS(field: AnimalDetailsField): boolean {
    return field.name === 'Weight' && this.isLBSValueDisplayed();
  }

  private isKgValueDisplayed() {
    return this.utilsService.getUnitType() === WeightUnitType.KG;
  }

  private isLBSValueDisplayed() {
    return this.utilsService.getUnitType() === WeightUnitType.LBS;
  }

  public getAutocompleteFieldItems(field: AnimalDetailsField) : string[] {
    if(field.name === 'RfidTag') {
      return this.animalInfoMetadata.rfIdTags;
    } else if(field.name === 'ScrTag') {
      return this.animalInfoMetadata.scrTags;
    }
  }

  public isFieldEditable(field: AnimalDetailsField) : boolean {
    if (this.configService.serverConfig.user.farmRole == FarmRole.ExternalUser) {
      return false;
    }
    if(this.isFieldInvalid(field)) {
      return false;
    }
    return this.viewState.isEditMode && field.isEditable;
  }

  public isFieldInvalid(field: AnimalDetailsField) : boolean {
    return field.name === this.viewState.errorField;
  }

  public getFieldTranslationKey(field: AnimalDetailsField) : string {
    if(this.isInputTextField(field) || this.isAnimalNameField(field)) {
      return 'ANIMAL.DETAILS.INFO.FIELD.' + field.name;
    } else if(field.name == 'BirthDate') {
      return 'ANIMAL.DETAILS.INFO.BIRTH_DATE';
    } else if(field.name == 'EntryDate') {
      return 'ANIMAL.DETAILS.INFO.ENTRY_DATE';
    } else if(field.name == 'LastCalvingDate') {
      return 'ANIMAL.DETAILS.INFO.LAST_CALVING_DATE';
    } else if(field.name == 'RfidTag') {
      return 'ANIMAL.DETAILS.INFO.RF_ID';
    } else if(field.name == 'ScrTag') {
      return 'ANIMAL.DETAILS.INFO.SCR';
    } else if(field.name == 'Group') {
      return 'ANIMAL.DETAILS.INFO.GROUP';
    } else if(field.name == 'SireChoice1') {
      return 'ANIMAL.DETAILS.INFO.SIRE_1';
    } else if(field.name == 'SireChoice2') {
      return 'ANIMAL.DETAILS.INFO.SIRE_2';
    } else if(field.name == 'SireChoice3') {
      return 'ANIMAL.DETAILS.INFO.SIRE_3';
    } else if(field.name == 'Dam') {
      return 'ANIMAL.DETAILS.INFO.DAM';
    } else if(field.name == 'Sire') {
      return 'ANIMAL.DETAILS.INFO.SIRE';
    } else if(field.name == 'Branch') {
      return 'ANIMAL.DETAILS.INFO.BRANCH';
    }
    return field.name;
  }

  public hasTimePicker(field: AnimalDetailsField) : boolean {
    return !(field.name == 'LastCalvingDate' ||
      field.name == 'EntryDate' ||
      field.name == 'BirthDate');
  }

  public getDateIsRemovable(field: AnimalDetailsField) : boolean {
    return !(field.name == 'EntryDate' ||
      field.name == 'BirthDate');
  }

  public getFieldSelectedBull(field: AnimalDetailsField) : BullListItem {
    if(field.value == null) {
      return null;
    } else {
      return {
        id: field.value.id,
        number: field.value.number,
        name: field.value.name
      }
    }
  }

  public setFieldSelectedBull(field: AnimalDetailsField, bullListItem: BullListItem) {
    field.value = bullListItem;
  }

  public getDropdownFieldItems(field: AnimalDetailsField) : DropdownListItem[] {
    if(field.name == 'Group') {
      return this.groupsDropdownListItems;
    } else if(field.name == 'Dam') {
      return this.damDropdownListItems;
    }
  }

  // tslint:disable-next-line:no-any
  public getFieldIdValue(field: AnimalDetailsField): any {
    if(field.value == null){
      return null;
    }
    if(field.name == 'Group') {
      return field.value.id;
    } else if(field.name == 'Dam') {
      return field.value.id;
    } else {
      return field.value;
    }
  }

  // tslint:disable-next-line:no-any
  public setFieldValue(field: AnimalDetailsFieldViewState, value: any){
    if(!field.fieldDetails.isEditable ||
       !this.viewState.isEditMode)
    {
      return;
    }
    field.updateValue(value);
    if(this.viewState.fieldsViewStates.find(details => !details.isFieldValueEqual) != null){
      this.saveDataViewStateService.activeEditArea = this.viewState.category;
    } else {
      this.saveDataViewStateService.activeEditArea = null;
    }
  }

  public getBranchFieldText(field: AnimalDetailsField) {
    if (field.isLocalization) {
      return this.translationService.translate('REPORTS.GRID.STATUS.' + field.value);
    } else {
      return field.value;
    }
  }

  public isDateField(field: AnimalDetailsFieldViewState) {
    return field instanceof AnimalDetailsDateFieldViewState;
  }

  public isTimeField(field: AnimalDetailsFieldViewState) {
    return field instanceof AnimalDetailsTimeFieldViewStateModel;
  }

  public onBullRemoved(bullListItem:BullListItem) {
    this.editableBullItemList = this.editableBullItemList.filter(value => value.id != bullListItem.id);
  }
}
