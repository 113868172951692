<div class="timeline-flow" (click)="toggleShowDetails()">
  <animal-card-timeline-header [header]="animalTimelineFlowModel.header"></animal-card-timeline-header>
  <ng-container *ngFor="let node of animalTimelineFlowModel.nodes; index as nodeIndex">
    <animal-card-timeline-node [nodeDetails]="node" [showDetails]="showDetails" [attr.sh-id]="'animal_card_timeline_node_' + nodeIndex"></animal-card-timeline-node>
    <animal-card-timeline-leg *ngIf="animalTimelineFlowModel.legs.length > nodeIndex"
                              [legDetails]="animalTimelineFlowModel.legs[nodeIndex]"
                              [attr.sh-id]="'animal_card_timeline_leg_' + nodeIndex"
                              [ngStyle]="{'width': animalTimelineFlowModel.legs[nodeIndex].legSizePercent + '%',
                                          'min-width': (animalTimelineFlowModel.legs[nodeIndex].legParts.length * 128) + 'px'}"></animal-card-timeline-leg>
  </ng-container>
</div>
