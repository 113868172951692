<notification-dialog-template #dialogRef
                              [isEscapeCloseDialog]="true"
                              [modalWidth]="580"
                              [modalHeight]="240"
                              [theme]="NotificationDialogTheme.blue"
                              [shIdPostfix]="'syncNowModalComponent'">
  <ng-container header>
    <div class="dialog-header">
      <div class="title-wrapper">
        <div class="icon icon-settings"></div>
        <div class="title" translation-id="SYSTEM.MANAGEMENT.DIALOGS.SYNC_NOW.TITLE">
          {{ 'SYSTEM.MANAGEMENT.DIALOGS.SYNC_NOW.TITLE' | translate }}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container body>
    <div class="dialog-body">
      <div class="description" translation-id="SYSTEM.MANAGEMENT.DIALOGS.SYNC_NOW.DESCRIPTION_1">
        {{ 'SYSTEM.MANAGEMENT.DIALOGS.SYNC_NOW.DESCRIPTION_1' | translate }}
        <span translation-id="SYSTEM.MANAGEMENT.DIALOGS.SYNC_NOW.DESCRIPTION_2">
          {{ 'SYSTEM.MANAGEMENT.DIALOGS.SYNC_NOW.DESCRIPTION_2' | translate }}
        </span>
      </div>
    </div>
  </ng-container>
</notification-dialog-template>
