<div class="breadcrumb-wrapper" saveDataWarningIgnore>
  <div class="breadcrumb-container">
    <div class="breadcrumb-item" *ngIf="isBranchBreadcrumbShown" [attr.sh-id]="'switch-to-branch'" (click)="navigateToCard(CardViewType.Branch)" saveDataWarningInject>
      <div class="branch-icon"></div>
      <div class="breadcrumb-title" *ngIf="isBranchLocalized">{{ 'REPORTS.GRID.STATUS.' + cardViewState.branchCardViewState.branchDetails.groupBranchName.value | translate }}</div>
      <div class="breadcrumb-title" *ngIf="!isBranchLocalized">{{ cardViewState.branchCardViewState.branchDetails.groupBranchName.value }}</div>
    </div>
    <div class="breadcrumb-item" *ngIf="isGroupBreadcrumbShown" [attr.sh-id]="'switch-to-group'" (click)="navigateToCard(CardViewType.Group)" saveDataWarningInject>
      <div class="group-icon"></div>
      <div class="breadcrumb-title">{{ cardViewState.groupCardViewState.groupDetails.groupName }}</div>
    </div>
    <div class="breadcrumb-item" *ngIf="isAnimalBreadcrumbShown" [attr.sh-id]="'switch-to-animal'" (click)="navigateToCard(CardViewType.Animal)" saveDataWarningInject>
      <div class="animal-icon"></div>
      <div class="breadcrumb-title">{{ cardViewState.animalCardViewState.animalInfo.animalName }}</div>
    </div>
  </div>
</div>
