import {Component, Input, OnInit} from '@angular/core';
import {ReportDataRow} from '../../../../../../services/reports/model/report.interface';
import {DescriptionCellComponent} from '../../../../data-grid/description-cell-template/description-cell.component';

export class SortingReportDataRow extends ReportDataRow {
  public SortingReasonCalculation: SortingReasonCalculation
}

export class SortingReasonCalculation {
  public mainReason: string;
  public otherReason: string[];
}

@Component({
  selector: 'sorting-reason-row-cell',
  templateUrl: './sorting-reason-row-cell.component.html',
  styleUrls: ['./sorting-reason-row-cell.component.scss']
})
export class SortingReasonRowCellComponent extends DescriptionCellComponent<SortingReportDataRow> implements OnInit {
  
  constructor() {
    super();
  }

  public ngOnInit() {
  }
  
  public get hasRowDetails() : boolean {
    return this.row.SortingReasonCalculation.otherReason != null &&
           this.row.SortingReasonCalculation.otherReason.length > 0;
  }
}


