<div class="led-tasks-editor-container">
  <led-task-editor-left-container
    [taskToEdit]="taskToEdit"
    [groupsList]="groupsList"
    [searchEntities]="searchEntities"
    [reportsList]="reportsList"
    [animalsList]="animalsList"></led-task-editor-left-container>
  <div [ngSwitch]="ledTasksService.taskModel.selectedDiscoverItem && ledTasksService.taskModel.selectedDiscoverItem.value">
    <led-task-editor-right-reports-container *ngSwitchCase="discoverItems.Reports"></led-task-editor-right-reports-container>
    <led-task-editor-right-animals-container *ngSwitchCase="discoverItems.Animals"></led-task-editor-right-animals-container>
  </div>
</div>
