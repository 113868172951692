<div class="reproduction-section" sh-id="farm-reproduction-content" *ngIf="model" saveDataEditSection [editAreaName]="editAreaName">
  <section #voluntaryWaiting id="voluntaryWaiting">
    <div class="section-header"
         translation-id="MANAGE.SETTINGS.REPRODUCTION.VOLUNTARY_WAITING_PERIOD.TITLE">{{ 'MANAGE.SETTINGS.REPRODUCTION.VOLUNTARY_WAITING_PERIOD.TITLE' | translate }}</div>
    <div class="section-content">
      <div class="section-description" translation-id="MANAGE.SETTINGS.REPRODUCTION.VOLUNTARY_WAITING_PERIOD.DESCRIPTION">
        {{ 'MANAGE.SETTINGS.REPRODUCTION.VOLUNTARY_WAITING_PERIOD.DESCRIPTION' | translate }}
      </div>
      <div class="section-controls">
        <div class="section-field-editor">
          <div class="header">
            <div class="title" translation-id="MANAGE.SETTINGS.REPRODUCTION.VOLUNTARY_WAITING_PERIOD.EDITOR_TITLE">
              {{ 'MANAGE.SETTINGS.REPRODUCTION.VOLUNTARY_WAITING_PERIOD.EDITOR_TITLE' | translate }}
            </div>
          </div>
          <div class="content">
            <div class="content-label" translation-id="MANAGE.SETTINGS.REPRODUCTION.VOLUNTARY_WAITING_PERIOD.HEIFER">
              {{ 'MANAGE.SETTINGS.REPRODUCTION.VOLUNTARY_WAITING_PERIOD.HEIFER' | translate }}
            </div>
            <numeric-input class="manage-settings-reproduction"
                           [shId]="'voluntaryWaitingPeriodForHeifers'"
                           [min]="model.voluntaryWaitingPeriodForHeifers.min"
                           [max]="model.voluntaryWaitingPeriodForHeifers.max"
                           [isDisabled]="isFieldEditable()"
                           [isSaveOnFocusOut]="true"
                           (onChangeSelectedValue)="updateValue('voluntaryWaitingPeriodForHeifers', $event)"
                           [selectedValue]="model.voluntaryWaitingPeriodForHeifers.value"></numeric-input>
          </div>
          <div class="content">
            <div class="content-label" translation-id="MANAGE.SETTINGS.REPRODUCTION.VOLUNTARY_WAITING_PERIOD.FIRST_LACTATION">
              {{ 'MANAGE.SETTINGS.REPRODUCTION.VOLUNTARY_WAITING_PERIOD.FIRST_LACTATION' | translate }}
            </div>
            <numeric-input class="manage-settings-reproduction"
                           [min]="model.voluntaryWaitingPeriodForLactation1.min"
                           [max]="model.voluntaryWaitingPeriodForLactation1.max"
                           [isDisabled]="isFieldEditable()"
                           [isSaveOnFocusOut]="true"
                           (onChangeSelectedValue)="updateValue('voluntaryWaitingPeriodForLactation1', $event)"
                           [selectedValue]="model.voluntaryWaitingPeriodForLactation1.value"></numeric-input>
          </div>
          <div class="content">
            <div class="content-label" translation-id="MANAGE.SETTINGS.REPRODUCTION.VOLUNTARY_WAITING_PERIOD.SECOND_LACTATION">
              {{ 'MANAGE.SETTINGS.REPRODUCTION.VOLUNTARY_WAITING_PERIOD.SECOND_LACTATION' | translate }}
            </div>
            <numeric-input class="manage-settings-reproduction"
                           [min]="model.voluntaryWaitingPeriodForLactation2Plus.min"
                           [max]="model.voluntaryWaitingPeriodForLactation2Plus.max"
                           [isDisabled]="isFieldEditable()"
                           [isSaveOnFocusOut]="true"
                           (onChangeSelectedValue)="updateValue('voluntaryWaitingPeriodForLactation2Plus', $event)"
                           [selectedValue]="model.voluntaryWaitingPeriodForLactation2Plus.value"></numeric-input>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section #pregnancyLength id="pregnancyLength">
    <div class="section-header"
         translation-id="MANAGE.SETTINGS.REPRODUCTION.PREGNANCY_LENGTH.TITLE">{{ 'MANAGE.SETTINGS.REPRODUCTION.PREGNANCY_LENGTH.TITLE' | translate }}</div>
    <div class="section-content">
      <div class="section-description" translation-id="MANAGE.SETTINGS.REPRODUCTION.PREGNANCY_LENGTH.DESCRIPTION">
        {{ 'MANAGE.SETTINGS.REPRODUCTION.PREGNANCY_LENGTH.DESCRIPTION' | translate }}
      </div>
      <div class="section-controls">
        <div class="section-field-editor">
          <div class="header">
            <div class="title" translation-id="MANAGE.SETTINGS.REPRODUCTION.PREGNANCY_LENGTH.EDITOR_TITLE">
              {{ 'MANAGE.SETTINGS.REPRODUCTION.PREGNANCY_LENGTH.EDITOR_TITLE' | translate }}
            </div>
          </div>
          <div class="content">
            <numeric-input class="manage-settings-reproduction"
                           [min]="model.pregnancyLength.min"
                           [max]="model.pregnancyLength.max"
                           [isDisabled]="isFieldEditable()"
                           [isSaveOnFocusOut]="true"
                           (onChangeSelectedValue)="updateValue('pregnancyLength', $event)"
                           [selectedValue]="model.pregnancyLength.value"></numeric-input>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section #pregnancyDaysToDryOff id="pregnancyDaysToDryOff" *ngIf="model && model.pregnancyDaysToDryOff">
    <div class="section-header"
         translation-id="MANAGE.SETTINGS.REPRODUCTION.PREGNANCY_DAYS_TO_DRY_OFF.TITLE">{{ 'MANAGE.SETTINGS.REPRODUCTION.PREGNANCY_DAYS_TO_DRY_OFF.TITLE' | translate }}</div>
    <div class="section-content">
      <div class="section-description" translation-id="MANAGE.SETTINGS.REPRODUCTION.PREGNANCY_DAYS_TO_DRY_OFF.DESCRIPTION">
        {{ 'MANAGE.SETTINGS.REPRODUCTION.PREGNANCY_DAYS_TO_DRY_OFF.DESCRIPTION' | translate }}
      </div>
      <div class="section-controls">
        <div class="section-field-editor">
          <div class="header">
            <div class="title" translation-id="MANAGE.SETTINGS.REPRODUCTION.PREGNANCY_DAYS_TO_DRY_OFF.EDITOR_TITLE">
              {{ 'MANAGE.SETTINGS.REPRODUCTION.PREGNANCY_DAYS_TO_DRY_OFF.EDITOR_TITLE' | translate }}
            </div>
          </div>
          <div class="content">
            <numeric-input class="manage-settings-reproduction"
                           [min]="model.pregnancyDaysToDryOff.min"
                           [max]="model.pregnancyDaysToDryOff.max"
                           [isDisabled]="isFieldEditable()"
                           [isSaveOnFocusOut]="true"
                           (onChangeSelectedValue)="updateValue('pregnancyDaysToDryOff', $event)"
                           [selectedValue]="model.pregnancyDaysToDryOff.value"></numeric-input>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
