<regular-edit-dialog-template [modalWidth]="368"
                              [isOpen]="isOpen"
                              [enableRemoveButton]="false"
                              [rightButtonDisabled]="selectedWidget == null"
                              [shIdPostfix]="'widget_select'"
                              (onCloseClick)="closeClick()"
                              (onLeftButtonClick)="closeClick()"
                              (onRightButtonClick)="applyClick()"
                              (onEnterButtonClick)="applyClick()">
  <ng-container header-caption>
    {{'DASHBOARD.ACCORDION.ADD_WIDGET' | translate}}
  </ng-container>
  <ng-container body>
    <div class="available-widgets-container" *ngIf="availableWidgets.length > 0">
      <div class="available-widget-item" *ngFor="let availableWidget of availableWidgets">
        <div class="widget-selected-icon" *ngIf="selectedWidget == availableWidget"></div>
        <div class="widget-unselected-icon" *ngIf="selectedWidget != availableWidget" (click)="selectWidget(availableWidget)"></div>
        <div class="icon" [ngClass]="getWidgetIcon(availableWidget.type, availableWidget.category)"></div>
        <div class="text" [attr.sh-id]='"widget_name_"+availableWidget.name' [attr.translation-id]="'DASHBOARD.ACCORDION.' + availableWidget.name">
          {{ 'DASHBOARD.ACCORDION.' + availableWidget.name | translate }}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container left-button>
    <span>{{ 'COMMON.BUTTON.CANCEL' | translate}}</span>
  </ng-container>
  <ng-container right-button>
    <span>{{ 'COMMON.BUTTON.APPLY' | translate}}</span>
  </ng-container>
</regular-edit-dialog-template>
