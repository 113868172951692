import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {IChartPoint} from '../../../../../services/model/charts/chart.interface';
import {
  ChartDurationSelectionLabel,
} from '../../../../../common/components/xy-chart/xy-chart.component';
import {IGroupChartConsistencyData, IGroupGraphDetails} from '../../../../../services/groups/model/group.interface';
import {
  ChartLegendConfiguration,
  NamedChartLegendState,
  XyChartLegendComponent
} from '../../../../../common/components/xy-chart-legend/xy-chart-legend.component';
import {GroupsService} from '../../../../../services/groups/groups.service';
import {SeriesModel} from '@syncfusion/ej2-charts';
import {
  ChartConfiguration,
  GridLineIntervalEnum,
} from '../../../../../services/charts/xy-chart-calculation.service';
import {GroupCardViewState} from '../../group-card-model';
import {TrendsConsistencyXyChartComponent} from '../trends-consistency-xy-chart/trends-consistency-xy-chart.component';

@Component({
  selector: 'group-card-trends-consistency-finishing',
  templateUrl: './group-card-trends-consistency-finishing.component.html',
  styleUrls: ['./group-card-trends-consistency-finishing.component.scss']
})
export class GroupCardTrendsConsistencyFinishingComponent implements OnChanges {

  @ViewChild('groupConsistencyChart')
  public groupConsistencyChart: TrendsConsistencyXyChartComponent;

  @ViewChild('groupHeatChartLegend')
  public groupHeatChartLegend: XyChartLegendComponent;

  @Input()
  public groupId: number;

  @Input()
  public groupGraphDetails: IGroupGraphDetails;

  @Input()
  public viewState: GroupCardViewState;

  public legendConfiguration: ChartLegendConfiguration = {
    translationPrefix: 'ANIMAL.GRAPH.SERIES_TITLE.',
    valuePostfix: ''
  };

  public static readonly FinishingConsistencyChartId = 11;

  public readonly daysToShowInSideBar: number = 10;

  public legendStates: NamedChartLegendState[];

  public selectedDurationLabel: ChartDurationSelectionLabel;

  public chartData: IGroupChartConsistencyData;

  private optionsDurationLabels: ChartDurationSelectionLabel[] = [];

  private chartConfigurations: ChartConfiguration[] = [];

  public lastDaysAverage:number = 0;

  public lastDaysConsistency:number = 0;

  public consistencySeverity:string = '';

  constructor(private groupsService: GroupsService) {
  }

  public async ngOnChanges(changes: SimpleChanges){
    if(changes.viewState != null &&
      changes.viewState.previousValue != changes.viewState.currentValue &&
      this.viewState != null) {
      this.initConfig();
      this.initChartLegend();
      this.initChart();
    }
  }

  private async initChart() {
    this.viewState.trendsConsistencySelectedDuration = this.optionsDurationLabels.indexOf(this.selectedDurationLabel);

    if(this.chartData == null) {
      let response = await this.groupsService.getFinishingGroupConsistencyChart(this.groupId, GroupCardTrendsConsistencyFinishingComponent.FinishingConsistencyChartId);
      if(response.status == 200){
        this.chartData = response.responseBody;
        this.lastDaysAverage = response.responseBody.lastDaysAverage;
        this.lastDaysConsistency = response.responseBody.lastDaysConsistency;
        this.consistencySeverity = response.responseBody.consistencySeverity;
      }
    }

    if (this.lastDaysAverage) {
      const chartDataSeriesSize = this.chartData.series.length;
      this.chartData.series.forEach((chartPoint, index) => {
        chartPoint.lastDaysAverageDailyRumination = chartPoint.averageDailyRumination && index > chartDataSeriesSize - 12 ? this.lastDaysAverage : null;
      });
    }
    this.groupConsistencyChart.initChart(this.getSeries(),
                                         this.chartData.series,
                                         this.chartConfigurations[this.viewState.trendsConsistencySelectedDuration],
                                         this.selectedDurationLabel,
                                         this.optionsDurationLabels,
                                         this.chartData.eventsSeries);
  }

  public onChartPointMouseOver(chartPoint: IChartPoint) {
    this.setLegendValue(chartPoint);
  }

  public onDurationSelected(duration: ChartDurationSelectionLabel) {
    this.selectedDurationLabel = duration;
    this.initChart();
  }

  private initChartLegend() {
    this.legendStates = [];
    this.legendStates.push({
      name: 'variabilityOfDailyRumination',
      value: null,
      altTranslation: 'GROUP.GRAPH.DETAILS.RUMINATION_VARIABILITY',
      altCss: 'dailyRumination'
    });
    this.legendStates.push({
      name: 'averageDailyRumination',
      value: null,
      altTranslation: 'GROUP.GRAPH.DETAILS.AVERAGE_DAILY_RUMINATION',
      altCss: 'consistencyAverageDailyRumination'
    });
  }

  // tslint:disable-next-line:no-any
  private setLegendValue(valueContainer: any) {
    if(valueContainer == null ){
      this.legendStates.forEach(legendState => {
        legendState.value = null;
      });
    } else {
      this.legendStates.forEach(legendState => {
        legendState.value = valueContainer[legendState.name];
        if(legendState.value == null) {
          legendState.value = 0;
        }
      });
    }
  }

  private initConfig() {
    this.optionsDurationLabels.push({
      selectedDisplayText: '7 DAYS',
      optionDisplayText: '7'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24,
      gridLineInterval: GridLineIntervalEnum.Day,
      visiblePeriodHours: 24 * 7,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0,
      primaryXAxisExtendSeconds: 1800,
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '14 DAYS',
      optionDisplayText: '14'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24,
      gridLineInterval: GridLineIntervalEnum.Day,
      visiblePeriodHours: 24 * 14,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0,
      primaryXAxisExtendSeconds: 3600,
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '30 DAYS',
      optionDisplayText: '30'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 2,
      gridLineInterval: GridLineIntervalEnum.TwoDays,
      visiblePeriodHours: 24 * 30,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '60 DAYS',
      optionDisplayText: '60'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 3,
      gridLineInterval: GridLineIntervalEnum.ThreeDays,
      visiblePeriodHours: 24 * 60,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '90 DAYS',
      optionDisplayText: '90'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 4,
      gridLineInterval: GridLineIntervalEnum.FourDays,
      visiblePeriodHours: 24 * 90,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '180 DAYS',
      optionDisplayText: '180'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 9,
      gridLineInterval: GridLineIntervalEnum.NineDays,
      visiblePeriodHours: 24 * 180,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '360 DAYS',
      optionDisplayText: '360'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 18,
      gridLineInterval: GridLineIntervalEnum.Month,
      visiblePeriodHours: 24 * 360,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '420 DAYS',
      optionDisplayText: '420'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 18,
      gridLineInterval: GridLineIntervalEnum.Month,
      visiblePeriodHours: 24 * 420,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.selectedDurationLabel = this.optionsDurationLabels[this.viewState.trendsConsistencySelectedDuration];
  }

  private getSeries() : SeriesModel[] {
    let seriesModel : SeriesModel[] = [];

    seriesModel.push({
      type: 'Line',
      xName: 'x',
      yName: 'averageDailyRumination',
      width: 2,
      fill: '#aa64aa',
      animation: {
        enable: false
      },
      dataSource: this.chartData.series
    });

    seriesModel.push({
      type: 'Area',
      xName: 'x',
      yAxisName: 'secYAxis',
      yName: 'variabilityOfDailyRumination',
      opacity: 0.5,
      fill: '#aa64aa',
      animation: {
        enable: false
      },
      dataSource: this.chartData.series
    });

    seriesModel.push({
      type: 'Area',
      xName: 'x',
      yName: 'lastDaysAverageDailyRumination',
      opacity: 0.5,
      fill: '#1f9bde',
      animation: {
        enable: false
      },
      dataSource: this.chartData.series,
    });

    return seriesModel;
  }
}
