<div class="content-column content-body-column">
  <div class="container-body" [ngStyle]="{'height' : 'calc(100% - ' + (footerHeight + 2) + 'px)',
                                          'background' : bodyBackground}">
    <ng-content select="[container-body]"></ng-content>
  </div>
  <div class="container-footer" [ngStyle]="{'height' : footerHeight + 'px',
                                            'background' : bodyBackground}">
    <ng-content select="[container-footer]"></ng-content>
  </div>
  <div class="bottom-border"></div>
</div>
<div class="corner-column content-padding-column" saveDataWarningIgnore>
  <div class="right-padding" [ngStyle]="{'height' : 'calc(100% - ' + (footerHeight + 2) + 'px)',
                                         'background' : bodyBackground}">
    <div class="right-padding-content" [ngStyle]="{'background' : bodyBackground}"></div>
    <div class="right-padding-border"></div>
  </div>
  <div class="right-padding" (click)="onCornerButtonClick.emit($event)" [ngStyle]="{'height' : (footerHeight - 38) + 'px'}">
    <div class="right-padding-content" [ngStyle]="{'background' : cornerColor}"></div>
    <div class="right-padding-border"></div>
  </div>
  <div class="corner-image" (click)="onCornerButtonClick.emit($event)">
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g fill="none">
        <path [attr.fill]="cornerColor" d="M0 0L36 0 36 11 8 38 0 38z"/>
        <path fill="#000" fill-opacity=".3" d="M0 0H4V11H0z" transform="translate(36)"/>
        <path fill="#000" fill-opacity=".3" d="M0 38L8 38 10 40 0 40z"/>
        <path fill="#000" fill-opacity=".3" d="M36 11L40 11 10 40 8 38z"/>
      </g>
    </svg>
  </div>
</div>
