import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  CreateCullingEventDetails,
  EventDetails,
  CullingCowEventMetadata
} from '../../../../../services/animals/model/animal-events';
import {EventFormComponent} from '../event-form.component';
import {DropdownListItem} from '../../../../../common/components/dropdown-list/dropdown-list-model';
import {TranslationService} from '../../../../../services/translations/translation.service';
import {FormsDLIconsThemes} from "../../../../../common/components/dropdown-list/forms-dropdown-list/forms-dropdown-list.component";

@Component({
  selector: 'culling-event-form',
  templateUrl: './culling-event-form.component.html',
  styleUrls: ['./culling-event-form.component.scss']
})
export class CullingEventFormComponent extends EventFormComponent<CullingCowEventMetadata, CreateCullingEventDetails> {

  public cullingReasonDropdownItems: DropdownListItem[] = [];

  public formsDLIconsThemes = FormsDLIconsThemes;

  public get cullingReason(): string | undefined {
    return this.editModel.cullingReason != null
      ? this.editModel.cullingReason.key
      : undefined;
  }

  public set cullingReason(newValue: string | undefined) {
    const getCullingReason = () => {
      if (newValue != null) {
        const cullingReason = this.meta.cullingReason.find(
          (cr) => cr.key === newValue
        );
        if (cullingReason != null) {
          return cullingReason;
        }
      }
      return null;
    };

    this.editModel.cullingReason = getCullingReason();

    this.emitModelChanged();
  }

  constructor(private readonly translationService: TranslationService) {
    super();
  }

  protected onChanges(changes: SimpleChanges): void {
    if (changes.meta != null) {
      this.cullingReasonDropdownItems = this.meta.cullingReason.map((value) => {
        return {
          value: value.key,
          displayValue: this.translationService.translate('ANIMAL.EVENTS.ADD_EVENT.CULLING_EVENT_REASONS.' + value.value),
          disabled: false,
          icon: null
        };
      });
    }
  }
}
