<div class="background">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
<div class="user-data">
  <ellipsisable-text class="username">{{ userName }}</ellipsisable-text>
  <span class="datetime">{{ lastLoggedInTime | epochDateTime:DateTimeFormatEnum.MediumDate}}</span>
</div>
<div class="btn-logout" (click)="onLogoutClick()" [attr.sh-id]="'button_logout'">
  <div class="icon icon-sign-out"></div>
</div>
