import {Component, OnInit, SimpleChanges} from '@angular/core';
import {EventFormComponent} from '../event-form.component';
import {
  CreateGeneralEventDetails,
  GeneralEventMetadata
} from '../../../../../services/animals/model/animal-events';
import { AutoCompleteColorScheme } from 'src/app/common/components/auto-complete/auto-complete-model';

@Component({
  selector: 'general-event-form',
  templateUrl: './general-event-form.component.html',
  styleUrls: ['./general-event-form.component.scss']
})
export class GeneralEventFormComponent  extends EventFormComponent<GeneralEventMetadata, CreateGeneralEventDetails> {

  public descriptionItems: string[] = [];
  
  public AutoCompleteColorScheme = AutoCompleteColorScheme;
  
  constructor() {
    super();
  }

  public onDescriptionChanged(description: string) {
    if(description == null || description.trim().length == 0) {
      this.editModel.description = null;
    } else {
      let selectedDescription = this.meta.descriptions.find(value => value.key.toLowerCase().trim() == description.toLowerCase().trim());
      if (selectedDescription != null) {
        this.editModel.description = selectedDescription;
      } else {
        this.editModel.description = {id: null, key: description.trim(), value: description.trim()};
      }
    }
    this.emitModelChanged();
  }
  
  protected onChanges(changes: SimpleChanges): void {
    if (changes.meta != null) {
      this.descriptionItems = this.meta.descriptions.map(value => value.key);
    }
  }
}
