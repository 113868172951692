<ng-container *ngIf="farmStatistics">
  <div class="farm-statistics-beef-wrapper">
    <div class="farm-statistics-beef-upper-diagrams">
      <div class="inner-left">
        <div class="top-left">
          <div class="text" [attr.translation-id]="'DASHBOARD.STATISTICS.FEMALES.TOTAL'">{{ 'DASHBOARD.STATISTICS.FEMALES.TOTAL' | translate }}</div>
          <div class="value">{{ farmStatistics.females.totalFemales.total }}</div>
        </div>
        <div class="bottom-left">
          <div class="text" [attr.translation-id]="'DASHBOARD.STATISTICS.FEMALES.PREGNANT'">{{ 'DASHBOARD.STATISTICS.FEMALES.PREGNANT' | translate }}</div>
          <div class="text">{{ farmStatistics.females.totalFemales.pregnant }} / {{ farmStatistics.females.totalFemales.pregnantPossible }}</div>
        </div>
      </div>
      <div class="inner-right">
        <ng-container *ngFor="let chartDataItem of chartData; let i = index">
          <ng-container *ngIf="i === 0">
            <div class="data-group">
              <div class="double-div">
                <div class="half-div purpleColor">
                  <div class="text" translation-id="DASHBOARD.STATISTICS.VWP">
                    {{ 'DASHBOARD.STATISTICS.VWP' | translate }}
                  </div>
                  <div class="value seperator">{{ farmStatistics.females.femalesVwp.total }}</div>
                </div>
                <div class="half-div">
                  <div class="text" [attr.translation-id]="'REPORTS.GRID.STATUS.Ready'">{{ 'REPORTS.GRID.STATUS.Ready' | translate }}</div>
                  <div class="value">{{ farmStatistics.females['femalesCalvingCount' + i].total }}</div>
                </div>
              </div>
              <div class="chart-div">
                <div class="chart-diagram-div">
                  <farm-statistics-beef-pie-chart [data]="chartData[i]" [chartElement]="'farmStatisticsPieChart' + i"></farm-statistics-beef-pie-chart>
                </div>
                <div class="legend-div">
                  <div class="value">{{ farmStatistics.females['femalesCalvingCount' + i].pregnant }}</div>
                  <div class="text" [attr.translation-id]="'DASHBOARD.STATISTICS.PREGNANT'">{{ 'DASHBOARD.STATISTICS.PREGNANT' | translate }}</div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="i > 0">
            <div class="data-group">
              <div class="text-div">
                <div class="text" [attr.translation-id]="'DASHBOARD.STATISTICS.FEMALES.CALVINGCOUNT.TOTAL'">{{ 'DASHBOARD.STATISTICS.FEMALES.CALVINGCOUNT.TOTAL' | translate }} {{ i === 3 ? '3+' : i }}</div>
                <div class="value">{{ farmStatistics.females['femalesCalvingCount' + i].total }}</div>
              </div>
              <div class="chart-div">
                <div class="chart-diagram-div">
                  <farm-statistics-beef-pie-chart [data]="chartData[i]" [chartElement]="'farmStatisticsPieChart' + i"></farm-statistics-beef-pie-chart>
                </div>
                <div class="legend-div">
                  <div class="value">{{ farmStatistics.females['femalesCalvingCount' + i].pregnant }}</div>
                  <div class="text" [attr.translation-id]="'DASHBOARD.STATISTICS.PREGNANT'">{{ 'DASHBOARD.STATISTICS.PREGNANT' | translate }}</div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="farm-statistics-beef-lower-diagrams">
      <div class="inner-left">
        <div class="top-left">
          <div class="text" [attr.translation-id]="'REPORTS.GRID.STATUS.Calves'">{{ 'REPORTS.GRID.STATUS.Calves' | translate }}</div>
          <div class="value">{{ farmStatistics.calves.totalCalves.female + farmStatistics.calves.totalCalves.male }}</div>
        </div>
        <div class="bottom-left">
          <div class="icon-text-item">
            <div class="text">{{ farmStatistics.calves.totalCalves.female }}</div>
            <div class="icon-female"></div>
          </div>
          <div class="icon-text-item">
            <div class="text">{{ farmStatistics.calves.totalCalves.male }}</div>
            <div class="icon-male"></div>
          </div>
        </div>
      </div>
      <div class="inner-right">
        <div class="calving-div">
          <div class="seperator"></div>
          <div class="calving-month-item">
            <div class="value">
              <span *ngIf="isNotZeroCalves(farmStatistics.calves.zeroToTwoMonths)">
                {{ farmStatistics.calves.zeroToTwoMonths.male + farmStatistics.calves.zeroToTwoMonths.female }}
              </span>
            </div>
            <div class="double-container-div">
              <div class="chart-div">
                <div class="column-chart">
                  <div class="MaleColumnChart" [ngStyle]="{ height: this.getColumnHeight(columnChartType.Male, farmStatistics.calves.zeroToTwoMonths)}"></div>
                  <div class="FemaleColumnChart" [ngStyle]="{ height: this.getColumnHeight(columnChartType.Female, farmStatistics.calves.zeroToTwoMonths)}"></div>
                </div>
              </div>
              <div class="double-div">
                <div class="half-div">
                  <div class="FemaleValue" *ngIf="farmStatistics.calves.zeroToTwoMonths.female" [ngStyle]="{ marginTop: this.getValueHeight(columnChartType.Female, farmStatistics.calves.zeroToTwoMonths)}">
                    {{ farmStatistics.calves.zeroToTwoMonths.female }}
                  </div>
                </div>
                <div class="half-div">
                  <div class="MaleValue" *ngIf="farmStatistics.calves.zeroToTwoMonths.male" [ngStyle]="{ marginTop: this.getValueHeight(columnChartType.Male, farmStatistics.calves.zeroToTwoMonths)}">
                    {{ farmStatistics.calves.zeroToTwoMonths.male }}
                  </div>
                </div>
              </div>
            </div>
            <div class="header-div">
              0-2 m
            </div>
          </div>
          <div class="seperator"></div>
          <div class="calving-month-item">
            <div class="value">
              <span *ngIf="isNotZeroCalves(farmStatistics.calves.threeToFourMonths)">
                {{ farmStatistics.calves.threeToFourMonths.male + farmStatistics.calves.threeToFourMonths.female }}
              </span>
            </div>
            <div class="double-container-div">
              <div class="chart-div">
                <div class="column-chart">
                  <div class="MaleColumnChart" [ngStyle]="{ height: this.getColumnHeight(columnChartType.Male, farmStatistics.calves.threeToFourMonths)}"></div>
                  <div class="FemaleColumnChart" [ngStyle]="{ height: this.getColumnHeight(columnChartType.Female, farmStatistics.calves.threeToFourMonths)}"></div>
                </div>
              </div>
              <div class="double-div">
                <div class="half-div">
                  <div class="FemaleValue" *ngIf="farmStatistics.calves.threeToFourMonths.female" [ngStyle]="{ marginTop: this.getValueHeight(columnChartType.Female, farmStatistics.calves.threeToFourMonths)}">
                    {{ farmStatistics.calves.threeToFourMonths.female }}
                  </div>
                </div>
                <div class="half-div">
                  <div class="MaleValue" *ngIf="farmStatistics.calves.threeToFourMonths.male" [ngStyle]="{ marginTop: this.getValueHeight(columnChartType.Male, farmStatistics.calves.threeToFourMonths)}">
                    {{ farmStatistics.calves.threeToFourMonths.male }}
                  </div>
                </div>
              </div>
            </div>
            <div class="header-div">
              3-4 m
            </div>
          </div>
          <div class="seperator"></div>
          <div class="calving-month-item">
            <div class="value">
              <span *ngIf="isNotZeroCalves(farmStatistics.calves.fivePlusMonths)">
                {{ farmStatistics.calves.fivePlusMonths.male + farmStatistics.calves.fivePlusMonths.female }}
              </span>
            </div>
            <div class="double-container-div">
              <div class="chart-div">
                <div class="column-chart">
                  <div class="MaleColumnChart" [ngStyle]="{ height: this.getColumnHeight(columnChartType.Male, farmStatistics.calves.fivePlusMonths)}"></div>
                  <div class="FemaleColumnChart" [ngStyle]="{ height: this.getColumnHeight(columnChartType.Female, farmStatistics.calves.fivePlusMonths)}"></div>
                </div>
              </div>
              <div class="double-div">
                <div class="half-div">
                  <div class="FemaleValue" *ngIf="farmStatistics.calves.fivePlusMonths.female" [ngStyle]="{ marginTop: this.getValueHeight(columnChartType.Female, farmStatistics.calves.fivePlusMonths)}">
                    {{ farmStatistics.calves.fivePlusMonths.female }}
                  </div>
                </div>
                <div class="half-div">
                  <div class="MaleValue" *ngIf="farmStatistics.calves.fivePlusMonths.male" [ngStyle]="{ marginTop: this.getValueHeight(columnChartType.Male, farmStatistics.calves.fivePlusMonths)}">
                    {{ farmStatistics.calves.fivePlusMonths.male }}
                  </div>
                </div>
              </div>
            </div>
            <div class="header-div">
              5+ m
            </div>
          </div>
          <div class="seperator"></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
