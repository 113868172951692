import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'testing-refresh-blank',
  templateUrl: './testing-refresh-blank.component.html',
  styleUrls: ['./testing-refresh-blank.component.scss']
})
export class TestingRefreshBlankComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
