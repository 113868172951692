<div class="initialize-sign-up-background">
  <div class="inner-container">
    <div class="sensehub-logo-icon"></div>
    <div class="welcome-to-sensehub-text" [attr.translation-id]="'REGISTRATION.FLOW.WELCOME_TO'">{{ 'REGISTRATION.FLOW.WELCOME_TO' | translate }} <span class="bold-text" [attr.translation-id]="'REGISTRATION.FLOW.SENSE_TIME'" sh-id="sensehub-text-header-div">{{ 'REGISTRATION.FLOW.SENSE_TIME' | translate }}</span></div>
    <div class="few-steps-away-text" [attr.translation-id]="'REGISTRATION.FLOW.YOU_ARE_JUST_FEW_STEPS_AWAY_DO_YOU_WANT_TO_SIGN_UP_NOW'">{{ 'REGISTRATION.FLOW.YOU_ARE_JUST_FEW_STEPS_AWAY_DO_YOU_WANT_TO_SIGN_UP_NOW' | translate }}</div>
    <button class="sign-up-button" [attr.sh-id]="'sign_up_button'" [attr.translation-id]="'REGISTRATION.FLOW.SIGN_UP'" (click)="navigateToSignUpPage()">{{ 'REGISTRATION.FLOW.SIGN_UP' | translate }}</button>
  </div>
  <div class="language-picker-wrapper">
    <translation-picker [theme]="TranslationPickerThemeEnum.White"></translation-picker>
  </div>
</div>
