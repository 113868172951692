import { Injectable } from '@angular/core';
import {HttpResponseResult, RestApiService} from '../rest-api/rest-api.service';
import {IDashboardAvailableWidget, IDashboardWidget} from './model/dashboard-widgets.interface';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardWidgetsService {
  
  public static readonly MaxNumberOfItems:number = 4;
  
  private static readonly WidgetsRoute = `/rest/api/dashboardwidgets`;
  
  private static readonly AvailableWidgetsRoute = `/rest/api/dashboardwidgets/available`;
  
  constructor(private readonly restApiService: RestApiService,
              private readonly authService: AuthService) { }
  
  public getDashboardWidgets() : Promise<HttpResponseResult<IDashboardWidget[]>> {
    return this.restApiService.sendGetMethod<IDashboardWidget[]>(DashboardWidgetsService.WidgetsRoute, this.authService.currentUserAuthState.authDetails);
  }
  
  public getDashboardAvailableWidgets() : Promise<HttpResponseResult<IDashboardAvailableWidget[]>> {
    return this.restApiService.sendGetMethod<IDashboardAvailableWidget[]>(DashboardWidgetsService.AvailableWidgetsRoute, this.authService.currentUserAuthState.authDetails);
  }
}
