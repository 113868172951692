export class DataGridQuerySortDetails {
  public column: string;
  public isSortedUp: boolean;
}

export class DataGridQuery {
  public offset?: number;
  public limit?: number;
  public type?: string;
  public filter?: string;
  public sortDetails?: DataGridQuerySortDetails;
  public get page() : number {
    return this.offset / this.limit;
  }

  public get sortedColumn() : string {
    if(this.sortDetails == null ||
       this.sortDetails.column == null){
      return null;
    }
    if(this.sortDetails.isSortedUp) {
      return this.sortDetails.column;
    } else {
      return '-' + this.sortDetails.column;
    }
  }
}
