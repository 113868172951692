
export class ServerSetup {
  public acknowledgeInitialSetup: boolean;
  public status: VersionStatus;
  public setupInProgress: boolean;
  public progress?: number;
}
export enum VersionStatus {
  Searching = 'Searching',
  VersionFound = 'VersionFound',
  NoVersionFound = 'NoVersionFound',
  Processing = 'Processing',
  Downloading = 'Downloading',
  Upgrading = 'Upgrading'
}
