<div class="header">
  <div class="header-text">
    <div class="header-text-main" [attr.translation-id]="'LED_TASKS.TASK_EDITOR.LED_TASKS'">{{ 'LED_TASKS.TASK_EDITOR.LED_TASKS' | translate }}<span *ngIf="isMadeChanges">:</span>
      <span class="header-text-sub-main" *ngIf="isMadeChanges" [attr.translation-id]="'MANAGE.SETTINGS.USERS.YOU_CHANGED_SOME_THING'">
          {{ 'MANAGE.SETTINGS.USERS.YOU_CHANGED_SOME_THING' | translate }}
        </span>
    </div>
  </div>
  <div class="buttons-div">
    <div class="save-btn" [attr.sh-id]="'save-led-task-button'" *ngIf="isMadeChanges" (click)="save()"></div>
    <div class="seperator" *ngIf="isMadeChanges"></div>
    <div class="close-btn" [attr.sh-id]="'revert-led-task-button'" (click)="revert($event)"></div>
  </div>
</div>
