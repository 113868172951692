<ng-container *ngFor="let widgetViewState of widgetsViewStates">
  <div class="accordion-section {{'accordion-section-' + farmMode | lowercase}}"
       [ngClass]="{'expanded': !editMode && widgetViewState.isExpanded}">
    <div class="accordion-header" [ngClass]="{'expanded': widgetViewState.isExpanded}" (click)="expandAccordionSection(widgetViewState)">
      <ng-container *ngIf="editMode">
        <div class="icon-remove" *ngIf="editMode && widgetViewState.widget" [attr.sh-id]="'remove_widget_' + widgetViewState.widget.name" (click)="removeWidget(widgetViewState)"></div>
        <div class="icon-add" *ngIf="editMode && widgetViewState.widget == null" [attr.sh-id]="'add_widget'" (click)="addWidget($event)"></div>
      </ng-container>
      <div class="accordion-header-title" *ngIf="widgetViewState.widget == null">
        <span class="highlight" [attr.translation-id]="'DASHBOARD.ACCORDION.ADD_WIDGET'">{{'DASHBOARD.ACCORDION.ADD_WIDGET' | translate}}</span>
      </div>
      <div class="accordion-header-title" *ngIf="widgetViewState.widget" [attr.sh-id]="'accordion_report_title_' + widgetViewState.widget.name">
        <span class="highlight" [attr.translation-id]="'DASHBOARD.ACCORDION.' + widgetViewState.widget.name"
                                [attr.sh-id]="'sh-id-' + widgetViewState.widget.name">{{'DASHBOARD.ACCORDION.' + widgetViewState.widget.name | translate}}</span>
        <ng-container *ngIf="!editMode">
          <span class="delimiter"> | </span>
          <accordion-report-widget-header *ngIf="widgetViewState.widget.type == WidgetType.Report"
                                          [reportWidget]="widgetViewState.widget"
                                          [report]="widgetViewState.widget.content"></accordion-report-widget-header>
          <accordion-graph-widget-header *ngIf="widgetViewState.widget.type == WidgetType.Graph"
                                          [dashboardWidget]="widgetViewState.widget"></accordion-graph-widget-header>
          <span class="icon" [ngClass]="getWidgetIcon(widgetViewState)"></span>
        </ng-container>
      </div>
      <ng-container *ngIf="!editMode">
        <accordion-report-widget-actions  *ngIf="!editMode && widgetViewState.isExpanded && widgetViewState.widget.type == WidgetType.Report"
                                          [report]="widgetViewState.widget.content"></accordion-report-widget-actions>
        <div class="btn btn-expand">
          <div class="icon-dropdown"></div>
        </div>
      </ng-container>
    </div>
    <div class="accordion-content animated fadeInUp" *ngIf="!editMode && widgetViewState.isExpanded" [@accordionSectionExpandAnimation]>
      <accordion-report-widget *ngIf="widgetViewState.widget.type == WidgetType.Report"
                               [dashboardWidget]="widgetViewState.widget"
                               [report]="widgetViewState.widget.content"></accordion-report-widget>
      <accordion-graph-widget *ngIf="widgetViewState.widget.type == WidgetType.Graph"
                              [viewState]="widgetViewState"></accordion-graph-widget>
    </div>
    <div class="shadow"></div>
  </div>
</ng-container>
<dashboard-widget-select-dialog #dashboardWidgetSelectDialog (onApply)="onWidgetSelectDialogApply($event)"></dashboard-widget-select-dialog>
