import {Injectable, Version} from '@angular/core';
import {ConfigService} from '../config/config.service';
import {MockHttpService} from '../../testing/services/mock-http.service';
import {SetupService} from '../setup/setup.service';
import {Router} from '@angular/router';
import {ClientSessionRecorderService} from '../../testing/services/client-session-recorder/client-session-recorder.service';
import {TranslationService} from '../translations/translation.service';

@Injectable({
  providedIn: 'root'
})
export class BootService {

  constructor(public configService: ConfigService,
              private mockHttpService: MockHttpService,
              private setupService: SetupService,
              private clientSessionRecorderService: ClientSessionRecorderService,
              private translationService: TranslationService) {
  }

  public init() {
    return new Promise(async (resolve, reject) => {
      let value = await this.configService.loadApplicationConfiguration();
      await this.clientSessionRecorderService.init();
      await this.translationService.initTranslations(null);
      this.mockHttpService.resetTemplatesToDefault();
      await this.setupService.loadServerSetup();
      resolve(true);
    });
  }
}
