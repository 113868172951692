<div class="events-header" [attr.sh-id]="'manage-events-header'">
  <manage-create-event #manageCreateEvent (toggleClicked)="toggleAddEvent($event)" (onCreateEventResizeMade)="windowResizeMade()"></manage-create-event>
  <grid-actions-dropdown isActionsOnLeftSide="true" [dataSource]="this" *ngIf="!isAddEventOpen"></grid-actions-dropdown>
</div>

<ng-template #eventsColumn let-column="column">
  <ng-container [ngSwitch]="column">
    <span *ngSwitchCase="'animalName'" [attr.translation-id]="'REPORTS.GRID.ANIMAL_ID'">{{'REPORTS.GRID.ANIMAL_ID' | translate}}</span>
    <span *ngSwitchCase="'startDateTime'" [attr.translation-id]="'MANAGE.EVENTS.DATE'">{{'MANAGE.EVENTS.DATE' | translate}}</span>
    <span *ngSwitchCase="'type'" [attr.translation-id]="'MANAGE.EVENTS.EVENT_NAME'">{{'MANAGE.EVENTS.EVENT_NAME' | translate}}</span>
    <span *ngSwitchCase="'daysInLactation'"
          [attr.translation-id]="applicationBundleRoutingModes.Finishing ? 'REPORTS.GRID.Entry_Age_In_Days_Calculation' : 'MANAGE.EVENTS.DIM'">
      {{ (routingMode === applicationBundleRoutingModes.Finishing ? 'REPORTS.GRID.Entry_Age_In_Days_Calculation' : 'MANAGE.EVENTS.DIM')  | translate }}</span>
    <span *ngSwitchCase="'description'" [attr.translation-id]="'MANAGE.EVENTS.DESCRIPTION'">{{'MANAGE.EVENTS.DESCRIPTION' | translate}}</span>
  </ng-container>
</ng-template>

<ng-template #eventsCell let-column="column" let-row="row">
  <ng-container [ngSwitch]="column">
    <div *ngSwitchCase="'animalName'" saveDataWarningInject (click)="openAnimalCard(row)" class="event-cell">
      <ellipsisable-text class="event-animal-id-cell" [ngClass]="{ scaledText: searchService.isHerdManagementDisplayed }">{{row.animalName}}</ellipsisable-text>
    </div>
    <div *ngSwitchCase="'startDateTime'" saveDataWarningInject (click)="openAnimalCard(row)" class="event-cell">
      <span class="event-cell-content date-time-cell">{{row.startDateTime | epochDateTime:DateTimeFormatEnum.DateTime}}</span>
    </div>
    <div *ngSwitchCase="'type'" saveDataWarningInject (click)="openAnimalCard(row)"  class="event-cell event-name-cell">
      <div class="event-cell-content">
        <div [ngClass]="getEventNameClass(row.type)"></div>
        <span *ngIf="['positivePregnancyCheck', 'negativePregnancyCheck', 'uncertainPregnancyCheck'].includes(row.type); else notPregnancyCheckEventTypeBlock">
          {{'ANIMAL.EVENTS.EVENT_TYPE.PregnancyCheck' | translate}}
        </span>
        <ng-template #notPregnancyCheckEventTypeBlock>
          <span>{{'ANIMAL.EVENTS.EVENT_TYPE.' + row.type | translate}}</span>
        </ng-template>
      </div>
    </div>
    <div *ngSwitchCase="'daysInLactation'" saveDataWarningInject (click)="openAnimalCard(row)"  class="event-cell">
      <span class="event-cell-content">{{ needToShowAgeInDaysOrDaysInLactation(row) }}</span>
    </div>
    <div *ngSwitchCase="'description'" class="description-cell">
      <ng-container [ngSwitch]="row.type">
        <calving-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.Calving"></calving-event-description>
        <system-health-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.SystemHealth"></system-health-event-description>
        <dnb-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.DoNotBreed"></dnb-event-description>
        <breeding-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.Breeding"></breeding-event-description>
        <system-heat-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.SystemHeat"></system-heat-event-description>
        <negative-pregnancy-check-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.NegativePregnancyCheck"></negative-pregnancy-check-event-description>
        <uncertain-pregnancy-check-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.UncertainPregnancyCheck"></uncertain-pregnancy-check-event-description>
        <positive-pregnancy-check-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.PositivePregnancyCheck"></positive-pregnancy-check-event-description>
        <assign-scr-tag-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.AssignScrTag"></assign-scr-tag-event-description>
        <assign-rfid-tag-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.AssignRfIdTag"></assign-rfid-tag-event-description>
        <change-scr-tag-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.ChangeScrTag"></change-scr-tag-event-description>
        <remove-scr-tag-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.RemoveScrTag"></remove-scr-tag-event-description>
        <change-rfid-tag-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.ChangeRfIdTag"></change-rfid-tag-event-description>
        <remove-rfid-tag-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.RemoveRfIdTag"></remove-rfid-tag-event-description>
        <culling-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.Culling"></culling-event-description>
        <change-group-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.ChangeGroup"></change-group-event-description>
        <herd-entry-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.HerdEntry"></herd-entry-event-description>
        <dryoff-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.DryOff"></dryoff-event-description>
        <abortion-new-lactation-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.AbortionNewLactation"></abortion-new-lactation-event-description>
        <abortion-same-lactation-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.AbortionSameLactation"></abortion-same-lactation-event-description>
        <distress-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.Distress"></distress-event-description>
        <tag-sw-update-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.TagSwUpdate"></tag-sw-update-event-description>
        <weight-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.Weight"></weight-event-description>
        <general-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.General"></general-event-description>
      </ng-container>
    </div>
    <div *ngSwitchCase="'actions'" class="event-cell">
      <div class="event-cell-content">
        <row-actions-popup [rowActions]="rowActionsMap.get(row)"
                           [shId]="row.eventId"
                           (onPopupOpen)="onRowActionsOpen()"
                           (onRowActionClick)="handleRowAction($event.rowActionPopupItem.action, row)">
        </row-actions-popup>
      </div>
    </div>
    <div *ngSwitchDefault class="event-cell">
      <span class="event-cell-content">{{row[column]}}</span>
    </div>
  </ng-container>
</ng-template>

<data-grid #sortedGrid  *ngIf="eventsResult"
  [attr.sh-id]='"events-table-grid"'
  [sortedGridColumn]="eventsColumn"
  [sortedGridCell]="eventsCell"
  [headerHeight]="40"
  [rowHeight]="40"
  [sortedGridQuery]="eventsSortedGridQuery"
  [totalRows]="eventsResult.total"
  [columnsMappings]="columnsMappings"
  [rowsData]="eventsResult.result"
  [viewContext]="this"
  saveDataWarningIgnore
  (onSortedGridQueryChanged)="getEvents(true)">
</data-grid>

<cancel-batch-event-dialog></cancel-batch-event-dialog>
