import { Injectable } from '@angular/core';
import {ConfigService} from '../config/config.service';
import {ActivatedRoute, Params} from '@angular/router';


export enum ApplicationBundleRoutingMode {
  Finishing = 'Finishing',
  Breeding = 'Breeding'
}

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  public static readonly appBoundleQueryParam = 'appBoundle';

  constructor(public configService:ConfigService) {

  }

  public getApplicationBundleRoutingMode(queryParams:Params) : ApplicationBundleRoutingMode {
    let applicationBundleRoutingMode:ApplicationBundleRoutingMode = queryParams[RoutingService.appBoundleQueryParam];
    if(applicationBundleRoutingMode !== ApplicationBundleRoutingMode.Breeding &&
       applicationBundleRoutingMode !== ApplicationBundleRoutingMode.Finishing){
      return null;
    }
    return applicationBundleRoutingMode;
  }
}
