import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {

  // init the value of the toggle (true or false)
  @Input()
  public Toggle: boolean;

  // output if the toggle has been changed
  @Output()
  public onToggleChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  public ngOnInit() {
  }

  public toggle() {
    if (this.Toggle) {
      this.onToggleChanged.emit(false);
    } else {
      this.onToggleChanged.emit(true);
    }
  }

}
