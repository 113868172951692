import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  ManageSettingsMenuItemModel,
  ManageSettingsSubMenuItemModel
} from './manage-settings-menuitem/manage-settings-menuitem.component';
import {ManageSettingsScreenType} from '../model/manage-settings.model';
import {ApplicationBundle, FarmMode} from 'src/app/services/config/model/server-config';
import {ConfigService} from '../../../../services/config/config.service';
import {FarmRole} from '../../../../services/auth/model/farm-role.enum';
import {ManageSettingsService} from '../../../../services/manage/manage-settings.service';
import {UserPermissionsService} from '../../../../services/auth/user-permissions.service';

@Component({
  selector: 'manage-settings-layout',
  templateUrl: './manage-settings-layout.component.html',
  styleUrls: ['./manage-settings-layout.component.scss']
})
export class ManageSettingsLayoutComponent implements OnInit, OnChanges {

  public menuItems: ManageSettingsMenuItemModel[] = [];

  @Input()
  public isFinishing : boolean;

  @Input()
  public hasPermissionsToHealth : boolean;

  @Input()
  public currentSettingsScreenType : ManageSettingsScreenType;

  @Output()
  public onOpenScreen : EventEmitter<ManageSettingsScreenType> = new EventEmitter<ManageSettingsScreenType>();

  public ManageSettingsScreenType = ManageSettingsScreenType;

  public FarmMode = FarmMode;

  constructor(private readonly configService : ConfigService,
              private readonly userPermissionsService: UserPermissionsService) { }

  public ngOnInit() {
    this.initMenuItems();
    this.updateMenuActiveState();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.currentSettingsScreenType &&
       changes.currentSettingsScreenType.previousValue != changes.currentSettingsScreenType.currentValue) {
      this.updateMenuActiveState();
    }
  }

  public menuItemClick(menuItem : ManageSettingsMenuItemModel) {
    if(menuItem.subMenus.length == 0) {
      this.onOpenScreen.emit(menuItem.screenType);
    } else {
      menuItem.isOpen = !menuItem.isOpen;
    }
  }

  public subMenuItemClick(menuItem : ManageSettingsMenuItemModel, subMenuItem : ManageSettingsSubMenuItemModel) {
    this.onOpenScreen.emit(subMenuItem.screenType);
  }

  public get isHealthScreen() : boolean {
    return this.currentSettingsScreenType == ManageSettingsScreenType.healthReport ||
           this.currentSettingsScreenType == ManageSettingsScreenType.healthFreshReport ||
           this.currentSettingsScreenType == ManageSettingsScreenType.healthDistressSensitivity ||
           this.currentSettingsScreenType == ManageSettingsScreenType.healthDaysToWeaning;
  }

  public get isReproductionScreen() : boolean {
    return this.currentSettingsScreenType == ManageSettingsScreenType.reproductionVoluntaryWaitingPeriod ||
           this.currentSettingsScreenType == ManageSettingsScreenType.reproductionPregnancyLength ||
           this.currentSettingsScreenType == ManageSettingsScreenType.reproductionPregnancyDaysToDryOff;
  }

  private initMenuItems() {
    this.menuItems = [];
    this.menuItems.push({screenType: ManageSettingsScreenType.welcome,
                         shId: 'welcome-route',
                         caption: 'MANAGE.WELCOME.TITLE.WELCOME',
                         isOpen: false,
                         isActive: false,
                         subMenus: []});
    if (this.configService.serverConfig.user.farmRole != FarmRole.ExternalUser && this.configService.serverConfig.user.farmRole != FarmRole.Trial) {
      this.menuItems.push({screenType: ManageSettingsScreenType.alerts,
        shId: 'notifications-route',
        caption: 'MANAGE.WELCOME.TITLE.NOTIFICATIONS',
        isOpen: false,
        isActive: false,
        subMenus: [{screenType: ManageSettingsScreenType.alerts,
          caption: 'MANAGE.SETTINGS.ALERTS.SECTION_TITLE',
          shId: 'notifications-alerts-route',
          isActive: false}]});
    }
    if(!this.isFinishing) {
      let reproductionMenuItem : ManageSettingsMenuItemModel = {screenType: ManageSettingsScreenType.reproductionVoluntaryWaitingPeriod,
                                                                      shId: 'reproduction-toggle',
                                                                   caption: 'MANAGE.WELCOME.TITLE.REPRODUCTION',
                                                                    isOpen: false,
                                                                  isActive: false,
                                                                  subMenus: [{screenType: ManageSettingsScreenType.reproductionVoluntaryWaitingPeriod,
                                                                                 caption: 'MANAGE.WELCOME.TITLE.VOLUNTARY_WAITING_PERIOD',
                                                                                    shId: 'reproduction-voluntary-waiting-route',
                                                                                isActive: false},
                                                                             {screenType: ManageSettingsScreenType.reproductionPregnancyLength,
                                                                                 caption: 'MANAGE.WELCOME.TITLE.PREGNANCY_LENGTH',
                                                                                    shId: 'reproduction-pregnancy-length-route',
                                                                                isActive: false}]};
      if(this.configService.serverConfig.farmMode === FarmMode.Dairy) {
        reproductionMenuItem.subMenus.push({screenType: ManageSettingsScreenType.reproductionPregnancyDaysToDryOff,
                                               caption: 'MANAGE.WELCOME.TITLE.PREGNANCY_DAYS_TO_DRY_OFF',
                                                  shId: 'reproduction-pregnancy-days-to-dry-off-route',
                                              isActive: false});
      }
      this.menuItems.push(reproductionMenuItem);
    }
    if(this.hasPermissionsToHealth || this.isFinishing) {
      let healthMenuItem : ManageSettingsMenuItemModel = {screenType: ManageSettingsScreenType.healthReport,
                                                                shId: 'health-toggle',
                                                             caption: 'MANAGE.WELCOME.TITLE.HEALTH',
                                                              isOpen: false,
                                                            isActive: false,
                                                            subMenus: []};
      if(this.isFinishing) {
        healthMenuItem.subMenus.push({screenType: ManageSettingsScreenType.healthReport,
                                         caption: 'MANAGE.WELCOME.TITLE.FINISHING_HEALTH_REPORT',
                                            shId: 'health-report-route',
                                        isActive: false});
      }
      else {
        healthMenuItem.subMenus.push({screenType: ManageSettingsScreenType.healthReport,
                                         caption: 'MANAGE.WELCOME.TITLE.HEALTH_REPORT',
                                            shId: 'health-report-route',
                                        isActive: false});
        if (this.userPermissionsService.isApplicationPlanSupportsExtendedViewForHealth) {
          healthMenuItem.subMenus.push({screenType: ManageSettingsScreenType.healthFreshReport,
            caption: 'MANAGE.WELCOME.TITLE.FRESH_REPORT',
            shId: 'fresh-report-route',
            isActive: false});
          healthMenuItem.subMenus.push({screenType: ManageSettingsScreenType.healthDistressSensitivity,
            caption: 'MANAGE.WELCOME.TITLE.DISTRESS_SENSITIVITY',
            shId: 'distress-sensitivity-route',
            isActive: false});
        }
        if(this.configService.serverConfig.farmMode === FarmMode.Beef) {
          healthMenuItem.subMenus.push({screenType: ManageSettingsScreenType.healthDaysToWeaning,
            caption: 'MANAGE.WELCOME.TITLE.DAYS_TO_WEANING',
            shId: 'days-to-weaning-route',
            isActive: false
          });
        }
      }
      this.menuItems.push(healthMenuItem);
    }
    if (this.configService.serverConfig.farmMode === FarmMode.Dairy &&
       (this.configService.serverConfig.bizModeParameters.applicationBundle == ApplicationBundle.Premium ||
        this.configService.serverConfig.isSortingEnabled)) {
      let farmRoutineMenuItem: ManageSettingsMenuItemModel = {screenType: this.configService.serverConfig.isSortingEnabled ?
                                                                          ManageSettingsScreenType.farmRoutineShifts :
                                                                          ManageSettingsScreenType.farmRoutineGroupRoutineEvents,
                                                                    shId: 'farm-routine-route',
                                                                 caption: 'MANAGE.WELCOME.TITLE.FARM_ROUTINE',
                                                                  isOpen: false,
                                                                isActive: false,
                                                                subMenus: []};
      if (this.configService.serverConfig.isSortingEnabled) {
        farmRoutineMenuItem.subMenus.push({screenType: ManageSettingsScreenType.farmRoutineShifts,
                                              caption: 'MANAGE.WELCOME.TITLE.SHIFTS',
                                                 shId: 'shifts-route',
                                             isActive: false});
      }
      if(this.configService.serverConfig.bizModeParameters.applicationBundle == ApplicationBundle.Premium) {
        farmRoutineMenuItem.subMenus.push({screenType: ManageSettingsScreenType.farmRoutineGroupRoutineEvents,
                                           caption: 'MANAGE.WELCOME.TITLE.GROUP_ROUTINE_EVENTS',
                                           shId: 'group-routine-events-route',
                                           isActive: false});
      }
      this.menuItems.push(farmRoutineMenuItem);
    }
  }

  private updateMenuActiveState() {
    for (let menuItem of this.menuItems) {
      menuItem.isActive =false;
      for (let subMenuItem of menuItem.subMenus) {
        subMenuItem.isActive = false;
      }
    }
    for (let menuItem of this.menuItems) {
      if (menuItem.screenType == this.currentSettingsScreenType ||
        menuItem.subMenus.some(subMenuItem => subMenuItem.screenType == this.currentSettingsScreenType)) {
        menuItem.isActive = true;
        menuItem.isOpen = true;
        for (let subMenuItem of menuItem.subMenus) {
          if (subMenuItem.screenType == this.currentSettingsScreenType) {
            subMenuItem.isActive = true;
          }
        }
      }
    }
  }
}
