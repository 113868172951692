export enum FarmRole {
  SuperUser = 'SuperUser',
  SystemUser = 'SystemUser',
  SupportUser = 'SupportUser',
  Admin = 'Admin',
  Trial = 'Trial',
  Thirdparty = 'Thirdparty',
  FarmManager = 'FarmManager',
  Farmer = 'Farmer',
  ExternalUser = 'ExternalUser'
}
