<div class="add-branch-wrapper" nonExternalUser>
  <div class="add-branch-btn" [ngClass]="{ addBranchOpen: isOpen }" [attr.sh-id]="'add-branch-button'" (click)="toggleOpen()" saveDataWarningInject>
    <div class="add-icon" *ngIf="!isOpen"></div>
    <div class="remove-icon" *ngIf="isOpen"></div>
  </div>
</div>

<corner-container *ngIf="isOpen"
                  [attr.sh-id]="'add-group-dialog'"
                  [cornerColor]="CornerColor.Blue"
                  (onCornerButtonClick)="saveBranch()"
                  [footerHeight]="50"
                  [bodyBackground]="CornerContainerBodyBackground.Blue">
  <ng-container container-body>
    <div class="add-branch-container">

      <div class="main-field">
        <div class="title-div" translation-id="MANAGE.BRANCHES.NEW_BRANCH.TITLE">
          {{ 'MANAGE.BRANCHES.NEW_BRANCH.TITLE' | translate }}
        </div>
        <div class="value-div">
          <input class="input-class"
                 [attr.sh-id]="'input_branch_name'"
                 [ngClass]="{ 'error': hasErrorForField(branchFields.BranchName)}"
                 type="text"
                 [attr.translation-id]="'MANAGE.BRANCHES.PLACEHOLDER.BRANCH_NAME'"
                 placeholder="{{ 'MANAGE.BRANCHES.PLACEHOLDER.BRANCH_NAME' | translate }}"
                 [maxLength]="16"
                 (ngModelChange)="clearErrors()"
                 [(ngModel)]="groupBranchModel.groupBranchName" />
        </div>
      </div>
      <div class="seperator"></div>
      <div class="secondery-field-wrapper">
        <div class="secondery-field">
          <div class="title-div" translation-id="MANAGE.BRANCHES.NEW_BRANCH.BRANCH_NUMBER">
            {{ 'MANAGE.BRANCHES.NEW_BRANCH.BRANCH_NUMBER' | translate }}
          </div>
          <div class="value-div">
            <input class="input-class"
                   [attr.sh-id]="'input_branch_number'"
                   [ngClass]="{ 'error': hasErrorForField(branchFields.BranchNumber)}"
                   type="text"
                   inputFilter
                   [inputFilterMode]="InputFilterMode.Numeric"
                   [attr.translation-id]="'MANAGE.BRANCHES.PLACEHOLDER.BRANCH_NUMBER'"
                   placeholder="{{ 'MANAGE.BRANCHES.PLACEHOLDER.BRANCH_NUMBER' | translate }}"
                   [maxLength]="20"
                   (ngModelChange)="clearErrors()"
                   [(ngModel)]="groupBranchModel.groupBranchNumber" />
          </div>
        </div>
        <div class="errors-div">
          <div class="error-text" *ngIf="Error && Error.key" [attr.translation-id]="'SYSTEM.MANAGEMENT.ERROR_BOLD: MANAGE.BRANCHES.WIZARD.ERROR.' + Error.key">
            <b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}:</b> {{ 'MANAGE.BRANCHES.WIZARD.ERROR.' + Error.key | translate }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container container-footer>
    <div class="lining-pad"></div>
    <!-- Buttons Container to contain all the actions of the container -->
    <div class="container-buttons">
      <div class="check-button" (click)="saveBranch()" [attr.sh-id]="'create_edit_branch_button'">
        <div class="check-icon"></div>
      </div>
    </div>
  </ng-container>
</corner-container>
