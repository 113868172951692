import { Component, OnInit } from '@angular/core';
import {SystemHealthEventRow} from '../../../../../services/animals/model/animal-events';
import {DescriptionCellComponent} from '../../../../../common/components/data-grid/description-cell-template/description-cell.component';

@Component({
  selector: 'system-health-event-description',
  templateUrl: './system-health-event-description.component.html',
  styleUrls: ['./system-health-event-description.component.scss']
})
export class SystemHealthEventDescriptionComponent extends DescriptionCellComponent<SystemHealthEventRow> implements OnInit {

  constructor() {
    super();
  }

  public ngOnInit() {
  }

}
