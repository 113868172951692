import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {TableColumn} from '@swimlane/ngx-datatable';
import {debounce} from 'lodash';
import {
  IReport,
  IReportColumnDefinition,
  ReportColumnFilterType,
  ReportColumnType,
  ReportDataRow,
  ReportKeyType,
  ReportRowNavigationScreen,
  SyncStatusDatetimeColumnName
} from '../../../../../../services/reports/model/report.interface';
import {ConfigService} from '../../../../../../services/config/config.service';
import {GoogleAnalyticsService} from '../../../../../../services/google-analytics/google-analytics.service';
import {ReportsService} from '../../../../../../services/reports/reports.service';
import {EntityType, SearchService} from '../../../../../../services/search/search.service';
import {SystemService} from '../../../../../../services/system/system.service';
import {WINDOW} from '../../../../../../utils/window-utils';
import {AnimalCardTabSection} from '../../../../../card-popup/animal-card/animal-card-model';
import {FarmMode} from '../../../../../../services/config/model/server-config';
import {OperationType} from '../../../../../../services/search/model/search.model';
import {DateTimeFormatEnum} from 'src/app/common/pipes/epoch-date-time.pipe';
import {PantingTrendType} from 'src/app/common/components/reports/report-grid/report-grid.component';
import {GroupCardTabSection} from '../../../../../card-popup/group-card/group-card-model';
import {CardViewStateService} from '../../../../../../services/ui/view-state/card-view-state.service';

enum DashboardReportValueType {
  Plain = 'Plain',
  Trend = 'Trend',
  Percentage = 'Percentage',
  AnimalId = 'AnimalId',
  GroupName = "GroupName",
  TagNumber = "TagNumber",
  LactationStatus = 'LactationStatus',
  DateFormat = 'DateFormat',
  Duration = 'Duration',
  HoursMinutesDuration = 'HoursMinutesDuration',
  Localization = 'Localization',
  DynamicLocalization = 'DynamicLocalization',
  BreedingWindow = 'BreedingWindow',
  Boolean = 'Boolean',
  AnimalsToInspectReasons = 'AnimalsToInspectReasons',
  SortingReason = 'SortingReason',
  Sorted = 'Sorted'
}

interface IDashboardTableColumn extends TableColumn {
  details : IReportColumnDefinition
}

@Component({
  selector: 'dashboard-report-data-grid',
  templateUrl: './dashboard-report-data-grid.component.html',
  styleUrls: ['./dashboard-report-data-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardReportDataGridComponent implements OnInit, OnChanges {

  @ViewChild('invisibleHeader', {static: true}) // tslint:disable-next-line:no-any
  public invisibleHeader: TemplateRef<any>;

  @ViewChild('animalIdTemplate', {static: true}) // tslint:disable-next-line:no-any
  public animalIdTemplate: TemplateRef<any>;

  @ViewChild('groupNameTemplate', {static: true})  // tslint:disable-next-line:no-any
  public groupNameTemplate: TemplateRef<any>;

  @ViewChild('tagNumberTemplate', {static: true})  // tslint:disable-next-line:no-any
  public tagNumberTemplate: TemplateRef<any>;

  @ViewChild('plainTemplate', {static: true}) // tslint:disable-next-line:no-any
  public plainTemplate: TemplateRef<any>;

  @ViewChild('dateFormatTemplate', {static: true}) // tslint:disable-next-line:no-any
  public dateFormatTemplate: TemplateRef<any>;

  @ViewChild('durationTemplate', {static: true}) // tslint:disable-next-line:no-any
  public durationTemplate: TemplateRef<any>;

  @ViewChild('hoursMinutesDurationTemplate', {static: true}) // tslint:disable-next-line:no-any
  public hoursMinutesDurationTemplate: TemplateRef<any>;

  @ViewChild('trendTemplate', {static: true}) // tslint:disable-next-line:no-any
  public trendTemplate: TemplateRef<any>;

  @ViewChild('percentageTemplate', {static: true}) // tslint:disable-next-line:no-any
  public percentageTemplate: TemplateRef<any>;

  @ViewChild('localizationTemplate', {static: true}) // tslint:disable-next-line:no-any
  public localizationTemplate: TemplateRef<any>;

  @ViewChild('dynamicLocalizationTemplate', {static: true}) // tslint:disable-next-line:no-any
  public dynamicLocalizationTemplate: TemplateRef<any>;

  @ViewChild('booleanTemplate', {static: true}) // tslint:disable-next-line:no-any
  public booleanTemplate: TemplateRef<any>;

  @ViewChild('inspectReasonTemplate', {static: true}) // tslint:disable-next-line:no-any
  public inspectReasonTemplate: TemplateRef<any>;

  @ViewChild('lactationStatusTemplate', {static: true}) // tslint:disable-next-line:no-any
  public lactationStatusTemplate: TemplateRef<any>;

  @ViewChild('breedingWindowTemplate', {static: true}) // tslint:disable-next-line:no-any
  public breedingWindowTemplate: TemplateRef<any>;

  @ViewChild('ngxDatatableContainer', {static: true})
  public ngxDatatableContainer: ElementRef;

  @Input()
  public report: IReport;

  public initGrid: boolean = true;

  public rows : ReportDataRow[] = [];

  public columns : IDashboardTableColumn[] = [];

  public DateTimeFormatEnum = DateTimeFormatEnum;

  public PantingTrendType = PantingTrendType;

  // tslint:disable-next-line:no-any
  private templatesMap:Map<DashboardReportValueType, TemplateRef<any>> = new Map<DashboardReportValueType, TemplateRef<any>>();

  constructor(private readonly cardViewStateService:CardViewStateService,
              public readonly systemService: SystemService,
              public readonly searchService: SearchService,
              private readonly reportsService: ReportsService,
              private readonly googleAnalyticsService: GoogleAnalyticsService,
              private readonly configService: ConfigService,
              @Inject(WINDOW) private _window: Window) {
  }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.initTemplatesMap();
    this.initData();
  }

  public get datatableContainerHeightPixels() : number {
    return (<HTMLElement>this.ngxDatatableContainer.nativeElement).getBoundingClientRect().height + 30;
  }

  public get datatableContainerWidthPixels() : number {
    return (<HTMLElement>this.ngxDatatableContainer.nativeElement).getBoundingClientRect().width;
  }

  public onGridScroll = debounce(() => {
      this.rows = [...this.rows];
    });

  public getRowHeight(row : ReportDataRow) : number {
    if(row['rowExpanded']) {
      return 120;
    }
    else {
      return 80;
    }
  }

  public toggleRowExpanded(selectedRow : ReportDataRow) {
    this.rows.forEach(row => {
      if(row == selectedRow) {
        row['rowExpanded'] = !row['rowExpanded'];
      } else {
        row['rowExpanded'] = false;
      }
    });
    // refresh the scroll height
    this.rows = [...this.rows];
  }

  public getRowClass():string{
    return 'report-row';
  }

  public async onReportGridValueCellClick(row: ReportDataRow) {
    switch (this.report.meta.reportKeyType) {
      case ReportKeyType.animal:
      {
        // tslint:disable-next-line:no-any
        let relatedAnimals = Array.from(this.report.rows.map((row: any) => { return row.CowDatabaseIDCalculation }));
        // tslint:disable-next-line:no-any
        let relatedAnimalRowIds = Array.from(this.report.rows.map((row: any) => { return row.rowId }));
        let selectedAnimalId = row['CowDatabaseIDCalculation'];
        let animalCardTabSection = AnimalCardTabSection.Events;
        if(row.navigation == ReportRowNavigationScreen.CowCardHeatGraph) {
          animalCardTabSection = AnimalCardTabSection.GraphsHeat;
        } else if(row.navigation == ReportRowNavigationScreen.CowCardHealthGraph) {
          animalCardTabSection = AnimalCardTabSection.GraphsHealth;
        }
        let operationType: OperationType = await this.searchService.findOperationTypeByEntityId(selectedAnimalId, EntityType.cow);
        this.cardViewStateService.openAnimalCard(selectedAnimalId,
          operationType,
          relatedAnimals,
          ['GROUP.NAVIGATION.COW_IN', 'REPORTS.HEADER.' + this.report.meta.reportName, 'GROUP.NAVIGATION.REPORT' ],
          animalCardTabSection);
        break;
      }
      case ReportKeyType.group:
      {
        // tslint:disable-next-line:no-any
        let relatedGroups = Array.from(this.report.rows.map((row: any) => { return row.GroupDatabaseIDCalculation }));
        // tslint:disable-next-line:no-any
        let relatedGroupsRowIds = Array.from(this.report.rows.map((row: any) => { return row.rowId }));
        let selectedGroupId = row['GroupDatabaseIDCalculation'];
        const groupCardTabSection = DashboardReportDataGridComponent.handleGroupCardNavigationFromReport(row.navigation);
        let operationType: OperationType = await this.searchService.findOperationTypeByEntityId(selectedGroupId, EntityType.group);
        this.cardViewStateService.openGroupCard(selectedGroupId,
          operationType,
          relatedGroups,
          ['GROUP.NAVIGATION.GROUP_IN','GROUP.NAVIGATION.GROUP_IN_FARM'],
          groupCardTabSection);
        break;
      }
      case ReportKeyType.tag:
      {
        if (!this.isTagMaintenanceClickIgnored(row)) {
          // tslint:disable-next-line:no-any
          let relatedAnimals = Array.from(this.report.rows.map((row: any) => { return row.TagCowDatabaseIDCalculation }));
          // tslint:disable-next-line:no-any
          let relatedAnimalRowIds = Array.from(this.report.rows.map((row: any) => { return row.rowId }));
          let selectedAnimalId = row['TagCowDatabaseIDCalculation'];
          let animalCardTabSection = AnimalCardTabSection.Events;
          this.cardViewStateService.openAnimalCard(selectedAnimalId,
            this.configService.serverConfig.farmMode == FarmMode.Beef ? OperationType.BeefBreeding : OperationType.Dairy,
            relatedAnimals,
            ['GROUP.NAVIGATION.COW_IN', 'REPORTS.HEADER.' + this.report.meta.reportName, 'GROUP.NAVIGATION.REPORT' ],
            animalCardTabSection);
        }
        break;
      }
    }
  }

  public isFirstColumn(column:IDashboardTableColumn) : boolean {
    return this.columns[0] == column;
  }

  public isLastColumn(column:IDashboardTableColumn) : boolean {
    return this.columns[this.columns.length - 1] == column;
  }

  private initData() {
    this.initGrid = true;
    let filteredColumns = ReportsService.filterVisibleColumns(this.report.columns);

    this.columns = [];
    filteredColumns.forEach((col : IReportColumnDefinition) => {
      let reportValueType = this.getDashboardReportValueType(col);
      this.columns.push({
        name: col.key,
        headerTemplate: this.invisibleHeader,
        cellTemplate: this.templatesMap.get(reportValueType),
        details: col,
        flexGrow: this.getColumnFlexGrow(col.key)
      });
    });
    this.rows = this.report.rows;
    this.rows = this.report.rows.map(reportDataRow => {
      this.reportsService.setRowNavigation(this.report, reportDataRow);
      return reportDataRow;
    });
    setTimeout(() => {
      this.initGrid = false;
    });
  }

  private initTemplatesMap() {
    this.templatesMap.set(DashboardReportValueType.AnimalId, this.animalIdTemplate);
    this.templatesMap.set(DashboardReportValueType.GroupName, this.groupNameTemplate);
    this.templatesMap.set(DashboardReportValueType.TagNumber, this.tagNumberTemplate);
    this.templatesMap.set(DashboardReportValueType.Plain, this.plainTemplate);
    this.templatesMap.set(DashboardReportValueType.DateFormat, this.dateFormatTemplate);
    this.templatesMap.set(DashboardReportValueType.Duration, this.durationTemplate);
    this.templatesMap.set(DashboardReportValueType.HoursMinutesDuration, this.hoursMinutesDurationTemplate);
    this.templatesMap.set(DashboardReportValueType.Trend, this.trendTemplate);
    this.templatesMap.set(DashboardReportValueType.Percentage, this.percentageTemplate);
    this.templatesMap.set(DashboardReportValueType.Localization, this.localizationTemplate);
    this.templatesMap.set(DashboardReportValueType.DynamicLocalization, this.dynamicLocalizationTemplate);
    this.templatesMap.set(DashboardReportValueType.Boolean, this.booleanTemplate);
    this.templatesMap.set(DashboardReportValueType.LactationStatus, this.lactationStatusTemplate);
    this.templatesMap.set(DashboardReportValueType.AnimalsToInspectReasons, this.inspectReasonTemplate);
    this.templatesMap.set(DashboardReportValueType.BreedingWindow, this.breedingWindowTemplate);
  }

  private isTagMaintenanceClickIgnored(row: ReportDataRow & { TagAnimalIDCalculation?: string, TagGroupNameCalculation?: string }): boolean {
    return (row.TagAnimalIDCalculation === null || typeof row.TagAnimalIDCalculation === 'undefined' || row.TagAnimalIDCalculation === '')
      && (row.TagGroupNameCalculation === null || typeof row.TagGroupNameCalculation === 'undefined' || row.TagGroupNameCalculation === '');
  }

  private static handleGroupCardNavigationFromReport(rowNavigation: ReportRowNavigationScreen): GroupCardTabSection {
    switch (rowNavigation) {
      case ReportRowNavigationScreen.GroupRoutineHeatStressGraph: {
        return GroupCardTabSection.GraphsHeat;
      }
      case ReportRowNavigationScreen.GroupRoutineRuminationGraph: {
        return GroupCardTabSection.GraphsActivity;
      }
      default: {
        return GroupCardTabSection.Details;
      }
    }
  }

  public isSyncStatusDateTimeCell(row: ReportDataRow, column: TableColumn & {valueType: DashboardReportValueType, name: string}): boolean {
    return column.valueType === DashboardReportValueType.DateFormat && [
      SyncStatusDatetimeColumnName.SyncStatusReceiveTimeCalculation as string,
      SyncStatusDatetimeColumnName.SyncStatusProcessStartTimeCalculation as string
    ].includes(column.name);
  }

  private getColumnFlexGrow(colKey:string):number{
    let flexGrow = 1;
    if(colKey === 'InseminationWindowCalculation'){
      flexGrow = 2;
    } else if(colKey === 'LactationStatusCalculation') {
      flexGrow = 0.5;
    }
    return flexGrow;
  }

  private getDashboardReportValueType(reportColumn: IReportColumnDefinition): DashboardReportValueType {
    if (reportColumn.type == ReportColumnType.DateTime) {
      if (reportColumn.webMetaType == 'Duration') {
        return DashboardReportValueType.Duration;
      } else {
        return DashboardReportValueType.DateFormat;
      }
    } else if (reportColumn.key === 'PantingTrendCalculation' && reportColumn.webMetaType === 'Trend') {
      return DashboardReportValueType.Trend;
    } else if (reportColumn.key === 'PercentOfPantingCowsCalculation' || reportColumn.key === 'PercentOfRuminatingCowsCalculation') {
      return DashboardReportValueType.Percentage;
    } else if (reportColumn.key === 'DistressDurationCalculation') {
      return DashboardReportValueType.HoursMinutesDuration;
    } else if (reportColumn.mobileMetaType == 'LactationStatus') {
      return DashboardReportValueType.LactationStatus;
    } else if (reportColumn.key == 'AnimalIDCalculation') {
      return DashboardReportValueType.AnimalId;
    } else if (reportColumn.key == 'GroupNameCalculation') {
      return DashboardReportValueType.GroupName;
    } else if (reportColumn.key == 'TagNumberCalculation') {
      return DashboardReportValueType.TagNumber;
    } else if (reportColumn.type == ReportColumnType.Localization) {
      return DashboardReportValueType.Localization;
    } else if (reportColumn.type == ReportColumnType.DynamicLocalization) {
      return DashboardReportValueType.DynamicLocalization;
    } else if (reportColumn.webMetaType == 'InseminationWindow') {
      return DashboardReportValueType.BreedingWindow;
    } else if (reportColumn.filterType == ReportColumnFilterType.Boolean) {
      return DashboardReportValueType.Boolean;
    } else if (reportColumn.type == ReportColumnType.AnimalsToInspectReasons) {
      return DashboardReportValueType.AnimalsToInspectReasons;
    } else if (reportColumn.type == ReportColumnType.SortingReason) {
      return DashboardReportValueType.SortingReason;
    } else if (reportColumn.key == 'SortedCalculation') {
      return DashboardReportValueType.Sorted;
    }
    return DashboardReportValueType.Plain;
  }
}
