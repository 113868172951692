<div sh-id="add-event-btn"
     class="add-event-btn"
     saveDataWarningInject
	   nonExternalUser
     [ngClass]="{ 'add-event-open': isOpen, 'add-event-btn-disabled': isAddEventButtonDisabled }"
     (click)="toggleOpen()">
  <div class="add-icon" *ngIf="!isOpen"></div>
  <div class="remove-icon" *ngIf="isOpen"></div>
</div>
<corner-container *ngIf="isOpen"
                  [cornerColor]="isSaveEnabled ? CornerColor.Blue : CornerColor.BlueDisabled"
                  (onCornerButtonClick)="save($event)"
                  [footerHeight]="50"
                  [bodyBackground]="CornerContainerBodyBackground.Gray">
  <ng-container container-body>
    <div class="add-event-section" saveDataEditSection [editAreaName]="EventEditorAreaName">
      <div class="add-new-event-field-div">
        <div class="title-div">
          {{ 'ANIMAL.EVENTS.ADD_EVENT.ADD_NEW_EVENT' | translate }}
        </div>
        <div class="value-div" [attr.sh-id]="'SelectEvent'">
          <animal-card-events-menu [availableEvents]="availableEvents"
                                   (eventSelected)="onEventSelected($event)"
                                   [applicationBundleRoutingMode]="applicationBundleRoutingMode"
                                   [autofocus]="true">
          </animal-card-events-menu>
        </div>
        <div class="title-div"></div>
        <div class="selection-date-time-wrapper">
          <div class="date-container-wrapper">
            <div class="title-div" [attr.translation-id]="'MANAGE.EVENTS.DATE'">
              {{ 'MANAGE.EVENTS.DATE' | translate }}
            </div>
            <date-picker [selectedEpoch]="selectedEpoch"
                         [isFieldError]="eventDetailsError?.fieldName == 'startDateTime'"
                         [shIdPostfix]="'selected_date'"
                         [shownRemovableIcon]="false"
                         [calendarIconColor]="'#1999d5'"
                         (epochChanged)="epochChanged($event)">
            </date-picker>
          </div>
          <div class="time-container-wrapper">
            <div class="title-div" [attr.translation-id]="'REPORTS.GRID.TIME'">
              {{ 'REPORTS.GRID.TIME' | translate }}
            </div>
            <time-picker [selectedEpoch]="selectedEpoch"
                         [isFieldError]="eventDetailsError?.fieldName == 'startDateTime'"
                         [shIdPostfix]="'selected_time'"
                         [themeColor]="'#1999d5'"
                         (epochChanged)="epochChanged($event)"
                         [shownRemovableIcon]="false">
            </time-picker>
          </div>

        </div>
      </div>
      <div class="line-seperator"></div>
      <div class="event-container" *ngIf="selectedEvent" [ngSwitch]="selectedEvent.type">
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.AssignScrTag">
          <assign-tag-event-form
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [mode]="CreateEventMode.single"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </assign-tag-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.AssignRfIdTag">
          <assign-tag-event-form
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [mode]="CreateEventMode.single"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </assign-tag-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.ChangeScrTag">
          <change-tag-event-form
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [mode]="CreateEventMode.single"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </change-tag-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.ChangeRfIdTag">
          <change-tag-event-form
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [mode]="CreateEventMode.single"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </change-tag-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.RemoveScrTag">
          <remove-tag-event-form
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [mode]="CreateEventMode.single"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </remove-tag-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.RemoveRfIdTag">
          <remove-tag-event-form
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [mode]="CreateEventMode.single"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </remove-tag-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Abortion">
          <abortion-event-form
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [mode]="CreateEventMode.single"
            [errorModel]="eventDetailsError"
            [isAddNewbornsDisabled]="false"
            (modelChanged)="onModelChanged($event)">
          </abortion-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.AbortionSameLactation">
          <abortion-same-lactation-event-form
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [mode]="CreateEventMode.single"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </abortion-same-lactation-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Breeding">
          <breeding-event-form
            [mode]="CreateEventMode.single"
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </breeding-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Calving">
          <calving-event-form
            class="red"
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [mode]="CreateEventMode.single"
            [errorModel]="eventDetailsError"
            [isAddNewbornsDisabled]="false"
            (modelChanged)="onModelChanged($event)">
          </calving-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.ChangeGroup">
          <change-group-event-form
            [mode]="CreateEventMode.single"
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </change-group-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Weaning">
          <weaning-event-form
            [mode]="CreateEventMode.single"
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </weaning-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Culling">
          <culling-event-form
            [mode]="CreateEventMode.single"
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </culling-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.DoNotBreed">
          <do-not-breed-event-form
            [mode]="CreateEventMode.single"
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </do-not-breed-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.DryOff">
          <dry-off-event-form
            [mode]="CreateEventMode.single"
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </dry-off-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.PregnancyCheck">
          <pregnancy-check-event-form
            [mode]="CreateEventMode.single"
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </pregnancy-check-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Unsort">
          <unsort-event-form
            [mode]="CreateEventMode.single"
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </unsort-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Weight">
          <weight-event-form
            [mode]="CreateEventMode.single"
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </weight-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Castration">
          <castration-event-form
            [mode]="CreateEventMode.single"
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </castration-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.StartBackground">
          <start-background-event-form
            [mode]="CreateEventMode.single"
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </start-background-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.StartFinishing">
          <start-finishing-event-form
            [mode]="CreateEventMode.single"
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </start-finishing-event-form>
        </div>
        <div class="event-template-container" *ngSwitchCase="EventTypeEnum.General">
          <general-event-form
            [mode]="CreateEventMode.single"
            [meta]="selectedEventMeta"
            [model]="eventDetails"
            [errorModel]="eventDetailsError"
            (modelChanged)="onModelChanged($event)">
          </general-event-form>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container container-footer>
    <div class="errors-div" saveDataWarningIgnore>
      <event-form-errors [errorModel]="eventDetailsError"></event-form-errors>
    </div>
    <div class="container-buttons" (click)="save($event)" [ngClass]="{'disabled' : !isSaveEnabled}" saveDataWarningIgnore>
      <div
        class="check-button"
        [attr.sh-id]="'save_event_button_' + (isSaveEnabled ? 'enabled':'disabled')">
        <div class="check-icon"></div>
      </div>
    </div>
  </ng-container>
</corner-container>
