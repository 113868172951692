<div outsideClickHandler (onOutsideClick)="closeAlertsDialog()">
  <div class="alerts-header" (click)="toggleAlertsDialog()" [ngClass]="{'open': alertCount, 'active': alertCount}" sh-id="animal_alerts_toggle">
    <div class="icon icon-alert-connection"></div>
    <div class="alerts-label" [ngClass]="{'active': alertCount}">
      <span>{{alertCount}}</span>&nbsp;&nbsp;
      <span>{{'ALERTS' | translate}}</span>
    </div>
    <div class="shadow" *ngIf="!isPopupOpen"></div>
  </div>
  <div class="animated-popup" *ngIf="isPopupOpen" sh-id="animal_alerts_popup" [ngClass]="{'open': alertCount, 'active': alertCount}" @animal-alerts-transition>
    <div class="animal-alerts-popup">
      <div class="frame-corner"></div>
      <div class="alerts-popup-title">
        <div class="title-text">{{'HEADER.ALERTS.ANIMAL_TITLE' | translate}}</div>
        <div class="btn-close" (click)="closeAlertsDialog()">
          <div class="icon icon-close"></div>
        </div>
        <div class="shadow"></div>
      </div>
      <div class="no-alerts-popup-content" *ngIf="!groupAlerts.length && !cowAlerts.length">
        <div class="empty-list">
          <div class="title">
            <span>{{ 'HEADER.ALERTS.NO_ALERTS' | translate}}</span>
            <span>{{ 'HEADER.ALERTS.ALL_GOOD' | translate}}</span>
          </div>
        </div>
        <div class="icon no-alerts-icon"></div>
      </div>
      <div class="alerts-popup-content" *ngIf="groupAlerts.length || cowAlerts.length">
        <ul class="alerts-popup-list">
          <li *ngFor="let cAlert of cowAlerts" class="alerts-popup-list-item" saveDataWarningInject>
            <div class="icon icon-cow" (click)="goToAnimal(cAlert)"></div>
            <a class="alert-link-text" (click)="goToAnimal(cAlert)">
              <span>#{{cAlert.entityNumber}}</span>
            </a>
            <span class="alert-description-text">{{cAlert.alertLocalization}}</span>
            <span class="alert-data-text">{{cAlert.alertDateTime | epochDateTime:DateTimeFormatEnum.DateTime}}</span>
          </li>
        </ul>
        <ul class="alerts-popup-list">
          <li *ngFor="let gAlert of groupAlerts" class="alerts-popup-list-item" saveDataWarningInject>
            <div class="icon icon-group" (click)="goToGroup(gAlert)"></div>
            <a class="alert-link-text" (click)="goToGroup(gAlert)">
              <span>#{{gAlert.entityNumber}}</span>
            </a>
            <span class="alert-description-text">{{gAlert.alertLocalization}}</span>
            <span class="alert-data-text">{{gAlert.alertDateTime | epochDateTime:DateTimeFormatEnum.DateTime}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
