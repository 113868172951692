import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'no-tasks-list',
  templateUrl: './no-tasks-list.component.html',
  styleUrls: ['./no-tasks-list.component.scss']
})
export class NoTasksListComponent implements OnInit {

  constructor(private router: Router) { }

  public ngOnInit() {
  }

  public async addLedTask() {
    await this.router.navigate(['/led-tasks/task-editor']);
  }

}
