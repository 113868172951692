import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'day-item',
  templateUrl: './day-item.component.html',
  styleUrls: ['./day-item.component.scss']
})
export class DayItemComponent implements OnInit {

  @Input()
  public dayText: string;

  @Input()
  public dayNumber: number;

  @Input()
  public isSelected: boolean = false;

  @Input()
  public isError: boolean = false;

  @Output()
  public onDaySelected: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  public ngOnInit() {
  }

  public toggleDay() {
    this.onDaySelected.emit(this.dayNumber);
  }

}
