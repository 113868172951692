import { Component, OnInit } from '@angular/core';
import {RegistrationService} from '../../../services/registration/registration.service';
import {
  IFarmThirdPartyResponse, ICRVRegistrationKeyRequest, ICRVRegistrationKeyResponse,
  IRegistrationKeyResponse,
  IThirdPartyItem
} from '../../../services/registration/model/registration.model';
import {HttpResponseResult} from '../../../services/rest-api/rest-api.service';
import {BindableToggleTheme} from '../../../common/components/bindable-toggle/bindable-toggle.component';
import {DropdownColorScheme} from '../../../common/components/dropdown-list/regular-dropdown-list/regular-dropdown-list.component';
import {DropdownListItem} from '../../../common/components/dropdown-list/dropdown-list-model';
import {InputFilterMode} from "../../../common/directives/input-filter.directive";
import {TranslationService} from '../../../services/translations/translation.service';

@Component({
  selector: 'herd-management',
  templateUrl: './herd-management.component.html',
  styleUrls: ['./herd-management.component.scss']
})
export class HerdManagementComponent implements OnInit {

  public displayedThirdPartyContainerType: ThirdPartyContainerType = ThirdPartyContainerType.validationMode;
  public thirdPartyContainerType = ThirdPartyContainerType;

  private thirdPartyOptionItems: DropdownListItem[];
  private selectedThirdParty: DropdownListItem;
  public bindableToggleTheme = BindableToggleTheme;
  private dropdownColorScheme = DropdownColorScheme;
  private isInputFolderInputShown: boolean;
  private isOutputFolderInputShown: boolean;
  private isCRVValueApproved: boolean;

  public InputFilterMode = InputFilterMode;

  constructor(public readonly registrationService: RegistrationService,
              private readonly translationService: TranslationService) { }

  public ngOnInit() {
    this.registrationService.getFarmThirdParty().then((response: HttpResponseResult<IFarmThirdPartyResponse>) => {
      this.registrationService.registrationModel.herdManagement = response.responseBody;
      this.updateHerdManagementFromCloud(response.responseBody);
    });
  }

  public updateHerdManagementFromCloud(herdManagement: IFarmThirdPartyResponse) {
    this.thirdPartyOptionItems = [];
    herdManagement.thirdParty.forEach((thirdPartyItem => {
      let dropdownItem: DropdownListItem = {
        displayValue: this.translationService.translate('REGISTRATION.THIRD_PARTY.' + thirdPartyItem.deviceType),
        value: thirdPartyItem.deviceType,
        disabled: false,
        icon: null
      };
      this.thirdPartyOptionItems.push(dropdownItem);
    }));
    if (herdManagement.deviceType) {
      this.registrationService.registrationModel.herdManagement.linkedStatus = true;
      this.selectedThirdParty = this.thirdPartyOptionItems.find((thirdParty: DropdownListItem) => thirdParty.value === herdManagement.deviceType) || null;
      this.onThirdPartySelected(this.selectedThirdParty);
    } else {
      this.registrationService.registrationModel.herdManagement.linkedStatus = false;
      this.registrationService.registrationModel.herdManagement.validationMode = true;
      this.selectedThirdParty = null;
    }
  }

  public toggleIntegrationMode() {
    if (this.registrationService.registrationModel.herdManagement.linkedStatus) {
      this.displayedThirdPartyContainerType = ThirdPartyContainerType.validationMode;
      this.registrationService.registrationModel.herdManagement.validationMode = true;
      this.selectedThirdParty = null;
    } else {
      this.selectedThirdParty = null;
      this.displayedThirdPartyContainerType = ThirdPartyContainerType.none;
      this.registrationService.registrationModel.herdManagement.validationMode = true;
    }
    this.registrationService.registrationModel.herdManagement.deviceType = null;
    this.registrationService.registrationModel.herdManagement.CRVRegistrationKey = null;
    this.registrationService.registrationModel.herdManagement.registrationKey = null;
    this.registrationService.registrationModel.herdManagement.linkedStatus = !this.registrationService.registrationModel.herdManagement.linkedStatus;
  }

  public toggleValidationMode() {
    this.registrationService.registrationModel.herdManagement.validationMode = !this.registrationService.registrationModel.herdManagement.validationMode;
  }

  public onThirdPartySelected(dropdownListItem: DropdownListItem) {
    if (dropdownListItem) {
      this.registrationService.registrationModel.herdManagement.deviceType = dropdownListItem.value;
      let thirdPartyItem: IThirdPartyItem = this.registrationService.registrationModel.herdManagement.thirdParty.find(thirdPartyItem => dropdownListItem.value === thirdPartyItem.deviceType );
      if (thirdPartyItem) {
        if (thirdPartyItem.supportByCloud) {
          if (thirdPartyItem.registrationKeyGeneratedByUser) {
            // display add farmID manually
            this.displayedThirdPartyContainerType = ThirdPartyContainerType.insertFarmIDManually;
          } else {
            this.displayedThirdPartyContainerType = ThirdPartyContainerType.registrationKeyFromCloud;
          }
        } else {
          if (thirdPartyItem.supportStatusFileWithOutputFolder || thirdPartyItem.supportStatusFileWithInputFolder) {
            // check if only input or output
            this.isInputFolderInputShown = thirdPartyItem.supportStatusFileWithInputFolder;
            this.isOutputFolderInputShown = thirdPartyItem.supportStatusFileWithOutputFolder;
            this.displayedThirdPartyContainerType = ThirdPartyContainerType.folderInputOutput;
          }
        }
      }
    } else {
      this.selectedThirdParty = null;
      this.registrationService.registrationModel.herdManagement.registrationKey = null;
      this.displayedThirdPartyContainerType = ThirdPartyContainerType.none;
    }
  }

  public getRegistrationKey() {
    this.registrationService.getRegistrationKey(this.registrationService.registrationModel.herdManagement.deviceType).then((response: HttpResponseResult<IRegistrationKeyResponse>) => {
      this.registrationService.registrationModel.herdManagement.registrationKey = response.responseBody.registrationKey;
    });
  }

  public applyCRVRegistrationKey() {
    this.clearErrors();
    let crvRegistrationKeyRequest: ICRVRegistrationKeyRequest = { deviceType: this.registrationService.registrationModel.herdManagement.deviceType, registrationKey: this.registrationService.registrationModel.herdManagement.CRVRegistrationKey };
    this.registrationService.postCRVRegistrationKey(crvRegistrationKeyRequest).then((response: HttpResponseResult<ICRVRegistrationKeyResponse>) => {
      if (response.responseBody && response.responseBody.succeeded) {
        this.registrationService.registrationModel.herdManagement.registrationKey = this.registrationService.registrationModel.herdManagement.CRVRegistrationKey;
        this.isCRVApproved();
      } else {
        this.registrationService.registrationModel.error = { fieldName: 'CRVRegistrationKeyInput', error: 'REGISTRATION.THIRD_PARTY.ERROR.' + response.errorResponseBody.result.failures[0] };
      }
      // tslint:disable-next-line:no-any
    }).catch((error: any) => {

    });
  }

  public isCRVApproved() {
    if (this.registrationService.registrationModel.herdManagement.linkedStatus && this.registrationService.registrationModel.herdManagement.CRVRegistrationKey) {
      this.isCRVValueApproved = this.registrationService.registrationModel.herdManagement.CRVRegistrationKey === this.registrationService.registrationModel.herdManagement.registrationKey;
    } else {
      this.isCRVValueApproved = false;
    }
    if (!this.isCRVValueApproved) {
      this.registrationService.registrationModel.herdManagement.registrationKey = null;
    }
  }

  public clearErrors() {
    this.registrationService.registrationModel.error = null;
  }
}
export enum ThirdPartyContainerType {
  none = 'none',
  validationMode = 'validation-mode',
  folderInputOutput = 'folder-input-output',
  registrationKeyFromCloud = 'registration-key-from-cloud',
  insertFarmIDManually = 'insert-farm-id-manually'
}
