import {Component, OnInit, ViewChild} from '@angular/core';
import {NotificationDialogTemplateComponent, NotificationDialogTheme} from '../../../common/components/dialogs/notification-dialog-template/notification-dialog-template.component';

import {ConfigService} from "../../../services/config/config.service";
import {AuthService} from "../../../services/auth/auth.service";

@Component({
  selector: 'database-is-updating-dialog',
  templateUrl: './database-is-updating-dialog.component.html',
  styleUrls: ['./database-is-updating-dialog.component.scss']
})
export class DatabaseIsUpdatingDialogComponent  implements OnInit {

  @ViewChild('dialogRef', {static: true})
  protected dialogRef: NotificationDialogTemplateComponent;
  
  public NotificationDialogTheme = NotificationDialogTheme;
  
  public async ngOnInit(): Promise<void> {
    await this.authService.getServerConfig();
  }

  constructor(private configService: ConfigService, private authService: AuthService) {
    this.configService.ServerConfigSubject.subscribe((serverConfig) => {
      if (serverConfig && serverConfig.systemMessages && serverConfig.systemMessages.length) {
        this.dialogRef.isOpen = true;
      }
    });
  }
}
