import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CardViewState, CardViewType} from '../card-popup-model';

@Component({
  selector: 'card-popup-header-breadcrumb',
  templateUrl: './card-popup-header-breadcrumb.component.html',
  styleUrls: ['./card-popup-header-breadcrumb.component.scss']
})
export class CardPopupHeaderBreadcrumbComponent implements OnInit {

  @Input()
  public cardViewState: CardViewState;

  public CardViewType = CardViewType;

  @Output()
  public onBreadcrumbNavigation: EventEmitter<CardViewType> = new EventEmitter<CardViewType>();

  constructor() { }

  public ngOnInit() {
  }

  public get isBranchLocalized() {
    return !!(this.cardViewState.groupCardViewState.groupDetails &&
      this.cardViewState.groupCardViewState.groupDetails.branchName &&
      this.cardViewState.groupCardViewState.groupDetails.branchName.isLocalization);
  }

  public navigateToCard(cardType: CardViewType) {
    this.onBreadcrumbNavigation.emit(cardType);
  }

  public get isAnimalBreadcrumbShown(): boolean {
    return !!(this.cardViewState.viewType != CardViewType.Animal && this.cardViewState.animalCardViewState.animalInfo);
  }

  public get isGroupBreadcrumbShown(): boolean {
    return !!(this.cardViewState.viewType != CardViewType.Group && this.cardViewState.groupCardViewState.groupDetails);
  }

  public get isBranchBreadcrumbShown(): boolean {
    return !!(this.cardViewState.viewType != CardViewType.Branch && this.cardViewState.branchCardViewState.branchDetails);
  }

}
