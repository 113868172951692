<div class="left-animals-container">
  <div class="time-fields">
    <div class="section-field single-time-field">
      <div class="title-div" [attr.translation-id]="'LED_TASKS.TASK_EDITOR.START_DATE'">{{ 'LED_TASKS.TASK_EDITOR.START_DATE' | translate }}</div>
      <div class="time-picker-wrapper">
        <date-picker [shownRemovableIcon]="false"
                     [datePickerBackground]="'#F4F4F4'"
                     [calendarIconColor]="'#1999D5'"
                     [selectedEpoch]="ledTasksService.taskModel.startDate"
                     (epochChanged)="ledTasksService.clearErrors()"
                     [shIdPostfix]="'start-date-picker-input'"
                     [isFieldError]="ledTasksService.isFieldError(LedTaskFields.startDate)"></date-picker>
      </div>
    </div>
        <div class="section-field single-time-field">
		  <div class="title-div" [attr.translation-id]="'LED_TASKS.TASK_EDITOR.START_TIME'">{{ 'LED_TASKS.TASK_EDITOR.START_TIME' | translate }}</div>
		  <div class="time-picker-wrapper">
			<time-picker [shownRemovableIcon]="false"
						 [themeColor]="'#1999D5'"
						 [backgroundColor]="'#F4F4F4'"
						 [selectedEpoch]="ledTasksService.taskModel.startTime"
						 [shIdPostfix]="'start-time-picker-input'"
						 (epochChanged)="ledTasksService.clearErrors()"
						 [isFieldError]="ledTasksService.isFieldError(LedTaskFields.startTime)"></time-picker>
		  </div>
		</div>
  </div>
  <div class="section-field led-mode-fields">
    <div class="led-mode-wrapper">
      <radio-button [attr.sh-id]="'blink-fast-radio-button'"
                    label="{{ 'DIALOGS.ADHOC.TURN_LED_LIGHTING_ON_DIALOG.BLINK.FAST' | translate }}"
                    [colorScheme]="RadioButtonColorTheme.BlueMarine"
                    [isChecked]="isLedModeChecked(LedMode.Fast)"
                    (change)="onLedModeSelected(LedMode.Fast)"></radio-button>
      <div class="seperator"></div>
      <radio-button [attr.sh-id]="'blink-slow-radio-button'"
                    label="{{ 'DIALOGS.ADHOC.TURN_LED_LIGHTING_ON_DIALOG.BLINK.SLOW' | translate }}"
                    [colorScheme]="RadioButtonColorTheme.BlueMarine"
                    [isChecked]="isLedModeChecked(LedMode.Slow)"
                    (change)="onLedModeSelected(LedMode.Slow)"></radio-button>
    </div>
  </div>

  <div class="section-field duration-slider-fields">
    <div class="slider-title" [attr.translation-id]="'LED_TASKS.TASK_EDITOR.DURATION'">{{ 'LED_TASKS.TASK_EDITOR.DURATION' | translate }}:
      <span class="black-text">{{ ledTasksService.taskModel.selectedDuration | duration:DurationInput.HoursMinutesNoLeadingZeroText }}</span>
    </div>
    <div class="scroll-section">
      <div class="scroll-label">{{ ledTasksService.ledModeSettings.minText }}</div>
      <div class="inner-scroll">
        <duration-horizontal-scroll [currentValue]="ledTasksService.taskModel.selectedDuration"
                                    [interval]="10" 
									[minValue]="ledTasksService.ledModeSettings.minValue"
                                    [maxValue]="ledTasksService.ledModeSettings.maxValue"
                                    [shIdPostFix]="'led-duration'"
                                    (onCurrentValueChange)="onSelectedDurationChanged($event)"></duration-horizontal-scroll>
      </div>
      <div class="scroll-label">{{ ledTasksService.ledModeSettings.maxText }}</div>
    </div>
  </div>
  <div class="repeat-days-field">
    <div class="checkbox-div">
      <image-checkbox [ngClass]="'led-task-checkbox'"
                      [shId]="'repeat-days'"
                      [isChecked]="ledTasksService.taskModel.isAnimalsTaskRepeat"
                      (isCheckedChanged)="toggleRepeatsForDaysCheckbox()"></image-checkbox>
      <div class="checkbox-text">{{ 'SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.REPEATS_FOR_CHECKBOX_CAPTION' | translate }}</div>
    </div>
    <div class="numeric-input-wrapper" *ngIf="ledTasksService.taskModel.isAnimalsTaskRepeat">
      <numeric-input class="led-tasks-repeat-days"
                     [selectedValue]="ledTasksService.taskModel.animalsDaysRepeat"
                     [max]="365"
                     [min]="1"
                     [shId]="'led-task-repeat-days'"
                     (onChangeSelectedValue)="onRepeatDaysChanged($event)"
                     [hideValueChangeArrows]="false"></numeric-input>
    </div>
    <div class="checkbox-text" *ngIf="ledTasksService.taskModel.isAnimalsTaskRepeat">{{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.DAYS' | translate }}</div>
  </div>
</div>
