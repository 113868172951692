import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {LedTasksService} from '../../../../services/led-tasks/led-tasks.service';
import {SearchEntry} from '../../../../services/search/model/search.model';
import {ActivatedRoute} from '@angular/router';
import {SearchService} from '../../../../services/search/search.service';
import {
  DiscoverItems,
  LedMode, LedModeSettings, QuickFilterModel,
  ReportModel,
  ScheduledTaskModel,
  TaskModel
} from '../../../../services/led-tasks/models/led-tasks.interface';
import {DropdownListItem} from '../../../../common/components/dropdown-list/dropdown-list-model';
import {TranslationService} from '../../../../services/translations/translation.service';
import {
  EpochStateModel,
  EpochStateModelMinMaxMode
} from '../../../../common/components/calendar/model/epoch-state-model';
import {Animal, Group} from '../../../../services/model/common-model';
import * as moment from 'moment';
import {ChipsItem} from '../../../../common/components/chips/chips-item.component';

@Component({
  selector: 'led-tasks-editor-container',
  templateUrl: './led-tasks-editor-container.component.html',
  styleUrls: ['./led-tasks-editor-container.component.scss']
})
export class LedTasksEditorContainerComponent implements OnInit {

  public groupsList: string[] = [];

  public animalsList: string[] = [];

  public reportsList: ReportModel[] = [];

  public searchEntities: SearchEntry[] = [];

  public discoverItems = DiscoverItems;

  private discoverItemsList: DropdownListItem[] = [
    { value: DiscoverItems.Animals, displayValue: 'MANAGE.TITLE.ANIMALS', disabled: false, icon: null },
    { value: DiscoverItems.Reports, displayValue: 'NAVIGATION.REPORTS', disabled: false, icon: null }
  ];

  public taskToEdit: number = null;

  constructor(public ledTasksService: LedTasksService,
              private searchService: SearchService,
              private translationService: TranslationService,
              private route: ActivatedRoute) { }

  public async ngOnInit() {
    this.ledTasksService.taskModel = new TaskModel();
    this.ledTasksService.clearErrors();
    await this.getAllGroupsAndAnimalsList();
    this.route.params.subscribe((params) => {
      this.taskToEdit = params && params.id;
    });
    const response = await this.ledTasksService.getReportsList();
    this.reportsList = response.responseBody.reports;
    this.ledTasksService.taskModel.reportsList = this.convertTaskReportListToDropdownList(response.responseBody.reports);
    this.ledTasksService.taskModel.discoversList = this.convertTaskDiscoversListToDropdownList();
    if (this.taskToEdit != null) {
      await this.getTaskModelToEdit();
    } else {
      this.ledTasksService.taskModel.selectedDiscoverItem = this.discoverItemsList[1];
      this.ledTasksService.ledModeSettings = new LedModeSettings();
      this.ledTasksService.taskModel.selectedDuration = 10;
      this.ledTasksService.originalTaskModel = _.cloneDeep(this.ledTasksService.taskModel);
    }
  }

  public convertTaskReportListToDropdownList(reportList: ReportModel[]) {
    let dropdownListToReturn: DropdownListItem[] = [];
    reportList.forEach(((reportItem: ReportModel) => {
      dropdownListToReturn.push({
        value: reportItem.id,
        displayValue: this.translationService.translate('SORTING_GATE.SORTING_REPORT.SORTING_REASONS.' + reportItem.name),
        disabled: false,
        icon: null
      });
    }));
    return dropdownListToReturn;
  }

  public convertTaskDiscoversListToDropdownList(): DropdownListItem[] {
    return this.discoverItemsList;
  }

  public async getAllGroupsAndAnimalsList() {
    this.groupsList = [];
    this.searchEntities = await this.searchService.listOfAvailableGroups();
    this.searchEntities.forEach((group: SearchEntry) => {
      this.groupsList.push(group.entityName);
    });
    this.animalsList = [];
    let availableAnimalsSearchEntities: SearchEntry[] = await this.searchService.listOfAvailableAnimals();
    availableAnimalsSearchEntities.forEach((animal: SearchEntry) => {
      this.animalsList.push(animal.entityName);
    });
    this.searchEntities = this.searchEntities.concat(availableAnimalsSearchEntities);
  }

  public async getTaskModelToEdit() {
    const task = await this.ledTasksService.getScheduledTaskById(this.taskToEdit);
    await this.fillTaskForEdit(task);
  }

  public async fillTaskForEdit(task: ScheduledTaskModel) {
    if (task.type === DiscoverItems.Reports) {
      this.fillReportsFields(task);
    } else {
      this.fillAnimalsFields(task);
    }
  }

  private fillReportsFields(task: ScheduledTaskModel) {
    this.ledTasksService.taskModel.selectedDiscoverItem = this.discoverItemsList[1];
    this.ledTasksService.taskModel.id = task.id;
    this.ledTasksService.taskModel.selectedReportItem = this.ledTasksService.taskModel.reportsList.find((report) => report.value === task.reportId);
    const reportQuickFilter: QuickFilterModel  = this.reportsList.find(report => report.id === task.reportId).quickFilter;
    if (task.isQuickFilter != null && !!reportQuickFilter) {
      this.ledTasksService.taskModel.isQuickFilter = reportQuickFilter;
      this.ledTasksService.taskModel.isQuickFilter.defaultValue = task.isQuickFilter;
    }
    this.ledTasksService.taskModel.startTime = new EpochStateModel(EpochStateModelMinMaxMode.Time);
    this.ledTasksService.taskModel.startTime.epochTime = task.startTime;
    this.ledTasksService.taskModel.selectedDays = task.weekDays;
    let selectedGroupsList: ChipsItem[] = [];
    task.groups.forEach((group: Group) => {
      let index = this.groupsList.findIndex((groupName: string) => groupName === group.name);
      if (index > -1) {
        this.groupsList.splice(index, 1);
      }
      selectedGroupsList.push({
        chipId: group.id,
        chipName: group.name
      });
    });
    this.ledTasksService.taskModel.selectedGroups = selectedGroupsList;
    this.ledTasksService.taskModel.ledMode = <LedMode>task.blinkRate;
    if (this.ledTasksService.taskModel.ledMode === LedMode.Slow) {
      this.ledTasksService.taskModel.selectedDuration = this.ledTasksService.ledModeSettings.changeToSlowModeSettings(task.duration);
    } else {
      this.ledTasksService.taskModel.selectedDuration = this.ledTasksService.ledModeSettings.changeToFastModeSettings(task.duration);
    }
    this.ledTasksService.originalTaskModel = _.cloneDeep(this.ledTasksService.taskModel);
  }

  private fillAnimalsFields(task: ScheduledTaskModel) {
    this.ledTasksService.taskModel.selectedDiscoverItem = this.discoverItemsList[0];
    this.ledTasksService.taskModel.id = task.id;
    this.ledTasksService.taskModel.selectedTaskName = task.name;
    let selectedAnimalsList: ChipsItem[] = [];
    task.animals.forEach((animal: Animal) => {
      let index = this.animalsList.findIndex((animalName: string) => animalName === animal.animalName);
      if (index > -1) {
        this.animalsList.splice(index, 1);
      }
      selectedAnimalsList.push({
        chipId: animal.id,
        chipName: animal.animalName
      });
    });
    this.ledTasksService.taskModel.selectedAnimals = selectedAnimalsList;
    this.ledTasksService.taskModel.startDate = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.ledTasksService.taskModel.startDate.epochDate = task.startDate;
    this.ledTasksService.taskModel.startDate.min = moment().utc().unix();
    this.ledTasksService.taskModel.startTime = new EpochStateModel(EpochStateModelMinMaxMode.Time);
    this.ledTasksService.taskModel.startTime.epochTime = task.startTime;
    if (task.repeatDays) {
      this.ledTasksService.taskModel.isAnimalsTaskRepeat = true;
      this.ledTasksService.taskModel.animalsDaysRepeat = task.repeatDays;
    } else {
      this.ledTasksService.taskModel.isAnimalsTaskRepeat = false;
    }
    this.ledTasksService.taskModel.ledMode = <LedMode>task.blinkRate;
    if (this.ledTasksService.taskModel.ledMode === LedMode.Slow) {
      this.ledTasksService.taskModel.selectedDuration = this.ledTasksService.ledModeSettings.changeToSlowModeSettings(task.duration);
    } else {
      this.ledTasksService.taskModel.selectedDuration = this.ledTasksService.ledModeSettings.changeToFastModeSettings(task.duration);
    }
    this.ledTasksService.originalTaskModel = _.cloneDeep(this.ledTasksService.taskModel);
  }
}
