<div outsideClickHandler *ngIf="isImportAnimalsFromFileModuleShown" (onOutsideClick)="isOpen = false">
  <div class="import-from-csv-header" [attr.sh-id]="'import_csv_download_icon'" (click)="toggleImportFromCsvDialog()">
    <div class="download-animation" *ngIf="importFromCsvService.isImportRunning"></div>
    <div class="download-icon" *ngIf="!importFromCsvService.isImportRunning"></div>
    <div class="shadow" *ngIf="!isOpen"></div>
  </div>
  <div class="import-from-csv-popup" *ngIf="isOpen" @import-from-csv-popup-transition>
    <div class="import-from-csv-popup-header">
      <div class="import-from-csv-header-text">{{ 'HEADER.IMPORT_FROM_CSV.TITLE' | translate }}</div>
      <div class="btn-close" (click)="isOpen = false">
        <div class="icon-close"></div>
      </div>
      <div class="shadow"></div>
    </div>
    <div class="import-from-csv-popup-content">
      <div class="csv-status-content">
        <div class="import-status-bar-div">
          <div class="icon-cow"></div>
          <div class="status-bar-div">
            <div class="status-bar">
              <div class="status-bar-progress" [ngStyle]="{'width' : calculateProgressWidth()}"></div>
            </div>
          </div>
          <div class="progress-text">
            <div class="purpleColor" [attr.sh-id]="'progress_text_imports_completed'">{{ importFromCsvService.ImportAnimalsFromCsvStatusModel.successfullyImported + importFromCsvService.ImportAnimalsFromCsvStatusModel.duplicatedRecords + importFromCsvService.ImportAnimalsFromCsvStatusModel.recordsWithErrors
            || 0 }}&nbsp;</div><span [attr.sh-id]="'progress_text_imports_total'">/ {{ importFromCsvService.ImportAnimalsFromCsvStatusModel.totalRecords || 0 }}</span></div>
        </div>
        <div class="import-info-div">
          <div class="info-text-div">{{ 'HEADER.IMPORT_FROM_CSV.SUCCESSFULLY_IMPORTED' | translate }}:&nbsp;
            <div class="purpleColor"  [attr.sh-id]="'number_of_successful_imports'">{{ importFromCsvService.ImportAnimalsFromCsvStatusModel.successfullyImported || 0 }}</div>
          </div>
          <div class="info-text-div">{{ 'HEADER.IMPORT_FROM_CSV.DUPLICATED_RECORDS' | translate }}:&nbsp;
            <div class="redColor" [attr.sh-id]="'number_of_duplicated_imports'">{{ importFromCsvService.ImportAnimalsFromCsvStatusModel.duplicatedRecords || 0 }}</div>
          </div>
          <div class="info-text-div">{{ 'HEADER.IMPORT_FROM_CSV.RECORDS_WITH_ERRORS' | translate }}:&nbsp;
            <div class="redColor" [attr.sh-id]="'number_of_imports_with_errors'">{{ importFromCsvService.ImportAnimalsFromCsvStatusModel.recordsWithErrors || 0 }}</div>
          </div>
        </div>
      </div>
      <div class="button-cancel-import" (click)="doneImportFromCSV()" *ngIf="importFromCsvService.ImportAnimalsFromCsvStatusModel.state === ImportFromCsvState.Running">
        <div class="cancel-import-text">{{ 'HEADER.IMPORT_FROM_CSV.STOP_IMPORT' | translate }}</div>
        <div class="popup-frame-corner"></div>
      </div>
      <div class="finish-and-export-errors-div" *ngIf="!noRejectedRecords && importFromCsvService.ImportAnimalsFromCsvStatusModel.state === ImportFromCsvState.Done">
        <div class="export-errors-button" (click)="downloadImportErrors()">
          <div class="export-errors-text">{{ 'HEADER.IMPORT_FROM_CSV.EXPORT_REJECTED_RECORDS' | translate }}</div>
          <div class="popup-frame-corner"></div>
        </div>
        <div class="empty-border">
          <div class="popup-frame-corner"></div>
        </div>
        <div class="finish-button" (click)="doneImportFromCSV()">
          <div class="done-text" [attr.sh-id]="'import_done_button'">{{ 'HEADER.IMPORT_FROM_CSV.DONE' | translate }}</div>
          <div class="popup-frame-corner"></div>
        </div>
      </div>
      <div class="finish-and-export-errors-div-no-rejected-records" *ngIf="noRejectedRecords && importFromCsvService.ImportAnimalsFromCsvStatusModel.state === ImportFromCsvState.Done">
        <div class="finish-button" (click)="doneImportFromCSV()">
          <div class="done-text">{{ 'HEADER.IMPORT_FROM_CSV.DONE' | translate }}</div>
          <div class="popup-frame-corner"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<notification-dialog-template #uploadErrorDialogRef
                              [isEscapeCloseDialog]="true"
                              [theme]="NotificationDialogTheme.red"
                              [modalHeight]="250"
                              [modalWidth]="512">
  <ng-container header>
    <div class="alert-icon"></div>
    <div [attr.translation-id]="'SYSTEM.MANAGEMENT.GRID.ERROR_IMPORTING_ANIMALS_FROM_FILE'">{{ 'SYSTEM.MANAGEMENT.GRID.ERROR_IMPORTING_ANIMALS_FROM_FILE' | translate }}</div>
  </ng-container>
  <ng-container body>
    <span>{{uploadError}}</span>
  </ng-container>
</notification-dialog-template>
<input #importFromCsvFileInput class="hidden-input" type="file" accept=".csv" (change)="onFileChange($event)">
