<group-card-animals-summary *ngIf="groupGraphDetails" [groupGraphDetails]="groupGraphDetails"></group-card-animals-summary>
<div class="heat-chart-header">
  <xy-chart-legend #groupHeatChartLegend
                   [configuration]="legendConfiguration"
                   [legendStates]="legendStates"></xy-chart-legend>
  <chart-series-dropdown-list [seriesSelections]="seriesSelectionStates"
                              [translationPrefix]="legendConfiguration.translationPrefix"
                              (selectionApplied)="onSelectionApplied($event)"
                              (restoreToDefaults)="onRestoreToDefaults()"></chart-series-dropdown-list>
</div>
<xy-chart #groupHeatChart
          [dateTimeFormat]="dateTimeFormat"
          [timeIsUtc]="true"
          (chartPointMouseOver)="onChartPointMouseOver($event)"
          (durationSelected)="onDurationSelected($event)"></xy-chart>
