import {DataRow} from '../../model/common-model';

export enum ReportDisplayType  {
  full = 'full',
  long = 'long',
  short = 'short',
  Extended = 'Extended'
}

export enum ReportColumnType {
  Integer = 'Integer',
  String = 'String',
  Boolean = 'Boolean',
  DateTime = 'DateTime',
  HiddenRef = 'HiddenRef',
  Hidden = 'Hidden',
  AnimalsToInspectReasons = 'AnimalsToInspectReasons',
  SortingReason = 'SortingReason',
  Localization = 'Localization',
  DynamicLocalization = 'DynamicLocalization',
  Long = 'Long',
  Double = 'Double'
}

export enum ReportColoringType {
  PregnancyProbabilityMedium = 'PregnancyProbabilityMedium',
  PregnancyProbabilityHigh = 'PregnancyProbabilityHigh',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Disabled = 'Disabled'
}

export enum ReportFilterType {
  setup = 'setup',
  boolean = 'boolean'
}

export enum ReportColumnFilterType {
  None = 'None',
  Search = 'Search',
  CheckList = 'CheckList',
  Numeric = 'Numeric',
  DateTime = 'DateTime',
  Boolean = 'Boolean'
}

export enum ReportColumnSortType {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum ReportNameEnum {
  TagMaintenanceCalls = 'TagMaintenanceCalls',
  GroupRoutine = 'GroupRoutine',
  GroupRoutineHeatStress = 'GroupRoutineHeatStress',
  YoungStockHealth = 'YoungStockHealth',
  Health = 'Health',
  AnimalsInHeat = 'AnimalsInHeat',
  AnimalsToInspect = 'AnimalsToInspect',
  FinishingGroupBranchList = 'FinishingGroupBranchList',
  HerdManagmentSyncSummary = 'HerdManagmentSyncSummary',
  LedTasksReport = 'LedTasksReport'
}

export enum ReportActionType {
  Export = 'Export',
  Print = 'Print',
  PregnancyCheck = 'PregnancyCheck',
  ShowPastReport = 'ShowPastReport',
  AddAnimalToList = 'AddAnimalToList',
  Import = 'Import',
  Cull = 'Cull',
  DoNotBreedOn = 'DoNotBreedOn',
  TurnLedLightingOn = 'TurnLedLightingOn'
}

export enum ReportBatchActionType {
  Cull = 'Cull',
  Breed = 'Breed',
  Abortion = 'Abortion',
  DoNotBreedOn = 'DoNotBreedOn',
  PregnancyCheck = 'PregnancyCheck',
  Export = 'Export',
  Print = 'Print',
  TurnLedLightingOn = 'TurnLedLightingOn'
}

export enum ReportRowActionType {
  Cull = 'Cull',
  RemoveCulling = 'RemoveCulling',
  AssignScrTag = 'AssignScrTag',
  Edit = 'Edit',
  Remove = 'Remove',
  Disable = 'Disable',
  Enable = 'Enable',
  Breed = 'Breed',
  DoNotBreedOn = 'DoNotBreedOn',
  Abortion = 'Abortion',
  PregnancyCheck = 'PregnancyCheck',
  SendToSortingGate = 'SendToSortingGate',
  ChangeGroup = 'ChangeGroup'
}

export enum IconCssClass {
  disable = 'disable',
  enable = 'enable',
  assignScrTag = 'assign-scr-tag',
  reportDnb = 'report-dnb',
  abortion = 'abortion',
  pregnancyCheck = 'pregnancy-check',
  reportBreeding = 'report-breeding',
  schedulerSorting = 'scheduler-sorting',
  changeGroup = 'change-group',
  culling = 'culling',
  removeCulling = 'remove-culling',
  remove = 'remove',
  edit = 'edit',
  suspend = 'suspend',
  unsuspend = 'unsuspend',
  default = ''
}

export function getIconCssClassByReportRowActionType(reportRowActionType: ReportRowActionType): IconCssClass {
  switch (reportRowActionType) {
    case ReportRowActionType.Disable:
      return IconCssClass.disable;
    case ReportRowActionType.Enable:
      return IconCssClass.enable;
    case ReportRowActionType.ChangeGroup:
      return IconCssClass.changeGroup;
    case ReportRowActionType.AssignScrTag:
      return IconCssClass.assignScrTag;
    case ReportRowActionType.DoNotBreedOn:
      return IconCssClass.reportDnb;
    case ReportRowActionType.Abortion:
      return IconCssClass.abortion;
    case ReportRowActionType.PregnancyCheck:
      return IconCssClass.pregnancyCheck;
    case ReportRowActionType.Breed:
      return IconCssClass.reportBreeding;
    case ReportRowActionType.SendToSortingGate:
      return IconCssClass.schedulerSorting;
    case ReportRowActionType.Cull:
      return IconCssClass.culling;
    case ReportRowActionType.RemoveCulling:
      return IconCssClass.removeCulling;
    case ReportRowActionType.Remove:
      return IconCssClass.remove;
    case ReportRowActionType.Edit:
      return IconCssClass.edit;
    default:
      return IconCssClass.default;
  }
};

export class ReportDataRow extends DataRow {
  public rowId: number;
  public rowNumber: number;
  public rowColor: string;
  public rowColumnColorings:  Map<string,  ReportColoringType>;
  public navigation: ReportRowNavigationScreen;
}

export interface IReportColumnSort {
  type: ReportColumnSortType;
  order: number;
}

export interface IReportFilterMetadata {
  filterType: ReportFilterType;
  filterColumnName: string;
  filterLocalization: string;
  filterValue: string;
}

export interface IReportAction {
  type: ReportActionType;
}

export interface IReportBatchAction {
  type: ReportBatchActionType;
}

export interface IReportMetaFilterValueColumn {
  calculationName: string;
  values: IReportMetaFilterValueItem[];
  isLocalization: boolean;
}

export interface IReportMetaFilterValueItem {
  // tslint:disable-next-line:no-any
  key: any;
  value:string;
}

export enum ReportKeyType {
  animal = 'animal',
  group = 'group',
  branch = 'branch',
  tag = 'tag'
}

export enum ReportMetaPackage {
  Starter = 'Starter',
  Advanced = 'Advanced',
  Premium = 'Premium',
  YoungStock = 'YoungStock',
  Finishing = 'Finishing'
}

export interface IReportMetadata {
  reportKeyType: ReportKeyType;
  reportId?: number;
  shiftId?: number;
  reportTime: number;
  reportUid?: number;
  reportName: ReportNameEnum;
  // tslint:disable-next-line:no-any
  lastUpdated: any;
  rowCount: number;
  rowTotal: number;
  rowTotalAfterFilter: number;
  runningTime: number;
  filter: IReportFilterMetadata;
  filterValues: IReportMetaFilterValueColumn[];
  // tslint:disable-next-line:no-any
  setupModel?: any;
  // tslint:disable-next-line:no-any
  navigation: ReportRowNavigationScreen;
  specialAction?: string;
  reportActions?: IReportAction[];
  reportBatchActions?: IReportBatchAction[];
  additionalData?: IReportMetadataAdditionalData;
  package?: ReportMetaPackage;
}

export interface IReportMetadataAdditionalData {
  animalsInFarm?: number;
}

export interface IReportColumnDefinition {
  key: string;
  name: string;
  type: ReportColumnType;
  filterType: ReportColumnFilterType;
  sorting: IReportColumnSort;
  webMetaType?: string;
  mobileMetaType?: string;
  isTranslate: string;
  width: number;
  isBold?: boolean;
  displayType: ReportDisplayType;
  isSortingEnable: boolean;
  isFilterEnable: boolean;
}

export interface IReportState {
  page?: number;
  loadedRows?: number;
  firstLoad?: boolean;
  // tslint:disable-next-line:no-any
  timeSinceUpdate?: any;
  isReportOutOfDate?: boolean;
  timeSincePastReport?: number;
}

export interface IReportColoring {
  columnKey: string;
  rowNumber: number;
  colorType: ReportColoringType;
}

export interface IReportRowAction {
  type: ReportRowActionType;
  additionalInfo?: IReportRowActionAdditionalInfo;
}

export interface IReportRowActionAdditionalInfo {
  eventId: number;
  eventStartDateTime: number;
}

export interface IReportRowActions {
  rowNumber: number;
  actions: IReportRowAction[];
}

export interface IReport {
  rowColoring?: IReportRowColoring[];
  meta: IReportMetadata;
  rowActions?: IReportRowActions[];
  columns: IReportColumnDefinition[];
  rows: ReportDataRow[];
  coloring: IReportColoring[];
  state?: IReportState;
  navigation: IReportRowNavigation[];
  // tslint:disable-next-line:no-any
  hiddenFieldValues?: any;
}

export interface IReportRowNavigation {
  rowNumber: number;
  navigation: ReportRowNavigationScreen;
}

export enum ReportRowNavigationScreen {
  None = 'None',
  CowCardHealthGraph = 'CowCardHealthGraph',
  CowCardHeatGraph = 'CowCardHeatGraph',
  CowCardEvents = 'CowCardEvents',
  SpecialField = 'SpecialField',
  GroupRoutineHeatStressGraph = 'GroupRoutineHeatStressGraph',
  GroupRoutineRuminationGraph = 'GroupRoutineRuminationGraph',
  GroupRoutineActivityGraph = 'GroupRoutineActivityGraph',
}

export interface IReportRowColoring {
  rowNumber: number;
  colorType: string;
}

export interface IReportIds {
  limit: number;
  offset: number;
  // tslint:disable-next-line:no-any
  result: any[];
  total: number;
}

export interface IReportsMenuItems {
  reports: IReportMenuItem[];
}

export interface IReportMenuItem {
  key: number;
  name: string;
  category: string;
  isFavorite: boolean;
  rowCount: number;
  categoryOrder: number;
}

export enum ReportRequestType {
  isFavorite = 'isFavorite',
}

export enum BlinkRate {
  Slow = 'Slow',
  Fast = 'Fast'
}

export interface IReportRequest {
  key: number;
  name: string;
  category: string;
  isFavorite: boolean;
  requestType: ReportRequestType;
}

export interface IAnimalsToInspectReport {
  anestrusCows: ISelected;
  animalDistress: ISelected;
  earlyFreshCows: ISelected;
  health: ISelected;
  irregularHeats: ISelected;
  suspectedForAbortion: ISelected;
  youngStockHealth: ISelected;
}

export interface ISelected {
  isSelected: boolean;
}

export interface IAdhocTaskModel {
  reportId: number;
  blinkRate: BlinkRate;
  duration: number;
  animalIds: number[];
}

export enum SyncStatusStatusCalculation {
  Processing = 'Processing',
  CullingThresholdError = 'CullingThresholdError',
  TagRemovalThresholdError = 'TagRemovalThresholdError',
  TimeoutError = 'TimeoutError',
  InterruptedError = 'InterruptedError',
  LimitsExceedError = 'LimitsExceedError',
  MultipleFullSyncError = 'MultipleFullSyncError',
  AllAnimalsInSyncFailed = 'AllAnimalsInSyncFailed',
  NoAnimalsInSyncError = 'NoAnimalsInSyncError',
  MissingExternalIdsError = 'MissingExternalIdsError',
  ServerStillStartingError = 'ServerStillStartingError',
  PartialSuccess = 'PartialSucess',
  SucceededWithWarnings = 'SucceededWithWarnings'
}

export enum SyncStatusDatetimeColumnName {
  SyncStatusReceiveTimeCalculation = 'SyncStatusReceiveTimeCalculation',
  SyncStatusProcessStartTimeCalculation = 'SyncStatusProcessStartTimeCalculation',
  SyncStatusStatusCalculation = 'SyncStatusStatusCalculation'
}

