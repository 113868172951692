<description-cell-template [canExpand]="canExpand"
                           [isRowExpanded]="isExpanded"
                           [row]="row"
                           [rowShId]="'row-effective-groups-' + rowShId"
                           (toggleExpanded)="toggleExpanded()">
  <div #entities class="entities" [ngClass]="{'expanded': isExpanded}" [ngStyle]="{'height': isExpanded ? 'auto' : (row.rowDefaultHeight +'px'),
                                                                                   'min-height' : row.rowDefaultHeight + 'px'}">
    <ng-container *ngIf="hasEntities">
      <cell-entity *ngFor="let entity of entitiesToDisplay;index as i"
                   [entityText]="entity.entityName"
                   [lineHeight]="row.rowDefaultHeight"
                   [maxWidth]="entitiesContainerWidth"
                   [isFirstElement]="i == 0"
                   [isLastElement]="isLastEntity(entity)"></cell-entity>
    </ng-container>
    <div *ngIf="!hasEntities" class="no-entities" [ngStyle]="{'height' : row.rowDefaultHeight + 'px'}">{{noEntitiesText | translate}}</div>
  </div>
</description-cell-template>
