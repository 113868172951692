import {Injectable} from '@angular/core';
import {DateTimeFormatEnum, EpochDateTimePipe} from '../../common/pipes/epoch-date-time.pipe';
import {IAxisLabelRenderEventArgs} from '@syncfusion/ej2-angular-charts';
import * as moment from 'moment';
import {EdgeLabelPlacement} from '@syncfusion/ej2-charts/src/chart/utils/enum';

export enum GridLineIntervalEnum {
  Hour = 'Hour',
  ThreeHours = 'ThreeHours',
  Day = 'Day',
  TwoDays = 'TwoDays',
  ThreeDays = 'ThreeDays',
  FourDays = 'FourDays',
  Week = 'Week',
  NineDays = 'NineDays',
  Month = 'Month'
}

export interface IChartConfiguration {
  visiblePeriodHours: number;
  scrollStepHours: number;
  gridLineInterval: GridLineIntervalEnum;
  primaryYAxisMaxValue: number;
  secondaryYAxisMaxValue: number;
  primaryXAxisExtendSeconds?: number;
}

export class ChartConfiguration implements IChartConfiguration{
  private _visiblePeriodHours: number;
  public scrollStepHours: number;
  public gridLineInterval: GridLineIntervalEnum;
  public primaryYAxisMaxValue: number;
  public secondaryYAxisMaxValue: number;
  public primaryXAxisExtendSeconds?: number;

  private readonly hourInSeconds: number = 3600;

  constructor(params: IChartConfiguration) {
    Object.assign(this, params);
  }

  public get visiblePeriodHours(): number {
    return this._visiblePeriodHours + (this.primaryXAxisExtendHours ?? 0);
  }

  public get visiblePeriodSeconds(): number {
    return this._visiblePeriodHours * this.hourInSeconds + (this.primaryXAxisExtendSeconds ?? 0);
  }

  public set visiblePeriodHours(value: number) {
    this._visiblePeriodHours = value;
  }

  private get primaryXAxisExtendHours(): number {
    return this.primaryXAxisExtendSeconds
      ? this.primaryXAxisExtendSeconds / this.hourInSeconds
      : null;
  }

  public get edgeLabelPlacement(): EdgeLabelPlacement {
    return this.primaryXAxisExtendSeconds ? 'Shift' : 'None';
  }
}

@Injectable({
  providedIn: 'root'
})
export class XyChartCalculationService {

  constructor(private epochDateTimePipe: EpochDateTimePipe) { }

  public convertGridLineIntervalToEstimatedTime(interval: GridLineIntervalEnum) : number {
    let hourInSeconds = 3600;
    switch (interval) {
      case GridLineIntervalEnum.Hour:
      case GridLineIntervalEnum.ThreeHours:
        return hourInSeconds;
      case GridLineIntervalEnum.Day:
        return hourInSeconds * 24;
      case GridLineIntervalEnum.TwoDays:
        return hourInSeconds * 24 * 2;
      case GridLineIntervalEnum.ThreeDays:
        return hourInSeconds * 24 * 3;
      case GridLineIntervalEnum.FourDays:
        return hourInSeconds * 24 * 4;
      case GridLineIntervalEnum.Week:
        return hourInSeconds * 24 * 7;
      case GridLineIntervalEnum.NineDays:
        return hourInSeconds * 24 * 9;
      case GridLineIntervalEnum.Month:
        return 30 * 24 * 3600;
    }
  }

  public renderPrimaryXAxis(args:IAxisLabelRenderEventArgs, interval:GridLineIntervalEnum, asUtc:boolean) : void {
    let date =  moment.unix(args.value).toDate();
    if(interval == GridLineIntervalEnum.ThreeHours) {
      let dateHours = date.getHours();
      if(dateHours % 3 != 0) {
        args.cancel = true;
        return;
      }
    }
    if(interval == GridLineIntervalEnum.Month) {
      let startOfMonthDate = new Date(date.getTime() -
                                             date.getHours() * 3600 * 1000 -
                                            (date.getDate() - 1) * 24 * 3600 * 1000);
      startOfMonthDate = moment(startOfMonthDate).add(1, 'month').toDate();
      args.value = startOfMonthDate.getTime() / 1000;
      args.text = this.epochDateTimePipe.transform(args.value, DateTimeFormatEnum.MonthAbbreviated, asUtc);
    } else if((interval == GridLineIntervalEnum.Hour
              || interval == GridLineIntervalEnum.ThreeHours)
              && moment.unix(args.value).utc().hours() != 0) {
      args.text = this.epochDateTimePipe.transform(args.value, DateTimeFormatEnum.Time, asUtc);
    } else {
      args.text = this.epochDateTimePipe.transform(args.value, [DateTimeFormatEnum.MonthAbbreviated, DateTimeFormatEnum.DayOfMonth], asUtc);
    }
  }
}
