<div class="notes-header" [attr.sh-id]="'manage-events-header'">
  <note-editor nonExternalUser
               [editorMode]="editDialogMode"
               [editNoteModel]="editedNote"
               (onAddNoteClicked)="onAddNoteClicked($event)"
               (onNoteListChanged)="onNotesListChanged()"></note-editor>
  <grid-summary *ngIf="notesResult && !editDialogMode" [summaryTitle]="'MANAGE.TITLE.NOTES'"
                [icon]="GridSummaryIcon.Notes"
                [displayMode]="displayMode"
                [rowTotal]="notesResult.total"></grid-summary>
</div>

<data-grid #notesGrid  *ngIf="notesResult"
           [attr.sh-id]='"notesColumn"'
           [sortedGridColumn]="notesColumn"
           [sortedGridCell]="notesCell"
           [headerHeight]="40"
           [rowHeight]="40"
           [sortedGridQuery]="notesGridQuery"
           [totalRows]="notesResult.total"
           [columnsMappings]="columnsMappings"
           [rowsData]="notesResult.result"
           [viewContext]="this"
           (onSortedGridQueryChanged)="getNotes(true)">
</data-grid>

<ng-template #notesColumn let-column="column">
  <ng-container [ngSwitch]="column">
    <span *ngSwitchCase="'startDateTime'" [attr.translation-id]="'NOTES.COLUMN.DATE'">{{'NOTES.COLUMN.DATE' | translate}}</span>
    <span *ngSwitchCase="'name'" [attr.translation-id]="'NOTES.COLUMN.NAME'">{{'NOTES.COLUMN.NAME' | translate}}</span>
    <span *ngSwitchCase="'groups'" [attr.translation-id]="'NOTES.COLUMN.GROUPS'">{{'NOTES.COLUMN.GROUPS' | translate}}</span>
  </ng-container>
</ng-template>

<ng-template #notesCell let-column="column" let-row="row">
  <ng-container [ngSwitch]="column">
    <div *ngSwitchCase="'startDateTime'" class="date-cell">
      <span>{{row.startDateTime | epochDateTime:DateTimeFormatEnum.DateTime:true}}</span>
    </div>
    <div *ngSwitchCase="'name'" class="name-cell">
      <ellipsisable-text>{{row.name }}</ellipsisable-text>
    </div>
    <description-cell-entities *ngSwitchCase="'groups'"
                               [noEntitiesText]="'LED_TASKS.GRID.ALL_GROUPS'"
                               [row]="row"
                               [entities]="getGroupDescriptionEntities(row)"
                               (onToggleExpanded)="toggleExpandRow()"></description-cell-entities>
    <div *ngSwitchCase="'actions'" class="actions-cell" saveDataWarningInject>
      <row-actions-popup [rowActions]="rowActionsMap.get(row)"
                         [shId]="row.id"
                         (onPopupOpen)="onRowActionsOpen()"
                         (onRowActionClick)="handleRowAction($event.rowActionPopupItem.action, row)">
      </row-actions-popup>
    </div>
  </ng-container>
</ng-template>
<confirm-delete-dialog [isOpen]="isDeleteDialogShown"
                       [headerText]="'NOTES.DELETE_DIALOG.HEADER'"
                       [primaryText]="'NOTES.DELETE_DIALOG.CONTENT'"
                       (onDelete)="deleteNote()"
                       (onCancel)="closeDeleteConfirmationDialog()">
</confirm-delete-dialog>
