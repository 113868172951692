import {Component, Input, OnInit} from '@angular/core';
import {OperationType} from '../../../../services/search/model/search.model';
import {IGroupGraphDetails} from '../../../../services/groups/model/group.interface';
import {GroupCardViewState} from '../group-card-model';
import {GroupsService} from '../../../../services/groups/groups.service';

@Component({
  selector: 'group-card-trends-heat',
  templateUrl: './group-card-trends-heat.component.html',
  styleUrls: ['./group-card-trends-heat.component.scss']
})
export class GroupCardTrendsHeatComponent implements OnInit {

  @Input()
  public groupId: number;

  @Input()
  public groupGraphDetails: IGroupGraphDetails;

  @Input()
  public viewState: GroupCardViewState;

  @Input()
  public operationType: OperationType;

  constructor(public groupsService: GroupsService) {
  }

  public ngOnInit(): void {
  }

  public get isFinishing(): boolean {
    return this.operationType === OperationType.BeefFinishing;
  }
}
