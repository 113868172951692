<div class="group-tabs" *ngIf="groupInfo">
  <ul class="group-card-nav">
    <li class="tab" [ngClass]="{'active': viewState.selectedTab === GroupCardTabSection.Details}" [attr.sh-id]="'group-card-tab-' + GroupCardTabSection.Details">
      <a (click)="selectTab(GroupCardTabSection.Details)">{{'GROUP.SIDE_NAVIGATION.DETAILS'| translate}}</a>
    </li>
    <li class="tab sub-menu" *ngIf="isRoutineGraphsMenuEnabled">
      <a>{{'GROUP.SIDE_NAVIGATION.ROUTINE_GRAPHS'| translate}}</a>
      <ul class="sub-menu">
        <!--(*ngIf='$ctrl.showGraphs')-->
        <li class="tab" [ngClass]="{'active': viewState.selectedTab === GroupCardTabSection.GraphsActivity || viewState.selectedTab === GroupCardTabSection.GraphsActivityRumination}" [attr.sh-id]="'group-card-tab-' + GroupCardTabSection.GraphsActivity">
          <a (click)="selectTab(GroupCardTabSection.GraphsActivity)">{{'GROUP.SIDE_NAVIGATION.ACTIVITY_RUM'| translate}}</a>
        </li>
        <li class="tab" [ngClass]="{'active': viewState.selectedTab === GroupCardTabSection.GraphsHeat}" [attr.sh-id]="'group-card-tab-' + GroupCardTabSection.GraphsHeat">
          <a (click)="selectTab(GroupCardTabSection.GraphsHeat)">{{'GROUP.SIDE_NAVIGATION.HEAT_STRESS'| translate}}</a>
        </li>
      </ul>
    </li>
    <li class="tab sub-menu" *ngIf="isTrendGraphsMenuEnabled">
      <a [attr.translation-id]="'GROUP.SIDE_NAVIGATION.TREND_GRAPHS'">{{'GROUP.SIDE_NAVIGATION.TREND_GRAPHS'| translate}}</a>
      <ul class="sub-menu">
        <!--(*ngIf='$ctrl.showTrends')-->
        <li class="tab" *ngIf="operationType !== operationTypes.BeefFinishing" [ngClass]="{'active': viewState.selectedTab === GroupCardTabSection.TrendsConsistency}" [attr.sh-id]="'group-card-tab-' + GroupCardTabSection.TrendsConsistency">
          <a (click)="selectTab(GroupCardTabSection.TrendsConsistency)">{{'GROUP.SIDE_NAVIGATION.CONSISTENCY'| translate}}</a>
        </li>
        <li class="tab" [ngClass]="{'active': viewState.selectedTab === GroupCardTabSection.TrendsHeat}" [attr.sh-id]="'group-card-tab-' + GroupCardTabSection.TrendsHeat">
          <a (click)="selectTab(GroupCardTabSection.TrendsHeat)">{{'GROUP.SIDE_NAVIGATION.HEAT_STRESS'| translate}}</a>
        </li>
      </ul>
    </li>
  </ul>
  <div class="group-tab-content">
    <group-card-details *ngIf="viewState.selectedTab === GroupCardTabSection.Details"
                        [operationType]="operationType"
                        [groupDetails]="viewState.groupDetails"></group-card-details>
    <group-card-graphs-activity-rumination *ngIf="viewState.selectedTab === GroupCardTabSection.GraphsActivity || viewState.selectedTab === GroupCardTabSection.GraphsActivityRumination"
                                           [groupId]="viewState.groupDetails.id"
                                           [viewState]="viewState"
                                           [groupGraphDetails]="groupGraphDetails"></group-card-graphs-activity-rumination>
    <group-card-graphs-heat *ngIf="viewState.selectedTab === GroupCardTabSection.GraphsHeat"
                            [viewState]="viewState"
                            [groupId]="viewState.groupDetails.id"
                            [groupGraphDetails]="groupGraphDetails"></group-card-graphs-heat>
    <group-card-trends-consistency *ngIf="viewState.selectedTab === GroupCardTabSection.TrendsConsistency"
                                   [operationType]="operationType"
                                   [viewState]="viewState"
                                   [groupId]="viewState.groupDetails.id"
                                   [groupGraphDetails]="groupGraphDetails"></group-card-trends-consistency>
    <group-card-trends-heat *ngIf="viewState.selectedTab === GroupCardTabSection.TrendsHeat"
                            [operationType]="operationType"
                            [viewState]="viewState"
                            [groupId]="viewState.groupDetails.id"
                            [groupGraphDetails]="groupGraphDetails"></group-card-trends-heat>
  </div>
</div>
