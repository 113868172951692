import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges, TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ColumnMode, DatatableComponent, TableColumn} from '@swimlane/ngx-datatable';
import {ScreenCalculationUtils} from '../../../utils/screen-calculation-utils';
import {DataGridQuery, DataGridQuerySortDetails} from './model/data-grid-query';
import {DataGridColumnMap} from './model/data-grid-column-map';
import {IDataGridViewContext, DataRow} from '../../../services/model/common-model';
import {debounce} from 'lodash';

@Component({
  selector: 'data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataGridComponent implements OnChanges {

  @ViewChild('datatable', {static: true})
  public datatable: DatatableComponent;

  @ViewChild('columnTemplate', {static: true})
  // tslint:disable-next-line:no-any
  public columnTemplate: TemplateRef<any>;

  @ViewChild('cellTemplate', {static: true})
  // tslint:disable-next-line:no-any
  public cellTemplate: TemplateRef<any>;

  @ViewChild('moreDetailsRow', {static: true})
  // tslint:disable-next-line:no-any
  public moreDetailsRow: ElementRef<any>;

  @ViewChild('ngxDatatableContainer', {static: true})
  public ngxDatatableContainer: ElementRef;

  @Input()
  public rowHeight: number;

  @Input()
  public headerHeight: number;

  @Input()
  public totalRows: number;

  @Input()
  public sortedGridQuery: DataGridQuery;

  @Input()
  public columnsMappings: Map<string, DataGridColumnMap>;

  @Input()
  public rowsData: DataRow[];

  @Input()
  // tslint:disable-next-line:no-any
  public sortedGridColumn: TemplateRef<any>;

  @Input()
  // tslint:disable-next-line:no-any
  public sortedGridCell: TemplateRef<any>;

  @Input()
  public isRowClickable: boolean = true;

  @Input()
  public viewContext: IDataGridViewContext;

  @Input()
  public hideHeaderBottomBorder: boolean = false;

  @Input()
  public isLoaderShifted: boolean = false;

  @Input()
  public set isLoadingGridData(isLoadingGridData) {
    this.pisLoadingGridData = isLoadingGridData;
  };

  @Output()
  public onSortedGridQueryChanged = new EventEmitter();

  @Output()
  public onRowClick: EventEmitter<DataRow> = new EventEmitter();

  public initGrid: boolean = true;

  public isLoading: boolean;

  public rows : DataRow[] = [];

  public columns: TableColumn[] = [];

  public get loaderIsLoading(): boolean {
    return this.pisLoadingGridData || this.isLoading;
  }

  private pisLoadingGridData: boolean = false;

  private _datatableContainerWidthPixels: number;

  private _datatableContainerHeightPixels: number;

  public ColumnMode = ColumnMode;

  constructor(public elementRef: ElementRef,
              private readonly changeDetector: ChangeDetectorRef) {

  }

  public get datatableContainerHeightPixels() : number {
    let currentHeight = (<HTMLElement>this.ngxDatatableContainer.nativeElement).getBoundingClientRect().height;
    if(currentHeight != this._datatableContainerHeightPixels){
      this._datatableContainerHeightPixels = currentHeight;
      this.changeDetector.detectChanges();
    }
    return this._datatableContainerHeightPixels;
  }

  public get datatableContainerWidthPixels() : number {
    let currentWidth = (<HTMLElement>this.ngxDatatableContainer.nativeElement).getBoundingClientRect().width;
    if(currentWidth != this._datatableContainerWidthPixels){
      this._datatableContainerWidthPixels = currentWidth;
      this.changeDetector.detectChanges();
    }
    return this._datatableContainerWidthPixels;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.rowsData) {
      return;
    }

    if (this.initGrid) {
      this.columns = [];
      Array.from(this.columnsMappings.keys()).forEach(col => {
        let column: TableColumn = {
          name: col,
          headerTemplate: this.columnTemplate,
          cellTemplate: this.cellTemplate,
          flexGrow: this.columnsMappings.get(col).flexGrow,
          resizeable: false
        };
        this.columns.push(column);
      });
      this.rows = this.rowsData;
      this.initGrid = false;
    } else {
      this.rows = [...this.rows, ...this.rowsData];
    }
    this.rows.forEach(row => {
      row.rowDefaultHeight = this.rowHeight;
      row.gridViewState = this.viewContext.getGridViewState();
    });
    this.isLoading = false;
  }

  public getRowHeight(row : DataRow) : number {
    let rowExpandedState = row.gridViewState.getRowExpandedState(row);
    if(rowExpandedState == null) {
      return row.rowDefaultHeight;
    }
    else {
      return rowExpandedState.expandedHeight;
    }
  }

  public reloadGrid() {
    this.initGrid = true;
    this.viewContext.getGridViewState().clearExpandedRows();
    this.sortedGridQuery.offset = 0;
    this.columns = [];
    this.rows = [];
    this.onSortedGridQueryChanged.emit();
  }

  public refreshRowsHeight() {
    this.rows = [...this.rows];
  }

  public onGridScroll = debounce((offsetY: number) => {
    this.rows = [...this.rows];
    if (this.totalRows <= this.rows.length) {
      return;
    }
    if (!this.isLoading && ScreenCalculationUtils.isScrollAtEndOfScreen(this.elementRef,
                                                                        offsetY,
                                                                        this.rows.length,
                                                                        this.rowHeight,
                                                                        this.headerHeight)) {
      this.isLoading = true;
      this.sortedGridQuery.offset += this.sortedGridQuery.limit;
      this.onSortedGridQueryChanged.emit();
    }
  });

  private columnClick(columnName: string) {
    if (!this.columnsMappings.get(columnName).enableSorting) {
      return;
    }
    if (this.sortedGridQuery.sortDetails == null ||
      this.sortedGridQuery.sortDetails.column != columnName) {
      this.sortedGridQuery.sortDetails = new DataGridQuerySortDetails();
      this.sortedGridQuery.sortDetails.column = columnName;
      this.sortedGridQuery.sortDetails.isSortedUp = true;
    } else if (this.sortedGridQuery.sortDetails.isSortedUp) {
      this.sortedGridQuery.sortDetails.isSortedUp = false;
    } else {
      this.sortedGridQuery.sortDetails = null;
    }
    this.reloadGrid();
  }

  // tslint:disable-next-line:no-any
  private isColumnSortedUp(columnName: any) {
    return this.sortedGridQuery != null &&
           this.sortedGridQuery.sortDetails != null &&
           this.sortedGridQuery.sortDetails.column == columnName &&
           this.sortedGridQuery.sortDetails.isSortedUp;
  }

  // tslint:disable-next-line:no-any
  private isColumnSortedDown(columnName: any) {
    return this.sortedGridQuery != null &&
           this.sortedGridQuery.sortDetails != null &&
           this.sortedGridQuery.sortDetails.column == columnName &&
           !this.sortedGridQuery.sortDetails.isSortedUp;
  }
}
