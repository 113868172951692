import {DataRow, Group} from '../../model/common-model';

export class GroupRoutineEventsResult {
  public offset: number;
  public limit: number;
  public total: number;
  public result: GroupRoutineEventRow[];
}

export enum GroupRoutineEventType {
  Milking = 'Milking',
  LockUpTime = 'LockUpTime',
  Cooling = 'Cooling',
  FeedPushUp = 'FeedPushUp',
  DeliverFreshFood = 'DeliverFreshFood',
  OutToPasture = 'OutToPasture',
  BackFromPasture = 'BackFromPasture'
}

export class GroupRoutineEventsMetadata {
  public eventTypes: GroupRoutineEventType[];
  public groups: Group[]
}

export class GroupRoutineEventRow extends DataRow {
  public id: number;
  public eventType: GroupRoutineEventType;
  public startTime: number;
  public groups: Group[];
}

export class GroupRoutineEventModifyModel {
  public eventType: GroupRoutineEventType;
  public startTime: number;
  public groupIds: number[];
}
