import {Directive, ElementRef, OnInit} from '@angular/core';
import {ConfigService} from '../../../services/config/config.service';
import {FarmRole} from '../../../services/auth/model/farm-role.enum';

@Directive({
  selector: '[nonExternalUser]'
})
export class NonExternalUserDirective implements OnInit {

  constructor(private elemnt: ElementRef<HTMLElement>,
              private readonly configService : ConfigService) {
  }

  public ngOnInit(): void {
    if(this.configService.serverConfig.user.farmRole == FarmRole.ExternalUser) {
      this.elemnt.nativeElement.remove();
    }
  }
}
