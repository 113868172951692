<div class="form-section-wrapper">
  <div class="form-section">
    <router-outlet></router-outlet>
    <div class="frame-corner"></div>
  </div>
</div>
<div class="welcome-section">
  <div class="icon sense-hub-logo" sh-id="welcome_logo"></div>
  <div class="welcome-text-header" translation-id="REGISTRATION.FLOW.WELCOME_TO">
    {{'REGISTRATION.FLOW.WELCOME_TO' | translate}}
    <span translation-id="REGISTRATION.FLOW.SENSE_TIME" sh-id="welcome-text-header-div">{{'REGISTRATION.FLOW.SENSE_TIME' | translate}}</span>
  </div>
  <div class="welcome-text-info error" *ngIf="serverError" (click)="reloadPage()">
    {{ 'REGISTRATION.FLOW.SERVER_NOT_RESPONDING' | translate }}
    <br/>
    <span sh-id="server_error_indication">{{ 'REGISTRATION.FLOW.TRY_RELOAD_AGAIN' | translate }}</span>
  </div>
</div>
<div class="language-picker-wrapper">
  <translation-picker [theme]="TranslationPickerThemeEnum.White"></translation-picker>
</div>
