import {Component, Input, OnInit} from '@angular/core';
import {DisplayMode} from './models/display.mode';

export enum GridSummaryIcon {
  Cow = 'Cow',
  Group = 'Group',
  Farm = 'Farm',
  Notes = 'Notes'
}

@Component({
  selector: 'grid-summary',
  templateUrl: './grid-summary.component.html',
  styleUrls: ['./grid-summary.component.scss']
})
export class GridSummaryComponent implements OnInit {

  @Input()
  public summaryTitle: string;

  @Input()
  public rowCount: number;

  @Input()
  public rowTotal: number;

  @Input()
  public icon: GridSummaryIcon;

  @Input()
  public displayMode: DisplayMode = DisplayMode.showOfPart;

  public GridSummaryIcon = GridSummaryIcon;

  constructor() { }

  public ngOnInit() {
  }

  public get showOfPart(): boolean {
    return this.displayMode == DisplayMode.showOfPart;
  }
}
