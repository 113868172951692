import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DropdownListItem} from '../../../dropdown-list/dropdown-list-model';
import {CreateCullingEventDetails, CullingCowEventMetadata, ICullingReason} from '../../../../../services/animals/model/animal-events';
import {RegularDropdownListComponent} from '../../../dropdown-list/regular-dropdown-list/regular-dropdown-list.component';

@Component({
  selector: 'create-batch-event-culling',
  templateUrl: './create-batch-event-culling.component.html',
  styleUrls: ['./create-batch-event-culling.component.scss']
})
export class CreateBatchEventCullingComponent implements OnInit, OnChanges {

  @Input()
  public cullingEventDetails : CreateCullingEventDetails;

  @Input()
  public meta: CullingCowEventMetadata;

  public cullingReasonsDropdownListItems: DropdownListItem[];

  public selectedCullingReason: DropdownListItem;

  @ViewChild(RegularDropdownListComponent, {static: true})
  public regularDropdown: RegularDropdownListComponent;

  constructor() { }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(this.meta == null){
      this.cullingReasonsDropdownListItems = [];
      this.selectedCullingReason = null;
    } else {
      this.cullingReasonsDropdownListItems = this.meta.cullingReason.map(value => {
        return {
          value: value.key,
          displayValue: 'ANIMAL.EVENTS.ADD_EVENT.CULLING_EVENT_REASONS.' + value.value,
          disabled: false,
          icon: null
        };
      });

      if (!this.cullingEventDetails.cullingReason) {
        this.cullingEventDetails.cullingReason = this.pickAReason(this.regularDropdown.selectedItem)
      }

      if(this.cullingEventDetails.cullingReason != null) {
        this.selectedCullingReason = this.cullingReasonsDropdownListItems.find(value => value.value == this.cullingEventDetails.cullingReason.key);
      } else {
        this.selectedCullingReason = null;
      }
    }
  }

  public onCullingReasonSelected(selectedCullingReason: DropdownListItem) {
    this.cullingEventDetails.cullingReason = this.pickAReason(selectedCullingReason);
  }

  private pickAReason(item: DropdownListItem): ICullingReason {
    return this.meta.cullingReason.find(value => !!item && value.key == item.value)
  }
}
