import {Component, ElementRef, OnInit} from '@angular/core';
import {ISuccessDialogData, SuccessDialogDataOperation, SuccessDialogService} from './success-dialog.service';
import {Subscription} from 'rxjs';
import {ClickPressEventsService} from '../../../../services/click-press-events/click-press-events.service';

@Component({
  selector: 'success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent implements OnInit {

  public subscription: Subscription = new Subscription();

  public isOpen: boolean = false;

  public messageText!: string;

  private onClose: () => void;

  constructor(private readonly successDialogService: SuccessDialogService,
              private element: ElementRef,
              private readonly clickPressEventsService: ClickPressEventsService) {}

  public escapeClick() {
    if (this.isOpen) {
      this.closeDialog();
    }
  }

  public ngOnInit(): void {
    document.body.appendChild(this.element.nativeElement);
    this.subscription.add(
      this.successDialogService.popupShown.subscribe((dialogData: ISuccessDialogData) => {
        if(dialogData.operation == SuccessDialogDataOperation.Close) {
          this.closeDialog();
          return;
        }
        this.isOpen = true;
        this.messageText = dialogData.messageText;
        this.onClose = dialogData.onClose;
        setTimeout(() => this.closeDialog(), 2000);
      })
    );
    this.subscription.add(
      this.clickPressEventsService.keydownEscape.subscribe(() => this.escapeClick())
    )
  }

  public closeDialog(): void {
    this.isOpen = false;
    if (this.onClose && typeof this.onClose === 'function') {
      this.onClose();
    }
    this.onClose = null;
  }

  public ngOnDestroy(): void {
    this.closeDialog();
    this.subscription.unsubscribe();
    if (this.element.nativeElement && this.element.nativeElement.parentNode == document.body) {
      document.body.removeChild(this.element.nativeElement);
    }
  }
}
