export enum DropdownListAutoSelectionMode {
  None= 'none',
  First = 'first',
  Single = 'single'
}

// tslint:disable-next-line:no-any
export class DropdownListItem<T = any> {
  public value: T;
  public displayValue: string;
  public disabled: boolean;
  public icon: DropdownListLeftIcon;
}

export enum DropdownListLeftIcon {
  Milking = 'Milking',
  LockUpTime = 'LockUpTime',
  Cooling = 'Cooling',
  FeedPushUp = 'FeedPushUp',
  DeliverFreshFood = 'DeliverFreshFood',
  OutToPasture = 'OutToPasture',
  BackFromPasture = 'BackFromPasture'
}
