import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'accordion-report-sizable-cell',
  templateUrl: './accordion-report-sizable-cell.component.html',
  styleUrls: ['./accordion-report-sizable-cell.component.scss']
})
export class AccordionReportSizableCellComponent implements OnInit {

  @ViewChild('sizableCell')
  private sizableCell: ElementRef<HTMLElement>;

  @ViewChild('sizableCellTitle')
  private sizableCellTitle: ElementRef<HTMLElement>;

  @Input()
  public title : string;

  @Input()
  public hasDnbBadge : boolean;

  @Input()
  public cellShId : string;

  @Output()
  public onClick = new EventEmitter<void>();

  public _useEllipsis: boolean = false;

  private static readonly TitleScales : number[] = [1, 0.83, 0.68];

  private currentScale: number = 1;

  public baseFontSize: number = 26;

  private titleBaseWidth: number;

  constructor(private readonly changeDetector: ChangeDetectorRef) { }

  public ngOnInit(): void {
  }

  public get titleScale() : number {
    let newScale = null;
    if(this.sizableCell == null ||
       this.sizableCellTitle == null ||
       this.sizableCell.nativeElement == null ||
       this.sizableCellTitle.nativeElement == null)
    {
      newScale = AccordionReportSizableCellComponent.TitleScales[0];
      if(newScale != this.currentScale) {
        this.currentScale = newScale;
        this.changeDetector.detectChanges();
      }
      return this.currentScale;
    }
    if(this.currentScale == null) {
      this.currentScale = AccordionReportSizableCellComponent.TitleScales[0];
    }
    let cellBounds = this.sizableCell.nativeElement.getBoundingClientRect();

    if (!this.titleBaseWidth) {
      let titleBounds = this.sizableCellTitle.nativeElement.getBoundingClientRect();
      this.titleBaseWidth = titleBounds.width;
    }

    for (let i = 0; i < AccordionReportSizableCellComponent.TitleScales.length; i++) {

      if (this.titleBaseWidth * AccordionReportSizableCellComponent.TitleScales[i] < cellBounds.width - 6) {
        newScale = AccordionReportSizableCellComponent.TitleScales[i];
        this.useEllipsis = false;
        break;
      }
      if (i == AccordionReportSizableCellComponent.TitleScales.length - 1) {
        newScale = AccordionReportSizableCellComponent.TitleScales[i];
        this.useEllipsis = true;
      }
    }
    if(newScale == null) {
      newScale = AccordionReportSizableCellComponent.TitleScales[AccordionReportSizableCellComponent.TitleScales.length - 1];
    }
    if(newScale != this.currentScale) {
      this.currentScale = newScale;
      this.changeDetector.detectChanges();
    }
    return this.currentScale;
  }

  public get useEllipsis(): boolean {
    return this._useEllipsis;
  }

  public set useEllipsis(value: boolean) {
    if (this._useEllipsis != value) {
      this._useEllipsis = value;
      this.changeDetector.detectChanges();
    }
  }

  public onCellClick() {
    this.onClick.emit();
  }

  public get fontSize(): number {
    return Math.round(this.baseFontSize * this.titleScale);
  }
}
