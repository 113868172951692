import { Component, OnInit } from '@angular/core';
import {IFarmVersion} from "../../../services/registration/model/registration.model";
import {RegistrationService} from "../../../services/registration/registration.service";

@Component({
  selector: 'farm-version',
  templateUrl: './farm-version.component.html',
  styleUrls: ['./farm-version.component.scss']
})
export class FarmVersionComponent implements OnInit {
  public farmVersionData: IFarmVersion;

  constructor(private registrationService: RegistrationService) { }

  public async ngOnInit() {
    this.farmVersionData = await this.registrationService.getFarmVersion();
  }

  public isFarmVersionDataAvailable() {
    return this.farmVersionData && this.farmVersionData.farmVersion;
  }
}
