import { Pipe, PipeTransform } from '@angular/core';
import {IKeyValuePair} from '../../services/model/key-value-pair';

@Pipe({
  name: 'keys'
})
export class KeysPipe implements PipeTransform {

  // tslint:disable-next-line:no-any
  public transform(value, preventNullValue?: boolean): IKeyValuePair[] {
    if (!value) {
      return [];
    }
    let keys : IKeyValuePair[] = [];
    for (const key of Object.keys(value)) {
      if(!preventNullValue || value[key] != null) {
        keys.push({key: key, value: value[key]});
      }
    }

    return keys;
  }

}
