import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {AnimalEventsService} from '../../../../../services/animals/animal-events.service';
import {
  AbortionCowEventMetadata,
  AbortionSameLactationCowEventMetadata,
  AssignRfIdTagCowEventMetadata,
  AssignScrTagCowEventMetadata,
  CalvingCowEventMetadata,
  CastrationEventDetails,
  ChangeRfIdTagCowEventMetadata,
  ChangeScrTagCowEventMetadata,
  CowEventMetadataBase,
  CreateAbortionEventDetails,
  CreateAbortionSameLactationEventDetails,
  CreateAssignRfIdTagEventDetails,
  CreateAssignSrcTagEventDetails,
  CreateBreedingEventDetails,
  CreateCalvingEventDetails,
  CreateChangeGroupEventDetails,
  CreateChangeRfIdTagEventDetails,
  CreateChangeScrTagEventDetails,
  CreateCullingEventDetails,
  CreateDoNotBreedEventDetails,
  CreateDryOffEventDetails,
  CreateEventMode,
  CreateGeneralEventDetails,
  CreatePregnancyCheckEventDetails,
  CreateRemoveRfIdTagEventDetails,
  CreateRemoveScrTagEventDetails,
  CreateUnsortEventDetails,
  CreateWeaningEventDetails,
  CullingCowEventMetadata,
  DryOffCowEventMetadata,
  EventActionDetails,
  EventDetails,
  EventTypeEnum,
  IAvailableEventInfo,
  ISavedEventResponse,
  RemoveRfIdTagCowEventMetadata,
  RemoveScrTagCowEventMetadata,
  StartBackgroundEventDetails,
  StartFinishingEventDetails,
  WeightEventDetails
} from '../../../../../services/animals/model/animal-events';
import {AutoCompleteColorScheme} from 'src/app/common/components/auto-complete/auto-complete-model';
import {UtilsService, WeightUnitType} from '../../../../../services/utils/utils.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import {ErrorModel} from '../../../../../services/model/common-model';
import {FarmMode} from '../../../../../services/config/model/server-config';
import {ConfigService} from '../../../../../services/config/config.service';
import {ActivatedRoute} from '@angular/router';
import {ApplicationBundleRoutingMode, RoutingService} from '../../../../../services/routing/routing.service';
import {Subscription} from 'rxjs';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../../../common/components/calendar/model/epoch-state-model';
import {HttpResponseResult} from '../../../../../services/rest-api/rest-api.service';
import {OperationType} from '../../../../../services/search/model/search.model';
import {AnimalCardTabSection} from '../../animal-card-model';
import {LoadingIconService} from "../../../../../services/loading-icon/loading-icon.service";
import {ConfirmCullingAssignedTagDialogService} from '../../../../../common/components/dialogs/confirm-dialog/confirm-culling-assigned-tag-dialog/confirm-culling-assigned-tag-dialog.service';
import {CardViewStateService} from '../../../../../services/ui/view-state/card-view-state.service';
import { CornerColor, CornerContainerBodyBackground } from 'src/app/common/components/containers/corner-container/corner-container.component';
import {SaveDataViewStateService} from '../../../../../services/ui/view-state/save-data-view-state.service';

@Component({
  selector: 'animal-card-create-event',
  templateUrl: './animal-card-create-event.component.html',
  styleUrls: ['./animal-card-create-event.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnimalCardCreateEventComponent implements OnInit, OnChanges, OnDestroy {

  public isOpen: boolean = false;

  public availableEvents: IAvailableEventInfo[] = [];

  public selectedEvent: IAvailableEventInfo;

  public selectedEventMeta: CowEventMetadataBase;

  public eventDetails?: WeightEventDetails | null;

  public selectedEpoch: EpochStateModel = new EpochStateModel(EpochStateModelMinMaxMode.Date);

  public AutoCompleteColorScheme = AutoCompleteColorScheme;

  public eventDetailsError: ErrorModel = new ErrorModel();

  public applicationBundleRoutingMode: ApplicationBundleRoutingMode;

  public readonly EventEditorAreaName: string = 'eventEditor';

  @Input()
  public animalId: number;

  @Output()
  public addEventClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get isSaveEnabled(): boolean {
    return this.eventDetails != null && this.eventDetails.isValid();
  }

  public get isAddEventButtonDisabled(): boolean {
    return !(this.availableEvents && this.availableEvents.length > 0);
  }

  public EventTypeEnum = EventTypeEnum;

  public CreateEventMode = CreateEventMode;

  private queryParamsSubscription: Subscription;

  public CornerColor = CornerColor;

  public CornerContainerBodyBackground = CornerContainerBodyBackground;

  constructor(
    private readonly animalEventsService: AnimalEventsService,
    private readonly cardViewStateService: CardViewStateService,
    private readonly confirmCullingAssignedTagDialogService: ConfirmCullingAssignedTagDialogService,
    private readonly loadingIconService: LoadingIconService,
    private utilsService: UtilsService,
    public configService: ConfigService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly saveDataViewStateService:SaveDataViewStateService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.queryParamsSubscription = this.route.queryParams.subscribe(async queryParams => {
      if (this.configService.serverConfig.farmMode === FarmMode.Dairy) {
        this.applicationBundleRoutingMode = null;
      } else {
        this.applicationBundleRoutingMode = this.routingService.getApplicationBundleRoutingMode(queryParams);
      }
    });
  }

  public async ngOnChanges(changes: SimpleChanges) {
    this.availableEvents = [];
    if (changes.animalId == null) {
      return;
    }

    this.availableEvents = await this.animalEventsService.getAnimalAvailableEvents(this.animalId);
  }

  public onModelChanged(model: EventDetails): void {
    this.eventDetails = model;
    this.clearErrors();
    this.changeDetectorRef.detectChanges();
  }

  private clearErrors(): void {
    this.eventDetailsError = new ErrorModel();
  }

  public async save($event: Event): Promise<void> {
    if(!this.isSaveEnabled) {
      return;
    }
    $event.stopPropagation();
    this.loadingIconService.show();
    if (!await this.validate()) {
      this.cardViewStateService.openLastActiveCardViewState();
      this.loadingIconService.hide();
      return;
    }

    const eventAction = new EventActionDetails();
    eventAction.startDateTime = this.selectedEpoch.epoch;
    let eventDetailsToSend: WeightEventDetails = Object.assign({}, this.eventDetails);
    // check if needs event is weight - and if is in pounds (according to language en-us) - convert weight to KG
    if (eventDetailsToSend.type === EventTypeEnum.Weight) {
      if (eventDetailsToSend.weightValueLBS === '') {
        eventDetailsToSend.weightValueLBS = null;
      }
      if (eventDetailsToSend.weightValueKG === '') {
        eventDetailsToSend.weightValueKG = null;
      }
      if (this.utilsService.getUnitType() === WeightUnitType.KG) {
        eventDetailsToSend.weightValueLBS = String(this.utilsService.convertKilogramsToPounds(Number(this.eventDetails.weightValueKG)));
      } else {
        eventDetailsToSend.weightValueKG = String(this.utilsService.convertPoundsToKilograms(Number(this.eventDetails.weightValueLBS)));
      }
    }
    const response: HttpResponseResult<ISavedEventResponse> = await this.animalEventsService.createAnimalEvent(this.animalId, eventAction, eventDetailsToSend);

    if (response.responseBody != null) {
      if (response.responseBody.isChangeOperation) {
        if (response.responseBody.isFinishing) {
          this.cardViewStateService.openAnimalCard(response.responseBody.animalId,
                                                    OperationType.BeefFinishing, null, null, AnimalCardTabSection.Events);
        } else {
          this.cardViewStateService.openAnimalCard(response.responseBody.animalId,
            OperationType.BeefBreeding, null, null, AnimalCardTabSection.Events);
        }
      } else {
        this.cardViewStateService.openLastActiveCardViewState();
      }
      this.loadingIconService.hide();
      this.animalEventsService.eventsListChanged.next(this.animalId);
    } else if (response.errorResponseBody != null) {
      this.eventDetailsError = response.errorResponseBody.result.failures[0];
      this.loadingIconService.hide();
    }
  }

  private async validate(): Promise<boolean> {
    if (this.eventDetails.type === EventTypeEnum.Culling && (this.selectedEventMeta as CullingCowEventMetadata).isScrTagExist) {
      this.loadingIconService.hide();
      const result = await this.confirmCullingAssignedTagDialogService.showPopup();
      this.loadingIconService.show();
      return result;
    } else {
      return true;
    }
  }

  public async toggleOpen(): Promise<void> {
    if (this.isAddEventButtonDisabled) {
      return;
    }
    this.toggle();
    if (!this.isOpen) {
      this.saveDataViewStateService.activeEditArea = null;
      this.selectedEvent = null;
    }
    this.addEventClicked.emit(this.isOpen);
  }

  private toggle(): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen === false) {
      this.clearForm();
      this.clearErrors();
    }
  }

  private clearForm(): void {}

  public epochChanged(epoch: EpochStateModel): void {
    this.selectedEpoch = _.cloneDeep(epoch);
    this.resetStartDateTimeError();
  }

  private resetStartDateTimeError() {
    if (this.eventDetailsError && this.eventDetailsError.fieldName === 'startDateTime') {
      this.clearErrors();
    }
  }

  public async onEventSelected(event: IAvailableEventInfo): Promise<void> {
    this.selectedEventMeta = await this.animalEventsService.getEventTypeMetadata(
      this.animalId,
      event.type
    );
    this.saveDataViewStateService.activeEditArea = this.EventEditorAreaName;
    this.selectedEvent = event;
    this.selectedEpoch = new EpochStateModel(EpochStateModelMinMaxMode.Date, moment().unix());
    this.selectedEpoch.min = this.selectedEvent.minDate;
    this.selectedEpoch.max = this.selectedEvent.maxDate;
    this.clearErrors();
    this.eventDetails = this.getDefaultEventDetails(event, this.selectedEventMeta);
  }

  private getDefaultEventDetails(
    event: IAvailableEventInfo,
    meta: CowEventMetadataBase
  ): EventDetails | null {
    switch (event.type) {
      case EventTypeEnum.PregnancyCheck:
        return new CreatePregnancyCheckEventDetails();
      case EventTypeEnum.Breeding:
        return new CreateBreedingEventDetails();
      case EventTypeEnum.DoNotBreed:
        return new CreateDoNotBreedEventDetails();
      case EventTypeEnum.Unsort:
        return new CreateUnsortEventDetails();
      case EventTypeEnum.DryOff:
        return new CreateDryOffEventDetails(meta as DryOffCowEventMetadata);
      case EventTypeEnum.ChangeGroup:
        return new CreateChangeGroupEventDetails();
      case EventTypeEnum.Culling:
        return new CreateCullingEventDetails();
      case EventTypeEnum.Calving:
        return new CreateCalvingEventDetails(meta as CalvingCowEventMetadata);
      case EventTypeEnum.Abortion:
        return new CreateAbortionEventDetails(meta as AbortionCowEventMetadata);
      case EventTypeEnum.AbortionSameLactation:
        return new CreateAbortionSameLactationEventDetails(meta as AbortionSameLactationCowEventMetadata);
      case EventTypeEnum.AssignScrTag:
        return new CreateAssignSrcTagEventDetails(meta as AssignScrTagCowEventMetadata);
      case EventTypeEnum.AssignRfIdTag:
        return new CreateAssignRfIdTagEventDetails(meta as AssignRfIdTagCowEventMetadata);
      case EventTypeEnum.RemoveScrTag:
        return new CreateRemoveScrTagEventDetails(meta as RemoveScrTagCowEventMetadata);
      case EventTypeEnum.RemoveRfIdTag:
        return new CreateRemoveRfIdTagEventDetails(meta as RemoveRfIdTagCowEventMetadata);
      case EventTypeEnum.ChangeScrTag:
        return new CreateChangeScrTagEventDetails(meta as ChangeScrTagCowEventMetadata);
      case EventTypeEnum.ChangeRfIdTag:
        return new CreateChangeRfIdTagEventDetails(meta as ChangeRfIdTagCowEventMetadata);
      case EventTypeEnum.Weight:
        return new WeightEventDetails();
      case EventTypeEnum.Castration:
        return new CastrationEventDetails();
      case EventTypeEnum.StartBackground:
        return new StartBackgroundEventDetails();
      case EventTypeEnum.StartFinishing:
        return new StartFinishingEventDetails();
      case EventTypeEnum.Weaning:
        return new CreateWeaningEventDetails();
      case EventTypeEnum.General:
        return new CreateGeneralEventDetails();
      default:
        return null;
    }
  }

  public ngOnDestroy(): void {
    this.queryParamsSubscription.unsubscribe();
  }
}
