<settings-section *ngIf="hasPermissionsToNotifications"
                  nonExternalUser
                  [icon]="'icon-alerts'"
                  [routeShId]="'welcome-notifications-route'"
                  [title]="'MANAGE.WELCOME.TITLE.NOTIFICATIONS'"
                  [description]="'MANAGE.WELCOME.ALERTS_DESCRIPTION'"
                  [screenType]="ManageSettingsScreenType.alerts"
                  [screenCategories]="[]"
                  (onScreenItemClick)="onOpenScreen.emit($event)"></settings-section>
<ng-container *ngIf="!isFinishing">
  <settings-section *ngIf="hasPermissionsToHealth"
                    [icon]="'icon-health'"
                    [routeShId]="'welcome-health-report-route'"
                    [title]="'MANAGE.WELCOME.TITLE.HEALTH'"
                    [description]="'MANAGE.WELCOME.HEALTH_DESCRIPTION'"
                    [screenType]="ManageSettingsScreenType.healthReport"
                    [screenCategories]="healthScreenCategories"
                    (onScreenItemClick)="onOpenScreen.emit($event)"></settings-section>
  <settings-section [icon]="'icon-reproduction'"
                    [routeShId]="'welcome-reproduction-voluntary-waiting-route'"
                    [title]="'MANAGE.WELCOME.TITLE.REPRODUCTION'"
                    [description]="'MANAGE.WELCOME.REPRODUCTION_DESCRIPTION'"
                    [screenType]="ManageSettingsScreenType.reproductionVoluntaryWaitingPeriod"
                    [screenCategories]="reproductionScreenCategories"
                    (onScreenItemClick)="onOpenScreen.emit($event)"></settings-section>
  <settings-section *ngIf="presentFarmRoutineSection"
                    [icon]="'icon-farm-routine'"
                    [routeShId]="'welcome-farm-routine-route'"
                    [title]="'MANAGE.WELCOME.TITLE.FARM_ROUTINE'"
                    [description]="'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.CAPTION'"
                    [screenType]="configService.serverConfig.isSortingEnabled ? ManageSettingsScreenType.farmRoutineShifts : ManageSettingsScreenType.farmRoutineGroupRoutineEvents"
                    [screenCategories]="farmRoutineCategories"
                    (onScreenItemClick)="onOpenScreen.emit($event)"></settings-section>
</ng-container>
<ng-container *ngIf="isFinishing">
  <settings-section [icon]="'icon-health'"
                    [routeShId]="'welcome-finishing-health-report-route'"
                    [title]="'MANAGE.WELCOME.TITLE.HEALTH'"
                    [description]="'MANAGE.WELCOME.FINISHING_HEALTH_DESCRIPTION'"
                    [screenType]="ManageSettingsScreenType.healthReport"
                    [screenCategories]="healthScreenCategories"
                    (onScreenItemClick)="onOpenScreen.emit($event)"></settings-section>
</ng-container>
