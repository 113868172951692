import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[no-selectstart-event-bubbling]'
})
export class NoSelectStartEventBubblingDirective {
  @HostListener('selectstart', ['$event'])
  public onSelectStart(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
}
