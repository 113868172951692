import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ManageSettingsService} from '../../../../../services/manage/manage-settings.service';
import {GoogleAnalyticsService} from '../../../../../services/google-analytics/google-analytics.service';
import {ManageSettingsScreenType} from '../../model/manage-settings.model';

export interface IManagePageCategory {
  category: ManageSettingsScreenType;
  translationId: string;
  shId: string;
}

@Component({
  selector: 'settings-section',
  templateUrl: './settings-section.component.html',
  styleUrls: ['./settings-section.component.scss']
})
export class SettingsItemComponent implements OnInit {

  @Input()
  public icon: string;

  @Input()
  public title: string;

  @Input()
  public description: string;

  @Input()
  public screenType: ManageSettingsScreenType;

  @Input()
  public screenCategories: IManagePageCategory[];

  @Input()
  public routeShId: string;

  @Output()
  public onScreenItemClick : EventEmitter<ManageSettingsScreenType> = new EventEmitter<ManageSettingsScreenType>();

  constructor(private googleAnalyticsService: GoogleAnalyticsService,
              private readonly manageSettingsService: ManageSettingsService) { }

  public ngOnInit() {
  }

  public itemClick() {
    this.googleAnalyticsService.send("Manage Settings", "Link Open", this.title);
    this.onScreenItemClick.emit(this.screenType);
  }

  public pageCategoryClick(category: ManageSettingsScreenType) {
    this.googleAnalyticsService.send("Manage Settings", "Link Open", this.title);
    this.onScreenItemClick.emit(category);
  }

}
