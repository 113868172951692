import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {LedTasksService} from '../led-tasks.service';
import {UserPermissionsService} from "../../auth/user-permissions.service";

@Injectable({
  providedIn: 'root'
})
export class SuspendGuard implements CanActivate {

  constructor(private readonly userPermissionsService: UserPermissionsService, private readonly router: Router) {}

  public async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    if (this.userPermissionsService.isSuspended()) {
      await this.router.navigate(['/dashboard']);
      return false;
    } else {
      return true;
    }
  }

}
