import {Component, SimpleChanges} from '@angular/core';
import {
  WeaningAnimalEventMetadata,
  CreateWeaningEventDetails
} from 'src/app/services/animals/model/animal-events';
import {IGroupMetadata} from '../../../../../services/groups/model/group.interface';
import {DropdownListItem} from '../../../../../common/components/dropdown-list/dropdown-list-model';
import {EventFormComponent} from '../event-form.component';
import {FormsDLIconsThemes} from "../../../../../common/components/dropdown-list/forms-dropdown-list/forms-dropdown-list.component";

@Component({
  selector: 'weaning-event-form',
  templateUrl: './weaning-event-form.component.html',
  styleUrls: ['./weaning-event-form.component.scss']
})
export class WeaningEventFormComponent extends EventFormComponent<WeaningAnimalEventMetadata, CreateWeaningEventDetails> {

  public formsDLIconsThemes = FormsDLIconsThemes;

  public get destinationGroup() {
    return this.editModel && this.editModel.destinationGroup;
  }

  public set destinationGroup(selectedDestinationGroup: number | undefined) {
    if (this.editModel) {
      this.editModel.destinationGroup = selectedDestinationGroup;
    }
    this.emitModelChanged();
  }

  public destinationGroups: DropdownListItem[] = [];

  constructor() {
    super();
  }

  protected onChanges(changes: SimpleChanges): void {
    if (changes.meta != null) {
      this.destinationGroups = [];
      this.meta.groups.forEach((group: IGroupMetadata) => {
        let item: DropdownListItem = {
          value: group.id,
          disabled: false,
          displayValue: group.name,
          icon: null
        };
        this.destinationGroups.push(item);
      });
    }
  }
}
