<div class="health-section" sh-id="farm-health-content" *ngIf="model" saveDataEditSection [editAreaName]="editAreaName">
  <section #healthReport id="healthReport">
    <div class="section-header"
         translation-id="MANAGE.SETTINGS.HEALTH.HEALTH_REPORT.TITLE">{{ 'MANAGE.SETTINGS.HEALTH.HEALTH_REPORT.TITLE' | translate }}</div>
    <div class="section-content">
      <div class="section-description" translation-id="MANAGE.SETTINGS.HEALTH.HEALTH_REPORT.DESCRIPTION">
        {{ 'MANAGE.SETTINGS.HEALTH.HEALTH_REPORT.DESCRIPTION' | translate }}
      </div>
      <div class="section-controls">
        <div class="section-field-editor" *ngIf="model.healthIndexThreshold">
          <div class="header">
            <div class="title" translation-id="MANAGE.SETTINGS.HEALTH.HEALTH_REPORT.EDITOR_TITLE1">
              {{ 'MANAGE.SETTINGS.HEALTH.HEALTH_REPORT.EDITOR_TITLE1' | translate }}
            </div>
            <div class="buttons-wrapper">
              <div class="revert-icon" (click)="revertHealthIndexThreshold()"></div>
            </div>
          </div>
          <div class="content">
            <numeric-input class="manage-settings-health"
                           [shId]="'healthIndexThreshold'"
                           [min]="model.healthIndexThreshold.min"
                           [max]="model.healthIndexThreshold.max"
                           [canDecrease]="canDecreaseHealthIndexThresholdValue"
                           [isDisabled]="isFieldEditable()"
                           (onChangeSelectedValue)="updateValue('healthIndexThreshold', $event)"
                           [selectedValue]="model.healthIndexThreshold.value"></numeric-input>
          </div>
        </div>
        <div class="section-field-editor" *ngIf="model.severeHealthIndexThreshold">
          <div class="header">
            <div class="title" translation-id="MANAGE.SETTINGS.HEALTH.HEALTH_REPORT.EDITOR_TITLE2">
              {{ 'MANAGE.SETTINGS.HEALTH.HEALTH_REPORT.EDITOR_TITLE2' | translate }}
            </div>
            <div class="buttons-wrapper">
              <div class="revert-icon" (click)="revertSevereHealthIndexThreshold()"></div>
            </div>
          </div>
          <div class="content">
            <numeric-input class="manage-settings-health"
                           [min]="model.severeHealthIndexThreshold.min"
                           [max]="model.severeHealthIndexThreshold.max"
                           [canIncrease]="canIncreaseSevereHealthIndexThresholdValue"
                           [isDisabled]="isFieldEditable()"
                           (onChangeSelectedValue)="updateValue('severeHealthIndexThreshold', $event)"
                           [selectedValue]="model.severeHealthIndexThreshold.value"></numeric-input>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section #freshReport id="freshReport" *ngIf="hasPermissionsToExtendedHealthView">
    <div class="section-header"
         translation-id="MANAGE.SETTINGS.HEALTH.FRESH_REPORT.TITLE">{{ 'MANAGE.SETTINGS.HEALTH.FRESH_REPORT.TITLE' | translate }}</div>
    <div class="section-content">
      <div class="section-description" translation-id="MANAGE.SETTINGS.HEALTH.FRESH_REPORT.DESCRIPTION">
        {{ 'MANAGE.SETTINGS.HEALTH.FRESH_REPORT.DESCRIPTION' | translate }}
      </div>
      <div class="section-controls" *ngIf="model.inclusionInEarlyFreshCowsReport">
        <div class="section-field-editor">
          <div class="header">
            <div class="title" translation-id="MANAGE.SETTINGS.HEALTH.FRESH_REPORT.EDITOR_TITLE">
              {{ 'MANAGE.SETTINGS.HEALTH.FRESH_REPORT.EDITOR_TITLE' | translate }}
            </div>
          </div>
          <div class="content">
            <numeric-input class="manage-settings-health"
                           [min]="model.inclusionInEarlyFreshCowsReport.min"
                           [max]="model.inclusionInEarlyFreshCowsReport.max"
                           [isDisabled]="isFieldEditable()"
                           (onChangeSelectedValue)="updateValue('inclusionInEarlyFreshCowsReport', $event)"
                           [selectedValue]="model.inclusionInEarlyFreshCowsReport.value"></numeric-input>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section #distressSensitivity id="distressSensitivity" *ngIf="hasPermissionsToExtendedHealthView">
    <div class="section-header"
         translation-id="MANAGE.SETTINGS.HEALTH.DISTRESS_SENSITIVITY.TITLE">{{ 'MANAGE.SETTINGS.HEALTH.DISTRESS_SENSITIVITY.TITLE' | translate }}</div>
    <div class="section-content">
      <div class="section-description" translation-id="MANAGE.SETTINGS.HEALTH.DISTRESS_SENSITIVITY.DESCRIPTION">
        {{ 'MANAGE.SETTINGS.HEALTH.DISTRESS_SENSITIVITY.DESCRIPTION' | translate }}
      </div>
      <div class="section-controls">
        <div class="section-field-editor">
          <div class="header">
            <div class="title" translation-id="MANAGE.SETTINGS.HEALTH.DISTRESS_SENSITIVITY.EDITOR_TITLE">
              {{ 'MANAGE.SETTINGS.HEALTH.DISTRESS_SENSITIVITY.EDITOR_TITLE' | translate }}
            </div>
          </div>
          <div class="content">
            <div class="range-div">
              <span translation-id="MANAGE.SETTINGS.HEALTH.DISTRESS_SENSITIVITY.LOW" [ngClass]="{'disabled': isFieldEditable()}">{{ 'MANAGE.SETTINGS.HEALTH.DISTRESS_SENSITIVITY.LOW' | translate }}</span>
              <input type="range" min="0" max="2" [ngModel]="distressSensitivityValue" [disabled]="isFieldEditable()" (ngModelChange)="changeDistressSensitivity($event)">
              <span translation-id="MANAGE.SETTINGS.HEALTH.DISTRESS_SENSITIVITY.HIGH" [ngClass]="{'disabled': isFieldEditable()}">{{ 'MANAGE.SETTINGS.HEALTH.DISTRESS_SENSITIVITY.HIGH' | translate }}</span>
              <div class="centered" [ngClass]="{'disabled': isFieldEditable()}">
                <span translation-id="MANAGE.SETTINGS.HEALTH.DISTRESS_SENSITIVITY.MEDIUM">{{ 'MANAGE.SETTINGS.HEALTH.DISTRESS_SENSITIVITY.MEDIUM' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section #daysToWeaning id="daysToWeaning" *ngIf="model.daysToWeaning">
    <div class="section-header"
         translation-id="MANAGE.SETTINGS.HEALTH.DAYS_TO_WEANING.TITLE">{{ 'MANAGE.SETTINGS.HEALTH.DAYS_TO_WEANING.TITLE' | translate }}</div>
    <div class="section-content">
      <div class="section-description" translation-id="MANAGE.SETTINGS.HEALTH.DAYS_TO_WEANING.DESCRIPTION">
        {{ 'MANAGE.SETTINGS.HEALTH.DAYS_TO_WEANING.DESCRIPTION' | translate }}
      </div>
      <div class="section-controls">
        <div class="section-field-editor">
          <div class="header">
            <div class="title" translation-id="MANAGE.SETTINGS.HEALTH.DAYS_TO_WEANING.EDITOR_TITLE">
              {{ 'MANAGE.SETTINGS.HEALTH.DAYS_TO_WEANING.EDITOR_TITLE' | translate }}
            </div>
          </div>
          <div class="content">
            <numeric-input class="manage-settings-health"
                           [min]="model.daysToWeaning.min"
                           [max]="model.daysToWeaning.max"
                           [isSaveOnFocusOut]="true"
                           [isDisabled]="isFieldEditable()"
                           (onChangeSelectedValue)="updateValue('daysToWeaning', $event)"
                           [selectedValue]="model.daysToWeaning.value"></numeric-input>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
