<div class="breeding-tab">
  <div class="double-field-div">
    <div class="input-field">
      <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.INSEMINATED'">
        {{ 'MANAGE.COWS.ACTIVE.WIZARD.INSEMINATED'| translate }}
      </div>
      <div class="value">
        <input class="input-class" (ngModelChange)="onErrorsClear.emit()"
                                   [ngClass]="{ error: animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.animalName)}"
                                   type="text"
                                   [(ngModel)]="addFemaleModel.animalNumber"
                                   [attr.sh-id]="'breeding_add_female_animal_number_breeding_tab_input'"
                                   [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER'"
                                   placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER' | translate }}"
                                   readonly/>
      </div>
    </div>
    <div class="input-field">
      <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'">
        {{ 'MANAGE.COWS.ACTIVE.WIZARD.GROUP' | translate }}
      </div>
      <div class="value">
        <forms-dropdown-list class="dropdown-list"
                             [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.group)"
                             [shIdPostfix]="'breeding_add_female_group_breeding_tab'"
                             [autoSelectionMode]="dropdownListAutoSelectionMode.Single"
                             [items]="groupsDropdownListItems"
                             [placeholder]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'"
                             [allowClear]="true"
                             [iconTheme]="formsDLIconsThemes.Green"
                             [translateListItem]="false"
                             [ngModel]="addFemaleModel.group"
                             (ngModelChange)="onGroupSelected.emit($event)"></forms-dropdown-list>
      </div>
    </div>
  </div>
  <div class="seperator"></div>
  <div class="fields-and-errors-div">
    <div class="single-field-div">
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.INSEMINATION_DATE'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.INSEMINATION_DATE' | translate }}
        </div>
        <div class="value">
          <date-picker [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.breedingDate)"
                       [calendarIconColor]="'#9fb103'"
                       [attr.sh-id]="'breeding_add_female_breeding_date'"
                       [selectedEpoch]="addFemaleModel.breedingDate"
                       [shIdPostfix]="'breeding_add_female_breeding_date_date_picker'"
                       (epochChanged)="onBreedingDateChanged($event)"></date-picker>
        </div>
      </div>
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.INSEMINATION_NUMBER'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.INSEMINATION_NUMBER' | translate }}
        </div>
        <div class="value">
          <numeric-input [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.breedingNumber)"
                         [attr.sh-id]="'breeding_add_female_breeding_Number_breeding_tab'"
                         [arrowIconsColor]="ArrowIconColors.green"
                         class="breeding-numeric-up-down"
                         [min]="1"
                         [isNullable]="true"
                         [selectedValue]="addFemaleModel.breedingNumber"
                         (onChangeSelectedValue)="onBreedingNumberChanged($event)"
                         [shId]="'breeding_number_field'"
                         [isSaveOnFocusOut]="true"></numeric-input>
        </div>
      </div>
      <div class="bull-choice-input-field">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.SIRE'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.SIRE' | translate }}
        </div>
        <div class="value">
          <bull-choose-dropdown-list [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.breedingSire)"
                                     [shIdPrefix]="'breeding_add_female_bull_choice_breeding_tab'"
                                     [items]="bullListItems"
                                     [iconTheme]="bullColorTheme.Green"
                                     [selectedBullListItem]="addFemaleModel.bull"
                                     (onBullSelectionChanged)="onBullSelected($event)"
                                     (onBullRemoved)="onBullRemoved($event)"></bull-choose-dropdown-list>
        </div>
      </div>
    </div>
  </div>
</div>
