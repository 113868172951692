<div class="form-group">
  <div class="radio-wrapper">
    <input id="radio-selected" type="radio" name="type" value="true" [(ngModel)]="filterBooleanQuery.value" />
    <label for="radio-selected"></label>
    <label class="title" translation-id="COMMON.TEXT.YES">
      {{ 'COMMON.TEXT.YES' | translate }}
    </label>
    <div class="separate-line"></div>
    <input id="radio-not-selected" type="radio" name="type" value="false" [(ngModel)]="filterBooleanQuery.value" />
    <label for="radio-not-selected"></label>
    <label class="title" translation-id="COMMON.TEXT.NO">
      {{ 'COMMON.TEXT.NO' | translate }}
    </label>
  </div>
</div>
