<div class="list-header">
  <div class="list-header__column list-header__column__shift-name" translation-id="MANAGE.SETTINGS.SHIFTS.COLUMN_SHIFT_NAME">
    {{'MANAGE.SETTINGS.SHIFTS.COLUMN_SHIFT_NAME' | translate }}
  </div>
  <div class="list-header__column" translation-id="MANAGE.SETTINGS.SHIFTS.COLUMN_STARTS">
    {{'MANAGE.SETTINGS.SHIFTS.COLUMN_STARTS' | translate }}
  </div>
  <div class="list-header__column" translation-id="MANAGE.SETTINGS.SHIFTS.COLUMN_ENDS">
    {{'MANAGE.SETTINGS.SHIFTS.COLUMN_ENDS' | translate }}
  </div>
  <div class="list-header__column list-header__column__actions"></div>
</div>
<manage-settings-shift-item *ngFor="let shift of shifts"
                            [shift]="shift"
                            [isDisabled]="isDisabled"
                            (onChangeName)="raiseChangeName(shift, $event.name)"
                            (onSlideStartTime)="raiseSlideStartTime(shift, $event.direction)"
                            (onRemove)="raiseRemove(shift)"></manage-settings-shift-item>
