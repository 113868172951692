import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import {DashboardComponent} from './dashboard.component';
import {DashboardAccordionComponent} from './dashboard-accordion/dashboard-accordion.component';
import {MaintenanceCallsComponent} from './maintenance-calls/maintenance-calls.component';
import {UpdateSoftwareModalComponent} from './maintenance-calls/update-software-modal/update-software-modal.component';
import {DashboardKpiAccordionComponent} from './dashboard-kpi-accordion/dashboard-kpi-accordion.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ShSharedModule} from '../../common/sh-shared.module';
import {SummaryPieChartComponent} from './summary-pie-chart/summary-pie-chart.component';
import {AccumulationChartModule} from '@syncfusion/ej2-angular-charts';
import {LoggedInLayoutModule} from '../logged-in-layout.module';
import { DashboardKpiDetailsComponent } from './dashboard-kpi-accordion/dashboard-kpi-details/dashboard-kpi-details.component';
import { DashboardKpiGraphNoDataComponent } from './dashboard-kpi-accordion/dashboard-kpi-details/dashboard-kpi-graph-no-data/dashboard-kpi-graph-no-data.component';
import { DashboardKpiGraphGradientComponent } from './dashboard-kpi-accordion/dashboard-kpi-details/dashboard-kpi-graph-gradient/dashboard-kpi-graph-gradient.component';
import { DashboardKpiGraphBarComponent } from './dashboard-kpi-accordion/dashboard-kpi-details/dashboard-kpi-graph-bar/dashboard-kpi-graph-bar.component';
import { DashboardKpiGraphEnhancedBarComponent } from './dashboard-kpi-accordion/dashboard-kpi-details/dashboard-kpi-graph-enhanced-bar/dashboard-kpi-graph-enhanced-bar.component';
import { DashboardKpiDescriptionComponent } from './dashboard-kpi-accordion/dashboard-kpi-details/dashboard-kpi-description/dashboard-kpi-description.component';
import { DashboardKpiSelectDialogComponent } from './dashboard-kpi-accordion/dashboard-kpi-select-dialog/dashboard-kpi-select-dialog.component';
import { AccordionReportWidgetActionsComponent } from './dashboard-accordion/accordion-widget/accordion-report-widget/accordion-report-widget-actions/accordion-report-widget-actions.component';
import { AccordionReportWidgetHeaderComponent } from './dashboard-accordion/accordion-widget/accordion-report-widget/accordion-report-widget-header/accordion-report-widget-header.component';
import {AccordionReportWidgetComponent} from './dashboard-accordion/accordion-widget/accordion-report-widget/accordion-report-widget.component';
import {DashboardReportDataGridComponent} from './dashboard-accordion/accordion-widget/accordion-report-widget/dashboard-report-datagrid/dashboard-report-data-grid.component';
import { DashboardWidgetSelectDialogComponent } from './dashboard-accordion/dashboard-widget-select-dialog/dashboard-widget-select-dialog.component';
import {FarmStatisticsFinishingComponent} from './dashboard-accordion/accordion-widget/graph-widget/finishing/farm-statistics-finishing.component';
import {FarmStatisticsFinishingBarChartComponent} from './dashboard-accordion/accordion-widget/graph-widget/finishing/farm-statistics-finishing-bar-chart/farm-statistics-finishing-bar-chart.component';
import {FarmStatisticsBeefComponent} from './dashboard-accordion/accordion-widget/graph-widget/farm-statistics-beef/farm-statistics-beef.component';
import {FarmStatisticsDairyComponent} from './dashboard-accordion/accordion-widget/graph-widget/farm-statistics-dairy/farm-statistics-dairy.component';
import {AccordionGraphWidgetHeaderComponent} from './dashboard-accordion/accordion-widget/graph-widget/graph-widget-header/accordion-graph-widget-header.component';
import {FarmStatisticsBeefHeaderComponent} from './dashboard-accordion/accordion-widget/graph-widget/farm-statistics-beef/farm-statistics-beef-header/farm-statistics-beef-header.component';
import {FarmStatisticsDairyHeaderComponent} from './dashboard-accordion/accordion-widget/graph-widget/farm-statistics-dairy/farm-statistics-dairy-header/farm-statistics-dairy-header.component';
import {FarmStatisticsFinishingHeaderComponent} from './dashboard-accordion/accordion-widget/graph-widget/finishing/farm-statistics-finishing-header/farm-statistics-finishing-header.component';
import {AccordionGraphWidgetComponent} from './dashboard-accordion/accordion-widget/graph-widget/accordion-graph-widget.component';
import {FarmStatisticsBeefPieChartComponent} from './dashboard-accordion/accordion-widget/graph-widget/farm-statistics-beef/farm-statistics-beef-pie-chart/farm-statistics-beef-pie-chart.component';
import { AccordionReportSizableCellComponent } from './dashboard-accordion/accordion-widget/accordion-report-widget/accordion-report-sizable-cell/accordion-report-sizable-cell.component';
import {MaintenanceCallsItemComponent} from "./maintenance-calls/maintenance-calls-item/maintenance-calls-item.component";

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardAccordionComponent,
    FarmStatisticsBeefComponent,
    FarmStatisticsFinishingComponent,
    FarmStatisticsFinishingBarChartComponent,
    FarmStatisticsDairyComponent,
    DashboardReportDataGridComponent,
    DashboardAccordionComponent,
    MaintenanceCallsComponent,
    MaintenanceCallsItemComponent,
    UpdateSoftwareModalComponent,
    DashboardKpiAccordionComponent,
    SummaryPieChartComponent,
    DashboardKpiDetailsComponent,
    DashboardKpiGraphNoDataComponent,
    DashboardKpiGraphGradientComponent,
    DashboardKpiGraphBarComponent,
    DashboardKpiGraphEnhancedBarComponent,
    DashboardKpiDescriptionComponent,
    DashboardKpiSelectDialogComponent,
    AccordionReportWidgetActionsComponent,
    AccordionReportWidgetHeaderComponent,
    AccordionGraphWidgetHeaderComponent,
    FarmStatisticsBeefHeaderComponent,
    FarmStatisticsDairyHeaderComponent,
    FarmStatisticsFinishingHeaderComponent,
    AccordionGraphWidgetComponent,
    AccordionReportWidgetComponent,
    FarmStatisticsBeefComponent,
    FarmStatisticsDairyComponent,
    DashboardReportDataGridComponent,
    FarmStatisticsBeefPieChartComponent,
    DashboardWidgetSelectDialogComponent,
    AccordionReportSizableCellComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    NgxDatatableModule,
    ShSharedModule,
    AccumulationChartModule,
    LoggedInLayoutModule
  ]
})
export class DashboardModule { }
