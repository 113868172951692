import { Component, ChangeDetectionStrategy } from '@angular/core';
import {BatchEventErrorsDialogService} from './batch-event-errors-dialog.service';

@Component({
  selector: 'batch-event-errors-dialog',
  styleUrls: ['./batch-event-errors-dialog.component.scss'],
  templateUrl: './batch-event-errors-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatchEventErrorsDialogComponent {
  public showPopup$ = this.batchEventErrorsPopupService.showPopup$;

  constructor(
    private readonly batchEventErrorsPopupService: BatchEventErrorsDialogService,
  ) {}

  public handleClose(): void {
    this.batchEventErrorsPopupService.hide();
  }
}
