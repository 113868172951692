import {Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DistressSensitivityValueType, IHealthSettings} from '../../../../../../services/manage/model/health-settings';
import {ManageSettingsService} from '../../../../../../services/manage/manage-settings.service';
import {ActivatedRoute} from '@angular/router';
import {EditBarService} from '../../../../../../services/edit-bar/edit-bar.service';
import {UserPermissionsService} from '../../../../../../services/auth/user-permissions.service';
import * as _ from 'lodash';
import {ManageSettingsScreenType} from '../../../model/manage-settings.model';
import {IResponseBody, IResult} from '../../../../../../services/manage/model/response.model';
import {HttpResponseResult} from '../../../../../../services/rest-api/rest-api.service';
import {ConfigService} from '../../../../../../services/config/config.service';

const sensitivityLevelsArray = Object.keys(DistressSensitivityValueType);

export interface IManageSettingsHealthRoute {
  section: ManageSettingsHealthSection;
}

export enum ManageSettingsHealthSection {
  healthReport = 'health-report',
  freshReport = 'fresh-report',
  distressSensitivity = 'distress-sensitivity',
  daysToWeaning = 'days-to-weaning'
}

@Component({
  selector: 'manage-settings-health-breeding',
  templateUrl: './manage-settings-health-breeding.component.html',
  styleUrls: ['./manage-settings-health-breeding.component.scss']
})
export class ManageSettingsHealthBreedingComponent implements OnInit {

  @ViewChild('healthReport')
  public healthReport: ElementRef;

  @ViewChild('freshReport')
  public freshReport: ElementRef;

  @ViewChild('distressSensitivity')
  public distressSensitivity: ElementRef;

  @ViewChild('daysToWeaning')
  public daysToWeaning: ElementRef;

  private masterHealthSettings: IHealthSettings;

  public model: IHealthSettings;

  public readonly editAreaName: string = 'health';

  private distressSensitivityValue: number;

  @Input()
  private currentSettingsScreenType: ManageSettingsScreenType;

  public get canDecreaseHealthIndexThresholdValue() {
    if (!this.model?.healthIndexThreshold?.value || !this.model?.severeHealthIndexThreshold?.value) {
      return false;
    }
    return this.model.healthIndexThreshold.value > this.model.severeHealthIndexThreshold.value;
  }

  public get canIncreaseSevereHealthIndexThresholdValue() {
    if (!this.model?.healthIndexThreshold?.value || !this.model?.severeHealthIndexThreshold?.value) {
      return false;
    }
    return this.model.healthIndexThreshold.value > this.model.severeHealthIndexThreshold.value;
  }

  constructor(private manageSettingsService: ManageSettingsService,
              private activatedRoute: ActivatedRoute,
              private readonly editBarService: EditBarService,
              private readonly userPermissionsService: UserPermissionsService) {

  }

  public async ngOnInit() {
    const healthSettingsResponse = await this.manageSettingsService.getHealthSettings();
    if(healthSettingsResponse.status == 200 &&
      healthSettingsResponse.responseBody) {
      this.masterHealthSettings = _.cloneDeep(healthSettingsResponse.responseBody);
      this.model = _.cloneDeep(healthSettingsResponse.responseBody);
      this.distressSensitivityValue = sensitivityLevelsArray.indexOf(this.model.distressSensitivity.value);
      this.editBarService.registerOnSaveFunction(null);
      this.editBarService.registerOnResetFunction(null);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.ngAfterViewInit();
    }, 200);
  }

  public ngAfterViewInit() {
    switch (this.currentSettingsScreenType) {
      case ManageSettingsScreenType.healthReport: {
        this.scrollTo(this.healthReport);
        break;
      }
      case ManageSettingsScreenType.healthFreshReport: {
        this.scrollTo(this.freshReport);
        break;
      }
      case ManageSettingsScreenType.healthDistressSensitivity: {
        this.scrollTo(this.distressSensitivity);
        break;
      }
      case ManageSettingsScreenType.healthDaysToWeaning: {
        this.scrollTo(this.daysToWeaning);
        break;
      }
      default: {
        this.scrollTo(this.healthReport);
        break;
      }
    }
  }

  private scrollTo(element: ElementRef) {
    if (element) {
      element.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  public ngOnDestroy() {
    this.reset();
  }

  public reset() {
    this.editBarService.resetEditBar();
    this.model = _.cloneDeep(this.masterHealthSettings);
    if(this.model != null &&
      this.model.distressSensitivity != null) {
      this.distressSensitivityValue = sensitivityLevelsArray.indexOf(this.model.distressSensitivity.value);
    } else {
      this.distressSensitivityValue = null;
    }
    this.dirtyCheck();
    this.clearError();
  }

  public async save() {
    const response: HttpResponseResult<IResult> = await this.manageSettingsService.sendHealthSettings(this.model);
    if (response.status === 200) {
      this.editBarService.registerOnResetFunction(null);
      this.masterHealthSettings = _.cloneDeep(this.model);
      this.dirtyCheck();
      this.clearError();
    } else {
      this.manageSettingsService.editBarErrorMessage
        = this.getErrorMessage(response.errorResponseBody);
    }
  }

  public clearError(): void {
    this.manageSettingsService.editBarErrorMessage = null;
  }

  public updateValue(field: string, value: number) {
    this.model[field].value = value;
    this.dirtyCheck();
    this.clearError();
  }

  public changeDistressSensitivity(value: number) {
    this.distressSensitivityValue = value;
    this.model.distressSensitivity.value = <DistressSensitivityValueType>sensitivityLevelsArray[value];
    this.dirtyCheck();
  }

  private dirtyCheck() {
    const noChangesDetected = _.isEqual(this.masterHealthSettings, this.model);
    this.editBarService.registerOnSaveFunction(noChangesDetected ? null : this.save.bind(this), this.editAreaName);
    this.editBarService.registerOnResetFunction(noChangesDetected ? null : this.reset.bind(this));
  }

  public revertHealthIndexThreshold() {
    this.model.healthIndexThreshold.value = _.cloneDeep(this.model.healthIndexThreshold.defaultValue);
    this.dirtyCheck();
    this.clearError();
  }

  public revertSevereHealthIndexThreshold() {
    this.model.severeHealthIndexThreshold.value = _.cloneDeep(this.model.severeHealthIndexThreshold.defaultValue);
    this.dirtyCheck();
    this.clearError();
  }

  public isFieldEditable() {
    return !this.userPermissionsService.hasUserPermissionsForEditManageSettings();
  }

  public get hasPermissionsToExtendedHealthView() {
    return this.userPermissionsService.isApplicationPlanSupportsExtendedViewForHealth;
  }

  private getErrorMessage(error: IResponseBody<IResult>) {
    if (error.result && error.result.key) {
      return 'MANAGE.SETTINGS.ERRORS.' + error.result.key;
    } else {
      return 'MANAGE.SETTINGS.ERRORS.UnknownError';
    }
  }
}
