import { Component, OnInit } from '@angular/core';
import {StartBackgroundEventDetails, StartFinishingEventMetadata} from '../../../../../services/animals/model/animal-events';
import {EventFormComponent} from '../event-form.component';
import {CustomEventWrapperCssClass} from '../event-form-errors/event-form-errors.component';

@Component({
  selector: 'start-finishing-event-form',
  templateUrl: './start-finishing-event-form.component.html',
  styleUrls: ['./start-finishing-event-form.component.scss']
})
export class StartFinishingEventFormComponent extends EventFormComponent<StartFinishingEventMetadata, StartBackgroundEventDetails> {

  public CustomEventWrapperCssClass = CustomEventWrapperCssClass;

  constructor() {
    super();
  }

}
