import {Component, SimpleChanges} from '@angular/core';
import {EventFormComponent, EventFormFieldName} from '../event-form.component';
import {
  AbortionSameLactationCowEventMetadata, CreateAbortionSameLactationEventDetails
} from '../../../../../services/animals/model/animal-events';
import {DropdownListItem} from '../../../../../common/components/dropdown-list/dropdown-list-model';
import {FormsDLIconsThemes} from "../../../../../common/components/dropdown-list/forms-dropdown-list/forms-dropdown-list.component";

@Component({
  selector: 'abortion-same-lactation-event-form',
  templateUrl: './abortion-same-lactation-event-form.component.html',
  styleUrls: ['./abortion-same-lactation-event-form.component.scss']
})
export class AbortionSameLactationEventFormComponent extends EventFormComponent<AbortionSameLactationCowEventMetadata, CreateAbortionSameLactationEventDetails> {

  public cowsGroupsSameLactation: DropdownListItem[] = [];

  public EventFormFieldName = EventFormFieldName;

  public formsDLIconsThemes = FormsDLIconsThemes;

  public get destinationGroup(): number | undefined {
    return this.editModel.destinationGroup;
  }

  public set destinationGroup(newValue: number | undefined) {
    this.editModel.destinationGroup = newValue;
    this.emitModelChanged();
  }

  protected onChanges(changes: SimpleChanges): void {
    if (changes.meta != null) {
      this.cowsGroupsSameLactation = this.meta.cowsGroupsSameLactation.map<
        DropdownListItem
        >((cg) => {
        return {
          value: cg.id,
          displayValue: cg.name,
          disabled: false,
          icon: null
        };
      });
    }
  }
}
