import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';
import {ClickPressEventsService} from '../../../services/click-press-events/click-press-events.service';
import {filter, tap} from 'rxjs/operators';

export class ChartSeriesSelectionState {
  public name: string;
  public isSelected: boolean;
  public isRemovable: boolean;
}

@Component({
  selector: 'chart-series-dropdown-list',
  templateUrl: './chart-series-dropdown-list.component.html',
  styleUrls: ['./chart-series-dropdown-list.component.scss']
})
export class ChartSeriesDropdownListComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public seriesSelections : ChartSeriesSelectionState[];

  @Input()
  public translationPrefix : string;

  @Output()
  public selectionApplied: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public restoreToDefaults: EventEmitter<void> = new EventEmitter();

  public isOpen: boolean = false;

  private seriesSelectionsInternal: ChartSeriesSelectionState[];

  private subscription: Subscription = new Subscription();

  constructor(private readonly clickPressEventsService: ClickPressEventsService) { }

  public ngOnInit() {
    this.subscription.add(this.clickPressEventsService.keydownEscape
      .pipe(
        filter(() => this.isOpen),
        tap(() => this.closeDropdown()),
      )
      .subscribe());
    this.subscription.add(this.clickPressEventsService.keyupEnter
      .pipe(
        filter(() => this.isOpen),
        tap(() => this.apply(false)),
      )
      .subscribe());
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.seriesSelections) {
      this.resetChanges();
    }
  }

  private resetChanges() {
    this.seriesSelectionsInternal = _.cloneDeep(this.seriesSelections);
  }

  public toggleDropdown() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.resetChanges();
    }
  }

  public closeDropdown() {
    this.isOpen = false;
  }

  public toggleSeriesItem(seriesItem: ChartSeriesSelectionState) {
    if(seriesItem.isRemovable) {
      seriesItem.isSelected = !seriesItem.isSelected;
    }
  }

  public apply(save: boolean) {
    this.isOpen = false;
    this.commitSelectionChanges();
    this.selectionApplied.emit(save);
  }

  private commitSelectionChanges() {
    for (let itemInternal of this.seriesSelectionsInternal) {
      const correspondingItem: ChartSeriesSelectionState = this.seriesSelections.find((item) => item.name == itemInternal.name);
      if (correspondingItem) {
        correspondingItem.isSelected = itemInternal.isSelected;
      }
    }
  }

  public onRestoreToDefaults() {
    this.isOpen = false;
    this.restoreToDefaults.emit();
  }
}
