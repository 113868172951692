<modal-dialog-template *ngIf="syncStatusResult"
                       class="sync-status-modal"
                       [isOpen]="syncStatusResult"
                       [modalWidth]="'80%'"
                       [modalHeight]="600">
  <div class="dialog-container">
    <div class="sync-status-wrapper">
      <div class="sync-status-header-upper">
        <div class="upper-info">
          <b>{{ 'SYSTEM.MANAGEMENT.GRID.' + selectedSyncStatusRow.SyncStatusSyncTypeCalculation | translate }}</b>
          <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <span>{{ 'SYSTEM.MANAGEMENT.GRID.' + selectedSyncStatusRow.SyncStatusStatusCalculation | translate }}</span>
          <span>:&nbsp;&nbsp;</span>
          <strong>{{ animalsFailed }} {{ 'SYSTEM.MANAGEMENT.GRID.ANIMALS_FAILED' | translate }}</strong>
          <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <span>{{ 'REPORTS.GRID.SYNC_STATUS_RECEIVE_TIME' | translate }}</span>
          <span>:&nbsp;&nbsp;</span>
          <strong>{{selectedSyncStatusRow.SyncStatusReceiveTimeCalculation | epochDateTime:DateTimeFormatEnum.DateTime }}</strong>
        </div>
      </div>
      <div class="sync-status-header-lower">
        <div class="lower-info">
          <span>{{ 'SYSTEM.MANAGEMENT.GRID.CORRELATION_ID' | translate }}</span>
          <span>:&nbsp;&nbsp;</span>
          <strong>{{ selectedSyncStatusRow.SyncStatusCorrelationIdCalculation }}</strong>
          <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <span>{{ 'SYSTEM.MANAGEMENT.GRID.START_TIME' | translate }}</span>
          <span>:&nbsp;&nbsp;</span>
          <strong>{{ selectedSyncStatusRow.SyncStatusProcessStartTimeCalculation | epochDateTime:DateTimeFormatEnum.DateTime }}</strong>
          <span>&nbsp;&nbsp;|&nbsp;&nbsp; Duration:&nbsp;&nbsp;</span>
          <strong>{{reportsService.minToMs(selectedSyncStatusRow.SyncStatusDurationCalculation)}}</strong>
        </div>
      </div>
      <div class="sync-status-content">
        <ng-template #syncStatusColumn let-column="column">
          <ng-container [ngSwitch]="column">
            <span *ngSwitchCase="'SyncErrorAnimalNumberCalculation'">{{'SYSTEM.MANAGEMENT.GRID.SYNC_STATUS.ANIMAL_NUMBER' | translate}}</span>
            <span *ngSwitchCase="'SyncErrorResultTypeCalculation'">{{'SYSTEM.MANAGEMENT.GRID.SYNC_STATUS.RESULT_TYPE' | translate}}</span>
            <span *ngSwitchCase="'SyncErrorCurrentValueCalculation'">{{'SYSTEM.MANAGEMENT.GRID.SYNC_STATUS.CURRENT_VALUE' | translate}}</span>
            <span *ngSwitchCase="'SyncErrorNewValueCalculation'">{{'SYSTEM.MANAGEMENT.GRID.SYNC_STATUS.NEW_VALUE' | translate}}</span>
            <span *ngSwitchCase="'SyncErrorExternalIdCalculation'">{{'SYSTEM.MANAGEMENT.GRID.SYNC_STATUS.EXTERNAL_ID' | translate}}</span>
          </ng-container>
        </ng-template>
        <ng-template #syncStatusCell let-column="column" let-row="row">
          <div class="sync-status-cell" [ngSwitch]="column">
            <div *ngSwitchCase="'SyncErrorAnimalNumberCalculation'">
              {{row[column]}}
            </div>
            <div class="result-type-cell" *ngSwitchCase="'SyncErrorResultTypeCalculation'">
              <div class="error-div" *ngIf="row.SyncErrorSeverityCalculation === 'Error'">
                <div class="icon icon-warnings"></div>
              </div>
              <div class="warning-div" *ngIf="row.SyncErrorSeverityCalculation === 'Warning'">
                <div class="icon icon-warnings"></div>
              </div>
              <div [attr.translation-id]="'SYSTEM.MANAGEMENT.GRID.' + row[column]">{{ 'SYSTEM.MANAGEMENT.GRID.' + row[column] | translate }}</div>
            </div>
            <div *ngSwitchCase="'SyncErrorCurrentValueCalculation'">
              {{getValueByType(row[column])}}
            </div>
            <div *ngSwitchCase="'SyncErrorNewValueCalculation'">
              {{getValueByType(row[column])}}
            </div>
            <div *ngSwitchCase="'SyncErrorExternalIdCalculation'">
              {{ row[column] ? row[column] : '-' }}
            </div>
            <div *ngSwitchDefault>
              {{row[column]}}
            </div>
          </div>
        </ng-template>
        <data-grid  #sortedGrid
                    *ngIf="syncStatusResult"
                    [viewContext]="this"
                    [sortedGridColumn]="syncStatusColumn"
                    [sortedGridCell]="syncStatusCell"
                    [headerHeight]="40"
                    [rowHeight]="40"
                    [sortedGridQuery]="syncStatusSortedGridQuery"
                    [totalRows]="syncStatusResult.length"
                    [columnsMappings]="columnsMappings"
                    [rowsData]="syncStatusResult"
                    (onSortedGridQueryChanged)="getSyncStatus()">
        </data-grid>
      </div>
    </div>
    <div class="corner"></div>
    <div class="dialog-close" (click)="onCloseModal()">
      <div class="icon icon-close"></div>
    </div>
  </div>
</modal-dialog-template>
