import {Component, OnInit} from '@angular/core';
import {RegistrationService} from '../../../../services/registration/registration.service';
import {IRegistrationResponse} from '../../../../services/registration/model/registration.model';
import {HttpResponseResult} from '../../../../services/rest-api/rest-api.service';
import {Router} from '@angular/router';
import {GoogleAnalyticsService} from '../../../../services/google-analytics/google-analytics.service';
import {FarmMode} from '../../../../services/config/model/server-config';
import {ConfigService} from '../../../../services/config/config.service';
import {AuthService, ITrialModeStatus} from '../../../../services/auth/auth.service';
import {SystemStateEnum} from '../../../../services/config/model/anonymous-auth-config';
import {ErrorDialogService} from "../../../../common/components/dialogs/error-dialog/error-dialog.service";

@Component({
  selector: 'initialize-registration',
  templateUrl: './initialize-registration.component.html',
  styleUrls: ['./initialize-registration.component.scss']
})
export class InitializeRegistrationComponent implements OnInit {

  public isLoading: boolean = false;

  public isError: string = null;

  constructor(public registrationService: RegistrationService,
              private router: Router,
              private googleAnalyticsService: GoogleAnalyticsService,
              private readonly errorDialogService: ErrorDialogService,
              public configService: ConfigService,
              private authService: AuthService) { }

  public async ngOnInit() {
    if (this.configService.anonymousAuthResult && this.configService.anonymousAuthResult.systemState === 'trial') {
      if (!this.authService.isPressedOnRegister) {
        await this.router.navigate(['/initialize-trial']);
        this.authService.isPressedOnRegister = false;
      }
    }
  }

  public async startRegistration() {
    this.isLoading = true;
    this.isError = null;
    let response: HttpResponseResult<IRegistrationResponse> = await this.registrationService.startRegistration();
    if ((response.status === 200 ||
    response.status === 201) &&
    response.responseBody.succeeded) {
      this.registrationService.registrationModel.farmMode = response.responseBody.farmMode;
      this.registrationService.registrationModel.operation = response.responseBody.operation;
      this.registrationService.isClickedOnRegister = true;
      this.registrationService.isFarmTopologyInit = false;
      this.authService.isPressedOnRegister = false;
      await this.router.navigate(['/farm-registration/account-details']);
    } else if (response.errorResponseBody && response.errorResponseBody.result && response.errorResponseBody.result.failures) {
      this.setStartRegistrationError(response.errorResponseBody.result.failures);
    }
    this.isLoading = false;
  }

  public setStartRegistrationError(failures: string[]): void {
    if (!failures[0]) {
      return;
    }
    switch (failures[0]) {
      case StartRegistrationError.SerialNumberNotValid:
      case StartRegistrationError.CouldNotConnectToCloud:
      case StartRegistrationError.FarmModeMissing:
        this.isError = failures[0];
        break;
      case StartRegistrationError.FarmModeConflict:
        this.errorDialogService.show('DIALOGS.REGISTRATION_ERROR.FARM_CONFLICT_ERROR_TITLE',
          [{ errorKey: 'FARM_CONFLICT_ERROR_TEXT', fieldName: ''}],
          'DIALOGS.REGISTRATION_ERROR');
        break;
      default:
        this.isError = null;
    }
  }

  public async startTrialMode() {
    // tslint:disable-next-line:no-any
    const request: any = {
      type: 'trialMode'
    };
    this.isError = null;
    // tslint:disable-next-line:no-any
    const response: any = await this.registrationService.postTrialMode(request);
    if (response.status === 201) {
      const farmMode: FarmMode = response.responseBody.farmMode;
      // call anonymous login to update the trial farm mode status.
      await this.authService.performAnonymousLogin();
      this.authService.isPressedOnRegister = false;
      this.googleAnalyticsService.send('Registration', 'start trial', farmMode);
      switch (farmMode) {
        case FarmMode.Dairy:
        case FarmMode.Beef: {
          // navigate to Trial Mode Screen
          const trialModeStatus: ITrialModeStatus = this.authService.getTrialModeStatus();
          if (!trialModeStatus) {
            this.authService.setIsStartedTrialModeForTheFirstTime(true);
          } else {
            this.authService.setIsStartedTrialModeForTheFirstTime(false);
          }
          await this.router.navigate(['/initialize-trial']);
          break;
        }
        case FarmMode.Unknown: {
          // navigate to Trial Mode Farm Mode Selection
          await this.router.navigate(['/trial-farm-mode-selection']);
          break;
        }
      }
    } else {
      // TODO: [DS] show error message
    }
  }

  public isTrialModeExpired() {
    if (this.configService.anonymousAuthResult) {
      return (!this.configService.anonymousAuthResult.trialMode.isTrial ||
        (this.configService.anonymousAuthResult.trialMode.isTrial && this.configService.anonymousAuthResult.trialMode.daysToExpiration > 0));
    } else {
      return true;
    }
  }
}

enum StartRegistrationError {
  SerialNumberNotValid = 'SerialNumberNotValid',
  CouldNotConnectToCloud = 'CouldNotConnectToCloud',
  FarmModeMissing = 'FarmModeMissing',
  FarmModeConflict = 'FarmModeConflict'
}
