<div class="groups-header">
  <group-editor #groupEditor
                (addGroupClicked)="toggleAddGroup()"
                [ngClass]="{'group-editor-open': groupEditor.isOpen}"></group-editor>
  <grid-summary *ngIf="groupsReport && !groupEditor.isOpen" [summaryTitle]="'MANAGE.TITLE.GROUPS'"
                [icon]="GridSummaryIcon.Group"
                [rowCount]="groupsReport.meta.rowTotalAfterFilter"
                [rowTotal]="groupsReport.meta.rowTotal"></grid-summary>
  <report-actions-dropdown *ngIf="groupsReport && !groupEditor.isOpen"
                                  [attr.sh-id]="'actions-dropdown-popup-wrapper'"
                                  [reportActions]="groupsReport?.meta.reportActions"
                                  [isActionsOnLeftSide]="true"
                                  [reportQuery]="groupsReportQuery"
                                  [reportContext]="this"
                                  [report]="groupsReport"></report-actions-dropdown>
</div>
<report-grid #groupsFilteredReportGrid
                      [attr.sh-id]='"groups-table-grid"'
                      [reportContext]="this"
                      [report]="groupsReport"
                      [reportQuery]="groupsReportQuery"
                      [rowHeight]="60"
                      (onReportQueryChanged)="onReportQueryChanged()"
                      (onReportGridValueCellClick)="onReportGridValueCellClick($event)"
                      saveDataWarningInject
                      [ngClass]="{ open: groupEditor.isOpen }">
</report-grid>
