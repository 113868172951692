import { Injectable } from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

export type EntityError = {
  key: string;
  fieldName: string;
};

export type EntityErrorResult = {
  succeeded: boolean;
  failures: EntityError[];
};

export type MultipleEntitiesErrorResult = {
  arrayFailures: EntityErrorResult[];
};

export type ErrorResult = EntityErrorResult | MultipleEntitiesErrorResult | HttpErrorResponse;

export type HttpErrorData = {
  result: ErrorResult;
};

@Injectable({
  providedIn: 'root'
})
export class SortingGateErrorsHandlerService {

  public handleHttpPromiseError(error: HttpErrorData): string {
    let message: string = '';

    if (this.isEntityErrorResult(error.result)) {
      message = `SORTING_GATE.SERVER_ERRORS.${error.result.failures[0].key}`;
    } else if (this.isMultipleEntitiesErrorResult(error.result)) {
      message = `SORTING_GATE.SERVER_ERRORS.${Object.values(error.result.arrayFailures)[0].failures[0].key}`;
    } else if (this.isHttpErrorResult(error.result)) {
      message = error.result.message;
    } else {
      message = 'Unknown error';
    }

    return message;
  }

  private isHttpErrorResult(httpError: ErrorResult): httpError is HttpErrorResponse {
    return (httpError as HttpErrorResponse).message !== undefined;
  }

  private isMultipleEntitiesErrorResult(httpError: ErrorResult): httpError is MultipleEntitiesErrorResult {
    return (httpError as MultipleEntitiesErrorResult).arrayFailures !== undefined;
  }

  private isEntityErrorResult(httpError: ErrorResult): httpError is EntityErrorResult {
    return !this.isHttpErrorResult(httpError) && !this.isMultipleEntitiesErrorResult(httpError);
  }

}
