import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {GroupBranchDetails} from '../../../../services/branches/model/branch.interface';
import {IReport, IReportIds, ReportDisplayType} from '../../../../services/reports/model/report.interface';
import {IFilterQuery, ReportQuery} from '../../../../services/reports/model/report-query.interface';
import {ReportRowSelectionState} from '../../../../services/reports/report-row-selection-state';
import {
  ReportGridComponent,
  ReportGridValueCellClickDetails
} from '../../../../common/components/reports/report-grid/report-grid.component';
import {ReportsService} from '../../../../services/reports/reports.service';
import {TranslationService} from '../../../../services/translations/translation.service';
import {OperationType} from '../../../../services/search/model/search.model';
import {HttpResponseResult} from '../../../../services/rest-api/rest-api.service';
import {GroupCardTabSection} from '../../group-card/group-card-model';
import {GridViewState} from '../../../../services/ui/view-state/grid-row/grid-view-state';
import {CardViewStateService} from '../../../../services/ui/view-state/card-view-state.service';

@Component({
  selector: 'branch-card-details',
  templateUrl: './branch-card-details.component.html',
  styleUrls: ['./branch-card-details.component.scss']
})
export class BranchCardDetailsComponent implements OnInit {

  public branchGroupsReport: IReport;

  public branchGroupQuery: ReportQuery = new ReportQuery();

  private gridViewState:GridViewState = new GridViewState();

  @ViewChild('branchGroupsReportGrid')
  private branchGroupsReportGrid: ReportGridComponent;

  @Input()
  public branchDetails: GroupBranchDetails;

  constructor(private readonly reportsService: ReportsService,
              private readonly translationService: TranslationService,
              private cardViewStateService: CardViewStateService) { }

  public async ngOnInit() {
    this.setDefaultReportQuery();
    let response = await this.reportsService.getBranchGroups(this.branchGroupQuery, this.branchDetails.id);
    if (response.status == 200) {
      this.branchGroupsReport = response.responseBody;
    }
  }

  public async onReportQueryChanged() {
    this.branchGroupQuery.filter.set('GroupBranchDatabaseIDCalculation',{ serializeToString: () => ` In ('${this.branchDetails.id}')`, isValid: true });
    this.branchGroupsReport = await this.getReportByQuery();
  }

  public async onReportGridValueCellClick(reportGridValueCellClickDetails: ReportGridValueCellClickDetails) {
    let operationType:OperationType = OperationType.BeefFinishing;
    let relatedGroupsReportQuery = this.branchGroupQuery.getIdProjectionReportQuery('GroupDatabaseIDCalculation');
    let relatedGroupsReportResult: HttpResponseResult<IReportIds>;
    relatedGroupsReportResult = await this.reportsService.getBranchGroupsIds(relatedGroupsReportQuery, this.branchDetails.id);
    if (relatedGroupsReportResult.status == 200) {
      let relatedGroups = relatedGroupsReportResult.responseBody.result.map(value => value['GroupDatabaseIDCalculation']);
      let selectedGroupId = reportGridValueCellClickDetails.row['GroupDatabaseIDCalculation'];
      this.cardViewStateService.openGroupCard(selectedGroupId,
        operationType,
        relatedGroups,
        ['GROUP.NAVIGATION.GROUP_IN','ANIMAL.DETAILS.INFO.BRANCH'],
        GroupCardTabSection.Details);
    }
  }

  private setDefaultReportQuery(){
    this.branchGroupQuery = new ReportQuery();
    this.branchGroupQuery.offset = 0;
    this.branchGroupQuery.limit = 30;
    this.branchGroupQuery.type = ReportDisplayType.full;
    this.branchGroupQuery.includeFilterMetaData = true;
  }

  public getRowSelectionState(): ReportRowSelectionState {
    return null;
  }

  public async getReportByQuery(): Promise<IReport> {
    let response = await this.reportsService.getBranchGroups(this.branchGroupQuery, this.branchDetails.id);
    if (response.status == 200) {
      return response.responseBody;
    } else {
      return null;
    }
  }

  public getGridViewState(): GridViewState {
    return this.gridViewState;
  }

  public getPrintIncludeDateInHeader(): boolean {
    return false;
  }

  public getPrintReportHeader(): string {
    if (this.branchDetails.groupBranchName.isLocalization) {
      return this.translationService.translate('REPORTS.GRID.STATUS.' + this.branchDetails.groupBranchName.value);
    } else {
      return this.branchDetails.groupBranchName.value;
    }

  }
}
