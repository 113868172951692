<regular-edit-dialog-template *ngIf="model"
                              [modalWidth]="364"
                              [isOpen]="isPopupOpen"
                              [enableRemoveButton]="false"
                              (onCloseClick)="cancelClick()"
                              (onLeftButtonClick)="cancelClick()"
                              (onRightButtonClick)="applyClick()"
                              (onEnterButtonClick)="applyClick()">
  <ng-container header-caption>
    {{'DIALOGS.ADHOC.TURN_LED_LIGHTING_ON_DIALOG.HEADER_TEXT' | translate}}
  </ng-container>
  <ng-container body>
    <dialog-scrollable-body>
      <ng-container content>
        <animal-ids [animalIds]="animalIds"></animal-ids>
        <div class="form-group form-group-radios">
          <div class="group-radio-buttons">
            <radio-button [isChecked]="isBlinkFastChecked"
                          [radioButtonSize]="'28px'"
                          [label]="labelFast"
                          [colorScheme]="RadioButtonColorTheme.Blue"
                          (change)="onSelectBlinkRate(BlinkRate.Fast)"></radio-button>
            <div class="radio-button-separator"></div>
            <radio-button [isChecked]="!isBlinkFastChecked"
                          [radioButtonSize]="'28px'"
                          [label]="labelSlow"
                          [colorScheme]="RadioButtonColorTheme.Blue"
                          (change)="onSelectBlinkRate(BlinkRate.Slow)"></radio-button>
          </div>
        </div>
        <div class="form-group fullWidth">
          <div class="duration-text" [attr.translation-id]="'DIALOGS.ADHOC.TURN_LED_LIGHTING_ON_DIALOG.Duration'">
            {{'DIALOGS.ADHOC.TURN_LED_LIGHTING_ON_DIALOG.Duration' | translate}}: <span class="black-text">{{ model.duration | duration:DurationInput.HoursMinutesText }}</span>
          </div>
          <duration-horizontal-scroll [currentValue]="model.duration"
                                      [interval]="10"
                                      [minText]="'10m'"
                                      [maxText]="model.taskMaxDurationText"
                                      [minValue]="minDuration"
                                      [maxValue]="model.taskMaxDuration"
                                      (onCurrentValueChange)="updateDuration($event)">
          </duration-horizontal-scroll>
        </div>
      </ng-container>
    </dialog-scrollable-body>
  </ng-container>
  <ng-container left-button>
    <span>{{ 'COMMON.BUTTON.CANCEL' | translate}}</span>
  </ng-container>
  <ng-container right-button>
    <span>{{ 'COMMON.BUTTON.APPLY' | translate}}</span>
  </ng-container>
</regular-edit-dialog-template>
