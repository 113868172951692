export enum ChartSetupProjection {
  resetsetup = 'resetsetup',
  getsetup = 'getsetup'
}

export interface IChartSetup<TEvents,TSeries> {
  events: TEvents,
  series: TSeries
}

export interface IChartSeriesSetupRecord {
  isRemovable: boolean,
  isSelected: boolean,
  isVisible?: boolean,
  isInLegend?: boolean,
  visibleRange?: IVisibleRange
}

export interface IVisibleRange {
  start: number,
  end: number
}
