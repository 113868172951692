import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'sizable-cell',
  templateUrl: './sizable-cell.component.html',
  styleUrls: ['./sizable-cell.component.scss']
})
export class SizableCellComponent implements OnInit {

  @ViewChild('cellContainer', {static: true})
  // tslint:disable-next-line:no-any
  public cellContainer: ElementRef<HTMLElement>;

  @ViewChild('cellContent', {static: true})
  // tslint:disable-next-line:no-any
  public cellContent: ElementRef<HTMLElement>;

  private static readonly FontScales : number[] = [1, 0.83, 0.68];

  public baseFontSize: number = 16;

  private _contentStartWidth: number;

  private currentScale: number = 1;

  constructor(private readonly changeDetector: ChangeDetectorRef) { }

  public ngOnInit() {
  }

  public get fontSize(): number {
    let newScale;
    for (let scale of SizableCellComponent.FontScales) {
      if (this.contentStartWidth * scale <= this.containerWidth) {
        newScale = scale;
        break;
      }
    }
    newScale = newScale || SizableCellComponent.FontScales[-1];
    if (newScale != this.currentScale) {
      this.currentScale = newScale;
      this.changeDetector.detectChanges();
    }

    return Math.round(this.baseFontSize * this.currentScale)
  }

  private get containerWidth(): number {
    return this.cellContainer.nativeElement ? this.cellContainer.nativeElement.getBoundingClientRect().width : null;
  }

  private get contentStartWidth(): number {
    if (!this._contentStartWidth && this.cellContent.nativeElement) {
      let contentBounds = this.cellContent.nativeElement.getBoundingClientRect();
      this._contentStartWidth = contentBounds.width;
    }

    return this._contentStartWidth;
  }
}
