export interface IDashboardDetailedKPI {
  name: string;
  order: number;
  category: Category;
  colorCode: ColorCode;
  graph: IKPIGraph;
  mainValue: IKPIValue;
  secondaryValue: IKPIValue;
}

export interface IDashboardAvailableKPI {
  name: string;
  order: number;
  category: Category;
  isSelected: boolean;
}

export interface IDashboardKPIUpdate {
  name: string;
  isSelected: boolean;
}

export interface IKPIValue {
  type: string;
  value: number;
}

export interface IKPIGraph {
  type: GraphType;
  sections: IKPISection[];
  colorPoints: IKPIColorPoint[];
}

export interface IKPISection {
  colorCode: ColorCode;
  isSectionLimitDisplayed: boolean;
  maxSectionLimit: number;
  minSectionLimit: number;
  sectionSize: string;
}

export interface IKPIColorPoint {
  position: string;
  colorCode: ColorCode;
}

export enum GraphType {
  Bar = 'Bar',
  EnhancedBar = 'EnhancedBar',
  Gradient = 'Gradient'
}

export type ColorCode = 'Positive' | 'Intermediate' | 'Negative';
export type Category = 'Reproduction' | 'Health' | 'Group';
