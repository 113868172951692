import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {AutoCompleteColorScheme, MatchingResultItem} from '../auto-complete-model';
import {EventKey} from "../../../../logged-in-layout/layout-header/search/search.component";
import {PopupTemplateComponent} from '../../popups/popup-template/popup-template.component';

@Component({
  selector: 'regular-auto-complete',
  templateUrl: './regular-auto-complete.component.html',
  styleUrls: ['./regular-auto-complete.component.scss']
})
export class RegularAutoCompleteComponent implements OnChanges, AfterViewInit {


  @Input()
  public disabled: boolean;

  @Input()
  public maxResults: number;

  @Input()
  public items: string[];

  @Input()
  public isFieldError: boolean = false;

  @Input()
  public placeholder: string;

  @Input()
  public maxInputLength: number = 30;

  @Input()
  public searchQuery: string;

  @Input()
  public shIdPostfix: string = '';

  @Input()
  public hasIcon: boolean = false;

  @Input()
  public hasDeleteIcon: boolean = false;

  @Input()
  public hasPermanentRemoveIcon: boolean = false;

  @Input()
  public selectOnEnter: boolean = false;

  @Input()
  public autoFocus: boolean = false;

  @Input()
  public hasRemoveButton: boolean = false;

  @Input()
  public isColorPlaceholderBlack: boolean = false;

  @Input()
  public isAllowUnmatchedSelection: boolean = false;

  @Input()
  public colorScheme: AutoCompleteColorScheme = AutoCompleteColorScheme.WhiteBlack;

  @Input()
  public caseInvariant: boolean = false;

  @Input()
  public isEnterHasDifferentFunctionality: boolean = false;

  @Output()
  public onEnterClicked = new EventEmitter<string>();

  @Output()
  public matchingItemChanged = new EventEmitter<string>();

  @Output()
  public searchQueryChanged = new EventEmitter<string>();

  @Output()
  public editingCompleted = new EventEmitter<string>();

  @Output()
  public matchingItemsFound = new EventEmitter<number>();

  @ViewChild('searchInput')
  public searchInput: ElementRef;

  @ViewChild(PopupTemplateComponent, {static: true})
  public popupTemplate: PopupTemplateComponent;

  public openResultsPopup: boolean;

  public matchingResults: MatchingResultItem[] = [];

  public lastMatchingItem: string;

  public AutoCompleteColorScheme = AutoCompleteColorScheme;

  public selectedIndex: number;

  private _isFoldsUp: boolean;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  public ngOnChanges(changes: SimpleChanges): void {
  }

  public ngAfterViewInit(): void {
    // this.changeDetectorRef.detectChanges();
  }

  public onSearchQueryChange(){
    this.searchQueryChanged.emit(this.searchQuery);
    this.matchingResults = [];
    let foundItem : boolean = false;
    if(this.searchQuery && this.searchQuery.length > 0 &&
       this.items != null) {
      for (let item of this.items) {
        let isStartsWith = item.startsWith(this.searchQuery);
        if(!isStartsWith && this.caseInvariant) {
          isStartsWith = item.toLowerCase().startsWith(this.searchQuery.toLowerCase());
        }
        if (isStartsWith) {
          let matchingResult = new MatchingResultItem();
          matchingResult.matchingPart = item.substr(0, this.searchQuery.length);
          matchingResult.endPart = item.substr(this.searchQuery.length);
          if (!Number(this.maxResults) || this.matchingResults.length < this.maxResults) {
            this.matchingResults.push(matchingResult);
          }
          let itemEqualsToSearchQuery = item == this.searchQuery;
          if(!itemEqualsToSearchQuery && this.caseInvariant) {
            itemEqualsToSearchQuery = item.toLowerCase() == this.searchQuery.toLowerCase();
          }
          if (itemEqualsToSearchQuery) {
            this.lastMatchingItem = item;
            foundItem = true;
            this.matchingItemChanged.emit(item);
          }
        }
      }
    }
    if(!foundItem){
      this.lastMatchingItem = null;
      this.matchingItemChanged.emit(null);
    }
    this.openResultsPopup = this.matchingResults.length > 0;
    if (!!this.searchQuery) {
      this.matchingItemsFound.emit(this.matchingResults.length);
    }
  }

  public selectItem(event:Event, value:string, emitDirectly?: boolean, isSelectedByEnterKey?: boolean){
    event.preventDefault();
    event.stopPropagation();
    this.searchQuery = value;
    this.onSearchQueryChange();
    this.openResultsPopup = false;
    if (!isSelectedByEnterKey) {
      this.editingCompleted.emit(emitDirectly ? value : this.lastMatchingItem);
    }
    this.resetSelectedIndex();
  }

  public onFocus(){
    if(this.matchingResults.length > 0){
      this.openResultsPopup = true;
    }
  }

  public isTextExceedsWidth(element, fullText: string) {
    if (element.offsetWidth < element.scrollWidth) {
      return fullText;
    } else {
      return '';
    }
  }

  public focusOnAutocomplete() {
    this.searchInput.nativeElement.focus();
  }

  public onRemoveSelection(event:Event) {
    event.preventDefault();
    event.stopPropagation();
    this.searchQuery = null;
    this.lastMatchingItem = null;
    this.matchingResults = [];
    this.openResultsPopup = false;
    this.matchingItemChanged.emit(null);
    this.searchInput.nativeElement.focus();
    this.searchQueryChanged.emit(null);
  }

  public onOutsideClick(): void {
    if (this.openResultsPopup) {
      this.openResultsPopup = false;
      this.editingCompleted.emit(this.lastMatchingItem);
    }
  }

  public calculateHeight() {
    if (this.maxResults > 0) {
      return `${(42.5 * this.maxResults)}px`;
    } else {
      return '';
    }
  }

  public clearValue(): void {
    this.searchQuery = null;
    this.onSearchQueryChange();
  }

  public onKeyUp(event: KeyboardEvent): void {
    if (event.key === EventKey.Enter) {
      event.preventDefault();
      event.stopPropagation();
      const selectedItem = this.selectedIndex !== null && this.selectedIndex > -1 && this.matchingResults
        && this.matchingResults[this.selectedIndex] && this.matchingResults[this.selectedIndex].value;
      if (this.isEnterHasDifferentFunctionality) {
        // if Enter key has different functionality than selection
        this.selectItem(event, selectedItem || this.lastMatchingItem, true, true);
        this.onEnterClicked.emit(selectedItem || this.lastMatchingItem);
      } else {
        // Enter key and mouse selection have the same functionality
        this.selectItem(event, selectedItem || this.lastMatchingItem, true);
      }
      return;
    }
    if (event.key === EventKey.ArrowDown) {
      this.incrementSelectedIndex();
      return;
    }
    if (event.key === EventKey.ArrowUp) {
      this.decrementSelectedIndex();
      return;
    }
    this.onSearchQueryChange();
  }

  private resetSelectedIndex(): void {
    if (this.matchingResults.length > 0) {
      this.selectedIndex = 0;
    } else {
      this.selectedIndex = null;
    }
  }

  private incrementSelectedIndex(): void {
    if (this.selectedIndex === 0) {
      this.selectedIndex = 1;
    } else {
      this.selectedIndex = this.selectedIndex ? this.selectedIndex + 1 : 0;
    }
    if (this.selectedIndex > this.matchingResults.length - 1) {
      this.selectedIndex = 0;
    }
  }

  private decrementSelectedIndex(): void {
    this.selectedIndex = this.selectedIndex ? this.selectedIndex - 1 : this.matchingResults.length - 1;
    if (this.selectedIndex < 0) {
      this.selectedIndex = this.matchingResults.length - 1;
    }
  }

  public get isFoldDirectionUp(): boolean {
    if (!!this.popupTemplate && this._isFoldsUp !== this.popupTemplate.isFoldsUp) {
      setTimeout(() => {
        this._isFoldsUp = this.popupTemplate.isFoldsUp;
        this.changeDetectorRef.detectChanges();
      });
    }
    return this._isFoldsUp;
  }
}
