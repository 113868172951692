<div class="include-in-report-dropdown-list-toggle" [ngClass]="{opened: isOpen}" [attr.sh-id]="'include_in_report_toggle'" (click)="toggleDropdown()">
  <div class="toggle-text" [attr.translation-id]="'REPORTS.HEADER.SETUP.TITLE'">{{ 'REPORTS.HEADER.SETUP.TITLE' | translate }}</div>
  <div class="dropdown-icon" [ngClass]="{ close: !isOpen, open: isOpen }"></div>
  <div class="line-shadow" *ngIf="isOpen"></div>
</div>

<div class="include-in-report-dropdown-popup" *ngIf="isOpen">
  <div class="popup-wrapper">
    <div class="header-section">
      <div class="restore-defaults-button" (click)="resetSetup()">
        <div class="restore-defaults-text" [attr.translation-id]="'REPORTS.HEADER.SETUP.RESTORE_TO_DEFAULT'">{{ 'REPORTS.HEADER.SETUP.RESTORE_TO_DEFAULT' | translate }}</div>
        <div class="restore-defaults-icon"></div>
      </div>
    </div>
    <div class="config-section">
      <div class="config-row" *ngFor="let animalsToInspectItem of animalsToInspectList; last as isLast;" (click)="toggleSetupItem(animalsToInspectItem)" [class.config-row--no-border]="isLast">
        <div class="config-icon" [ngClass]="{ selected: animalsToInspectItem?.value.isSelected, notSelected: !animalsToInspectItem?.value.isSelected, disabled: !canEdit }"></div>
        <div class="config-text" [attr.translation-id]="'REPORTS.HEADER.SETUP.' + animalsToInspectItem.key">{{ 'REPORTS.HEADER.SETUP.' + animalsToInspectItem.key | translate }}</div>
      </div>
    </div>
  </div>
  <corner-container [cornerColor]="CornerColor.White" [footerHeight]="50" [bodyBackground]="CornerContainerBodyBackground.White">
    <ng-container container-footer>
      <div class="buttons-section">
        <div class="close-button" (click)="closePopup()" [attr.sh-id]="'close'">
          <div class="text" [attr.translation-id]="'COMMON.BUTTON.CANCEL'">{{ 'COMMON.BUTTON.CANCEL' | translate }}</div>
        </div>
        <div class="seperator"></div>
        <div class="save-button" (click)="saveSetup(false)" [ngClass]="{disabled: !canEdit}">
          <div class="text" [attr.translation-id]="'COMMON.BUTTON.APPLY'">{{ 'COMMON.BUTTON.APPLY' | translate }}</div>
        </div>
      </div>
    </ng-container>
  </corner-container>
</div>
