<regular-edit-dialog-template  *ngIf="farmAlertConfigData"
                              [modalWidth]="332"
                              [modalHeight]="318"
                              [isOpen]="isOpen"
                              [enableRemoveButton]="false"
                              [rightButtonDisabled]="isInvalid"
                              (onCloseClick)="handleClose()"
                              (onLeftButtonClick)="handleClose()"
                              [shIdPostfix]="'alert_timing_' + farmAlertConfigData.key"
                              [showCloseButton]="false"
                              (onRightButtonClick)="handleApply()">
  <ng-container header-caption>
    <p class="notification-timing-dialog-header-caption">
      {{'MANAGE.SETTINGS.ALERTS.' + farmAlertConfigData.key | translate}}{{' '}}
      {{'MANAGE.SETTINGS.ALERTS.ALERT_TIMING' | translate}}
    </p>
  </ng-container>
  <ng-container body>
    <div class="dialog-body-container">
      <div class="form-group form-group-radios">
        <radio-button [isChecked]="isAlways"
                      [radioButtonSize]="'24px'"
                      [label]="labelAlways"
                      [colorScheme]="RadioButtonColorTheme.Blue"
                      [attr.sh-id]="'notification-timing-dialog-always'"
                      (change)="onFrequencySelect(AlertTimingFrequency.always)"></radio-button>
        <div class="radio-button-separator"></div>
        <radio-button [isChecked]="!isAlways"
                      [radioButtonSize]="'24px'"
                      [label]="labelByHours"
                      [colorScheme]="RadioButtonColorTheme.Blue"
                      [attr.sh-id]="'notification-timing-dialog-by-hours'"
                      (change)="onFrequencySelect(AlertTimingFrequency.byHours)"></radio-button>
      </div>
      <div class="form-group form-group-time-pickers">
        <div class="time-fields" *ngIf="!isAlways">
          <div class="section-field single-time-field">
            <div class="title-div" [attr.translation-id]="'MANAGE.SETTINGS.ALERTS.TIMING.START_TIME'">{{ 'MANAGE.SETTINGS.ALERTS.TIMING.START_TIME' | translate }}</div>
            <div class="time-picker-wrapper">
              <time-picker [shownRemovableIcon]="false"
                           [themeColor]="'#1999D5'"
                           [selectedEpoch]="farmAlertConfigData.value.startTime"
                           [shIdPostfix]="'start-time-picker-input'"></time-picker>
            </div>
          </div>
          <div class="section-field single-time-field">
            <div class="title-div" [attr.translation-id]="'MANAGE.SETTINGS.ALERTS.TIMING.END_TIME'">{{ 'MANAGE.SETTINGS.ALERTS.TIMING.END_TIME' | translate }}</div>
            <div class="time-picker-wrapper">
              <time-picker [shownRemovableIcon]="false"
                           [themeColor]="'#1999D5'"
                           [selectedEpoch]="farmAlertConfigData.value.endTime"
                           [shIdPostfix]="'end-time-picker-input'"></time-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container left-button>
    <span>{{ 'COMMON.BUTTON.CANCEL' | translate}}</span>
  </ng-container>
  <ng-container right-button>
    <span>{{ 'COMMON.BUTTON.APPLY' | translate}}</span>
  </ng-container>
</regular-edit-dialog-template>
