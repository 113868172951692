import {Component, ViewChild} from '@angular/core';
import {NotificationDialogTemplateComponent, NotificationDialogTheme} from '../../../../common/components/dialogs/notification-dialog-template/notification-dialog-template.component';
import {IDialog} from '../../../../services/dialogs/model/dialog.model';

@Component({
  selector: 'new-version-software-modal',
  templateUrl: './new-version-software-modal.component.html',
  styleUrls: ['./new-version-software-modal.component.scss']
})
export class NewVersionSoftwareModalComponent implements IDialog {
  
  @ViewChild('dialogRef', {static: true})
  public dialogRef: NotificationDialogTemplateComponent;
  
  public NotificationDialogTheme = NotificationDialogTheme;
  
  public showDialog() {
    this.dialogRef.isOpen = true;
  }
  
  public closeDialog() {
    this.dialogRef.isOpen = false;
  }
}
