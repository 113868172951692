import * as _ from 'lodash';
import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ManageSettingsService} from '../../../../../services/manage/manage-settings.service';
import {IReproductionSettings} from '../../../../../services/manage/model/reproduction-settings';
import {ActivatedRoute} from '@angular/router';
import {EditBarService} from '../../../../../services/edit-bar/edit-bar.service';
import {UserPermissionsService} from '../../../../../services/auth/user-permissions.service';
import {ManageSettingsScreenType} from '../../model/manage-settings.model';

@Component({
  selector: 'manage-settings-reproduction',
  templateUrl: './manage-settings-reproduction.component.html',
  styleUrls: ['./manage-settings-reproduction.component.scss']
})
export class ManageSettingsReproductionComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('voluntaryWaiting')
  public voluntaryWaiting: ElementRef;

  @ViewChild('pregnancyLength')
  public pregnancyLength: ElementRef;

  @ViewChild('pregnancyDaysToDryOff')
  public pregnancyDaysToDryOff: ElementRef;

  @Input()
  private currentSettingsScreenType: ManageSettingsScreenType;

  private masterReproductionSettings: IReproductionSettings;

  public model: IReproductionSettings;

  public readonly editAreaName: string = 'reproduction';

  constructor(private manageSettingsService: ManageSettingsService,
              private activatedRoute: ActivatedRoute,
              private readonly editBarService: EditBarService,
              private userPermissionsService: UserPermissionsService) { }

  public async ngOnInit() {
    const reproductionSettingsResponse = await this.manageSettingsService.getReproductionSettings();
    this.masterReproductionSettings = _.cloneDeep(reproductionSettingsResponse.responseBody);
    this.model = _.cloneDeep(reproductionSettingsResponse.responseBody);
    this.editBarService.registerOnSaveFunction(null);
    this.editBarService.registerOnResetFunction(null);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.ngAfterViewInit();
    }, 200);
  }

  public ngAfterViewInit() {
      switch (this.currentSettingsScreenType) {
        case ManageSettingsScreenType.reproductionVoluntaryWaitingPeriod: {
          this.scrollTo(this.voluntaryWaiting);
          break;
        }
        case ManageSettingsScreenType.reproductionPregnancyLength: {
          this.scrollTo(this.pregnancyLength);
          break;
        }
        case ManageSettingsScreenType.reproductionPregnancyDaysToDryOff: {
          this.scrollTo(this.pregnancyDaysToDryOff);
          break;
        }
        default: {
          this.scrollTo(this.voluntaryWaiting);
          break;
        }
      }
  }

  private scrollTo(element: ElementRef) {
    if (element) {
      element.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  public ngOnDestroy() {
    this.reset();
  }

  public reset() {
    this.model = _.cloneDeep(this.masterReproductionSettings);
    this.editBarService.resetEditBar();
    this.dirtyCheck();
  }

  public async save() {
    await this.manageSettingsService.sendReproductionSettings(this.model);
    this.editBarService.registerOnResetFunction(null);
    this.masterReproductionSettings = _.cloneDeep(this.model);
    this.dirtyCheck();
  }

  public updateValue(field: string, value: number) {
    this.model[field].value = value;
    this.dirtyCheck();
  }

  private dirtyCheck() {
    const noChangesDetected = _.isEqual(this.masterReproductionSettings, this.model);
    this.editBarService.registerOnSaveFunction(noChangesDetected ? null : this.save.bind(this), this.editAreaName);
    this.editBarService.registerOnResetFunction(noChangesDetected ? null : this.reset.bind(this));
  }

  public isFieldEditable() {
    return this.userPermissionsService && !this.userPermissionsService.hasUserPermissionsForEditManageSettings()
  }

}
