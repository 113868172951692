import {Inject, Injectable} from '@angular/core';
import {PrintHtmlService} from './print-html.service';
import {WINDOW} from '../../utils/window-utils';
import {IReport} from '../reports/model/report.interface';
import {DatePipe} from '@angular/common';
import {TranslationService} from '../translations/translation.service';
import {DateTimeFormatEnum, EpochDateTimePipe} from '../../common/pipes/epoch-date-time.pipe';
import {ReportsService} from '../reports/reports.service';
import * as moment from 'moment';
import {DurationInput, DurationPipe} from '../../common/pipes/duration.pipe';
import {ReportQuery} from '../reports/model/report-query.interface';
import {HttpResponseResult} from '../rest-api/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class PrintReportService {

  constructor(@Inject(WINDOW) private windoW: Window,
              private printHtmlService: PrintHtmlService,
              private translationService: TranslationService,
              private datePipe: DatePipe,
              private durationPipe: DurationPipe,
              private epochDateTimePipe: EpochDateTimePipe,
              private reportsService: ReportsService) { }

  public printReport(report: IReport, headerText: string, includeDate: boolean) {
    const document = this.windoW.document;
    const container = document.createElement("div");
    const titleContainer = document.createElement("div");

    titleContainer.textContent = this.translationService.translate(headerText);
    if(includeDate &&
       report.meta.reportTime) {
      titleContainer.textContent += ' - ' + this.epochDateTimePipe.transform(report.meta.reportTime, DateTimeFormatEnum.DateTime);
    }
    titleContainer.style.width = '100%';
    titleContainer.style.height = '50px';
    titleContainer.style.fontFamily = 'sans-serif';
    titleContainer.style.textAlign = 'center';
    container.appendChild(titleContainer);

    const table = document.createElement("table");
    table.style.width = '100%';
    //table.style.height = 'calc(100% - 50px)';
    table.style.margin = '0';
    table.style.borderCollapse = 'collapse';
    table.style.fontFamily = 'sans-serif';

    const headerRow = table.insertRow();

    report.columns.forEach(column => {
      if ((column.type !== 'HiddenRef' && column.type !== 'Hidden' && column.webMetaType !== 'Badge' && column.mobileMetaType !== 'Badge') ||
           column.key == 'DNBCalculation') {
        const headerCell = document.createElement('th');
        headerCell.style.width = column.width > 0 ? `${column.width}%` : `calc(100% / ${report.columns.length})`;
        headerCell.style.fontWeight = 'bold';
        headerCell.style.border = '1px solid black';
        headerCell.style.borderBottomStyle = 'double';
        headerCell.style.padding = '10px';
        headerCell.setAttribute('sh-id', column.key);

        const translatedName = column.name ?
          this.translationService.translate(`REPORTS.GRID.${column.name}`) :
          '';

        headerCell.textContent = translatedName;
        headerRow.appendChild(headerCell);
      }
    });

    report.rows.forEach(row => {
      const tableRow = table.insertRow();
      report.columns.forEach(column => {
        if ((column.type !== 'HiddenRef' && column.type !== 'Hidden' && column.webMetaType !== 'Badge' && column.mobileMetaType !== 'Badge') ||
             column.key == 'DNBCalculation') {
          const tableCell = document.createElement('td');
          tableCell.style.width = column.width > 0 ? `${column.width}%` : `calc(100% / ${report.columns.length})`;
          tableCell.style.border = '1px solid black';
          tableCell.style.padding = '10px';
          tableCell.setAttribute('sh-id', column.key);
          switch (column.type) {
            case 'SortingReason':
              tableCell.textContent = this.translationService.translate('SORTING_GATE.SORTING_REPORT.SORTING_REASONS.'+ row[column.key].mainReason);
              break;
            case 'AnimalsToInspectReasons':
              if(row[column.key] != null) {
                tableCell.textContent = this.translationService.translate('GROUP.REPORTS.' + row[column.key].mostImportantReport);
              }
              break;
            case 'DynamicLocalization': {
              if (row[column.key].isLocalization) {
                tableCell.textContent = this.translationService.translate('REPORTS.GRID.STATUS.' + row[column.key].value);
              } else {
                tableCell.textContent = row[column.key].value;
              }
              break;
            }
            case 'DateTime':
              switch (column.key) {
                case 'CullingDateCalculation':
                case 'TagTypeCalculation':
                case 'DistressStartDateAndTimeCalculation':
                case 'SyncStatusReceiveTimeCalculation':
                case 'SyncStatusProcessStartTimeCalculation':
                case 'SyncStatusProcessEndTimeCalculation':
                case 'LastUpdatedCalculation':
                  tableCell.textContent = this.epochDateTimePipe.transform(row[column.key], DateTimeFormatEnum.DateTime);
                  break;
                default:
                  tableCell.textContent = row[column.key];
                  break;
              }
              break;
            case 'Localization':
              if(column.key === 'MaintenanceCallsIssueIDCalculation') {
                tableCell.textContent = row[column.key] ? this.translationService.translate('SYSTEM.TAGS.GRID.STATUS.' + row[column.key]): '';
              } else {
                tableCell.textContent = row[column.key] ? this.translationService.translate('REPORTS.GRID.STATUS.' + row[column.key]): '';
              }
              break;
            case 'Integer':
              switch (column.key) {
                case 'TagLedUseCalculation':
                  tableCell.textContent = row[column.key] ? row[column.key] + ' h': '-';
                  break;
                case 'DistressDurationCalculation':
                  tableCell.textContent = this.durationPipe.transform(row[column.key], DurationInput.Minutes);
                  break;
                case 'SyncStatusDurationCalculation':
                  tableCell.textContent = this.reportsService.minToMs(row[column.key]);
                  break;
                default:
                  tableCell.textContent = row[column.key];
                  break;
              }
              break;
            case 'String':
              switch (column.key) {
                case 'SyncStatusResultsCalculation':
                  tableCell.textContent = this.reportsService.parseSyncStatusResults(row[column.key]);
                  break;
                case 'SyncStatusStatusCalculation':
                  tableCell.textContent = this.translationService.translate('SYSTEM.MANAGEMENT.GRID.' + row[column.key]);
                  break;
                case 'SortedCalculation':
                  tableCell.textContent = this.translationService.translate('SORTING_GATE.SORTING_REPORT.SORTED_CALCULATION.' + row[column.key]);
                  break;
                default:
                  tableCell.textContent = row[column.key];
                  break;
              }
              break;
            case 'Double':
              if(row[column.key] !== null) {
                switch (column.key) {
                  case 'PercentOfRuminatingCowsCalculation':
                  case 'PercentOfPantingCowsCalculation':
                    tableCell.textContent = row[column.key] + '%';
                    break;
                  default:
                    tableCell.textContent = row[column.key];
                }
              }
              break;
            default: {
              if(row[column.key] != null) {
                tableCell.textContent = row[column.key];
              }
              break;
            }
          }
          tableRow.appendChild(tableCell);
        }
      });
    });

    container.appendChild(table);

    this.printHtmlService.printHTML(container.outerHTML);
  }

  public async printAccordionWidgetReport(report: IReport, headerText: string, includeDate: boolean): Promise<void> {
    const reportId: string = report.meta.reportId + "";
    const fullReportQuery = (new ReportQuery()).getFullReportQuery();
    const fullReportResponse: HttpResponseResult<IReport> = await this.reportsService.getReport(reportId, fullReportQuery);
    if (fullReportResponse.responseBody) {
      this.printReport(fullReportResponse.responseBody, headerText, includeDate);
    } else {
      this.printReport(report, headerText, includeDate);
    }
  }

}
