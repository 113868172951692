import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Group, IDataGridViewContext} from '../../../services/model/common-model';
import {NotesService} from '../../../services/notes/notes.service';
import {DataGridQuery} from '../../../common/components/data-grid/model/data-grid-query';
import {HttpResponseResult} from '../../../services/rest-api/rest-api.service';
import {LoadingIconService} from '../../../services/loading-icon/loading-icon.service';
import {NoteRow, NotesResult} from '../../../services/notes/model/notes.model';
import {GridSummaryIcon} from 'src/app/common/components/grid-summary/grid-summary.component';
import {SearchEntry} from '../../../services/search/model/search.model';
import {SearchService} from '../../../services/search/search.service';
import {DataGridComponent} from '../../../common/components/data-grid/data-grid.component';
import {DataGridColumnMap} from '../../../common/components/data-grid/model/data-grid-column-map';
import {DateTimeFormatEnum} from 'src/app/common/pipes/epoch-date-time.pipe';
import {RowActionsPopupItem} from '../../../common/components/row-actions-popup/row-actions-popup.component';
import {IconCssClass} from '../../../services/reports/model/report.interface';
import {TranslationService} from '../../../services/translations/translation.service';
import {Failure} from '../../../services/registration/model/registration.model';
import {ErrorDialogService} from '../../../common/components/dialogs/error-dialog/error-dialog.service';
import {DisplayMode} from '../../../common/components/grid-summary/models/display.mode';
import {DescriptionCellEntity} from '../../../common/components/data-grid/description-cell-template/description-cell-entities/model/description-cell-entity.model';
import {FarmMode} from '../../../services/config/model/server-config';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {ConfigService} from '../../../services/config/config.service';
import {ApplicationBundleRoutingMode, RoutingService} from '../../../services/routing/routing.service';
import {UserPermissionsService} from '../../../services/auth/user-permissions.service';
import {GridViewState} from '../../../services/ui/view-state/grid-row/grid-view-state';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../common/components/calendar/model/epoch-state-model';
import {ChipsItem} from '../../../common/components/chips/chips-item.component';
import * as moment from 'moment';
import {EditNoteModel} from './note-editor/models/edit-note.model';
import {EditorMode} from './note-editor/models/editor.mode';

export enum NotesRowActions {
  Edit = 'Edit',
  Delete = 'Delete'
}

@Component({
  selector: 'manage-notes',
  templateUrl: './manage-notes.component.html',
  styleUrls: ['./manage-notes.component.scss']
})
export class ManageNotesComponent implements OnInit, IDataGridViewContext, OnDestroy {

  @ViewChild('notesGrid')
  public notesGrid: DataGridComponent;

  public notesGridQuery: DataGridQuery;

  public notesResult: NotesResult;

  public GridSummaryIcon = GridSummaryIcon;

  public DateTimeFormatEnum = DateTimeFormatEnum;

  public readonly columnsMappings: Map<string, DataGridColumnMap> = new Map();

  public groupsList: SearchEntry[] = [];

  public isDeleteDialogShown: boolean = false;

  public editDialogMode: EditorMode;

  public editedNote: EditNoteModel = new EditNoteModel();

  public applicationBundleRoutingMode: ApplicationBundleRoutingMode;

  private noteIdToDelete: number;

  private queryParamsSubscription: Subscription;

  public readonly rowActionsMap: Map<NoteRow, RowActionsPopupItem[]> = new Map<NoteRow, RowActionsPopupItem[]>();

  private gridViewState : GridViewState = new GridViewState();

  constructor(private readonly notesService: NotesService,
              private readonly loadingIconService: LoadingIconService,
              private readonly searchService: SearchService,
              private readonly route: ActivatedRoute,
              private readonly userPermissionsService: UserPermissionsService,
              private readonly routingService: RoutingService,
              private readonly configService: ConfigService,
              private readonly errorDialogService: ErrorDialogService,
              private readonly translationService: TranslationService) {
    this.columnsMappings.set('startDateTime',
      {
        enableSorting: false,
        flexGrow: 0.5
      });
    this.columnsMappings.set('name',
      {
        enableSorting: false,
        flexGrow: 1
      });
    this.columnsMappings.set('groups',
      {
        enableSorting: false,
        flexGrow: 1
      });
    this.columnsMappings.set('actions',
      {
        enableSorting: false,
        flexGrow: 0.1
      });
  }

  public async ngOnInit() {
    this.getApplicationBundleRoutingMode();
    this.setDefaultEventsSortedGridQuery();
    this.rowActionsMap.clear();
    await this.getNotes();
    if (!this.notesResult || this.notesResult.result.length == 0) {
      this.editDialogMode = EditorMode.create;
    }
  }

  private getApplicationBundleRoutingMode() {
    this.queryParamsSubscription = this.route.queryParams.subscribe(queryParams => {
      if (this.configService.serverConfig.farmMode === FarmMode.Dairy) {
        this.applicationBundleRoutingMode = null;
      } else {
        this.applicationBundleRoutingMode = this.routingService.getApplicationBundleRoutingMode(queryParams);
      }
    });
  }

  public onAddNoteClicked(editorMode: EditorMode|null) {
    this.editDialogMode = editorMode;
    setTimeout(() => {
      this.toggleExpandRow();
    });
  }

  public getGridViewState(): GridViewState {
    return this.gridViewState;
  }

  public async reloadDefault() {
    this.getNotes();
  }

  public async onNotesListChanged() {
    this.editDialogMode = null;
    this.editedNote = null;
    await this.reload();
  }

  public async reload() {
    this.notesGrid.reloadGrid();
  }

  public async getNotes(isLoadOnScroll?: boolean) {
    let notesResult: HttpResponseResult<NotesResult>;
    if (!isLoadOnScroll) {
      this.loadingIconService.show();
    }
    this.groupsList = await this.searchService.listOfAvailableGroups();
    if (this.applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing) {
      notesResult = await this.notesService.getFinishingNotes(this.notesGridQuery.page,
        this.notesGridQuery.limit);
    } else {
      notesResult = await this.notesService.getBreedingNotes(this.notesGridQuery.page,
        this.notesGridQuery.limit);
    }
    if(notesResult.responseBody && this.groupsList) {
      // if events exists in events list
      this.notesResult = notesResult.responseBody;
      this.notesResult.result.forEach(value => {
        this.setRowActions(value);
      });
      if (!isLoadOnScroll) {
        this.loadingIconService.hide();
      }
    } else {
      // if no events exists in events list
      this.groupsList = [];
      this.notesResult = {
        limit: 0,
        offset: 0,
        total: 0,
        result: []
      };
      if (!isLoadOnScroll) {
        this.loadingIconService.hide();
      }
    }
  }

  public toggleExpandRow() {
    this.notesGrid.refreshRowsHeight();
  }

  public onRowActionsOpen() {
    this.gridViewState.clearExpandedRows();
    this.notesGrid.refreshRowsHeight();
  }

  public async handleRowAction(rowAction: string, rowModel: NoteRow) {
    switch (<NotesRowActions>rowAction) {
      case NotesRowActions.Delete: {
        this.noteIdToDelete = rowModel.id;
        this.isDeleteDialogShown = true;
        break;
      }
      case NotesRowActions.Edit: {
        this.editedNote = new EditNoteModel();
        this.editedNote.id = rowModel.id;
        this.editedNote.noteName = rowModel.name;
        this.editedNote.date = new EpochStateModel(EpochStateModelMinMaxMode.Date, rowModel.startDateTime - (moment().utcOffset() * 60));
        this.editedNote.selectedGroups = rowModel.groups.map((group: Group) => {
          let groupItem: ChipsItem = new ChipsItem();
          groupItem.chipId = group.id;
          groupItem.chipName = group.name;
          return groupItem;
        });
        this.editDialogMode = EditorMode.edit;
        setTimeout( () => {
          this.toggleExpandRow();
        });
        break;
      }
    }
  }

  public async deleteNote() {
    this.isDeleteDialogShown = false;
    let response;
    if (this.applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing) {
      response = await this.notesService.deleteFinishingNote(this.noteIdToDelete);
    } else {
      response = await this.notesService.deleteBreedingNote(this.noteIdToDelete);
    }
    if (response.status === 204) {
      this.notesGrid.reloadGrid();
      this.notesService.getNotesMetadataSubject.next();
    } else {
      setTimeout(() => {
        let failures: Failure[] = [{errorKey: response.errorResponseBody.result.failures[0].key, fieldName: response.errorResponseBody.result.failures[0].fieldName }];
        this.errorDialogService.show('NOTES.DELETE_DIALOG.ERROR.FAILED_DELETE_NOTE',failures, 'NOTES.DELETE_DIALOG.ERROR', null);
      }, 200);
    }
    this.noteIdToDelete = null;
  }

  public closeDeleteConfirmationDialog() {
    this.isDeleteDialogShown = false;
  }

  private setDefaultEventsSortedGridQuery() {
    this.notesGridQuery = new DataGridQuery();
    this.notesGridQuery.offset = 0;
    this.notesGridQuery.limit = 100;
  }

  private setRowActions(rowModel: NoteRow) {
    let rowActions: RowActionsPopupItem[] = [];
    if (this.userPermissionsService.hasPermissionsForNotes()) {
      rowActions.push({
        action: NotesRowActions.Edit,
        displayValue: this.translationService.translate('MANAGE.SETTINGS.USERS.USERS_LIST.EDIT'),
        iconCssClass: IconCssClass.edit,
        shIdRowIdentifier: 'note_edit'
      });
      rowActions.push({
        action: NotesRowActions.Delete,
        displayValue: this.translationService.translate('MANAGE.SETTINGS.USERS.USERS_LIST.DELETE'),
        iconCssClass: IconCssClass.remove,
        shIdRowIdentifier: 'note_delete'
      });
    }
    this.rowActionsMap.set(rowModel, rowActions);
  }

  public getGroupDescriptionEntities(row: NoteRow): DescriptionCellEntity[] {
     return row.groups.map(group => ({
       entityId: group.id,
       entityName: group.name,
     }));
  }

  public get displayMode(): DisplayMode {
    return DisplayMode.hideOfPart;
  }

  public ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }
}
