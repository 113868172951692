import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import {TestingGuard} from './testing/services/route-guards/testing.guard';
import {SetupProgressComponent} from './setup-progress/setup-progress.component';
import {TestingRefreshBlankComponent} from './testing/testing-refresh-blank/testing-refresh-blank.component';

const routes: Routes = [
  {
    path: 'setup-progress',
    component: SetupProgressComponent
  },
  {
    path: '',
    loadChildren: () => import('./logged-in-layout/logged-in-layout.module').then(m => m.LoggedInLayoutModule)
  },
  {
    path: '',
    loadChildren: () => import('./logged-out-layout/logged-out-layout.module').then(m => m.LoggedOutLayoutModule)
  },
  {
    path: 'testing-refresh-blank',
    component: TestingRefreshBlankComponent,
    canActivate: [TestingGuard]
  },
  { path: '**', redirectTo: '/dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, enableTracing: false}),
            HttpClientModule],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
