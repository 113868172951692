import {Injectable} from '@angular/core';
import {ReportQuery} from '../reports/model/report-query.interface';
import {HttpResponseResult, HttpResponseResultNoBody, RestApiService} from '../rest-api/rest-api.service';
import {IReport} from '../reports/model/report.interface';
import {AuthService} from '../auth/auth.service';
import {
  AnimalDetails,
  IAnimalChartData,
  IAnimalInfo,
  IAnimalInfoMetadata,
  IAddFemaleRequest,
  IAddMaleRequest,
  IFemalesMetadata,
  IMaleMetadata, IAddFemaleFinishingRequest, IAddMaleFinishingRequest, IAnimalIdMeta, IErrors
} from './model/animal.model';
import {HttpParams} from '@angular/common/http';
import {AnimalEventRow, AnimalsEventsResult, ISire} from './model/animal-events';
import {ChartResolution} from '../model/charts/chart.interface';
import {Observable, Subject} from 'rxjs';
import {IFailuresItem} from '../system/model/system-account';

@Injectable({
  providedIn: 'root'
})
export class AnimalsService {

  private static readonly AnimalDetailsRoute = '/rest/api/animals/{animalId}/details';

  private static readonly FinishingAnimalDetailsRoute = '/rest/api/finishing/animals/{animalId}/details';

  private static readonly AnimalInfoMetaDataRoute = '/rest/api/animals/{animalId}/details?projection=animalInfoMetaData';

  private static readonly FinishingAnimalInfoMetaDataRoute = '/rest/api/finishing/animals/{animalId}/details?projection=animalInfoMetaData';

  private static readonly FemalesFinishingMetadataRoute = '/rest/api/finishing/animals?projection=cowsMetaData';

  private static readonly MalesFinishingMetadataRoute = '/rest/api/finishing/animals?projection=malesMetaData';

  private static readonly AnimalInfoRoute = '/rest/api/animals/{animalId}/details?projection=animalInfo';

  private static readonly AnimalChartDataRoute = '/rest/api/animals/{animalId}/graphs/{chartId}';

  private static readonly FinishingAnimalChartDataRoute = '/rest/api/finishing/animals/{animalId}/graphs/{chartId}';

  private static readonly BullRoute = '/rest/api/bulls/{bullId}';

  private static readonly MalesMetadataRoute = '/rest/api/animals?projection=malesMetaData';

  private static readonly FemalesMetadataRoute = '/rest/api/animals?projection=cowsMetaData';

  private static readonly AddMaleRoute = '/rest/api/animals/males';

  private static readonly AddMaleFinishingRoute = '/rest/api/finishing/animals/males';

  private static readonly AddFemaleRoute = '/rest/api/animals/cows';

  private static readonly AddFemaleFinishingRoute = '/rest/api/finishing/animals/cows';

  private static readonly AnimalsCanAssignTag = '/rest/api/animals?projection=canAssignTag';

  public isAddAnimalOpen: boolean = false;

  public isAddAnimalButtonShown: boolean = true;

  // tslint:disable-next-line:no-any
  public onCloseAddAnimalPopup: Subject<any> = new Subject<any>();

  // tslint:disable-next-line:no-any
  public onAnimalsListChanged: Subject<any> = new Subject<any>();

  constructor(private restApiService: RestApiService, private authService: AuthService) {

  }

  public getAnimalDetails(animalId: number): Promise<HttpResponseResult<AnimalDetails>> {
    let route = AnimalsService.AnimalDetailsRoute.replace('{animalId}', animalId.toString());
    return this.restApiService.sendGetMethod<AnimalDetails>(route, this.authService.currentUserAuthState.authDetails);
  }

  public getFinishingAnimalDetails(animalId: number): Promise<HttpResponseResult<AnimalDetails>> {
    let route = AnimalsService.FinishingAnimalDetailsRoute.replace('{animalId}', animalId.toString());
    return this.restApiService.sendGetMethod<AnimalDetails>(route, this.authService.currentUserAuthState.authDetails);
  }

  public updateAnimalDetails(animalId: number, animalDetails: AnimalDetails): Promise<HttpResponseResult<AnimalDetails>> {
    let route = AnimalsService.AnimalDetailsRoute.replace('{animalId}', animalId.toString());
    return this.restApiService.sendPutMethodAny<AnimalDetails>(route, this.authService.currentUserAuthState.authDetails, animalDetails);
  }

  public updateFinishingAnimalDetails(animalId: number, animalDetails: AnimalDetails): Promise<HttpResponseResult<AnimalDetails>> {
    let route = AnimalsService.FinishingAnimalDetailsRoute.replace('{animalId}', animalId.toString());
    return this.restApiService.sendPutMethodAny<AnimalDetails>(route, this.authService.currentUserAuthState.authDetails, animalDetails);
  }

  public getAnimalInfoMetaData(animalId: number): Promise<HttpResponseResult<IAnimalInfoMetadata>> {
    let route = AnimalsService.AnimalInfoMetaDataRoute.replace('{animalId}', animalId.toString());
    return this.restApiService.sendGetMethod<IAnimalInfoMetadata>(route, this.authService.currentUserAuthState.authDetails);
  }

  public getFinishingAnimalInfoMetaData(animalId: number): Promise<HttpResponseResult<IAnimalInfoMetadata>> {
    let route = AnimalsService.FinishingAnimalInfoMetaDataRoute.replace('{animalId}', animalId.toString());
    return this.restApiService.sendGetMethod<IAnimalInfoMetadata>(route, this.authService.currentUserAuthState.authDetails);
  }

  public getAnimalInfo(animalId: number): Promise<HttpResponseResult<IAnimalInfo>> {
    let route = AnimalsService.AnimalInfoRoute.replace('{animalId}', animalId.toString());
    return this.restApiService.sendGetMethod<IAnimalInfo>(route, this.authService.currentUserAuthState.authDetails);
  }

  public getAnimalToAssignTag(): Promise<HttpResponseResult<IAnimalIdMeta[]>> {
    return this.restApiService.sendGetMethod<IAnimalIdMeta[]>(AnimalsService.AnimalsCanAssignTag, this.authService.currentUserAuthState.authDetails);
  }

  public getAnimalChartData(animalId: number,
                            chartId: number,
                            resolution: ChartResolution,
                            showEvents: boolean,
                            series: string[],
                            isFinishing?: boolean): Promise<HttpResponseResult<IAnimalChartData>> {
    let route: string;
    if (isFinishing) {
      route = AnimalsService.FinishingAnimalChartDataRoute.replace('{animalId}', animalId.toString())
        .replace('{chartId}', chartId.toString());
    } else {
      route = AnimalsService.AnimalChartDataRoute.replace('{animalId}', animalId.toString())
        .replace('{chartId}', chartId.toString());
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.append('projection', 'flat');
    httpParams = httpParams.append('resolution', resolution);
    if (showEvents) {
      httpParams = httpParams.append('showEvents', 'true');
    }
    if (series) {
      httpParams = httpParams.append('series', series.join(','));
    }
    return this.restApiService.sendGetMethod<IAnimalChartData>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public deleteBull(bullId: number): Promise<HttpResponseResultNoBody> {
    let route = AnimalsService.BullRoute.replace('{bullId}', bullId.toString());
    return this.restApiService.sendDeleteMethod(route, this.authService.currentUserAuthState.authDetails);
  }

  public updateBull(sire: ISire): Promise<HttpResponseResult<ISire>> {
    let route = AnimalsService.BullRoute.replace('{bullId}', sire.id.toString());
    return this.restApiService.sendPutMethod(route, this.authService.currentUserAuthState.authDetails, sire);
  }

  public getMalesMetadata(): Promise<HttpResponseResult<IMaleMetadata>> {
    return this.restApiService.sendGetMethod<IMaleMetadata>(AnimalsService.MalesMetadataRoute, this.authService.currentUserAuthState.authDetails);
  }

  public getFinishingMalesMetadata(): Promise<HttpResponseResult<IMaleMetadata>> {
    return this.restApiService.sendGetMethod<IMaleMetadata>(AnimalsService.MalesFinishingMetadataRoute, this.authService.currentUserAuthState.authDetails);
  }

  public getFemalesMetadata(): Promise<HttpResponseResult<IFemalesMetadata>> {
    return this.restApiService.sendGetMethod<IFemalesMetadata>(AnimalsService.FemalesMetadataRoute, this.authService.currentUserAuthState.authDetails);
  }

  public getFinishingFemalesMetadata(): Promise<HttpResponseResult<IFemalesMetadata>> {
    return this.restApiService.sendGetMethod<IFemalesMetadata>(AnimalsService.FemalesFinishingMetadataRoute, this.authService.currentUserAuthState.authDetails);
  }

  // tslint:disable-next-line:no-any
  public addMale(request: IAddMaleRequest): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPostMethod(AnimalsService.AddMaleRoute, this.authService.currentUserAuthState.authDetails, request);
  }

  public addMaleFinishing(request: IAddMaleFinishingRequest): Promise<HttpResponseResult<IAnimalResultModel>> {
    return this.restApiService.sendPostMethod<IAnimalResultModel>(AnimalsService.AddMaleFinishingRoute, this.authService.currentUserAuthState.authDetails, request);
  }

  // tslint:disable-next-line:no-any
  public addFemale(request: IAddFemaleRequest): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPostMethod<IAddFemaleRequest>(AnimalsService.AddFemaleRoute, this.authService.currentUserAuthState.authDetails, request);
  }

  public addFemaleFinishing(request: IAddFemaleFinishingRequest): Promise<HttpResponseResult<IAnimalResultModel>> {
    return this.restApiService.sendPostMethod<IAnimalResultModel>(AnimalsService.AddFemaleFinishingRoute, this.authService.currentUserAuthState.authDetails, request);
  }
  
  public hasErrorForField(errors: IErrors[], field:string) {
    return errors && errors.some((error : IErrors) => error.fieldName === field);
  }
  
}
export interface IAnimalResultModel {
  succeeded: boolean;
  failures: IFailuresItem[];
}
