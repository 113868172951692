import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {WINDOW} from '../../../../utils/window-utils';
import {AnimalsService} from '../../../../services/animals/animals.service';
import {GoogleAnalyticsService} from '../../../../services/google-analytics/google-analytics.service';
import {ApplicationBundleRoutingMode, RoutingService} from '../../../../services/routing/routing.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ConfigService} from '../../../../services/config/config.service';
import {FarmMode, SystemState, YoungStockApplicationPlan} from '../../../../services/config/model/server-config';
import {AnimalSex} from '../../../../services/animals/model/animal.model';

@Component({
  selector: 'add-animal',
  templateUrl: './add-animal.component.html',
  styleUrls: ['./add-animal.component.scss']
})
export class AddAnimalComponent implements OnInit, OnDestroy {

  public addAnimalSex: AnimalSex = AnimalSex.Female;

  public isAddAnimalChooserShown: boolean = false;

  public applicationBundleRoutingMode: ApplicationBundleRoutingMode;

  private queryParamsSubscription: Subscription;

  public animalSex = AnimalSex;

  public ApplicationBundleRoutingMode = ApplicationBundleRoutingMode;

  constructor(@Inject(WINDOW) private windoW: Window,
              private route: ActivatedRoute,
              private routingService: RoutingService,
              public animalsService: AnimalsService,
              public configService: ConfigService,
              private googleAnalyticsService: GoogleAnalyticsService) {

  }

  public ngOnInit() {
    this.queryParamsSubscription = this.route.queryParams.subscribe(queryParams => {
      if (this.configService.serverConfig.farmMode === FarmMode.Dairy) {
        this.applicationBundleRoutingMode = null;
      } else {
        this.applicationBundleRoutingMode = this.routingService.getApplicationBundleRoutingMode(queryParams);
      }
    });
    this.animalsService.onCloseAddAnimalPopup.subscribe(() => {
      this.animalsService.isAddAnimalOpen = false;
      this.isAddAnimalChooserShown = false;
      this.windowResizeMade();
    });
    this.animalsService.isAddAnimalOpen = false;
    this.isAddAnimalChooserShown = false;
  }

  public ngOnDestroy(): void {
    this.queryParamsSubscription.unsubscribe();
  }

  public toggleAddAnimal() {
    if (this.animalsService.isAddAnimalOpen) {
      this.animalsService.isAddAnimalOpen = false;
      this.isAddAnimalChooserShown = false;
      this.windowResizeMade();
    } else {
      this.googleAnalyticsService.send("Manage", "Open", "Add Animal");
      if(this.configService.serverConfig.farmMode === FarmMode.Beef ||
        (this.configService.serverConfig.farmMode === FarmMode.Dairy &&
        (this.configService.serverConfig.bizModeParameters.systemState == SystemState.Trial ||
         this.configService.serverConfig.bizModeParameters.youngStockApplicationBundle !== YoungStockApplicationPlan.None))) {
        this.isAddAnimalChooserShown = !this.isAddAnimalChooserShown;
      } else {
        this.addFemale();
      }
    }
  }

  public isBreedingOrDairyShown() {
    return (this.applicationBundleRoutingMode === ApplicationBundleRoutingMode.Breeding || this.applicationBundleRoutingMode == null);
  }

  public isFinishingShown() {
    return (this.applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing);
  }

  public addFemale() {
    this.addAnimalSex = AnimalSex.Female;
    this.isAddAnimalChooserShown = false;
    this.animalsService.isAddAnimalOpen = true;
    this.windowResizeMade();
  }

  public addMale() {
    this.addAnimalSex = AnimalSex.Male;
    this.isAddAnimalChooserShown = false;
    this.animalsService.isAddAnimalOpen = true;
    this.windowResizeMade();
  }

  private windowResizeMade() {
    setTimeout(() => {
      this.windoW.dispatchEvent(new Event('resize'));
    });
  }
}
