import {Component, Input, OnInit} from '@angular/core';
import {PrintReportService} from '../../../../../../services/export/print-report.service';
import {Router} from '@angular/router';
import {GoogleAnalyticsService} from '../../../../../../services/google-analytics/google-analytics.service';
import {IReport} from '../../../../../../services/reports/model/report.interface';
import {FarmMode} from '../../../../../../services/config/model/server-config';
import {ConfigService} from '../../../../../../services/config/config.service';

@Component({
  selector: 'accordion-report-widget-actions',
  templateUrl: './accordion-report-widget-actions.component.html',
  styleUrls: ['./accordion-report-widget-actions.component.scss']
})
export class AccordionReportWidgetActionsComponent implements OnInit {

  @Input()
  public report: IReport;

  public farmMode: FarmMode;

  constructor(private readonly configService: ConfigService,
              private readonly printReportService: PrintReportService,
              private readonly router: Router,
              private readonly googleAnalyticsService: GoogleAnalyticsService) { }

  public ngOnInit() {
    this.farmMode = this.configService.serverConfig.farmMode;
  }

  public async printReport() {
    this.googleAnalyticsService.send('dashboard', 'accordion', 'Print report - ' + this.report.meta.reportName);
    await this.printReportService.printAccordionWidgetReport(this.report, 'DASHBOARD.ACCORDION.' + this.report.meta.reportName, false);
  }

  public navigateToReport() {
    this.googleAnalyticsService.send('dashboard', 'accordion', 'Navigate report - ' +  this.report.meta.reportName);
    this.router.navigate(['/reports/' + this.report.meta.reportId]);
  }
}
