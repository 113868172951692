import {Component, Input, OnInit} from '@angular/core';
import {IGroupDetails} from '../../../../services/groups/model/group.interface';
import {ROW_ACTION_TRANSITIONS} from '../../../../common/animations/animations';
import {OperationType} from '../../../../services/search/model/search.model';

@Component({
  selector: 'group-card-details',
  templateUrl: './group-card-details.component.html',
  styleUrls: ['./group-card-details.component.scss'],
  animations: [ROW_ACTION_TRANSITIONS]
})
export class GroupCardDetailsComponent implements OnInit {

  @Input()
  public groupDetails: IGroupDetails;

  @Input()
  public operationType: OperationType;

  public OperationType = OperationType;

  public ngOnInit() {}

  public isBreedingGroupCardShown(operationType: OperationType): boolean {
    return operationType === OperationType.BeefBreeding || operationType === OperationType.Dairy;
  }

  public isFinishingGroupCardShown(operationType: OperationType): boolean {
    return operationType === OperationType.BeefFinishing;
  }
}
