<div class="animal-events-header" saveDataWarningIgnore>
  <animal-card-create-event *ngIf="!isAnimalCulled"
                            [ngClass]="{'open' : isEventComposerVisible}"
                            [animalId]="animalId"
                            (addEventClicked)="onExpanderToggle($event)">
  </animal-card-create-event>
  <ng-container *ngIf="!isEventComposerVisible">
    <div class="animal-events-title" [attr.translation-id]="'ANIMAL.EVENTS.EVENTS_HISTORY'">{{ 'ANIMAL.EVENTS.EVENTS_HISTORY' | translate}}</div>
    <grid-actions-dropdown *ngIf="!isEventComposerVisible" [dataSource]="this"></grid-actions-dropdown>
  </ng-container>
</div>

<ng-template #animalEventsColumn let-column="column">
  <ng-container [ngSwitch]="column">
    <span *ngSwitchCase="'startDateTime'" [attr.translation-id]="'MANAGE.EVENTS.DATE'">{{'MANAGE.EVENTS.DATE' | translate}}</span>
    <span *ngSwitchCase="'type'" [attr.translation-id]="'MANAGE.EVENTS.EVENT_NAME'">{{'MANAGE.EVENTS.EVENT_NAME' | translate}}</span>
    <span *ngSwitchCase="'daysInLactation'" [attr.translation-id]="ageInDaysOrDaysInLactationColumnTranslation">{{ageInDaysOrDaysInLactationColumnTranslation | translate}}</span>
    <span *ngSwitchCase="'description'" [attr.translation-id]="'MANAGE.EVENTS.DESCRIPTION'">{{'MANAGE.EVENTS.DESCRIPTION' | translate}}</span>
  </ng-container>
</ng-template>

<ng-template #animalEventsCell let-column="column" let-row="row">
  <ng-container [ngSwitch]="column">
    <div *ngSwitchCase="'startDateTime'" class="event-cell" [ngClass]="getCellStylingClasses(row, column)">
      <span class="event-cell-content date-time-cell">{{row.startDateTime | epochDateTime:DateTimeFormatEnum.DateTime}}</span>
    </div>
    <div *ngSwitchCase="'type'" class="event-cell event-name-cell" [ngClass]="getCellStylingClasses(row, column)">
      <div class="event-cell-content">
        <div [ngClass]="getEventNameClass(row.type)"></div>
        <span>{{'ANIMAL.EVENTS.EVENT_TYPE.' + row.type | translate}}</span>
      </div>
    </div>
    <div *ngSwitchCase="'daysInLactation'"  class="event-cell" [ngClass]="getCellStylingClasses(row, column)">
        <span class="event-cell-content">{{ ageInDaysOrDaysInLactation(row) }}</span>
    </div>
    <div *ngSwitchCase="'description'" class="description-cell" [ngClass]="getCellStylingClasses(row, column)">
      <div class="description-text" [ngSwitch]="row.type">
        <calving-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.Calving"></calving-event-description>
        <system-health-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.SystemHealth"></system-health-event-description>
        <dnb-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.DoNotBreed"></dnb-event-description>
        <breeding-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.Breeding"></breeding-event-description>
        <system-heat-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.SystemHeat"></system-heat-event-description>
        <negative-pregnancy-check-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.NegativePregnancyCheck"></negative-pregnancy-check-event-description>
        <uncertain-pregnancy-check-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.UncertainPregnancyCheck"></uncertain-pregnancy-check-event-description>
        <positive-pregnancy-check-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.PositivePregnancyCheck"></positive-pregnancy-check-event-description>
        <assign-scr-tag-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.AssignScrTag"></assign-scr-tag-event-description>
        <assign-rfid-tag-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.AssignRfIdTag"></assign-rfid-tag-event-description>
        <change-scr-tag-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.ChangeScrTag"></change-scr-tag-event-description>
        <remove-scr-tag-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.RemoveScrTag"></remove-scr-tag-event-description>
        <change-rfid-tag-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.ChangeRfIdTag"></change-rfid-tag-event-description>
        <remove-rfid-tag-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.RemoveRfIdTag"></remove-rfid-tag-event-description>
        <culling-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.Culling"></culling-event-description>
        <change-group-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.ChangeGroup"></change-group-event-description>
        <herd-entry-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.HerdEntry"></herd-entry-event-description>
        <dryoff-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.DryOff"></dryoff-event-description>
        <abortion-new-lactation-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.AbortionNewLactation"></abortion-new-lactation-event-description>
        <abortion-same-lactation-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.AbortionSameLactation"></abortion-same-lactation-event-description>
        <distress-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.Distress"></distress-event-description>
        <unsort-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.Unsort"></unsort-event-description>
        <tag-sw-update-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.TagSwUpdate"></tag-sw-update-event-description>
        <weight-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.Weight"></weight-event-description>
        <general-event-description [row]="row" (onToggleExpanded)="toggleExpandRow(row)" *ngSwitchCase="EventTypeEnum.General"></general-event-description>
      </div>
    </div>
    <div *ngSwitchCase="'actions'" class="event-cell" [ngClass]="getCellStylingClasses(row, column)">
      <div class="event-cell-content">
        <row-actions-popup [rowActions]="rowActionsMap.get(row)"
                           (onPopupOpen)="onRowActionsOpen()"
                           (onRowActionClick)="handleRowAction($event.rowActionPopupItem.action, row)">
        </row-actions-popup>
      </div>
    </div>
    <div *ngSwitchDefault class="event-cell" [ngClass]="getCellStylingClasses(row, column)">
      <span class="event-cell-content">{{row[column]}}</span>
    </div>
  </ng-container>
</ng-template>

<data-grid  #animalEventsSortedGrid
            saveDataWarningIgnore
            [sortedGridColumn]="animalEventsColumn"
            [sortedGridCell]="animalEventsCell"
            [headerHeight]="40"
            [rowHeight]="40"
            [sortedGridQuery]="animalEventsSortedGridQuery"
            [totalRows]="totalRows"
            [columnsMappings]="columnsMappings"
            [rowsData]="rowsDataResult"
            [viewContext]="this"
            [isLoaderShifted]="true"
            (onSortedGridQueryChanged)="getEvents()">
</data-grid>
