import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DataGridComponent} from '../../../../../common/components/data-grid/data-grid.component';
import {IGroupAnimalDetails, IGroupAnimals, IGroupDetails} from '../../../../../services/groups/model/group.interface';
import {DataGridQuery, DataGridQuerySortDetails} from '../../../../../common/components/data-grid/model/data-grid-query';
import {DataGridColumnMap} from '../../../../../common/components/data-grid/model/data-grid-column-map';
import {RowActionsPopupItem} from '../../../../../common/components/row-actions-popup/row-actions-popup.component';
import {ITag} from '../../../../../services/tags/model/tags-model';
import {GroupCardDetailsAnimalRowActionType} from '../../group-card-model';
import {ConfigService} from '../../../../../services/config/config.service';
import {AnimalCardTabSection} from '../../../animal-card/animal-card-model';
import {OperationType} from '../../../../../services/search/model/search.model';
import {DialogActionsService} from '../../../../../services/dialogs/dialog-actions.service';
import {IDataGridViewContext} from '../../../../../services/model/common-model';
import {TagsService} from '../../../../../services/tags/tags.service';
import {CreateCullingEventDetails, EventActionDetails} from '../../../../../services/animals/model/animal-events';
import * as moment from 'moment';
import {HttpResponseResult} from '../../../../../services/rest-api/rest-api.service';
import {AnimalEventsService} from '../../../../../services/animals/animal-events.service';
import {IconCssClass} from '../../../../../services/reports/model/report.interface';
import {GroupsService} from '../../../../../services/groups/groups.service';
import {
  ExportedColumnDescription,
  IPrintExportDataSource
} from '../../../../../common/components/grid-actions-dropdown/grid-actions-dropdown.component';
import {TranslationService} from '../../../../../services/translations/translation.service';
import {BranchesService} from '../../../../../services/branches/branches.service';
import {ConfirmCullingAssignedTagDialogService} from '../../../../../common/components/dialogs/confirm-dialog/confirm-culling-assigned-tag-dialog/confirm-culling-assigned-tag-dialog.service';
import {GridViewState} from '../../../../../services/ui/view-state/grid-row/grid-view-state';
import {CardViewStateService} from '../../../../../services/ui/view-state/card-view-state.service';

@Component({
  selector: 'group-card-details-breeding',
  templateUrl: './group-card-details-breeding.component.html',
  styleUrls: ['./group-card-details-breeding.component.scss']
})
export class GroupCardDetailsBreedingComponent implements OnInit, OnChanges, IPrintExportDataSource, IDataGridViewContext {

  @ViewChild('groupCardAnimalsSortedGrid')
  private groupCardAnimalsSortedGrid: DataGridComponent;

  @ViewChild('printTable')
  private printTable: ElementRef;

  @Input()
  public operationType: OperationType;

  @Input()
  public groupDetails: IGroupDetails;

  public groupAnimals: IGroupAnimals;

  private animalsSortedGridQuery: DataGridQuery;

  private actionsSelectedRow : IGroupAnimalDetails;

  private readonly columnsMappings: Map<string, DataGridColumnMap> = new Map();

  private readonly rowActionsMap: Map<IGroupAnimalDetails, RowActionsPopupItem[]> = new Map<IGroupAnimalDetails, RowActionsPopupItem[]>();

  private scrUnassignedTags: ITag[];

  private gridViewState:GridViewState = new GridViewState();

  public GroupCardDetailsAnimalRowActionType = GroupCardDetailsAnimalRowActionType;

  constructor(private readonly groupsService:GroupsService,
              private readonly configService: ConfigService,
              private readonly branchesService: BranchesService,
              private readonly translationService: TranslationService,
              private readonly dialogActionsService: DialogActionsService,
              private readonly tagsService: TagsService,
              private readonly animalEventsService: AnimalEventsService,
              private readonly cardViewStateService: CardViewStateService,
              private readonly confirmCullingAssignedTagDialogService: ConfirmCullingAssignedTagDialogService) {
    this.setDefaultAnimalsSortedGridQuery();
    this.columnsMappings.set('animalName',
      {
        enableSorting: false,
        flexGrow: 1
      });
    this.columnsMappings.set('lactationStatus',
      {
        enableSorting: false,
        flexGrow: 1
      });
    this.columnsMappings.set('lactationNumber',
      {
        enableSorting: false,
        flexGrow: 1
      });
    this.columnsMappings.set('dimAge',
      {
        enableSorting: false,
        flexGrow: 1
      });
    this.columnsMappings.set('daysSinceLastHeat',
      {
        enableSorting: false,
        flexGrow: 1
      });
    this.columnsMappings.set('daysPregnant',
      {
        enableSorting: false,
        flexGrow: 1
      });
    this.columnsMappings.set('scrTagNumber',
      {
        enableSorting: false,
        flexGrow: 1
      });
    this.columnsMappings.set('actions',{
      enableSorting: false,
      flexGrow: 0.2
    })
  }

  public ngOnInit() {
  }

  public async ngOnChanges(changes: SimpleChanges) {

    let groupDetailsChanges = changes['groupDetails'];
    if(groupDetailsChanges == null ||
      groupDetailsChanges.previousValue == groupDetailsChanges.currentValue){
      return;
    }
    await this.getGroupAnimals();
  }

  private setDefaultAnimalsSortedGridQuery() {
    this.animalsSortedGridQuery = new DataGridQuery();
    this.animalsSortedGridQuery.offset = 0;
    this.animalsSortedGridQuery.limit = 100;
    this.animalsSortedGridQuery.sortDetails = new DataGridQuerySortDetails();
    this.animalsSortedGridQuery.sortDetails.column = 'insertedDateTime';
  }

  public onCellClick(groupAnimalDetails: IGroupAnimalDetails) {
    let relatedAnimals = this.groupAnimals.animals.map(value => value.id);
    let selectedAnimalId = groupAnimalDetails.id;
    this.cardViewStateService.openAnimalCard(selectedAnimalId,
      this.operationType,
      relatedAnimals,
      ['GROUP.NAVIGATION.COW_IN','GROUP.REPORTS.NAVIGATION.GROUP'],
      AnimalCardTabSection.Events);
  }

  private async handleRowAction(action:string, row:IGroupAnimalDetails) {
    switch (action) {
      case GroupCardDetailsAnimalRowActionType.changeGroup: {
        await this.changeGroup(row);
        break;
      }
      case GroupCardDetailsAnimalRowActionType.cullAnimal: {
        await this.cullAnimal(row);
        break;
      }
      case GroupCardDetailsAnimalRowActionType.assignTag: {
        if(this.scrUnassignedTags == null) {
          let response = await this.tagsService.getScrUnassignedTags();
          if(response.responseBody &&
            response.responseBody.scrTags &&
            response.responseBody.scrTags.unassignedTags) {
            this.scrUnassignedTags = response.responseBody.scrTags.unassignedTags;
          } else {
            this.scrUnassignedTags = [];
          }
        }
        await this.dialogActionsService.openAssignTagToAnimal(this, row.id, this.scrUnassignedTags);
        break;
      }
    }
  }

  private async changeGroup(row: IGroupAnimalDetails) {
    await this.dialogActionsService.openChangeGroupEventDialog(row.id, this);
  }

  private async cullAnimal(row: IGroupAnimalDetails) {
    let perform = true;
    if (row.scrTagNumber) {
      perform = await this.confirmCullingAssignedTagDialogService.showPopup();
    }
    if(perform) {
      let eventDetails = new CreateCullingEventDetails();
      let eventActionDetails = new EventActionDetails();
      eventActionDetails.startDateTime = moment().unix();
      // tslint:disable-next-line:no-any
      const createAnimalEventResponse: HttpResponseResult<any>
        = await this.animalEventsService.createAnimalEvent(row.id, eventActionDetails, eventDetails);
      await this.reloadDefault();
    }
  }

  public async reloadDefault(): Promise<void> {
    await this.getGroupAnimals();
    this.groupsService.groupGraphDetailsSubscription.next();
    this.groupsService.onGroupsListChanged.next();
  }

  private async getGroupAnimals() {
    this.groupAnimals = null;
    this.rowActionsMap.clear();
    if(this.groupDetails == null){
      return;
    }
    this.scrUnassignedTags = null;
    let groupAnimalsResult = await this.groupsService.getGroupAnimals(this.groupDetails.id,
      this.animalsSortedGridQuery.offset,
      this.animalsSortedGridQuery.limit,
      this.animalsSortedGridQuery.sortedColumn,
      false,
      'includeAverages');
    if (groupAnimalsResult.status == 200) {
      this.groupAnimals = groupAnimalsResult.responseBody;
      this.groupAnimals.animals.forEach(value => {
        this.rowActionsMap.set(value, this.getRowActions(value));
      });
    }
  }

  private getRowActions(row:IGroupAnimalDetails) : RowActionsPopupItem[] {
    let rowActions: RowActionsPopupItem[] = [];
    if(row.changeGroupEnabled && (this.configService.serverConfig.thirdPartyParameters == null ||
      this.configService.serverConfig.thirdPartyParameters.group == true)) {
      rowActions.push({action: GroupCardDetailsAnimalRowActionType.changeGroup,
        displayValue: 'GROUP.TABLE.ACTION.CHANGE_GROUP', iconCssClass: IconCssClass.changeGroup,
        shIdRowIdentifier: row.id.toString()});
    }
    if (row.culledAnimalEnabled && this.configService.serverConfig.thirdPartyParameters && this.configService.serverConfig.thirdPartyParameters.isSupportCulling  == true) {
      rowActions.push({action: GroupCardDetailsAnimalRowActionType.cullAnimal,
        displayValue: 'GROUP.TABLE.ACTION.CULL_ANIMAL', iconCssClass: IconCssClass.culling,
        shIdRowIdentifier: row.id.toString()});
    }
    return rowActions;
  }

  private openRowActions(row:IGroupAnimalDetails) {
    this.actionsSelectedRow = row;
  }

  private closeRowActions() {
    this.actionsSelectedRow = null;
  }

  public get exportedColumns() : ExportedColumnDescription[] {
    return [{
      name: 'animalName',
      widthCss: 'auto'
    },{
      name: 'lactationStatus',
      widthCss: 'auto'
    },{
      name: 'lactationNumber',
      widthCss: 'auto'
    },{
      name: 'dimAge',
      widthCss: 'auto'
    },{
      name: 'daysSinceLastHeat',
      widthCss: 'auto'
    },{
      name: 'daysPregnant',
      widthCss: 'auto'
    },{
      name: 'scrTagNumber',
      widthCss: 'auto'
    }];
  }

  public getGridViewState(): GridViewState {
    return this.gridViewState;
  }

  // tslint:disable-next-line:no-any
  public async getDataToExport(): Promise<any[]> {
    let printableGroupAnimalsResult = await this.groupsService.getGroupAnimals(this.groupDetails.id,
      0,
      0,
      this.animalsSortedGridQuery.sortedColumn,
      false,
      'includeAverages');
    if (printableGroupAnimalsResult.status == 200) {
      return  printableGroupAnimalsResult.responseBody.animals;
    }
    else {
      return null;
    }
  }

  public transformColumnToPresentable(column: string): string {
    if(column === 'animalName')
      return this.translationService.translate('GROUP.TABLE.HEADER.COW');
    else if(column === 'lactationStatus')
      return this.translationService.translate('GROUP.TABLE.HEADER.STATUS');
    else if(column === 'lactationNumber')
      return this.translationService.translate('GROUP.TABLE.HEADER.LACTATION');
    else if(column === 'dimAge')
      return this.translationService.translate('GROUP.TABLE.HEADER.DIM_AGE');
    else if(column === 'daysSinceLastHeat')
      return this.translationService.translate('GROUP.TABLE.HEADER.DAYS_SINCE') + ' ' + this.translationService.translate('GROUP.TABLE.HEADER.DAYS_SINCE_LAST_HEAT');
    else if(column === 'daysPregnant')
      return this.translationService.translate('GROUP.TABLE.HEADER.DAYS_PREGNANT');
    else if(column === 'scrTagNumber')
      return this.translationService.translate('GROUP.TABLE.HEADER.SCR_TAG');
    return column;
  }

  // tslint:disable-next-line:no-any
  public transformFieldToPresentable(column: string, row: any): string {
    if(column === 'lactationStatus') {
      return this.translationService.translate('ANIMAL.TIME_LINE.STATUS.' + row[column]);
    } else {
      return row[column];
    }
  }

  public onAdditionalActionClick(actionKey: string) {

  }

  public get exportTitle(): string {
    return 'Animals in group  ' + this.groupDetails.groupName;
  }

  public get filePartName(): string {
    return 'animalsInGroup';
  }
}
