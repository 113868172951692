<div class="breeding-window-values" *ngIf="isExpanded">
  <div class="val-1">26</div>
  <div class="val-2">23</div>
  <div class="val-3">8</div>
  <div class="val-4">1</div>
  <div class="val-5">-4</div>
</div>
<div class="breeding-window-indicator">
  <div class="bg-1"></div>
  <div class="bg-2"></div>
  <div class="bg-3"></div>
  <div class="bg-4"></div>
  <div class="marker" *ngIf="inseminationWindowCalculation != null" [ngStyle]="{left: calculateLeftOffset + '%'}"></div>
</div>
<div class="breeding-window-desc" *ngIf="isExpanded">{{inseminationWindowCalculation}} {{ 'TAGS_UPDATE.UPDATE_PROGRESS.HOURS' | translate }}</div>
