import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {OperationType} from '../../search/model/search.model';
import {AnimalCardTabSection, AnimalCardViewState} from '../../../logged-in-layout/card-popup/animal-card/animal-card-model';
import {CardViewSelectionState, CardViewState, CardViewType} from '../../../logged-in-layout/card-popup/card-popup-model';
import {GroupCardTabSection, GroupCardViewState} from '../../../logged-in-layout/card-popup/group-card/group-card-model';
import {BranchCardTabSection, BranchCardViewState} from '../../branches/model/branch.interface';
import {ManageSettingsService} from "../../manage/manage-settings.service";

@Injectable({
  providedIn: 'root'
})
export class CardViewStateService {

  public cardViewStateSubject: Subject<CardViewState> = new Subject();

  private lastActiveCardPopupViewState:CardViewState;

  constructor(private manageSettingsService: ManageSettingsService) {

  }

  public openAnimalCard(animalId:number,
                        operationType:OperationType,
                        relatedAnimalIds:number[],
                        translations:string[],
                        selectedTab: AnimalCardTabSection,
                        cardViewSelectionState?: CardViewSelectionState) {
    this.manageSettingsService.closeManageSettingsDialog();
    const cardPopupViewState = new CardViewState();
    cardPopupViewState.viewType = CardViewType.Animal;
    cardPopupViewState.animalId = animalId;
    cardPopupViewState.operationType = operationType;
    cardPopupViewState.relatedAnimalIds = relatedAnimalIds;
    if(relatedAnimalIds != null) {
      cardPopupViewState.selectedItemIndex = relatedAnimalIds.indexOf(animalId);
    }
    cardPopupViewState.translationIds = translations;
    cardPopupViewState.animalCardViewState = new AnimalCardViewState();
    cardPopupViewState.animalCardViewState.selectedTab = selectedTab;
    cardPopupViewState.groupCardViewState = new GroupCardViewState();
    cardPopupViewState.selectionState = cardViewSelectionState;
    cardPopupViewState.branchCardViewState = new BranchCardViewState();
    this.lastActiveCardPopupViewState = cardPopupViewState;
    this.cardViewStateSubject.next(cardPopupViewState);
  }

  public openGroupCard(groupId:number,
                       operationType:OperationType,
                       relatedGroupIds:number[],
                       translations:string[],
                       selectedTab: GroupCardTabSection,
                       cardViewSelectionState?: CardViewSelectionState) {
    this.manageSettingsService.closeManageSettingsDialog();
    const cardPopupViewState = new CardViewState();
    cardPopupViewState.viewType = CardViewType.Group;
    cardPopupViewState.groupId = groupId;
    cardPopupViewState.operationType = operationType;
    cardPopupViewState.relatedGroupIds = relatedGroupIds;
    if(relatedGroupIds != null) {
      cardPopupViewState.selectedItemIndex = relatedGroupIds.indexOf(groupId);
    }
    cardPopupViewState.translationIds = translations;
    cardPopupViewState.groupCardViewState = new GroupCardViewState();
    cardPopupViewState.groupCardViewState.selectedTab = selectedTab;
    cardPopupViewState.animalCardViewState = new AnimalCardViewState();
    cardPopupViewState.selectionState = cardViewSelectionState;
    cardPopupViewState.branchCardViewState = new BranchCardViewState();
    this.lastActiveCardPopupViewState = cardPopupViewState;
    this.cardViewStateSubject.next(cardPopupViewState);
  }

  public openBranchCard(branchId: number,
                        relatedBranchIds: number[],
                        translations: string[],
                        selectedTab: BranchCardTabSection) {
    this.manageSettingsService.closeManageSettingsDialog();
    const cardPopupViewState = new CardViewState();
    cardPopupViewState.viewType = CardViewType.Branch;
    cardPopupViewState.branchId = branchId;
    cardPopupViewState.operationType = OperationType.BeefFinishing;
    cardPopupViewState.relatedBranchIds = relatedBranchIds;
    if (relatedBranchIds != null) {
      cardPopupViewState.selectedItemIndex = relatedBranchIds.indexOf(branchId);
    }
    cardPopupViewState.translationIds = translations;
    cardPopupViewState.branchCardViewState = new BranchCardViewState();
    cardPopupViewState.branchCardViewState.selectedTab = selectedTab;
    cardPopupViewState.animalCardViewState = new AnimalCardViewState();
    cardPopupViewState.groupCardViewState = new GroupCardViewState();
    cardPopupViewState.selectionState = null;
    this.lastActiveCardPopupViewState = cardPopupViewState;
    this.cardViewStateSubject.next(cardPopupViewState);
  }

  public openCardViewState(cardViewState:CardViewState) {
    this.lastActiveCardPopupViewState = cardViewState;
    this.cardViewStateSubject.next(cardViewState)
  }

  public openLastActiveCardViewState() {
    this.cardViewStateSubject.next(this.lastActiveCardPopupViewState);
  }
}
