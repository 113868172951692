import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AnimalsService} from '../../services/animals/animals.service';
import {AnimalSex} from '../../services/animals/model/animal.model';
import {GroupsService} from '../../services/groups/groups.service';
import {CardViewState, CardViewType} from './card-popup-model';
import * as _ from 'lodash';
import {OperationType} from '../../services/search/model/search.model';
import {BranchesService} from '../../services/branches/branches.service';
import {HttpResponseResult} from '../../services/rest-api/rest-api.service';
import {GroupBranchDetails} from '../../services/branches/model/branch.interface';
import {CardViewStateService} from '../../services/ui/view-state/card-view-state.service';
import {Router} from "@angular/router";
import {SaveDataViewStateService} from "../../services/ui/view-state/save-data-view-state.service";

@Component({
  selector: 'card-popup',
  templateUrl: './card-popup.component.html',
  styleUrls: ['./card-popup.component.scss']
})
export class CardPopupComponent implements OnInit, OnDestroy {

  public cardViewState: CardViewState;

  public CardViewType = CardViewType;

  public AnimalSex = AnimalSex;

  private animalCardViewStateSubscription: Subscription;

  private routerSubscription: Subscription;

  constructor(private cardViewStateService:CardViewStateService,
              public animalsService:AnimalsService,
              private groupService:GroupsService,
              private branchesService: BranchesService,
              private readonly saveDataViewStateService: SaveDataViewStateService,
              private readonly router: Router) {

  }

  public ngOnInit() {
    this.animalCardViewStateSubscription = this.cardViewStateService.cardViewStateSubject.subscribe(value => this.onCardViewStateReceived(value));
    this.routerSubscription = this.router.events.subscribe((navigationEvent) => {
      this.closeCard();
    });
  }

  public ngOnDestroy(): void {
    this.animalCardViewStateSubscription.unsubscribe();
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  public get isAnimalCard(): boolean {
    if(this.cardViewState == null){
      return false;
    }
    if(this.cardViewState.animalCardViewState.animalInfo == null){
      return false;
    }
    return this.cardViewState.viewType == CardViewType.Animal;
  }

  public get isGroupCard(): boolean {
    if(this.cardViewState == null){
      return false;
    }
    if(this.cardViewState.groupCardViewState.groupDetails == null) {
      return false;
    }
    return this.cardViewState.viewType == CardViewType.Group;
  }

  public get isBranchCard(): boolean {
    if (this.cardViewState == null) {
      return false;
    }
    if (this.cardViewState.branchCardViewState.branchDetails == null) {
      return false;
    }
    return this.cardViewState.viewType == CardViewType.Branch;
  }

  public get isNavigationAvailable(): boolean {
    if (this.isAnimalCard) {
      return this.cardViewState.relatedAnimalIds.length > 1;
    } else if (this.isGroupCard) {
      return this.cardViewState.relatedGroupIds.length > 1;
    } else if (this.isBranchCard) {
      return this.cardViewState.relatedBranchIds.length > 1;
    } else {
      return false;
    }
  }

  public get isCardFooterVisible():boolean {
    if(this.cardViewState == null){
      return false;
    } else if(this.cardViewState.viewType === CardViewType.Animal &&
       this.cardViewState.relatedAnimalIds != null){
      return true;
    } else if(this.cardViewState.viewType === CardViewType.Group &&
       this.cardViewState.relatedGroupIds != null){
      return true;
    } else if (this.cardViewState.viewType === CardViewType.Branch &&
        this.cardViewState.relatedBranchIds != null) {
      return true;
    }
    return false;
  }

  public get isSelected() : boolean {
    return this.cardViewState.selectionState.rowSelectionState.isRowSelected(this.cardViewState.selectionState.openedRowId);
  }

  public closeCard() {
    this.saveDataViewStateService.activeEditArea = null;
    this.cardViewState = null;
  }

  public switchToAnimal() {
    this.cardViewState.viewType = CardViewType.Animal;
  }

  public switchToGroup() {
    this.cardViewState.viewType = CardViewType.Group;
  }

  public switchToBranch() {
    this.cardViewState.viewType = CardViewType.Branch;
  }

  public handleBreadcrumbNavigation(cardType: CardViewType) {
    switch (cardType) {
      case CardViewType.Animal: {
        this.switchToAnimal();
        break;
      }
      case CardViewType.Group: {
        this.switchToGroup();
        break;
      }
      case CardViewType.Branch: {
        this.switchToBranch();
        break;
      }
    }
  }

  public navigateNextItem(): void {
    if (!this.isNavigationAvailable) {
      return;
    }
    // get the reference for the row selections in order to keep the binding with the row selection at the context
    let rowSelectionStateReference;
    if (this.cardViewState.selectionState && this.cardViewState.selectionState.rowSelectionState) {
      rowSelectionStateReference = this.cardViewState.selectionState.rowSelectionState;
    }
    // copy deep the data
    let nextCardViewState = _.cloneDeep<CardViewState>(this.cardViewState);
    // set the reference to keep the row selections context
    if (rowSelectionStateReference) {
      nextCardViewState.selectionState.rowSelectionState = rowSelectionStateReference;
    }
    nextCardViewState.selectedItemIndex = this.cardViewState.selectedItemIndex + 1;

    switch (nextCardViewState.viewType) {
      case CardViewType.Animal: {
        if(nextCardViewState.selectedItemIndex == this.cardViewState.relatedAnimalIds.length){
          nextCardViewState.selectedItemIndex = 0;
        }
        nextCardViewState.animalId = this.cardViewState.relatedAnimalIds[nextCardViewState.selectedItemIndex];
        break;
      }
      case CardViewType.Group: {
        if(nextCardViewState.selectedItemIndex == this.cardViewState.relatedGroupIds.length){
          nextCardViewState.selectedItemIndex = 0;
        }
        nextCardViewState.groupId = this.cardViewState.relatedGroupIds[nextCardViewState.selectedItemIndex];
        break;
      }
      case CardViewType.Branch: {
        if(nextCardViewState.selectedItemIndex == this.cardViewState.relatedBranchIds.length){
          nextCardViewState.selectedItemIndex = 0;
        }
        nextCardViewState.branchId = this.cardViewState.relatedBranchIds[nextCardViewState.selectedItemIndex];
      }
    }

    if(this.cardViewState.selectionState != null) {
      nextCardViewState.selectionState.openedRowId = this.cardViewState.selectionState.relatedRowIds[nextCardViewState.selectedItemIndex];
    }

    this.cardViewStateService.openCardViewState(nextCardViewState);
  }

  public navigatePrevItem(): void {
    if (!this.isNavigationAvailable) {
      return;
    }
    // get the reference for the row selections in order to keep the binding with the row selection at the context
    let rowSelectionStateReference;
    if (this.cardViewState.selectionState && this.cardViewState.selectionState.rowSelectionState) {
      rowSelectionStateReference = this.cardViewState.selectionState.rowSelectionState;
    }
    // copy deep the data
    let prevCardViewState =  _.cloneDeep<CardViewState>(this.cardViewState);
    // set the reference to keep the row selections context
    if (rowSelectionStateReference) {
      prevCardViewState.selectionState.rowSelectionState = rowSelectionStateReference;
    }
    prevCardViewState.selectedItemIndex = this.cardViewState.selectedItemIndex - 1;

    switch (prevCardViewState.viewType) {
      case CardViewType.Animal: {
        if(prevCardViewState.selectedItemIndex < 0) {
          prevCardViewState.selectedItemIndex = this.cardViewState.relatedAnimalIds.length -1;
        }
        prevCardViewState.animalId = this.cardViewState.relatedAnimalIds[prevCardViewState.selectedItemIndex];
        break;
      }
      case CardViewType.Group: {
        if(prevCardViewState.selectedItemIndex < 0) {
          prevCardViewState.selectedItemIndex = this.cardViewState.relatedGroupIds.length -1;
        }
        prevCardViewState.groupId = this.cardViewState.relatedGroupIds[prevCardViewState.selectedItemIndex];
        break;
      }
      case CardViewType.Branch: {
        if(prevCardViewState.selectedItemIndex < 0) {
          prevCardViewState.selectedItemIndex = this.cardViewState.relatedBranchIds.length -1;
        }
        prevCardViewState.branchId = this.cardViewState.relatedBranchIds[prevCardViewState.selectedItemIndex];
        break;
      }
    }

    if(this.cardViewState.selectionState != null) {
      prevCardViewState.selectionState.openedRowId = this.cardViewState.selectionState.relatedRowIds[prevCardViewState.selectedItemIndex];
    }
    this.cardViewStateService.openCardViewState(prevCardViewState);
  }

  private async onCardViewStateReceived(cardViewState: CardViewState) {
    this.cardViewState = cardViewState;
    if(this.cardViewState == null){
      return;
    }
    await this.getAnimalDetailsByAnimalId();
    await this.getGroupDetailsByGroupId();
    await this.getBranchDetailsByBranchId();
  }

  public async getAnimalDetailsByAnimalId() {
    this.cardViewState.animalCardViewState.animalInfo = null;
    if(this.cardViewState.viewType == CardViewType.Animal &&
      this.cardViewState.animalId != null) {
      let animalInfoResponse = await this.animalsService.getAnimalInfo(this.cardViewState.animalId);
      if(animalInfoResponse.status == 200) {
        this.cardViewState.animalCardViewState.animalInfo = animalInfoResponse.responseBody;
        this.cardViewState.groupId = this.cardViewState.animalCardViewState.animalInfo.groupId;
        if (this.cardViewState.animalCardViewState.animalInfo.branchId) {
          this.cardViewState.branchId = this.cardViewState.animalCardViewState.animalInfo.branchId;
        }
      }
    }
  }

  public async getGroupDetailsByGroupId() {
    this.cardViewState.groupCardViewState.groupDetails = null;
    if(this.cardViewState.groupId != null &&
      (this.cardViewState.animalCardViewState.animalInfo == null ||
        !this.cardViewState.animalCardViewState.animalInfo.isCulled)) {
      let groupDetailsResponse = null;
      if (this.cardViewState.operationType === OperationType.BeefFinishing) {
        groupDetailsResponse = await this.groupService.getGroupDetailsForFinishing(this.cardViewState.groupId);
      } else {
        groupDetailsResponse = await this.groupService.getGroupDetails(this.cardViewState.groupId);
      }

      if(groupDetailsResponse.status == 200) {
        this.cardViewState.groupCardViewState.groupDetails = groupDetailsResponse.responseBody;
        if (this.cardViewState.groupCardViewState.groupDetails.branchId) {
          this.cardViewState.branchId = this.cardViewState.groupCardViewState.groupDetails.branchId;
        }
      }
    }
  }

  public async getBranchDetailsByBranchId() {
    this.cardViewState.branchCardViewState.branchDetails = null;
    if (this.cardViewState.branchId != null && this.cardViewState.operationType === OperationType.BeefFinishing) {
      let branchCardDetails: HttpResponseResult<GroupBranchDetails> = await this.branchesService.getBranchCardDetails(this.cardViewState.branchId);
      if (branchCardDetails.status == 200) {
        this.cardViewState.branchCardViewState.branchDetails = branchCardDetails.responseBody;
      }
    }
  }

  public async toggleSelectedItem() {
    this.cardViewState.selectionState.rowSelectionState.toggleRowSelection(this.cardViewState.selectionState.openedRowId);
  }

  public get getLengthAccordingToDisplayedContext(): number {
    switch (this.cardViewState.viewType) {
      case CardViewType.Animal: {
        return this.cardViewState.relatedAnimalIds.length > 0 ? this.cardViewState.relatedAnimalIds.length : 1;
      }
      case CardViewType.Group: {
        return this.cardViewState.relatedGroupIds.length > 0 ? this.cardViewState.relatedGroupIds.length : 1;
      }
      case CardViewType.Branch: {
        return this.cardViewState.relatedBranchIds.length > 0 ? this.cardViewState.relatedBranchIds.length : 1;
      }
    }
  }

  public get currentIndex(): number {
    return this.cardViewState.selectedItemIndex == -1 ? 1 : this.cardViewState.selectedItemIndex + 1;
  }
}
