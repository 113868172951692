import {Component, Input, OnInit} from '@angular/core';
import {dashboardAccordion} from '../../animations/dashboard-animations';
import {AccordionWidgetViewState} from '../../dashboard-accordion.component';
import { FarmMode } from 'src/app/services/config/model/server-config';
import {ConfigService} from '../../../../../services/config/config.service';
import {FarmStatisticsWidgetName} from '../../../../../services/dashboard/model/farm-statistics-finishing';

@Component({
  selector: 'accordion-graph-widget',
  templateUrl: './accordion-graph-widget.component.html',
  styleUrls: ['./accordion-graph-widget.component.scss'],
  animations: [dashboardAccordion]
})
export class AccordionGraphWidgetComponent implements OnInit {

  @Input()
  public viewState: AccordionWidgetViewState;

  public FarmMode = FarmMode;

  public farmMode: FarmMode;

  public FarmStatisticsWidgetName = FarmStatisticsWidgetName;

  constructor(private readonly configService: ConfigService) { }

  public ngOnInit() {
    this.farmMode = this.configService.serverConfig.farmMode;
  }
}
