import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ManageSettingsScreenType} from '../../model/manage-settings.model';

export class ManageSettingsMenuItemModel {
  public isActive: boolean;
  public caption: string;
  public isOpen: boolean;
  public subMenus: ManageSettingsSubMenuItemModel[];
  public shId: string;
  public screenType: ManageSettingsScreenType;
}

export class ManageSettingsSubMenuItemModel {
  public isActive: boolean;
  public caption: string;
  public shId: string;
  public screenType: ManageSettingsScreenType;
}

export class ManageSettingsSubMenuClickEvent {
  public menuItem: ManageSettingsMenuItemModel;
  public subMenuItem: ManageSettingsSubMenuItemModel;
}

@Component({
  selector: 'manage-settings-menuitem',
  templateUrl: './manage-settings-menuitem.component.html',
  styleUrls: ['./manage-settings-menuitem.component.scss']
})
export class ManageSettingsMenuitemComponent implements OnInit {

  @Input()
  public menuItemModel: ManageSettingsMenuItemModel;

  @Output()
  public menuItemClick : EventEmitter<ManageSettingsMenuItemModel> = new EventEmitter<ManageSettingsMenuItemModel>();

  @Output()
  public subMenuItemClick : EventEmitter<ManageSettingsSubMenuClickEvent> = new EventEmitter<ManageSettingsSubMenuClickEvent>();

  constructor() { }

  public ngOnInit() {

  }

}
