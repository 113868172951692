import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export type ShowPopupOptions = {
  isShown: boolean;
  closeSubject?: Subject<boolean>;
};

@Injectable({
  providedIn: 'root'
})
export class CancelBatchEventDialogService {
  public showPopup$ = new Subject<ShowPopupOptions>();

  public show(): Observable<boolean> {
    const closeSubject = new Subject<boolean>();
    this.showPopup$.next({
      closeSubject,
      isShown: true
    });
    return closeSubject.asObservable();
  }
}
