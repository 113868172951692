import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnimalsService} from "../../../../../../../services/animals/animals.service";
import {AddAnimalFieldsErrorsEnum, IErrors} from "../../../../../../../services/animals/model/animal.model";
import {FinishingAddMaleModel} from "../add-male-finishing.component";
import {DropdownListItem} from "../../../../../../../common/components/dropdown-list/dropdown-list-model";
import {EpochStateModel} from "../../../../../../../common/components/calendar/model/epoch-state-model";

@Component({
  selector: 'add-male-finishing-farm-tab',
  templateUrl: './add-male-finishing-farm-tab.component.html',
  styleUrls: ['./add-male-finishing-farm-tab.component.scss']
})
export class AddMaleFinishingFarmTabComponent implements OnInit {

  public AddAnimalFieldsErrorsEnum = AddAnimalFieldsErrorsEnum;

  @Input()
  public finishingAddMaleModel: FinishingAddMaleModel;

  @Input()
  public groupsDropdownListItems: DropdownListItem[];

  @Input()
  public isKgValueDisplayed: boolean;

  @Input()
  public errors: IErrors[];

  @Output()
  public onClearErrors: EventEmitter<void> = new EventEmitter<void>();

  constructor(public animalsService: AnimalsService) { }

  public ngOnInit() {
  }

  public clearErrors() {
    this.onClearErrors.emit();
  }

  public onGroupSelected(selectedGroup: DropdownListItem) {
    if (!this.finishingAddMaleModel.group) {
      this.clearErrors();
    }
    this.finishingAddMaleModel.group = selectedGroup;
  }

  public onEntryDateChanged(selectedEntryDate: EpochStateModel) {
    this.finishingAddMaleModel.entryDate = selectedEntryDate;
    this.finishingAddMaleModel.updateDatesRanges();
    this.clearErrors();
  }

  public hasErrorForBirthdateField() {
    return this.animalsService.hasErrorForField(this.errors, this.AddAnimalFieldsErrorsEnum.birthDate) || (this.errors && this.errors[0] && this.errors[0].key && this.errors[0].key === 'HerdEntryAndBirthDateAreMissing');
  }

  public isBirthdateAndEntryDateNotDefined() {
    return (this.finishingAddMaleModel.birthDate.epochDate == null && this.finishingAddMaleModel.entryDate.epochDate == null);
  }

  public onBirthDateChanged(selectedBirthDate: EpochStateModel) {
    this.finishingAddMaleModel.birthDate = selectedBirthDate;
    this.finishingAddMaleModel.updateDatesRanges();
    this.clearErrors();
  }

  public onWeighingDateChanged(selectedWeighingDate: EpochStateModel) {
    this.finishingAddMaleModel.weighing = selectedWeighingDate;
    this.finishingAddMaleModel.updateDatesRanges();
    this.clearErrors();
  }
}
