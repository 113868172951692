import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {AnimalsService} from '../../../../services/animals/animals.service';
import {IAnimalChartData, IAnimalChartDataPoint, IAnimalChartSetupSeries} from '../../../../services/animals/model/animal.model';
import {SeriesModel} from '@syncfusion/ej2-charts';
import {
  ChartDurationSelectionLabel,
  XyChartComponent
} from '../../../../common/components/xy-chart/xy-chart.component';
import {
  ChartLegendConfiguration,
  NamedChartLegendState,
  XyChartLegendComponent
} from '../../../../common/components/xy-chart-legend/xy-chart-legend.component';
import {ChartSeriesSelectionState} from '../../../../common/components/chart-series-dropdown-list/chart-series-dropdown-list.component';
import {ChartResolution, IChartPoint} from '../../../../services/model/charts/chart.interface';
import {ChartsService} from '../../../../services/charts/charts.service';
import {ChartSetupProjection, IChartSeriesSetupRecord, IChartSetup} from '../../../../services/charts/model/chart.interface';
import {
  ChartConfiguration,
  GridLineIntervalEnum,
  XyChartCalculationService
} from '../../../../services/charts/xy-chart-calculation.service';
import {GoogleAnalyticsService} from '../../../../services/google-analytics/google-analytics.service';
import {AnimalCardViewState} from '../animal-card-model';

@Component({
  selector: 'animal-card-heat',
  templateUrl: './animal-card-heat.component.html',
  styleUrls: ['./animal-card-heat.component.scss']
})
export class AnimalCardHeatComponent implements OnChanges {

  @Input()
  public animalId: number;

  @Input()
  public viewState: AnimalCardViewState;

  @ViewChild('heatChart')
  public heatChart: XyChartComponent;

  @ViewChild('heatChartLegend')
  public heatChartLegend: XyChartLegendComponent;

  public configuration: ChartLegendConfiguration = {
    translationPrefix: 'ANIMAL.GRAPH.SERIES_TITLE.',
    valuePostfix: ''
  };

  private readonly heatChartId : number = 1;

  public seriesSelections : ChartSeriesSelectionState[];

  private animalChartHourlyData: IAnimalChartData;

  private animalChartDailyData: IAnimalChartData;

  public selectedDurationLabel: ChartDurationSelectionLabel;

  private optionsDurationLabels: ChartDurationSelectionLabel[] = [];

  private chartConfigurations: ChartConfiguration[] = [];

  public legendStates: NamedChartLegendState[];

  constructor(public animalsService:AnimalsService,
              private chartsService: ChartsService,
              private googleAnalyticsService: GoogleAnalyticsService) {
  }

  public async ngOnChanges(changes: SimpleChanges){
    if(changes.viewState != null &&
       changes.viewState.previousValue != changes.viewState.currentValue &&
       this.viewState != null) {
      this.initConfig();
      if(this.viewState.heatChartSetup == null) {
        await this.getChartSetup(false);
      }
      this.initChartSeriesSelection();
      this.initChart(true);
    }
  }

  public onChartPointMouseOver(chartPoint: IChartPoint) {
    this.setLegendValue(chartPoint);
  }

  public onDurationSelected(duration: ChartDurationSelectionLabel) {
    this.selectedDurationLabel = duration;
    this.initChart(false);
  }

  public async onSelectionApplied(save: boolean) {

    if(save) {
      this.googleAnalyticsService.send("Cow Card Graph","Change Settings - Save", JSON.stringify(this.viewState.heatChartSetup));
    }
    else  {
      this.googleAnalyticsService.send("Cow Card Graph","Change Settings - Without Save", JSON.stringify(this.viewState.heatChartSetup));
    }

    for (let seriesItem of this.seriesSelections) {
      if(seriesItem.name == 'events') {
        this.viewState.heatChartSetup.events.isSelected = seriesItem.isSelected;
      } else {
        this.viewState.heatChartSetup.series[seriesItem.name].isSelected = seriesItem.isSelected;
      }
    }
    this.heatChart.resetChart();
    setTimeout(async () => {
      if(save) {
        let result = await this.chartsService.updateChartSetup(this.heatChartId, this.viewState.heatChartSetup);
        if (result.status != 200) {
          await this.getChartSetup(false);
        }
      }
      this.initChart(true);
    });
  }

  public async onRestoreToDefaults() {

    this.heatChart.resetChart();
    setTimeout(async () =>{
      await this.getChartSetup(true);
      this.initChartSeriesSelection();
      this.initChart(true);
    });
  }

  private initConfig() {
    this.optionsDurationLabels.push({
      selectedDisplayText: '1 DAY',
      optionDisplayText: '1'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 1,
      gridLineInterval: GridLineIntervalEnum.Hour,
      visiblePeriodHours: 24,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 100
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '3 DAYS',
      optionDisplayText: '3'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 3,
      gridLineInterval: GridLineIntervalEnum.ThreeHours,
      visiblePeriodHours: 24 * 3,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 100
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '7 DAYS',
      optionDisplayText: '7'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 8,
      gridLineInterval: GridLineIntervalEnum.Day,
      visiblePeriodHours: 24 * 7,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 100
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '15 DAYS',
      optionDisplayText: '15'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 20,
      gridLineInterval: GridLineIntervalEnum.Day,
      visiblePeriodHours: 24 * 15,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 100
    }));


    this.optionsDurationLabels.push({
      selectedDisplayText: '30 DAYS',
      optionDisplayText: '30'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 37,
      gridLineInterval: GridLineIntervalEnum.Week,
      visiblePeriodHours: 24 * 30,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 100
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '60 DAYS',
      optionDisplayText: '60'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 3,
      gridLineInterval: GridLineIntervalEnum.Week,
      visiblePeriodHours: 24 * 60,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 100
    }));


    this.optionsDurationLabels.push({
      selectedDisplayText: '180 DAYS',
      optionDisplayText: '180'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 10,
      gridLineInterval: GridLineIntervalEnum.Month,
      visiblePeriodHours: 24 * 180,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 100
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '360 DAYS',
      optionDisplayText: '360'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 18,
      gridLineInterval: GridLineIntervalEnum.Month,
      visiblePeriodHours: 24 * 360,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 100
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '500 DAYS',
      optionDisplayText: '500'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 18,
      gridLineInterval: GridLineIntervalEnum.Month,
      visiblePeriodHours: 24 * 500,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 100
    }));

    this.selectedDurationLabel = this.optionsDurationLabels[this.viewState.heatChartSelectedDuration];
  }

  private async getChartSetup(resest: boolean) {
    let response = await this.chartsService.getChartSetup<IChartSeriesSetupRecord, IAnimalChartSetupSeries>(this.heatChartId, resest ? ChartSetupProjection.resetsetup : ChartSetupProjection.getsetup);
    if(response.status == 200){
      this.viewState.heatChartSetup = response.responseBody;
    }
  }

  private async initChart(reloadData:boolean) {
    this.viewState.heatChartSelectedDuration = this.optionsDurationLabels.indexOf(this.selectedDurationLabel);
    if(this.viewState.heatChartSelectedDuration  < 6) {
      if(this.animalChartHourlyData == null ||
         reloadData){
        let response = await this.animalsService.getAnimalChartData(this.animalId,
                                                                    this.heatChartId,
                                                                    ChartResolution.Hour,
                                                                    this.seriesSelections.some(value => value.name == 'events' && value.isSelected),
                                                                    this.seriesSelections.filter(value => value.name != 'events' &&
                                                                                                          value.isSelected)
                                                                                         .map(value => value.name));
        if(response.status == 200) {
          this.animalChartHourlyData = response.responseBody;
        }
      }
      this.heatChart.initChart(this.getSeriesConfig(this.animalChartHourlyData.series),
                                                    this.animalChartHourlyData.series,
                                                    this.chartConfigurations[this.viewState.heatChartSelectedDuration],
                                                    false,
                                                    this.selectedDurationLabel,
                                                    this.optionsDurationLabels,
        (this.viewState.heatChartSetup.events.isSelected &&
                        this.viewState.heatChartSetup.events.isVisible) ? this.animalChartHourlyData.eventsSeries : null);
    } else {
      if(this.animalChartDailyData == null ||
        reloadData){
        let response = await this.animalsService.getAnimalChartData(this.animalId,
                                                                    this.heatChartId,
                                                                    ChartResolution.Day,
                                                                    this.seriesSelections.some(value => value.name == 'events' && value.isSelected),
                                                                    this.seriesSelections.filter(value => value.name != 'events' &&
                                                                                                          value.isSelected)
                                                                                         .map(value => value.name));
        if(response.status == 200) {
          this.animalChartDailyData = response.responseBody;
        }
      }
      this.heatChart.initChart(this.getSeriesConfig(this.animalChartDailyData.series),
                                                    this.animalChartDailyData.series,
                                                    this.chartConfigurations[this.viewState.heatChartSelectedDuration],
                                                    false,
                                                    this.selectedDurationLabel,
                                                    this.optionsDurationLabels,
        (this.viewState.heatChartSetup.events.isSelected &&
                        this.viewState.heatChartSetup.events.isVisible) ? this.animalChartDailyData.eventsSeries: null);
    }
    this.initChartLegend(this.viewState.heatChartSetup.series);
  }

  private getSeriesConfig(dataPoints: IAnimalChartDataPoint[]) : SeriesModel[] {
    let seriesModel : SeriesModel[] = [];

    if(this.viewState.heatChartSetup.series.dailyRumination.isSelected &&
       this.viewState.heatChartSetup.series.dailyRumination.isVisible) {

      seriesModel.push({
        type: 'Line',
        xName: 'x',
        yName: 'dailyRumination',
        width: 2,
        fill: '#aa64aa',
        animation: {
          enable: false
        },
        dataSource: dataPoints
      });
      seriesModel.push({
        type: 'Area',
        xName: 'x',
        yName: 'dailyRumination',
        opacity: 0.2,
        fill: '#aa64aa',
        animation: {
          enable: false
        },
        dataSource: dataPoints
      });

    }

    if(this.viewState.heatChartSetup.series.activityTrend.isSelected &&
       this.viewState.heatChartSetup.series.activityTrend.isVisible) {

      seriesModel.push({
        type: 'Line',
        xName: 'x',
        yName: 'activityTrend',
        width: 2,
        fill: '#DF9226',
        animation: {
          enable: false
        },
        dataSource: dataPoints
      });
      seriesModel.push({
        type: 'Area',
        xName: 'x',
        yName: 'activityTrend',
        opacity: 0.2,
        fill: '#ffcb05',
        animation: {
          enable: false
        },
        dataSource: dataPoints
      });

    }

    if(this.viewState.heatChartSetup.series.heatTrend.isSelected &&
       this.viewState.heatChartSetup.series.heatTrend.isVisible) {

      seriesModel.push({
        type: 'Line',
        xName: 'x',
        yName: 'heatTrend',
        yAxisName: 'secYAxis',
        width: 2,
        fill: '#2978a0',
        animation: {
          enable: false
        },
        dataSource: dataPoints
      });
      seriesModel.push({
        type: 'Area',
        xName: 'x',
        yName: 'heatTrend',
        yAxisName: 'secYAxis',
        opacity: 0.2,
        fill: '#1999b5',
        animation: {
          enable: false
        },
        dataSource: dataPoints
      });

    }

    if(this.viewState.heatChartSetup.series.dailyEating.isSelected &&
       this.viewState.heatChartSetup.series.dailyEating.isVisible) {

      seriesModel.push({
        type: 'Line',
        xName: 'x',
        yName: 'dailyEating',
        width: 2,
        fill: '#56c3b8',
        animation: {
          enable: false
        },
        dataSource: dataPoints
      });
    }
    return seriesModel;
  }

  // tslint:disable-next-line:no-any
  private initChartLegend(seriesSetupContainer: any) {
    this.legendStates = [];
    for (let containerSeriesName of Object.getOwnPropertyNames(seriesSetupContainer)) {
      let record : IChartSeriesSetupRecord = seriesSetupContainer[containerSeriesName];
      if(record.isInLegend &&
         record.isVisible &&
         record.isSelected) {
        this.legendStates.push({
          name: containerSeriesName,
          value: null,
          altTranslation: null,
          altCss: null
        });
      }
    }
  }

  private initChartSeriesSelection() {
    this.seriesSelections = [];
    for (let containerSeriesName of Object.getOwnPropertyNames(this.viewState.heatChartSetup.series)) {
      let record : IChartSeriesSetupRecord = this.viewState.heatChartSetup.series[containerSeriesName];
      if(record.isVisible) {
        this.seriesSelections.push({
          name: containerSeriesName,
          isSelected: record.isSelected,
          isRemovable: record.isRemovable
        });
      }
    }
    if(this.viewState.heatChartSetup.events.isVisible) {
      this.seriesSelections.push({
        name: 'events',
        isSelected: this.viewState.heatChartSetup.events.isSelected,
        isRemovable: this.viewState.heatChartSetup.events.isRemovable
      });
    }
  }

  // tslint:disable-next-line:no-any
  private setLegendValue(valueContainer: any) {
    if(valueContainer == null ){
      this.legendStates.forEach(legendState => {
        legendState.value = null;
      });
    } else {
      this.legendStates.forEach(legendState => {
        legendState.value = valueContainer[legendState.name];
        if(legendState.value == null) {
          legendState.value = 0;
        }
      });
    }
  }
}
