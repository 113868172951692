import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IDashboardAvailableKPI} from '../../../../services/dashboard/model/dashboard-kpi.interface';
import {DashboardKpiService} from '../../../../services/dashboard/dashboard-kpi.service';

export class SelectKpiDialogModel {
  public editAvailableKpi: IDashboardAvailableKPI[];
}

@Component({
  selector: 'dashboard-kpi-select-dialog',
  templateUrl: './dashboard-kpi-select-dialog.component.html',
  styleUrls: ['./dashboard-kpi-select-dialog.component.scss']
})
export class DashboardKpiSelectDialogComponent implements OnInit {

  @Input()
  public model: SelectKpiDialogModel;

  @Output()
  public onApply: EventEmitter<void> = new EventEmitter<void>();
  
  @Output()
  public onCancel: EventEmitter<void> = new EventEmitter<void>();
  
  public isOpen: boolean;
  
  constructor(public readonly dashboardKpiService: DashboardKpiService) { }
  
  public ngOnInit() {
  
  }
  
  public open() {
    this.isOpen = true;
  }
  
  public closeClick() {
    this.isOpen = false;
    this.onCancel.emit();
  }
  
  public applyClick() {
    this.isOpen = false;
    this.onApply.emit();
  }
  
  public selectKpi(kpi:IDashboardAvailableKPI) {
    if(this.model.editAvailableKpi.filter(value => value.isSelected).length < DashboardKpiService.MaxNumberOfItems) {
      kpi.isSelected = true;
    }
  }
  
  public unselectKpi(kpi:IDashboardAvailableKPI) {
    kpi.isSelected = false;
  }
}
