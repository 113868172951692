<div class="manage-animals">
  <ul class="nav" *ngIf="!animalsService.isAddAnimalOpen">
    <li class="nav-item" routerLinkActive="active">
      <a routerLink="/manage/animals/active" [queryParamsHandling]="'preserve'" sh-id="manage-animals-active-route" [attr.translation-id]="'MANAGE.TITLE.ANIMALS_IN_HERD'">{{'MANAGE.TITLE.ANIMALS_IN_HERD' | translate}}</a>
    </li>
    <li class="nav-item">
      <div class="icon-switch"></div>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a routerLink="/manage/animals/culled" [queryParamsHandling]="'preserve'" sh-id="manage-animals-culled-route" [attr.translation-id]="'MANAGE.TITLE.CULLED_ANIMALS'">{{'MANAGE.TITLE.CULLED_ANIMALS' | translate}}</a>
    </li>
  </ul>
  <div class="manage-animals-content" [ngClass]="{'add-animal-open' : animalsService.isAddAnimalOpen}" saveDataWarningInject>
    <router-outlet></router-outlet>
  </div>
</div>

