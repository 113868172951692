import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {HttpResponseResultNoBody, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from '../auth/auth.service';
import {Subject, Subscription} from 'rxjs';
import {PollingKeyNames, PollingService} from '../rest-api/polling.service';

export enum ImportFromCsvState {
  None = 'None',
  Running = 'Running',
  Done = 'Done'
}

export interface IImportFromCSVModel {
  totalRecords: number;
  successfullyImported: number;
  duplicatedRecords: number;
  recordsWithErrors: number;
  state: ImportFromCsvState;
}

@Injectable({
  providedIn: 'root'
})
export class ImportFromCsvService implements OnInit, OnDestroy {

  // tslint:disable-next-line:no-any
  private interval: any;

  private static readonly ImportAnimalsFromCsvStatusRoute = '/rest/api/import/animals/file';

  private static readonly ImportFinishingAnimalsFromCsvStatusRoute = '/rest/api/import/finishing/animals/file';

  private static readonly CompleteImportFromCsvProcessRoute = '/rest/api/import/animals/file?projection=completeProcess';

  private static readonly RejectImportFromCsvProcessRoute = '/rest/api/import/animals/file?projection=exportRejected';

  // tslint:disable-next-line:no-any
  public onImportFromFile: Subject<any> = new Subject();

  public importFromFileSubscription: Subscription;

  public ImportAnimalsFromCsvStatusModel: IImportFromCSVModel = { totalRecords: null, duplicatedRecords: null, successfullyImported: null, recordsWithErrors: null, state: ImportFromCsvState.None };

  constructor(private restApiService: RestApiService,
              private authService: AuthService,
              private pollingService: PollingService) {
    this.importFromFileSubscription = this.pollingService.pollingChangesState.subscribe(async value => {
      if(value.length && value.includes(PollingKeyNames.importFromFileUpdated)) {
        await  this.getImportAnimalsFromCsvStatus();
        if(this.isImportRunning) {
          this.startGettingImportAnimalsStatus();
        } else {
          clearInterval(this.interval);
        }
      }
    });
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.importFromFileSubscription.unsubscribe();
  }

  public get isImportRunning() : boolean {
    return this.ImportAnimalsFromCsvStatusModel && this.ImportAnimalsFromCsvStatusModel.state === ImportFromCsvState.Running;
  }

  public startGettingImportAnimalsStatus() {
    this.interval = setInterval(async () => {
      await this.getImportAnimalsFromCsvStatus();
      if(!this.isImportRunning){
        clearInterval(this.interval);
      }
    }, 2000);
  }

  public async getImportAnimalsFromCsvStatus(): Promise<void> {
    let response = await this.restApiService.sendGetMethodAny<IImportFromCSVModel>(ImportFromCsvService.ImportAnimalsFromCsvStatusRoute, this.authService.currentUserAuthState.authDetails);
    if(response.status === 200 ||
       response.status === 202){
      this.ImportAnimalsFromCsvStatusModel = response.responseBody;
    }
  }

  public async doneImportAnimalsFromCsv(): Promise<HttpResponseResultNoBody> {
    return await this.restApiService.sendDeleteMethod(ImportFromCsvService.CompleteImportFromCsvProcessRoute, this.authService.currentUserAuthState.authDetails);
  }

  public async exportRejectedAnimalsFromCsv() {
    // tslint:disable-next-line:no-any
    let response =  await this.restApiService.sendDeleteMethodAny<any>(ImportFromCsvService.RejectImportFromCsvProcessRoute, this.authService.currentUserAuthState.authDetails);
    this.createFileFromBase64File(response.responseBody);
  }

  // tslint:disable-next-line:no-any
  public async importAnimalFromFile(file: any, fileName: string) {
    // tslint:disable-next-line:no-any
    return await this.restApiService.sendPostMethodAny<any>(ImportFromCsvService.ImportAnimalsFromCsvStatusRoute, this.authService.currentUserAuthState.authDetails, {
      file: file,
      fileName: fileName
    });
  }

  // tslint:disable-next-line:no-any
  public async importFinishingAnimalFromFile(file: any, fileName: string) {
    // tslint:disable-next-line:no-any
    return await this.restApiService.sendPostMethodAny<any>(ImportFromCsvService.ImportFinishingAnimalsFromCsvStatusRoute, this.authService.currentUserAuthState.authDetails, {
      file: file,
      fileName: fileName
    });
  }

  // tslint:disable-next-line:no-any
  private createFileFromBase64File(response: any) {
    // convert the data from base64
    // tslint:disable-next-line:no-any
    let byteCharacters: any = atob(response.file);
    // create a byte array with the length of the data
    // tslint:disable-next-line:no-any
    let byteNumbers: any[] = new Array(byteCharacters.length);
    // insert data bytes into the bytes array
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    // convert the array to UINT
    // tslint:disable-next-line:no-any
    let byteArray: any = new Uint8Array(byteNumbers);
    // create a blob with the file name and the type of the file
    let blob = new Blob([byteArray], {type: 'application/vnd.ms-excel'});
    // create the file using the blob
    let convertedFile: File = new File([blob],response.fileName,{ type: 'application/vnd.ms-excel'});
    // download the file
    // tslint:disable-next-line:no-any
    let link: any = document.createElement("a");
    link.download = convertedFile.name;
    link.href = URL.createObjectURL(convertedFile);
    link.click();
  }


}
