import { Component, OnInit } from '@angular/core';
import {CastrationEventDetails, CastrationEventMetadata} from '../../../../../services/animals/model/animal-events';
import {EventFormComponent} from '../event-form.component';
import {CustomEventWrapperCssClass} from "../event-form-errors/event-form-errors.component";

@Component({
  selector: 'castration-event-form',
  templateUrl: './castration-event-form.component.html',
  styleUrls: ['./castration-event-form.component.scss']
})
export class CastrationEventFormComponent extends EventFormComponent<CastrationEventMetadata, CastrationEventDetails>{

  public CustomEventWrapperCssClass = CustomEventWrapperCssClass;

  constructor() {
    super();
  }
}
