import { Injectable } from '@angular/core';
import {HttpResponseResult, RestApiService} from '../rest-api/rest-api.service';
import {HttpParams} from '@angular/common/http';
import {AuthService} from '../auth/auth.service';
import {ChartSetupProjection, IChartSetup} from './model/chart.interface';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  private static readonly ChartSetupRoute = '/rest/api/graphs/{chartId}';

  constructor(private restApiService: RestApiService, private authService: AuthService) { }

  public getChartSetup<TEvents,TSeries>(chartId:number, projection:ChartSetupProjection) : Promise<HttpResponseResult<IChartSetup<TEvents,TSeries>>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('projection', projection);
    let route = ChartsService.ChartSetupRoute.replace('{chartId}', chartId.toString());
    return this.restApiService.sendGetMethod<IChartSetup<TEvents,TSeries>>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public updateChartSetup<TEvents,TSeries>(chartId:number, chartSetup:IChartSetup<TEvents,TSeries>) : Promise<HttpResponseResult<void>> {
    let route = ChartsService.ChartSetupRoute.replace('{chartId}', chartId.toString());
    return this.restApiService.sendPutMethod<void>(route, this.authService.currentUserAuthState.authDetails, chartSetup);
  }
}
