<div class="create-event-form">
  <div class="form-section-title">
    {{ 'ANIMAL.EVENTS.ADD_EVENT.EXPIRATION_DATE' | translate }}
  </div>
  <div class="form-section-item">
    <date-picker class="form__control"
                      [selectedEpoch]="expirationDate"
                      [calendarIconColor]="'#1999d5'"
                      [ngClass]="{ invalid: errorModel.fieldName === 'expirationDate' }"
                      (epochChanged)="onSelectedTimeChanged($event)">
    </date-picker>
  </div>
</div>
