import { Injectable } from '@angular/core';
import {HttpResponseResult, RestApiService} from '../rest-api/rest-api.service';
import {SearchEntitiesResult, OperationType, SearchEntry} from './model/search.model';
import {AuthService} from '../auth/auth.service';
import {HerdManagementHerdsResponse} from '../system/model/system-herds';
import {AddEventPhase, AnimalEventsService} from '../animals/animal-events.service';
import {PollingKeyNames, PollingService} from '../rest-api/polling.service';
import {AnimalsService} from '../animals/animals.service';
import {GroupsService} from '../groups/groups.service';
import {Subscription} from 'rxjs';
import {StringUtils} from '../../utils/string-utils';
import {TranslationService} from '../translations/translation.service';
import {BranchesService} from '../branches/branches.service';

export class SearchResult {
  public matchingAnimals: SearchEntry[] = [];
  public matchingGroups: SearchEntry[] = [];
  public matchingBranches: SearchEntry[] = [];
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private static readonly SearchRoute = '/rest/api/search';

  private static readonly HerdManagementHerdsRoute = '/rest/api/system/herdmanagement/herds';

  public static readonly AnimalNameLengthInHerd : number = 5;

  private herds: string[] = [];

  private searchEntitiesResult : SearchEntitiesResult;

  public isHerdManagementDisplayed: boolean = false;

  private animalsListChangedSubscription: Subscription;

  private groupsListChangedSubscription: Subscription;

  private branchesListChangedSubscription: Subscription;

  private animalEventsListSubscription: Subscription;

  private pollingServiceSubscription: Subscription;

  constructor(private readonly restApiService: RestApiService,
              private readonly animalsService: AnimalsService,
              private readonly groupsService: GroupsService,
              private readonly branchesService: BranchesService,
              private readonly animalEventsService: AnimalEventsService,
              private readonly authService: AuthService,
              private readonly translationService: TranslationService,
              private readonly pollingService: PollingService) {
    this.animalsListChangedSubscription = this.animalsService.onAnimalsListChanged.subscribe(async value => {
      await this.loadSearchEntries();
    });
    this.groupsListChangedSubscription = this.groupsService.onGroupsListChanged.subscribe(async value => {
      await this.loadSearchEntries();
    });
    this.branchesListChangedSubscription = this.branchesService.onBranchesListChanged.subscribe(async value => {
      await this.loadSearchEntries();
    });
    this.animalEventsListSubscription = this.animalEventsService.eventsListChanged.subscribe(async value => {
      await this.loadSearchEntries();
    });
    this.animalEventsService.addEventPhaseSubject.subscribe(async value => {
      if(value == AddEventPhase.completed) {
        await this.loadSearchEntries();
      }
    });
    this.pollingServiceSubscription = this.pollingService.pollingChangesState.subscribe(async pollingName => {
      if (pollingName.includes(PollingKeyNames.search) ||
        pollingName.includes(PollingKeyNames.scrTags) ||
        pollingName.includes(PollingKeyNames.rfIdTags)) {
        await this.loadSearchEntries();
      }
    });
    this.loadSearchEntries();
  }

  public get availableSearchEntities() : SearchEntry[] {
    if(this.searchEntitiesResult == null) {
      return [];
    } else {
      return this.searchEntitiesResult.result;
    }
  }

  public async listOfAvailableGroups(): Promise<SearchEntry[]> {
    if (this.searchEntitiesResult == null) {
      this.searchEntitiesResult = await this.getSearchEntities();
    }
    return this.searchEntitiesResult.result.filter((searchEntry: SearchEntry) => searchEntry.entityType === EntityType.group);
  }

  public async listOfAvailableAnimals(): Promise<SearchEntry[]> {
    if (this.searchEntitiesResult == null) {
      this.searchEntitiesResult = await this.getSearchEntities();
    }
    return this.searchEntitiesResult.result.filter((searchEntry: SearchEntry) => searchEntry.entityType === EntityType.male || searchEntry.entityType === EntityType.cow);
  }

  public search(searchQuery: string, includeGroups: boolean, includeBranches?: boolean, removeSlicing?: boolean) : SearchResult {
    let result: SearchResult = new SearchResult();
    if (searchQuery == null ||
      searchQuery.trim().length == 0 ||
      this.searchEntitiesResult == null ||
      this.searchEntitiesResult.result == null) {
      return result;
    }

    result.matchingAnimals = this.searchEntitiesResult.result.filter((animal) => this.filterAnimal(searchQuery, animal, false));
    if (!removeSlicing) {
      result.matchingAnimals = result.matchingAnimals.slice(0, 4);
    }

    if(result.matchingAnimals.length < 4) {
      result.matchingAnimals = result.matchingAnimals.concat(this.searchEntitiesResult.result.filter((animal) => result.matchingAnimals.indexOf(animal) == -1 &&
        this.filterAnimal(searchQuery,animal, true)));
      if (!removeSlicing) {
        result.matchingAnimals = result.matchingAnimals.slice(0, 4);
      }
    }

    if(includeGroups) {
      result.matchingGroups = this.searchEntitiesResult.result.filter(value => this.filterGroup(searchQuery, value)).slice(0, 4);
    }

    if (includeBranches) {
      result.matchingBranches = this.searchEntitiesResult.result.filter(value => this.filterBranch(searchQuery, value)).slice(0, 4);
      // limit number of results to 8
      const totalAnimals: number = result.matchingAnimals.length;
      const totalGroups: number = result.matchingGroups.length;
      const totalBranches: number = result.matchingBranches.length;
      if (totalAnimals > 4) {
        result.matchingAnimals = result.matchingAnimals.slice(0, 4);
      }
      if (totalGroups === 0 && totalBranches > 4) {
        result.matchingBranches = result.matchingBranches.slice(0,4);
      } else if (totalBranches === 0 && totalGroups > 4) {
        result.matchingGroups = result.matchingGroups.slice(0, 4);
      } else if (totalGroups === 1 && totalBranches > 3) {
        result.matchingBranches = result.matchingBranches.slice(0, 3);
      } else if (totalBranches === 1 && totalGroups > 3) {
        result.matchingGroups = result.matchingGroups.slice(0, 3);
      } else if (totalGroups > 2 && totalBranches > 2) {
        result.matchingGroups = result.matchingGroups.slice(0,2);
        result.matchingBranches = result.matchingBranches.slice(0,2);
      }
    }
    return result;
  }

  public async findOperationTypeByEntityId(entityId: number, entityType: EntityType): Promise<OperationType> {
    let entity = null;
    if (entityType === EntityType.cow || entityType === EntityType.male) {
      entity = this.searchEntitiesResult.result.find((searchEntity: SearchEntry) => (searchEntity.entityId === entityId));
    } else {
      entity = this.searchEntitiesResult.result.find((searchEntity: SearchEntry) => (searchEntity.entityId === entityId && searchEntity.entityType === entityType));
    }
    if (entity) {
      return entity.operationType;
    } else {
      return null;
    }
  }

  public getAnimalMatchingParts(searchQuery: string, searchEntry: SearchEntry) : string[] {

    if(searchQuery == null || searchQuery.length == 0) {
      return [];
    }

    let entityNameLowerCase = searchEntry.entityName ? searchEntry.entityName.toLowerCase() : null;
    let entityAliasLowerCase = searchEntry.entityAlias ? searchEntry.entityAlias.toLowerCase() : null;
    let searchQueryLowerCase = searchQuery.toLowerCase();

    if(this.herds.length > 0) {

      let entryNameSearchPart = (searchEntry.entityName &&
        searchEntry.entityName.length >= SearchService.AnimalNameLengthInHerd) ?
        searchEntry.entityName.substr(searchEntry.entityName.length - SearchService.AnimalNameLengthInHerd) : searchEntry.entityName;

      let entryNameSearchPartLowerCase = entryNameSearchPart  ? entryNameSearchPart.toLowerCase() : entryNameSearchPart;

      if(entryNameSearchPart &&
        entryNameSearchPartLowerCase.indexOf(searchQueryLowerCase) >= 0) {

        let entityNameParts = ['', entryNameSearchPart.slice(0, entryNameSearchPartLowerCase.indexOf(searchQueryLowerCase)),
          entryNameSearchPart.slice(entryNameSearchPartLowerCase.indexOf(searchQueryLowerCase), entryNameSearchPartLowerCase.indexOf(searchQueryLowerCase) + searchQuery.length),
          entryNameSearchPart.slice(entryNameSearchPartLowerCase.indexOf(searchQueryLowerCase) + searchQuery.length)];

        entityNameParts[1] = searchEntry.entityName.substr(0,searchEntry.entityName.length - SearchService.AnimalNameLengthInHerd) + entityNameParts[1];

        if(searchEntry.entityAlias &&
          searchEntry.entityAlias.length > 0) {
          entityNameParts = [...entityNameParts, ...['',' - ','', searchEntry.entityAlias]];
        }
        return entityNameParts;
      } else if(searchEntry.entityAlias &&
        entityAliasLowerCase.indexOf(searchQueryLowerCase) >= 0){

        return ['', searchEntry.entityName, '',' - ','',
          searchEntry.entityAlias.slice(0, entityAliasLowerCase.indexOf(searchQueryLowerCase)),
          searchEntry.entityAlias.slice(entityAliasLowerCase.indexOf(searchQueryLowerCase), entityAliasLowerCase.indexOf(searchQueryLowerCase) + searchQuery.length),
          searchEntry.entityAlias.slice(entityAliasLowerCase.indexOf(searchQueryLowerCase) + searchQuery.length)];

      }

    } else {

      if(searchEntry.entityName &&
        entityNameLowerCase.indexOf(searchQueryLowerCase) >= 0) {

        let entityNameParts = ['', searchEntry.entityName.slice(0, entityNameLowerCase.indexOf(searchQueryLowerCase)),
          searchEntry.entityName.slice(entityNameLowerCase.indexOf(searchQueryLowerCase), entityNameLowerCase.indexOf(searchQueryLowerCase) + searchQuery.length),
          searchEntry.entityName.slice(entityNameLowerCase.indexOf(searchQueryLowerCase) + searchQuery.length)];

        if(searchEntry.entityAlias &&
          searchEntry.entityAlias.length > 0) {
          entityNameParts = [...entityNameParts, ...['',' - ','', searchEntry.entityAlias]];
        }
        return entityNameParts;
      } else if(searchEntry.entityAlias &&
        entityAliasLowerCase.indexOf(searchQueryLowerCase) >= 0){

        return ['', searchEntry.entityName, '',' - ','',
          searchEntry.entityAlias.slice(0, entityAliasLowerCase.indexOf(searchQueryLowerCase)),
          searchEntry.entityAlias.slice(entityAliasLowerCase.indexOf(searchQueryLowerCase), entityAliasLowerCase.indexOf(searchQueryLowerCase) + searchQuery.length),
          searchEntry.entityAlias.slice(entityAliasLowerCase.indexOf(searchQueryLowerCase) + searchQuery.length)];

      }
    }
  }

  public getGroupMatchingParts(searchQuery: string, groupName: string) : string[] {
    const escapedSearchTerm = this.escapeRegExp(searchQuery);
    const regexp = new RegExp(`(^${escapedSearchTerm})(.*)`, 'i');

    const result = groupName && searchQuery ?
      (groupName.match(regexp) || []).slice(1) :
      [];
    return result;
  }

  public getBranchMatchingParts(searchQuery: string, branchName: string, isLocalization: boolean): string[] {
    const escapedSearchTerm = this.escapeRegExp(searchQuery);
    const regexp = new RegExp(`(^${escapedSearchTerm})(.*)`, 'i');
    let localizedBranchName: string;
    if (isLocalization) {
      localizedBranchName = this.translationService.translate('REPORTS.GRID.STATUS.' + branchName);
    } else {
      localizedBranchName = branchName;
    }
    let resultToReturn: string[];
    if (localizedBranchName && searchQuery) {
      resultToReturn = (localizedBranchName.match(regexp) || []).slice(1);
    } else {
      resultToReturn = [];
    }
    return resultToReturn;
  }

  private escapeRegExp(str: string) : string {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }

  private async loadSearchEntries() {
    this.herds = [];
    let herdsResponse = await this.getHerdManagementHerds();
    if(herdsResponse != null) {
      this.herds = herdsResponse.herds;
    }
    this.searchEntitiesResult = await this.getSearchEntities();
  }

  private async getHerdManagementHerds() : Promise<HerdManagementHerdsResponse> {
    let response =  await this.restApiService.sendGetMethod<HerdManagementHerdsResponse>(SearchService.HerdManagementHerdsRoute, this.authService.currentUserAuthState.authDetails);
    if(response.status == 200 &&
       response.responseBody != null){
      this.isHerdManagementDisplayed = response.responseBody.herds.length > 0;
      return response.responseBody;
    } else {
      this.isHerdManagementDisplayed = false;
      return null;
    }
  }

  private async getSearchEntities() : Promise<SearchEntitiesResult> {
    let response = await this.restApiService.sendGetMethodAny<SearchEntitiesResult>(SearchService.SearchRoute, this.authService.currentUserAuthState.authDetails);
    if(response.status == 200) {
      return response.responseBody;
    }
    else {
      return null;
    }
  }

  private filterAnimal(searchQuery: string, searchEntry: SearchEntry, filterByAlias: boolean) {
    if(searchEntry.entityType === EntityType.group || searchEntry.entityType === EntityType.branch) {
      return false;
    }

    if(this.herds.length > 0 && !filterByAlias) {
      if(searchEntry.entityName.length <= SearchService.AnimalNameLengthInHerd) {
        return searchEntry.entityName.toLowerCase().includes(searchQuery.toLowerCase());
      } else {
        let entityNameLastPart = searchEntry.entityName.substr(searchEntry.entityName.length - SearchService.AnimalNameLengthInHerd);
        return entityNameLastPart.toLowerCase().includes(searchQuery.toLowerCase());
      }
    } else {
      let animalSearchValue = filterByAlias ? searchEntry.entityAlias : searchEntry.entityName;

      if (animalSearchValue == null) {
        return false;
      }

      if (this.isSearchQueryNumber(searchQuery) &&
        !StringUtils.isNumber(animalSearchValue[0])) {
        return animalSearchValue.toLowerCase().includes(searchQuery.toLowerCase());
      } else {
        return animalSearchValue.toLowerCase().startsWith(searchQuery.toLowerCase());
      }
    }
  }

  private filterGroup(searchQuery: string, searchEntry: SearchEntry) {
    if(searchEntry.entityType !== EntityType.group ||
      searchEntry.entityName == null)
    {
      return false;
    }
    return searchEntry.entityName.toLowerCase().startsWith(searchQuery.toLowerCase());
  }

  private filterBranch(searchQuery: string, searchEntry: SearchEntry) {
    if (searchEntry.entityType !== EntityType.branch) {
      return false;
    }
    if (searchEntry.isLocalization) {
      return this.translationService.translate('REPORTS.GRID.STATUS.' + searchEntry.entityName).toLowerCase().startsWith(searchQuery.toLowerCase());
    } else {
      return searchEntry.entityName.toLowerCase().startsWith(searchQuery.toLowerCase());
    }

  }

  private isSearchQueryNumber(searchQuery: string):boolean {
    for (let i =0; i<searchQuery.length; i++){
      if(!StringUtils.isNumber(searchQuery[i])){
        return false;
      }
    }
    return true;
  }
}

export enum EntityType {
  branch = "branch",
  group = "group",
  cow = "cow",
  male = "male"
}
