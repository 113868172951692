<modal-dialog-template [modalWidth]="555"
                       [modalHeight]="479"
                       (onEnterButtonClick)="saveChanges()"
                       (onEscapeButtonClick)="closeDialog()"
                       [isOpen]="isPopupOpen">
  <div class="edit-hardware-popup-container-wrapper">
    <div class="edit-hardware-popup-container">
      <div class="edit-hardware-body">
        <div class="header">
          <div class="title">{{ 'REGISTRATION.HARDWARE_TYPE.' + HardwareDetails.hardwareType | translate }}</div>
          <div class="remove-icon" (click)="closeDialog()"></div>
        </div>
        <div class="form-group">
          <div class="form-item">
            <div class="item-title" [attr.translation-id]="'REGISTRATION.FLOW.HARDWARE_TABLE.HARDWARE_DESCRIPTION'">
              {{ 'REGISTRATION.FLOW.HARDWARE_TABLE.HARDWARE_DESCRIPTION' | translate }}
            </div>
            <input [(ngModel)]="HardwareDetails.hardwareDesc" (ngModelChange)="clearErrors();" [ngClass]="{'error': errors && errors[0] && errors[0].fieldName === 'hardwareDesc' }" />
          </div>
          <div class="form-item">
            <div class="item-title disabled" [attr.translation-id]="'REGISTRATION.FLOW.HARDWARE_TABLE.HARDWARE_DESCRIPTION'">
              {{ 'REGISTRATION.FLOW.HARDWARE_TABLE.HARDWARE_TYPE' | translate }}
            </div>
            <input disabled [value]="('REGISTRATION.HARDWARE_TYPE.' + HardwareDetails.hardwareType | translate)"/>
          </div>
        </div>
        <div class="form-group">
          <div class="form-item">
            <div class="item-title disabled" [attr.translation-id]="'REGISTRATION.FLOW.HARDWARE_TABLE.IP_ADDRESS'">
              {{ 'REGISTRATION.FLOW.HARDWARE_TABLE.IP_ADDRESS' | translate }}
            </div>
            <input disabled [value]="HardwareDetails.ip"/>
          </div>
          <div class="form-item">
            <div class="item-title disabled" [attr.translation-id]="'REGISTRATION.FLOW.HARDWARE_TABLE.SERIAL_NUMBER'">
              {{ 'REGISTRATION.FLOW.HARDWARE_TABLE.SERIAL_NUMBER' | translate }}
            </div>
            <input disabled [value]="HardwareDetails.serialNumber"/>
          </div>
        </div>
        <div class="form-group">
          <div class="form-item">
            <div class="item-title  disabled" [attr.translation-id]="'REGISTRATION.FLOW.HARDWARE_TABLE.SOFTWARE_VERSION'">
              {{ 'REGISTRATION.FLOW.HARDWARE_TABLE.SOFTWARE_VERSION' | translate }}
            </div>
            <input disabled [value]="HardwareDetails.ddVersion"/>
          </div>
          <div class="form-item">
            <div class="item-title disabled" [attr.translation-id]="'REGISTRATION.DIALOG.EDIT.MAC_ADDRESS'">
              {{ 'REGISTRATION.DIALOG.EDIT.MAC_ADDRESS' | translate }}
            </div>
            <input disabled [value]="HardwareDetails.mac"/>
          </div>
        </div>
        <div class="errors-div" *ngIf="errors && errors[0]">
          <div class="error-text" [attr.translation-id]="'SYSTEM.MANAGEMENT.ERROR_BOLD: '"><b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}:</b> {{ 'REGISTRATION.DIALOG.' + errors[0].key | translate }}</div>
        </div>
      </div>
      <div class="edit-hardware-buttons">
        <div class="apply-button" [attr.translation-id]="'COMMON.BUTTON.APPLY'" (click)="saveChanges()"><div class="text">{{ 'COMMON.BUTTON.APPLY' | translate }}</div></div>
        <div class="cancel-button" [attr.translation-id]="'COMMON.BUTTON.CANCEL'" (click)="closeDialog()">{{ 'COMMON.BUTTON.CANCEL' | translate }}</div>
      </div>
    </div>
  </div>
</modal-dialog-template>
