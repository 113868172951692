import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {PopupViewStateService} from '../../services/ui/view-state/popup-view-state.service';

@Component({
  selector: 'layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  constructor(private headerElementRef: ElementRef,
              private popupViewStateService : PopupViewStateService) { }

  public ngOnInit() {
    this.popupViewStateService.registerComponent("header", this.headerElementRef);
  }

  public ngOnDestroy(): void {
    this.popupViewStateService.unregisterComponent('header');
  }
}
