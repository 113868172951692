import { Injectable } from '@angular/core';
import {HttpResponseResult, HttpResponseResultNoBody, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from "../auth/auth.service";
import {AlertType} from './model/alert-type.enum';
import {DashboardSaveModel} from './model/dashboard.interface';

export interface IMaintenanceCallsResponse {
  maintenanceCalls: IMaintenanceCalls[];
}

export interface IMaintenanceCalls {
  alertType: string;
  alertLocalization: string;
  entityType: AlertType;
  alertDateTime: number;
  entityNumber: string;
  entityId: number;
  filterValue?: string;
}

export class MaintenanceCallsItemModel implements IMaintenanceCalls {
  constructor(public alertType: string,
              public alertLocalization: string,
              public entityType: AlertType,
              public alertDateTime: number,
              public entityNumber: string,
              public entityId: number,
              public filterValue: string) {
  }
}

export enum MaintenanceCallsMajorAlertType {
  Update = 'Update',
  TagSwUpdate = 'TagSwUpdate'
}

export interface IDashboardTagsResponse {
  totalCows: number;
  withTags: number;
  withoutTags: number;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private static readonly MaintenanceCallsRoute = '/rest/api/dashboard?projection=maintenance';
  private static readonly DashboardTagsRoute = `/rest/api/dashboard?projection=tags`;
  private static readonly FarmUpgradeRoute = `/rest/api/server/upgrade`;
  private static readonly DashboardSaveRoute = `/rest/api/dashboard`;

  constructor(private restApiService: RestApiService, private authService: AuthService) { }

  public getMaintenanceCalls(): Promise<HttpResponseResult<IMaintenanceCallsResponse>> {
    return this.restApiService.sendGetMethod<IMaintenanceCallsResponse>(DashboardService.MaintenanceCallsRoute,this.authService.currentUserAuthState.authDetails);
  }

  public getTags(): Promise<HttpResponseResult<IDashboardTagsResponse>> {
    return this.restApiService.sendGetMethod<IDashboardTagsResponse>(DashboardService.DashboardTagsRoute, this.authService.currentUserAuthState.authDetails);
  }

  // tslint:disable-next-line:no-any
  public farmUpgrade(): Promise<HttpResponseResultNoBody> {
    return this.restApiService.sendPutMethodAny(DashboardService.FarmUpgradeRoute, this.authService.currentUserAuthState.authDetails, {});
  }

  public saveDashboard(dashboardSaveModel: DashboardSaveModel) : Promise<HttpResponseResultNoBody> {
    return this.restApiService.sendPutMethod(DashboardService.DashboardSaveRoute, this.authService.currentUserAuthState.authDetails, dashboardSaveModel);
  }
}

