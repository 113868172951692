<div class="last-updated-header">
  <last-updated [isPastReport]="isPastReport" (onRefreshClick)="reloadDefault()" *ngIf="report">
    <ng-container content *ngIf="isPastReport ? report.meta.reportTime : report.meta.lastUpdated">{{ (isPastReport ? report.meta.reportTime : report.meta.lastUpdated) | epochDateTime: isTimeAgoORDateTime(isPastReport)}}</ng-container>
  </last-updated>
</div>
<div class="report-header">
  <report-actions-dropdown [reportActions]="report?.meta?.reportActions"
                           [reportBatchActions]="report?.meta?.reportBatchActions"
                           [isActionsOnLeftSide]="true"
                           [report]="report"
                           [reportContext]="this"
                           [reportQuery]="reportQuery"
                           (onShowPastReport)="showPastReport($event)"></report-actions-dropdown>
  <grid-summary [summaryTitle]="report === null ? null : 'REPORTS.HEADER.' + report?.meta.reportName"
                [icon]="GridSummaryIcon.Cow"
                [rowCount]="report?.meta.rowTotalAfterFilter"
                [rowTotal]="report?.meta.rowTotal"></grid-summary>
  <report-filters-container [report]="report" (onAction)="onHeaderAction($event)" (onSetupChanged)="reload(true, null)" [defaultFilterValue]="defaultFilter"></report-filters-container>
</div>
<report-grid #reportGrid
                      *ngIf="report"
                      [attr.sh-id]="'report-table-' + report.meta.reportName"
                      [reportContext]="this"
                      [report]="report"
                      [reportQuery]="reportQuery"
                      [rowHeight]="60"
                      (onReportGridValueCellClick)="onReportGridValueCellClick($event.row)"
                      (onReportQueryChanged)="onReportQueryChanged()">
</report-grid>
