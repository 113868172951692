import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DIALOG_TRANSITIONS} from '../../../animations/animations';

export enum NotificationDialogTheme {
  red = 'red',
  blue = 'blue',
  olive = 'olive',
  green = 'green',
  turquoiseInverse = 'turquoiseInverse',
  beef = 'beef',
  dairy = 'dairy'
}

@Component({
  selector: 'notification-dialog-template',
  templateUrl: './notification-dialog-template.component.html',
  styleUrls: ['./notification-dialog-template.component.scss'],
  animations: [DIALOG_TRANSITIONS]
})
export class NotificationDialogTemplateComponent implements OnInit {

  public isOpen: boolean = false;

  @Input()
  public modalWidth: number | string = 580;

  @Input()
  public modalHeight: number | string = 'auto';

  @Input()
  public isEscapeCloseDialog: boolean = false;

  @Input()
  public isCloseForbidden: boolean = false;

  @Input()
  public theme: NotificationDialogTheme;

  @Input()
  public shIdPostfix: string;

  @Output()
  public onEnterClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  public ngOnInit() {

  }

  public enterClick(): void {
    this.onEnterClick.emit();
  }

  public close() {
    if (!this.isCloseForbidden) {
      this.isOpen = false;
    }
  }
}
