import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {IDialog} from '../../../../../services/dialogs/model/dialog.model';
import {ConfirmCullingAssignedTagDialogService} from './confirm-culling-assigned-tag-dialog.service';

@Component({
  selector: 'confirm-culling-assigned-tag-dialog',
  styleUrls: ['./confirm-culling-assigned-tag-dialog.component.scss'],
  templateUrl: './confirm-culling-assigned-tag-dialog.component.html'
})
export class ConfirmCullingAssignedTagDialogComponent  implements IDialog, OnInit, OnDestroy {
  public subscription: Subscription;

  public isOpen;

  constructor(private readonly confirmCullingAssignedTagDialogService: ConfirmCullingAssignedTagDialogService) {

  }

  public ngOnInit(): void {
    this.subscription = this.confirmCullingAssignedTagDialogService.popupShown.subscribe(value => {
      if (value) {
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
    })
  }

  public close(result: boolean): void {
    this.confirmCullingAssignedTagDialogService.closePopup(result);
  }

  public showDialog() {
    this.isOpen = true;
  }

  public ngOnDestroy(): void {
    this.close(false);
    this.subscription.unsubscribe();
  }
}
