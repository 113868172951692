<span [attr.translation-id]="'BRANCH.GRAPH.DETAILS.ANIMALS_IN_BRANCH'">
  {{'BRANCH.GRAPH.DETAILS.ANIMALS_IN_BRANCH'| translate}}:
</span>
<span class="bold">
  {{ branchGraphDetails.numberOfAnimals }}
</span>
<div class="icon-cow"></div>
<span class="bold bold-sep">
  {{ branchGraphDetails.numberOfAnimalsWithTags }}
</span>
<div class="icon-with-tag"></div>
