import {Directive, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {PopupViewStateService} from '../../services/ui/view-state/popup-view-state.service';

@Directive({
  selector: '[outsideClickIgnore]'
})
export class OutsideClickIgnoreDirective implements OnInit, OnDestroy {

  constructor(public elementRef: ElementRef,
              private popupViewStateService:PopupViewStateService) {

  }

  public ngOnInit(): void {
    this.popupViewStateService.registerOutsideClickIgnoreElement(this.elementRef.nativeElement);
  }

  public ngOnDestroy(): void {
    this.popupViewStateService.unregisterOutsideClickIgnoreElement(this.elementRef.nativeElement);
  }
}
