<div class="date-time-wrapper">
  <popup-template [isOpen]="isOpen"
                  [adjustPopupContentWidth]="false"
                  (close)="onOutsideClick()">
    <ng-container header>
      <div class="date-time-picker-header" [ngStyle]="{ background: isDisabled ? '' : backgroundColor}" [class]="{'header-color-theme': this.themeColor}" (click)="onHeaderClick()" [ngClass]="{ 'error': markAsError,
                                                                              'disabled': isDisabled}">
        <input class="value"
               [ngClass]="{'value-no-padding' : noLeftPadding}"
               [(ngModel)]="timeText"
               (keyup)="onSelectedDateTimeTextChange($event)"
               (keydown)="onSelectedDateTimeTextChange($event)"
               (paste)="onPaste($event)"
               [placeholder]="'HH:MM'"
               [attr.sh-id]="'date-time-picker-' + shIdPostfix"
               [disabled]="isDisabled"
               (focus)="onFocus()"
               (blur)="onBlur()"/>
        <div class="icons-wrapper">
          <div class="calendar-icon icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path [attr.fill]="themeColor" d="M11.687 7.282c.043.222.566 2.725.88 4.231 1.5.314 4.016.836 4.235.885.29.057.425.183.425.414 0 .231-.143.366-.425.424l-5.202 1.09c-.343.07-.7.025-1.014-.126-.63-.307-.973-1-.834-1.687.17-.838 1.024-4.954 1.087-5.231.062-.277.192-.42.424-.42.231 0 .361.143.424.42zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10c-.007 5.52-4.48 9.993-10 10zm0-18a8 8 0 1 0 8 8 8.007 8.007 0 0 0-8-8z"/>
            </svg>
          </div>
          <am-pm-selection [selectedEpoch]="selectedEpoch"
                           [themeColor]="themeColor"></am-pm-selection>
          <div class="remove-icon-wrapper" *ngIf="isRemoveIconShown">
            <div class="remove-icon-circle icon" *ngIf="isRemoveIconShown" (click)="clearDateValue($event)">
              <div class="remove-icon"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container popup-content>
      <div class="date-time-picker-content">
        <time-selection [selectedEpoch]="selectedEpoch"
                        [hoursMode]="translationService.hoursMode"
                        (epochChanged)="onTimeEpochChanged($event)"></time-selection>
      </div>
    </ng-container>
  </popup-template>
</div>
