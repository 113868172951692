<corner-container *ngIf="true"
                  [attr.sh-id]="'add_female_dialog_finishing'"
                  [cornerColor]="CornerColor.DeepPurple"
                  (onCornerButtonClick)="finishingStep === AddFemaleFinishingSteps.Entry ? goToStep(AddFemaleFinishingSteps.Farm) : addFinishingFemale()"
                  [footerHeight]="50"
                  [bodyBackground]="CornerContainerBodyBackground.LightPurple">
  <ng-container container-body>
    <div class="add-animal-container-finishing">
      <ng-container [ngSwitch]="finishingStep">

        <!-- Entry Or Birthdate tab -->
        <add-female-finishing-entry-tab
          *ngSwitchCase="AddFemaleFinishingSteps.Entry"
          [addFinishingFemaleModel]="addFinishingFemaleModel"
          [groupsDropdownListItems]="groupsDropdownListItems"
          [rfidsTagsNumbers]="rfidsTagsNumbers"
          [scrTagsNumbers]="scrTagsNumbers"
          [addFemaleFinishingMetadata]="addFemaleFinishingMetadata"
          [errors]="errors"
          (onClearErrors)="clearErrors()">
        </add-female-finishing-entry-tab>

        <!-- Farm Tab -->
        <add-female-finishing-farm-tab
          *ngSwitchCase="AddFemaleFinishingSteps.Farm"
          [groupsDropdownListItems]="groupsDropdownListItems"
          [addFinishingFemaleModel]="addFinishingFemaleModel"
          [isKgValueDisplayed]="isKgValueDisplayed()"
          [errors]="errors"
          (onClearErrors)="clearErrors()">
        </add-female-finishing-farm-tab>

        <!-- Finishing Tab -->
        <add-female-finishing-finishing-tab
          *ngSwitchCase="AddFemaleFinishingSteps.Finishing"
          [addFinishingFemaleModel]="addFinishingFemaleModel"
          [groupsDropdownListItems]="groupsDropdownListItems"
          [errors]="errors"
          (onClearErrors)="clearErrors()">
        </add-female-finishing-finishing-tab>

      </ng-container>
    </div>
  </ng-container>
  <ng-container container-footer>
    <!-- Error container to display all errors for form -->
    <div class="errors-container">
      <div class="error-text" *ngIf="errors && errors[0]" [attr.sh-id]="'error-text-' + errors[0].key">
        <b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}: </b>{{ 'MANAGE.COWS.ACTIVE.WIZARD.ERRORS.' + errors[0].key | translate }}
      </div>
    </div>
    <!-- Buttons Container to contain all the actions of the container -->
    <div class="navigation-buttons">
      <div class="container-buttons">
        <div class="check-button" [attr.sh-id]="'apply_add_female_finishing'" *ngIf="finishingStep !== AddFemaleFinishingSteps.Entry" (click)="addFinishingFemale()">
          <div class="check-icon"></div>
        </div>
        <div class="check-button" [attr.sh-id]="'button_next'" *ngIf="finishingStep === AddFemaleFinishingSteps.Entry" (click)="goToStep(AddFemaleFinishingSteps.Farm)">
          <div class="next-icon"></div>
        </div>
      </div>
      <div class="seperator" *ngIf="finishingStep !== AddFemaleFinishingSteps.Entry"></div>
      <div class="navigation-button" [attr.sh-id]="'button_next'" *ngIf="finishingStep !== AddFemaleFinishingSteps.Entry && finishingStep !== AddFemaleFinishingSteps.Finishing" (click)="goToStep(finishingStep + 1)">
        <div class="next-icon"></div>
      </div>
      <div class="navigation-button" [attr.sh-id]="'button_previous'" *ngIf="finishingStep !== AddFemaleFinishingSteps.Entry" (click)="performNavigationToStep(finishingStep - 1)">
        <div class="previous-icon"></div>
      </div>
    </div>
  </ng-container>
</corner-container>


<div class="steps-wizard">
  <div class="summary-div">
    <div class="step-div-text">
      <div [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER'" *ngIf="isCurrentSummaryDisplayed(AddFemaleFinishingSteps.Entry)"><b>{{ 'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER' | translate }}:</b> {{ addFinishingFemaleModel?.animalNumber }}</div>
      <div [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'" *ngIf="isCurrentSummaryDisplayed(AddFemaleFinishingSteps.Entry)"><b>{{ 'MANAGE.COWS.ACTIVE.WIZARD.GROUP' | translate }}:</b> {{ addFinishingFemaleModel?.group?.displayValue }}</div>
    </div>
    <div class="step-div-text">
      <div [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE'" *ngIf="isCurrentSummaryDisplayed(AddFemaleFinishingSteps.Farm) && addFinishingFemaleModel.birthDate.epochDate"><b>{{ 'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE' | translate }}:</b> {{ addFinishingFemaleModel?.birthDate.epochDate | epochDateTime:dateTimeFormatEnum.Date  }}</div>
      <div [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE'" *ngIf="isCurrentSummaryDisplayed(AddFemaleFinishingSteps.Farm) && !addFinishingFemaleModel.birthDate.epochDate"><b>{{ 'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE' | translate }}:</b> -</div>
      <div [attr.translation-id]="'MANAGE.FINISHING.ACTIVE.WIZARD.WEIGHT'" *ngIf="isCurrentSummaryDisplayed(AddFemaleFinishingSteps.Farm)"><b>{{ 'MANAGE.FINISHING.ACTIVE.WIZARD.WEIGHT' | translate }}:</b> {{ getWeightValueAccordingToLocalization() }} {{ isWeightValueExists() ? utilsService.getUnitType() : '' }}</div>
    </div>
    <div class="step-div-text"></div>
  </div>
  <div class="steps-graph-div">
    <div class="step-icon"
         (click)="checkIfCanNavigateToStep(AddFemaleFinishingSteps.Entry)"
         [ngClass]="{ CurrentFinishingStep: finishingStep === AddFemaleFinishingSteps.Entry, ActiveFinishingStep: AddFemaleFinishingSteps.Entry < finishingStep }">
      <div class="finishing-cow-icon" [ngClass]="{ CurrentFinishingStep: finishingStep === AddFemaleFinishingSteps.Entry, ActiveFinishingStep: AddFemaleFinishingSteps.Entry < finishingStep }"></div>
    </div>
    <div class="seperator" [ngClass]="{ active: finishingStep >= AddFemaleFinishingSteps.Farm }"></div>
    <div class="step-icon"
         (click)="checkIfCanNavigateToStep(AddFemaleFinishingSteps.Farm)"
         [ngClass]="{ CurrentFinishingStep: finishingStep === AddFemaleFinishingSteps.Farm, ActiveFinishingStep: AddFemaleFinishingSteps.Farm < finishingStep }">
      <div class="finishing-farm-icon" [ngClass]="{ CurrentFinishingStep: finishingStep === AddFemaleFinishingSteps.Farm, ActiveFinishingStep: AddFemaleFinishingSteps.Farm < finishingStep }"></div>
    </div>
    <div class="seperator" [ngClass]="{ active: finishingStep >= AddFemaleFinishingSteps.Finishing }"></div>
    <div class="step-icon"
         (click)="checkIfCanNavigateToStep(AddFemaleFinishingSteps.Finishing)"
         [ngClass]="{ CurrentFinishingStep: finishingStep === AddFemaleFinishingSteps.Finishing }">
      <div class="finishing-icon" [ngClass]="{ CurrentFinishingStep: finishingStep === AddFemaleFinishingSteps.Finishing, ActiveFinishingStep: AddFemaleFinishingSteps.Finishing < finishingStep }"></div>
    </div>
  </div>
</div>
