import {AfterViewInit, Component, HostBinding, OnDestroy, OnInit, VERSION} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigService } from './services/config/config.service';
import { TranslationService } from './services/translations/translation.service';
import { Subscription } from 'rxjs';
import {ApplicationEventsService} from "./services/application-events/application-events.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly subscription: Subscription = new Subscription();

  constructor(private router: Router,
              public configService: ConfigService,
              private translationService: TranslationService,
              private readonly applicationEventsService: ApplicationEventsService) {
    this.preventBrowserBackButton();
  }

  public ngOnInit(): void {
    // tslint:disable-next-line:no-console
    console.log('%c Angular version: ' + VERSION.full + ' ', 'background: #222; color: #FF0000; font-size: 20px;');
    if (this.configService.configuration.clientVersion != "") {
      // tslint:disable-next-line:no-console
      console.log('%c SenseHub Client Version: ' + this.configService.configuration.clientVersion + ' ', 'background: #222; color: #bada55; font-size: 20px;');
    }
  }

  public ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

  public ngAfterViewInit(): void {
      this.applicationEventsService.afterAppInitSubject.next();
  }

  @HostBinding('@.disabled')
  public get animationsDisabled() : boolean {
    return this.configService.disableAnimations;
  }

  public preventBrowserBackButton() {
    this.subscription.add(this.router.events
      .subscribe(value => {
        if(value instanceof NavigationEnd) {
          history.pushState(null, null, location.href);
          window.onpopstate = function () {
            history.go(1);
          };
        }
      }));
  }
}
