<modal-dialog-template [isOpen]="isOpen"
                       [modalWidth]="modalWidth"
                       [modalHeight]="modalHeight"
                       (onEnterButtonClick)="enterClick()"
                       (onEscapeButtonClick)="close()">
  <div class="notification-dialog" [ngClass]="theme" *ngIf="isOpen" [attr.sh-id]="'notification_dialog_' + shIdPostfix" @dialog-transitions>
    <div class="notification-dialog-close">
      <div class="close-icon" *ngIf="!isCloseForbidden" [attr.sh-id]="'notification_dialog_close_' + shIdPostfix" (click)="close()"></div>
    </div>
    <div class="notification-dialog-header">
      <ng-content select="[header]"></ng-content>
    </div>
    <div class="notification-dialog-body">
      <ng-content select="[body]"></ng-content>
    </div>
  </div>
</modal-dialog-template>
