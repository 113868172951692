import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  ITagsStartUpdateRequest,
  ITagsToUpdateVersions,
  ITagsUpdateProgressStatus, ITagsUpdateSummary,
  UpdateStatusType
} from '../../../services/tags-provisioning/model/tags-provisioning.model';
import {TagsProvisioningService} from '../../../services/tags-provisioning/tags-provisioning.service';
import {DateTimeFormatEnum} from 'src/app/common/pipes/epoch-date-time.pipe';
import {Subscription} from 'rxjs';
import {TAGS_UPDATE_POPUP_TRANSITIONS} from '../../../common/animations/animations';
import {GoogleAnalyticsService} from '../../../services/google-analytics/google-analytics.service';

@Component({
  selector: 'tags-provisioning',
  templateUrl: './tags-provisioning.component.html',
  styleUrls: ['./tags-provisioning.component.scss'],
  animations: [TAGS_UPDATE_POPUP_TRANSITIONS]
})
export class TagsProvisioningComponent implements OnInit, OnDestroy {

  private isClickedFromOuterButtonToOpenUpdatePopup: boolean = false;

  private tagsToUpdateVersions : ITagsToUpdateVersions;

  private tagsToUpdateVersionsSubscription: Subscription;

  private tagsUpdateCompletedSubscription: Subscription;

  private tagsUpdateErrorSubscription: Subscription;

  private openTagSwUpdateSubscription: Subscription;

  public isUpdateVisible : boolean;

  public status: UpdateStatusType;

  public progressStatus: ITagsUpdateProgressStatus;

  public UpdateStatusType = UpdateStatusType;

  public DateTimeFormatEnum = DateTimeFormatEnum;

  public isOpen : boolean;

  public isPerformUpdateFromMaintenance: boolean;

  public updateAssignedTagsOnly: boolean = true;

  public dialogTagsUpdateSummary : ITagsUpdateSummary;

  public showUpdateErrorDialog : boolean;

  constructor(private tagsProvisioningService:TagsProvisioningService,
              private googleAnalyticsService:GoogleAnalyticsService) { }

  public async ngOnInit() {
      this.subscribeToEntitiesListUpdates();
      let tagsUpdateStatus = await this.tagsProvisioningService.getTagsUpdateStatus(1);
      await this.updateTagsUpdateProgressStatus(tagsUpdateStatus);
  }

  public ngOnDestroy(): void {
    this.unsubscribeFromEntitiesListUpdates();
  }

  public onOutsideClick() {
    if(this.isOpen){
      this.toggleTagsUpdateDialog();
    }
  }

  public async toggleTagsUpdateDialog() {
    if (this.isOpen) {
      this.closeTagsUpdateDialog();
      if (this.isPerformUpdateFromMaintenance && this.status !== 'inProgress') {
        this.isUpdateVisible = false;
        this.isPerformUpdateFromMaintenance = false;
      }
      else if(this.isPerformUpdateFromMaintenance && this.status === 'inProgress') {
        this.isUpdateVisible = true;
        this.isPerformUpdateFromMaintenance = false;
      }
    } else {
      this.isClickedFromOuterButtonToOpenUpdatePopup = true;
      this.googleAnalyticsService.send('TopMenu', 'Open', 'TagsUpdate');
      this.isOpen = true;
      let currentStatus = this.status;

      let tagsUpdateProgressStatus = await this.tagsProvisioningService.getTagsUpdateStatus(1);
      if(tagsUpdateProgressStatus != null) {
        this.updateTagsUpdateProgressStatus(tagsUpdateProgressStatus);
        if (this.status == 'notStarted' && currentStatus == UpdateStatusType.inProgress) {
          this.status = UpdateStatusType.inProgress;
        }
        this.isClickedFromOuterButtonToOpenUpdatePopup = false;
      } else {
        this.isClickedFromOuterButtonToOpenUpdatePopup = false;
      }
    }
  }

  public closeTagsUpdateDialog() {
    this.isOpen = false;
  }

  public startUpdateClick() {
    const startUpdateDetails: ITagsStartUpdateRequest = {
      assignedTagsOnly: this.updateAssignedTagsOnly
    };

    this.status = UpdateStatusType.inProgress;
    this.tagsProvisioningService.tagsUpdateStatus.next(true);
    this.closeTagsUpdateDialog();
    this.tagsProvisioningService.startUpdate(startUpdateDetails)
      .then(() => {

      })
      .catch((error) => {
        this.tagsProvisioningService.tagsUpdateStatus.next(false);
      });
  }

  public stopUpdateClick() {
    this.closeTagsUpdateDialog();
    this.tagsProvisioningService.stopUpdate(1)
      .then(() => {
        this.isUpdateVisible = false;
        this.status = UpdateStatusType.notStarted;
        this.tagsProvisioningService.tagsUpdateStatus.next(false);
      })
      .catch((error) => {

      });
  }

  private async updateTagsUpdateProgressStatus(data: ITagsUpdateProgressStatus) {
    if(data) {
      this.status = data.status;
      this.progressStatus = data;
    }
    this.tagsToUpdateVersions = await this.tagsProvisioningService.getTagsVersions();
    if (this.status == UpdateStatusType.notStarted) {
      if (this.tagsToUpdateVersions.versions.length > 0 ||
        this.isPerformUpdateFromMaintenance) {
        this.isUpdateVisible = true;
      } else {
        this.isOpen = false;
        this.isUpdateVisible = false;
      }
    } else if (this.status == 'inProgress') {
      this.isUpdateVisible = true;
    }
  }

  private subscribeToEntitiesListUpdates() {
    this.tagsToUpdateVersionsSubscription = this.tagsProvisioningService.tagsUpdateProgressStatus
      .subscribe((data: ITagsUpdateProgressStatus) => {
        this.updateTagsUpdateProgressStatus(data);
      });

    this.tagsUpdateCompletedSubscription = this.tagsProvisioningService.tagsUpdateCompleted
      .subscribe((data: ITagsUpdateSummary) => {
        if (this.status != "inProgress") {
          return;
        }
        this.isOpen = false;
        this.isUpdateVisible = false;
        this.tagsProvisioningService.tagsUpdateStatus.next(false);
        this.dialogTagsUpdateSummary = data;
      });

    this.tagsUpdateErrorSubscription = this.tagsProvisioningService.tagsSwUpdateError
      .subscribe(() => {
        if (this.status != "inProgress") {
          return;
        }
        this.status = null;
        this.isOpen = false;
        this.isUpdateVisible = false;
        this.showUpdateErrorDialog = true;
        this.tagsProvisioningService.tagsUpdateStatus.next(false);
      });
    this.openTagSwUpdateSubscription = this.tagsProvisioningService.openTagSwUpdate.subscribe(value => {
      this.isPerformUpdateFromMaintenance = true;
      this.isUpdateVisible = true;
      this.toggleTagsUpdateDialog();
    })
  }

  private unsubscribeFromEntitiesListUpdates() {
    this.tagsToUpdateVersionsSubscription.unsubscribe();
    this.tagsUpdateCompletedSubscription.unsubscribe();
    this.tagsUpdateErrorSubscription.unsubscribe();
    this.openTagSwUpdateSubscription.unsubscribe();
  }
}
