import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textLimit'
})
export class TextLimitPipe implements PipeTransform {

  // tslint:disable-next-line:no-any
  public transform(text: string, len: number, addEtc?: boolean): any {

    if( text == null)
      return '';
    if (text.length <= len)
      return text;
    return text.substring(0, len) + (addEtc ? "..." : "");
  }

}
