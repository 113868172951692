import {APP_INITIALIZER} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {DatePipe} from '@angular/common';

import { MockHttpInterceptorProvider } from './testing/mock-http-interceptor';
import { BootService } from './services/boot/boot.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {WINDOW_PROVIDERS} from './utils/window-utils';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {BsDropdownModule } from 'ngx-bootstrap';
import { MockHttpManagementComponent } from './testing/testing-layout/mock-http-management/mock-http-management.component';
import { TestingLayoutComponent } from './testing/testing-layout/testing-layout.component';
import { MockDashboardReportsComponent } from './testing/testing-layout/mock-dashboard-reports/mock-dashboard-reports.component';
import { EpochDateTimePipe } from './common/pipes/epoch-date-time.pipe';
import {ClipboardModule} from 'ngx-clipboard';
import {RoundPipe} from './common/pipes/round.pipe';
import {SetupProgressComponent} from './setup-progress/setup-progress.component';
import { DurationPipe } from './common/pipes/duration.pipe';
import { TimeAgoTranslatedPipe } from './common/pipes/time-ago-translated.pipe';
import { TestingRefreshBlankComponent } from './testing/testing-refresh-blank/testing-refresh-blank.component';
import { MockPrintExportComponent } from './testing/testing-layout/mock-print-export/mock-print-export.component';
import { MockTranslationsComponent } from './testing/testing-layout/mock-translations/mock-translations.component';
import {ShSharedModule} from './common/sh-shared.module';
import {LoggedInLayoutModule} from './logged-in-layout/logged-in-layout.module';
import { MockSystemStateComponent } from './testing/testing-layout/mock-system-state/mock-system-state.component';
import {SaveDataWarningDialogComponent} from './common/components/dialogs/save-data-warning-dialog/save-data-warning-dialog.component';
import {MockNotificationDialogsComponent} from "./testing/testing-layout/mock-notification-dialogs/mock-notification-dialogs.component";
import {ManageModule} from "./logged-in-layout/manage/manage.module";
import {DashboardModule} from "./logged-in-layout/dashboard/dashboard.module";
import {LoggedOutLayoutModule} from './logged-out-layout/logged-out-layout.module';
import {LoadingIconComponent} from "./common/components/loading-icon/loading-icon.component";

@NgModule({
  declarations: [
    AppComponent,
    SetupProgressComponent,
    TestingRefreshBlankComponent,
    TestingLayoutComponent,
    SaveDataWarningDialogComponent,
    MockTranslationsComponent,
    MockPrintExportComponent,
    MockDashboardReportsComponent,
    MockHttpManagementComponent,
    MockSystemStateComponent,
    MockNotificationDialogsComponent,
    SaveDataWarningDialogComponent,
    LoadingIconComponent
  ],
  imports: [
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    ClipboardModule,
    ShSharedModule,
    LoggedInLayoutModule,
    ManageModule,
    DashboardModule,
    LoggedOutLayoutModule
  ],
  providers: [DatePipe,
    BootService,
    EpochDateTimePipe,
    TimeAgoTranslatedPipe,
    DurationPipe,
    RoundPipe,
    {provide: APP_INITIALIZER, useFactory: (boot: BootService) => () => boot.init(), deps: [BootService], multi: true},
    MockHttpInterceptorProvider, WINDOW_PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule { }
