<corner-container *ngIf="true"
                  [attr.sh-id]="'add_female_dialog'"
                  [cornerColor]="cornerColor"
                  (onCornerButtonClick)="step === addFemaleSteps.BasicDetails ? goToStep(addFemaleSteps.Calving) : addFemale()"
                  [footerHeight]="50"
                  [ngClass]="step ? 'active-' + step : ''"
                  [bodyBackground]="cornerContainerBodyBackground">
  <ng-container container-body>
    <div class="add-animal-container" [ngClass]="step ? 'active-' + step : ''" [ngSwitch]="step">
      <!-- Basic Details Tab -->
      <add-female-breeding-general-tab *ngSwitchCase="addFemaleSteps.BasicDetails"
                                       [addFemaleModel]="addFemaleModel"
                                       [groupsDropdownListItems]="groupsDropdownListItems"
                                       [femalesMetadata]="femalesMetadata"
                                       [errors]="errors"
                                       (onErrorsClear)="clearErrors()"
                                       (onGroupSelected)="onGroupSelected($event)"></add-female-breeding-general-tab>

      <!-- Calving Tab -->
      <add-female-breeding-calving-tab *ngSwitchCase="addFemaleSteps.Calving"
                                       [addFemaleModel]="addFemaleModel"
                                       [groupsDropdownListItems]="groupsDropdownListItems"
                                       [errors]="errors"
                                       (onErrorsClear)="clearErrors()"
                                       (onGroupSelected)="onGroupSelected($event)"></add-female-breeding-calving-tab>

      <!-- Breeding Tab -->
      <add-female-breeding-breeding-tab *ngSwitchCase="addFemaleSteps.Breeding"
                                        [addFemaleModel]="addFemaleModel"
                                        [groupsDropdownListItems]="groupsDropdownListItems"
                                        [femalesMetadata]="femalesMetadata"
                                        [errors]="errors"
                                        (onErrorsClear)="clearErrors()"
                                        (onGroupSelected)="onGroupSelected($event)"></add-female-breeding-breeding-tab>

      <!-- Pregnancy Check Tab -->
      <add-female-breeding-preg-check-tab *ngSwitchCase="addFemaleSteps.PregnancyCheck"
                                          [addFemaleModel]="addFemaleModel"
                                          [groupsDropdownListItems]="groupsDropdownListItems"
                                          [errors]="errors"
                                          (onErrorsClear)="clearErrors()"
                                          (onGroupSelected)="onGroupSelected($event)"></add-female-breeding-preg-check-tab>

      <!-- Dry Off Tab -->
      <add-female-breeding-dry-off-tab *ngSwitchCase="addFemaleSteps.DryOff"
                                       [addFemaleModel]="addFemaleModel"
                                       [groupsDropdownListItems]="groupsDropdownListItems"
                                       [errors]="errors"
                                       (onErrorsClear)="clearErrors()"
                                       (onGroupSelected)="onGroupSelected($event)"></add-female-breeding-dry-off-tab>

    </div>
  </ng-container>
  <ng-container container-footer>
    <!-- Error container to display all errors for form -->
    <div class="errors-container">
      <div class="error-text" *ngIf="errors && errors[0]" [attr.sh-id]="'error-text-' + errors[0].key">
        <b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}: </b>{{ 'MANAGE.COWS.ACTIVE.WIZARD.ERRORS.' + errors[0].key | translate }}
      </div>
    </div>
    <!-- Buttons Container to contain all the actions of the container -->
    <div class="navigation-buttons">
      <div class="container-buttons">
        <div class="check-button" [attr.sh-id]="'apply_add_female'" *ngIf="step !== addFemaleSteps.BasicDetails" (click)="addFemale()">
          <div class="check-icon"></div>
        </div>
        <div class="check-button" [attr.sh-id]="'button_next'" *ngIf="step === addFemaleSteps.BasicDetails" (click)="goToStep(addFemaleSteps.Calving)">
          <div class="next-icon"></div>
        </div>
      </div>
      <div class="seperator" *ngIf="step !== addFemaleSteps.BasicDetails"></div>
      <div class="navigation-button" [attr.sh-id]="'button_next'" *ngIf="isNextButtonShouldBeShown()" (click)="goToStep(step + 1)">
        <div class="next-icon"></div>
      </div>
      <div class="navigation-button" [attr.sh-id]="'button_previous'" *ngIf="step !== addFemaleSteps.BasicDetails" (click)="goToStep(step - 1)">
        <div class="previous-icon"></div>
      </div>
    </div>
  </ng-container>
</corner-container>
<div class="steps-wizard">
  <div class="summary-div">
    <div class="step-div-text">
      <div *ngIf="markAsValidatedStepGraph(addFemaleSteps.Calving)"><b [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER'">{{ 'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER' | translate }}: </b><span>{{ addFemaleModel.animalNumber }}</span></div>
      <div *ngIf="markAsValidatedStepGraph(addFemaleSteps.Calving)"><b [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'">{{ 'MANAGE.COWS.ACTIVE.WIZARD.GROUP' | translate }}: </b><span>{{ addFemaleModel.group?.name }}</span></div>
    </div>
    <div class="step-div-text">
      <div *ngIf="markAsValidatedStepGraph(addFemaleSteps.Breeding)"><b [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.LACTATION_NUMBER'">{{ 'MANAGE.COWS.ACTIVE.WIZARD.LACTATION_NUMBER' | translate }}: </b><span>{{ addFemaleModel.lactationNumber }}</span></div>
      <div *ngIf="markAsValidatedStepGraph(addFemaleSteps.Breeding)"><b [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.LAST_CALVING_DATE'">{{ 'MANAGE.COWS.ACTIVE.WIZARD.LAST_CALVING_DATE' | translate }}: </b><span>{{ addFemaleModel.lastCalvingDate.epochDate | epochDateTime:DateTimeFormatEnum.Date }}</span></div>
      <div *ngIf="markAsValidatedStepGraph(addFemaleSteps.Breeding)"><b [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE'">{{ 'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE' | translate }}: </b><span>{{ addFemaleModel.birthdate.epochDate | epochDateTime:DateTimeFormatEnum.Date }}</span></div>
    </div>
    <div class="step-div-text">
      <div *ngIf="markAsValidatedStepGraph(addFemaleSteps.PregnancyCheck)"><b [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.INSEMINATION_DATE'">{{ 'MANAGE.COWS.ACTIVE.WIZARD.INSEMINATION_DATE' | translate }}: </b><span>{{ addFemaleModel.breedingDate.epochDate | epochDateTime:DateTimeFormatEnum.Date }}</span></div>
      <div *ngIf="markAsValidatedStepGraph(addFemaleSteps.PregnancyCheck)"><b [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.INSEMINATION_NUMBER'">{{ 'MANAGE.COWS.ACTIVE.WIZARD.INSEMINATION_NUMBER' | translate }}: </b><span>{{ addFemaleModel.breedingNumber }}</span></div>
      <div *ngIf="markAsValidatedStepGraph(addFemaleSteps.PregnancyCheck)"><b [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.SIRE'">{{ 'MANAGE.COWS.ACTIVE.WIZARD.SIRE' | translate }}: </b><span>{{ addFemaleModel.bull?.name }}</span></div>
    </div>
    <div class="step-div-text" *ngIf="isDryOffShouldBeShown()">
      <div *ngIf="markAsValidatedStepGraph(addFemaleSteps.DryOff)"><b [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.PREG_CHECK_DATE'">{{ 'MANAGE.COWS.ACTIVE.WIZARD.PREG_CHECK_DATE' | translate }}: </b><span>{{ addFemaleModel.pregnancyCheckDate.epochDate | epochDateTime:DateTimeFormatEnum.Date }}</span></div>
    </div>
    <div class="step-div-text"></div>
  </div>
  <div class="steps-graph-div">
    <div class="step-icon" [attr.sh-id]="'step_icon_basic'" [ngClass]="markAsValidatedStepGraph(addFemaleSteps.BasicDetails) ? 'active-' + step : ''" (click)="navigateToStepIfPossible(addFemaleSteps.BasicDetails)">
      <div class="female-animal-icon"></div>
    </div>
    <div class="seperator" [ngClass]="[markAsValidatedStepGraph(addFemaleSteps.Calving) ? 'active-' + step : '', isStepLessThanMaxStep(addFemaleSteps.Calving) ? 'step-opacity' : '']"></div>
    <div class="step-icon" [attr.sh-id]="'step_icon_calving'" [ngClass]="[markAsValidatedStepGraph(addFemaleSteps.Calving) ? 'active-' + step : '', isStepLessThanMaxStep(addFemaleSteps.Calving) ? 'step-opacity' : '']" (click)="navigateToStepIfPossible(addFemaleSteps.Calving)">
      <div class="calving-icon" [ngClass]="{ active: markAsValidatedStepGraph(addFemaleSteps.Calving), 'step-opacity':  isStepLessThanMaxStep(addFemaleSteps.Calving) }"></div>
    </div>
    <div class="seperator" [ngClass]="[markAsValidatedStepGraph(addFemaleSteps.Breeding) ? 'active-' + step : '', isStepLessThanMaxStep(addFemaleSteps.Breeding) ? 'step-opacity' : '']"></div>
    <div class="step-icon" [attr.sh-id]="'step_icon_breeding'" [ngClass]="[markAsValidatedStepGraph(addFemaleSteps.Breeding) ? 'active-' + step : '', isStepLessThanMaxStep(addFemaleSteps.Breeding) ? 'step-opacity' : '']" (click)="navigateToStepIfPossible(addFemaleSteps.Breeding)">
      <div class="breeding-icon" [ngClass]="{ active: markAsValidatedStepGraph(addFemaleSteps.Breeding), 'step-opacity':  isStepLessThanMaxStep(addFemaleSteps.Breeding) }"></div>
    </div>
    <div class="seperator" [ngClass]="[markAsValidatedStepGraph(addFemaleSteps.PregnancyCheck) ? 'active-' + step : '', isStepLessThanMaxStep(addFemaleSteps.PregnancyCheck) ? 'step-opacity' : '']"></div>
    <div class="step-icon" [attr.sh-id]="'step_icon_preg_check'" [ngClass]="[markAsValidatedStepGraph(addFemaleSteps.PregnancyCheck) ? 'active-' + step : '', isStepLessThanMaxStep(addFemaleSteps.PregnancyCheck) ? 'step-opacity' : '']" (click)="navigateToStepIfPossible(addFemaleSteps.PregnancyCheck)">
      <div class="pregnancy-check-icon" [ngClass]="{ active: markAsValidatedStepGraph(addFemaleSteps.PregnancyCheck), 'step-opacity':  isStepLessThanMaxStep(addFemaleSteps.PregnancyCheck) }"></div>
    </div>
    <div class="seperator" *ngIf="isDryOffShouldBeShown()" [ngClass]="[markAsValidatedStepGraph(addFemaleSteps.DryOff) ? 'active-' + step : '', isStepLessThanMaxStep(addFemaleSteps.DryOff) ? 'step-opacity' : '']"></div>
    <div class="step-icon" [attr.sh-id]="'step_icon_dryoff'" *ngIf="isDryOffShouldBeShown()" [ngClass]="[markAsValidatedStepGraph(addFemaleSteps.DryOff) ? 'active-' + step : '', isStepLessThanMaxStep(addFemaleSteps.DryOff) ? 'step-opacity' : '']">
      <div class="dry-off-icon" [ngClass]="{ active: markAsValidatedStepGraph(addFemaleSteps.DryOff), 'step-opacity':  isStepLessThanMaxStep(addFemaleSteps.DryOff) }"></div>
    </div>
  </div>
</div>
