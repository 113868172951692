import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {
  ChartDurationSelectionLabel,
  XyChartComponent
} from '../../../../../common/components/xy-chart/xy-chart.component';
import {
  ChartLegendConfiguration,
  NamedChartLegendState,
  XyChartLegendComponent
} from '../../../../../common/components/xy-chart-legend/xy-chart-legend.component';
import {
  IGroupChartHeatStressData,
  IGroupChartTrendHeatSetupSeries,
  IGroupGraphDetails
} from '../../../../../services/groups/model/group.interface';
import {ChartSetupProjection, IChartSeriesSetupRecord} from '../../../../../services/charts/model/chart.interface';
import {ChartSeriesSelectionState} from '../../../../../common/components/chart-series-dropdown-list/chart-series-dropdown-list.component';
import {GroupsService} from '../../../../../services/groups/groups.service';
import {ChartResolution, IChartPoint} from '../../../../../services/model/charts/chart.interface';
import {ChartsService} from '../../../../../services/charts/charts.service';
import {SeriesModel} from '@syncfusion/ej2-charts';
import {
  ChartConfiguration,
  GridLineIntervalEnum,
} from '../../../../../services/charts/xy-chart-calculation.service';
import {GoogleAnalyticsService} from '../../../../../services/google-analytics/google-analytics.service';
import {GroupCardViewState} from '../../group-card-model';

@Component({
  selector: 'group-card-trends-heat-breeding',
  templateUrl: './group-card-trends-heat-breeding.component.html',
  styleUrls: ['./group-card-trends-heat-breeding.component.scss']
})
export class GroupCardTrendsHeatBreedingComponent implements OnChanges {

  @ViewChild('groupHeatChart')
  public groupHeatChart: XyChartComponent;

  @ViewChild('groupHeatChartLegend')
  public groupHeatChartLegend: XyChartLegendComponent;

  @Input()
  public groupId: number;

  @Input()
  public groupGraphDetails: IGroupGraphDetails;

  @Input()
  public viewState: GroupCardViewState;

  public legendConfiguration: ChartLegendConfiguration = {
    translationPrefix: 'ANIMAL.GRAPH.SERIES_TITLE.',
    valuePostfix: ''
  };

  private static readonly BreedingHeatTrendChartId: number = 7;

  public legendStates: NamedChartLegendState[];

  public selectedDurationLabel: ChartDurationSelectionLabel;

  private optionsDurationLabels: ChartDurationSelectionLabel[] = [];

  private chartConfigurations: ChartConfiguration[] = [];

  public seriesSelectionStates: ChartSeriesSelectionState[];

  public chartData: IGroupChartHeatStressData;

  constructor(private groupsService: GroupsService,
              private chartsService: ChartsService,
              private googleAnalyticsService: GoogleAnalyticsService) {

  }

  public async ngOnChanges(changes: SimpleChanges) {
    if (changes.viewState != null &&
      changes.viewState.previousValue != changes.viewState.currentValue &&
      this.viewState != null) {
      this.initConfig();
      if (this.viewState.groupTrendsHeatChartSetup == null) {
        await this.getChartSetup(false);
      }
      this.initChartSeriesSelection();
      this.initChart(true);
    }
  }

  public onChartPointMouseOver(chartPoint: IChartPoint) {
    this.setLegendValue(chartPoint);
  }

  public onDurationSelected(duration: ChartDurationSelectionLabel) {
    this.selectedDurationLabel = duration;
    this.initChart(false);
  }

  public async onSelectionApplied(save: boolean) {

    if(save) {
      this.googleAnalyticsService.send("Group Card Graph","Change Settings - Save", JSON.stringify(this.viewState.groupTrendsHeatChartSetup));
    }
    else  {
      this.googleAnalyticsService.send("Group Card Graph","Change Settings - Without Save", JSON.stringify(this.viewState.groupTrendsHeatChartSetup));
    }

    for (let seriesItem of this.seriesSelectionStates) {
      this.viewState.groupTrendsHeatChartSetup.series[seriesItem.name].isSelected = seriesItem.isSelected;
    }
    this.groupHeatChart.resetChart();
    this.initChartLegend(this.viewState.groupTrendsHeatChartSetup.series);
    setTimeout(async () => {
      if(save) {
        let result = await this.chartsService.updateChartSetup(GroupCardTrendsHeatBreedingComponent.BreedingHeatTrendChartId, this.viewState.groupTrendsHeatChartSetup);
        if (result.status != 200) {
          await this.getChartSetup(false);
        }
      }
      this.initChart(true);
    });
  }

  public async onRestoreToDefaults() {

    this.groupHeatChart.resetChart();
    setTimeout(async () =>{
      await this.getChartSetup(true);
      this.initChart(true);
    });
  }

  // tslint:disable-next-line:no-any
  private initChartLegend(seriesSetupContainer: any) {
    this.legendStates = [];
    for (let containerSeriesName of Object.getOwnPropertyNames(seriesSetupContainer)) {
      let record : IChartSeriesSetupRecord = seriesSetupContainer[containerSeriesName];
      if(record.isInLegend &&
        record.isVisible &&
        record.isSelected) {
        this.legendStates.push({
          name: containerSeriesName,
          value: null,
          altTranslation: null,
          altCss: null
        });
      }
    }
  }

  private async getChartSetup(resest: boolean) {
    // tslint:disable-next-line:no-any
    let response = await this.chartsService.getChartSetup<IChartSeriesSetupRecord, IGroupChartTrendHeatSetupSeries>(GroupCardTrendsHeatBreedingComponent.BreedingHeatTrendChartId, resest ? ChartSetupProjection.resetsetup : ChartSetupProjection.getsetup);
    if(response.status == 200){
      this.viewState.groupTrendsHeatChartSetup = response.responseBody;
      this.initChartLegend(this.viewState.groupTrendsHeatChartSetup.series);
      this.initChartSeriesSelection();
    }
  }

  private initChartSeriesSelection() {
    this.seriesSelectionStates = [];
    for (let containerSeriesName of Object.getOwnPropertyNames(this.viewState.groupTrendsHeatChartSetup.series)) {
      let record : IChartSeriesSetupRecord = this.viewState.groupTrendsHeatChartSetup.series[containerSeriesName];
      if(record.isVisible) {
        this.seriesSelectionStates.push({
          name: containerSeriesName,
          isSelected: record.isSelected,
          isRemovable: record.isRemovable
        });
      }
    }
  }

  private async initChart(reloadData:boolean) {
    this.viewState.trendsHeatSelectedDuration = this.optionsDurationLabels.indexOf(this.selectedDurationLabel);

    if(this.chartData == null ||
       reloadData) {
      let response = await this.groupsService.getBreedingGroupChartHeatStress(this.groupId, GroupCardTrendsHeatBreedingComponent.BreedingHeatTrendChartId,
        ChartResolution.Hour, this.seriesSelectionStates.filter(value => value.isSelected).map(value => value.name));
      if(response.status == 200){
        this.chartData = response.responseBody;
      }
    }

    this.groupHeatChart.initChart(this.getSeries(),
      this.chartData.series,
      this.chartConfigurations[this.viewState.trendsHeatSelectedDuration],
      false,
      this.selectedDurationLabel,
      this.optionsDurationLabels,
      this.chartData.eventsSeries);
    this.initChartLegend(this.viewState.groupTrendsHeatChartSetup.series);
  }

  private initConfig() {
    this.optionsDurationLabels.push({
      selectedDisplayText: '7 DAYS',
      optionDisplayText: '7'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24,
      gridLineInterval: GridLineIntervalEnum.Day,
      visiblePeriodHours: 24 * 7,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0,
      primaryXAxisExtendSeconds: 1800,
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '14 DAYS',
      optionDisplayText: '14'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24,
      gridLineInterval: GridLineIntervalEnum.Day,
      visiblePeriodHours: 24 * 14,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0,
      primaryXAxisExtendSeconds: 3600,
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '30 DAYS',
      optionDisplayText: '30'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 2,
      gridLineInterval: GridLineIntervalEnum.TwoDays,
      visiblePeriodHours: 24 * 30,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '60 DAYS',
      optionDisplayText: '60'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 3,
      gridLineInterval: GridLineIntervalEnum.ThreeDays,
      visiblePeriodHours: 24 * 60,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '90 DAYS',
      optionDisplayText: '90'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 4,
      gridLineInterval: GridLineIntervalEnum.FourDays,
      visiblePeriodHours: 24 * 90,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '180 DAYS',
      optionDisplayText: '180'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 9,
      gridLineInterval: GridLineIntervalEnum.NineDays,
      visiblePeriodHours: 24 * 180,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '360 DAYS',
      optionDisplayText: '360'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 18,
      gridLineInterval: GridLineIntervalEnum.Month,
      visiblePeriodHours: 24 * 360,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '420 DAYS',
      optionDisplayText: '420'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 18,
      gridLineInterval: GridLineIntervalEnum.Month,
      visiblePeriodHours: 24 * 420,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.selectedDurationLabel = this.optionsDurationLabels[this.viewState.trendsHeatSelectedDuration];
  }

  // tslint:disable-next-line:no-any
  private setLegendValue(valueContainer: any) {
    if(valueContainer == null ){
      this.legendStates.forEach(legendState => {
        legendState.value = null;
      });
    } else {
      this.legendStates.forEach(legendState => {
        legendState.value = valueContainer[legendState.name];
        if(legendState.value == null) {
          legendState.value = 0;
        }
      });
    }
  }

  private getSeries() : SeriesModel[] {
    let seriesModel : SeriesModel[] = [];

    if(this.viewState.groupTrendsHeatChartSetup.series.averageDailyRumination.isSelected &&
      this.viewState.groupTrendsHeatChartSetup.series.averageDailyRumination.isVisible) {

      seriesModel.push({
        type: 'Line',
        xName: 'x',
        yName: 'averageDailyRumination',
        width: 2,
        fill: '#aa64aa',
        animation: {
          enable: false
        },
        dataSource: this.chartData.series
      });

      seriesModel.push({
        type: 'Area',
        xName: 'x',
        yName: 'averageDailyRumination',
        opacity: 0.2,
        fill: '#aa64aa',
        animation: {
          enable: false
        },
        dataSource: this.chartData.series
      });

    }

    if(this.viewState.groupTrendsHeatChartSetup.series.averageDailyEating.isSelected &&
      this.viewState.groupTrendsHeatChartSetup.series.averageDailyEating.isVisible) {

      seriesModel.push({
        type: 'Line',
        xName: 'x',
        yName: 'averageDailyEating',
        width: 2,
        fill: '#56c3b8',
        animation: {
          enable: false
        },
        dataSource: this.chartData.series
      });

    }

    if(this.viewState.groupTrendsHeatChartSetup.series.averageDailyPanting.isSelected &&
      this.viewState.groupTrendsHeatChartSetup.series.averageDailyPanting.isVisible) {

      seriesModel.push({
        type: 'Line',
        xName: 'x',
        yName: 'averageDailyPanting',
        width: 2,
        fill: '#f04c39',
        animation: {
          enable: false
        },
        dataSource: this.chartData.series
      });

      seriesModel.push({
        type: 'Area',
        xName: 'x',
        yName: 'averageDailyPanting',
        opacity: 0.2,
        fill: '#f04c39',
        animation: {
          enable: false
        },
        dataSource: this.chartData.series
      });

    }

    return seriesModel;
  }


}
