import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfigService} from '../../../services/config/config.service';
import {NotificationDialogTemplateComponent, NotificationDialogTheme} from '../../../common/components/dialogs/notification-dialog-template/notification-dialog-template.component';
import {IDialog} from '../../../services/dialogs/model/dialog.model';


@Component({
  selector: 'started-trial-dialog',
  templateUrl: './started-trial-dialog.component.html',
  styleUrls: ['./started-trial-dialog.component.scss']
})
export class StartedTrialDialogComponent  implements OnInit, IDialog {

  @ViewChild('dialogRef', {static: true})
  protected dialogRef: NotificationDialogTemplateComponent;

  public trialDays: number = null;
  
  public NotificationDialogTheme = NotificationDialogTheme;

  constructor(private readonly configService: ConfigService) {
  
  }

  public ngOnInit() {
    if(!this.configService.configuration.cloud) {
      if (this.configService.anonymousAuthResult.trialMode.isTrial && !this.dialogRef.isOpen) {
        this.dialogRef.isOpen = true;
      }
      if (this.dialogRef.isOpen && this.configService.anonymousAuthResult.trialMode.isTrial) {
        if (this.configService.anonymousAuthResult.trialMode.daysToExpiration > 0) {
          this.trialDays = this.configService.anonymousAuthResult.trialMode.daysToExpiration;
        }
      }
    }
  }
  
  public showDialog() {
    this.dialogRef.isOpen = true;
  }
}
