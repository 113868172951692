import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {ReportActionsService} from '../../../../../services/reports/report-actions.service';
import {
  IReport,
  IReportAction,
  IReportBatchAction,
  ReportActionType,
  ReportBatchActionType
} from '../../../../../services/reports/model/report.interface';
import {ReportQuery} from '../../../../../services/reports/model/report-query.interface';
import {IReportViewContext} from '../../../../../services/reports/model/report-view-context';
import {TranslationService} from '../../../../../services/translations/translation.service';
import {DateTimeDialogComponent} from '../../../calendar/date-time-dialog/date-time-dialog.component';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../calendar/model/epoch-state-model';
import {ClickPressEventsService} from '../../../../../services/click-press-events/click-press-events.service';
import {Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';

@Component({
  selector: 'report-actions-dropdown',
  templateUrl: './report-actions-dropdown.component.html',
  styleUrls: ['./report-actions-dropdown.component.scss']
})
export class ReportActionsDropdownComponent implements OnInit, OnDestroy {

  public isOpen: boolean = false;

  public isBatchAction: boolean = false;

  public isShowExportToFilePopup: boolean = false;

  public pastReportDate: EpochStateModel = new EpochStateModel(EpochStateModelMinMaxMode.Date);

  @ViewChild('pastReportDateTimeDialog', {static: true})
  public pastReportDateTimeDialog: DateTimeDialogComponent;

  @Input()
  public ShIdPrefix: string = '';

  @Input()
  public reportActions: IReportAction[] = [{ type: ReportActionType.Print}, { type: ReportActionType.Export}];

  @Input()
  public reportBatchActions: IReportBatchAction[] = [];

  @Input()
  public isActionsOnLeftSide: boolean = true;

  @Input()
  public showExtendedColumns: boolean = false;

  @Input()
  public report: IReport;

  @Input()
  public reportQuery: ReportQuery;

  @Input()
  public reportContext: IReportViewContext;
  //
  // @Output()
  // onActionSelected: EventEmitter<IActionTypeData> = new EventEmitter<IActionTypeData>();

  @Output()
  public onShowPastReport: EventEmitter<number> = new EventEmitter<number>();

  private subscription: Subscription;

  constructor(private readonly translationService: TranslationService,
              private reportActionsService: ReportActionsService,
              private readonly clickPressEventsService: ClickPressEventsService) { }

  public ngOnInit() {
    this.subscription = this.clickPressEventsService.keydownEscape
      .pipe(
        filter(() => this.isOpen),
        tap(() => this.closePopup()),
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public toggleActionsDropdown() {
    this.isOpen = !this.isOpen;
  }

  public closePopup() {
    this.isOpen = false;
  }

  public async onPrintClick(event:Event) {
    if(this.showBatchActions) {
      await this.onBatchActionClicked(ReportBatchActionType.Print, event);
    } else {
      await this.onActionClicked(ReportActionType.Print, event);
    }
  }

  public async onActionClicked(action: ReportActionType, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.isBatchAction = false;
    if (action === ReportActionType.ShowPastReport) {
      this.pastReportDate = new EpochStateModel(EpochStateModelMinMaxMode.Date);
      let currentDate = moment().unix();
      this.pastReportDate.epoch = currentDate;
      this.pastReportDate.max = this.pastReportDate.epoch;
      this.pastReportDate.min = moment.unix(this.pastReportDate.max).subtract(1, 'year').startOf('day').unix();
      this.pastReportDateTimeDialog.openDialog();
      this.isOpen = false;
    } else if (action === ReportActionType.Export) {
      this.isOpen = false;
      this.isShowExportToFilePopup = true;
    } else {
      await this.reportActionsService.performReportAction(this.reportContext, action, this.report, this.reportQuery, this.showExtendedColumns);
      this.isOpen = false;
    }
  }

  public async onBatchActionClicked(action: ReportBatchActionType, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.isBatchAction = true;
    if (action === ReportBatchActionType.Export) {
      this.isOpen = false;
      this.isShowExportToFilePopup = true;
    } else {
      await this.reportActionsService.performReportBatchAction(this.reportContext,
        action,
        this.report,
        this.reportQuery);
    }
    this.isOpen = false;
  }

  public getTextType(actionType: string): string {
    switch(actionType) {
      case ReportActionType.Print: {
        return 'PRINT';
      }
      case ReportActionType.Export: {
        return 'EXPORT';
      }
      case ReportActionType.ShowPastReport: {
        return 'SHOW_PAST_REPORT';
      }
      case ReportActionType.AddAnimalToList: {
        return 'ADD_ANIMAL_TO_THIS_LIST';
      }
      case ReportActionType.Import: {
        return 'IMPORT_FROM_FILE';
      }
      case ReportActionType.TurnLedLightingOn: {
        return 'TURN_LED_LIGHTING_ON';
      }
      default:
        return actionType;
    }
  }

  public getBatchActionsText(actionType: string) {
    switch(actionType) {
      case ReportActionType.Print: {
        return `${this.translationService.translate('COMMON.ACTIONS.PRINT')} ${this.translationService.translate('COMMON.ACTIONS.SELECTED')}`;
      }
      case ReportActionType.Export: {
        return `${this.translationService.translate('COMMON.ACTIONS.EXPORT_SELECTED')}`;
      }
      case ReportActionType.Cull: {
        return `${this.translationService.translate('COMMON.ACTIONS.Cull')} ${this.translationService.translate('COMMON.ACTIONS.SELECTED')}`;
      }
      case ReportActionType.DoNotBreedOn: {
        return `${this.translationService.translate('COMMON.ACTIONS.DoNotBreedOn')} ${this.translationService.translate('COMMON.ACTIONS.SELECTED')}`;
      }
      case ReportActionType.TurnLedLightingOn: {
        return `${this.translationService.translate('COMMON.ACTIONS.TURN_LED_LIGHTING_ON')} ${this.translationService.translate('COMMON.ACTIONS.SELECTED')}`;
      }
      case ReportActionType.PregnancyCheck: {
        return `${this.translationService.translate('ANIMAL.GRAPH.SERIES_TITLE.pregnancyCheck')}`;
      }
      default:
        return actionType;
    }
  }

  public goBackToActionsMenu() {
    setTimeout(() => {
      this.isShowExportToFilePopup = false;
      this.isOpen = true;
    });
  }

  public async showPastReport() {
    await this.onShowPastReport.emit(this.pastReportDate.epoch);
  }

  public get showBatchActions() : boolean {
    if(this.reportContext.getRowSelectionState() != null) {
      return this.reportContext.getRowSelectionState().selectedRowsCount > 0;
    }
  }

  public closeExportToFilePopup() {
    setTimeout(() => {
      this.isShowExportToFilePopup = false;
      this.isOpen = false;
    });
  }

  public async exportToCSVFile() {
    if (this.isBatchAction) {
      await this.reportActionsService.performReportBatchAction(this.reportContext,
        ReportBatchActionType.Export,
        this.report,
        this.reportQuery);
    } else {
      await this.reportActionsService.performReportAction(this.reportContext, ReportActionType.Export, this.report, this.reportQuery, this.showExtendedColumns);
    }
    this.isShowExportToFilePopup = false;
  }

  public get isOneOfReportActionPopupsIsOpen(): boolean {
    return this.isOpen || this.isShowExportToFilePopup;
  }

  public get isExportToFileOrPastReportPopupShown(): boolean {
    return this.isShowExportToFilePopup;
  }
}
