import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { IPremiumTrialStartedResponse, PremiumTrialService } from '../../../services/premium-trial/premium-trial.service';
import { HttpResponseResult } from '../../../services/rest-api/rest-api.service';
import {PollingKeyNames, PollingService} from '../../../services/rest-api/polling.service';
import { Subscription } from 'rxjs';
import {NotificationDialogTemplateComponent, NotificationDialogTheme} from '../../../common/components/dialogs/notification-dialog-template/notification-dialog-template.component';
import {IDialog} from '../../../services/dialogs/model/dialog.model';


@Component({
  selector: 'started-premium-trial-dialog',
  templateUrl: './started-premium-trial-dialog.component.html',
  styleUrls: ['./started-premium-trial-dialog.component.scss']
})
export class StartedPremiumTrialDialogComponent  implements IDialog, OnInit, OnDestroy {

  @ViewChild('dialogRef', {static: true})
  public dialogRef: NotificationDialogTemplateComponent;
  
  public trialDays: number = 60;

  public NotificationDialogTheme = NotificationDialogTheme;
  
  private readonly subscription = new Subscription();
  
  constructor(private premiumTrialService: PremiumTrialService, private pollingService: PollingService) {
  
  }

  public ngOnInit() {
    this.premiumTrialService.checkIfPremiumTrialHasStarted().then((response: HttpResponseResult<IPremiumTrialStartedResponse>) => {
      if (response.responseBody && response.responseBody.status === 'AwaitingApproval') {
        this.dialogRef.isOpen = true;
      }
    });

    this.subscription.add(this.pollingService.pollingChangesState.subscribe(async value => {
      if (value.length && value.includes(PollingKeyNames.premiumTrialStarts)) {
        this.premiumTrialService.checkIfPremiumTrialHasStarted().then((response: HttpResponseResult<IPremiumTrialStartedResponse>) => {
          if (response.responseBody && response.responseBody.status === 'AwaitingApproval' && !this.dialogRef.isOpen) {
            this.dialogRef.isOpen = true;
          }
        });
      }
    }));

  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onApprovingPremiumTrialPlan() {
    // tslint:disable-next-line:no-any
    this.premiumTrialService.startPremiumTrial().then((response: HttpResponseResult<any>) => {
      this.dialogRef.close();
      // tslint:disable-next-line:no-any
    }).catch((responseError: HttpResponseResult<any>) => {

    });
  }
  
  public showDialog() {
    this.dialogRef.isOpen = true;
  }
}
