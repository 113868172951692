import {Injectable} from '@angular/core';
import {
  ProvisioningAuthModel,
  IProvisioningSignInRequest,
  ISignInCorrelationIdResponseData,
  ISignInResponseData,
  ISignUpResponseData
} from './model/provisioning.model';
import {HttpResponseResult, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProvisioningService {

  private static readonly DataSharingSignInRoute = '/rest/api/datasharing/signin';
  private static readonly DataSharingSignUpRoute = '/rest/api/datasharing/signup';

  constructor(private readonly restApiService: RestApiService,
              private readonly authService: AuthService) {
  }

  public async provisioningSignIn(dataSharingModel: ProvisioningAuthModel): Promise<HttpResponseResult<ISignInResponseData>> {
    const signInCorrelationIDResponse = await this.getSignInCorrelationID(dataSharingModel);
    if (signInCorrelationIDResponse.status == 200) {
      return await this.signIn(signInCorrelationIDResponse.responseBody.correlationId);
    } else {
      return signInCorrelationIDResponse;
    }
  }

  public async getSignInCorrelationID(signInRequestData: IProvisioningSignInRequest): Promise<HttpResponseResult<ISignInCorrelationIdResponseData>> {
    return this.restApiService.sendPostMethod<ISignInCorrelationIdResponseData>(ProvisioningService.DataSharingSignInRoute, this.authService.currentUserAuthState.authDetails, signInRequestData)
  }

  public async signIn(correlationId: string): Promise<HttpResponseResult<ISignInResponseData>> {
    return this.restApiService.sendGetMethod<ISignInResponseData>(ProvisioningService.DataSharingSignInRoute + '?correlationId=' + correlationId, this.authService.currentUserAuthState.authDetails)
  }

  public async signUp(correlationId: string): Promise<HttpResponseResult<ISignUpResponseData>> {
    return this.restApiService.sendGetMethod<ISignUpResponseData>(ProvisioningService.DataSharingSignUpRoute + '?correlationId=' + correlationId, this.authService.anonymousAuthKnownFarmDetails)
  }
}
