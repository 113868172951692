import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '../../utils/window-utils';
import {TranslationService} from '../translations/translation.service';
import {IReport} from '../reports/model/report.interface';
import {DateTimeFormatEnum, EpochDateTimePipe} from '../../common/pipes/epoch-date-time.pipe';
import {DatePipe} from '@angular/common';
import {DurationInput, DurationPipe} from '../../common/pipes/duration.pipe';
import * as moment from 'moment';
import {ReportsService} from '../reports/reports.service';

export type ColumnPresentationTransformCallback = (column:string) => string;
// tslint:disable-next-line:no-any
export type FieldPresentationTransformCallback = (column:string, row:any) => string;

@Injectable({
  providedIn: 'root'
})
export class CsvExportService {

  constructor(@Inject(WINDOW) private windoW: Window,
              private translationService: TranslationService,
              private datePipe: DatePipe,
              private durationPipe: DurationPipe,
              private reportsService: ReportsService,
              private epochDateTimePipe: EpochDateTimePipe) { }

  public exportReportCsv(report:IReport, headerText: string, includeDate: boolean):void {
    const exportTime = report.meta.lastUpdated;
    let format: string = this.translationService.translate('LOCALES.DATE_FORMAT.DEFAULT.DATE_TEMPLATE') + " HH:mm";
    let exportDateTime: string = moment.unix(exportTime).format(format);
    let csvString = this.createReportTable(report, headerText, includeDate);
    let link = this.windoW.document.createElement('a');
    link.href ='data:text/csv;charset=utf-8,' + encodeURI(csvString);
    link.download = `export-${report.meta.reportName}-${exportDateTime}.csv`;
    link.click();
    link.remove();
  }

  public exportTableCsv<T>(dataToExport: T[],
                           columns: string[],
                           title: string,
                           filePartName: string,
                           transformColumnToPresentable: ColumnPresentationTransformCallback,
                           transformFieldToPresentable? : FieldPresentationTransformCallback): void {
    const exportTime = new Date().getTime();

    //let csvString = this._jsonToCsv(dataToExport);
    let csvString = this.jsonToCsvWithHeader(dataToExport,
                                              columns,
                                              title,
                                             true,
                                              transformColumnToPresentable,
                                              transformFieldToPresentable);
    let link = this.windoW.document.createElement('a');
    link.href ='data:text/csv;charset=utf-8,' + encodeURI(csvString);
    link.download = `export-${filePartName}-${exportTime}.csv`;
    link.click();
    link.remove();
  }

  private jsonToCsvWithHeader<T>(jsonData: T[],
                                 columns: string[],
                                 title: string,
                                 showLabel: boolean,
                                 transformColumnToPresentable: ColumnPresentationTransformCallback,
                                 transformFieldToPresentable? : FieldPresentationTransformCallback) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    let arrData = typeof jsonData != 'object' ? JSON.parse(jsonData) : jsonData;

    let csv = '';
    //Set Report title in first row or line
    csv += title + '\r\n\n';
    //This condition will generate the Label/Header
    if (showLabel) {
      let row = '';
      // This loop will extract the label from 1st index of on array
      for (const column of columns) {
        row += transformColumnToPresentable(column) + ',';
      }
      row = row.slice(0, -1);
      //append Label row with line break
      csv += row + '\r\n';
    }
    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";
      //2nd loop will extract each column and convert it in string comma-seprated
      for (const column of columns) {
        const content = transformFieldToPresentable(column, arrData[i]);
        if (typeof content == 'string' && (content.indexOf('\r\n') > -1)) {
          row += '"' + content.replace(/\r\n/g, ', ') + '",';
        } else {
          row += '"' + content + '",';
        }
      }
      row = row.slice(0, row.length - 1);
      //add a line break after each row
      csv = csv + row + '\r\n';
    }

    if (csv == '') {
      alert("Invalid data");
      return;
    }

    return csv;
  }

  private createReportTable(report:IReport, headerText: string, includeDate: boolean) {
    const document = this.windoW.document;
    const container = document.createElement("div");

    const titleContainer = document.createElement("div");
    titleContainer.textContent = this.translationService.translate(headerText);
    if(includeDate &&
      report.meta.reportTime) {
      titleContainer.textContent += ' - ' + this.epochDateTimePipe.transform(report.meta.reportTime, DateTimeFormatEnum.DateTime);
    }
    titleContainer.style.width = '100%';
    titleContainer.style.height = '50px';
    titleContainer.style.fontFamily = 'sans-serif';
    titleContainer.style.textAlign = 'center';
    container.appendChild(titleContainer);

    // tslint:disable-next-line:no-any
    const table:any = document.createElement("table");
    table.className = 'report-table';
    table.id = 'reportTable';
    table.style.width = '100%';
    table.style.height = 'calc(100% - 50px)';
    table.style.margin = '0';
    table.style.borderCollapse = 'collapse';
    table.style.fontFamily = 'sans-serif';
    const headerRow = table.insertRow();

    report.columns.forEach(column => {
      if ((column.type !== 'HiddenRef' && column.type !== 'Hidden' && column.webMetaType !== 'Badge' && column.mobileMetaType !== 'Badge') ||
           column.key == 'DNBCalculation') {
        const headerCell = document.createElement('th');
        headerCell.style.width = column.width > 0 ? `${column.width}%` : `calc(100% / ${report.columns.length})`;
        headerCell.style.fontWeight = 'bold';
        headerCell.style.border = '1px solid black';
        headerCell.style.borderBottomStyle = 'double';
        headerCell.style.padding = '10px';
        headerCell.setAttribute('sh-id', column.key);

        headerCell.textContent = (column.name === '_') ? column.type : this.translationService.translate(`REPORTS.GRID.${column.name}`);
        headerRow.appendChild(headerCell);
      }
    });

    report.rows.forEach(row => {
      const tableRow = table.insertRow();
      report.columns.forEach(column => {
        if ((column.type !== 'HiddenRef' && column.type !== 'Hidden' && column.webMetaType !== 'Badge' && column.mobileMetaType !== 'Badge')  ||
             column.key == 'DNBCalculation') {
          const tableCell = document.createElement('td');
          tableCell.style.width = column.width > 0 ? `${column.width}%` : `calc(100% / ${report.columns.length})`;
          tableCell.style.border = '1px solid black';
          tableCell.style.padding = '10px';
          tableCell.setAttribute('sh-id', column.key);
          if( row[column.key] == null) {
            tableCell.textContent = '';
          } else if ((typeof row[column.key] === 'object') ||
                     (typeof row[column.key] === 'string')) {
            switch (column.type) {
              case 'SortingReason':
                tableCell.textContent = this.translationService.translate('SORTING_GATE.SORTING_REPORT.SORTING_REASONS.'+ row[column.key].mainReason);
                break;
              case 'AnimalsToInspectReasons':
                if(row[column.key] != null) {
                  tableCell.textContent = this.translationService.translate(`REPORTS.GRID.${row[column.key].mostImportantReport}`);
                }
                break;
              case 'DynamicLocalization': {
                if (row[column.key].isLocalization) {
                  tableCell.textContent = this.translationService.translate('REPORTS.GRID.STATUS.' + row[column.key].value);
                } else {
                  tableCell.textContent = row[column.key].value;
                }
                break;
              }
              case 'DateTime':
                switch (column.key) {
                  case 'TagTypeCalculation':
                  case 'DistressStartDateAndTimeCalculation':
                    tableCell.textContent = this.epochDateTimePipe.transform(row[column.key], DateTimeFormatEnum.DateTime);
                    break;
                  default:
                    tableCell.textContent = row[column.key];
                    break;
                }
                break;
              case 'Localization':
                switch (column.key) {
                  case 'MaintenanceCallsRecommendedActionCalculation':
                  case 'LactationStatusCalculation':
                  case 'SuspectedForAbortionCalculation':
                  case 'DistressTypeCalculation':
                  case 'GroupBranchCalculation':
                  case 'PregnancyProbabilityCalculation':
                  case 'TagModeCalculation':
                  case 'RuminationRoutineRankingCalculation':
                  case 'ActivityRoutineRankingCalculation':
                    tableCell.textContent = this.translationService.translate('REPORTS.GRID.STATUS.' + row[column.key]);
                    break;
                  case 'MaintenanceCallsIssueIDCalculation':
                    tableCell.textContent = this.translationService.translate('SYSTEM.TAGS.GRID.STATUS.' + row[column.key]);
                    break;
                }
                break;
              case 'String':
                switch (column.key) {
                  case 'SyncStatusResultsCalculation':
                    tableCell.textContent = this.reportsService.parseSyncStatusResults(row[column.key]);
                    break;
                  case 'SyncStatusStatusCalculation':
                    tableCell.textContent = this.translationService.translate('SYSTEM.MANAGEMENT.GRID.' + row[column.key]);
                    break;
                  case 'SortedCalculation':
                    tableCell.textContent = this.translationService.translate('SORTING_GATE.SORTING_REPORT.SORTED_CALCULATION.' + row[column.key]);
                    break;
                  default:
                    tableCell.textContent = row[column.key];
                    break;
                }
                break;
              default:
                tableCell.textContent = JSON.stringify(row[column.key]);
                break;
            }
          } else {
            if (column.key === 'BullChoiceListCalculation') {
              tableCell.textContent = row[column.key].replace(/,/g, '.');
            } else if (column.key === 'CullingDateCalculation') {
              tableCell.textContent = this.epochDateTimePipe.transform(row[column.key], DateTimeFormatEnum.DateTime);
            } else if (column.key === 'SyncStatusProcessStartTimeCalculation') {
              tableCell.textContent = this.epochDateTimePipe.transform(row[column.key], DateTimeFormatEnum.DateTime);
            } else if (column.key === 'SyncStatusReceiveTimeCalculation') {
              tableCell.textContent = this.epochDateTimePipe.transform(row[column.key], DateTimeFormatEnum.DateTime);
            } else if (column.key === 'SyncStatusProcessEndTimeCalculation') {
              tableCell.textContent = this.epochDateTimePipe.transform(row[column.key], DateTimeFormatEnum.DateTime);
            } else if (column.key === 'LastUpdatedCalculation') {
              tableCell.textContent = this.epochDateTimePipe.transform(row[column.key], DateTimeFormatEnum.DateTime);
            } else if (column.key === 'LactationStatusCalculation') {
              tableCell.textContent = this.translationService.translate(`REPORTS.GRID.STATUS.${row[column.key]}`);
            } else if (column.key.indexOf('Time') !== -1) {
              tableCell.textContent = this.epochDateTimePipe.transform(row[column.key], DateTimeFormatEnum.DateTime);
            } else if (column.key === 'LastUpdatedCalculation') {
              tableCell.textContent = this.epochDateTimePipe.transform(row[column.key], DateTimeFormatEnum.DateTime);
            } else if (column.key === 'SyncStatusDurationCalculation') {
              tableCell.textContent = this.reportsService.minToMs(row[column.key]);
            } else if (column.key === 'SyncStatusResultsCalculation') {
              tableCell.textContent = this.reportsService.parseSyncStatusResults(row[column.key]);
            } else if (column.key === 'SyncStatusStatusCalculation') {
              tableCell.textContent = this.translationService.translate('SYSTEM.MANAGEMENT.GRID.' + row[column.key]);
            } else if (column.key === 'DistressDurationCalculation') {
              tableCell.textContent = this.durationPipe.transform(row[column.key], DurationInput.Minutes);
            } else if (column.key === 'PercentOfRuminatingCowsCalculation') {
              tableCell.textContent = row[column.key] + '%';
            } else if (column.key === 'PercentOfPantingCowsCalculation') {
              tableCell.textContent = row[column.key] + '%';
            } else if (column.key === 'TagLedUseCalculation') {
              tableCell.textContent = row[column.key] ? row[column.key] + ' h' : '-';
            } else {
              tableCell.textContent = row[column.key];
            }
          }
          tableRow.appendChild(tableCell);
        }
      });
    });

    container.appendChild(table);

    var csvString = '';
    for (var i = 0; i < table.rows.length; i++) {
      // tslint:disable-next-line:no-any
      var rowData: any = table.rows[i].cells;
      for (var j = 0; j < rowData.length; j++) {
        csvString = csvString + rowData[j].innerHTML + ",";
      }
      csvString = csvString.substring(0, csvString.length - 1);
      csvString = csvString + '\r\n';
    }
    csvString = csvString.substring(0, csvString.length - 1);
    return csvString;
  }
}
