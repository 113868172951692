import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {
  FarmStatisticsBeefBarChartData,
  IFarmStatisticsFinishingWidgetContent,
  IMalesFemales
} from '../../../../../../services/dashboard/model/farm-statistics-finishing';

@Component({
  selector: 'farm-statistics-finishing',
  templateUrl: './farm-statistics-finishing.component.html',
  styleUrls: ['./farm-statistics-finishing.component.scss']
})
export class FarmStatisticsFinishingComponent implements OnChanges {

  @Input()
  public farmStatistics: IFarmStatisticsFinishingWidgetContent;

  public chartData: FarmStatisticsBeefBarChartData[] = [];

  public ngOnChanges(changes: SimpleChanges): void {
    if(this.farmStatistics != null) {
      this.initializeChartData();
    }
  }

  private initializeChartData(): void {
    const chartData: FarmStatisticsBeefBarChartData[] = [];
    const highestAggrValue = { value: 0 };
    if (this.farmStatistics.threeToSixMonth) {
      chartData.push(new FarmStatisticsBeefBarChartData(this.farmStatistics.threeToSixMonth, 0, AnimalAgeBoundaries.threeToSixMonth, null));
      this.checkHighestAggrValue(highestAggrValue, this.farmStatistics.threeToSixMonth);
    }
    if (this.farmStatistics.sixToNineMonth) {
      chartData.push(new FarmStatisticsBeefBarChartData(this.farmStatistics.sixToNineMonth, 1, AnimalAgeBoundaries.sixToNineMonth, null));
      this.checkHighestAggrValue(highestAggrValue, this.farmStatistics.sixToNineMonth);
    }
    if (this.farmStatistics.nineToTwelveMonth) {
      chartData.push(new FarmStatisticsBeefBarChartData(this.farmStatistics.nineToTwelveMonth, 2, AnimalAgeBoundaries.nineToTwelveMonth, null));
      this.checkHighestAggrValue(highestAggrValue, this.farmStatistics.nineToTwelveMonth);
    }
    if (this.farmStatistics.twelveToFifteenMonth) {
      chartData.push(new FarmStatisticsBeefBarChartData(this.farmStatistics.twelveToFifteenMonth, 3, AnimalAgeBoundaries.twelveToFifteenMonth, null));
      this.checkHighestAggrValue(highestAggrValue, this.farmStatistics.twelveToFifteenMonth);
    }
    if (this.farmStatistics.fifteenToEighteenMonth) {
      chartData.push(new FarmStatisticsBeefBarChartData(this.farmStatistics.fifteenToEighteenMonth, 4, AnimalAgeBoundaries.fifteenToEighteenMonth, null));
      this.checkHighestAggrValue(highestAggrValue, this.farmStatistics.fifteenToEighteenMonth);
    }
    if (this.farmStatistics.eighteenPlusMonth) {
      chartData.push(new FarmStatisticsBeefBarChartData(this.farmStatistics.eighteenPlusMonth, 5, AnimalAgeBoundaries.eighteenPlusMonth, null));
      this.checkHighestAggrValue(highestAggrValue, this.farmStatistics.eighteenPlusMonth);
    }
    this.chartData = chartData.sort((left: FarmStatisticsBeefBarChartData, right: FarmStatisticsBeefBarChartData) => left.order - right.order);
    this.chartData.forEach((chartDataItem: FarmStatisticsBeefBarChartData) => {
      chartDataItem.highestAggrValue = highestAggrValue.value;
    });
  }

  private checkHighestAggrValue(highestValueContainer: { value: number }, malesFemales: IMalesFemales): void {
    if (highestValueContainer.value < malesFemales.males + malesFemales.females) {
      highestValueContainer.value = malesFemales.males + malesFemales.females;
    }
  }
}

export enum ColumnChartType {
  Male = 'Male',
  Female = 'Female'
}

export enum AnimalAgeBoundaries {
  threeToSixMonth = 'threeToSixMonth',
  sixToNineMonth = 'sixToNineMonth',
  nineToTwelveMonth = 'nineToTwelveMonth',
  twelveToFifteenMonth = 'twelveToFifteenMonth',
  fifteenToEighteenMonth = 'fifteenToEighteenMonth',
  eighteenPlusMonth = 'eighteenPlusMonth'
};
