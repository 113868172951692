<div class="account-details-container-wrapper">
  <div class="left-section">
    <div class="form-group">
      <div class="label" [attr.translation-id]="'REGISTRATION.FLOW.FARM_ID'">{{ 'REGISTRATION.FLOW.FARM_ID' | translate }}: <span [attr.sh-id]="'span_farm_id_text'">{{ registrationService.registrationModel.farmDetails.farmId }}</span></div>
    </div>
    <div class="form-group">
      <div class="text" [attr.translation-id]="'REGISTRATION.FLOW.NEEDED_FOR_LOGIN_BY_PROXY'">* {{ 'REGISTRATION.FLOW.NEEDED_FOR_LOGIN_BY_PROXY' | translate }}</div>
    </div>
    <div class="form-group">
      <div class="label" [attr.translation-id]="'REGISTRATION.FLOW.PLAN'">
        {{ 'REGISTRATION.FLOW.PLAN' | translate }}:
        <span *ngIf="showSingleApplicationPlanOnly" class="text" [attr.sh-id]="'plan_'+orderedPopulationPlanModel.plans[0].plan" [attr.translation-id]="'FOOTER.ABOUT.PLAN.' + orderedPopulationPlanModel.plans[0].value">
          {{ 'FOOTER.ABOUT.PLAN.' + orderedPopulationPlanModel.plans[0].value | translate }}
        </span>
      </div>
    </div>
    <ng-container *ngIf="showMultipleApplicationPlans && !showSingleApplicationPlanOnly">
      <ng-container *ngFor="let p of orderedPopulationPlanModel.plans;">
        <div class="form-group" *ngIf="showApplicationPlanItem(p)">
            <div class="text" [attr.sh-id]="'plan_'+p.plan" [attr.translation-id]="'FOOTER.ABOUT.PLAN.' + p.value">
              {{ 'FOOTER.ABOUT.PLAN.' + p.value | translate }}
              <ng-container *ngIf="showPlanDetails(p)">
                {{' '}}({{ 'FOOTER.ABOUT.PLAN.' + p.plan | translate }})
              </ng-container>
            </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="form-group">
      <div class="label" [attr.translation-id]="'REGISTRATION.FLOW.LINKED'">{{ 'REGISTRATION.FLOW.LINKED' | translate }}: <span class="text" [attr.sh-id]="'linked_services'" [attr.translation-id]="'FOOTER.ABOUT.LINKED.' + registrationService.registrationModel.farmDetails.link">{{ 'FOOTER.ABOUT.LINKED.' + registrationService.registrationModel.farmDetails.link | translate }}</span></div>
    </div>
  </div>
  <div class="right-section">
    <div class="dual-input-fields-wrapper">
      <div class="form-field">
        <div class="label" [attr.translation-id]="'REGISTRATION.FLOW.FARM_NAME'">{{ 'REGISTRATION.FLOW.FARM_NAME' | translate }}</div>
        <input class="green-input value-area"
               [attr.sh-id]="'farm_name_textbox'"
               [ngClass]="{ error: registrationService.registrationModel.error && registrationService.registrationModel.error.fieldName === 'farmName' }"
               type="text"
               [(ngModel)]="registrationService.registrationModel.farmDetails.farmName"
               (ngModelChange)="clearErrors()"
               placeholder="{{ 'REGISTRATION.FLOW.FARM_NAME' | translate }}"/>
      </div>
      <div class="form-field">
        <div class="label" [ngClass]="{ disabled: isFinishingStandalone() }" [attr.translation-id]="'REGISTRATION.FLOW.FARM_HOUSING'">{{ 'REGISTRATION.FLOW.FARM_HOUSING' | translate }}</div>
        <regular-dropdown-list *ngIf="!isFinishingStandalone()"
                               [isFieldError]="registrationService.registrationModel.error && registrationService.registrationModel.error.fieldName === 'farmHousing'"
                               [colorScheme]="dropdownColorScheme.greyGreen"
                               [items]="FarmHousingOptionItems"
                               [placeholder]="'REGISTRATION.FLOW.FARM_HOUSING'"
                               [shIdPostfix]="'REGISTRATION.FLOW.FARM_HOUSING'"
                               [selectedValue]="selectedFarmHousing"
                               (onItemSelected)="selectFarmHousing($event); clearErrors();"></regular-dropdown-list>
        <input class="grey-input value-area"
               *ngIf="isFinishingStandalone()"
               type="text"
               disabled="disabled"
               [ngModel]="selectedFarmHousing?.displayValue"
               [attr.sh-id]="'address_textbox'"
               placeholder="{{ 'REGISTRATION.FLOW.FARM_HOUSING' | translate }}"/>
      </div>
    </div>
    <div class="dual-input-fields-wrapper">
      <div class="form-field">
        <div class="label" [attr.translation-id]="'REGISTRATION.FLOW.ADDRESS'">{{ 'REGISTRATION.FLOW.ADDRESS' | translate }}</div>
        <input class="grey-input value-area"
               type="text"
               [(ngModel)]="registrationService.registrationModel.farmDetails.address"
               [attr.sh-id]="'address_textbox'"
               (ngModelChange)="clearErrors()"
               placeholder="{{ 'REGISTRATION.FLOW.ADDRESS' | translate }}"/>
      </div>
      <div class="form-field">
        <div class="label" [attr.translation-id]="'REGISTRATION.FLOW.CITY'">{{ 'REGISTRATION.FLOW.CITY' | translate }}</div>
        <input class="grey-input value-area"
               type="text"
               [(ngModel)]="registrationService.registrationModel.farmDetails.city"
               (ngModelChange)="clearErrors()"
               [attr.sh-id]="'city_textbox'"
               placeholder="{{ 'REGISTRATION.FLOW.CITY' | translate }}"/>
      </div>
    </div>
    <div class="dual-input-fields-wrapper">
      <div class="form-field">
        <div class="label" [attr.translation-id]="'REGISTRATION.FLOW.COUNTRY'">{{ 'REGISTRATION.FLOW.COUNTRY' | translate }}</div>
        <regular-dropdown-list [colorScheme]="dropdownColorScheme.greyGreen"
                               [items]="CountriesOptionItems"
                               [placeholder]="'REGISTRATION.FLOW.COUNTRY'"
                               [shIdPostfix]="'REGISTRATION.FLOW.COUNTRY'"
                               [selectedValue]="selectedCountry"
                               (onItemSelected)="selectCountry($event); clearErrors();"></regular-dropdown-list>
      </div>
      <div class="form-field">
        <div class="label" [attr.translation-id]="'REGISTRATION.FLOW.TIME_ZONE'">{{ 'REGISTRATION.FLOW.TIME_ZONE' | translate }}</div>
        <regular-dropdown-list [isFieldError]="registrationService.registrationModel.error && registrationService.registrationModel.error.fieldName === 'timeZone'"
                               [colorScheme]="dropdownColorScheme.greyGreen"
                               [items]="TimeZoneOptionItems"
                               [placeholder]="'REGISTRATION.FLOW.TIME_ZONE'"
                               [shIdPostfix]="'REGISTRATION.FLOW.TIME_ZONE'"
                               [selectedValue]="selectedTimeZone"
                               (onItemSelected)="selectTimeZone($event); clearErrors();"></regular-dropdown-list>
      </div>
    </div>
    <div class="error-wrapper" *ngIf="registrationService.registrationModel.error">
      <b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}: </b> {{ registrationService.registrationModel.error.error }}
    </div>
  </div>
</div>
