import {PollingStatusText} from '../../rest-api/rest-api.service';
import {ErrorModel} from "../../model/common-model";

export enum ProvisioningResponseError {
  FailedToSignupToDataSharing = 'FailedToSignupToDataSharing',
  AdminDoesntHaveEmail = 'AdminDoesntHaveEmail',
  WrongCredentials = 'WrongCredentials',
  ThereIsMoreThanOneAdminInTheDB = 'ThereIsMoreThanOneAdminInTheDB'
}

export class ProvisioningAuthModel implements IProvisioningSignInRequest {
  constructor(public adminName: string, public password: string) {
  }
}

export interface IProvisioningSignInRequest {
  adminName: string;
  password: string;
}

export interface ISignInCorrelationIdResponseData {
  succeeded: boolean;
  failures?: ErrorModel[];
  correlationId: string;
}

export interface ISignInResponseData {
  succeeded: boolean;
  provisioningLink?: string;
  pollingStatus?: PollingStatusText,
  failures?: ErrorModel[];
}

export interface ISignUpResponseData {
  succeeded: boolean;
  pollingStatus?: PollingStatusText;
  failures?: ErrorModel[];
}

export interface IProvisioningSignInResponse {
  result: ISignInResponseData;
}
