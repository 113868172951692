<div class="card-header">
  <div class="animal-title" *ngIf="isAnimalTitleShown">
    <span *ngIf="!cardViewState.animalCardViewState.animalInfo.isCulled && cardViewState.animalCardViewState.animalInfo.sex === AnimalSex.Male" class="icon-animal-male"></span>
    <span *ngIf="!cardViewState.animalCardViewState.animalInfo.isCulled && cardViewState.animalCardViewState.animalInfo.sex === AnimalSex.Female" class="icon-animal-cow"></span>
    <span *ngIf="cardViewState.animalCardViewState.animalInfo.isCulled" class="icon-animal-culled"></span>
    <span class="animal-name" [attr.sh-id]="'animal_card_' + cardViewState.animalCardViewState.animalInfo.animalName">{{cardViewState.animalCardViewState.animalInfo.animalName}}</span>
  </div>
  <div class="group-title" *ngIf="isGroupTitleShown">
    <span class="icon-group"></span>
    <span class="group-name" [attr.sh-id]="'group_card_' + cardViewState.groupCardViewState.groupDetails.groupName">{{cardViewState.groupCardViewState.groupDetails.groupName}}</span>
  </div>
  <div class="branch-title" *ngIf="isBranchTitleShown">
    <span class="icon-branch"></span>
    <span class="branch-name" *ngIf="!cardViewState.branchCardViewState.branchDetails.groupBranchName.isLocalization"
          [attr.sh-id]="'branch_card_' + cardViewState.branchCardViewState.branchDetails.groupBranchName.value">{{ cardViewState.branchCardViewState.branchDetails.groupBranchName.value }}</span>
    <span class="branch-name" *ngIf="cardViewState.branchCardViewState.branchDetails.groupBranchName.isLocalization"
          [attr.sh-id]="'branch_card_' + cardViewState.branchCardViewState.branchDetails.groupBranchName.value"
          [attr.translation-id]="'REPORTS.GRID.STATUS.' + cardViewState.branchCardViewState.branchDetails.groupBranchName.value">{{ 'REPORTS.GRID.STATUS.' + cardViewState.branchCardViewState.branchDetails.groupBranchName.value | translate }}</span>
  </div>
</div>
