import {AfterViewInit, Component} from '@angular/core';
import {
  CreatePregnancyCheckEventDetails,
  PregnancyCheckCowEventMetadata,
  PregnancyCheckType,
  CreateEventMode
} from '../../../../../services/animals/model/animal-events';
import * as moment from 'moment';
import {EventFormComponent} from '../event-form.component';
import {DropdownListItem} from '../../../../../common/components/dropdown-list/dropdown-list-model';
import {TranslationService} from '../../../../../services/translations/translation.service';
import {FormsDLIconsThemes} from "../../../../../common/components/dropdown-list/forms-dropdown-list/forms-dropdown-list.component";

@Component({
  selector: 'pregnancy-check-event-form',
  templateUrl: './pregnancy-check-event-form.component.html',
  styleUrls: ['./pregnancy-check-event-form.component.scss']
})
export class PregnancyCheckEventFormComponent extends EventFormComponent<PregnancyCheckCowEventMetadata, CreatePregnancyCheckEventDetails> implements AfterViewInit {

  public PregnancyCheckType = PregnancyCheckType;

  public formsDLIconsThemes = FormsDLIconsThemes;

  public inseminations: DropdownListItem<number>[] = [];

  public get pregnancyCheckType(): PregnancyCheckType {
    return this.editModel.type;
  }

  public set pregnancyCheckType(newValue: PregnancyCheckType) {
    this.editModel.type = newValue;
    if (newValue !== PregnancyCheckType.positivePregnancyCheck) {
      this.editModel.effectiveInsemination = null;
    } else if (this.meta.inseminations && this.meta.inseminations.length > 0 && this.mode === CreateEventMode.single) {
      this.editModel.effectiveInsemination = this.inseminations[0].value;
    }
    this.emitModelChanged();
  }

  public get effectiveInsemination(): number {
    return this.editModel.effectiveInsemination;
  }

  public set effectiveInsemination(newValue: number) {
    this.editModel.effectiveInsemination = newValue;
    this.emitModelChanged();
  }

  public get isEffectiveInseminationAvailable(): boolean {
    return this.meta.inseminations != null &&
      this.pregnancyCheckType === PregnancyCheckType.positivePregnancyCheck &&
      this.mode === CreateEventMode.single;
  }

  constructor(private readonly translationService: TranslationService) {
    super();
  }

  public updateInseminations(): void {
    const dateFormat = `${this.translationService.translate('LOCALES.DATE_FORMAT.DEFAULT.DATE_TEMPLATE')} HH:mm`;
    this.inseminations = (this.meta.inseminations || [])
      .sort((a, b) => b.breedingNumber - a.breedingNumber)
      .map<DropdownListItem<number>>((insemination) => ({
        displayValue: `${insemination.breedingNumber} - ${moment.unix(insemination.startDateTime).format(dateFormat)}`,
        disabled: false,
        icon: null,
        value: insemination.eventId
      }));
  }

  public ngAfterViewInit(): void {
    this.updateInseminations();
  }
}
