import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownListItem } from 'src/app/common/components/dropdown-list/dropdown-list-model';
import { IAvailableSortingGate, IAssociatedGateViewModel } from '../system-sorting-pens.component';

@Component({
  selector: 'system-sorting-pen-associated-gate',
  templateUrl: 'system-sorting-pen-associated-gate.component.html',
  styleUrls: ['system-sorting-pen-associated-gate.component.scss', '../../shared.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SystemSortingPenAssociatedGateComponent),
      multi: true
    }
  ]
})
export class SystemSortingPenAssociatedGateComponent implements ControlValueAccessor, OnChanges {
  public disabled = false;
  @Input()
  public availableGates?: IAvailableSortingGate[];
  public gateItems: DropdownListItem<number>[];
  public currentGateId?: number;
  public currentDirection: 'left' | 'right' | null;
  private readonly defaultAvailableDirections = {
    left: false,
    right: false
  };
  public availableDirections: {
    left: boolean;
    right: boolean;
  } = this.defaultAvailableDirections;
  private onTouched: Function;
  private onChange: Function;

  public get isDirectionsDisable(): boolean {
    if (this.currentDirection === 'left' && this.availableDirections.right === false) {
      return true;
    }

    if (this.currentDirection === 'right' && this.availableDirections.left === false) {
      return true;
    }

    return this.currentDirection == null && this.availableDirections.left === false && this.availableDirections.right === false;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('availableGates' in changes) {
      this.gateItems = this.availableGates.map<DropdownListItem<number>>((ag) => ({
        value: ag.gateId,
        displayValue: ag.name,
        disabled: false,
        icon: null
      }));
    }

    this.updateAvailableDirections(this.currentGateId);
  }

  public onDirectionChanged(direction: 'left' | 'right'): void {
    this.currentDirection = direction;
    this.onChange({
      gateId: this.currentGateId,
      direction: this.currentDirection
    });
  }

  public onSortingGateChanged(newGateId: number): void {
    this.currentGateId = newGateId;
    this.onChange({
      gateId: this.currentGateId,
      direction: this.currentDirection
    });

    this.updateAvailableDirections(newGateId);

    if (this.currentDirection == null) {
      if (this.availableDirections.left === true && this.availableDirections.right === false) {
        this.currentDirection = 'left';
      } else if (this.availableDirections.right === true && this.availableDirections.left === false) {
        this.currentDirection = 'right';
      }
    }
  }

  public writeValue(obj: IAssociatedGateViewModel): void {
    if (obj == null) {
      return;
    }
    this.currentGateId = obj.gateId;
    this.currentDirection = obj.direction;
    this.updateAvailableDirections(this.currentGateId);
  }

  // tslint:disable-next-line:no-any
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // tslint:disable-next-line:no-any
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private updateAvailableDirections(gateId: number | null) {
    if (gateId == null) {
      return this.defaultAvailableDirections;
    }

    this.availableDirections = this.availableGates.find((ag) => ag.gateId === gateId).directions;
  }
}
