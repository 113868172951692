<ng-container *ngIf="editModel">
  <div class="create-event-form">
    <div class="form-section-title">
      {{ 'ANIMAL.EVENTS.ADD_EVENT.OPEN_NEW_LACTATION' | translate }}
    </div>
    <div class="form-section-item">
      <div class="abortion-type">
        <input id="radio-abortion-new-lactation"
               type="radio"
               name="abortionType"
               value="abortionNewLactation"
               [(ngModel)]="abortionType" />
        <label [attr.sh-id]='"abortionNewLactation"' for="radio-abortion-new-lactation">{{ 'ANIMAL.EVENTS.ADD_EVENT.OPEN_NEW_LACTATION_YES' | translate }}</label>
        <input id="radio-abortion-same-lactation"
               type="radio"
               name="abortionType"
               value="abortionSameLactation"
               [(ngModel)]="abortionType" />
        <label [attr.sh-id]='"abortionSameLactation"' for="radio-abortion-same-lactation">{{ 'ANIMAL.EVENTS.ADD_EVENT.OPEN_NEW_LACTATION_NO' | translate }}</label>
      </div>
    </div>
    <button  *ngIf="!isAddNewbornsDisabled && abortionType === AbortionType.AbortionNewLactation && mode === CreateEventMode.single"
            (click)="handleAddNewborn($event)"
            class="add-newborn-button"
            [disabled]="editModel.newborns.length >= 3">
      <div class="add-newborn-button__icon">
        <div class="icon icon-x-button-90"></div>
      </div>
      {{ 'ANIMAL.EVENTS.ADD_EVENT.ADD_NEW_BORN' | translate }}
    </button>
  </div>

  <div class="create-event-form">
    <div class="form-section-title">
      {{ 'ANIMAL.EVENTS.ADD_EVENT.DESTINATION_GROUP' | translate }}
    </div>

    <div class="form-section-item">
      <forms-dropdown-list
        class="form__control"
        [items]="cowsGroups"
        [iconTheme]="formsDLIconsThemes.Blue"
        [(ngModel)]="destinationGroup"
        [placeholder]="'Select Group'"
        [isFieldError]="isFieldNameWithError(EventFormFieldName.destinationGroup)"
        [translateListItem]="false"
        [translatePlaceholder]="false">
      </forms-dropdown-list>
    </div>

    <div class="newborns-control form__control" *ngIf="!isAddNewbornsDisabled && abortionType === AbortionType.AbortionNewLactation && editModel.newborns?.length > 0">
      <div class="newborn-item"
           *ngFor="let newborn of editModel.newborns; let i = index"
           [class.newborns-control__item--invalid]="errorModel.fieldName && errorModel.fieldName.startsWith('newborns') && errorModel.index === i"
           (click)="handleEditNewborn($event, newborn)">
        <span>{{ newborn.sex  | uppercase | slice : 0:1 }}</span>
        <div class="border"></div>
      </div>
    </div>
  </div>
</ng-container>

<add-newborn-dialog
  [meta]="meta"
  [destinationGroups]="meta.heifersGroups"
  [enableRemoveButton]="editableNewbornIndex != null"
  [model]="editedNewborn"
  [errorFieldName]="newbornErrorField"
  (close)="handleCloseAddNewbornPopup()"
  (newbornChange)="handleAddNewbornChange($event)"
  (remove)="handleRemoveNewborn()">
</add-newborn-dialog>
