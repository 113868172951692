import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IErrors, AddAnimalFieldsErrorsEnum, IGroupItem, IFemalesMetadata} from '../../../../../../../services/animals/model/animal.model';
import {AnimalsService} from '../../../../../../../services/animals/animals.service';
import {
  DropdownListAutoSelectionMode,
  DropdownListItem
} from '../../../../../../../common/components/dropdown-list/dropdown-list-model';
import {
  BullColorTheme,
  BullListItem
} from '../../../../../../../common/components/bull-choose-dropdown-list/bull-choose-dropdown-list.component';
import {EpochStateModel} from '../../../../../../../common/components/calendar/model/epoch-state-model';
import {AddFemaleModel} from '../add-female-breeding.component';
import {ArrowIconColors} from '../../../../../../../common/components/numeric-input/numeric-input.component';
import {FormsDLIconsThemes} from '../../../../../../../common/components/dropdown-list/forms-dropdown-list/forms-dropdown-list.component';

@Component({
  selector: 'add-female-breeding-breeding-tab',
  templateUrl: './add-female-breeding-breeding-tab.component.html',
  styleUrls: ['./add-female-breeding-breeding-tab.component.scss']
})
export class AddFemaleBreedingBreedingTabComponent implements OnInit, OnChanges {

  public dropdownListAutoSelectionMode = DropdownListAutoSelectionMode;

  @Input()
  public addFemaleModel: AddFemaleModel;

  @Input()
  public groupsDropdownListItems: DropdownListItem[] = [];

  @Input()
  public femalesMetadata: IFemalesMetadata;

  @Input()
  public errors: IErrors[] = [];

  @Output()
  public onGroupSelected : EventEmitter<IGroupItem> = new EventEmitter<IGroupItem>();

  @Output()
  public onErrorsClear: EventEmitter<void> = new EventEmitter<void>();

  public bullListItems: BullListItem[] = [];

  public AddAnimalFieldsErrorsEnum = AddAnimalFieldsErrorsEnum;

  public ArrowIconColors = ArrowIconColors;

  public formsDLIconsThemes = FormsDLIconsThemes;

  public bullColorTheme = BullColorTheme;

  constructor(public readonly animalsService: AnimalsService) { }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.femalesMetadata != null &&
      changes.femalesMetadata.previousValue != changes.femalesMetadata.currentValue) {
      if (this.femalesMetadata.sireList != null) {
        this.bullListItems = this.femalesMetadata.sireList.map(value => ({id: value.id, name: value.name, number: value.number}));
      } else {
        this.bullListItems = []
      }
    }
  }

  // tslint:disable-next-line:no-any
  public onBreedingDateChanged(event: EpochStateModel) {
    this.addFemaleModel.breedingDate = event;
    this.addFemaleModel.updateDatesRanges();
    this.onErrorsClear.emit();
  }

  public onBreedingNumberChanged(selectedBreedingNumber: number) {
    this.addFemaleModel.breedingNumber = selectedBreedingNumber;
    this.onErrorsClear.emit();
  }

  public onBullSelected(selectedBull: BullListItem) {
    this.addFemaleModel.bull = selectedBull;
  }

  public onBullRemoved(bullListItem:BullListItem) {
    this.bullListItems = this.bullListItems.filter(value => value.id != bullListItem.id);
  }
}
