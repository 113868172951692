import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IDashboardAvailableKPI} from '../../../../services/dashboard/model/dashboard-kpi.interface';
import {IDashboardAvailableWidget, IDashboardWidget} from '../../../../services/dashboard/model/dashboard-widgets.interface';
import {DashboardWidgetsService} from '../../../../services/dashboard/dashboard-widgets.service';

export class SelectWidgetDialogModel {
  public availableWidgets: IDashboardAvailableWidget[];
  public selectedWidgets: IDashboardWidget[];
}

@Component({
  selector: 'dashboard-widget-select-dialog',
  templateUrl: './dashboard-widget-select-dialog.component.html',
  styleUrls: ['./dashboard-widget-select-dialog.component.scss']
})
export class DashboardWidgetSelectDialogComponent implements OnInit {

  @Output()
  public onApply: EventEmitter<IDashboardAvailableWidget> = new EventEmitter<IDashboardAvailableWidget>();

  @Output()
  public onCancel: EventEmitter<void> = new EventEmitter<void>();

  public isOpen: boolean;

  public availableWidgets: IDashboardAvailableWidget[] = [];

  public selectedWidget: IDashboardAvailableWidget;

  constructor(public readonly dashboardWidgetsService: DashboardWidgetsService) { }

  public ngOnInit() {
  }

  public open(selectWidgetDialogModel:SelectWidgetDialogModel) {
    this.availableWidgets = [];
    this.selectedWidget = null;
    for (let availableWidget of selectWidgetDialogModel.availableWidgets) {
      let selectedWidget = selectWidgetDialogModel.selectedWidgets.find(value => value.category == availableWidget.category &&
                                                                                           value.name == availableWidget.name &&
                                                                                           value.type == availableWidget.type);
      if(selectedWidget == null){
        this.availableWidgets.push(availableWidget);
      }
    }
    this.isOpen = true;
  }

  public selectWidget(widget:IDashboardAvailableWidget) {
    this.selectedWidget = widget;
  }

  public getWidgetIcon(type:string, category:string) : string {
    if(type == 'Report') {
      switch (category) {
        case 'Reproduction': {
          return 'icon-reproduction';
        }
        case 'Health': {
          return 'icon-systemHealth';
        }
        case 'Group': {
          return 'icon-group';
        }
        case 'YoungStock': {
          return 'icon-young-stock';
        }
        case 'Maintenance': {
          return 'icon-maintenance';
        }
        case 'Finishing': {
          return 'icon-finishing-health';
        }
      }

    } else if(type == 'Graph') {
      return 'icon-statistics';
    }
  }

  public closeClick() {
    this.isOpen = false;
    this.onCancel.emit();
  }

  public applyClick() {
    if (this.selectedWidget) {
      this.isOpen = false;
      this.onApply.emit(this.selectedWidget);
    }
  }
}
