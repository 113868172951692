import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {WINDOW} from '../../../utils/window-utils';
import {SMALL_SCREEN_MAX_WIDTH} from '../../../utils/screen-calculation-utils';
import {MenuItemType} from '../left-menu.component';

@Component({
  selector: 'menu-item-default',
  templateUrl: './menu-item-default.component.html',
  styleUrls: ['./menu-item-default.component.scss']
})
export class MenuItemDefaultComponent implements OnInit {

  @Input()
  public titleKey: string;

  @Input()
  public iconClass: string;

  @Input()
  public link: string;

  @Input()
  public isOpenMenu: boolean;

  @Output()
  public onToggleMenu: EventEmitter<MenuItemType> = new EventEmitter();

  public onClick() {
    this.onToggleMenu.emit(MenuItemType.Default);
  }

  constructor(@Inject(WINDOW) private windoW: Window) { }

  public ngOnInit() {}
}
