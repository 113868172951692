import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ApplicationBundleRoutingMode, RoutingService} from '../../routing/routing.service';
import {ConfigService} from '../../config/config.service';
import {AllowedManageAppBundle} from '../../config/model/server-config';


@Injectable({
  providedIn: 'root'
})
export class ManageGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router,
              private routingService: RoutingService,
              public configService: ConfigService) {

  }

  public canActivate (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.handleRoute(next, state);
    return true;
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.handleRoute(childRoute, state);
    return true;
  }

  private handleRoute(activeRouteSnapshot:ActivatedRouteSnapshot, state: RouterStateSnapshot){
    let allowedManageAppBundle = this.configService.allowedManageAppBundle;
    let routingMode = this.routingService.getApplicationBundleRoutingMode(activeRouteSnapshot.queryParams);
    if(routingMode === ApplicationBundleRoutingMode.Breeding &&
      allowedManageAppBundle === AllowedManageAppBundle.Finishing) {
      this.router.navigate([state.url.split('?')[0]], {queryParams: {'appBoundle': ApplicationBundleRoutingMode.Finishing}});
    } else if(routingMode === ApplicationBundleRoutingMode.Finishing &&
      allowedManageAppBundle === AllowedManageAppBundle.Breeding) {
      this.router.navigate([state.url.split('?')[0]], {queryParams: {'appBoundle': ApplicationBundleRoutingMode.Breeding}});
    } else if(routingMode !== ApplicationBundleRoutingMode.Breeding &&
      routingMode !== ApplicationBundleRoutingMode.Finishing){
      if (allowedManageAppBundle === AllowedManageAppBundle.Breeding ||
        allowedManageAppBundle === AllowedManageAppBundle.BreedingAndFinishing) {
        this.router.navigate([state.url.split('?')[0]], {queryParams: {'appBoundle': ApplicationBundleRoutingMode.Breeding}});
      } else {
        this.router.navigate([state.url.split('?')[0]], {queryParams: {'appBoundle': ApplicationBundleRoutingMode.Finishing}});
      }
    }
  }
}
