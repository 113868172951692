import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {PollingService} from '../../../../services/rest-api/polling.service';

@Component({
  selector: 'connectivity-dialog',
  templateUrl: './connectivity-dialog.component.html',
  styleUrls: ['./connectivity-dialog.component.scss']
})
export class ConnectivityDialogComponent implements OnInit, OnDestroy {

  public hasConnectivity: boolean = true;

  private subscription: Subscription;

  constructor(private pollingService: PollingService) {

  }

  public async ngOnInit(): Promise<void> {
    this.subscription = this.pollingService.serverResponsibility.subscribe(value => {
        this.hasConnectivity = value.isResponsible;
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
