import {Component, OnDestroy, OnInit} from '@angular/core';
import {IEntityAlert} from '../../../services/alerts/model/alerts';
import {Subscription} from 'rxjs';
import {AlertsService} from '../../../services/alerts/alerts.service';
import {SYSTEM_ALRTS_TRANSITIONS} from '../../../common/animations/animations';
import {GoogleAnalyticsService} from '../../../services/google-analytics/google-analytics.service';
import { DateTimeFormatEnum } from 'src/app/common/pipes/epoch-date-time.pipe';
import * as moment from 'moment';
import {TranslationService} from '../../../services/translations/translation.service';

@Component({
  selector: 'system-alerts',
  templateUrl: './system-alerts.component.html',
  styleUrls: ['./system-alerts.component.scss'],
  animations: [SYSTEM_ALRTS_TRANSITIONS]
})
export class SystemAlertsComponent implements OnInit, OnDestroy {

  public systemAlertsSubscription: Subscription;

  public isPopupOpen: boolean = false;

  public alerts: IEntityAlert[] = [];

  public DateTimeFormatEnum = DateTimeFormatEnum;

  constructor(private alertsService: AlertsService,
              private translationService: TranslationService,
              private googleAnalyticsService: GoogleAnalyticsService) {}

  public async ngOnInit() {
      this.systemAlertsSubscription = this.alertsService.systemAlertsSubject.subscribe((data) => {
          this.initAlertsData(data)
      });
      let entityAlertsResponse = await this.alertsService.getSystemAlerts();
      if (entityAlertsResponse.responseBody) {
        this.initAlertsData(entityAlertsResponse.responseBody);
      }
  }

  public ngOnDestroy(): void {
    this.systemAlertsSubscription.unsubscribe();
  }

  public get alertCount() {
    return (this.alerts || []).length;
  }

  private initAlertsData(data:IEntityAlert[]){
      this.alerts = [];
      if (data) {
          data.forEach((entity: IEntityAlert) => {
              if (entity.alertDateTime) {
                  entity.alertDateTime = entity.alertDateTime;
                  entity.calculatedTime = this.timeDuration(entity.alertDateTime);
              } else {
                  entity.alertDateTime = moment().unix();
                  entity.calculatedTime = this.timeDuration(entity.alertDateTime);
              }
              this.alerts.push(entity);
          });
      }
  }

  // tslint:disable-next-line:no-any
  private timeDuration(timeMin: any) {
    let currentTime: number = moment().unix();
    const timeDifference: number = Math.floor((currentTime - timeMin));
    let interval: number = Math.floor(timeDifference / 31536000);

    if (interval > 1) {
      return `${interval} ${this.translationService.translate('REPORTS.GRID.TIME_AGO.YEARS_AGO')}`;
    }
    interval = Math.floor(timeDifference / 2592000);
    if (interval > 1) {
      return `${interval} ${this.translationService.translate('REPORTS.GRID.TIME_AGO.DAYS_AGO')}`;
    }
    interval = Math.floor(timeDifference / 86400);
    if (interval > 1) {
      return `${interval} ${this.translationService.translate('REPORTS.GRID.TIME_AGO.DAYS_AGO')}`;
    }
    interval = Math.floor(timeDifference / 3600);
    if (interval > 1) {
      return `${interval} ${this.translationService.translate('REPORTS.GRID.TIME_AGO.HOURS_AGO')}`;
    }
    interval = Math.floor(timeDifference / 60);
    if (interval > 1) {
      return `${interval} ${this.translationService.translate('REPORTS.GRID.TIME_AGO.MINUTES_AGO')}`;
    }
    return this.translationService.translate('REPORTS.GRID.TIME_AGO.FEW_SECONDS');
  }

  private calculateTimeDuration() {
    this.alerts.forEach((alert: IEntityAlert) => {
      alert.calculatedTime = this.calculateData(alert.alertDateTime);
    });
  }

  // tslint:disable-next-line:no-any
  private calculateData(time: any) {
    return this.timeDuration(time);
  }

  public toggleAlertsDialog() {
    this.calculateTimeDuration();
    if (this.isPopupOpen) {
      this.closeAlertsDialog();
    } else {
      this.isPopupOpen = true;
      this.googleAnalyticsService.send('TopMenu', 'Open', 'SystemAlerts');
    }
  }

  public closeAlertsDialog() {
    this.isPopupOpen = false;
  }
}
