<div class="menu-header" saveDataWarningIgnore (click)="toggleMenu(MenuItemType.MenuHeader)" sh-id="left_menu_header" #leftMenuHeader>
  <div class="burger-menu"></div>
  <div class="app-logo"></div>
  <div class="brand">SenseHub</div>
  <div class="shadow"></div>
</div>
<nav class="app-nav-menu" saveDataWarningIgnore>
  <menu-item-default link="/dashboard"
                     saveDataWarningInject
                     (click)="sendGoogleAnalyticsData('DASHBOARD')"
                     iconClass="icon-dashboard"
                     titleKey="DASHBOARD"
                     (onToggleMenu)="toggleMenu($event)"
                     [isOpenMenu]="isOpenMenu"
                     [ngClass]="{'menu-open': isOpenMenu}"
                     sh-id="menu-item-dashboard"></menu-item-default>
  <menu-item-reports link="/reports"
                     saveDataWarningIgnore
                     (click)="sendGoogleAnalyticsData('REPORTS')"
                     iconClass="icon-reports"
                     titleKey="REPORTS"
                     (onToggleMenu)="toggleMenu($event)"
                     [isOpenMenu]="isOpenMenu"
                     [ngClass]="{'menu-open': isOpenMenu}"
                     sh-id="menu-item-reports"></menu-item-reports>
  <menu-item-manage  link="/manage"
                     saveDataWarningInject
                     (click)="sendGoogleAnalyticsData('MANAGE')"
                     iconClass="icon-manage"
                     titleKey="MANAGE"
                     (onToggleMenu)="toggleMenu($event)"
                     [isOpenMenu]="isOpenMenu"
                     [ngClass]="{'menu-open': isOpenMenu}"
                     sh-id="menu-item-manage"></menu-item-manage>
  <menu-item-sorting-gate *ngIf="isSortingGateMenuVisible"
                          saveDataWarningIgnore
                          link="/sorting-gate"
                          (click)="sendGoogleAnalyticsData('SORTING_GATEE')"
                          iconClass="icon-sorting"
                          titleKey="SORTING_GATE"
                          (onToggleMenu)="toggleMenu($event)"
                          [isOpenMenu]="isOpenMenu"
                          [ngClass]="{'menu-open': isOpenMenu}"
                          sh-id="menu-item-sorting-gate"></menu-item-sorting-gate>
  <menu-item-default link="/led-tasks"
                     saveDataWarningInject
                     *ngIf="isLedTasksMenuVisible"
                     (click)="sendGoogleAnalyticsData('LED-TASKS')"
                     iconClass="icon-led-tasks"
                     titleKey="LED_TASKS"
                     (onToggleMenu)="toggleMenu($event)"
                     [isOpenMenu]="isOpenMenu"
                     [ngClass]="{'menu-open': isOpenMenu}"
                     sh-id="menu-item-led-tasks"></menu-item-default>
  <menu-item-default *ngIf="hasPermissionsToSystem"
                     class="bottom-menu-first-item"
                     link="/system"
                     saveDataWarningInject
                     (click)="sendGoogleAnalyticsData('SYSTEM')"
                     iconClass="icon-system"
                     titleKey="SYSTEM"
                     (onToggleMenu)="toggleMenu($event)"
                     [isOpenMenu]="isOpenMenu"
                     [ngClass]="{'menu-open': isOpenMenu}"
                     sh-id="menu-item-system"></menu-item-default>
</nav>
<reports-menu [isOpen]="isReportsMenuOpen" (onReportsItemClick)="toggleMenu($event)"></reports-menu>
<sorting-gate-menu [isOpen]="isSortingGateMenuOpen" (onCloseMenuClick)="onCloseSortingMenu()"></sorting-gate-menu>
<manage-menu [isOpen]="isManageMenuOpen" (onCloseDialogClicked)="isManageMenuOpen = false"></manage-menu>
