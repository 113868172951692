import {Injectable} from '@angular/core';
import {ConfigService} from '../config/config.service';
import {FarmRole} from './model/farm-role.enum';
import {ApplicationBundle, FinishingApplicationBundle, SystemState, YoungStockApplicationPlan} from '../config/model/server-config';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {

  constructor(private readonly configService: ConfigService) {

  }

  public hasPermissionsForRoute(route: string) : boolean {
    if(route.startsWith('/system/')) {
      return this.hasSystemRoutePermissions();
    } else if(route.startsWith('/manage/settings/users')) {
      return this.hasManageUsersPermissions();
    } else if(route.startsWith('/manage/settings/health')) {
      return this.hasManageHealthPermissions();
    } else if (route.startsWith('/manage/settings/alerts')) {
      return this.hasManageNotificationsPermissions();
    } else if (route.startsWith('/manage/settings/reproduction')) {
      return this.hasManageReproductionPermissions();
    }
    return true;
  }

  public hasManageUsersPermissions() {
    if (this.configService.serverConfig == null) {
      return false;
    }
    return this.configService.serverConfig.user.farmRole === FarmRole.Admin ||
           this.configService.serverConfig.user.farmRole === FarmRole.SuperUser ||
           this.configService.serverConfig.user.farmRole === FarmRole.SupportUser;
  }

  public hasManageNotificationsChangePermissions() {
    return this.configService.serverConfig.user.farmRole === FarmRole.FarmManager ||
      this.configService.serverConfig.user.farmRole === FarmRole.Admin ||
      this.configService.serverConfig.user.farmRole === FarmRole.SupportUser ||
      this.configService.serverConfig.user.farmRole === FarmRole.SuperUser;
  }

  public hasManageNotificationsPermissions() {
    return this.configService.serverConfig.user.farmRole !== FarmRole.Trial &&
      this.configService.serverConfig.user.farmRole !== FarmRole.ExternalUser;
  }

  public get isApplicationPlanSupportsViewForHealth() {
    return ([ApplicationBundle.Advanced, ApplicationBundle.Premium].includes(this.configService.serverConfig.bizModeParameters.applicationBundle)
      || this.configService.serverConfig.bizModeParameters.youngStockApplicationBundle === YoungStockApplicationPlan.YoungStock);
  }

  public get isApplicationPlanSupportsExtendedViewForHealth() {
    return ([ApplicationBundle.Advanced, ApplicationBundle.Premium].includes(this.configService.serverConfig.bizModeParameters.applicationBundle));
  }

  public isFinishingStandalone() {
    return (this.configService.serverConfig.bizModeParameters.applicationBundle == ApplicationBundle.None &&
            this.configService.serverConfig.bizModeParameters.finishingApplicationBundle == FinishingApplicationBundle.Finishing);
  }

  public isUserAdmin() {
    return this.configService.serverConfig.user.farmRole === FarmRole.Admin;
  }

  public isSuspended() {
    return this.configService.serverConfig.bizModeParameters.systemState == SystemState.Suspend;
  }

  public isSuperUser() {
    return this.configService.serverConfig.user.farmRole === FarmRole.SuperUser;
  }

  public isExternalUser(): boolean {
    return this.configService.serverConfig.user.farmRole === FarmRole.ExternalUser;
  }

  public hasUserPermissionsForEditManageSettings() {
    return this.configService.serverConfig.user.farmRole === FarmRole.Admin ||
      this.configService.serverConfig.user.farmRole === FarmRole.FarmManager ||
      this.configService.serverConfig.user.farmRole === FarmRole.SupportUser ||
      this.configService.serverConfig.user.farmRole === FarmRole.Trial ||
      this.configService.serverConfig.user.farmRole === FarmRole.SuperUser;
  }

  public hasPermissionsForLedTasksRoute() {
    return this.configService.serverConfig.user.farmRole === FarmRole.SuperUser ||
      this.configService.serverConfig.user.farmRole === FarmRole.SupportUser ||
      this.configService.serverConfig.user.farmRole === FarmRole.Admin ||
      this.configService.serverConfig.user.farmRole === FarmRole.FarmManager ||
    this.configService.serverConfig.user.farmRole === FarmRole.Trial;
  }

  public hasPermissionsForNotes() {
    return this.configService.serverConfig.user.farmRole !== FarmRole.ExternalUser;
  }

  private hasSystemRoutePermissions() {
    return this.configService.serverConfig.user.farmRole === FarmRole.Admin ||
      this.configService.serverConfig.user.farmRole === FarmRole.FarmManager ||
      this.configService.serverConfig.user.farmRole === FarmRole.SupportUser ||
      this.configService.serverConfig.user.farmRole === FarmRole.SuperUser;
  }

  private hasManageHealthPermissions() {
    return this.configService.serverConfig.bizModeParameters.applicationBundle !== ApplicationBundle.Starter &&
          (this.configService.serverConfig.user.farmRole === FarmRole.Admin ||
           this.configService.serverConfig.user.farmRole === FarmRole.FarmManager ||
           this.configService.serverConfig.user.farmRole === FarmRole.SupportUser ||
           this.configService.serverConfig.user.farmRole === FarmRole.SuperUser);
  }

  private hasManageReproductionPermissions() {
    return this.configService.serverConfig.user.farmRole === FarmRole.Admin ||
           this.configService.serverConfig.user.farmRole === FarmRole.FarmManager ||
           this.configService.serverConfig.user.farmRole === FarmRole.SupportUser ||
           this.configService.serverConfig.user.farmRole === FarmRole.SuperUser;
  }
}
