<div class="terms-and-conditions-container">
  <div class="terms-and-conditions-body-wrapper">
    <div class="terms-and-conditions-text">{{ 'REGISTRATION.FLOW.STEP_5.HELLO' | translate }} {{ username }}</div>
    <div class="terms-and-conditions-logo-wrapper">
      <div class="terms-and-conditions-logo"></div>
    </div>
    <div class="terms-and-conditions-errors-div" *ngIf="registrationService.registrationModel.error">
      <div class="error-text" [attr.translation-id]="'SYSTEM.MANAGEMENT.ERROR_BOLD: REGISTRATION.FLOW.ERROR.' + registrationService.registrationModel.error.error">
        <b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}:</b> {{ 'REGISTRATION.FLOW.ERROR.' + registrationService.registrationModel.error.error | translate }}
      </div>
    </div>
    <div class="review-and-accept-text-div" [attr.translation-id]="'REGISTRATION.FLOW.STEP_5.DISCLAIMER_NEW'">{{ 'REGISTRATION.FLOW.STEP_5.DISCLAIMER_NEW' | translate }}</div>
    <div class="terms-and-conditions-checkboxes-wrapper">
      <div class="terms-and-conditions-checkbox-row">
        <image-checkbox [attr.sh-id]="'checkboxes_scrTermsOfUse'" [isChecked]="checkboxes.scrTermsOfUse" (isCheckedChanged)="toggleCheckbox(termsAndConditionsType.scrTermsOfUse)" [checkBoxStyles]="checkboxStyle"></image-checkbox>
        <div class="label" [attr.translation-id]="'REGISTRATION.FLOW.I_AGREE_TO'">{{ 'REGISTRATION.FLOW.I_AGREE_TO' | translate }} <a class="link" [attr.translation-id]="'REGISTRATION.FLOW.STEP_5.SCR_TERMS_OF_USE'" (click)="onTermsAndConditionsClicked(termsAndConditionsType.scrTermsOfUse)">{{ 'REGISTRATION.FLOW.STEP_5.SCR_TERMS_OF_USE' | translate }}</a></div>
      </div>
      <div class="terms-and-conditions-checkbox-row">
        <image-checkbox [attr.sh-id]="'checkboxes_scrPrivacyPolicy'" [isChecked]="checkboxes.scrPrivacyPolicy" (isCheckedChanged)="toggleCheckbox(termsAndConditionsType.scrPrivacyPolicy)" [checkBoxStyles]="checkboxStyle"></image-checkbox>
        <div class="label" [attr.translation-id]="'REGISTRATION.FLOW.I_HAVE_READ_THE'">{{ 'REGISTRATION.FLOW.I_HAVE_READ_THE' | translate }} <a class="link" [attr.translation-id]="'REGISTRATION.FLOW.STEP_5.SCR_PRIVACY_POLICY'" (click)="onTermsAndConditionsClicked(termsAndConditionsType.scrPrivacyPolicy)">{{ 'REGISTRATION.FLOW.STEP_5.SCR_PRIVACY_POLICY' | translate }}</a></div>
      </div>
      <div class="terms-and-conditions-checkbox-row">
        <image-checkbox [attr.sh-id]="'checkboxes_scrLicenseAgreement'"  [isChecked]="checkboxes.scrLicenseAgreement" (isCheckedChanged)="toggleCheckbox(termsAndConditionsType.scrLicenseAgreement)" [checkBoxStyles]="checkboxStyle"></image-checkbox>
        <div class="label" [attr.translation-id]="'REGISTRATION.FLOW.I_AGREE_TO'">{{ 'REGISTRATION.FLOW.I_AGREE_TO' | translate }} <a class="link" [attr.translation-id]="'REGISTRATION.FLOW.STEP_5.SCR_LICENSE_AGREEMENT'" (click)="onTermsAndConditionsClicked(termsAndConditionsType.scrLicenseAgreement)">{{ 'REGISTRATION.FLOW.STEP_5.SCR_LICENSE_AGREEMENT' | translate }}</a></div>
      </div>
    </div>
  </div>
  <div class="terms-and-conditions-buttons-wrapper">
    <button class="back-button" [attr.sh-id]="'back_button'" [attr.translation-id]="'REGISTRATION.FLOW.BACK'" (click)="navigateBack()">{{ 'REGISTRATION.FLOW.BACK' | translate }}</button>
    <button class="finish-button" [attr.sh-id]="'finish_button'" [ngClass]="{ disabled: !eula.approved }" [attr.translation-id]="'REGISTRATION.FLOW.SIGN_UP'" (click)="signUp()">{{ 'REGISTRATION.FLOW.SIGN_UP' | translate }}</button>
  </div>
</div>
<div class="loading" *ngIf="registrationService.isLoading">
  <div class="loading-icon"></div>
</div>
