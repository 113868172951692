import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ManageSettingsScreenType} from '../model/manage-settings.model';
import {ConfigService} from '../../../../services/config/config.service';
import {IManagePageCategory} from './settings-section/settings-section.component';
import {ApplicationBundle, FarmMode} from '../../../../services/config/model/server-config';
import {UserPermissionsService} from '../../../../services/auth/user-permissions.service';

@Component({
  selector: 'manage-settings-welcome',
  templateUrl: './manage-settings-welcome.component.html',
  styleUrls: ['./manage-settings-welcome.component.scss']
})
export class ManageSettingsWelcomeComponent implements OnInit {

  @Input()
  public hasPermissionsToNotifications: boolean;

  @Input()
  public hasPermissionsToHealth: boolean;

  @Input()
  public isFinishing: boolean;

  @Output()
  public onOpenScreen: EventEmitter<ManageSettingsScreenType> = new EventEmitter<ManageSettingsScreenType>();

  public reproductionScreenCategories: IManagePageCategory[];

  public healthScreenCategories: IManagePageCategory[];

  public farmRoutineCategories: IManagePageCategory[] = [];

  public ManageSettingsScreenType = ManageSettingsScreenType;

  public FarmMode = FarmMode;

  constructor(public readonly configService: ConfigService,
              private readonly userPermissionsService: UserPermissionsService) {}

  public ngOnInit(): void {

    if(this.isFinishing) {
      this.initFinishingScreenCategories();
    } else {
      this.initBreedingScreenCategories();
    }
  }

  public get presentFarmRoutineSection() : boolean {
    return this.configService.serverConfig.farmMode == FarmMode.Dairy &&
      (this.configService.serverConfig.bizModeParameters.applicationBundle == ApplicationBundle.Premium ||
       this.configService.serverConfig.isSortingEnabled);
  }

  private initFinishingScreenCategories() {
    this.healthScreenCategories = [
      {category: ManageSettingsScreenType.healthReport, translationId: 'MANAGE.WELCOME.TITLE.FINISHING_HEALTH_REPORT', shId: ''}
    ];
  }

  private initBreedingScreenCategories() {

    this.reproductionScreenCategories = [
      {category: ManageSettingsScreenType.reproductionVoluntaryWaitingPeriod, translationId: 'MANAGE.WELCOME.TITLE.VOLUNTARY_WAITING_PERIOD', shId: ''},
      {category: ManageSettingsScreenType.reproductionPregnancyLength, translationId: 'MANAGE.WELCOME.TITLE.PREGNANCY_LENGTH', shId: ''}
    ];
    if (this.configService.serverConfig.farmMode === FarmMode.Dairy) {
      this.reproductionScreenCategories.push({category: ManageSettingsScreenType.reproductionPregnancyDaysToDryOff,
                                              translationId: 'MANAGE.WELCOME.TITLE.PREGNANCY_DAYS_TO_DRY_OFF',
                                              shId: ''});
    }

    this.healthScreenCategories = [
      {category: ManageSettingsScreenType.healthReport, translationId: 'MANAGE.WELCOME.LINK.HEALTH_REPORT', shId: ''},
    ];
    if (this.userPermissionsService.isApplicationPlanSupportsExtendedViewForHealth) {
      this.healthScreenCategories = this.healthScreenCategories.concat([
          {category: ManageSettingsScreenType.healthFreshReport, translationId: 'MANAGE.WELCOME.LINK.FRESH_REPORT', shId: ''},
          {category: ManageSettingsScreenType.healthDistressSensitivity, translationId: 'MANAGE.WELCOME.LINK.DISTRESS_SENSITIVITY', shId: ''}
        ]);
    }
    if (this.configService.serverConfig.farmMode === FarmMode.Beef) {
      this.healthScreenCategories.push({category: ManageSettingsScreenType.healthDaysToWeaning, translationId: 'MANAGE.WELCOME.LINK.DAYS_TO_WEANING.TITLE', shId: ''});
    }

    this.farmRoutineCategories = [];
    if(this.configService.serverConfig.farmMode === FarmMode.Dairy) {
      if(this.configService.serverConfig.isSortingEnabled) {
        this.farmRoutineCategories.push({category: ManageSettingsScreenType.farmRoutineShifts, translationId: 'MANAGE.WELCOME.TITLE.SHIFTS', shId: 'welcome-shifts-route'});
      }
      if(this.configService.serverConfig.bizModeParameters.applicationBundle == ApplicationBundle.Premium) {
        this.farmRoutineCategories.push({
          category: ManageSettingsScreenType.farmRoutineGroupRoutineEvents,
          translationId: 'MANAGE.WELCOME.TITLE.GROUP_ROUTINE_EVENTS',
          shId: ''
        });
      }
    }
  }
}
