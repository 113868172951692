import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ReportQuery} from '../../../../services/reports/model/report-query.interface';
import {IReport, IReportIds, ReportDataRow, ReportDisplayType} from '../../../../services/reports/model/report.interface';
import {HttpResponseResult} from '../../../../services/rest-api/rest-api.service';
import {ReportsService} from '../../../../services/reports/reports.service';
import {AnimalsService} from '../../../../services/animals/animals.service';
import {GridSummaryIcon} from 'src/app/common/components/grid-summary/grid-summary.component';
import {ActivatedRoute} from '@angular/router';
import {ApplicationBundleRoutingMode, RoutingService} from '../../../../services/routing/routing.service';
import {Subscription} from 'rxjs';
import {ConfigService} from '../../../../services/config/config.service';
import {
  ReportGridComponent,
  ReportGridValueCellClickDetails
} from '../../../../common/components/reports/report-grid/report-grid.component';
import {AnimalCardTabSection} from '../../../card-popup/animal-card/animal-card-model';
import {ReportRowSelectionState} from '../../../../services/reports/report-row-selection-state';
import * as moment from 'moment';
import {IReportViewContext} from '../../../../services/reports/model/report-view-context';
import {AnimalEventsService} from '../../../../services/animals/animal-events.service';
import {OperationType} from '../../../../services/search/model/search.model';
import {FarmMode, FinishingApplicationBundle} from '../../../../services/config/model/server-config';
import {GridViewState} from '../../../../services/ui/view-state/grid-row/grid-view-state';
import {CardViewStateService} from '../../../../services/ui/view-state/card-view-state.service';

@Component({
  selector: 'manage-active-animals',
  templateUrl: './manage-active-animals.component.html',
  styleUrls: ['./manage-active-animals.component.scss']
})
export class ManageActiveAnimalsComponent implements OnInit, OnDestroy, IReportViewContext {

  public activeAnimalsReportQuery:ReportQuery;
  public activeAnimalsReport:IReport;

  @ViewChild('activeAnimalsReportGrid')
  public activeAnimalsReportGrid: ReportGridComponent;

  public applicationBundleRoutingMode: ApplicationBundleRoutingMode;

  private eventListChangeSubscription:Subscription;

  private animalListChangeSubscription:Subscription;

  private queryParamsSubscription:Subscription;

  private gridViewState:GridViewState = new GridViewState();

  public GridSummaryIcon = GridSummaryIcon;

  constructor(private readonly reportsService: ReportsService,
              public readonly animalsService: AnimalsService,
              private readonly animalEventsService: AnimalEventsService,
              private readonly cardViewStateService: CardViewStateService,
              private readonly activatedRoute:ActivatedRoute,
              private readonly routingService:RoutingService,
              public readonly configService: ConfigService) {
  }

  public async ngOnInit() {
    this.eventListChangeSubscription = this.animalEventsService.eventsListChanged.subscribe(() => {
      setTimeout(() => {
        this.activeAnimalsReportGrid.reloadGrid(true, null, true);
      }, 500);
    });
    this.animalListChangeSubscription = this.animalsService.onAnimalsListChanged.subscribe(() => {
      this.activeAnimalsReportGrid.reloadGrid(false, moment().unix(), true);
    });
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(value => {
      this.setDefaultActiveAnimalsReportQuery();
      this.applicationBundleRoutingMode = this.routingService.getApplicationBundleRoutingMode(value);
      // show add animal button for active animals section
      this.animalsService.isAddAnimalButtonShown = true;
      setTimeout(() =>{
        this.activeAnimalsReportGrid.reloadGrid(true, null, true);
      });
    });
  }

  public ngOnDestroy(): void {
    this.eventListChangeSubscription.unsubscribe();
    this.animalListChangeSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
  }

  public async onReportQueryChanged() {
    await this.loadActiveAnimalsReport();
  }

  public async onReportGridValueCellClick(reportGridValueCellClickDetails: ReportGridValueCellClickDetails) {
    let relatedAnimalsReportQuery = this.activeAnimalsReportQuery.getIdProjectionReportQuery('CowDatabaseIDCalculation');
    let relatedAnimalsReportResult: HttpResponseResult<IReportIds> = await this.reportsService.getActiveAnimalsIds(relatedAnimalsReportQuery, this.applicationBundleRoutingMode);
    if (relatedAnimalsReportResult.status == 200) {
      let relatedAnimals = relatedAnimalsReportResult.responseBody.result.map(value => value['CowDatabaseIDCalculation']);
      let selectedAnimalId = reportGridValueCellClickDetails.row['CowDatabaseIDCalculation'];
      let operationType:OperationType;
      if(this.applicationBundleRoutingMode == ApplicationBundleRoutingMode.Breeding){
        operationType = this.configService.serverConfig.farmMode == FarmMode.Beef ? OperationType.BeefBreeding : OperationType.Dairy;
      } else {
        operationType = OperationType.BeefFinishing;
      }
      this.cardViewStateService.openAnimalCard(selectedAnimalId,
                                               operationType,
                                               relatedAnimals,
                                               ['GROUP.NAVIGATION.COW_IN','GROUP.REPORTS.SPECIAL_TITLE.NAVIGATION.MANAGE'],
                                               AnimalCardTabSection.Events);
    }
  }

  private async loadActiveAnimalsReport() {
    this.activeAnimalsReport = await this.getReportByQuery(this.activeAnimalsReportQuery);
  }

  private setDefaultActiveAnimalsReportQuery(){
    this.activeAnimalsReportQuery = new ReportQuery();
    this.activeAnimalsReportQuery.offset = 0;
    this.activeAnimalsReportQuery.limit = 30;
    this.activeAnimalsReportQuery.type = ReportDisplayType.full;
    this.activeAnimalsReportQuery.includeFilterMetaData = true;
  }

  public async getReportByQuery(reportQuery: ReportQuery): Promise<IReport> {
    let reportResponse: HttpResponseResult<IReport> = await this.reportsService.getActiveAnimals(reportQuery, this.applicationBundleRoutingMode);
    if (reportResponse.responseBody) {
      return reportResponse.responseBody;
    } else {
      return null;
    }
  }

  public getSelectedRowsReport() {
    return null;
  }

  public async reload(isRefresh:boolean, pastReportTime:number): Promise<void> {
    await this.activeAnimalsReportGrid.reloadGrid(isRefresh, pastReportTime, true);
  }

  public async reloadDefault(): Promise<void> {
    await this.reload(true, null);
  }

  public getLoadedRows(): ReportDataRow[] {
    return null;
  }

  public getRowSelectionState(): ReportRowSelectionState {
    return null;
  }

  public getGridViewState(): GridViewState {
    return this.gridViewState;
  }

  public getPrintIncludeDateInHeader(): boolean {
    return false;
  }

  public getPrintReportHeader(): string {
    return 'MANAGE.TITLE.ANIMALS_IN_HERD';
  }

  public isAddAnimalShown(): boolean {
    return ((this.configService.serverConfig && this.configService.serverConfig.thirdPartyParameters.addCow) ||
            (this.configService.serverConfig.bizModeParameters.finishingApplicationBundle === FinishingApplicationBundle.Finishing && this.applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing));
  }
}
