<!--Add Animal Button-->
<div class="add-animal-wrapper" *ngIf="animalsService.isAddAnimalButtonShown">
    <div class="add-animal-btn" [attr.sh-id]="'add_animal_button'" [ngClass]="{ addFemaleOpen: animalsService.isAddAnimalOpen,
                                                                                breeding: (applicationBundleRoutingMode === ApplicationBundleRoutingMode.Breeding && !animalsService.isAddAnimalOpen),
                                                                                finishing: (applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing && !animalsService.isAddAnimalOpen) }"
         (click)="toggleAddAnimal()">
      <div class="add-icon" *ngIf="!animalsService.isAddAnimalOpen && !isAddAnimalChooserShown"></div>
      <div class="remove-icon" *ngIf="!animalsService.isAddAnimalOpen && isAddAnimalChooserShown"></div>
      <div class="remove-icon" *ngIf="animalsService.isAddAnimalOpen"></div>
    </div>
    <div class="addAnimalChooser" [attr.sh-id]="'add_animal_chooser_popup'" *ngIf="isAddAnimalChooserShown">
      <div class="add-button-chooser-female" [attr.sh-id]="'add_female_button'" [attr.translation-id]="'MANAGE.ADD_ANIMAL_WIZARD.ADD_FEMALE'" (click)="addFemale()"><div class="female-icon"></div> {{ 'MANAGE.ADD_ANIMAL_WIZARD.ADD_FEMALE' | translate }}</div>
      <div class="seperator"></div>
      <div class="add-button-chooser-male" [attr.sh-id]="'add_male_button'" [attr.translation-id]="'MANAGE.ADD_ANIMAL_WIZARD.ADD_MALE'" (click)="addMale()"><div class="male-icon"></div> {{ 'MANAGE.ADD_ANIMAL_WIZARD.ADD_MALE' | translate }}</div>
    </div>
</div>
<!--Add Animal Dialog-->
<div class="container-wrapper" [ngSwitch]="addAnimalSex" *ngIf="animalsService.isAddAnimalOpen">
  <ng-container *ngSwitchCase="animalSex.Female">
    <add-female-breeding *ngIf="isBreedingOrDairyShown()"></add-female-breeding>
    <add-female-finishing *ngIf="isFinishingShown()"></add-female-finishing>
  </ng-container>
  <ng-container *ngSwitchCase="animalSex.Male">
    <add-male-breeding *ngIf="isBreedingOrDairyShown()"></add-male-breeding>
    <add-male-finishing *ngIf="isFinishingShown()"></add-male-finishing>
  </ng-container>
</div>
