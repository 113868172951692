import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DIALOG_TRANSITIONS} from '../../../animations/animations';

@Component({
  selector: 'regular-edit-dialog-template',
  templateUrl: './regular-edit-dialog-template.component.html',
  styleUrls: ['./regular-edit-dialog-template.component.scss'],
  animations: [DIALOG_TRANSITIONS]
})
export class RegularEditDialogTemplateComponent implements OnInit {

  public readonly dialogShId = 'regular_edit_dialog';

  @Input()
  public isOpen: boolean = false;

  @Input()
  public modalWidth?: number | string = 'auto';

  @Input()
  public modalHeight?: number | string = 'auto';

  @Input()
  public enableRemoveButton: boolean;

  @Input()
  public showCloseButton: boolean = true;

  @Input()
  public showDialogShadow: boolean = false;

  @Input()
  public leftButtonDisabled: boolean = false;

  @Input()
  public rightButtonDisabled: boolean = false;

  @Input()
  public shIdPostfix: string = '';

  @Output()
  public onRemoveClick: EventEmitter<void> = new EventEmitter();

  @Output()
  public onCloseClick: EventEmitter<void> = new EventEmitter();

  @Output()
  public onLeftButtonClick: EventEmitter<void> = new EventEmitter();

  @Output()
  public onRightButtonClick: EventEmitter<void> = new EventEmitter();

  @Output()
  public onEnterButtonClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  public ngOnInit() {
  }

  private removeClick() {
    this.onRemoveClick.emit();
  }

  public onEscapeButtonClick() {
    this.closeClick();
  }

  public enterClick() {
    this.onEnterButtonClick.emit();
  }

  public closeClick() {
    this.onCloseClick.emit();
  }

  private leftButtonClick() {
    if (!this.leftButtonDisabled) {
      this.onLeftButtonClick.emit();
    }
  }

  private rightButtonClick() {
    if (!this.rightButtonDisabled) {
      this.onRightButtonClick.emit();
    }
  }

  public createShId(elementName?: string): string {
    const dialogShId = `${this.dialogShId}_${this.shIdPostfix}`;
    return (elementName != null) ? `${dialogShId}_${elementName}` : dialogShId;
  }
}

