import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LedTasksService} from "../../../../../../services/led-tasks/led-tasks.service";
import {AutoCompleteColorScheme} from "../../../../../../common/components/auto-complete/auto-complete-model";
import {LedTaskFields} from "../../../../../../services/led-tasks/models/led-tasks.interface";
import {SearchEntry} from "../../../../../../services/search/model/search.model";
import {EntityType} from "../../../../../../services/search/search.service";
import {RegularAutoCompleteComponent} from "../../../../../../common/components/auto-complete/regular-auto-complete/regular-auto-complete.component";
import {InputFilterMode} from "../../../../../../common/directives/input-filter.directive";
import {ChipsItem} from '../../../../../../common/components/chips/chips-item.component';

@Component({
  selector: 'animals-container',
  templateUrl: './animals-container.component.html',
  styleUrls: ['./animals-container.component.scss']
})
export class AnimalsContainerComponent implements OnInit {

  public AutoCompleteColorScheme = AutoCompleteColorScheme;

  public LedTaskFields = LedTaskFields;

  public inputFilterMode = InputFilterMode;

  public animalSearchQuery: string;

  @ViewChild('animalsAutoComplete')
  public animalsAutoComplete: RegularAutoCompleteComponent;

  @Input()
  public animalsList: string[] = [];

  @Input()
  public searchEntities: SearchEntry[] = [];

  constructor(public ledTasksService: LedTasksService) { }

  public ngOnInit() {
  }

  public onAnimalsSelected(selectedAnimal: string) {
    if (selectedAnimal == null) {
      return;
    }
    let animalSearchEntity = this.searchEntities.find((animal: SearchEntry) => animal.entityName === selectedAnimal && (animal.entityType === EntityType.cow || animal.entityType === EntityType.male));
    let animalItem: ChipsItem = new ChipsItem();
    animalItem.chipId = animalSearchEntity.entityId;
    animalItem.chipName = animalSearchEntity.entityName;
    const isExist = this.ledTasksService.taskModel.selectedAnimals.find((animal: ChipsItem) => animal.chipId === animalItem.chipId);
    if (isExist) {
      return;
    } else {
      this.ledTasksService.clearErrors();
      this.ledTasksService.taskModel.selectedAnimals.push(animalItem);
      let index = this.animalsList.findIndex(group => group === animalItem.chipName);
      this.animalsList.splice(index, 1);
      this.animalSearchQuery = null;
      this.animalsAutoComplete.clearValue();
    }
  }

  public removeSelectedAnimal(selectedAnimal: ChipsItem) {
    const isExist = this.animalsList.find((animal: string) => animal === selectedAnimal.chipName);
    if (!isExist) {
      this.animalsList.push(selectedAnimal.chipName);
      this.animalsList = this.animalsList.sort();
    }
    const index = this.ledTasksService.taskModel.selectedAnimals.findIndex((animal: ChipsItem) => animal.chipId === selectedAnimal.chipId);
    this.ledTasksService.taskModel.selectedAnimals.splice(index, 1);
  }
}
