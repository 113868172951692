import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnimalsService} from "../../../../../../../services/animals/animals.service";
import {FinishingAddFemaleModel} from "../add-female-finishing.component";
import {DropdownListItem} from "../../../../../../../common/components/dropdown-list/dropdown-list-model";
import {AddAnimalFieldsErrorsEnum, IErrors} from "../../../../../../../services/animals/model/animal.model";
import {EpochStateModel} from "../../../../../../../common/components/calendar/model/epoch-state-model";

@Component({
  selector: 'add-female-finishing-finishing-tab',
  templateUrl: './add-female-finishing-finishing-tab.component.html',
  styleUrls: ['./add-female-finishing-finishing-tab.component.scss']
})
export class AddFemaleFinishingFinishingTabComponent implements OnInit {

  public AddAnimalFieldsErrorsEnum = AddAnimalFieldsErrorsEnum;

  @Input()
  public addFinishingFemaleModel: FinishingAddFemaleModel;

  @Input()
  public groupsDropdownListItems: DropdownListItem[];

  @Input()
  public errors: IErrors[];

  @Output()
  public onClearErrors: EventEmitter<void> = new EventEmitter<void>();

  constructor(public animalsService: AnimalsService) { }

  public ngOnInit() {
  }

  public clearErrors() {
    this.onClearErrors.emit();
  }

  public onGroupSelected(selectedGroup: DropdownListItem) {
    if (!this.addFinishingFemaleModel.group) {
      this.clearErrors();
    }
    this.addFinishingFemaleModel.group = selectedGroup;
  }

  public onBackgroundStartChanged(backgroundStartDate: EpochStateModel) {
    this.addFinishingFemaleModel.backgroundStartDate = backgroundStartDate;
    this.addFinishingFemaleModel.updateDatesRanges();
    this.clearErrors();
  }

  public onFinishingStartDateChanged(finishingStartDate: EpochStateModel) {
    this.addFinishingFemaleModel.finishingStart = finishingStartDate;
    this.addFinishingFemaleModel.updateDatesRanges();
    this.clearErrors();
  }
}
