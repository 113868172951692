<div class="manage" *ngIf="configService.serverConfig" saveDataWarningIgnore>
  <manage-settings *ngIf="isSettingsPopupShown" (onCloseSettingsDialog)="toggleSettings()"></manage-settings>
  <div class="nav-wrapper">
    <ul class="nav">
      <li class="nav-item" (click)="sendGoogleAnalyticsData('Animals')" routerLinkActive="active" saveDataWarningInject>
        <a routerLink="/manage/animals"
           [queryParamsHandling]="'preserve'"
           sh-id="manage-animals-route" [attr.translation-id]="'MANAGE.TITLE.ANIMALS'">{{ 'MANAGE.TITLE.ANIMALS' | translate }}</a>
      </li>
      <li class="nav-item" (click)="sendGoogleAnalyticsData('Events')" routerLinkActive="active" saveDataWarningInject>
        <a routerLink="/manage/events"
           [queryParamsHandling]="'preserve'"
           sh-id="manage-events-route" [attr.translation-id]="'MANAGE.TITLE.EVENTS'">{{ 'MANAGE.TITLE.EVENTS' | translate }}</a>
      </li>
      <li class="nav-item" (click)="sendGoogleAnalyticsData('Groups')" routerLinkActive="active" saveDataWarningInject>
        <a routerLink="/manage/groups"
           [queryParamsHandling]="'preserve'"
           sh-id="manage-groups-route" [attr.translation-id]="'MANAGE.TITLE.GROUPS'">{{ 'MANAGE.TITLE.GROUPS' | translate }}</a>
      </li>
      <li class="nav-item" (click)="sendGoogleAnalyticsData('Branches')" routerLinkActive="active" *ngIf=" applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing" saveDataWarningInject>
        <a routerLink="/manage/branches"
           [queryParamsHandling]="'preserve'"
           sh-id="manage-branches-route" [attr.translation-id]="'MANAGE.TITLE.BRANCHES'">{{ 'MANAGE.TITLE.BRANCHES' | translate }}</a>
      </li>
      <li class="nav-item" (click)="sendGoogleAnalyticsData('Notes')" routerLinkActive="active" saveDataWarningInject>
        <a routerLink="/manage/notes"
           [queryParamsHandling]="'preserve'"
           sh-id="manage-notes-route" [attr.translation-id]="'MANAGE.TITLE.NOTES'">{{ 'MANAGE.TITLE.NOTES' | translate }}</a>
      </li>
    </ul>
    <div class="settings-button-wrapper">
      <a class="settings-button"
         (click)="toggleSettings()"
         sh-id="manage-settings-route"
         [ngClass]="{ 'breeding': isBreeding,
                      'finishing': isFinishing,
                      'dairy': isDairy}"
         [attr.translation-id]="'MANAGE.TITLE.SETTINGS'"
         saveDataWarningInject>
        <div class="settings-icon" [ngClass]="{ 'breeding': isBreeding,
                                                'finishing': isFinishing,
                                                'dairy': isDairy }"></div> {{ 'MANAGE.TITLE.SETTINGS' | translate }}</a>
    </div>
  </div>

  <div class="manage-content">
    <router-outlet></router-outlet>
  </div>
</div>

