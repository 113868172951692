import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges, ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  DropdownListAutoSelectionMode,
  DropdownListItem
} from '../dropdown-list-model';
import {TranslationService} from '../../../../services/translations/translation.service';
import {PopupTemplateComponent} from '../../popups/popup-template/popup-template.component';

export enum FormsDLInputBackgroundColorThemes {
  White = 'white',
  Gray = 'gray'
}

export enum FormsDLIconsThemes {
  Black = 'black',
  Blue = 'blue',
  Pink = 'pink',
  Green = 'green',
}

export enum FormsDLInputTextColorThemes {
  Black = 'black',
  Blue = 'blue'
}

export enum FormsDLInputBorderStyleThemes {
  Default = 'default',
  Blue = 'blue'
}

@Component({
  selector: 'forms-dropdown-list',
  templateUrl: './forms-dropdown-list.component.html',
  styleUrls: ['./forms-dropdown-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormsDropdownListComponent),
      multi: true
    }
  ]
})
export class FormsDropdownListComponent  implements OnChanges, ControlValueAccessor {

  @Input()
  public inputBorderStyle: FormsDLInputBorderStyleThemes = FormsDLInputBorderStyleThemes.Default;

  @Input()
  public inputTextColor: FormsDLInputTextColorThemes = FormsDLInputTextColorThemes.Black;

  @Input()
  public iconTheme: FormsDLIconsThemes = FormsDLIconsThemes.Black;

  @Input()
  public backgroundColorTheme: FormsDLInputBackgroundColorThemes = FormsDLInputBackgroundColorThemes.White;

  @Input()
  public shIdPostfix: string = '';

  @Input()
  public items: DropdownListItem[] = [];

  @Input()
  public autoSelectionMode?: DropdownListAutoSelectionMode =  DropdownListAutoSelectionMode.None;

  @Input()
  public allowClear?: boolean = true;

  @Input()
  public placeholder: string = '';

  @Input()
  public isFieldError: boolean = false;

  @Input()
  public translatePlaceholder = true;

  @Input()
  public translateListItem = true;

  @Input()
  public emitChangeEventWhenSetValue = true;

  @ViewChild(PopupTemplateComponent, {static: true})
  public popupTemplate: PopupTemplateComponent;

  public disabled: boolean;

  public isOpen: boolean;

  public markedItem: number;

  public selectedItem: DropdownListItem;

  // tslint:disable-next-line:no-any
  public onChange = (value: any) => {};

  public onTouched = () => {};

  private _isFoldsUp: boolean;

  constructor(private readonly translationService:TranslationService,
              private readonly detectorRef: ChangeDetectorRef) {}

  public get selectedDisplayValue(): string {
    return this.selectedItem != null ? this.getListItemDisplayValue(this.selectedItem) : this.getTranslatedPlaceholder;
  }

  public get getTranslatedPlaceholder() : string {
    if(this.placeholder == null ||
       this.placeholder.trim().length == 0) {
      return '';
    }
    if(this.translatePlaceholder) {
      return this.translationService.translate(this.placeholder);
    } else {
      return this.placeholder;
    }
  }

  public getListItemDisplayValue(dropdownListItem: DropdownListItem) {
    if(dropdownListItem == null ||
       dropdownListItem.displayValue == null ||
       dropdownListItem.displayValue.trim().length == 0) {
      return ''
    }
    if(this.translateListItem) {
      return this.translationService.translate(dropdownListItem.displayValue);
    } else {
      return dropdownListItem.displayValue;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.markedItem = 0;
      if (this.selectedItem != null) {
        this.selectItemWithValue(this.selectedItem.value);
      } else {
        const items = changes.items.currentValue || [];
        switch (this.autoSelectionMode) {
          case 'none':
            break;
          case 'first':
            if (items.length > 0 && !this.selectedItem) {
              this.selectItem(items[0], null);
            }
            break;
          case 'single':
          default:
            if (items.length === 1 && !this.selectedItem) {
              this.selectItem(items[0], null);
            }
            break;
        }
      }
    }
  }

  public showPlaceholder() {
    return !this.isOpen && this.placeholder && this.placeholder.trim();
  }

  // tslint:disable-next-line:no-any
  public writeValue(value: any): void {
    this.selectItemWithValue(value);
  }

  // tslint:disable-next-line:no-any
  private selectItemWithValue(value: any): void {
    if (this.items != null) {
      const selectedItemCandidate = this.items.find((item) => item.value === value);
      if (selectedItemCandidate) {
        this.selectedItem = selectedItemCandidate;
        if (this.emitChangeEventWhenSetValue) {
          this.onChange(this.selectedItem.value);
        }
        return;
      }
    }
    this.selectedItem = null;
    if (this.emitChangeEventWhenSetValue) {
      this.onChange(null);
    }
  }

  // tslint:disable-next-line:no-any
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // tslint:disable-next-line:no-any
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public toggleOpen() {
    this.isOpen = !this.isOpen;
    if (this.items.length > 0) {
      this.markedItem = 0;
    }
  }

  public closeDropdown() {
    this.isOpen = false;
  }

  public selectItem(item: DropdownListItem, event:Event) {
    if(event != null) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (item) {
      if (item.disabled) {
        return;
      }
      this.onChange(item.value);
      this.writeValue(item.value);
    } else {
      this.onChange(null);
      this.writeValue(null);
    }
    this.onTouched();
    this.isOpen = false;
  }

  public onEnter(event: Event) {
    this.selectedItem = this.items[this.markedItem];
    this.selectItem(this.selectedItem, event);
  }

  public onArrowDown() {
    this.incrementSelectedIndex();
  }

  public onArrowUp() {
    this.decrementSelectedIndex();
  }

  public decrementSelectedIndex() {
    this.markedItem--;
    if (this.markedItem < 0) {
      this.markedItem = this.items.length - 1;
    }
    document.querySelector('#list-item-' + this.markedItem).scrollIntoView({ block: 'nearest'});
  }

  public incrementSelectedIndex() {
    this.markedItem++;
    if (this.markedItem === this.items.length) {
      this.markedItem = 0;
    }
    document.querySelector('#list-item-' + this.markedItem).scrollIntoView({ block: 'nearest'});
  }

  public isMarked(index: number) {
    return index === this.markedItem;
  }

  public get isFoldDirectionUp(): boolean {
    if (!!this.popupTemplate && this._isFoldsUp !== this.popupTemplate.isFoldsUp) {
      setTimeout(() => {
        this._isFoldsUp = this.popupTemplate.isFoldsUp;
        this.detectorRef.detectChanges();
      });
    }
    return this._isFoldsUp;
  }
}
