import { Component, OnInit } from '@angular/core';
import {ChangeRfIdTagEventRow} from '../../../../../services/animals/model/animal-events';
import {DescriptionCellComponent} from '../../../../../common/components/data-grid/description-cell-template/description-cell.component';

@Component({
  selector: 'change-rfid-tag-event-description',
  templateUrl: './change-rfid-tag-event-description.component.html',
  styleUrls: ['./change-rfid-tag-event-description.component.scss']
})
export class ChangeRfidTagEventDescriptionComponent extends DescriptionCellComponent<ChangeRfIdTagEventRow> implements OnInit {

  constructor() {
    super();
  }

  public ngOnInit() {
  }

}
