import {Component, Input, OnInit} from '@angular/core';
import {IGroupGraphDetails} from '../../../../services/groups/model/group.interface';

@Component({
  selector: 'group-card-animals-summary',
  templateUrl: './group-card-animals-summary.component.html',
  styleUrls: ['./group-card-animals-summary.component.scss']
})
export class GroupCardAnimalsSummaryComponent implements OnInit {

  @Input()
  public groupGraphDetails: IGroupGraphDetails;

  constructor() { }

  public ngOnInit() {
  }
}
