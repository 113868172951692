import {IDataGridViewContext, ErrorModel} from '../../model/common-model';
import {IAnimalIdMeta} from '../../animals/model/animal.model';
import {ITag} from '../../tags/model/tags-model';
import {
  CowEventMetadataBase,
  CreateChangeGroupEventDetails,
  CreateCullingEventDetails,
  CullingEventRow,
  EventDetails
} from '../../animals/model/animal-events';
import {IGroupMetadata} from '../../groups/model/group.interface';
import {AnimalSortingTaskModel, SchedulerModel, SchedulerShiftModel, SortingTaskModel} from '../../sorting-gate/model/scheduler-model';
import {IDay, IRepeatDay, ISortingPenModel} from '../../sorting-gate/model/sorting-gate-model';
import {ConcreteShiftDetails, ShiftModel} from '../../sorting-gate/model/shifts-model';


export abstract class DialogActionsModel {

  public actionsContext: IDataGridViewContext;

}

export class AssignAnimalToTagDialogModel extends DialogActionsModel {

  public tagNumber: number;

  public animals: IAnimalIdMeta[];

  public actionsContext: IDataGridViewContext;
}

export class AssignTagToAnimalDialogModel extends DialogActionsModel {

  public animalId: number;

  public unassignedTags: ITag[];

  public actionsContext: IDataGridViewContext;
}

export class CreateBatchEventDialogModel extends DialogActionsModel {

  public eventDetails: EventDetails;

  public meta: CowEventMetadataBase;

  public animalIds: number[];

  public errors: ErrorModel[];

}

export class CreateGroupCardCullingEventDialogModel extends DialogActionsModel {

  public eventDetails: CreateCullingEventDetails;

  public animalId: number;

  public actionsContext: IDataGridViewContext;

}

export class CreateChangeGroupEventDialogModel extends DialogActionsModel {

  public eventDetails: CreateChangeGroupEventDetails;

  public animalId: number;

  public groups: IGroupMetadata[];

  public actionsContext: IDataGridViewContext;

}

export class CreateSendToSortingGateDialogModel extends DialogActionsModel {

  public shifts: ShiftModel[];

  public concreteShifts: ConcreteShiftDetails[];

  public activeShift: ConcreteShiftDetails;

  public pens: ISortingPenModel[];

  public activePen: ISortingPenModel;

  public schedulerModel: SchedulerModel;

  public sortingTask: AnimalSortingTaskModel;

  public activeDay: IDay;

  public days: IDay[];

  public isRepeatActive: boolean = false;

  public repeatDays: IRepeatDay[];

  public activeRepeatDay: IRepeatDay;

}
