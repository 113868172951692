<div class="mock-translations-area">
  <div class="validation-summary">
    <span *ngIf="summary == ValidationSummaryType.Loading" sh-id="translation_validation_loading">Loading</span>
    <span *ngIf="summary == ValidationSummaryType.Valid" sh-id="translation_validation_valid">Valid</span>
    <span *ngIf="summary == ValidationSummaryType.Invalid" sh-id="translation_validation_invalid">Invalid</span>
  </div>
  <div *ngFor="let validationItem of validationItems" class="validation-item" [ngClass]="{'invalid': !validationItem.isValid}">
    <b>{{validationItem.description}}</b><br/>
    <b>{{validationItem.isValid ? 'VALID' : 'INVALID'}}</b><br/>
    <span *ngFor="let detailItem of validationItem.detailsItems">
      {{detailItem}}<br/>
    </span>
  </div>
</div>
