import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IAnimalsToInspectReport, ISelected} from '../../../../services/reports/model/report.interface';
import {ReportsService} from '../../../../services/reports/reports.service';
import {ResetAnimalToInspectReportToDefaultModel} from '../../../../services/reports/model/report-query.interface';
import {ConfigService} from '../../../../services/config/config.service';
import {FarmRole} from '../../../../services/auth/model/farm-role.enum';
import {LoadingIconService} from "../../../../services/loading-icon/loading-icon.service";
import {
  CornerColor,
  CornerContainerBodyBackground
} from "../../../../common/components/containers/corner-container/corner-container.component";

@Component({
  selector: 'include-in-report-dropdown-list',
  templateUrl: './include-in-report-dropdown-list.component.html',
  styleUrls: ['./include-in-report-dropdown-list.component.scss']
})
export class IncludeInReportDropdownListComponent implements OnInit {

  @Input()
  public reportKey: string;

  @Output() // tslint:disable-next-line:no-any
  public onSetupChanged: EventEmitter<any> = new EventEmitter<any>();

  public isOpen: boolean = false;
  public defaultAnimalsToInspectSetup: IAnimalsToInspectReport = { anestrusCows: null, animalDistress: null, earlyFreshCows: null, health: null, irregularHeats: null, suspectedForAbortion: null, youngStockHealth: null };
  public animalsToInspectList: IAnimalsToInspectItem[] = [];

  public CornerColor = CornerColor;

  public CornerContainerBodyBackground = CornerContainerBodyBackground;

  constructor(private readonly reportsService: ReportsService,
              private readonly loadingIconService: LoadingIconService,
              private readonly configService: ConfigService) { }

  public async ngOnInit() {
    await this.loadSetup();
  }

  public toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  public closePopup() {
    this.isOpen = false;
  }

  public toggleSetupItem(animalToInspectItem: IAnimalsToInspectItem) {
    if (!this.canEdit) {
      return;
    }
    animalToInspectItem.value.isSelected = !animalToInspectItem.value.isSelected;
  }

  public async resetSetup() {
    await this.reportsService.resetAnimalToInspectReportToDefault(this.reportKey, true);
    await this.loadSetup();
    this.onSetupChanged.emit();
    this.closePopup();
  }

  public async saveSetup(resetToDefault: boolean) {
    if (!this.canEdit) {
      return;
    }
    this.loadingIconService.show();
    let setup = new ResetAnimalToInspectReportToDefaultModel();
    if (this.animalsToInspectList.length) {
      this.animalsToInspectList.forEach((animalToInspectItem: IAnimalsToInspectItem) => {
        setup[animalToInspectItem.key] = animalToInspectItem.value;
      });
    }
    setup.resetToDefault = resetToDefault;
    await this.reportsService.setAnimalToInspectSetup(this.reportKey, setup);
    this.loadingIconService.hide();
    this.onSetupChanged.emit();
    this.closePopup();
  }

  public get canEdit() : boolean {
    return this.configService.serverConfig.user.farmRole != FarmRole.ExternalUser;
  }

  private async loadSetup() {
    let response = await this.reportsService.getAnimalToInspectSetup();
    this.defaultAnimalsToInspectSetup = response.responseBody;
    this.animalsToInspectList = [];
    Object.keys(this.defaultAnimalsToInspectSetup).forEach((animalToInspect: string) => {
      if (this.defaultAnimalsToInspectSetup[animalToInspect]) {
        this.animalsToInspectList.push({ key: animalToInspect, value: Object.assign({}, this.defaultAnimalsToInspectSetup[animalToInspect])});
      }
    });
  }
}


export interface IAnimalsToInspectItem {
  key: string;
  value: ISelected;
}
