<div class="card-container"
     *ngIf="cardViewState">
  <div class="card-container-content">
    <div class="card-container-header">
      <card-popup-header-breadcrumb [cardViewState]="cardViewState" (onBreadcrumbNavigation)="handleBreadcrumbNavigation($event)"></card-popup-header-breadcrumb>
      <div class="title-container" saveDataWarningIgnore>
        <card-popup-header-title [cardViewState]="cardViewState"></card-popup-header-title>
      </div>
      <div class="close-button-container" saveDataWarningIgnore>
        <div class="close-btn" (click)="closeCard()" saveDataWarningInject>
          <div class="icon-close"></div>
        </div>
      </div>
    </div>
    <div class="card-container-body" saveDataWarningIgnore>
      <animal-card *ngIf="isAnimalCard"
                   [viewState]="cardViewState.animalCardViewState"
                   [operationType]="cardViewState.operationType"></animal-card>
      <group-card *ngIf="isGroupCard"
                  [viewState]="cardViewState.groupCardViewState"
                  [operationType]="cardViewState.operationType"></group-card>
      <branch-card *ngIf="isBranchCard"
                   [viewState]="cardViewState.branchCardViewState"></branch-card>
    </div>
  </div>
  <div class="bottom-corner"></div>
  <div class="card-footer" saveDataWarningIgnore>
    <div class="card-footer-details" *ngIf="isCardFooterVisible">
      <div class="selection-div" *ngIf="cardViewState.selectionState != null">
        <div class="selectable-row-button" [ngClass]="{ selected: isSelected }" (click)="toggleSelectedItem()" saveDataWarningInject>
          <div class="selected-icon" *ngIf="isSelected"></div>
        </div>
      </div>
      <div class="selection-label">
        <ng-container *ngFor="let translation of cardViewState.translationIds">
          <span [attr.sh-id]="'selection-label-' + translation">{{ translation | translate}} </span>
        </ng-container>
      </div>
      <div class="position">
        <span class="icon-prev" (click)="navigatePrevItem()" saveDataWarningInject></span>
        <span class="position-indicator">
          <span class="selected-item">{{currentIndex}}</span>
          &nbsp;/&nbsp;{{getLengthAccordingToDisplayedContext}}
        </span>
        <span class="icon-next" (click)="navigateNextItem()" saveDataWarningInject></span>
      </div>
    </div>
  </div>
</div>
