<div class="animal-detail-info-section" saveDataEditSection [editAreaName]="viewState.category">
  <div class="section-title-area">
    <div class="section-title">
      <div class="btn-toggle" (click)="toggleSectionExpansion()" [attr.sh-id]="'cow_card_details_toggle_section-' + viewState.category">
        <div class="icon" [ngClass]="viewState.isExpanded ? 'icon-prev' : 'icon-next'"></div>
        <span [attr.translation-id]="'ANIMAL.DETAILS.INFO.SECTION.' + viewState.category">{{'ANIMAL.DETAILS.INFO.SECTION.' + viewState.category | translate}}</span></div>
      <div class="edit-controls" *ngIf="isEditControlsVisible" nonExternalUser>
        <div class="icon icon-delete" (click)="undo()" saveDataWarningIgnore *ngIf="viewState.isEditMode" [attr.sh-id]="'cow_card_details_undo_' + viewState.category"></div>
        <div class="icon icon-save" (click)="save()" saveDataWarningIgnore *ngIf="viewState.isEditMode" [attr.sh-id]="'cow_card_details_save_' + viewState.category"></div>
        <div class="icon icon-edit" (click)="edit()" saveDataWarningInject [ngClass]="{'hidden': viewState.isEditMode}" [attr.sh-id]="'cow_card_details_edit_' + viewState.category"></div>
      </div>
    </div>
    <div class="section-error" *ngIf="viewState.errorKey" [attr.translation-id]="'ANIMAL.DETAILS.ERROR.' + viewState.errorKey">{{'ANIMAL.DETAILS.ERROR.' + viewState.errorKey | translate}}</div>
  </div>
  <div class="section-content-area" saveDataWarningIgnore>
    <div class="field-wrapper" *ngFor="let field of visibleFields">
      <div class="form-group">
        <span class="form-group-label" [attr.translation-id]="getFieldTranslationKey(field.fieldDetails)">{{getFieldTranslationKey(field.fieldDetails) | translate}}</span>
        <input class="form-group-value"
               [ngClass]="{'editable' : isFieldEditable(field.fieldDetails),
                           'invalid' : isFieldInvalid(field.fieldDetails)}"
               type="text"
               saveDataWarningIgnore
               inputFilter
               [inputFilterMaxLength]="16"
               [attr.sh-id]="'input_' + field.fieldDetails.name"
               [readOnly]="!viewState.isEditMode || !field.fieldDetails.isEditable"
               *ngIf="isAnimalNameField(field.fieldDetails)"
               [(ngModel)]="field.fieldDetails.value"
               (keyup)="setFieldValue(field, field.fieldDetails.value)"
               (keydown)="setFieldValue(field, field.fieldDetails.value)"/>
        <input class="form-group-value"
               [ngClass]="{'editable' : isFieldEditable(field.fieldDetails),
                           'invalid' : isFieldInvalid(field.fieldDetails)}"
               type="text"
               saveDataWarningIgnore
               [attr.sh-id]="'input_' + field.fieldDetails.name"
               [readOnly]="!viewState.isEditMode || !field.fieldDetails.isEditable"
               *ngIf="isInputTextField(field.fieldDetails)"
               [(ngModel)]="field.fieldDetails.value"
               (keyup)="setFieldValue(field, field.fieldDetails.value)"
               (keydown)="setFieldValue(field, field.fieldDetails.value)"/>
        <input class="form-group-value"
               [ngClass]="{'editable' : isFieldEditable(field.fieldDetails),
                           'invalid' : isFieldInvalid(field.fieldDetails)}"
               type="text"
               saveDataWarningIgnore
               [attr.sh-id]="'input_' + field.fieldDetails.name"
               [readOnly]="true"
               *ngIf="isBranchField(field.fieldDetails)"
               [value]="getBranchFieldText(field.fieldDetails)"/>
        <input class="form-group-value"
               saveDataWarningIgnore
               *ngIf="isInputWeightKG(field.fieldDetails)"
               [disabled]="!isFieldEditable(field.fieldDetails)"
               [readOnly]="!viewState.isEditMode || !field.fieldDetails.isEditable"
               [ngClass]="{'editable' : isFieldEditable(field.fieldDetails),
                           'invalid' : isFieldInvalid(field.fieldDetails)}"
               placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL' | translate }}"
               [attr.sh-id]="'input_' + field.fieldDetails.name + '_KG'"
               weightValueLimit
               contenteditable='true'
               [(ngModel)]="field.fieldDetails.value.weightValueKG"
               type="text"/>
        <input class="form-group-value"
               saveDataWarningIgnore
               *ngIf="isInputWeightLBS(field.fieldDetails)"
               [disabled]="!isFieldEditable(field.fieldDetails)"
               [ngClass]="{'editable' : isFieldEditable(field.fieldDetails),
                           'invalid' : isFieldInvalid(field.fieldDetails)}"
               [readOnly]="!viewState.isEditMode || !field.fieldDetails.isEditable"
               placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL' | translate }}"
               [attr.sh-id]="'input_' + field.fieldDetails.name + '_LBS'"
               weightValueLimit
               contenteditable='true'
               [(ngModel)]="field.fieldDetails.value.weightValueLBS"
               type="text"/>
        <date-picker class="form-group-value"
                      *ngIf="isDateField(field)"
                     [popupContentHorizontalAlignment]="alignEnd"
                      [ngClass]="{'editable' : isFieldEditable(field.fieldDetails),
                                'invalid' : isFieldInvalid(field.fieldDetails)}"
                      saveDataWarningIgnore
                      [disabled]="!viewState.isEditMode || !field.fieldDetails.isEditable"
                      [shIdPostfix]="field.fieldDetails.name"
                      [shownRemovableIcon]="getDateIsRemovable(field.fieldDetails)"
                      (epochChanged)="setFieldValue(field, $event)"
                      [selectedEpoch]="field.editValue"></date-picker>
        <time-picker class="form-group-value"
                     *ngIf="isTimeField(field)"
                     [ngClass]="{'editable' : isFieldEditable(field.fieldDetails),
                                    'invalid' : isFieldInvalid(field.fieldDetails)}"
                     saveDataWarningIgnore
                     [disabled]="!viewState.isEditMode || !field.fieldDetails.isEditable"
                     [shIdPostfix]="field.fieldDetails.name"
                     [shownRemovableIcon]="getDateIsRemovable(field.fieldDetails)"
                     (epochChanged)="setFieldValue(field, $event)"
                     [selectedEpoch]="field.editValue"></time-picker>
        <forms-dropdown-list class="form-group-value"
                             [ngClass]="{'editable' : isFieldEditable(field.fieldDetails),
                                         'invalid' : isFieldInvalid(field.fieldDetails)}"
                             [items]="getDropdownFieldItems(field.fieldDetails)"
                             saveDataWarningIgnore
                             [ngModel]="getFieldIdValue(field.fieldDetails)"
                             [disabled]="!viewState.isEditMode || !field.fieldDetails.isEditable"
                             [translateListItem]="false"
                             [iconTheme]="formsDLIconsThemes.Blue"
                             *ngIf="isInputDropdownList(field.fieldDetails)"
                             (ngModelChange)="setFieldValue(field, $event)"></forms-dropdown-list>
        <bull-choose-dropdown-list class="form-group-value"
                                   saveDataWarningIgnore
                                   [ngClass]="{'editable' : isFieldEditable(field.fieldDetails),
                                               'invalid' : isFieldInvalid(field.fieldDetails)}"
                                   [disabled]="!viewState.isEditMode || !field.fieldDetails.isEditable"
                                   [selectedBullListItem]="getFieldSelectedBull(field.fieldDetails)"
                                   (onBullSelectionChanged)="setFieldValue(field, $event)"
                                   (onBullRemoved)="onBullRemoved($event)"
                                   [iconTheme]="bullColorTheme.Blue"
                                   *ngIf="isInputBullChooseList(field.fieldDetails)"
                                   [items]="editableBullItemList"></bull-choose-dropdown-list>
        <regular-auto-complete
                       class="form-group-value"
                       [ngClass]="{'editable' : isFieldEditable(field.fieldDetails),
                                   'invalid' : isFieldInvalid(field.fieldDetails)}"
                       saveDataWarningIgnore
                       [disabled]="!viewState.isEditMode || !field.fieldDetails.isEditable"
                       [isAllowUnmatchedSelection]="true"
                       [searchQuery]="field.editValue"
                       (searchQueryChanged)="setFieldValue(field, $event)"
                       [items]="getAutocompleteFieldItems(field.fieldDetails)"
                       *ngIf="isInputAutocomplete(field.fieldDetails)">
        </regular-auto-complete>
      </div>
    </div>
  </div>
</div>
