<popup-template [isOpen]="isOpen && !disabled" (close)="closeDropdown()">
  <ng-container header>
    <div [ngClass]="[
        'input-area',
        disabled ? 'disabled' : '',
        isFieldError ? 'error' : '',
        'bg-color-' + backgroundColorTheme,
        'controls-theme-' + iconTheme,
        'input-border-style-' + inputBorderStyle]">
      <input [ngClass]="['search', 'bg-color-' + backgroundColorTheme, 'input-text-color-' + inputTextColor]"
             [attr.sh-id]="'dropdown_list_search' + (shIdPostfix ? '_' + shIdPostfix : '')"
             type="button"
             [disabled]="disabled"
             (keydown.enter)="onEnter($event)"
             (keydown.arrowdown)="onArrowDown()"
             (keydown.arrowup)="onArrowUp()"
             (click)="toggleOpen()"
             [value]="selectedDisplayValue"/>
      <div class="icon icon-delete" *ngIf="!disabled && selectedItem != null && allowClear" (click)="selectItem(null, null)"></div>
      <div class="icon icon-drop-down" [ngClass]="[isOpen ? 'fold-up': '', disabled ? 'disabled' : '']" *ngIf="!(allowClear && selectedItem)" (click)="toggleOpen()"></div>
    </div>
  </ng-container>
  <ng-container popup-content>
    <div class="dropdown-popup" [ngClass]="{'fold-up': isFoldDirectionUp}">
      <ul class="items">
        <li (click)="selectItem(null, $event)" class="placeholder" *ngIf="showPlaceholder()">
          <span>{{getTranslatedPlaceholder}}</span>
        </li>
        <li *ngFor="let dropdownItem of items; index as i"
            [ngClass]="{ marked: isMarked(i) }"
            [id]="'list-item-' + i"
            (mouseenter)="markedItem = i"
            (mouseleave)="markedItem = null"
            (click)="selectItem(dropdownItem, $event)"
            [attr.disabled]="dropdownItem.disabled"
            [attr.sh-id]="'dropdown_list_item_' + i + (shIdPostfix ? '_' + shIdPostfix : '')"
            [attr.alt-sh-id]='"dropdown_list_item_" + getListItemDisplayValue(dropdownItem)'>
          <ellipsisable-text class="forms-dropdown-list__item">{{ getListItemDisplayValue(dropdownItem) }}</ellipsisable-text>
        </li>
      </ul>
    </div>
  </ng-container>
</popup-template>
