import { Component, OnInit } from '@angular/core';
import {StartBackgroundEventDetails, StartBackgroundEventMetadata} from '../../../../../services/animals/model/animal-events';
import {EventFormComponent} from '../event-form.component';
import {CustomEventWrapperCssClass} from '../event-form-errors/event-form-errors.component';

@Component({
  selector: 'start-background-event-form',
  templateUrl: './start-background-event-form.component.html',
  styleUrls: ['./start-background-event-form.component.scss']
})
export class StartBackgroundEventFormComponent extends EventFormComponent<StartBackgroundEventMetadata, StartBackgroundEventDetails> {

  public CustomEventWrapperCssClass = CustomEventWrapperCssClass;

  constructor() {
    super();
  }

}
