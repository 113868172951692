import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
  IErrors,
  AddAnimalFieldsErrorsEnum,
  IGroupItem,
  IFemalesMetadata,
  ISCRTagItem,
  TagTypeEnum
} from 'src/app/services/animals/model/animal.model';
import {AnimalsService} from '../../../../../../../services/animals/animals.service';
import {
  DropdownListAutoSelectionMode,
  DropdownListItem
} from '../../../../../../../common/components/dropdown-list/dropdown-list-model';
import { AutoCompleteColorScheme } from 'src/app/common/components/auto-complete/auto-complete-model';
import {AddFemaleModel} from '../add-female-breeding.component';
import {FormsDLIconsThemes} from "../../../../../../../common/components/dropdown-list/forms-dropdown-list/forms-dropdown-list.component";


@Component({
  selector: 'add-female-breeding-general-tab',
  templateUrl: './add-female-breeding-general-tab.component.html',
  styleUrls: ['./add-female-breeding-general-tab.component.scss']
})
export class AddFemaleBreedingGeneralTabComponent implements OnInit, OnChanges {

  public dropdownListAutoSelectionMode = DropdownListAutoSelectionMode;

  @Input()
  public addFemaleModel: AddFemaleModel;

  @Input()
  public groupsDropdownListItems: DropdownListItem[] = [];

  @Input()
  public femalesMetadata: IFemalesMetadata;

  @Input()
  public errors: IErrors[] = [];

  @Output()
  public onGroupSelected : EventEmitter<IGroupItem> = new EventEmitter<IGroupItem>();

  @Output()
  public onErrorsClear: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('registrationNumberInput')
  public registrationNumberInput: ElementRef;

  public AutoCompleteColorScheme = AutoCompleteColorScheme;

  public AddAnimalFieldsErrorsEnum = AddAnimalFieldsErrorsEnum;

  public rfidsTagsNumbers: string[] = [];

  public scrTagsNumbers: string[] = [];

  public formsDLIconsThemes = FormsDLIconsThemes;

  constructor(public readonly animalsService: AnimalsService) { }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.femalesMetadata != null &&
       changes.femalesMetadata.previousValue != changes.femalesMetadata.currentValue) {
      if (this.femalesMetadata.rfidTagList != null) {
        this.rfidsTagsNumbers = this.femalesMetadata.rfidTagList.map((rfIdItem: ISCRTagItem) => (rfIdItem.tagNumber));
      } else {
        this.rfidsTagsNumbers = [];
      }
      if (this.femalesMetadata.scrTagList != null) {
        this.scrTagsNumbers = this.femalesMetadata.scrTagList.map((tagItem: ISCRTagItem) => (tagItem.tagNumber));
      } else {
        this.scrTagsNumbers = [];
      }
    }
  }

  public onClearRegistrationNumber(): void {
    this.addFemaleModel.registrationNumber = null;
    this.registrationNumberInput.nativeElement.focus();
    this.onErrorsClear.emit();
  }

  public onScrTagChanged(tagNumber: string) {
    if(tagNumber == null ||
      tagNumber === '') {
      this.addFemaleModel.tagNumber = null;
    } else {
      this.addFemaleModel.tagNumber = this.femalesMetadata.scrTagList.find(value => value.tagNumber === tagNumber);
      if(this.addFemaleModel.tagNumber == null) {
        this.addFemaleModel.tagNumber = {
          tagNumber: tagNumber,
          id: null,
          tagType: TagTypeEnum.Scr
        };
      }
    }
    this.onErrorsClear.emit();
  }

  public onRfIdTagChanged(tagNumber: string) {
    if(tagNumber == null ||
      tagNumber === '') {
      this.addFemaleModel.EID = null;
    } else {
      this.addFemaleModel.EID = this.femalesMetadata.rfidTagList.find(value => value.tagNumber === tagNumber);
      if(this.addFemaleModel.EID == null) {
        this.addFemaleModel.EID = {
          tagNumber: tagNumber,
          id: null,
          tagType: TagTypeEnum.RfId
        };
      }
    }
    this.onErrorsClear.emit();
  }
}
