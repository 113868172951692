import {Component, Input, OnInit} from '@angular/core';
import {FilterNumericQuery, NumericQueryCompareOperatorsEnum} from '../../../../../../services/reports/model/report-query.interface';
import {DropdownListItem} from '../../../../dropdown-list/dropdown-list-model';

@Component({
  selector: 'filter-numeric',
  templateUrl: './filter-numeric.component.html',
  styleUrls: ['./filter-numeric.component.scss']
})
export class FilterNumericComponent implements OnInit {

  @Input()
  public filterNumericQuery : FilterNumericQuery;

  public operatorsDropdownItems: DropdownListItem[];

  constructor() { }

  public ngOnInit() {
    this.initOperatorsDropdownItems();
  }

  private initOperatorsDropdownItems(){
    this.operatorsDropdownItems = [];
    for (let operator in NumericQueryCompareOperatorsEnum) {
      this.operatorsDropdownItems.push({
        value: operator,
        displayValue: 'COMMON.FILTER.OPERATOR.NUMERIC.' + operator,
        disabled: false,
        icon: null
      });
    }
  }

  public onOperatorSelected(operator:DropdownListItem) {
    this.filterNumericQuery.operator = operator.value;
  }


}
