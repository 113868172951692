import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {AuthService, LoginResult} from '../../../services/auth/auth.service';
import { ConfigService } from '../../../services/config/config.service';
import {AuthLocalValidatorService} from '../../../services/auth/auth-local-validator.service';
import {HeaderAuthorizationDetails} from '../../../services/rest-api/models/header-authorization-details';
import {HttpResponseResult} from '../../../services/rest-api/rest-api.service';


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public authDetails: HeaderAuthorizationDetails = new HeaderAuthorizationDetails();

  public rememberUser: boolean;

  public errorReason: string;

  public serverError: boolean;

  private static readonly EnterKey = 'Enter';

  constructor(private router: Router,
              public configService: ConfigService,
              private authService: AuthService,
              private authLocalValidator: AuthLocalValidatorService) {

  }

  public ngOnInit() {
    this.authDetails = this.authService.getAuthDetailsFromLocalStorage();
    if(this.authDetails === null) {
      this.authDetails = new HeaderAuthorizationDetails();
    }
    else {
      this.rememberUser = true;
    }
    if(this.configService.anonymousAuthResult) {
      this.authDetails.farmId = this.configService.anonymousAuthResult.farmId;
    } else {
      this.serverError = true;
    }
  }

  public validateUserName($event: KeyboardEvent) {
    if ($event.key !== LoginComponent.EnterKey) {
      this.resetErrorReason($event);
      this.authDetails.userName = this.authLocalValidator.validateUserNameCharacters(this.authDetails.userName);
    }
  }

  public resetErrorReason($event: KeyboardEvent) {
    if ($event.key !== LoginComponent.EnterKey) {
      this.errorReason = null;
    }
  }

  public onSubmit() {
    this.authService.performLogin(this.authDetails, this.rememberUser).then((response: HttpResponseResult<LoginResult>) => {

      if(response.responseBody) {
        if(response.responseBody.authorized) {
          if(response.responseBody.isEulaRequired) {
            this.router.navigate(['/sign-eula']);
          } else {
            this.router.navigate(['/']);
          }
        }
        else {
          this.errorReason = "Unauthorized";
        }
      }
      else {
        if(response.status === 401) {
          this.errorReason = "Unauthorized";
        } else {
          this.errorReason = "WRONG_API_URL";
        }
      }
    });
  }

  public onForgotPasswordClick() {
    this.router.navigate(['/forgot-password']);
  }

  public onRememberUserClick() {
    this.rememberUser = !this.rememberUser;
  }
}
