import { Injectable } from '@angular/core';
import {HttpResponseResult, HttpResponseResultNoBody, RestApiService} from '../rest-api/rest-api.service';
import {HttpParams} from '@angular/common/http';
import {AuthService} from '../auth/auth.service';
import {NoteObject, NoteRow, NotesMetadataResult, NotesResult} from './model/notes.model';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  private static readonly NotesRoute = '/rest/api/notes';

  private static readonly FinishingNotesRoute = '/rest/api/finishing/notes';

  public getNotesMetadataSubject: Subject<void> = new Subject<void>();

  constructor(private readonly restApiService: RestApiService, private readonly authService: AuthService) { }

  public getBreedingNotes(offsetPage: number, limit: number): Promise<HttpResponseResult<NotesResult>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('offset', offsetPage.toString());
    httpParams = httpParams.append('limit', limit.toString());
    return this.restApiService.sendGetMethodAny<NotesResult>(NotesService.NotesRoute, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getFinishingNotes(offsetPage: number, limit: number): Promise<HttpResponseResult<NotesResult>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('offset', offsetPage.toString());
    httpParams = httpParams.append('limit', limit.toString());
    return this.restApiService.sendGetMethodAny<NotesResult>(NotesService.FinishingNotesRoute, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getAddNewNoteBreedingMetadata(): Promise<HttpResponseResult<NotesMetadataResult>> {
    return this.restApiService.sendGetMethod<NotesMetadataResult>(NotesService.NotesRoute + '?projection=metadata', this.authService.currentUserAuthState.authDetails);
  }

  public getAddNewNoteFinishingMetadata(): Promise<HttpResponseResult<NotesMetadataResult>> {
    return this.restApiService.sendGetMethod<NotesMetadataResult>(NotesService.FinishingNotesRoute + '?projection=metadata', this.authService.currentUserAuthState.authDetails);
  }

  public createBreedingNote(createNoteRequest: NoteObject): Promise<HttpResponseResult<NoteRow[]>> {
    return this.restApiService.sendPostMethod<NoteRow[]>(NotesService.NotesRoute, this.authService.currentUserAuthState.authDetails, createNoteRequest);
  }

  public createFinishingNote(createNoteRequest: NoteObject): Promise<HttpResponseResult<NoteRow[]>> {
    return this.restApiService.sendPostMethod<NoteRow[]>(NotesService.FinishingNotesRoute, this.authService.currentUserAuthState.authDetails, createNoteRequest);
  }

  public updateBreedingNote(noteId: number, updateNoteRequest: NoteObject): Promise<HttpResponseResult<NoteRow[]>> {
    return this.restApiService.sendPutMethod<NoteRow[]>(NotesService.NotesRoute + '/' + noteId, this.authService.currentUserAuthState.authDetails, updateNoteRequest);
  }

  public updateFinishingNote(noteId: number, updateNoteRequest: NoteObject): Promise<HttpResponseResult<NoteRow[]>> {
    return this.restApiService.sendPutMethod<NoteRow[]>(NotesService.FinishingNotesRoute + '/' + noteId, this.authService.currentUserAuthState.authDetails, updateNoteRequest);
  }

  public deleteBreedingNote(noteId: number): Promise<HttpResponseResultNoBody> {
    return this.restApiService.sendDeleteMethod(NotesService.NotesRoute + '/' + noteId, this.authService.currentUserAuthState.authDetails);
  }

  public deleteFinishingNote(noteId: number): Promise<HttpResponseResultNoBody> {
    return this.restApiService.sendDeleteMethod(NotesService.FinishingNotesRoute + '/' + noteId, this.authService.currentUserAuthState.authDetails);
  }
}
