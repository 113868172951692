import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ManageComponent} from './manage.component';
import {ManageGuard} from '../../services/manage/route-guards/manage.guard';
import {ManageAnimalsComponent} from './manage-animals/manage-animals.component';
import {ManageActiveAnimalsComponent} from './manage-animals/manage-active-animals/manage-active-animals.component';
import {ManageCulledAnimalsComponent} from './manage-animals/manage-culled-animals/manage-culled-animals.component';
import {ManageEventsComponent} from './manage-events/manage-events.component';
import {ManageGroupsComponent} from './manage-groups/manage-groups.component';
import {ManageBranchesComponent} from './manage-branches/manage-branches.component';
import {ManageNotesComponent} from './manage-notes/manage-notes.component';

const routes: Routes = [{
  path: '',
  component: ManageComponent,
  canActivate: [ManageGuard],
  canActivateChild: [ManageGuard],
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'animals'
    },
    {
      path: 'animals',
      component: ManageAnimalsComponent,
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'active'
        },
        {
          path: 'active',
          component: ManageActiveAnimalsComponent
        },
        {
          path: 'culled',
          component: ManageCulledAnimalsComponent
        }
      ]
    },
    {
      path: 'events',
      component: ManageEventsComponent
    },
    {
      path: 'groups',
      component: ManageGroupsComponent
    },
    {
      path: 'branches',
      component: ManageBranchesComponent
    },
    {
      path: 'notes',
      component: ManageNotesComponent
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageRoutingModule { }
