import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnimalsService} from "../../../../../../../services/animals/animals.service";
import {AddAnimalFieldsErrorsEnum, IErrors} from "../../../../../../../services/animals/model/animal.model";
import {FinishingAddMaleModel} from "../add-male-finishing.component";
import {DropdownListItem} from "../../../../../../../common/components/dropdown-list/dropdown-list-model";
import {EpochStateModel} from "../../../../../../../common/components/calendar/model/epoch-state-model";

@Component({
  selector: 'add-male-finishing-finishing-tab',
  templateUrl: './add-male-finishing-finishing-tab.component.html',
  styleUrls: ['./add-male-finishing-finishing-tab.component.scss']
})
export class AddMaleFinishingFinishingTabComponent implements OnInit {

  public AddAnimalFieldsErrorsEnum = AddAnimalFieldsErrorsEnum;

  @Input()
  public finishingAddMaleModel: FinishingAddMaleModel;

  @Input()
  public groupsDropdownListItems: DropdownListItem[];

  @Input()
  public errors: IErrors[];

  @Output()
  public onClearErrors: EventEmitter<void> = new EventEmitter<void>();

  constructor(public animalsService: AnimalsService) { }

  public ngOnInit() {
  }

  public clearErrors() {
    this.onClearErrors.emit();
  }

  public onGroupSelected(selectedGroup: DropdownListItem) {
    if (!this.finishingAddMaleModel.group) {
      this.clearErrors();
    }
    this.finishingAddMaleModel.group = selectedGroup;
  }

  public onCastrationDateChanged(selectedCastrationDate: EpochStateModel) {
    this.finishingAddMaleModel.castration = selectedCastrationDate;
    this.finishingAddMaleModel.updateDatesRanges();
    this.clearErrors();
  }

  public onBackgroundStartChanged(selectedBackgroundDate: EpochStateModel) {
    this.finishingAddMaleModel.backgroundStartDate = selectedBackgroundDate;
    this.finishingAddMaleModel.updateDatesRanges();
    this.clearErrors();
  }

  public onFinishingStartDateChanged(selectedFinishingDate: EpochStateModel) {
    this.finishingAddMaleModel.finishingStart = selectedFinishingDate;
    this.finishingAddMaleModel.updateDatesRanges();
    this.clearErrors();
  }
}
