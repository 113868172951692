import { Component, SimpleChanges } from '@angular/core';
import {
  ChangeTagCowEventMetadataBase,
  CreateChangeTagEventDetailsBase,
  EventTypeEnum,
} from 'src/app/services/animals/model/animal-events';
import {ITag, TagType} from '../../../../../services/tags/model/tags-model';
import {EventFormComponent} from '../event-form.component';

@Component({
  selector: 'change-tag-event-form',
  templateUrl: './change-tag-event-form.component.html',
  styleUrls: ['./change-tag-event-form.component.scss']
})
export class ChangeTagEventFormComponent extends EventFormComponent<ChangeTagCowEventMetadataBase, CreateChangeTagEventDetailsBase> {
  public unassignedTags: string[];

  public get tagNumber(): string | null {
    return this.editModel.tag != null ? this.editModel.tag.tagNumber : null;
  }

  public set tagNumber(newValue: string) {
    const getTag = (): ITag | null => {
      if (newValue == null || newValue.length === 0) {
        return null;
      }

      const existsUnassigedTag = (this.meta.unassignedTags || []).find((ut) => ut.tagNumber === newValue);
      if (typeof existsUnassigedTag !== 'undefined' && existsUnassigedTag != null) {
        return existsUnassigedTag;
      }
      return {
        tagNumber: newValue,
        tagType: this.editModel.type === EventTypeEnum.ChangeScrTag ? TagType.scr : TagType.rfId
      };
    };

    this.editModel.tag = getTag();
    this.emitModelChanged();
  }

  constructor() {
    super();
  }

  protected onChanges(changes: SimpleChanges): void {
    if (changes.meta != null) {
      this.unassignedTags = (this.meta.unassignedTags || []).map((ut) => ut.tagNumber);
    }
  }

  public onSearchQueryChanged(tagNumber: string): void {
    this.tagNumber = tagNumber;
  }
}
