import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogActionsService} from '../../../../services/dialogs/dialog-actions.service';
import {Subscription} from 'rxjs';
import {AssignAnimalToTagDialogModel} from '../../../../services/dialogs/model/dialog-actions-model';
import {IAnimalIdMeta} from '../../../../services/animals/model/animal.model';
import {AnimalEventsService} from '../../../../services/animals/animal-events.service';
import * as moment from 'moment';
import {AssignScrTagCowEventMetadata, CreateAssignSrcTagEventDetails} from '../../../../services/animals/model/animal-events';
import {TagType} from '../../../../services/tags/model/tags-model';

@Component({
  selector: 'assign-animal-to-tag-dialog',
  templateUrl: './assign-animal-to-tag-dialog.component.html',
  styleUrls: ['./assign-animal-to-tag-dialog.component.scss']
})
export class AssignAnimalToTagDialogComponent implements OnInit, OnDestroy {

  private dialogActionsSubscription: Subscription;

  public isOpen: boolean;

  private error: string;

  public model: AssignAnimalToTagDialogModel;

  private selectedAnimal: IAnimalIdMeta;

  private animalNames: string[];

  constructor(private readonly dialogActionsService:DialogActionsService,
              private readonly animalEventsService:AnimalEventsService) { }

  public ngOnInit() {
    this.dialogActionsSubscription = this.dialogActionsService.assignAnimalToTagDialogSubject.subscribe(model => {
      this.selectedAnimal = null;
      this.model = model;
      this.animalNames = this.model.animals.map(animal => animal.animalName);
      this.isOpen = true;
    });
  }

  public ngOnDestroy() {
    this.dialogActionsSubscription.unsubscribe();
  }

  private onMatchingItem(animalName:string) {
    if(animalName == null) {
      this.selectedAnimal = null;
    } else {
      this.selectedAnimal = this.model.animals.find(animal => animal.animalName === animalName);
    }
  }

  private async applyClick() {
    if(this.selectedAnimal == null) {
      return;
    }
    let assignTagEvent: CreateAssignSrcTagEventDetails = new CreateAssignSrcTagEventDetails(new AssignScrTagCowEventMetadata());
    assignTagEvent.tag = {tagNumber: this.model.tagNumber.toString(), tagType: TagType.scr};
    let response = await this.animalEventsService.createAnimalEvent(this.selectedAnimal.animalId, {startDateTime:moment().unix()}, assignTagEvent)
    this.isOpen = false;
    setTimeout(async () => {
      await this.model.actionsContext.reloadDefault();
      this.model = null;
    })
  }

  public closeClick() {
    if(this.model) {
      this.isOpen = false;
    }
  }

  public get animalIds(): string[] {
    return this.model.animals.map((animal) => animal.animalName);
  }
}
