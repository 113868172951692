import {
  Component,
  SimpleChanges
} from '@angular/core';
import {
  CreateDryOffEventDetails,
  DryOffCowEventMetadata
} from '../../../../../services/animals/model/animal-events';
import { IGroupMetadata } from 'src/app/services/groups/model/group.interface';
import {EventFormComponent, EventFormFieldName} from '../event-form.component';
import {DropdownListItem} from '../../../../../common/components/dropdown-list/dropdown-list-model';
import {FormsDLIconsThemes} from "../../../../../common/components/dropdown-list/forms-dropdown-list/forms-dropdown-list.component";

@Component({
  selector: 'dry-off-event-form',
  templateUrl: './dry-off-event-form.component.html',
  styleUrls: ['./dry-off-event-form.component.scss']
})
export class DryOffEventFormComponent extends EventFormComponent<DryOffCowEventMetadata, CreateDryOffEventDetails> {

  public formsDLIconsThemes = FormsDLIconsThemes;

  public get destinationGroup(): number | undefined {
    return this.editModel.destinationGroup;
  }

  public set destinationGroup(newValue: number | undefined) {
    this.editModel.destinationGroup = newValue;
    this.emitModelChanged();
  }

  public groupsDropdownItems: DropdownListItem[] = [];

  public EventFormFieldName = EventFormFieldName;

  constructor() {
    super();
  }

  protected onChanges(changes: SimpleChanges): void {
    if (changes.meta != null) {
      this.groupsDropdownItems = this.mapGroupMetadataToDropdownItems(this.meta.groups);
    }
  }

  private mapGroupMetadataToDropdownItems(groups: IGroupMetadata[]): DropdownListItem[] {
    return groups.map((group) => {
      return {
        value: group.id,
        displayValue: group.name,
        disabled: false,
        icon: null
      };
    });
  }
}
