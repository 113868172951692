import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {DIALOG_TRANSITIONS} from '../../../../animations/animations';
import {ReportActionsService} from '../../../../../services/reports/report-actions.service';
import {Subscription} from 'rxjs';
import {BlinkRate} from "../../../../../services/reports/model/report.interface";
import {RadioButtonColorTheme} from '../../../radio-button/radio-button.component';
import {TranslationService} from '../../../../../services/translations/translation.service';
import {DurationInput} from 'src/app/common/pipes/duration.pipe';
import {TurnLedLightingOnDialogModel} from '../../../../../services/reports/model/turn-led-lighting-on-dialog-model';
import {SearchService} from '../../../../../services/search/search.service';
import {SearchEntry} from '../../../../../services/search/model/search.model';

@Component({
  selector: 'turn-led-lighting-on-dialog',
  templateUrl: './turn-led-lighting-on-dialog.component.html',
  styleUrls: ['./turn-led-lighting-on-dialog.component.scss'],
  animations: [DIALOG_TRANSITIONS]
})
export class TurnLedLightingOnDialogComponent implements OnInit, OnDestroy {

  private reportActionsSubscription: Subscription;

  public isPopupOpen: boolean;

  public labelSlow!: string;

  public labelFast!: string;

  public model: TurnLedLightingOnDialogModel;

  public BlinkRate = BlinkRate;

  public DurationInput = DurationInput;

  public RadioButtonColorTheme = RadioButtonColorTheme;

  public animalIds: string[] = [];

  constructor(private translationService: TranslationService,
              private searchService: SearchService,
              private reportActionsService: ReportActionsService) {
  }

  public ngOnInit() {
    this.labelFast = this.translationService.translate('DIALOGS.ADHOC.TURN_LED_LIGHTING_ON_DIALOG.BLINK.FAST');
    this.labelSlow = this.translationService.translate('DIALOGS.ADHOC.TURN_LED_LIGHTING_ON_DIALOG.BLINK.SLOW');

    this.reportActionsSubscription = this.reportActionsService.turnLedLightningOnDialogSubject.subscribe(value => {
      this.model = value;
      this.isPopupOpen = !!value;
      this.fillUpAnimalIdList();
    });
  }

  private async fillUpAnimalIdList(): Promise<void> {
    if (this.model) {
      const availableAnimalsSearchEntities: SearchEntry[] = await this.searchService.listOfAvailableAnimals();
      this.animalIds = availableAnimalsSearchEntities
        .filter((searchAnimal: SearchEntry) => this.model.animalIds.some(selectedAnimalId => searchAnimal.entityId == selectedAnimalId))
        .map((animal: SearchEntry) => animal.entityName);
    }
  }

  public ngOnDestroy(): void {
    this.reportActionsSubscription.unsubscribe();
  }

  public get isBlinkFastChecked(): boolean {
    return this.model && this.model.blinkRate === BlinkRate.Fast;
  }

  public onSelectBlinkRate(blinkRate: BlinkRate): void {
    this.model.blinkRate = blinkRate;
  }

  public applyClick() {
    if (this.model) {
      this.reportActionsService.turnLedLightingOn(this.model);
      this.isPopupOpen = false;
    }
  }

  public cancelClick(): void {
    this.isPopupOpen = false;
    this.model.duration = this.minDuration;
  }

  public updateDuration(duration: number) {
    this.model.duration = Math.floor(duration);
  }

  public get minDuration(): number {
    return TurnLedLightingOnDialogModel.MinDuration;
  }
}
