import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FarmAlertConfigData} from '../../../../../../services/manage/manage-settings.service';
import {DateTimeFormatEnum} from '../../../../../../common/pipes/epoch-date-time.pipe';

@Component({
  selector: 'notification-timing-plate',
  templateUrl: './notification-timing-plate.component.html',
  styleUrls: ['./notification-timing-plate.component.scss']
})
export class NotificationTimingPlateComponent {

  @Input()
  public canEdit: boolean;

  @Input()
  public farmAlertConfigData: FarmAlertConfigData;

  @Output()
  public onNotificationTimingPlateClick: EventEmitter<FarmAlertConfigData> = new EventEmitter<FarmAlertConfigData>();

  public get showAlways(): boolean {
    return this.farmAlertConfigData && this.farmAlertConfigData.value && this.farmAlertConfigData.value.isAlways;
  }

  public get showTiming(): boolean {
    return this.farmAlertConfigData && this.farmAlertConfigData.value && !this.farmAlertConfigData.value.isAlways
      && this.farmAlertConfigData.value.startTime.epochTime !== null && this.farmAlertConfigData.value.endTime.epochTime !== null;
  }

  public handlePlateClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.onNotificationTimingPlateClick.emit(this.farmAlertConfigData);
  }

  public dateTimeFormatEnum = DateTimeFormatEnum;
}
