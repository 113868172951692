import {Component, OnInit, ViewChild} from '@angular/core';
import {
  GroupRoutineEventModifyModel,
  GroupRoutineEventRow,
  GroupRoutineEventsResult
} from '../../../../../services/manage/model/group-routine-events.model';
import {ManageSettingsService} from '../../../../../services/manage/manage-settings.service';
import {DataGridQuery} from '../../../../../common/components/data-grid/model/data-grid-query';
import {HttpResponseResult} from '../../../../../services/rest-api/rest-api.service';
import {LoadingIconService} from '../../../../../services/loading-icon/loading-icon.service';
import {SearchEntry} from '../../../../../services/search/model/search.model';
import {SearchService} from '../../../../../services/search/search.service';
import {DataGridColumnMap} from '../../../../../common/components/data-grid/model/data-grid-column-map';
import {DateTimeFormatEnum} from 'src/app/common/pipes/epoch-date-time.pipe';
import {DataGridComponent} from '../../../../../common/components/data-grid/data-grid.component';
import {IDataGridViewContext} from '../../../../../services/model/common-model';
import {RowActionsPopupItem} from '../../../../../common/components/row-actions-popup/row-actions-popup.component';
import {IconCssClass} from '../../../../../services/reports/model/report.interface';
import {ErrorDialogService} from '../../../../../common/components/dialogs/error-dialog/error-dialog.service';
import {TranslationService} from '../../../../../services/translations/translation.service';
import {Failure} from '../../../../../services/registration/model/registration.model';
import {DescriptionCellEntity} from '../../../../../common/components/data-grid/description-cell-template/description-cell-entities/model/description-cell-entity.model';
import {UserPermissionsService} from '../../../../../services/auth/user-permissions.service';
import {GridViewState} from '../../../../../services/ui/view-state/grid-row/grid-view-state';
import {ConfigService} from '../../../../../services/config/config.service';
import {FarmRole} from '../../../../../services/auth/model/farm-role.enum';

export enum ManageEventsRowActions {
  Edit = 'Edit',
  Delete = 'Delete'
}

@Component({
  selector: 'manage-settings-group-routine-events',
  templateUrl: './manage-settings-gre.component.html',
  styleUrls: ['./manage-settings-gre.component.scss']
})
export class ManageSettingsGroupRoutineEventsComponent implements OnInit, IDataGridViewContext  {

  @ViewChild('groupRoutineEventsGrid')
  public groupRoutineEventsGrid: DataGridComponent;

  public groupRoutineEventsResult: GroupRoutineEventsResult;

  public groupRoutineEventsGridQuery: DataGridQuery;

  public groupsList: SearchEntry[] = [];

  public readonly columnsMappings: Map<string, DataGridColumnMap> = new Map();

  public DateTimeFormatEnum = DateTimeFormatEnum;

  public readonly rowActionsMap: Map<GroupRoutineEventRow, RowActionsPopupItem[]> = new Map<GroupRoutineEventRow, RowActionsPopupItem[]>();

  public isDeleteDialogShown: boolean = false;

  public isEditEventOpen: boolean;

  public editedGroupRoutineEvent : GroupRoutineEventModifyModel;

  public editedGroupRoutineEventId : number;

  private gridViewState : GridViewState = new GridViewState();

  private groupRoutineEventIdToDelete: number;

  constructor(private readonly manageSettingsService:ManageSettingsService,
              private readonly loadingIconService: LoadingIconService,
              private readonly searchService: SearchService,
              private readonly configService: ConfigService,
              private readonly errorDialogService: ErrorDialogService,
              private readonly translationService: TranslationService,
              public readonly userPermissionsService: UserPermissionsService) {
    this.columnsMappings.set('eventType',
      {
        enableSorting: false,
        flexGrow: 1
      });
    this.columnsMappings.set('startTime',
      {
        enableSorting: false,
        flexGrow: 0.5
      });
    this.columnsMappings.set('groups',
      {
        enableSorting: false,
        flexGrow: 1
      });
    if (this.configService.serverConfig.user.farmRole != FarmRole.ExternalUser) {
      this.columnsMappings.set('actions',
        {
          enableSorting: false,
          flexGrow: 0.1
        });
    }
  }

  public ngOnInit() {
    this.setDefaultEventsSortedGridQuery();
    this.getGroupRoutineEvents();
  }

  public get hideEventsGrid() : boolean {
    return this.groupRoutineEventsResult == null ||
           this.groupRoutineEventsResult.result.length == 0;
  }

  public get hideNoEventsLayout() : boolean {
    return this.groupRoutineEventsResult == null ||
      this.groupRoutineEventsResult.result.length > 0;
  }

  public async getGroupRoutineEvents() {
    let groupRoutineEventsResult: HttpResponseResult<GroupRoutineEventsResult>;
    this.rowActionsMap.clear();
    this.loadingIconService.show();
    this.groupsList = await this.searchService.listOfAvailableGroups();
    groupRoutineEventsResult = await this.manageSettingsService.getGroupRoutineEvents(this.groupRoutineEventsGridQuery.page,
                                                                                      this.groupRoutineEventsGridQuery.limit);
    if(groupRoutineEventsResult.responseBody && this.groupsList) {
      // if events exists in events list
      this.groupRoutineEventsResult = groupRoutineEventsResult.responseBody;
      this.groupRoutineEventsResult.result.forEach(value => {
        this.setRowActions(value);
      });
    } else {
      // if no events exists in events list
      this.groupsList = [];
      this.groupRoutineEventsResult = {
        limit: 0,
        offset: 0,
        total: 0,
        result: []
      }
    }
    this.loadingIconService.hide();
  }

  public toggleExpandRow() {
    this.groupRoutineEventsGrid.refreshRowsHeight();
  }

  public getGridViewState(): GridViewState {
    return this.gridViewState;
  }

  public async reloadDefault() {
    this.getGroupRoutineEvents();
  }

  public onRowActionsOpen() {
    this.gridViewState.clearExpandedRows();
    this.groupRoutineEventsGrid.refreshRowsHeight();
  }

  public async handleRowAction(rowAction: string, rowModel: GroupRoutineEventRow) {
    switch (<ManageEventsRowActions>rowAction) {
      case ManageEventsRowActions.Delete: {
        this.groupRoutineEventIdToDelete = rowModel.id;
        this.isDeleteDialogShown = true;
        break;
      }
      case ManageEventsRowActions.Edit: {
        this.editedGroupRoutineEvent = new GroupRoutineEventModifyModel();
        this.editedGroupRoutineEvent.startTime = rowModel.startTime;
        this.editedGroupRoutineEvent.eventType = rowModel.eventType;
        this.editedGroupRoutineEvent.groupIds = rowModel.groups.map(group => group.id);
        this.editedGroupRoutineEventId = rowModel.id;
        this.isEditEventOpen = true;
        break;
      }
    }
  }

  public async deleteGroupRoutineEvent() {
    this.isDeleteDialogShown = false;
    let response = await this.manageSettingsService.deleteGroupRoutineEvent(this.groupRoutineEventIdToDelete);
    if (response.status === 204) {
      this.groupRoutineEventsGrid.reloadGrid();
    } else {
      setTimeout(() => {
        let failures: Failure[] = [{errorKey: response.errorResponseBody.result.failures[0].key, fieldName: response.errorResponseBody.result.failures[0].fieldName }];
        this.errorDialogService.show('MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.DELETE_DIALOG.ERROR.FAILED_DELETE_NOTE',
                                      failures,
                                     'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.DELETE_DIALOG.ERROR',
                                     null);
      }, 200);
    }
    this.groupRoutineEventIdToDelete = null;
  }

  public createEventClick() {
    this.editedGroupRoutineEvent = new GroupRoutineEventModifyModel();
    this.editedGroupRoutineEvent.groupIds = [];
    this.editedGroupRoutineEventId = null;
    this.isEditEventOpen = true;
  }

  public closeDeleteConfirmationDialog() {
    this.isDeleteDialogShown = false;
  }

  public onSaveCompleted() {
    this.isEditEventOpen = false;
    this.editedGroupRoutineEventId = null;
    this.editedGroupRoutineEvent = null;
    this.groupRoutineEventsGrid.reloadGrid();
  }

  public onClose() {
    this.isEditEventOpen = false;
  }

  private setDefaultEventsSortedGridQuery() {
    this.groupRoutineEventsGridQuery = new DataGridQuery();
    this.groupRoutineEventsGridQuery.offset = 0;
    this.groupRoutineEventsGridQuery.limit = 100;
  }

  private setRowActions(rowModel: GroupRoutineEventRow) {
    if(this.userPermissionsService.hasUserPermissionsForEditManageSettings()) {
      let rowActions: RowActionsPopupItem[] = [];
      rowActions.push({
        action: ManageEventsRowActions.Edit,
        displayValue: this.translationService.translate('MANAGE.SETTINGS.USERS.USERS_LIST.EDIT'),
        iconCssClass: IconCssClass.edit,
        shIdRowIdentifier: 'group_routine_event_edit'
      });
      rowActions.push({
        action: ManageEventsRowActions.Delete,
        displayValue: this.translationService.translate('MANAGE.SETTINGS.USERS.USERS_LIST.DELETE'),
        iconCssClass: IconCssClass.remove,
        shIdRowIdentifier: 'group_routine_event_delete'
      });
      this.rowActionsMap.set(rowModel, rowActions);
    }
  }

  public getGroupDescriptionEntities(row: GroupRoutineEventRow): DescriptionCellEntity[] {
    return row.groups.map(group => ({
      entityId: group.id,
      entityName: group.name,
    }));
  }
}
