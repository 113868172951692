import { Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[input-focus]'
})
export class InputFocusDirective implements AfterViewInit {

  constructor(private el: ElementRef) {
  }

  public ngAfterViewInit() {
    this.el.nativeElement.focus();
  }

}
