<div class="security-questions-container">
  <div class="security-questions-body-wrapper">
    <div class="security-questions-text" [attr.translation-id]="'REGISTRATION.FLOW.SECURITY_QUESTIONS'">{{ 'REGISTRATION.FLOW.SECURITY_QUESTIONS' | translate }}</div>
    <div class="form-group">
      <!--<div class="title-text" [attr.translation-id]="'MANAGE.SETTINGS.USERS.USER_EDIT.SECURITY_QUESTION1'">{{ 'MANAGE.SETTINGS.USERS.USER_EDIT.SECURITY_QUESTION1' | translate }}</div>-->
      <div class="question-wrapper" [ngClass]="{ error: isErrorField(signUpErrorFields.questionOne) }">
        <regular-dropdown-list [items]="securityQuestions"
                               [colorScheme]="dropdownColorScheme.blue"
                               [selectedValue]="selectedQuestionOne"
                               [allowClear]="true"
                               (onItemSelected)="selectQuestion($event, 1)"></regular-dropdown-list>
      </div>
      <input class="input-class"
             [ngClass]="{ error: isErrorField(signUpErrorFields.answerOne) }"
             [(ngModel)]="questionOne && questionOne.value"
             (ngModelChange)="clearErrors()"
             type="text"
             [attr.translation-id]="'REGISTRATION.FLOW.ANSWER'"
              [attr.sh-id]="'security_question_1'"
             [attr.placeholder]="'REGISTRATION.FLOW.ANSWER' | translate" />
    </div>
    <div class="form-group">
      <div class="question-wrapper" [ngClass]="{ error: isErrorField(signUpErrorFields.questionTwo) }">
        <regular-dropdown-list [items]="securityQuestions"
                               [colorScheme]="dropdownColorScheme.blue"
                               [selectedValue]="selectedQuestionTwo"
                               [allowClear]="true"
                               (onItemSelected)="selectQuestion($event, 2)"></regular-dropdown-list>
      </div>
      <!--<div class="title-text" [attr.translation-id]="'MANAGE.SETTINGS.USERS.USER_EDIT.SECURITY_QUESTION2'">{{ 'MANAGE.SETTINGS.USERS.USER_EDIT.SECURITY_QUESTION2' | translate }}</div>-->
      <input class="input-class"
             [ngClass]="{ error: isErrorField(signUpErrorFields.answerTwo) }"
              [attr.sh-id]="'security_question_2'"
             [(ngModel)]="questionTwo && questionTwo.value"
             (ngModelChange)="clearErrors()"
             type="text"
             [attr.translation-id]="'REGISTRATION.FLOW.ANSWER'"
             [attr.placeholder]="'REGISTRATION.FLOW.ANSWER' | translate" />
    </div>
    <div class="errors-div" *ngIf="registrationService.registrationModel.error">
      <div class="error-text"
           [attr.translation-id]="'SYSTEM.MANAGEMENT.ERROR_BOLD: REGISTRATION.FLOW.ERROR.' + registrationService.registrationModel.error.error"><b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}:</b> {{ 'REGISTRATION.FLOW.ERROR.' + registrationService.registrationModel.error.error | translate }}</div>
    </div>
  </div>
  <div class="security-questions-buttons-wrapper">
    <button class="back-button" [attr.sh-id]="'back_button'" [attr.translation-id]="'REGISTRATION.FLOW.BACK'" (click)="navigateBack()">{{ 'REGISTRATION.FLOW.BACK' | translate }}</button>
    <button class="next-button" [attr.sh-id]="'next_button'" [attr.translation-id]="'REGISTRATION.FLOW.NEXT'" (click)="navigateNext()">{{ 'REGISTRATION.FLOW.NEXT' | translate }}</button>
  </div>
</div>
