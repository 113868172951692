import { Input, Output, EventEmitter, OnChanges, SimpleChanges, Directive } from '@angular/core';

import {
  CreateEventMode, CowEventMetadataBase, EventDetails, EventDetailsError,
} from 'src/app/services/animals/model/animal-events';

import { cloneDeep } from 'lodash';

@Directive()
export abstract class EventFormComponent<TMeta extends CowEventMetadataBase, TModel extends EventDetails> implements OnChanges  {

  @Input()
  public meta: TMeta;

  @Input()
  public mode: CreateEventMode;

  @Output()
  public modelChanged: EventEmitter<TModel> = new EventEmitter<TModel>();

  @Input()
  public model: TModel;

  @Input()
  public errorModel: EventDetailsError;

  public editModel: TModel;

  public CreateEventMode = CreateEventMode;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.model != null) {
      this.editModel = cloneDeep(this.model);
    }

    this.onChanges(changes);
  }

  protected onChanges(changes: SimpleChanges): void {}

  public emitModelChanged(model?: TModel): void {
    this.modelChanged.emit(model || this.editModel);
  }

  public get newbornErrorField() : string {
    if (this.errorModel != null &&
        this.errorModel.fieldName != null &&
        this.errorModel.fieldName.startsWith('newborns.')) {
      return  this.errorModel.fieldName.split('.')[1];
    }
    return '';
  }

  public isFieldNameWithError(fieldName: string): boolean {
    if (this.errorModel) {
      return this.errorModel.fieldName === fieldName;
    }
    return false;
  }
}

export enum EventFormFieldName {
  newGroupId = 'newGroupId',
  destinationGroup = 'destinationGroup'
}
