<div class="alert-section" sh-id="farm-alerts-content" saveDataEditSection [editAreaName]="editAreaName">
  <div class="section-header" translation-id="MANAGE.SETTINGS.ALERTS.SECTION_TITLE">{{ 'MANAGE.SETTINGS.ALERTS.SECTION_TITLE' | translate }}</div>
  <div class="section-content">
    <div class="alerts-section-description">
      <div class="description-text" translation-id="MANAGE.SETTINGS.ALERTS.SECTION_DESCRIPTION">{{ 'MANAGE.SETTINGS.ALERTS.SECTION_DESCRIPTION' | translate }}</div>
      <div class="error-section" *ngIf="error" sh-id="farm-alerts-content">
        <span class="error" translation-id="SYSTEM.MANAGEMENT.ERROR_BOLD">{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}: </span>
        <span class="error-description" [attr.translation-id]="'MANAGE.SETTINGS.ALERTS.ERROR.' + error.key">{{'MANAGE.SETTINGS.ALERTS.ERROR.' + error.key | translate }}</span>
      </div>
    </div>
    <div class="alerts-section-controls" [ngClass]="{ disabled: isFieldEditable() }" [attr.sh-id]="'alerts-section'">
      <div class="alerts-types-captions">
        <div class="alert-type">{{'MANAGE.SETTINGS.ALERTS.CAPTIONS.NOTIFICATION' | translate}}</div>
        <div class="alert-type">{{'MANAGE.SETTINGS.ALERTS.CAPTIONS.SMS' | translate}}</div>
        <div class="alert-type">{{'MANAGE.SETTINGS.ALERTS.CAPTIONS.EMAIL' | translate}}</div>
      </div>
      <div class="alerts-notification-config" *ngFor="let data of modelData" [ngClass]="{'invalid': checkAlertErrorsForField(data.key)}">
        <div class="notification-panel-container">
          <div [attr.sh-id]="'manage-settings-alerts-'+data.key" class="notification-title" [attr.translation-id]="'MANAGE.SETTINGS.ALERTS.' + data.key">
            {{ 'MANAGE.SETTINGS.ALERTS.' + data.key | translate }}
          </div>
          <notification-timing-plate [canEdit]="canEdit" [farmAlertConfigData]="data" (onNotificationTimingPlateClick)="onNotificationTimingPlateClick($event)"></notification-timing-plate>
        </div>
        <div class="notification-buttons-container">
          <div class="notification-btn" [ngClass]="{'active': data.value.email}" (click)="changeCategoryAlert(data.key, 'email')" [attr.sh-id]="'notification-btn-email-' + data.key">
            <div class="icon icon-email"></div>
          </div>
          <div class="notification-btn" [ngClass]="{'active': data.value.sms}" (click)="changeCategoryAlert(data.key, 'sms')" [attr.sh-id]="'notification-btn-sms-' + data.key">
            <div class="icon icon-sms"></div>
          </div>
          <div class="notification-btn" [ngClass]="{'active': data.value.mobile}" (click)="changeCategoryAlert(data.key, 'mobile')" [attr.sh-id]="'notification-btn-mobile-' + data.key">
            <div class="icon icon-mobile"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<notification-timing-dialog (onApply)="onDialogApply($event)" (onCancel)="onDialogCancel()"></notification-timing-dialog>
