import {Component, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {ShiftModel} from '../../../../../services/sorting-gate/model/shifts-model';
import {SortingGateService} from '../../../../../services/sorting-gate/sorting-gate.service';
import {ManageSettingsShiftsService} from '../../../../../services/manage/manage-settings-shifts.service';
import {ManageSettingsService} from '../../../../../services/manage/manage-settings.service';
import {UserPermissionsService} from '../../../../../services/auth/user-permissions.service';
import {EditBarService} from '../../../../../services/edit-bar/edit-bar.service';
import {SortingGateErrorsHandlerService} from '../../../../../services/sorting-gate/sorting-gate-errors-handler.service';

export type SlideStartTimeEventParams = {
  shift: ShiftModel;
  direction: SlideStartTimeDirection
};

export type ChangeNameEventParams = {
  shift: ShiftModel;
  name: string;
};

export type RemoveShiftEventParams = {
  shift: ShiftModel;
};

export enum SlideStartTimeDirection {
  Up = 'up',
  Down = 'down'
}

@Component({
  selector: 'manage-settings-shifts',
  templateUrl: './manage-settings-shifts.component.html',
  styleUrls: ['./manage-settings-shifts.component.scss']
})
export class ManageSettingsShiftsComponent implements OnInit, OnDestroy {

  public maxShiftLength: number = 6;

  private originalShifts: ShiftModel[];

  public shifts: ShiftModel[];

  public errorMessage: string;

  public readonly editAreaName: string = 'shifts';

  constructor(private sortingGateService: SortingGateService,
              private manageSettingsShiftsService: ManageSettingsShiftsService,
              private sortingGateErrorsHandlerService: SortingGateErrorsHandlerService,
              private manageSettingsService: ManageSettingsService,
              private readonly editBarService: EditBarService,
              private userPermissionsService: UserPermissionsService) {
  }

  public async ngOnInit() {
    const shiftsResponse = await this.sortingGateService.getShifts();
    this.originalShifts = _.cloneDeep(shiftsResponse.responseBody.shifts);
    this.shifts = _.cloneDeep(shiftsResponse.responseBody.shifts);
    this.editBarService.registerOnSaveFunction(null);
    this.editBarService.registerOnResetFunction(null);
  }

  public ngOnDestroy() {
    this.reset();
  }

  public async save() {
    if (this.isShiftsHasEmptyNames()) {
      this.errorMessage = 'SORTING_GATE.SERVER_ERRORS.ShiftNameIsEmpty';
      return;
    }

    const sendShiftsResponse = await this.sortingGateService.sendShifts(this.shifts);
    if (sendShiftsResponse.status === 200) {
      const getShiftsResponse = await this.sortingGateService.getShifts();
      this.originalShifts = _.cloneDeep(getShiftsResponse.responseBody.shifts);
      this.shifts = _.cloneDeep(getShiftsResponse.responseBody.shifts);
      this.editBarService.registerOnResetFunction(null);
      this.dirtyCheckShifts();
      this.clearError();
    } else {
      this.manageSettingsService.editBarErrorMessage
        = this.sortingGateErrorsHandlerService.handleHttpPromiseError(sendShiftsResponse.errorResponseBody);
    }
  }

  private isShiftsHasEmptyNames(): boolean {
    return this.shifts.some(shift => !shift.name);
  }

  public reset(): void {
    this.shifts = _.cloneDeep(this.originalShifts);
    this.editBarService.resetEditBar();
    this.clearError();
  }

  public clearError(): void {
    this.manageSettingsService.editBarErrorMessage = null;
    this.errorMessage = null;
  }

  public addShift(): void {
    const arrayOfNumberShiftNames: number[] = [];
    let newShiftIndex = this.shifts.length;
    let shiftName: number;
    let newShift: ShiftModel;

    this.shifts.forEach((shift: ShiftModel) => {
      shiftName = Number(shift.name);
      if (!isNaN(shiftName)) {
        arrayOfNumberShiftNames.push(shiftName);
      }
    });
    if (arrayOfNumberShiftNames.length) {
      newShiftIndex = Math.max(...arrayOfNumberShiftNames);
    }

    newShift = new ShiftModel();
    newShift.name =  (newShiftIndex + 1).toString();

    this.shifts = this.manageSettingsShiftsService.arrangeShifts(
      [...this.shifts, newShift] as ShiftModel[],
      'down',
      newShiftIndex - 1
    );
    this.dirtyCheckShifts();
  }

  public onSlideStartTime(event: SlideStartTimeEventParams): void {
    event.shift.startTime = event.direction === 'up' ?
      this.manageSettingsShiftsService.increaseStartTime(event.shift.startTime) :
      this.manageSettingsShiftsService.decreaseStartTime(event.shift.startTime);

    const index = this.shifts.indexOf(event.shift);
    this.shifts = this.manageSettingsShiftsService.arrangeShifts(
      this.shifts as ShiftModel[],
      event.direction,
      index
    );
    this.dirtyCheckShifts();
  }

  public onChangeName(event: ChangeNameEventParams): void {
    event.shift.name = event.name;
    this.clearError();
    this.dirtyCheckShifts();
  }

  public onRemoveShift(event: RemoveShiftEventParams): void {
    const index = this.shifts.indexOf(event.shift);
    this.shifts.splice(index, 1);

    this.shifts = this.manageSettingsShiftsService.arrangeShifts(
      this.shifts as ShiftModel[],
      'up',
      index
    );
    this.clearError();
    this.dirtyCheckShifts();
  }

  public shiftsIsEqual(): boolean {
    return _.isEqual(this.originalShifts, this.shifts);
  }

  private dirtyCheckShifts(): void {
    const noChangesDetected = _.isEqual(this.originalShifts, this.shifts);
    this.editBarService.registerOnSaveFunction(noChangesDetected ? null : this.save.bind(this), this.editAreaName);
    this.editBarService.registerOnResetFunction(noChangesDetected ? null : this.reset.bind(this));
  }

  public isFieldEditable() {
    return this.userPermissionsService && !this.userPermissionsService.hasUserPermissionsForEditManageSettings()
  }
}
