<div *ngIf="isOpen" class="manage-menu" outsideClickHandler [outsideClickIgnoreListComponents]="['menuItemManage']" (onOutsideClick)="closeManageMenu()"  @manageMenuTransition>
  <div class="manage-menu-header">
    <div class="title" [attr.translation-id]="'NAVIGATION.MANAGE'">{{ 'NAVIGATION.MANAGE' | translate }}</div>
    <div class="close-button" (click)="closeManageMenu()">
      <div class="close-icon"></div>
    </div>
    <div class="shadow"></div>
  </div>
  <div class="manage-menu-content">
    <div class="menu-item" [attr.sh-id]="'manage-menu-item-breeding'" (click)="navigateTo(ApplicationBundleRoutingMode.Breeding)" [attr.translation-id]="'NAVIGATION.BREEDING_OPERATION'">{{ 'NAVIGATION.BREEDING_OPERATION' | translate }}</div>
    <div class="menu-item" [attr.sh-id]="'manage-menu-item-finishing'" (click)="navigateTo(ApplicationBundleRoutingMode.Finishing)" [attr.translation-id]="'NAVIGATION.FINISHING'">{{ 'NAVIGATION.FINISHING' | translate }}</div>
  </div>
</div>
