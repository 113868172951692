<notification-dialog-template #dialogRef
                              [isEscapeCloseDialog]="true"
                              [modalWidth]="600"
                              [modalHeight]="230"
                              [theme]="NotificationDialogTheme.blue"
                              [shIdPostfix]="'newVersionSoftwareModalComponent'">
  <ng-container header>
    <div class="new-version-software-modal-dialog-header">
      <div class="new-version-software-modal-title-wrapper">
        <div class="new-version-software-modal-settings-icon"></div>
        <div class="new-version-software-modal-title" translation-id="DIALOG.NEW_VERSION_SOFTWARE.TITLE">
          {{ 'DIALOG.NEW_VERSION_SOFTWARE.TITLE' | translate }}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container body>
    <div class="new-version-software-modal-dialog-body">
      <div class="new-version-software-modal-form-group">
        <span class="new-version-software-modal-form-group-label" translation-id="DIALOG.NEW_VERSION_SOFTWARE.TEXT">
          {{ 'DIALOG.NEW_VERSION_SOFTWARE.TEXT' | translate }}
        </span>
      </div>
      <div class="new-version-software-modal-form-group">
        <span class="new-version-software-modal-form-group-label">&nbsp;</span>
      </div>
    </div>
  </ng-container>
</notification-dialog-template>
