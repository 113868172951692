<modal-dialog-template *ngIf="isOpen"
                       [isOpen]="isOpen"
                       [modalWidth]="512"
                       [modalHeight]="295"
                       (outsideModalClick)="close()">
  <div class="email-not-provided-dialog" [attr.sh-id]="'email_not_provided_dialog'">
    <div class="email-not-provided-dialog-header">
      <div class="email-not-provided-dialog-header-text" [attr.sh-id]="'SYSTEM.DATA_SHARING.DIALOGS.NO_EMAIL.TITLE'">
        <div class="email-not-provided-dialog-exclamation"></div>{{ 'SYSTEM.DATA_SHARING.DIALOGS.NO_EMAIL.TITLE' | translate }}
      </div>
      <div class="email-not-provided-dialog-cross" (click)="close()" [attr.sh-id]="'email_not_provided_dialog_close'"></div>
    </div>
    <div class="email-not-provided-dialog-body">
      <div class="email-not-provided-dialog-content">
        <span [attr.sh-id]="'SYSTEM.DATA_SHARING.DIALOGS.NO_EMAIL.CONTENT'">
          {{'SYSTEM.DATA_SHARING.DIALOGS.NO_EMAIL.CONTENT' | translate}}
        </span>
      </div>
      <div class="email-not-provided-dialog-proceed" (click)="onRightButtonClick()" [attr.sh-id]="'email_not_provided_dialog_proceed'">
        <span [attr.sh-id]="'SYSTEM.DATA_SHARING.DIALOGS.NO_EMAIL.BUTTON'">
          {{'SYSTEM.DATA_SHARING.DIALOGS.NO_EMAIL.BUTTON' | translate}}
        </span>
      </div>
    </div>
  </div>
</modal-dialog-template>
