import {Component, Inject, OnInit} from '@angular/core';
import {ServerConfig} from '../../../services/config/model/server-config';
import {ConfigService} from '../../../services/config/config.service';
import {WINDOW} from '../../../utils/window-utils';
import {TranslationService} from '../../../services/translations/translation.service';
import {GoogleAnalyticsService} from '../../../services/google-analytics/google-analytics.service';

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  public serverConfig: ServerConfig;

  constructor(@Inject(WINDOW) private readonly window: Window,
              private readonly translationService: TranslationService,
              private readonly configService: ConfigService,
              private readonly googleAnalyticsService: GoogleAnalyticsService) {

  }

  public ngOnInit() {
    this.serverConfig = this.configService.serverConfig;
  }

  public get displayContactUs() : boolean {
    return this.serverConfig && this.serverConfig.contactUsUrl != null;
  }

  public openContactUs() {
    let editableURL: string = this.serverConfig.contactUsUrl;
    editableURL = editableURL.replace('[DEVICE]', 'Web');
    editableURL = editableURL.replace('[ORIGIN]', 'Contact');
    editableURL = editableURL.replace('[LOCALE]', this.translationService.selectedLanguage);
    editableURL = editableURL.replace('[LANG]', this.translationService.selectedLanguage.split('-')[0]);
    this.googleAnalyticsService.send('outbound_link', 'click', 'contact_us');
    window.open(editableURL, '_blank');
  }
}
