import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {DatatableComponent, TableColumn} from '@swimlane/ngx-datatable';
import {
  getIconCssClassByReportRowActionType,
  IReport,
  IReportColoring,
  IReportColumnDefinition,
  IReportMetaFilterValueColumn,
  IReportRowActions,
  IReportRowColoring,
  ReportColoringType,
  ReportColumnFilterType,
  ReportColumnSortType,
  ReportColumnType,
  ReportDataRow,
  ReportNameEnum,
  ReportRowActionType,
  SyncStatusDatetimeColumnName,
  SyncStatusStatusCalculation
} from '../../../../services/reports/model/report.interface';
import {
  FilterBooleanQuery,
  FilterChecklistQuery,
  FilterNumericQuery,
  FilterSearchQuery,
  IFilterQuery,
  ReportQuery
} from '../../../../services/reports/model/report-query.interface';
import {DateTimeFormatEnum} from 'src/app/common/pipes/epoch-date-time.pipe';
import {ReportsService} from '../../../../services/reports/reports.service';
import {ConfigService} from '../../../../services/config/config.service';
import {WINDOW} from '../../../../utils/window-utils';
import {GroupsService} from '../../../../services/groups/groups.service';
import {AnimalsService} from '../../../../services/animals/animals.service';
import {ScreenCalculationUtils} from '../../../../utils/screen-calculation-utils';
import {RowActionClickDetails, RowActionsPopupItem} from '../../row-actions-popup/row-actions-popup.component';
import {ReportActionsService} from '../../../../services/reports/report-actions.service';
import {IReportViewContext} from '../../../../services/reports/model/report-view-context';
import {debounce} from 'lodash';
import {SystemService} from '../../../../services/system/system.service';
import {SearchService} from '../../../../services/search/search.service';
import {TranslationService} from '../../../../services/translations/translation.service';
import {LoadingIconService} from "../../../../services/loading-icon/loading-icon.service";

export enum ReportValueType {
  Plain = 'Plain',
  Trend = 'Trend',
  Percentage = 'Percentage',
  AnimalId = 'AnimalId',
  LactationStatus = 'LactationStatus',
  DateFormat = 'DateFormat',
  Duration = 'Duration',
  HoursMinutesDuration = 'HoursMinutesDuration',
  Localization = 'Localization',
  DynamicLocalization = 'DynamicLocalization',
  BreedingWindow = 'BreedingWindow',
  Boolean = 'Boolean',
  AnimalsToInspectReasons = 'AnimalsToInspectReasons',
  SortingReason = 'SortingReason',
  Sorted = 'Sorted'
}

interface IReportValueColumn extends TableColumn {
  details: IReportColumnDefinition,
  valueType: ReportValueType
}

interface IBottomBorderCss {
  offsetLeft?: number;
  offsetRight?: number;
}

export class ReportGridValueCellClickDetails {
  public columnKey: string;
  public row: ReportDataRow;
}

export enum PantingTrendType {
  increase = 'Increase',
  decrease = 'Decrease',
  stayTheSame = 'StayTheSame'
}

@Component({
  selector: 'report-grid',
  templateUrl: './report-grid.component.html',
  styleUrls: ['./report-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportGridComponent implements OnInit, OnChanges, OnDestroy {

  private static readonly HeaderHeightWithFiltering:number = 75;

  private static readonly HeaderHeightWithoutFiltering:number = 40;

  @ViewChild('datatable', {static: true})
  public datatable: DatatableComponent;

  @ViewChild('valueColumn', {static: true})
  // tslint:disable-next-line:no-any
  public valueColumn: TemplateRef<any>;

  @ViewChild('selectionHeaderColumn', {static: true})
  // tslint:disable-next-line:no-any
  public selectionHeaderColumn: TemplateRef<any>;

  @ViewChild('clearFiltersColumn', {static: true})
  // tslint:disable-next-line:no-any
  public clearFiltersColumn: TemplateRef<any>;

  @ViewChild('selectionValueCell', {static: true})
  // tslint:disable-next-line:no-any
  public selectionValueCell: TemplateRef<any>;

  @ViewChild('valueCell', {static: true})
  // tslint:disable-next-line:no-any
  public valueCell: TemplateRef<any>;

  @ViewChild('actionsCell', {static: true})
  // tslint:disable-next-line:no-any
  public actionsCell: TemplateRef<any>;

  @ViewChild('ngxDatatableContainer', {static: true})
  public ngxDatatableContainer: ElementRef;

  @Input()
  public report: IReport;

  @Input()
  public isReportGridShownInCardPopup: boolean = false;

  @Input()
  public reportQuery: ReportQuery;

  @Input()
  public isClearFilterIconSmall: boolean = false;

  @Input()
  public rowHeight: number;

  @Input()
  public showExtendedColumns: boolean = false;

  @Input()
  public reportContext: IReportViewContext;

  @Input()
  public bottomBorderCss?: IBottomBorderCss;

  @Input()
  public useRelativePosition: boolean;

  @Output()
  public onReportQueryChanged = new EventEmitter();

  @Output()
  public onReportGridValueCellClick = new EventEmitter<ReportGridValueCellClickDetails>();

  public DateTimeFormatEnum = DateTimeFormatEnum;

  public ReportValueType = ReportValueType;

  public ReportColumnFilterType = ReportColumnFilterType;

  public rows: ReportDataRow[] = [];

  public columns: TableColumn[] = [];

  public selectedColumn: IReportValueColumn;

  public selectedFilter: IFilterQuery;

  public initGrid: boolean = true;

  public isLoading: boolean;

  private openFillterSection: boolean;

  private readonly rowActionsColumnName: string = 'RowActions';

  public columnActionsHeight: number = 56;

  public columnActionsWidth: number = 192;

  public popupCardHeader: number = 60;

  public popupCardNavMenuEndWidth: number = 225;

  public headerHeight: number;

  public PantingTrendType = PantingTrendType;

  // tslint:disable-next-line:no-any
  public openOneRowAction: any;

  private _datatableContainerWidthPixels: number;

  private _datatableContainerHeightPixels: number;

  constructor(@Inject(WINDOW) private _window: Window,
              public elementRef: ElementRef,
              public configService: ConfigService,
              public reportsService: ReportsService,
              public systemService: SystemService,
              public searchService: SearchService,
              private translationService: TranslationService,
              private readonly loadingIconService: LoadingIconService,
              public animalsService: AnimalsService,
              private groupsService: GroupsService,
              private reportActionsService: ReportActionsService,
              private readonly changeDetector: ChangeDetectorRef ) {

  }

  public async ngOnInit() {

  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.report) {
      return;
    }
    // if grid is not init and the grid changed from extended report to regular report or regular report to extended report
    if (!this.initGrid && changes.showExtendedColumns && changes.showExtendedColumns.currentValue !== changes.showExtendedColumns.previousValue) {
      this.reloadColumns();
      return;
    }
    // add coloring to rows in report
    if (this.initGrid) {
      this.columns = [];
      if(this.reportsService.reportIsSortableOrFilterable(this.report)) {
        this.headerHeight = ReportGridComponent.HeaderHeightWithFiltering;
      } else {
        this.headerHeight = ReportGridComponent.HeaderHeightWithoutFiltering;
      }
      if (this.reportContext.getRowSelectionState() != null) {
        let selectableColumn: TableColumn = {
          name: '',
          headerTemplate: this.selectionHeaderColumn,
          cellTemplate: this.selectionValueCell,
          flexGrow: 0,
          minWidth: 75,
          resizeable: false
        };
        this.columns.push(selectableColumn);
      }
      let filteredColumns = ReportsService.filterVisibleColumns(this.report.columns, this.showExtendedColumns);
      filteredColumns.forEach(col => {
        let valueType = this.getReportValueType(col);
        let valueColumn: IReportValueColumn = {
          name: col.key,
          headerTemplate: this.valueColumn,
          cellTemplate: this.valueCell,
          details: col,
          valueType: valueType,
          flexGrow: this.getFlexGrow(col),
          resizeable: false
        };
        this.columns.push(valueColumn);
      });
      this.columns.push({
        name: this.rowActionsColumnName,
        headerTemplate: this.clearFiltersColumn,
        cellTemplate: this.actionsCell,
        flexGrow: 0.3,
        minWidth: 79,
        resizeable: false
      });
      this.rows = this.report.rows.map(reportDataRow => {
        this.initReportDataRow(reportDataRow);
        return reportDataRow;
      });
      this.initGrid = false;
    } else {
      // tslint:disable-next-line:no-any
      let nextPageRows = this.report.rows.map(reportDataRow => {
        this.initReportDataRow(reportDataRow);
        return reportDataRow;
      });
      this.rows = [...this.rows, ...nextPageRows];
      if(this.reportContext.getRowSelectionState() != null &&
        this.reportContext.getRowSelectionState().performingSelectAllRows) {
        this.reportContext.getRowSelectionState().selectRows(this.rows.map(row => row.rowId));
        this.reportContext.getRowSelectionState().performingSelectAllRows = false;
      }
    }
    this.loadingIconService.hide();
    this.isLoading = false;
  }

  public ngOnDestroy(): void {
    this.loadingIconService.hide();
  }

  private reloadColumns() {
    this.columns = [];
    if(this.reportsService.reportIsSortableOrFilterable(this.report)) {
      this.headerHeight = ReportGridComponent.HeaderHeightWithFiltering;
    } else {
      this.headerHeight = ReportGridComponent.HeaderHeightWithoutFiltering;
    }
    if (this.reportContext.getRowSelectionState() != null) {
      let selectableColumn: TableColumn = {
        name: '',
        headerTemplate: this.selectionHeaderColumn,
        cellTemplate: this.selectionValueCell,
        flexGrow: 0,
        minWidth: 75,
        resizeable: false
      };
      this.columns.push(selectableColumn);
    }
    let filteredColumns = ReportsService.filterVisibleColumns(this.report.columns, this.showExtendedColumns);
    filteredColumns.forEach(col => {
      let valueType = this.getReportValueType(col);
      let valueColumn: IReportValueColumn = {
        name: col.key,
        headerTemplate: this.valueColumn,
        cellTemplate: this.valueCell,
        details: col,
        valueType: valueType,
        flexGrow: this.getFlexGrow(col),
        resizeable: false
      };
      this.columns.push(valueColumn);
    });
    this.columns.push({
      name: this.rowActionsColumnName,
      headerTemplate: this.clearFiltersColumn,
      cellTemplate: this.actionsCell,
      flexGrow: 0.3,
      minWidth: 79,
      resizeable: false
    });
  }

  private initReportDataRow(row:ReportDataRow) : ReportDataRow {
    row.actions = this.getActionsList(row.rowNumber);
    row.gridViewState = this.reportContext.getGridViewState();
    row.rowDefaultHeight = this.rowHeight;
    this.setRowColor(row);
    this.setRowColumnColor(row);
    this.reportsService.setRowNavigation(this.report, row);
    return row;
  }

  private setRowColor(row:ReportDataRow) {
    if(this.report.rowColoring == null){
      return;
    }
    let rowColoring = this.report.rowColoring.find((rowColoring: IReportRowColoring) => rowColoring.rowNumber === row.rowNumber);
    if(rowColoring == null) {
      row.rowColor = null;
    } else {
      row.rowColor = rowColoring.colorType.toLocaleLowerCase();
    }
  }

  private setRowColumnColor(row:ReportDataRow) {
    if(this.report.coloring == null){
      return;
    }
    let rowColumnColorings = this.report.coloring.filter((rowColumnColoring: IReportColoring) => rowColumnColoring.rowNumber === row.rowNumber);
    if(rowColumnColorings == null) {
      row.rowColumnColorings = null;
    } else if (rowColumnColorings.length === 1 && rowColumnColorings[0].columnKey === null) {
      row.rowColor = rowColumnColorings[0].colorType.toLocaleLowerCase();
    } else {
      row.rowColumnColorings = new Map<string, ReportColoringType>();
      rowColumnColorings.forEach(value => {
        row.rowColumnColorings.set(value.columnKey, value.colorType);
      });
    }
  }

  public get datatableContainerHeightPixels() : number {
    let currentHeight = (<HTMLElement>this.ngxDatatableContainer.nativeElement).getBoundingClientRect().height;
    if(currentHeight != this._datatableContainerHeightPixels){
      this._datatableContainerHeightPixels = currentHeight;
      this.changeDetector.detectChanges();
    }
    return this._datatableContainerHeightPixels;
  }

  public get datatableContainerWidthPixels() : number {
    let currentWidth = (<HTMLElement>this.ngxDatatableContainer.nativeElement).getBoundingClientRect().width;
    if(currentWidth != this._datatableContainerWidthPixels){
      this._datatableContainerWidthPixels = currentWidth;
      this.changeDetector.detectChanges();
    }
    return this._datatableContainerWidthPixels;
  }

  public getRowColoring = (row:ReportDataRow) => {
    if (row.hasOwnProperty(SyncStatusDatetimeColumnName.SyncStatusStatusCalculation) && row[SyncStatusDatetimeColumnName.SyncStatusStatusCalculation]) {
      const status = row[SyncStatusDatetimeColumnName.SyncStatusStatusCalculation];
      if (status === SyncStatusStatusCalculation.Processing) {
        return 'cell-color-sync-status-Low';
      }
      if ([SyncStatusStatusCalculation.CullingThresholdError, SyncStatusStatusCalculation.TagRemovalThresholdError,
        SyncStatusStatusCalculation.TimeoutError, SyncStatusStatusCalculation.InterruptedError,
        SyncStatusStatusCalculation.LimitsExceedError, SyncStatusStatusCalculation.MultipleFullSyncError,
        SyncStatusStatusCalculation.AllAnimalsInSyncFailed, SyncStatusStatusCalculation.NoAnimalsInSyncError,
        SyncStatusStatusCalculation.MissingExternalIdsError, SyncStatusStatusCalculation.ServerStillStartingError].includes(status)) {
        return 'cell-color-sync-status-High';
      }
    }
    if (row.rowColor != null) {
      return `cell-color-${row.rowColor}`;
    } else {
      return '';
    }
  };

  public getBottomBorderClass(): string {
    let result = '';
    if (this.bottomBorderCss) {
      result = `report__bottom-border`;
      if (Number(this.bottomBorderCss.offsetLeft)) {
        result = `${result} report__bottom-border_left-${Number(this.bottomBorderCss.offsetLeft)}`;
      }
      if (Number(this.bottomBorderCss.offsetRight)) {
        result = `${result} report__bottom-border_right-${Number(this.bottomBorderCss.offsetRight)}`;
      }
    }
    return result;
  }

  public getLinkClassForContent(row): string {
    const rowId = this.getRowId(row);
    return rowId !== null ? 'value-cell__content-link' : '';
  }

  public getRowId(row): number|null {
    const columnWithID = this.report && this.report.columns && this.report.columns
      .find((column) => column.type === ReportColumnType.HiddenRef && column.key != 'TagDatabaseIDCalculation');
    const rowId = row && columnWithID && Number(row[columnWithID.key]);
    return (rowId || rowId === 0) ? rowId : null;
  }

  public onGridScroll = debounce((offsetY: number) => {
    this.rows = [...this.rows];
    if (this.report.meta.rowTotalAfterFilter <= this.rows.length) {
      return;
    }
    if (!this.isLoading && ScreenCalculationUtils.isScrollAtEndOfScreen(this.elementRef,
      offsetY,
      this.rows.length,
      this.rowHeight,
      this.headerHeight)) {
      this.isLoading = true;
      this.reportQuery.offset += this.reportQuery.limit;
      this.reportQuery.pastReportTime = this.report.meta.reportTime;
      this.reportQuery.reportUid = this.report.meta.reportUid;
      this.reportQuery.isRefresh = null;
      this.onReportQueryChanged.emit();
    }
  });

  // tslint:disable-next-line:no-any
  public isColumnSortedUp(columnName: any) {
    return this.reportQuery.sort.has(columnName) && this.reportQuery.sort.get(columnName) === ReportColumnSortType.ASC;
  }

  // tslint:disable-next-line:no-any
  public isColumnSortedDown(columnName: any) {
    return this.reportQuery.sort.has(columnName) && this.reportQuery.sort.get(columnName) === ReportColumnSortType.DESC;
  }

  public isFilteredByThisColumn(columnName: string): boolean {
    return this.reportQuery.filter.has(columnName) && (!this.openFilterSection || columnName != this.selectedColumn?.name)
  }

  public get isSelectedColumnSorted(): boolean {
    return this.reportQuery.sort.has(this.selectedColumn.name);
  }

  public get columnActionsTopPosition(): number {
    if (this.isReportGridShownInCardPopup) {
      return this.elementRef.nativeElement.getBoundingClientRect().top - this.columnActionsHeight - this.popupCardHeader;
    } else {
      return this.elementRef.nativeElement.getBoundingClientRect().top - this.columnActionsHeight;
    }
  }

  public get columnActionsLeftPosition(): number {
    if (!this.selectedColumn) {
      return this.elementRef.nativeElement.getBoundingClientRect().left;
    } else {
      let leftPosition: number;
      if (!this.isReportGridShownInCardPopup) {
        leftPosition = this.elementRef.nativeElement.getBoundingClientRect().left;
      } else {
        leftPosition = this.popupCardNavMenuEndWidth;
      }
      for (let colIndex = 0; colIndex < this.columns.length; colIndex++) {
        if (this.columns[colIndex].name !== this.selectedColumn.name) {
          leftPosition += this.columns[colIndex].width;
        } else {
          leftPosition += (this.columns[colIndex].width / 2) - (this.columnActionsWidth / 2);
          let leftReference = this.elementRef.nativeElement.getBoundingClientRect().left;
          if(this.columns[0].headerTemplate == this.selectionHeaderColumn) {
            leftReference += this.columns[0].width;
          }
          if(leftPosition < leftReference) {
              leftPosition = leftReference;
          } else if(leftPosition + this.columnActionsWidth > this.elementRef.nativeElement.getBoundingClientRect().right) {
            leftPosition = this.elementRef.nativeElement.getBoundingClientRect().right - this.columnActionsWidth;
          }
          break;
        }
      }
      return leftPosition;
    }
  }

  public get selectedRowsCount() : number {
    return this.reportContext.getRowSelectionState().selectedRowsCount;
  }

  public sortUpBySelectedColumn() {
    this.reportQuery.sort.set(this.selectedColumn.name, ReportColumnSortType.ASC);
    this.reloadGrid(false, this.report.meta.reportTime,true);
  }

  public sortDownBySelectedColumn() {
    this.reportQuery.sort.set(this.selectedColumn.name, ReportColumnSortType.DESC);
    this.reloadGrid(false, this.report.meta.reportTime,true);
  }

  public removeSortingBySelectedColumn() {
    this.reportQuery.sort.delete(this.selectedColumn.name);
    this.reloadGrid(false, this.report.meta.reportTime,true);
  }

  // tslint:disable-next-line:no-any
  public columnClick(column: IReportValueColumn) {
    if (!column.details.isFilterEnable  && !column.details.isSortingEnable) {
      return;
    }
    if (this.openFilterSection) {
      this.revertFilter();
    }
    if (this.selectedColumn != null &&
      this.selectedColumn.name === column.name) {
      this.selectedColumn = null;
      this.selectedFilter = null;
    } else {
      this.selectedColumn = column;
      this.initSelectedFilter();
    }
  }

  public clearFilters() {
    if (this.reportQuery.sort.size > 0 || this.reportQuery.filter.size > 0) {
      this.reportQuery.sort.clear();
      this.reportQuery.filter.clear();
      this.openFilterSection = false;
      this.reloadGrid(false, this.report.meta.reportTime,true);
    }
  }

  public getIconForStatus(status: string) {
    let iconStringToReturn: string = null;
    if (status === 'Heifer' && this.configService.serverConfig.farmMode === 'Dairy') {
      iconStringToReturn = 'lactation-status-Heifer';
    } else if (status === 'Heifer' && this.configService.serverConfig.farmMode === 'Beef') {
      iconStringToReturn = 'lactation-status-Heifer-Beef';
    } else {
      iconStringToReturn = 'lactation-status-' + status;
    }
    return iconStringToReturn;
  }

  public getActionsList(rowNumber) : RowActionsPopupItem[] {
    // tslint:disable-next-line:no-any
    let rowActions = this.report.rowActions.find((row: IReportRowActions) => row.rowNumber === rowNumber);
    if(rowActions == null) {
      return null;
    }
    return rowActions.actions.length > 0 ? rowActions.actions.map(ra => {
      let rowActionPopupItem = new RowActionsPopupItem();
      rowActionPopupItem.action = ra.type;
      rowActionPopupItem.iconCssClass = getIconCssClassByReportRowActionType(ra.type as ReportRowActionType);
      rowActionPopupItem.displayValue = this.getActionText(ra.type);
      rowActionPopupItem.shIdRowIdentifier = rowNumber.toString();
      return rowActionPopupItem;
    }) : null;
  }

  public getActionText(type:ReportRowActionType) {
    switch (type) {
      case ReportRowActionType.Disable:
        return 'REPORTS.GRID.IGNORE_MAINTENANCE_CALL';
      case ReportRowActionType.Enable:
        return 'REPORTS.GRID.UN_IGNORE_MAINTENANCE_CALL';
      case ReportRowActionType.AssignScrTag:
        return `REPORTS.GRID.ACTIONS_LIST.ASSIGN_TAG`;
      case ReportRowActionType.DoNotBreedOn:
        return 'REPORT.GRID.ACTIONS_LIST.SET_DNB';
      case ReportRowActionType.Abortion:
        return 'REPORT.GRID.SET_ABORTION';
      case ReportRowActionType.PregnancyCheck:
        return 'REPORT.GRID.SET_PREGNANCY_CHECK';
      case ReportRowActionType.Breed:
        return 'REPORT.GRID.ACTIONS_LIST.SET_BREEDING';
      case ReportRowActionType.SendToSortingGate:
        return 'REPORT.GRID.ACTIONS_LIST.SEND_TO_SORTING_GATE';
      case ReportRowActionType.Cull:
        return 'MANAGE.COWS.ACTIVE.ACTION.CULL_ANIMAL';
      case ReportRowActionType.RemoveCulling:
        return 'MANAGE.COWS.ACTIVE.ACTION.REMOVE_CULLING_ANIMAL';
      case ReportRowActionType.Remove:
        return 'MANAGE.GROUPS.TABLE.ACTION.DELETE';
      case ReportRowActionType.Edit:
        return 'MANAGE.GROUPS.TABLE.ACTION.EDIT';
      default:
        return null;
    }
  }

  public openRowAction(id) {
    this.openOneRowAction = id;
  }

  public closeRowAction() {
    this.openOneRowAction = null;
  }

  public async rowActionClick(rowAction:RowActionClickDetails, row:ReportDataRow) {
    await this.reportActionsService.performReportRowAction(this.reportContext, row, <ReportRowActionType>rowAction.rowActionPopupItem.action, this.report);
  }

  public get openFilterSection(): boolean {
    return this.openFillterSection;
  }

  public set openFilterSection(value: boolean) {
    this.openFillterSection = value;
    setTimeout(() => {
      this._window.dispatchEvent(new Event('resize'));
    });
  }

  public applyFilter() {
    this.reportQuery.filter.set(this.selectedColumn.name, this.selectedFilter);
    this.selectedFilter = null;
    this.reloadGrid(false, this.report.meta.reportTime,true);
  }

  public removeFilter() {
    this.revertFilter();
    this.reloadGrid(false, this.report.meta.reportTime,true);
  }

  public revertFilter() {
    this.reportQuery.filter.delete(this.selectedColumn.name);
    this.openFilterSection = false;
    this.createSelectedFilter();
  }

  public get selectedReportMetaFilterValueColumn(): IReportMetaFilterValueColumn {
    return this.report.meta.filterValues.find(value => value.calculationName == this.selectedColumn.name);
  }

  public initSelectedFilter() {
    if (this.reportQuery.filter.has(this.selectedColumn.name)) {
      this.selectedFilter = this.reportQuery.filter.get(this.selectedColumn.name);
    } else {
      this.createSelectedFilter();
    }
  }

  // tslint:disable-next-line:no-any
  public reportGridValueCellClick(column: any, row: ReportDataRow) {
    const ignoreColumns: ReportValueType[] = [
      ReportValueType.BreedingWindow,
      ReportValueType.AnimalsToInspectReasons,
    ];
    if(ignoreColumns.includes(column.valueType)) {
      return;
    }
    let reportGridValueCellClickDetails = new ReportGridValueCellClickDetails();
    if(column.details != null) {
      reportGridValueCellClickDetails.columnKey = column.details.key;
    }
    reportGridValueCellClickDetails.row = row;
    this.onReportGridValueCellClick.emit(reportGridValueCellClickDetails);
  }

  public async reloadGrid(isRefresh:boolean, pastReportTime:number, resetOffset:boolean) {
    // this.isLoading = true;
    this.loadingIconService.show();
    this.initReportGrid();
    this.columns = [];
    this.rows = [];
    if(resetOffset) {
      this.reportQuery.offset = 0;
    }
    if(isRefresh) {
      this.reportQuery.isRefresh = true;
      this.reportQuery.reportUid = null;
    } else {
      this.reportQuery.isRefresh = null;
      if(this.report != null) {
        this.reportQuery.reportUid = this.report.meta.reportUid;
      } else {
        this.reportQuery.reportUid = null;
      }
    }
    this.reportQuery.pastReportTime = pastReportTime;
    await this.onReportQueryChanged.emit();
  }

  // tslint:disable-next-line:no-any
  public getColoringClass(row: ReportDataRow, column: TableColumn, value: any): ReportColoringType | string {
    if(row.rowColumnColorings != null &&
      row.rowColumnColorings.has(column.name)){
      return row.rowColumnColorings.get(column.name);
    }
    return '';
  }

  public initReportGrid() {
    this.openFilterSection = false;
    this.selectedColumn = null;
    this.selectedFilter = null;
    this.initGrid = true;
  }

  public toggleSelectedRow(row:ReportDataRow) {
    this.reportContext.getRowSelectionState().toggleRowSelection(row.rowId);
  }

  public isRowSelected(row: ReportDataRow) : boolean {
    return this.reportContext.getRowSelectionState().isRowSelected(row.rowId)
  }

  public toggleSelectAll() {
    let visibleRowIds: number[] = this.rows.map(row => row.rowId);
    const isFilterEnabled = this.reportQuery.filter != null && this.reportQuery.filter.size > 0;
    if (this.reportContext.getRowSelectionState().isAllRowsSelected(visibleRowIds)) {
      this.reportContext.getRowSelectionState().unselectRows(visibleRowIds);
    } else if (isFilterEnabled) {
      this.reportContext.getRowSelectionState().selectRows(visibleRowIds);
    } else {
      this.reportContext.getRowSelectionState().performingSelectAllRows = true;
      this.loadingIconService.show();
      this.reportQuery.offset = this.reportQuery.offset + this.reportQuery.limit;
      this.reportQuery.limit = this.report.meta.rowTotalAfterFilter;
      this.reportQuery.pastReportTime = this.report.meta.reportTime;
      this.reportQuery.reportUid = this.report.meta.reportUid;
      this.reportQuery.isRefresh = null;
      this.onReportQueryChanged.emit();
    }
  }

  public getRowHeight(row : ReportDataRow) : number {
    let rowExpandedState = row.gridViewState.getRowExpandedState(row);
    if(rowExpandedState == null) {
      return row.rowDefaultHeight;
    }
    else {
      return rowExpandedState.expandedHeight;
    }
  }

  public toggleBreedingWindow(row : ReportDataRow)  {
    let rowExpandedState = row.gridViewState.getRowExpandedState(row);
    if(rowExpandedState == null) {
      row.gridViewState.clearExpandedRows();
      row.gridViewState.setRowExpanded(row, {
        expandedHeight: 120
      });
    } else {
      row.gridViewState.removeExpandedRow(row);
    }
    this.rows = [...this.rows];
  }

  public onRowActionsPopupOpen(row: ReportDataRow): void {
    row.gridViewState.clearExpandedRows();
    this.toggleReasonDetails();
  }

  public toggleReasonDetails()  {
    this.rows = [...this.rows];
  }

  public isBreedingWindowExpanded(row : ReportDataRow) {
    return row.gridViewState.getRowExpandedState(row) != null;
  }

  public isSyncStatusDateTimeCell(row: ReportDataRow, column: TableColumn & {valueType: ReportValueType, name: string}): boolean {
    return column.valueType === ReportValueType.DateFormat && [
      SyncStatusDatetimeColumnName.SyncStatusReceiveTimeCalculation as string,
      SyncStatusDatetimeColumnName.SyncStatusProcessStartTimeCalculation as string
    ].includes(column.name);
  }

  // Verify if selectable report line exists in report and create sh-id accordingly
  public getAltShIdForTableCellByColumnId(row:ReportDataRow, column:IReportValueColumn ){
    if (this.columns[0].name==""){
      return column.details.key + '-' + row[this.columns[1].name];
    }
    return column.details.key + '-' + row[this.columns[0].name];
  }

  public isSyncSummaryDetailsButtonShown(row: ReportDataRow): boolean {
    if (this.report.meta.reportName === ReportNameEnum.HerdManagmentSyncSummary) {
      switch (row[SyncStatusDatetimeColumnName.SyncStatusStatusCalculation]) {
        case SyncStatusStatusCalculation.SucceededWithWarnings:
        case SyncStatusStatusCalculation.AllAnimalsInSyncFailed:
        case SyncStatusStatusCalculation.PartialSuccess: {
          return true;
        }
        default: {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  public columnHasFilterOrSort(reportColumn: IReportValueColumn) : boolean {
    return (reportColumn.details.isFilterEnable &&
            reportColumn.details.filterType != ReportColumnFilterType.None) ||
            reportColumn.details.isSortingEnable;
  }

  private getReportValueType(reportColumn: IReportColumnDefinition): ReportValueType {
    if (reportColumn.type == ReportColumnType.DateTime) {
      if (reportColumn.webMetaType == 'Duration') {
        return ReportValueType.Duration;
      } else {
        return ReportValueType.DateFormat;
      }
    } else if (reportColumn.key === 'PantingTrendCalculation' && reportColumn.webMetaType === 'Trend') {
      return ReportValueType.Trend;
    } else if (reportColumn.key === 'PercentOfPantingCowsCalculation' || reportColumn.key === 'PercentOfRuminatingCowsCalculation') {
      return ReportValueType.Percentage;
    } else if (reportColumn.key === 'DistressDurationCalculation') {
      return ReportValueType.HoursMinutesDuration;
    } else if (reportColumn.mobileMetaType == 'LactationStatus') {
      return ReportValueType.LactationStatus;
    } else if (reportColumn.key == 'AnimalIDCalculation') {
      return ReportValueType.AnimalId;
    } else if (reportColumn.key == 'SortedCalculation') {
        return ReportValueType.Sorted;
    } else if (reportColumn.type == ReportColumnType.Localization) {
      return ReportValueType.Localization;
    } else if (reportColumn.type == ReportColumnType.DynamicLocalization) {
      return ReportValueType.DynamicLocalization;
    } else if (reportColumn.webMetaType == 'InseminationWindow') {
      return ReportValueType.BreedingWindow;
    } else if (reportColumn.filterType == ReportColumnFilterType.Boolean) {
      return ReportValueType.Boolean;
    } else if (reportColumn.type == ReportColumnType.AnimalsToInspectReasons) {
      return ReportValueType.AnimalsToInspectReasons;
    } else if (reportColumn.type == ReportColumnType.SortingReason) {
      return ReportValueType.SortingReason;
    }
    return ReportValueType.Plain;
  }

  public showPantingTrendIconByType(rowValue: string, pantingTrendToDisplay: PantingTrendType) {
    return rowValue === pantingTrendToDisplay;
  }

  private getFlexGrow(col: IReportColumnDefinition): number {
    if(col.width == null ||
      col.width == 0) {
      return 1;
    } else {
      return col.width / 10;
    }
  }

  private createSelectedFilter() {
    switch (this.selectedColumn.details.filterType) {
      case ReportColumnFilterType.CheckList: {
        this.selectedFilter = new FilterChecklistQuery();
        break;
      }
      case ReportColumnFilterType.Boolean: {
        this.selectedFilter = new FilterBooleanQuery();
        break;
      }
      case ReportColumnFilterType.Search: {
        this.selectedFilter = new FilterSearchQuery();
        break;
      }
      case ReportColumnFilterType.Numeric:
      case ReportColumnFilterType.DateTime: {
        this.selectedFilter = new FilterNumericQuery();
        break;
      }
    }
  }

  private columnIsFilteredOrSorted(columnName: string) {
    return this.isFilteredByThisColumn(columnName)||
      this.isColumnSortedUp(columnName) ||
      this.isColumnSortedDown(columnName)
  }
}
