import {Component, OnInit, ViewChild} from '@angular/core';
import {IDialog} from '../../../services/dialogs/model/dialog.model';
import {NotificationDialogTemplateComponent, NotificationDialogTheme} from '../../../common/components/dialogs/notification-dialog-template/notification-dialog-template.component';
import {Subscription} from 'rxjs';
import {AnimalEventsService, IRemoveAnimalEventErrorDialogDetailsModel} from '../../../services/animals/animal-events.service';

@Component({
  selector: 'delete-event-error-dialog',
  templateUrl: './delete-event-error-dialog.component.html',
  styleUrls: ['./delete-event-error-dialog.component.scss']
})
export class DeleteEventErrorDialogComponent implements IDialog, OnInit {

  @ViewChild('dialogRef', {static: true})
  public dialogRef: NotificationDialogTemplateComponent;

  public removeAnimalEventErrorDialogDetailsSubscription: Subscription;

  public NotificationDialogTheme = NotificationDialogTheme;

  public constructor(private animalEventsService: AnimalEventsService) {

  }

  public ngOnInit(): void {
    this.removeAnimalEventErrorDialogDetailsSubscription = this.animalEventsService.removeAnimalEventErrorDialogSubject
                                                                                   .subscribe((dialogDetails: IRemoveAnimalEventErrorDialogDetailsModel) => {
      if (dialogDetails.errors && dialogDetails.errors.length) {
        this.showDialog();
      }
    });
  }

  public showDialog() {
    this.dialogRef.isOpen = true;
  }
}
