<div class="edit-bar-frame" [style.display]="editBarVisibility ? 'flex' : 'none'">
  <div class="changes-notification-header">
    <span class="note" translation-id="MANAGE.SETTINGS.USERS.NOTE">{{ 'MANAGE.SETTINGS.USERS.NOTE' | translate }}: </span>
    <span translation-id="MANAGE.SETTINGS.USERS.YOU_CHANGED_SOME_THING">{{ 'MANAGE.SETTINGS.USERS.YOU_CHANGED_SOME_THING' | translate }}</span>
  </div>
  <div class="buttons" sh-id="system_edit_bar_buttons">
    <div class="btn btn-save" (click)="save()" sh-id="system_edit_bar_save_btn" [ngClass]="{'btn-save-unavailable': !isSaveAvailable}">
      <div class="icon icon-save" [ngClass]="{'icon-save-unavailable': !isSaveAvailable}"></div>
    </div>
    <div class="btn btn-cancel" (click)="reset($event)" sh-id="system_edit_bar_reset_btn">
      <div class="icon icon-x-button" [ngClass]="{'icon-x-button-unavailable': !isResetAvailable}"></div>
    </div>
  </div>
</div>
