import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ROW_ACTION_TRANSITIONS} from '../../animations/animations';
import {IconCssClass} from "../../../services/reports/model/report.interface";
import {ClickPressEventsService} from '../../../services/click-press-events/click-press-events.service';
import {filter, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';

export class RowActionsPopupItem {
  public action:string;
  public displayValue:string;
  public shIdRowIdentifier:string;
  public iconCssClass: IconCssClass;
}

export class RowActionClickDetails {
  public rowActionPopupItem: RowActionsPopupItem;
  public event: Event;
}

@Component({
  selector: 'row-actions-popup',
  templateUrl: './row-actions-popup.component.html',
  styleUrls: ['./row-actions-popup.component.scss'],
  animations: [ROW_ACTION_TRANSITIONS]
})
export class RowActionsPopupComponent implements OnInit, OnDestroy {

  @Input()
  public rowActions:RowActionsPopupItem[];

  @Input()
  public shId: string = 'default';

  public isOpen: boolean;

  @Output()
  public onRowActionClick: EventEmitter<RowActionClickDetails> = new EventEmitter();

  @Output()
  public onPopupOpen: EventEmitter<void> = new EventEmitter<void>();

  private subscription: Subscription;

  constructor(private readonly clickPressEventsService: ClickPressEventsService) { }

  public ngOnInit() {
    this.subscription = this.clickPressEventsService.keydownEscape
      .pipe(
        filter(() => this.isOpen),
        tap(() => this.close()),
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public open() {
    this.isOpen = true;
    this.onPopupOpen.emit();
  }

  public close() {
    this.isOpen = false;
  }

  public rowActionClick(event:Event, rowAction:RowActionsPopupItem) {
    event.preventDefault();
    event.stopPropagation();
    this.isOpen = false;
    this.onRowActionClick.emit({rowActionPopupItem: rowAction, event: event});
  }
}
