<div class="create-event-form">
  <div class="form-section-title sire-header">
    <span [attr.translation-id]="'ANIMAL.EVENTS.ADD_EVENT.SIRE'">{{ 'ANIMAL.EVENTS.ADD_EVENT.SIRE' | translate }}</span>
    <div class="icon-add-sire" (click)="addSireClick($event)"></div>
  </div>
  <div class="form-section-item">
    <bull-choose-dropdown-list #bullChooseDropdownList
                               [preventAddBull]="true"
                               [iconTheme]="bullColorTheme.Blue"
                               [items]="meta.sires"
                               [selectedBullListItem]="editModel.sire"
                               [placeholder]="'Optional'"
                               (onBullSelectionChanged)="onSireSelected($event)"
                               (onBullRemoved)="onBullRemoved($event)">
    </bull-choose-dropdown-list>
  </div>
</div>
