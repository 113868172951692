import {ReportColumnSortType, ReportDisplayType} from './report.interface';
import {Report} from "../../../../../e2e/src/reports/reports-utils";

export class ReportQuery {
  public offset: number;
  public limit: number;
  public pastReportTime?: number;
  public reportUid?: number;
  public type: ReportDisplayType;
  public includeFilterMetaData: boolean;
  public sort: Map<string, ReportColumnSortType> = new Map();
  public filter: Map<string, IFilterQuery> = new Map();
  public filterColumnName?: string;
  public filterValue?: boolean;
  public useDefaultFilter?: boolean;
  public projection?: string;
  public isRefresh?:boolean;
  public shiftId?:number;

  public getFullReportQuery() : ReportQuery {
    let reportQuery = new ReportQuery();
    reportQuery.offset = 0;
    reportQuery.limit = 0;
    reportQuery.type = ReportDisplayType.full;
    reportQuery.includeFilterMetaData = this.includeFilterMetaData;
    reportQuery.sort = this.sort;
    reportQuery.filter = this.filter;
    reportQuery.filterColumnName = this.filterColumnName;
    reportQuery.filterValue = this.filterValue;
    reportQuery.useDefaultFilter = this.useDefaultFilter;
    reportQuery.reportUid = this.reportUid;
    reportQuery.pastReportTime = this.pastReportTime;
    reportQuery.shiftId = this.shiftId;
    return reportQuery;
  }

  public getExtendedReportQuery(): ReportQuery {
    let reportQuery = new ReportQuery();
    reportQuery.offset = 0;
    reportQuery.limit = 0;
    reportQuery.type = ReportDisplayType.Extended;
    reportQuery.includeFilterMetaData = this.includeFilterMetaData;
    reportQuery.sort = this.sort;
    reportQuery.filter = this.filter;
    reportQuery.filterColumnName = this.filterColumnName;
    reportQuery.filterValue = this.filterValue;
    reportQuery.useDefaultFilter = this.useDefaultFilter;
    reportQuery.reportUid = this.reportUid;
    reportQuery.pastReportTime = this.pastReportTime;
    reportQuery.shiftId = this.shiftId;
    return reportQuery;
  }

  public getIdProjectionReportQuery(idFieldName:string) : ReportQuery {
    let reportQuery = new ReportQuery();
    reportQuery.offset = 0;
    reportQuery.limit = 0;
    reportQuery.type = ReportDisplayType.full;
    reportQuery.projection = idFieldName;
    reportQuery.isRefresh = false;
    reportQuery.includeFilterMetaData = this.includeFilterMetaData;
    reportQuery.sort = this.sort;
    reportQuery.filter = this.filter;
    reportQuery.filterColumnName = this.filterColumnName;
    reportQuery.filterValue = this.filterValue;
    reportQuery.useDefaultFilter = this.useDefaultFilter;
    return reportQuery;
  }

  public getSortingQuery()  : string {
    let queryString = '';
    Array.from(this.sort.keys()).forEach(sortKey => {
      if(queryString.length > 0){
        queryString +=  ',';
      }
      if(this.sort.get(sortKey) === ReportColumnSortType.DESC) {
        queryString += '-';
      }
      queryString += sortKey;
    });
    return queryString;
  }
}

export interface IFilterQuery {
  serializeToString() : string;
  isValid : boolean;
}

export class FilterBooleanQuery implements IFilterQuery{
  public value: boolean;

  public serializeToString(): string {
    return " " + NumericQueryCompareOperatorsEnum.Eq + " " + String(this.value);
  }

  public get isValid(): boolean
  {
    return this.value != null;
  }
}

export class FilterChecklistQuery implements IFilterQuery{
  public selectedItems: Set<string> = new Set();

  public serializeToString(): string {
    let result = ' In (';
    let itemsArray = Array.from(this.selectedItems.keys());
    for (let i=0; i< itemsArray.length; i++) {
      result += "'" + itemsArray[i] + "'";
      if(i < itemsArray.length - 1){
        result += ',';
      }
    }
    result += ')';
    return result;
  }

  public get isValid(): boolean
  {
    return this.selectedItems.size > 0;
  }
}

export class FilterSearchQuery implements IFilterQuery{
  public equalsValue: string;

  public serializeToString(): string {
    return " " + NumericQueryCompareOperatorsEnum.Eq + " '" + this.equalsValue + "'";
  }

  public get isValid(): boolean
  {
    return this.equalsValue && this.equalsValue.length > 0;
  }
}

export enum NumericQueryCompareOperatorsEnum {
  Eq = 'Eq',
  Ne = 'Ne',
  Gt = 'Gt',
  Lt = 'Lt',
  IRn = 'IRn',
  ORn = 'ORn'
}

export class FilterNumericQuery implements IFilterQuery{
  public operator?: NumericQueryCompareOperatorsEnum;
  public compValue1?: number;
  public compValue2?: number;

  public serializeToString(): string {
    if(this.isMultiValue) {
      return " " + this.operator + " (" + this.compValue1 + "," + this.compValue2 + ")";
    }
    return " " + this.operator + " " + this.compValue1;
  }

  public get isValid(): boolean
  {
    if(this.operator == null) {
      return false;
    }
    if(this.isMultiValue) {
      return this.compValue1 != null && this.compValue2 != null;
    } else {
      return this.compValue1 != null;
    }
  }

  public get isMultiValue(): boolean {
    return this.operator === NumericQueryCompareOperatorsEnum.IRn ||
           this.operator === NumericQueryCompareOperatorsEnum.ORn;
  }
}

export class IncludeAnimalToReportModel {

  public readonly requestType:string = 'addAnimal';

  public animalId:string;
}

export class ResetAnimalToInspectReportToDefaultModel {

  public readonly requestType:string = 'animalsToInspectReports';

  public resetToDefault:boolean;
}
