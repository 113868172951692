<div class="left-navs">
  <manage-settings-menuitem *ngFor="let menuItem of menuItems"
                            [menuItemModel]="menuItem"
                            (menuItemClick)="menuItemClick($event)"
                            (subMenuItemClick)="subMenuItemClick($event.menuItem, $event.subMenuItem)">
  </manage-settings-menuitem>
</div>
<div class="settings-content">
  <!--<router-outlet></router-outlet>-->
  <manage-settings-notifications-alerts *ngIf="currentSettingsScreenType == ManageSettingsScreenType.alerts"
                                        [isFinishing]="isFinishing"></manage-settings-notifications-alerts>
  <manage-settings-health *ngIf="isHealthScreen"
                          [currentSettingsScreenType]="currentSettingsScreenType"
                          [isFinishing]="isFinishing"></manage-settings-health>
  <manage-settings-reproduction *ngIf="isReproductionScreen"
                                [currentSettingsScreenType]="currentSettingsScreenType"></manage-settings-reproduction>
  <manage-settings-shifts *ngIf="currentSettingsScreenType == ManageSettingsScreenType.farmRoutineShifts"></manage-settings-shifts>
  <manage-settings-group-routine-events *ngIf="currentSettingsScreenType == ManageSettingsScreenType.farmRoutineGroupRoutineEvents"></manage-settings-group-routine-events>
</div>
