import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  AbortionCowEventMetadata,
  AbortionType,
  CreateAbortionEventDetails,
  EventTypeEnum
} from '../../../../../services/animals/model/animal-events';
import {DropdownListItem} from '../../../dropdown-list/dropdown-list-model';
import {ErrorModel} from '../../../../../services/model/common-model';

@Component({
  selector: 'create-batch-event-abortion',
  templateUrl: './create-batch-event-abortion.component.html',
  styleUrls: ['./create-batch-event-abortion.component.scss']
})
export class CreateBatchEventAbortionComponent implements OnInit, OnChanges {

  public AbortionType = AbortionType;

  @Input()
  public abortionEventDetails : CreateAbortionEventDetails;

  @Input()
  public meta: AbortionCowEventMetadata;

  @Input()
  public errorDetails: ErrorModel[] = [];

  public cowsGroupsNewLactationDropdownListItems: DropdownListItem[];

  public selectedCowGroup: DropdownListItem;

  constructor() { }

  public ngOnInit() {
  }

  public get hasTypeErrorFieldName() : boolean{
    if(this.errorDetails == null) {
      return false;
    }
    return this.errorDetails.find(value => value.fieldName === 'type')  != null;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(this.meta == null) {
      this.cowsGroupsNewLactationDropdownListItems = [];
      this.selectedCowGroup = null;
    } else {
      this.cowsGroupsNewLactationDropdownListItems = this.meta.cowsGroupsNewLactation.map(value => {
        return {
          value: value.id,
          displayValue: value.name,
          icon: null,
          disabled: false
        }
      });
      this.selectedCowGroup = this.cowsGroupsNewLactationDropdownListItems.find(value => value.value === this.abortionEventDetails.destinationGroup);
    }
  }

  public onGroupSelected(group: DropdownListItem<number>) {
    if (!group) {
      this.abortionEventDetails.destinationGroup = null;
      return;
    }
    this.abortionEventDetails.destinationGroup = group.value;
  }
}
