import {Component, Input} from "@angular/core";
import {MaintenanceCallsItemModel} from "../../../../services/dashboard/dashboard.service";

export enum MaintenanceCallsItemTextColor {
  white = 'white',
  yellow = 'yellow'
}

@Component({
  selector: 'maintenance-calls-item',
  templateUrl: './maintenance-calls-item.component.html',
  styleUrls: ['./maintenance-calls-item.component.scss']
})
export class MaintenanceCallsItemComponent {
  @Input()
  public color: MaintenanceCallsItemTextColor = MaintenanceCallsItemTextColor.white;

  @Input()
  public isLocked: boolean;

  @Input()
  public iconCssClass: string;

  @Input()
  public model: MaintenanceCallsItemModel;

  public get headerIconClass() {
    if (this.iconCssClass) {
      return 'item-header-icon item-header-icon--' + this.iconCssClass;
    }
    return 'item-header-icon';
  }

  public createIconShId() {
    if (this.iconCssClass) {
      return 'maintenance_calls_icon_' + this.iconCssClass;
    }
    return '';
  }
}
