<div class="form-group">
  <div class="form-group-label">{{ "ANIMAL.EVENTS.ADD_EVENT.RESULT" | translate}}</div>
  <div class="pregnancy-check-results create-batch-event-content-control">
    <input id="radio-positive-pregnancy-check"
           type="radio"
           name="type"
           value="positivePregnancyCheck"
           [ngModel]="pregnancyCheckEventDetails.type"
           (ngModelChange)="onPregnancyCheckTypeChanged($event)"
           [disabled]="!meta.allowedPositive"/>
    <label for="radio-positive-pregnancy-check">+</label>
    <input id="radio-negative-pregnancy-check" type="radio"
           name="type"
           value="negativePregnancyCheck"
           [ngModel]="pregnancyCheckEventDetails.type"
           (ngModelChange)="onPregnancyCheckTypeChanged($event)"
           [disabled]="!meta.allowedNegative"/>
    <label for="radio-negative-pregnancy-check">-</label>
    <input id="radio-uncertain-pregnancy-check"
           type="radio"
           name="type"
           value="uncertainPregnancyCheck"
           [ngModel]="pregnancyCheckEventDetails.type"
           (ngModelChange)="onPregnancyCheckTypeChanged($event)"
           [disabled]="!meta.allowedUncertain" />
    <label for="radio-uncertain-pregnancy-check" translation-id="COMMON.TEXT.MAYBE">
      {{ 'COMMON.TEXT.MAYBE' | translate }}
    </label>
  </div>
</div>
