<description-cell-details-template [hasRowDetails]="false"
                                   [isRowExpanded]="isExpanded"
                                   [row]="row"
                                   [rowShId]="row.eventId"
                                   (toggleExpanded)="toggleExpanded()">
  <ng-container key *ngIf="row.expirationDate || row.endDate">{{"ANIMAL.EVENTS.EVENT_DESCRIPTION.EXPIRATION_DATE"| translate}}:&nbsp;</ng-container>
  <ng-container value>
    <ng-container *ngIf="!row.endDate && row.expirationDate">
      {{row.expirationDate | epochDateTime:DateTimeFormatEnum.Date }}
    </ng-container>
    <ng-container *ngIf="row.endDate">
      {{row.endDate | epochDateTime:DateTimeFormatEnum.Date }}
    </ng-container>
  </ng-container>
</description-cell-details-template>
