<ejs-accumulationchart background="transparent"
                       [attr.id]="chartElement"
                       [width]="'100px'"
                       [height]="'100px'"
                       [legendSettings]="legendSettings"
                       [enableAnimation]='enableAnimation'>
  <e-accumulation-series-collection>
    <e-accumulation-series [dataSource]='chartData' xName='title' yName='value' pointColorMapping="color">
    </e-accumulation-series>
  </e-accumulation-series-collection>
</ejs-accumulationchart>
