export enum AboutModelStateEnum {
  Inactive = 'Inactive',
  Trial = 'Trial',
  Active = 'Active',
  Suspend = 'Suspend'
}

export enum AboutModelBizModelEnum {
  Legacy = 'Legacy',
  Up = 'Up',
  Go = 'Go'
}

export class OrderedPopulationPlanModel {
  constructor(public plans: IOrderedPopulationPlanItem[]) {
  }

  public showApplicationPlan(): boolean {
    return this.plans && this.plans.length > 0;
  }

  public showMultipleApplicationPlans(): boolean {
    const cowsPlan = this.plans.find((plan: IOrderedPopulationPlanItem) => plan.plan == PopulationPlanName.Cows);
    const heifersPlan = this.plans.find((plan: IOrderedPopulationPlanItem) => plan.plan == PopulationPlanName.Heifers);
    const youngStockPlan = this.plans.find((plan: IOrderedPopulationPlanItem) => plan.plan == PopulationPlanName.YoungStock);
    const finishingPlan = this.plans.find((plan: IOrderedPopulationPlanItem) => plan.plan == PopulationPlanName.Finishing);
    return !!((cowsPlan && heifersPlan && cowsPlan.value != heifersPlan.value) || youngStockPlan || finishingPlan);
  }

  public showSingleApplicationPlanOnly(): boolean {
    const cowsPlan = this.plans.find((plan: IOrderedPopulationPlanItem) => plan.plan == PopulationPlanName.Cows);
    const heifersPlan = this.plans.find((plan: IOrderedPopulationPlanItem) => plan.plan == PopulationPlanName.Heifers);
    return cowsPlan && heifersPlan && cowsPlan.value == heifersPlan.value;
  }

  public hasFinishingOrYoungStock(): boolean {
    const youngStockPlan = this.plans.find((plan: IOrderedPopulationPlanItem) => plan.plan == PopulationPlanName.YoungStock);
    const finishingPlan = this.plans.find((plan: IOrderedPopulationPlanItem) => plan.plan == PopulationPlanName.Finishing);
    return !!((finishingPlan && finishingPlan.value == AboutModelPopulationPlanEnum.Finishing) || (youngStockPlan && youngStockPlan.value == AboutModelPopulationPlanEnum.YoungStock));
  }

  public showApplicationPlanItem(item: IOrderedPopulationPlanItem): boolean {
    if (item.value == AboutModelPopulationPlanEnum.None || item.value == AboutModelPopulationPlanEnum.FinishingNone) {
      return false;
    }
    let result = false;
    switch (item.plan) {
      case PopulationPlanName.Cows:
      case PopulationPlanName.Heifers: {
        if (this.showSingleApplicationPlanOnly()) {
          result = item.plan == PopulationPlanName.Cows;
        } else {
          result = true;
        }
        break;
      }
      case PopulationPlanName.YoungStock:
      case PopulationPlanName.Finishing:
        result = this.hasFinishingOrYoungStock();
        break;
      default:
    }
    return result;
  }
}

export interface IOrderedPopulationPlanItem {
  plan: PopulationPlanName,
  value: AboutModelPopulationPlanEnum
}

export class AboutModel {
  public farmId:string;
  public farmName:string;
  public version:string;
  public state:AboutModelStateEnum;
  public linked:boolean;
  public bizModel:AboutModelBizModelEnum;
  public duration:AboutModelDuration;
  public thirdParty:AboutModelThirdParty;
  public daysForSuspension:number;
  public healthIndexBCMVersion:string;
  public groupMonitorVersion:string;
  public routineCSVersion:string;
  public algoAnalysisVersion:string;
  public animalStatesLibraryVersion:string;
  public populationPlan:AboutModelPopulationPlan;
  public orderedPopulationPlan: OrderedPopulationPlanModel;
  public serialNumber:string;
}

export class AboutModelDuration {
  public years:number;
  public months:number;
  public updatedDate:number;
}

export class AboutModelThirdParty {
  public name:string;
  public lastSyncDate:number;
}

export enum AboutModelPopulationPlanEnum {
  None = 'None',
  FinishingNone = 'FinishingNone',
  Finishing = 'Finishing',
  YoungStock = 'YoungStock',
  Starter = 'Starter',
  Advanced = 'Advanced',
  Premium = 'Premium'
}

export enum PopulationPlanName {
  Cows = 'cows',
  Heifers = 'heifers',
  YoungStock = 'youngStock',
  Finishing = 'finishing'
}

export class AboutModelPopulationPlan {
  public finishing: AboutModelPopulationPlanEnum;
  public youngStock: AboutModelPopulationPlanEnum;
  public heifers: AboutModelPopulationPlanEnum;
  public cows: AboutModelPopulationPlanEnum;
}
