import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {dashboardAccordion} from './animations/dashboard-animations';
import {GoogleAnalyticsService} from '../../../services/google-analytics/google-analytics.service';
import {PollingKeyNames, PollingService} from '../../../services/rest-api/polling.service';
import {Subscription} from 'rxjs';
import {IDashboardAvailableWidget, IDashboardWidget, WidgetType} from '../../../services/dashboard/model/dashboard-widgets.interface';
import {DashboardWidgetsService} from '../../../services/dashboard/dashboard-widgets.service';
import {ConfigService} from '../../../services/config/config.service';
import {FarmMode} from '../../../services/config/model/server-config';
import {
  DashboardWidgetSelectDialogComponent,
  SelectWidgetDialogModel
} from './dashboard-widget-select-dialog/dashboard-widget-select-dialog.component';
import {DashboardSaveWidget} from '../../../services/dashboard/model/dashboard.interface';
import {IReport, ReportKeyType} from '../../../services/reports/model/report.interface';

export class AccordionWidgetViewState {

  public widget: IDashboardWidget;

  public isExpanded: boolean;
}

@Component({
  selector: 'dashboard-accordion',
  templateUrl: './dashboard-accordion.component.html',
  styleUrls: ['./dashboard-accordion.component.scss'],
  animations: [dashboardAccordion]
})

export class DashboardAccordionComponent implements OnInit, OnChanges, OnDestroy {

  private pollingServiceSubscription: Subscription;

  @Input()
  public editMode:boolean;

  @ViewChild('dashboardWidgetSelectDialog')
  public dashboardWidgetSelectDialog: DashboardWidgetSelectDialogComponent;

  public widgetsViewStates : AccordionWidgetViewState[] = [];

  private selectionAvailableWidgets: IDashboardAvailableWidget[];

  public WidgetType = WidgetType;

  public farmMode: FarmMode;

  constructor(public readonly configService: ConfigService,
              private readonly dashboardWidgetsService: DashboardWidgetsService,
              private readonly pollingService: PollingService,
              private readonly googleAnalyticsService: GoogleAnalyticsService) {

  }

  public async ngOnInit() {
    this.farmMode = this.configService.serverConfig.farmMode;
    await this.getDashboardWidgets();
    this.pollingServiceSubscription = this.pollingService.pollingChangesState.subscribe(pollingName => {
      if (pollingName.includes(PollingKeyNames.reportsDashboard) ||
          pollingName.includes(PollingKeyNames.statistics)) {
        this.updateDashboardWidgets();
      }
    });
  }

  public async ngOnChanges(changes: SimpleChanges) {
    if (changes.editMode.currentValue !== changes.editMode.previousValue) {
      if (this.editMode) {
        let response = await this.dashboardWidgetsService.getDashboardAvailableWidgets();
        this.selectionAvailableWidgets = response.responseBody;
        this.modifyDashboardWidgetsToEditMode();
      } else {
        await this.getDashboardWidgets();
      }
    }
  }

  public ngOnDestroy(): void {
    this.pollingServiceSubscription?.unsubscribe();
  }

  public expandAccordionSection(widgetViewState:AccordionWidgetViewState){
    if (!this.editMode) {
      this.widgetsViewStates.forEach(viewState => {
        viewState.isExpanded = false;
      });
      this.googleAnalyticsService.send('dashboard', 'accordion', 'Open - ' + widgetViewState.widget.name);
      widgetViewState.isExpanded = true;
    }
  }

  public getWidgetIcon(widgetViewState:AccordionWidgetViewState) {
    if(widgetViewState.widget.type == WidgetType.Graph) {
      return 'icon-cow';
    } else {
      if((<IReport>widgetViewState.widget.content).meta.reportKeyType == ReportKeyType.group) {
        return 'icon-group';
      } else {
        return 'icon-cow';
      }
    }
  }

  public removeWidget(widgetViewState:AccordionWidgetViewState) {
    const index = this.widgetsViewStates.indexOf(widgetViewState);
    if (index > -1) {
      this.widgetsViewStates.splice(index, 1);
    }
    if(this.widgetsViewStates.length < DashboardWidgetsService.MaxNumberOfItems &&
       this.widgetsViewStates[this.widgetsViewStates.length -1].widget != null) {
      this.widgetsViewStates.push({widget: null, isExpanded:false});
    }
  }

  public addWidget(event:Event) {
    event.preventDefault();
    event.stopPropagation();
    let selectWidgetDialogModel : SelectWidgetDialogModel = {
      selectedWidgets: this.widgetsViewStates.filter(value => value.widget != null)
                                             .map(value => value.widget),
      availableWidgets: this.selectionAvailableWidgets
    };
    this.dashboardWidgetSelectDialog.open(selectWidgetDialogModel);
  }

  public onWidgetSelectDialogApply(selectedWidget:IDashboardAvailableWidget) {
    this.widgetsViewStates[this.widgetsViewStates.length - 1].widget = {
      name: selectedWidget.name,
      type: selectedWidget.type,
      category: selectedWidget.category,
      content: null,
      isSuspended: null
    };
    if(this.widgetsViewStates.length < DashboardWidgetsService.MaxNumberOfItems) {
      this.widgetsViewStates.push({widget: null, isExpanded:false});
    }
  }

  public getSelectedWidgetsForSave() : DashboardSaveWidget[] {
    let selectedWidgets : DashboardSaveWidget[] = this.widgetsViewStates.filter(value => value.widget != null)
      .map(value => {
        let selectedWidget : DashboardSaveWidget = {
          name: value.widget.name,
          type: value.widget.type
        };
        return selectedWidget;
      });
    return selectedWidgets;
  }

  private modifyDashboardWidgetsToEditMode() {
    for (let widgetViewState of this.widgetsViewStates) {
      widgetViewState.isExpanded = false;
      widgetViewState.widget.isSuspended = null;
      widgetViewState.widget.content = null;
    }
    if(this.widgetsViewStates.length < DashboardWidgetsService.MaxNumberOfItems) {
      this.widgetsViewStates.push({widget: null, isExpanded:false});
    }
  }

  private async getDashboardWidgets() {
    this.widgetsViewStates = [];
    let response = await this.dashboardWidgetsService.getDashboardWidgets();
    if(response.responseBody) {
      this.widgetsViewStates = response.responseBody.filter(value => value.isSuspended == false).map(value => {
        return { isExpanded: false, widget: value}
      });
      if(this.widgetsViewStates.length > 0) {
        this.widgetsViewStates[0].isExpanded = true;
      }
    }
  }

  private async updateDashboardWidgets() {
    if(this.editMode) {
      return;
    }
    let response =  await this.dashboardWidgetsService.getDashboardWidgets();
    if (response.responseBody) {
      this.widgetsViewStates.forEach((widgetViewState: AccordionWidgetViewState) => {
        let updatedWidget = response.responseBody.find(widget => widget.name == widgetViewState.widget.name &&
                                                                           widget.category == widgetViewState.widget.category &&
                                                                           widget.type == widgetViewState.widget.type);
        if (updatedWidget) {
          widgetViewState.widget = updatedWidget;
        }
      });
    }
  }
}
