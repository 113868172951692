import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {FarmStatisticsDairyWidget} from '../../../../../../services/dashboard/model/farm-statistics-dairy';
import {PollingKeyNames, PollingService} from '../../../../../../services/rest-api/polling.service';
import {DashboardService} from '../../../../../../services/dashboard/dashboard.service';
import {HttpResponseResult} from '../../../../../../services/rest-api/rest-api.service';

@Component({
  selector: 'farm-statistics-dairy',
  templateUrl: './farm-statistics-dairy.component.html',
  styleUrls: ['./farm-statistics-dairy.component.scss']
})
export class FarmStatisticsDairyComponent implements OnChanges {

  public ObjectKeys = Object.keys;

  @Input()
  public farmStatistics: FarmStatisticsDairyWidget;

  constructor() { }

  public async ngOnInit() {

  }
  
  public ngOnChanges(changes: SimpleChanges): void {
    if(this.farmStatistics != null) {
      this.farmStatistics.animals.subGroups[0][0].color = '#042f3f';
      this.farmStatistics.cows.subGroups[0][0].color = '#042f3f';
      this.farmStatistics.cows.subGroups[0][1].color = '#df9226';
      this.farmStatistics.cows.subGroups[1][0].color = '#1999d5';
      this.farmStatistics.cows.subGroups[1][1].color = '#ef4c39';
      this.farmStatistics.heifers.subGroups[0][0].color = '#1999d5';
    }
  }

  public calculatePercentage(num1: number, num2: number, useForWidth?: boolean): number{
    let result;
    if (num2 === 0 || num1 === 0) {
      return 0;
    }
    result = num1 / num2 * 100;
    if (useForWidth && result > 100) {
      return 100;
    }
    return result;
  }
}
