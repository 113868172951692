import {ShiftModel} from './shifts-model';

export interface IScheduleRequestParams {
  relativeWeek: number;
}

export interface ISortingTaskTypeModel {
  type: SortingTaskSortType;
  label: string;
}

export abstract class ScheduleModel {
  public readonly abstract type: ScheduleMetaType;
}

export class ScheduleRepeatModel extends ScheduleModel {
  public type = ScheduleMetaType.repeat;
  public weekGap: number;
  public daysOfWeek: number[] = [];
  public shiftIds: number[] = [];
}

export class ScheduleSingleModel extends ScheduleModel {
  public shiftId: number;
  public type = ScheduleMetaType.single;
}

export class ScheduleAnimalsRepeatModel extends ScheduleModel {
  public shiftIds: number[] = [];
  public type = ScheduleMetaType.repeatAnimals;
  public repeatDays: number;
}

export class SortingTasksCommentsResponseModel {
  public comments: string[]
}

export class SortingTasksResponseModel {
  public tasks: SortingTaskModel[];
}

export abstract class SortingTaskModel {
  public readonly abstract type: SortingTaskSortType;
  public penId?: number;
  public taskId: number;
  public date: number;
  public schedule: ScheduleModel;
  public comment:string;
  public isHerdManagement?: boolean;
}

export class ReportSortingTaskModel extends SortingTaskModel {
  public type = SortingTaskSortType.report;
  public priority: number;
  public report: string;
  public filter: boolean;
  public groups: number[] = [];
  public groupsSearchQuery: string;
}

export class AnimalSortingTaskModel extends SortingTaskModel {
  public priority: number;
  public type = SortingTaskSortType.animals;
  public animals: string[] = [];
  public quickSort?: boolean;
}

export class UnidentifiedSortingTaskModel extends SortingTaskModel {
  public priority: number;
  public type = SortingTaskSortType.unidentifiedAnimals;
}

export class GroupsSortingTaskModel extends SortingTaskModel {
  public priority: number;
  public type = SortingTaskSortType.groups;
  public groups: number[] = [];
}

export class SchedulerModel {
  public startDate: number;
  public relativeWeek: number;
  public scheduledDays: number[];
  public shifts: SchedulerShiftModel[];
  public currentDate: number;
}

export class SchedulerShiftModel extends ShiftModel {
  public schedulerDays: SchedulerDay[];
}

export class SchedulerDay  {
  public date: number;
  public sortingTasks: ISortingTaskMetaData[];
}

export interface ISortingTaskMetaData {
  id: number;
  type: SortingTaskSortType;
  meta: ISortingTaskMetaInformation;
  scheduleType: ScheduleMetaType;
}

export interface ISortingTaskMetaInformation {
  numberOfAnimals?: number;
  numberOfGroups?: number;
  quickSort?: boolean;
  reportName?: Reports;
  cowsForAI?: boolean;
  markedHealthIssue?: boolean;
  unassignedTags?: boolean;
}

export enum ScheduleMetaType {
  single = 'single',
  repeat = 'repeat',
  repeatAnimals = 'repeatAnimals'
}

export enum SortingTaskSortType {
  report = 'report',
  animals = 'animals',
  unidentifiedAnimals = 'unidentifiedAnimals',
  newTask = 'newTask',
  groups = 'groups'
}

export enum Reports {
  AnimalsInHeat = 'AnimalsInHeat',
  AnimalsToInspect = 'AnimalsToInspect',
  Health = 'Health',
  AnimalDistress = 'AnimalDistress',
  EarlyFreshCows = 'EarlyFreshCows',
  AnestrusCows = 'AnestrusCows',
  IrregularHeats = 'IrregularHeats',
  SuspectedForAbortion = 'SuspectedForAbortion',
  GroupRoutine = 'GroupRoutine',
  GroupRoutineHeatStress = 'GroupRoutineHeatStress',
  TagMaintenanceCalls = 'TagMaintenanceCalls'
}

export class ReportFilterMetaModel {
  public code: string;
  public default: boolean;
  public editable: boolean;
}

export enum ReportKey {
  Animal = 'Animal',
  Tag = 'Tag'
}

export class ReportMetaModel {
  public id: number;
  public name: string;
  public filter: ReportFilterMetaModel[];
  public reportKey?: ReportKey | string;
}

