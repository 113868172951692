import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FarmMode} from "../../../../../services/config/model/server-config";
import {ConfirmDialogIcons, ConfirmDialogThemes} from "../confirm-dialog.component";
import {TrialOperation} from "../../../../../services/config/model/anonymous-auth-config";

@Component({
  selector: 'confirm-trial-farm-mode-dialog',
  templateUrl: './confirm-trial-farm-mode-dialog.component.html',
  styleUrls: ['./confirm-trial-farm-mode-dialog.component.scss']
})
export class ConfirmTrialFarmModeDialogComponent implements OnInit {

  @Input()
  public farmMode: FarmMode;

  @Input()
  public operationType: TrialOperation;

  @Input()
  public isOpen: boolean = false;

  @Output()
  public onCloseClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onGoToDashboardClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  public ngOnInit() {
  }

  public getIconClassByFarmAndOperationMode() {
    if (this.farmMode === FarmMode.Dairy) {
      return ConfirmDialogIcons.dairyIcon;
    } else {
      if (this.operationType === TrialOperation.finishing) {
        return ConfirmDialogIcons.finishingIcon;
      } else {
        return ConfirmDialogIcons.breedingIcon;
      }
    }
  }

  public getThemeClassByFarmAndOperationMode() {
    if (this.farmMode === FarmMode.Dairy) {
      return ConfirmDialogThemes.Green;
    } else {
      if (this.operationType === TrialOperation.finishing) {
        return ConfirmDialogThemes.Brown;
      } else {
        return ConfirmDialogThemes.Purple;
      }
    }
  }

  public getTitleTextByFarmAndOperationMode() {
    if (this.farmMode === FarmMode.Dairy) {
      return 'REGISTRATION.TRIAL_DAIRY_DIALOG_CONFIRM.HEADER_TEXT';
    } else {
      if (this.operationType === TrialOperation.finishing) {
        return 'REGISTRATION.TRIAL_FINISHING_DIALOG_CONFIRM.HEADER_TEXT';
      } else {
        return 'REGISTRATION.TRIAL_BREEDING_DIALOG_CONFIRM.HEADER_TEXT';
      }
    }
  }

  public getPrimaryTextByFarmAndOperationMode() {
    if (this.farmMode === FarmMode.Dairy) {
      return 'REGISTRATION.TRIAL_DAIRY_DIALOG_CONFIRM.TITLE';
    } else {
      if (this.operationType === TrialOperation.finishing) {
        return 'REGISTRATION.TRIAL_FINISHING_DIALOG_CONFIRM.TITLE';
      } else {
        return 'REGISTRATION.TRIAL_BREEDING_DIALOG_CONFIRM.TITLE';
      }
    }
  }

  public onClose() {
    this.closeDialog();
    this.onCloseClicked.emit();
  }

  public goToDashboard() {
    this.onGoToDashboardClicked.emit();
  }

  public showDialog() {
    this.isOpen = true;
  }

  public closeDialog() {
    this.isOpen = false;
  }
}
