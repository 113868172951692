<div #ngxDatatableContainer class="ngx-datatable-container">
  <ngx-datatable *ngIf="!initGrid"
                 #datatable
                 [columns]="columns"
                 [columnMode]="ColumnMode.flex"
                 [rows]="rows"
                 [virtualization]="true"
                 [rowHeight]="getRowHeight"
                 [headerHeight]="headerHeight"
                 [scrollbarV]="true"
                 [scrollbarH]="false"
                 [externalSorting]="true"
                 [reorderable]="false"
                 [messages]="{emptyMessage: '', totalMessage: ''}"
                 (scroll)="onGridScroll($event.offsetY)"
                 [ngStyle]="{ 'width': datatableContainerWidthPixels + 'px',
                              'height': datatableContainerHeightPixels + 'px'}"
                 class="data-grid-datatable">
  </ngx-datatable>
</div>

<ng-template #columnTemplate let-column="column">
  <div (click)="columnClick(column.name)" class="value-column" [ngClass]="{'clickable': columnsMappings.get(column.name).enableSorting,
                                                                           'no-bottom-border': hideHeaderBottomBorder}">
    <span class="column-name">
      <ng-container *ngTemplateOutlet="sortedGridColumn;context:{column:column.name}"></ng-container>
    </span>
    <div class="column-indicators" *ngIf="columnsMappings.get(column.name).enableSorting">
      <div *ngIf="isColumnSortedUp(column.name)" class="sorting-icon sorting-icon-up"></div>
      <div *ngIf="isColumnSortedDown(column.name)" class="sorting-icon sorting-icon-down"></div>
    </div>
  </div>
</ng-template>

<ng-template #cellTemplate let-column="column" let-row="row" let-value="value">
  <div class="value-cell" [ngClass]="{ 'clickable': isRowClickable }" (click)="onRowClick.emit(row)">
    <ng-container *ngTemplateOutlet="sortedGridCell;context:{column:column.name, row:row}"></ng-container>
  </div>
</ng-template>
<loader [isLoading]="loaderIsLoading" [contextElementRef]="elementRef" [class.shifted-loader]="isLoaderShifted"></loader>
