<div class="manage-settings-popup-wrapper" [attr.sh-id]="'manage-settings-popup'">
  <manage-settings-header [applicationBundleRoutingMode]="applicationBundleRoutingMode"
                          (onCloseSettingsDialog)="onCloseSettingsDialog.emit()"></manage-settings-header>
  <manage-settings-welcome *ngIf="currentSettingsScreenType == ManageSettingsScreenType.welcome"
                           [attr.sh-id]="'manage_settings_welcome'"
                           [hasPermissionsToNotifications]="hasPermissionsToNotifications"
                           [hasPermissionsToHealth]="hasPermissionsToHealth"
                           [isFinishing]="isFinishing"
                           (onOpenScreen)="openScreen($event)"></manage-settings-welcome>
  <manage-settings-layout *ngIf="currentSettingsScreenType != ManageSettingsScreenType.welcome"
                          [hasPermissionsToHealth]="hasPermissionsToHealth"
                          [currentSettingsScreenType]="currentSettingsScreenType"
                          [isFinishing]="isFinishing"
                          (onOpenScreen)="openScreen($event)"></manage-settings-layout>
</div>
