import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {ConfigService} from '../config/config.service';
import * as moment from 'moment';

import {registerLocaleData} from '@angular/common';

import localeBg from '@angular/common/locales/bg';
import localeCs from '@angular/common/locales/cs';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeEl from '@angular/common/locales/el';
import localeEnGb from '@angular/common/locales/en-GB';
import localeEsMx from '@angular/common/locales/es-MX';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeLt from '@angular/common/locales/lt';
import localeLv from '@angular/common/locales/lv';
import localeNb from '@angular/common/locales/nb';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRo from '@angular/common/locales/ro';
import localeRu from '@angular/common/locales/ru';
import localeSk from '@angular/common/locales/sk';
import localeSl from '@angular/common/locales/sl';
import localeSv from '@angular/common/locales/sv';
import localeTr from '@angular/common/locales/tr';
import localeZhHans from '@angular/common/locales/zh-Hans';
import localeZhHansHk from '@angular/common/locales/zh-Hans-HK';
import {FarmMode, ServerConfig} from '../config/model/server-config';
import localeTh from '@angular/common/locales/th';
import {RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from '../auth/auth.service';
import {TimeSelectionHoursMode} from '../../common/components/calendar/time-selection/time-selection.component';

registerLocaleData(localeBg);
registerLocaleData(localeCs);
registerLocaleData(localeDa);
registerLocaleData(localeDe);
registerLocaleData(localeEl);
registerLocaleData(localeEnGb);
registerLocaleData(localeEsMx);
registerLocaleData(localeFi);
registerLocaleData(localeFr);
registerLocaleData(localeHu);
registerLocaleData(localeIt);
registerLocaleData(localeJa);
registerLocaleData(localeKo);
registerLocaleData(localeLt);
registerLocaleData(localeLv);
registerLocaleData(localeNb);
registerLocaleData(localeNl);
registerLocaleData(localePl);
registerLocaleData(localePt);
registerLocaleData(localeRo);
registerLocaleData(localeRu);
registerLocaleData(localeSk);
registerLocaleData(localeSl);
registerLocaleData(localeSv);
registerLocaleData(localeTr);
registerLocaleData(localeZhHans, 'zh-chs');
registerLocaleData(localeZhHansHk, 'zh-cht');
registerLocaleData(localeTh, 'th-th');

export class UpdateMessagingLanguageModel {
  public messagingLanguage: string;
}

export class TranslationDetails {

  public language: string;

  public farmMode: FarmMode;

  // tslint:disable-next-line:no-any
  public translationObject: any;
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  // tslint:disable-next-line:naming-convention
  private static readonly selectedLanguageKey: string = 'appLanguage';

  // tslint:disable-next-line:naming-convention
  private static readonly languagesJsonFile: string = 'assets/i18n/langs.json';

  private static readonly UpdateUserLanguageRoute: string = '/rest/api/system/users/{userId}/language';

  private static readonly DefaultLanguage: string = 'en-us';

  private static readonly DefaultFarmMode: FarmMode = FarmMode.Dairy;

  private fallbackTranslation: TranslationDetails;

  private selectedTranslation: TranslationDetails;

  private defaultTranslation: TranslationDetails;

  private readonly translationCacheMap: Map<string, string> = new Map<string, string>();

  public static readonly ShTranslationServiceKey: string = 'shTranslationService';

  public selectedLanguage: string = TranslationService.DefaultLanguage;

  public languageChangeSubject = new Subject();

  constructor(private httpClient: HttpClient,
              public readonly configService: ConfigService,
              private readonly restApiService: RestApiService,
              private authService: AuthService) {
    this.translate[TranslationService.ShTranslationServiceKey] = this;
    configService.ServerConfigSubject.subscribe(async value => {
      await this.initTranslations(value);
    });
  }

  public get shortDateFormatFullYear() : string {
    return moment.localeData(this.selectedLanguage).longDateFormat('L');
  }

  public get shortDateFormatShortYear() : string {
    return this.shortDateFormatFullYear.replace('YYYY', 'YY');
  }

  public get shortTimeFormat() : string {
    return moment.localeData(this.selectedLanguage).longDateFormat('LT');
  }

  public get localeShortDateTimeFormat() : string {
    return this.shortDateFormatFullYear + ' ' + this.shortTimeFormat;
  }

  public get hoursMode() : TimeSelectionHoursMode {
    if(this.selectedLanguage == 'en-us') {
      return TimeSelectionHoursMode.Mode12Hours;
    } else {
      return TimeSelectionHoursMode.Mode24Hours;
    }
  }

  public getLanguages(): Observable<string[]> {
    return this.httpClient.get<string[]>(TranslationService.languagesJsonFile);
  }

  private getFarmMode(serverConfig: ServerConfig) : FarmMode {
    if(serverConfig != null) {
      return serverConfig.farmMode;
    }
    return TranslationService.DefaultFarmMode;
  }

  public async selectLanguage(selectedLanguage: string, serverConfig: ServerConfig, updateLanguage: boolean) {
    await this.updateLanguage(selectedLanguage, serverConfig, updateLanguage);
    if(this.defaultTranslation == null) {
      this.defaultTranslation = await this.loadTranslation(TranslationService.DefaultFarmMode, TranslationService.DefaultLanguage);
    }
    this.selectedLanguage = selectedLanguage;
    moment.locale(this.selectedLanguage);
    this.fallbackTranslation = null;
    if(this.getFarmMode(serverConfig) == TranslationService.DefaultFarmMode &&
       this.selectedLanguage == TranslationService.DefaultLanguage) {
      this.selectedTranslation = this.defaultTranslation;
    } else {
      this.selectedTranslation = await this.loadTranslation(this.getFarmMode(serverConfig), this.selectedLanguage);
      this.fallbackTranslation = await this.loadTranslation(this.getFarmMode(serverConfig), TranslationService.DefaultLanguage);
    }
    this.translationCacheMap.clear();
    this.languageChangeSubject.next();
  }

  public translate(key:string) : string {
    if(key == null) {
      return null;
    }
    if(!this.translationCacheMap.has(key)) {
      let foundTranslation = this.resolveMissingTranslation(key);
      if(foundTranslation == null) {
        this.translationCacheMap.set(key, key);
      } else {
        this.translationCacheMap.set(key, foundTranslation);
      }
    }
    return this.translationCacheMap.get(key);
  }

  public resolveMissingTranslation(key:string){
    let translationResult = this.findMissingTranslation(key, this.selectedTranslation.translationObject);
    if(translationResult == key) {
      if(this.fallbackTranslation != null) {
        translationResult = this.findMissingTranslation(key, this.fallbackTranslation.translationObject);
      }
    }
    return translationResult;
  }

  public async initTranslations(serverConfig: ServerConfig) {
    if(serverConfig != null &&
      serverConfig.user != null){
      this.selectedLanguage = serverConfig.user.messagingLanguage;
    } else {
      this.selectedLanguage = sessionStorage.getItem(TranslationService.selectedLanguageKey);
      if(this.selectedLanguage == null) {
        this.selectedLanguage = TranslationService.DefaultLanguage;
      }
    }
    await this.selectLanguage(this.selectedLanguage, serverConfig, false);
  }

  // tslint:disable-next-line:no-any
  private findMissingTranslation(key:string, translationObj: any) {
    let translation = this.findMissingKeyTranslation(key, translationObj);
    if (translation != null) {
      return translation;
    } else {
      return key;
    }
  }

  // tslint:disable-next-line:no-any
  private findMissingKeyTranslation( key:string, translationObj: any) : string {
    if(key == null) {
      return null;
    }
    let keyParts = key.split('.');
    let leftKeyPart = key;
    for (let i = keyParts.length - 1; i >= 0; i--) {
      let propertyNameInvariantCase = Object.getOwnPropertyNames(translationObj).find(value => value.toLowerCase() == leftKeyPart.toLowerCase());
      if(propertyNameInvariantCase != null) {
        let subTranslationObj = translationObj[propertyNameInvariantCase];
        if (subTranslationObj != null) {
          if (leftKeyPart == key) {
            if ((typeof subTranslationObj) === 'string') {
              return <string>subTranslationObj;
            } else {
              continue;
            }
          } else {
            if (!((typeof subTranslationObj) === 'string')) {
              let foundSubTranslation = this.findMissingKeyTranslation(key.substr(leftKeyPart.length + 1), subTranslationObj);
              if(foundSubTranslation != null) {
                return foundSubTranslation;
              }
            }
          }
        }
      }
      leftKeyPart = leftKeyPart.substr(0, leftKeyPart.length - ('.' + keyParts[i]).length);
    }
    return null;
  }

  private getTranslationKey(farmMode: FarmMode, language: string) {
    return farmMode + ':' + language
  }

  private async loadTranslation(farmMode: FarmMode, language: string) : Promise<TranslationDetails> {
    let translationDetails = new TranslationDetails();
    translationDetails.farmMode = farmMode;
    translationDetails.language = language;
    translationDetails.translationObject = await this.getTranslation(translationDetails.farmMode, translationDetails.language);
    return translationDetails;
  }

  private async updateLanguage(language:string, serverConfig: ServerConfig, updateLanguageStorage: boolean){
    if(!updateLanguageStorage) {
      return;
    }
    if(serverConfig != null &&
       serverConfig.user != null) {
      let route = TranslationService.UpdateUserLanguageRoute.replace('{userId}', serverConfig.user.userId.toString());
      let updateMessagingLanguageModel: UpdateMessagingLanguageModel = {
        messagingLanguage: language
      };
      let response = await this.restApiService.sendPutMethod(route, this.authService.currentUserAuthState.authDetails, updateMessagingLanguageModel);
      if(response.status == 200) {
        sessionStorage.setItem(TranslationService.selectedLanguageKey, language);
      }
    }
    else {
      sessionStorage.setItem(TranslationService.selectedLanguageKey, language);
    }
  }

  // tslint:disable-next-line:no-any
  public async getTranslation(farmMode: string, language: string): Promise<any> {
    return this.httpClient.get(`assets/i18n/${farmMode.toLowerCase()}/${language}.json?` + this.configService.configuration.clientVersion).toPromise();
  }
}
