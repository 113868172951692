import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IRowActionModel, LedTasksColumns} from "../tasks.component";
import {
  ScheduledTaskModel
} from "../../../../services/led-tasks/models/led-tasks.interface";
import {RowActionsPopupItem} from "../../../../common/components/row-actions-popup/row-actions-popup.component";
import {DataGridComponent} from "../../../../common/components/data-grid/data-grid.component";
import {DateTimeFormatEnum} from "../../../../common/pipes/epoch-date-time.pipe";
import {SearchEntry} from "../../../../services/search/model/search.model";
import {DescriptionCellEntity} from '../../../../common/components/data-grid/description-cell-template/description-cell-entities/model/description-cell-entity.model';
import {GridViewState} from '../../../../services/ui/view-state/grid-row/grid-view-state';

@Component({
  selector: 'animal-row',
  templateUrl: './animal-row.component.html',
  styleUrls: ['./animal-row.component.scss']
})
export class AnimalRowComponent implements OnInit {

  @Input()
  public column: LedTasksColumns;

  @Input()
  public row: ScheduledTaskModel;

  @Input()
  public animalsList: SearchEntry[];

  @Input()
  public rowActionsMap: Map<ScheduledTaskModel, RowActionsPopupItem[]>;

  @Input()
  public gridViewState: GridViewState;

  @Input()
  public ledTasksDataGrid: DataGridComponent;

  @Output()
  public onRowAction: EventEmitter<IRowActionModel> = new EventEmitter<IRowActionModel>();

  public LedTasksColumns = LedTasksColumns;

  public dateTimeFormatEnum = DateTimeFormatEnum;

  constructor() { }

  public ngOnInit() {
  }

  public onRowActionsOpen() {
    this.gridViewState.clearExpandedRows();
    this.ledTasksDataGrid.refreshRowsHeight();
  }

  public handleRowAction(rowAction: string, rowModel: ScheduledTaskModel) {
    const rowActionModel: IRowActionModel = { rowAction: rowAction, rowModel: rowModel };
    this.onRowAction.emit(rowActionModel);
  }

  public toggleExpandRow(row) {
    this.ledTasksDataGrid.refreshRowsHeight();
  }

  public get animalDescriptionEntities(): DescriptionCellEntity[] {
    return this.row.animals.map(animal => ({
      entityId: animal.id,
      entityName: animal.animalName,
    }));
  }
}
