<notification-dialog-template #dialogRef
                              [isEscapeCloseDialog]="true"
                              [modalWidth]="464"
                              [modalHeight]="191"
                              [theme]="NotificationDialogTheme.red"
                              [shIdPostfix]="'deleteEventErrorDialogComponent'">
  <ng-container header>
    <div class="alert-icon"></div>
    <div [attr.translation-id]="'MANAGE.EVENTS.DELETE_EVENT_FAIL_HEADER'">{{'MANAGE.EVENTS.DELETE_EVENT_FAIL_HEADER' | translate}}</div>
  </ng-container>
  <ng-container body>
    <span [attr.translation-id]="'MANAGE.EVENTS.DELETE_EVENT_FAIL_BODY'">{{'MANAGE.EVENTS.DELETE_EVENT_FAIL_BODY' | translate}}</span>
  </ng-container>
</notification-dialog-template>
