import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuItemType} from '../left-menu.component';
import {Router} from '@angular/router';
import {PopupViewStateService} from '../../../services/ui/view-state/popup-view-state.service';

@Component({
  selector: 'menu-item-reports',
  templateUrl: './menu-item-reports.component.html',
  styleUrls: ['./menu-item-reports.component.scss']
})
export class MenuItemReportsComponent implements OnInit {

  @Input()
  public titleKey: string;

  @Input()
  public iconClass: string;

  @Input()
  public link: string;

  @Input()
  public isOpenMenu: boolean;

  @Output()
  public onToggleMenu: EventEmitter<MenuItemType> = new EventEmitter();

  constructor(private router: Router,
              private menuItemReportsElementRef: ElementRef,
              private popupViewStateService: PopupViewStateService) {

  }

  public ngOnInit() {
    this.popupViewStateService.registerComponent('menuItemReports', this.menuItemReportsElementRef);
  }

  public get isReportsRoute() : boolean {
    return this.router.url.includes('reports/');
  }

  public onClick() {
    this.onToggleMenu.emit(MenuItemType.Reports);
  }
}
