<description-cell-details-template [hasRowDetails]="row.tag.tagType"
                                   [isRowExpanded]="isExpanded"
                                   [row]="row"
                                   [rowShId]="row.eventId"
                                   (toggleExpanded)="toggleExpanded()">
  <ng-container key>{{"ANIMAL.EVENTS.EVENT_DESCRIPTION.NEW_TAG"| translate}}:&nbsp;</ng-container>
  <ng-container value>{{row.tag.tagNumber}}</ng-container>
  <ng-container content>
    <p class="description-item" *ngIf="row.tag.tagType">
      <span>{{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.PREVIOUS_TAG' | translate}}:&nbsp;</span>
      <span class="value">{{row.previousTag.tagNumber | uppercase}}</span>
    </p>
  </ng-container>
</description-cell-details-template>
