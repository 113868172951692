<div class="culled-animals-header">
  <grid-summary [summaryTitle]="'MANAGE.TITLE.CULLED_ANIMALS'"
                [icon]="GridSummaryIcon.Cow"
                [rowCount]="culledAnimalsReport?.meta.rowTotalAfterFilter"
                [rowTotal]="culledAnimalsReport?.meta.rowTotal"></grid-summary>
  <report-actions-dropdown [reportActions]="culledAnimalsReport?.meta.reportActions"
                                  [isActionsOnLeftSide]="true"
                                  [reportQuery]="culledAnimalsReportQuery"
                                  [reportContext]="this"
                                  [report]="culledAnimalsReport"></report-actions-dropdown>
</div>
<report-grid  #culledAnimalsReportGrid
              [attr.sh-id]='"culled-animals-table-grid"'
              [reportContext]="this"
              [report]="culledAnimalsReport"
              [reportQuery]="culledAnimalsReportQuery"
              [rowHeight]="60"
              (onReportQueryChanged)="onReportQueryChanged()"
              (onReportGridValueCellClick)="onReportGridValueCellClick($event)">
</report-grid>
