<div class="create-event-form" *ngIf="mode === CreateEventMode.single && tagNumber != null">
  <div class="form-section-title" [ngClass]="{'disabled': isTagNumberInputDisabled}">
    {{ 'ANIMAL.EVENTS.ADD_EVENT.EXISTING_TAG' | translate }}
  </div>
  <div class="form-section-item">
    <input
      class="form__control"
      type="text"
      [(ngModel)]="tagNumber"
      [disabled]="isTagNumberInputDisabled"/>
  </div>
</div>
