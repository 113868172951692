import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ReportsService} from '../../../../services/reports/reports.service';
import {DataGridColumnMap} from '../../../../common/components/data-grid/model/data-grid-column-map';
import {DataGridQuery, DataGridQuerySortDetails} from '../../../../common/components/data-grid/model/data-grid-query';
import {IReport, ReportDataRow} from '../../../../services/reports/model/report.interface';
import {SystemService} from '../../../../services/system/system.service';
import {FilterSearchQuery} from '../../../../services/reports/model/report-query.interface';
import {DateTimeFormatEnum} from '../../../../common/pipes/epoch-date-time.pipe';
import * as moment from "moment";
import {IDataGridViewContext} from '../../../../services/model/common-model';
import {TranslationService} from '../../../../services/translations/translation.service';
import {GridViewState} from '../../../../services/ui/view-state/grid-row/grid-view-state';

export interface IValueObject {
  // tslint:disable-next-line:no-any
  value: any;
  valueType: string;
}

@Component({
  selector: 'sync-status-modal',
  templateUrl: './sync-status-modal.component.html',
  styleUrls: ['./sync-status-modal.component.scss']
})
export class SyncStatusModalComponent implements OnChanges, IDataGridViewContext {

  @Input()
  // tslint:disable-next-line:no-any
  public selectedSyncStatusRow: any;

  @Output()
  // tslint:disable-next-line:no-any
  public closeModal: EventEmitter<any> = new EventEmitter<any>();

  private readonly columnsMappings: Map<string, DataGridColumnMap> = new Map();

  private syncStatusSortedGridQuery: DataGridQuery;

  private readonly defaultSortColumn: string = '-SyncErrorAnimalNumberCalculation';

  public syncStatusResult: ReportDataRow[];

  public animalsFailed: number;

  public gridViewState:GridViewState = new GridViewState();

  public DateTimeFormatEnum = DateTimeFormatEnum;

  constructor(public readonly reportsService: ReportsService,
              private readonly systemService: SystemService,
              private readonly translationService: TranslationService) {
    this.columnsMappings.set('SyncErrorAnimalNumberCalculation',
      {
        enableSorting: true,
        flexGrow: 1
      });
    this.columnsMappings.set('SyncErrorResultTypeCalculation',
      {
        enableSorting: true,
        flexGrow: 2
      });
    this.columnsMappings.set('SyncErrorCurrentValueCalculation',
      {
        enableSorting: true,
        flexGrow: 1
      });
    this.columnsMappings.set('SyncErrorNewValueCalculation',
      {
        enableSorting: true,
        flexGrow: 1
      });
    this.columnsMappings.set('SyncErrorExternalIdCalculation',
      {
        enableSorting: true,
        flexGrow: 1
      });
  }

  public async ngOnChanges(changes: SimpleChanges) {
    if(this.selectedSyncStatusRow) {
      this.animalsFailed = this.getAnimalsFailed(this.selectedSyncStatusRow);
      this.setDefaultSyncStatusSortedGridQuery();
      await this.getSyncStatus();
    } else {
      this.syncStatusSortedGridQuery = null;
      this.animalsFailed = null;
      this.syncStatusResult = null;
    }
  }

  public getGridViewState(): GridViewState {
    return this.gridViewState;
  }

  public reloadDefault(): Promise<void> {
    return null;
  }

  public setDefaultSyncStatusSortedGridQuery() {
    this.syncStatusSortedGridQuery = new DataGridQuery();
    this.syncStatusSortedGridQuery.type = 'full';
    this.syncStatusSortedGridQuery.sortDetails = new DataGridQuerySortDetails();
    this.syncStatusSortedGridQuery.sortDetails.column = this.defaultSortColumn;
    const filterSearchQuery = new FilterSearchQuery();
    filterSearchQuery.equalsValue = this.selectedSyncStatusRow.SyncStatusCorrelationIdCalculation;
    this.syncStatusSortedGridQuery.filter = 'SyncErrorCorrelationIdCalculation' + filterSearchQuery.serializeToString();
  }

  public async getSyncStatus() {
    const syncStatusResponse = await this.systemService.getSyncStatusModalData(this.syncStatusSortedGridQuery);
    if (syncStatusResponse.responseBody.result.rows) {
      this.syncStatusResult = syncStatusResponse.responseBody.result.rows;
    }
  }

  public onCloseModal() {
    this.closeModal.emit();
  }

  public getAnimalsFailed(rowData): number {
    // tslint:disable-next-line:no-any
    const resultsStatus: any = JSON.parse(rowData['SyncStatusResultsCalculation']);
    if (resultsStatus && resultsStatus.statistics !== null) {
      return resultsStatus.statistics.failedAnimals;
    } else {
      return 0;
    }
  }

  // tslint:disable-next-line:no-any
  public getValueByType(vO: any) {
    const valueObject: IValueObject = JSON.parse(vO);
    let valueToReturn: string = '-';
    if (valueObject.valueType === 'String') {
      if (valueObject.value) {
        valueToReturn = valueObject.value;
      } else {
        valueToReturn = '-';
      }
    } else if (valueObject.valueType === 'DateTime') {
      const format: string = this.translationService.translate('LOCALES.DATE_FORMAT.DEFAULT.DATE_TEMPLATE') + ' HH:mm';
      if (valueObject.value) {
        valueToReturn = moment.unix(valueObject.value).format(format);
      } else {
        valueToReturn = '-';
      }
    }
    return valueToReturn;
  }
}
