<ejs-chart id='chart-container'
           #xyChart
           *ngIf="series"
           (mousewheel)="onScroll($event)"
           (mouseleave)="onChartMouseLeave()"
           (chartMouseMove)="onChartMouseMove($event)"
           (chartMouseDown)="onChartMouseDown($event)"
           (chartMouseUp)="onChartMouseUp()"
           (axisLabelRender)="axisLabelRender($event)"
           (resized)="onChartResized()">
</ejs-chart>
<div class="selected-point-details" *ngIf="selectedChartPoint" [ngStyle]="{'margin-left': selectedPointDetailsLeftMargin + 'px'}">
  <span class="selected-point-date">{{selectedChartPoint.x | epochDateTime:[DateTimeFormatEnum.MonthAbbreviated, DateTimeFormatEnum.DayOfMonth] : timeIsUtc}}</span>
  <span class="selected-point-time">{{selectedChartPoint.x | epochDateTime:DateTimeFormatEnum.Time : timeIsUtc}}</span>
</div>
<div class="duration-selection-area" *ngIf="selectedDurationLabel && series">
  <div class="selected-duration-label" (click)="toggleDurationsVisibility()">{{selectedDurationLabel.selectedDisplayText}}</div>
  <div class="duration-options" [ngClass]="{'duration-options-expanded': openDurationSelection}">
    <div class="option-duration-label" (click)="onDurationSelected(optionDurationLabel)"
                                       [ngClass]="{'selected': optionDurationLabel === selectedDurationLabel}"
                                       *ngFor="let optionDurationLabel of optionsDurationLabels">{{optionDurationLabel.optionDisplayText}}</div>
  </div>
</div>
<div class="series-event" *ngFor="let event of eventViewModels"
                          [ngStyle]="{'margin-top' : eventsMarginTop + 'px',
                                      'margin-left' : event.marginLeft + 'px',
                                      'visibility' : event.visible ? 'visible' : 'hidden'}" >
  <span [ngClass]="'event-icon-' + event.pointData[0].name + (event.multipleEvents ? '-multi' : '')"></span>
</div>
<div #hoverEventDetails
     class="hover-event-details"
     *ngIf="hoverEventViewModel"
     [ngStyle]="{'margin-left': hoverEventDetailsLeftMargin + 'px',
                 'margin-top': xyChart.initialClipRect.y + 'px',
                 'visibility': _hoverEventDetailsLeftMargin == null ? 'hidden' : 'visible',
                 'height': xyChart.initialClipRect.height - 20 + 'px'}">
  <div class="hover-events-popup">
    <div class="events-content">
      <div class="event-item" *ngFor="let eventPoint of hoverEventViewModel.pointData.slice(0, 4)">
        <span class="event-icon-wrapper" [ngClass]="'event-icon-' + eventPoint.name"></span>
        <ng-container [ngSwitch]="eventPoint.name">
          <span *ngSwitchCase="'note'" class="text event-type"><ellipsisable-text [maxChars]="50">{{eventPoint.tooltip}}</ellipsisable-text></span>
          <span *ngSwitchCase="'generalEvent'" class="text event-type"><ellipsisable-text [maxChars]="50">{{eventPoint.tooltip}}</ellipsisable-text></span>
          <span *ngSwitchDefault class="text event-type"><ellipsisable-text [maxChars]="50">{{'ANIMAL.EVENTS.EVENT_TYPE.' + eventPoint.name.replace("Event", "") | translate}}</ellipsisable-text></span>
        </ng-container>
        <span class="text event-date">{{eventPoint.x | epochDateTime:dateTimeFormat : timeIsUtc}}</span>
      </div>
    </div>
  </div>
</div>
