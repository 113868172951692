<div class="action-wrapper" outsideClickHandler (onOutsideClick)="closePopup()"
[ngClass]="isOpen ? 'action-wrapper_open' : ''">
  <div class="print-btn" *ngIf="!isExportToFileOrPastReportPopupShown" [attr.sh-id]="ShIdPrefix + 'print-btn'"  (click)="onPrintClick($event)">
    <div class="print-icon"></div>
  </div>
  <div class="no-print-btn" *ngIf="isExportToFileOrPastReportPopupShown"></div>
  <div class="separator" *ngIf="!isExportToFileOrPastReportPopupShown"></div>
  <div class="actions-dropdown" [ngClass]="{'actions-dropdown-left': !isActionsOnLeftSide}">
    <div class="actions-dropdown-button" (click)="toggleActionsDropdown()" [attr.sh-id]="'report-actions-dropdown-toggle'">
      <div class="actions-button-text" [attr.translation-id]="'COMMON.ACTIONS.MORE_ACTIONS'" *ngIf="!showBatchActions" sh-id="actions-dropdown-more-actions">
        {{ 'COMMON.ACTIONS.MORE_ACTIONS' | translate }}
      </div>
      <div class="actions-button-text" [attr.translation-id]="'COMMON.ACTIONS.BATCH_ACTIONS'" *ngIf="showBatchActions">
        {{ 'COMMON.ACTIONS.BATCH_ACTIONS' | translate }}
      </div>
      <div class="dropdown-icon-wrapper" [ngClass]="{'open' : isOneOfReportActionPopupsIsOpen}">
        <div class="down-icon" *ngIf="!isOneOfReportActionPopupsIsOpen"></div>
        <div class="up-icon" *ngIf="isOneOfReportActionPopupsIsOpen"></div>
      </div>
    </div>
  </div>
  <div class="dropdown-popup" [ngClass]="{ left: isActionsOnLeftSide, right: !isActionsOnLeftSide }" *ngIf="isOpen" [attr.sh-id]="'reports-dropdown-popup-menu'">
    <div class="decorative-shadow"></div>
    <ul class="actions-list" *ngIf="!showBatchActions">
      <li *ngFor="let action of reportActions" [attr.sh-id]="'actions-dropdown-' + action.type" [attr.translation-id]="'COMMON.ACTIONS.' + getTextType(action.type)" (click)="onActionClicked(action.type, $event)">{{ 'COMMON.ACTIONS.' + getTextType(action.type) | translate }}</li>
    </ul>
    <ul class="actions-list" *ngIf="showBatchActions">
      <li *ngFor="let batchAction of reportBatchActions" [attr.translation-id]="'COMMON.ACTIONS.' + getTextType(batchAction.type)" (click)="onBatchActionClicked(batchAction.type, $event)" [attr.sh-id]="'batch-actions-dropdown-' + batchAction.type">{{ getBatchActionsText(batchAction.type) }}</li>
    </ul>
    <div class="dropdown-frame-corner"></div>
  </div>
  <div class="export-to-file-popup-wrapper" [ngClass]="{ left: isActionsOnLeftSide, right: !isActionsOnLeftSide }" outsideClickHandler (onOutsideClick)="closeExportToFilePopup()" *ngIf="isShowExportToFilePopup" [attr.sh-id]="'export-to-csv-file-popup'">
    <div class="decorative-shadow"></div>
    <div class="export-to-file-popup">
      <div class="export-to-file-header">
        <div class="back-btn" (click)="goBackToActionsMenu()" [attr.sh-id]="'back-to-report-actions-menu'">
          <div class="back-btn-icon"></div>
          <div class="back-btn-text" [attr.translation-id]="'REGISTRATION.FLOW.BACK'">{{ 'REGISTRATION.FLOW.BACK' | translate }}</div>
        </div>
        <div class="close-btn" (click)="closeExportToFilePopup()" [attr.sh-id]="'close-export-to-file-popup'"></div>
      </div>
      <div class="export-to-file-body">
        <div class="title" [attr.translation-id]="'COMMON.ACTIONS.EXPORT'">{{ 'COMMON.ACTIONS.EXPORT' | translate }}:</div>
        <div class="export-btn" (click)="exportToCSVFile()" [attr.sh-id]="'export-to-csv-file-btn'">CSV</div>
      </div>
    </div>
    <div class="export-to-file-frame-corner"></div>
  </div>
</div>
<date-time-dialog #pastReportDateTimeDialog
                  [epochState]="pastReportDate"
                  [title]="'COMMON.ACTIONS.SHOW_PAST_REPORT'"
                  (onApply)="showPastReport()"></date-time-dialog>
