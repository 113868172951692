import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'breeding-window',
  templateUrl: './breeding-window.component.html',
  styleUrls: ['./breeding-window.component.scss']
})
export class BreedingWindowComponent implements OnInit {

  @Input()
  public inseminationWindowCalculation: number;

  @Input()
  public isExpanded: boolean;

  constructor() { }

  public ngOnInit() {
  }

  public get calculateLeftOffset() : number{
    return (100 - ((this.inseminationWindowCalculation + 4) / 30 * 100))
  }
}
