export class FarmStatisticsDairyWidget {
  public animals: IFarmStatisticsDairy;
  public cows: IFarmStatisticsDairy;
  public heifers: IFarmStatisticsDairy;
}

export interface IFarmStatisticsDairy {
  subGroups: IFarmStatisticsDairyTotal[][];
  total: IFarmStatisticsDairyTotal;
}

export interface IFarmStatisticsDairyTotal {
  count: number;
  label: string;
  color?: string;
}
