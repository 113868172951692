<ng-container [ngSwitch]="column">
  <div class="reason-cell" [ngClass]="{ disabled: !row.isEnabled }" [attr.sh-id]="'reason-cell-' + row.id" *ngSwitchCase="LedTasksColumns.reason" [attr.translation-id]="getReportNameByReportId(row)">
    <div class="suspend-icon" [attr.sh-id]="'led_task_suspend_icon_' + row.id" *ngIf="!row.isEnabled"></div>
    <ellipsisable-text class="reason-text">{{ getReportNameByReportId(row) | translate }}</ellipsisable-text>
  </div>
  <div class="time-cell" [ngClass]="{ disabled: !row.isEnabled }" *ngSwitchCase="LedTasksColumns.time">
    {{ row.startTime | epochDateTime:dateTimeFormatEnum.Time:true }}-{{ row.endTime | epochDateTime:dateTimeFormatEnum.Time:true }}
  </div>
  <div class="effective-group-cell" [ngClass]="{ disabled: !row.isEnabled }" *ngSwitchCase="LedTasksColumns.effectiveGroupsAnimals">
    <description-cell-entities [isDisabled]="!row.isEnabled"
                               [noEntitiesText]="'LED_TASKS.GRID.ALL_GROUPS'"
                               [row]="row"
                               [entities]="groupDescriptionEntities"
                               (onToggleExpanded)="toggleExpandRow($event)"></description-cell-entities>
  </div>
  <div class="repeats-cell" [ngClass]="{ disabled: !row.isEnabled }" *ngSwitchCase="LedTasksColumns.repeats">
    <span>{{ convertWeekdayToText(row) }}</span>
  </div>
  <div class="actions-cell" *ngSwitchCase="LedTasksColumns.actions">
    <row-actions-popup [rowActions]="rowActionsMap.get(row)"
                       [shId]="row.id.toString()"
                       (onPopupOpen)="onRowActionsOpen()"
                       (onRowActionClick)="handleRowAction($event.rowActionPopupItem.action, row)">
    </row-actions-popup>
  </div>
</ng-container>
