import { Injectable } from '@angular/core';
import {HttpResponseResult, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from '../auth/auth.service';
import {
  IScheduledTaskModelItem, LedModeSettings,
  LedTaskFields,
  LedTaskReportsList,
  LedTasksResult,
  ScheduledTaskModel,
  TaskModel
} from './models/led-tasks.interface';
import {HttpParams} from '@angular/common/http';
import {DataGridQuery} from '../../common/components/data-grid/model/data-grid-query';
import * as _ from 'lodash';
import {ErrorModel} from '../model/common-model';

export interface IToggleSuspendRequestModel {
  isEnabled: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LedTasksService {

  private static readonly ScheduledTasksRoute = '/rest/api/ledoperation/scheduledtask';

  private static readonly  ScheduledTasksReportsListRoute = '/rest/api/ledoperation/reports';

  public taskModel: TaskModel;

  public originalTaskModel: TaskModel;

  public ledModeSettings: LedModeSettings = new LedModeSettings();

  public Error: ErrorModel = null;

  constructor(private restApiService: RestApiService, private authService: AuthService) { }

  public getAllScheduledTasks(scheduledTasksDataGridQuery: DataGridQuery): Promise<HttpResponseResult<LedTasksResult>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('offset', scheduledTasksDataGridQuery.offset.toString());
    httpParams = httpParams.append('limit', scheduledTasksDataGridQuery.limit.toString());
    if (scheduledTasksDataGridQuery.sortedColumn !== null) {
      httpParams = httpParams.append('sort', scheduledTasksDataGridQuery.sortedColumn);
    }
    return this.restApiService.sendGetMethodAny<LedTasksResult>(LedTasksService.ScheduledTasksRoute, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public async getScheduledTaskById(taskId: number): Promise<ScheduledTaskModel> {
    const response = await this.restApiService.sendGetMethodAny<LedTasksResult>(LedTasksService.ScheduledTasksRoute, this.authService.currentUserAuthState.authDetails);
    const tasksList: ScheduledTaskModel[] = response.responseBody.result;
    return tasksList.find((task: ScheduledTaskModel) => task.id == taskId);
  }

  public addLedTask(scheduledTaskModel: IScheduledTaskModelItem): Promise<HttpResponseResult<void>> {
    return this.restApiService.sendPostMethod(LedTasksService.ScheduledTasksRoute, this.authService.currentUserAuthState.authDetails, scheduledTaskModel);
  }

  public editLedTask(scheduledTaskModel: IScheduledTaskModelItem): Promise<HttpResponseResult<void>> {
    return this.restApiService.sendPutMethod(LedTasksService.ScheduledTasksRoute + `/${scheduledTaskModel.id}`, this.authService.currentUserAuthState.authDetails, scheduledTaskModel);
  }

  public deleteLedTask(taskId: number) {
    return this.restApiService.sendDeleteMethod(LedTasksService.ScheduledTasksRoute + '/' + taskId, this.authService.currentUserAuthState.authDetails);
  }

  public async toggleSuspendTask(taskId: number, isEnabled: boolean): Promise<HttpResponseResult<ScheduledTaskModel>> {
    let request: IToggleSuspendRequestModel = { isEnabled: isEnabled };
    return this.restApiService.sendPutMethod<ScheduledTaskModel>(LedTasksService.ScheduledTasksRoute + '/' + taskId + '/enabled', this.authService.currentUserAuthState.authDetails, request);
  }

  public async hasLedTasks() {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('offset', '0');
    httpParams = httpParams.append('limit', '1000');
    let response: HttpResponseResult<LedTasksResult> = await this.restApiService.sendGetMethodAny<LedTasksResult>(LedTasksService.ScheduledTasksRoute, this.authService.currentUserAuthState.authDetails, httpParams);
    if (response) {
      if (response.status === 200) {
        return response.responseBody.result.length > 0;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public getReportsList(): Promise<HttpResponseResult<LedTaskReportsList>> {
    return this.restApiService.sendGetMethod<LedTaskReportsList>(LedTasksService.ScheduledTasksReportsListRoute, this.authService.currentUserAuthState.authDetails);
  }

  public get isMadeChanges() {
    return !(_.isEqual(this.taskModel, this.originalTaskModel));
  }

  public isFieldError(field: LedTaskFields): boolean {
    return this.Error && this.Error.fieldName === field;
  }

  public clearErrors() {
    this.Error = null;
  }
}
