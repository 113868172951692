export enum EntityAlertType {
  Cow = 'cow',
  Group = 'group'
}

export enum AlertNavigation {
  groupRoutineRuminationGraph = 'groupRoutineRuminationGraph',
  groupRoutineActivityGraph = 'groupRoutineActivityGraph',
}

export interface IEntityAlert {
  entityType: EntityAlertType;
  calculatedTime: string;
  alertDateTime: number;
  alertLocalization: string;
  alertType: string;
  entityId: number;
  entityNumber: string;
  navigation: AlertNavigation;
}
