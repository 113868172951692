import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {RegistrationService} from '../../../../../services/registration/registration.service';
import {DropdownListItem} from '../../../../../common/components/dropdown-list/dropdown-list-model';
import {SignUpErrorFields} from '../terms-and-conditions/terms-and-conditions.component';
import {DropdownColorScheme} from '../../../../../common/components/dropdown-list/regular-dropdown-list/regular-dropdown-list.component';
import {TranslationService} from '../../../../../services/translations/translation.service';

@Component({
  selector: 'security-questions',
  templateUrl: './security-questions.component.html',
  styleUrls: ['./security-questions.component.scss']
})
export class SecurityQuestionsComponent implements OnInit {

  public signUpErrorFields = SignUpErrorFields;

  public dropdownColorScheme = DropdownColorScheme;

  public securityQuestions: DropdownListItem[] = [
    {
      value: 1,
      displayValue: this.translationService.translate('COMMON.SECURITY_QUESTIONS.WHAT_IS_YOUR_FAVORITE_MOVIE'),
      disabled: true,
      icon: null
    },
    {
      value: 2,
      displayValue: this.translationService.translate('COMMON.SECURITY_QUESTIONS.WHAT_IS_YOUR_MOTHERS_MAIDEN_NAME'),
      disabled: true,
      icon: null
    },
    {
      value: 3,
      displayValue: this.translationService.translate('COMMON.SECURITY_QUESTIONS.WHAT_IS_THE_NAME_OF_YOUR_FIRST_GRADE_TEACHER'),
      disabled: false,
      icon: null
    },
    {
      value: 4,
      displayValue: this.translationService.translate('COMMON.SECURITY_QUESTIONS.WHAT_IS_YOUR_FAVORITE_COLOR'),
      disabled: false,
      icon: null
    },
    {
      value: 5,
      displayValue: this.translationService.translate('COMMON.SECURITY_QUESTIONS.WHAT_IS_THE_NAME_OF_YOUR_FIRST_PET'),
      disabled: false,
      icon: null
    }
  ];

  public questionOne: ISecurityQuestionObject = { key: 1, value: null };

  public questionTwo: ISecurityQuestionObject = { key: 2, value: null };

  public selectedQuestionOne: DropdownListItem = null;

  public selectedQuestionTwo: DropdownListItem = null;

  constructor(private router: Router,
              public registrationService: RegistrationService,
              private translationService: TranslationService) { }

  public ngOnInit() {
    this.questionOne = this.registrationService.registrationModel.createAccount.account.questionOne;
    if (this.questionOne && this.questionOne.key) {
      this.selectedQuestionOne = this.securityQuestions.find((question: DropdownListItem) => question.value === this.questionOne.key);
    } else {
      this.selectedQuestionOne = this.securityQuestions[0];
      this.questionOne = { key: 1, value: null };
    }
    this.questionTwo = this.registrationService.registrationModel.createAccount.account.questionTwo;
    if (this.questionTwo && this.questionTwo.key) {
      this.selectedQuestionTwo = this.securityQuestions.find((question: DropdownListItem) => question.value === this.questionTwo.key);
    } else {
      this.selectedQuestionTwo = this.securityQuestions[1];
      this.questionTwo = { key: 2, value: null };
    }
  }

  public selectQuestion(question: DropdownListItem, questionNumber: number) {
    switch (questionNumber) {
      case 1: {
        this.securityQuestions.forEach((currentQuestion: DropdownListItem) => {
          if (question) {
            currentQuestion.disabled = (question.value === currentQuestion.value || this.selectedQuestionTwo && this.selectedQuestionTwo.value === currentQuestion.value);
          } else {
            currentQuestion.disabled = !!this.selectedQuestionTwo && (this.selectedQuestionTwo.value === currentQuestion.value);
          }
        });
        if (question) {
          this.selectedQuestionOne = question;
          if (this.isErrorField(this.signUpErrorFields.questionOne)) {
            this.registrationService.registrationModel.error = null;
          }
        } else {
          this.selectedQuestionOne = null;
        }
        break;
      }
      case 2: {
        this.securityQuestions.forEach((currentQuestion: DropdownListItem) => {
          if (question) {
            currentQuestion.disabled = (question.value === currentQuestion.value || this.selectedQuestionOne && this.selectedQuestionOne.value === currentQuestion.value);
          } else {
            currentQuestion.disabled = !!this.selectedQuestionOne && (this.selectedQuestionOne.value === currentQuestion.value);
          }
        });
        if (question) {
          this.selectedQuestionTwo = question;
          if (this.isErrorField(this.signUpErrorFields.questionTwo)) {
            this.registrationService.registrationModel.error = null;
          }
        } else {
          this.selectedQuestionTwo = null;
        }
        break;
      }
    }
  }

  public async navigateBack() {
    this.clearErrors();
    await this.router.navigate(['/registration/sign-up/account-details']);
  }

  public async navigateNext() {
    if (this.validateStep()) {
      this.registrationService.registrationModel.createAccount.account.questionOne = { key: this.selectedQuestionOne.value, value: this.questionOne.value };
      this.registrationService.registrationModel.createAccount.account.questionTwo = { key: this.selectedQuestionTwo.value, value: this.questionTwo.value };
      this.clearErrors();
      await this.router.navigate(['/registration/sign-up/terms-and-conditions']);
    }
  }

  public validateStep(): boolean {
    if (!this.selectedQuestionOne) {
      this.registrationService.registrationModel.error = { fieldName: SignUpErrorFields.questionOne, error: 'FIRST_QUESTION_REQUIRED' };
      return false;
    }
    if (this.isEmpty(this.questionOne.value) || this.isEmpty(String(this.questionOne.key))) {
      this.registrationService.registrationModel.error = { fieldName: SignUpErrorFields.answerOne, error: 'FIRST_ANSWER_REQUIRED' };
      return false;
    }
    if (!this.selectedQuestionTwo) {
      this.registrationService.registrationModel.error = { fieldName: SignUpErrorFields.questionTwo, error: 'SECOND_QUESTION_REQUIRED' };
      return false;
    }
    if (this.isEmpty(this.questionTwo.value) || this.isEmpty(String(this.questionTwo.key))) {
      this.registrationService.registrationModel.error = { fieldName: SignUpErrorFields.answerTwo, error: 'SECOND_ANSWER_REQUIRED' };
      return false;
    }
    return true;
  }

  public isEmpty(string: string) {
    return (!string || 0 === string.length);
  }

  public clearErrors() {
    this.registrationService.registrationModel.error = null;
  }

  public isErrorField(errorField: SignUpErrorFields) {
    return (this.registrationService.registrationModel.error && this.registrationService.registrationModel.error.fieldName === errorField);
  }

}
export interface ISecurityQuestion {
  key: number;
  value: string;
  disabled: boolean;
}
export interface ISecurityQuestionObject {
  key: number;
  value: string;
}
