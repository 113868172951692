import {
  Component,
  Input,
  SimpleChanges
} from '@angular/core';
import {
  CreateAssignTagEventDetailsBase,
  EventTypeEnum,
  AssignTagCowEventMetadataBase
} from '../../../../../services/animals/model/animal-events';
import {ITag, TagType} from '../../../../../services/tags/model/tags-model';
import {EventFormComponent} from '../event-form.component';

@Component({
  selector: 'assign-tag-event-form',
  templateUrl: './assign-tag-event-form.component.html',
  styleUrls: ['./assign-tag-event-form.component.scss']
})
export class AssignTagEventFormComponent extends EventFormComponent<
  AssignTagCowEventMetadataBase,
  CreateAssignTagEventDetailsBase
> {

  public unassignedTags: string[] = [];

  public get tagNumber(): string | null {
    return this.editModel.tag != null ? this.editModel.tag.tagNumber : null;
  }

  public set tagNumber(newValue: string) {
    const getTag = (): ITag | null => {
      if (newValue == null || newValue.length === 0) {
        return null;
      }
      if(this.meta.unassignedTags != null ) {
        const existsUnassigedTag = this.meta.unassignedTags.find((ut) => ut.tagNumber === newValue);
        if (existsUnassigedTag != null) {
          return existsUnassigedTag;
        }
      }
      return {
        tagNumber: newValue,
        tagType: this.editModel.type === EventTypeEnum.AssignScrTag ? TagType.scr : TagType.rfId
      };
    };

    this.editModel.tag = getTag();
    this.emitModelChanged();
  }

  constructor() {
    super();
  }

  public onChanges(changes: SimpleChanges): void {
    if (changes.meta != null) {
      if(this.meta.unassignedTags) {
        this.unassignedTags = this.meta.unassignedTags.map((ut) => ut.tagNumber);
      } else {
        this.unassignedTags = [];
      }
    }
  }

  public onTagSelected(tagNumber: string) {
    this.editModel.tag = this.meta.unassignedTags.find(
      (value) => value.tagNumber === tagNumber
    );

    this.emitModelChanged();
  }
}
