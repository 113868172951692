import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MockHttpEndpointTemplate, MockHttpService} from '../../services/mock-http.service';
import {Router} from '@angular/router';
import {InputFilterMode} from "../../../common/directives/input-filter.directive";



@Component({
  selector: 'mock-http-management',
  templateUrl: './mock-http-management.component.html',
  styleUrls: ['./mock-http-management.component.scss']
})
export class MockHttpManagementComponent{

  public endpoint:string;

  public template:string;

  InputFilterMode = InputFilterMode;

  constructor(public mockHttpService:MockHttpService,
              private router: Router) {
  }
  
  @HostListener('window:message', ['$event'])
  public onMessage(evt: MessageEvent) {
    this.template = evt.data;
  }

  public async load() {
    let templateObj = await this.mockHttpService.getTemplate(this.endpoint);
    this.template = JSON.stringify(templateObj, null, '  ');
  }

  public async save(refresh: boolean) {

    let templateModel : MockHttpEndpointTemplate = JSON.parse(this.template);
    await this.mockHttpService.setTemplate(templateModel);
    if(refresh) {
      let currentUrl = this.router.url;
      await this.router.navigate(['/testing-refresh-blank']);
      await this.router.navigate([currentUrl])
    }
  }

  private async endpointSelected(endpoint:string) {
    this.endpoint = endpoint;
    await this.load();
  }
}
