import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {ConfirmDialogThemes, ConfirmDialogIcons} from "../../../common/components/dialogs/confirm-dialog/confirm-dialog.component";
import {FarmMode} from "../../../services/config/model/server-config";
import {ConfirmTrialFarmModeDialogComponent} from "../../../common/components/dialogs/confirm-dialog/confirm-trial-farm-mode-dialog/confirm-trial-farm-mode-dialog.component";
import {AuthService, ITrialModeStatus} from "../../../services/auth/auth.service";
import {RegistrationService} from "../../../services/registration/registration.service";
import {GoogleAnalyticsService} from "../../../services/google-analytics/google-analytics.service";
import {TrialOperation} from "../../../services/config/model/anonymous-auth-config";

@Component({
  selector: 'trial-operation-type-selection',
  templateUrl: './trial-operation-type-selection.component.html',
  styleUrls: ['./trial-operation-type-selection.component.scss']
})
export class TrialOperationTypeSelectionComponent implements OnInit {

  @ViewChild(ConfirmTrialFarmModeDialogComponent, {static: false})
  public confirmTrialFarmModeDialog: ConfirmTrialFarmModeDialogComponent;

  public selectedOperationType: TrialOperation;

  public TrialOperation = TrialOperation;

  public isDialogShown: boolean = false;

  public FarmMode = FarmMode;

  public ConfirmDialogThemes = ConfirmDialogThemes;

  public ConfirmDialogIcons = ConfirmDialogIcons;

  constructor(private readonly router: Router,
              private readonly registrationService: RegistrationService,
              private readonly authService: AuthService,
              private readonly googleAnalyticsService: GoogleAnalyticsService
              ) { }

  public ngOnInit() {
  }

  public closeDialog() {
    this.confirmTrialFarmModeDialog.closeDialog();
  }

  public async goToDashboard() {
    await this.authService.setIsStartedTrialModeForTheFirstTime(false);
    this.confirmTrialFarmModeDialog.closeDialog();
    // tslint:disable-next-line:no-any
    const request: any = {
      type: 'trialMode',
      farmMode: FarmMode.Beef,
      operation: this.selectedOperationType
    };
    // tslint:disable-next-line:no-any
    const response: any = await this.registrationService.postTrialMode(request);
    if (response.status === 201) {
      let farmMode: FarmMode = response.responseBody.farmMode;
      // call anonymous login to update the trial farm mode status.
      await this.authService.performAnonymousLogin();
      this.googleAnalyticsService.send('Registration', 'start trial', farmMode);
      const trialModeStatus: ITrialModeStatus = this.authService.getTrialModeStatus();
      if (!trialModeStatus) {
        this.authService.setIsStartedTrialModeForTheFirstTime(true);
      } else {
        this.authService.setIsStartedTrialModeForTheFirstTime(false);
      }
      await this.registrationService.loginAsTrialUser();
    }
  }

  public navigateToFarmModeSelection() {
    this.router.navigate(['/trial-farm-mode-selection']);
  }

  public apply() {
    this.confirmTrialFarmModeDialog.showDialog();
  }
}
