import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
  CowEventMetadataBase,
  CreateEventMode,
  EventDetails,
  EventDetailsError,
  EventTypeEnum,
  IAvailableEventInfo
} from '../../../../services/animals/model/animal-events';
import {
  AnimalEventsService,
  BatchAnimal,
  BatchAnimalValidationState,
  CreateBatchEventResponse
} from '../../../../services/animals/animal-events.service';
import {SearchService} from '../../../../services/search/search.service';
import {OperationType, SearchEntry} from '../../../../services/search/model/search.model';
import {catchError, filter, tap} from 'rxjs/operators';
import {EMPTY, Observable, Subscription} from 'rxjs';
import {AutoCompleteColorScheme} from 'src/app/common/components/auto-complete/auto-complete-model';
import {ApplicationBundleRoutingMode, RoutingService} from '../../../../services/routing/routing.service';
import {FarmMode} from '../../../../services/config/model/server-config';
import {ConfigService} from '../../../../services/config/config.service';
import {ActivatedRoute} from '@angular/router';
import {FormsAutoCompleteComponent} from '../../../../common/components/auto-complete/forms-auto-complete/forms-auto-complete.component';
import * as moment from 'moment';
import {BatchEventErrorsDialogService} from '../../../animal-events/animal-events-editor/dialogs/batch-event-errors-dialog/batch-event-errors-dialog.service';
import {CancelBatchEventDialogService} from '../../../animal-events/animal-events-editor/dialogs/cancel-batch-event-dialog/cancel-batch-event-dialog.service';
import {ErrorModel} from '../../../../services/model/common-model';
import {SystemService} from '../../../../services/system/system.service';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../../common/components/calendar/model/epoch-state-model';
import {SuccessDialogService} from '../../../../common/components/dialogs/success-dialog/success-dialog.service';
import {LoadingIconService} from "../../../../services/loading-icon/loading-icon.service";
import { CornerColor, CornerContainerBodyBackground } from 'src/app/common/components/containers/corner-container/corner-container.component';
import {PollingKeyNames, PollingService} from '../../../../services/rest-api/polling.service';
import {SaveDataViewStateService} from '../../../../services/ui/view-state/save-data-view-state.service';
import {AnimalCardEventsMenuComponent} from '../../../animal-events/animal-events-editor/animal-card-events-menu/animal-card-events-menu.component';
import {ChipsItemBackgroundColor} from '../../../../common/components/chips/chips-item.component';

@Component({
  selector: 'manage-create-event',
  templateUrl: './manage-create-event.component.html',
  styleUrls: ['./manage-create-event.component.scss']
})
export class ManageCreateEventComponent implements OnInit, OnDestroy {
  private animalsList: SearchEntry[];

  private readonly subscription = new Subscription();

  public CornerColor = CornerColor;

  public CornerContainerBodyBackground = CornerContainerBodyBackground;

  @ViewChild('autoCompleteAddAnimal')
  private autoCompleteAddAnimal: FormsAutoCompleteComponent;

  @ViewChild('animalCardEventsMenu')
  public animalCardEventsMenu: AnimalCardEventsMenuComponent;

  public get isEventCreationAvailable(): boolean {
    return this.availableEvents && this.availableEvents.length > 0;
  }

  public get isBatchAnimalsInvalid(): boolean {
    if (this.selectedAnimals == null) {
      return true;
    }

    return this.selectedAnimals.some((ba) => ba.validationState === 'invalid');
  }

  public get isSaveEnabled(): boolean {
    return (
      this.selectedAnimals.length > 0 &&
      this.eventDetails &&
      this.eventDetails.type &&
      this.selectedAnimals.every((sa) => this.selectionMode === 'batch' ? sa.validationState === 'valid' : true)
    );
  }

  public get isBatchListVisible(): boolean {
    return this.selectionMode === 'batch' && this.selectedAnimals.length > 0;
  }

  public get availableAnimals(): string[] {
    if (this.selectionMode === 'batch') {
      return this.animalsNames.filter(name => this.selectedAnimals.find(sa => sa.chipName === name) == null);
    } else {
      return this.animalsNames;
    }
  }

  public set selectedAnimal(newValue: string | null) {
    this.sellectedAnimal = newValue;
    this.onAnimalSelected(newValue);
  }

  public get selectedAnimal(): string | null {
    return this.sellectedAnimal;
  }

  private sellectedAnimal: string | null = null;

  public animalsNames: string[] = [];

  public isOpen: boolean = false;

  public availableEvents: IAvailableEventInfo[] = [];

  public selectedEvent: IAvailableEventInfo;

  public selectedEventMeta: CowEventMetadataBase;

  public eventDetails: EventDetails;

  public eventDetailsError: ErrorModel = new ErrorModel();

  public selectedEpoch: EpochStateModel = new EpochStateModel(EpochStateModelMinMaxMode.Date);

  public selectedAnimals: BatchAnimal[] = [];

  public selectionMode: CreateEventMode = CreateEventMode.single;

  private queryParamsSubscription: Subscription;

  public applicationBundleRoutingMode: ApplicationBundleRoutingMode;

  public applicationBundleRoutingModes = ApplicationBundleRoutingMode;

  public AutoCompleteColorScheme = AutoCompleteColorScheme;

  public selectionModes = CreateEventMode;

  @Output()
  public toggleClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public onCreateEventResizeMade: EventEmitter<void> = new EventEmitter<void>();

  public EventTypeEnum = EventTypeEnum;

  public readonly EventEditorAreaName: string = 'eventEditor';

  public readonly ChipsItemBackgroundColor = ChipsItemBackgroundColor;

  private deviceType: string = null;

  constructor(
    private searchService: SearchService,
    private animalEventsService: AnimalEventsService,
    private readonly batchEventErrorsDialogService: BatchEventErrorsDialogService,
    private readonly cancelBatchEventDialogService: CancelBatchEventDialogService,
    private readonly loadingIconService: LoadingIconService,
    private readonly successDialogService: SuccessDialogService,
    public configService: ConfigService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private systemService: SystemService,
    private readonly pollingService: PollingService,
    private readonly saveDataViewStateService:SaveDataViewStateService,
    public elementRef: ElementRef) {
  }

  public async ngOnInit(): Promise<void> {
    this.queryParamsSubscription = this.route.queryParams.subscribe(async queryParams => {
      // get the current application bundle
      if (this.configService.serverConfig.farmMode === FarmMode.Dairy) {
        this.applicationBundleRoutingMode = null;
      } else {
        this.applicationBundleRoutingMode = this.routingService.getApplicationBundleRoutingMode(queryParams);
      }
      await this.getAvailableEventsAccordingToCurrentApplicationBundle();
      await this.getAnimalsListFromSearchEntities();
      await this.getDeviceType();
    });

    this.subscription.add(
      this.pollingService.pollingChangesState.subscribe(async (pollsChanges) => {
        if (pollsChanges.includes(PollingKeyNames.search)) {
          await this.getAnimalsListFromSearchEntities();
        }
      })
    );

    this.subscription.add(
      this.saveDataViewStateService.onApproveDataLossClick.subscribe(() => {
        this.saveDataViewStateService.activeEditArea = null;
      })
    );
  }

  public async getDeviceType(): Promise<void> {
    const response = await this.systemService.getSystemLastSync();
    this.deviceType = response.responseBody.deviceType || null;
  }

  public async getAnimalsListFromSearchEntities() {
    this.animalsNames = [];
    let animalList: SearchEntry[] = await this.searchService.listOfAvailableAnimals();
    this.animalsList = animalList.filter(
      (value) => (this.applicationBundleRoutingMode == ApplicationBundleRoutingMode.Breeding && value.operationType != OperationType.BeefFinishing) ||
          (this.applicationBundleRoutingMode == ApplicationBundleRoutingMode.Finishing && value.operationType == OperationType.BeefFinishing) ||
          (this.applicationBundleRoutingMode == null && value.operationType == OperationType.Dairy)
    );
    this.animalsList.forEach((value) => {
      this.animalsNames.push(value.entityName);
    });
  }

  public async getAvailableEventsAccordingToCurrentApplicationBundle() {
    // get available events according to the current application bundle
    if (this.applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing) {
      this.availableEvents = await this.animalEventsService.getAvailableFinishingBatchEvents(moment().unix());
    } else {
      this.availableEvents = await this.animalEventsService.getAvailableBatchEvents(moment().unix());
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onModelChanged(eventDetails: EventDetails) {
    this.clearErrors();
    this.eventDetails = eventDetails;
    if (this.selectionMode === CreateEventMode.batch && this.selectedAnimals.length > 0) {
      this.revalidateBatchAnimals();
    }
  }

  public save(event: Event): void {
    if (!this.isSaveEnabled) {
      return;
    }
    this.loadingIconService.show();
    const getAction = (): Observable<CreateBatchEventResponse> => {
      let createBatchEvent$: Observable<CreateBatchEventResponse>;
      if (this.applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing) {
        createBatchEvent$ = this.animalEventsService.createFinishingBatchEvent(
          this.eventDetails,
          {
            startDateTime: this.selectedEpoch.epoch,
            animalIds: this.selectedAnimals.map<number>((animal) => animal.chipId)
          }
        );
      } else {
        createBatchEvent$ = this.animalEventsService.createBatchEvent(
          this.eventDetails,
          {
            startDateTime: this.selectedEpoch.epoch,
            animalIds: this.selectedAnimals.map<number>((animal) => animal.chipId)
          }
        );
      }

      if (this.selectionMode === CreateEventMode.single) {
        return createBatchEvent$.pipe(
          tap(async (result) => {
            if (result.state === 'CompletedWithErrors') {
              if (result.failures.length > 0) {
                this.eventDetailsError = result.failures[0].errors[0];
                this.loadingIconService.hide();
                return;
              }
            } else if (result.state === 'Completed') {
              this.selectedAnimals = [];
              this.autoCompleteAddAnimal.onRemoveSelection(event);
              await this.getAnimalsListFromSearchEntities();
              this.autoCompleteAddAnimal.focusAndClean();
              this.successDialogService.show();
            }
            this.loadingIconService.hide();
            this.clearForm();
            this.saveDataViewStateService.activeEditArea = null;
          })
        );
      } else {
        const showBatchEventError = (animals: string[]) =>
          this.batchEventErrorsDialogService.show(animals);

        const selectedAnimalNames = this.selectedAnimals.map<string>((animal) => animal.chipName);

        return createBatchEvent$.pipe(
          tap((result) => {
            if (result.state === 'CompletedWithErrors') {
              showBatchEventError(result.failures.map((f) => f.animalId));
            } else if (result.state === 'Completed') {
              this.successDialogService.show();
            }
            this.loadingIconService.hide();
            this.clearForm();
            this.saveDataViewStateService.activeEditArea = null;
            this.toggle();
          }),
          catchError(() => {
            showBatchEventError(selectedAnimalNames);
            this.loadingIconService.hide();
            return EMPTY;
          })
        );
      }
    };

    getAction().subscribe();
  }

  public toggleOpen(): void {
    if(!this.isEventCreationAvailable) {
      return;
    }
    this.toggle();
  }

  private toggle() {
    this.isOpen = !this.isOpen;
    if (this.isOpen === false) {
      this.clearForm();
      this.saveDataViewStateService.activeEditArea = null;
    }
    this.toggleClicked.emit(this.isOpen);
  }

  private clearForm() {
    this.selectedAnimals = [];
    this.selectedEvent = null;
    this.eventDetailsError = new EventDetailsError();
    if (this.autoCompleteAddAnimal) {
      this.autoCompleteAddAnimal.clear();
    }
    if (this.animalCardEventsMenu) {
      this.animalCardEventsMenu.reset();
      this.animalCardEventsMenu.openPopup();
    }
    this.selectionMode = CreateEventMode.single;
  }

  public onBatchModeClick(): void {
    this.changeSelectionMode(CreateEventMode.batch);
    this.autoCompleteAddAnimal.focusAndClean();

  }

  public onSingleModeClick(): void {
    if (!this.isSaveEnabled) {
      return;
    }
    const cancelBatch$ = this.cancelBatchEventDialogService.show()
      .pipe(
        filter((canClose) => canClose === true),
        tap(() => {
          this.changeSelectionMode(CreateEventMode.single);
          this.onCreateEventResizeMade.emit();
          this.autoCompleteAddAnimal.focusAndClean();
        })
      );

    this.subscription.add(
      cancelBatch$.subscribe()
    );
  }

  private changeSelectionMode(createEventMode: CreateEventMode) {
    this.selectedAnimals = [];
    if (this.eventDetails != null) {
      this.eventDetailsError = new EventDetailsError();
    }
    this.selectionMode = createEventMode;
  }

  public removeSelectedAnimal(batchAnimal: BatchAnimal) {
    this.selectedAnimals = this.selectedAnimals.filter(
      (selectedAnimal) => selectedAnimal !== batchAnimal
    );
    if (this.selectedAnimals.length === 0) {
      this.onCreateEventResizeMade.emit();
    }
  }

  public epochChanged(epoch: EpochStateModel): void {
    this.selectedEpoch = epoch;
    this.eventDetailsError = new EventDetailsError();
  }

  public async onEventSelected(event: IAvailableEventInfo) {
    if (this.applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing) {
      this.selectedEventMeta = await this.animalEventsService.getFinishingBatchEventTypeMetadata(event.type, new Date());
    } else {
      this.selectedEventMeta = await this.animalEventsService.getBatchEventTypeMetadata(event.type, new Date());
    }
    this.selectedEvent = event;
    this.clearErrors();
    this.selectedAnimals = [];
    this.selectedAnimal = null;
    this.selectedEpoch = new EpochStateModel(EpochStateModelMinMaxMode.Date, moment().unix());
    this.selectedEpoch.max = this.selectedEpoch.epoch;
    this.eventDetails = this.getDefaultEventDetails(
      event,
      this.selectedEventMeta
    );
    this.saveDataViewStateService.activeEditArea = this.EventEditorAreaName;
    this.onCreateEventResizeMade.emit();
  }

  private revalidateBatchAnimals(): void {
    this.selectedAnimals = this.selectedAnimals.map<BatchAnimal>((animal) => ({
      ...animal,
      validationState: BatchAnimalValidationState.inProgress
    }));

    const validation$ = this.animalEventsService
      .validateBatchEvents({
        event: this.eventDetails,
        startDateTime: this.selectedEpoch.epoch,
        animalIds: this.selectedAnimals.map<number>((animal) => animal.chipId)
      })
      .pipe(
        tap(
          (result) =>
            (this.selectedAnimals = this.selectedAnimals.map<BatchAnimal>(
              (animal) => ({
                ...animal,
                validationState:
                  result.failures.find((f) => f.id === animal.chipId) != null
                    ? BatchAnimalValidationState.invalid
                    : BatchAnimalValidationState.valid
              })
            ))
        )
      );

    this.subscription.add(
      validation$.subscribe()
    );
  }

  private getDefaultEventDetails(event: IAvailableEventInfo, meta: CowEventMetadataBase): EventDetails {
    return this.animalEventsService.getDefaultEventDetails(event.type, meta);
  }

  public onAnimalAutocompleteEnterPress(event:Event) {
    if(this.selectionMode == CreateEventMode.single &&
       this.selectedAnimals.length > 0) {
      this.save(event);
    }
  }

  public onAnimalSelected(animalName: string): void {
    let batchAnimal: BatchAnimal;
    const animal = this.animalsList.find((a) => a.entityName === animalName);

    if (this.selectionMode === CreateEventMode.single) {
      if (animalName == null || animal == null) {
        this.selectedAnimals = [];
        return;
      } else {
        batchAnimal = {
          chipName: animal.entityName,
          chipId: animal.entityId
        };
        this.selectedAnimals = [batchAnimal];
      }
    } else {
      if (
        animal != null && animalName != null &&
        this.selectedAnimals.findIndex((sa) => sa.chipName === animalName) === -1
      ) {
        batchAnimal = {
          chipName: animal.entityName,
          chipId: animal.entityId
        };
        this.selectedAnimals.unshift(batchAnimal);
        batchAnimal.validationState = BatchAnimalValidationState.inProgress;

        const validate$ = this.animalEventsService
          .validateBatchEvents({
            event: this.eventDetails,
            startDateTime: this.selectedEpoch.epoch,
            animalIds: [batchAnimal.chipId]
          })
          .pipe(
            tap((result) => {
              batchAnimal.validationState = result.state === 'Correct' ? BatchAnimalValidationState.valid : BatchAnimalValidationState.invalid;
            }),
            catchError(() => {
              batchAnimal.validationState = BatchAnimalValidationState.invalid;
              return EMPTY;
            })
          );

        this.subscription.add(
          validate$.subscribe()
        );

        this.sellectedAnimal = null;
        this.autoCompleteAddAnimal.focusAndClean();
        if (this.selectedAnimals.length > 0) {
          this.onCreateEventResizeMade.emit();
        }
      }
    }
  }

  public clearErrors() {
    this.eventDetailsError = new EventDetailsError();
  }
}
