<div class="create-event-form">
  <div class="form-section-with-icon">
    <div>{{ 'ANIMAL.EVENTS.ADD_EVENT.WEIGHT' | translate }}</div>
    <div class="icon-remove-weight-value" (click)="clearWeightValueField()"></div>
  </div>

  <div class="form-section-item">
    <input class="input-class"
           *ngIf="isKgValueDisplayed()"
           weightValueLimit
           contenteditable='true'
           [(ngModel)]="weightKG"
           [ngClass]="{ error: isWeightValueFieldError() }"/>
    <input class="input-class"
           *ngIf="!isKgValueDisplayed()"
           weightValueLimit
           contenteditable='true'
           [(ngModel)]="weightLBS"
           [ngClass]="{ error: isWeightValueFieldError() }"/>
  </div>
</div>
