import { Injectable } from '@angular/core';
import {HttpResponseResult, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from '../auth/auth.service';
import {HeaderAuthorizationDetails} from '../rest-api/models/header-authorization-details';
import {Router} from '@angular/router';
import {
  IFarmDetailsResponse,
  FarmRegistrationModel,
  IFarmThirdPartyResponse,
  ICreateAccountResponse,
  ICRVRegistrationKeyRequest,
  ICRVRegistrationKeyResponse,
  IFarmTopologyResponse,
  IPostDetailsResponse,
  IRegistrationAccount,
  IRegistrationKeyResponse,
  IRegistrationResponse,
  IFarmVersion,
  RegistrationServiceProjection,
  IPostTrialModeRequest, IRegistrationPostDetailsResponse
} from './model/registration.model';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  public registrationModel: FarmRegistrationModel = new FarmRegistrationModel();
  // flag to provide permission to load the registration steps (farm details, herd-management and Topology)
  public isClickedOnRegister: boolean = false;
  public isClickedOnSignUp: boolean = false;
  public isFarmTopologyInit: boolean = false;

  public isLoading: boolean = false;

  private static readonly RegistrationAPIURL: string = '/rest/api/auth/registration';

  constructor(private restApiService: RestApiService, private authService: AuthService, private router: Router) { }

  public startRegistration(): Promise<HttpResponseResult<IRegistrationResponse>> {
    return this.restApiService.sendPostMethod<IRegistrationResponse>(
      RegistrationService.RegistrationAPIURL, this.authService.anonymousAuthKnownFarmDetails, { serialNumber: null, type: "validateSerial" });
  }

  public getFarmDetails(): Promise<HttpResponseResult<IFarmDetailsResponse>> {
    return this.restApiService.sendGetMethod<IFarmDetailsResponse>(
      RegistrationService.RegistrationAPIURL,
      this.authService.anonymousAuthKnownFarmDetails,
      {projection: RegistrationServiceProjection.farmDetails});
  }

  public postFarmDetails(farmDetails: IFarmDetailsResponse): Promise<HttpResponseResult<IPostDetailsResponse>> {
    return this.restApiService.sendPostMethod<IPostDetailsResponse>(RegistrationService.RegistrationAPIURL, this.authService.anonymousAuthKnownFarmDetails, farmDetails);
  }

  public getFarmThirdParty(): Promise<HttpResponseResult<IFarmThirdPartyResponse>> {
    return this.restApiService.sendGetMethod<IFarmThirdPartyResponse>(
      RegistrationService.RegistrationAPIURL,
      this.authService.anonymousAuthKnownFarmDetails,
      {projection: RegistrationServiceProjection.thirdParty});
  }

  public postFarmThirdParty(farmThirdParty: IFarmThirdPartyResponse): Promise<HttpResponseResult<IPostDetailsResponse>> {
    return this.restApiService.sendPostMethod<IPostDetailsResponse>(
      RegistrationService.RegistrationAPIURL,
      this.authService.anonymousAuthKnownFarmDetails,
      farmThirdParty);
  }

  public getRegistrationKey(thirdPartyType: string): Promise<HttpResponseResult<IRegistrationKeyResponse>> {
    return this.restApiService.sendGetMethod<IRegistrationKeyResponse>(
      RegistrationService.RegistrationAPIURL,
      this.authService.anonymousAuthKnownFarmDetails,
      { deviceType: thirdPartyType });
  }

  public postCRVRegistrationKey(registrationKey: ICRVRegistrationKeyRequest): Promise<HttpResponseResult<ICRVRegistrationKeyResponse>> {
    return this.restApiService.sendPostMethod<ICRVRegistrationKeyResponse>(
      `${RegistrationService.RegistrationAPIURL}/registrationkey`,
      this.authService.anonymousAuthKnownFarmDetails,
      registrationKey);
  }

  public getFarmTopology(): Promise<HttpResponseResult<IFarmTopologyResponse>> {
    return this.restApiService.sendGetMethod<IFarmTopologyResponse>(
      RegistrationService.RegistrationAPIURL,
      this.authService.anonymousAuthKnownFarmDetails,
      {projection: RegistrationServiceProjection.topology});
  }

  public postFarmTopology(farmTopology: IFarmTopologyResponse): Promise<HttpResponseResult<IPostDetailsResponse>> {
    return this.restApiService.sendPostMethod<IPostDetailsResponse>(RegistrationService.RegistrationAPIURL, this.authService.anonymousAuthKnownFarmDetails, farmTopology);
  }

  public getCreateAccount(): Promise<HttpResponseResult<ICreateAccountResponse>> {
    return this.restApiService.sendGetMethod<ICreateAccountResponse>(RegistrationService.RegistrationAPIURL, this.authService.anonymousAuthKnownFarmDetails,
      {projection: RegistrationServiceProjection.createAccount});
  }

  public postCreateAccount(accountToCreate: IRegistrationAccount): Promise<HttpResponseResult<IRegistrationPostDetailsResponse>> {
    return this.restApiService.sendPostMethod<IRegistrationPostDetailsResponse>(RegistrationService.RegistrationAPIURL, this.authService.anonymousAuthKnownFarmDetails, accountToCreate);
  }

  // tslint:disable-next-line:no-any
  public postTrialMode(request: IPostTrialModeRequest): Promise<HttpResponseResult<any>> {
    // tslint:disable-next-line:no-any
    return this.restApiService.sendPostMethod<any>(RegistrationService.RegistrationAPIURL, this.authService.anonymousAuthKnownFarmDetails, request);
  }

  public async loginAsTrialUser() {
    const authDetails: HeaderAuthorizationDetails = {
      userName: 'trial',
      password: 'trial',
      farmId: '0M'
    };
    const loginResult = await this.authService.performLogin(authDetails, false);
    if (loginResult.status === 200) {
      this.authService.isTrialModeDashboardNavigationAllowed = true;
      await this.router.navigate(['/dashboard']);
    }
  }

  public async getFarmVersion(): Promise<IFarmVersion> {
    const data = await this.restApiService.sendGetMethod<IFarmVersion>(
      RegistrationService.RegistrationAPIURL,
      this.authService.anonymousAuthKnownFarmDetails,
      { projection: RegistrationServiceProjection.farmVersion });
    if (data && data.responseBody) {
      return data.responseBody;
    }
    return null;
  }
}
