<div class="btn btn-decrement" *ngIf="!hideValueChangeArrows" (click)="decrement()" no-selectstart-event-bubbling>
  <div class="icon icon-prev" [ngClass]="{'icon-prev--inactive': !canDecrease, disabled: isDisabled}">
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="55" viewBox="0 0 100 55">
      <path [attr.fill]="arrowIconsColor" fill-rule="nonzero" d="M50.022 55a5.528 5.528 0 0 1-3.87-1.593L1.608 9.251a5.381 5.381 0 0 1-1.19-5.91A5.461 5.461 0 0 1 5.477 0l44.56 23.164L94.581 0a5.46 5.46 0 0 1 5.012 3.367 5.382 5.382 0 0 1-1.202 5.884L53.876 53.422A5.528 5.528 0 0 1 50.022 55z"></path>
    </svg>
  </div>
</div>
<input type="text"
       [ngClass]="{ error: isFieldError,
                    'hidden-arrows': hideValueChangeArrows,
                    'input-editable': !isReadonly && !isDisabled}"
       [attr.sh-id]="shId"
       #inputRef
       [min]="min"
       [max]="max"
       [readonly]="isReadonly"
       [disabled]="isDisabled"
       [ngModel]="value"
       (ngModelChange)="onChange($event)"
       placeholder="{{ placeholder | translate }}"
       autocomplete="off"
       required
       (focusout)="saveOnFocusOut()"
       name="numericUpDown">
<div class="btn btn-increment" *ngIf="!hideValueChangeArrows" (click)="increment()" no-selectstart-event-bubbling>
  <div class="icon icon-next" [ngClass]="{'icon-next--inactive': !canIncrease, disabled: isDisabled}">
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="55" viewBox="0 0 100 55">
      <path [attr.fill]="arrowIconsColor" fill-rule="nonzero" d="M50.022 0a5.528 5.528 0 0 0-3.87 1.593L1.608 45.749a5.381 5.381 0 0 0-1.19 5.91A5.461 5.461 0 0 0 5.477 55l44.56-23.164L94.581 55a5.46 5.46 0 0 0 5.012-3.367 5.382 5.382 0 0 0-1.202-5.884L53.876 1.578A5.528 5.528 0 0 0 50.022 0z"></path>
    </svg>
  </div>
</div>
