import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuItemType} from "../../left-menu.component";
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'sorting-gate-menu',
  templateUrl: './sorting-gate-menu.component.html',
  styleUrls: ['./sorting-gate-menu.component.scss'],
  animations: [
    trigger('sgMenuTransition', [
      transition(':enter', [
        style({width: '0'}),
        animate('0.3s ease', style({width: '320px', overflow: 'hidden'}))
      ]),
      transition(':leave', [
        style({width: '320px'}),
        animate('0.3s ease', style({width: '0',  overflow: 'hidden'}))
      ])
    ])
  ]
})
export class SortingGateMenuComponent implements OnInit {

  @Input()
  public isOpen: boolean;

  @Output()
  public onCloseMenuClick: EventEmitter<void> = new EventEmitter();

  constructor() { }

  public ngOnInit() {
  }

  public close() {
    this.isOpen = false;
    this.onCloseMenuClick.emit();
  }
}
