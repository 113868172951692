<notification-dialog-template #dialogRef
                              [isCloseForbidden]="true"
                              [modalWidth]="600"
                              [modalHeight]="300"
                              [theme]="farmMode === farmModes.Dairy ? NotificationDialogTheme.dairy : NotificationDialogTheme.beef"
                              [shIdPostfix]="farmMode === farmModes.Dairy ? 'dairyTrialFarmSelectionDialog' : 'beefTrialFarmSelectionDialog'">
  <ng-container header>
    <div class="dialog-header">
      <div class="title-text" *ngIf="farmMode === farmModes.Dairy" [attr.translation-id]="'REGISTRATION.TRIAL_DAIRY_DIALOG_CONFIRM.TITLE'">
        {{ 'REGISTRATION.TRIAL_DAIRY_DIALOG_CONFIRM.TITLE' | translate }}
      </div>
      <div class="title-text" *ngIf="farmMode === farmModes.Beef">
        {{ 'REGISTRATION.TRIAL_BEEF_DIALOG_CONFIRM.TITLE' | translate }}
      </div>
    </div>
  </ng-container>
  <ng-container body>
    <div class="trial-farm-selection-body">
      <div class="dialog-body">
        <div class="body-text" *ngIf="farmMode === farmModes.Dairy" [attr.translation-id]="'REGISTRATION.TRIAL_DAIRY_DIALOG_CONFIRM.CONTENT'">
          {{ 'REGISTRATION.TRIAL_DAIRY_DIALOG_CONFIRM.CONTENT' | translate }}
        </div>
        <div class="body-text" *ngIf="farmMode === farmModes.Beef" [attr.translation-id]="'REGISTRATION.TRIAL_BEEF_DIALOG_CONFIRM.CONTENT'">
          {{ 'REGISTRATION.TRIAL_BEEF_DIALOG_CONFIRM.CONTENT' | translate }}
        </div>
      </div>
      <div class="dialog-buttons">
        <div class="button" [attr.sh-id]="farmMode === farmModes.Dairy ? 'notification_dialog_close_dairyTrialFarmSelectionDialog' : 'notification_dialog_close_beefTrialFarmSelectionDialog'" (click)="closeDialog()">
          <div class="back-icon"></div>
          <div class="button-text" [attr.translation-id]="'COMMON.BUTTON.CANCEL'">{{ 'COMMON.BUTTON.CANCEL' | translate }}</div>
        </div>
        <div class="button" (click)="goToDashboard()" [attr.sh-id]="'button_take_me_to_dashboard'">
          <div class="button-text bold" [attr.translation-id]="'REGISTRATION.FLOW.TAKE_ME_TO_DASHBOARD'">{{ 'REGISTRATION.FLOW.TAKE_ME_TO_DASHBOARD' | translate }}</div>
          <div class="next-icon"></div>
        </div>
      </div>
    </div>
  </ng-container>
</notification-dialog-template>
