import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'last-updated',
  templateUrl: './last-updated.component.html',
  styleUrls: ['./last-updated.component.scss']
})
export class LastUpdatedComponent implements OnInit {

  @Input()
  public isPastReport: boolean = false;

  @Output()
  public onRefreshClick = new EventEmitter();

  constructor() { }

  public ngOnInit() {
  }

}
