import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {AuthService, LoginStatus} from '../auth.service';
import {ConfigService} from '../../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedOutGuard implements CanActivate {

  constructor(private router: Router,
              private authService: AuthService,
              public configService: ConfigService) {

  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let loginResult: LoginStatus = await this.authService.getLoginStatus();
    switch (loginResult) {
      case LoginStatus.LoggedIn: {
        await this.router.navigate(['/']);
        return false;
      }
      case LoginStatus.ServerError: {
        await this.router.navigate(['/setup-process']);
        return false;
      }
      default: {
        return true;
      }
    }
  }
}
export enum NavigationPath {
  login = 'login',
  forgotPassword = 'forgot-password',
  signEula = 'sign-eula'
}
