<div class="consistency-chart-header">
  <xy-chart-legend #groupHeatChartLegend
                   [configuration]="legendConfiguration"
                   [legendStates]="legendStates"></xy-chart-legend>
  <group-card-animals-summary *ngIf="groupGraphDetails" [groupGraphDetails]="groupGraphDetails"></group-card-animals-summary>
</div>
<div class="consistency-chart-body">
  <trends-consistency-xy-chart #groupConsistencyChart
                               (chartPointMouseOver)="onChartPointMouseOver($event)"
                               (durationSelected)="onDurationSelected($event)"></trends-consistency-xy-chart>
  <div class="sideBar" [ngStyle]="{'height': (groupConsistencyChart.variabilitySeparatorMarginTop - 9) + 'px'}">
    <div class="label">
      {{daysToShowInSideBar}}
      <span [attr.translation-id]="'GROUP.GRAPH.DETAILS.DAYS'">
        {{'GROUP.GRAPH.DETAILS.DAYS'| translate}}
      </span>
      <br/>
      <span [attr.translation-id]="'GROUP.GRAPH.DETAILS.AVG_GROUP_CONSISTENCY'">
        {{'GROUP.GRAPH.DETAILS.AVG_GROUP_CONSISTENCY'| translate}}
      </span>
      <div class="valueLabel">
        <div class="value">{{ lastDaysConsistency }}</div>
        <div class="icon icon-{{consistencySeverity}}"></div>
      </div>
    </div>
    <hr/>
    <div class="label" style="top: 60%;">
      {{daysToShowInSideBar}}
      <span [attr.translation-id]="'GROUP.GRAPH.DETAILS.DAYS'">
        {{'GROUP.GRAPH.DETAILS.DAYS'| translate}}
      </span>
      <br/>
      <span [attr.translation-id]="'GROUP.GRAPH.DETAILS.AVG_DAILY_RUMINATION'">
        {{'GROUP.GRAPH.DETAILS.AVG_DAILY_RUMINATION'| translate}}
      </span>
      <div class="valueLabel" style="color: #1f9bde;">{{ lastDaysAverage }}</div>
    </div>
  </div>
</div>
