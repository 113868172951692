<modal-dialog-template [isOpen]="isOpenModal"
                       [modalWidth]="555"
                       [modalHeight]="333"
                       (onEscapeButtonClick)="closeModal()"
                       (onEnterButtonClick)="proceedToNextArea()">
  <div *ngIf="isOpenModal" class="save-data-warning-dialog" sh-id="save-data-warning-dialog" @dialog-transitions>
    <div class="dialog-close">
      <div class="close-icon" sh-id="save-data-warning-close" (click)="closeModal()"></div>
    </div>
    <div class="dialog-header">
      <div class="title-wrapper">
        <div class="icon-alerts"></div>
        <div class="title">{{'DIALOG.COMMON.SAVE_DATA_BEFORE_CLOSE_DIALOG_TITLE' | translate}}</div>
      </div>
    </div>
    <div class="dialog-body">
      <div class="message">{{'DIALOG.COMMON.SAVE_DATA_BEFORE_CLOSE_DIALOG_MAIN_DESCRIPTION' | translate}}</div>
      <div class="action" sh-id="save-data-warning-proceed" (click)="onApprove($event)">
        {{'DIALOG.COMMON.SAVE_DATA_BEFORE_CLOSE_DIALOG_ACTION_BUTTON' | translate}}
        <div class="icon-action-left"></div>
      </div>
    </div>
  </div>
</modal-dialog-template>
