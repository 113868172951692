<div class="health-section" [attr.sh-id]="'farm-health-content'" *ngIf="model" saveDataEditSection [editAreaName]="editAreaName">
  <section>
    <div class="section-header"
         [attr.sh-id]="'finishing-health-report-title'"
         [attr.translation-id]="'MANAGE.WELCOME.TITLE.FINISHING_HEALTH_REPORT'">{{ 'MANAGE.WELCOME.TITLE.FINISHING_HEALTH_REPORT' | translate }}</div>
    <div class="section-content">
      <div class="section-description" [attr.translation-id]="'MANAGE.SETTINGS.HEALTH.FINISHING_HEALTH_REPORT.DESCRIPTION'">
        {{ 'MANAGE.SETTINGS.HEALTH.FINISHING_HEALTH_REPORT.DESCRIPTION' | translate }}
      </div>
      <div class="section-controls">
        <div class="section-field-editor" *ngIf="model.healthIndexThreshold">
          <div class="header">
            <div class="title" [attr.translation-id]="'MANAGE.SETTINGS.HEALTH.HEALTH_REPORT.EDITOR_TITLE1'">
              {{ 'MANAGE.SETTINGS.HEALTH.HEALTH_REPORT.EDITOR_TITLE1' | translate }}
            </div>
            <div class="buttons-wrapper">
              <div class="revert-icon" (click)="revertHealthIndexThreshold()"></div>
            </div>
          </div>
          <div class="content">
            <numeric-input class="manage-settings-health"
                           [shId]="'finishingHealthIndexThreshold'"
                           [min]="model.healthIndexThreshold.min"
                           [max]="model.healthIndexThreshold.max"
                           [canDecrease]="canDecreaseHealthIndexThresholdValue"
                           [isDisabled]="isFieldEditable()"
                           (onChangeSelectedValue)="updateValue('healthIndexThreshold', $event)"
                           [selectedValue]="model.healthIndexThreshold.value"></numeric-input>
          </div>
        </div>
        <div class="section-field-editor" *ngIf="model.severeHealthIndexThreshold">
          <div class="header">
            <div class="title" [attr.translation-id]="'MANAGE.SETTINGS.HEALTH.HEALTH_REPORT.EDITOR_TITLE2'">
              {{ 'MANAGE.SETTINGS.HEALTH.HEALTH_REPORT.EDITOR_TITLE2' | translate }}
            </div>
            <div class="buttons-wrapper">
              <div class="revert-icon" (click)="revertSevereHealthIndexThreshold()"></div>
            </div>
          </div>
          <div class="content">
            <numeric-input class="manage-settings-health"
                           [shId]="'finishingSevereHealthIndexThreshold'"
                           [min]="model.severeHealthIndexThreshold.min"
                           [max]="model.severeHealthIndexThreshold.max"
                           [canIncrease]="canIncreaseSevereHealthIndexThresholdValue"
                           [isSaveOnFocusOut]="true"
                           [isDisabled]="isFieldEditable()"
                           (onChangeSelectedValue)="updateValue('severeHealthIndexThreshold', $event)"
                           [selectedValue]="model.severeHealthIndexThreshold.value"></numeric-input>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
