export enum AutoCompleteColorScheme {
  WhiteBlack = 'WhiteBlack',
  BlueWhite = 'BlueWhite',
  WhiteBlue = 'WhiteBlue',
  WhiteBlueWithBlackPlaceholder = 'WhiteBlueWithBlackPlaceholder',
  GreyBlue = 'GreyBlue',
  WhiteBlackWithIcon = 'WhiteBlackWithIcon',
  GreyBlueWithIcon = 'GreyBlueWithIcon',
  WhiteBlackWithPermanentRemoveIcon = 'WhiteBlackWithPermanentRemoveIcon'
}

export class MatchingResultItem {
  public matchingPart:string;
  public endPart:string;
  public get value() : string{
    return this.matchingPart + this.endPart;
  }
}
