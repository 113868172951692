import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'report-filters-container',
  templateUrl: './report-filters-container.component.html',
  styleUrls: ['./report-filters-container.component.scss']
})
export class ReportFiltersContainerComponent implements OnInit, OnChanges {

  @Input() // tslint:disable-next-line:no-any
  public report: any;

  @Input()
  public defaultFilterValue: boolean;

  @Output() // tslint:disable-next-line:no-any
  public onAction: EventEmitter<any> = new EventEmitter<any>();

  @Output() // tslint:disable-next-line:no-any
  public onSetupChanged: EventEmitter<any> = new EventEmitter<any>();

  public toggle: boolean = false;

  constructor() { }

  public ngOnInit() {

  }

  public onToggleFilter(columnName: string, filterValue: boolean) {
    if (filterValue) {
      this.onAction.emit({
        action: 'filter',
        data: {
          columnName: columnName,
          filterValue: filterValue
        }
      });
    } else {
      this.onAction.emit({
        action: 'filter',
        data: {
          columnName: null,
          filterValue: null
        }
      });
    }
    this.toggle = filterValue;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultFilterValue && changes.defaultFilterValue.currentValue !== changes.defaultFilterValue.previousValue) {
      this.toggle = this.defaultFilterValue;
    }
  }

  public setupChanged() {
    this.onSetupChanged.emit();
  }
}
