import { Component, OnInit } from '@angular/core';
import {AnimalsService} from '../../../services/animals/animals.service';

@Component({
  selector: 'manage-animals',
  templateUrl: './manage-animals.component.html',
  styleUrls: ['./manage-animals.component.scss']
})
export class ManageAnimalsComponent implements OnInit {

  constructor(public animalsService: AnimalsService) { }

  public ngOnInit() {
  }
}
