import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {ConfigService} from '../config/config.service';
import {RestApiResult} from './models/rest-api-result';
import * as moment from 'moment';
import {HeaderAuthorizationDetails} from './models/header-authorization-details';
import {Observable} from 'rxjs';

export enum PollingStatusText {
  Success = 'Success',
  Failed = 'Failed',
  Pending = 'Pending'
}

export class HttpResponseResultNoBody {
  // tslint:disable-next-line:no-any
  public errorResponseBody: any;
  public status: number;
  public statusText: string;
}

export class HttpResponseResult<T> extends HttpResponseResultNoBody{
  public responseBody: T;
}

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  private static readonly DefaultPendingTimeout = 1000;

  constructor(private httpClient: HttpClient,
              public configService: ConfigService) {
  }

  public getFullUrl(apiRoute: string): string {
    return this.configService.configuration.apiUrl + apiRoute;
  }

  public getRequestHeaders(headerAuthorizationDetails: HeaderAuthorizationDetails): {
    [header: string]: string | string[];
  } {
    if(headerAuthorizationDetails == null) {
      return null;
    }
    let creds = btoa(`${headerAuthorizationDetails.userName}_${headerAuthorizationDetails.farmId}_IL10:${headerAuthorizationDetails.password}`);
    if(this.configService.serverConfig == null) {
      return {
        Authorization: `Basic ${creds}`,
        farmId: headerAuthorizationDetails.farmId
      };
    } else {
      let scrClientTime = {
        dateTime: moment().format("YYYY-MM-DDTHH:mm:ss.sZ")
      };
      let clientType = {
        name: "optional",
        type: "web",
        version: this.configService.serverConfig.displayVersion
      };
      return {
        Authorization: `Basic ${creds}`,
        farmId: headerAuthorizationDetails.farmId,
        'SCR-Client-Time': JSON.stringify(scrClientTime),
        'SCR-Client-Type': JSON.stringify(clientType),
        'SCR-Farm-Id': headerAuthorizationDetails.farmId
      };
    }
  }

  public async sendGetMethodAny<T>(route: string, headerAuthorizationDetails: HeaderAuthorizationDetails, queryParams?: HttpParams): Promise<HttpResponseResult<T>> {
    const result: HttpResponseResult<T> = new HttpResponseResult<T>();
    const observable: Observable<HttpResponse<T & {pollingStatus?: PollingStatusText}>> = this.httpClient.get<T>(this.getFullUrl(route),
      {
        headers: this.getRequestHeaders(headerAuthorizationDetails),
        params: queryParams,
        observe: 'response'
      });
    return await observable.toPromise().then(async (value: HttpResponse<T & { pollingStatus?: PollingStatusText }>) => {
      if (value.body && value.body && value.body.pollingStatus == PollingStatusText.Pending) {
        return await this.waitForServerResponseAny<T>(observable);
      }
      if (value.body) {
        result.responseBody = value.body;
      } else {
        result.responseBody = null;
      }
      result.status = value.status;
      result.statusText = value.statusText;
      return result;
    }).catch((value: HttpErrorResponse) => {
      result.status = value.status;
      result.statusText = value.statusText;
      result.errorResponseBody = value.error;
      return result;
    });
  }

  public async sendGetMethod<T>(route: string, headerAuthorizationDetails: HeaderAuthorizationDetails, queryParams?: HttpParams | {[param: string]: string | string[]}):
    Promise<HttpResponseResult<T>> {
    let result: HttpResponseResult<T> = new HttpResponseResult<T>();
    const observable: Observable<HttpResponse<RestApiResult<T & {pollingStatus?: PollingStatusText}>>> = this.httpClient.get<RestApiResult<T>>(this.getFullUrl(route),
      {
        headers: this.getRequestHeaders(headerAuthorizationDetails),
        params: queryParams,
        observe: 'response'
      });
    return await observable.toPromise().then(async (value: HttpResponse<RestApiResult<T & {pollingStatus?: PollingStatusText}>>) => {
      if (value.body && value.body.result && value.body.result.pollingStatus == PollingStatusText.Pending) {
        return await this.waitForServerResponse<T>(observable);
      }
      if (value.body && value.body.result) {
        result.responseBody = value.body.result;
      } else {
        result.responseBody = null;
      }
      result.status = value.status;
      result.statusText = value.statusText;
      return result;
    }).catch((value: HttpErrorResponse) => {
      result.status = value.status;
      result.statusText = value.statusText;
      result.errorResponseBody = value.error;
      return result;
    });
  }

  // tslint:disable-next-line:no-any
  public async sendPostMethod<T>(route: string, headerAuthorizationDetails: HeaderAuthorizationDetails, requestBody: any, queryParams?: HttpParams): Promise<HttpResponseResult<T>> {
    let result: HttpResponseResult<T> = new HttpResponseResult<T>();
    const observable: Observable<HttpResponse<RestApiResult<T & {pollingStatus?: PollingStatusText}>>> = this.httpClient.post<RestApiResult<T>>(this.getFullUrl(route), requestBody,
      {
        headers: this.getRequestHeaders(headerAuthorizationDetails),
        params: queryParams,
        observe: 'response'
      });
    return await observable.toPromise().then(async (value: HttpResponse<RestApiResult<T & { pollingStatus?: PollingStatusText }>>) => {
      if (value.body && value.body.result && value.body.result.pollingStatus == PollingStatusText.Pending) {
        return await this.waitForServerResponse<T>(observable);
      }
      if (value.body && value.body.result) {
        result.responseBody = value.body.result;
      } else {
        result.responseBody = null;
      }
      result.status = value.status;
      result.statusText = value.statusText;
      return result;
    }).catch((value: HttpErrorResponse) => {
      result.status = value.status;
      result.statusText = value.statusText;
      result.errorResponseBody = value.error;
      return result;
    });
  }

  // tslint:disable-next-line:no-any
  public async sendPostMethodAny<TResponse, TRequest = any>(route: string,  headerAuthorizationDetails: HeaderAuthorizationDetails, requestBody: TRequest, queryParams?: HttpParams): Promise<HttpResponseResult<TResponse>> {
    let result: HttpResponseResult<TResponse> = new HttpResponseResult<TResponse>();
    const observable: Observable<HttpResponse<TResponse & {pollingStatus?: PollingStatusText}>> = this.httpClient.post<TResponse>(this.getFullUrl(route), requestBody,
      {
        headers: this.getRequestHeaders(headerAuthorizationDetails),
        params: queryParams,
        observe: 'response'
      });
    return await observable.toPromise().then(async (value: HttpResponse<TResponse & { pollingStatus?: PollingStatusText }>) => {
      if (value.body && value.body.pollingStatus == PollingStatusText.Pending) {
        return await this.waitForServerResponseAny<TResponse>(observable);
      }
      if (value.body) {
        result.responseBody = value.body;
      } else {
        result.responseBody = null;
      }
      result.status = value.status;
      result.statusText = value.statusText;
      return result;
    }).catch((value: HttpErrorResponse) => {
      result.status = value.status;
      result.statusText = value.statusText;
      result.errorResponseBody = value.error;
      return result;
    });
  }

  // tslint:disable-next-line:no-any
  public async sendPutMethod<T>(route: string,  headerAuthorizationDetails: HeaderAuthorizationDetails, requestBody: any, queryParams?: HttpParams): Promise<HttpResponseResult<T>> {
    let result: HttpResponseResult<T> = new HttpResponseResult<T>();
    const observable: Observable<HttpResponse<RestApiResult<T & {pollingStatus?: PollingStatusText}>>> = this.httpClient.put<RestApiResult<T>>(this.getFullUrl(route), requestBody,
      {
        headers: this.getRequestHeaders(headerAuthorizationDetails),
        params: queryParams,
        observe: 'response'
      });
    return await observable.toPromise().then(async (value: HttpResponse<RestApiResult<T & { pollingStatus?: PollingStatusText }>>) => {
      if (value.body && value.body.result && value.body.result.pollingStatus == PollingStatusText.Pending) {
        return await this.waitForServerResponse<T>(observable);
      }
      if (value.body && value.body.result) {
        result.responseBody = value.body.result;
      } else {
        result.responseBody = null;
      }
      result.status = value.status;
      result.statusText = value.statusText;
      return result;
    }).catch((value: HttpErrorResponse) => {
      result.status = value.status;
      result.statusText = value.statusText;
      result.errorResponseBody = value.error;
      return result;
    });
  }

  // tslint:disable-next-line:no-any
  public async sendPutMethodAny<T>(route: string,   headerAuthorizationDetails: HeaderAuthorizationDetails, requestBody: any, queryParams?: HttpParams): Promise<HttpResponseResult<T>> {
    let result: HttpResponseResult<T> = new HttpResponseResult<T>();

    const observable: Observable<HttpResponse<T & {pollingStatus?: PollingStatusText}>> = this.httpClient.put<T>(this.getFullUrl(route), requestBody,
      {
        headers: this.getRequestHeaders(headerAuthorizationDetails),
        params: queryParams,
        observe: 'response'
      });
    return await observable.toPromise().then(async (value: HttpResponse<T & { pollingStatus?: PollingStatusText }>) => {
      if (value.body && value.body.pollingStatus == PollingStatusText.Pending) {
        return await this.waitForServerResponseAny<T>(observable);
      }
      if (value.body) {
        result.responseBody = value.body;
      } else {
        result.responseBody = null;
      }
      result.status = value.status;
      result.statusText = value.statusText;
      return result;
    }).catch((value: HttpErrorResponse) => {
      result.status = value.status;
      result.statusText = value.statusText;
      result.errorResponseBody = value.error;
      return result;
    });
  }

  public async sendDeleteMethod(route: string,  headerAuthorizationDetails: HeaderAuthorizationDetails, queryParams?: HttpParams): Promise<HttpResponseResultNoBody> {
    let result: HttpResponseResultNoBody = new HttpResponseResultNoBody();

    // tslint:disable-next-line:no-any
    const observable: Observable<any> = this.httpClient.delete(this.getFullUrl(route),
      {
        headers: this.getRequestHeaders(headerAuthorizationDetails),
        params: queryParams,
        observe: 'response'
      });
    // tslint:disable-next-line:no-any
    return await observable.toPromise().then(async (value: any) => {
      if (value.body && value.body.result && value.body.result.pollingStatus == PollingStatusText.Pending) {
        return await this.waitForServerResponse<HttpResponseResultNoBody>(observable);
      }
      result.status = value.status;
      result.statusText = value.statusText;
      return result;
    }).catch((value: HttpErrorResponse) => {
      result.status = value.status;
      result.statusText = value.statusText;
      result.errorResponseBody = value.error;
      return result;
    });
  }

  public async sendDeleteMethodAny<T>(route: string,  headerAuthorizationDetails: HeaderAuthorizationDetails, queryParams?: HttpParams): Promise<HttpResponseResult<T>> {
    let result: HttpResponseResult<T> = new HttpResponseResult<T>();

    const observable: Observable<HttpResponse<T & {pollingStatus?: PollingStatusText}>> = this.httpClient.delete<T>(this.getFullUrl(route),
      {
        headers: this.getRequestHeaders(headerAuthorizationDetails),
        params: queryParams,
        observe: 'response'
      });
    return await observable.toPromise().then(async (value: HttpResponse<T & { pollingStatus?: PollingStatusText }>) => {
      if (value.body && value.body.pollingStatus == PollingStatusText.Pending) {
        return await this.waitForServerResponseAny<T>(observable);
      }
      if (value.body) {
        result.responseBody = value.body;
      } else {
        result.responseBody = null;
      }
      result.status = value.status;
      result.statusText = value.statusText;
      return result;
    }).catch((value: HttpErrorResponse) => {
      result.status = value.status;
      result.statusText = value.statusText;
      result.errorResponseBody = value.error;
      return result;
    });
  }

  /**
   * This helper method handles all four HTTP requests supported with rest-api-service which require too much time (> 5s) to be completed
   * It returns a final result once pending is completed.
   * @param {Observable<HttpResponse<RestApiResult<T>>>} observableToHandle - an observable to be handled
   * @return {Promise<HttpResponseResult<T>>>}
   * */
  private async waitForServerResponse<T>(observableToHandle: Observable<HttpResponse<RestApiResult<T>>>): Promise<HttpResponseResult<T>> {
    return await new Promise( async (resolve, reject) => {
      let result: HttpResponseResult<T> = new HttpResponseResult<T>();
      const interval = setInterval(async () => {
        await observableToHandle.subscribe((value: HttpResponse<RestApiResult<T & { pollingStatus?: PollingStatusText }>>) => {
          if (!(value && value.body && value.body.result && value.body.result.pollingStatus == PollingStatusText.Pending)) {
            if (value.body && value.body.result) {
              result.responseBody = value.body.result;
            } else {
              result.responseBody = null;
            }
            result.status = value.status;
            result.statusText = value.statusText;
            resolve(result);
            clearInterval(interval);
          }
        }, (error) => {
          reject(error);
          clearInterval(interval);
        });
      }, RestApiService.DefaultPendingTimeout);
    });
  }

  /**
   * This helper method supports sendXXXXMethodAny-like methods which require too much time (> 5s) to be completed
   * It returns a final result after pending is completed.
   * @param {Observable<HttpResponse<T>>} observableToHandle - an observable to be handled
   * @return {Promise<HttpResponseResult<T>>>}
   * */
  private async waitForServerResponseAny<T>(observableToHandle: Observable<HttpResponse<T>>): Promise<HttpResponseResult<T>> {
    return await new Promise( async (resolve, reject) => {
      let result: HttpResponseResult<T> = new HttpResponseResult<T>();
      const interval = setInterval(async () => {
        await observableToHandle.subscribe((promiseToWaitResult: HttpResponse<T & { pollingStatus?: PollingStatusText }>) => {
          if (!(promiseToWaitResult && promiseToWaitResult.body && promiseToWaitResult.body.pollingStatus == PollingStatusText.Pending)) {
            if (promiseToWaitResult.body) {
              result.responseBody = promiseToWaitResult.body;
            } else {
              result.responseBody = null;
            }
            result.status = promiseToWaitResult.status;
            result.statusText = promiseToWaitResult.statusText;
            resolve(result);
            clearInterval(interval);
          }
        }, (error) => {
          reject(error);
          clearInterval(interval);
        });
      }, RestApiService.DefaultPendingTimeout);
    });
  }
}
