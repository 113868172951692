import {IAnimalChartSetupSeries, IAnimalInfo} from '../../../services/animals/model/animal.model';
import {ChartSeriesSelectionState} from '../../../common/components/chart-series-dropdown-list/chart-series-dropdown-list.component';
import {IChartSeriesSetupRecord, IChartSetup} from '../../../services/charts/model/chart.interface';

export enum AnimalCardTabSection{
  Events = 'Events',
  GraphsHealth = 'GraphsHealth',
  GraphsHeat = 'GraphsHeat',
  Details = 'Details'
}

export class AnimalCardViewState {
  public animalInfo: IAnimalInfo;

  public selectedTab: AnimalCardTabSection = AnimalCardTabSection.Events;
  
  public healthChartSetup: IChartSetup<IChartSeriesSetupRecord, IAnimalChartSetupSeries>;
  
  public healthChartSelectedDuration: number = 4;
  
  public heatChartSetup: IChartSetup<IChartSeriesSetupRecord, IAnimalChartSetupSeries>;
  
  public heatChartSelectedDuration: number = 5;
}
