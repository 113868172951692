<modal-dialog-template *ngIf="isPopupOpen"
                       [modalWidth]="480"
                       [modalHeight]="440"
                       [isOpen]="isPopupOpen"
                       (onEscapeButtonClick)="outsidePopupModalClicked()">
  <div class="popup-modal-container" @dialog-transitions>
    <div class="popup-body">
      <div class="popup-header">
        <div class="header-text">{{ 'COMMON.ADD_ANIMAL_TO_LIST_DIALOG.ADD_ANIMAL' | translate }}</div>
        <div class="close-dialog-icon" (click)="outsidePopupModalClicked()" sh-id="add-animal-close"></div>
      </div>
      <div class="seperator"></div>
      <div class="input-and-note-container">
        <div class="auto-complete-container">
          <div outsideClickIgnore>
            <regular-auto-complete #autoCompleteAddAnimal
                           [placeholder]="'SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.ENTER_ANIMAL_NUMBER_TEXTBOX_CATION'"
                           [selectOnEnter]="false"
                           [maxResults]="4"
                           [isAllowUnmatchedSelection]="false"
                            [isEnterHasDifferentFunctionality]="true"
                           (onEnterClicked)="onEnterKeyClickedForSelection($event)"
                           [items]="items"
                           [autoFocus]="true"
                           [hasIcon]="true"
                           [hasDeleteIcon]="true"
                           [colorScheme]="autoCompleteColorScheme.WhiteBlack"
                           [shIdPostfix]="'animalId'"
                           (editingCompleted)="selectAnimal($event)"
                           (matchingItemChanged)="onMatchingItemChanged($event)">
            </regular-auto-complete>
          </div>
        </div>
        <div class="note">
          <div class="alert-yellow-icon"></div>
          <div class="note-text" [attr.translation-id]="'MANAGE.SETTINGS.USERS.NOTE: COMMON.ADD_ANIMAL_TO_LIST_DIALOG.NOTE_ANIMALS_YOU_ADD_WILL_REMAIN_UNTIL_REFRESH'"><b>{{ 'MANAGE.SETTINGS.USERS.NOTE' | translate }}</b>: {{ 'COMMON.ADD_ANIMAL_TO_LIST_DIALOG.NOTE_ANIMALS_YOU_ADD_WILL_REMAIN_UNTIL_REFRESH' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="popup-add-animal-button-container">
      <button class="add-animal-button" type="button" [disabled]="!searchItem" sh-id="add-animal-button" (click)="onAddAnimalButtonClick($event)">{{ 'COMMON.ADD_ANIMAL_TO_LIST_DIALOG.ADD_ANIMAL' | translate }}</button>
    </div>
  </div>
</modal-dialog-template>
