<div class="item-header" (click)="itemClick()"
     [attr.sh-id]="routeShId">
  <div class="header-info">
    <div [ngClass]="['icon', icon]"></div>
    <div class="text" [attr.translation-id]="title">{{title | translate}}</div>
    <div class="icon icon-next"></div>
  </div>
</div>
<div class="item-content">
  <div class="item-description">
    <span class="description-text" [attr.translation-id]="description">{{description | translate}}</span>
  </div>
  <div class="link-navs">
    <div class="link-nav" *ngFor="let categoryWrapper of screenCategories">
      <a (click)="pageCategoryClick(categoryWrapper.category)"
         [attr.sh-id]="categoryWrapper.shId"
         [attr.translation-id]="categoryWrapper.translationId">{{categoryWrapper.translationId | translate}}</a>
    </div>
  </div>
</div>
<div class="frame-corner"></div>
