import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';

export class ChipsItem {
  public chipId: number;
  public chipName: string;
}

export enum ChipsItemBackgroundColor {
  defaultColor = '#e8f5fb',
  white = '#fff'
}

@Component({
  selector: 'chips-item',
  templateUrl: './chips-item.component.html',
  styleUrls: ['./chips-item.component.scss']
})
export class ChipsItemComponent implements OnInit, AfterViewChecked {

  @Input()
  public chipBackground: string = ChipsItemBackgroundColor.defaultColor;

  @Input()
  public hasError: boolean = false;

  @Input()
  public chipItem: ChipsItem;

  @Output()
  public onRemoveItemClicked: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('style.flex-grow')
  private _flexGrow = 0;

  private _initialWidth: number;

  constructor(private elementRef: ElementRef, private readonly changeDetectionRef: ChangeDetectorRef) {
  }

  public ngAfterViewChecked(): void {
    if (this._initialWidth == null) {
      this._initialWidth = this.hostWidth;
    }
  }

  public get initialWidth(): number {
    return this._initialWidth;
  }

  public ngOnInit() {
  }

  public onRemoveItem() {
    this.onRemoveItemClicked.emit();
  }

  private get hostWidth(): number {
    return this.elementRef.nativeElement.offsetWidth + this.margins;
  }

  private get margins(): number {
    const marginLeft = Number(getComputedStyle(this.elementRef.nativeElement, null).marginLeft.replace('px', ''));
    const marginRight = Number(getComputedStyle(this.elementRef.nativeElement, null).marginRight.replace('px', ''));
    return  marginLeft + marginRight;
  }

  public set flexGrow(value: number) {
    if (this._flexGrow !== value) {
      setTimeout(() => {
        this._flexGrow = value;
        this.changeDetectionRef.detectChanges();
      });
    }
  }

  public get flexGrow(): number {
    return this._flexGrow;
  }
}
