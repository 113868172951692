import {Animal, EntitiesDataRow, Group} from '../../model/common-model';
import {DropdownListItem} from '../../../common/components/dropdown-list/dropdown-list-model';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../common/components/calendar/model/epoch-state-model';
import * as moment from 'moment';
import {ChipsItem} from '../../../common/components/chips/chips-item.component';

export class LedTasksResult {
  public limit: number;
  public offset: number;
  public result: ScheduledTaskModel[];
  public total: number;
}

export class ScheduledTaskModel extends EntitiesDataRow {
  public id: number;
  public type: DiscoverItems;
  public isEnabled: boolean;
  public isSuspendable: boolean;
  public isEditable: boolean;
  public reportId?: number;
  public isQuickFilter?: boolean;
  public blinkRate: string;
  public duration: number;
  public name?: string;
  public startDate?: number;
  public startTime: number;
  public endTime: number;
  public weekDays?: number[];
  public repeatDays?: number;
  public groups?: Group[];
  public animals?: Animal[];
}

export class LedTaskReportsList {
  public reports: ReportModel[];
}
export class ReportModel {
  public id: number;
  public name: string;
  public quickFilter: QuickFilterModel;
}

export class QuickFilterModel {
  public code: string;
  public defaultValue: boolean;
}

export interface IScheduledTaskModelItem {
  id: number;
  type: string;
  isEditable?: boolean;
  isSupportSuspending?: boolean;
  isEnabled: boolean;
  blinkRate: LedMode;
  duration: number;
  startTime: number;
  endTime: number;

  convertEditableModelToScheduledTaskModel(taskModel: TaskModel): void;
}

export class ScheduledTaskModelItemAnimal implements IScheduledTaskModelItem {
  public id: number;
  public type: string;
  public isEnabled: boolean;
  public name: string;
  public blinkRate: LedMode;
  public startTime: number;
  public endTime: number;
  public startDate: number;
  public duration: number;
  public repeatDays: number;
  public animalIds: number[];

  constructor() {}

  public convertEditableModelToScheduledTaskModel(taskModel: TaskModel): void {
    this.id = taskModel.id;
    this.type = DiscoverItems.Animals;
    this.isEnabled = true;
    this.blinkRate = taskModel.ledMode;
    this.name = taskModel.selectedTaskName;
    this.duration = taskModel.selectedDuration;
    this.startDate = moment.unix(taskModel.startDate.epochDate).utc(true).unix();
    this.startTime = taskModel.startTime.epochTime;
    if (taskModel.isAnimalsTaskRepeat) {
      this.repeatDays = taskModel.animalsDaysRepeat;
    } else {
      this.repeatDays = null;
    }
    this.animalIds = this.convertSelectedAnimalsToAnimalIdsList(taskModel.selectedAnimals);
  }

  private convertSelectedAnimalsToAnimalIdsList(selectedAnimals: ChipsItem[]): number[] {
    const animalIdsList: number[] = [];
    selectedAnimals.forEach((animal: ChipsItem) => {
      animalIdsList.push(animal.chipId);
    });
    return animalIdsList;
  }
}

export class ScheduledTaskModelItemReport implements IScheduledTaskModelItem {
  public id: number;
  public type: string;
  public isEnabled: boolean;
  public blinkRate: LedMode;
  public startTime: number;
  public endTime: number;
  public duration: number;
  public reportId: number;
  public isQuickFilter: boolean;
  public weekDays: number[];
  public groupIds: number[];

  constructor() {}

  public convertEditableModelToScheduledTaskModel(taskModel: TaskModel): void {
    this.id = taskModel.id;
    this.type = DiscoverItems.Reports;
    this.isEnabled = true;
    this.reportId = taskModel.selectedReportItem && taskModel.selectedReportItem.value;
    this.isQuickFilter = taskModel.isQuickFilter && taskModel.isQuickFilter.defaultValue;
    this.blinkRate = taskModel.ledMode;
    this.startTime = taskModel.startTime.epochTime;
    this.duration = taskModel.selectedDuration;
    this.weekDays = taskModel.selectedDays;
    this.groupIds = this.convertSelectedGroupsToGroupIdsList(taskModel.selectedGroups);
  }

  private convertSelectedGroupsToGroupIdsList(selectedGroups: ChipsItem[]): number[] {
    const groupIdsList: number[] = [];
    selectedGroups.forEach((group: ChipsItem) => {
      groupIdsList.push(group.chipId);
    });
    return groupIdsList;
  }
}

export class TaskModel {
  public id: number;
  public reportsList: DropdownListItem[];
  public selectedReportItem: DropdownListItem;
  public discoversList: DropdownListItem[];
  public selectedDiscoverItem: DropdownListItem;
  public isQuickFilter: QuickFilterModel;
  public startDate: EpochStateModel;
  public startTime: EpochStateModel;
  public ledMode: LedMode;
  public selectedTaskName: string;
  public selectedDays: number[];
  public selectedDuration: number;
  public selectedGroups: ChipsItem[];
  public selectedAnimals: ChipsItem[];
  public isAnimalsTaskRepeat: boolean;
  public animalsDaysRepeat: number;

  constructor() {
    this.selectedDays = [];
    this.selectedReportItem = null;
    this.selectedDiscoverItem = null;
    this.selectedGroups = [];
    this.selectedAnimals = [];
    this.isQuickFilter = null;
    this.selectedTaskName = null;
    this.ledMode = LedMode.Slow;
    this.selectedDuration = null;
    this.isAnimalsTaskRepeat = false;
    this.animalsDaysRepeat = null;
    this.startDate = null;
    this.startTime = new EpochStateModel(EpochStateModelMinMaxMode.Time);
  }

  public initReportFields() {
    this.selectedReportItem = null;
    this.isQuickFilter = null;
    this.selectedTaskName = null;
    this.selectedDuration = null;
    this.ledMode = LedMode.Slow;
    this.selectedDays = [];
    this.isAnimalsTaskRepeat = false;
    this.animalsDaysRepeat = null;
    this.startDate = null;
    this.startTime = new EpochStateModel(EpochStateModelMinMaxMode.Time);
  }

  public initAnimalFields() {
    this.selectedReportItem = null;
    this.isQuickFilter = null;
    this.selectedTaskName = null;
    this.selectedDuration = null;
    this.ledMode = LedMode.Slow;
    this.selectedDays = [];
    this.isAnimalsTaskRepeat = false;
    this.animalsDaysRepeat = null;
    this.startDate = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.startDate.min = moment().unix();
    this.startTime = new EpochStateModel(EpochStateModelMinMaxMode.Time);
  }
}

export class LedModeSettings {
  public minValue: number;
  public maxValue: number;
  public minText: string;
  public maxText: string;

  constructor() {
        this.minValue = 10;
        this.maxValue = 600;
        this.minText = '10m';
        this.maxText = '10h';
  }

  public changeToSlowModeSettings(currentDuration: number) {
    this.minValue = 10;
    this.maxValue = 600;
    this.maxText = '10h';
    return currentDuration;
  }

  public changeToFastModeSettings(currentDuration: number): number {
    let duration: number;
    this.minValue = 10;
    this.maxValue = 240;
    this.maxText = '4h';
    if (currentDuration > this.maxValue) {
      duration = this.maxValue;
    } else {
      duration = currentDuration;
    }
    return duration;
  }
}


export enum LedMode {
  Slow = 'Slow',
  Fast = 'Fast'
}
export enum LedTaskFields {
  discover = 'discover',
  report = 'reportId',
  startTime = 'startTime',
  startDate = 'startDate',
  weekdays = 'weekDays',
  animalTaskName = 'animalTaskName',
  animalsSelection = 'animalsSelection',
  effectiveGroups = 'effectiveGroups'
}
export enum DiscoverItems {
  Reports = 'ByReport',
  Animals = 'ByAnimals'
}
