import {Component, ViewChild} from '@angular/core';
import {
  BreedingCowEventMetadata,
  CreateBreedingEventDetails
} from '../../../../../services/animals/model/animal-events';
import {EventFormComponent} from '../event-form.component';
import {
  BullChooseDropdownListComponent, BullColorTheme,
  BullListItem
} from '../../../../../common/components/bull-choose-dropdown-list/bull-choose-dropdown-list.component';
import {Subscription} from 'rxjs';
import {AnimalEventsService} from '../../../../../services/animals/animal-events.service';

@Component({
  selector: 'breeding-event-form',
  templateUrl: './breeding-event-form.component.html',
  styleUrls: ['./breeding-event-form.component.scss']
})
export class BreedingEventFormComponent extends EventFormComponent<BreedingCowEventMetadata, CreateBreedingEventDetails> {

  public bullColorTheme = BullColorTheme;

  @ViewChild('bullChooseDropdownList')
  public bullChooseDropdownList: BullChooseDropdownListComponent;

  constructor() {
    super();
  }

  public onSireSelected(selectedSire: BullListItem) {
    this.editModel.sire = selectedSire;
    this.emitModelChanged();
  }

  public addSireClick(event:Event){
    this.bullChooseDropdownList.openNewBullEditModal(event);
  }

  public onBullRemoved(bullListItem:BullListItem) {
    this.meta.sires = this.meta.sires.filter(value => value.id != bullListItem.id);
  }
}
