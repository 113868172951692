<div class="effective-groups-container">
    <div class="time-fields">
    <div class="section-field full-width-time-field">
      <div class="title-div" [attr.translation-id]="'LED_TASKS.TASK_EDITOR.START_TIME'">{{ 'LED_TASKS.TASK_EDITOR.START_TIME' | translate }}</div>
      <div class="time-picker-wrapper">
        <time-picker [shownRemovableIcon]="false"
                     [themeColor]="'#1999D5'"
                     [backgroundColor]="'#F4F4F4'"
                     [selectedEpoch]="ledTasksService.taskModel.startTime"
                     (epochChanged)="ledTasksService.clearErrors()"
                     [shIdPostfix]="'start-time-picker-input'"
                      [isFieldError]="ledTasksService.isFieldError(LedTaskFields.startTime)"></time-picker>
      </div>
    </div>
<!--    <div class="section-field single-time-field">
      <div class="title-div" [attr.translation-id]="'LED_TASKS.TASK_EDITOR.END_TIME'">{{ 'LED_TASKS.TASK_EDITOR.END_TIME' | translate }}</div>
      <div class="time-picker-wrapper">
        <time-picker [shownRemovableIcon]="false"
                     [themeColor]="'#1999D5'"
                     [backgroundColor]="'#F4F4F4'"
                     [selectedEpoch]="ledTasksService.taskModel.endTime"
                     [shIdPostfix]="'end-time-picker-input'"
                     (epochChanged)="ledTasksService.clearErrors()"
                     [isFieldError]="ledTasksService.isFieldError(LedTaskFields.endTime)"></time-picker>
      </div>
    </div>-->
  </div>
  <div class="section-field led-mode-fields">
    <div class="led-mode-wrapper">
      <radio-button [attr.sh-id]="'blink-fast-radio-button'"
                    label="{{ 'DIALOGS.ADHOC.TURN_LED_LIGHTING_ON_DIALOG.BLINK.FAST' | translate }}"
                    [colorScheme]="RadioButtonColorTheme.BlueMarine"
                    [isChecked]="isLedModeChecked(LedMode.Fast)"
                    (change)="onLedModeSelected(LedMode.Fast)"></radio-button>
      <div class="seperator"></div>
      <radio-button [attr.sh-id]="'blink-slow-radio-button'"
                    label="{{ 'DIALOGS.ADHOC.TURN_LED_LIGHTING_ON_DIALOG.BLINK.SLOW' | translate }}"
                    [colorScheme]="RadioButtonColorTheme.BlueMarine"
                    [isChecked]="isLedModeChecked(LedMode.Slow)"
                    (change)="onLedModeSelected(LedMode.Slow)"></radio-button>
    </div>
  </div>
  <div class="section-field duration-slider-fields">
    <div class="slider-title" [attr.translation-id]="'LED_TASKS.TASK_EDITOR.DURATION'">{{ 'LED_TASKS.TASK_EDITOR.DURATION' | translate }}:
      <span class="black-text">{{ ledTasksService.taskModel.selectedDuration | duration:DurationInput.HoursMinutesNoLeadingZeroText }}</span>
    </div>
    <div class="scroll-section">
      <div class="scroll-label">{{ ledTasksService.ledModeSettings.minText }}</div>
      <div class="inner-scroll">
        <duration-horizontal-scroll [currentValue]="ledTasksService.taskModel.selectedDuration"
                                    [shIdPostFix]="'led-duration'"
                                    [interval]="10"
                                    [minValue]="ledTasksService.ledModeSettings.minValue"
                                    [maxValue]="ledTasksService.ledModeSettings.maxValue"
                                    (onCurrentValueChange)="onSelectedDurationChanged($event)"></duration-horizontal-scroll>
      </div>
      <div class="scroll-label">{{ ledTasksService.ledModeSettings.maxText }}</div>
    </div>
  </div>
  <div class="section-field repeat-fields">
    <div class="title-and-checkbox-div" [attr.translation-id]="">
      <div class="title-div" [attr.translation-id]="'LED_TASKS.TASK_EDITOR.REPEAT'">{{ 'LED_TASKS.TASK_EDITOR.REPEAT' | translate }}</div>
    </div>
    <div class="days-list">
      <day-item [dayText]="'All Days'" [isError]="ledTasksService.isFieldError(LedTaskFields.weekdays)" [dayNumber]="0" [isSelected]="isAllDaysChecked()" (onDaySelected)="toggleRepeatAllDays()"></day-item>
      <ng-container *ngFor="let day of days">
        <day-item [dayText]="convertDayNumberToText(day)" [isError]="ledTasksService.isFieldError(LedTaskFields.weekdays)" [dayNumber]="day" [isSelected]="isDaySelected(day)" (onDaySelected)="toggleDay($event)"></day-item>
      </ng-container>
    </div>
  </div>
</div>
