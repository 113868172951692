import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {DialogActionsService} from '../../../../services/dialogs/dialog-actions.service';
import {AssignTagToAnimalDialogModel} from '../../../../services/dialogs/model/dialog-actions-model';
import {ITag, TagType} from '../../../../services/tags/model/tags-model';
import {AssignScrTagCowEventMetadata, CreateAssignSrcTagEventDetails} from '../../../../services/animals/model/animal-events';
import * as moment from 'moment';
import {AnimalEventsService} from '../../../../services/animals/animal-events.service';
import {RegularAutoCompleteComponent} from '../../auto-complete/regular-auto-complete/regular-auto-complete.component';
import {SearchService} from '../../../../services/search/search.service';
import {SearchEntry} from '../../../../services/search/model/search.model';

@Component({
  selector: 'assign-tag-to-animal-dialog',
  templateUrl: './assign-tag-to-animal-dialog.component.html',
  styleUrls: ['./assign-tag-to-animal-dialog.component.scss']
})
export class AssignTagToAnimalDialogComponent implements OnInit, OnDestroy {

  @ViewChild('tagSearchAutocomplete')
  public tagSearchAutocomplete: RegularAutoCompleteComponent;

  private dialogActionsSubscription: Subscription;

  public isOpen: boolean;

  public isApplyDisabled: boolean = true;

  public animalId: string;

  private error: string;

  public model: AssignTagToAnimalDialogModel;

  private selectedTag: ITag;

  private unassignedTagsNumbers: string[];

  constructor(private readonly dialogActionsService:DialogActionsService,
              private searchService: SearchService,
              private readonly animalEventsService:AnimalEventsService) { }

  public ngOnInit() {
    this.dialogActionsSubscription = this.dialogActionsService.assignTagToAnimalDialogSubject.subscribe(value => {
      this.selectedTag = null;
      this.model = value;
      this.unassignedTagsNumbers = this.model.unassignedTags.map(tag => tag.tagNumber);
      this.isOpen = true;
      this.isApplyDisabled = true;
      setTimeout(async () => {
        this.tagSearchAutocomplete.focusOnAutocomplete();
        await this.defineAnimalId();
      })
    });
  }

  public ngOnDestroy() {
    this.dialogActionsSubscription.unsubscribe();
  }

  public onMatchingItem(tagNumber:string) {
    if (tagNumber == null) {
      return;
    }
    this.error = null;
    if(tagNumber === '') {
      this.selectedTag = null;
      this.isApplyDisabled = true;
    } else {
      this.selectedTag = this.model.unassignedTags.find(tag => tag.tagNumber === tagNumber);
      if(this.selectedTag == null) {
        this.selectedTag = {tagNumber: tagNumber, tagType: TagType.scr, id: null};
      }
      this.isApplyDisabled = false;
    }
  }

  public async applyClick() {
    if (this.isApplyDisabled) {
      return;
    }
    this.isApplyDisabled = true;
    if(this.selectedTag == null) {
      return;
    }
    let assignTagEvent: CreateAssignSrcTagEventDetails = new CreateAssignSrcTagEventDetails(new AssignScrTagCowEventMetadata());
    assignTagEvent.tag = this.selectedTag;
    let response = await this.animalEventsService.createAnimalEvent(this.model.animalId, {startDateTime:moment().unix()}, assignTagEvent);
    if(response.status == 201) {
      this.isOpen = false;
      setTimeout(async () => {
        await this.model.actionsContext.reloadDefault();
        this.model = null;
      });
    } else {
      this.error = response.errorResponseBody.result.failures[0].key;
      this.isApplyDisabled = false;
    }
  }

  public async onTagSelected(): Promise<void> {
    await this.applyClick();
  }

  public closeClick() {
    this.error = null;
    if(this.model) {
      this.isOpen = false;
      this.model = null;
    }
  }

  private async defineAnimalId(): Promise<void> {
    if (this.model) {
      const availableAnimalsSearchEntities: SearchEntry[] = await this.searchService.listOfAvailableAnimals();
      this.animalId = availableAnimalsSearchEntities
        .filter((searchAnimal: SearchEntry) => this.model.animalId == searchAnimal.entityId)
        .map((animal: SearchEntry) => animal.entityName)[0];
    }
  }
}
