import { Component, OnInit } from '@angular/core';
import {PregnancyCheckEventRow} from '../../../../../services/animals/model/animal-events';
import {DescriptionCellComponent} from '../../../../../common/components/data-grid/description-cell-template/description-cell.component';
import { DateTimeFormatEnum } from 'src/app/common/pipes/epoch-date-time.pipe';

@Component({
  selector: 'negative-pregnancy-check-event-description',
  templateUrl: './negative-pregnancy-check-event-description.component.html',
  styleUrls: ['./negative-pregnancy-check-event-description.component.scss']
})
export class NegativePregnancyCheckEventDescriptionComponent extends DescriptionCellComponent<PregnancyCheckEventRow> implements OnInit {

  public DateTimeFormatEnum = DateTimeFormatEnum;

  constructor() {
    super();
  }

  public ngOnInit() {
  }

}
