<description-cell-details-template [hasRowDetails]="row.oldVersion"
                                   [isRowExpanded]="isExpanded"
                                   [row]="row"
                                   [rowShId]="row.eventId"
                                   (toggleExpanded)="toggleExpanded()">
  <ng-container key>
    <span [attr.translation-id]="'ANIMAL.EVENTS.EVENT_DESCRIPTION.TAG_SW_UPDATE.NEW_VERSION'">
      {{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.TAG_SW_UPDATE.NEW_VERSION' | translate}}:&nbsp;
    </span>
  </ng-container>
  <ng-container value>{{row.newVersion}}</ng-container>
  <ng-container content>
    <p class="description-item" *ngIf="row.oldVersion">
      <span [attr.translation-id]="'ANIMAL.EVENTS.EVENT_DESCRIPTION.TAG_SW_UPDATE.OLD_VERSION'">
        {{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.TAG_SW_UPDATE.OLD_VERSION' | translate}}:&nbsp;
      </span>
      <span class="value">{{row.oldVersion}}</span>
    </p>
  </ng-container>
</description-cell-details-template>
