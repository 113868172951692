import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BreedingCowEventMetadata, CreateBreedingEventDetails} from '../../../../../services/animals/model/animal-events';
import {BullListItem} from '../../../bull-choose-dropdown-list/bull-choose-dropdown-list.component';

@Component({
  selector: 'create-batch-event-breeding',
  templateUrl: './create-batch-event-breeding.component.html',
  styleUrls: ['./create-batch-event-breeding.component.scss']
})
export class CreateBatchEventBreedingComponent implements OnInit, OnChanges {

  @Input()
  public breedingEventDetails : CreateBreedingEventDetails;

  @Input()
  public meta: BreedingCowEventMetadata;

  public bullListItems: BullListItem[];

  public selectedBullListItem: BullListItem;

  constructor() { }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(this.meta == null) {
      this.bullListItems = [];
      this.selectedBullListItem = null;
    } else {
      this.bullListItems = this.meta.sires.map(value => {
        return {
          id: value.id,
          number: value.number,
          name: value.name
        }
      });
      if(this.breedingEventDetails.sire != null) {
        this.selectedBullListItem = this.bullListItems.find(value => value.id == this.breedingEventDetails.sire.id &&
                                                                              value.name == this.breedingEventDetails.sire.name &&
                                                                              value.number == this.breedingEventDetails.sire.number);
      }
    }
  }

  public onBullSelectionChanged(bullListItem: BullListItem) {
    this.breedingEventDetails.sire = {
      number: bullListItem.number,
      name: bullListItem.name,
      id: bullListItem.id
    };
  }

  public onBullRemoved(bullListItem:BullListItem) {
    this.bullListItems = this.bullListItems.filter(value => value.id != bullListItem.id);
  }
}
