import {Injectable} from '@angular/core';
import {PrintReportService} from '../export/print-report.service';
import {CsvExportService} from '../export/csv-export.service';
import {
  IReport,
  ReportActionType,
  ReportBatchActionType,
  ReportDataRow,
  ReportKeyType,
  ReportRowActionType
} from './model/report.interface';
import {ReportQuery} from './model/report-query.interface';
import {ReportsService} from './reports.service';
import {ImportFromCsvService} from '../import/import-from-csv.service';
import {EventTypeEnum} from '../animals/model/animal-events';
import {Subject} from 'rxjs';
import * as moment from 'moment';
import {AnimalEventsService} from '../animals/animal-events.service';
import {AnimalsService} from '../animals/animals.service';
import {GroupsService} from '../groups/groups.service';
import {DialogActionsService} from '../dialogs/dialog-actions.service';
import {TagsService} from '../tags/tags.service';
import {ITag} from '../tags/model/tags-model';
import {CreateBatchEventDialogModel} from '../dialogs/model/dialog-actions-model';
import {IManageBranchesViewContext, IManageGroupsViewContext, IReportViewContext} from './model/report-view-context';
import {EntityType, SearchService} from '../search/search.service';
import {OperationType} from '../search/model/search.model';
import {TurnLedLightingErrorDialogService} from '../../logged-in-layout/animal-events/animal-events-editor/dialogs/turn-led-lighting-error-dialog/turn-led-lighting-error-dialog.service';
import {BranchesService} from '../branches/branches.service';
import {SuccessDialogService} from '../../common/components/dialogs/success-dialog/success-dialog.service';
import {ApplicationBundleRoutingMode} from '../routing/routing.service';
import {ConfirmCullingAssignedTagDialogService} from '../../common/components/dialogs/confirm-dialog/confirm-culling-assigned-tag-dialog/confirm-culling-assigned-tag-dialog.service';
import {TurnLedLightingOnDialogModel} from './model/turn-led-lighting-on-dialog-model';

export class AddAnimalToReportDialogModel {

  public report:IReport;

  public reportContext:IReportViewContext;
}

@Injectable({
  providedIn: 'root'
})
export class ReportActionsService {

  public addAnimalToReportDialogSubject: Subject<AddAnimalToReportDialogModel> = new Subject<AddAnimalToReportDialogModel>();

  public turnLedLightningOnDialogSubject: Subject<TurnLedLightingOnDialogModel> = new Subject<TurnLedLightingOnDialogModel>();

  constructor(private reportsService:ReportsService,
              private printReportService: PrintReportService,
              private csvExportService: CsvExportService,
              private importFromCsvService: ImportFromCsvService,
              private animalEventsService: AnimalEventsService,
              public animalsService: AnimalsService,
              private groupsService: GroupsService,
              private branchesService: BranchesService,
              private searchService: SearchService,
              private dialogActionsService: DialogActionsService,
              private successDialogService: SuccessDialogService,
              private turnLedLightingErrorDialogService: TurnLedLightingErrorDialogService,
              private confirmCullingAssignedTagDialogService: ConfirmCullingAssignedTagDialogService,
              private tagsService: TagsService) {

  }

  public async performReportAction(reportContext:IReportViewContext, actionType:ReportActionType, report:IReport, reportQuery:ReportQuery, isExtendedReport?: boolean) {
    switch (actionType) {
      case ReportActionType.Print:
      {
        await this.print(reportContext, reportQuery, isExtendedReport);
        break;
      }
      case ReportActionType.Export:
      {
        await this.exportToFile(reportContext, reportQuery, isExtendedReport);
        break;
      }
      case ReportActionType.Import:
      {
        this.importFromCsvService.onImportFromFile.next();
        break;
      }
      case ReportActionType.AddAnimalToList: {
        await this.openAddAnimalToReportDialog(reportContext, report);
        break;
      }
      case ReportActionType.TurnLedLightingOn: {
        await this.openTurnLedLightningOnDialog(reportContext, report, false);
        break;
      }
    }
  }

  public async performReportBatchAction(reportContext:IReportViewContext, actionType:ReportBatchActionType, report:IReport, reportQuery:ReportQuery) {
    switch (actionType) {
      case ReportBatchActionType.Print:
      {
        await this.printSelected(reportContext, reportQuery, report);
        break;
      }
      case ReportBatchActionType.Export:
      {
        await this.exportSelectedToFile(reportContext, reportQuery, report);
        break;
      }
      case ReportBatchActionType.Breed:
      {
        await this.createBatchEvent(reportContext,
                                    EventTypeEnum.Breeding,
                                    this.getBatchEventAnimalsFromSelectedRows(reportContext, report, false));
        break;
      }
      case ReportBatchActionType.PregnancyCheck:
      {
        await this.createBatchEvent(reportContext,
                                    EventTypeEnum.PregnancyCheck,
                                    this.getBatchEventAnimalsFromSelectedRows(reportContext, report, false));
        break;
      }
      case ReportBatchActionType.Abortion:
      {
        await this.createBatchEvent(reportContext,
                                    EventTypeEnum.Abortion,
                                    this.getBatchEventAnimalsFromSelectedRows(reportContext, report, false));
        break;
      }
      case ReportBatchActionType.Cull: {
        await this.createBatchEvent(reportContext,
                                    EventTypeEnum.Culling,
                                    this.getBatchEventAnimalsFromSelectedRows(reportContext, report, false));
        break;
      }
      case ReportBatchActionType.DoNotBreedOn: {
        await this.createBatchEvent(reportContext,
                                    EventTypeEnum.DoNotBreed,
                                    this.getBatchEventAnimalsFromSelectedRows(reportContext, report, false));
        break;
      }
      case ReportBatchActionType.TurnLedLightingOn: {
        await this.openTurnLedLightningOnDialog(reportContext, report, true);
        break;
      }
    }
  }

  public async performReportRowAction(reportContext:IReportViewContext, row:ReportDataRow, actionType:ReportRowActionType, report:IReport) {
    switch (actionType) {
      case ReportRowActionType.Disable:
      case ReportRowActionType.Enable: {
        await this.ignoreMaintenanceCall(reportContext, row, report, actionType == ReportRowActionType.Disable ? true : false);
        return;
      }
      case ReportRowActionType.AssignScrTag: {
        if (report.meta.reportKeyType == ReportKeyType.animal) {
          let unassignedScrTags: ITag[] = [];
          let tagsAssignmentResponse = await this.tagsService.getScrUnassignedTags();
          if (tagsAssignmentResponse.responseBody &&
            tagsAssignmentResponse.responseBody.scrTags &&
            tagsAssignmentResponse.responseBody.scrTags.unassignedTags) {
            unassignedScrTags = tagsAssignmentResponse.responseBody.scrTags.unassignedTags;
          }
          await this.dialogActionsService.openAssignTagToAnimal(reportContext, row['CowDatabaseIDCalculation'], unassignedScrTags);
        } else if (report.meta.reportKeyType == ReportKeyType.tag) {
          await this.dialogActionsService.openAssignAnimalToTag(reportContext, row['TagNumberCalculation']);
        }
      }
        return;
      case ReportRowActionType.DoNotBreedOn: {
        await this.createBatchEvent(reportContext, EventTypeEnum.DoNotBreed,  [row['CowDatabaseIDCalculation']]);
        break;
      }
      case ReportRowActionType.Abortion: {
        await this.createBatchEvent(reportContext, EventTypeEnum.Abortion,  [row['CowDatabaseIDCalculation']]);
        break;
      }
      case ReportRowActionType.PregnancyCheck: {
        await this.createBatchEvent(reportContext, EventTypeEnum.PregnancyCheck,  [row['CowDatabaseIDCalculation']]);
        break;
      }
      case ReportRowActionType.Breed: {
        await this.createBatchEvent(reportContext, EventTypeEnum.Breeding,  [row['CowDatabaseIDCalculation']]);
        break;
      }
      case ReportRowActionType.SendToSortingGate:
        await this.dialogActionsService.openSendToSortingGateDialog(row['AnimalIDCalculation']);
        break;
      case ReportRowActionType.Cull: {
        // tslint:disable-next-line:no-any
        if (row.hasOwnProperty('CowScrTagNumberCalculation') && (row as any).CowScrTagNumberCalculation) {
          const isConfirmedByUser = await this.confirmCullingAssignedTagDialogService.showPopup();
          if (isConfirmedByUser) {
            await this.createBatchEvent(reportContext, EventTypeEnum.Culling,  [row['CowDatabaseIDCalculation']]);
          }
        } else {
          await this.createBatchEvent(reportContext, EventTypeEnum.Culling,  [row['CowDatabaseIDCalculation']]);
        }
        break;
      }
      case ReportRowActionType.RemoveCulling: {
        await this.removeCulling(reportContext, row['CowDatabaseIDCalculation']);
        break;
      }
      case ReportRowActionType.Remove:
        if (report.meta.reportKeyType == ReportKeyType.branch) {
          await this.removeBranch(reportContext, row);
        } else {
          await this.removeGroup(reportContext, row);
        }
        break;
      case ReportRowActionType.Edit: {
        if(report.meta.reportKeyType == ReportKeyType.group) {
          await this.editGroup(<IManageGroupsViewContext>reportContext, row);
        } else if (report.meta.reportKeyType == ReportKeyType.branch) {
          await this.editBranch(<IManageBranchesViewContext>reportContext, row);
        }
        break;
      }
      default:
        break;
    }
  }

  private async ignoreMaintenanceCall(reportContext:IReportViewContext, row: ReportDataRow, report:IReport, newValue:boolean) {
    // tslint:disable-next-line:no-any
    const setup: any = {
      requestType: 'updateValue',
      columnKey: 'IGNORE_MAINTENANCE_CALL',
      keyEntityId: row['TagDatabaseIDCalculation'],
      newValue: newValue
    };
    await this.reportsService.ignoreMaintenanceCall(report.meta.reportId.toString(), setup);
    await reportContext.reload(false, report.meta.reportTime);
  }

  public async removeCulling(reportContext:IReportViewContext, animalId:number) {
    let response = await this.animalEventsService.animalRemoveCulling(animalId);
    if(response.status == 200) {
      await reportContext.reload(true, null);
    }
  }

  public async removeGroup(reportContext:IReportViewContext, row: ReportDataRow) {
    let entityOperationType = await this.searchService.findOperationTypeByEntityId(Number(row['GroupDatabaseIDCalculation']), EntityType.group);
    if (entityOperationType === OperationType.BeefFinishing) {
      await this.groupsService.deleteFinishingGroup(row['GroupDatabaseIDCalculation']);
    } else {
      await this.groupsService.deleteGroup(row['GroupDatabaseIDCalculation']);
    }
    await reportContext.reload(true, null);
  }

  public async removeBranch(reportContext:IReportViewContext, row: ReportDataRow) {
    await this.branchesService.deleteBranch(row['BranchDatabaseIDCalculation']);
    await reportContext.reload(true, null);
    await this.branchesService.onBranchesListChanged.next();
  }

  public async editGroup(manageGroupsContext:IManageGroupsViewContext, row: ReportDataRow) {
    manageGroupsContext.editGroup(row);
  }

  public async editBranch(manageBranchesContext: IManageBranchesViewContext, row: ReportDataRow) {
    manageBranchesContext.editBranch(row);
  }

  public async exportToFile(reportContext:IReportViewContext, reportQuery:ReportQuery, isExtendedReport?: boolean) {
    let report: IReport = await this.reportsService.getAllRowsReport(reportContext, reportQuery, isExtendedReport);
    if (report) {
      await this.csvExportService.exportReportCsv(report,  reportContext.getPrintReportHeader(), reportContext.getPrintIncludeDateInHeader());
    }
  }

  public async print(reportContext:IReportViewContext, reportQuery:ReportQuery, isExtendedReport?: boolean) {
    let report: IReport = await this.reportsService.getAllRowsReport(reportContext, reportQuery, isExtendedReport);
    if (report) {
      await this.printReportService.printReport(report, reportContext.getPrintReportHeader(), reportContext.getPrintIncludeDateInHeader());
    }
  }

  public async printSelected(reportContext:IReportViewContext, reportQuery:ReportQuery, report:IReport) {
    let selectedLinesReport: IReport =  await reportContext.getSelectedRowsReport();
    if (selectedLinesReport) {
      await this.printReportService.printReport(selectedLinesReport,  reportContext.getPrintReportHeader(), reportContext.getPrintIncludeDateInHeader());
    }
  }

  public async exportSelectedToFile(reportContext:IReportViewContext, reportQuery:ReportQuery, report:IReport) {
    let selectedLinesReport: IReport = await reportContext.getSelectedRowsReport();
    if (selectedLinesReport) {
      await this.csvExportService.exportReportCsv(selectedLinesReport,  reportContext.getPrintReportHeader(), reportContext.getPrintIncludeDateInHeader());
    }
  }

  public async openAddAnimalToReportDialog(reportContext:IReportViewContext, report:IReport) {
    let dialogModel = new AddAnimalToReportDialogModel();
    dialogModel.reportContext = reportContext;
    dialogModel.report = report;
    this.addAnimalToReportDialogSubject.next(dialogModel);
  }

  public async openTurnLedLightningOnDialog(reportContext:IReportViewContext & {reportKey?: string}, report:IReport, isBatch: boolean = false): Promise<void> {
    const dialogModel = new TurnLedLightingOnDialogModel();
    dialogModel.animalIds = isBatch
      ? this.getBatchEventAnimalsFromSelectedRows(reportContext, report, true)
      : this.getAllEventAnimalsFromSelectedRows(reportContext, report, true);
    dialogModel.reportId = Number(reportContext.reportKey);
    this.turnLedLightningOnDialogSubject.next(dialogModel);
  }

  public async turnLedLightingOn(turnLedLightningOnDialogModel: TurnLedLightingOnDialogModel): Promise<void> {
    const response = await this.reportsService.postTurnLedLightingOn(turnLedLightningOnDialogModel);
    if (response.status === 201) {
      this.successDialogService.show();
    } else {
      this.turnLedLightingErrorDialogService.show(response.errorResponseBody.result.failures);
    }
  }

  public async addAnimalToReport(addAnimalToReportDialogModel:AddAnimalToReportDialogModel, animalId:string) {
    let response = await this.reportsService.includeAnimalInReport(addAnimalToReportDialogModel.report.meta.reportId, animalId);
    await addAnimalToReportDialogModel.reportContext.reload(false, addAnimalToReportDialogModel.report.meta.reportTime);
  }

  private async createBatchEvent(reportContext:IReportViewContext,
                                 eventType: EventTypeEnum,
                                 animalIds:number[]) {
    let createBatchEventDetails = new CreateBatchEventDialogModel();

    createBatchEventDetails.animalIds = animalIds;
    const currentTimestamp = moment().valueOf();
    if(reportContext.applicationBundleRoutingMode == ApplicationBundleRoutingMode.Finishing) {
      const batchEvent = (await this.animalEventsService.getAvailableFinishingBatchEvents(currentTimestamp)).find(be => be.type === eventType);
      createBatchEventDetails.meta = await this.animalEventsService.getFinishingBatchEventTypeMetadata(batchEvent.type, new Date());
    } else {
      const batchEvent = (await this.animalEventsService.getAvailableBatchEvents(currentTimestamp)).find(be => be.type === eventType);
      createBatchEventDetails.meta = await this.animalEventsService.getBatchEventTypeMetadata(batchEvent.type, new Date());
    }
    createBatchEventDetails.eventDetails = this.animalEventsService.getDefaultEventDetails(eventType, createBatchEventDetails.meta);
    createBatchEventDetails.actionsContext = reportContext;
    await this.dialogActionsService.openCreateBatchEventDialog(createBatchEventDetails);
  }

  private getBatchEventAnimalsFromSelectedRows(reportContext:IReportViewContext, report:IReport, ignoreManuallyAddedAnimals: boolean) : number[] {
    let databaseIdFieldName = this.reportsService.getDatabaseIdFieldNameForReport(report.meta.reportName);
    let selectedRows = reportContext.getLoadedRows()
      .filter((row: ReportDataRow) =>
        reportContext.getRowSelectionState().isRowSelected(row.rowId));
    if (!ignoreManuallyAddedAnimals) {
      selectedRows = selectedRows
        .filter((row: ReportDataRow) => report.rowColoring.findIndex((coloredRow) => coloredRow.rowNumber === row.rowNumber
          && coloredRow.colorType != 'AddedRow') == -1)
    } else {
      selectedRows = selectedRows
        .filter((row: ReportDataRow) => report.rowColoring.findIndex((coloredRow) => coloredRow.rowNumber === row.rowNumber) == -1);
    }
    return selectedRows.map(r => r[databaseIdFieldName]);
  }

  private getAllEventAnimalsFromSelectedRows(reportContext:IReportViewContext, report:IReport, ignoreManuallyAddedAnimals: boolean) : number[] {
    let databaseIdFieldName = this.reportsService.getDatabaseIdFieldNameForReport(report.meta.reportName);
    let loadedRows = reportContext.getLoadedRows();
    if (!ignoreManuallyAddedAnimals) {
      loadedRows = loadedRows.filter((row) => report.rowColoring.findIndex((coloredRow) => coloredRow.rowNumber === row.rowNumber
        && coloredRow.colorType != 'AddedRow') == -1)
    } else {
      loadedRows = loadedRows.filter((row) => report.rowColoring.findIndex((coloredRow) => coloredRow.rowNumber === row.rowNumber) == -1)
    }
    return loadedRows.map(r => r[databaseIdFieldName]);
  }
}
