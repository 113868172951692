<div class="create-event-form">
  <div class="form-section-title">
    {{ 'ANIMAL.EVENTS.ADD_EVENT.CALVING_EASE' | translate }}
  </div>
  <div class="form-section-item">
    <forms-dropdown-list
      class="form__control"
      [iconTheme]="formsDLIconsThemes.Blue"
      [items]="calvingEaseDropdownItems"
      [(ngModel)]="calvingEase"
      [placeholder]="'ANIMAL.EVENTS.ADD_EVENT.CALVING_EASE_PLACEHOLDER'">
    </forms-dropdown-list>
  </div>
  <div class="newborn-container" *ngIf="!isAddNewbornsDisabled && mode === CreateEventMode.single">
    <button class="add-newborn-button"
            (click)="handleAddNewborn($event)"
            [disabled]="editModel.newborns.length >= 3">
      <div class="add-newborn-button__icon"></div>
      {{ 'ANIMAL.EVENTS.ADD_EVENT.ADD_NEW_BORN' | translate }}
    </button>
  </div>
</div>

<div class="create-event-form">
  <div class="form-section-title">
    {{ 'ANIMAL.EVENTS.ADD_EVENT.DESTINATION_GROUP' | translate }}
  </div>
  <div class="form-section-item">
    <forms-dropdown-list
      class="form__control"
      [items]="destinationGroupsDropdownItems"
      [placeholder]="'ANIMAL.EVENTS.ADD_EVENT.DESTINATION_GROUP_PLACEHOLDER'"
      [(ngModel)]="destinationGroup"
      [iconTheme]="formsDLIconsThemes.Blue"
      [isFieldError]="isFieldNameWithError(EventFormFieldName.destinationGroup)"
      [translateListItem]="false"
      required>
    </forms-dropdown-list>
  </div>
  <div class="newborn-list-container" *ngIf="!isAddNewbornsDisabled && editModel.newborns && editModel.newborns.length > 0">
    <div class="form-section-title">
      {{ 'ANIMAL.EVENTS.ADD_EVENT.SEX' | translate }}
    </div>
    <div class="newborns-control form__control">
      <div class="newborn-item"
           *ngFor="let newborn of editModel.newborns; let i = index"
           [class.newborns-control__item--invalid]="isFieldErrorForNewBornItem(i)"
           (click)="handleEditNewborn($event, newborn)">
        <span>{{ newborn.sex  | uppercase | slice : 0:1 }}</span>
        <div class="border"></div>
      </div>
    </div>
  </div>
</div>

<add-newborn-dialog
  [meta]="meta"
  [destinationGroups]="meta.newBornsGroups"
  [enableRemoveButton]="editableNewbornIndex != null"
  [model]="editedNewborn"
  [errorFieldName]="newbornErrorField"
  (close)="handleCloseAddNewbornPopup()"
  (newbornChange)="handleAddNewbornChange($event)"
  (remove)="handleRemoveNewborn()">
</add-newborn-dialog>
