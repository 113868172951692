import {IReport} from '../../reports/model/report.interface';
import {IFarmStatisticsFinishingWidgetContent} from './farm-statistics-finishing';
import {FarmStatisticsDairyWidget} from './farm-statistics-dairy';
import {FarmStatisticsBeefWidget} from './farm-statistics-beef';

export enum WidgetType {
  Report = 'Report',
  Graph = 'Graph'
}

export interface IDashboardAvailableWidget {
  type: WidgetType,
  name: string,
  category: string
}

export interface IDashboardWidget {
  type: WidgetType,
  name: string,
  category: string,
  isSuspended: boolean;
  content: IReport | IFarmStatisticsFinishingWidgetContent | FarmStatisticsDairyWidget | FarmStatisticsBeefWidget;
}
