<div class="farm-page" >
  <div class="double-field-div">
    <div class="input-field">
      <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER'">
        {{ 'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER' | translate }}
      </div>
      <div class="value">
        <input class="input-class colored"
               (ngModelChange)="clearErrors()"
               [ngClass]="{ error: animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.animalName)}"
               type="text"
               [(ngModel)]="addFinishingFemaleModel.animalNumber"
               [attr.sh-id]="'finishing_add_female_animal_number_farm_tab'"
               [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER'"
               placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER' | translate }}" readonly/>
      </div>
    </div>
    <div class="input-field">
      <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'">
        {{ 'MANAGE.COWS.ACTIVE.WIZARD.GROUP' | translate }}
      </div>
      <div class="value">
        <regular-dropdown-list class="dropdown-list"
                               [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.group)"
                               [shIdPostfix]="'finishing_add_female_group_farm_tab'"
                               [items]="groupsDropdownListItems"
                               [placeholder]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'"
                               [allowClear]="true"
                               [selectedValue]="addFinishingFemaleModel.group"
                               (onItemSelected)="onGroupSelected($event)"></regular-dropdown-list>
      </div>
    </div>
  </div>
  <div class="seperator"></div>
  <div class="fields-and-errors-div">
    <div class="single-field-div">
      <div class="input-field paddingTop">
        <div class="title" [attr.translation-id]="'MANAGE.FINISHING.ACTIVE.WIZARD.ENTRY_DATE'">
          {{ 'MANAGE.FINISHING.ACTIVE.WIZARD.ENTRY_DATE' | translate }}
        </div>
        <div class="value">
          <date-picker [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.entryDate)"
                       [calendarIconColor]="'#B14C9B'"
                       [attr.sh-id]="'finishing_add_female_entry_date_farm_tab'"
                       [selectedEpoch]="addFinishingFemaleModel.entryDate"
                       [shownRemovableIcon]="false"
                       [shIdPostfix]="'finishing_add_female_entry_date_farm_tab_date_picker'"
                       (epochChanged)="onEntryDateChanged($event)"></date-picker>
        </div>
      </div>
      <div class="input-field paddingTop">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE' | translate }}
        </div>
        <div class="value">
          <date-picker [isFieldError]="hasErrorForBirthdateField"
                       [calendarIconColor]="'#B14C9B'"
                       [attr.sh-id]="'finishing_add_female_birth_date_farm_tab'"
                       [selectedEpoch]="addFinishingFemaleModel.birthDate"
                       [shownRemovableIcon]="true"
                       [shIdPostfix]="'finishing_add_female_birth_date_farm_tab_date_picker'"
                       (epochChanged)="onbirthDateChanged($event)"></date-picker>
        </div>
      </div>
      <div class="input-field paddingTop">
        <div class="title" [attr.translation-id]="'MANAGE.FINISHING.ACTIVE.WIZARD.WEIGHING_DATE'">
          {{ 'MANAGE.FINISHING.ACTIVE.WIZARD.WEIGHING_DATE' | translate }}
        </div>
        <div class="value">
          <date-picker [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.weightDate)"
                       [calendarIconColor]="'#B14C9B'"
                       [attr.sh-id]="'finishing_add_female_weighing_date_farm_tab'"
                       [selectedEpoch]="addFinishingFemaleModel.weighing"
                       [disabled]="isBirthdateAndEntryDateNotDefined()"
                       [shownRemovableIcon]="true"
                       [shIdPostfix]="'finishing_add_female_weighing_date_farm_tab_date_picker'"
                       (epochChanged)="onWeighingChanged($event)"></date-picker>
        </div>
      </div>
    </div>
    <div class="single-field-div">
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.FINISHING.ACTIVE.WIZARD.WEIGHING_TIME'">
          {{ 'MANAGE.FINISHING.ACTIVE.WIZARD.WEIGHING_TIME' | translate }}
        </div>
        <div class="value">
          <time-picker [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.weightDate)"
                       [themeColor]="'#B14C9B'"
                       [attr.sh-id]="'finishing_add_female_weighing_date_farm_tab'"
                       [selectedEpoch]="addFinishingFemaleModel.weighing"
                       [disabled]="isBirthdateAndEntryDateNotDefined()"
                       [shownRemovableIcon]="true"
                       [shIdPostfix]="'finishing_add_female_weighing_date_farm_tab_date_picker'"
                       (epochChanged)="onWeighingChanged($event)"></time-picker>
        </div>
      </div>
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.FINISHING.ACTIVE.WIZARD.WEIGHT'" [ngClass]="{'disabled': isBirthdateAndEntryDateNotDefined()}">
          {{ 'MANAGE.FINISHING.ACTIVE.WIZARD.WEIGHT' | translate }}
        </div>
        <div class="value">
          <input class="input-class"
                 *ngIf="isKgValueDisplayed"
                 [ngClass]="{ error: animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.weightValueKG), disabled: isBirthdateAndEntryDateNotDefined() }"
                 [disabled]="isBirthdateAndEntryDateNotDefined()"
                 placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL' | translate }}"
                 [attr.sh-id]="'finishing_add_female_weight_value_farm_tab'"
                 weightValueLimit
                 contenteditable='true'
                 [(ngModel)]="addFinishingFemaleModel.weightValueKG"
                 (ngModelChange)="clearErrors()"
                 type="text"/>
          <input class="input-class"
                 *ngIf="!isKgValueDisplayed"
                 [ngClass]="{ error: animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.weightValueLBS), disabled: isBirthdateAndEntryDateNotDefined() }"
                 [disabled]="isBirthdateAndEntryDateNotDefined()"
                 placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL' | translate }}"
                 [attr.sh-id]="'finishing_add_female_weight_value_farm_tab'"
                 weightValueLimit
                 contenteditable='true'
                 [(ngModel)]="addFinishingFemaleModel.weightValueLBS"
                 (ngModelChange)="clearErrors()"
                 type="text"/>
        </div>
      </div>
    </div>
  </div>
</div>
