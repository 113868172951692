import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {RegistrationService} from '../../../services/registration/registration.service';
import {IFarmDetailsResponse} from '../../../services/registration/model/registration.model';
import {DropdownListItem} from '../../../common/components/dropdown-list/dropdown-list-model';
import {DropdownColorScheme} from '../../../common/components/dropdown-list/regular-dropdown-list/regular-dropdown-list.component';
import {TranslationService} from '../../../services/translations/translation.service';
import {
  AboutModelPopulationPlanEnum,
  IOrderedPopulationPlanItem,
  OrderedPopulationPlanModel,
  PopulationPlanName
} from "../../../services/system/model/about-model";
import {FarmMode} from "../../../services/config/model/server-config";
import {TrialOperation} from "../../../services/config/model/anonymous-auth-config";

export enum RegistrationPopulationPlan {
  None = 'None',
  Starter = 'Starter',
  Advanced = 'Advanced',
  Premium = 'Premium',
  YoungStock = 'YoungStock',
  Finishing = 'Finishing'
}

@Component({
  selector: 'account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AccountDetailsComponent implements OnInit {

  public FarmHousingOptionItems: DropdownListItem[] = [];

  public selectedFarmHousing: DropdownListItem;

  public CountriesOptionItems: DropdownListItem[] = [];

  public selectedCountry: DropdownListItem;

  public TimeZoneOptionItems: DropdownListItem[] = [];

  public selectedTimeZone: DropdownListItem;

  public orderedPopulationPlanModel: OrderedPopulationPlanModel;

  public showMultipleApplicationPlans: boolean;

  public showSingleApplicationPlanOnly: boolean;

  public dropdownColorScheme = DropdownColorScheme;

  public PopulationPlanName = PopulationPlanName;

  constructor(public registrationService: RegistrationService,
              private translationService: TranslationService) { }

  public async ngOnInit() {
    let farmDetails = await this.registrationService.getFarmDetails();
    this.registrationService.registrationModel.farmDetails = farmDetails.responseBody;
    this.calculatePlans();
    this.updateFarmDetailsModelFromCloud(farmDetails.responseBody);
  }

  public calculatePlans(): void {
    this.orderedPopulationPlanModel = new OrderedPopulationPlanModel(this.registrationService.registrationModel.farmDetails.orderedPopulationPlan.plans);
    this.showMultipleApplicationPlans = this.orderedPopulationPlanModel.showMultipleApplicationPlans();
    this.showSingleApplicationPlanOnly = this.orderedPopulationPlanModel.showSingleApplicationPlanOnly() && !this.orderedPopulationPlanModel.hasFinishingOrYoungStock();
  }

  public updateFarmDetailsModelFromCloud(farmDetails: IFarmDetailsResponse) {
    this.FarmHousingOptionItems = [];
    // tslint:disable-next-line:no-any
    farmDetails.metadata.farmHousing.forEach((farmHouse: any) => {
      let item: DropdownListItem = {
        value: farmHouse.value,
        disabled: false,
        displayValue: this.translationService.translate('REGISTRATION.FARM_HOUSING.' + farmHouse.value),
        icon: null
      };
      this.FarmHousingOptionItems.push(item);
    });
    this.CountriesOptionItems = [];
    // tslint:disable-next-line:no-any
    farmDetails.metadata.countries.forEach((country: any) => {
      let item: DropdownListItem = {
        value: country.countryName,
        disabled: false,
        displayValue: country.countryName,
        icon: null
      };
      this.CountriesOptionItems.push(item);
    });
    this.TimeZoneOptionItems = [];
    // tslint:disable-next-line:no-any
    farmDetails.metadata.timeZones.forEach((timeZone: any) => {
      let item: DropdownListItem = {
        displayValue: timeZone.value,
        disabled: false,
        value: timeZone.key,
        icon: null
      };
      this.TimeZoneOptionItems.push(item);
    });
    this.selectedFarmHousing = this.FarmHousingOptionItems.find((farmHousing: DropdownListItem) => farmHousing.value === farmDetails.farmHousing) || null;

    this.selectedCountry = this.CountriesOptionItems.find((country: DropdownListItem) => country.value === farmDetails.country) || null;
    // { displayValue: farmDetails.country, isVisible: true, disabled: false, value: farmDetails.country };
    this.selectedTimeZone = this.TimeZoneOptionItems.find((timeZone: DropdownListItem) => timeZone.value === farmDetails.timeZone) || null;
    // { displayValue: '', isVisible: true, disabled: false, value: farmDetails.timeZone };
  }

  public selectFarmHousing(farmHousing: DropdownListItem) {
    this.selectedFarmHousing = farmHousing;
    if (this.selectedFarmHousing) {
      this.registrationService.registrationModel.farmDetails.farmHousing = this.selectedFarmHousing.value;
    } else {
      this.registrationService.registrationModel.farmDetails.farmHousing = null;
    }
  }

  public selectCountry(country: DropdownListItem) {
    this.selectedCountry = country;
    if (this.selectedCountry) {
      this.registrationService.registrationModel.farmDetails.country = this.selectedCountry.value;
      // tslint:disable-next-line:no-any
      let countryItem: any = this.registrationService.registrationModel.farmDetails.metadata.countries.find((country: any) => country.countryName === this.selectedCountry.value);
      this.registrationService.registrationModel.farmDetails.countryCode = countryItem.countryCode;
    } else {
      this.registrationService.registrationModel.farmDetails.country = null;
      this.registrationService.registrationModel.farmDetails.countryCode = null;
    }
  }

  public selectTimeZone(timeZone: DropdownListItem) {
    this.selectedTimeZone = timeZone;
    if (this.selectedTimeZone) {
      this.registrationService.registrationModel.farmDetails.timeZone = this.selectedTimeZone.value;
    } else {
      this.registrationService.registrationModel.farmDetails.timeZone = null;
    }
  }

  // tslint:disable-next-line:no-any
  public convertItemsToDropdownListItemList(keyValueList: any[]) {
    let dropdownListToReturn: DropdownListItem[] = [];
    if (keyValueList && keyValueList.length > 0) {
      // tslint:disable-next-line:no-any
      keyValueList.forEach((keyValue: any) => {
        dropdownListToReturn.push({
          value: keyValue.key,
          displayValue: this.translationService.translate('REGISTRATION.FARM_TYPE.' + keyValue.key),
          disabled: false,
          icon: null
        })
      });
    }
  }

  public clearErrors() {
    this.registrationService.registrationModel.error = undefined;
  }

  public isFinishingStandalone() : boolean {
    return this.registrationService.registrationModel.farmMode == FarmMode.Beef &&
      this.registrationService.registrationModel.operation == TrialOperation.finishing
  }

  public showApplicationPlanItem(item: IOrderedPopulationPlanItem): boolean {
    return this.orderedPopulationPlanModel.showApplicationPlanItem(item);
  }

  public showPlanDetails(item: IOrderedPopulationPlanItem): boolean {
    if (item.plan == PopulationPlanName.Cows || item.plan == PopulationPlanName.Heifers) {
      return !this.orderedPopulationPlanModel.showSingleApplicationPlanOnly();
    }
    return false;
  }
}
