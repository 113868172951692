import {IGroupChartHeatSetupSeries, IGroupChartTrendHeatSetupSeries, IGroupDetails} from '../../../services/groups/model/group.interface';
import {IChartSeriesSetupRecord, IChartSetup} from '../../../services/charts/model/chart.interface';
import {IAnimalChartSetupSeries} from '../../../services/animals/model/animal.model';

export class GroupCardViewState {
  public groupDetails: IGroupDetails;

  public selectedTab: GroupCardTabSection = GroupCardTabSection.Details;

  public activityRuminationMode: GroupActivityRuminationMode = GroupActivityRuminationMode.Activity;

  public activityRuminationChartSelectedDuration: number = 0;

  public graphsHeatChartSelectedDuration: number = 0;

  public trendsConsistencySelectedDuration: number = 5;

  public trendsHeatSelectedDuration: number = 5;

  public healthAndDimFilterEnabled: boolean = false;

  public groupHeatChartSetup: IChartSetup<IChartSeriesSetupRecord, IGroupChartHeatSetupSeries>;

  public groupTrendsHeatChartSetup: IChartSetup<IChartSeriesSetupRecord, IGroupChartTrendHeatSetupSeries>;

  public finishingGroupTrendsHeatChartSetup: IChartSetup<IChartSeriesSetupRecord, IGroupChartTrendHeatSetupSeries>;
}

export enum GroupActivityRuminationMode {
  Activity = 'Activity',
  Rumination = 'Rumination'
}

export enum GroupCardTabSection {
  Details = 'Details',
  GraphsActivity = 'GraphsActivity',
  GraphsActivityRumination = 'GraphsActivityRumination',
  GraphsHeat = 'GraphsHeat',
  TrendsConsistency = 'TrendsConsistency',
  TrendsHeat = 'TrendsHeat'
}

export enum GroupCardDetailsAnimalRowActionType {
  changeGroup = 'changeGroup',
  cullAnimal = 'cullAnimal',
  assignTag = 'assignTag'
}
