import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IDashboardDetailedKPI, GraphType} from '../../../../../services/dashboard/model/dashboard-kpi.interface';
import {DashboardKpiService} from '../../../../../services/dashboard/dashboard-kpi.service';

@Component({
  selector: 'dashboard-kpi-graph-bar',
  templateUrl: './dashboard-kpi-graph-bar.component.html',
  styleUrls: ['./dashboard-kpi-graph-bar.component.scss']
})
export class DashboardKpiGraphBarComponent implements OnInit {

  @Input()
  public details: IDashboardDetailedKPI;
  @ViewChild('barWrapper', {static: true})
  public barWrapper : ElementRef<HTMLElement>;

  private _currentValuePosition : number;

  constructor(public readonly dashboardKpiService:DashboardKpiService,
              private readonly changeDetector: ChangeDetectorRef) {

  }

  public ngOnInit() {
  }

  public get valuePosition() : number {
    let newValuePosition : number;
    if(this.barWrapper == null) {
      newValuePosition = 0;
    }
    else {
      newValuePosition =  this.dashboardKpiService.getValueRatio(this.details.mainValue.value,
        this.details.graph.sections[0].minSectionLimit,
        this.details.graph.sections[this.details.graph.sections.length - 1].maxSectionLimit);
      newValuePosition = this.barWrapper.nativeElement.getBoundingClientRect().left + (this.barWrapper.nativeElement.getBoundingClientRect().width * newValuePosition);
    }
    if(newValuePosition != this._currentValuePosition) {
      this._currentValuePosition = newValuePosition;
      this.changeDetector.detectChanges();
    }
    return this._currentValuePosition;
  }
}
