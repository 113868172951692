<description-cell-details-template [hasRowDetails]="hasRowDetails"
                                   [isRowExpanded]="isExpanded"
                                   [row]="row"
                                   [rowShId]="row.rowId"
                                   (toggleExpanded)="toggleExpanded()">
  <ng-container key>{{("SORTING_GATE.SORTING_REPORT.SORTING_REASONS." + row.SortingReasonCalculation.mainReason) | translate}}</ng-container>
  <ng-container value></ng-container>
  <ng-container content>
    <p class="description-item" *ngFor="let otherReason of row.SortingReasonCalculation.otherReason">{{("SORTING_GATE.SORTING_REPORT.SORTING_REASONS." + otherReason) | translate}}</p>
  </ng-container>
</description-cell-details-template>
