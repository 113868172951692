import {Component, Input} from '@angular/core';

@Component({
  selector: 'manage-settings-notifications-alerts',
  templateUrl: './manage-settings-notifications-alerts.component.html',
  styleUrls: ['./manage-settings-notifications-alerts.component.scss']
})
export class ManageSettingsNotificationsAlertsComponent {
  @Input()
  public isFinishing: boolean;
}
