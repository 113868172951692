import {Component, Input, OnInit} from '@angular/core';
import {AnimalTimelineNode} from '../../../../../services/animal-timeline/model/animal-timeline-model';

@Component({
  selector: 'animal-card-timeline-node',
  templateUrl: './animal-card-timeline-node.component.html',
  styleUrls: ['./animal-card-timeline-node.component.scss']
})
export class AnimalCardTimelineNodeComponent implements OnInit {

  @Input()
  public nodeDetails: AnimalTimelineNode;
  
  @Input()
  public showDetails: boolean;
  
  public constructor() { }

  public ngOnInit() {
  
  }

}
