import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  DashboardService,
  IMaintenanceCallsResponse,
  MaintenanceCallsItemModel, MaintenanceCallsMajorAlertType
} from '../../../services/dashboard/dashboard.service';
import {HttpResponseResult} from '../../../services/rest-api/rest-api.service';
import {ConfigService} from '../../../services/config/config.service';
import {FarmMode} from '../../../services/config/model/server-config';
import {AlertType} from '../../../services/dashboard/model/alert-type.enum';
import {Router} from '@angular/router';
import {ReportsService} from '../../../services/reports/reports.service';
import {FilterChecklistQuery} from '../../../services/reports/model/report-query.interface';
import {TagsProvisioningService} from '../../../services/tags-provisioning/tags-provisioning.service';
import {GoogleAnalyticsService} from '../../../services/google-analytics/google-analytics.service';
import {AuthService} from '../../../services/auth/auth.service';
import {Subscription} from 'rxjs';
import {PollingKeyNames, PollingService} from '../../../services/rest-api/polling.service';
import {SystemStateEnum} from '../../../services/config/model/anonymous-auth-config';
import {NewVersionSoftwareModalComponent} from "./new-version-software-modal/new-version-software-modal.component";
import {UserPermissionsService} from '../../../services/auth/user-permissions.service';
import {MaintenanceCallsItemTextColor} from './maintenance-calls-item/maintenance-calls-item.component';

@Component({
  selector: 'maintenance-calls',
  templateUrl: './maintenance-calls.component.html',
  styleUrls: ['./maintenance-calls.component.scss']
})
export class MaintenanceCallsComponent implements OnInit, OnDestroy {

  public maintenanceCalls: MaintenanceCallsItemModel[] = [];

  public lesserMaintenanceCalls: MaintenanceCallsItemModel[] = [];

  public updateSystemSoftwareMaintenanceCallsItem: MaintenanceCallsItemModel;

  public tagSWUpdateMaintenanceCallsItem: MaintenanceCallsItemModel;

  public isSoftwareModalOpen: boolean = false;

  public isNewVersionSoftwareModalOpen: boolean = false;

  public canClickOnMaintenanceCallsItem: boolean;

  public showDelimiterBetweenMajorAndLesserItems: boolean = false;

  private maintenanceCallsSubscription: Subscription;

  @ViewChild(NewVersionSoftwareModalComponent)
  public newVersionSoftwareDialogContainer: NewVersionSoftwareModalComponent;

  public FarmMode = FarmMode;

  public MaintenanceCallsItemTextColor = MaintenanceCallsItemTextColor;

  public MaintenanceCallsMajorAlertType = MaintenanceCallsMajorAlertType;

  public currentFarmMode: FarmMode;

  constructor(private dashboardService: DashboardService,
              public configService: ConfigService,
              private reportsService: ReportsService,
              private tagsProvisioningService: TagsProvisioningService,
              private router: Router,
              private authService: AuthService,
              private pollingService: PollingService,
              private readonly userPermissionsService: UserPermissionsService,
              private googleAnalyticsService: GoogleAnalyticsService) { }

  public ngOnInit() {
    this.currentFarmMode = this.configService.serverConfig.farmMode;
    this.getMaintenanceCalls();
    this.maintenanceCallsSubscription = this.pollingService.pollingChangesState.subscribe(pollingName => {
      if (pollingName.includes(PollingKeyNames.tagMaintenanceCallsDashboard)) {
        this.getMaintenanceCalls();
      }
    });
    this.canClickOnMaintenanceCallsItem = !this.userPermissionsService.isExternalUser();
  }

  private getMaintenanceCalls() {
    this.dashboardService.getMaintenanceCalls().then((response: HttpResponseResult<IMaintenanceCallsResponse>) => {
      this.maintenanceCalls = response.responseBody.maintenanceCalls.map(maintenanceCallsItemData => new MaintenanceCallsItemModel(
        maintenanceCallsItemData.alertType,
        maintenanceCallsItemData.alertLocalization,
        maintenanceCallsItemData.entityType,
        maintenanceCallsItemData.alertDateTime,
        maintenanceCallsItemData.entityNumber,
        maintenanceCallsItemData.entityId,
        maintenanceCallsItemData.filterValue
      )).sort((left, right) => right.alertDateTime - left.alertDateTime); //from new (first) to old (last)
      this.lesserMaintenanceCalls = this.maintenanceCalls
        .filter(maintenanceCall => maintenanceCall.alertType !== MaintenanceCallsMajorAlertType.Update && maintenanceCall.alertType !== MaintenanceCallsMajorAlertType.TagSwUpdate);
      this.updateSystemSoftwareMaintenanceCallsItem = this.maintenanceCalls.find(maintenanceCallsItem => maintenanceCallsItem.alertType === MaintenanceCallsMajorAlertType.Update);
      this.tagSWUpdateMaintenanceCallsItem = this.maintenanceCalls.find(maintenanceCallsItem => maintenanceCallsItem.alertType === MaintenanceCallsMajorAlertType.TagSwUpdate);
      this.showDelimiterBetweenMajorAndLesserItems = this.lesserMaintenanceCalls.length > 0 && this.maintenanceCalls.length > this.lesserMaintenanceCalls.length;
    });
  }

  public ngOnDestroy(): void {
    this.maintenanceCallsSubscription?.unsubscribe();
  }

  public getIconCssClassByAlertType(alertType: string): string {
    if (alertType == MaintenanceCallsMajorAlertType.Update) {
      return 'update';
    }
    if (alertType == MaintenanceCallsMajorAlertType.TagSwUpdate) {
      return 'tagSwUpdate';
    }
    return '';
  }

  public createShId(item: MaintenanceCallsItemModel) {
    if(item.alertType != null &&
      item.alertType !== 'Maintenance') {
      return item.alertType;
    } else if(item.filterValue != null) {
      return item.filterValue;
    }
  }

  public async onMaintenanceCallsItemClick(item: MaintenanceCallsItemModel, event:Event) {
    if (this.canClickOnMaintenanceCallsItem) {
      if (item.alertType !== AlertType.Update && item.alertType !== AlertType.TagSwUpdate) {
        this.reportsService.onLoadDefaultReportQuery = this.reportsService.defaultReportQuery;
        let maintenanceCallsChecklistQuery = new FilterChecklistQuery();
        maintenanceCallsChecklistQuery.selectedItems.add(item.filterValue);
        this.reportsService.onLoadDefaultReportQuery.filter.set('MaintenanceCallsIssueIDCalculation', maintenanceCallsChecklistQuery);
        this.googleAnalyticsService.send("dashboard", "maintenance", "Click - Tags to Assign");
        await this.router.navigate(['reports/6']);
      } else if (item.alertType === AlertType.TagSwUpdate) {
        event.stopPropagation();
        event.preventDefault();
        this.tagsProvisioningService.openTagSwUpdate.next();
      } else if (item.alertType === AlertType.Update) {
        event.stopPropagation();
        event.preventDefault();
        this.isSoftwareModalOpen = true;
      }
    }
  }

  public onCloseUpdateSoftwareModal() {
    this.isSoftwareModalOpen = false;
  }

  public async onApplyUpdateSoftware() {
    this.isSoftwareModalOpen = false;
    await this.dashboardService.farmUpgrade();
    this.newVersionSoftwareDialogContainer.showDialog();
    setTimeout(() => {
      this.newVersionSoftwareDialogContainer.closeDialog();
      this.logout();
    }, 3000);
  }

  public async logout() {
    this.authService.performLogout();
    if (!this.configService.configuration.cloud && this.configService.anonymousAuthResult.systemState === SystemStateEnum.trial) {
      await this.router.navigate(['/registration/initialize-registration']);
    } else if (this.configService.configuration.cloud) {
      window.location.href = '/';
    } else {
      await this.router.navigate(['/login']);
    }
  }
}
