<div class="led-tasks-editor-wrapper" saveDataEditSection>
  <task-editor-edit-bar [isMadeChanges]="isMadeChanges" (onSavePressed)="save()" (onRevertPressed)="backToTasks($event)"></task-editor-edit-bar>
  <div class="led-tasks-body">
    <div class="errors-div" [attr.sh-id]="'error-div'" *ngIf="ledTasksService.Error">
      <div class="error-text" [attr.translation-id]="'SYSTEM.MANAGEMENT.ERROR_BOLD'"><b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}:</b>
        <span class="error-message">
          {{ 'LED_TASKS.TASK_EDITOR.ERROR.' + ledTasksService.Error.key | translate }}
        </span>
      </div>
    </div>
    <led-tasks-editor-container></led-tasks-editor-container>
  </div>
</div>
