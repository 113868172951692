import { Injectable } from '@angular/core';
import {HttpResponseResult, RestApiService} from '../rest-api/rest-api.service';
import {AuthService, LoginResult} from './auth.service';
import {ConfigService} from '../config/config.service';
import {HttpParams} from '@angular/common/http';
import {KeyValuePair} from '../model/key-value-pair';

export class UserSecurityQuestionsModel {

  public question1 : KeyValuePair<string, string>;

  public question2 : KeyValuePair<string, string>;

  public username: string;
}

export class UserTempCredentialsModel {

  public farmId: string;

  public tempPassword: string;

  public username: string;

}

export class UserResetPasswordModel {

  public username: string;

  public newPassword: string;

  public confirmPassword: string;

}

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  public static readonly securityQuestionsTranslations: KeyValuePair<string,string>[] =
    [{
      key: '1',
      value: 'COMMON.SECURITY_QUESTIONS.WHAT_IS_YOUR_FAVORITE_MOVIE'
    },
    {
      key: '2',
      value: 'COMMON.SECURITY_QUESTIONS.WHAT_IS_YOUR_MOTHERS_MAIDEN_NAME'
    },
    {
      key: '3',
      value: 'COMMON.SECURITY_QUESTIONS.WHAT_IS_THE_NAME_OF_YOUR_FIRST_GRADE_TEACHER'
    },
    {
      key: '4',
      value: 'COMMON.SECURITY_QUESTIONS.WHAT_IS_YOUR_FAVORITE_COLOR'
    },
    {
      key: '5',
      value: 'COMMON.SECURITY_QUESTIONS.WHAT_IS_THE_NAME_OF_YOUR_FIRST_PET'
    }];

  private static readonly ForgotPasswordRoute: string = '/rest/api/auth/forgotPassword';

  private static readonly ResetPasswordRoute: string = '/rest/api/auth/resetPassword';

  constructor(private restApiService: RestApiService,
              private authService: AuthService,
              public configService: ConfigService) { }

  public getUserSecurityQuestionsDetails(userName: string): Promise<HttpResponseResult<UserSecurityQuestionsModel>> {

    let queryParams:HttpParams = new HttpParams().set('username', userName);

    return this.restApiService.sendGetMethod<UserSecurityQuestionsModel>(ForgotPasswordService.ForgotPasswordRoute,
                                                                             this.authService.anonymousAuthKnownFarmDetails,
                                                                             queryParams);
  }

  public validateUserSecurityQuestionsDetails(userSecurityQuestions:UserSecurityQuestionsModel) : Promise<HttpResponseResult<UserTempCredentialsModel>> {

    return this.restApiService.sendPutMethod(ForgotPasswordService.ForgotPasswordRoute,
                                             this.authService.anonymousAuthKnownFarmDetails,
                                             userSecurityQuestions);

  }

  // tslint:disable-next-line:no-any
  public resetPassword(userResetPassword: UserResetPasswordModel) : Promise<HttpResponseResult<any>> {

    return this.restApiService.sendPutMethod(ForgotPasswordService.ResetPasswordRoute,
                                             this.authService.anonymousAuthKnownFarmDetails,
                                             userResetPassword);

  }
}
