<notification-dialog-template #dialogRef
                              [isEscapeCloseDialog]="true"
                              [modalWidth]="700"
                              [modalHeight]="250"
                              [theme]="NotificationDialogTheme.blue"
                              [shIdPostfix]="'startedTrialDialogComponent'">
  <ng-container header>
      <span class="attention-icon"></span>
      <span>{{ 'DIALOGS.TRIAL.YOU_ARE_IN_TRIAL_MODE' | translate }}!</span>
  </ng-container>
  <ng-container body>
    <div class="content-days-left" [attr.translation-id]="'DIALOGS.TRIAL.DAYS_LEFT'">
      {{ trialDays }} {{ 'DIALOGS.TRIAL.DAYS_LEFT' | translate }}
    </div>
    <div class="content-text" [attr.translation-id]="'DIALOGS.TRIAL.CALL_YOUR_LOCAL_DEALER_FOR_ADDITIONAL_INFORMATION'">
      {{ 'DIALOGS.TRIAL.CALL_YOUR_LOCAL_DEALER_FOR_ADDITIONAL_INFORMATION' | translate }}
    </div>
  </ng-container>
</notification-dialog-template>
