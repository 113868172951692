<div *ngIf="isOpen" class="reports-menu" outsideClickHandler [outsideClickIgnoreListComponents]="['menuItemReports']" (onOutsideClick)="onReportItemClick(null)" @reportsMenuTransition saveDataWarningIgnore>
  <div class="report-menu-header"><span class="report-menu-title" [attr.translation-id]="'SIDE_BAR.REPORTS.YOUR_FAVOURITES'">{{ 'SIDE_BAR.REPORTS.BROWSE_REPORT' | translate }}</span>
    <a class="close-report-menu" (click)="onReportItemClick(null)">
      <div class="icon-close"></div>
    </a>
    <div class="shadow"></div>
  </div>
  <div class="report-menu-section favourite-reports" sh-id="favourite-reports">
    <ul class="navs">
      <li class="report-menu-section-title">
        <div class="icon-favorites"></div><span class="report-menu-category-title" [attr.translation-id]="'SIDE_BAR.REPORTS.YOUR_FAVOURITES'">{{ 'SIDE_BAR.REPORTS.YOUR_FAVOURITES' | translate }}</span>
      </li>
      <li class="nav-item" *ngFor="let favorite of favorites" [attr.sh-id]="'favorite-report-menu-item-' + favorite.name">
        <div class="report-link-wrapper" [routerLink]="['/reports/', favorite.key]" (click)="onReportItemClick(favorite.name)" saveDataWarningInject><a class="nav-link"><span [attr.translation-id]="'SIDE_BAR.REPORTS.' + favorite.name">{{"SIDE_BAR.REPORTS." + favorite.name | translate}}</span></a>
          <div class="icon-placeholder" [ngClass]="{'is-favourite' : favorite.isFavorite}"></div><span class="row-count">{{favorite.rowCount}}</span></div>
      </li>
    </ul>
  </div>
  <div class="report-list">
    <div class="report-list-scroll-wrapper">
      <div class="report-menu-section" *ngFor="let category of categoryKeys">
        <ul class="navs">
          <li class="report-menu-section-title">
            <div class="report-menu-category-title-wrapper">
              <div class="icon-and-title-category-wrapper">
                <div class="icon report-menu-section-title-icon" [ngClass]="'icon-' + category"></div><span class="report-menu-category-title" [attr.translation-id]="'SIDE_BAR.REPORTS.' + category">{{ "SIDE_BAR.REPORTS." + category | uppercase | translate }}</span>
              </div>
              <div class="premium-trial-remaining-days" *ngIf="isRemainingDaysShownForPremiumTrial(category)">({{ premiumTrialRemainingDays }} {{ 'DIALOGS.TRIAL.DAYS_LEFT' | translate }})</div>
            </div>
          </li>
          <li class="nav-item" *ngFor="let report of categories.get(category)">
            <div class="icon-favourite" (click)="toggleFavorite(report)" [ngClass]="{'is-favourite': report.isFavorite}"></div>
            <div class="report-link-wrapper" [routerLink]="['/reports/', report.key]"  [attr.sh-id]="'report-menu-item-' + report.name" (click)="onReportItemClick(report.name)" saveDataWarningInject><a class="nav-link"><span [attr.translation-id]="'SIDE_BAR.REPORTS.' + report.name">{{"SIDE_BAR.REPORTS." + report.name | translate}}</span></a>
              <div class="icon-placeholder"></div><span class="row-count" [attr.sh-id]="'report-menu-item-row-count-' + report.name">{{report.rowCount}}</span></div>
          </li>
        </ul>
      </div>
      <div class="report-menu-section">
        <ul class="navs">
          <li class="report-menu-section-title report-menu-section-teaser" *ngIf="displayYoungStockTeaser">
            <div class="icon-youngstock-green"></div><span class="report-menu-category-title">{{"SIDE_BAR.REPORTS.YOUNGSTOCK"| translate}}</span></li>
          <li class="nav-item nav-item-teaser" *ngIf="displayYoungStockTeaser">
            <div class="report-link-wrapper"></div>
            <div class="teaser-content"><span [attr.translation-id]="'SIDE_BAR.REPORTS.TEASER.YOUNGSTOCK'">{{"SIDE_BAR.REPORTS.TEASER.YOUNGSTOCK" | translate}}</span></div>
            <div class="teaser-content">
              <div class="teaser-learn-more" (click)="learnMore(LearnMorePlanType.YoungStock)" nonExternalUser>
                <div class="icon-link-arrow"></div><span [attr.translation-id]="'SIDE_BAR.REPORTS.TEASER.LEARN_MORE'">{{"SIDE_BAR.REPORTS.TEASER.LEARN_MORE" | translate}}</span></div>
            </div>
          </li>
          <li class="report-menu-section-title report-menu-section-teaser" *ngIf="displayHealthTeaser">
            <div class="icon-health-green"></div><span class="report-menu-category-title" [attr.translation-id]="'SIDE_BAR.REPORTS.Health'">{{"SIDE_BAR.REPORTS.Health"| translate}}</span></li>
          <li class="nav-item nav-item-teaser" *ngIf="displayHealthTeaser">
            <div class="report-link-wrapper"></div>
            <div class="teaser-content"><span [attr.translation-id]="'SIDE_BAR.REPORTS.TEASER.HEALTH'">{{"SIDE_BAR.REPORTS.TEASER.HEALTH" | translate}}</span></div>
            <div class="teaser-content">
              <div class="teaser-learn-more" *ngIf="isStartFreeTrialButtonShown()" nonExternalUser>
                <button class="start-free-trial-button" (click)="openStartFreeTrialURL()" [attr.translation-id]="'SIDE_BAR.REPORTS.TEASER.START_FREE_TRIAL'">{{ 'SIDE_BAR.REPORTS.TEASER.START_FREE_TRIAL' | translate }}</button>
                <!--<div class="icon-link-arrow"></div><span translation-id="SIDE_BAR.REPORTS.TEASER.LEARN_MORE">{{"SIDE_BAR.REPORTS.TEASER.LEARN_MORE" | translate}}</span></div>-->
              </div>
            </div>
          </li>
          <li class="report-menu-section-title report-menu-section-teaser" *ngIf="displayGroupTeaser">
            <div class="icon-group-green"></div><span class="report-menu-category-title">{{"SIDE_BAR.REPORTS.GROUP"| translate}}</span></li>
          <li class="nav-item nav-item-teaser" *ngIf="displayGroupTeaser">
            <div class="report-link-wrapper"></div>
            <div class="teaser-content"><span [attr.translation-id]="'SIDE_BAR.REPORTS.TEASER.GROUP'">{{"SIDE_BAR.REPORTS.TEASER.GROUP" | translate}}</span></div>
            <div class="teaser-content">
              <div class="teaser-learn-more" *ngIf="isStartFreeTrialButtonShown()" nonExternalUser>
                <button class="start-free-trial-button" (click)="openStartFreeTrialURL()" [attr.translation-id]="'SIDE_BAR.REPORTS.TEASER.START_FREE_TRIAL'">{{ 'SIDE_BAR.REPORTS.TEASER.START_FREE_TRIAL' | translate }}</button>
                <!--<div class="icon-link-arrow"></div><span translation-id="SIDE_BAR.REPORTS.TEASER.LEARN_MORE">{{"SIDE_BAR.REPORTS.TEASER.LEARN_MORE" | translate}}</span></div>-->
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
