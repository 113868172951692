<div class="filter-content">
  <div class="form-group">
    <regular-dropdown-list class="value"
              [items]="operatorsDropdownItems"
              [placeholder]="'COMMON.PLACE_HOLDER.AUTO_COMPLETE.SELECT_ITEM'"
              (onItemSelected)="onOperatorSelected($event)">
    </regular-dropdown-list>
  </div>
  <div class="form-group value-input">
    <input class="value"
           [(ngModel)]="filterNumericQuery.compValue1"
           [disabled]="!filterNumericQuery.operator"
           type="number"
           [placeholder]="(filterNumericQuery.isMultiValue ? 'COMMON.PLACE_HOLDER.AUTO_COMPLETE.FROM_MULTI' : 'COMMON.PLACE_HOLDER.AUTO_COMPLETE.FROM_SINGLE') | translate"/>
  </div>
  <div class="form-group" *ngIf="filterNumericQuery.isMultiValue">
    <input class="value"
           [(ngModel)]="filterNumericQuery.compValue2"
           type="number"
           [placeholder]="'COMMON.PLACE_HOLDER.AUTO_COMPLETE.TO_MULTI' | translate" />
  </div>
</div>
