import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IErrors, AddAnimalFieldsErrorsEnum, IGroupItem} from 'src/app/services/animals/model/animal.model';
import {AnimalsService} from '../../../../../../../services/animals/animals.service';
import {
  DropdownListAutoSelectionMode,
  DropdownListItem
} from '../../../../../../../common/components/dropdown-list/dropdown-list-model';
import {EpochStateModel} from '../../../../../../../common/components/calendar/model/epoch-state-model';
import {AddFemaleModel} from '../add-female-breeding.component';
import {FormsDLIconsThemes} from '../../../../../../../common/components/dropdown-list/forms-dropdown-list/forms-dropdown-list.component';

@Component({
  selector: 'add-female-breeding-preg-check-tab',
  templateUrl: './add-female-breeding-preg-check-tab.component.html',
  styleUrls: ['./add-female-breeding-preg-check-tab.component.scss']
})
export class AddFemaleBreedingPregCheckTabComponent implements OnInit {

  public dropdownListAutoSelectionMode = DropdownListAutoSelectionMode;

  @Input()
  public addFemaleModel: AddFemaleModel;

  @Input()
  public groupsDropdownListItems: DropdownListItem[] = [];

  @Input()
  public errors: IErrors[] = [];

  @Output()
  public onGroupSelected : EventEmitter<IGroupItem> = new EventEmitter<IGroupItem>();

  @Output()
  public onErrorsClear: EventEmitter<void> = new EventEmitter<void>();

  public AddAnimalFieldsErrorsEnum = AddAnimalFieldsErrorsEnum;

  public formsDLIconsThemes = FormsDLIconsThemes;

  constructor(public readonly animalsService: AnimalsService) { }

  public ngOnInit() {
  }

  public onPregCheckDateChanged(event: EpochStateModel) {
    this.addFemaleModel.pregnancyCheckDate = event;
    this.addFemaleModel.updateDatesRanges();
    this.onErrorsClear.emit();
  }
}
