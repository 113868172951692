import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  GroupRoutineEventModifyModel,
  GroupRoutineEventsMetadata,
  GroupRoutineEventType
} from '../../../../../../services/manage/model/group-routine-events.model';
import {ManageSettingsService} from '../../../../../../services/manage/manage-settings.service';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../../../../common/components/calendar/model/epoch-state-model';
import {AutoCompleteColorScheme} from 'src/app/common/components/auto-complete/auto-complete-model';
import {ErrorModel, Group} from '../../../../../../services/model/common-model';
import {ChipsItem} from '../../../../../../common/components/chips/chips-item.component';
import {TranslationService} from '../../../../../../services/translations/translation.service';
import {DropdownColorScheme} from 'src/app/common/components/dropdown-list/regular-dropdown-list/regular-dropdown-list.component';
import {DropdownListItem, DropdownListLeftIcon} from '../../../../../../common/components/dropdown-list/dropdown-list-model';
import {RegularAutoCompleteComponent} from '../../../../../../common/components/auto-complete/regular-auto-complete/regular-auto-complete.component';
import {SuccessDialogService} from '../../../../../../common/components/dialogs/success-dialog/success-dialog.service';
import {LoadingIconService} from '../../../../../../services/loading-icon/loading-icon.service';
import * as _ from 'lodash';
import {SaveDataViewStateService} from '../../../../../../services/ui/view-state/save-data-view-state.service';

export enum GroupRoutineEventField {
  eventType = 'eventType',
  startTime = 'startTime',
  groupIds = 'groupIds'
}

@Component({
  selector: 'edit-gre',
  templateUrl: './edit-gre.component.html',
  styleUrls: ['./edit-gre.component.scss']
})
export class EditGreComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public groupRoutineEventModifyModel : GroupRoutineEventModifyModel;

  @Input()
  public groupRoutineEventId : number;

  @Output()
  public onSaveCompleted = new EventEmitter<void>();

  @Output()
  public onClose = new EventEmitter<void>();

  @ViewChild('effectiveGroupsAutoComplete')
  public effectiveGroupsAutoComplete: RegularAutoCompleteComponent;

  public metadata : GroupRoutineEventsMetadata;

  public selectedTime : EpochStateModel = new EpochStateModel(EpochStateModelMinMaxMode.Time);

  public groupSearchQuery: string = '';

  public AutoCompleteColorScheme = AutoCompleteColorScheme;

  public GroupRoutineEventField = GroupRoutineEventField;

  public DropdownColorScheme = DropdownColorScheme;

  public availableEventsDropdownListItems : DropdownListItem<GroupRoutineEventType>[] = [];

  public selectedEventDropdownListItem : DropdownListItem<GroupRoutineEventType>;

  public Error: ErrorModel = null;

  public selectedGroupsChipsItems : ChipsItem[] = [];

  public readonly editAreaName: string = 'group-routine-events';

  private groupRoutineEventModifyModelInitGauge : GroupRoutineEventModifyModel;

  constructor(private element: ElementRef,
              private readonly manageSettingsService:ManageSettingsService,
              private readonly translationService:TranslationService,
              private readonly loadingIconService:LoadingIconService,
              private readonly successDialogService:SuccessDialogService,
              private readonly saveDataViewStateService: SaveDataViewStateService) { }

  public async ngOnInit() {
    document.body.appendChild(this.element.nativeElement);
    this.metadata = (await this.manageSettingsService.getGroupRoutineEventsMetadata()).responseBody;
    this.availableEventsDropdownListItems = this.metadata.eventTypes.map<DropdownListItem<GroupRoutineEventType>>(value => {
      return {
        disabled: false,
        value: value,
        icon: <DropdownListLeftIcon><string>value,
        displayValue: this.translationService.translate('MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.EVENT_TYPE.' + value)
      };
    });
    this.selectedEventDropdownListItem = this.availableEventsDropdownListItems.find(value => value.value == this.groupRoutineEventModifyModel.eventType);
    this.updateSelectedGroupsChipsItems();
    this.groupRoutineEventModifyModelInitGauge = _.cloneDeep(this.groupRoutineEventModifyModel);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.groupRoutineEventModifyModel != null &&
       changes.groupRoutineEventModifyModel.previousValue != changes.groupRoutineEventModifyModel.currentValue) {
      this.selectedTime.epochTime = this.groupRoutineEventModifyModel.startTime;
    }
  }

  public ngOnDestroy() {
    if (this.element.nativeElement && this.element.nativeElement.parentNode == document.body) {
      document.body.removeChild(this.element.nativeElement);
      this.saveDataViewStateService.activeEditArea = null;
    }
  }

  private get selectedGroups() : Group[] {
    return this.groupRoutineEventModifyModel.groupIds.map(value => {
      return this.metadata.groups.find(group =>  group.id == value);
    });
  }

  private updateSelectedGroupsChipsItems() {
    this.selectedGroupsChipsItems = this.selectedGroups.map(group => {
      return {
        chipName: group.name,
        chipId: group.id
      }
    });
  }

  public get availableGroupsList() : string[] {
    return this.metadata.groups.filter(value => this.groupRoutineEventModifyModel.groupIds.find(groupid => groupid == value.id) == null)
                               .map(value => value.name);
  }

  public get groupInputPlaceholder() : string {
    if (this.groupRoutineEventModifyModel.groupIds.length === 0) {
      return this.translationService.translate('LED_TASKS.TASK_EDITOR.ALL_GROUPS');
    } else {
      return this.translationService.translate('LED_TASKS.TASK_EDITOR.SELECT_GROUP');
    }
  }

  public async onSaveClick() {
    if(this.isGroupRoutineEventValid()) {
      this.loadingIconService.show();
      this.groupRoutineEventModifyModel.startTime = this.selectedTime.epochTime;
      let response;
      if (this.groupRoutineEventId == null) {
        response = await this.manageSettingsService.createGroupRoutineEvent(this.groupRoutineEventModifyModel);
      } else {
        response = await this.manageSettingsService.editGroupRoutineEvent(this.groupRoutineEventModifyModel, this.groupRoutineEventId);
      }
      this.loadingIconService.hide();
      if (response.status == 201 ||
          response.status == 200) {
        this.successDialogService.show();
        this.onSaveCompleted.emit();
        this.groupRoutineEventModifyModelInitGauge = _.cloneDeep(this.groupRoutineEventModifyModel);
      } else if (response.status == 400) {
        if (response.status === 400) {
          this.Error = new ErrorModel();
          this.Error.key = response.errorResponseBody.result.key;
          this.Error.fieldName = response.errorResponseBody.result.fieldName;
        }
      }
    }
  }

  public onCloseClick() {
    this.onClose.emit();
  }

  public onEventTypeSelected(dropdownListItem : DropdownListItem<GroupRoutineEventType>) {
    if(dropdownListItem == null) {
      this.groupRoutineEventModifyModel.eventType = null;
    } else {
      this.groupRoutineEventModifyModel.eventType = dropdownListItem.value;
    }
    this.clearErrors();
    this.dirtyCheck();
  }

  public onGroupNameSelected(groupName: string) {
    let group = this.metadata.groups.find(group => group.name == groupName);
    if(group != null) {
      this.groupRoutineEventModifyModel.groupIds.push(group.id);
      this.updateSelectedGroupsChipsItems();
      this.effectiveGroupsAutoComplete.clearValue();
      this.effectiveGroupsAutoComplete.focusOnAutocomplete();
      this.clearErrors();
    }
    this.dirtyCheck();
  }

  public onEpochChanged(): void {
    this.clearErrors();
    this.dirtyCheck();
  }

  public removeSelectedGroup(removeGroupChipsItem : ChipsItem) {
    let groupIdToRemove = this.groupRoutineEventModifyModel.groupIds.indexOf(removeGroupChipsItem.chipId);
    this.groupRoutineEventModifyModel.groupIds.splice(groupIdToRemove, 1);
    this.updateSelectedGroupsChipsItems();
    this.dirtyCheck();
  }

  public clearErrors() {
    this.Error = null;
  }

  public isFieldError(field : GroupRoutineEventField) {
    return this.Error && this.Error.fieldName === field;
  }

  private isGroupRoutineEventValid() : boolean {
    if(this.groupRoutineEventModifyModel.eventType == null) {
      this.Error = {key: 'MissingEventType', fieldName: GroupRoutineEventField.eventType};
      return false;
    }
    if(this.selectedTime.epochTime == null) {
      this.Error = {key: 'MissingStartTime', fieldName: GroupRoutineEventField.startTime};
      return false;
    }
    return true;
  }

  private dirtyCheck(): void {
    if (_.isEqual(this.groupRoutineEventModifyModel, this.groupRoutineEventModifyModelInitGauge)) {
      this.saveDataViewStateService.activeEditArea = null;
    } else {
      this.saveDataViewStateService.activeEditArea = this.editAreaName;
    }
  }
}
