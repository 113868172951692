import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FarmMode} from '../../../services/config/model/server-config';
import {ConfigService} from '../../../services/config/config.service';
import {ManageSettingsService} from '../../../services/manage/manage-settings.service';
import {Subscription} from 'rxjs';
import {UserPermissionsService} from '../../../services/auth/user-permissions.service';
import {ApplicationBundleRoutingMode, RoutingService} from '../../../services/routing/routing.service';
import {ManageSettingsScreenType} from './model/manage-settings.model';

@Component({
  selector: 'manage-settings',
  templateUrl: './manage-settings.component.html',
  styleUrls: ['./manage-settings.component.scss']
})
export class ManageSettingsComponent implements OnInit, OnDestroy {

  public currentFarmMode: FarmMode;

  public currentSettingsScreenType: ManageSettingsScreenType = ManageSettingsScreenType.welcome;

  public applicationBundleRoutingMode: ApplicationBundleRoutingMode;

  private queryParamsSubscription: Subscription;

  @Output()
  public onCloseSettingsDialog: EventEmitter<void> = new EventEmitter<void>();

  public FarmMode = FarmMode;

  public ManageSettingsScreenType = ManageSettingsScreenType;

  constructor(private readonly router: Router,
              private readonly route: ActivatedRoute,
              public readonly configService: ConfigService,
              private readonly routingService: RoutingService,
              private readonly manageSettingsService: ManageSettingsService,
              private readonly userPermissionsService:UserPermissionsService) {
  }

  public ngOnInit() {
    this.currentFarmMode = this.configService.serverConfig.farmMode;
    this.queryParamsSubscription = this.route.queryParams.subscribe(queryParams => {
      if (this.configService.serverConfig.farmMode === FarmMode.Dairy) {
        this.applicationBundleRoutingMode = null;
      } else {
        this.applicationBundleRoutingMode = this.routingService.getApplicationBundleRoutingMode(queryParams);
      }
    });
  }

  public ngOnDestroy(): void {
    this.queryParamsSubscription.unsubscribe();
  }

  public openScreen(screenType: ManageSettingsScreenType) {
    this.currentSettingsScreenType = screenType;
  }

  public get hasPermissionsToNotifications() : boolean {
    return this.userPermissionsService.hasManageNotificationsPermissions();
  }

  public get hasPermissionsToHealth() : boolean {
    return this.userPermissionsService.isApplicationPlanSupportsViewForHealth;
  }

  public get isFinishing() {
    return this.applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing;
  }
}

