<description-cell-details-template *ngIf="row.AnimalsToInspectReasonsCalculation"
                                   [hasRowDetails]="hasRowDetails"
                                   [isRowExpanded]="isExpanded"
                                   [row]="row"
                                   [rowShId]="row.rowId"
                                   (toggleExpanded)="toggleExpanded()">
  <ng-container key>{{("REPORTS.GRID." + row.AnimalsToInspectReasonsCalculation?.mostImportantReport) | translate}}</ng-container>
  <ng-container value></ng-container>
  <ng-container content>
    <p class="description-item" *ngFor="let otherReason of row.AnimalsToInspectReasonsCalculation?.restOfReports">{{("REPORTS.GRID." + otherReason) | translate}}</p>
  </ng-container>
</description-cell-details-template>
