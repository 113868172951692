import {Component, ElementRef, OnInit} from '@angular/core';
import {AnimalsService} from '../../../../../../services/animals/animals.service';
import {DropdownListItem} from '../../../../../../common/components/dropdown-list/dropdown-list-model';
import * as moment from 'moment';
import {
  AddAnimalFieldsErrorsEnum,
  IAddFemaleFinishingRequest, IErrors,
  IFemalesMetadata,
  IGroupItem,
  ISCRTagItem,
} from '../../../../../../services/animals/model/animal.model';
import {DateTimeFormatEnum} from '../../../../../../common/pipes/epoch-date-time.pipe';
import {UtilsService, WeightUnitType} from '../../../../../../services/utils/utils.service';
import {InputFilterMode} from '../../../../../../common/directives/input-filter.directive';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../../../../common/components/calendar/model/epoch-state-model';
import {LoadingIconService} from "../../../../../../services/loading-icon/loading-icon.service";
import { CornerColor, CornerContainerBodyBackground } from 'src/app/common/components/containers/corner-container/corner-container.component';

export class FinishingAddFemaleModel {

  public animalNumber: string;

  public group: DropdownListItem;

  public registrationNumber: string;

  // tslint:disable-next-line:no-any
  public EID: any;

  // tslint:disable-next-line:no-any
  public tagNumber: any;

  public entryDate: EpochStateModel;

  public birthDate: EpochStateModel;

  public weighing: EpochStateModel;

  public weightValueKG: string;

  public weightValueLBS: string;

  public backgroundStartDate: EpochStateModel;

  public finishingStart: EpochStateModel;

  constructor() {
    this.animalNumber = null;
    this.group = null;
    this.registrationNumber = null;
    this.EID = null;
    this.tagNumber = null;
    this.entryDate = new EpochStateModel(EpochStateModelMinMaxMode.Date, moment().unix());
    this.birthDate = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.weighing = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.weighing.epochTime = moment().unix();
    this.weightValueKG = null;
    this.weightValueLBS = null;
    this.backgroundStartDate = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.backgroundStartDate.epochTime = moment().unix();
    this.finishingStart = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.finishingStart.epochTime = moment().unix();
    this.updateDatesRanges();
  }

  public clear() {
    this.animalNumber = null;
    this.group = null;
    this.registrationNumber = null;
    this.EID = null;
    this.tagNumber = null;
    this.entryDate = new EpochStateModel(EpochStateModelMinMaxMode.Date, moment().unix());
    this.birthDate = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.weighing = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.weighing.epochTime = moment().unix();
    this.weightValueKG = null;
    this.weightValueLBS = null;
    this.backgroundStartDate = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.backgroundStartDate.epochTime = moment().unix();
    this.finishingStart = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.finishingStart.epochTime = moment().unix();
    this.updateDatesRanges();
  }

  public updateDatesRanges() {
    let maxDate = moment().unix();

    this.birthDate.max = maxDate;

    this.entryDate.min = this.birthDate.epochDate;
    this.entryDate.max = maxDate;
    this.entryDate = this.entryDate.clone();

    this.weighing.min = this.birthDate.epochDate;
    this.weighing.max = maxDate;
    this.weighing = this.weighing.clone();

    this.backgroundStartDate.min = this.birthDate.epochDate;
    this.backgroundStartDate.max = this.finishingStart.epoch ? this.finishingStart.epoch : maxDate;
    this.backgroundStartDate = this.backgroundStartDate.clone();

    this.finishingStart.min = this.getMinDateForStartFinishing();
    this.finishingStart.max = maxDate;
    this.finishingStart = this.finishingStart.clone();
  }

  private getMinDateForStartFinishing() : number {
    if (this.backgroundStartDate.epoch) {
      return this.backgroundStartDate.epoch;
    } else if (this.birthDate.epochDate) {
      return this.birthDate.epochDate;
    } else {
      return null;
    }
  }
}

export enum AddFemaleFinishingSteps {
  Entry = 1,
  Farm = 2,
  Finishing = 3
}

@Component({
  selector: 'add-female-finishing',
  templateUrl: './add-female-finishing.component.html',
  styleUrls: ['./add-female-finishing.component.scss']
})
export class AddFemaleFinishingComponent implements OnInit {

  public errors: IErrors[] = [];

  public addFemaleFinishingMetadata: IFemalesMetadata;

  public finishingStep: AddFemaleFinishingSteps = AddFemaleFinishingSteps.Entry;

  public addFinishingFemaleModel: FinishingAddFemaleModel = new FinishingAddFemaleModel();

  public groupsDropdownListItems: DropdownListItem[] = [];

  public rfidsTagsNumbers: string[] = [];

  public scrTagsNumbers: string[] = [];

  public AddFemaleFinishingSteps = AddFemaleFinishingSteps;

  public dateTimeFormatEnum = DateTimeFormatEnum;

  public InputFilterMode = InputFilterMode;

  public isLoading: boolean;

  public AddAnimalFieldsErrorsEnum = AddAnimalFieldsErrorsEnum;

  public CornerColor = CornerColor;

  public CornerContainerBodyBackground = CornerContainerBodyBackground;

  constructor(public elementRef: ElementRef,
              public readonly animalsService: AnimalsService,
              private readonly loadingIconService: LoadingIconService,
              private utilsService: UtilsService) { }

  public async ngOnInit() {
    let response = await this.animalsService.getFinishingFemalesMetadata();
    this.addFemaleFinishingMetadata = response.responseBody;
    if (this.addFemaleFinishingMetadata) {
      if(this.addFemaleFinishingMetadata.groupList != null) {
        this.groupsDropdownListItems = this.addFemaleFinishingMetadata.groupList.map((groupItem: IGroupItem) => ({
          value: groupItem,
          displayValue: groupItem.name,
          disabled: false,
          icon: null
        }));
      }
      if(this.addFemaleFinishingMetadata.rfidTagList != null) {
        this.rfidsTagsNumbers = this.addFemaleFinishingMetadata.rfidTagList.map((rfIdItem: ISCRTagItem) => (rfIdItem.tagNumber));
      }
      if(this.addFemaleFinishingMetadata.scrTagList != null) {
        this.scrTagsNumbers = this.addFemaleFinishingMetadata.scrTagList.map((tagItem: ISCRTagItem) => (tagItem.tagNumber));
      }
    }
  }

  private clearErrors() {
    this.errors = [];
  }

  public isCurrentSummaryDisplayed(step: AddFemaleFinishingSteps) {
    return this.finishingStep > step;
  }

  private validateEntryStep(): boolean {
    if (!this.addFinishingFemaleModel.animalNumber) {
      this.errors[0] = { key: 'ANIMAL_ID_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.animalName };
      return false;
    }
    if (!this.addFinishingFemaleModel.group) {
      this.errors[0] = { key: 'GROUP_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.group };
      return false;
    }
    return true;
  }

  private validateFarmStep(): boolean {
    if (!this.addFinishingFemaleModel.group) {
      this.errors[0] = { key: 'GROUP_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.group };
      return false;
    }
    if (!this.addFinishingFemaleModel.birthDate.epochDate && !this.addFinishingFemaleModel.entryDate.epochDate) {
      if (!this.addFinishingFemaleModel.birthDate.epochDate) {
        this.errors[0] = {key: 'HerdEntryAndBirthDateAreMissing', fieldName: AddAnimalFieldsErrorsEnum.entryDate };
        return false;
      }
    }
    return true;
  }

  private validateFinishingStep(): boolean {
    if (!this.addFinishingFemaleModel.group) {
      this.errors[0] = { key: 'GROUP_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.group };
      return false;
    }
    return true;
  }

  private goToStep(step: AddFemaleFinishingSteps) {
    switch (step) {
      case AddFemaleFinishingSteps.Farm: {
        if (this.validateEntryStep()) {
          this.performNavigationToStep(step);
        }
        break;
      }
      case AddFemaleFinishingSteps.Finishing: {
        if (this.validateFarmStep()) {
          this.performNavigationToStep(step);
        }
        break;
      }
    }
  }

  private performNavigationToStep(step: AddFemaleFinishingSteps) {
    this.finishingStep = step;
  }

  private convertToNumberIfNotNull(number: string): number | null {
    if (number == null) {
      return null;
    } else {
      return Number(number);
    }
  }

  private async addFinishingFemale() {
    if (this.validateEntryStep() &&
        this.validateFarmStep() &&
        this.validateFinishingStep()) {
      this.fillWeightValues();
      const addFinishingFemaleRequest: IAddFemaleFinishingRequest = {
        animalName: this.addFinishingFemaleModel.animalNumber,
        group: this.addFinishingFemaleModel.group.value,
        orn: this.addFinishingFemaleModel.registrationNumber,
        birthDate: this.addFinishingFemaleModel.birthDate.epochDate,
        herdEntryDateTime: this.addFinishingFemaleModel.entryDate.epochDate,
        rfidTag: this.addFinishingFemaleModel.EID,
        scrTag: this.addFinishingFemaleModel.tagNumber,
        startBackgroundDate: this.addFinishingFemaleModel.backgroundStartDate.epoch,
        startFinishingDate: this.addFinishingFemaleModel.finishingStart.epoch,
        weightDate: this.addFinishingFemaleModel.weighing.epoch,
        weightValueKG: this.convertToNumberIfNotNull(this.addFinishingFemaleModel.weightValueKG),
        weightValueLBS: this.convertToNumberIfNotNull(this.addFinishingFemaleModel.weightValueLBS)
      };
      this.loadingIconService.show();
      let response = await this.animalsService.addFemaleFinishing(addFinishingFemaleRequest);
      if (response.status === 201) {
        // reload animal list
        this.animalsService.onAnimalsListChanged.next();
        // after submiting form - clear form and get back to the first step
       this.addFinishingFemaleModel.clear();
       this.finishingStep = AddFemaleFinishingSteps.Entry;
        this.loadingIconService.hide();
      } else {
        if (response && response.errorResponseBody && !response.errorResponseBody.result.message) {
            this.errors[0] = response.errorResponseBody.result.failures && response.errorResponseBody.result.failures[0];
            this.navigateToErrorStep();
        }
        this.loadingIconService.hide();
      }
    } else {
      if (this.errors && this.errors.length > 0) {
        this.navigateToErrorStep();
      }
    }
  }

  private fillWeightValues() {
    if (this.addFinishingFemaleModel.weightValueKG === '') {
      this.addFinishingFemaleModel.weightValueKG = null;
    }
    if (this.addFinishingFemaleModel.weightValueLBS === '') {
      this.addFinishingFemaleModel.weightValueLBS = null;
    }
    if (this.isKgValueDisplayed()) {
      if (this.addFinishingFemaleModel.weightValueKG == null) {
        this.addFinishingFemaleModel.weightValueLBS = null;
        return;
      }
      this.addFinishingFemaleModel.weightValueLBS = String(this.utilsService.convertKilogramsToPounds(Number(this.addFinishingFemaleModel.weightValueKG)));
    } else {
      if (this.addFinishingFemaleModel.weightValueLBS == null) {
        this.addFinishingFemaleModel.weightValueKG = null;
        return;
      }
      this.addFinishingFemaleModel.weightValueKG = String(this.utilsService.convertPoundsToKilograms(Number(this.addFinishingFemaleModel.weightValueLBS)));
    }
  }

  public isKgValueDisplayed() {
    return this.utilsService.getUnitType() === WeightUnitType.KG;
  }

  public checkIfCanNavigateToStep(step: AddFemaleFinishingSteps) {
    if (this.finishingStep > step) {
      this.finishingStep = step;
    }
  }

  private navigateToErrorStep() {
    if (this.errors) {
      switch (this.errors[0].fieldName) {
        case AddAnimalFieldsErrorsEnum.weightDate:
        case AddAnimalFieldsErrorsEnum.weightValueLBS:
        case AddAnimalFieldsErrorsEnum.weightValueKG: {
          this.finishingStep = AddFemaleFinishingSteps.Farm;
          break;
        }
        case AddAnimalFieldsErrorsEnum.animalName:
        case AddAnimalFieldsErrorsEnum.orn:
        case AddAnimalFieldsErrorsEnum.rfIdTagNumber:
        case AddAnimalFieldsErrorsEnum.scrTagNumber: {
          this.finishingStep = AddFemaleFinishingSteps.Entry;
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  public getWeightValueAccordingToLocalization() {
    if (this.isKgValueDisplayed()) {
      if (this.addFinishingFemaleModel.weightValueKG) {
        return this.addFinishingFemaleModel.weightValueKG;
      } else {
        return '-';
      }
    } else {
      if (this.addFinishingFemaleModel.weightValueLBS) {
        return this.addFinishingFemaleModel.weightValueLBS;
      } else {
        return '-';
      }
    }
  }

  public isWeightValueExists() {
    if (this.isKgValueDisplayed()) {
      return !!this.addFinishingFemaleModel.weightValueKG;
    } else {
      return !!this.addFinishingFemaleModel.weightValueLBS;
    }
  }
}
