<div class="create-event-form">
  <div class="form-section-title">
    {{ 'ANIMAL.EVENTS.ADD_EVENT.DESCRIPTION' | translate }}
  </div>
  <div class="form-section-item">
    <regular-auto-complete [placeholder]="'MANAGE.COWS.ACTIVE.GENERAL.PLACE_HOLDER_DESCRIPTION'"
                           [isAllowUnmatchedSelection]="true"
                           [items]="descriptionItems"
                           [maxInputLength]="20"
                           [hasIcon]="true"
                           [hasDeleteIcon]="true"
                           [shIdPostfix]="'general-event-description'"
                           [colorScheme]="AutoCompleteColorScheme.WhiteBlack"
                           (searchQueryChanged)="onDescriptionChanged($event)"
                           [isFieldError]="errorModel && errorModel.fieldName === 'description'"
                           [caseInvariant]="true"
                           [autoFocus]="true">
    </regular-auto-complete>
  </div>
</div>
