import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as moment from 'moment';
import {TranslationService} from '../../../../services/translations/translation.service';
import {Subscription} from 'rxjs';
import {EpochStateModel} from '../model/epoch-state-model';
import {WINDOW} from '../../../../utils/window-utils';
import {AlignPopupType, PopupTemplateComponent} from '../../popups/popup-template/popup-template.component';

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public selectedEpoch : EpochStateModel;

  @Input()
  public disabled:boolean;

  @Input()
  public isFieldError: boolean = false;

  @Input()
  public shIdPostfix: string = '';

  @Input()
  public calendarIconColor: string = '#000';

  @Input()
  public shownRemovableIcon: boolean = true;

  @Input()
  public datePickerBackground: string = '#FFFFFF';

  @Output()
  public epochChanged = new EventEmitter<EpochStateModel>();

  @Input()
  public popupContentHorizontalAlignment: AlignPopupType = AlignPopupType.start;

  @ViewChild('dateTimePickerContent')
  public dateTimePickerContent: ElementRef;

  @ViewChild('dateTimePickerHeader')
  public dateTimePickerHeader: ElementRef;

  @ViewChild(PopupTemplateComponent, {static: true})
  public popupTemplate: PopupTemplateComponent;

  public isOpen: boolean;

  public dateText: string = '';

  private inputFocused : boolean;

  private langChangedSubscription: Subscription;

  private _isFoldsUp: boolean;

  constructor(@Inject(WINDOW) private readonly _window: Window,
              public readonly translationService: TranslationService,
              private readonly detectorRef: ChangeDetectorRef) {

  }

  public ngOnInit() {
    this.langChangedSubscription = this.translationService.languageChangeSubject.subscribe(() => {
      this.updateSelectedDateText();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.updateSelectedDateText();
  }

  public ngOnDestroy(): void {
    this.langChangedSubscription.unsubscribe();
  }

  public get isDisabled(): boolean {
    return this.disabled || this.selectedEpoch.errorMinMax;
  }

  public get placeholderText() : string {
    return this.translationService.shortDateFormatFullYear;
  }

  public get isRemoveIconShown() : boolean {
    return !this.isDisabled &&
      this.shownRemovableIcon &&
      this.dateText != null &&
      this.dateText.length > 0;
  }

  public get markAsError() : boolean {
    if(this.isFieldError) {
      return true;
    }
    return !this.inputFocused &&
           this.dateText != null &&
           this.dateText.length > 0 &&
           this.selectedEpoch.date == null;
  }

  public onOutsideClick() {
    this.isOpen = false;
  }

  public onHeaderClick() {
    if(this.isDisabled) {
      return;
    }
    this.isOpen = true;
    this.reloadMinMaxDetection();
  }

  public clearDateValue(event:Event) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedEpoch.epochDate = null;
    this.updateSelectedDateText();
    this.epochChanged.emit(this.selectedEpoch);
  }

  public onDateChanged(date:Date) {
    this.selectedEpoch.date = date;
    this.updateSelectedDateText();
    this.isOpen = false;
    this.epochChanged.emit(this.selectedEpoch);
  }

  public onSelectedDateTimeTextChange(event: KeyboardEvent) {
    if(event.key == 'Tab' &&
       event.type == 'keydown') {
      this.isOpen = false;
      return;
    }
    this.isOpen = true;
    if(this.dateText.length < this.translationService.shortDateFormatShortYear.length) {
      this.selectedEpoch.date = null;
    } else {
      let parsedDate = moment(this.dateText, this.translationService.shortDateFormatFullYear, false);
      if(parsedDate.isValid()) {
        this.selectedEpoch.date = parsedDate.toDate();
      } else {
        this.selectedEpoch.date = null;
      }
    }
  }

  public onPaste(event: ClipboardEvent) {
    this.isOpen = true;
    let clipboardData = event.clipboardData.getData("text");
    let parsedDate = moment(clipboardData, this.translationService.shortDateFormatFullYear, false);
    if(parsedDate.isValid()) {
      this.selectedEpoch.date = parsedDate.toDate();
      this.updateSelectedDateText();
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.selectedEpoch.date = null;
    }
    this.epochChanged.emit(this.selectedEpoch);
  }

  public onFocus() {
    this.isOpen = true;
    this.inputFocused = true;
    this.reloadMinMaxDetection();
  }

  public onBlur() {
    this.inputFocused = false;
    this.updateSelectedDateText();
    this.epochChanged.emit(this.selectedEpoch);
  }

  public get selectedEpochDate() : Date {
    return this.selectedEpoch.date;
  }

  private reloadMinMaxDetection() {
    setTimeout(() => {
      let element: HTMLElement = document.getElementsByClassName('owl-dt-calendar-cell-disabled')[0] as HTMLElement;
      if (element) {
        element.click();
      }
    });
  }

  private updateSelectedDateText() {
    if(this.selectedEpoch.momentDate != null ) {
      this.dateText = this.selectedEpoch.momentDate.format(this.translationService.shortDateFormatFullYear);
    } else {
      this.dateText = '';
    }
  }

  public get isFoldDirectionUp(): boolean {
    if (!!this.popupTemplate && this._isFoldsUp !== this.popupTemplate.isFoldsUp) {
      setTimeout(() => {
        this._isFoldsUp = this.popupTemplate.isFoldsUp;
        this.detectorRef.detectChanges();
      });
    }
    return this._isFoldsUp;
  }
}
