import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {FarmAlertConfigData} from '../../../../../../services/manage/manage-settings.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationTimingDialogService {
  public popupShown: Subject<FarmAlertConfigData> = new Subject();

  public show(farmAlertConfigData: FarmAlertConfigData): void {
    this.popupShown.next(farmAlertConfigData);
  }

  public hide(): void {
    this.popupShown.next(null);
  }
}
