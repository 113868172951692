import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CowEventCategory, IAvailableEventInfo} from '../../../../services/animals/model/animal-events';
import {ApplicationBundleRoutingMode} from '../../../../services/routing/routing.service';
import {DropdownPopupComponent, DropdownPopupColorScheme} from '../../../../common/components/popups/dropdown-popup/dropdown-popup.component';

interface IAnimalCardEventsCategory {
  title: string;
  type: CowEventCategory;
  events: IAvailableEventInfoViewModel[][];
}

interface IAvailableEventInfoViewModel extends IAvailableEventInfo {
  selectedByKb?: boolean;
}

@Component({
  selector: 'animal-card-events-menu',
  templateUrl: './animal-card-events-menu.component.html',
  styleUrls: ['./animal-card-events-menu.component.scss']
})
export class AnimalCardEventsMenuComponent implements OnInit, OnChanges {
  @Input()
  public availableEvents: IAvailableEventInfo[];

  @Input()
  public autofocus: boolean;

  @Input()
  public applicationBundleRoutingMode: ApplicationBundleRoutingMode;

  @Output()
  public eventSelected: EventEmitter<IAvailableEventInfo> = new EventEmitter<IAvailableEventInfo>();

  @ViewChild('eventsDropdownPopup')
  public eventsDropdownPopup: DropdownPopupComponent;

  public selectedEvent: IAvailableEventInfo;

  public eventCategories: IAnimalCardEventsCategory[];

  public DropdownPopupColorScheme = DropdownPopupColorScheme;

  constructor() {}

  public ngOnInit(): void {
    setTimeout(() => {
      this.openPopup();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.availableEvents && changes.availableEvents.previousValue !== changes.availableEvents.currentValue) {
      this.eventCategories = this.calculateEventCategories(changes.availableEvents.currentValue);
      this.selectedEvent = null;
    }
  }

  public selectEvent(event: IAvailableEventInfo): void {
    this.selectedEvent = event;
    this.eventSelected.emit(event);
    this.eventsDropdownPopup.closePopup();
  }

  public reset(): void {
    this.selectedEvent = null;
  }

  public openPopup(): void {
    this.eventsDropdownPopup.openPopup();
  }

  private calculateEventCategories(events: IAvailableEventInfo[]): IAnimalCardEventsCategory[] {
    if (!events) {
      return [];
    }
    const tagManagementEvents = events.filter((event) => event.category === 'tagManagement' && event.isVisible);
    const reproductionAndGeneralEvents = events.filter((event) => event.category === 'reproductionAndGeneral' && event.isVisible);
    let categories: IAnimalCardEventsCategory[] = [
      {
        title: this.getGeneralEventsTitle(),
        type: CowEventCategory.ReproductionAndGeneral,
        events: this.pageItems(reproductionAndGeneralEvents)
      }
    ];
    if (tagManagementEvents.length > 0) {
      categories.push({
        title: EventCategoryTranslationKey.TagManagementEvents,
        type: CowEventCategory.TagManagement,
        events: this.pageItems(tagManagementEvents)
      });
    }
    return categories;
  }

  private pageItems(items: IAvailableEventInfo[], pageSize: number = 5): IAvailableEventInfo[][] {
    return items.reduce(
      (agg: IAvailableEventInfo[][], item: IAvailableEventInfo) => {
        const lastPage = agg[agg.length - 1];
        if (lastPage.length < pageSize) {
          lastPage.push(item);
        } else {
          agg.push([item]);
        }
        return agg;
      },
      [[]]
    );
  }

  public toggleTagsClass(eventType: string): string {
    let classStyle = eventType;
    if (eventType === 'removeScrTag' || eventType === 'removeRfIdTag') {
      classStyle = 'removeTag';
    }
    if (eventType === 'changeScrTag' || eventType === 'changeRfIdTag') {
      classStyle = 'changeTag';
    }
    if (eventType === 'assignScrTag' || eventType === 'assignRfIdTag') {
      classStyle = 'assignTag';
    }
    return classStyle;
  }

  private getGeneralEventsTitle(): string {
    let generalEventsTitle;
    switch (this.applicationBundleRoutingMode) {
      case ApplicationBundleRoutingMode.Breeding:
        generalEventsTitle = EventCategoryTranslationKey.ReproductionAndGeneralEvents;
        break;
      case ApplicationBundleRoutingMode.Finishing:
        generalEventsTitle = EventCategoryTranslationKey.GeneralEvents;
        break;
      default:
        generalEventsTitle = EventCategoryTranslationKey.ReproductionAndGeneralEvents;
    }
    return generalEventsTitle;
  }
}

export enum EventCategoryTranslationKey {
  ReproductionAndGeneralEvents = 'MANAGE.EVENTS.REPRODUCTION_AND_GENERAL_EVENTS',
  GeneralEvents = 'MANAGE.EVENTS.GENERAL_EVENTS',
  TagManagementEvents = 'MANAGE.EVENTS.TAG_MANAGEMENT_EVENTS'
}
