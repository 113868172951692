import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuItemType} from "../../left-menu.component";
import {Router} from "@angular/router";
import {PopupViewStateService} from '../../../../services/ui/view-state/popup-view-state.service';

@Component({
  selector: 'menu-item-sorting-gate',
  templateUrl: './menu-item-sorting-gate.component.html',
  styleUrls: ['./menu-item-sorting-gate.component.scss']
})
export class MenuItemSortingGateComponent implements OnInit {

  @Input()
  public titleKey: string;

  @Input()
  public iconClass: string;

  @Input()
  public link: string;

  @Input()
  public isOpenMenu: boolean;

  @Output()
  public onToggleMenu: EventEmitter<MenuItemType> = new EventEmitter();

  constructor(private router: Router,
              private menuItemSortingGateElementRef: ElementRef,
              private popupViewStateService: PopupViewStateService) {

  }

  public ngOnInit() {
    this.popupViewStateService.registerComponent('menuItemSortingGate', this.menuItemSortingGateElementRef);
  }

  public get isSortingGateRoute() : boolean {
    return this.router.url.includes('sorting-gate/');
  }

  public onClick() {
    this.onToggleMenu.emit(MenuItemType.SortingGate);
  }
}
