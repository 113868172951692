<confirm-dialog [primaryText]="'MANAGE.COWS.ACTIVE.DIALOGS.CULLING_EVENT_DIALOG_MAIN_DESCRIPTION'"
                [secondaryText]="'MANAGE.COWS.ACTIVE.DIALOGS.CULLING_EVENT_DIALOG_SECONDARY_DESCRIPTION'"
                [headerText]="'MANAGE.COWS.ACTIVE.DIALOGS.CULLING_EVENT_DIALOG_TITLE'"
                [isOpen]="isOpen"
                [containerShId]="'cullingCowWithAssignedTag-confirmation-dialog'"
                [leftButtonShId]="'notification_dialog_close_cullingCowWithAssignedTagDialog'"
                [rightButtonShId]="'notification_dialog_apply_cullingCowWithAssignedTagDialog'"
                [leftButtonText]="'COMMON.BUTTON.CANCEL'"
                [rightButtonText]="'MANAGE.COWS.ACTIVE.DIALOGS.CULLING_EVENT_DIALOG_ACTION_BUTTON'"
                (onCloseClick)="close(false)"
                (onLeftButtonClick)="close(false)"
                (onRightButtonClick)="close(true)">
</confirm-dialog>


