import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class InitializeTrialGuard implements CanActivate {

  constructor(private router: Router,
              private authService: AuthService) {

  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if(this.authService.isRegistered()) {
      await this.router.navigate(['/']);
      return false;
    }
    return true;
  }

}
