<confirm-dialog [iconClass]="getIconClassByFarmAndOperationMode()"
                [colorTheme]="getThemeClassByFarmAndOperationMode()"
                [headerText]="getTitleTextByFarmAndOperationMode()"
                [primaryText]="getPrimaryTextByFarmAndOperationMode()"
                [secondaryText]="'REGISTRATION.TRIAL_DAIRY_DIALOG_CONFIRM.CONTENT'"
                [rightButtonText]="'REGISTRATION.FLOW.TAKE_ME_TO_DASHBOARD'"
                [leftButtonText]="'COMMON.BUTTON.CANCEL'"
                [isOpen]="isOpen"
                (onCloseClick)="onClose()"
                (onLeftButtonClick)="onClose()"
                (onRightButtonClick)="goToDashboard()"
                ></confirm-dialog>
