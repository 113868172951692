import {Component, Input} from "@angular/core";
import {EventDetailsError} from "../../../../../services/animals/model/animal-events";

export enum CustomEventWrapperCssClass {
  noFormSection = 'no-form-section',
  calvingFormSection = 'calving-form-section'
}

@Component({
  selector: 'event-form-errors',
  templateUrl: './event-form-errors.component.html',
  styleUrls: ['./event-form-errors.component.scss']
})
export class EventFormErrorsComponent {
  @Input()
  public errorModel: EventDetailsError;

  @Input()
  public customEventWrapperCssClass: string = null;

  public CustomEventWrapperCssClass = CustomEventWrapperCssClass;
}
