import {Injectable} from '@angular/core';
import {HttpResponseResult, HttpResponseResultNoBody, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from '../auth/auth.service';
import {ConcreteShiftDetails, IShiftsHistoryResponse, IShiftsResponse, ShiftModel} from './model/shifts-model';
import {
  GroupsSortingTaskModel,
  IScheduleRequestParams,
  ReportMetaModel,
  SchedulerModel,
  SortingTaskModel,
  SortingTasksCommentsResponseModel,
  SortingTaskSortType,
  SortingTasksResponseModel
} from './model/scheduler-model';
import {HttpParams} from "@angular/common/http";
import {IDay, IRepeatDay, ISortingGateModel, ISortingPenModel} from './model/sorting-gate-model';
import * as moment from "moment";
import * as _ from 'lodash';
import {ConfigService} from "../config/config.service";
import {TranslationService} from '../translations/translation.service';
import {ChipsItem} from "../../common/components/chips/chips-item.component";

class SortingIsActiveResponse {
  public isActive: boolean;
}

class ReportsResponse {
  public reports: ReportMetaModel[];
}

class SortingPensRequest {
  constructor(public pens: ISortingPenModel[]) {}
}

class GetSortingGatesResponse {
  public gates: ISortingGateModel[];
}

export interface IErrorResponseFailure {
  fieldName: string | null,
  key: string
}

export interface IErrorResponseArrayFailure {
  failures: IErrorResponseFailure[],
  succeeded: boolean;
}

interface IErrorResponse {
  result: {
    arrayFailures: {[key: string]: IErrorResponseArrayFailure}
    failures: IErrorResponseFailure[],
    succeeded: boolean;
  };
}

@Injectable({
  providedIn: 'root'
})
export class SortingGateService {

  public static readonly MaxRepeatDays = 14;

  public static readonly MinRepeatDays = 2;

  public static readonly MinRepeatWeeksGap = 1;

  public static readonly MaxRepeatWeeksGap = 4;

  public static readonly SortingGateReportId = 22;

  private static readonly ShiftsRoute = '/rest/api/sorting/shifts';

  private static readonly SortingIsActiveRoute = '/rest/api/sorting/isactive';

  private static readonly SchedulerRoute = '/rest/api/sorting/scheduler';

  private static readonly SortingTasksRoute = '/rest/api/sorting/tasks?ids=';

  private static readonly SortingTaskRoute = '/rest/api/sorting/task/';

  private static readonly SortingTaskV2Route = '/rest/api/v2/sorting/task/';

  private static readonly SortingReportsRoute = '/rest/api/sorting/reports/';

  private static readonly SortingPensRoute = '/rest/api/sorting/pens';

  private static readonly SortingGatesRoute = '/rest/api/sorting/gates';

  private static readonly ShiftsHistoryRoute = '/rest/api/sorting/shifts/history';

  private static readonly SortingTasksComments = '/rest/api/sorting/tasks/comments';

  constructor(private restApiService: RestApiService,
              public configService: ConfigService,
              private authService: AuthService,
              private translationService: TranslationService) { }

  public get sortingTaskTypes() {
    return [
      {
        label: this.translationService.translate('SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.SORTING_BY_DROPDOWN.ANIMALS'),
        type: SortingTaskSortType.animals
      },
      {
        label: this.translationService.translate('SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.SORTING_BY_DROPDOWN.GROUPS'),
        type: SortingTaskSortType.groups
      },
      {
        label: this.translationService.translate('SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.SORTING_BY_DROPDOWN.REPORTS'),
        type: SortingTaskSortType.report
      },
      {
        label: this.translationService.translate('SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.SORTING_BY_DROPDOWN.UNIDENTIFIED'),
        type: SortingTaskSortType.unidentifiedAnimals
      }
    ];
  }

  public getShifts(): Promise<HttpResponseResult<IShiftsResponse>> {
    return this.restApiService.sendGetMethodAny<IShiftsResponse>(SortingGateService.ShiftsRoute, this.authService.currentUserAuthState.authDetails);
  }

  // tslint:disable-next-line:no-any
  public sendShifts(shifts: ShiftModel[]): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPostMethod(SortingGateService.ShiftsRoute, this.authService.currentUserAuthState.authDetails, {shifts: shifts});
  }

  public getShiftsHistory(date?: number): Promise<HttpResponseResult<IShiftsHistoryResponse>> {
    let route = SortingGateService.ShiftsHistoryRoute;
    if (date) {
      route += `?date=${date}`;
    }
    return this.restApiService.sendGetMethodAny<IShiftsHistoryResponse>(route, this.authService.currentUserAuthState.authDetails);
  }

  public async getSortingPens(): Promise<ISortingPenModel[]> {
    const response = await this.restApiService
      .sendGetMethodAny<SortingPensRequest>(SortingGateService.SortingPensRoute, this.authService.currentUserAuthState.authDetails);

    if (response.errorResponseBody != null) {
      return Promise.reject('Error while loading sorting pens');
    } else {
      return response.responseBody.pens;
    }
  }

  public async getSortingGates(): Promise<ISortingGateModel[]> {
    const response = await this.restApiService
      .sendGetMethodAny<GetSortingGatesResponse>(SortingGateService.SortingGatesRoute, this.authService.currentUserAuthState.authDetails);

    if (response.errorResponseBody != null) {
      return Promise.reject('Error while loading sorting gates');
    } else {
      return response.responseBody.gates;
    }
  }

  public async updateSortingPens(sortingPens: ISortingPenModel[]): Promise<void> {
    const result = await this.restApiService.sendPostMethodAny<void>(
      SortingGateService.SortingPensRoute,
      this.authService.currentUserAuthState.authDetails,
      new SortingPensRequest(sortingPens)
    );

    if (result.errorResponseBody != null) {
      // tslint:disable-next-line:no-any
      const getErrorMessage = (error: any): string => {
        if (this.isErrorResponse(error)) {
          const key = this.getErrorKey(error);
          return this.translationService.translate(`SORTING_GATE.SERVER_ERRORS.${key}`);
        } else {
          return 'Error while saving sorting pens';
        }
      };

      return Promise.reject(getErrorMessage(result.errorResponseBody));
    } else {
      return result.responseBody;
    }
  }

  public async sortingIsActive() : Promise<boolean> {
    let response = await this.restApiService.sendGetMethodAny<SortingIsActiveResponse>(SortingGateService.SortingIsActiveRoute, this.authService.currentUserAuthState.authDetails);
    if(response.responseBody) {
      return response.responseBody.isActive;
    }
    return false;
  }

  public async getScheduler(schedulerRequestParams: IScheduleRequestParams): Promise<SchedulerModel> {
    let queryParams: HttpParams = new HttpParams().set('relativeWeek', schedulerRequestParams.relativeWeek.toString());
    let schedulerModelHttpResponseResult = await this.restApiService.sendGetMethodAny<SchedulerModel>(SortingGateService.SchedulerRoute,
                                                                                    this.authService.currentUserAuthState.authDetails,
                                                                                    queryParams);
    if(schedulerModelHttpResponseResult.responseBody == null) {
      return null;
    }
    this.normalizeSchedulerModel(schedulerModelHttpResponseResult.responseBody);
    return schedulerModelHttpResponseResult.responseBody;
  }

  private normalizeSchedulerModel(schedulerModel:SchedulerModel) {
    schedulerModel.currentDate = this.convertToFarmZoneDateEpoch(schedulerModel.currentDate).unix();
    schedulerModel.startDate = this.convertToFarmZoneDateEpoch(schedulerModel.startDate).unix();
    for (let i = 0; i < schedulerModel.scheduledDays.length; i++) {
      schedulerModel.scheduledDays[i] = this.convertToFarmZoneDateEpoch(schedulerModel.scheduledDays[i]).unix();
    }
    for (let shift of schedulerModel.shifts) {
      for (let scheduleDay of shift.schedulerDays) {
        scheduleDay.date = this.convertToFarmZoneDateEpoch(scheduleDay.date).unix();
      }
      shift.schedulerDays = shift.schedulerDays.filter(schedulerDay => schedulerModel.scheduledDays.includes(schedulerDay.date));
    }
  }

  public async getSortingTasksByIds(taskIds: number[]) : Promise<SortingTaskModel[]> {
    if(taskIds == null || taskIds.length == 0) {
      return [];
    }
    let route = SortingGateService.SortingTasksRoute + taskIds.toString();
    let response = await this.restApiService.sendGetMethodAny<SortingTasksResponseModel>(route, this.authService.currentUserAuthState.authDetails);
    return response.responseBody.tasks;
  }

  public async getSortingTasksComments() : Promise<string[]> {
    let response = await this.restApiService.sendGetMethodAny<SortingTasksCommentsResponseModel>(SortingGateService.SortingTasksComments, this.authService.currentUserAuthState.authDetails);
    return response.responseBody.comments;
  }

  public async getReports(): Promise<ReportMetaModel[]> {
    let response = await this.restApiService.sendGetMethodAny<ReportsResponse>(SortingGateService.SortingReportsRoute, this.authService.currentUserAuthState.authDetails);
    if(response.responseBody) {
      return response.responseBody.reports;
    }
    return null;
  }

  public async createSortingTask(sortingTask: SortingTaskModel): Promise<HttpResponseResult<HttpResponseResultNoBody>> {
    const serverSortingTask = _.cloneDeep(sortingTask);
    serverSortingTask.date = this.convertToServerDateEpoch(serverSortingTask.date);
    const result: HttpResponseResult<HttpResponseResultNoBody> = await this.restApiService.sendPostMethodAny<HttpResponseResultNoBody>(SortingGateService.SortingTaskV2Route,
                                                                          this.authService.currentUserAuthState.authDetails,
                                                                          serverSortingTask);
    if (result.errorResponseBody != null) {
      // tslint:disable-next-line:no-any
      const getErrorMessage = (error: any): string => {
        if (this.isErrorResponse(error)) {
          const key = this.getErrorKey(error);
          return this.translationService.translate(`SORTING_GATE.SERVER_ERRORS.${key}`);
        } else {
          return this.translationService.translate(`SORTING_GATE.SERVER_ERRORS.ErrorWhileCreatingSortingTask`);
        }
      };

      return Promise.reject(getErrorMessage(result.errorResponseBody));
    } else {
      return result;
    }
  }

  public async updateSortingTask(sortingTask: SortingTaskModel) {
    const serverSortingTask = _.cloneDeep(sortingTask);
    serverSortingTask.date = this.convertToServerDateEpoch(serverSortingTask.date);
    let route = SortingGateService.SortingTaskV2Route + serverSortingTask.taskId;
    await this.restApiService.sendPutMethodAny<HttpResponseResultNoBody>(route, this.authService.currentUserAuthState.authDetails, serverSortingTask);
  }

  public async removeSortingTask(taskId: number) {
    let route = SortingGateService.SortingTaskRoute + taskId;
    await this.restApiService.sendDeleteMethodAny<HttpResponseResultNoBody>(route, this.authService.currentUserAuthState.authDetails);
  }

  public getCurrentFarmZoneEpoch() : moment.Moment {
    return this.convertToFarmZoneDateEpoch(moment().unix() - moment().utcOffset() * 60)
  }

  public convertToFarmZoneDateEpoch(epoch: number) : moment.Moment {
    return moment.unix(epoch + (this.configService.serverConfig.timeZone.utcOffset * 60));
  }

  public convertToServerDateEpoch(epoch: number) : number {
    return moment.unix(epoch - (this.configService.serverConfig.timeZone.utcOffset * 60)).unix();
  }

  public generateConcreteShifts(shifts: ShiftModel[], generateFutureShifts: boolean, selectedDateFarmZoneEpoch?: number) : ConcreteShiftDetails[] {

    let currentFarmZoneDateTime = this.getCurrentFarmZoneEpoch();

    if(selectedDateFarmZoneEpoch == null) {
      selectedDateFarmZoneEpoch = currentFarmZoneDateTime.clone().startOf('day').unix();
    }

    let selectedDate = moment.unix(selectedDateFarmZoneEpoch);
    let concreteShifts : ConcreteShiftDetails[] = [];
    let sameDayShifts = shifts.filter(shift => shift.startTime < shift.endTime);
    for (let sameDayShift of sameDayShifts) {
      let shiftStartDateTime = selectedDate.clone().add(sameDayShift.startTime, 'milliseconds');
      let shiftEndDateTime = selectedDate.clone().add(sameDayShift.endTime, 'milliseconds');

      if((generateFutureShifts && shiftEndDateTime > currentFarmZoneDateTime) ||
        (!generateFutureShifts && shiftStartDateTime < currentFarmZoneDateTime)) {
        concreteShifts.push({
          shift: sameDayShift,
          startDateTime: shiftStartDateTime.unix(),
          endDateTime: shiftEndDateTime.unix(),
          isCurrent: this.isCurrentShift(shiftStartDateTime, shiftEndDateTime, currentFarmZoneDateTime)
        });
      }
    }
    let differentDaysShift = shifts.find(value => !sameDayShifts.includes(value));
    if(differentDaysShift != null) {
      let shiftStartDateTime = selectedDate.clone().add(differentDaysShift.startTime, 'milliseconds');
      let shiftEndDateTime = selectedDate.clone().add(differentDaysShift.endTime, 'milliseconds');

      if(generateFutureShifts) {
        shiftEndDateTime = shiftEndDateTime.clone().add(1, 'day');
      } else {
        shiftStartDateTime = shiftStartDateTime.clone().add(-1, 'day');
      }

      if(concreteShifts.find(concreteShift => concreteShift.isCurrent) == null) {
        if(shiftStartDateTime > currentFarmZoneDateTime && shiftStartDateTime.day() == currentFarmZoneDateTime.day()) {
          shiftStartDateTime = shiftStartDateTime.clone().add(-1, 'day');
          shiftEndDateTime = shiftEndDateTime.clone().add(-1, 'day');
        } else if(shiftEndDateTime < currentFarmZoneDateTime) {
          shiftStartDateTime = shiftStartDateTime.clone().add(1, 'day');
          shiftEndDateTime = shiftEndDateTime.clone().add(1, 'day');
        }
      }

      if((generateFutureShifts && shiftEndDateTime > currentFarmZoneDateTime) ||
        (!generateFutureShifts && shiftStartDateTime < currentFarmZoneDateTime)) {
        concreteShifts.push({
          shift: differentDaysShift,
          startDateTime: shiftStartDateTime.unix(),
          endDateTime: shiftEndDateTime.unix(),
          isCurrent: this.isCurrentShift(shiftStartDateTime, shiftEndDateTime, currentFarmZoneDateTime)
        });
      }
    }
    return concreteShifts.sort((a, b) => a.shift.startTime - b.shift.startTime);
  }

  private isCurrentShift(shiftStartDateTime: moment.Moment, shiftEndDateTime: moment.Moment, currentFarmZoneDateTime : moment.Moment) {
    return shiftStartDateTime <= currentFarmZoneDateTime && shiftEndDateTime >= currentFarmZoneDateTime
  }

  public createWeekDays(fromDate: number): IDay[] {
    let days: IDay[] = [],
      i: number,
      day: moment.Moment,
      isToday: boolean,
      formatted: string;

    for (i = 0; i < 7; i++) {
      day = moment.unix(fromDate).add(i, 'd');
      isToday = i === 0;
      formatted = isToday ? 'Today' : day.format('dddd');
      days.push({ day, formatted, isToday });
    }

    return days;
  }

  public createRepeatDays(): IRepeatDay[] {
    let days: IRepeatDay[] = [],
      maxDays: number = SortingGateService.MinRepeatDays + 13,
      i: number;
    for (i = SortingGateService.MinRepeatDays; i < maxDays; i++) {
      days.push({ value: i, label: `${i} Days`});
    }

    return days;
  }

  private getErrorKey(errorResponse: IErrorResponse): string | null {
    let failure;
    if (errorResponse.result.failures && errorResponse.result.failures.length > 0) {
      failure = errorResponse.result.failures[0];
    } else if (errorResponse.result.arrayFailures && Object.values(errorResponse.result.arrayFailures).length > 0) {
      failure = Object.values(errorResponse.result.arrayFailures)[0].failures[0];
    }
    if (failure != null && failure.key != null) {
      return failure.key;
    } else {
      return null;
    }
  }

// tslint:disable-next-line:no-any
  private isErrorResponse(item: any): item is IErrorResponse {
    return 'result' in item && 'failures' in item.result;
  }
}
