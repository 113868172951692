import {Inject, Injectable} from '@angular/core';
import {ClientSessionRecorderSession} from "../../common-model/csr-message.model";
import {
  EndOfAutoPlaybackActionType,
  PlaybackChangeModeClientSessionRecorderStatus
} from "../../common-model/csr-message-status.model";
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "../../../services/config/config.service";
import {ActivatedRouteSnapshot} from "@angular/router";
import {ClientSessionRecorderStorageService} from "./client-session-recorder-storage.service";
import {WINDOW} from "../../../utils/window-utils";
import {PlaybackTestCase, PlaybackTestResult} from "./model/playback-test-result";

@Injectable({
  providedIn: 'root'
})
export class ClientSessionTestingService {

  private static readonly TestParam = 'testCase';

  private static readonly RecordedTestsPath: string = `assets/testing/recordings/`;

  private static readonly TestResultApiEndpoint : string = '/api/testresults';

  private testResult : PlaybackTestResult;

  constructor(@Inject(WINDOW) private _window: Window,
              private readonly httpClient : HttpClient,
              private readonly configService: ConfigService,
              private readonly clientSessionRecorderStorageService: ClientSessionRecorderStorageService) {

  }

  public async init() {
    let testFile = this.getTestingFileName();
    if(testFile == null) {
      return;
    }
    if (this.configService.configuration.testingServerUrl == null) {
      return;
    }
    let testSession = await this.httpClient.get<ClientSessionRecorderSession>(ClientSessionTestingService.RecordedTestsPath + testFile).toPromise();
    var playbackModeStatus = new PlaybackChangeModeClientSessionRecorderStatus();
    playbackModeStatus.playbackSession = testSession;
    playbackModeStatus.autoPlayback = {clickInterval:500,
                                       inputInterval:100,
                                       keyboardInterval:100,
                                       scrollInterval:100,
                                       windowResizeInterval:100,
                                       stopEventId: null,
                                       endOfPlaybackAction:EndOfAutoPlaybackActionType.ExitPlaybackMode};
    this.testResult = new PlaybackTestResult();
    if(playbackModeStatus.playbackSession.testSuite?.name != null) {
      this.testResult.suiteName = playbackModeStatus.playbackSession.testSuite.name;
    } else {
      this.testResult.suiteName = testFile;
    }
    this.testResult.fileName = testFile;
    this.clientSessionRecorderStorageService.storeNewRecorderStatus(playbackModeStatus);
    this.clientSessionRecorderStorageService.loadCurrentMode();
  }

  public get playAsTest() : boolean {
    return this.testResult != null;
  }

  public addDefaultTestCase() {
    if(!this.playAsTest) {
      return;
    }
    this.testResult.testCases.push({name: this.testResult.suiteName, success: false, failureReason: null});
  }

  public addTestCase(testCase : PlaybackTestCase) {
    if(!this.playAsTest) {
      return;
    }
    this.testResult.testCases.push(testCase);
  }

  public get currentTestCase() : PlaybackTestCase {
    if(!this.playAsTest) {
      return;
    }
    if(this.testResult.testCases.length > 0) {
      return this.testResult.testCases[this.testResult.testCases.length - 1];
    } else {
      return null;
    }
  }


  public async notifyCompleted() {
    if(!this.playAsTest) {
      return;
    }
    if(this.currentTestCase == null) {
      this.addDefaultTestCase();
    }
    if(this.currentTestCase.failureReason == null) {
      this.currentTestCase.success = true;
    }
    await this.httpClient.post(this.configService.configuration.testingServerUrl + ClientSessionTestingService.TestResultApiEndpoint,
                                   JSON.stringify(this.testResult),
      {headers: {"Content-Type": "application/json",
                         "Accept": "application/json"}}).toPromise();
  }

  private getTestingFileName() : string {
    let clientUrl = this._window.location.toString();
    if(clientUrl.indexOf('?' + ClientSessionTestingService.TestParam) == -1 &&
       clientUrl.indexOf('&' + ClientSessionTestingService.TestParam) == -1) {
      return null;
    }
    let searchParams = new URLSearchParams(clientUrl.substr(clientUrl.indexOf('?')));
    if(!searchParams.has(ClientSessionTestingService.TestParam)) {
      return null;
    }
    let fileName = searchParams.get(ClientSessionTestingService.TestParam);
    let jsonIndex = fileName.indexOf('.json');
    return fileName.substring(0, jsonIndex + '.json'.length);
  }
}
