import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingIconService {

  public loadingIconSubject: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  public show(): void {
    this.loadingIconSubject.next(true);
  }

  public hide(): void {
    this.loadingIconSubject.next(false);
  }
}
