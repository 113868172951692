import {DataRow} from '../../../model/common-model';

export class GridViewState {

  private expandedRows:Map<DataRow, ExpandedRowViewState> = new Map<DataRow, ExpandedRowViewState>();

  public getRowExpandedState(row: DataRow) : ExpandedRowViewState {
    if(this.expandedRows.has(row)) {
      return this.expandedRows.get(row);
    }
    return null;
  }

  public setRowExpanded(row: DataRow, expandedState: ExpandedRowViewState) {
    this.expandedRows.set(row, expandedState);
  }

  public removeExpandedRow(row: DataRow) {
    this.expandedRows.delete(row);
  }

  public clearExpandedRows() {
    this.expandedRows.clear();
  }
}

export class ExpandedRowViewState {
  public expandedHeight: number;
}
