import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {DropdownListAutoSelectionMode, DropdownListItem} from '../dropdown-list-model';
import {PopupTemplateComponent} from '../../popups/popup-template/popup-template.component';

export enum DropdownColorScheme {
  greyGreen = 'greyGreen',
  whiteGreen = 'whiteGreen',
  greyBlue = 'greyBlue',
  regular = 'regular',
  blue = 'blue',
  whiteBlue = 'whiteBlue'
}

export enum DropdownCustomIcon {
  none = 'none',
  blueCalendar = 'blueCalendar'
}

@Component({
  selector: 'regular-dropdown-list',
  templateUrl: './regular-dropdown-list.component.html',
  styleUrls: ['./regular-dropdown-list.component.scss'],
})
export class RegularDropdownListComponent implements OnInit, OnChanges  {

  @Input()
  public shIdPostfix: string = '';

  @Input()
  public items: DropdownListItem[] = [];

  @Input()
  public colorScheme: DropdownColorScheme = DropdownColorScheme.regular;

  @Input()
  public disabled: boolean;

  @Input()
  public autoSelectionMode?: DropdownListAutoSelectionMode = DropdownListAutoSelectionMode.None;

  @Input()
  public allowClear: boolean = true;

  @Input()
  public placeholder: string = '';

  @Input()
  public selectedValue?: DropdownListItem;

  @Input()
  public isFieldError: boolean = false;

  @Input()
  public itemsMaxHeight: number = 240;

  @Input()
  public inputAreaHeight: number = 40;

  @Input()
  public hidePopupWhenOneOrLessItem: boolean;

  @Input()
  public customIcon: DropdownCustomIcon = DropdownCustomIcon.none;

  @Output()
  public onItemSelected = new EventEmitter<DropdownListItem>();

  @ViewChild('itemsListElement')
  public itemsListElement: ElementRef;

  @ViewChild(PopupTemplateComponent, {static: true})
  public popupTemplate: PopupTemplateComponent;

  public isOpen: boolean;

  public markedItem: number;

  public selectedItem: DropdownListItem;

  public DropdownCustomIcon = DropdownCustomIcon;

  private _isFoldsUp: boolean;

  public get canDisplayPopupAccordingNumberOfItems() : boolean {
    if(!this.hidePopupWhenOneOrLessItem){
      return true;
    }
    return this.items.length > 1;
  }

  constructor(private readonly detectorRef: ChangeDetectorRef) {
  }

  public ngOnInit() {

  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      const items = changes.items.currentValue || [];
      switch (this.autoSelectionMode) {
        case DropdownListAutoSelectionMode.None: {
          break;
        }
        case DropdownListAutoSelectionMode.First: {
          if (items.length > 0 && !this.selectedItem) {
            this.selectItem(items[0], null);
          }
          break;
        }
        case DropdownListAutoSelectionMode.Single:
        default: {
          if (items.length === 1 && !this.selectedItem) {
            this.selectItem(items[0], null);
          }
          break;
        }
      }
    }
    if (changes.selectedValue && changes.selectedValue.currentValue) {
      const selectedValue: DropdownListItem = changes.selectedValue.currentValue;
      this.selectedItem = this.items.find(item => item.value === selectedValue.value);
    } else if (changes.selectedValue && changes.selectedValue.currentValue == null) {
      this.selectedItem = null;
    }
  }

  public get presentDropdownFoldUpIcon() : boolean {
    if(this.selectedItem != null && this.allowClear) {
      return false;
    }
    return true;
  }

  public showPlaceholder() {
    return !this.isOpen && this.placeholder && this.placeholder.trim();
  }

  public writeValue(value): void {
    if(this.items != null) {
      const selectedEl = this.items.find(item => item.value === value);
      if (selectedEl) {
        this.selectedItem = selectedEl;
        this.onItemSelected.emit(selectedEl);
        return;
      }
    }
    this.selectedItem = null;
    this.onItemSelected.emit(null);
  }

  public toggleOpen(event?: MouseEvent) {
    if (event && (event.x === 0) && (event.y === 0)) {
      return;
    }
    if(!this.canDisplayPopupAccordingNumberOfItems || this.disabled) {
      return;
    }
    this.isOpen = !this.isOpen;
    if (this.items.length > 0) {
      this.markedItem = 0;
    }
  }

  public closeDropdown() {
    this.isOpen = false;
  }

  public selectItem(item: DropdownListItem, event: Event) {
    if(event != null) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (item) {
      if (item.disabled) {
        return;
      }
      this.writeValue(item.value);
    } else {
      this.writeValue(null);
    }
    this.isOpen = false;
  }

  public onEnter(event: Event) {
    if (this.isOpen && this.markedItem > -1) {
      event.preventDefault();
      event.stopPropagation();
      this.selectedItem = this.items[this.markedItem];
      this.selectItem(this.selectedItem, event);
    }
  }

  public onArrowDown() {
    this.incrementSelectedIndex();
  }

  public onArrowUp() {
    this.decrementSelectedIndex();
  }

  public decrementSelectedIndex() {
    this.markedItem--;
    if (this.markedItem < 0) {
      this.markedItem = this.items.length - 1;
    }
    document.querySelector('#regular-item-' + this.markedItem).scrollIntoView({ block: 'nearest'});
  }

  public incrementSelectedIndex() {
    this.markedItem++;
    if (this.markedItem === this.items.length) {
      this.markedItem = 0;
    }
    document.querySelector('#regular-item-' + this.markedItem).scrollIntoView({ block: 'nearest'});
  }

  public isMarked(index: number) {
    return index === this.markedItem;
  }

  public get isFoldDirectionUp(): boolean {
    if (!!this.popupTemplate && this._isFoldsUp !== this.popupTemplate.isFoldsUp) {
      setTimeout(() => {
        this._isFoldsUp = this.popupTemplate.isFoldsUp;
        this.detectorRef.detectChanges();
      });
    }
    return this._isFoldsUp;
  }
}
