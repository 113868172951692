import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {FinishingApplicationBundle} from '../../../../services/config/model/server-config';
import {ApplicationBundleRoutingMode} from '../../../../services/routing/routing.service';
import {AnimalsService} from '../../../../services/animals/animals.service';
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'manage-menu',
  templateUrl: './manage-menu.component.html',
  styleUrls: ['./manage-menu.component.scss'],
  animations: [
    trigger('manageMenuTransition', [
      transition(':enter', [
        style({width: '0', overflow: 'hidden'}),
        animate('0.3s ease', style({width: '320px', overflow: 'hidden'}))
      ]),
      transition(':leave', [
        style({width: '320px', overflow: 'hidden'}),
        animate('0.3s ease', style({width: '0', overflow: 'hidden'}))
      ])
    ])
  ]
})
export class ManageMenuComponent implements OnInit {

  @Input()
  public isOpen: boolean;

  @Output()
  // tslint:disable-next-line:no-any
  public onCloseDialogClicked: EventEmitter<any> = new EventEmitter<any>();

  public ApplicationBundleRoutingMode = ApplicationBundleRoutingMode;

  constructor(private router: Router, public animalsService: AnimalsService) { }

  public ngOnInit() {
  }

  public closeManageMenu() {
    this.onCloseDialogClicked.emit();
  }

  public async navigateTo(applicationBundleRoutingMode: ApplicationBundleRoutingMode) {
    this.closeManageMenu();
    this.animalsService.onCloseAddAnimalPopup.next();
    await this.router.navigate(['/manage'], { queryParams: { 'appBoundle': applicationBundleRoutingMode}});
  }

}
