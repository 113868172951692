import { Injectable } from '@angular/core';
import {HttpResponseResult, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from '../auth/auth.service';
import {TagsAssignmentResponse} from './model/tags-model';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  private static readonly UnassignedTagsRoute = '/rest/api/tags?tagType=scr&tagStatus=unassigned';

  constructor(private restApiService: RestApiService, private authService: AuthService) {

  }

  public getScrUnassignedTags(): Promise<HttpResponseResult<TagsAssignmentResponse>> {
    return this.restApiService.sendGetMethodAny<TagsAssignmentResponse>(TagsService.UnassignedTagsRoute, this.authService.currentUserAuthState.authDetails);
  }
}
