import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {
  AssignAnimalToTagDialogModel,
  AssignTagToAnimalDialogModel,
  CreateBatchEventDialogModel,
  CreateChangeGroupEventDialogModel,
  CreateGroupCardCullingEventDialogModel,
  CreateSendToSortingGateDialogModel
} from './model/dialog-actions-model';
import {AnimalsService} from '../animals/animals.service';
import {IDataGridViewContext} from '../model/common-model';
import {ITag} from '../tags/model/tags-model';
import {IGroupAnimalDetails} from '../groups/model/group.interface';
import {
  ChangeGroupCowEventMetadata,
  CreateChangeGroupEventDetails,
  CreateCullingEventDetails,
  EventTypeEnum
} from '../animals/model/animal-events';
import {AnimalEventsService} from '../animals/animal-events.service';
import {SortingGateService} from '../sorting-gate/sorting-gate.service';
import {
  AnimalSortingTaskModel,
  IScheduleRequestParams,
  ScheduleAnimalsRepeatModel, ScheduleSingleModel,
  SortingTaskSortType
} from '../sorting-gate/model/scheduler-model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DialogActionsService {

  public assignAnimalToTagDialogSubject: Subject<AssignAnimalToTagDialogModel> = new Subject<AssignAnimalToTagDialogModel>();

  public assignTagToAnimalDialogSubject: Subject<AssignTagToAnimalDialogModel> = new Subject<AssignTagToAnimalDialogModel>();

  public createBatchEventDialogSubject: Subject<CreateBatchEventDialogModel> = new Subject<CreateBatchEventDialogModel>();

  public changeGroupDialogSubject: Subject<CreateChangeGroupEventDialogModel> = new Subject<CreateChangeGroupEventDialogModel>();

  public sendToSortingGateDialogSubject: Subject<CreateSendToSortingGateDialogModel> = new Subject<CreateSendToSortingGateDialogModel>();

  constructor(private readonly animalsService:AnimalsService,
              private animalEventsService: AnimalEventsService,
              private sortingGateService: SortingGateService) {

  }

  public async openAssignAnimalToTag(actionsContext: IDataGridViewContext, tagNumber: number) {
    let assignAnimalToTagModel = new AssignAnimalToTagDialogModel();
    let animalsToAssignResponse = await this.animalsService.getAnimalToAssignTag();
    assignAnimalToTagModel.actionsContext = actionsContext;
    assignAnimalToTagModel.tagNumber = tagNumber;
    assignAnimalToTagModel.animals = animalsToAssignResponse.responseBody;
    setTimeout(() =>{
      this.assignAnimalToTagDialogSubject.next(assignAnimalToTagModel);
    })
  }

  public async openAssignTagToAnimal(actionsContext: IDataGridViewContext, animalId: number, unassignedTags: ITag[]) {
    let assignTagToAnimalModel = new AssignTagToAnimalDialogModel();
    assignTagToAnimalModel.animalId = animalId;
    assignTagToAnimalModel.actionsContext = actionsContext;
    assignTagToAnimalModel.unassignedTags = unassignedTags;
    setTimeout(() => {
      this.assignTagToAnimalDialogSubject.next(assignTagToAnimalModel);
    });
  }

  public async openCreateBatchEventDialog(createBatchEventDialogModel: CreateBatchEventDialogModel) {
    setTimeout(() => {
      this.createBatchEventDialogSubject.next(createBatchEventDialogModel);
    });
  }

  public async openChangeGroupEventDialog(animalId: number, actionContext: IDataGridViewContext) {
    let changeGroupEventDialogModel: CreateChangeGroupEventDialogModel = new CreateChangeGroupEventDialogModel();
    changeGroupEventDialogModel.eventDetails = new CreateChangeGroupEventDetails();
    let changeGroupEventMetadata
      = await this.animalEventsService.getEventTypeMetadata<ChangeGroupCowEventMetadata>(animalId, EventTypeEnum.ChangeGroup);
    changeGroupEventDialogModel.groups = changeGroupEventMetadata.groups;
    changeGroupEventDialogModel.animalId = animalId;
    changeGroupEventDialogModel.actionsContext = actionContext;
    setTimeout(() => {
      this.changeGroupDialogSubject.next(changeGroupEventDialogModel);
    })
  }

  public async openSendToSortingGateDialog(animalId: string) {
    const schedulerRequestParams: IScheduleRequestParams = {relativeWeek: 0};
    let sendToSortingGateDialogModel: CreateSendToSortingGateDialogModel = new CreateSendToSortingGateDialogModel();
    sendToSortingGateDialogModel.shifts = (await this.sortingGateService.getShifts()).responseBody.shifts;
    sendToSortingGateDialogModel.pens = await this.sortingGateService.getSortingPens();
    sendToSortingGateDialogModel.schedulerModel = (await this.sortingGateService.getScheduler(schedulerRequestParams));
    sendToSortingGateDialogModel.sortingTask = new AnimalSortingTaskModel();
    sendToSortingGateDialogModel.sortingTask.animals.push(animalId);
    sendToSortingGateDialogModel.sortingTask.schedule = new ScheduleSingleModel();
    sendToSortingGateDialogModel.concreteShifts = this.sortingGateService.generateConcreteShifts(sendToSortingGateDialogModel.shifts, true);
    sendToSortingGateDialogModel.activeShift = sendToSortingGateDialogModel.concreteShifts.find(value => value.isCurrent);
    (<ScheduleSingleModel>sendToSortingGateDialogModel.sortingTask.schedule).shiftId = sendToSortingGateDialogModel.activeShift.shift.shiftId;
    sendToSortingGateDialogModel.sortingTask.date = sendToSortingGateDialogModel.activeShift.startDateTime;
    if (sendToSortingGateDialogModel.pens.length === 1) {
      sendToSortingGateDialogModel.activePen = sendToSortingGateDialogModel.pens[0];
      sendToSortingGateDialogModel.sortingTask.penId = sendToSortingGateDialogModel.activePen.penId;
    }
    sendToSortingGateDialogModel.days = this.sortingGateService.createWeekDays(sendToSortingGateDialogModel.schedulerModel.currentDate);
    sendToSortingGateDialogModel.repeatDays = this.sortingGateService.createRepeatDays();
    sendToSortingGateDialogModel.activeRepeatDay = sendToSortingGateDialogModel.repeatDays[0];
    sendToSortingGateDialogModel.activeDay = sendToSortingGateDialogModel.days[0];
    setTimeout(() => {
      this.sendToSortingGateDialogSubject.next(sendToSortingGateDialogModel);
    })
  }

}
