import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataRow} from '../../../../services/model/common-model';

@Component({
  selector: 'description-cell-template',
  templateUrl: './description-cell-template.component.html',
  styleUrls: ['./description-cell-template.component.scss']
})
export class DescriptionCellTemplateComponent implements OnInit {

  @Input()
  public isRowExpanded: boolean;

  @Input()
  public row: DataRow;

  @Input()
  public canExpand: boolean;

  @Input()
  public rowShId: string;

  @Output()
  public toggleExpanded: EventEmitter<void> = new EventEmitter<void>()

  constructor(public elementRef: ElementRef) { }

  public ngOnInit() {
  }

  public onToggleExpanded() {
    let rowExpandedDetails = this.row.gridViewState.getRowExpandedState(this.row);
    this.row.gridViewState.clearExpandedRows();
    if(rowExpandedDetails == null) {
      this.row.gridViewState.setRowExpanded(this.row, {
        expandedHeight: this.elementRef.nativeElement.getBoundingClientRect().height
      });
    }
    this.toggleExpanded.emit();
  }
}
