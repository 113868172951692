<div class="date-time-wrapper">
  <popup-template [isOpen]="isOpen" (close)="onOutsideClick()"
                  [adjustPopupContentWidth]="false"
                  [popupContentHorizontalAlignment]="popupContentHorizontalAlignment">
    <ng-container header>
      <div class="date-time-picker-header" (click)="onHeaderClick()" [ngClass]="{ 'error': markAsError,
                                                                              'disabled': isDisabled}">
        <input class="value" [(ngModel)]="dateText"
               [ngStyle]="!isDisabled && { backgroundColor: datePickerBackground }"
               (keyup)="onSelectedDateTimeTextChange($event)"
               (keydown)="onSelectedDateTimeTextChange($event)"
               (paste)="onPaste($event)"
               [placeholder]="placeholderText"
               [attr.sh-id]="'date-time-picker-' + shIdPostfix"
               [disabled]="isDisabled"
               (focus)="onFocus()"
               (blur)="onBlur()"/>
        <div class="icons-wrapper">
          <div class="calendar-icon icon" [ngClass]="{disabled: isDisabled}">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 56 56">
              <path [attr.fill]="calendarIconColor"
                    fill-rule="nonzero"
                    d="M45 16.999H10.014v-1.896a1.521 1.521 0 0 1 1.513-1.525h6.094V9h3.03v4.578h13.716V9h3.04v4.578H43.5c.832.015 1.5.694 1.499 1.525v1.896zm0 2.682V45.48A1.517 1.517 0 0 1 43.491 47H11.527A1.524 1.524 0 0 1 10 45.485V19.658l35 .023zM19.143 37.898H13.05v6.085h6.09l.004-6.085zm0-7.595H13.05v6.08h6.09l.004-6.08zm0-7.605H13.05v6.085h6.09l.004-6.085zm7.616 15.2h-6.107v6.085h6.103l.004-6.085zm0-7.595h-6.107v6.08h6.103l.004-6.08zm0-7.605h-6.107v6.085h6.103l.004-6.085zm7.617 15.2h-6.099v6.085h6.09l.009-6.085zm0-7.595h-6.099v6.08h6.09l.009-6.08zm0-7.605h-6.099v6.085h6.09l.009-6.085zm7.607 15.2h-6.099v6.085h6.09l.009-6.085zm0-7.595h-6.099v6.08h6.09l.009-6.08zm0-7.605h-6.099v6.085h6.09l.009-6.085z"/>
            </svg>
          </div>
          <div class="remove-icon-wrapper icon" [ngClass]="{hidden:!isRemoveIconShown, disabled: isDisabled}">
            <div class="remove-icon-circle" (click)="clearDateValue($event)">
              <div class="remove-icon"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container popup-content>
      <div class="date-time-picker-content" [ngClass]="{'fold-up': isFoldDirectionUp}">
        <owl-date-time-inline [min]="selectedEpoch.minDate"
                              [max]="selectedEpoch.maxDate"
                              [ngModel]="selectedEpochDate"
                              [lang]="translationService.selectedLanguage"
                              (ngModelChange)="onDateChanged($event)"
                              [pickerType]="'calendar'"></owl-date-time-inline>
      </div>
    </ng-container>
  </popup-template>
</div>
