import {EntityType} from '../search.service';

export enum OperationType {
  Dairy = 'Dairy',
  BeefBreeding = 'BeefBreeding',
  BeefFinishing = 'BeefFinishing'
}

export class SearchEntry {
  public entityAlias: string;
  public entityId: number;
  public entityName: string;
  public entityRfidTagNumber: string;
  public entityType: EntityType;
  public isLocalization?: boolean;
  public operationType: OperationType;
}

export class SearchEntitiesResult {

  public offset: number;

  public limit: number;

  public total: number;

  public result: SearchEntry[];
}
