
<div class="finishing-page">
  <div class="double-field-div">
    <div class="input-field">
      <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER'">
        {{ 'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER' | translate }}
      </div>
      <div class="value">
        <input class="input-class colored"
               (ngModelChange)="clearErrors()"
               [ngClass]="{ error: animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.animalName)}"
               type="text"
               [(ngModel)]="finishingAddMaleModel.animalNumber"
               [attr.sh-id]="'finishing_add_male_animal_number_input_finishing_tab'"
               [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER'"
               placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER' | translate }}" readonly/>
      </div>
    </div>
    <div class="input-field">
      <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'">
        {{ 'MANAGE.COWS.ACTIVE.WIZARD.GROUP' | translate }}
      </div>
      <div class="value">
        <regular-dropdown-list class="dropdown-list"
                               [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.group)"
                               [shIdPostfix]="'finishing_add_male_group_finishing_tab'"
                               [items]="groupsDropdownListItems"
                               placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.GROUP' | translate }}"
                               [allowClear]="true"
                               [selectedValue]="finishingAddMaleModel.group"
                               (onItemSelected)="onGroupSelected($event)"></regular-dropdown-list>
      </div>
    </div>
  </div>
  <div class="seperator"></div>
  <div class="fields-and-errors-div">
    <div class="single-field-div">
      <div class="input-field paddingTop">
        <div class="title" [attr.translation-id]="'MANAGE.FINISHING.ACTIVE.WIZARD.CASTRATION_DATE'">
          {{ 'MANAGE.FINISHING.ACTIVE.WIZARD.CASTRATION_DATE' | translate }}
        </div>
        <div class="value">
          <date-picker [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.castration)"
                       [calendarIconColor]="'#3B4570'"
                       [attr.sh-id]="'finishing_add_male_castration_finishing_tab'"
                       [selectedEpoch]="finishingAddMaleModel.castration"
                       [shIdPostfix]="'finishing_add_male_castration_finishing_tab_date_picker'"
                       (epochChanged)="onCastrationDateChanged($event)"></date-picker>
        </div>
      </div>
      <div class="input-field paddingTop">
        <div class="title" [attr.translation-id]="'MANAGE.FINISHING.ACTIVE.WIZARD.CASTRATION_TIME'">
          {{ 'MANAGE.FINISHING.ACTIVE.WIZARD.CASTRATION_TIME' | translate }}
        </div>
        <div class="value">
          <time-picker [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.castration)"
                       [themeColor]="'#3B4570'"
                       [attr.sh-id]="'finishing_add_male_castration_finishing_tab'"
                       [selectedEpoch]="finishingAddMaleModel.castration"
                       [shIdPostfix]="'finishing_add_male_castration_finishing_tab_date_picker'"
                       (epochChanged)="onCastrationDateChanged($event)"></time-picker>
        </div>
      </div>
      <div class="input-field paddingTop">
        <div class="title" [attr.translation-id]="'MANAGE.FINISHING.ACTIVE.WIZARD.BACKGROUND_START_DATE'">
          {{ 'MANAGE.FINISHING.ACTIVE.WIZARD.BACKGROUND_START_DATE' | translate }}
        </div>
        <div class="value">
          <date-picker [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.backgroundStart)"
                       [calendarIconColor]="'#3B4570'"
                       [attr.sh-id]="'finishing_add_male_background_start_finishing_tab'"
                       [selectedEpoch]="finishingAddMaleModel.backgroundStartDate"
                       [shIdPostfix]="'finishing_add_male_background_start_finishing_tab_date_picker'"
                       (epochChanged)="onBackgroundStartChanged($event)"></date-picker>
        </div>
      </div>
    </div>
    <div class="single-field-div">
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.FINISHING.ACTIVE.WIZARD.BACKGROUND_START_TIME'">
          {{ 'MANAGE.FINISHING.ACTIVE.WIZARD.BACKGROUND_START_TIME' | translate }}
        </div>
        <div class="value">
          <time-picker [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.backgroundStart)"
                       [themeColor]="'#3B4570'"
                       [attr.sh-id]="'finishing_add_male_background_start_finishing_tab'"
                       [selectedEpoch]="finishingAddMaleModel.backgroundStartDate"
                       [shIdPostfix]="'finishing_add_male_background_start_finishing_tab_date_picker'"
                       (epochChanged)="onBackgroundStartChanged($event)"></time-picker>
        </div>
      </div>
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.FINISHING.ACTIVE.WIZARD.FINISHING_START_DATE'">
          {{ 'MANAGE.FINISHING.ACTIVE.WIZARD.FINISHING_START_DATE' | translate }}
        </div>
        <div class="value">
          <date-picker [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.finishingStart)"
                       [calendarIconColor]="'#3B4570'"
                       [attr.sh-id]="'finishing_add_male_finishing_start_finishing_tab'"
                       [selectedEpoch]="finishingAddMaleModel.finishingStart"
                       [shIdPostfix]="'finishing_add_male_finishing_start_finishing_tab_date_picker'"
                       (epochChanged)="onFinishingStartDateChanged($event)"></date-picker>
        </div>
      </div>
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.FINISHING.ACTIVE.WIZARD.FINISHING_START_TIME'">
          {{ 'MANAGE.FINISHING.ACTIVE.WIZARD.FINISHING_START_TIME' | translate }}
        </div>
        <div class="value">
          <time-picker [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.finishingStart)"
                       [themeColor]="'#3B4570'"
                       [attr.sh-id]="'finishing_add_male_finishing_start_finishing_tab'"
                       [selectedEpoch]="finishingAddMaleModel.finishingStart"
                       [shIdPostfix]="'finishing_add_male_finishing_start_finishing_tab_date_picker'"
                       (epochChanged)="onFinishingStartDateChanged($event)"></time-picker>
        </div>
      </div>
    </div>
  </div>
</div>
