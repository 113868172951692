<div class="add-note-wrapper">
  <div class="add-note-btn" *ngIf="hasPermissionsForNotes" [ngClass]="{ addNoteOpen: editorMode, breeding: (applicationBundleRoutingMode === ApplicationBundleRoutingMode.Breeding &&
                                                                                   !editorMode),
                                                                        finishing: (applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing &&
                                                                        !editorMode)  }" [attr.sh-id]="'add-note-button'" (click)="toggleNote()" saveDataWarningInject>
    <div class="add-icon" *ngIf="!editorMode"></div>
    <div class="remove-icon" *ngIf="editorMode"></div>
  </div>
</div>

<corner-container *ngIf="editorMode"
                  [attr.sh-id]="'add-note-dialog'"
                  [cornerColor]="isSaveButtonActive ? CornerColor.Blue : CornerColor.BlueDisabled"
                  (onCornerButtonClick)="saveNote()"
                  [footerHeight]="50"
                  [bodyBackground]="CornerContainerBodyBackground.Blue">
  <ng-container container-body>
    <div class="add-note-container" saveDataEditSection [editAreaName]="NoteEditorAreaName">
      <div class="add-note-upper-section">
        <div class="form-field note-name">
          <div class="title-div"
               *ngIf="editorMode"
               [attr.translation-id]="translationId">
            {{  translationId | translate }}
          </div>
          <div class="value-div">
            <regular-auto-complete
              #noteNameAutoComplete
              [hasIcon]="true"
              [selectOnEnter]="true"
              [hasPermanentRemoveIcon]="false"
              [isFieldError]="isFieldError(AddNoteFields.noteName)"
              [maxInputLength]="50"
              [shIdPostfix]="'note-name'"
              [colorScheme]="AutoCompleteColorScheme.WhiteBlue"
              [placeholder]="'Note Name...'"
              [isAllowUnmatchedSelection]="true"
              [caseInvariant]="true"
              [items]="notesList"
              [maxResults]="6"
              (editingCompleted)="onNoteSelected($event)"
              [searchQuery]="editNoteModel.noteName"
              (searchQueryChanged)="onNoteSelected($event)"
            ></regular-auto-complete>
          </div>
        </div>
        <div class="seperator"></div>
        <div class="min-limit-div">
          <div class="form-field effective-groups">
            <div class="title-div"
                 [attr.translation-id]="'NOTES.COLUMN.GROUPS'">
              {{ 'NOTES.COLUMN.GROUPS' | translate }}
            </div>
            <div class="value-div">
              <regular-auto-complete
                #effectiveGroupsAutoComplete
                [hasIcon]="true"
                [shIdPostfix]="'effective-groups'"
                [colorScheme]="AutoCompleteColorScheme.WhiteBlueWithBlackPlaceholder"
                [isColorPlaceholderBlack]="isPlaceholderShouldBeBlack()"
                [placeholder]="isAllGroupsShown()"
                [isAllowUnmatchedSelection]="true"
                [caseInvariant]="true"
                [items]="groupsList"
                [maxResults]="6"
                (editingCompleted)="onGroupSelected($event)"
                (searchQueryChanged)="clearErrors()"
                [searchQuery]="noteSearchQuery"
              ></regular-auto-complete>
            </div>
          </div>
          <div class="form-field date-time">
            <div class="title-div"
                 [attr.translation-id]="'NOTES.COLUMN.DATE'">
              {{ 'NOTES.COLUMN.DATE' | translate }}
            </div>
            <div class="value-div">
              <date-picker [calendarIconColor]="pickerColor.blue"
                           [shownRemovableIcon]="false"
                           [isFieldError]="isFieldError(AddNoteFields.date)"
                           [selectedEpoch]="editNoteModel.date"
                           [shIdPostfix]="'add-note-date'"
                           (epochChanged)="onEpochChanged($event)"></date-picker>
            </div>
          </div>
          <div class="form-field date-time">
            <div class="title-div"
                 [attr.translation-id]="'REPORTS.GRID.TIME'">
              {{ 'REPORTS.GRID.TIME' | translate }}
            </div>
            <div class="value-div">
              <time-picker [selectedEpoch]="editNoteModel.date"
                           [shownRemovableIcon]="false"
                           [isFieldError]="isFieldError(AddNoteFields.date)"
                           [themeColor]="pickerColor.blue"
                           [shIdPostfix]="'add-note-time'"
                           (epochChanged)="onEpochChanged($event)"></time-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="add-note-lower-section">
        <chip-list class="chips-wrapper">
          <ng-container *ngFor="let selectedGroup of editNoteModel.selectedGroups">
            <chips-item [chipBackground]="ChipsItemBackgroundColor.white" [chipItem]="selectedGroup" (onRemoveItemClicked)="removeSelectedGroup(selectedGroup)"></chips-item>
          </ng-container>
        </chip-list>
      </div>
    </div>
  </ng-container>
  <ng-container container-footer>
    <!-- Error container to display all errors for form -->
    <div class="error-div" saveDataWarningIgnore>
      <div class="error-text" *ngIf="Error" [attr.translation-id]="'SYSTEM.MANAGEMENT.ERROR_BOLD: MANAGE.NOTES.WIZARD.ERROR.' + Error.key" [attr.sh-id]="'add-note-error'">
        <b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}:</b> {{ 'MANAGE.NOTES.WIZARD.ERROR.' + Error.key | translate }}
      </div>
    </div>
    <!-- Buttons Container to contain all the actions of the container -->
    <div class="container-buttons"
         (click)="saveNote()"
         [ngClass]="{ disabled: !isSaveButtonActive }"
         [attr.sh-id]="'create-new-note-button'"
         saveDataWarningIgnore>
      <div class="check-button">
        <div class="check-icon"></div>
      </div>
    </div>
  </ng-container>
</corner-container>
