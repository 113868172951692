<corner-container *ngIf="isOpen"
                  [attr.sh-id]="'add-group-dialog'"
                  [cornerColor]="isSaveButtonActive ? CornerColor.Blue : CornerColor.BlueDisabled"
                  (onCornerButtonClick)="saveGroup()"
                  [footerHeight]="50"
                  [bodyBackground]="CornerContainerBodyBackground.Blue">
  <ng-container container-body>
    <div class="add-group-container">
      <div class="add-new-group-field-div">
        <div class="title-div"
             [attr.translation-id]="'MANAGE.GROUPS.HEADER.ADD_NEW_GROUP'"
             *ngIf="!groupId">
          {{ 'MANAGE.GROUPS.HEADER.ADD_NEW_GROUP' | translate }}
        </div>
        <div class="title-div"
             [attr.translation-id]="'MANAGE.GROUPS.HEADER.EDIT_GROUP'"
             *ngIf="groupId">
          {{ 'MANAGE.GROUPS.HEADER.EDIT_GROUP' | translate }}
        </div>
        <div class="value-div">
          <input class="input-class"
                 sh-id="input-new-group-name"
                 [ngClass]="{ 'error': hasErrorForField(groupFields.groupName) }"
                 type="text"
                 [attr.translation-id]="'MANAGE.GROUPS.PLACEHOLDER.GROUP_NAME'"
                 placeholder="{{ 'MANAGE.GROUPS.PLACEHOLDER.GROUP_NAME' | translate }}"
                 [maxLength]="20"
                 (ngModelChange)="clearErrors()"
                 [(ngModel)]="addGroupModel.groupName" />
        </div>
      </div>
      <div class="line-seperator"></div>
      <div class="add-group-fields-and-errors-wrapper">
        <div class="field-div-wrapper">
          <div class="field-div">
            <div class="title-div" [attr.translation-id]="'MANAGE.GROUPS.HEADER.GROUP_NUMBER'">
              {{ 'MANAGE.GROUPS.HEADER.GROUP_NUMBER' | translate }}
            </div>
            <div class="value-div">
              <numeric-input class="input-numeric-class"
                             [isFieldError]="hasErrorForField(groupFields.groupNumber)"
                             [isNullable]="true"
                             [min]="0"
                             [max]="999999999"
                             [selectedValue]="addGroupModel.groupNumber"
                             [shId]="'input-new-group-number'"
                             (onChangeSelectedValue)="onGroupNumberChanged($event)"
                             [isSaveOnFocusOut]="true"
                             [hideValueChangeArrows]="true"
                             [attr.translation-id]="'MANAGE.GROUPS.PLACEHOLDER.GROUP_NUMBER'"
                             [placeholder]="'MANAGE.GROUPS.PLACEHOLDER.GROUP_NUMBER'"></numeric-input>
            </div>
          </div>
          <div class="field-div">
            <div class="title-div" [attr.translation-id]="'MANAGE.GROUPS.HEADER.BRANCH'">
              {{ 'MANAGE.GROUPS.HEADER.BRANCH' | translate }}
            </div>
            <div class="value-div">
              <regular-dropdown-list class="dropdown-list"
                                     [isFieldError]="hasErrorForField(groupFields.branchName)"
                                     [autoSelectionMode]="dropdownListAutoSelectionMode.Single"
                                     [shIdPostfix]="'Group_Branch'"
                                     [items]="groupBranchesDropdownListItems"
                                     [placeholder]="'MANAGE.GROUPS.HEADER.BRANCH'"
                                     [allowClear]="true"
                                     [selectedValue]="initSelectValue"
                                     [colorScheme]="DropdownColorScheme.whiteBlue"
                                     (onItemSelected)="onGroupBranchSelected($event)"></regular-dropdown-list>
            </div>
          </div>
          <div class="field-div heat-index-box">
            <div class="title-div-wrapper" [attr.translation-id]="'MANAGE.GROUPS.HEADER.HEAT_INDEX_THRESHOLD'">
              <div class="title-div">
                {{ 'MANAGE.GROUPS.HEADER.HEAT_INDEX_THRESHOLD' | translate }}
              </div>
              <div class="button-div" (click)="resetIndexThreshold()"></div>
            </div>
            <div class="value-div">
              <numeric-input [isFieldError]="hasErrorForField(groupFields.heatIndexThreshold)"
                             [selectedValue]="addGroupModel.heatIndexThreshold"
                             [min]="1"
                             [max]="99"
                             (onChangeSelectedValue)="onHeatIndexThresholdChanged($event)"
                             [shId]="'input-new-group-health-index'"
                             [isDisabled]="false"></numeric-input>
            </div>
          </div>
        </div>
        <div class="errors-div">
          <div class="error-text" *ngIf="Error" [attr.translation-id]="'SYSTEM.MANAGEMENT.ERROR_BOLD: MANAGE.GROUPS.WIZARD.ERROR.' + Error.key">
            <b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}:</b> {{ 'MANAGE.GROUPS.WIZARD.ERROR.' + Error.key | translate }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container container-footer>
    <div class="lining-pad"></div>
    <!-- Buttons Container to contain all the actions of the container -->
    <div class="container-buttons" [ngClass]="{ disabled: !isSaveButtonActive }">
      <div class="check-button" (click)="saveGroup()" [attr.sh-id]="'create-new-group-button'">
        <div class="check-icon"></div>
      </div>
    </div>
  </ng-container>
</corner-container>
