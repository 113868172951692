<ng-container *ngIf="farmStatistics">
  <div class="statistics-row" *ngFor="let rowStatisticKey of ObjectKeys(farmStatistics)">
    <div class="totals" [attr.sh-id]="'fs-totals-' + rowStatisticKey">
      <div class="value">{{ farmStatistics[rowStatisticKey].total.count }}</div>
      <div class="title" [attr.translation-id]="'DASHBOARD.STATISTICS.' + farmStatistics[rowStatisticKey].total.label">{{ 'DASHBOARD.STATISTICS.' + farmStatistics[rowStatisticKey].total.label | translate}}</div>
    </div>
    <div class="data-container">
      <div class="data-group" *ngFor="let subGroup of farmStatistics[rowStatisticKey].subGroups">
        <div class="data-bar">
          <div class="bard-section"
               *ngFor="let group of subGroup"
               [ngStyle]='{background: group.color, width: (calculatePercentage(group.count , farmStatistics[rowStatisticKey].total.count, true)) + "%"}'></div>
        </div>
        <div class="separator"></div>
        <div class="data-labels">
          <div class="data-label" *ngFor="let group of subGroup">
            <span class="percentage" [ngStyle]='{color: group.color}'>{{ calculatePercentage(group.count , farmStatistics[rowStatisticKey].total.count) | number:'1.0-0'}} %</span>
            <div class="separator"></div>
            <span class="value">{{ group.count }}</span>
            <span class="title" [attr.translation-id]="'DASHBOARD.STATISTICS.' + group.label">{{ 'DASHBOARD.STATISTICS.' + group.label | translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
