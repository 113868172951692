<div outsideClickHandler (onOutsideClick)="close()" *ngIf="rowActions != null && rowActions.length > 0">
  <div class="actions-cell" (click)="open()" [attr.sh-id]="'open_row_actions' + shId">
    <span class="actions-icon" ></span>
  </div>
  <div class="wrapper-actions" *ngIf="isOpen" [attr.sh-id]="'row_actions_popup_' + rowActions[0].shIdRowIdentifier" @toggelOpenRowAction>
    <i class="icon-close" (click)="close()"></i>
    <div class="wr-actions">
      <ul class="wr-actions__list">
        <li class="wr-actions__item" *ngFor="let rowAction of rowActions" [attr.sh-id]="'row_action_' + rowAction.action + '_' + rowAction.shIdRowIdentifier" (click)="rowActionClick($event, rowAction)">
          <a class="wr-actions__link">
            <span *ngIf="rowAction.iconCssClass" [attr.class]="'row-action-icon row-action-icon-' + rowAction.iconCssClass"></span>
            <span [attr.translation-id]="rowAction.displayValue">{{rowAction.displayValue | translate}}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
