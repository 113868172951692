<div saveDataWarningIgnore class="testing-layout" *ngIf="isVisible">
  <div class="navigation">
    <a (click)="selectSection(TestingLayoutSection.HttpManagement)" sh-id="mock_http">Mock HTTP</a>
    <a (click)="selectSection(TestingLayoutSection.DashboardReports)" sh-id="dashboard_reports">Dashboard Reports</a>
    <a (click)="selectSection(TestingLayoutSection.PrintToExport)" sh-id="print_to_export">Print/Export</a>
    <a (click)="selectSection(TestingLayoutSection.Translations)" sh-id="translations">Translations</a>
    <a (click)="selectSection(TestingLayoutSection.SystemState)" sh-id="system_state">System State</a>
    <a (click)="selectSection(TestingLayoutSection.NotificationDialogs)" sh-id="notification_dialogs">Notification dialogs</a>
  </div>
  <mock-http-management *ngIf="selectedSection == TestingLayoutSection.HttpManagement"></mock-http-management>
  <mock-dashboard-reports *ngIf="selectedSection == TestingLayoutSection.DashboardReports"></mock-dashboard-reports>
  <mock-print-export *ngIf="selectedSection == TestingLayoutSection.PrintToExport"></mock-print-export>
  <mock-translations *ngIf="selectedSection == TestingLayoutSection.Translations"></mock-translations>
  <mock-system-state *ngIf="selectedSection == TestingLayoutSection.SystemState"></mock-system-state>
  <mock-notification-dialogs *ngIf="selectedSection == TestingLayoutSection.NotificationDialogs"></mock-notification-dialogs>
</div>
<button saveDataWarningIgnore (click)="isVisible = !isVisible" sh-id="navigate_mock_webapi" class="mock-http-button">Mock Web API</button>
