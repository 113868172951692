import {Component, Input, OnInit} from '@angular/core';
import {FarmMode} from '../../../services/config/model/server-config';
import {ConfigService} from '../../../services/config/config.service';

export enum AnimalLifecycleStatusType {
  Calf = 'Calf',
  BullCalf = 'BullCalf',
  Heifer = 'Heifer',
  HeiferReady = 'HeiferReady',
  HeiferInseminated = 'HeiferInseminated',
  HeiferOpen = 'HeiferOpen',
  HeiferPregnant = 'HeiferPregnant',
  Ready = 'Ready',
  Inseminated = 'Inseminated',
  Open = 'Open',
  Pregnant = 'Pregnant',
  Dry = 'Dry',
  Fresh = 'Fresh',
  Steer = 'Steer',
  FinishingBullCalf = 'FinishingBullCalf'
}

@Component({
  selector: 'animal-lifecycle-status-icon',
  templateUrl: './animal-lifecycle-status-icon.component.html',
  styleUrls: ['./animal-lifecycle-status-icon.component.scss']
})
export class AnimalLifecycleStatusIconComponent implements OnInit {

  @Input()
  public status:AnimalLifecycleStatusType;

  constructor(private readonly configService: ConfigService) { }

  public ngOnInit() {
  }

  public get farmMode() : FarmMode {
    if(this.configService.serverConfig) {
      return this.configService.serverConfig.farmMode;
    } else {
      return FarmMode.Unknown;
    }
  }
}
