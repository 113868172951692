import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslationService} from '../../../services/translations/translation.service';
import {RestApiResult} from '../../../services/rest-api/models/rest-api-result';
import {FarmMode, FinishingApplicationBundle, ServerConfig} from '../../../services/config/model/server-config';
import {MockHttpService} from '../../services/mock-http.service';

export class TranslationValidationItem {
  public isValid: boolean;
  public description: string;
  public detailsItems: string[] = [];
}

export enum ValidationSummaryType {
  Loading = 'Loading',
  Valid = 'Valid',
  Invalid = 'Invalid'
}

@Component({
  selector: 'mock-translations',
  templateUrl: './mock-translations.component.html',
  styleUrls: ['./mock-translations.component.scss']
})
export class MockTranslationsComponent implements OnInit {

  private static readonly translationsPath: string = `assets/i18n/`;

  private static readonly langsFilePath: string = MockTranslationsComponent.translationsPath + 'langs.json';

  private languages: string[] = [];

  public validationItems: TranslationValidationItem[] = [];

  public summary: ValidationSummaryType = ValidationSummaryType.Loading;

  ValidationSummaryType = ValidationSummaryType;

  constructor(private readonly httpClient:HttpClient,
              private readonly mockHttpService:MockHttpService,
              private readonly translationService: TranslationService) { }

  async ngOnInit() {
    await this.loadLanguages();
    await this.performValidations();
  }

  private async loadLanguages() {
    this.languages = await this.httpClient.get<string[]>(MockTranslationsComponent.langsFilePath).toPromise();
  }

  private async performValidations() {
    this.validationItems = [];
    let validatedLanguageJsonStructure = await this.validateLanguageJsonStructure();
    this.validationItems.push(validatedLanguageJsonStructure);
    if(validatedLanguageJsonStructure.isValid) {
      this.validationItems.push(await this.validateChineseCopyright());
      this.validationItems.push(await this.validateAnimalDetailsBeefEnUk());
    }
    this.validationItems.push(await this.validateThaiLanguageNameQuestionMarks());
    if(this.validationItems.some(value => !value.isValid)){
      this.summary = ValidationSummaryType.Invalid;
    } else {
      this.summary = ValidationSummaryType.Valid;
    }
  }

  private async validateLanguageJsonStructure() : Promise<TranslationValidationItem> {
    let validationItem = new TranslationValidationItem();
    validationItem.description = 'Translation language JSON structure'
    validationItem.isValid = true;
    for (let lang of this.languages) {
      try {
        let translationDairy = await this.httpClient.get<any>(MockTranslationsComponent.translationsPath + 'dairy/' + lang + '.json').toPromise();
        if(translationDairy == null) {
          validationItem.detailsItems.push('Invalid JSON for Dairy ' + lang);
          validationItem.isValid = false;
        }
      } catch (e) {
        validationItem.detailsItems.push('Invalid JSON for Dairy ' + lang + ' ' + e);
        validationItem.isValid = false;
      }

      try {
        let translationBeef = await this.httpClient.get<any>(MockTranslationsComponent.translationsPath + 'beef/' + lang + '.json').toPromise();
        if(translationBeef == null) {
          validationItem.detailsItems.push('Invalid JSON for Beef ' + lang);
          validationItem.isValid = false;
        }
      } catch (e) {
        validationItem.detailsItems.push('Invalid JSON for Beef ' + lang + ' ' + e);
        validationItem.isValid = false;
      }
    }
    return validationItem;
  }

  private async validateChineseCopyright() : Promise<TranslationValidationItem> {
    let validationItem = new TranslationValidationItem();
    validationItem.description = 'Chinese copyright translations';
    validationItem.isValid = true;
    for (let lang of this.languages) {
      let translationDairy = await this.httpClient.get<any>(MockTranslationsComponent.translationsPath + 'dairy/' + lang + '.json').toPromise();
      if(translationDairy['FOOTER'] == null) {

      } else {
        if (translationDairy['FOOTER']['CHINA_COPYRIGHT'] == null ||
          translationDairy['FOOTER']['CHINA_COPYRIGHT'] === 'Invisible' ||
          translationDairy['FOOTER']['CHINA_COPYRIGHT'] === '') {

        } else {
          if (lang != 'zh-chs' &&
            lang != 'zh-cht') {
            validationItem.detailsItems.push('Incorrect translation for Dairy ' + lang + ', ' + translationDairy['FOOTER']['CHINA_COPYRIGHT']);
            validationItem.isValid = false;
          }
        }
      }

      let translationBeef = await this.httpClient.get<any>(MockTranslationsComponent.translationsPath + 'beef/' + lang + '.json').toPromise();
      if(translationBeef['FOOTER'] == null) {

      } else {
        if (translationBeef['FOOTER']['CHINA_COPYRIGHT'] == null ||
          translationBeef['FOOTER']['CHINA_COPYRIGHT'] === 'Invisible' ||
          translationBeef['FOOTER']['CHINA_COPYRIGHT'] === '') {

        } else {
          if (lang != 'zh-chs' &&
              lang != 'zh-cht') {
            validationItem.detailsItems.push('Incorrect translation for Beef ' + lang + ', ' + translationBeef['FOOTER']['CHINA_COPYRIGHT']);
            validationItem.isValid = false;
          }
        }
      }
    }
    return validationItem;
  }

  private async validateThaiLanguageNameQuestionMarks() : Promise<TranslationValidationItem> {
    let validationItem = new TranslationValidationItem();
    validationItem.description = 'Bug 50001: Name of the Thai language not displayed and the interface language don\'t change to Thai.';
    validationItem.isValid = true;
    let translationDairy = await this.httpClient.get<any>(MockTranslationsComponent.translationsPath + 'dairy/en-us.json').toPromise();
    if(translationDairy['LOCALES']['th-th'] != 'ภาษาไทย') {
      validationItem.detailsItems.push('Invalid translation for dairy ' + translationDairy['LOCALES']['th-th']);
      validationItem.isValid = false;
    }
    let translationBeef = await this.httpClient.get<any>(MockTranslationsComponent.translationsPath + 'beef/en-us.json').toPromise();
    if(translationDairy['LOCALES']['th-th'] != 'ภาษาไทย') {
      validationItem.detailsItems.push('Invalid translation for beef ' + translationDairy['LOCALES']['th-th']);
      validationItem.isValid = false;
    }
    return validationItem;
  }
  
  private async validateAnimalDetailsBeefEnUk() : Promise<TranslationValidationItem> {
    let validationItem = new TranslationValidationItem();
    validationItem.description = 'Bug 56410: Animal Card - Beef - Broken translation for EN-EU language';
    validationItem.isValid = true;
    let configTemplate = await this.mockHttpService.getTemplate('/rest/api/v3/auth/configuration');
    let configurationTemplate: RestApiResult<ServerConfig> = configTemplate.messages[0].responseBody;
    configurationTemplate.result.farmMode = FarmMode.Beef;
    configurationTemplate.result.bizModeParameters.finishingApplicationBundle = FinishingApplicationBundle.None;
    await this.translationService.selectLanguage('en-gb', configurationTemplate.result,false);
    let translation = this.translationService.resolveMissingTranslation('ANIMAL.DETAILS.INFO.SECTION.AnimalId');
    if(translation == null ||
       translation.length == 0 ||
       translation == 'ANIMAL.DETAILS.INFO.SECTION.AnimalId') {
      validationItem.detailsItems.push('unable to resolve translation key ANIMAL.DETAILS.INFO.SECTION.AnimalId');
      validationItem.isValid = false;
    }
    return validationItem;
  }
}
