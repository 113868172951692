import { Component, OnInit } from '@angular/core';
import {TranslationPickerThemeEnum} from "../../common/components/translation/translation-picker/translation-picker.component";
import {TranslationService} from '../../services/translations/translation.service';

@Component({
  selector: 'layout-footer',
  templateUrl: './layout-footer.component.html',
  styleUrls: ['./layout-footer.component.scss']
})
export class FooterComponent implements OnInit {

  public TranslationPickerThemeEnum = TranslationPickerThemeEnum;

  constructor(private translationService: TranslationService) { }

  public ngOnInit() {
  }

  public get copyrightText(){
    let copyrightText = this.translationService.translate('FOOTER.CHINA_COPYRIGHT');
    if(copyrightText == 'Invisible' ||
      copyrightText == 'FOOTER.CHINA_COPYRIGHT'){
      copyrightText = '';
    }
    return copyrightText;
  }
}
