import {Component, ElementRef, OnInit} from '@angular/core';
import {DropdownListItem} from '../../../../../../common/components/dropdown-list/dropdown-list-model';
import * as moment from 'moment';
import {DateTimeFormatEnum} from '../../../../../../common/pipes/epoch-date-time.pipe';
import {
  AddAnimalFieldsErrorsEnum,
  IAddMaleFinishingRequest,
  IErrors,
  IGroupItem,
  IMaleMetadata,
  ISCRTagItem,
} from '../../../../../../services/animals/model/animal.model';
import {AnimalsService} from '../../../../../../services/animals/animals.service';
import {UtilsService, WeightUnitType} from '../../../../../../services/utils/utils.service';
import {InputFilterMode} from '../../../../../../common/directives/input-filter.directive';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../../../../common/components/calendar/model/epoch-state-model';
import {LoadingIconService} from "../../../../../../services/loading-icon/loading-icon.service";
import { CornerColor, CornerContainerBodyBackground } from 'src/app/common/components/containers/corner-container/corner-container.component';

export class FinishingAddMaleModel {

  public animalNumber: string;

  public group: DropdownListItem;

  public registrationNumber: string;

  // tslint:disable-next-line:no-any
  public EID: any;

  // tslint:disable-next-line:no-any
  public tagNumber: any;

  public entryDate: EpochStateModel;

  public birthDate: EpochStateModel;

  public weighing: EpochStateModel;

  public weightValueKG: string;

  public weightValueLBS: string;

  public castration: EpochStateModel;

  public backgroundStartDate: EpochStateModel;

  public finishingStart: EpochStateModel;

  constructor() {
    this.animalNumber = null;
    this.group = null;
    this.registrationNumber = null;
    this.EID = null;
    this.tagNumber = null;
    this.entryDate =  new EpochStateModel(EpochStateModelMinMaxMode.Date, moment().unix());
    this.birthDate = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.weighing = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.weighing.epochTime = moment().unix();
    this.weightValueKG = null;
    this.weightValueLBS = null;
    this.castration = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.castration.epochTime = moment().unix();
    this.backgroundStartDate = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.backgroundStartDate.epochTime = moment().unix();
    this.finishingStart = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.finishingStart.epochTime = moment().unix();
    this.updateDatesRanges();
  }

  public clear() {
    this.animalNumber = null;
    this.group = null;
    this.registrationNumber = null;
    this.EID = null;
    this.tagNumber = null;
    this.entryDate =  new EpochStateModel(EpochStateModelMinMaxMode.Date, moment().unix());
    this.birthDate = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.weighing = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.weighing.epochTime = moment().unix();
    this.weightValueKG = null;
    this.weightValueLBS = null;
    this.castration = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.castration.epochTime = moment().unix();
    this.backgroundStartDate = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.backgroundStartDate.epochTime = moment().unix();
    this.finishingStart = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.finishingStart.epochTime = moment().unix();
    this.updateDatesRanges();
  }

  public updateDatesRanges() {
    let maxDate = moment().unix();

    this.birthDate.max = maxDate;

    this.entryDate.min = this.birthDate.epochDate;
    this.entryDate.max = maxDate;
    this.entryDate = this.entryDate.clone();

    this.weighing.min = this.birthDate.epochDate;
    this.weighing.max = maxDate;
    this.weighing = this.weighing.clone();

    this.backgroundStartDate.min = this.birthDate.epochDate;
    this.backgroundStartDate.max = this.finishingStart.epoch ? this.finishingStart.epoch : maxDate;
    this.backgroundStartDate = this.backgroundStartDate.clone();

    this.finishingStart.min = this.getMinDateForStartFinishing();
    this.finishingStart.max = maxDate;
    this.finishingStart = this.finishingStart.clone();

    this.castration.min = this.birthDate.epochDate;
    this.castration.max = maxDate;
    this.castration = this.castration.clone();
  }

  private getMinDateForStartFinishing() : number {
    if (this.backgroundStartDate.epoch) {
      return this.backgroundStartDate.epoch;
    } else if (this.birthDate.epochDate) {
      return this.birthDate.epochDate;
    } else {
      return null;
    }
  }
}

export enum AddMaleFinishingSteps {
  Entry = 1,
  Farm = 2,
  Finishing = 3
}


@Component({
  selector: 'add-male-finishing',
  templateUrl: './add-male-finishing.component.html',
  styleUrls: ['./add-male-finishing.component.scss']
})
export class AddMaleFinishingComponent implements OnInit {

  public finishingStep: AddMaleFinishingSteps = AddMaleFinishingSteps.Entry;

  public addMaleFinishingMetadata: IMaleMetadata;

  public finishingAddMaleModel: FinishingAddMaleModel = new FinishingAddMaleModel();

  public AddMaleFinishingSteps = AddMaleFinishingSteps;

  public errors: IErrors[] = [];

  public groupsDropdownListItems: DropdownListItem[] = [];

  public rfidsTagsNumbers: string[] = [];

  public scrTagsNumbers: string[] = [];

  public dateTimeFormatEnum = DateTimeFormatEnum;

  public AddAnimalFieldsErrorsEnum = AddAnimalFieldsErrorsEnum;

  public InputFilterMode = InputFilterMode;

  public CornerColor = CornerColor;

  public CornerContainerBodyBackground = CornerContainerBodyBackground;

  constructor(public animalsService: AnimalsService,
              private readonly loadingIconService: LoadingIconService,
              private utilsService: UtilsService,
              public elementRef: ElementRef) {
  }

  public async ngOnInit() {
    let response = await this.animalsService.getFinishingMalesMetadata();
    this.addMaleFinishingMetadata = response.responseBody;
    if (this.addMaleFinishingMetadata) {
      if (this.addMaleFinishingMetadata.groupList !== null) {
        this.groupsDropdownListItems = this.addMaleFinishingMetadata.groupList.map((groupItem: IGroupItem) => ({ value: groupItem, displayValue: groupItem.name, disabled: false, icon: null }));
      }
      if (this.addMaleFinishingMetadata.rfidTagList !== null) {
        this.rfidsTagsNumbers = this.addMaleFinishingMetadata.rfidTagList.map((rfIdItem: ISCRTagItem) => (rfIdItem.tagNumber));
      }
      if (this.addMaleFinishingMetadata.scrTagList !== null) {
        this.scrTagsNumbers = this.addMaleFinishingMetadata.scrTagList.map((tagItem: ISCRTagItem) => (tagItem.tagNumber));
      }
    }
  }

  private convertToNumberIfNotNull(number: string): number | null {
    if (number == null) {
      return null;
    } else {
      return Number(number);
    }
  }

  public async addFinishingMale() {
    if (this.validateEntryStep() &&
        this.validateFarmStep() &&
        this.validateFinishingStep()) {
      this.fillWeightValues();
      const addFinishingMaleRequest: IAddMaleFinishingRequest = {
        animalName: this.finishingAddMaleModel.animalNumber,
        group: this.finishingAddMaleModel.group.value,
        orn: this.finishingAddMaleModel.registrationNumber,
        rfidTag: this.finishingAddMaleModel.EID,
        scrTag: this.finishingAddMaleModel.tagNumber,
        birthDate: this.finishingAddMaleModel.birthDate.epochDate,
        herdEntryDateTime: this.finishingAddMaleModel.entryDate.epochDate,
        weightDate: this.finishingAddMaleModel.weighing.epoch,
        weightValueKG: this.convertToNumberIfNotNull(this.finishingAddMaleModel.weightValueKG),
        weightValueLBS: this.convertToNumberIfNotNull(this.finishingAddMaleModel.weightValueLBS),
        castrationDate: this.finishingAddMaleModel.castration.epoch,
        startBackgroundDate: this.finishingAddMaleModel.backgroundStartDate.epoch,
        startFinishingDate: this.finishingAddMaleModel.finishingStart.epoch
      };
      this.loadingIconService.show();
      const response = await this.animalsService.addMaleFinishing(addFinishingMaleRequest);
      if (response && response.status === 201) {
        // reload animal list
        this.animalsService.onAnimalsListChanged.next();
        // after submiting form - clear form and get back to the first step
        this.finishingAddMaleModel.clear();
        this.finishingStep = AddMaleFinishingSteps.Entry;
        this.loadingIconService.hide();
      } else {
        if (response && response.errorResponseBody && !response.errorResponseBody.result.message) {
          this.errors[0] = response.errorResponseBody.result.failures && response.errorResponseBody.result.failures[0];
          this.navigateToErrorStep();
        }
        this.loadingIconService.hide();
      }
    } else {
      if (this.errors && this.errors.length > 0) {
        this.navigateToErrorStep();
      }
    }
  }

  public fillWeightValues() {
    if (this.finishingAddMaleModel.weightValueKG === '') {
      this.finishingAddMaleModel.weightValueKG = null;
    }
    if (this.finishingAddMaleModel.weightValueLBS === '') {
      this.finishingAddMaleModel.weightValueLBS = null;
    }
    if (this.isKgValueDisplayed()) {
      if (this.finishingAddMaleModel.weightValueKG == null) {
        this.finishingAddMaleModel.weightValueLBS = null;
        return;
      }
      this.finishingAddMaleModel.weightValueLBS = String(this.utilsService.convertKilogramsToPounds(Number(this.finishingAddMaleModel.weightValueKG)));
    } else {
      if (this.finishingAddMaleModel.weightValueLBS == null) {
        this.finishingAddMaleModel.weightValueKG = null;
        return;
      }
      this.finishingAddMaleModel.weightValueKG = String(this.utilsService.convertPoundsToKilograms(Number(this.finishingAddMaleModel.weightValueLBS)));
    }
  }

  public isKgValueDisplayed() {
    return this.utilsService.getUnitType() === WeightUnitType.KG;
  }

  public goToStep(step: AddMaleFinishingSteps) {
    switch (step) {
      case AddMaleFinishingSteps.Farm: {
        if (this.validateEntryStep()) {
          this.performNavigationToStep(step);
        }
        break;
      }
      case AddMaleFinishingSteps.Finishing: {
        if (this.validateFarmStep()) {
          this.performNavigationToStep(step);
        }
        break;
      }
    }
  }

  public checkIfCanNavigateToStep(step: AddMaleFinishingSteps) {
    if (this.finishingStep > step) {
      this.finishingStep = step;
    }
  }

  public isCurrentSummaryDisplayed(currentStep: AddMaleFinishingSteps) {
    return this.finishingStep > currentStep;
  }

  public clearErrors() {
    this.errors = [];
  }

  private validateEntryStep(): boolean {
    if (!this.finishingAddMaleModel.animalNumber) {
      this.errors[0] = { key: 'ANIMAL_ID_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.animalName };
      return false;
    }
    if (!this.finishingAddMaleModel.group) {
      this.errors[0] = { key: 'GROUP_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.group };
      return false;
    }
    return true;
  }

  private validateFarmStep(): boolean {
    if (!this.finishingAddMaleModel.group) {
      this.errors[0] = { key: 'GROUP_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.group };
      return false;
    }
    if (!this.finishingAddMaleModel.birthDate.epochDate && !this.finishingAddMaleModel.entryDate.epochDate) {
      if (!this.finishingAddMaleModel.birthDate.epochDate) {
        this.errors[0] = {key: 'HerdEntryAndBirthDateAreMissing', fieldName: AddAnimalFieldsErrorsEnum.entryDate };
        return false;
      }
    }
    return true;
  }

  private validateFinishingStep(): boolean {
    if (!this.finishingAddMaleModel.group) {
      this.errors[0] = { key: 'GROUP_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.group };
      return false;
    }
    return true;
  }

  private performNavigationToStep(step: AddMaleFinishingSteps) {
    this.finishingStep = step;
  }

  private navigateToErrorStep() {
    if (this.errors) {
      switch (this.errors[0].fieldName) {
        case AddAnimalFieldsErrorsEnum.weightDate:
        case AddAnimalFieldsErrorsEnum.weightValueLBS:
        case AddAnimalFieldsErrorsEnum.weightValueKG: {

          this.finishingStep = AddMaleFinishingSteps.Farm;
          break;
        }
        case AddAnimalFieldsErrorsEnum.animalName:
        case AddAnimalFieldsErrorsEnum.orn:
        case AddAnimalFieldsErrorsEnum.rfIdTagNumber:
        case AddAnimalFieldsErrorsEnum.scrTagNumber: {
          this.finishingStep = AddMaleFinishingSteps.Entry;
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  public getWeightValueAccordingToLocalization() {
    if (this.isKgValueDisplayed()) {
      if (this.finishingAddMaleModel.weightValueKG) {
        return this.finishingAddMaleModel.weightValueKG;
      } else {
        return '-';
      }
    } else {
      if (this.finishingAddMaleModel.weightValueLBS) {
        return this.finishingAddMaleModel.weightValueLBS;
      } else {
        return '-';
      }
    }
  }

  public isWeightValueExists() {
    if (this.isKgValueDisplayed()) {
      return !!this.finishingAddMaleModel.weightValueKG;
    } else {
      return !!this.finishingAddMaleModel.weightValueLBS;
    }
  }
}
