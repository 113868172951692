import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../services/config/config.service';
import {Router} from '@angular/router';

@Component({
  selector: 'logged-out-layout',
  templateUrl: './logged-out-layout.component.html',
  styleUrls: ['./logged-out-layout.component.scss']
})
export class LoggedOutLayoutComponent implements OnInit {

  constructor() { }

  public ngOnInit() {
  }
}
