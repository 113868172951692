import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TurnLedLightingErrorDialogService {
  public showPopup$ = new Subject<Array<{key: string, fieldName: string}> | null>();

  public show(errorsData: Array<{key: string, fieldName: string}>): void {
    this.showPopup$.next(errorsData);
  }

  public hide(): void {
    this.showPopup$.next(null);
  }
}
