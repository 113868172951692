<div class="graph-bar">
  <div class="bar-wrapper" #barWrapper>
    <div class="bar-section" *ngFor="let section of details.graph.sections; let i = index" [ngStyle]="{ background: dashboardKpiService.setGraphSectionColor(section.colorCode), width: section.sectionSize + '%'}">
      <div class="section-text">
        <div class="text">{{ dashboardKpiService.showGradientValue(details.mainValue.type, section.minSectionLimit) }}</div>
      </div>
      <div class="max-section-text" *ngIf="i === (details.graph.sections.length - 1)">
        <div class="text">{{ dashboardKpiService.showGradientValue(details.mainValue.type, section.maxSectionLimit) }}</div>
      </div>
    </div>
    <div class="value" *ngIf="(details.mainValue && details.mainValue.value !== null)"
                       [ngStyle]="{ left: (valuePosition - 12) + 'px'}">
    </div>
  </div>
</div>
