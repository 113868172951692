<form class="login-form" [ngClass]="{'serverError': serverError}" name="loginForm" ngNativeValidate autocomplete="off" (ngSubmit)="onSubmit()" #loginForm="ngForm">
  <div class="form-field-label">
    <div class="menu-header">
      <div class="allflex-logo" sh-id="login_welcome_logo"></div>
    </div>
  </div>
  <div class="form-field-title"><label translation-id="LOGIN.TITLE.USERNAME" sh-id="username-label">{{'LOGIN.TITLE.USERNAME' | translate}}</label></div>
  <div class="form-field"><input type="text" name="userName" translation-id="LOGIN.TITLE.USERNAME" [(ngModel)]="authDetails.userName" #userName="ngModel" (keyup)="validateUserName($event)" (keydown)="validateUserName($event)" required placeholder="{{'LOGIN.TITLE.USERNAME' | translate}}" spellcheck="false" sh-id="login_username" /></div>
  <div class="form-field-title"><label translation-id="LOGIN.TITLE.PASSWORD" sh-id="password-label">{{'LOGIN.TITLE.PASSWORD' | translate}}</label></div>
  <div class="form-field"><input type="password" translation-id="LOGIN.TITLE.PASSWORD" name="password" [(ngModel)]="authDetails.password" #password="ngModel" (keyup)="resetErrorReason($event)" (keydown)="resetErrorReason($event)" placeholder="{{'LOGIN.TITLE.PASSWORD' | translate}}" required sh-id="login_password" /></div>
  <div class="form-field-title"><label translation-id="LOGIN.TITLE.FARM_ID" sh-id="farmid-label">{{'LOGIN.TITLE.FARM_ID' | translate}}</label></div>
  <div class="form-field"><input type="text" translation-id="LOGIN.TITLE.FARM_ID" name="farmId" [(ngModel)]="authDetails.farmId" #farmId="ngModel" readonly="readonly" required="required" placeholder="{{'LOGIN.TITLE.FARM_ID' | translate}}" sh-id="login_farmId" /></div>
  <div class="form-field-title remember-me" sh-id="login_remember_user" (click)="onRememberUserClick()">
    <image-checkbox [isChecked]="rememberUser" class="login_remember_user_checkbox" [shId]="'login_remember_user_checkbox'"></image-checkbox>
    <label translation-id="LOGIN.TITLE.REMEMBER_ME" sh-id="rememberme-checkbox">{{ 'LOGIN.TITLE.REMEMBER_ME' | translate }}</label>
  </div>
  <div class="form-field form-field-error" *ngIf="errorReason">
    <label [attr.sh-id]="'login_error_' + errorReason" [attr.translation-id]="'LOGIN.ERROR.' + errorReason">{{'LOGIN.ERROR.' + errorReason | translate}}</label>
  </div>
  <div class="form-field form-field-btn">
    <input type="button" class="forgot-password-btn" [disabled]="serverError" (click)="onForgotPasswordClick()" value="{{'LOGIN.TITLE.FORGOT_PASSWORD' | translate}}" translation-id="LOGIN.TITLE.FORGOT_PASSWORD" sh-id="login_forgotPassword">
    <input class="submit-btn" [disabled]="serverError" type="submit" value="{{'LOGIN.TITLE.LOGIN' | translate}}" translation-id="LOGIN.TITLE.LOGIN" sh-id="login_loginButton">
  </div>
</form>
