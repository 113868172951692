<div class="create-admin-account-container">
  <div class="create-admin-account-body-wrapper">
    <div class="create-admin-account-text" [attr.translation-id]="'REGISTRATION.FLOW.CREATE_ACCOUNT'">{{ 'REGISTRATION.FLOW.CREATE_ACCOUNT' | translate }}</div>
    <div class="form-group">
      <div class="title-text" [attr.translation-id]="'MANAGE.SETTINGS.USERS.USER_EDIT.NAME'">{{ 'MANAGE.SETTINGS.USERS.USER_EDIT.NAME' | translate }}</div>
      <input class="input-class" [attr.sh-id]="'enter_name_input'" [ngClass]="{ error: registrationService.registrationModel.error && registrationService.registrationModel.error.fieldName === 'customerName'}" [(ngModel)]="customerName" (ngModelChange)="clearErrors()" [attr.translation-id]="'REGISTRATION.FLOW.PLACEHOLDER.ENTER_NAME'" placeholder="{{'REGISTRATION.FLOW.PLACEHOLDER.ENTER_NAME' | translate}}" />
    </div>
    <div class="form-group">
      <div class="title-text" [attr.translation-id]="'REGISTRATION.FLOW.CELL_PHONE_NUMBER'">{{ 'REGISTRATION.FLOW.CELL_PHONE_NUMBER' | translate }}</div>
      <form [formGroup]="phoneForm">
      <ngx-intl-tel-input
        [cssClass]="'sign-up-tel-form'"
        [attr.sh-id]="'phone_number_input'" 
        [value]="registrationService.registrationModel.createAccount && registrationService.registrationModel.createAccount.account.phone"
        [preferredCountries]="['us', 'gb']"
        [enableAutoCountrySelect]="true"
        [enablePlaceholder]="true"
        (ngModelChange)="clearErrors()"
        name="phone"
        formControlName="phone"></ngx-intl-tel-input>
      </form>
    </div>
    <div class="form-group">
      <div class="title-text" [attr.translation-id]="'MANAGE.SETTINGS.USERS.USER_EDIT.EMAIL'">{{ 'MANAGE.SETTINGS.USERS.USER_EDIT.EMAIL' | translate }}</div>
      <input  [attr.sh-id]="'email_address_input'" class="input-class" [ngClass]="{ error: registrationService.registrationModel.error && registrationService.registrationModel.error.fieldName === 'email'}" type="email" [(ngModel)]="email" (ngModelChange)="clearErrors()" [attr.translation-id]="'MANAGE.SETTINGS.USERS.USER_EDIT.EMAIL_PLACEHOLDER'" placeholder="{{'MANAGE.SETTINGS.USERS.USER_EDIT.EMAIL_PLACEHOLDER' | translate}}" />
    </div>
    <div class="errors-div" *ngIf="registrationService.registrationModel.error">
      <div class="error-text" [attr.translation-id]="'SYSTEM.MANAGEMENT.ERROR_BOLD: REGISTRATION.FLOW.ERROR.' + registrationService.registrationModel.error.error"><b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}:</b> {{ 'REGISTRATION.FLOW.ERROR.' + registrationService.registrationModel.error.error | translate }}</div>
    </div>
  </div>
  <div class="create-admin-account-buttons-wrapper">
    <button class="next-button" [attr.sh-id]="'next_button'" [attr.translation-id]="'REGISTRATION.FLOW.NEXT'" (click)="next()">{{ 'REGISTRATION.FLOW.NEXT' | translate }}</button>
  </div>

</div>
