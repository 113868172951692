<div class="report-grid-content">
  <div class="filter-section" [attr.sh-id]="'filter-section'" *ngIf="selectedColumn && openFilterSection">
    <div class="filter-header">
      <div class="filter-left-part">
        <div class="icon-filter"></div>
        <div class="filter-title">{{'REPORTS.GRID.' + selectedColumn.details.name | translate}}</div>
      </div>
      <div class="filter-right-part">
        <div class="icon icon-filter-apply"
             *ngIf="selectedFilter.isValid"
             (click)="applyFilter()"
             sh-id="report-column-apply-filter"></div>
        <div class="icon icon-filter-close"
             (click)="revertFilter()"
             sh-id="report-column-revert-filter"></div>
      </div>
    </div>
    <div class="frame">
      <div class="shadow"></div>
    </div>
    <div class="filter-body">
      <div class="switch-for-templates" [ngSwitch]="selectedColumn.details.filterType">
        <filter-boolean *ngSwitchCase="ReportColumnFilterType.Boolean"
                        [filterBooleanQuery]="selectedFilter"></filter-boolean>
        <filter-check-list *ngSwitchCase="ReportColumnFilterType.CheckList"
                           [columnType]="selectedColumn.valueType"
                           [filterChecklistQuery]="selectedFilter"
                           [reportMetaFilterValueColumn]="selectedReportMetaFilterValueColumn"></filter-check-list>
        <filter-date-time *ngSwitchCase="ReportColumnFilterType.DateTime"
                          [filterNumericQuery]="selectedFilter"></filter-date-time>
        <filter-numeric *ngSwitchCase="ReportColumnFilterType.Numeric"
                        [filterNumericQuery]="selectedFilter"></filter-numeric>
        <filter-search *ngSwitchCase="ReportColumnFilterType.Search"
                       [filterSearchQuery]="selectedFilter"
                       [reportMetaFilterValueColumn]="selectedReportMetaFilterValueColumn"></filter-search>
      </div>
    </div>
    <div class="close"></div>
  </div>
  <div #ngxDatatableContainer class="ngx-datatable-container">
    <ngx-datatable *ngIf="!initGrid"
                   #datatable
                   [columns]="columns"
                   [columnMode]="'flex'"
                   [rows]="rows"
                   [rowClass]="getRowColoring"
                   [virtualization]="true"
                   [rowHeight]="getRowHeight"
                   [headerHeight]="headerHeight"
                   [scrollbarV]="true"
                   [scrollbarH]="false"
                   [externalSorting]="true"
                   [reorderable]="false"
                   [messages]="{emptyMessage: '', totalMessage: ''}"
                   (scroll)="onGridScroll($event.offsetY)"
                   [ngStyle]="{ 'width': datatableContainerWidthPixels + 'px',
                                'height': datatableContainerHeightPixels + 'px',
                                'position': useRelativePosition ? 'relative' : 'absolute'}"
                   [ngClass]="getBottomBorderClass()"
                   class="report">
    </ngx-datatable>
  </div>
  <ng-template #valueColumn let-column="column">
    <div (click)="columnClick(column)"
         class="value-column "
         [ngClass]="{
          'open': column.name === selectedColumn?.name && !openFilterSection,
          'filter': column.name === selectedColumn?.name && openFilterSection,
          'filter-active': isFilteredByThisColumn(column.name),
          'filter-or-sort-active': columnIsFilteredOrSorted(column.name)
         }"
         [attr.sh-id]="'report-column-' + column.name">
      <span class="column-name">{{'REPORTS.GRID.' + column.details.name | translate}}</span>
      <div class="column-indicators" *ngIf="columnHasFilterOrSort(column)">
        <div *ngIf="isFilteredByThisColumn(column.name)" class="sorting-icon sorting-icon-filter" [attr.sh-id]="'sorting-icon-filter'"></div>
        <div class="two-lines-icon"></div>
        <div *ngIf="isColumnSortedUp(column.name)" class="sorting-icon sorting-icon-up"></div>
        <div *ngIf="isColumnSortedDown(column.name)" class="sorting-icon sorting-icon-down"></div>
      </div>
    </div>
  </ng-template>

  <ng-template #selectionHeaderColumn let-column="column">

    <div class="select-all-div-wrapper" [ngStyle]="{'height': headerHeight + 'px'}">
      <div class="total-selected-rows-text" *ngIf="selectedRowsCount > 0">
        {{ selectedRowsCount }}
      </div>
      <div class="select-all-rows-button" [ngClass]="{ selected:selectedRowsCount > 0 }" (click)="toggleSelectAll()">
        <div class="selected-icon"></div>
      </div>
    </div>
  </ng-template>

  <ng-template #clearFiltersColumn let-column="column">
    <div (click)="clearFilters()" class="clear-filters-column" *ngIf="reportsService.reportIsSortableOrFilterable(report)"
      [ngClass]="{ 'clear-filters-column-active': (reportQuery.filter.size > 0 || reportQuery.sort.size > 0)}"
      [attr.sh-id]="'clear-filters-grid-button'">
      <span class="icon-remove-filter" [ngClass]="{ smallIcon: isClearFilterIconSmall }"></span>
    </div>
    <div class="value-column" *ngIf="!reportsService.reportIsSortableOrFilterable(report)">
    </div>
  </ng-template>

  <ng-template #valueCell let-column="column" let-row="row" let-value="value">

    <div [ngClass]="['value-cell', getColoringClass(row, column, value), getLinkClassForContent(row)]"
         [attr.sh-id]="column.details.key + '-' + row[column.details.key]"
         [attr.alt-sh-id-row-number]="row.rowId"
         [attr.alt-sh-id]="getAltShIdForTableCellByColumnId(row, column)"
         [ngSwitch]="column.valueType"
         (click)="reportGridValueCellClick(column, row)">
      <span *ngSwitchCase="ReportValueType.DateFormat">
        <ng-template [ngIf]="isSyncStatusDateTimeCell(row, column)" [ngIfElse]="isNotSyncStatusDateTimeCell">
          {{ row[column.details.key] == null ? '-' : row[column.details.key] | epochDateTime:DateTimeFormatEnum.SyncStatusDateTime }}
        </ng-template>
        <ng-template #isNotSyncStatusDateTimeCell>
          <ng-container *ngIf="column.details.key === 'DistressStartDateAndTimeCalculation'">
            {{ row[column.details.key] == null ? '-' : row[column.details.key] | epochDateTime:DateTimeFormatEnum.DateTime }}
          </ng-container>
          <ng-container *ngIf="column.details.key != 'DistressStartDateAndTimeCalculation'">
            {{ row[column.details.key] | epochDateTime:DateTimeFormatEnum.DateTime }}
          </ng-container>
        </ng-template>
      </span>
      <span *ngSwitchCase="ReportValueType.Duration">
        {{ row[column.details.key] | reportDuration:report.meta.reportTime }}
      </span>
      <span *ngSwitchCase="ReportValueType.HoursMinutesDuration">
        {{ row[column.details.key] | duration }}
      </span>
      <div *ngSwitchCase="ReportValueType.Trend" class="panting-trend-wrapper">
        <div class="increase-icon" *ngIf="row[column.details.key] == PantingTrendType.increase"></div>
        <div class="decrease-icon" *ngIf="row[column.details.key] == PantingTrendType.decrease"></div>
        <div class="same-icon" *ngIf="row[column.details.key] == PantingTrendType.stayTheSame"></div>
        <div *ngIf="!row[column.details.key]" [attr.sh-id]="column.details.key + '-' + row.rowId + '-' + row[column.details.key]">-</div>
      </div>
      <span *ngSwitchCase="ReportValueType.Percentage">
          {{ row[column.details.key] ? row[column.details.key] + '%' : '-' }}
      </span>
      <span *ngSwitchCase="ReportValueType.LactationStatus" class="lactation-status">
        <animal-lifecycle-status-icon [status]="row[column.details.key]"></animal-lifecycle-status-icon>
        <ellipsisable-text [attr.translation-id]="'REPORTS.GRID.STATUS.' + row[column.details.key]">{{'REPORTS.GRID.STATUS.' + row[column.details.key] | translate}}</ellipsisable-text>
      </span>
      <span *ngSwitchCase="ReportValueType.AnimalId" class="animal-id">
        <ellipsisable-text [ngClass]="{ scaledText: searchService.isHerdManagementDisplayed }" class="animal-id-value">{{ row[column.details.key] }}</ellipsisable-text>
        <span *ngIf="row.AnimalBadgeCalculation === 'DNB' || row.DNBCalculation" class="special-dnb" translation-id="COMMON.BADGE.DNB">
          {{ 'COMMON.BADGE.DNB' | translate }}
        </span>
        <span *ngIf="row.AnimalBadgeCalculation === 'Male'" class="special-male" translation-id="COMMON.BADGE.MALE">
          {{ 'COMMON.BADGE.MALE' | translate }}
        </span>
      </span>
      <ellipsisable-text *ngSwitchCase="ReportValueType.Localization" [attr.translation-id]="'REPORTS.GRID.STATUS.' + row[column.details.key]" class="localization">
        {{ 'REPORTS.GRID.STATUS.' + row[column.details.key] | translate}}
      </ellipsisable-text>
      <ng-container *ngSwitchCase="ReportValueType.DynamicLocalization">
        <ellipsisable-text *ngIf="row[column.details.key] == null">{{ row[column.details.key] }}</ellipsisable-text>
        <ellipsisable-text *ngIf="row[column.details.key] != null && row[column.details.key].isLocalization" [attr.translation-id]="'REPORTS.GRID.STATUS.' + row[column.details.key]">
          {{ 'REPORTS.GRID.STATUS.' + row[column.details.key].value | translate}}
        </ellipsisable-text>
        <ellipsisable-text *ngIf="row[column.details.key] != null && !row[column.details.key].isLocalization">
          {{ row[column.details.key].value }}
        </ellipsisable-text>
      </ng-container>
      <span *ngSwitchCase="ReportValueType.BreedingWindow" class="breeding-window">
        <ng-container *ngIf="row[column.details.key] != null">
          <div class="zoom-icon-container">
            <div class="zoom-icon" (click)="toggleBreedingWindow(row)"></div>
          </div>
          <breeding-window [inseminationWindowCalculation]="row[column.details.key]" [isExpanded]="isBreedingWindowExpanded(row)"></breeding-window>
        </ng-container>
      </span>
      <span [attr.sh-id]="column.details.key + '-boolean-row-' + row.rowId" *ngSwitchCase="ReportValueType.Boolean" [attr.translation-id]="(row[column.details.key] === true || row[column.details.key] === 'true') ? 'REGISTRATION.FLOW.YES' : (row[column.details.key] === false || row[column.details.key] === 'false') ? 'REGISTRATION.FLOW.NO' : ''">
        {{ ((row[column.details.key] === true || row[column.details.key] === 'true') ? 'REGISTRATION.FLOW.YES' : (row[column.details.key] === false || row[column.details.key] === 'false') ? 'REGISTRATION.FLOW.NO' : '') | translate }}
      </span>
      <div *ngSwitchCase="ReportValueType.AnimalsToInspectReasons" class="sorting-reason-cell">
        <animals-to-inspect-reasons-cell (onToggleExpanded)="toggleReasonDetails()" [row]="row"></animals-to-inspect-reasons-cell>
      </div>
      <ng-container *ngSwitchCase="ReportValueType.Plain">
        <div class="plain-detail" [ngSwitch]="column.details.key">
          <ellipsisable-text *ngSwitchCase="'TagLedUseCalculation'">
            {{ row[column.details.key] == null ? '-' : row[column.details.key] + ' h' }}
          </ellipsisable-text>
          <ellipsisable-text *ngSwitchCase="'SyncStatusResultsCalculation'">
            {{reportsService.parseSyncStatusResults(row[column.details.key])}}
          </ellipsisable-text>
          <ellipsisable-text *ngSwitchCase="'SyncStatusStatusCalculation'" [attr.sh-id]="'SyncStatusStatusCalculation-' + row[column.details.key]">
            {{'SYSTEM.MANAGEMENT.GRID.' + row[column.details.key] | translate}}
          </ellipsisable-text>
          <ellipsisable-text *ngSwitchCase="'SyncStatusDurationCalculation'">
            {{reportsService.minToMs(row[column.details.key])}}
          </ellipsisable-text>
          <ellipsisable-text *ngSwitchDefault>
            {{ row[column.details.key] }}
          </ellipsisable-text>
        </div>
      </ng-container>
      <div *ngSwitchCase="ReportValueType.SortingReason" class="sorting-reason-cell">
        <sorting-reason-row-cell (onToggleExpanded)="toggleReasonDetails()" [row]="row"></sorting-reason-row-cell>
      </div>
      <span *ngSwitchCase="ReportValueType.Sorted">
        {{ 'SORTING_GATE.SORTING_REPORT.SORTED_CALCULATION.' + row[column.details.key] | translate }}
      </span>
      <span *ngSwitchDefault>
        {{ row[column.details.key] }}
      </span>
    </div>
  </ng-template>

  <ng-template #selectionValueCell let-column="column" let-row="row">

    <div class="selection-value-cell-wrapper">

      <div class="selectable-row-button" [ngClass]="{ selected: isRowSelected(row) }" (click)="toggleSelectedRow(row)" [attr.sh-id]="'row-selection-radio-btn-' + row.rowId">
        <div class="selected-icon" *ngIf="isRowSelected(row)"></div>
      </div>
    </div>
  </ng-template>

  <ng-template #actionsCell let-column="column" let-row="row" let-value="value">
    <row-actions-popup *ngIf="row.actions"
                       [shId]="row.rowNumber"
                       [rowActions]="row.actions"
                       (onPopupOpen)="onRowActionsPopupOpen(row)"
                       (onRowActionClick)="rowActionClick($event, row)">
    </row-actions-popup>
    <div *ngIf="isSyncSummaryDetailsButtonShown(row)"
         [attr.sh-id]="'zoom-icon-' + row.rowId"
         (click)="reportGridValueCellClick(column, row)"
         class="icon-zoom icon-zoom_large">
    </div>
  </ng-template>

  <div *ngIf="selectedColumn && !openFilterSection" class="report-column-actions" [attr.sh-id]="'report-column-actions'" [ngStyle]="{'left': columnActionsLeftPosition + 'px',
                                                                                                     'top': columnActionsTopPosition + 'px',
                                                                                                     'width': columnActionsWidth + 'px',
                                                                                                     'height': columnActionsHeight + 'px'}">
    <div class="report-column-actions-buttons">
      <span *ngIf="isSelectedColumnSorted"
            (click)="removeSortingBySelectedColumn()"
            [attr.sh-id]="'report-column-remove-sorting-' + selectedColumn.name"
            class="icon remove-sorting"></span>
      <span class="action-separator" *ngIf="isSelectedColumnSorted"></span>
      <span *ngIf="!isColumnSortedUp(selectedColumn.name)"
            (click)="sortUpBySelectedColumn()"
            [attr.sh-id]="'report-column-sort-up-' + selectedColumn.name"
            class="icon sorting-up"></span>
      <span class="action-separator" *ngIf="!isColumnSortedUp(selectedColumn.name) && !isColumnSortedDown(selectedColumn.name)"></span>
      <span *ngIf="!isColumnSortedDown(selectedColumn.name)"
            (click)="sortDownBySelectedColumn()"
            [attr.sh-id]="'report-column-sort-down-' + selectedColumn.name"
            class="icon sorting-down"></span>
      <ng-container *ngIf="selectedColumn.details.filterType != ReportColumnFilterType.None">
        <span class="action-separator"></span>
        <span *ngIf="!reportQuery.filter.has(selectedColumn.name)"
              (click)="openFilterSection = true"
              [attr.sh-id]="'report-column-open-filter-' + selectedColumn.name"
              class="filters icon filter"></span>
        <span *ngIf="reportQuery.filter.has(selectedColumn.name)"
              (click)="removeFilter()"
              [attr.sh-id]="'report-column-remove-filter-' + selectedColumn.name"
              class="filters icon remove-filter"></span>
      </ng-container>
    </div>
    <span class="report-column-actions-separator"></span>
  </div>
  <loader [isLoading]="isLoading" [contextElementRef]="elementRef"></loader>
</div>
