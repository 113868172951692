import {Injectable} from '@angular/core';
import {AnonymousAuthDetailsResult} from './model/anonymous-auth-config';
import {AllowedManageAppBundle, ApplicationBundle, FarmMode, FinishingApplicationBundle, ServerConfig} from './model/server-config';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HeaderAuthorizationDetails} from '../rest-api/models/header-authorization-details';
import {FarmRole} from '../auth/model/farm-role.enum';

export interface IApplicationConfiguration {
  apiUrl: string;
  clientVersion: string;
  useMockHttp: boolean;
  pingInterval: number;
  cloud: boolean;
  googleAnalyticsProd: boolean;
  testingServerUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public static readonly anonymousAuthUnknownFarmDetails: HeaderAuthorizationDetails = {
    userName: 'username',
    farmId: '0',
    password: 'Password1'
  };

  private static readonly JsonConfigFilePath: string = `assets/config/config.json`;

  private _configuration: IApplicationConfiguration;

  private _anonymousAuthResult: AnonymousAuthDetailsResult;

  private _serverConfig: ServerConfig;

  public ServerConfigSubject: Subject<ServerConfig> = new Subject();

  public printContentInPopup: boolean = false;

  public disableAnimations: boolean = false;

  constructor(private httpClient:HttpClient) {

  }

  public loadApplicationConfiguration() : Promise<void> {
    return new Promise(resolve => {
      this.httpClient.get<IApplicationConfiguration>(ConfigService.JsonConfigFilePath).toPromise()
        .then((configuration: IApplicationConfiguration) => {
          this.configuration = configuration;
          this.disableAnimations = this.configuration.useMockHttp;
          resolve();
        });
    });
  }

  public get configuration(): IApplicationConfiguration {
    return this._configuration;
  }

  public set configuration(configuration: IApplicationConfiguration) {
    this._configuration = configuration;
  }

  public get anonymousAuthResult(): AnonymousAuthDetailsResult {
    return this._anonymousAuthResult;
  }

  public set anonymousAuthResult(authDetails: AnonymousAuthDetailsResult) {
    this._anonymousAuthResult = authDetails;
  }

  public get serverConfig(): ServerConfig {
    return this._serverConfig;
  }

  public set serverConfig(serverConfig: ServerConfig){
    this._serverConfig = serverConfig;
    this.ServerConfigSubject.next(this._serverConfig);
  }

  public setLedOperationEnabled(isLedOperationEnabled: boolean) {
    this._serverConfig.isLedOperationEnabled = isLedOperationEnabled;
  }

  public get allowedManageAppBundle() : AllowedManageAppBundle {
    if(this._serverConfig.farmMode === FarmMode.Dairy ||
      this._serverConfig.farmMode === FarmMode.Unknown ||
      this._serverConfig.bizModeParameters.finishingApplicationBundle === FinishingApplicationBundle.None
    ) {
      return AllowedManageAppBundle.Breeding;
    }
    if(this._serverConfig.bizModeParameters.applicationBundle === ApplicationBundle.None) {
      return AllowedManageAppBundle.Finishing;
    }
    return AllowedManageAppBundle.BreedingAndFinishing;
  }
}
