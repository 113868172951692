import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IRowActionModel, LedTasksColumns} from "../tasks.component";
import {
  ScheduledTaskModel
} from "../../../../services/led-tasks/models/led-tasks.interface";
import {DateTimeFormatEnum} from "../../../../common/pipes/epoch-date-time.pipe";
import {DataGridComponent} from "../../../../common/components/data-grid/data-grid.component";
import {SearchEntry} from "../../../../services/search/model/search.model";
import {RowActionsPopupItem} from "../../../../common/components/row-actions-popup/row-actions-popup.component";
import {Router} from "@angular/router";
import {DescriptionCellEntity} from '../../../../common/components/data-grid/description-cell-template/description-cell-entities/model/description-cell-entity.model';
import {GridViewState} from '../../../../services/ui/view-state/grid-row/grid-view-state';

@Component({
  selector: 'report-row',
  templateUrl: './report-row.component.html',
  styleUrls: ['./report-row.component.scss']
})
export class ReportRowComponent implements OnInit {

  @Input()
  public column: LedTasksColumns;

  @Input()
  public row: ScheduledTaskModel;

  @Input()
  public groupsList: SearchEntry[];

  @Input()
  public gridViewState: GridViewState;

  @Input()
  public ledTasksDataGrid: DataGridComponent;

  @Input()
  public rowActionsMap: Map<ScheduledTaskModel, RowActionsPopupItem[]>;

  @Output()
  public onRowAction: EventEmitter<IRowActionModel> = new EventEmitter<IRowActionModel>();

  public LedTasksColumns = LedTasksColumns;

  public dateTimeFormatEnum = DateTimeFormatEnum;

  constructor(public router: Router) { }

  public ngOnInit() {
  }

  public getReportNameByReportId(row: ScheduledTaskModel) {
    switch (row.reportId) {
      case 13: {
        if (row.isQuickFilter) {
          return 'LED_TASKS.QUICK_FILTER.AnimalsForAI';
        } else {
          return 'SORTING_GATE.SORTING_REPORT.SORTING_REASONS.AnimalsInHeat';
        }
      }
      case 1: {
        return 'LED_TASKS.QUICK_FILTER.anestrusCows';
      }
      case 2: {
        return 'SORTING_GATE.SORTING_REPORT.SORTING_REASONS.irregularHeats';
      }
      case 4: {
        if (row.isQuickFilter) {
          return 'LED_TASKS.QUICK_FILTER.MarkedHealth';
        } else {
          return 'SORTING_GATE.SORTING_REPORT.SORTING_REASONS.Health';
        }
      }
      case 7: {
        if (row.isQuickFilter) {
          return 'LED_TASKS.QUICK_FILTER.ActiveDistress';
        } else {
          return 'SORTING_GATE.SORTING_REPORT.SORTING_REASONS.AnimalDistress';
        }
      }
      case 21: {
        return 'SORTING_GATE.SORTING_REPORT.SORTING_REASONS.YoungStockHealth';
      }
      case 105: {
        return 'SORTING_GATE.SORTING_REPORT.SORTING_REASONS.FinishingHealth';
      }
    }
  }

  public convertWeekdayToText(row: ScheduledTaskModel): string {
    let weekdays: string = '';
    if (row && row.weekDays) {
      if (row.weekDays.length === 7) {
        weekdays = 'Every Day';
      } else {
        row.weekDays.forEach((weekday, index) => {
          switch (weekday) {
            case 1: {
              weekdays += 'Sun';
              break;
            }
            case 2: {
              weekdays += 'Mon';
              break;
            }
            case 3: {
              weekdays += 'Tue';
              break;
            }
            case 4: {
              weekdays += 'Wed';
              break;
            }
            case 5: {
              weekdays += 'Thu';
              break;
            }
            case 6: {
              weekdays += 'Fri';
              break;
            }
            case 7: {
              weekdays += 'Sat';
              break;
            }
          }
          if (index !== (row.weekDays.length - 1)) {
            weekdays += ', ';
          }
        });
      }
    }
    return weekdays;
  }

  public onRowActionsOpen() {
    this.gridViewState.clearExpandedRows();
    this.ledTasksDataGrid.refreshRowsHeight();
  }

  public handleRowAction(rowAction: string, rowModel: ScheduledTaskModel) {
    const rowActionModel: IRowActionModel = { rowAction: rowAction, rowModel: rowModel };
    this.onRowAction.emit(rowActionModel);
  }

  public toggleExpandRow(row) {
    this.ledTasksDataGrid.refreshRowsHeight();
  }

  public get groupDescriptionEntities(): DescriptionCellEntity[] {
    return this.row.groups.map(group => ({
      entityId: group.id,
      entityName: group.name,
    }));
  }
}

