import {Component, Input, OnInit} from '@angular/core';
import {FarmStatisticsDairyWidget} from '../../../../../../../services/dashboard/model/farm-statistics-dairy';

@Component({
  selector: 'farm-statistics-dairy-header',
  templateUrl: './farm-statistics-dairy-header.component.html',
  styleUrls: ['./farm-statistics-dairy-header.component.scss']
})
export class FarmStatisticsDairyHeaderComponent implements OnInit {
  
  @Input()
  public farmStatistics: FarmStatisticsDairyWidget;
  
  constructor() { }

  public ngOnInit() {
  }
  
  public get totalsCaption() : string {
    return this.farmStatistics.animals.total.count.toString();
  }
}
