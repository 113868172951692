import {Component, Input, OnInit} from '@angular/core';

export interface IChartDataItem {
  value: number;
  title: string;
  color: string;
}

@Component({
  selector: 'farm-statistics-beef-pie-chart',
  templateUrl: './farm-statistics-beef-pie-chart.component.html',
  styleUrls: ['./farm-statistics-beef-pie-chart.component.scss']
})
export class FarmStatisticsBeefPieChartComponent implements OnInit {

  @Input()
  public data: IChartDataItem[];

  @Input()
  public chartElement: string;

  public chartData: IChartDataItem[] = [{
    value: 50,
    title: 'pregnant',
    color: 'rgba(25,153,214,1.0)'
  }, {
    value: 40,
    title: 'not pregnant',
    color: '#d4d4d4'
  }];

  public legendSettings: Object = {
    visible: false
  };
  public enableAnimation: boolean = false;

  constructor() { }

  public ngOnInit() {
    if (this.data) {
      this.chartData = this.data;
    }
  }
}
