<div outsideClickHandler (onOutsideClick)="isSearchOpen = false">
  <div class="search-header">
    <div class="btn-search" (click)="toggleSearch()" sh-id="header_search_toggleSearch">
      <div class="icon-find"></div>
      <div class="shadow"></div>
    </div>
    <div class="search-section" *ngIf="isSearchOpen" @search-text-transition>
      <input class="txt-search"
             input-focus
             type="text"
             [(ngModel)]="searchQuery"
             (keyup)="onSearchQueryChange($event)"
             (input)="onSearchQueryChange($event)"
             [placeholder]="getSearchPlaceholderText()"
             sh-id="header_search_searchTerm" />
      <div class="btn-close" (click)="closeSearch()">
        <i class="icon-close"></i>
      </div>
      <div class="shadow"></div>
    </div>
  </div>
  <div class="search-popup" *ngIf="isPopupOpen">
    <div class="frame-corner"></div>
    <div class="animal-search-popup-title">{{ getSearchPopupTitleText() }}
      <div class="shadow"></div>
    </div>
    <div class="animal-search-results">
      <div class="no-results" *ngIf="!hasMatchingResults" sh-id="header_search_noResult">
        <ul class="search-results cows">
          <li><span>{{getNoDataText()}}</span></li>
        </ul>
      </div>
      <ul class="search-results cows" *ngIf="lastSearchResult != null && lastSearchResult.matchingAnimals.length > 0" sh-id="header_search_cowData">
        <li *ngFor="let animal of lastSearchResult.matchingAnimals"
            [ngClass]="{ focused: (focusedSearchEntry &&
                                    animal.entityId === focusedSearchEntry.entityId &&
                                    (focusedSearchEntry.entityType === EntityType.male ||
                                    focusedSearchEntry.entityType === EntityType.cow)) }"
            (mouseenter)="focusedSearchEntry = animal"
            (mouseleave)="focusedSearchEntry = null"
            saveDataWarningInject
            (click)="openAnimalCard(animal.entityId, animal.operationType)"
            [attr.sh-id]="'header_search_animal_' + animal.entityId">
          <i class="icon-cow" *ngIf="animal.entityType === EntityType.cow"></i>
          <i class="icon-male" *ngIf="animal.entityType === EntityType.male"></i>
          <span class="entity-label animated fadeInRight" [attr.sh-id]="'search_result_entry_'+animal.entityName">
              <span *ngFor="let part of searchService.getAnimalMatchingParts(searchQuery, animal)">{{part}}</span>
          </span>
        </li>
      </ul>
      <ul class="search-results groups" *ngIf="lastSearchResult != null && lastSearchResult.matchingGroups.length > 0" sh-id="header_search_groupData">
        <li *ngFor="let group of lastSearchResult.matchingGroups"
            [ngClass]="{focused: (focusedSearchEntry && group.entityId === focusedSearchEntry.entityId &&
                                  focusedSearchEntry.entityType === EntityType.group) }"
            (mouseenter)="focusedSearchEntry = group"
            (mouseleave)="focusedSearchEntry = null"
            saveDataWarningInject
            (click)="openGroupCard(group.entityId, group.operationType)"
            [attr.sh-id]="'header_search_group_' + group.entityId">
          <i class="icon-group"></i>
          <span class="entity-label animated fadeInRight">
            <span *ngFor="let part of searchService.getGroupMatchingParts(searchQuery, group.entityName)">{{part}}</span>
          </span>
        </li>
      </ul>
      <ul class="search-results branches" *ngIf="lastSearchResult != null && lastSearchResult.matchingBranches.length > 0" sh-id="header_search_branchData">
        <li *ngFor="let branch of lastSearchResult.matchingBranches"
            [ngClass]="{focused: (focusedSearchEntry && branch.entityId === focusedSearchEntry.entityId &&
                                  focusedSearchEntry.entityType === EntityType.branch) }"
            (mouseenter)="focusedSearchEntry = branch"
            (mouseleave)="focusedSearchEntry = null"
            saveDataWarningInject
            (click)="openBranchCard(branch.entityId, branch.operationType)"
            [attr.sh-id]="'header_search_branch_' + branch.entityId">
          <i class="icon-branch"></i>
          <span class="entity-label animated fadeInRight">
            <span *ngFor="let part of searchService.getBranchMatchingParts(searchQuery, branch.entityName, branch.isLocalization)">{{part}}</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>
