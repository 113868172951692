<notification-dialog-template #dialogRef
                              [isEscapeCloseDialog]="true"
                              [modalWidth]="700"
                              [modalHeight]="250"
                              [theme]="NotificationDialogTheme.blue" [shIdPostfix]="'databaseIsUpdatingDialog'">
  <ng-container header>
    <span class="settings-icon"></span>
    <span>{{'DIALOG.COMMON.DATA_BASE_UPDATE.THE_SENSE_TIME_DATABASE_IS_BEING_UPDATED' | translate}}</span>
  </ng-container>
  <ng-container body>
    <div class="content-text" [attr.translation-id]="'DIALOG.COMMON.DATA_BASE_UPDATE.PLEASE_CONTINUE_TO_USE_SENSETIME_NORMALLY'">
      {{'DIALOG.COMMON.DATA_BASE_UPDATE.PLEASE_CONTINUE_TO_USE_SENSETIME_NORMALLY' | translate}}
    </div>
  </ng-container>
</notification-dialog-template>
