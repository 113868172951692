<div class="section-field report-field">
  <div class="title-and-checkbox-div">
    <div class="title-div" [attr.sh-id]="'led-task-report-title'" [attr.translation-id]="'SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.REPORT'">{{ 'SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.REPORT' | translate }}</div>
    <div class="checkbox-div">
      <div class="checkbox-wrapper" *ngIf="ledTasksService.taskModel.isQuickFilter != null">
        <image-checkbox [ngClass]="'led-task-checkbox'" [isChecked]="ledTasksService.taskModel.isQuickFilter.defaultValue" (isCheckedChanged)="toggleCheckbox()"></image-checkbox>
        <div class="checkbox-text">{{ 'LED_TASKS.QUICK_FILTER.' + ledTasksService.taskModel.isQuickFilter.code | translate }}</div>
      </div>
    </div>
  </div>
  <div class="value-div">
    <regular-dropdown-list [colorScheme]="DropdownColorScheme.greyBlue"
                           [items]="ledTasksService.taskModel.reportsList"
                           [placeholder]="'LED_TASKS.TASK_EDITOR.SELECT_REPORT'"
                           [shIdPostfix]="'reports-dropdown'"
                           [isFieldError]="ledTasksService.isFieldError(LedTaskFields.report)"
                           [allowClear]="true"
                           [selectedValue]="ledTasksService.taskModel.selectedReportItem"
                           (onItemSelected)="selectReportId($event)"></regular-dropdown-list>
  </div>
</div>
<div class="section-field effective-groups-fields">
  <div class="title-div" [attr.translation-id]="'LED_TASKS.TASK_EDITOR.EFFECTIVE_GROUPS'">{{ 'LED_TASKS.TASK_EDITOR.EFFECTIVE_GROUPS' | translate }}</div>
  <div class="groups-autocomplete-div">
    <regular-auto-complete
      #effectiveGroupsAutoComplete
      [isFieldError]="ledTasksService.isFieldError(LedTaskFields.effectiveGroups)"
      [hasIcon]="true"
      [shIdPostfix]="'effective-groups-led-tasks'"
      [colorScheme]="AutoCompleteColorScheme.GreyBlue"
      [placeholder]="groupListPlaceholder"
      [isColorPlaceholderBlack]="isAllGroupsShown"
      [isAllowUnmatchedSelection]="false"
      [caseInvariant]="true"
      [items]="groupsList"
      [maxResults]="6"
      (editingCompleted)="onGroupSelected($event)"
      [searchQuery]="groupSearchQuery"
    ></regular-auto-complete>
  </div>
  <chip-list class="groups-list-div">
    <ng-container *ngFor="let group of ledTasksService.taskModel.selectedGroups">
      <chips-item [chipItem]="group" (onRemoveItemClicked)="removeSelectedGroup(group)"></chips-item>
    </ng-container>
  </chip-list>
</div>
