import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {

  // tslint:disable-next-line:no-any
  public transform(value: number): any {
      return Math.round(value);
  }

}
