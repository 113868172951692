import {Injectable} from '@angular/core';
import {RectIntersectionResultType, UtilsService} from '../../../services/utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class ClientSessionRecorderDomService {

  constructor(private readonly utilsService: UtilsService) { }

  public getElementXpath(element : Element) : string {
    let originalElement = element;
    var xpath = '';
    while (element != null)
    {
      let id = '';
      if(element.parentElement != null) {
        let sameNameElements : Element[] = [];
        for (let childIndex = 0; childIndex < element.parentElement.children.length; childIndex++) {
          let otherChildElement = element.parentElement.children.item(childIndex);
          if(otherChildElement.tagName == element.tagName) {
            sameNameElements.push(otherChildElement)
          }
        }
        if(sameNameElements.length > 1) {
          id = '[' + (sameNameElements.indexOf(element) + 1) + ']';
        }
      }
      xpath = '/' + element.tagName.toLowerCase() + id + xpath;
      element = element.parentElement;
    }
    let evaluatedDocument = document.evaluate(xpath, document, null, XPathResult.ANY_TYPE, null);
    let singleNodeValue = evaluatedDocument.iterateNext();
    if(singleNodeValue == originalElement) {
      return xpath;
    }
    return null;
  }

  public findElementShId(element : Element) : string {
    let shId = element.getAttribute('sh-id');
    if(shId != null) {
      return shId;
    }
    let originalElement = element;
    let originalElementRect = originalElement.getBoundingClientRect();
    while (element != null) {
      element = element.parentElement;
      if(element == null) {
        continue;
      }
      let elementBoundingRect = element.getBoundingClientRect();
      if(this.utilsService.domRectIntersect(elementBoundingRect, originalElementRect) != RectIntersectionResultType.NoIntersection) {
        shId = element.getAttribute('sh-id');
        if(shId != null) {
          return shId;
        }
      }
    }
    return null;
  }

  public findElementByXpath(xpath : string) : Element {
    let evaluatedDocument = document.evaluate(xpath, document, null, XPathResult.ANY_TYPE, null);
    return <Element>evaluatedDocument.iterateNext();
  }

  public findElementByShId(elementType:string, shId : string) : Element {
    let evaluatedDocument = document.evaluate("//" + elementType + "[@sh-id='" + shId + "']", document, null, XPathResult.ANY_TYPE, null);
    return <Element>evaluatedDocument.iterateNext();
  }
}
