<div outsideClickHandler (onOutsideClick)="closeDropdown()">
  <div class="chart-series-dropdown-list-toggle" [ngClass]="{'open': isOpen}" [attr.sh-id]="'include_in_report_toggle'" (click)="toggleDropdown()">
    <div class="toggle-text" [attr.translation-id]="'ANIMAL.GRAPH.SETUP'">{{ 'ANIMAL.GRAPH.SETUP' | translate }}</div>
    <div class="dropdown-icon" [ngClass]="{ close: !isOpen, open: isOpen }"></div>
    <div class="line-shadow" *ngIf="isOpen"></div>
  </div>
  <div class="chart-series-dropdown-popup" *ngIf="isOpen">
    <div class="header-section">
      <div class="restore-defaults-button" (click)="onRestoreToDefaults()">
        <div class="restore-defaults-icon"></div>
      </div>
      <div class="seperator"></div>
      <div class="close-button" (click)="toggleDropdown()">
        <div class="close-icon"></div>
      </div>
    </div>
    <div class="config-section">
      <div class="config-row" *ngFor="let seriesItem of seriesSelectionsInternal" (click)="toggleSeriesItem(seriesItem)">
        <div class="config-icon" [ngClass]="{ selected: seriesItem.isSelected && seriesItem.isRemovable,
                                              notSelected: !seriesItem.isSelected && seriesItem.isRemovable,
                                              disabled: !seriesItem.isRemovable }"></div>
        <span [ngClass]="'icon-' + seriesItem.name"></span>
        <span class="config-text">{{translationPrefix + seriesItem.name | translate}}</span>
      </div>
    </div>
    <div class="buttons-section">
      <div class="save-button" (click)="apply(false)">
        <div class="text" [attr.translation-id]="'COMMON.BUTTON.APPLY_WITH_OUT_SAVING'">{{ 'COMMON.BUTTON.APPLY_WITH_OUT_SAVING' | translate }}</div>
      </div>
      <div class="seperator"></div>
      <div class="close-button" [attr.sh-id]="'close'" (click)="apply(true)">
        <div class="text" [attr.translation-id]="'COMMON.BUTTON.SAVE'">{{ 'COMMON.BUTTON.SAVE' | translate }}</div>
      </div>
    </div>
    <div class="corner"></div>
  </div>
</div>
