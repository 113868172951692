import {Directive, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {SaveDataViewStateService} from '../../../services/ui/view-state/save-data-view-state.service';

@Directive({
  selector: '[saveDataWarningInject]'
})
export class SaveDataWarningInjectDirective  implements OnInit, OnDestroy {

  constructor(private warningInjectElement: ElementRef,
              private saveDataViewStateService:SaveDataViewStateService) { }

  public ngOnInit(): void {
    this.saveDataViewStateService.addWarningInjectElement(this.warningInjectElement.nativeElement);
  }

  public ngOnDestroy(): void {
    this.saveDataViewStateService.removeWarningInjectElement(this.warningInjectElement.nativeElement);
  }
}
