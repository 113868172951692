import {Component, Input, OnInit} from '@angular/core';
import {IDashboardDetailedKPI} from '../../../../../services/dashboard/model/dashboard-kpi.interface';

@Component({
  selector: 'dashboard-kpi-graph-no-data',
  templateUrl: './dashboard-kpi-graph-no-data.component.html',
  styleUrls: ['./dashboard-kpi-graph-no-data.component.scss']
})
export class DashboardKpiGraphNoDataComponent implements OnInit {
  
  @Input()
  public details: IDashboardDetailedKPI;
  
  constructor() { }

  public ngOnInit() {
  }

}
