import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoggedInLayoutComponent} from './logged-in-layout.component';
import {SetupGuard} from '../services/setup/route-guards/setup.guard';
import {LoggedInGuard} from '../services/auth/route-guards/logged-in.guard';
import {ReportsComponent} from './reports/reports.component';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ShSharedModule} from '../common/sh-shared.module';

const routes: Routes = [{
  path: '',
  canActivate: [SetupGuard, LoggedInGuard],
  component: LoggedInLayoutComponent,
  children: [
    {
      path: '',
      redirectTo: '/dashboard',
      pathMatch: 'full'
    },
    {
      path: 'dashboard',
      loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'reports/:key',
      component: ReportsComponent
    },
    {
      path: 'manage',
      loadChildren: () => import('./manage/manage.module').then(m => m.ManageModule)
    },
    {
      path: 'system',
      loadChildren: () => import('./system/system.module').then(m => m.SystemModule)
    },
    {
      path: 'sorting-gate',
      loadChildren: () => import('./sorting-gate/sorting-gate.module').then(m => m.SortingGateModule)
    },
    {
      path: 'led-tasks',
      loadChildren: () => import('./led-tasks/led-tasks.module').then(m => m.LedTasksModule)
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes), FormsModule, CommonModule, ShSharedModule],
  exports: [RouterModule]
})
export class LoggedInLayoutRoutingModule { }
