export interface IFarmStatisticsFinishingWidgetContent {
  totalFemales: number;
  totalMales: number;
  threeToSixMonth?: IMalesFemales;
  sixToNineMonth?: IMalesFemales;
  nineToTwelveMonth?: IMalesFemales;
  twelveToFifteenMonth?: IMalesFemales;
  fifteenToEighteenMonth?: IMalesFemales;
  eighteenPlusMonth?: IMalesFemales;
}

export enum FarmStatisticsWidgetName {
  FarmStatisticsDairy = 'FarmStatisticsDairy',
  FarmStatisticsBeef = 'FarmStatisticsBeef',
  FarmStatisticsFinishing = 'FarmStatisticsFinishing',
}

export interface IMalesFemales {
  females: number;
  males: number;
}

export class FarmStatisticsBeefBarChartData implements IMalesFemales {
  public males: number;
  public females: number;
  public order: number;
  public ageTranslationKey: string;
  public highestAggrValue: number;

  constructor(animalCounters: IMalesFemales, order: number, ageTranslationKey: string, highestAggrValue: number) {
    this.males = animalCounters.males;
    this.females = animalCounters.females;
    this.order = order;
    this.ageTranslationKey = ageTranslationKey;
    this.highestAggrValue = highestAggrValue;
  }

  public getAggregatedValue(): number {
    if (this.males + this.females === 0) {
      return null;
    } else {
      return this.males + this.females;
    }
  }
}
