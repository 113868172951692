import {HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse, HttpResponseBase} from '@angular/common/http';
import {ConfigService} from '../../services/config/config.service';
import * as _ from 'lodash';
import {Injectable} from '@angular/core';

export class MockHttpMessageTemplate {
  method: string;
  requestHeaders: any;
  requestBody: any;
  responseHeaders: any;
  responseBody: any;
  responseStatus: any;
  isErrorResponse: boolean;
  errorResponseBody?: any;
}

export class MockHttpEndpointTemplateState {
  defaultEndpointTemplate:MockHttpEndpointTemplate;
  currentEndpointTemplate:MockHttpEndpointTemplate;
  file:string;
}

export class MockHttpEndpointTemplate {
  endpoint: string;
  messages: MockHttpMessageTemplate[];
}

export class HttpMessageTrace {
  endpoint: string;
  method: string;
  requestHeaders: any;
  requestBody: any;
  responseHeaders: any;
  responseStatus: string;
  date: Date;
}

@Injectable({
  providedIn: 'root'
})
export class MockHttpService {

  private templatesMap: Map<string, MockHttpEndpointTemplateState> = new Map<string, MockHttpEndpointTemplateState>();

  public httpMessagesHistory : HttpMessageTrace[] = [];

  private static readonly maxHistoryMessages: number = 20;

  public handleMessageDelayMs : number = 0;

  constructor(private httpClient: HttpClient,
              public configService: ConfigService) {

  }

  public traceWebApiMessage(request: HttpRequest<any>,
                            response: HttpResponseBase,
                            outputToConsole: boolean) {

    const endpointTemplate = new MockHttpEndpointTemplate();
    endpointTemplate.endpoint = this.resolveEndpointName(request);
    endpointTemplate.messages = [new MockHttpMessageTemplate()];
    endpointTemplate.messages[0].method = request.method;
    endpointTemplate.messages[0].requestHeaders = this.serializeHttpHeaders(request.headers);
    endpointTemplate.messages[0].requestBody = request.body;
    endpointTemplate.messages[0].responseHeaders = this.serializeHttpHeaders(response.headers);
    if(response instanceof HttpResponse){
      endpointTemplate.messages[0].responseBody = response.body;
    } else if(response instanceof  HttpErrorResponse){
      endpointTemplate.messages[0].responseBody = response.error;
    }
    endpointTemplate.messages[0].responseStatus = response.status;
    if(outputToConsole) {
      console.log(JSON.stringify(endpointTemplate));
    }
    this.httpMessagesHistory.push({
      date: new Date(),
      endpoint: endpointTemplate.endpoint,
      method: request.method,
      requestHeaders: endpointTemplate.messages[0].requestHeaders,
      requestBody: _.cloneDeep(request.body),
      responseHeaders: endpointTemplate.messages[0].responseHeaders,
      responseStatus: endpointTemplate.messages[0].responseStatus
    });

    if(this.httpMessagesHistory.length > MockHttpService.maxHistoryMessages) {
      this.httpMessagesHistory = this.httpMessagesHistory.slice(1, MockHttpService.maxHistoryMessages + 1);
    }
  }

  public async handleRequest(request: HttpRequest<any>): Promise<HttpResponseBase> {
    let endpointName = this.resolveEndpointName(request);
    if(!this.templatesMap.has(endpointName))
    {
      let candidateEndpointName : string = '';
      let keys = Array.from(this.templatesMap.keys());
      for (let key of keys) {
        if(endpointName.indexOf(key) == 0 &&
          key.length > candidateEndpointName.length) {
          candidateEndpointName = key;
        }
      }
      if(candidateEndpointName.length == 0) {
        return null;
      }
      console.log('The request exact endpoint mock not found, it replaced with following mock\nREQUEST: ' + endpointName + '\n   MOCK:' + candidateEndpointName);
      endpointName = candidateEndpointName;
    }
    let templateState = this.templatesMap.get(endpointName);
    await this.loadEndpointTemplate(templateState, endpointName);
    const messageTemplate = templateState.currentEndpointTemplate.messages
      .find(msg => {
        return msg.method === request.method;
      });
    if (messageTemplate != null) {
      if(messageTemplate.isErrorResponse) {
        return new HttpErrorResponse({
          headers: this.deserializeHttpHeaders(messageTemplate.responseHeaders),
          status: messageTemplate.responseStatus,
          error: _.cloneDeep(messageTemplate.responseBody || messageTemplate.errorResponseBody)
        });
      }
      else {
        return new HttpResponse({
          headers: this.deserializeHttpHeaders(messageTemplate.responseHeaders),
          status: messageTemplate.responseStatus,
          body: _.cloneDeep(messageTemplate.responseBody)
        });
      }
    }
  }

  public async getTemplate(endpoint: string): Promise<MockHttpEndpointTemplate> {

    if (this.templatesMap.has(endpoint)) {
      let templateState = this.templatesMap.get(endpoint);
      await this.loadEndpointTemplate(templateState, endpoint)
      return templateState.currentEndpointTemplate;
    }
    return null;

  }

  public async setTemplate(template: MockHttpEndpointTemplate) {
    let templateState = this.templatesMap.get(template.endpoint);
    if(templateState != null) {
      templateState.currentEndpointTemplate = template;
    }
  }

  public resetTemplatesToDefault() {
    if (this.configService.configuration.useMockHttp) {
      this.initTemplates();
    }
  }

  public get endpoints() : string[] {
    return Array.from(this.templatesMap.keys());
  }

  public serializeHttpHeaders(httpHeaders: HttpHeaders): any {
    return httpHeaders.keys()
      .map((headerKey) => {
        return {[headerKey]: httpHeaders.getAll(headerKey)};
      });
  }

  public deserializeHttpHeaders(httpHeadersArray: any[]): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    httpHeadersArray.forEach(httpHeaderObject => {
      Object.keys(httpHeaderObject).forEach(key => {
        httpHeaders = httpHeaders.append(key, httpHeaderObject[key]);
      });
    });
    return httpHeaders;
  }

  public resolveEndpointName(request: HttpRequest<any>) : string {
    return request.urlWithParams.replace(this.configService.configuration.apiUrl, '');
  }

  private async loadEndpointTemplate(templateState:MockHttpEndpointTemplateState, endpointName:string) {
    if(templateState.defaultEndpointTemplate == null) {
      templateState.defaultEndpointTemplate  = await this.httpClient.get<MockHttpEndpointTemplate>(templateState.file).toPromise();
      if(templateState.defaultEndpointTemplate .endpoint != endpointName) {
        throw new Error('Mapped endpoint: ' + endpointName +
          ' not match to:' + templateState.defaultEndpointTemplate.endpoint +
          ' in file:' + templateState.file);
      }
    }
    if(templateState.currentEndpointTemplate == null) {
      templateState.currentEndpointTemplate = _.cloneDeep<MockHttpEndpointTemplate>(templateState.defaultEndpointTemplate);
    }
  }

  private initTemplates() {

    //Loading server setup
    this.initTemplateState('/rest/api/server/setup',`assets/testing/templates/server-setup.json`);

    //Loading ping
    this.initTemplateState('/rest/api/server/ping',`assets/testing/templates/ping.json`);

    //Loading sync
    this.initTemplateState('/rest/api/v3/server/sync',`assets/testing/templates/sync.json`);

    //Loading auth template
    this.initTemplateState('/rest/api/v2/auth',`assets/testing/templates/auth.json`);

    //Loading login template
    this.initTemplateState('/rest/api/v2/auth/login',`assets/testing/templates/login.json`);

    //Loading chartLegendConfiguration template
    this.initTemplateState('/rest/api/v3/auth/configuration',`assets/testing/templates/configuration.json`);

    //Loading eula details template
    this.initTemplateState('/rest/api/v2/auth/eula',`assets/testing/templates/eula.json`);

    //Loading search
    this.initTemplateState('/rest/api/search',`assets/testing/templates/search.json`);

    //Loading GET maintenance calls template
    this.initTemplateState('/rest/api/dashboard?projection=maintenance',`assets/testing/templates/maintenance-call.json`);

    //Loading GET dashboard accordion widgets template
    this.initTemplateState('/rest/api/dashboardwidgets',`assets/testing/templates/dashboard-widgets.json`);

    //Loading GET dashboard accordion available widgets template
    this.initTemplateState('/rest/api/dashboardwidgets/available',`assets/testing/templates/dashboard-available-widgets.json`);

    //Loading dashboard KPI template
    this.initTemplateState('/rest/api/dashboardkpis',`assets/testing/templates/dashboard-detailed-kpis.json`);

    //Loading dashboard available KPI template
    this.initTemplateState('/rest/api/dashboardkpis/available',`assets/testing/templates/dashboard-available-kpis.json`);

    //Loading forgot password template
    this.initTemplateState('/rest/api/auth/forgotPassword',`assets/testing/templates/forgot-password.json`);

    //Loading reset password template
    this.initTemplateState('/rest/api/auth/resetPassword',`assets/testing/templates/reset-password.json`);

    //Loading groups template
    this.initTemplateState('/rest/api/v3/groups',`assets/testing/templates/groups-v3.json`);

    //Loading groups ids template
    this.initTemplateState('/rest/api/v3/groups?offset=0&limit=0&type=full&includeFilterMetaData=true&projection=GroupDatabaseIDCalculation',`assets/testing/templates/groups-ids-v3.json`);

    //Loading groups for beef template
    this.initTemplateState('/rest/api/v3/finishing/groups?offset=0&limit=30&type=full&includeFilterMetaData=true', 'assets/testing/templates/groups-ids-v3-beef.json');

    //Loading branches for beef template
    this.initTemplateState('/rest/api/finishing/groupbranches?offset=0&limit=0&type=full&includeFilterMetaData=true&projection=BranchDatabaseIDCalculation', 'assets/testing/templates/beef-branches.json');

    //Loading branches for beef template
    this.initTemplateState('/rest/api/finishing/groupbranches?offset=0&limit=30&type=full&includeFilterMetaData=true', 'assets/testing/templates/beef-branches-list.json');

    //Loading branches for beef for print template
    this.initTemplateState('/rest/api/finishing/groupbranches?offset=0&limit=0&type=full&includeFilterMetaData=true', 'assets/testing/templates/beef-branches-list-print.json');

    //Loading branches for beef for sort ASC template
    this.initTemplateState('/rest/api/finishing/groupbranches?offset=0&limit=30&type=full&pastReportTime=1574698510&reportUid=1939501198&includeFilterMetaData=true&sort=-GroupBranchNameCalculation', 'assets/testing/templates/beef-branches-list-sort.json');

    //Loading Create branches for beef
    this.initTemplateState('/rest/api/finishing/groupbranches', 'assets/testing/templates/create-branch.json');

    // Loading Branch graph details (counters: animals & tagged animals)
    this.initTemplateState('/rest/api/finishing/groupbranches/9?projection=graph', 'assets/testing/templates/branch-graph-details.json');

    //Loading Led Tasks List
    this.initTemplateState('/rest/api/ledoperation/scheduledtask', `assets/testing/templates/led-tasks-list.json`);

    //Loading Led Task Editor - Reports List metadata
    this.initTemplateState('/rest/api/ledoperation/reports', `assets/testing/templates/led-tasks-editor-reports-list.json`);

    //Loading Led Task Editor - Edit request
    this.initTemplateState('/rest/api/ledoperation/scheduledtask/24', `assets/testing/templates/led-task-editor-edit-request.json`);

    //Loading active animals template
    this.initTemplateState('/rest/api/animals',`assets/testing/templates/active-animals.json`);

    //Loading active animals for beef template
    this.initTemplateState('/rest/api/finishing/animals?offset=0&limit=30&type=full&includeFilterMetaData=true&isRefresh=true', 'assets/testing/templates/active-animals-beef.json');

    //Loading active animals ids template
    this.initTemplateState('/rest/api/animals?offset=0&limit=0&type=full&includeFilterMetaData=true&projection=CowDatabaseIDCalculation',`assets/testing/templates/active-animals-ids.json`);

    //Loading active animals ids for finishing template
    this.initTemplateState('/rest/api/finishing/animals?offset=0&limit=0&type=full&includeFilterMetaData=true&projection=CowDatabaseIDCalculation',`assets/testing/templates/active-animals-ids-finishing.json`);

    //Loading culled animals template
    this.initTemplateState('/rest/api/animals/culled',`assets/testing/templates/culled-animals.json`);

    //Loading farm alerts template
    this.initTemplateState('/rest/api/farm/alerts',`assets/testing/templates/farm-alerts.json`);

    //Loading reports list template
    this.initTemplateState('/rest/api/v2/reports',`assets/testing/templates/reports.json`);

    //Loading tags-maintenance-report
    this.initTemplateState('/rest/api/v2/reports/6',`assets/testing/templates/tags-maintenance-report.json`);

    //Loading animal-distress-report
    this.initTemplateState('/rest/api/v2/reports/7',`assets/testing/templates/animal-distress-report.json`);

    // Loading animals-to-inspect-report
    this.initTemplateState('/rest/api/v2/reports/11',`assets/testing/templates/animals-to-inspect-report.json`);

    //Loading anestrus-cows-report (by full path)
    this.initTemplateState('/rest/api/v2/reports/1?offset=0&limit=30&type=full&includeFilterMetaData=true&useDefaultFilter=true&isRefresh=true',`assets/testing/templates/anestrus-cows-report-by-fullpath.json`);

    //Loading anestrus-cows-report
    this.initTemplateState('/rest/api/v2/reports/1',`assets/testing/templates/anestrus-cows-report.json`);

    //Loading finishing health report
    this.initTemplateState('/rest/api/v2/reports/105?offset=0&limit=30&type=full&includeFilterMetaData=true&useDefaultFilter=true&isRefresh=true', `assets/testing/templates/finishing-health-report-by-fullpath.json`);

    //Loading Group routine report
    this.initTemplateState('/rest/api/v2/reports/12', `assets/testing/templates/group-routine-report.json`);

    //Loading Group routine group card details
    this.initTemplateState('/rest/api/v3/groups/7', `assets/testing/templates/group-routine-group-card-details.json`);

    //Loading Group card details for finishing with projection = groupIdCalculation
    this.initTemplateState('/rest/api/v3/finishing/groups?offset=0&limit=0&type=full&includeFilterMetaData=true&projection=GroupDatabaseIDCalculation', `assets/testing/templates/group-card-finishing-projection-id.json`);

    //Loading Group card details for finishing group
    this.initTemplateState('/rest/api/v3/finishing/groups/17', `assets/testing/templates/group-card-finishing-details.json`);

    //Loading Group card details for finishing group
    this.initTemplateState('/rest/api/v3/finishing/groups/25', `assets/testing/templates/group-card-finishing-details-2.json`);

    //Loading Group card animals list for finishing group
    this.initTemplateState('/rest/api/v3/finishing/groups/17/animals?offset=0&limit=100&culled=false&sort=-insertedDateTime&projection=includeAverages', `assets/testing/templates/group-card-finishing-animals.json`);

    //Loading Group card animals list for finishing group
    this.initTemplateState('/rest/api/v3/finishing/groups/25/animals?offset=0&limit=100&culled=false&sort=-insertedDateTime&projection=includeAverages', `assets/testing/templates/group-card-finishing-animals-2.json`);

    //Loading Group card animals list for finishing group for printing
    this.initTemplateState('/rest/api/v3/finishing/groups/17/animals?offset=0&limit=0&culled=false&sort=-insertedDateTime&projection=includeAverages', `assets/testing/templates/group-card-finishing-animals-for-printing.json`);

    //Loading Group routine group card groupInfo
    this.initTemplateState('/rest/api/v3/groups/7?projection=groupInfo', `assets/testing/templates/group-routine-group-card-group-info.json`);

    //Loading Group routine group card totals
    this.initTemplateState('/rest/api/v3/groups/7?projection=graph', `assets/testing/templates/group-routine-group-card-group-totals.json`);

    //Loading Group routine group card graphs
    this.initTemplateState('/rest/api/groups/7/graphs/3?projection=flat&resolution=hour', `assets/testing/templates/group-routine-group-card-group-graph.json`);

    //Loading farm health settings
    this.initTemplateState('/rest/api/farm/health',`assets/testing/templates/farm-health-settings.json`);

    //Loading finishing farm health settings
    this.initTemplateState('/rest/api/finishing/farm/health', `assets/testing/templates/manage-settings-finishing-health-report.json`);

    //Loading reproduction settings
    this.initTemplateState('/rest/api/farm/reproduction',`assets/testing/templates/farm-reproduction-settings.json`);

    //Loading animals in heat
    this.initTemplateState('/rest/api/v2/reports/13',`assets/testing/templates/animals-in-heat-report.json`);

    //Loading group routine heat stress report
    this.initTemplateState('/rest/api/v2/reports/14',`assets/testing/templates/group-routine-heat-stress-report.json`);

    //Loading sorting shifts
    this.initTemplateState('/rest/api/sorting/shifts',`assets/testing/templates/sorting-shifts.json`);

    //Loading sorting shifts
    this.initTemplateState('/rest/api/sorting/tasks',`assets/testing/templates/sorting-tasks.json`);

    //Loading sorting shifts history
    this.initTemplateState('/rest/api/sorting/shifts/history',`assets/testing/templates/sorting-shifts-history.json`);

    //Loading sorting report
    this.initTemplateState('/rest/api/v2/sorting/report/22',`assets/testing/templates/sorting-report.json`);

    // Loading sorting scheduler
    this.initTemplateState('/rest/api/sorting/scheduler?relativeWeek=0', 'assets/testing/templates/sorting-scheduler_relative-week-0.json');

    // Loading groups
    this.initTemplateState('/rest/api/v3/groups?offset=0&limit=1000&type=short&includeFilterMetaData=false', 'assets/testing/templates/groups-for-sorting-task.json');

    //Loading system account users
    this.initTemplateState('/rest/api/v1/system/users',`assets/testing/templates/system-account-users.json`);

    //Loading animal details
    this.initTemplateState('/rest/api/animals/1/details',`assets/testing/templates/animal-details.json`);

    //Loading animal details for beef finishing
    this.initTemplateState('/rest/api/finishing/animals/1/details',`assets/testing/templates/animal-details-beef-finishing.json`);

    //Loading animal details for beef finishing - animal 888
    this.initTemplateState('/rest/api/finishing/animals/888/details', `assets/testing/templates/animal-details-beef-animal888-finishing.json`);

    //Loading animal details - animal info
    this.initTemplateState('/rest/api/animals/1/details?projection=animalInfo',`assets/testing/templates/animal-details-animal-info.json`);

    //Loading animal details - animal info for finishing animal
    this.initTemplateState('/rest/api/animals/888/details?projection=animalInfo', `assets/testing/templates/animal-details-animal-info-finishing.json`);

    //Loading animal card health graph for beef finishing animal
    this.initTemplateState('/rest/api/finishing/animals/888/graphs/10?projection=flat&resolution=hour&showEvents=true&series=finishingHealthIndex,dailyRumination', `assets/testing/templates/animal-card-finishing-health-graph.json`);

    // Loading animal card health graph setup for beef finishing animal
    this.initTemplateState('/rest/api/graphs/10?projection=getsetup', `assets/testing/templates/animal-card-finishing-health-graph-setup.json`);

    //Loading animal heat chart setup
    this.initTemplateState('/rest/api/graphs/1?projection=getsetup',`assets/testing/templates/animal-heat-chart-setup.json`);

    //Loading animal heat chart hour
    this.initTemplateState('/rest/api/animals/1/graphs/1?projection=flat&resolution=hour',`assets/testing/templates/animal-heat-chart-hour.json`);

    //Loading animal heat chart day
    this.initTemplateState('/rest/api/animals/1/graphs/1?projection=flat&resolution=day',`assets/testing/templates/animal-heat-chart-day.json`);

    //Loading animal health chart setup
    this.initTemplateState('/rest/api/graphs/2?projection=getsetup',`assets/testing/templates/animal-health-chart-setup.json`);

    //Loading animal health chart hour
    this.initTemplateState('/rest/api/animals/1/graphs/2?projection=flat&resolution=hour',`assets/testing/templates/animal-health-chart-hour.json`);

    //Loading animal health chart day
    this.initTemplateState('/rest/api/animals/1/graphs/2?projection=flat&resolution=day',`assets/testing/templates/animal-health-chart-day.json`);

    //Loading animal events timeline
    this.initTemplateState('/rest/api/animals/1/details?projection=eventsTimeline',`assets/testing/templates/animal-events-timeline.json`);

    //Loading animal events timeline
    this.initTemplateState('/rest/api/finishing/animals/1/details?projection=eventsTimeline',`assets/testing/templates/animal-events-timeline-finishing.json`);

    //Loading animal events timeline for finishing animal
    this.initTemplateState('/rest/api/finishing/animals/888/details?projection=eventsTimeline',`assets/testing/templates/animal-events-timeline-finishing.json`);

    //Loading animals events
    this.initTemplateState('/rest/api/animals/1/events',`assets/testing/templates/animal-events.json`);

    //Loading animal events for finishing animal
    this.initTemplateState('/rest/api/animals/888/events', `assets/testing/templates/animal-events-finishing.json`);

    //Loading animal info metadata
    this.initTemplateState('/rest/api/animals/1/details?projection=animalInfoMetaData',`assets/testing/templates/animal-info-metadata.json`);

    //Loading animal info metadata for animal finishing
    this.initTemplateState('/rest/api/animals/888/details?projection=animalInfoMetaData', `assets/testing/templates/animal-info-metadata-finishing.json`);

    //Loading system kpis
    this.initTemplateState('/rest/api/system/kpis',`assets/testing/templates/system-kpis.json`);

    //Loading system chartLegendConfiguration
    this.initTemplateState('/rest/api/system/configurations',`assets/testing/templates/system-configurations.json`);

    //Loading system account
    this.initTemplateState('/rest/api/system/account',`assets/testing/templates/system-account.json`);

    //Loading group details
    this.initTemplateState('/rest/api/v3/groups/1',`assets/testing/templates/group-details.json`);

    //Loading group metadata
    this.initTemplateState('/rest/api/v3/groups?projection=groupsMetaData',`assets/testing/templates/groups-metadata-v3.json`);

    //Loading group metadata for beef
    this.initTemplateState('/rest/api/v3/finishing/groups?projection=groupsMetaData', 'assets/testing/templates/groups-metadata-v3-beef.json');

    //Loading group animals
    this.initTemplateState('/rest/api/v3/groups/1/animals',`assets/testing/templates/group-animals.json`);

    //Loading group graph details
    this.initTemplateState('/rest/api/v3/groups/1?projection=graph',`assets/testing/templates/group-graph-details.json`);

    //Loading group chart activity
    this.initTemplateState('/rest/api/groups/1/graphs/3?projection=flat&resolution=hour',`assets/testing/templates/group-chart-activity.json`);

    //Loading group chart rumination
    this.initTemplateState('/rest/api/groups/1/graphs/4?projection=flat&resolution=hour',`assets/testing/templates/group-chart-rumination.json`);

    //Loading group chart heat setup
    this.initTemplateState('/rest/api/graphs/6?projection=getsetup',`assets/testing/templates/group-chart-heat-setup.json`);

    //Loading group chart heat
    this.initTemplateState('/rest/api/groups/1/graphs/6?projection=flat&resolution=hour',`assets/testing/templates/group-chart-heat.json`);

    //Loading group chart heat trend setup
    this.initTemplateState('/rest/api/graphs/7?projection=getsetup',`assets/testing/templates/group-chart-heat-trend-setup.json`);

    //Loading group chart heat trend
    this.initTemplateState('/rest/api/groups/1/graphs/7?projection=flat&resolution=hour',`assets/testing/templates/group-chart-heat-trend.json`);

    //Loading group chart consistency
    this.initTemplateState('/rest/api/groups/1/graphs/5?projection=flat',`assets/testing/templates/group-chart-consistency.json`);

    //Loading Add Male Metadata
    this.initTemplateState('/rest/api/animals?projection=malesMetaData',`assets/testing/templates/add-males-metadata.json`);

    //Loading Add Female Metadata
    this.initTemplateState('/rest/api/animals?projection=cowsMetaData',`assets/testing/templates/add-cows-metadata.json`);

    //Loading Add Male Metadata
    this.initTemplateState('/rest/api/finishing/animals?projection=malesMetaData',`assets/testing/templates/add-males-metadata-finishing.json`);

    //Loading Add Female Metadata
    this.initTemplateState('/rest/api/finishing/animals?projection=cowsMetaData',`assets/testing/templates/add-cows-metadata-finishing.json`);

    //Loading System Herdmanagement Metadata
    this.initTemplateState('/rest/api/system/herdmanagement',`assets/testing/templates/system-herdmanagement.json`);

    //Loading System Herdmanagement ThirdParties Results Metadata
    this.initTemplateState('/rest/api/system/herdmanagement/thirdparties/results',`assets/testing/templates/system-herdmanagement-thirdparties-results.json`);

    //Loading import animals summary Metadata
    this.initTemplateState('/rest/api/import/animals/summary',`assets/testing/templates/import-animals-summary.json`);

    //Loading import animals details Metadata
    this.initTemplateState('/rest/api/import/animals/details',`assets/testing/templates/import-animals-details.json`);

    //Loading system tags Metadata
    this.initTemplateState('/rest/api/system/tags',`assets/testing/templates/system-tags.json`);

    //Loading system tags kpis Metadata
    this.initTemplateState('/rest/api/system/tags/kpis',`assets/testing/templates/system-tags-kpis.json`);

    //Loading system devices Metadata
    this.initTemplateState('/rest/api/system/devices',`assets/testing/templates/system-devices.json`);

    //Loading system device 2 main Metadata
    this.initTemplateState('/rest/api/system/devices/2',`assets/testing/templates/system-devices-2.json`);

    //Loading system device 2 uptime Metadata
    this.initTemplateState('/rest/api/system/devices/2/uptime',`assets/testing/templates/system-devices-2-uptime.json`);

    //Loading system device 2 uptime correlation id Metadata
    this.initTemplateState('/rest/api/system/devices/2/uptime/622589141',`assets/testing/templates/system-devices-2-uptime-correlation-id.json`);

    //Loading system device 2 rfchannels Metadata
    this.initTemplateState('/rest/api/system/devices/2/rfChannels',`assets/testing/templates/system-devices-2-rfchannels.json`);

    //Loading system device Metadata
    this.initTemplateState('/rest/api/system/devices/5',`assets/testing/templates/system-devices-5.json`);

    //Loading system device 5 uptime Metadata
    this.initTemplateState('/rest/api/system/devices/5/uptime',`assets/testing/templates/system-devices-5-uptime.json`);

    //Loading system device 5 uptime correlation id Metadata
    this.initTemplateState('/rest/api/system/devices/5/uptime/677068912',`assets/testing/templates/system-devices-5-uptime-correlation-id.json`);

    //Loading about Metadata
    this.initTemplateState('/rest/api/system/about',`assets/testing/templates/system-about.json`);

    //Loading system alerts Metadata
    this.initTemplateState('/rest/api/alerts/system',`assets/testing/templates/alerts-system.json`);

    //Loading animal alerts Metadata
    this.initTemplateState('/rest/api/alerts/farm',`assets/testing/templates/alerts-animal.json`);

    //Loading add male
    this.initTemplateState('/rest/api/animals/males',`assets/testing/templates/add-male.json`);

    //Loading add female
    this.initTemplateState('/rest/api/animals/cows',`assets/testing/templates/add-female.json`);

    //Loading animal available events
    this.initTemplateState('/rest/api/animals/1/events?projection=availableEvents',`assets/testing/templates/animal-available-events.json`);

    //Loading change SCR tag meta
    this.initTemplateState('/rest/api/animals/1/events?projection=metadata&type=changeScrTag',`assets/testing/templates/change-scr-tag-meta.json`);

    //Loading manage events
    this.initTemplateState('/rest/api/animals/events', `assets/testing/templates/manage-events.json`);

    //Loading manage - Add event for beef finishing (start Backgrounding event)
    this.initTemplateState('/rest/api/finishing/animals/events', `assets/testing/templates/manage-add-event-beef-finishing.json`);

    //Loading manage events for beef finishing
    this.initTemplateState('/rest/api/finishing/animals/events?offset=0&limit=100&sort=-startDateTime', `assets/testing/templates/manage-events-beef-finishing.json`);

    //Loading manage available events for beef finishing
    this.initTemplateState('/rest/api/finishing/animals/events?projection=availableBatchEvents', `assets/testing/templates/manage-available-events-beef-finishing.json`)

    //Loading manage available events for beef breeding - weaning metadata
    this.initTemplateState('/rest/api/animals/events?projection=batchmetadata&type=weaning', `assets/testing/templates/batch-events-breeding-weaning-metadata.json`);

    //Loading farm upgrade
    this.initTemplateState('/rest/api/server/upgrade', `assets/testing/templates/farm-upgrade.json`);

    //Loading auth registration
    this.initTemplateState('/rest/api/auth/registration',`assets/testing/templates/auth-registration.json`);

    //Loading auth registration farm version
    this.initTemplateState('/rest/api/auth/registration?projection=farmVersion',`assets/testing/templates/auth-registration-farm-version.json`);

    //Loading auth registration farm details
    this.initTemplateState('/rest/api/auth/registration?projection=farmDetails',`assets/testing/templates/auth-registration-farm-details.json`);

    //Loading auth registration third party
    this.initTemplateState('/rest/api/auth/registration?projection=thirdParty',`assets/testing/templates/auth-registration-third-party.json`);

    //Loading auth registration topology
    this.initTemplateState('/rest/api/auth/registration?projection=topology',`assets/testing/templates/auth-registration-topology.json`);

    //Loading dashboard tags
    this.initTemplateState('/rest/api/dashboard?projection=tags',`assets/testing/templates/dashboard-tags.json`);

    //Loading sorting gate is active
    this.initTemplateState('/rest/api/sorting/isactive',`assets/testing/templates/sorting-is-active.json`);

    //Loading sorting pens
    this.initTemplateState('/rest/api/sorting/pens', 'assets/testing/templates/sorting-pens.json');

    //Loading sorting reports
    this.initTemplateState('/rest/api/sorting/reports/', 'assets/testing/templates/sorting-reports.json');

    //Loading import animals file
    this.initTemplateState('/rest/api/import/animals/file',`assets/testing/templates/import-animals-file.json`);

    //Loading tags provisioning software
    this.initTemplateState('/rest/api/provisioning/tags/sw',`assets/testing/templates/provisioning-tags-sw.json`);

    //Loading tags provisioning software session 1
    this.initTemplateState('/rest/api/provisioning/tags/sw/1',`assets/testing/templates/provisioning-tags-sw-1.json`);

    //Data sharing: sign in - poll by correlationId
    this.initTemplateState('/rest/api/datasharing/signin?correlationId=some-correlation-id', `assets/testing/templates/datasharing-signin.json`);

    //Data sharing: sign in - get correlation Id
    this.initTemplateState('/rest/api/datasharing/signin', `assets/testing/templates/datasharing-signin-correlation-id.json`);

    //Loading premium trial details
    this.initTemplateState('/rest/api/v2/auth/premiumTrial',`assets/testing/templates/auth-premiumTrial.json`);

    //Loading available batch events
    this.initTemplateState('/rest/api/animals/events?projection=availableBatchEvents',`assets/testing/templates/available-batch-events.json`);

    //Loading delete animal event
    this.initTemplateState('/rest/api/animals/1/events/1',`assets/testing/templates/delete-animal-event.json`);

    //Loading herd management herds
    this.initTemplateState('/rest/api/system/herdmanagement/herds',`assets/testing/templates/herd-management-herds.json`);

    //Loading System tags > eSense V2 LEDs toggle
    this.initTemplateState('/rest/api/system/ledoperation/toggle', `assets/testing/templates/system-ledoperation-toggle.json`);

    //Loading general batch event metadata
    this.initTemplateState('/rest/api/animals/events?projection=batchmetadata&type=general',`assets/testing/templates/batch-events-general-metadata.json`);

    //Loading branch consistency chart data
    this.initTemplateState('/rest/api/graphs/8?projection=getsetup',`assets/testing/templates/branch-card-consistency-getsetup.json`);

    //Loading branch card itself for branchId = 9
    this.initTemplateState('/rest/api/finishing/groupbranches/9',`assets/testing/templates/branch-card-branch-data.json`);

    //Loading branch card details for branchId = 9
    this.initTemplateState('/rest/api/finishing/groupbranches/9/groups?offset=0&limit=30&type=full&includeFilterMetaData=true', `assets/testing/templates/branch-card-number-0-details.json`);

    //Loading branch heat stress graph setup for branchId = 9
    this.initTemplateState('/rest/api/finishing/groupbranches/9/graphs/8?projection=flat',`assets/testing/templates/branch-card-heat-stress-series.json`);

    //Loading branch consistency graph setup for branchId = 9
    this.initTemplateState('/rest/api/finishing/groupbranches/9/graphs/9?projection=flat',`assets/testing/templates/branch-card-consistency-series.json`);

    //Loading notes
    this.initTemplateState('/rest/api/notes',`assets/testing/templates/notes.json`);

    //Loading notes metadata
    this.initTemplateState('/rest/api/notes?projection=metadata', `assets/testing/templates/notes-metadata.json`);

    //Loading finishing notes
    this.initTemplateState('/rest/api/finishing/notes',`assets/testing/templates/notes-finishing.json`);

    //Loading notes finishing metadata
    this.initTemplateState('/rest/api/finishing/notes?projection=metadata', `assets/testing/templates/notes-finishing-metadata.json`);

    //Loading Group Routine Events
    this.initTemplateState('/rest/api/farm/grouproutineevents',`assets/testing/templates/settings-group-routine-events.json`);

    //Loading Group Routine Events meta
    this.initTemplateState('/rest/api/farm/grouproutineevents?projection=metadata',`assets/testing/templates/settings-group-routine-events-meta.json`);
  }

  private initTemplateState(endpoint:string, file: string) {

//  let value = await this.httpClient.get<MockHttpEndpointTemplate>(file).toPromise();
    if (this.templatesMap.has(endpoint)) {
      let endpointState = this.templatesMap.get(endpoint);
      if(endpointState.file != file) {
        throw new Error('Cannot add template from file:' +
          file +
          '. Endpoint already exist:' +
          endpoint);
      }
      endpointState.currentEndpointTemplate = null;
    }
    else {
      let endpointState = new MockHttpEndpointTemplateState();
      endpointState.file = file;
      this.templatesMap.set(endpoint, endpointState);
    }
  }
}
