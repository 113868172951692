<div class="add-event-wrapper" nonExternalUser>
  <div
    class="add-event-btn"
    [ngClass]="{'add-event-open': isOpen,
      breeding: (applicationBundleRoutingMode === applicationBundleRoutingModes.Breeding && !isOpen),
      finishing: (applicationBundleRoutingMode === applicationBundleRoutingModes.Finishing && !isOpen),
      disabled: !isEventCreationAvailable}"
    (click)="toggleOpen()" saveDataWarningInject>
    <div class="add-icon" *ngIf="!isOpen" sh-id="add_event_btn_add"></div>
    <div class="remove-icon" *ngIf="isOpen" sh-id="add_event_btn_remove"></div>
  </div>
</div>

<corner-container *ngIf="isOpen"
                  sh-id="add_event_container_wrapper"
                  [cornerColor]="isSaveEnabled ? CornerColor.Blue : CornerColor.BlueDisabled"
                  (onCornerButtonClick)="save($event)"
                  [footerHeight]="50"
                  [bodyBackground]="CornerContainerBodyBackground.Blue">
  <ng-container container-body>
    <div class="add-event-section" saveDataEditSection [editAreaName]="EventEditorAreaName">
      <div class="add-new-event-field-div">
        <div class="title-div"
             [attr.translation-id]="'MANAGE.EVENTS.ADD_NEW_BATCH_EVENT'"
             [attr.sh-id]="'add_new_batch_event_title'">
          {{ 'MANAGE.EVENTS.ADD_NEW_BATCH_EVENT' | translate }}
        </div>
        <div class="value-div">
          <animal-card-events-menu #animalCardEventsMenu
                                   [availableEvents]="availableEvents"
                                   (eventSelected)="onEventSelected($event)"
                                   [applicationBundleRoutingMode]="applicationBundleRoutingMode"
                                   [autofocus]="true">
          </animal-card-events-menu>
        </div>
        <div class="selection-date-time-wrapper">
          <div class="date-time-field date-container">
            <div class="form-section-title">
              {{ 'MANAGE.EVENTS.DATE' | translate }}
            </div>
            <div class="form-section-item">
              <date-picker [selectedEpoch]="selectedEpoch"
                           (epochChanged)="epochChanged($event)"
                           [shownRemovableIcon]="false"
                           [calendarIconColor]="'#1999d5'"
                           [isFieldError]="eventDetailsError?.fieldName == 'startDateTime'"
                           [shIdPostfix]="'selected_date'">
              </date-picker>
            </div>
          </div>
          <div class="date-time-field time-container">
            <div class="form-section-title">
              {{ 'REPORTS.GRID.TIME' | translate }}
            </div>
            <div class="form-section-item">
              <time-picker [selectedEpoch]="selectedEpoch"
                           (epochChanged)="clearErrors()"
                           [shownRemovableIcon]="false"
                           [themeColor]="'#1999d5'"
                           [isFieldError]="eventDetailsError?.fieldName == 'startDateTime'"
                           [shIdPostfix]="'selected_time'">
              </time-picker>
            </div>
          </div>
        </div>
      </div>

      <div class="line-seperator"></div>
      <div class="custom-event-form-wrapper"
           *ngIf="selectedEvent">
        <div [ngSwitch]="selectedEvent.type" class="custom-event-form" #eventEditor>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.RemoveScrTag">
            <remove-tag-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </remove-tag-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.RemoveRfIdTag">
            <remove-tag-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </remove-tag-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Abortion">
            <abortion-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              [isAddNewbornsDisabled]="true"
              (modelChanged)="onModelChanged($event)">
            </abortion-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.AbortionSameLactation">
            <abortion-same-lactation-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </abortion-same-lactation-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Breeding">
            <breeding-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </breeding-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Calving">
            <calving-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              [isAddNewbornsDisabled]="true"
              (modelChanged)="onModelChanged($event)">
            </calving-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.ChangeGroup">
            <change-group-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </change-group-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Weaning">
            <weaning-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </weaning-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Culling">
            <culling-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </culling-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.DoNotBreed">
            <do-not-breed-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </do-not-breed-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.DryOff">
            <dry-off-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </dry-off-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.PregnancyCheck">
            <pregnancy-check-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </pregnancy-check-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Weight">
            <weight-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </weight-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.StartBackground">
            <start-background-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </start-background-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.StartFinishing">
            <start-finishing-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </start-finishing-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Castration">
            <castration-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </castration-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.Unsort">
            <unsort-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </unsort-event-form>
          </div>
          <div class="event-template-container" *ngSwitchCase="EventTypeEnum.General">
            <general-event-form
              [mode]="selectionMode"
              [model]="eventDetails"
              [meta]="selectedEventMeta"
              [errorModel]="eventDetailsError"
              (modelChanged)="onModelChanged($event)">
            </general-event-form>
          </div>
        </div>
        <div class="add-animal-sections" *ngIf="selectedEvent">
          <div class="create-event-form">
            <div class="form-section-title">
              <span [attr.translation-id]="'ANIMAL.EVENTS.ADD_EVENT.ANIMAL_ID'">{{ "ANIMAL.EVENTS.ADD_EVENT.ANIMAL_ID" | translate }}</span>
              <span class="batch-selected-animals" *ngIf="selectionMode === 'batch'" translation-id="ANIMAL.EVENTS.ADD_EVENT.TOTAL_ANIMALS_IN_BATCH">
                {{ 'ANIMAL.EVENTS.ADD_EVENT.TOTAL_ANIMALS_IN_BATCH' | translate}}: {{ selectedAnimals.length }}
              </span>
            </div>
            <div class="form-section-item">
              <div class="magnifying-icon" *ngIf="selectionMode == selectionModes.batch"></div>
              <forms-auto-complete #autoCompleteAddAnimal
                                   [ngClass]="{ expended: selectionMode === selectionModes.batch }"
                                   [(ngModel)]="selectedAnimal"
                                   (ngModelChange)="clearErrors()"
                                   [items]="availableAnimals"
                                   [colorScheme]="AutoCompleteColorScheme.BlueWhite"
                                   [shIdPostfix]="'animalId'"
                                   [autoFocus]="true"
                                   [maxResults]="6"
                                   [disabled]="!eventDetails.isValid()"
                                   [placeholder]="(selectionMode === selectionModes.single ? ('MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER' | translate) : ('ANIMAL.EVENTS.ADD_EVENT.BATCH_ENTER_COW_NUMBER' | translate )) + '...'"
                                   (onEnterPress)="onAnimalAutocompleteEnterPress($event)"
                                   [isAllowUnmatchedSelection]="false">
              </forms-auto-complete>
              <button class="batch-mode-button"
                      *ngIf="selectionMode == selectionModes.single"
                      (click)="onBatchModeClick()" translation-id="ANIMAL.EVENTS.ADD_EVENT.BATCH_BUTTON_TEXT">
                {{ 'ANIMAL.EVENTS.ADD_EVENT.BATCH_BUTTON_TEXT' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="batch-animals-container" *ngIf="isBatchListVisible" saveDataWarningIgnore>

      <chip-list class="batch-animals">
        <ng-container *ngFor="let animal of selectedAnimals">
          <chips-item [chipItem]="animal"
                      [chipBackground]="ChipsItemBackgroundColor.white"
                      [hasError]="animal.validationState === 'invalid' || animal.validationState === 'error'"
                      (onRemoveItemClicked)="removeSelectedAnimal(animal)">
            <div *ngIf="animal.validationState === 'inProgress'" class="batch-animals-item__validation-in-progress-action"></div>
          </chips-item>
        </ng-container>
      </chip-list>
      <div class="batch-animals-warning-message" *ngIf="isBatchAnimalsInvalid" translation-id="ANIMAL.EVENTS.ADD_EVENT.BATCH_ERROR_BOLD: ANIMAL.EVENTS.ADD_EVENT.NOT_VALID_FOR_THIS_EVENT">
        <b translation-id="ANIMAL.EVENTS.ADD_EVENT.BATCH_ERROR_BOLD">{{ 'ANIMAL.EVENTS.ADD_EVENT.BATCH_ERROR_BOLD' | translate }}</b>: {{ 'ANIMAL.EVENTS.ADD_EVENT.NOT_VALID_FOR_THIS_EVENT' | translate }}
      </div>
    </div>
  </ng-container>
  <ng-container container-footer>
    <div class="errors-div" saveDataWarningIgnore>
      <event-form-errors [errorModel]="eventDetailsError"></event-form-errors>
    </div>
    <div class="cancel-batch-button" [ngClass]="{ disabled: !isSaveEnabled }" *ngIf="selectionMode === 'batch'" (click)="onSingleModeClick()" saveDataWarningIgnore>
      <div class="cancel-batch-icon"></div>
    </div>
    <div class="container-buttons" [ngClass]="{ disabled: !isSaveEnabled }" [attr.sh-id]="'check-button'" saveDataWarningIgnore>
      <div class="check-button" (click)="save($event)">
        <div class="check-icon"></div>
      </div>
    </div>
  </ng-container>
</corner-container>
