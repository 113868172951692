  <div class="action-wrapper" outsideClickHandler (onOutsideClick)="close()"
       [ngClass]="{'open' : isOpen}">
  <div class="print-btn" [attr.sh-id]="getShId('print-btn')" (click)="print($event)">
    <div class="print-icon"></div>
  </div>
  <div class="separator"></div>
  <div class="actions-dropdown">
    <div class="actions-dropdown-button" (click)="toggle()">
      <div class="actions-button-text" [attr.translation-id]="'COMMON.ACTIONS.MORE_ACTIONS'" sh-id="actions-dropdown-more-actions">
        {{ 'COMMON.ACTIONS.MORE_ACTIONS' | translate }}
      </div>
      <div class="dropdown-icon-wrapper" [ngClass]="{'open' : isOpen}">
        <div class="down-icon" *ngIf="!isPopupsShown()"></div>
        <div class="up-icon" *ngIf="isPopupsShown()"></div>
      </div>
    </div>
  </div>
  <div class="dropdown-popup" [ngClass]="{'dropdown-popup_left': isActionsOnLeftSide,
                                          'dropdown-popup_right': !isActionsOnLeftSide}"
                              *ngIf="isOpen">
    <div class="decorative-shadow"></div>
    <ul class="actions-list">
      <li *ngFor="let additionalAction of additionalActions" (click)="additionalActionClick(additionalAction.key, $event)">
        <span>{{additionalAction.displayValue | translate}}</span>
      </li>
      <li (click)="exportToFile($event)"><span>{{'COMMON.ACTIONS.EXPORT' | translate}}</span></li>
      <li (click)="print($event)"><span>{{'COMMON.ACTIONS.PRINT' | translate}}</span></li>
    </ul>
    <div class="dropdown-frame-corner"></div>
  </div>
  <div class="export-to-file-popup-wrapper" *ngIf="isShowExportToFilePopup" [attr.sh-id]="'export-to-csv-file-popup'">
    <div class="decorative-shadow"></div>
    <div class="export-to-file-popup">
      <div class="export-to-file-header">
        <div class="back-btn" (click)="goBackToActionsMenu($event)" [attr.sh-id]="'back-to-report-actions-menu'">
          <div class="back-btn-icon"></div>
          <div class="back-btn-text" [attr.translation-id]="'REGISTRATION.FLOW.BACK'">{{ 'REGISTRATION.FLOW.BACK' | translate }}</div>
        </div>
        <div class="close-btn" (click)="close()" [attr.sh-id]="'close-export-to-file-popup'"></div>
      </div>
      <div class="export-to-file-body">
        <div class="title" [attr.translation-id]="'COMMON.ACTIONS.EXPORT'">{{ 'COMMON.ACTIONS.EXPORT' | translate }}:</div>
        <div class="export-btn" (click)="exportToCSVFile($event)" [attr.sh-id]="'export-to-csv-file-btn'">CSV</div>
      </div>
    </div>
    <div class="export-to-file-frame-corner"></div>
  </div>
</div>

<div class="print-template" #printTemplate>
  <div style="width: 100%; height: 50px; font-family: sans-serif; text-align: center;">{{dataSource.exportTitle}}</div>
  <table style="height: auto; width: 100%; margin: 0 auto; border-collapse: collapse; font-family: sans-serif;">
    <thead>
    <th *ngFor="let column of dataSource.exportedColumns"
        [ngStyle]="{'width' : column.widthCss}"
        [attr.sh-id]="column.name"
        style="font-weight: bold; border: 1px solid black; border-bottom-style: double; padding: 10px;">
      {{dataSource.transformColumnToPresentable(column.name)}}
    </th>
    </thead>
    <tbody *ngIf="htmlPrintableRows">
    <tr *ngFor="let eventRow of htmlPrintableRows">
      <td *ngFor="let column of dataSource.exportedColumns"
          [ngStyle]="{'width' : column.widthCss}"
          align="center"
          style="border: 1px solid black; padding: 10px; white-space: pre-line;line-height: 30px;">
        {{dataSource.transformFieldToPresentable(column.name, eventRow)}}
      </td>
    </tr>
    </tbody>
  </table>
</div>
