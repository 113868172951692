<confirm-dialog [primaryText]="primaryText"
                [secondaryText]="secondaryText"
                [headerText]="headerText"
                [isOpen]="isOpen"
                [containerShId]="'delete-task-confirmation-dialog'"
                [leftButtonShId]="'task-deletion-confirmation-cancel-btn'"
                [rightButtonShId]="'task-deletion-confirmation-delete-btn'"
                [leftButtonText]="'COMMON.BUTTON.CANCEL'"
                [rightButtonText]="'MANAGE.GROUPS.TABLE.ACTION.DELETE'"
                (onCloseClick)="closeDialog()"
                (onLeftButtonClick)="closeDialog()"
                (onRightButtonClick)="deleteClick()">
</confirm-dialog>
