<div class="auto-complete-container">
  <popup-template [isOpen]="openResultsPopup" (close)="onOutsideClick()">
    <ng-container header>
      <div class="input-area {{colorScheme}}" [ngClass]="{ error: isFieldError, disabled: disabled, 'regular-with-icon': (hasIcon || hasPermanentRemoveIcon)}">
        <input class="search"
               #searchInput
               [ngClass]="colorScheme"
               [class.isPlaceHolderBlack]="isColorPlaceholderBlack"
               [(ngModel)]="searchQuery"
               (keydown)="onSearchQueryChange()"
               (keyup)="onKeyUp($event)"
               (paste)="onSearchQueryChange()"
               [autofocus]="true"
               [disabled]="disabled"
               (focus)="onFocus()"
               [maxLength]="maxInputLength"
               [attr.sh-id]="'auto-complete-' + shIdPostfix"
               placeholder="{{ placeholder | translate }}"
               type="text"/>
        <div class="search-icon {{colorScheme}}" [ngClass]="{disabled: disabled}" *ngIf="hasIcon && !lastMatchingItem"></div>
        <div class="delete-icon {{colorScheme}}" [ngClass]="{disabled: disabled}" *ngIf="hasIcon && hasDeleteIcon && lastMatchingItem" (click)="onRemoveSelection($event)"></div>
        <div class="remove-icon {{colorScheme}}" [ngClass]="{disabled: disabled}" *ngIf="hasPermanentRemoveIcon" (click)="onRemoveSelection($event)"></div>
      </div>
    </ng-container>
    <ng-container popup-content>
      <div class="dropdown-popup" [ngClass]="{'fold-up': isFoldDirectionUp}">
        <ul class="search-results" [ngStyle]="{ maxHeight: calculateHeight() }">
          <li *ngFor="let matchingResult of matchingResults; let i = index"
              [attr.sh-id]="'auto-complete-' + shIdPostfix + '-' + matchingResult.value"
              [class.selected-item]="selectedIndex === i"
              [title]="isTextExceedsWidth(endPartElement, matchingResult.value)"
              (click)="selectItem($event, matchingResult.value)">
            <span class="matching-part">{{matchingResult.matchingPart}}</span>
            <ellipsisable-text class="end-part" #endPartElement>{{matchingResult.endPart}}</ellipsisable-text>
          </li>
        </ul>
      </div>
    </ng-container>
  </popup-template>
</div>
