import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {RegistrationService} from '../../../../../services/registration/registration.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'create-admin-account',
  templateUrl: './create-admin-account.component.html',
  styleUrls: ['./create-admin-account.component.scss']
})
export class CreateAdminAccountComponent implements OnInit {

  public customerName: string = null;
  public email: string = null;
  public phoneForm: FormGroup = new FormGroup({
    phone: new FormControl(null)
  });

  constructor(private router: Router, public registrationService: RegistrationService) { }

  public async ngOnInit() {
    if (this.registrationService.registrationModel.createAccount && this.registrationService.registrationModel.createAccount.account) {
      this.customerName = this.registrationService.registrationModel.createAccount.account.customerName || null;
      this.phoneForm = new FormGroup({
        phone: new FormControl(this.registrationService.registrationModel.createAccount.account.phone || null)
      });
      this.email = this.registrationService.registrationModel.createAccount.account.email || null;
    }
  }

  public async next() {
    if (this.validateStep()) {
      this.registrationService.registrationModel.createAccount.account.customerName = this.customerName;
      this.registrationService.registrationModel.createAccount.account.phone = this.phoneForm.controls.phone.value ? this.phoneForm.controls.phone.value.number : null;
      this.registrationService.registrationModel.createAccount.account.email = this.email;
      this.clearErrors();
      await this.router.navigate(['/registration/sign-up/account-details']);
    } else {

    }
  }

  public validateStep(): boolean {
    if (!this.customerName) {
      this.registrationService.registrationModel.error = { fieldName: 'customerName', error: 'CUSTOMER_NAME_REQUIRED' };
      return false;
    } else if (!this.phoneForm.controls.phone.value || !this.phoneForm.controls.phone.value.number) {
      this.registrationService.registrationModel.error = {fieldName: 'phone', error: 'PHONE_REQUIRED'};
      return false;
    } else if (!this.email) {
      this.registrationService.registrationModel.error = {fieldName: 'email', error: 'EMAIL_REQUIRED'};
      return false;
    } else {
      return true;
    }
  }

  public clearErrors() {
    this.registrationService.registrationModel.error = null;
  }

}
