import {Component, OnInit} from '@angular/core';
import {ConfigService} from '../../services/config/config.service';
import {FarmMode} from '../../services/config/model/server-config';
import {Router} from '@angular/router';
import {RegistrationService} from '../../services/registration/registration.service';
import {AuthService, ITrialModeStatus} from '../../services/auth/auth.service';
import {TranslationPickerThemeEnum} from "../../common/components/translation/translation-picker/translation-picker.component";
import {TranslationService} from '../../services/translations/translation.service';
import {TrialOperation} from "../../services/config/model/anonymous-auth-config";

@Component({
  selector: 'initialize-trial-mode',
  templateUrl: './initialize-trial-mode.component.html',
  styleUrls: ['./initialize-trial-mode.component.scss']
})
export class InitializeTrialModeComponent implements OnInit {

  public farmMode: FarmMode;

  public farmModes = FarmMode;

  public operationType: TrialOperation;

  public operationTypes = TrialOperation;

  public isStartedTrialModeForTheFirstTime: boolean = false;

  public isTrialOver: boolean = false;

  public TranslationPickerThemeEnum = TranslationPickerThemeEnum;

  constructor(public configService: ConfigService,
              private translationService: TranslationService,
              private router: Router,
              public registrationService: RegistrationService,
              private authService: AuthService) { }

  public async ngOnInit() {
    // check if entered trial mode for the first time and show message accordingly.
    let trialModeStatus: ITrialModeStatus = this.authService.getTrialModeStatus();
    if (!trialModeStatus) {
      await this.authService.setIsStartedTrialModeForTheFirstTime(true);
      trialModeStatus = this.authService.getTrialModeStatus();
    }
    if (trialModeStatus && trialModeStatus.isStartedTrialModeForTheFirstTime) {
      this.isStartedTrialModeForTheFirstTime = true;
      await this.authService.setIsStartedTrialModeForTheFirstTime(false);
    }
    const farmMode = await this.configService.anonymousAuthResult.trialMode.farmMode;
    this.farmMode = <FarmMode>farmMode;
    this.operationType = await this.configService.anonymousAuthResult.trialMode.operation;
    if (this.configService.anonymousAuthResult.trialMode && this.configService.anonymousAuthResult.trialMode.daysToExpiration === 0) {
      this.isTrialOver = true;
    }
  }

  public getTitleAccordingToFarmMode() {
    if (this.farmMode) {
      if (this.farmMode === FarmMode.Dairy) {
        return 'REGISTRATION.FLOW.DAIRY';
      } else {
        if (this.operationType == TrialOperation.breeding) {
          return 'ANIMAL.EVENTS.EVENT_TYPE.breeding';
        } else if (this.operationType == TrialOperation.finishing) {
          return 'FOOTER.ABOUT.PLAN.finishing'
        }
      }
    }
  }

  public async navigateToRegistration() {
    this.authService.isPressedOnRegister = true;
    await this.router.navigate(['/registration/initialize-registration']);
  }

  public async loginAsTrial() {
    await this.registrationService.loginAsTrialUser();
  }

  public get isFinishing() {
    return this.farmMode == FarmMode.Beef && this.operationType == TrialOperation.finishing;
  }

  public get isBreeding() {
    return this.farmMode == FarmMode.Beef && this.operationType == TrialOperation.breeding;
  }

  public get isDairy() {
    return this.farmMode == FarmMode.Dairy;
  }
}
