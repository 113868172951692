import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {PollingKeyNames, PollingService} from '../rest-api/polling.service';
import {Subject, Subscription} from 'rxjs';
import {HttpResponseResult, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from '../auth/auth.service';
import {IEntityAlert} from './model/alerts';

@Injectable({
  providedIn: 'root'
})
export class AlertsService implements OnInit, OnDestroy {

  private static readonly SystemAlertsRoute = '/rest/api/alerts/system';

  private static readonly FarmAlertsRoute = '/rest/api/alerts/farm';

  public alertsSubscription: Subscription;

  public systemAlertsSubject: Subject<IEntityAlert[]> = new Subject();

  public entityAlertsSubject: Subject<IEntityAlert[]> = new Subject();

  constructor(private pollingService: PollingService, private restApiService: RestApiService, private authService: AuthService) {

    this.alertsSubscription = this.pollingService.pollingChangesState.subscribe(async value => {
        if (value.includes(PollingKeyNames.systemAlerts)) {
            let systemAlertsResponse = await this.getSystemAlerts();
            this.systemAlertsSubject.next(systemAlertsResponse.responseBody);
        }
        if(value.includes(PollingKeyNames.entityAlerts)) {
            let entityAlertsResponse = await this.getEntityAlerts();
            this.entityAlertsSubject.next(entityAlertsResponse.responseBody);
        }
    });
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.alertsSubscription.unsubscribe();
  }

  // tslint:disable-next-line:no-any
  public getSystemAlerts(): Promise<HttpResponseResult<any>> {
    // tslint:disable-next-line:no-any
    return this.restApiService.sendGetMethod<any>(AlertsService.SystemAlertsRoute, this.authService.currentUserAuthState.authDetails);
  }

  // tslint:disable-next-line:no-any
  public getEntityAlerts(): Promise<HttpResponseResult<any>> {
    // tslint:disable-next-line:no-any
    return this.restApiService.sendGetMethod<any>(AlertsService.FarmAlertsRoute, this.authService.currentUserAuthState.authDetails);
  }
}
