import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {HttpResponseResult} from '../../../../services/rest-api/rest-api.service';
import {
  AnimalEventRow,
  AnimalsEventsResult,
  EventTypeEnum,
} from '../../../../services/animals/model/animal-events';
import {AnimalsService} from '../../../../services/animals/animals.service';
import {DateTimeFormatEnum, EpochDateTimePipe} from '../../../../common/pipes/epoch-date-time.pipe';
import {DataGridComponent} from '../../../../common/components/data-grid/data-grid.component';
import {DataGridQuery, DataGridQuerySortDetails} from '../../../../common/components/data-grid/model/data-grid-query';
import {DataGridColumnMap} from '../../../../common/components/data-grid/model/data-grid-column-map';
import {WINDOW} from '../../../../utils/window-utils';
import {ROW_ACTION_TRANSITIONS} from '../../../../common/animations/animations';
import {RowActionsPopupItem} from '../../../../common/components/row-actions-popup/row-actions-popup.component';
import {
  ExportedColumnDescription,
  IPrintExportDataSource
} from '../../../../common/components/grid-actions-dropdown/grid-actions-dropdown.component';
import * as moment from 'moment';
import {AnimalEventsService} from '../../../../services/animals/animal-events.service';
import {AnimalCardTabSection, AnimalCardViewState} from '../animal-card-model';
import {IDataGridViewContext} from '../../../../services/model/common-model';
import {IconCssClass} from '../../../../services/reports/model/report.interface';
import {TranslationService} from '../../../../services/translations/translation.service';
import {OperationType} from '../../../../services/search/model/search.model';
import {AnimalSex} from '../../../../services/animals/model/animal.model';
import {LoadingIconService} from '../../../../services/loading-icon/loading-icon.service';
import {GridViewState} from '../../../../services/ui/view-state/grid-row/grid-view-state';
import {CardViewStateService} from '../../../../services/ui/view-state/card-view-state.service';
import {ConfigService} from '../../../../services/config/config.service';
import {FarmRole} from '../../../../services/auth/model/farm-role.enum';

enum AnimalCardEventRowActionType {
  delete = 'delete',
  stop = 'stop'
}

enum CellStylingClasses {
  calvingHighlited = 'calving-highlighted',
  expandedBackground = 'expanded-background'
}

@Component({
  selector: 'animal-card-events',
  templateUrl: './animal-card-events.component.html',
  styleUrls: ['./animal-card-events.component.scss'],
  animations: [ROW_ACTION_TRANSITIONS]
})
export class AnimalCardEventsComponent implements OnInit, IDataGridViewContext, IPrintExportDataSource {

  @Input()
  public animalId: number;

  @Input()
  public viewState: AnimalCardViewState;

  @Input()
  public operationType: OperationType;

  @ViewChild('animalEventsSortedGrid', {static: true})
  private animalEventsSortedGrid: DataGridComponent;

  @ViewChild('animalEventsPrintTable')
  private animalEventsPrintTable: ElementRef;

  public animalEventsSortedGridQuery: DataGridQuery;

  public animalEventsResult : AnimalsEventsResult;

  private readonly _columnsMappings: Map<string, DataGridColumnMap> = new Map<string, DataGridColumnMap>();

  public get columnsMappings(): Map<string, DataGridColumnMap> {
    return this._columnsMappings;
  }

  public get rowsDataResult(): AnimalEventRow[] {
    return this.animalEventsResult ? this.animalEventsResult.result : []
  }

  public isEventComposerVisible: boolean = false;

  public readonly rowActionsMap: Map<AnimalEventRow, RowActionsPopupItem[]> = new Map<AnimalEventRow, RowActionsPopupItem[]>();

  private gridViewState : GridViewState = new GridViewState();

  public DateTimeFormatEnum = DateTimeFormatEnum;

  public EventTypeEnum = EventTypeEnum;

  public OperationType = OperationType;

  public get totalRows(): number {
    return this.animalEventsResult ? this.animalEventsResult.total || 0 : 0;
  }

  constructor(public animalsService:AnimalsService,
              private animalEventsService: AnimalEventsService,
              private translationService : TranslationService,
              private readonly loadingIconService: LoadingIconService,
              private epochDateTimePipe: EpochDateTimePipe,
              @Inject(WINDOW) private windoW: Window,
              private readonly configService: ConfigService,
              private cardViewStateService:CardViewStateService) {

    this._columnsMappings.set('startDateTime',
      {
        enableSorting: false,
        flexGrow: 1
      });
    this._columnsMappings.set('type',
      {
        enableSorting: false,
        flexGrow: 1.3
      });
    this._columnsMappings.set('daysInLactation',
      {
        enableSorting: false,
        flexGrow: 0.7
      });
    this._columnsMappings.set('description',
      {
        enableSorting: false,
        flexGrow: 1.7
      });
    this._columnsMappings.set('actions',
      {
        enableSorting: false,
        flexGrow: 0.2
      });
  }

  public async ngOnInit() {
    this.setDefaultEventsSortedGridQuery();
    await this.getEvents();
  }

  public async reloadDefault() {
    await this.getEvents();
  }

  public getGridViewState(): GridViewState {
    return this.gridViewState;
  }

  public get isAnimalCulled() {
    return this.viewState && this.viewState.animalInfo && this.viewState.animalInfo.isCulled;
  }

  public isRowExpanded(row) : boolean {
    if(this.animalEventsSortedGrid == null){
      return false;
    }
    return this.gridViewState.getRowExpandedState(row) != null;
  }

  public onExpanderToggle(isOpen:boolean) {
    this.isEventComposerVisible = isOpen;
    this.windowResizeMade();
  }

  private windowResizeMade() {
    setTimeout(() => {
      this.windoW.dispatchEvent(new Event('resize'));
    });
  }

  public getEventNameClass(event: string) {
    let prefix = 'event-icon-';
    if (event === 'positivePregnancyCheck' || event === 'negativePregnancyCheck' || event === 'uncertainPregnancyCheck') {
      prefix += 'pregnancy-check';
    } else if (event === 'abortionSameLactation' || event === 'abortionNewLactation') {
      prefix += 'abortion';
    } else if (event === 'removeScrTag' || event === 'removeRfIdTag') {
      prefix += 'remove-tag';
    } else if (event === 'changeScrTag' || event === 'changeRfIdTag') {
      prefix += 'change-tag';
    } else if (event === 'assignScrTag' || event === 'assignRfIdTag') {
      prefix += 'assign-tag';
    } else if (event === 'tagSwUpdate') {
      prefix += 'tag-sw-update'
    } else if (event === 'birth') {
      prefix += 'birth';
    } else if (event === 'weanedCalf') {
      prefix += 'weaned-calf';
    } else if (event === 'weight') {
      prefix += 'weight';
    } else if (event === 'startBackground') {
      prefix += 'startBackground';
    } else if (event === 'startFinishing') {
      prefix += 'startFinishing';
    } else if (event === 'castration') {
      prefix += 'castration';
    } else {
      prefix += event;
    }
    return prefix;
  }

  public toggleExpandRow(row) {
    this.animalEventsSortedGrid.refreshRowsHeight();
  }

  public get exportedColumns(): ExportedColumnDescription[] {
    return [{
      name: 'startDateTime',
      widthCss: '20%'
    },{
      name: 'type',
      widthCss: '20%'
    },{
      name: 'daysInLactation',
      widthCss: '15%'
    },{
      name: 'description',
      widthCss: 'auto'
    }]
  }

  // tslint:disable-next-line:no-any
  public async getDataToExport(): Promise<any[]> {
    let animalsEventsResult: HttpResponseResult<AnimalsEventsResult> = await this.animalEventsService.getAnimalEvents(this.animalId, 0,
      0,
      this.animalEventsSortedGridQuery.sortedColumn);
    if (animalsEventsResult.responseBody) {
      return animalsEventsResult.responseBody.result;
    }
    return null;
  }

  public transformColumnToPresentable(column: string): string {
    switch (column) {
      case 'startDateTime':
        return this.translationService.translate('MANAGE.EVENTS.DATE');
      case 'type':
        return this.translationService.translate('MANAGE.EVENTS.EVENT_NAME');
      case 'daysInLactation':
        return this.translationService.translate('MANAGE.EVENTS.DIM');
      case 'description':
        return this.translationService.translate('MANAGE.EVENTS.DESCRIPTION');
    }
  }

  // tslint:disable-next-line:no-any
  public transformFieldToPresentable(column: string, row: any): string {
    switch (column) {
      case 'startDateTime':
        return this.epochDateTimePipe.transform(row.startDateTime, DateTimeFormatEnum.DateTime);
      case 'type':
        return this.translationService.translate(`ANIMAL.EVENTS.EVENT_TYPE.${row.type}`);
      case 'daysInLactation':
        return this.ageInDaysOrDaysInLactation(row).toString();
      case 'description':
        return this.animalEventsService.getAnimalEventDescription(row);
    }
  }

  public async getEvents() {
    this.loadingIconService.show();
    let animalsEventsResult: HttpResponseResult<AnimalsEventsResult> = await this.animalEventsService.getAnimalEvents(this.animalId,
      this.animalEventsSortedGridQuery.page,
      this.animalEventsSortedGridQuery.limit,
      this.animalEventsSortedGridQuery.sortedColumn);
    if(animalsEventsResult.responseBody) {
      this.rowActionsMap.clear();
      this.animalEventsResult = animalsEventsResult.responseBody;
      this.animalEventsResult.result.forEach(value => {
        this.rowActionsMap.set(value, this.getRowActions(value));
      });
    }
    this.loadingIconService.hide();
  }

  private setDefaultEventsSortedGridQuery() {
    this.animalEventsSortedGridQuery = new DataGridQuery();
    this.animalEventsSortedGridQuery.offset = 0;
    this.animalEventsSortedGridQuery.limit = 100;
    this.animalEventsSortedGridQuery.sortDetails = new DataGridQuerySortDetails();
    this.animalEventsSortedGridQuery.sortDetails.column = 'startDateTime';
  }

  private async deleteAnimalEvent(row:AnimalEventRow) {
    let response = await this.animalEventsService.deleteAnimalEvent(this.animalId, row.eventId);
    if(response.status == 204) {
      if (row.isChangeOperation) {
      // if deleted change group event changed the operation
        if (row.isFinishing) {
          // if deleted change group event was finishing - load breeding
          this.cardViewStateService.openAnimalCard(row.animalId,
            OperationType.BeefBreeding,
            null,
            null, AnimalCardTabSection.Events);
        } else {
          // if deleted change group event was breeding - load finishing
          this.cardViewStateService.openAnimalCard(row.animalId,
            OperationType.BeefFinishing,
            null,
            null,
            AnimalCardTabSection.Events);
        }
      } else {
        this.cardViewStateService.openLastActiveCardViewState();
      }
    }
  }

  private async stopAnimalEvent(row:AnimalEventRow) {
    row.endDate = moment().unix();
    let response = await this.animalEventsService.updateAnimalEvent(this.animalId, row.eventId, row);
    this.cardViewStateService.openLastActiveCardViewState();
  }

  private getRowActions(row:AnimalEventRow) : RowActionsPopupItem[] {
    let rowActions: RowActionsPopupItem[] = [];
    if(this.configService.serverConfig.user.farmRole != FarmRole.ExternalUser) {
      if (row.deletable) {
        rowActions.push({
          action: AnimalCardEventRowActionType.delete,
          displayValue: 'ANIMAL.EVENTS.TABLE.REMOVE_EVENT', iconCssClass: IconCssClass.remove,
          shIdRowIdentifier: row.eventId.toString()
        });
      }
      if (row.active) {
        rowActions.push({
          action: AnimalCardEventRowActionType.stop,
          displayValue: 'ANIMAL.EVENTS.TABLE.STOP_EVENT', iconCssClass: IconCssClass.disable,
          shIdRowIdentifier: row.eventId.toString()
        });
      }
    }
    return rowActions;
  }

  public onRowActionsOpen() {
    this.gridViewState.clearExpandedRows();
    this.animalEventsSortedGrid.refreshRowsHeight();
  }

  public async handleRowAction(action:string, row:AnimalEventRow) {
    switch (action) {
      case AnimalCardEventRowActionType.delete: {
        await this.deleteAnimalEvent(row);
        break;
      }
      case AnimalCardEventRowActionType.stop: {
        await this.stopAnimalEvent(row);
        break;
      }
    }
  }

  public onAdditionalActionClick(actionKey: string, event: Event) {
  }

  public getCellStylingClasses(row: AnimalEventRow, column: string): string[]{
    let stylingClasses = [];
    switch (row.type as EventTypeEnum) {
      case EventTypeEnum.Calving:
      case EventTypeEnum.AbortionNewLactation:
        stylingClasses.push(CellStylingClasses.calvingHighlited);
        break;
    }
    switch (column) {
      case 'description':
        if (this.isRowExpanded(row)) {
          stylingClasses.push(CellStylingClasses.expandedBackground);
        }
        break;
    }

    return stylingClasses;
  }

  public get exportTitle(): string {
    return this.translationService.translate('ANIMAL.EVENTS.EVENTS_HISTORY');
  }

  public get filePartName(): string {
    return 'animalEvents';
  }

  public ageInDaysOrDaysInLactation(row: AnimalEventRow): string {
    if (this.operationType === OperationType.BeefFinishing || this.viewState.animalInfo.sex === AnimalSex.Male) {
      if (this.operationType === OperationType.BeefFinishing) {
        return row.ageInDays == null ? 'N/A' : row.ageInDays.toString();
      } else {
        return row.ageInDays.toString();
      }
    } else {
      return row.daysInLactation.toString();
    }
  }

  public get ageInDaysOrDaysInLactationColumnTranslation() : string {
    if(this.operationType == OperationType.BeefFinishing) {
      return 'MANAGE.EVENTS.AGE_ENTRANCE';
    } else {
      return 'MANAGE.EVENTS.DIM';
    }
  }
}
