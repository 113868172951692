import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoggedOutLayoutComponent} from './logged-out-layout.component';
import {WelcomeLayoutComponent} from './welcome-layout/welcome-layout.component';
import {LoginComponent} from './welcome-layout/login/login.component';
import {LoginGuard} from '../services/auth/route-guards/login.guard';
import {ForgotPasswordComponent} from './welcome-layout/forgot-password/forgot-password.component';
import {SignEulaComponent} from './welcome-layout/sign-eula/sign-eula.component';
import {RegistrationComponent} from './welcome-layout/registration/registration.component';
import {RegistrationGuard} from '../services/auth/route-guards/registration.guard';
import {InitializeRegistrationComponent} from './welcome-layout/registration/initialize-registration/initialize-registration.component';
import {SignUpComponent} from './welcome-layout/registration/sign-up/sign-up.component';
import {RegistrationSignUpGuard} from '../services/auth/route-guards/registration-sign-up.guard';
import {CreateAdminAccountComponent} from './welcome-layout/registration/sign-up/create-admin-account/create-admin-account.component';
import {SignUpAccountDetailsComponent} from './welcome-layout/registration/sign-up/sign-up-account-details/sign-up-account-details.component';
import {SecurityQuestionsComponent} from './welcome-layout/registration/sign-up/security-questions/security-questions.component';
import {TermsAndConditionsComponent} from './welcome-layout/registration/sign-up/terms-and-conditions/terms-and-conditions.component';
import {TrialFarmModeSelectionComponent} from './welcome-layout/trial-farm-mode-selection/trial-farm-mode-selection.component';
import {FarmRegistrationComponent} from './farm-registration/farm-registration.component';
import {AccountDetailsComponent} from './farm-registration/account-details/account-details.component';
import {HerdManagementComponent} from './farm-registration/herd-management/herd-management.component';
import {FarmTopologyComponent} from './farm-registration/farm-topology/farm-topology.component';
import {InitializeSignUpComponent} from './initialize-sign-up/initialize-sign-up.component';
import {InitializeTrialModeComponent} from './initialize-trial-mode/initialize-trial-mode.component';
import {SetupGuard} from '../services/setup/route-guards/setup.guard';
import {LoggedOutGuard} from '../services/auth/route-guards/logged-out.guard';
import {InitializeTrialGuard} from '../services/auth/route-guards/initialize-trial.guard';
import {TrialOperationTypeSelectionComponent} from "./welcome-layout/trial-operation-type-selection/trial-operation-type-selection.component";

const routes: Routes = [{
  path: '',
  canActivate: [SetupGuard, LoggedOutGuard],
  component: LoggedOutLayoutComponent,
  children: [
    {
      path: '',
      component: WelcomeLayoutComponent,
      children: [
        {
          path: 'login',
          component: LoginComponent,
          canActivate: [LoginGuard]
        },
        {
          path: 'forgot-password',
          component: ForgotPasswordComponent,
          canActivate: [LoginGuard]
        },
        {
          path: 'sign-eula',
          component: SignEulaComponent
        },
        {
          path: 'registration',
          component: RegistrationComponent,
          canActivate: [RegistrationGuard],
          children: [
            {
              path: 'initialize-registration',
              canActivate: [RegistrationGuard],
              component: InitializeRegistrationComponent
            },
            {
              path: 'sign-up',
              component: SignUpComponent,
              canActivateChild: [RegistrationSignUpGuard],
              children: [
                {
                  path: 'create-admin-account',
                  component: CreateAdminAccountComponent
                },
                {
                  path: 'account-details',
                  component: SignUpAccountDetailsComponent
                },
                {
                  path: 'security-questions',
                  component: SecurityQuestionsComponent
                },
                {
                  path: 'terms-and-conditions',
                  component: TermsAndConditionsComponent
                },
                {
                  path: '',
                  redirectTo: '/create-admin-account',
                  pathMatch: 'full'
                }
              ]
            }
          ]
        },
        {
          path: 'trial-farm-mode-selection',
          component: TrialFarmModeSelectionComponent
        },
        {
          path: 'trial-operation-type-selection',
          component: TrialOperationTypeSelectionComponent
        }
      ]
    },
    {
      path: 'farm-registration',
      component: FarmRegistrationComponent,
      canActivateChild: [RegistrationGuard],
      children: [
        {
          path: '',
          redirectTo: '/account-details',
          pathMatch: 'full'
        },
        {
          path: 'account-details',
          component: AccountDetailsComponent
        },
        {
          path: 'herd-management',
          component: HerdManagementComponent
        },
        {
          path: 'farm-topology',
          component: FarmTopologyComponent
        }
      ]
    },
    {
      path: 'initialize-sign-up',
      canActivate: [RegistrationSignUpGuard],
      component: InitializeSignUpComponent
    },
    {
      path: 'initialize-trial',
      canActivate: [InitializeTrialGuard],
      component: InitializeTrialModeComponent
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoggedOutLayoutRoutingModule { }
