<modal-dialog-template
  *ngIf="showPopup$ | async as failures"
  [modalWidth]="'auto'"
  [modalHeight]="'auto'"
  [isOpen]="failures && failures.length > 0"
  (onEscapeButtonClick)="handleClose()">
  <div class="layout">
    <div class="layout-header">
      <div class="layout-header__icon"></div>
      <div class="layout-header__text">
        <span [attr.translation-id]="'DIALOGS.ADHOC.ERROR_DIALOG.HEADER'">
          {{'DIALOGS.ADHOC.ERROR_DIALOG.HEADER' | translate}}
        </span>
      </div>
      <div class="layout-header__close-icon" (click)="handleClose()"></div>
    </div>
    <div class="layout-content">
      <p *ngFor="let f of failures" [attr.translation-id]="'DIALOGS.ADHOC.ERROR_DIALOG.ERROR_MESSAGES.' + f.key">
        {{ 'DIALOGS.ADHOC.ERROR_DIALOG.ERROR_MESSAGES.' + f.key | translate }}
      </p>
    </div>
  </div>
</modal-dialog-template>
