import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {LoadingIconService} from "../../../services/loading-icon/loading-icon.service";
import {Subscription} from "rxjs";
import {ConfigService} from '../../../services/config/config.service';

@Component({
  selector: 'loading-icon',
  templateUrl: './loading-icon.component.html',
  styleUrls: ['./loading-icon.component.scss']
})
export class LoadingIconComponent implements OnInit, OnDestroy {

  private loadingIconSubscription: Subscription;

  public isLoaderShown: boolean = false;

  constructor(private readonly loadingIconService: LoadingIconService, private readonly configService: ConfigService) {}

  @HostListener('click', ['$event'])
  public onLoaderOverlayClick($event: Event): void {
    $event.stopPropagation();
  }

  public ngOnInit() {
    this.loadingIconSubscription = this.loadingIconService.loadingIconSubject.subscribe(async (isLoaderShown: boolean) => {
      this.isLoaderShown = isLoaderShown;
    });
  }

  public get isInTestingMode(): boolean {
    return !!(this.configService.configuration && this.configService.configuration.useMockHttp);
  }

  public ngOnDestroy(): void {
    if (this.loadingIconSubscription) {
      this.loadingIconSubscription.unsubscribe();
    }
  }
}
