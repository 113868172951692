import {Component, Input, OnInit} from '@angular/core';
import {FilterBooleanQuery} from '../../../../../../services/reports/model/report-query.interface';

@Component({
  selector: 'filter-boolean',
  templateUrl: './filter-boolean.component.html',
  styleUrls: ['./filter-boolean.component.scss']
})
export class FilterBooleanComponent implements OnInit {

  @Input()
  public filterBooleanQuery : FilterBooleanQuery;

  constructor() { }

  public ngOnInit() {
  }

}
