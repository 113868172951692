import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ellipsisable-text',
  templateUrl: './ellipsisable-text.component.html',
  styleUrls: ['./ellipsisable-text.component.scss']
})
export class EllipsisableTextComponent implements OnInit {

  @Input()
  public maxChars: number = null;

  private _textContent: string;

  private _isTextUpdated: boolean = false;

  constructor(private host: ElementRef) { }

  public ngOnInit(): void {

  }

  public get ellipsisClassName(): string {
    this.saveInitialTextContent();
    this.updateContent();
    this.updateTitle();
    return 'ellipsis';
  }

  private updateTitle() {
    const isEllipsisActive: boolean = this.host.nativeElement.offsetWidth < this.host.nativeElement.scrollWidth;
    const title: string = isEllipsisActive || this._isTextUpdated ? this._textContent : '';
    if (this.title !== title) {
      this.title = title;
    }
  }

  private set title(value: string) {
    this.host.nativeElement.setAttribute('title', value);
  }

  private get title() {
    return this.host.nativeElement.getAttribute('title');
  }

  private updateContent() {
    if (!!this.maxChars && this.host.nativeElement.textContent?.length > this.maxChars) {
      this.host.nativeElement.textContent = this.host.nativeElement.textContent.substr(0, this.maxChars) + '...';
      this._isTextUpdated = true;
    } else {
      this._isTextUpdated = false;
    }
  }

  private saveInitialTextContent() {
    if (!this._textContent) {
      this._textContent = this.host.nativeElement.textContent;
    }
  }
}
