<div class="mock-system-state-area">
  <div class="input-group">
    <button [disabled]="isApplying" (click)="resetStateToDefault()" sh-id="reset_state_button">Reset State To Default (Dairy)</button>
  </div>
  <div class="input-group">
    <button [disabled]="isApplying" (click)="setRegistrationState()">Registration State</button>
  </div>
  <div class="input-group">
    <button [disabled]="isApplying" (click)="setBeefBreeding()">Set Beef Breeding State</button>
  </div>
  <div class="input-group">
    <button [disabled]="isApplying" [attr.sh-id]="'switch_to_beef_coexist_btn'" (click)="setBeefFinishing()">Set Beef Finishing & Breeding State (co-exist)</button>
  </div>
</div>
<div class="mock-system-state-permissions">
  <div class="title">Change User Permissions</div>
  <div class="user-selection-wrapper">
    <select class="user-permissions" [attr.sh-id]="'user-permissions-selections'" [(ngModel)]="selectedUserPermission">
      <option *ngFor="let farmRole of farmRoles" [attr.sh-id]="'user-permissions-selection-' + farmRole">{{ farmRole }}</option>
    </select>
    <button class="submit-user-permissions-btn" [attr.sh-id]="'change-user-permissions-button'" (click)="changeFarmRole()">Apply</button>
  </div>

</div>
