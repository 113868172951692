import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss']
})
export class ConfirmDeleteDialogComponent implements OnInit {

  @Input()
  public isOpen: boolean;

  @Input()
  public headerText: string;

  @Input()
  public primaryText : string;

  @Input()
  public secondaryText : string;

  @Output()
  public onCancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onDelete: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  public ngOnInit() {
  }

  public closeDialog() {
    this.onCancel.emit();
  }

  public deleteClick() {
    this.onDelete.emit();
  }
}
