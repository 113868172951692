import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  LedTasksService
} from '../../../services/led-tasks/led-tasks.service';
import * as _ from 'lodash';
import {ErrorModel} from '../../../services/model/common-model';
import {HttpResponseResult} from '../../../services/rest-api/rest-api.service';
import {
  DiscoverItems,
  IScheduledTaskModelItem,
  LedTaskFields,
  ScheduledTaskModelItemAnimal,
  ScheduledTaskModelItemReport,
  TaskModel
} from '../../../services/led-tasks/models/led-tasks.interface';
import {Subscription} from 'rxjs';
import {ISaveDataWarningDialogState} from '../../../common/components/dialogs/save-data-warning-dialog/save-data-warning-dialog.component';
import {SaveDataViewStateService} from '../../../services/ui/view-state/save-data-view-state.service';
import {LoadingIconService} from "../../../services/loading-icon/loading-icon.service";

@Component({
  selector: 'task-editor',
  templateUrl: './task-editor.component.html',
  styleUrls: ['./task-editor.component.scss']
})
export class TaskEditorComponent implements OnInit, OnDestroy {

  private taskToEdit: number = null;

  private isTaskEditorDetectedChanges: boolean;

  private dialogState: ISaveDataWarningDialogState = { isCancelClickedRecently: false };

  private readonly onApproveDataLossClickSubscription: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private saveDataViewStateService:SaveDataViewStateService,
              private readonly changeDetector: ChangeDetectorRef,
              private readonly loadingIconService: LoadingIconService,
              public ledTasksService: LedTasksService) {
    this.onApproveDataLossClickSubscription = this.saveDataViewStateService.onApproveDataLossClick.subscribe(async () => {
      await this.router.navigate(['/led-tasks/tasks']);
    });
  }

  public async ngOnInit() {
    this.route.params.subscribe((params) => {
      this.taskToEdit = params && params.id;
    });
  }

  public ngOnDestroy(): void {
    if (this.onApproveDataLossClickSubscription) {
      this.onApproveDataLossClickSubscription.unsubscribe();
    }
  }

  public async backToTasks(event:Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.isMadeChanges) {
      this.saveDataViewStateService.showSaveDataWarningModal(this, this.dialogState);
    } else {
      await this.router.navigate(['/led-tasks/tasks']);
    }
  }

  public async save() {
    this.loadingIconService.show();
    if (this.isTaskValid()) {
      let scheduledTaskModel: IScheduledTaskModelItem;
      if (this.ledTasksService.taskModel.selectedDiscoverItem.value === DiscoverItems.Reports) {
        scheduledTaskModel = new ScheduledTaskModelItemReport();
      } else {
        scheduledTaskModel = new ScheduledTaskModelItemAnimal();
      }
      scheduledTaskModel.convertEditableModelToScheduledTaskModel(this.ledTasksService.taskModel);
      let response: HttpResponseResult<void>;
      if (this.taskToEdit) {
        response = await this.ledTasksService.editLedTask(scheduledTaskModel);
      } else {
        response = await this.ledTasksService.addLedTask(scheduledTaskModel);
      }
      if (response.status === 201 || response.status === 200) {
        this.taskToEdit = null;
        this.ledTasksService.taskModel = new TaskModel();
        this.ledTasksService.originalTaskModel = _.cloneDeep(this.ledTasksService.taskModel);
        await this.router.navigate(['/led-tasks/tasks']);
      } else {
        if (response.status === 400) {
          this.ledTasksService.Error = new ErrorModel();
          this.ledTasksService.Error.key = response.errorResponseBody.result.failures[0].key;
          this.ledTasksService.Error.fieldName = response.errorResponseBody.result.failures[0].fieldName;
        }
      }
    }
    this.loadingIconService.hide();
  }

  private isTaskValid(): boolean {
    if (this.ledTasksService.taskModel.selectedDiscoverItem.value === DiscoverItems.Reports) {
      // if discover = reports
      if (!this.ledTasksService.taskModel.selectedReportItem || !this.ledTasksService.taskModel.selectedReportItem.value) {
        this.ledTasksService.Error = { key: 'ReportIsNotSupportsLedLighting', fieldName: LedTaskFields.report };
        return false;
      } else if (this.ledTasksService.taskModel.startTime.epochTime == null) {
        this.ledTasksService.Error = { key: 'MissingScheduleStartTime', fieldName: LedTaskFields.startTime };
        return false;
      } else if (this.ledTasksService.taskModel.selectedDays.length === 0) {
        this.ledTasksService.Error = { key: 'MissingScheduleWeekDays', fieldName: LedTaskFields.weekdays };
        return false;
      } else {
        return true;
      }
    } else {
      // if discover = animals
      if (!this.ledTasksService.taskModel.selectedTaskName) {
        this.ledTasksService.Error = { key: 'AnimalTaskNameRequired', fieldName: LedTaskFields.animalTaskName };
        return false;
      } else if (!this.ledTasksService.taskModel.selectedAnimals.length) {
        this.ledTasksService.Error = { key: 'selectedAnimalsRequired', fieldName: LedTaskFields.animalsSelection };
        return false;
      } else if (this.ledTasksService.taskModel.startDate.epochDate == null) {
        this.ledTasksService.Error = { key: 'MissingScheduleStartDate', fieldName: LedTaskFields.startDate };
        return false;
      } else if (this.ledTasksService.taskModel.startTime.epochTime == null) {
        this.ledTasksService.Error = { key: 'MissingScheduleStartTime', fieldName: LedTaskFields.startTime };
        return false;
      } else {
        return true;
      }
    }
  }

  public get isMadeChanges() {
    let isChanged: boolean = !(_.isEqual(this.ledTasksService.taskModel, this.ledTasksService.originalTaskModel));
    if (this.ledTasksService.originalTaskModel != null && isChanged != this.isTaskEditorDetectedChanges) {
      this.isTaskEditorDetectedChanges = isChanged;
      this.changeDetector.detectChanges();
    }
    return this.isTaskEditorDetectedChanges;
  }
}

