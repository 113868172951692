<div *ngIf="maintenanceCalls.length == 0" class="maintenance-calls" [ngClass]="currentFarmMode == FarmMode.Beef ? 'no-maintenance-calls-beef' : 'no-maintenance-calls-dairy'">
  <div class="no-maintenance-calls-text" [attr.translation-id]="'DASHBOARD.MAINTENANCE.NO_TAG_MAINTENANCE'" [attr.sh-id]="'maintenance_calls_no_tag_maintenance'">
    {{ 'DASHBOARD.MAINTENANCE.NO_TAG_MAINTENANCE' | translate }}
  </div>
</div>
<div *ngIf="maintenanceCalls.length > 0" class="maintenance-calls" [ngClass]="currentFarmMode == FarmMode.Beef ? 'maintenance-beef' : 'maintenance-dairy'">
  <div class="maintenance-calls-header" [attr.translation-id]="'DASHBOARD.MAINTENANCE.TAG_MAINTENANCE'">
    {{ 'DASHBOARD.MAINTENANCE.TAG_MAINTENANCE' | translate }}
  </div>
  <div class="maintenance-calls-body" [attr.sh-id]="'maintainance_calls_body'">
    <ng-container *ngIf="updateSystemSoftwareMaintenanceCallsItem">
      <maintenance-calls-item
        [isLocked]="!canClickOnMaintenanceCallsItem"
        [color]="MaintenanceCallsItemTextColor.yellow"
        [model]="updateSystemSoftwareMaintenanceCallsItem"
        [iconCssClass]="getIconCssClassByAlertType(MaintenanceCallsMajorAlertType.Update)"
        (click)="onMaintenanceCallsItemClick(updateSystemSoftwareMaintenanceCallsItem, $event)"
        sh-id="maintenance_calls_entry_update">
      </maintenance-calls-item>
    </ng-container>
    <ng-container *ngIf="tagSWUpdateMaintenanceCallsItem">
      <maintenance-calls-item
        [isLocked]="!canClickOnMaintenanceCallsItem"
        [color]="MaintenanceCallsItemTextColor.yellow"
        [model]="tagSWUpdateMaintenanceCallsItem"
        [iconCssClass]="getIconCssClassByAlertType(MaintenanceCallsMajorAlertType.TagSwUpdate)"
        (click)="onMaintenanceCallsItemClick(tagSWUpdateMaintenanceCallsItem, $event)"
        sh-id="maintenance_calls_entry_tagSwUpdate">
      </maintenance-calls-item>
    </ng-container>
    <div *ngIf="showDelimiterBetweenMajorAndLesserItems" class="maintenance-calls-delimiter"></div>
    <ng-container *ngFor="let maintenanceCallsItem of lesserMaintenanceCalls">
      <maintenance-calls-item
        [isLocked]="!canClickOnMaintenanceCallsItem"
        [color]="MaintenanceCallsItemTextColor.white"
        [model]="maintenanceCallsItem"
        [iconCssClass]="getIconCssClassByAlertType(maintenanceCallsItem.alertType)"
        (click)="onMaintenanceCallsItemClick(maintenanceCallsItem, $event)"
        [attr.sh-id]="'maintenance_calls_entry_' + createShId(maintenanceCallsItem)">
      </maintenance-calls-item>
    </ng-container>
  </div>
</div>
<update-software-modal [isModalOpen]="isSoftwareModalOpen" (onApplyModalDialog)="onApplyUpdateSoftware()" (onCloseModalDialog)="onCloseUpdateSoftwareModal()"></update-software-modal>
<new-version-software-modal #newVersionSoftwareDialogContainer></new-version-software-modal>
