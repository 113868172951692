import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ApplicationBundleRoutingMode} from '../../../../services/routing/routing.service';
import {FarmMode} from '../../../../services/config/model/server-config';
import {ConfigService} from '../../../../services/config/config.service';
import {SystemEditBarComponent} from '../../../system/system-edit-bar/system-edit-bar.component';
import {EditBarService} from '../../../../services/edit-bar/edit-bar.service';
import {ManageSettingsService} from '../../../../services/manage/manage-settings.service';
import {ISaveDataWarningDialogState} from '../../../../common/components/dialogs/save-data-warning-dialog/save-data-warning-dialog.component';
import {SaveDataViewStateService} from '../../../../services/ui/view-state/save-data-view-state.service';

@Component({
  selector: 'manage-settings-header',
  templateUrl: './manage-settings-header.component.html',
  styleUrls: ['./manage-settings-header.component.scss']
})
export class ManageSettingsHeaderComponent extends SystemEditBarComponent implements OnInit {

  @Input()
  public applicationBundleRoutingMode: ApplicationBundleRoutingMode;

  @ViewChild('resetBtn')
  public resetBtn: ElementRef;

  @Output()
  public onCloseSettingsDialog: EventEmitter<void> = new EventEmitter<void>();

  private saveDataWarningDialogState: ISaveDataWarningDialogState = {
    isCancelClickedRecently: false
  };

  constructor(private readonly configService: ConfigService,
              public readonly manageSettingsService: ManageSettingsService,
              private readonly saveDataViewStateService: SaveDataViewStateService,
              editBarService: EditBarService) {
    super(editBarService);
  }

  public get isDairy() : boolean {
    return this.configService.serverConfig.farmMode === FarmMode.Dairy;
  }

  public get isBreeding() {
    return this.applicationBundleRoutingMode === ApplicationBundleRoutingMode.Breeding;
  }

  public get isFinishing() {
    return this.applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing;
  }

  public reset($event: Event): void {
    $event.stopPropagation();
    if (this.saveDataWarningDialogState.isCancelClickedRecently) {
      super.reset($event);
      this.saveDataWarningDialogState.isCancelClickedRecently = false;
    } else {
      this.saveDataViewStateService.showSaveDataWarningModal(this.resetBtn.nativeElement, this.saveDataWarningDialogState);
    }
  }
}
