export enum ManageSettingsScreenType {
  /*Welcome screen*/
  welcome = 'welcome',
  /*Alerts screen*/
  alerts = 'alerts',
  /*Health screen*/
  healthReport = 'health-report',
  healthFreshReport = 'health-fresh-report',
  healthDistressSensitivity = 'health-distress-sensitivity',
  healthDaysToWeaning = 'health-days-to-weaning',
  /*Reproduction screen*/
  reproductionVoluntaryWaitingPeriod = 'reproduction-voluntary-waiting-period',
  reproductionPregnancyLength = 'reproduction-pregnancy-length',
  reproductionPregnancyDaysToDryOff = 'reproduction-pregnancy-days-to-dry-off',
  /*Farm routine screen*/
  farmRoutineShifts = 'shifts',
  farmRoutineGroupRoutineEvents = 'group-routine-events'
}
