export interface ITagsToUpdateVersions {
  currentJob: number,
  versions:ITagToUpdateSoftwareDetails[];
}

export enum TagModelType {
  cSense = 'cSense',
  eSense = 'eSense',
  cSenseEx = 'cSenseEx',
  eSenseEx = 'eSenseEx'
}

export interface ITagToUpdateSoftwareDetails {
  type:TagModelType
  version: string,
  lastDownloadDate: number
}

export enum UpdateStatusType {
  notStarted = 'notStarted',
  inProgress = 'inProgress',
  completed = 'completed',
  failed = 'failed'
}

export interface ITagsUpdateProgressStatus {
  timeToComplete:number,
  status:UpdateStatusType
}

export interface ITagsUpdateSummary {
  completed: number,
  tagsToUpdate: number,
  tagsUpdated: number,
  availableTagsToUpdate: number
}

export interface ITagsStartUpdateRequest {
  assignedTagsOnly: boolean;
}

export interface ITagsStartUpdateResponse {
  currentJob:number;
}
