import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ReportQuery} from '../../../services/reports/model/report-query.interface';
import {IReport, IReportIds, ReportDataRow, ReportDisplayType} from '../../../services/reports/model/report.interface';
import {ReportsService} from '../../../services/reports/reports.service';
import {HttpResponseResult} from '../../../services/rest-api/rest-api.service';
import {WINDOW} from '../../../utils/window-utils';
import {GoogleAnalyticsService} from '../../../services/google-analytics/google-analytics.service';
import {Subscription} from 'rxjs';
import {GridSummaryIcon} from 'src/app/common/components/grid-summary/grid-summary.component';
import {ReportGridComponent, ReportGridValueCellClickDetails} from '../../../common/components/reports/report-grid/report-grid.component';
import {FarmMode} from '../../../services/config/model/server-config';
import {ApplicationBundleRoutingMode, RoutingService} from '../../../services/routing/routing.service';
import {ActivatedRoute} from '@angular/router';
import {ConfigService} from '../../../services/config/config.service';
import {GroupsService} from '../../../services/groups/groups.service';
import {GroupCardTabSection} from '../../card-popup/group-card/group-card-model';
import {ReportRowSelectionState} from '../../../services/reports/report-row-selection-state';
import * as moment from 'moment';
import {IManageGroupsViewContext} from '../../../services/reports/model/report-view-context';
import {GroupEditorComponent} from './group-editor/group-editor.component';
import {OperationType} from '../../../services/search/model/search.model';
import {TranslationService} from '../../../services/translations/translation.service';
import {GridViewState} from '../../../services/ui/view-state/grid-row/grid-view-state';
import {CardViewStateService} from '../../../services/ui/view-state/card-view-state.service';

@Component({
  selector: 'manage-groups',
  templateUrl: './manage-groups.component.html',
  styleUrls: ['./manage-groups.component.scss']
})
export class ManageGroupsComponent implements OnInit, IManageGroupsViewContext {

  @ViewChild('groupsFilteredReportGrid')
  public groupsFilteredReportGrid: ReportGridComponent;

  @ViewChild('groupEditor')
  public groupEditor: GroupEditorComponent;

  public groupsReportQuery: ReportQuery;

  public groupsReport: IReport;

  public applicationBundleRoutingMode: ApplicationBundleRoutingMode;

  private queryParamsSubscription: Subscription;

  private groupsListChangedSubscription: Subscription;

  private applicationBundleRoutingModes = ApplicationBundleRoutingMode;

  private gridViewState:GridViewState = new GridViewState();

  public GridSummaryIcon = GridSummaryIcon;

  constructor(private reportsService: ReportsService,
              private cardViewStateService:CardViewStateService,
              private googleAnalyticsService: GoogleAnalyticsService,
              private routingService: RoutingService,
              private route: ActivatedRoute,
              private configService: ConfigService,
              private groupsService: GroupsService,
              @Inject(WINDOW) private window: Window) {
  }

  public async ngOnInit() {
    this.groupsListChangedSubscription = this.groupsService.onGroupsListChanged.subscribe(async (isGroupEditorOpen: boolean) => {
      this.groupEditor.isOpen = isGroupEditorOpen;
      // is created new group
      if (isGroupEditorOpen) {
        await this.groupsFilteredReportGrid.reloadGrid(false, moment().unix(), true);
      } else {
        // is edit group
        await this.groupsFilteredReportGrid.reloadGrid(true, moment().unix(), true);
      }
    });
    this.queryParamsSubscription = this.route.queryParams.subscribe(queryParams => {
      if (this.configService.serverConfig.farmMode === FarmMode.Dairy) {
        this.applicationBundleRoutingMode = null;
      } else {
        this.applicationBundleRoutingMode = this.routingService.getApplicationBundleRoutingMode(queryParams);
      }
    });
    this.setDefaultGroupsReportQuery();
    await this.loadGroups();
  }

  public ngOnDestroy() {
    this.groupsListChangedSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
  }

  public async reload(isRefresh:boolean, pastReportTime:number): Promise<void> {
    this.windowResizeMade();
    await this.groupsFilteredReportGrid.reloadGrid(isRefresh, pastReportTime, true);
  }

  public toggleAddGroup() {
    this.windowResizeMade();
  }

  private windowResizeMade() {
    setTimeout(() => {
      this.window.dispatchEvent(new Event('resize'));
    });
  }

  public async onReportQueryChanged() {
    await this.loadGroups();
  }

  public async onReportGridValueCellClick(reportGridValueCellClickDetails: ReportGridValueCellClickDetails) {
    let operationType:OperationType;
    if(this.applicationBundleRoutingMode == null ||
      this.applicationBundleRoutingMode == ApplicationBundleRoutingMode.Breeding){
      operationType = this.configService.serverConfig.farmMode == FarmMode.Beef ? OperationType.BeefBreeding : OperationType.Dairy;
    } else {
      operationType = OperationType.BeefFinishing;
    }
    let relatedGroupsReportQuery = this.groupsReportQuery.getIdProjectionReportQuery('GroupDatabaseIDCalculation');
    let relatedGroupsReportResult: HttpResponseResult<IReportIds>;
    if (operationType === OperationType.BeefFinishing) {
      relatedGroupsReportResult = await this.reportsService.getFinishingGroupsIds(relatedGroupsReportQuery);
    } else {
      relatedGroupsReportResult = await this.reportsService.getGroupsIds(relatedGroupsReportQuery);
    }
    if (relatedGroupsReportResult.status == 200) {
      let relatedGroups = relatedGroupsReportResult.responseBody.result.map(value => value['GroupDatabaseIDCalculation']);
      let selectedGroupId = reportGridValueCellClickDetails.row['GroupDatabaseIDCalculation'];
      this.cardViewStateService.openGroupCard(selectedGroupId,
                                              operationType,
                                              relatedGroups,
                                             ['GROUP.NAVIGATION.GROUP_IN','GROUP.NAVIGATION.GROUP_IN_FARM'],
                                              GroupCardTabSection.Details);
    }
  }

  private async loadGroups() {
    this.groupsReport = await this.getReportByQuery(this.groupsReportQuery);
  }

  private setDefaultGroupsReportQuery() {
    this.groupsReportQuery = new ReportQuery();
    this.groupsReportQuery.offset = 0;
    this.groupsReportQuery.limit = 30;
    this.groupsReportQuery.type = ReportDisplayType.full;
    this.groupsReportQuery.includeFilterMetaData = true;
  }

  public async getReportByQuery(reportQuery: ReportQuery): Promise<IReport> {
    let groupsResponse: HttpResponseResult<IReport>;
    if (this.applicationBundleRoutingMode === this.applicationBundleRoutingModes.Finishing) {
      groupsResponse = await this.reportsService.getFinishingGroups(reportQuery);
    } else {
      groupsResponse = await this.reportsService.getGroups(reportQuery);
    }
    return groupsResponse.responseBody;
  }

  public getSelectedRowsReport() {
    return null;
  }

  public async reloadDefault(): Promise<void> {
    await this.reload(true, null);
  }

  // tslint:disable-next-line:no-any
  public editGroup(row: any) {
    this.groupsService.editGroupSubscription.next(row);
  }

  public getLoadedRows(): ReportDataRow[] {
    return null;
  }

  public getRowSelectionState(): ReportRowSelectionState {
    return undefined;
  }

  public getGridViewState(): GridViewState {
    return this.gridViewState;
  }

  public getPrintIncludeDateInHeader(): boolean {
    return false;
  }

  public getPrintReportHeader(): string {
    return 'MANAGE.TITLE.GROUPS';
  }
}
