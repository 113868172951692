<div outsideClickHandler saveDataWarningIgnore (onOutsideClick)="closePopup()">
  <div class="about-button" (click)="togglePopup()" [ngClass]="{'open': isPopupOpen}" [attr.sh-id]="'about_toggle'">
    <div class="title">
      <span [attr.translation-id]="'FOOTER.ABOUT.TITLE'">{{'FOOTER.ABOUT.TITLE' | translate}}</span>
    </div>
  </div>
  <div class="about-popup" *ngIf="isPopupOpen && aboutModel" @about-transition [attr.sh-id]="'about_popup'">
    <div class="btn-close">
      <div class="icon icon-close" (click)="togglePopup()"></div>
    </div>
    <div class="actions-popup-title">
      <span class="event-text-title" [attr.translation-id]="'FOOTER.ABOUT.ABOUT_SENSETIME'">{{'FOOTER.ABOUT.ABOUT_SENSETIME' | translate}}</span>
    </div>
    <ul class="actions">
      <li>
        <span class="title" [attr.translation-id]="'FOOTER.ABOUT.FARM_NAME'">{{'FOOTER.ABOUT.FARM_NAME' | translate}}</span>:
        <span class="farm-name-wrapper"><ellipsisable-text [maxChars]="40" [attr.sh-id]="'about_farmName'">{{aboutModel.farmName}}</ellipsisable-text></span>
      </li>
      <li>
        <span class="title" [attr.translation-id]="'FOOTER.ABOUT.FARM_ID'">{{'FOOTER.ABOUT.FARM_ID' | translate}}</span>:
        <span [attr.sh-id]="'about_farmId'">{{aboutModel.farmId}}</span>
      </li>
      <li>
        <span class="title" [attr.translation-id]="'FOOTER.ABOUT.SYSTEM_STATE'">{{'FOOTER.ABOUT.SYSTEM_STATE' | translate}}</span>:
        <span [attr.sh-id]="'about_state'" [attr.translation-id]="'FOOTER.ABOUT.SYSTEM_STATE_TYPE.' + aboutModel.state">{{'FOOTER.ABOUT.SYSTEM_STATE_TYPE.' + aboutModel.state | translate}}</span>
      </li>
      <li>
        <span class="title" [attr.translation-id]="'FOOTER.ABOUT.SENSETIME_VERSION'">{{'FOOTER.ABOUT.SENSETIME_VERSION' | translate}}</span>:
        <span [attr.sh-id]="'about_version'">{{aboutModel.version}}</span>
      </li>
      <ng-container *ngIf="orderedPopulationPlanModel">
        <li *ngIf="showApplicationPlan">
          <span class="title" [attr.translation-id]="'FOOTER.ABOUT.APPLICATION_PLAN'">{{'FOOTER.ABOUT.APPLICATION_PLAN' | translate}}</span>:
          <span *ngIf="showSingleApplicationPlanOnly" [attr.translation-id]="'FOOTER.ABOUT.PLAN.' + orderedPopulationPlanModel.plans[0].value" [attr.sh-id]="'application-plan-text-' + orderedPopulationPlanModel.plans[0].value + '-in-position-0'">
            {{ 'FOOTER.ABOUT.PLAN.' + orderedPopulationPlanModel.plans[0].value | translate }}
          </span>
        </li>
        <ng-container *ngIf="showMultipleApplicationPlans && !showSingleApplicationPlanOnly">
          <ng-container *ngFor="let p of orderedPopulationPlanModel.plans; let i = index">
            <li *ngIf="showApplicationPlanItem(p)" [attr.translation-id]="'FOOTER.ABOUT.PLAN.' + p.value" [attr.sh-id]="'application-plan-text-' + p.value + '-in-position-' + i">
              {{ 'FOOTER.ABOUT.PLAN.' + p.value | translate }}
              <ng-container *ngIf="showPlanDetails(p)">
                {{' '}}({{ 'FOOTER.ABOUT.PLAN.' + p.plan | translate }})
              </ng-container>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <li *ngIf="isStartFreeTrialShown()" nonExternalUser>
        <div class="premium-trial-header" [attr.translation-id]="'FOOTER.ABOUT.FREE'">{{ 'FOOTER.ABOUT.FREE' | translate }}!</div>
        <div class="premium-trial-sub-header" [attr.translation-id]="'FOOTER.ABOUT.PREMIUM_TRIAL_SUB_HEADER_TEXT'">{{ 'FOOTER.ABOUT.PREMIUM_TRIAL_SUB_HEADER_TEXT' | translate }}</div>
        <button class="start-free-trial-btn" [attr.translation-id]="'SIDE_BAR.REPORTS.TEASER.START_FREE_TRIAL'" (click)="startFreeTrialURL()">{{ 'SIDE_BAR.REPORTS.TEASER.START_FREE_TRIAL' | translate }}</button>
      </li>
      <li *ngIf="configService.serverConfig.premiumTrialInfo && configService.serverConfig.premiumTrialInfo.remainingDays !== null">
        <span class="title" [attr.translation-id]="'SIDE_BAR.REPORTS.TRIAL_PLAN'">{{ 'SIDE_BAR.REPORTS.TRIAL_PLAN' | translate }}: </span>
        <span>{{ configService.serverConfig.bizModeParameters.applicationBundle }}</span>
        <span [attr.sh-id]="'trial_plan_days_left'"> ({{ 'SIDE_BAR.REPORTS.TEASER.DAYS_LEFT' | translate }}: {{ configService.serverConfig.premiumTrialInfo.remainingDays }})</span>
      </li>
      <li>
        <span class="title" [attr.translation-id]="'FOOTER.ABOUT.LINKED_NAME'">{{'FOOTER.ABOUT.LINKED_NAME' | translate}}</span>:
        <span [attr.sh-id]="'about_linked'" [attr.translation-id]="'FOOTER.ABOUT.LINKED.' + aboutModel.linked">{{'FOOTER.ABOUT.LINKED.' + aboutModel.linked | translate}}</span>
      </li>
      <li class="suspended" *ngIf="aboutModel.state == AboutModelStateEnum.Suspend">
        <span class="title" [attr.translation-id]="'FOOTER.ABOUT.ACCOUNT_SUSPENDED'">{{'FOOTER.ABOUT.ACCOUNT_SUSPENDED' | translate}}</span>:
        <span [attr.translation-id]="'FOOTER.ABOUT.CALL_YOUR_LOCAL_DEALER_FOR_ADDITIONAL_INFORMATION'">{{'FOOTER.ABOUT.CALL_YOUR_LOCAL_DEALER_FOR_ADDITIONAL_INFORMATION' | translate}}</span>
      </li>
      <li *ngIf="aboutModel.thirdParty?.name">
        <span class="title" [attr.translation-id]="'FOOTER.ABOUT.THIRD_PARTY'">{{'FOOTER.ABOUT.THIRD_PARTY' | translate}}</span>:
        <span [attr.sh-id]="'about_third_party_name'" [attr.translation-id]="'REGISTRATION.THIRD_PARTY.' + aboutModel.thirdParty?.name">{{'REGISTRATION.THIRD_PARTY.' + aboutModel.thirdParty?.name | translate}}</span>
      </li>
      <li *ngIf="aboutModel.bizModel">
        <span class="title">{{'FOOTER.ABOUT.BIZ_MODEL' | translate}}</span>:
        <span [attr.sh-id]="'about_bizModel'" [attr.translation-id]="'FOOTER.ABOUT.BIZ_MODEL_TYPE.' + aboutModel.bizModel">{{"FOOTER.ABOUT.BIZ_MODEL_TYPE." + aboutModel.bizModel | translate}}</span>
      </li>
      <li class="learn-more" *ngIf="isTrial">
        <div class="icon icon-next-blue-2"></div>
        <span (click)="register()" [attr.sh-id]="'about_register_btn'" [attr.translation-id]="'FOOTER.ABOUT.REGISTRATION_LINK'">{{'FOOTER.ABOUT.REGISTRATION_LINK' | translate}}</span>
      </li>
      <li class="learn-more">
        <div class="icon icon-next-blue-2"></div>
        <span (click)="learnMore('Tutorial')" [attr.sh-id]="'about_tutorial_btn'" [attr.translation-id]="'FOOTER.ABOUT.WHATS_NEW_LINK'">{{'FOOTER.ABOUT.WHATS_NEW_LINK' | translate}}</span>
      </li>
      <li class="separate">
        <span>&nbsp;</span>
      </li>
      <li class="legal" nonExternalUser>
        <div class="icon icon-next-green-2"></div>
        <span (click)="learnMore('Terms')" [attr.sh-id]="'about_terms_and_conditions_btn'" [attr.translation-id]="'FOOTER.ABOUT.TERMS_AND_CONDITIONS'">{{'FOOTER.ABOUT.TERMS_AND_CONDITIONS' | translate}}</span>
      </li>
<!--      <li class="legal" nonExternalUser>-->
<!--        <div class="icon icon-next-green-2"></div>-->
<!--        <span (click)="learnMore('Regulatory')" [attr.sh-id]="'regulatory_information_btn'" [attr.translation-id]="'FOOTER.ABOUT.REGULATORY_INFORMATION'">{{'FOOTER.ABOUT.REGULATORY_INFORMATION' | translate}}</span>-->
<!--      </li>-->
    </ul>
  </div>
</div>
