import { Component } from '@angular/core';
import {
  RemoveTagCowEventMetadataBase,
  CreateRemoveTagEventDetails
} from 'src/app/services/animals/model/animal-events';
import {EventFormComponent} from '../event-form.component';
import {CustomEventWrapperCssClass} from "../event-form-errors/event-form-errors.component";

@Component({
  selector: 'remove-tag-event-form',
  templateUrl: './remove-tag-event-form.component.html',
  styleUrls: ['./remove-tag-event-form.component.scss']
})
export class RemoveTagEventFormComponent extends EventFormComponent<RemoveTagCowEventMetadataBase, CreateRemoveTagEventDetails> {

  public get tagNumber(): string | undefined {
    return this.editModel.tag != null ? this.editModel.tag.tagNumber : undefined;
  }

  public set tagNumber(newValue: string) {
    this.editModel.tag.tagNumber = newValue;
    this.emitModelChanged();
  }

  public get isTagNumberInputDisabled(): boolean {
    return true;
  }
}
