<div class="value-aggr">{{ data.getAggregatedValue() }}</div>
<div class="wrapper" [style.height]="maleBarHeight">
  <div class="value"></div>
  <div class="bar bar-male"></div>
  <div class="value value-male">
    <span *ngIf="data.males">{{ data.males }}</span>
  </div>
</div>
<div class="wrapper wrapper-female" [style.height]="femaleBarHeight">
  <div class="value value-female" [ngStyle]="{ marginTop: calculateMarginTop(data.females)}">
    <span *ngIf="data.females">{{ data.females }}</span>
  </div>
  <div class="bar bar-female"></div>
  <div class="value"></div>
</div>
<div class="borders" [ngClass]="{'borders-last': isLast}"></div>
<p class="title" [attr.translation-id]="'DASHBOARD.ACCORDION.AGE.' + data.ageTranslationKey">
  {{ 'DASHBOARD.ACCORDION.AGE.' + data.ageTranslationKey | translate }}
</p>
