import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ReportQuery} from '../../../../services/reports/model/report-query.interface';
import {IReport, ReportDataRow, IReportIds, ReportDisplayType} from '../../../../services/reports/model/report.interface';
import {ReportsService} from '../../../../services/reports/reports.service';
import {HttpResponseResult} from '../../../../services/rest-api/rest-api.service';
import {AnimalsService} from '../../../../services/animals/animals.service';
import {GridSummaryIcon} from 'src/app/common/components/grid-summary/grid-summary.component';
import {ApplicationBundleRoutingMode, RoutingService} from '../../../../services/routing/routing.service';
import {ActivatedRoute} from '@angular/router';
import {
  ReportGridComponent,
  ReportGridValueCellClickDetails
} from '../../../../common/components/reports/report-grid/report-grid.component';
import {Subscription} from 'rxjs';
import {AnimalCardTabSection} from '../../../card-popup/animal-card/animal-card-model';
import {ReportRowSelectionState} from '../../../../services/reports/report-row-selection-state';
import {AnimalEventsService} from '../../../../services/animals/animal-events.service';
import {IReportViewContext} from '../../../../services/reports/model/report-view-context';
import {OperationType} from '../../../../services/search/model/search.model';
import {FarmMode} from '../../../../services/config/model/server-config';
import {ConfigService} from '../../../../services/config/config.service';
import {TranslationService} from '../../../../services/translations/translation.service';
import {GridViewState} from '../../../../services/ui/view-state/grid-row/grid-view-state';
import {CardViewStateService} from '../../../../services/ui/view-state/card-view-state.service';

@Component({
  selector: 'manage-culled-animals',
  templateUrl: './manage-culled-animals.component.html',
  styleUrls: ['./manage-culled-animals.component.scss']
})
export class ManageCulledAnimalsComponent implements OnInit, OnDestroy, IReportViewContext {

  @ViewChild('culledAnimalsReportGrid')
  public culledAnimalsReportGrid: ReportGridComponent;

  public culledAnimalsReportQuery:ReportQuery;
  public culledAnimalsReport:IReport;

  public applicationBundleRoutingMode:ApplicationBundleRoutingMode;

  private eventListChangeSubscription:Subscription;

  private queryParamsSubscription:Subscription;

  private gridViewState:GridViewState = new GridViewState();

  public GridSummaryIcon = GridSummaryIcon;

  constructor(private readonly configService: ConfigService,
              private readonly reportsService: ReportsService,
              public readonly animalsService: AnimalsService,
              private readonly animalEventsService: AnimalEventsService,
              private readonly cardViewStateService:CardViewStateService,
              private activatedRoute:ActivatedRoute,
              private readonly routingService:RoutingService) {

  }

  public async ngOnInit() {
    this.eventListChangeSubscription = this.animalEventsService.eventsListChanged.subscribe(() => {
      this.culledAnimalsReportGrid.reloadGrid(true, null, true);
    });
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(async queryParams => {
      // hide add animal button for culled cows section
      this.applicationBundleRoutingMode = this.routingService.getApplicationBundleRoutingMode(queryParams);
      this.animalsService.isAddAnimalButtonShown = false;
      this.setDefaultCulledAnimalsReportQuery();
      await this.loadCulledAnimalsReport();
    });
  }

  public async ngOnDestroy() {
    this.eventListChangeSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
  }

  public async onReportQueryChanged() {
    await this.loadCulledAnimalsReport();
  }

  public async onReportGridValueCellClick(reportGridValueCellClickDetails: ReportGridValueCellClickDetails) {
    let relatedAnimalsReportQuery = this.culledAnimalsReportQuery.getIdProjectionReportQuery('CowDatabaseIDCalculation');
    let relatedAnimalsReportResult: HttpResponseResult<IReportIds> = await this.reportsService.getCulledAnimalsIds(relatedAnimalsReportQuery, this.applicationBundleRoutingMode);
    if (relatedAnimalsReportResult.status == 200) {
      let relatedAnimals = relatedAnimalsReportResult.responseBody.result.map(value => value['CowDatabaseIDCalculation']);
      let selectedAnimalId = reportGridValueCellClickDetails.row['CowDatabaseIDCalculation'];
      let operationType:OperationType;
      if(this.applicationBundleRoutingMode == ApplicationBundleRoutingMode.Breeding){
        operationType = this.configService.serverConfig.farmMode == FarmMode.Beef ? OperationType.BeefBreeding : OperationType.Dairy;
      } else {
        operationType = OperationType.BeefFinishing;
      }
      this.cardViewStateService.openAnimalCard(selectedAnimalId,
                                               operationType,
                                               relatedAnimals,
                                               ['GROUP.NAVIGATION.COW_IN','GROUP.REPORTS.SPECIAL_TITLE.NAVIGATION.MANAGE'],
                                               AnimalCardTabSection.Events);
    }
  }

  private async loadCulledAnimalsReport() {
    this.culledAnimalsReport = await this.getReportByQuery(this.culledAnimalsReportQuery);
  }

  private setDefaultCulledAnimalsReportQuery(){
    this.culledAnimalsReportQuery = new ReportQuery();
    this.culledAnimalsReportQuery.offset = 0;
    this.culledAnimalsReportQuery.limit = 30;
    this.culledAnimalsReportQuery.type = ReportDisplayType.full;
    this.culledAnimalsReportQuery.includeFilterMetaData = true;
  }

  public async getReportByQuery(reportQuery: ReportQuery): Promise<IReport> {
    let reportResponse: HttpResponseResult<IReport> = await this.reportsService.getCulledAnimals(reportQuery, this.applicationBundleRoutingMode);
    if (reportResponse.responseBody) {
      return reportResponse.responseBody;
    } else {
      return null;
    }
  }

  public getSelectedRowsReport() {
    return null;
  }

  public async reload(isRefresh:boolean, pastReportTime:number): Promise<void> {
    await this.culledAnimalsReportGrid.reloadGrid(isRefresh, pastReportTime, true);
  }

  public async reloadDefault(): Promise<void> {
    await this.reload(true, null);
  }

  public getLoadedRows(): ReportDataRow[] {
    return null;
  }

  public getRowSelectionState(): ReportRowSelectionState {
    return undefined;
  }

  public getGridViewState(): GridViewState {
    return this.gridViewState;
  }

  public getPrintIncludeDateInHeader(): boolean {
    return false;
  }

  public getPrintReportHeader(): string {
    return 'MANAGE.TITLE.CULLED_ANIMALS';
  }
}
