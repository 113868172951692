import {ReportRowSelectionState} from '../../services/reports/report-row-selection-state';
import {GroupCardViewState} from './group-card/group-card-model';
import {AnimalCardViewState} from './animal-card/animal-card-model';
import {OperationType} from '../../services/search/model/search.model';
import {BranchCardViewState} from '../../services/branches/model/branch.interface';

export enum CardViewType {
  Animal = 'Animal',
  Group = 'Group',
  Branch = 'Branch'
}

export class CardViewState {
  public viewType: CardViewType;
  public operationType: OperationType;
  public animalId:number;
  public groupId:number;
  public branchId: number;
  public relatedGroupIds:number[];
  public relatedAnimalIds:number[];
  public relatedBranchIds: number[];
  public selectedItemIndex:number;
  public translationIds:string[];
  public animalCardViewState: AnimalCardViewState;
  public groupCardViewState: GroupCardViewState;
  public branchCardViewState: BranchCardViewState;
  public selectionState: CardViewSelectionState;
}

export class CardViewSelectionState {
  public openedRowId:number;
  public relatedRowIds:number[];
  public rowSelectionState: ReportRowSelectionState;
}
