import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {BranchCardViewState, IBranchGraphDetails} from '../../../../services/branches/model/branch.interface';
import {
  ChartLegendConfiguration,
  NamedChartLegendState,
  XyChartLegendComponent
} from '../../../../common/components/xy-chart-legend/xy-chart-legend.component';
import {ChartSetupProjection, IChartSeriesSetupRecord, IChartSetup} from '../../../../services/charts/model/chart.interface';
import {
  IGroupChartHeatStressData,
  IGroupChartTrendHeatSetupSeries
} from '../../../../services/groups/model/group.interface';
import {ChartDurationSelectionLabel, XyChartComponent} from '../../../../common/components/xy-chart/xy-chart.component';
import {
  ChartConfiguration,
  GridLineIntervalEnum,
  XyChartCalculationService
} from '../../../../services/charts/xy-chart-calculation.service';
import {ChartSeriesSelectionState} from '../../../../common/components/chart-series-dropdown-list/chart-series-dropdown-list.component';
import {ChartsService} from '../../../../services/charts/charts.service';
import {GoogleAnalyticsService} from '../../../../services/google-analytics/google-analytics.service';
import {ChartResolution, IChartPoint} from '../../../../services/model/charts/chart.interface';
import {SeriesModel} from '@syncfusion/ej2-charts';
import {BranchesService} from '../../../../services/branches/branches.service';

@Component({
  selector: 'branch-card-graphs-heat-stress',
  templateUrl: './branch-card-graphs-heat-stress.component.html',
  styleUrls: ['./branch-card-graphs-heat-stress.component.scss']
})
export class BranchCardGraphsHeatStressComponent implements OnChanges  {

  @Input()
  public viewState: BranchCardViewState;

  @Input()
  public branchId: number;

  @Input()
  public branchGraphDetails: IBranchGraphDetails;

  @ViewChild('branchHeatStressChart')
  public branchHeatStressChart: XyChartComponent;

  @ViewChild('branchHeatStressChartLegend')
  public branchHeatStressChartLegend: XyChartLegendComponent;

  public legendConfiguration: ChartLegendConfiguration = {
    translationPrefix: 'ANIMAL.GRAPH.SERIES_TITLE.',
    valuePostfix: ''
  };

  private static readonly HeatChartId: number = 8;

  public legendStates: NamedChartLegendState[];

  // tslint:disable-next-line:no-any
  private branchHeatStressChartSetup: IChartSetup<any, IGroupChartTrendHeatSetupSeries>;

  public selectedDurationLabel: ChartDurationSelectionLabel;

  private optionsDurationLabels: ChartDurationSelectionLabel[] = [];

  private chartConfigurations: ChartConfiguration[] = [];

  public seriesSelectionStates: ChartSeriesSelectionState[];

  public chartData: IGroupChartHeatStressData;

  constructor(private branchesService: BranchesService,
              private chartsService: ChartsService,
              private googleAnalyticsService: GoogleAnalyticsService,
              private xyChartCalculationService: XyChartCalculationService) {

  }

  public async ngOnChanges(changes: SimpleChanges){
    if(changes.viewState != null &&
      changes.viewState.previousValue != changes.viewState.currentValue &&
      this.viewState != null) {
      this.initConfig();
      await this.getChartSetup(false);
      this.initChart(true);
    }
  }

  public onChartPointMouseOver(chartPoint: IChartPoint) {
    this.setLegendValue(chartPoint);
  }

  public async onDurationSelected(duration: ChartDurationSelectionLabel): Promise<void> {
    this.selectedDurationLabel = duration;
    await this.initChart(false);
  }

  public async onSelectionApplied(save: boolean) {
    if (save) {
      this.googleAnalyticsService.send("Branch Card Graph","Change Settings - Save", JSON.stringify(this.branchHeatStressChartSetup));
    } else  {
      this.googleAnalyticsService.send("Branch Card Graph","Change Settings - Without Save", JSON.stringify(this.branchHeatStressChartSetup));
    }

    for (let seriesItem of this.seriesSelectionStates) {
      this.branchHeatStressChartSetup.series[seriesItem.name].isSelected = seriesItem.isSelected;
    }
    this.initChartLegend(this.branchHeatStressChartSetup.series);
    this.branchHeatStressChart.resetChart();
    setTimeout(async () => {
      if(save) {
        let result = await this.chartsService.updateChartSetup(BranchCardGraphsHeatStressComponent.HeatChartId, this.branchHeatStressChartSetup);
        if (result.status != 200) {
          await this.getChartSetup(false);
        }
      }
      this.initChart(true);
    });
  }

  public async onRestoreToDefaults() {

    this.branchHeatStressChart.resetChart();
    setTimeout(async () =>{
      await this.getChartSetup(true);
      this.initChart(true);
    });
  }

  // tslint:disable-next-line:no-any
  private initChartLegend(seriesSetupContainer: any) {
    this.legendStates = [];
    for (let containerSeriesName of Object.getOwnPropertyNames(seriesSetupContainer)) {
      let record : IChartSeriesSetupRecord = seriesSetupContainer[containerSeriesName];
      if(record.isInLegend &&
        record.isVisible &&
        record.isSelected) {
        this.legendStates.push({
          name: containerSeriesName,
          value: null,
          altTranslation: null,
          altCss: null
        });
      }
    }
  }

  private async getChartSetup(reset: boolean) {
    // tslint:disable-next-line:no-any
    let response = await this.chartsService.getChartSetup<any, IGroupChartTrendHeatSetupSeries>(BranchCardGraphsHeatStressComponent.HeatChartId, reset ? ChartSetupProjection.resetsetup : ChartSetupProjection.getsetup);
    if(response.status == 200){
      this.branchHeatStressChartSetup = response.responseBody;
      this.initChartLegend(this.branchHeatStressChartSetup.series);
      this.initChartSeriesSelection();
    }
  }

  private initChartSeriesSelection() {
    this.seriesSelectionStates = [];
    for (let containerSeriesName of Object.getOwnPropertyNames(this.branchHeatStressChartSetup.series)) {
      let record : IChartSeriesSetupRecord = this.branchHeatStressChartSetup.series[containerSeriesName];
      if(record.isVisible) {
        this.seriesSelectionStates.push({
          name: containerSeriesName,
          isSelected: record.isSelected,
          isRemovable: record.isRemovable
        });
      }
    }
  }

  private async initChart(reloadData:boolean) {
    this.viewState.graphsHeatChartSelectedDuration = this.optionsDurationLabels.indexOf(this.selectedDurationLabel);

    if(this.chartData == null ||
      reloadData) {
      let response = await this.branchesService.getBranchHeatStressChart(
        this.branchId,
        BranchCardGraphsHeatStressComponent.HeatChartId,
        ChartResolution.Hour,
        this.seriesSelectionStates.filter(value => value.isSelected)
          .map(value => value.name));
      if(response.status == 200){
        this.chartData = response.responseBody;
      }
    }

    this.branchHeatStressChart.initChart(this.getSeries(),
      this.chartData.series,
      this.chartConfigurations[this.viewState.graphsHeatChartSelectedDuration],
      false,
      this.selectedDurationLabel,
      this.optionsDurationLabels,
      this.chartData.eventsSeries);
  }

  private initConfig() {
    this.optionsDurationLabels.push({
      selectedDisplayText: '7 DAYS',
      optionDisplayText: '7'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24,
      gridLineInterval: GridLineIntervalEnum.Day,
      visiblePeriodHours: 24 * 7,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '14 DAYS',
      optionDisplayText: '14'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24,
      gridLineInterval: GridLineIntervalEnum.Day,
      visiblePeriodHours: 24 * 14,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '30 DAYS',
      optionDisplayText: '30'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 2,
      gridLineInterval: GridLineIntervalEnum.TwoDays,
      visiblePeriodHours: 24 * 30,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '60 DAYS',
      optionDisplayText: '60'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 3,
      gridLineInterval: GridLineIntervalEnum.ThreeDays,
      visiblePeriodHours: 24 * 60,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '90 DAYS',
      optionDisplayText: '90'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 4,
      gridLineInterval: GridLineIntervalEnum.FourDays,
      visiblePeriodHours: 24 * 90,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '180 DAYS',
      optionDisplayText: '180'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 9,
      gridLineInterval: GridLineIntervalEnum.NineDays,
      visiblePeriodHours: 24 * 180,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '360 DAYS',
      optionDisplayText: '360'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 18,
      gridLineInterval: GridLineIntervalEnum.Month,
      visiblePeriodHours: 24 * 360,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '420 DAYS',
      optionDisplayText: '420'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 18,
      gridLineInterval: GridLineIntervalEnum.Month,
      visiblePeriodHours: 24 * 420,
      primaryYAxisMaxValue: 800,
      secondaryYAxisMaxValue: 0
    }));

    this.selectedDurationLabel = this.optionsDurationLabels[this.viewState.graphsHeatChartSelectedDuration];
  }

  // tslint:disable-next-line:no-any
  private setLegendValue(valueContainer: any) {
    if(valueContainer == null ){
      this.legendStates.forEach(legendState => {
        legendState.value = null;
      });
    } else {
      this.legendStates.forEach(legendState => {
        legendState.value = valueContainer[legendState.name];
        if(legendState.value == null) {
          legendState.value = 0;
        }
      });
    }
  }

  private getSeries() : SeriesModel[] {
    let seriesModel : SeriesModel[] = [];

    if(this.branchHeatStressChartSetup.series.averageDailyRumination.isSelected &&
      this.branchHeatStressChartSetup.series.averageDailyRumination.isVisible) {

      seriesModel.push({
        type: 'Line',
        xName: 'x',
        yName: 'averageDailyRumination',
        width: 2,
        fill: '#aa64aa',
        animation: {
          enable: false
        },
        dataSource: this.chartData.series
      });

      seriesModel.push({
        type: 'Area',
        xName: 'x',
        yName: 'averageDailyRumination',
        opacity: 0.2,
        fill: '#aa64aa',
        animation: {
          enable: false
        },
        dataSource: this.chartData.series
      });

    }

    if(this.branchHeatStressChartSetup.series.averageDailyEating.isSelected &&
      this.branchHeatStressChartSetup.series.averageDailyEating.isVisible) {
      seriesModel.push({
        type: 'Line',
        xName: 'x',
        yName: 'averageDailyEating',
        width: 2,
        fill: '#56c3b8',
        animation: {
          enable: false
        },
        dataSource: this.chartData.series
      });
    }

    if(this.branchHeatStressChartSetup.series.averageDailyPanting.isSelected &&
      this.branchHeatStressChartSetup.series.averageDailyPanting.isVisible) {
      seriesModel.push({
        type: 'Line',
        xName: 'x',
        yName: 'averageDailyPanting',
        width: 2,
        fill: '#f04c39',
        animation: {
          enable: false
        },
        dataSource: this.chartData.series
      });

      seriesModel.push({
        type: 'Area',
        xName: 'x',
        yName: 'averageDailyPanting',
        opacity: 0.2,
        fill: '#f04c39',
        animation: {
          enable: false
        },
        dataSource: this.chartData.series
      });

    }

    return seriesModel;
  }
}
