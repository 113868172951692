import {IGroupMetadata} from '../../groups/model/group.interface';
import {ITag} from '../../tags/model/tags-model';
import {DataRow, ErrorModel} from '../../model/common-model';

export enum EventTypeEnum {
  Birth = 'birth',
  Breeding = 'breeding',
  Calving = 'calving',
  ChangeGroup = 'changeGroup',
  Culling = 'culling',
  DoNotBreed = 'doNotBreed',
  Unsort = 'unsort',
  DryOff = 'dryOff',
  Distress = 'distress',
  HerdEntry = 'herdEntry',
  PregnancyCheck = 'pregnancyCheck',
  PositivePregnancyCheck = 'positivePregnancyCheck',
  NegativePregnancyCheck = 'negativePregnancyCheck',
  UncertainPregnancyCheck = 'uncertainPregnancyCheck',
  AssignScrTag = 'assignScrTag',
  AssignRfIdTag = 'assignRfIdTag',
  ChangeScrTag = 'changeScrTag',
  ChangeRfIdTag = 'changeRfIdTag',
  RemoveTag = 'removeTag',
  RemoveRfIdTag = 'removeRfIdTag',
  RemoveScrTag = 'removeScrTag',
  SystemHealth = 'systemHealth',
  SystemHeat = 'systemHeat',
  Abortion = 'abortion',
  AbortionSameLactation = 'abortionSameLactation',
  AbortionNewLactation = 'abortionNewLactation',
  AbortionType = 'abortionType',
  AssignTag = 'assignTag',
  Weaning = 'weaning',
  ChangeTag = 'changeTag',
  Weight = 'weight',
  Castration = 'castration',
  StartFinishing = 'startFinishing',
  StartBackground = 'startBackground',
  General = 'general',
  TagSwUpdate = 'tagSwUpdate'
}

export enum CreateEventMode {
  single = 'single',
  batch = 'batch'
}

export enum BatchResultState {
  InProgress = 'InProgress',
  Completed = 'Completed',
  CompletedWithErrors ='CompletedWithErrors',
  Correct = 'Correct',
  Incorrect = 'Incorrect',
  BatchEventUnsupported = 'BatchEventUnsupported'
}

export enum EventValidationErrorTypes {
  AnimalNotFound = 'animalNotFound',
  AnimalIsCulled = 'animalIsCulled',
  EventDateStartDateNotExist = 'eventDateStartDateNotExist',
  EventDateStartDateInTheFuture = 'eventDateStartDateInTheFuture',
  LactationEventStartDateIsBeforeLast = 'lactationEventStartDateIsBeforeLast',
  LactationEventIsFromPreviousLactation = 'lactationEventIsFromPreviousLactation',
  CowEntryBirthDateIsEmpty = 'cowEntryBirthDateIsEmpty',
  CowEntryBirthDateIsAfterOrEqualsToEventStartDate = 'cowEntryBirthDateIsAfterOrEqualsToEventStartDate',
  AddEventNotValidForCow = 'addEventNotValidForCow',
  AddEventForbiddenForCow = 'addEventForbiddenForCow',
  RemoveEventForbiddenForCow = 'removeEventForbiddenForCow',
  UpdateEventForbiddenForCow = 'updateEventForbiddenForCow',
  AnimalGroupNotValid = 'animalGroupNotValid',
  EventStartDateIsBeforeLastSameEvent = 'eventStartDateIsBeforeLastSameEvent',
  TransferredToCulledGroupWithNoCullingEvent = 'transferredToCulledGroupWithNoCullingEvent',
  GeneralFailure = 'generalFailure',
  EventDateIsLessThanMinDaysToReportCalving = 'eventDateIsLessThanMinDaysToReportCalving',
  EventDateIsLessThanMinDaysToReportAbortion = 'eventDateIsLessThanMinDaysToReportAbortion',
  EventDateIsMoreThanMaxDaysToReportAbortion = 'eventDateIsMoreThanMaxDaysToReportAbortion',
  EventNotFound = 'eventNotFound',
  EventNotBelongToCow = 'eventNotBelongToCow',
  LactationEventNotLast = 'lactationEventNotLast',
  NotAllowDeleteChangeGroupEvent = 'notAllowDeleteChangeGroupEvent',
  NotAllowDeleteHerdEntryEvent = 'notAllowDeleteHerdEntryEvent',
  NotAllowDeleteBirthEvent = 'notAllowDeleteBirthEvent',
  NotAllowDeleteTagReplacementEvent = 'notAllowDeleteTagReplacementEvent',
  NotValidTagReplacementEventAction = 'notValidTagReplacementEventAction',
  TagNotFound = 'tagNotFound',
  TagNotAssignToAnimal = 'tagNotAssignToAnimal',
  TagAssignedToOtherAnimal = 'tagAssignedToOtherAnimal',
  TagNumberNotValid = 'tagNumberNotValid',
  OldDoNotBreedEventStillActive = 'oldDoNotBreedEventStillActive',
  TransferredToSameGroup = 'transferredToSameGroup',
  EffectiveInseminationRequired = 'effectiveInseminationRequired',
  BreedingEventNotFound = 'breedingEventNotFound',
  UpdatedBirthDateAfterExistingEvent = 'updatedBirthDateAfterExistingEvent',
  NewDNBEndDateIsBeforeStartDate = 'newDNBEndDateIsBeforeStartDate',
  NewDNBExpirationDateIsBeforeStartDate = 'newDNBExpirationDateIsBeforeStartDate',
  DoNotBreedEndDateSetToNull = 'doNotBreedEndDateSetToNull',
  DoNotBreedEventAlreadyClosed = 'doNotBreedEventAlreadyClosed',
  EventCanNotBeUpdated = 'eventCanNotBeUpdated',
  UpdatedHerdEntryAfterSomeEvent = 'updatedHerdEntryAfterSomeEvent',
  SireDetailsMissing = 'sireDetailsMissing',
  TagTypeNotSpecified = 'tagTypeNotSpecified',
  CowEntryStatusMissing = 'cowEntryStatusMissing',
  AnimalAlreadyHasTag = 'animalAlreadyHasTag',
  TagModelIsMissing = 'tagModelIsMissing',
  CowOnTagDoesNotMatchCow = 'cowOnTagDoesNotMatchCow',
  CullingEventCantStartBeforeExistingEvent = 'cullingEventCantStartBeforeExistingEvent',
  SameTagAlreadyAssignToThisAnimal = 'sameTagAlreadyAssignToThisAnimal',
  AnimalIdAlreadyInUse = 'animalIdAlreadyInUse',
  StartDateTimeBeforeLastSameEvent = 'StartDateTimeBeforeLastSameEvent',
  AnimalIdInvalid = 'AnimalIdInvalid',
  SexIsMissing = 'SexIsMissing',
  EaseIsMissing = 'EaseIsMissing',
  ConditionIsMissing = 'ConditionIsMissing',
  SireAlreadyExists = 'sireAlreadyExists',
  ORNAlreadyInUse = 'ORNAlreadyInUse',
  DuplicateORNForNewborns = 'DuplicateORNForNewborns',
  DuplicateAnimalIdForNewborns = 'DuplicateAnimalIdForNewborns',
  LactationNumberNotValid = 'LactationNumberNotValid',
  UnknownEventType = 'unknownEventType',
  EventStartDateIsBeforeLastCalvingEvent = 'EventStartDateIsBeforeLastCalvingEvent',
  AnimalIdUpdateIsForbidden = 'animalIdUpdateIsForbidden',
  OrnUpdateIsForbidden = 'ornUpdateIsForbidden',
  SireChoice1UpdateIsForbidden = 'sireChoice1UpdateIsForbidden',
  SireChoice2UpdateIsForbidden = 'sireChoice2UpdateIsForbidden',
  SireChoice3UpdateIsForbidden = 'sireChoice3UpdateIsForbidden',
  LactationNumberUpdateIsForbidden = 'lactationNumberUpdateIsForbidden',
  LastCalvingDateUpdateIsForbidden = 'lastCalvingDateUpdateIsForbidden',
  SireInUse = 'sireInUse',
  EventCanNotBeDeleted = 'eventCanNotBeDeleted',
  ExternalIdAlreadyInUse = 'externalIdAlreadyInUse',
  NewUnsortExpirationDateIsBeforeCurrentDate = 'newUnsortExpirationDateIsBeforeCurrentDate',
  NewUnsortExpirationDateIsNull = 'newUnsortExpirationDateIsNull',
  ServerError = 'SERVER_ERROR'
}

export class EventValidationError {
  public key: EventValidationErrorTypes;
  public fieldName: string;
}

export class EventValidationErrorResponseResult {
  public eventId: number;
  public failures: EventValidationError[];
  public succeeded: boolean;
}

export class AnimalValidationResult {
  public id: number;
  public animalId: string;
  public errors: EventValidationError[];
}

export class BatchEventResult {
  public succeeded: boolean;
  public state: BatchResultState;
  public correlationId: string;
  public createdEvents: number;
  public failures: AnimalValidationResult[];
}

export class AnimalsEventsResult {
  public limit: number;
  public offset: number;
  public result: AnimalEventRow[];
  public total: number;
}

export class AnimalEventRow extends DataRow{
  public type: string;
  public animalId: number;
  public animalName: string;
  public eventId: number;
  public lactationNumber: number;
  public daysInLactation: number;
  public ageInDays: number;
  public startDateTime: number;
  public reportingDateTime: number;
  public isChangeOperation?: boolean;
  public isFinishing?: boolean;
  public deletable?: boolean;
  public active?: boolean;
  public endDate?: number;
}

export interface ISire {
  id?: number;
  number?: string;
  name?: string;
}

export interface IDam {
  // tslint:disable-next-line:no-any
  id: any;
  // tslint:disable-next-line:no-any
  name: any;
}

export interface ICalvingEase {
  id: number;
  key: string;
  value: string;
}

export enum NewbornCondition {
  alive = 'alive',
  dead = 'dead',
  sold = 'sold'
}

export enum NewbornSex {
  female = 'female',
  male = 'male'
}

export class Newborn {
  public animalName: number;
  public sex: NewbornSex;
  public condition: NewbornCondition;
  public destinationGroup: number;
  public orn: string;
}

export interface ICullingReason {
  id: number;
  key: string;
  value: string;
}

export enum PregnancyCheckType {
  negativePregnancyCheck = 'negativePregnancyCheck',
  positivePregnancyCheck = 'positivePregnancyCheck',
  uncertainPregnancyCheck = 'uncertainPregnancyCheck'
}

export class BirthEventRow extends AnimalEventRow {
  public type: string = 'birth';
}

export class BreedingEventRow extends AnimalEventRow {
  public type: string = 'breeding';
  public sire: ISire;
  public breedingNumber: number;
  public isOutOfBreedingWindow: boolean;
  public interval: number;
  public breedingComment: string;
}

export class CalvingEventRow extends AnimalEventRow {
  public type: string = 'calving';
  public destinationGroup: number;
  public destinationGroupName: string;
  public calvingEase: ICalvingEase;
  public daysInPregnancy: number;
  public oldLactationNumber: number;
  public newborns: Newborn[];
}

export class AbortionSameLactationEventRow extends AnimalEventRow {
  public type: string = 'abortionSameLactation';
  public destinationGroup: number;
  public destinationGroupName: string;
  public ease: ICalvingEase;
  public daysInPregnancy: number;
  public oldLactationNumber: number;
  public newborns: Newborn[];
}

export class AbortionNewLactationEventRow extends AnimalEventRow {
  public type: string = 'abortionNewLactation';
  public destinationGroup: number;
  public destinationGroupName: string;
  public ease: ICalvingEase;
  public daysInPregnancy: number;
  public oldLactationNumber: number;
  public newborns: Newborn[];
}

export class ChangeGroupEventRow extends AnimalEventRow {
  public type: string = 'changeGroup';
  public newGroupId: number;
  public newGroupName: string;
  public currentGroupId: number;
  public currentGroupName: string;
}

export class CullingEventRow extends AnimalEventRow {
  public type: string = 'culling';
  public cullingReason: ICullingReason;
}

export class DoNotBreedEventRow extends AnimalEventRow {
  public type: string = 'doNotBreed';
  public active: boolean;
  public expirationDate: number;
  public endDate: number;
}

export class UnsortEventRow extends AnimalEventRow {
  public type: string = 'unsort';
  public active: boolean;
  public expirationDate: number;
  public endDate: number;
}

export class DryOffEventRow extends AnimalEventRow {
  public type: string = 'dryOff';
  public destinationGroup: number;
  public destinationGroupName: string;
  public daysInPregnancy: number;
}

export class HerdEntryEventRow extends AnimalEventRow {
  public type: string = 'herdEntry';
  public destinationGroup: number;
  public destinationGroupName: string;
  public animalEntryStatus: string;
}

export class PregnancyCheckEventRow extends AnimalEventRow {
  public type: PregnancyCheckType;
  public effectiveInsemination: number;
  public embryoAge: number;
  public effectiveInseminationDate: number;
}

export class AssignScrTagEventRow extends AnimalEventRow {
  public type: string = 'assignScrTag';
  public tag: ITag;
}

export class AssignRfIdTagEventRow extends AnimalEventRow {
  public type: string = 'assignRfIdTag';
  public tag: ITag;
}

export class ChangeScrTagEventRow extends AnimalEventRow {
  public type: string = 'changeScrTag';
  public previousTag: ITag;
  public tag: ITag;
}

export class ChangeRfIdTagEventRow extends AnimalEventRow {
  public type: string = 'changeRfIdTag';
  public previousTag: ITag;
  public tag: ITag;
}

export class RemoveTagEventRow extends AnimalEventRow {
  public type: string = 'removeTag';
  public previousTag: ITag;
  public tag: ITag;
}

export class RemoveRfIdTagEventRow extends AnimalEventRow {
  public type: string = 'removeRfIdTag';
  public previousTag: ITag;
  public tag: ITag;
}

export class RemoveScrTagEventRow extends AnimalEventRow {
  public type: string = 'removeScrTag';
  public previousTag: ITag;
  public tag: ITag;
}

export class SystemHealthEventRow extends AnimalEventRow {
  public type: string = 'systemHealth';
  public healthIndex: number;
}

export class SystemHeatEventRow extends AnimalEventRow {
  public type: string = 'systemHeat';
  public heatIndexPeak: number;
}

export class WeightEventRow extends AnimalEventRow{
  public type: string = 'weight';
  public weightValueKG?: number;
  public weightValueLBS?: number;
}

export class TagSwUpdateEventRow extends AnimalEventRow {
  public oldVersion: string;
  public newVersion: string;
}

export class DistressEventRow extends AnimalEventRow {
  public duration: number;
  public alertType: string;
  public daysInPregnancy: number
}

export class GeneralEventRow extends AnimalEventRow{
  public description: GeneralEventDescription;
}

export interface IAvailableEventInfo {
  type?: EventTypeEnum;
  childEvents?: IAvailableEventInfo[];
  category?: CowEventCategory;
  isEnabled?: boolean;
  isVisible: boolean;
  // tslint:disable-next-line:no-any
  minDate?: number;
  // tslint:disable-next-line:no-any
  maxDate?: number;
}

export interface IErrorNewborns {
  errorAnimalID: boolean;
  errorCondition: boolean;
  errorDestinationGroup: boolean;
  errorOrn: boolean;
  errorSex: boolean;
}

export class GeneralEventDescription {
  public id: number;
  public value: string;
  public key: string;
}

export enum CowEventCategory {
  ReproductionAndGeneral = 'reproductionAndGeneral',
  TagManagement = 'tagManagement'
}

export abstract class CowEventMetadataBase {
  public type: EventTypeEnum;
}

export class GeneralEventMetadata extends CowEventMetadataBase{
  public descriptions: GeneralEventDescription[];

  constructor() {
    super();
    this.type = EventTypeEnum.General;
  }
}

export class AbortionCowEventMetadata extends CowEventMetadataBase {
  public openNewLactation: boolean;
  public calvingEase: ICalvingEase[];
  public cowsGroupsSameLactation: IGroupMetadata[];
  public cowsGroupsNewLactation: IGroupMetadata[];
  public heifersGroups: IGroupMetadata[];

  constructor() {
    super();
    this.type = EventTypeEnum.Abortion;
  }
}

export class AbortionSameLactationCowEventMetadata extends CowEventMetadataBase{
  public cowsGroupsSameLactation: IGroupMetadata[];

  constructor() {
    super();
    this.type = EventTypeEnum.AbortionSameLactation;
  }
}

export class AssignTagCowEventMetadataBase extends CowEventMetadataBase {
  public unassignedTags: ITag[];

  constructor() {
    super();
  }
}

export class AssignScrTagCowEventMetadata extends AssignTagCowEventMetadataBase {
  constructor() {
    super();
    this.type = EventTypeEnum.AssignScrTag;
  }
}

export class AssignRfIdTagCowEventMetadata extends AssignTagCowEventMetadataBase {
  constructor() {
    super();
    this.type = EventTypeEnum.AssignRfIdTag;
  }
}

export class AssignTagCowEventMetadata extends CowEventMetadataBase {
  public animalTag: ITag;
  public unassignedTags: ITag[];

  constructor() {
    super();
    this.type = EventTypeEnum.AssignTag;
  }
}

export class BreedingCowEventMetadata extends CowEventMetadataBase {
  public sires: ISire[];

  constructor() {
    super();
    this.type = EventTypeEnum.Breeding;
  }
}

export class CalvingCowEventMetadata extends CowEventMetadataBase {
  public calvingEase: ICalvingEase[];
  public cowGroups: IGroupMetadata[];
  public newBornsGroups: IGroupMetadata[];

  constructor() {
    super();
    this.type = EventTypeEnum.Calving;
  }
}

export class ChangeGroupCowEventMetadata extends CowEventMetadataBase {
  public groups: IGroupMetadata[];

  constructor() {
    super();
    this.type = EventTypeEnum.ChangeGroup;
  }
}

export class WeaningAnimalEventMetadata extends CowEventMetadataBase {
  public groups: IGroupMetadata[];

  constructor() {
    super();
    this.type = EventTypeEnum.Weaning;
  }
}

export class ChangeTagCowEventMetadata extends CowEventMetadataBase {
  public animalScrTag: ITag;
  public animalRfIdTag: ITag;
  public scrTags: ITag[];
  public rfIdTags: ITag[];

  constructor() {
    super();
    this.type = EventTypeEnum.ChangeGroup;
  }
}

export class ChangeTagCowEventMetadataBase extends CowEventMetadataBase {
  public animalTag: ITag;
  public unassignedTags: ITag[];

  constructor() {
    super();
    this.type = EventTypeEnum.ChangeGroup;
  }
}

export class ChangeRfIdTagCowEventMetadata extends ChangeTagCowEventMetadataBase {
  constructor() {
    super();
    this.type = EventTypeEnum.ChangeRfIdTag;
  }
}

export class ChangeScrTagCowEventMetadata extends ChangeTagCowEventMetadataBase {
  constructor() {
    super();
    this.type = EventTypeEnum.ChangeScrTag;
  }
}

export class CullingCowEventMetadata extends CowEventMetadataBase {
  public cullingReason: ICullingReason[];
  public isScrTagExist: boolean;

  constructor() {
    super();
    this.type = EventTypeEnum.Culling;
  }
}

export class DoNotBreedCowEventMetadata extends CowEventMetadataBase {
  // tslint:disable-next-line:no-any
  public expirationDate: any;

  constructor() {
    super();
    this.type = EventTypeEnum.DoNotBreed;
  }
}

export class UnsortCowEventMetadata extends CowEventMetadataBase {
  constructor() {
    super();
    this.type = EventTypeEnum.Unsort;
  }
}

export class DryOffCowEventMetadata extends CowEventMetadataBase {
  public groups: IGroupMetadata[];

  constructor() {
    super();
    this.type = EventTypeEnum.DryOff;
  }
}

export class PregnancyCheckCowEventMetadata extends CowEventMetadataBase {
  public inseminations?: BreedingEventRow[];
  public allowedNegative: boolean;
  public allowedPositive: boolean;
  public allowedUncertain: boolean;

  constructor() {
    super();
    this.type = EventTypeEnum.PositivePregnancyCheck;
  }
}

export class RemoveTagCowEventMetadataBase extends CowEventMetadataBase {
  public animalTag: ITag;

  constructor() {
    super();
  }
}

export class RemoveScrTagCowEventMetadata extends RemoveTagCowEventMetadataBase {
  constructor() {
    super();
    this.type = EventTypeEnum.RemoveScrTag;
  }
}

export class RemoveRfIdTagCowEventMetadata extends RemoveTagCowEventMetadataBase {
  constructor() {
    super();
    this.type = EventTypeEnum.RemoveRfIdTag;
  }
}

export class RemoveTagCowEventMetadata extends CowEventMetadataBase {
  public animalScrTag: ITag;
  public animalRfIdTag: ITag;
  public scrTags: ITag[];
  public rfIdTags: ITag[];

  constructor() {
    super();
    this.type = EventTypeEnum.RemoveTag;
  }
}

export class WeightEventMetadata extends CowEventMetadataBase {
  constructor() {
    super();
    this.type = EventTypeEnum.Weight;
  }
}

export class CastrationEventMetadata extends CowEventMetadataBase {
  constructor() {
    super();
    this.type = EventTypeEnum.Castration;
  }
}

export class StartBackgroundEventMetadata extends CowEventMetadataBase {
  constructor() {
    super();
    this.type = EventTypeEnum.StartBackground;
  }
}

export class StartFinishingEventMetadata extends CowEventMetadataBase {
  constructor() {
    super();
    this.type = EventTypeEnum.StartFinishing;
  }
}

export type BatchEventActionType = 'validationEvent' | 'batchEvent';

export type BatchEventActionDetails = {
  startDateTime: number;
  actionEvent: BatchEventActionType;
  animalIds: number[];
};

export class EventActionDetails {
  public startDateTime: number;
}

export enum AbortionType {
  AbortionNewLactation = 'abortionNewLactation',
  AbortionSameLactation = 'abortionSameLactation',
  Unselected = '',
}

export class EventDetailsError extends ErrorModel {
  public index?: number;
}

export class EventDetailsBase {
  public type?: EventTypeEnum | PregnancyCheckType | AbortionType;
}

export abstract class EventDetails extends EventDetailsBase {
  public isValid(): boolean {
    return this.type != null;
  }
}

export class CreateGeneralEventDetails extends EventDetails {
  public description: GeneralEventDescription;

  constructor(meta?: GeneralEventMetadata) {
    super();
    this.type = EventTypeEnum.General;
  }

  public isValid(): boolean {
    return super.isValid() && (this.description != null);
  }
}

export class CreateAbortionEventDetails extends EventDetails {
  public destinationGroup?: number;
  public ease: ICalvingEase;
  public newborns: Newborn[] = [];

  constructor(meta?: AbortionCowEventMetadata) {
    super();
    this.type = AbortionType.Unselected;

    if (meta != null) {
      if(meta.cowsGroupsSameLactation != null) {
        const defaultDestinationGroup = meta.cowsGroupsSameLactation.find(g => g.isDefault);
        if (defaultDestinationGroup != null) {
          this.destinationGroup = defaultDestinationGroup.id;
        }
      }
    }
  }

  /*
   * @Override
   * */
  public isValid(): boolean {
    return this.type !== AbortionType.Unselected && super.isValid();
  }
}

export class CreateAbortionSameLactationEventDetails extends EventDetails {
  public destinationGroup?: number;

  constructor(meta?: AbortionSameLactationCowEventMetadata) {
    super();
    this.type = AbortionType.AbortionSameLactation;

    if (meta != null) {
      const defaultDestinationGroup = meta.cowsGroupsSameLactation.find(g => g.isDefault);
      if (defaultDestinationGroup != null) {
        this.destinationGroup = defaultDestinationGroup.id;
      }
    }
  }
}

export class CreateAssignTagEventDetailsBase extends EventDetails {
  public tag?: ITag;

  constructor(meta: CowEventMetadataBase) {
    super();
  }

  public isValid(): boolean {
    return super.isValid() && this.tag != null;
  }
}

export class CreateAssignRfIdTagEventDetails extends CreateAssignTagEventDetailsBase {
  constructor(meta: AssignRfIdTagCowEventMetadata) {
    super(meta);
    this.type = EventTypeEnum.AssignRfIdTag;
  }
}

export class CreateAssignSrcTagEventDetails extends CreateAssignTagEventDetailsBase {
  constructor(meta: AssignScrTagCowEventMetadata) {
    super(meta);
    this.type = EventTypeEnum.AssignScrTag;
  }
}

export class CreateBreedingEventDetails extends EventDetails {
  public sire: ISire;

  constructor() {
    super();
    this.type = EventTypeEnum.Breeding;
  }
}

export class CreateCalvingEventDetails extends EventDetails {
  public destinationGroup?: number | null;
  public calvingEase?: ICalvingEase | null;
  public newborns: Newborn[] = [];

  constructor(meta?: CalvingCowEventMetadata) {
    super();
    this.type = EventTypeEnum.Calving;

    if (meta != null) {
      const defaultDestinationGroup = meta.cowGroups.find(g => g.isDefault);
      if (defaultDestinationGroup != null) {
        this.destinationGroup = defaultDestinationGroup.id;
      }
    }
  }

  public isValid(): boolean {
    return super.isValid() && this.destinationGroup != null;
  }
}

export class WeightEventDetails extends EventDetails {
  public weightValueKG?: string = null;
  public weightValueLBS?: string = null;

  constructor() {
    super();
    this.type = EventTypeEnum.Weight;
  }

  public isValid(): boolean {
    return super.isValid() && (this.weightValueKG != null || this.weightValueLBS != null) && (this.weightValueKG !== '' || this.weightValueLBS !== '');
  }
}

export class CastrationEventDetails extends EventDetails {
  constructor() {
    super();
    this.type = EventTypeEnum.Castration;
  }
}

export class StartBackgroundEventDetails extends EventDetails {
  constructor() {
    super();
    this.type = EventTypeEnum.StartBackground;
  }

  public isValid(): boolean {
    return super.isValid();
  }
}

export class StartFinishingEventDetails extends EventDetails {
  constructor() {
    super();
    this.type = EventTypeEnum.StartFinishing;
  }

  public isValid(): boolean {
    return super.isValid();
  }

}

export class CreateChangeGroupEventDetails extends EventDetails {
  public newGroupId?: number;

  constructor() {
    super();
    this.type = EventTypeEnum.ChangeGroup;
  }

  public isValid(): boolean {
    return super.isValid() && this.newGroupId != null;
  }
}

export class CreateWeaningEventDetails extends EventDetails {
  public destinationGroup: number;

  constructor() {
    super();
    this.type = EventTypeEnum.Weaning;
  }

  public isValid(): boolean {
    return super.isValid() && this.destinationGroup != null;
  }
}

export abstract class CreateChangeTagEventDetailsBase extends EventDetails {
  public tag: ITag;

  constructor(meta: CowEventMetadataBase) {
    super();
  }

  public isValid(): boolean {
    return super.isValid() && this.tag != null;
  }
}

export class CreateChangeTagEventDetails extends CreateChangeTagEventDetailsBase {
  constructor(meta: ChangeTagCowEventMetadata) {
    super(meta);
    this.type = EventTypeEnum.ChangeTag;
  }
}

export class CreateChangeScrTagEventDetails extends CreateChangeTagEventDetailsBase {
  constructor(meta: ChangeScrTagCowEventMetadata) {
    super(meta);
    this.type = EventTypeEnum.ChangeScrTag;
  }
}

export class CreateChangeRfIdTagEventDetails extends CreateChangeTagEventDetailsBase {
  constructor(meta: ChangeRfIdTagCowEventMetadata) {
    super(meta);
    this.type = EventTypeEnum.ChangeRfIdTag;
  }
}

export class CreateCullingEventDetails extends EventDetails {
  public cullingReason: ICullingReason;
  public isScrTagExist: boolean;

  constructor() {
    super();
    this.type = EventTypeEnum.Culling;
  }
}

export class CreateDoNotBreedEventDetails extends EventDetails {
  public expirationDate: number;

  constructor() {
    super();
    this.type = EventTypeEnum.DoNotBreed;
  }
}

export class CreateUnsortEventDetails extends EventDetails {
  public expirationDate: number;

  constructor() {
    super();
    this.type = EventTypeEnum.Unsort;
  }
}

export class CreateDryOffEventDetails extends EventDetails {
  public destinationGroup?: number | null;

  constructor(meta?: DryOffCowEventMetadata) {
    super();
    this.type = EventTypeEnum.DryOff;

    if (meta != null) {
      const defaultDestinationGroup = meta.groups.find((g) => g.isDefault);
      if (defaultDestinationGroup != null) {
        this.destinationGroup = defaultDestinationGroup.id;
      }
    }
  }

  public isValid(): boolean {
    return super.isValid() && this.destinationGroup != null;
  }
}

export class CreatePregnancyCheckEventDetails extends EventDetails {
  public type: PregnancyCheckType;
  public effectiveInsemination: number;
}

export class CreateRemoveTagEventDetails extends EventDetails {
  public tag?: ITag;

  constructor() {
    super();
    this.type = EventTypeEnum.RemoveTag;
  }

  public isValid(): boolean {
    return super.isValid();
  }
}

export class CreateRemoveRfIdTagEventDetails extends CreateRemoveTagEventDetails {
  constructor(meta?: RemoveRfIdTagCowEventMetadata) {
    super();
    this.type = EventTypeEnum.RemoveRfIdTag;
    this.tag = meta != null ? meta.animalTag : undefined;
  }
}

export class CreateRemoveScrTagEventDetails extends CreateRemoveTagEventDetails {
  constructor(meta?: RemoveScrTagCowEventMetadata) {
    super();
    this.type = EventTypeEnum.RemoveScrTag;
    this.tag = meta != null ? meta.animalTag : undefined;
  }
}

export interface ISavedEventResponse {
  newGroupId: number,
  newGroupName: string,
  currentGroupId: number,
  currentGroupName: string,
  isChangeOperation: boolean,
  isFinishing: boolean,
  type: string,
  cowId: number,
  animalId: number,
  animalName: string,
  eventId: number,
  deletable: boolean,
  lactationNumber: number,
  daysInLactation: number,
  ageInDays: number,
  startDateTime: number,
  reportingDateTime: number
}
