import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NewVersionSoftwareModalComponent} from "../../../logged-in-layout/dashboard/maintenance-calls/new-version-software-modal/new-version-software-modal.component";
import {StartedTrialDialogComponent} from "../../../logged-in-layout/dialogs/started-trial-dialog/started-trial-dialog.component";
import {StartedPremiumTrialDialogComponent} from "../../../logged-in-layout/dialogs/started-premium-trial-dialog/started-premium-trial-dialog.component";
import {SyncNowModalComponent} from "../../../logged-in-layout/system/system-management/sync-now-modal/sync-now-modal.component";
import {SystemManagementErrorModalComponent} from "../../../logged-in-layout/system/system-management/system-management-error-modal/system-management-error-modal.component";
import {FarmMode} from "../../../services/config/model/server-config";
import {ConfirmUserDeleteDialogComponent} from "../../../logged-in-layout/system/system-users/dialogs/confirm-user-delete-dialog/confirm-user-delete-dialog.component";
import {AuthService} from "../../../services/auth/auth.service";
import {IDialog} from '../../../services/dialogs/model/dialog.model';
import {DeleteEventErrorDialogComponent} from '../../../logged-in-layout/dialogs/delete-event-error-dialog/delete-event-error-dialog.component';

@Component({
  selector: 'mock-notification-dialogs',
  templateUrl: './mock-notification-dialogs.component.html',
  styleUrls: ['./mock-notification-dialogs.component.scss']
})
export class MockNotificationDialogsComponent implements OnInit, AfterViewInit {

  public loaded: boolean = false;

  public dialogContainers: Map<string, IDialog> = new Map<string, IDialog>();

  //tslint:disable-next-line:no-any
  public selectedDialogContainerKey: string;

  @ViewChild('newVersionSoftwareModalComponent', {static: true})
  public newVersionSoftwareModalComponent: NewVersionSoftwareModalComponent;

  @ViewChild('startedTrialDialogComponent', {static: true})
  public startedTrialDialogComponent: StartedTrialDialogComponent;

  @ViewChild('startedPremiumTrialDialogComponent', {static: true})
  public startedPremiumTrialDialogComponent: StartedPremiumTrialDialogComponent;

  @ViewChild('systemManagementErrorModalComponent', {static: true})
  public systemManagementErrorModalComponent: SystemManagementErrorModalComponent;

  @ViewChild('syncNowModalComponent', {static: true})
  public syncNowModalComponent: SyncNowModalComponent;

  @ViewChild('confirmUserDeleteDialogComponent', {static: true})
  public confirmUserDeleteDialogComponent: ConfirmUserDeleteDialogComponent;

  public farmModes = FarmMode;

  constructor(private authService: AuthService) {
    this.loaded = false;
  }

  public ngOnInit(): void {
    this.mapDialogContainers();
  }

  public ngAfterViewInit(): void {
    this.loaded = true;
  }

  public showSelectedDialog($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    if (this.selectedDialogContainerKey != null) {
      this.dialogContainers.get(this.selectedDialogContainerKey).showDialog();
    }
  }

  public get dialogContainersKeys() : string[] {
    return Array.from(this.dialogContainers.keys());
  }

  private mapDialogContainers(): void {
    this.selectedDialogContainerKey = null;
    this.dialogContainers.set('newVersionSoftwareModalComponent', this.newVersionSoftwareModalComponent);
    this.dialogContainers.set('startedTrialDialogComponent', this.startedTrialDialogComponent);
    this.dialogContainers.set('startedPremiumTrialDialogComponent', this.startedPremiumTrialDialogComponent);
    this.dialogContainers.set('systemManagementErrorModalComponent', this.systemManagementErrorModalComponent);
    this.dialogContainers.set('syncNowModalComponent', this.syncNowModalComponent);
    this.dialogContainers.set('confirmUserDeleteDialogComponent', this.confirmUserDeleteDialogComponent);
  }

  public mockData: any = {
    userToDelete: {
      allowDelete: true,
      allowEdit: true,
      password: '123',
      userId: 123,
      firstName: 'FirstName',
      lastName: 'LastName',
      farmRole: 'farmer',
      phone: '1234567',
      email: 'eklmn@ok.ok',
      username: 'vasya777',
      messagingLanguage: 'en-US',
      question1: { key: 1, value: '111' },
      question2: { key: 2, value: '222' }
    },
    HardwareDetails: { id: 111, hardwareDesc: 'description', hardwareType: 'aaa', hardwareVersion: '1.1.1', isDefault: false, ip: '111.222.111.222', serialNumber: 'AAAAAAAAAAAABBB', ddVersion: '1.1.1', mac: 'AA-AA-AA-AA' },
    HardwareRows: [
      { id: 111, hardwareDesc: 'description 111', hardwareType: 'aaa', hardwareVersion: '1.1.1', isDefault: false, ip: '111.222.111.222', serialNumber: 'AAAAAAAAAAAABBB', ddVersion: '1.1.1', mac: 'AA-AA-AA-AA' },
      { id: 222, hardwareDesc: 'description 222', hardwareType: 'bbb', hardwareVersion: '1.1.1', isDefault: false, ip: '222.111.222.111', serialNumber: 'BBBAAAAAAAAAAAA', ddVersion: '1.1.1', mac: 'BB-BB-BB-BB' }
    ],
  };

  public doNothing(): void {

  }

  public async updateSystemConfiguration(): Promise<void> {
    await this.authService.getServerConfig();
  }
}
