import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {
  DropdownListAutoSelectionMode,
  DropdownListItem
} from '../../../../../../common/components/dropdown-list/dropdown-list-model';
import {
  AddAnimalFieldsErrorsEnum,
  IAddMaleRequest,
  IErrors,
  IGroupItem,
  IMaleMetadata,
  ISCRTagItem,
  TagTypeEnum
} from '../../../../../../services/animals/model/animal.model';
import {WINDOW} from '../../../../../../utils/window-utils';
import {AnimalsService} from '../../../../../../services/animals/animals.service';
import {ConfigService} from '../../../../../../services/config/config.service';
import {FarmMode} from '../../../../../../services/config/model/server-config';
import {InputFilterMode} from '../../../../../../common/directives/input-filter.directive';
import {TranslationService} from '../../../../../../services/translations/translation.service';
import {AutoCompleteColorScheme} from '../../../../../../common/components/auto-complete/auto-complete-model';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../../../../common/components/calendar/model/epoch-state-model';
import {LoadingIconService} from "../../../../../../services/loading-icon/loading-icon.service";
import { CornerColor, CornerContainerBodyBackground } from 'src/app/common/components/containers/corner-container/corner-container.component';

export class AddAnimalModel  {
  public Number: string;

  public Group: IGroupItem;

  public RegistrationNumber: string;

  public EID: ISCRTagItem;

  public tagNumber: ISCRTagItem;

  public birthdate: EpochStateModel = new EpochStateModel(EpochStateModelMinMaxMode.Date);

  constructor() {
    this.birthdate.max = moment().unix();
  }
}

@Component({
  selector: 'add-male-breeding',
  templateUrl: './add-male-breeding.component.html',
  styleUrls: ['./add-male-breeding.component.scss']
})
export class AddMaleBreedingComponent implements OnInit, OnDestroy {

  public dropdownListAutoSelectionMode = DropdownListAutoSelectionMode;

  public addAnimalModel: AddAnimalModel = new AddAnimalModel();

  public maxDate: number = moment().unix();

  public errors: IErrors[] = [];

  public groupsDropdownListItems : DropdownListItem[] = [];

  public rfidsTagsNumbers: string[] = [];

  public scrTagsNumbers: string[] = [];

  public errorFields = AddAnimalFieldsErrorsEnum;

  private maleMetadata :IMaleMetadata;

  public FarmMode = FarmMode;

  public InputFilterMode = InputFilterMode;

  public autoCompleteColorScheme = AutoCompleteColorScheme;

  @ViewChild('registrationNumberInput')
  public registrationNumberInput: ElementRef;

  public CornerColor = CornerColor;

  public CornerContainerBodyBackground = CornerContainerBodyBackground;

  constructor(@Inject(WINDOW) private windoW: Window,
              private readonly loadingIconService: LoadingIconService,
              public animalsService: AnimalsService,
              public configService: ConfigService,
              public elementRef: ElementRef,
              public translationService: TranslationService) {}

  public async ngOnInit() {
      let response = await this.animalsService.getMalesMetadata();
      this.maleMetadata = response.responseBody;
      if (this.maleMetadata.groupList !== null) {
        this.groupsDropdownListItems = this.maleMetadata.groupList.map((groupItem: IGroupItem) => ({ value: groupItem, displayValue: groupItem.name, disabled: false, icon: null }));
      }
      if (this.maleMetadata.rfidTagList !== null) {
        this.rfidsTagsNumbers = this.maleMetadata.rfidTagList.map((rfIdItem: ISCRTagItem) => (rfIdItem.tagNumber));
      }
      if (this.maleMetadata.scrTagList !== null) {
        this.scrTagsNumbers = this.maleMetadata.scrTagList.map((tagItem: ISCRTagItem) => (tagItem.tagNumber));
      }
  }

  public onGroupSelected(groupItem: IGroupItem) {
    this.addAnimalModel.Group = groupItem;
    this.clearErrors();
  }

  public onDateChanged(changedDate: EpochStateModel) {
    this.addAnimalModel.birthdate = changedDate;
    this.clearErrors();
  }

  public onRfIdTagChanged(tagNumber: string) {
    if(tagNumber == null ||
      tagNumber === '') {
      this.addAnimalModel.EID = null;
    } else {
      this.addAnimalModel.EID = this.maleMetadata.rfidTagList.find(value => value.tagNumber === tagNumber);
      if(this.addAnimalModel.EID == null) {
        this.addAnimalModel.EID = {
          tagNumber: tagNumber,
          id: null,
          tagType: TagTypeEnum.RfId
        };
      }
    }
    this.clearErrors();
  }

  public onScrTagChanged(tagNumber: string) {
    if(tagNumber == null ||
      tagNumber === '') {
      this.addAnimalModel.tagNumber = null;
    } else {
      this.addAnimalModel.tagNumber = this.maleMetadata.scrTagList.find(value => value.tagNumber === tagNumber);
      if(this.addAnimalModel.tagNumber == null) {
        this.addAnimalModel.tagNumber = {
          tagNumber: tagNumber,
          id: null,
          tagType: TagTypeEnum.Scr
        };
      }
    }
    this.clearErrors();
  }

  public onClearRegistrationNumber(): void {
    this.addAnimalModel.RegistrationNumber = null;
    this.registrationNumberInput.nativeElement.focus();
    this.clearErrors();
  }

  public isRequiredFieldsValid(): boolean {
    if (!this.addAnimalModel.Number) {
      this.errors[0] = {key: 'ANIMAL_ID_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.animalName };
      return false;
    } else if (!this.addAnimalModel.Group) {
      this.errors[0] = {key: 'GROUP_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.group };
      return false;
    } else if (!this.addAnimalModel.birthdate.epochDate) {
      this.errors[0] = {key: 'BirthDateIsMandatory', fieldName: AddAnimalFieldsErrorsEnum.birthDate };
      return false;
    } else {
      return true;
    }
  }

  public async addMale() {
    this.animalsService.isAddAnimalButtonShown = false;
    if (this.isRequiredFieldsValid()) {
      const request: IAddMaleRequest = {
        animalName: this.addAnimalModel.Number,
        birthDate: this.addAnimalModel.birthdate.epochDate,
        group: this.addAnimalModel.Group,
        scrTag: this.addAnimalModel.tagNumber,
        orn: this.addAnimalModel.RegistrationNumber,
        rfidTag: this.addAnimalModel.EID
      };
      this.loadingIconService.show();
      let response = await this.animalsService.addMale(request);
      if(response.status === 201) {
        this.addAnimalModel = new AddAnimalModel();
        this.loadingIconService.hide();
        this.animalsService.onAnimalsListChanged.next();
      } else {
        this.loadingIconService.hide();
        this.errors = response.errorResponseBody.result.failures;
      }
    }
    this.animalsService.isAddAnimalButtonShown = true;
  }

  public ngOnDestroy() {
    this.animalsService.isAddAnimalOpen = false;
    this.windowResizeMade();
  }

  public clearErrors() {
    this.errors = [];
  }

  private windowResizeMade() {
    setTimeout(() => {
      this.windoW.dispatchEvent(new Event('resize'));
    });
  }
}
