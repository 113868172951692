<div class="calving-tab">
  <div class="double-field-div">
    <div class="input-field">
      <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.READY'">
        {{ 'MANAGE.COWS.ACTIVE.WIZARD.READY' | translate }}
      </div>
      <div class="value">
        <input class="input-class"
               (ngModelChange)="onErrorsClear.emit()"
               [ngClass]="{ error: animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.animalName)}"
               type="text"
               [(ngModel)]="addFemaleModel.animalNumber"
               [attr.sh-id]="'breeding_add_female_animal_number_calving_tab_input'"
               [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER'"
               placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER' | translate }}" readonly/>
      </div>
    </div>
    <div class="input-field">
      <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'">
        {{ 'MANAGE.COWS.ACTIVE.WIZARD.GROUP' | translate }}
      </div>
      <div class="value">
        <forms-dropdown-list class="dropdown-list"
                             [shIdPostfix]="'breeding_add_female_group_calving_tab'"
                             [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.group)"
                             [items]="groupsDropdownListItems"
                             [autoSelectionMode]="dropdownListAutoSelectionMode.Single"
                             [placeholder]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'"
                             [allowClear]="true"
                             [iconTheme]="formsDLIconsThemes.Pink"
                             [translateListItem]="false"
                             [ngModel]="addFemaleModel.group"
                             (ngModelChange)="onGroupSelected.emit($event)"></forms-dropdown-list>
      </div>
    </div>
  </div>
  <div class="seperator"></div>
  <div class="fields-and-errors-div">
    <div class="single-field-div">
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.LACTATION_NUMBER'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.LACTATION_NUMBER' | translate }}
        </div>
        <div class="value">
          <numeric-input [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.lactation)"
                         [attr.sh-id]="'breeding_add_female_breeding_number_calving_tab'"
                         [arrowIconsColor]="ArrowIconColors.pink"
                         class="lactation-numeric-up-down"
                         [min]="0"
                         [max]="99"
                         [selectedValue]="addFemaleModel.lactationNumber"
                         (onChangeSelectedValue)="onLactationNumberChanged($event)"
                         [shId]="'lactation_number_field'"
                         [isSaveOnFocusOut]="true"></numeric-input>
        </div>
      </div>
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE' | translate }}
        </div>
        <div class="value">
          <date-picker  [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.birthDate)"
                        [calendarIconColor]="'#c46da9'"
                        [attr.sh-id]="'breeding_add_female_birth_date_calving_tab'"
                        [selectedEpoch]="addFemaleModel.birthdate"
                        [shownRemovableIcon]="false"
                        [shIdPostfix]="'breeding_add_female_birth_date_calving_tab_date_picker'"
                        (epochChanged)="onBirthdateFieldChanged($event);"></date-picker>
        </div>
      </div>
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.LAST_CALVING_DATE'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.LAST_CALVING_DATE' | translate }}
        </div>
        <div class="value">
          <date-picker [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.calvingDate)"
                       [attr.sh-id]="'breeding_add_female_calving_date_calving_tab'"
                       [calendarIconColor]="'#c46da9'"
                       [disabled]="!addFemaleModel.lactationNumber"
                       [selectedEpoch]="addFemaleModel.lastCalvingDate"
                       [shIdPostfix]="'breeding_add_female_calving_date_calving_tab_date_picker'"
                       (epochChanged)="onCalvingDateFieldChanged($event);"></date-picker>
        </div>
      </div>
    </div>
    <div class="single-field-div">
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.LAST_CALVING_DATE'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.LAST_CALVING_TIME' | translate }}
        </div>
        <div class="value">
          <time-picker [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.calvingDate)"
                       [attr.sh-id]="'breeding_add_female_calving_date_calving_tab'"
                       [themeColor]="'#c46da9'"
                       [disabled]="!addFemaleModel.lactationNumber"
                       [selectedEpoch]="addFemaleModel.lastCalvingDate"
                       [shIdPostfix]="'breeding_add_female_last_calving_time_calving_tab_date_picker'"
                       (epochChanged)="onCalvingDateFieldChanged($event);"></time-picker>
        </div>
      </div>
    </div>
  </div>
</div>
