<div class="heat-chart-header" [attr.sh-id]="'animal_card_health_finishing'">
  <xy-chart-legend #healthChartLegend
                   [configuration]="configuration"
                   [legendStates]="legendStates"></xy-chart-legend>
  <chart-series-dropdown-list [seriesSelections]="seriesSelections"
                              [translationPrefix]="configuration.translationPrefix"
                              (selectionApplied)="onSelectionApplied($event)"
                              (restoreToDefaults)="onRestoreToDefaults()"></chart-series-dropdown-list>
</div>
<xy-chart #healthChart
          [attr.sh-id]="'animal_card_health_graph_finishing'"
          (chartPointMouseOver)="onChartPointMouseOver($event)"
          (durationSelected)="onDurationSelected($event)"></xy-chart>
