import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RegistrationService} from '../../../../../services/registration/registration.service';
import {
  IError,
  IEula,
  IPostDetailsResponse,
  IRegistrationPostDetailsResponse
} from '../../../../../services/registration/model/registration.model';
import {BizModel, FarmMode} from '../../../../../services/config/model/server-config';
import {HttpResponseResult} from '../../../../../services/rest-api/rest-api.service';
import {TranslationService} from '../../../../../services/translations/translation.service';
import {ProvisioningService} from '../../../../../services/auth/provisioning.service';

@Component({
  selector: 'terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  public username: string;

  public eula: IEula;

  // tslint:disable-next-line:no-any
  public checkboxStyle: any = { width: '20px', height: '20px'};

  public checkboxes: ICheckboxes = { scrTermsOfUse: false, scrPrivacyPolicy: false, scrLicenseAgreement: false };

  public termsAndConditionsType = TermsAndConditionsType;

  constructor(private router: Router,
              public registrationService: RegistrationService,
              private translationService: TranslationService,
              private readonly provisioningService: ProvisioningService) { }

  public ngOnInit() {
    this.username = this.registrationService.registrationModel.createAccount.account.username;
    this.eula = this.registrationService.registrationModel.createAccount.account.eula;
    if (this.eula.approved) {
      this.checkboxes = {
        scrLicenseAgreement: true,
        scrPrivacyPolicy: true,
        scrTermsOfUse: true
      }
    }
  }

  public async navigateBack() {
    await this.router.navigate(['/registration/sign-up/security-questions']);
  }

  public async signUp() {
    this.registrationService.registrationModel.createAccount.account.language = this.translationService.selectedLanguage;
    this.registrationService.isLoading = true;
    if (this.registrationService.registrationModel.error) {
      this.registrationService.registrationModel.error = null;
    }
    const postCreateAccountResponse: HttpResponseResult<IRegistrationPostDetailsResponse> = await this.registrationService.postCreateAccount(this.registrationService.registrationModel.createAccount.account);
    if (postCreateAccountResponse.status == 201) {
      if (postCreateAccountResponse.responseBody.correlationId != null) {
        const provisioningSignUpResponse = await this.provisioningService.signUp(postCreateAccountResponse.responseBody.correlationId);
        if (provisioningSignUpResponse.status == 200) {
          this.registrationService.isLoading = false;
          await this.router.navigate(['/login']);
          return;
        } else {
          if (provisioningSignUpResponse.errorResponseBody.result && provisioningSignUpResponse.errorResponseBody.result.failures && provisioningSignUpResponse.errorResponseBody.result.failures.length > 0) {
            const error = { fieldName: null, error: provisioningSignUpResponse.errorResponseBody.result.failures[0].key };
            await this.showRegistrationError(error, provisioningSignUpResponse.errorResponseBody.result);
          }
        }
      } else {
        this.registrationService.isLoading = false;
        await this.router.navigate(['/login']);
        return;
      }
    } else {
      if (postCreateAccountResponse.errorResponseBody.result && postCreateAccountResponse.errorResponseBody.result.failures && postCreateAccountResponse.errorResponseBody.result.failures.length > 0) {
        await this.showRegistrationError(
          { fieldName: postCreateAccountResponse.errorResponseBody.result.field, error: postCreateAccountResponse.errorResponseBody.result.failures[0] },
          postCreateAccountResponse.errorResponseBody.result
        );
      }
    }
  }

  private async showRegistrationError(error: IError, result): Promise<void> {
    this.registrationService.registrationModel.error = error;
    this.registrationService.isLoading = false;
    await this.navigateToErrorPage(result);
  }

  public async navigateToErrorPage(errorResponse: IPostDetailsResponse) {
    switch (errorResponse.field) {
      case SignUpErrorFields.password:
      case SignUpErrorFields.username: {
        await this.router.navigate(['/registration/sign-up/account-details']);
        break;
      }
      case SignUpErrorFields.questionOne:
      case SignUpErrorFields.questionTwo: {
        await this.router.navigate(['/registration/sign-up/security-questions']);
        break;
      }
      default: {
        // do nothing
      }
    }
  }

  public onTermsAndConditionsClicked(termsAndConditionsType: TermsAndConditionsType) {
    let url = '';
    switch (this.eula.farmMode) {
      case FarmMode.Unknown:
      case FarmMode.Dairy: {
        switch (termsAndConditionsType) {
          case TermsAndConditionsType.scrTermsOfUse: {
            url = 'http://www.scrdairy.com/end-user-system-license-agreement/legal.html#TERMS-OF-USE';
            switch (this.eula.bizModelMode) {
              case BizModel.Go: {
                url += '-GO-MODEL';
                break;
              }
              case BizModel.Up: {
                url += '-UP-MODEL';
              }
            }
            break;
          }
          case TermsAndConditionsType.scrPrivacyPolicy: {
            url = 'http://www.scrdairy.com/end-user-system-license-agreement/legal.html#privacy';
            break;
          }
          case TermsAndConditionsType.scrLicenseAgreement: {
            url = 'http://www.scrdairy.com/end-user-system-license-agreement/legal.html#END-USER';
            break;
          }
        }
        break;
      }
      case FarmMode.Beef: {
        switch (termsAndConditionsType) {
          case TermsAndConditionsType.scrTermsOfUse: {
            url = 'http://www.scrdairy.com/END-USER-SYSTEM-LICENSE-AGREEMENT-beef/legal-beef.html#TERMS-OF-USE';
            switch (this.eula.bizModelMode) {
              case BizModel.Go: {
                url += '-GO-MODEL';
                break;
              }
              case BizModel.Up: {
                url += '-UP-MODEL';
                break;
              }
            }
            break;
          }
          case TermsAndConditionsType.scrPrivacyPolicy: {
            url = 'http://www.scrdairy.com/END-USER-SYSTEM-LICENSE-AGREEMENT-beef/legal-beef.html#privacy';
            break;
          }
          case TermsAndConditionsType.scrLicenseAgreement: {
            url = 'http://www.scrdairy.com/END-USER-SYSTEM-LICENSE-AGREEMENT-beef/legal-beef.html#END-USER';
            break;
          }
        }
        break;
      }
    }
    window.open(url,'_blank');
  }

  public toggleCheckbox(checkboxType: TermsAndConditionsType) {
    switch (checkboxType) {
      case TermsAndConditionsType.scrTermsOfUse: {
        this.checkboxes.scrTermsOfUse = !this.checkboxes.scrTermsOfUse;
        break;
      }
      case TermsAndConditionsType.scrPrivacyPolicy: {
        this.checkboxes.scrPrivacyPolicy = !this.checkboxes.scrPrivacyPolicy;
        break;
      }
      case TermsAndConditionsType.scrLicenseAgreement: {
        this.checkboxes.scrLicenseAgreement = !this.checkboxes.scrLicenseAgreement;
        break;
      }
    }
    this.eula.approved = (this.checkboxes.scrTermsOfUse && this.checkboxes.scrPrivacyPolicy && this.checkboxes.scrLicenseAgreement);
  }

}
export enum TermsAndConditionsType {
  scrTermsOfUse = 'scrTermsOfUse',
  scrPrivacyPolicy = 'scrPrivacyPolicy',
  scrLicenseAgreement = 'scrLicenseAgreement'
}
export interface ICheckboxes {
  scrTermsOfUse: boolean;
  scrPrivacyPolicy: boolean;
  scrLicenseAgreement: boolean;
}
export enum SignUpErrorFields {
  username = 'username',
  password = 'password',
  questionOne = 'questionOne',
  questionTwo = 'questionTwo',
  answerOne = 'answerOne',
  answerTwo = 'answerTwo'
}
