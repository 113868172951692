import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {GoogleAnalyticsService} from '../../services/google-analytics/google-analytics.service';
import {ConfigService} from '../../services/config/config.service';
import {ApplicationBundleRoutingMode, RoutingService} from '../../services/routing/routing.service';
import {Subscription} from 'rxjs';
import {FarmMode} from '../../services/config/model/server-config';
import {ManageSettingsService} from "../../services/manage/manage-settings.service";

@Component({
  selector: 'manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit, OnDestroy {

  public applicationBundleRoutingMode: ApplicationBundleRoutingMode;

  private queryParamsSubscription: Subscription;

  private closeManageSettingsDialogSubscription: Subscription;

  private isSettingsPopupShown: boolean = false;

  public ApplicationBundleRoutingMode = ApplicationBundleRoutingMode;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private googleAnalytics: GoogleAnalyticsService,
              public configService: ConfigService,
              private manageSettingsService: ManageSettingsService,
              private routingService: RoutingService) {

  }

  public ngOnInit() {
    this.queryParamsSubscription = this.route.queryParams.subscribe(queryParams => {
      if (this.configService.serverConfig.farmMode === FarmMode.Dairy) {
        this.applicationBundleRoutingMode = null;
      } else {
        this.applicationBundleRoutingMode = this.routingService.getApplicationBundleRoutingMode(queryParams);
      }
    });
    this.closeManageSettingsDialogSubscription = this.manageSettingsService.closeManageSettingsDialogSubject.subscribe(() => {
      this.isSettingsPopupShown = false;
    });
  }

  public ngOnDestroy(): void {
      this.queryParamsSubscription.unsubscribe();
      this.closeManageSettingsDialogSubscription.unsubscribe();
  }

  public sendGoogleAnalyticsData(navRoute: string) {
    this.googleAnalytics.send('Manage','Click', navRoute);
  }

  public get isDairy() : boolean {
    return this.configService.serverConfig.farmMode === FarmMode.Dairy;
  }

  public get isBreeding() : boolean {
    return this.applicationBundleRoutingMode == ApplicationBundleRoutingMode.Breeding;
  }

  public get isFinishing() : boolean {
    return this.applicationBundleRoutingMode == ApplicationBundleRoutingMode.Finishing;
  }

  public toggleSettings() {
    this.isSettingsPopupShown = !this.isSettingsPopupShown;
  }
}
