import { ChangeDetectorRef, Directive, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { NgbButtonLabelDirective } from './label';

const NGB_CHECKBOX_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NgbCheckBoxDirective),
  multi: true
};

/**
 * Allows to easily create Bootstrap-style checkbox buttons.
 *
 * Integrates with forms, so the value of a checked button is bound to the underlying form control
 * either in a reactive or template-driven way.
 */
@Directive({
  selector: '[ngbButton][type=checkbox]',
  host: {
    autocomplete: 'off',
    '[checked]': 'checked',
    '[disabled]': 'disabled',
    '(change)': 'onInputChange($event)',
    '(focus)': 'focused = true',
    '(blur)': 'focused = false'
  },
  providers: [NGB_CHECKBOX_VALUE_ACCESSOR]
})
export class NgbCheckBoxDirective implements ControlValueAccessor {
  public checked;

  /**
   * If `true`, the checkbox button will be disabled
   */
  @Input() public disabled = false;

  /**
   * The form control value when the checkbox is checked.
   */
  @Input() public valueChecked = true;

  /**
   * The form control value when the checkbox is unchecked.
   */
  @Input() public valueUnChecked = false;

  // tslint:disable-next-line:no-any
  public onChange = (changes: any) => {};
  public onTouched = () => {};

  public set focused(isFocused: boolean) {
    this.label.focused = isFocused;
    if (!isFocused) {
      this.onTouched();
    }
  }

  constructor(private label: NgbButtonLabelDirective, private cd: ChangeDetectorRef) {}

  public onInputChange($event) {
    const modelToPropagate = $event.target.checked
      ? this.valueChecked
      : this.valueUnChecked;
    this.onChange(modelToPropagate);
    this.onTouched();
    this.writeValue(modelToPropagate);
  }

  // tslint:disable-next-line:no-any
  public registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  // tslint:disable-next-line:no-any
  public registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.label.disabled = isDisabled;
  }

  public writeValue(value) {
    this.checked = value === this.valueChecked;
    this.label.active = this.checked;

    // label won't be updated, if it is inside the OnPush component when [ngModel] changes
    this.cd.markForCheck();
  }
}
