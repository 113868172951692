<div class="nav-item multiple"
     [ngClass]="{ active: menuItemModel.isActive }"
     [attr.sh-id]="menuItemModel.shId">
  <ng-container *ngIf="menuItemModel.subMenus.length == 0">
    <div class="nav-label"
         (click)="menuItemClick.emit(menuItemModel)"
         [attr.translation-id]="menuItemModel.caption"
         saveDataWarningInject>{{menuItemModel.caption | translate}}</div>
  </ng-container>
  <ng-container *ngIf="menuItemModel.subMenus.length > 0">
    <div class="nav-label"
         (click)="menuItemClick.emit(menuItemModel)"
         [attr.translation-id]="menuItemModel.caption">{{menuItemModel.caption | translate}}</div>
  </ng-container>
  <div class="sub-items" *ngIf="menuItemModel.isOpen">
    <div class="sub-item" *ngFor="let subMenu of menuItemModel.subMenus"
         (click)="subMenuItemClick.emit({menuItem: this.menuItemModel, subMenuItem: subMenu})"
         [ngClass]="{ active: subMenu.isActive }"
         [attr.sh-id]="subMenu.shId"
         saveDataWarningInject>
      <div class="nav-label" [attr.translation-id]="subMenu.caption">{{subMenu.caption | translate}}</div>
    </div>
  </div>
</div>
