<div class="content">
  <div class="sense-time-logo"></div>
  <div class="title-header-wrapper">
    <div class="title-header title-header-install">Installing</div>
    <div class="title-header">SenseHub</div>
  </div>
  <ng-container [ngSwitch]="setupService.serverSetup && setupService.serverSetup.status">
    <!-- Searching if minimal version is installed -->
    <ng-container *ngSwitchCase="versionStatus.Searching">
      <div class="title-content margin-bottom">Searching for version - Please wait...</div>
      <div class="loading-animation"></div>
    </ng-container>
    <!-- Show Install button after finding minimal version -->
    <ng-container *ngSwitchCase="versionStatus.VersionFound">
      <div class="title-content" [ngStyle]="{ marginBottom: '20px'}">Minimal Software version has been found - System must be at minimal version in order to be able to register</div>
      <div class="title-content title-content-install" id="install"  style="margin-bottom:10px;padding:10px;" (click)="startInstall()">Start Install</div>
    </ng-container>
    <!-- Show Processing screen -->
    <ng-container *ngSwitchCase="versionStatus.Processing">
      <div class="step-title">Step 1 out of 3</div>
      <div class="title-content margin-bottom">Processing - Please Wait</div>
      <div class="loading-animation"></div>
      <div class="title-content" [attr.sh-id]="'install_or_server_error'">This might take a while. Do not turn off your SenseHub Collector</div>
    </ng-container>
    <ng-container *ngSwitchCase="versionStatus.Downloading">
      <!-- Should show download bar -->
      <div class="step-title downloading-color">Step 2 out of 3</div>
      <div class="title-content margin-bottom">Downloading - Please Wait...</div>
      <div class="downloading-bar">
        <div class="inner-bar downloading-color" [ngStyle]="{ width: loadingBarPercentage + '%' }"></div>
      </div>
      <div class="title-content" [attr.sh-id]="'install_or_server_error'">This might take a while. Do not turn off your SenseHub Collector</div>
    </ng-container>
    <ng-container *ngSwitchCase="versionStatus.Upgrading">
      <!-- should show upgrading loader -->
      <div class="step-title upgrading-color">Step 3 out of 3</div>
      <div class="title-content margin-bottom">Upgrading - Please Wait...</div>
      <div class="loading-animation"></div>
      <div class="title-content" [attr.sh-id]="'install_or_server_error'">This might take a while. Do not turn off your SenseHub Collector</div>
    </ng-container>
  </ng-container>
  <div class="title-error" *ngIf="errorVisible" [attr.sh-id]="'server_error_indication'">SenseHub is currently not available. Try again in a few moments.</div>
</div>
