import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth/auth.service";
import {HttpResponseResult} from "../../../services/rest-api/rest-api.service";
import {Router} from "@angular/router";
import {SignatureType} from '../../../services/auth/model/eula/signature-type.enum';
import {ISignEulaModel} from '../../../services/auth/model/eula/sign-eula-model.interface';
import {BizModel, FarmMode} from '../../../services/config/model/server-config';

@Component({
  selector: 'sign-eula',
  templateUrl: './sign-eula.component.html',
  styleUrls: ['./sign-eula.component.scss']
})
export class SignEulaComponent implements OnInit {

  private static readonly TermsAndConditionsDairyURL: string = 'http://www.scrdairy.com/end-user-system-license-agreement/legal.html';
  private static readonly TermsAndConditionsBeefURL: string = 'http://www.scrdairy.com/END-USER-SYSTEM-LICENSE-AGREEMENT-Beef/legal-Beef.html';

  public signEulaModel: ISignEulaModel = {
    approved: null,
    bizModelMode: BizModel.Up,
    farmMode: FarmMode.Dairy,
    name: '',
    type: '',
    version: null
  };
  public eulaSelection: IEulaSelection = {
    isLicenseAgreementSigned: false,
    isPrivacyPolicySigned: false,
    isTermsOfUseSigned: false
  };
  public SignatureType = SignatureType;
  public username: string;
  public password: string;
  public farmId: string;

  constructor(private authService: AuthService,
              private router: Router) { }

  public ngOnInit() {
    // get the sign eula model from the server
    this.getEulaDetails();
  }

  public getEulaDetails() {
    if(this.authService.currentUserAuthState &&
       this.authService.currentUserAuthState.loginResult.isEulaRequired) {
      this.authService.getEulaDetails().then((response: HttpResponseResult<ISignEulaModel>) => {
        this.signEulaModel = response.responseBody;
      });
    }
    else {
      this.router.navigate(['/']);
    }
  }

  public readTermsAndPolicy(type: SignatureType) {
    let url = '';
    switch (this.signEulaModel.farmMode) {
      case FarmMode.Unknown:
      case FarmMode.Dairy: {
        switch (type) {
          case SignatureType.termsOfUse: {
            if (this.signEulaModel.bizModelMode === BizModel.Go) {
              url = `${SignEulaComponent.TermsAndConditionsDairyURL}#TERMS-OF-USE-GO-MODEL`;
            } else {
              url = `${SignEulaComponent.TermsAndConditionsDairyURL}#TERMS-OF-USE-UP-MODEL`;
            }
            break;
          }
          case SignatureType.licenseAgreement: {
            url = `${SignEulaComponent.TermsAndConditionsDairyURL}#END-USER`;
            break;
          }
          case SignatureType.privacyPolicy: {
            url = `${SignEulaComponent.TermsAndConditionsDairyURL}#privacy`;
            break;
          }
        }
        break;
      }
      case FarmMode.Beef: {
        switch (type) {
          case SignatureType.termsOfUse: {
            if (this.signEulaModel.bizModelMode === BizModel.Go) {
              url = `${SignEulaComponent.TermsAndConditionsBeefURL}#TERMS-OF-USE-GO-MODEL`;
            } else {
              url = `${SignEulaComponent.TermsAndConditionsBeefURL}#TERMS-OF-USE-UP-MODEL`;
            }
            break;
          }
          case SignatureType.licenseAgreement: {
            url = `${SignEulaComponent.TermsAndConditionsBeefURL}#END-USER`;
            break;
          }
          case SignatureType.privacyPolicy: {
            url = `${SignEulaComponent.TermsAndConditionsBeefURL}#privacy`;
          }
        }
        break;
      }
    }
    window.open(url, '_blank');
  }

  // enable or disable the submit eula button
  public validateTermsAndConditions() {
    if (this.eulaSelection.isTermsOfUseSigned && this.eulaSelection.isPrivacyPolicySigned && this.eulaSelection.isLicenseAgreementSigned) {
      this.signEulaModel.approved = true;
      return false;
    } else {
      this.signEulaModel.approved = false;
      return true;
    }
  }

  public submitTermsAndPolicyAndContinueToDashboard() {
    if (this.signEulaModel.approved) {
      // send the eula model to the server after approving
      // tslint:disable-next-line:no-any
      this.authService.sendUserEulaResponse(this.signEulaModel).then((response: HttpResponseResult<any>) => {
        this.router.navigate(['/dashboard']);
      }).catch((error) => {
        // TODO [DS]: needs to add a toast to show server error
      });
    }
  }

}

export interface IEulaSelection {
  isTermsOfUseSigned: boolean;
  isPrivacyPolicySigned: boolean;
  isLicenseAgreementSigned: boolean;
}
