import {ChipsItem} from '../../../../../common/components/chips/chips-item.component';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../../../common/components/calendar/model/epoch-state-model';
import * as moment from 'moment';

export class EditNoteModel {
    public id: number;
    public noteName: string;
    public selectedGroups: ChipsItem[];
    public date: EpochStateModel;

    constructor() {
        this.id = null;
        this.noteName = '';
        this.selectedGroups = [];
        this.date = new EpochStateModel(EpochStateModelMinMaxMode.Date, moment().utc().unix());
    }
}
