import {Component} from '@angular/core';
import {CreateUnsortEventDetails, UnsortCowEventMetadata} from 'src/app/services/animals/model/animal-events';
import {EventFormComponent} from '../event-form.component';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../../../common/components/calendar/model/epoch-state-model';
import * as moment from 'moment';

@Component({
  selector: 'unsort-event-form',
  templateUrl: './unsort-event-form.component.html',
  styleUrls: ['./unsort-event-form.component.scss']
})
export class UnsortEventFormComponent extends EventFormComponent<UnsortCowEventMetadata, CreateUnsortEventDetails> {

  public expirationDate : EpochStateModel;

  constructor() {
    super();
    this.expirationDate = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.expirationDate.min =moment().add(1, 'day').startOf('day').unix();
  }

  public onSelectedTimeChanged(selectedEpoch: EpochStateModel) {
    this.editModel.expirationDate = selectedEpoch.epochDate;
    this.modelChanged.emit(this.editModel);
  }
}
