import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BatchEventErrorsDialogService {
  public showPopup$ = new Subject<string[] | null>();

  public show(animalNames: string[]): void {
    this.showPopup$.next(animalNames);
  }

  public hide(): void {
    this.showPopup$.next(null);
  }
}
