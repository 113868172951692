<div class="farm-topology-container-wrapper">
  <div class="farm-topology-header">
    <div class="get-topology-button" (click)="reloadTopologyFromServer()">
      <div class="get-topology-text" [attr.translation-id]="'REGISTRATION.FLOW.RELOAD_TOPOLOGY_FROM_SERVER'">{{ 'REGISTRATION.FLOW.RELOAD_TOPOLOGY_FROM_SERVER' | translate }}</div>
      <div class="refresh-icon"></div>
    </div>
  </div>
  <div class="farm-topology-container">
    <div class="farm-topology-summary">
      <div class="title-text" [attr.translation-id]="'REGISTRATION.FLOW.DISCOVERED_DEVICES'">{{ 'REGISTRATION.FLOW.DISCOVERED_DEVICES' | translate }}</div>
      <div class="title-text" [attr.translation-id]="'REGISTRATION.FLOW.MAIN_COLLECTOR'">{{ 'REGISTRATION.FLOW.MAIN_COLLECTOR' | translate }}: <span  [attr.sh-id]="'counter_REGISTRATION.FLOW.MAIN_COLLECTOR'" class="value">{{ registrationService.registrationModel.farmTopology.totalCollector }}</span></div>
      <div class="title-text" [attr.translation-id]="'REGISTRATION.FLOW.ANTENNA_ONLY'">{{ 'REGISTRATION.FLOW.ANTENNA_ONLY' | translate }}: <span [attr.sh-id]="'counter_REGISTRATION.FLOW.ANTENNA_ONLY'" class="value">{{ registrationService.registrationModel.farmTopology.totalAntenna }}</span></div>
      <div class="title-text" [attr.translation-id]="'REGISTRATION.FLOW.STANDALONE_PC'">{{ 'REGISTRATION.FLOW.STANDALONE_PC' | translate }}: <span [attr.sh-id]="'counter_REGISTRATION.FLOW.STANDALONE_PC'" class="value">{{ registrationService.registrationModel.farmTopology.totalStandalonePC }}</span></div>
      <div class="title-text" [attr.translation-id]="'REGISTRATION.FLOW.FULL'">{{ 'REGISTRATION.FLOW.FULL' | translate }}: <span [attr.sh-id]="'counter_REGISTRATION.FLOW.FULL'"  class="value">{{ registrationService.registrationModel.farmTopology.total }}</span></div>
    </div>
    <div class="farm-topology-table-container">
      <div class="title-text" [attr.translation-id]="'REGISTRATION.FLOW.HARDWARE'">{{ 'REGISTRATION.FLOW.HARDWARE' | translate }}</div>
      <ng-template #columnCell let-column="column">
        <div class="column-class" [attr.id]="">
          {{ 'REGISTRATION.FLOW.HARDWARE_TABLE.' + column | translate }}
        </div>
      </ng-template>
      <ng-template #rowCell let-column="column" let-row="row">
        <div class="row-class" [ngSwitch]="column">
          <div class="choose-collector-class" *ngSwitchCase="'CHOOSE_COLLECTOR'">
            <image-checkbox [attr.sh-id]="'checkbox_'+row['serialNumber']" [checkBoxStyles]="{ width: '25px', height: '25px' }" [isChecked]="row['isEnable']" (isCheckedChanged)="toggleCheckbox(row)"></image-checkbox>
          </div>
          <div class="hardware-type-class" [attr.sh-id]="'hardware_type_'+row['serialNumber']" *ngSwitchCase="'HARDWARE_TYPE'" [ngClass]="{ 'notEnabled': !row['isEnable'] }">
            {{ 'REGISTRATION.HARDWARE_TYPE.' + row['hardwareType'] | translate }}
          </div>
          <div class="hardware-description-class" *ngSwitchCase="'HARDWARE_DESCRIPTION'" [ngClass]="{ 'notEnabled': !row['isEnable'] }">
            <div>{{ row['hardwareDesc'] }}</div>
            <div class="edit-icon" *ngIf="row['isEnable']" (click)="editHardwareDescription(row)"></div>
            <div class="edit-icon-disabled" *ngIf="!row['isEnable']"></div>
          </div>
          <div class="ip-address-class" [attr.sh-id]="'ip_address_'+row['serialNumber']" *ngSwitchCase="'IP_ADDRESS'" [ngClass]="{ 'notEnabled': !row['isEnable'] }">
            {{ row['ip'] }}
          </div>
          <div class="serial-number-class" [attr.sh-id]="'serial_number_'+row['serialNumber']" *ngSwitchCase="'SERIAL_NUMBER'" [ngClass]="{ 'notEnabled': !row['isEnable'] }">
            {{ row['serialNumber'] }}
          </div>
          <div class="dd-version-class" [attr.sh-id]="'sw_version_'+row['serialNumber']" *ngSwitchCase="'SOFTWARE_VERSION'" [ngClass]="{ 'notEnabled': !row['isEnable'] }">
            {{ row['ddVersion'] }}
          </div>
        </div>
      </ng-template>
      <data-grid *ngIf="hardware"
                 #hardwareTable
                 [sortedGridQuery]="dataGridQuery"
                 [isRowClickable]="false"
                 [sortedGridColumn]="columnCell"
                 [sortedGridCell]="rowCell"
                 [columnsMappings]="columnsMappings"
                 [viewContext]="this"
                 [headerHeight]="55"
                 [rowHeight]="45"
                 [totalRows]="hardware.length"
                 [rowsData]="hardware"></data-grid>
    </div>
  </div>
</div>
<edit-hardware-dialog *ngIf="selectedHardwareForEdit" [isPopupOpen]="isEditPopupOpen" [HardwareRows]="hardware" [HardwareDetails]="selectedHardwareForEdit" (onCloseDialogClicked)="isEditPopupOpen = false;"></edit-hardware-dialog>
