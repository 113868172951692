import { EventEmitter, Input, Output, Directive } from "@angular/core";

@Directive()
export class NotificationDialogComponent {

  @Output()
  public onProceed?: EventEmitter<void> = new EventEmitter();

  @Output()
  public onClose?: EventEmitter<void> = new EventEmitter();

  @Input()
  public isOpen: boolean;

  @Input()
  public dialogTitle: string;

  @Input()
  public message: string;

  constructor() {}

  public proceed(): void {
    if (this.onProceed && this.isOpen) {
      this.onProceed.emit();
    }
  }

  public close(): void {
    if (this.onClose && this.isOpen) {
      this.onClose.emit();
    }
  }
}
