<description-cell-details-template [hasRowDetails]="row.daysInPregnancy"
                                   [isRowExpanded]="isExpanded"
                                   [row]="row"
                                   [rowShId]="row.eventId"
                                   (toggleExpanded)="toggleExpanded()">
  <ng-container key>{{"ANIMAL.EVENTS.EVENT_DESCRIPTION.SAME_LACTATION_NUMBER" | translate}}:&nbsp;</ng-container>
  <ng-container value>{{row.lactationNumber}}</ng-container>
  <ng-container content>
    <p class="description-item" *ngIf="row.daysInPregnancy">
      <span>{{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.DAYS_PREGNANT' | translate}}:&nbsp;</span>
      <span class="value">{{row.daysInPregnancy}}</span>
    </p>
  </ng-container>
</description-cell-details-template>
