<div class="filter-content">
  <div class="form-group">
    <forms-dropdown-list class="value"
              [items]="operatorsDropdownItems"
              [placeholder]="'COMMON.PLACE_HOLDER.AUTO_COMPLETE.SELECT_ITEM'"
              [ngModel]="selectedQueryOperator"
              (ngModelChange) = "onOperatorSelected($event)">
    </forms-dropdown-list>
  </div>
  <div class="form-group value-input">
    <date-picker [selectedEpoch]="initialValue1"
      (epochChanged)="onDateTimeValue1Changed($event)"
      [disabled]="!filterNumericQuery.operator"></date-picker>
  </div>
  <div class="form-group" *ngIf="filterNumericQuery.isMultiValue">
    <date-picker [selectedEpoch]="initialValue2"
      (epochChanged)="onDateTimeValue2Changed($event)"></date-picker>
  </div>
</div>
