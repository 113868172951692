<div class="edit-gre-header">
  <div class="title" [attr.translation-id]="'MANAGE.WELCOME.TITLE.GROUP_ROUTINE_EVENTS'">{{ 'MANAGE.WELCOME.TITLE.GROUP_ROUTINE_EVENTS' | translate }}</div>
  <div class="buttons" sh-id="manage-settings-edit-bar-buttons">
    <div class="btn btn-save" (click)="onSaveClick()" saveDataWarningIgnore>
      <div class="icon icon-save" sh-id="manage-settings-edit-bar-save-btn"></div>
    </div>
    <div class="btn btn-cancel" (click)="onCloseClick()">
      <div class="icon icon-x-button"></div>
    </div>
  </div>
</div>
<div class="errors-div" [attr.sh-id]="'error-div'">
  <ng-container *ngIf="Error">
    <div class="error-text" [attr.translation-id]="'SYSTEM.MANAGEMENT.ERROR_BOLD'">
      <b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}: </b>
      <span class="error-message">{{ 'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.ERROR.' + Error.key | translate }}</span>
    </div>
  </ng-container>
</div>
<div class="edit-gre-body" saveDataEditSection [editAreaName]="editAreaName">
  <div class="left-section">
    <div class="field-title" [attr.translation-id]="'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.COLUMN.NAME'"> {{'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.COLUMN.NAME' | translate}}</div>
    <div class="field-value">
      <regular-dropdown-list [isFieldError]="isFieldError(GroupRoutineEventField.eventType)"
                             [colorScheme]="DropdownColorScheme.greyBlue"
                             [items]="availableEventsDropdownListItems"
                             [selectedValue]="selectedEventDropdownListItem"
                             (onItemSelected)="onEventTypeSelected($event)"
                             [inputAreaHeight]=""></regular-dropdown-list>
    </div>
    <div class="field-separator"></div>
    <div class="field-title" [attr.translation-id]="'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.COLUMN.TIME'"> {{'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.COLUMN.TIME' | translate}}</div>
    <div class="field-value">
      <time-picker [shownRemovableIcon]="false"
                   [themeColor]="'#1999D5'"
                   [backgroundColor]="'#F4F4F4'"
                   [selectedEpoch]="selectedTime"
                   (epochChanged)="onEpochChanged()"
                   [shIdPostfix]="'start-time-picker-input'"
                   [isFieldError]="isFieldError(GroupRoutineEventField.startTime)"></time-picker>
    </div>
  </div>
  <div class="right-section" *ngIf="metadata">
    <div class="field-title" [attr.translation-id]="'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.COLUMN.GROUPS'"> {{'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.COLUMN.GROUPS' | translate}}</div>
    <div class="field-value">
      <regular-auto-complete #effectiveGroupsAutoComplete
                             [isFieldError]="isFieldError(GroupRoutineEventField.groupIds)"
                             [hasIcon]="true"
                             [shIdPostfix]="'effective-groups-led-tasks'"
                             [colorScheme]="AutoCompleteColorScheme.GreyBlue"
                             [placeholder]="groupInputPlaceholder"
                             [isAllowUnmatchedSelection]="false"
                             [caseInvariant]="true"
                             [items]="availableGroupsList"
                             [maxResults]="6"
                             (editingCompleted)="onGroupNameSelected($event)"
                             [searchQuery]="groupSearchQuery"></regular-auto-complete>
    </div>
    <chip-list class="groups-list-div">
      <ng-container *ngFor="let group of selectedGroupsChipsItems">
        <chips-item [chipItem]="group" (onRemoveItemClicked)="removeSelectedGroup(group)"></chips-item>
      </ng-container>
    </chip-list>
  </div>
</div>
