import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export enum CornerColor {
  Blue = '#1999d6',
  BlueDisabled = '#abd7ee',
  DeepBlue = '#3B4570',
  Pink = '#c46da9',
  Green = '#9fb103',
  LightBlue = '#32aee2',
  Brown = '#b67317',
  DeepPurple = '#B14C9B',
  White = '#fff'
}

export enum CornerContainerBodyBackground {
  Gray = '#f4f4f4',
  Blue = '#d1eaf6',
  LightGray = '#E4E7F4',
  LightPurple = '#F4E6F4',
  LightPink = '#e1c6df',
  LightGreen = '#e5edd8',
  LightBlue = '#99d6f0',
  LightBrown = '#e5ad46',
  White = '#fff'
}

@Component({
  selector: 'corner-container',
  templateUrl: './corner-container.component.html',
  styleUrls: ['./corner-container.component.scss']
})
export class CornerContainerComponent implements OnInit {

  @Input()
  public cornerColor : CornerColor;

  @Input()
  public bodyBackground: CornerContainerBodyBackground;

  @Input()
  public footerHeight : number;

  @Output()
  public onCornerButtonClick = new EventEmitter<Event>();

  constructor() { }

  public ngOnInit() {
  }

}
