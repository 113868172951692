import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslationService} from '../services/translations/translation.service';
import {PollingKeyNames, PollingService} from '../services/rest-api/polling.service';
import {ConfigService} from "../services/config/config.service";
import {Subscription} from 'rxjs';

@Component({
  selector: 'logged-in-layout',
  templateUrl: './logged-in-layout.component.html',
  styleUrls: ['./logged-in-layout.component.scss']
})
export class LoggedInLayoutComponent implements OnInit, OnDestroy {

  public isOpenMenu: boolean;

  public reloginSubscription: Subscription;

  constructor(private translationService: TranslationService,
              private pollingService: PollingService,
              public configService: ConfigService) {
  }

  public ngOnInit() {
    this.reloginSubscription = this.pollingService.pollingChangesState.subscribe(value => {
      if (value.length && value.includes(PollingKeyNames.login)) {
        location.reload();
      }
    });
  }

  public ngOnDestroy(): void {
    this.reloginSubscription.unsubscribe();
  }

  public onOpenMenuChange(isOpenMenu: boolean) {
    this.isOpenMenu = isOpenMenu;
  }
}
