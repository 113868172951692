import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import {CancelBatchEventDialogService} from './cancel-batch-event-dialog.service';

@Component({
  selector: 'cancel-batch-event-dialog',
  templateUrl: 'cancel-batch-event-dialog.component.html',
  styleUrls: ['cancel-batch-event-dialog.component.scss']
})
export class CancelBatchEventDialogComponent {
  public showPopup$ = this.cancelBatchEventPopupService.showPopup$;

  constructor(
    private readonly cancelBatchEventPopupService: CancelBatchEventDialogService
  ) {}

  public closePopup(closeSubject: Subject<boolean>, result: boolean = false): void {
    this.cancelBatchEventPopupService.showPopup$.next({
      isShown: false
    });
    closeSubject.next(result);
    closeSubject.complete();
  }
}
