import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RegistrationService} from '../../services/registration/registration.service';
import {IPostDetailsResponse, IThirdPartyItem} from '../../services/registration/model/registration.model';
import {HttpResponseResult} from '../../services/rest-api/rest-api.service';
import {FarmMode} from '../../services/config/model/server-config';
import {AuthService, ITrialModeStatus} from '../../services/auth/auth.service';
import {GoogleAnalyticsService} from '../../services/google-analytics/google-analytics.service';
import {TranslationPickerThemeEnum} from "../../common/components/translation/translation-picker/translation-picker.component";
import {TranslationService} from '../../services/translations/translation.service';
import {TrialOperation} from "../../services/config/model/anonymous-auth-config";

@Component({
  selector: 'farm-registration',
  templateUrl: './farm-registration.component.html',
  styleUrls: ['./farm-registration.component.scss']
})
export class FarmRegistrationComponent implements OnInit {

  public currentStep: FarmRegistrationSteps = FarmRegistrationSteps.AccountDetails;

  public FarmRegistrationStepsList = FarmRegistrationSteps;

  public FarmModes = FarmMode;

  public TrialOperations = TrialOperation;

  public TranslationPickerThemeEnum = TranslationPickerThemeEnum;

  constructor(private router: Router,
              public registrationService: RegistrationService,
              private translationService: TranslationService,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private googleAnalyticsService: GoogleAnalyticsService) { }

  public ngOnInit() {
    this.currentStep = FarmRegistrationSteps.AccountDetails;
  }

  public isFinishingStandalone() {
    return this.registrationService.registrationModel.farmMode == FarmMode.Beef && this.registrationService.registrationModel.operation == TrialOperation.finishing
  }

  public getTriangleLocationClass(): string {
    switch (this.currentStep) {
      case FarmRegistrationSteps.AccountDetails: {
        return 'left';
      }
      case FarmRegistrationSteps.HerdManagement: {
        return 'center';
      }
      case FarmRegistrationSteps.FarmTopology: {
        return 'right';
      }
    }
  }

  public async navigateBack() {
    switch (this.currentStep) {
      case FarmRegistrationSteps.AccountDetails: {
        await  this.router.navigate(['registration/initialize-registration']);
        break;
      }
      case FarmRegistrationSteps.HerdManagement: {
        this.currentStep = FarmRegistrationSteps.AccountDetails;
        await this.router.navigate(['farm-registration/account-details']);
        break;
      }
      case FarmRegistrationSteps.FarmTopology: {
        if (this.isFinishingStandalone()) {
          this.currentStep = FarmRegistrationSteps.AccountDetails;
          await this.router.navigate(['farm-registration/account-details']);
        } else {
          this.currentStep = FarmRegistrationSteps.HerdManagement;
          await this.router.navigate(['farm-registration/herd-management']);
        }
        break;
      }
    }
  }

  public async navigateNext() {
    switch (this.currentStep) {
      case FarmRegistrationSteps.AccountDetails: {
        if (this.validateAccountDetails()) {
          // send data to server and proceed to the next step
          this.registrationService.isLoading = true;
          let response: HttpResponseResult<IPostDetailsResponse> = await this.registrationService.postFarmDetails(this.registrationService.registrationModel.farmDetails);
          this.registrationService.isLoading = false;
          if (response.responseBody.succeeded) {
            if (this.isFinishingStandalone()) {
              this.currentStep = FarmRegistrationSteps.FarmTopology;
              await this.router.navigate(['farm-registration/farm-topology']);
            } else {
              this.currentStep = FarmRegistrationSteps.HerdManagement;
              await this.router.navigate(['farm-registration/herd-management']);
            }
          }
        }
        break;
      }
      case FarmRegistrationSteps.HerdManagement: {
        if (this.validateHerdManagement()) {
          // send data to server and proceed to the next step
          this.registrationService.isLoading = true;
          let response: HttpResponseResult<IPostDetailsResponse> = await this.registrationService.postFarmThirdParty(this.registrationService.registrationModel.herdManagement);
          this.registrationService.isLoading = false;
          if (response.responseBody.succeeded) {
            this.currentStep = FarmRegistrationSteps.FarmTopology;
            await this.router.navigate(['farm-registration/farm-topology']);
          }
        }
        break;
      }
    }
  }

  public isStepGreaterThan(stepToCheck: FarmRegistrationSteps): boolean {
    return this.currentStep > stepToCheck;
  }

  public validateAccountDetails(): boolean {
    if (!(this.registrationService.registrationModel.farmDetails.farmName && this.registrationService.registrationModel.farmDetails.farmName !== '')) {
      this.registrationService.registrationModel.error = { fieldName: 'farmName', error: this.translationService.translate('SYSTEM.ACCOUNT.SECTION.VALIDATION.FARM_NAME') };
      return false;
    } else if (!(this.registrationService.registrationModel.farmDetails.farmHousing)) {
      this.registrationService.registrationModel.error = { fieldName: 'farmHousing', error: this.translationService.translate('SYSTEM.ACCOUNT.SECTION.VALIDATION.FARM_HOUSING') };
      return false;
    } else if (!(this.registrationService.registrationModel.farmDetails.timeZone)) {
      this.registrationService.registrationModel.error = { fieldName: 'timeZone', error: this.translationService.translate('SYSTEM.ACCOUNT.SECTION.VALIDATION.TIME_ZONE') };
      return false;
    } else {
      return true;
    }
  }

  public validateHerdManagement(): boolean {
    // if third party is enabled
    if (this.registrationService.registrationModel.herdManagement.linkedStatus) {
      if (!this.registrationService.registrationModel.herdManagement.deviceType) {
        this.registrationService.registrationModel.error = { fieldName: 'thirdParty', error: this.translationService.translate('SYSTEM.ACCOUNT.SECTION.VALIDATION.THIRD_PARTY_REQUIRED') };
        return false;
      }
      let thirdPartyItem: IThirdPartyItem = this.registrationService.registrationModel.herdManagement.thirdParty.find((item) => item.deviceType === this.registrationService.registrationModel.herdManagement.deviceType);
      if (thirdPartyItem.supportByCloud) {
        if (thirdPartyItem.registrationKeyGeneratedByUser && !this.registrationService.registrationModel.herdManagement.registrationKey) {
          this.registrationService.registrationModel.error = { fieldName: 'CRVRegistrationKeyInput', error: this.translationService.translate('SYSTEM.ACCOUNT.SECTION.VALIDATION.CRV_REGISTRATION_KEY_REQUIRED') };
        }
        return !!this.registrationService.registrationModel.herdManagement.registrationKey;
      } else if (thirdPartyItem.supportStatusFileWithOutputFolder || thirdPartyItem.supportStatusFileWithInputFolder) {
        let response: boolean = true;
        if (thirdPartyItem.supportStatusFileWithOutputFolder) {
          if (!this.registrationService.registrationModel.herdManagement.outputFolder) {
            this.registrationService.registrationModel.error = { fieldName: 'outputFolder', error: this.translationService.translate('SYSTEM.ACCOUNT.SECTION.VALIDATION.OUTPUT_FOLDER_REQUIRED') };
            response = false;
          }
        }
        if (thirdPartyItem.supportStatusFileWithInputFolder) {
          if (!this.registrationService.registrationModel.herdManagement.inputFolder) {
            this.registrationService.registrationModel.error = { fieldName: 'inputFolder', error: this.translationService.translate('SYSTEM.ACCOUNT.SECTION.VALIDATION.INPUT_FOLDER_REQUIRED') };
            response = false;
          }
        }
        return response;
      }
    } else {
      // if third party is disabled - continue
      return true;
    }
  }

  public isSelectedCollectors(): boolean {
    let isSelectedCollectors: boolean = false;
    if (this.registrationService.registrationModel.farmTopology && this.registrationService.registrationModel.farmTopology.hardware) {
      // tslint:disable-next-line:no-any
      this.registrationService.registrationModel.farmTopology.hardware.forEach((hardware: any) => {
        if (hardware.isEnable) {
          isSelectedCollectors = true;
        }
      });
    }
    return isSelectedCollectors;
  }

  public isNextDisabled(): boolean {
    if (this.currentStep === FarmRegistrationSteps.AccountDetails) {
      if (this.registrationService.registrationModel.farmDetails.farmName && this.registrationService.registrationModel.farmDetails.farmName !== '') {
        return false;
      } else if (this.registrationService.registrationModel.farmDetails.farmHousing) {
        return false;
      } else if (this.registrationService.registrationModel.farmDetails.timeZone) {
        return false;
      } else {
        return true;
      }
    } else if (this.currentStep === FarmRegistrationSteps.HerdManagement) {
      if (this.registrationService.registrationModel.herdManagement.linkedStatus) {
        if (!this.registrationService.registrationModel.herdManagement.deviceType) {
          return true;
        }
        let thirdPartyItem: IThirdPartyItem = this.registrationService.registrationModel.herdManagement.thirdParty.find((item) => item.deviceType === this.registrationService.registrationModel.herdManagement.deviceType);
        if (thirdPartyItem.supportByCloud) {
          return !this.registrationService.registrationModel.herdManagement.registrationKey;
        } else if (thirdPartyItem.supportStatusFileWithOutputFolder || thirdPartyItem.supportStatusFileWithInputFolder) {
          let response: boolean = true;
          if (thirdPartyItem.supportStatusFileWithOutputFolder) {
            if (this.registrationService.registrationModel.herdManagement.outputFolder) {
              response = false;
            }
          }
          if (thirdPartyItem.supportStatusFileWithInputFolder) {
            if (this.registrationService.registrationModel.herdManagement.inputFolder) {
              response = false;
            }
          }
          return response;
        }
      } else {
        return false;
      }
    }
  }

  public async goToTrialMode() {
    // tslint:disable-next-line:no-any
    const request: any = {
      type: 'trialMode'
    };
    // tslint:disable-next-line:no-any
    const response: any = await this.registrationService.postTrialMode(request);
    if (response.status === 201) {
      const farmMode: FarmMode = response.responseBody.farmMode;
      // call anonymous login to update the trial farm mode status.
      await this.authService.performAnonymousLogin();
      this.googleAnalyticsService.send('Registration', 'start trial', farmMode);
      switch (farmMode) {
        case FarmMode.Dairy:
        case FarmMode.Beef: {
          // navigate to Trial Mode Screen
          const trialModeStatus: ITrialModeStatus = this.authService.getTrialModeStatus();
          if (!trialModeStatus) {
            this.authService.setIsStartedTrialModeForTheFirstTime(true);
          } else {
            this.authService.setIsStartedTrialModeForTheFirstTime(false);
          }
          await this.router.navigate(['/initialize-trial']);
          break;
        }
        case FarmMode.Unknown: {
          // navigate to Trial Mode Farm Mode Selection
          await this.router.navigate(['/trial-farm-mode-selection']);
          break;
        }
      }
    } else {
      // TODO: [DS] show error message
    }
  }

  public async finishRegistration() {
    let response: HttpResponseResult<IPostDetailsResponse> = await this.registrationService.postFarmTopology(this.registrationService.registrationModel.farmTopology);
    if (response.responseBody.succeeded) {
      // navigate to sign-up page
      await this.router.navigate(['/initialize-sign-up'])
    }
  }
}

export enum FarmRegistrationSteps {
  AccountDetails,
  HerdManagement,
  FarmTopology
}
