<regular-edit-dialog-template *ngIf="model"
                              [modalWidth]="456"
                              [isOpen]="isOpen"
                              [enableRemoveButton]="false"
                              [showDialogShadow]="true"
                              [rightButtonDisabled]="isApplyDisabled"
                              (onCloseClick)="closeClick()"
                              (onLeftButtonClick)="closeClick()"
                              (onRightButtonClick)="applyClick()">
  <ng-container header-caption>
    {{'Send to Sorting Gate' | translate}}
  </ng-container>
  <ng-container body>
    <dialog-scrollable-body [bodyHeight]="374">
      <ng-container content>
        <animal-ids [animalIds]="animalIds"></animal-ids>
        <div class="control-wrapper">
          <div class="date-shift">
            <div class="date">
              <regular-dropdown-list [items]="daysDropdownListItems"
                                     [selectedValue]="activeDayDropdownListItem"
                                     [allowClear]="false"
                                     [customIcon]="DropdownCustomIcon.blueCalendar"
                                     (onItemSelected)="onDaySelected($event)"></regular-dropdown-list>
            </div>
            <div class="shift">
              <regular-dropdown-list [placeholder]="'Select shift'"
                                     [items]="concreteShiftsDropdownListItems"
                                     [selectedValue]="selectedConcreteShiftDropdownListItem"
                                     [allowClear]="false"
                                     [colorScheme]="DropdownColorScheme.whiteBlue"
                                     (onItemSelected)="onShiftSelected($event)"></regular-dropdown-list>
            </div>
          </div>
          <div class="sorting-pen">
            <div class="label" [attr.translation-id]="'SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.SORT_TO_PEN_DROPDOWN_CAPTION'">
              {{'SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.SORT_TO_PEN_DROPDOWN_CAPTION' | translate}}
            </div>
            <div class="pen">
              <regular-dropdown-list [placeholder]="'SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.SELECT' | translate"
                                     [items]="pensDropdownListItems"
                                     [selectedValue]="activePenDropdownListItem"
                                     [allowClear]="false"
                                     [colorScheme]="DropdownColorScheme.whiteBlue"
                                     (onItemSelected)="onPenSelected($event)"></regular-dropdown-list>
            </div>
          </div>
          <div class="comment">
            <div class="label">{{'SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.COMMENT_CHECKBOX_CAPTION' | translate}}</div>
            <div class="comment-input">
              <regular-auto-complete [placeholder]="'SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.COMMENT_TEXTBOX_CAPTION'"
                                     [isAllowUnmatchedSelection]="true"
                                     [items]="comments"
                                     [maxInputLength]="50"
                                     [searchQuery]="model.sortingTask.comment"
                                     [shIdPostfix]="'sorting_task_comment_input'"
                                     [colorScheme]="AutoCompleteColorScheme.WhiteBlack"
                                     (searchQueryChanged)="onTaskNameChange($event)"
                                     [caseInvariant]="true">
              </regular-auto-complete>
            </div>
          </div>
          <div class="repeat-for">
            <div class="label">
              <span class="icon"
                    [ngClass]="{'icon-select-checkbox': isRepeatActive, 'icon-no-select-checkbox': !isRepeatActive}"
                    (click)="toggleRepeatsForField()"></span>
              <span class="label-name">{{'SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.REPEATS_FOR_CHECKBOX_CAPTION' | translate}}</span>
            </div>
            <div class="repeat-day" *ngIf="isRepeatActive">
              <regular-dropdown-list [placeholder]="'SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.DAYS_SELECTION_CAPTION' | translate"
                                     [items]="repeatDaysDropdownListItems"
                                     [selectedValue]="activeRepeatDayDropdownListItem"
                                     [allowClear]="false"
                                     [colorScheme]="DropdownColorScheme.whiteBlue"
                                     (onItemSelected)="onRepeatDaySelected($event)"></regular-dropdown-list>
            </div>
            <div class="shifts-container" *ngIf="isRepeatActive">
              <button class="button" (click)="clickAllShifts()" [class.button--active]="isActiveAllShifts()">
                {{ 'SORTING_GATE.SORTING_SCHEDULER.TASK_POPUP.ALL_SHIFTS_BUTTON_CAPTION' | translate }}
              </button>
              <div class="buttons-container">
                <button class="button" *ngFor="let shift of shifts" (click)="clickShift(shift.shiftId)"
                        [class.button--active]="isActiveShift(shift.shiftId)">
                  {{ shift.name }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </dialog-scrollable-body>
  </ng-container>
  <ng-container left-button>
    <span>{{ 'COMMON.BUTTON.CANCEL' | translate}}</span>
  </ng-container>
  <ng-container right-button>
    <span>{{ 'COMMON.BUTTON.APPLY' | translate}}</span>
  </ng-container>
</regular-edit-dialog-template>
