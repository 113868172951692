<div class="graph-gradient">
  <div class="gradient-wrapper" #gradientWrapper [ngStyle]="{ background: createCustomGradient(details.graph.colorPoints)}">
    <div class="gradient-graph-text-wrapper" *ngFor="let section of details.graph.sections; let i = index" [ngStyle]="{ width: section.sectionSize + '%'}">
      <div class="min-text">
        <div class="text">{{ dashboardKpiService.showGradientValue(details.mainValue.type, section.minSectionLimit) }}</div>
      </div>
      <div class="max-text" *ngIf="i === (details.graph.sections.length - 1)">
        <div class="text">{{ dashboardKpiService.showGradientValue(details.mainValue.type, section.maxSectionLimit) }}</div>
      </div>
    </div>
    <div class="value" *ngIf="(details.mainValue && details.mainValue.value !== null)"
                       [ngStyle]="{ left: (valuePosition - 12) + 'px'}"></div>
  </div>
</div>
