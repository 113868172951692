<div class="kpi-accordion" [attr.sh-id]="'kpi-accordion'" #kpiAccordion>
  <div *ngIf="!isFinishingStandalone">
    <div class="kpi-accordion-item" *ngFor="let kpi of kpis" [id]="'kpi-button-' + kpi.name">
      <div class="kpi-accordion-item-button"  [ngStyle]="{'cursor': !editMode ? 'pointer' : 'auto'}" (click)="toggleExpansion(kpi)" [attr.sh-id]="'kpi-button-' + kpi.name">
        <div class="kpi-item-icon">
          <div class="icon" *ngIf="!editMode" [ngClass]="dashboardKpiService.getIconAccordingToKpiApplicationPlan(kpi.details.category)"></div>
          <div class="icon-remove" *ngIf="editMode && kpi.name" [attr.sh-id]="'kpi_remove_' + kpi.name" (click)="unselectKPI(kpi)"></div>
          <div class="icon-add" *ngIf="editMode && !kpi.name" [attr.sh-id]="'kpi_add'" (click)="openSelectPopup($event)"></div>
        </div>
        <div class="kpi-item-body" [ngClass]="getItemBodyColor(kpi)">
          <div class="text-and-open-icon">
            <div class="icon" *ngIf="!editMode" [ngClass]="{ 'icon-editor-drop-down': !kpi.isExpanded, 'icon-editor-fold-up': kpi.isExpanded}"></div>
            <div class="text" *ngIf="kpi.name" [attr.sh-id]='"span-kpi-"+kpi.name'>{{ 'DASHBOARD.KPI.' + kpi.name | translate }}</div>
            <div class="text-white" *ngIf="!kpi.name">{{ 'DASHBOARD.KPI.NEW_KPI' | translate}}</div>
          </div>
          <div class="value" *ngIf="!editMode">{{ showValueFromKpiAccordingToType(kpi.details) }} <span *ngIf="!editMode && kpi.details.secondaryValue">{{ showSecondaryValueFromKpiAccordingToType(kpi.details) }}</span></div>
        </div>
      </div>
      <dashboard-kpi-details *ngIf="!editMode" [isExpanded]="kpi.isExpanded" [details]="kpi.details"></dashboard-kpi-details>
      <div class="shadow" [ngClass]="{'shadow-dairy': farmMode === 'Dairy', 'shadow-beef': farmMode === 'Beef'}"></div>
    </div>
  </div>
</div>
<dashboard-kpi-select-dialog #dashboardKpiSelectDialog
                             [model]="selectKpiDialogModel"
                             (onApply)="onKpiSelectDialogApply()"
                             (onCancel)="onKpiSelectDialogCancel()">
</dashboard-kpi-select-dialog>
