import {Component, Input, OnInit} from '@angular/core';
import {AnimalsService} from '../../../../services/animals/animals.service';
import {AnimalCardViewState} from '../animal-card-model';
import {OperationType} from '../../../../services/search/model/search.model';

@Component({
  selector: 'animal-card-health',
  templateUrl: './animal-card-health.component.html',
  styleUrls: ['./animal-card-health.component.scss']
})
export class AnimalCardHealthComponent implements OnInit {

  public ngOnInit(): void {
  }

  @Input()
  public operationType: OperationType;

  @Input()
  public animalId: number;

  @Input()
  public viewState: AnimalCardViewState;

  constructor(public animalsService: AnimalsService) {
  }

  public get isFinishing(): boolean {
    return this.operationType === OperationType.BeefFinishing;
  }
}
