import {
  animation, trigger, animateChild, group,
  transition, animate, style, query
} from '@angular/animations';

export const PICKER_TRANSITIONS = trigger('picker-transition', [
  transition(':enter', [style({transform: 'translateY(100%)'}),
    animate('200ms ease-out', style({opacity: 1, transform: 'translateY(0%)'}))]),
  transition(':leave', [animate('200ms ease-in', style({opacity: 0, transform: 'translateY(100%)'}))])
]);

export const ABOUT_TRANSITIONS = trigger('about-transition', [
  transition(':enter', [style({transform: 'translateY(100%)'}),
    animate('200ms ease-out', style({opacity: 1, transform: 'translateY(0%)'}))]),
  transition(':leave', [animate('200ms ease-in', style({opacity: 0, transform: 'translateY(100%)'}))])
]);

export const SYSTEM_ALRTS_TRANSITIONS = trigger('system-alerts-transition', [
  transition(':enter', [style({opacity: 0, transform: 'translateY(-60px)'}),
    animate('400ms ease-out', style({opacity: 1, transform: 'translateY(0)'}))]),
  transition(':leave', [animate('400ms ease-in', style({opacity: 0, transform: 'translateY(-60px)'}))])
]);

export const TAGS_UPDATE_POPUP_TRANSITIONS = trigger('tags-update-popup-transition', [
  transition(':enter', [style({opacity: 0, transform: 'translateY(-60px)'}),
    animate('400ms ease-out', style({opacity: 1, transform: 'translateY(0)'}))]),
  transition(':leave', [animate('400ms ease-in', style({opacity: 0, transform: 'translateY(-60px)'}))])
]);

export const IMPORT_FROM_CSV_TRANSITIONS = trigger('import-from-csv-popup-transition', [
  transition(':enter', [style({opacity: 0, transform: 'translateY(-60px)'}),
    animate('400ms ease-out', style({opacity: 1, transform: 'translateY(0)'}))]),
  transition(':leave', [animate('400ms ease-in', style({opacity: 0, transform: 'translateY(-60px)'}))])
]);

export const ANIMAL_ALERTS_TRANSITIONS = trigger('animal-alerts-transition', [
  transition(':enter', [style({opacity: 0, transform: 'translateY(-60px)'}),
    animate('400ms ease-out', style({opacity: 1, transform: 'translateY(0)'}))]),
  transition(':leave', [animate('400ms ease-in', style({opacity: 0, transform: 'translateY(-60px)'}))])
]);

export const DIALOG_TRANSITIONS = trigger('dialog-transitions', [
  transition(':enter', [style({opacity: 0, transform: 'translateY(-60px)'}),
    animate('400ms ease-out', style({ opacity: 1, transform: 'translateY(0)'}))]),
  transition(':leave', [animate('400ms ease-in', style({opacity: 0, transform: 'translateY(-60px)'}))])
]);

export const SEARCH_TEXT_TRANSITIONS = trigger('search-text-transition', [
  transition(':enter', [style({transform: 'translateX(-100%)'}),
    animate('400ms ease-out', style({transform: 'translateX(0%)'}))]),
  transition(':leave', [animate('400ms ease-in', style({transform: 'translateX(-100%)'}))])
]);

export const SEARCH_POPUP_TRANSITIONS = trigger('search-popup-transition', [
  transition(':enter', [style({opacity: 0, transform: 'translateX(-60px)'}),
    animate('400ms ease-out', style({opacity: 1, transform: 'translateX(0)'}))]),
  transition(':leave', [animate('400ms ease-in', style({opacity: 0, transform: 'translateX(-60px)'}))])
]);

export const ROW_ACTION_TRANSITIONS = trigger('toggelOpenRowAction', [
  transition(':enter', [style({transform: 'translateX(100%)'}), animate('0.1s ease-in-out', style({transform: 'translateX(0)'}))]),
  transition(':leave', [animate('0.1s ease-in-out', style({transform: 'translateX(100%)'}))]
  )
]);
