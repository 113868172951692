import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FinishingAddMaleModel} from "../add-male-finishing.component";
import {AnimalsService} from "../../../../../../../services/animals/animals.service";
import {
  AddAnimalFieldsErrorsEnum,
  IErrors, IMaleMetadata,
  TagTypeEnum
} from "../../../../../../../services/animals/model/animal.model";
import {
  DropdownListAutoSelectionMode,
  DropdownListItem
} from "../../../../../../../common/components/dropdown-list/dropdown-list-model";
import {AutoCompleteColorScheme} from "../../../../../../../common/components/auto-complete/auto-complete-model";

@Component({
  selector: 'add-male-finishing-entry-tab',
  templateUrl: './add-male-finishing-entry-tab.component.html',
  styleUrls: ['./add-male-finishing-entry-tab.component.scss']
})
export class AddMaleFinishingEntryTabComponent implements OnInit {

  public AddAnimalFieldsErrorsEnum = AddAnimalFieldsErrorsEnum;

  public dropdownListAutoSelectionMode = DropdownListAutoSelectionMode;

  public autoCompleteColorScheme = AutoCompleteColorScheme;

  @ViewChild('registrationNumberInput')
  public registrationNumberInput: ElementRef;

  @Input()
  public finishingAddMaleModel: FinishingAddMaleModel;

  @Input()
  public addMaleFinishingMetadata: IMaleMetadata;

  @Input()
  public groupsDropdownListItems: DropdownListItem[];

  @Input()
  public scrTagsNumbers: string[];

  @Input()
  public rfidsTagsNumbers: string[];

  @Input()
  public errors: IErrors[];

  @Output()
  public onClearErrors: EventEmitter<void> = new EventEmitter<void>();

  constructor(public animalsService: AnimalsService) { }

  public ngOnInit() {
  }

  public clearErrors() {
    this.onClearErrors.emit();
  }

  public onGroupSelected(selectedGroup: DropdownListItem) {
    if (!this.finishingAddMaleModel.group) {
      this.clearErrors();
    }
    this.finishingAddMaleModel.group = selectedGroup;
  }

  public onScrTagChanged(tagNumber: string) {
    if(tagNumber == null ||
      tagNumber === '') {
      this.finishingAddMaleModel.tagNumber = null;
    } else {
      if (this.addMaleFinishingMetadata.scrTagList) {
        this.finishingAddMaleModel.tagNumber = this.addMaleFinishingMetadata.scrTagList.find(value => value.tagNumber === tagNumber);
      }
      if(this.finishingAddMaleModel.tagNumber == null) {
        this.finishingAddMaleModel.tagNumber = {
          tagNumber: tagNumber,
          id: null,
          tagType: TagTypeEnum.Scr
        };
      }
    }
    this.clearErrors();
  }

  public onRfIdTagChanged(tagNumber: string) {
    if(tagNumber == null ||
      tagNumber === '') {
      this.finishingAddMaleModel.EID = null;
    } else {
      if (this.addMaleFinishingMetadata.rfidTagList) {
        this.finishingAddMaleModel.EID = this.addMaleFinishingMetadata.rfidTagList.find(value => value.tagNumber === tagNumber);
      }
      if(this.finishingAddMaleModel.EID == null) {
        this.finishingAddMaleModel.EID = {
          tagNumber: tagNumber,
          id: null,
          tagType: TagTypeEnum.RfId
        };
      }
    }
    this.clearErrors();
  }

  public onClearRegistrationNumber(): void {
    this.finishingAddMaleModel.registrationNumber = null;
    this.registrationNumberInput.nativeElement.focus();
    this.clearErrors();
  }
}
