import {Component, OnInit, ViewChild} from '@angular/core';
import {IconCssClass} from '../../../services/reports/model/report.interface';
import {Router} from '@angular/router';
import {DataGridColumnMap} from '../../../common/components/data-grid/model/data-grid-column-map';
import {DataGridQuery, DataGridQuerySortDetails} from '../../../common/components/data-grid/model/data-grid-query';
import {LedTasksService} from '../../../services/led-tasks/led-tasks.service';
import {DiscoverItems, LedTasksResult, ScheduledTaskModel} from '../../../services/led-tasks/models/led-tasks.interface';
import {HttpResponseResult} from '../../../services/rest-api/rest-api.service';
import {DateTimeFormatEnum} from '../../../common/pipes/epoch-date-time.pipe';
import {SearchService} from '../../../services/search/search.service';
import {SearchEntry} from '../../../services/search/model/search.model';
import {RowActionsPopupItem} from '../../../common/components/row-actions-popup/row-actions-popup.component';
import {DataGridComponent} from '../../../common/components/data-grid/data-grid.component';
import {TranslationService} from '../../../services/translations/translation.service';
import {ErrorDialogService} from '../../../common/components/dialogs/error-dialog/error-dialog.service';
import {Failure} from '../../../services/registration/model/registration.model';
import {GridViewState} from '../../../services/ui/view-state/grid-row/grid-view-state';
import {ConfigService} from '../../../services/config/config.service';
import {FarmRole} from '../../../services/auth/model/farm-role.enum';

export enum LedTasksColumns {
  reason = 'reason',
  time = 'time',
  effectiveGroupsAnimals = 'effectiveGroupsAnimals',
  repeats = 'repeats',
  actions = 'actions'
}
export enum LedTasksRowActions {
  Edit = 'Edit',
  Delete = 'Delete',
  Suspend = 'Suspend',
  Unsuspend = 'Unsuspend'
}


@Component({
  selector: 'tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  public LedTasksColumns = LedTasksColumns;

  @ViewChild('ledTasksDataGrid')
  public ledTasksDataGrid: DataGridComponent;

  public dateTimeFormatEnum = DateTimeFormatEnum;

  public discoverItems = DiscoverItems;

  private gridViewState : GridViewState = new GridViewState();

  private groupsList: SearchEntry[] = [];

  private animalsList: SearchEntry[]= [];

  public readonly columnsMappings: Map<string, DataGridColumnMap> = new Map();

  public ledTasksSortedGridQuery: DataGridQuery;

  public isDeleteDialogShown: boolean = false;

  private taskIdToDelete: number;

  public readonly rowActionsMap: Map<ScheduledTaskModel, RowActionsPopupItem[]> = new Map<ScheduledTaskModel, RowActionsPopupItem[]>();

  public ledTasksData: LedTasksResult = {
    total: 0,
    offset: 0,
    limit: 0,
    result: []
  };

  constructor(private router: Router,
              private searchService: SearchService,
              public configService: ConfigService,
              private errorDialogService: ErrorDialogService,
              private translationService: TranslationService,
              private ledTasksService: LedTasksService) {
    this.columnsMappings.set('reason',
      {
        enableSorting: false,
        flexGrow: 1
      });
    this.columnsMappings.set('time',
      {
        enableSorting: false,
        flexGrow: 1
      });
    this.columnsMappings.set('effectiveGroupsAnimals',
      {
        enableSorting: false,
        flexGrow: 1
      });
    this.columnsMappings.set('repeats',
      {
        enableSorting: false,
        flexGrow: 1
      });
    this.columnsMappings.set('actions',
      {
        enableSorting: false,
        flexGrow: 0.2
      });
  }

  public async ngOnInit() {
    this.groupsList = await this.searchService.listOfAvailableGroups();
    this.animalsList = await this.searchService.listOfAvailableAnimals();
    await this.setDefaultLedTasksSortedGridQuery();
    await this.getLedTasks();
  }

  private setDefaultLedTasksSortedGridQuery() {
    this.ledTasksSortedGridQuery = new DataGridQuery();
    this.ledTasksSortedGridQuery.offset = 0;
    this.ledTasksSortedGridQuery.limit = 100;
    this.ledTasksSortedGridQuery.sortDetails = new DataGridQuerySortDetails();
    this.ledTasksSortedGridQuery.sortDetails.column = 'reportId';
  }

  public getGridViewState(): GridViewState {
    return this.gridViewState;
  }

  public async getLedTasks() {
    const response: HttpResponseResult<LedTasksResult> = await this.ledTasksService.getAllScheduledTasks(this.ledTasksSortedGridQuery);
    if (response && response.responseBody && response.responseBody.total > 0) {
      this.rowActionsMap.clear();
      this.ledTasksData = response.responseBody;
      this.updateRowActions();
      this.ledTasksDataGrid.reloadGrid();
    } else {
      await this.router.navigate(['led-tasks/no-tasks']);
    }
  }

  private updateRowActions() {
    this.ledTasksData.result.forEach((row: ScheduledTaskModel) => {
      this.setRowActions(row);
    });
  }

  public setRowActions(rowModel: ScheduledTaskModel) {
    let rowActions: RowActionsPopupItem[] = [];
    if (this.configService.serverConfig.user.farmRole != FarmRole.ExternalUser) {
      if (rowModel.isSuspendable) {
        if (rowModel.isEnabled) {
          rowActions.push({
            action: LedTasksRowActions.Suspend,
            displayValue: this.translationService.translate('FOOTER.ABOUT.SYSTEM_STATE_TYPE.suspend'),
            iconCssClass: IconCssClass.suspend,
            shIdRowIdentifier: 'led_task_suspend'
          });
        } else {
          rowActions.push({
            action: LedTasksRowActions.Unsuspend,
            displayValue: this.translationService.translate('FOOTER.ABOUT.SYSTEM_STATE_TYPE.unsuspend'),
            iconCssClass: IconCssClass.unsuspend,
            shIdRowIdentifier: 'led_task_unsuspend'
          });
        }
      }
      if (rowModel.isEditable) {
        rowActions.push({
          action: LedTasksRowActions.Edit,
          displayValue: this.translationService.translate('MANAGE.SETTINGS.USERS.USERS_LIST.EDIT'),
          iconCssClass: IconCssClass.edit,
          shIdRowIdentifier: 'led_task_edit'
        });
      }

      rowActions.push({
        action: LedTasksRowActions.Delete,
        displayValue: this.translationService.translate('MANAGE.SETTINGS.USERS.USERS_LIST.DELETE'),
        iconCssClass: IconCssClass.remove,
        shIdRowIdentifier: 'ledt_task_delete'
      });
    }
    this.rowActionsMap.set(rowModel, rowActions);
  }

  public async handleRowActionWrapper(rowActionModel: IRowActionModel) {
    await this.handleRowAction(rowActionModel.rowAction, rowActionModel.rowModel);
  }

  public async handleRowAction(rowAction: string, rowModel: ScheduledTaskModel) {
    switch (<LedTasksRowActions>rowAction) {
      case LedTasksRowActions.Suspend: {
        await this.toggleSuspend(rowModel.id, false);
        break;
      }
      case LedTasksRowActions.Unsuspend: {
        await this.toggleSuspend(rowModel.id, true);
        break;
      }
      case LedTasksRowActions.Edit: {
        await this.router.navigate(['/led-tasks/task-editor', rowModel.id]);
        break;
      }
      case LedTasksRowActions.Delete: {
        this.taskIdToDelete = rowModel.id;
        this.isDeleteDialogShown = true;
        break;
      }
    }
  }

  public async toggleSuspend(taskId: number, isSuspend: boolean): Promise<void> {
    let response: HttpResponseResult<ScheduledTaskModel> = await this.ledTasksService.toggleSuspendTask(taskId, isSuspend);
    if (response.status === 200) {
      let isEnabled: boolean = response.responseBody.isEnabled;
      let task = this.ledTasksData.result.find((taskItem) => taskItem.id === taskId);
      task.isEnabled = isEnabled;
      this.updateRowActions();
    }
  }

  public async deleteTask() {
    this.isDeleteDialogShown = false;
    let response = await this.ledTasksService.deleteLedTask(this.taskIdToDelete);
    if (response.status === 204) {
      await this.getLedTasks();
    } else {
      setTimeout(() => {
        let failures: Failure[] = [{errorKey: response.errorResponseBody.result.failures[0].key, fieldName: response.errorResponseBody.result.failures[0].fieldName }];
        this.errorDialogService.show('LED_TASKS.TASK_EDITOR.DELETE_DIALOG.ERROR.FAILED_DELETE_TASK',failures, 'LED_TASKS.TASK_EDITOR.ERROR', null);
      }, 200);
    }
    this.taskIdToDelete = null;
  }

  public async addLedTask() {
    await this.router.navigate(['/led-tasks/task-editor']);
  }

  public isRowExpanded(row) : boolean {
    if(this.ledTasksDataGrid == null) {
      return false;
    }
    return this.gridViewState.getRowExpandedState(row) != null;
  }

  public closeDeleteConfirmationDialog() {
    this.isDeleteDialogShown = false;
  }
}

export interface IRowActionModel {
  rowAction: string;
  rowModel: ScheduledTaskModel;
}

