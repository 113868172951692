import { Injectable } from '@angular/core';
import {HttpResponseResult, HttpResponseResultNoBody, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from '../auth/auth.service';
import {Subject} from 'rxjs';
import {TranslationService} from '../translations/translation.service';
import {DropdownListItem} from '../../common/components/dropdown-list/dropdown-list-model';
import {GroupBranchDetails, GroupBranchModel, IBranchGraphDetails} from './model/branch.interface';
import {ChartResolution} from '../model/charts/chart.interface';
import {HttpParams} from '@angular/common/http';
import {IBranchChartConsistencyData, IBranchChartHeatStressData} from '../../logged-in-layout/card-popup/branch-card/branch-card-model';
import {IGroupChartConsistencyData} from '../groups/model/group.interface';


export class BranchLocalizationModel {
  public value: string;
  public isLocalization: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class BranchesService {

  private static readonly BranchesRoute = '/rest/api/finishing/groupbranches';

  private static readonly DeleteBranchesRoute = '/rest/api/finishing/groupbranches/{groupBranchId}';

  private static readonly BranchCardDetails = '/rest/api/finishing/groupbranches/{groupBranchId}';

  private static readonly BranchGraphSetupRoute = '/rest/api/graphs/{chartId}?projection=getsetup';

  private static readonly BranchGraphRoute = '/rest/api/finishing/groupbranches/{branchId}/graphs/{chartId}';

  // tslint:disable-next-line:no-any
  public onBranchesListChanged: Subject<any> = new Subject<any>();

  public editBranchesSubscription: Subject<GroupBranchModel> = new Subject<GroupBranchModel>();

  constructor(private restApiService: RestApiService, private authService: AuthService, private translationService: TranslationService) { }

  public createBranch(newBranch: GroupBranchModel): Promise<HttpResponseResultNoBody> {
    return this.restApiService.sendPostMethod<void>(BranchesService.BranchesRoute, this.authService.currentUserAuthState.authDetails, newBranch);
  }

  public editBranch(editedBranch: GroupBranchModel): Promise<HttpResponseResultNoBody> {
    return this.restApiService.sendPutMethod<void>(BranchesService.BranchesRoute, this.authService.currentUserAuthState.authDetails, editedBranch);
  }

  public deleteBranch(groupBranchId: number): Promise<HttpResponseResultNoBody> {
    let route = BranchesService.DeleteBranchesRoute.replace('{groupBranchId}', groupBranchId.toString());
    return this.restApiService.sendDeleteMethod(route, this.authService.currentUserAuthState.authDetails);
  }

  public getGroupBranchText(currentBranch: BranchLocalizationModel): string {
    if (currentBranch.isLocalization) {
      return this.translationService.translate('REPORTS.GRID.STATUS.' + currentBranch.value);
    } else {
      return currentBranch.value;
    }
  }

  public getGroupBranchDropdownItemsList(branchesList: BranchLocalizationModel[]): DropdownListItem[] {
    let dropdownListToReturn: DropdownListItem[] = [];
    branchesList.forEach((branch: BranchLocalizationModel) => {
      if (branch.isLocalization) {
        dropdownListToReturn.push({
          value: branch.value,
          displayValue: this.translationService.translate('REPORTS.GRID.STATUS.' + branch.value),
          disabled: false,
          icon: null
        });
      } else {
        dropdownListToReturn.push({
          value: branch.value,
          displayValue: branch.value,
          disabled: false,
          icon: null
        });
      }
    });
    return dropdownListToReturn;
  }

  public async getBranchCardDetails(groupBranchId: number): Promise<HttpResponseResult<GroupBranchDetails>> {
    let route = BranchesService.BranchCardDetails.replace('{groupBranchId}', groupBranchId.toString());
    return this.restApiService.sendGetMethod<GroupBranchDetails>(route, this.authService.currentUserAuthState.authDetails);
  }

  public async getBranchGraphDetails(branchId: number): Promise<HttpResponseResult<IBranchGraphDetails>> {
    return this.restApiService.sendGetMethod<IBranchGraphDetails>(
      `${BranchesService.BranchesRoute}/${branchId}?projection=graph`,
      this.authService.currentUserAuthState.authDetails
    );
  }

  public async getBranchHeatStressChart(branchId: number, chartId: number, resolution: ChartResolution, series: string[]):
    Promise<HttpResponseResult<IBranchChartHeatStressData>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('projection', 'flat');
    httpParams = httpParams.append('resolution', resolution);
    if (series) {
      httpParams = httpParams.append('series', series.join(','));
    }
    return this.restApiService.sendGetMethod<IBranchChartHeatStressData>(
      BranchesService.BranchGraphRoute
        .replace('{branchId}', branchId.toString(10))
        .replace('{chartId}', chartId.toString(10)),
      this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public async getBranchChartConsistency(branchId: number, chartId: number):
    Promise<HttpResponseResult<IBranchChartConsistencyData>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('projection', 'flat');
    return this.restApiService.sendGetMethod<IBranchChartConsistencyData>(
      BranchesService.BranchGraphRoute
        .replace('{branchId}', branchId.toString(10))
        .replace('{chartId}', chartId.toString(10)),
      this.authService.currentUserAuthState.authDetails, httpParams);
  }
}
