import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {RegistrationService} from '../../services/registration/registration.service';
import {ICreateAccountResponse} from '../../services/registration/model/registration.model';
import {HttpResponseResult} from '../../services/rest-api/rest-api.service';
import {TranslationPickerThemeEnum} from "../../common/components/translation/translation-picker/translation-picker.component";

@Component({
  selector: 'initialize-sign-up',
  templateUrl: './initialize-sign-up.component.html',
  styleUrls: ['./initialize-sign-up.component.scss']
})
export class InitializeSignUpComponent implements OnInit {

  public TranslationPickerThemeEnum = TranslationPickerThemeEnum;

  constructor(private router: Router, public registrationService: RegistrationService) { }

  public ngOnInit() {
  }

  public async navigateToSignUpPage() {
    this.registrationService.isClickedOnSignUp = true;
    let response: HttpResponseResult<ICreateAccountResponse> = await this.registrationService.getCreateAccount();
    if (response.status === 200) {
      this.registrationService.registrationModel.createAccount = response.responseBody;
      await this.router.navigate(['/registration/sign-up/create-admin-account']);
    }
  }
}
