import {Component, Input, OnInit} from '@angular/core';
import {DropdownColorScheme} from '../../../../../common/components/dropdown-list/regular-dropdown-list/regular-dropdown-list.component';
import {LedTasksService} from '../../../../../services/led-tasks/led-tasks.service';
import {
  DiscoverItems, LedModeSettings,
  LedTaskFields,
  ReportModel,
  TaskModel
} from '../../../../../services/led-tasks/models/led-tasks.interface';
import {DropdownListItem} from '../../../../../common/components/dropdown-list/dropdown-list-model';
import {AutoCompleteColorScheme} from "../../../../../common/components/auto-complete/auto-complete-model";
import {SearchEntry} from "../../../../../services/search/model/search.model";
import * as _ from 'lodash';

@Component({
  selector: 'led-task-editor-left-container',
  templateUrl: './led-task-editor-left-container.component.html',
  styleUrls: ['./led-task-editor-left-container.component.scss']
})
export class LedTaskEditorLeftContainerComponent implements OnInit {

  public DropdownColorScheme = DropdownColorScheme;

  public LedTaskFields = LedTaskFields;

  public DiscoverItems = DiscoverItems;

  public AutoCompleteColorScheme = AutoCompleteColorScheme;

  @Input()
  public taskToEdit: number = null;

  @Input()
  public reportsList: ReportModel[] = [];

  @Input()
  public groupsList: string[] = [];

  @Input()
  public animalsList: string[] = [];

  @Input()
  public searchEntities: SearchEntry[] = [];

  constructor(public ledTasksService: LedTasksService) { }

  public async ngOnInit() {}

  public selectDiscoverId(discoverItem: DropdownListItem) {
    if (discoverItem == null) {
      this.ledTasksService.taskModel.selectedDiscoverItem = null;
    } else {
      if (discoverItem.value != this.ledTasksService.taskModel.selectedDiscoverItem.value) {
        this.ledTasksService.taskModel.selectedDiscoverItem = discoverItem;
        this.initEditorAccordingToDiscoverItem(this.ledTasksService.taskModel.selectedDiscoverItem);
      }
    }
    // clear all selections for both Animals and Reports
    this.ledTasksService.clearErrors();
  }

  public initEditorAccordingToDiscoverItem(discoverItem: DropdownListItem) {
    switch (<DiscoverItems>discoverItem.value) {
      case DiscoverItems.Reports: {
        this.ledTasksService.ledModeSettings = new LedModeSettings();
        this.ledTasksService.taskModel.initReportFields();
        this.ledTasksService.originalTaskModel = _.cloneDeep(this.ledTasksService.taskModel);
        break;
      }
      case DiscoverItems.Animals: {
        this.ledTasksService.ledModeSettings = new LedModeSettings();
        this.ledTasksService.taskModel.initAnimalFields();
        this.ledTasksService.originalTaskModel = _.cloneDeep(this.ledTasksService.taskModel);
        setTimeout(() => {
          this.ledTasksService.taskModel.selectedTaskName = 'Manual';
        });
        break;
      }
    }
  }

  public get isEdit() {
    return this.taskToEdit != null;
  }
}
