import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ConfigService} from '../../../services/config/config.service';
import {ApplicationBundle, FinishingApplicationBundle} from '../../../services/config/model/server-config';
import {GroupsService} from '../../../services/groups/groups.service';
import {GroupInfo, IGroupGraphDetails} from '../../../services/groups/model/group.interface';
import {GroupCardTabSection, GroupCardViewState} from './group-card-model';
import {OperationType} from '../../../services/search/model/search.model';
import {Subscription} from "rxjs";

@Component({
  selector: 'group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss']
})
export class GroupCardComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public viewState: GroupCardViewState;

  @Input()
  public operationType: OperationType;

  public groupGraphDetails: IGroupGraphDetails;

  public groupInfo: GroupInfo;

  public GroupCardTabSection = GroupCardTabSection;

  private groupGraphDetailsSubscription: Subscription;

  public ApplicationBoundle = ApplicationBundle;

  public operationTypes = OperationType;

  public get isRoutineGraphsMenuEnabled(): boolean {
    return !!this.groupInfo && this.groupInfo.plan === ApplicationBundle.Premium && !!this.groupGraphDetails;
  }

  public get isTrendGraphsMenuEnabled(): boolean {
    return !!this.groupInfo && (this.groupInfo.plan === ApplicationBundle.Premium || this.groupInfo.plan === FinishingApplicationBundle.Finishing) && !!this.groupGraphDetails;
  }

  constructor(public configService: ConfigService, private groupsService: GroupsService) { }

  public ngOnInit(): void {
    this.groupGraphDetailsSubscription = this.groupsService.groupGraphDetailsSubscription.subscribe(async () => {
      await this.getGroupCardGraphDetails();
    });
  }

  public async ngOnChanges(changes: SimpleChanges) {
    let viewStateChange = changes['viewState'];
    if(viewStateChange.previousValue == viewStateChange.currentValue){
      return;
    }
    this.groupGraphDetails = null;
    this.groupInfo = null;
    if(this.viewState.groupDetails != null) {
      let groupInfoResponse = await this.groupsService.getGroupInfo(this.viewState.groupDetails.id);
      if(groupInfoResponse.status == 200) {
        this.groupInfo = groupInfoResponse.responseBody;
        await this.getGroupCardGraphDetails();
      }
    }
    if ([GroupCardTabSection.GraphsActivity, GroupCardTabSection.GraphsHeat, GroupCardTabSection.TrendsConsistency, GroupCardTabSection.TrendsHeat]
      .includes(this.viewState.selectedTab) && (!this.groupGraphDetails || this.configService.serverConfig.bizModeParameters.applicationBundle != ApplicationBundle.Premium)) {
      this.selectTab(GroupCardTabSection.Details)
    }
  }

  public ngOnDestroy(): void {
    if (this.groupGraphDetailsSubscription) {
      this.groupGraphDetailsSubscription.unsubscribe();
    }
  }

  private async getGroupCardGraphDetails(): Promise<void> {
    if(this.groupInfo.plan == ApplicationBundle.Premium || this.groupInfo.plan == FinishingApplicationBundle.Finishing) {
      let graphDetailsResponse = await this.groupsService.getGroupGraphDetails(this.viewState.groupDetails.id);
      if(graphDetailsResponse.status == 200) {
        this.groupGraphDetails = graphDetailsResponse.responseBody;
      }
    }
  }

  public selectTab(tab:GroupCardTabSection){
    this.viewState.selectedTab = tab;
  }
}
