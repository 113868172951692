import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {AnimalsService} from '../../../../services/animals/animals.service';
import {AnimalDetails, AnimalDetailsField, IAnimalInfoMetadata} from '../../../../services/animals/model/animal.model';
import {UtilsService} from '../../../../services/utils/utils.service';
import {EntityType, SearchService} from '../../../../services/search/search.service';
import {OperationType} from '../../../../services/search/model/search.model';
import {
  AnimalDetailsDamFieldViewState,
  AnimalDetailsDateFieldViewState,
  AnimalDetailsGroupFieldViewState,
  AnimalDetailsSectionViewState,
  AnimalDetailsValueFieldViewState
} from './model/animal-card-details-view-state';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../../common/components/calendar/model/epoch-state-model';
import {CardViewStateService} from '../../../../services/ui/view-state/card-view-state.service';
import {SaveDataViewStateService} from '../../../../services/ui/view-state/save-data-view-state.service';

@Component({
  selector: 'animal-card-details',
  templateUrl: './animal-card-details.component.html',
  styleUrls: ['./animal-card-details.component.scss']
})
export class AnimalCardDetailsComponent implements OnInit {

  @Input()
  public animalId: number;

  private performingSave:boolean;

  public animalDetails:AnimalDetails;

  public animalInfoMetadata:IAnimalInfoMetadata;

  public currentAnimalOperationType: OperationType;

  public animalSectionsViewStatesByCategory: Map<string, AnimalDetailsSectionViewState> = new Map<string, AnimalDetailsSectionViewState>();

  public animalSectionsViewStates: AnimalDetailsSectionViewState[] = [];

  private editableFieldDetails: AnimalDetailsField[] = [];

  constructor(public readonly animalsService:AnimalsService,
              public readonly cardViewStateService:CardViewStateService,
              public readonly utilsService: UtilsService,
              public readonly searchService: SearchService,
              private readonly saveDataViewStateService:SaveDataViewStateService) {

  }

  public async ngOnInit() {
    this.currentAnimalOperationType = await this.searchService.findOperationTypeByEntityId(this.animalId, EntityType.cow);
    await this.getAnimalInfoMetaData();
    await this.getAnimalDetails();
  }

  public async onSaveChanges(animalDetailsSectionViewState:AnimalDetailsSectionViewState) {
    this.performingSave = true;
    this.editableFieldDetails.forEach(field => {
      if (field.value === '') {
        field.value = null;
      }
    });
    let animalDetailsResponse = null;
    if (this.currentAnimalOperationType === OperationType.BeefFinishing) {
      animalDetailsResponse = await this.animalsService.updateFinishingAnimalDetails(this.animalId, {
        animalId: this.animalId,
        details: this.editableFieldDetails
      });
    } else {
      animalDetailsResponse = await this.animalsService.updateAnimalDetails(this.animalId, {
        animalId: this.animalId,
        details: this.editableFieldDetails
      });
    }
    if(animalDetailsResponse.status == 200){
      animalDetailsSectionViewState.errorKey = null;
      animalDetailsSectionViewState.errorField = null;
      this.saveDataViewStateService.activeEditArea = null;
      this.animalDetails = animalDetailsResponse.responseBody;
      this.mapAnimalFieldsByCategory();
      this.cardViewStateService.openLastActiveCardViewState();
    } else {
      animalDetailsSectionViewState.errorKey = animalDetailsResponse.errorResponseBody.Description;
      animalDetailsSectionViewState.errorField = animalDetailsResponse.errorResponseBody.FieldName;
    }
    this.performingSave = false;
  }

  public onRevertChanges() {
    this.mapAnimalFieldsByCategory();
  }

  public onRequestEdit(category:string) {
    this.animalSectionsViewStates.forEach(sectionViewState => {
      const isSameCategory: boolean = sectionViewState.category === category;
      sectionViewState.isExpanded = isSameCategory;
      sectionViewState.isEditMode = isSameCategory;
    });
  }

  public onSectionExpanded(category:string) {
    this.animalSectionsViewStates.forEach(sectionViewState => {
      if(sectionViewState.category != category){
        sectionViewState.isExpanded = false;
      }
    });
  }

  private async getAnimalDetails() {
    let animalDetailsResponse = null;
    if (this.currentAnimalOperationType === OperationType.BeefFinishing) {
      animalDetailsResponse = await this.animalsService.getFinishingAnimalDetails(this.animalId);
    } else {
      animalDetailsResponse = await this.animalsService.getAnimalDetails(this.animalId);
    }
    if(animalDetailsResponse && animalDetailsResponse.status == 200) {
      this.animalDetails = animalDetailsResponse.responseBody;
      this.mapAnimalFieldsByCategory();
    }
  }

  private async getAnimalInfoMetaData() {
    let animalInfoMetaDataResponse = null;
    if (this.currentAnimalOperationType === OperationType.BeefFinishing) {
      animalInfoMetaDataResponse = await this.animalsService.getFinishingAnimalInfoMetaData(this.animalId);
    } else {
      animalInfoMetaDataResponse = await this.animalsService.getAnimalInfoMetaData(this.animalId);
    }
    if(animalInfoMetaDataResponse.status == 200){
      this.animalInfoMetadata = animalInfoMetaDataResponse.responseBody;
    }
  }

  private mapAnimalFieldsByCategory() {
    this.animalSectionsViewStatesByCategory = new Map<string, AnimalDetailsSectionViewState>();
    this.animalSectionsViewStates = [];
    this.editableFieldDetails = [];
    for (let field of this.animalDetails.details)
    {
      if(!this.animalSectionsViewStatesByCategory.has(field.category)) {
        let animalSectionViewState : AnimalDetailsSectionViewState = {
          category: field.category,
          isEditMode: false,
          isExpanded: false,
          errorKey: null,
          errorField: null,
          fieldsViewStates: []
        };
        this.animalSectionsViewStates.push(animalSectionViewState);
        this.animalSectionsViewStatesByCategory.set(field.category, animalSectionViewState);
      }
      let fieldSection = this.animalSectionsViewStatesByCategory.get(field.category);
      let editableField = _.cloneDeep(field);
      this.editableFieldDetails.push(editableField);
      this.mapFieldDetailsToViewState(fieldSection, editableField);
    }
  }

  private mapFieldDetailsToViewState(fieldSection: AnimalDetailsSectionViewState, fieldDetails: AnimalDetailsField) {
    if(fieldDetails.name == 'Dam') {
      fieldSection.fieldsViewStates.push(new AnimalDetailsDamFieldViewState(this.animalInfoMetadata, fieldDetails));
    } else if(fieldDetails.name == 'Group') {
      fieldSection.fieldsViewStates.push(new AnimalDetailsGroupFieldViewState(this.animalInfoMetadata, fieldDetails));
    } else if(fieldDetails.name == 'EntryDate' ||
              fieldDetails.name == 'BirthDate' ||
              fieldDetails.name == 'LastCalvingDate') {
      let epochStateModel = new EpochStateModel(EpochStateModelMinMaxMode.Date ,fieldDetails.value);
      epochStateModel.min = this.getFieldMinDate(fieldDetails);
      epochStateModel.max = this.getFieldMaxDate(fieldDetails);
      fieldSection.fieldsViewStates.push(new AnimalDetailsDateFieldViewState(true, epochStateModel, fieldDetails));
    } else {
      fieldSection.fieldsViewStates.push(new AnimalDetailsValueFieldViewState(fieldDetails));
    }
  }

  private getFieldMinDate(field: AnimalDetailsField) : number {
    if(field.name === 'EntryDate' &&
      this.animalInfoMetadata.entryDates) {
      return this.animalInfoMetadata.entryDates.minDate;
    } else if(field.name === 'BirthDate' &&
      this.animalInfoMetadata.birthDates) {
      return this.animalInfoMetadata.birthDates.minDate;
    } else if(field.name === 'LastCalvingDate' &&
      this.animalInfoMetadata.calvingDates) {
      return this.animalInfoMetadata.calvingDates.minDate;
    }
  }

  private getFieldMaxDate(field: AnimalDetailsField) : number {
    if(field.name === 'EntryDate' &&
      this.animalInfoMetadata.entryDates) {
      return this.animalInfoMetadata.entryDates.maxDate;
    } else if(field.name === 'BirthDate' &&
      this.animalInfoMetadata.birthDates) {
      return this.animalInfoMetadata.birthDates.maxDate;
    } else if(field.name === 'LastCalvingDate' &&
      this.animalInfoMetadata.calvingDates) {
      return this.animalInfoMetadata.calvingDates.maxDate;
    }
  }
}
