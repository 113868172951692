import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { DateTimeFormatEnum } from 'src/app/common/pipes/epoch-date-time.pipe';
import {DataRow} from '../../../../services/model/common-model';

@Directive()
export abstract class DescriptionCellComponent<T extends DataRow> {

  @Input()
  public row : T;

  @Output()
  public onToggleExpanded: EventEmitter<void> = new EventEmitter<void>();

  public get isExpanded() : boolean {
    return this.row.gridViewState.getRowExpandedState(this.row) != null;
  }

  public toggleExpanded() {
    this.onToggleExpanded.emit();
  }
}
