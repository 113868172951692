import {Component, Input, OnInit} from '@angular/core';

export class NamedChartLegendState {
  public name:string;
  // tslint:disable-next-line:no-any
  public value:any;
  public altTranslation:string;
  public altCss:string;
}

export class ChartLegendConfiguration {
  public translationPrefix: string;
  public valuePostfix: string;
}

@Component({
  selector: 'xy-chart-legend',
  templateUrl: './xy-chart-legend.component.html',
  styleUrls: ['./xy-chart-legend.component.scss']
})
export class XyChartLegendComponent implements OnInit {

  @Input()
  public configuration: ChartLegendConfiguration;

  @Input()
  public legendStates: NamedChartLegendState[];

  constructor() { }

  public ngOnInit() {

  }
}
