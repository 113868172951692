export enum ClientSessionRecorderModeType {
  Idle = 'Idle',
  Recording = 'Recording',
  Playback = 'Playback'
}

//Message
export enum ClientSessionRecorderMessageDirection {
  ClientToExtension = 'ClientToExtension',
  ExtensionToClient = 'ExtensionToClient'
}

export enum ClientSessionRecorderMessageType {
  Event = 'Event',
  Status = 'Status'
}

export class ClientSessionRecorderMessage {
  public direction : ClientSessionRecorderMessageDirection;
  public messageId: string;
  public data : ClientSessionRecorderMessageData;
  public tabId : number;
  public handledByBackground : boolean;
}

export abstract class ClientSessionRecorderMessageData {
  public abstract type: ClientSessionRecorderMessageType;
}

//Session
export class ClientSessionRecorderSession{
  public recordedEvents: ClientSessionRecorderEvent[] = [];
  public sessionId: string;
  public testSuite: TestSuiteDefinition = new TestSuiteDefinition();
}

//Test suite
export class TestSuiteDefinition {
  public name : string;
}

//Events
export enum ClientSessionRecorderEventType {
  WebApi = 'WebApi',
  WindowSize = 'WindowSize',
  DomElement = 'DomElement',
  TestRelated = 'TestRelated'
}

export abstract class ClientSessionRecorderEvent extends ClientSessionRecorderMessageData {
  public abstract eventType: ClientSessionRecorderEventType;
  public type: ClientSessionRecorderMessageType;
  public eventId: string;

  protected constructor() {
    super();
    this.type = ClientSessionRecorderMessageType.Event;
  }
}
