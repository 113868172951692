import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config/config.service';
import {Router} from '@angular/router';
import {TranslationPickerThemeEnum} from "../../common/components/translation/translation-picker/translation-picker.component";

@Component({
  selector: 'app-welcome-layout',
  templateUrl: './welcome-layout.component.html',
  styleUrls: ['./welcome-layout.component.scss']
})
export class WelcomeLayoutComponent implements OnInit {

  public serverError: boolean;

  public TranslationPickerThemeEnum = TranslationPickerThemeEnum;

  constructor(private router: Router,
              public configService: ConfigService) { }

  public ngOnInit() {

    if(this.configService.anonymousAuthResult === null) {
      this.serverError = true;
    }

  }

  public reloadPage() {
    window.location.reload();
  }
}
