import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {DateTimeFormatEnum, EpochDateTimePipe} from '../../../common/pipes/epoch-date-time.pipe';
import {ConfigService} from '../../config/config.service';
import {AuthService} from '../../auth/auth.service';
import {HttpResponseResult, RestApiService} from '../../rest-api/rest-api.service';
import {FarmMode} from '../../config/model/server-config';
import {AnimalLactationStatus, IBreedingAnimalEventsTimelineData} from './model/animal-timeline-breeding-model';
import {AnimalTimelineFlowModel, AnimalTimelineHeader} from '../model/animal-timeline-model';
import {AnimalTimelineUtils} from '../utils/animal-timeline-utils';
import {TranslationService} from '../../translations/translation.service';

enum BreedingAnimalTimeLinEventsTypeEnum {
  Start,
  Breeding,
  DryOff,
  Calving
}

class BreedingAnimalTimeLineEvent {
  public dateTimeServer: number;
  public localDate: number;
  public eventType: BreedingAnimalTimeLinEventsTypeEnum;
  public daysLate?: number;
}

@Injectable({
  providedIn: 'root'
})
export class AnimalTimelineBreedingService {
  
  private readonly breedingTimelineColorsByStatus: Map<AnimalLactationStatus, string[]> = new Map([
    [AnimalLactationStatus.BullCalf, ['#6e6ea0', '#9b95c9', '#cdcdf1']],
    [AnimalLactationStatus.Calf, ['#6e6ea0', '#9b95c9', '#cdcdf1']],
    [AnimalLactationStatus.Heifer, ['#58595c', '#a8aaad', '#d3d4d6']],
    [AnimalLactationStatus.Fresh, ['#b14c9b', '#bc6fac', '#f4e6f4']],
    [AnimalLactationStatus.Ready, ['#007d79', '#56c3b8', '#99dbd5']],
    [AnimalLactationStatus.Pregnant, ['#3c96dc', '#38afe7', '#cfe2f4']],
    [AnimalLactationStatus.Open, ['#a41e22', '#cf3a3e', '#ffbab2']],
    [AnimalLactationStatus.HeiferReady, ['#007d79', '#56c3b8', '#99dbd5']],
    [AnimalLactationStatus.HeiferInseminated, ['#93c83d', '#b6da63', '#e5edd8']],
    [AnimalLactationStatus.Inseminated, ['#93c83d', '#b6da63', '#e5edd8']],
    [AnimalLactationStatus.HeiferOpen, ['#a41e22', '#cf3a3e', '#ffbab2']],
    [AnimalLactationStatus.HeiferPregnant, ['#3c96dc', '#38afe7', '#cfe2f4']],
    [AnimalLactationStatus.Dry, ['#643e17', '#b0732a', '#e6b45a']],
    [AnimalLactationStatus.None, ['#9fa1a1', '#9fa1a1', '#c5c6c6']]
  ]);
  
  private static readonly AnimalEventsTimelineRoute = '/rest/api/animals/{animalId}/details?projection=eventsTimeline';
  
  constructor(private readonly restApiService: RestApiService,
              private readonly authService: AuthService,
              private readonly configService: ConfigService,
              private readonly epochDateTimePipe: EpochDateTimePipe,
              private readonly translationService: TranslationService) {
    
  }
  
  public async getAnimalTimeline(animalId: number): Promise<AnimalTimelineFlowModel>
  {
    let response = await this.getAnimalEventsTimeline(animalId);
    if(response.responseBody == null) {
      return null;
    }
    return this.transformToFlowModel(response.responseBody);
  }
  
  private getAnimalEventsTimeline(animalId: number) : Promise<HttpResponseResult<IBreedingAnimalEventsTimelineData>> {
    let route = AnimalTimelineBreedingService.AnimalEventsTimelineRoute.replace('{animalId}', animalId.toString());
    return this.restApiService.sendGetMethod<IBreedingAnimalEventsTimelineData>(route, this.authService.currentUserAuthState.authDetails);
  }
  
  private transformToFlowModel(animalEventsTimelineData: IBreedingAnimalEventsTimelineData) : AnimalTimelineFlowModel {
    let timelineFlow = new AnimalTimelineFlowModel();
    timelineFlow.header = this.getAnimalTimelineHeader(animalEventsTimelineData);
    
    let startDateLocalTime = AnimalTimelineUtils.convertDateTimeToLocalDate(animalEventsTimelineData.startDate);
    
    timelineFlow.nodes.push({
      nodeBackground : this.breedingTimelineColorsByStatus.get(animalEventsTimelineData.lactationStatus)[1],
      detailsText: this.epochDateTimePipe.transform(startDateLocalTime, DateTimeFormatEnum.Date),
      iconClass: this.getFirstNodeIcon(animalEventsTimelineData),
      displayNumber: animalEventsTimelineData.lactationNumber,
      displayNumberColor: this.getTextColor(animalEventsTimelineData)
    });
    const now = new Date();
    const today = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).unix();
    let animalEventsSorted = this.getBreedingAnimalEventsSorted(animalEventsTimelineData, today);
    let isAfterToday = false;
    if(animalEventsSorted.length == 1) {
      let legDaysDuration = AnimalTimelineUtils.calculateDaysBetweenDates(animalEventsSorted[0].localDate, today);
      timelineFlow.legs.push({
        legParts: [{
          backgroundColor: this.breedingTimelineColorsByStatus.get(animalEventsTimelineData.lactationStatus)[1],
          legPartSizePercent: 100,
          innerText: legDaysDuration + ' ' + this.translationService.translate('ANIMAL.TIME_LINE.DAYS'),
          innerTextColor: this.getTextColor(animalEventsTimelineData)
        }],
        legSizePercent: 100
      });
      timelineFlow.nodes.push({
        nodeBackground: this.breedingTimelineColorsByStatus.get(animalEventsTimelineData.lactationStatus)[2],
        bottomText: this.translationService.translate('ANIMAL.TIME_LINE.TODAY'),
        detailsText: this.translationService.translate('ANIMAL.TIME_LINE.TODAY')
      });
    } else {
      let lastEvent = animalEventsSorted[animalEventsSorted.length - 1];
      let totalDaysDuration : number;
      if(lastEvent.localDate < today) {
        totalDaysDuration = AnimalTimelineUtils.calculateDaysBetweenDates(animalEventsSorted[0].localDate, today);
      }
      else {
        totalDaysDuration = AnimalTimelineUtils.calculateDaysBetweenDates(animalEventsSorted[0].localDate, lastEvent.localDate);
      }
      for (let animalEventIndex = 1; animalEventIndex< animalEventsSorted.length; animalEventIndex++) {
        let animalEvent = animalEventsSorted[animalEventIndex];
        let prevAnimalEvent = animalEventsSorted[animalEventIndex - 1];
        let legDaysDuration = AnimalTimelineUtils.calculateDaysBetweenDates(prevAnimalEvent.localDate, animalEvent.localDate);
        if(prevAnimalEvent.localDate <= today && animalEvent.localDate > today) {
          let prevLegToTodayDaysDuration = AnimalTimelineUtils.calculateDaysBetweenDates(prevAnimalEvent.localDate, today);
          let todayToCurrentLegDaysDuration = AnimalTimelineUtils.calculateDaysBetweenDates(today, animalEvent.localDate);
          timelineFlow.legs.push({
            legParts: [{innerText: prevLegToTodayDaysDuration + ' ' + this.translationService.translate('ANIMAL.TIME_LINE.DAYS'),
                        innerTextColor: this.getTextColor(animalEventsTimelineData),
                        legPartSizePercent: (prevLegToTodayDaysDuration / legDaysDuration) * 100,
                        backgroundColor: this.breedingTimelineColorsByStatus.get(animalEventsTimelineData.lactationStatus)[1],
                        bottomText: this.translationService.translate('ANIMAL.TIME_LINE.TODAY')},
                       {innerText: todayToCurrentLegDaysDuration + ' ' + this.translationService.translate('ANIMAL.TIME_LINE.DAYS'),
                        innerTextColor: this.getTextColor(animalEventsTimelineData),
                        legPartSizePercent: (todayToCurrentLegDaysDuration / legDaysDuration) * 100,
                        backgroundColor: this.breedingTimelineColorsByStatus.get(animalEventsTimelineData.lactationStatus)[2]}],
            legSizePercent: (legDaysDuration / totalDaysDuration) * 100
          });
          isAfterToday = true;
        }
        else {
          timelineFlow.legs.push({
            legParts: [{innerText: legDaysDuration + ' ' + this.translationService.translate('ANIMAL.TIME_LINE.DAYS'),
                        innerTextColor: this.getTextColor(animalEventsTimelineData),
                        legPartSizePercent: 100,
                        backgroundColor: this.breedingTimelineColorsByStatus.get(animalEventsTimelineData.lactationStatus)[this.getColoringIndexByIsAfterToday(isAfterToday)]}],
                        legSizePercent: (legDaysDuration / totalDaysDuration) * 100
          });
        }
        timelineFlow.nodes.push({
          nodeBackground: this.breedingTimelineColorsByStatus.get(animalEventsTimelineData.lactationStatus)[this.getColoringIndexByIsAfterToday(isAfterToday)],
          detailsText: this.epochDateTimePipe.transform(animalEvent.localDate, DateTimeFormatEnum.Date),
          iconClass: this.getMiddleNodeIcon(animalEventsTimelineData, animalEvent.eventType, isAfterToday),
          bottomTextRed: animalEvent.daysLate != null ? animalEvent.daysLate + ' ' + this.translationService.translate('ANIMAL.TIME_LINE.ERROR.LATE') : null,
          displayNumber: (animalEvent.eventType == BreedingAnimalTimeLinEventsTypeEnum.Breeding &&
                          animalEventsTimelineData.breedingNumber > 0) ? animalEventsTimelineData.breedingNumber : null,
          displayNumberColor: this.getTextColor(animalEventsTimelineData)
        });
      }
      if(lastEvent.localDate < today) {
        let legDaysDuration = AnimalTimelineUtils.calculateDaysBetweenDates(lastEvent.localDate, today);
        timelineFlow.legs.push({
          legParts: [{innerText: legDaysDuration + ' ' + this.translationService.translate('ANIMAL.TIME_LINE.DAYS'),
                      innerTextColor: this.getTextColor(animalEventsTimelineData),
                      legPartSizePercent: 100,
                      backgroundColor: this.breedingTimelineColorsByStatus.get(animalEventsTimelineData.lactationStatus)[this.getColoringIndexByIsAfterToday(isAfterToday)]}],
          legSizePercent: (legDaysDuration / totalDaysDuration) * 100
        });
        timelineFlow.nodes.push({
          nodeBackground: this.breedingTimelineColorsByStatus.get(animalEventsTimelineData.lactationStatus)[2],
          bottomText: this.translationService.translate('ANIMAL.TIME_LINE.TODAY'),
          detailsText: this.translationService.translate('ANIMAL.TIME_LINE.TODAY')
        });
      }
    }
    return timelineFlow;
  }
  
  private getColoringIndexByIsAfterToday(isAfterToday: boolean) {
    return isAfterToday ? 2 : 1;
  }
  
  private getFirstNodeIcon(animalEventsTimelineData: IBreedingAnimalEventsTimelineData) : string {
    if(!animalEventsTimelineData.lactationNumber){
      return this.useWhiteColor(animalEventsTimelineData) ? 'icon-birth-white' : 'icon-birth';
    } else {
      return this.useWhiteColor(animalEventsTimelineData) ? 'icon-calving-white' : 'icon-calving';
    }
  }
  
  private getMiddleNodeIcon(animalEventsTimelineData: IBreedingAnimalEventsTimelineData,
                            eventType: BreedingAnimalTimeLinEventsTypeEnum,
                            isAfterToday: boolean) : string {
    switch (eventType) {
      case BreedingAnimalTimeLinEventsTypeEnum.Breeding: {
        if(animalEventsTimelineData.lactationStatus == AnimalLactationStatus.Open ||
           animalEventsTimelineData.lactationStatus == AnimalLactationStatus.HeiferOpen) {
          return 'icon-open-white';
        } else if(animalEventsTimelineData.lactationStatus == AnimalLactationStatus.Pregnant ||
                  animalEventsTimelineData.lactationStatus == AnimalLactationStatus.HeiferPregnant ||
                  animalEventsTimelineData.lactationStatus == AnimalLactationStatus.Dry) {
          return 'icon-effective-breeding';
        } else {
          return this.useWhiteColor(animalEventsTimelineData) ? 'icon-breeding-white' : 'icon-breeding';
        }
      }
      case BreedingAnimalTimeLinEventsTypeEnum.Calving: {
        if(isAfterToday) {
          return 'icon-calving-f-' + animalEventsTimelineData.lactationStatus.toString().toLowerCase();
        } else {
          return this.useWhiteColor(animalEventsTimelineData) ? 'icon-calving-white' : 'icon-calving';
        }
      }
      case BreedingAnimalTimeLinEventsTypeEnum.DryOff: {
        if(isAfterToday) {
          return 'icon-dry-off-f-' + animalEventsTimelineData.lactationStatus.toString().toLowerCase();
        } else {
          return this.useWhiteColor(animalEventsTimelineData) ? 'icon-dry-off-white' : 'icon-dry-off';
        }
      }
    }
    return null;
  }
  
  private useWhiteColor(animalEventsTimelineData: IBreedingAnimalEventsTimelineData) : boolean {
    return animalEventsTimelineData.lactationStatus == AnimalLactationStatus.Open ||
           animalEventsTimelineData.lactationStatus == AnimalLactationStatus.HeiferOpen ||
           animalEventsTimelineData.lactationStatus == AnimalLactationStatus.Fresh;
  }
  
  private getTextColor(animalEventsTimelineData: IBreedingAnimalEventsTimelineData) : string {
    return this.useWhiteColor(animalEventsTimelineData) ? '#fff' : '#000';
  }
  
  private getBreedingAnimalEventsSorted(animalEventsTimelineData: IBreedingAnimalEventsTimelineData, today: number) : BreedingAnimalTimeLineEvent[] {
    let events:BreedingAnimalTimeLineEvent[] = [];
    events.push({dateTimeServer: animalEventsTimelineData.startDate,
                 localDate: AnimalTimelineUtils.convertDateTimeToLocalDate(animalEventsTimelineData.startDate),
                 eventType:BreedingAnimalTimeLinEventsTypeEnum.Start});
    if(animalEventsTimelineData.breedingDate != null) {
      events.push({dateTimeServer: animalEventsTimelineData.breedingDate,
                   localDate: AnimalTimelineUtils.convertDateTimeToLocalDate(animalEventsTimelineData.breedingDate),
                   eventType:BreedingAnimalTimeLinEventsTypeEnum.Breeding});
    }
    if(animalEventsTimelineData.dryOffDate != null) {
      let dryOffTimelineEvent : BreedingAnimalTimeLineEvent = {dateTimeServer: animalEventsTimelineData.dryOffDate,
                                                               localDate: AnimalTimelineUtils.convertDateTimeToLocalDate(animalEventsTimelineData.dryOffDate),
                                                               eventType:BreedingAnimalTimeLinEventsTypeEnum.DryOff};
      if(animalEventsTimelineData.isExpectedDryOffPassed) {
        this.updateDaysLate(dryOffTimelineEvent, today);
      }
      events.push(dryOffTimelineEvent);
    }
    if(animalEventsTimelineData.expectedCalvingDate != null) {
      let calvingTimelineEvent : BreedingAnimalTimeLineEvent = {dateTimeServer: animalEventsTimelineData.expectedCalvingDate,
                                                                localDate: AnimalTimelineUtils.convertDateTimeToLocalDate(animalEventsTimelineData.expectedCalvingDate),
                                                                eventType:BreedingAnimalTimeLinEventsTypeEnum.Calving};
      this.updateDaysLate(calvingTimelineEvent, today);
      events.push(calvingTimelineEvent);
    }
    events.sort((a, b) => {
      return a.dateTimeServer < b.dateTimeServer ? -1 : 1;
    });
    return events;
  }
  
  private updateDaysLate(dryOffTimelineEvent: BreedingAnimalTimeLineEvent, today: number) {
    if (dryOffTimelineEvent.localDate < today) {
      dryOffTimelineEvent.daysLate = AnimalTimelineUtils.calculateDaysBetweenDates(dryOffTimelineEvent.localDate, today);
    }
  }
  
  private getAnimalTimelineHeader(animalEventsTimelineData: IBreedingAnimalEventsTimelineData) : AnimalTimelineHeader {
    let header = new AnimalTimelineHeader();
    header.color = this.breedingTimelineColorsByStatus.get(animalEventsTimelineData.lactationStatus)[0];
    header.badge = animalEventsTimelineData.animalBadge;
    header.animalStatus = animalEventsTimelineData.lactationStatus;
    if(animalEventsTimelineData.lactationNumber == 0) {
      header.animalAgeCaption = 'AGE'
    } else {
      header.animalAgeCaption = this.configService.serverConfig.farmMode == FarmMode.Beef ? 'DSLC' : 'DIM';
    }
    header.animalAgeValue = animalEventsTimelineData.dim;
    return header;
  }
}
