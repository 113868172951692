import {Directive, ElementRef, HostListener, Input} from '@angular/core';

export enum InputFilterMode {
  AlphaNumeric = 'AlphaNumeric',
  Numeric = 'Numeric',
}

@Directive({
  selector: '[inputFilter]'
})
export class InputFilterDirective {

  @Input()
  public inputFilterMode: InputFilterMode = InputFilterMode.AlphaNumeric;

  @Input()
  public inputFilterMaxLength: number = 0;

  constructor(private el: ElementRef) {

  }

  @HostListener('input')
  public onChange() {
    if(this.inputFilterMode == InputFilterMode.AlphaNumeric) {

      this.removeForbiddenCharactersByRegExp(/\W/g);

    } else if (this.inputFilterMode == InputFilterMode.Numeric) {

      this.removeForbiddenCharactersByRegExp(/[^0-9]/g);

    }
    if(this.inputFilterMaxLength > 0) {

      if((this.el.nativeElement as HTMLInputElement).value.length > this.inputFilterMaxLength) {
        (this.el.nativeElement as HTMLInputElement).value = (this.el.nativeElement as HTMLInputElement).value.substr(0, this.inputFilterMaxLength);
      }

    }
  }

  private removeForbiddenCharactersByRegExp(forbiddenCharactersRegExp: RegExp): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    inputElement.value = inputElement.value.replace(forbiddenCharactersRegExp, '');
  }
}
