<regular-edit-dialog-template [modalWidth]="368"
                              [isOpen]="isOpen"
                              [enableRemoveButton]="false"
                              [shIdPostfix]="'kpi_select'"
                              (onCloseClick)="closeClick()"
                              (onLeftButtonClick)="closeClick()"
                              (onRightButtonClick)="applyClick()"
                              (onEnterButtonClick)="applyClick()">
  <ng-container header-caption>
    {{'DASHBOARD.KPI.NEW_KPI' | translate}}
  </ng-container>
  <ng-container body>
    <div class="available-kpi-container" *ngIf="model">
      <div class="available-kpi-item" *ngFor="let kpi of model.editAvailableKpi">
        <div class="kpi-selected-icon" *ngIf="kpi.isSelected" (click)="unselectKpi(kpi)"></div>
        <div class="kpi-unselected-icon" *ngIf="!kpi.isSelected" (click)="selectKpi(kpi)"></div>
        <div class="icon" [ngClass]="dashboardKpiService.getIconAccordingToKpiApplicationPlan(kpi.category)"></div>
        <div class="text" [attr.sh-id]='"span-kpi-"+kpi.name'>{{ 'DASHBOARD.KPI.' + kpi.name | translate }}</div>
      </div>
    </div>
  </ng-container>
  <ng-container left-button>
    <span>{{ 'COMMON.BUTTON.CANCEL' | translate}}</span>
  </ng-container>
  <ng-container right-button>
    <span>{{ 'COMMON.BUTTON.APPLY' | translate}}</span>
  </ng-container>
</regular-edit-dialog-template>
