<div class="led-tasks-page-wrapper">
  <div class="tasks-header-wrapper" [attr.sh-id]="'led-tasks-header'">
    <div class="header">
      <div class="title" [attr.translation-id]="'LED_TASKS.TASK_EDITOR.LED_TASKS'">
        {{ 'LED_TASKS.TASK_EDITOR.LED_TASKS' | translate }}
      </div>
      <div class="description" [attr.translation-id]="'LED_TASKS.TASK_EDITOR.DESCRIPTION'">
        {{ 'LED_TASKS.TASK_EDITOR.DESCRIPTION' | translate }}
      </div>
    </div>
    <div class="add-button" [attr.sh-id]="'add-led-task-button'" (click)="addLedTask()" nonExternalUser>
      <div class="add-icon"></div>
    </div>
  </div>
  <div class="led-tasks-grid">

    <ng-template #ledTasksColumns let-column="column">
      <ng-container [ngSwitch]="column">
        <span *ngSwitchCase="LedTasksColumns.reason" [attr.translation-id]="'LED_TASKS.GRID.Reason'">{{'LED_TASKS.GRID.Reason' | translate}}</span>
        <span *ngSwitchCase="LedTasksColumns.time" [attr.translation-id]="'LED_TASKS.GRID.Time'">{{'LED_TASKS.GRID.Time' | translate}}</span>
        <span *ngSwitchCase="LedTasksColumns.effectiveGroupsAnimals" [attr.translation-id]="'LED_TASKS.GRID.EFFECTIVE_GROUPS'">{{'LED_TASKS.GRID.EFFECTIVE_GROUPS' | translate}}</span>
        <span *ngSwitchCase="LedTasksColumns.repeats" [attr.translation-id]="'LED_TASKS.GRID.REPEATS'">{{'LED_TASKS.GRID.REPEATS' | translate}}</span>
        <span *ngSwitchCase="LedTasksColumns.actions"></span>
      </ng-container>
    </ng-template>

    <ng-template #ledTasksRows let-column="column" let-row="row">
      <ng-container [ngSwitch]="row.type">
        <report-row *ngSwitchCase="discoverItems.Reports"
                    [column]="column"
                    [row]="row"
                    [groupsList]="groupsList"
                    [gridViewState]="getGridViewState()"
                    [ledTasksDataGrid]="ledTasksDataGrid"
                    (onRowAction)="handleRowActionWrapper($event)"
                    [rowActionsMap]="rowActionsMap"></report-row>
        <animal-row *ngSwitchCase="discoverItems.Animals"
                    [column]="column"
                    [row]="row"
                    [animalsList]="animalsList"
                    [gridViewState]="getGridViewState()"
                    [ledTasksDataGrid]="ledTasksDataGrid"
                    (onRowAction)="handleRowActionWrapper($event)"
                    [rowActionsMap]="rowActionsMap"></animal-row>
      </ng-container>
    </ng-template>

    <data-grid
      #ledTasksDataGrid
      [rowHeight]="40"
      [headerHeight]="40"
      [totalRows]="ledTasksData.total"
      [sortedGridQuery]="ledTasksSortedGridQuery"
      [viewContext]="this"
      [isRowClickable]="false"
      [sortedGridColumn]="ledTasksColumns"
      [sortedGridCell]="ledTasksRows"
      [rowsData]="ledTasksData.result"
      [columnsMappings]="columnsMappings"></data-grid>
  </div>
</div>
<confirm-delete-dialog [isOpen]="isDeleteDialogShown"
                       [headerText]="'LED_TASKS.TASK_EDITOR.DELETE_DIALOG.DELETE_A_LED_TASK'"
                       [primaryText]="'LED_TASKS.TASK_EDITOR.DELETE_DIALOG.ABOUT_TO_DELETE_LED_TASK'"
                       [secondaryText]="'LED_TASKS.TASK_EDITOR.DELETE_DIALOG.THIS_CANNOT_BE_UNDONE'"
                       (onDelete)="deleteTask()"
                       (onCancel)="closeDeleteConfirmationDialog()">
</confirm-delete-dialog>
