import {Component, Input} from '@angular/core';
import {IFarmStatisticsFinishingWidgetContent} from '../../../../../../../services/dashboard/model/farm-statistics-finishing';

@Component({
  selector: 'farm-statistics-finishing-header',
  templateUrl: './farm-statistics-finishing-header.component.html',
  styleUrls: ['./farm-statistics-finishing-header.component.scss']
})
export class FarmStatisticsFinishingHeaderComponent {

  @Input()
  public farmStatistics: IFarmStatisticsFinishingWidgetContent;

  public get totalsCaption() : string {
    return (this.farmStatistics.totalFemales + this.farmStatistics.totalMales).toString();
  }
}
