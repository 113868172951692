import {Component, Input, OnInit} from '@angular/core';
import {AnimalTimelineLeg} from '../../../../../services/animal-timeline/model/animal-timeline-model';

@Component({
  selector: 'animal-card-timeline-leg',
  templateUrl: './animal-card-timeline-leg.component.html',
  styleUrls: ['./animal-card-timeline-leg.component.scss']
})
export class AnimalCardTimelineLegComponent implements OnInit {
  
  @Input()
  public legDetails: AnimalTimelineLeg;
  
  public constructor() { }

  public ngOnInit() {
  
  }

}
