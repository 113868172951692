import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PrintHtmlService} from '../../../services/export/print-html.service';
import {CsvExportService} from '../../../services/export/csv-export.service';
import {Subscription} from 'rxjs';
import {ClickPressEventsService} from '../../../services/click-press-events/click-press-events.service';
import {filter, tap} from 'rxjs/operators';

export interface IPrintExportDataSource {

  exportTitle: string;

  filePartName: string;

  exportedColumns : ExportedColumnDescription[];

  // tslint:disable-next-line:no-any
  getDataToExport() : Promise<any[]>;

  transformColumnToPresentable(column:string) : string;

  // tslint:disable-next-line:no-any
  transformFieldToPresentable(column:string, row:any) : string;

  onAdditionalActionClick(actionKey:string, event:Event);
}

export class ExportedColumnDescription {
  public name:string;
  public widthCss:string;
}

export class GridActionsDropdownAdditionalAction {
  public key:string;
  public displayValue;
}

@Component({
  selector: 'grid-actions-dropdown',
  templateUrl: './grid-actions-dropdown.component.html',
  styleUrls: ['./grid-actions-dropdown.component.scss']
})
export class GridActionsDropdownComponent implements OnInit, OnDestroy {

  @ViewChild('printTemplate')
  private printTemplate: ElementRef;

  @Input()
  public dataSource: IPrintExportDataSource;

  @Input()
  public isActionsOnLeftSide?: boolean = false;

  @Input()
  public prefixShId: string = null;

  @Input()
  public additionalActions: GridActionsDropdownAdditionalAction[] = [];

  // tslint:disable-next-line:no-any
  public htmlPrintableRows: any[];

  public isOpen: boolean = false;

  public isShowExportToFilePopup: boolean = false;

  private subscription: Subscription;

  constructor(private printHtmlService: PrintHtmlService,
              private csvExportService: CsvExportService,
              private readonly clickPressEventsService: ClickPressEventsService) {

  }

  public ngOnInit() {
    this.subscription = this.clickPressEventsService.keydownEscape
      .pipe(
        filter(() => this.isOpen),
        tap(() => this.close()),
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async print(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.htmlPrintableRows = await this.dataSource.getDataToExport();
    setTimeout(() =>{
      let printHtml = this.printTemplate.nativeElement.outerHTML;
      this.printHtmlService.printHTML(printHtml);
      this.htmlPrintableRows = null;
    });
    this.isOpen = false;
  }

  public async exportToFile(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.isOpen = false;
    this.isShowExportToFilePopup = true;
  }

  public close() {
    this.isOpen = false;
    this.isShowExportToFilePopup = false;
  }

  public toggle() {
    if (this.isShowExportToFilePopup) {
      this.isShowExportToFilePopup = false;
      this.isOpen = false;
      return;
    }
    this.isOpen = !this.isOpen;
  }

  public goBackToActionsMenu(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.isOpen = true;
    this.isShowExportToFilePopup = false;
  }

  public async exportToCSVFile(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    let exportedData = await this.dataSource.getDataToExport();
    this.csvExportService.exportTableCsv(exportedData,
      this.dataSource.exportedColumns.map(value => value.name),
      this.dataSource.exportTitle,
      this.dataSource.filePartName,
      column => this.dataSource.transformColumnToPresentable(column),
      (column, row) => this.dataSource.transformFieldToPresentable(column, row));
    this.isOpen = false;
    this.isShowExportToFilePopup = false;
  }

  public additionalActionClick(actionKey:string, event:Event) {
    event.preventDefault();
    event.stopPropagation();
    this.isOpen = false;
    this.dataSource.onAdditionalActionClick(actionKey, event);
  }

  public isPopupsShown() {
    return (this.isOpen || this.isShowExportToFilePopup)
  }

  public getShId(prefixText: string) {
    if (this.prefixShId == null) {
      return prefixText;
    } else {
      return `${prefixText}-${this.prefixShId}`;
    }
  }
}
