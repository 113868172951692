<div #ngxDatatableContainer class="ngx-datatable-container">
  <ngx-datatable *ngIf="!initGrid"
    [columns]="columns"
    [columnMode]="'flex'"
    [rowClass]="getRowClass"
    [rows]="rows"
    [virtualization]="true"
    [rowHeight]="getRowHeight"
    [scrollbarV]="true"
    [attr.sh-id]="'reports-datatable'"
    (scroll)="onGridScroll()"
    [ngStyle]="{ 'width': datatableContainerWidthPixels + 'px',
                 'height': datatableContainerHeightPixels + 'px',
                 'position': 'absolute'}"
    class="dashboard-report">
  </ngx-datatable>
</div>

<ng-template #invisibleHeader let-column="column"></ng-template>

<ng-template #animalIdTemplate let-column="column" let-row="row" let-value="value">
  <div class="dashboard-report-cell" [ngClass]="{'expanded' : row.rowExpanded,
                                                 'first': isFirstColumn(column),
                                                 'last': isLastColumn(column)}">
    <accordion-report-sizable-cell (onClick)="onReportGridValueCellClick(row)"
                                   [cellShId]="'dashboard_' + report.meta.reportName + '_' + row.CowDatabaseIDCalculation"
                                   [hasDnbBadge]="row.DNBCalculation == true"
                                   [title]="row.AnimalIDCalculation"></accordion-report-sizable-cell>
  </div>
</ng-template>

<ng-template #groupNameTemplate let-column="column" let-row="row" let-value="value">
  <div class="dashboard-report-cell" [ngClass]="{'expanded' : row.rowExpanded,
                                                 'first': isFirstColumn(column),
                                                 'last': isLastColumn(column)}">
    <accordion-report-sizable-cell (onClick)="onReportGridValueCellClick(row)"
                                   [cellShId]="'dashboard_' + report.meta.reportName + '_' + row[column.details.key]"
                                   [title]="row[column.details.key]"></accordion-report-sizable-cell>
  </div>
</ng-template>

<ng-template #tagNumberTemplate let-column="column" let-row="row" let-value="value">
  <div class="dashboard-report-cell" [ngClass]="{'expanded' : row.rowExpanded,
                                                 'first': isFirstColumn(column),
                                                 'last': isLastColumn(column)}">
    <accordion-report-sizable-cell (onClick)="onReportGridValueCellClick(row)"
                                   [cellShId]="'dashboard_' + report.meta.reportName + '_' + row[column.details.key]"
                                   [title]="row[column.details.key]"></accordion-report-sizable-cell>
  </div>
</ng-template>

<ng-template #plainTemplate let-column="column" let-row="row" let-value="value">
  <div class="dashboard-report-cell" [ngClass]="{'expanded' : row.rowExpanded,
                                                 'first': isFirstColumn(column),
                                                 'last': isLastColumn(column)}">
    <div class="data-cell">
      <div class="title">
        <ellipsisable-text class="title-text" [attr.translation-id]="'REPORTS.GRID.' + column.details.name">{{ 'REPORTS.GRID.' + column.details.name | translate}}</ellipsisable-text>
      </div>
      <div class="text">
        <div class="plain-detail" [ngSwitch]="column.details.key">
          <div *ngSwitchCase="'SyncStatusResultsCalculation'">
            {{reportsService.parseSyncStatusResults(row[column.details.key])}}
          </div>
          <div *ngSwitchCase="'SyncStatusStatusCalculation'" [attr.sh-id]="'SyncStatusStatusCalculation-' + row[column.details.key]">
            {{'SYSTEM.MANAGEMENT.GRID.' + row[column.details.key] | translate}}
          </div>
          <div *ngSwitchCase="'SyncStatusDurationCalculation'">
            {{reportsService.minToMs(row[column.details.key])}}
          </div>
          <ellipsisable-text *ngSwitchDefault class="ellipsisable">
            {{ row[column.details.key] }}
          </ellipsisable-text>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #durationTemplate let-column="column" let-row="row" let-value="value">
  <div class="dashboard-report-cell" [ngClass]="{'expanded' : row.rowExpanded,
                                                 'first': isFirstColumn(column),
                                                 'last': isLastColumn(column)}">
    <div class="data-cell">
      <div class="title">
        <ellipsisable-text class="title-text" [attr.translation-id]="'REPORTS.GRID.' + column.details.name">{{ 'REPORTS.GRID.' + column.details.name | translate}}</ellipsisable-text>
      </div>
      <div class="text">{{ row[column.details.key] | reportDuration:report.meta.reportTime }}</div>
    </div>
  </div>
</ng-template>

<ng-template #hoursMinutesDurationTemplate let-column="column" let-row="row" let-value="value">
  <div class="dashboard-report-cell" [ngClass]="{'expanded' : row.rowExpanded,
                                                 'first': isFirstColumn(column),
                                                 'last': isLastColumn(column)}">
    <div class="data-cell">
      <div class="title">
        <ellipsisable-text class="title-text" [attr.translation-id]="'REPORTS.GRID.' + column.details.name">{{ 'REPORTS.GRID.' + column.details.name | translate}}</ellipsisable-text>
      </div>
      <div class="text">{{ row[column.details.key] | duration }}</div>
    </div>
  </div>
</ng-template>

<ng-template #trendTemplate let-column="column" let-row="row" let-value="value">
  <div class="dashboard-report-cell" [ngClass]="{'expanded' : row.rowExpanded,
                                                 'first': isFirstColumn(column),
                                                 'last': isLastColumn(column)}">
    <div class="data-cell">
      <div class="title">
        <ellipsisable-text class="title-text" [attr.translation-id]="'REPORTS.GRID.' + column.details.name">{{ 'REPORTS.GRID.' + column.details.name | translate}}</ellipsisable-text>
      </div>
      <div class="text">
        <div class="increase-icon" *ngIf="row[column.details.key] == PantingTrendType.increase"></div>
        <div class="decrease-icon" *ngIf="row[column.details.key] == PantingTrendType.decrease"></div>
        <div class="same-icon" *ngIf="row[column.details.key] == PantingTrendType.stayTheSame"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #percentageTemplate let-column="column" let-row="row" let-value="value">
  <div class="dashboard-report-cell" [ngClass]="{'expanded' : row.rowExpanded,
                                                 'first': isFirstColumn(column),
                                                 'last': isLastColumn(column)}">
    <div class="data-cell">
      <div class="title">
        <ellipsisable-text class="title-text" [attr.translation-id]="'REPORTS.GRID.' + column.details.name">{{ 'REPORTS.GRID.' + column.details.name | translate}}</ellipsisable-text>
      </div>
      <div class="text">{{ row[column.details.key] ? row[column.details.key] + '%' : '' }}</div>
    </div>
  </div>
</ng-template>

<ng-template #localizationTemplate let-column="column" let-row="row" let-value="value">
  <div class="dashboard-report-cell" [ngClass]="{'expanded' : row.rowExpanded,
                                                 'first': isFirstColumn(column),
                                                 'last': isLastColumn(column)}">
    <div class="data-cell">
      <div class="title">
        <ellipsisable-text class="title-text" [attr.translation-id]="'REPORTS.GRID.' + column.details.name">{{ 'REPORTS.GRID.' + column.details.name | translate}}</ellipsisable-text>
      </div>
      <div class="text"  [attr.translation-id]="'REPORTS.GRID.STATUS.' + row[column.details.key]">{{ 'REPORTS.GRID.STATUS.' + row[column.details.key] | translate}}</div>
    </div>
  </div>
</ng-template>

<ng-template #dynamicLocalizationTemplate let-column="column" let-row="row" let-value="value">
  <div class="dashboard-report-cell" [ngClass]="{'expanded' : row.rowExpanded,
                                                 'first': isFirstColumn(column),
                                                 'last': isLastColumn(column)}">
    <div class="data-cell">
      <div class="title">
        <ellipsisable-text class="title-text" [attr.translation-id]="'REPORTS.GRID.' + column.details.name">{{ 'REPORTS.GRID.' + column.details.name | translate}}</ellipsisable-text>
      </div>
      <div class="text" *ngIf="row[column.details.key].isLocalization"  [attr.translation-id]="'REPORTS.GRID.STATUS.' + row[column.details.key].value">{{ 'REPORTS.GRID.STATUS.' + row[column.details.key].value | translate}}</div>
      <div class="text" *ngIf="!row[column.details.key].isLocalization">{{ row[column.details.key].value }}</div>
    </div>
  </div>
</ng-template>

<ng-template #booleanTemplate let-column="column" let-row="row" let-value="value">
  <div class="dashboard-report-cell" [ngClass]="{'expanded' : row.rowExpanded,
                                                 'first': isFirstColumn(column),
                                                 'last': isLastColumn(column)}">
    <div class="data-cell">
      <div class="title">
        <ellipsisable-text class="title-text" [attr.translation-id]="'REPORTS.GRID.' + column.details.name">{{ 'REPORTS.GRID.' + column.details.name | translate}}</ellipsisable-text>
      </div>
      <div class="text">{{ (row[column.details.key] === true || row[column.details.key] === 'true') ? 'Yes' : (row[column.details.key] === false || row[column.details.key] === 'false') ? 'No' : ''}}</div>
    </div>
  </div>
</ng-template>

<ng-template #dateFormatTemplate let-column="column" let-row="row" let-value="value">
  <div class="dashboard-report-cell" [ngClass]="{'expanded' : row.rowExpanded,
                                                 'first': isFirstColumn(column),
                                                 'last': isLastColumn(column)}">
    <div class="data-cell">
      <div class="title">
        <ellipsisable-text class="title-text" [attr.translation-id]="'REPORTS.GRID.' + column.details.name">{{ 'REPORTS.GRID.' + column.details.name | translate}}</ellipsisable-text>
      </div>
      <div class="text">
        <ng-template [ngIf]="isSyncStatusDateTimeCell(row, column)" [ngIfElse]="isNotSyncStatusDateTimeCell">
          {{ row[column.details.key] | epochDateTime:DateTimeFormatEnum.SyncStatusDateTime }}
        </ng-template>
        <ng-template #isNotSyncStatusDateTimeCell>
          <ng-container *ngIf="column.details.key === 'DistressStartDateAndTimeCalculation'">
            {{ row[column.details.key] == null ? '-' : row[column.details.key] | epochDateTime:DateTimeFormatEnum.DateTime }}
          </ng-container>
          <ng-container *ngIf="column.details.key != 'DistressStartDateAndTimeCalculation'">
            {{ row[column.details.key] | epochDateTime:DateTimeFormatEnum.DateTime }}
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #inspectReasonTemplate let-column="column" let-row="row" let-value="value">
  <div class="dashboard-report-cell" [ngClass]="{'expanded' : row.rowExpanded,
                                                 'first': isFirstColumn(column),
                                                 'last': isLastColumn(column)}">
    <div class="data-cell">
      <div class="title">
        <ellipsisable-text class="title-text" [attr.translation-id]="'REPORTS.GRID.ANIMALS_TO_INSPECT_REASONS'">{{ 'REPORTS.GRID.ANIMALS_TO_INSPECT_REASONS' | translate}}</ellipsisable-text>
      </div>
      <ellipsisable-text class="text ellipsisable" [attr.translation-id]="'REPORTS.GRID.' + row.AnimalsToInspectReasonsCalculation.mostImportantReport ">
        {{ 'REPORTS.GRID.' + row.AnimalsToInspectReasonsCalculation.mostImportantReport | translate }}
      </ellipsisable-text>
    </div>
  </div>
</ng-template>

<ng-template #lactationStatusTemplate let-column="column" let-row="row" let-value="value">
  <div class="dashboard-report-cell" [ngClass]="{'expanded' : row.rowExpanded,
                                                 'first': isFirstColumn(column),
                                                 'last': isLastColumn(column)}">
    <div class="data-cell">
      <animal-lifecycle-status-icon [status]="row.LactationStatusCalculation"></animal-lifecycle-status-icon>
    </div>
  </div>
</ng-template>

<ng-template #breedingWindowTemplate let-column="column" let-row="row" let-value="value">
  <div class="dashboard-report-cell" [ngClass]="{'expanded' : row.rowExpanded,
                                                 'first': isFirstColumn(column),
                                                 'last': isLastColumn(column)}">
    <div class="data-cell data-cell-breeding">
      <div class="header">
        <div class="title" [attr.translation-id]="'REPORTS.GRID.INSEM_WINDOW'">{{ 'REPORTS.GRID.INSEM_WINDOW' | translate}}</div>
        <div class="zoom-icon-container">
          <div class="zoom-icon" (click)="toggleRowExpanded(row)"></div>
        </div>
      </div>
      <breeding-window [inseminationWindowCalculation]="row.InseminationWindowCalculation" [isExpanded]="row.rowExpanded"></breeding-window>
    </div>
  </div>
</ng-template>
