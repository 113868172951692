import {Component, Input, OnInit} from '@angular/core';
import {PasswordValidationResult} from 'src/app/services/auth/auth-local-validator.service';

@Component({
  selector: 'password-validate',
  templateUrl: './password-validate.component.html',
  styleUrls: ['./password-validate.component.scss']
})
export class PasswordValidateComponent implements OnInit {

  @Input()
  public passwordValidationResult: PasswordValidationResult;

  @Input()
  public validationPopupPosition: PasswordValidationPopupPosition = PasswordValidationPopupPosition.registration;

  constructor() { }

  public ngOnInit() {
  }
}
export enum PasswordValidationPopupPosition {
  registration = 'registration',
  users = 'users'
}
