<form class="sign-eula-form" name="signEulaForm" ngNativeValidate autocomplete="off" (ngSubmit)="submitTermsAndPolicyAndContinueToDashboard()" #signEulaForm="ngForm">
  <div class="content-wrapper">
    <div class="form-field">
      <div class="eula-header" translation-id="REGISTRATION.FLOW.STEP_5.HELLO">
        {{ 'REGISTRATION.FLOW.STEP_5.HELLO' | translate }} {{ signEulaModel.name }}
      </div>
    </div>
    <div class="form-field">
      <div class="cloud-connect"></div>
    </div>
    <div class="form-field">
      <div class="disclaimer-text" translation-id="LOGIN.SIGN_EULA.DISCLAIMER_NEW">
        {{'LOGIN.SIGN_EULA.DISCLAIMER_NEW' | translate}}
      </div>
    </div>
    <div class="form-field">
      <div class="checkbox-field">
        <image-checkbox [ngClass]="'image-checkbox'" [isChecked]="eulaSelection.isTermsOfUseSigned" (isCheckedChanged)="eulaSelection.isTermsOfUseSigned=$event" [attr.sh-id]="'checkbox_terms_and_conditions'"></image-checkbox>
        <label translation-id="REGISTRATION.FLOW.I_AGREE_TO">{{'REGISTRATION.FLOW.I_AGREE_TO' | translate}} <span translation-id="REGISTRATION.FLOW.STEP_5.SCR_TERMS_OF_USE" (click)="readTermsAndPolicy(SignatureType.termsOfUse)">{{'REGISTRATION.FLOW.STEP_5.SCR_TERMS_OF_USE' | translate}}</span></label>
      </div>
    </div>
    <div class="form-field">
      <div class="checkbox-field">
        <image-checkbox [ngClass]="'image-checkbox'" [isChecked]="eulaSelection.isPrivacyPolicySigned" (isCheckedChanged)="eulaSelection.isPrivacyPolicySigned=$event" [attr.sh-id]="'checkbox_privacy_policy'"></image-checkbox>
        <label translation-id="REGISTRATION.FLOW.I_HAVE_READ_THE">{{'REGISTRATION.FLOW.I_HAVE_READ_THE' | translate}} <span translation-id="REGISTRATION.FLOW.STEP_5.SCR_PRIVACY_POLICY" (click)="readTermsAndPolicy(SignatureType.privacyPolicy)">{{'REGISTRATION.FLOW.STEP_5.SCR_PRIVACY_POLICY' | translate}}</span></label>
      </div>
    </div>
    <div class="form-field">
      <div class="checkbox-field">
        <image-checkbox [ngClass]="'image-checkbox'" [isChecked]="eulaSelection.isLicenseAgreementSigned" (isCheckedChanged)="eulaSelection.isLicenseAgreementSigned=$event" [attr.sh-id]="'checkbox_license_agreement'"></image-checkbox>
        <label translation-id="REGISTRATION.FLOW.I_AGREE_TO">{{'REGISTRATION.FLOW.I_AGREE_TO' | translate}} <span translation-id="REGISTRATION.FLOW.STEP_5.SCR_LICENSE_AGREEMENT" (click)="readTermsAndPolicy(SignatureType.licenseAgreement)">{{'REGISTRATION.FLOW.STEP_5.SCR_LICENSE_AGREEMENT' | translate}}</span></label>
      </div>
    </div>
  </div>
  <div class="form-field form-field-btn">
    <input class="agree-and-continue-btn" [ngClass]="{ disabled: validateTermsAndConditions() }" [disabled]="validateTermsAndConditions()" type="submit" value="{{'REGISTRATION.FLOW.FINISH_BUTTON' | translate}}" translation-id="REGISTRATION.FLOW.FINISH_BUTTON" sh-id="sign_eula_agree_and_continue">
  </div>
</form>
