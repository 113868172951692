import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {EditBarService} from '../../../services/edit-bar/edit-bar.service';

export interface ISystemEditBarComponent {
  isSaveAvailable: boolean;
  isResetAvailable: boolean;
  save(): void;
  reset($event: Event): void;
}

@Component({
  selector: 'system-edit-bar',
  templateUrl: 'system-edit-bar.component.html',
  styleUrls: ['system-edit-bar.component.scss']
})
export class SystemEditBarComponent implements OnInit, OnDestroy, ISystemEditBarComponent {

  public isSaveAvailable: boolean = false;

  public isResetAvailable: boolean = false;

  @HostBinding('class.system-edit-bar-open')
  public get isSystemEditBarOpen(): boolean {
    return this.editBarVisibility;
  };

  private saveFn: (() => void)|null = null;

  private resetFn: (() => void)|null = null;

  constructor(private readonly editBarService: EditBarService) { }

  public ngOnInit() {
    this.editBarService.registerEditBarInstance(this);
  }

  public save(): void {
    if (!this.isSaveAvailable) {
      return;
    }
    this.saveFn();
  }

  public reset($event: Event): void {
    if (!this.isResetAvailable) {
      return;
    }
    this.resetFn();
  }

  public attachOnSaveClickCallback(onSaveFn: () => void): void {
    this.isSaveAvailable = true;
    this.saveFn = onSaveFn;
  }

  public removeOnSaveClickCallback(): void {
    this.isSaveAvailable = false;
    this.saveFn = null;
  }

  public attachOnResetClickCallback(onResetFn: () => void): void {
    this.isResetAvailable = true;
    this.resetFn = onResetFn;
  }

  public removeOnResetClickCallback(): void {
    this.isResetAvailable = false;
    this.resetFn = null;
  }

  public get editBarVisibility(): boolean {
    return this.isResetAvailable || this.isSaveAvailable;
  }

  public resetEditBar(): void {
    this.isSaveAvailable = false;
    this.isResetAvailable = false;
    this.saveFn = null;
    this.resetFn = null;
  }

  public ngOnDestroy(): void {
    this.resetEditBar();
    this.editBarService.unregisterEditBarInstance(this);
  }

}
