import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '../../utils/window-utils';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonUiService {

  public scrollSubject = new Subject<Event>();

  constructor(@Inject(WINDOW) private readonly _window: Window) {
    this._window.document.addEventListener('scroll', evt => this.onScroll(evt), {capture: true});
  }

  private onScroll(evt : Event) {
    this.scrollSubject.next(evt);
  }
}
