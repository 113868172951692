import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

import {ConfigService} from '../../../services/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class TestingGuard implements CanActivate {

  constructor(private router: Router,
              public configService: ConfigService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.configService.configuration.useMockHttp;
  }
}
