import {Component} from '@angular/core';
import {MockHttpService} from '../../services/mock-http.service';
import {Router} from '@angular/router';
import {RestApiResult} from '../../../services/rest-api/models/rest-api-result';
import {AnonymousAuthDetailsResult, SystemStateEnum} from '../../../services/config/model/anonymous-auth-config';
import {FarmMode, FinishingApplicationBundle, ServerConfig} from '../../../services/config/model/server-config';
import {FarmRole} from '../../../services/auth/model/farm-role.enum';
import {
  AboutModel,
  AboutModelPopulationPlanEnum,
  OrderedPopulationPlanModel,
  PopulationPlanName
} from "../../../services/system/model/about-model";

@Component({
  selector: 'mock-system-state',
  templateUrl: './mock-system-state.component.html',
  styleUrls: ['./mock-system-state.component.scss']
})
export class MockSystemStateComponent {

  public isApplying:boolean;

  public selectedUserPermission: FarmRole;

  public farmRoles: FarmRole[] = [
    FarmRole.SuperUser,
    FarmRole.Admin,
    FarmRole.FarmManager,
    FarmRole.Farmer,
    FarmRole.ExternalUser,
  ];

  constructor(public mockHttpService:MockHttpService,
              private router: Router) { }

  public async resetStateToDefault() {
    this.isApplying = true;
    this.mockHttpService.handleMessageDelayMs = 0;
    await this.mockHttpService.resetTemplatesToDefault();
    this.isApplying = false;
    await this.router.navigate(['/testing-refresh-blank']);
    await this.router.navigate(['/']);
  }

  public async setRegistrationState() {
    this.isApplying = true;
    await this.mockHttpService.resetTemplatesToDefault();
    let authTemplate = await this.mockHttpService.getTemplate('/rest/api/v2/auth');
    let authDetailsResult: RestApiResult<AnonymousAuthDetailsResult> = authTemplate.messages[0].responseBody;
    authDetailsResult.result.systemState = SystemStateEnum.trial;
    authDetailsResult.result.trialMode.isTrial = true;
    authDetailsResult.result.trialMode.farmMode = FarmMode.Dairy;
    authDetailsResult.result.trialMode.daysToExpiration = 30;
    authTemplate.messages[0].responseBody = authDetailsResult;
    await this.mockHttpService.setTemplate(authTemplate);
    this.isApplying = false;
    await this.router.navigate(['/testing-refresh-blank']);
    await this.router.navigate(['/']);
  }

  public async setBeefBreeding() {
    this.isApplying = true;
    await this.mockHttpService.resetTemplatesToDefault();
    // set configuration API to beef finishing (co-exist)
    let configTemplate = await this.mockHttpService.getTemplate('/rest/api/v3/auth/configuration');
    let configurationTemplate: RestApiResult<ServerConfig> = configTemplate.messages[0].responseBody;
    configurationTemplate.result.farmMode = FarmMode.Beef;
    configurationTemplate.result.bizModeParameters.finishingApplicationBundle = FinishingApplicationBundle.None;
    configTemplate.messages[0].responseBody = configurationTemplate;
    await this.mockHttpService.setTemplate(configTemplate);
    this.isApplying = false;
    // reload enviroment as beef
    await this.router.navigate(['/testing-refresh-blank']);
    await this.router.navigate(['/']);
  }

  public async setBeefFinishing() {
    this.isApplying = true;
    await this.mockHttpService.resetTemplatesToDefault();
    // set configuration API to beef finishing (co-exist)
    let configTemplate = await this.mockHttpService.getTemplate('/rest/api/v3/auth/configuration');
    let configurationTemplate: RestApiResult<ServerConfig> = configTemplate.messages[0].responseBody;
    configurationTemplate.result.farmMode = FarmMode.Beef;
    configurationTemplate.result.bizModeParameters.finishingApplicationBundle = FinishingApplicationBundle.Finishing;
    configTemplate.messages[0].responseBody = configurationTemplate;
    await this.mockHttpService.setTemplate(configTemplate);
    const systemAboutTemplate = await this.mockHttpService.getTemplate('/rest/api/system/about');
    const systemAboutResponseResult: RestApiResult<AboutModel> = systemAboutTemplate.messages[0].responseBody;
    systemAboutResponseResult.result.orderedPopulationPlan = new OrderedPopulationPlanModel([
      {
        'plan': PopulationPlanName.Cows,
        'value': AboutModelPopulationPlanEnum.Premium
      },
      {
        'plan': PopulationPlanName.Heifers,
        'value': AboutModelPopulationPlanEnum.Premium
      },
      {
        'plan': PopulationPlanName.YoungStock,
        'value': AboutModelPopulationPlanEnum.None
      },
      {
        'plan': PopulationPlanName.Finishing,
        'value': AboutModelPopulationPlanEnum.Finishing
      }
    ]);
    systemAboutTemplate.messages[0].responseBody = systemAboutResponseResult;
    await this.mockHttpService.setTemplate(systemAboutTemplate);

    let searchTemplate = await this.mockHttpService.getTemplate('/rest/api/search');
    searchTemplate.messages[0].responseBody.result.push(
      {
        entityAlias: null,
        entityId: 17,
        entityName: "Finishing Default",
        entityRfidTagNumber: null,
        entityType: "group",
        operationType: "BeefFinishing"
      });
    searchTemplate.messages[0].responseBody.result.push(
      {
        entityAlias: null,
        entityId: 25,
        entityName: "555",
        entityRfidTagNumber: null,
        entityType: "group",
        operationType: "BeefFinishing"
      });
    searchTemplate.messages[0].responseBody.result.push(
      {
        entityAlias: null,
        entityId: 1,
        entityName: "123125412",
        entityRfidTagNumber: null,
        entityType: "cow",
        operationType: "BeefFinishing"
      }
    );
    await this.mockHttpService.setTemplate(searchTemplate);
    this.isApplying = false;
    // reload enviroment as beef
    await this.router.navigate(['/testing-refresh-blank']);
    await this.router.navigate(['/']);
  }

  public async changeFarmRole() {
    this.isApplying = true;
    let configTemplate = await this.mockHttpService.getTemplate('/rest/api/v3/auth/configuration');
    let configurationTemplate: RestApiResult<ServerConfig> = configTemplate.messages[0].responseBody;
    console.log(this.selectedUserPermission);
    configurationTemplate.result.user.farmRole = this.selectedUserPermission;
    configTemplate.messages[0].responseBody = configurationTemplate;
    await this.mockHttpService.setTemplate(configTemplate);
    this.isApplying = false;
    await this.router.navigate(['/testing-refresh-blank']);
    await this.router.navigate(['/']);
  }
}
