<div class="create-event-form">
  <div class="form-section-title">
    {{ 'ANIMAL.EVENTS.ADD_EVENT.CULLING_REASON' | translate }}
  </div>
  <div class="form-section-item">
    <forms-dropdown-list
      class="form__control"
      [iconTheme]="formsDLIconsThemes.Blue"
      [items]="cullingReasonDropdownItems"
      [(ngModel)]="cullingReason">
    </forms-dropdown-list>
  </div>
</div>
