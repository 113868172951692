<regular-edit-dialog-template *ngIf="model"
                              [modalWidth]="555"
                              [isOpen]="isOpen"
                              [enableRemoveButton]="enableRemoveButton"
                              (onCloseClick)="handleClose()"
                              (onEnterButtonClick)="handleApply()"
                              (onRemoveClick)="handleRemoveNewborn()"
                              (onLeftButtonClick)="handleClose()"
                              (onRightButtonClick)="handleApply()">
  <ng-container header-caption>
    {{ 'ANIMAL.EVENTS.ADD_EVENT.NEW_BORN' | translate }}
  </ng-container>
  <ng-container body *ngIf="model">
    <div class="form-group">
      <div class="form-group-label" [attr.translation-id]="'ANIMAL.DETAILS.INFO.SEX'">{{ 'ANIMAL.DETAILS.INFO.SEX' | translate }}</div>
      <regular-dropdown-list [items]="availableSexDropdownListItems"
                             [selectedValue]="selectedSex"
                             [colorScheme]="dropdownColorScheme.whiteBlue"
                             [isFieldError]="isSexFieldError"
                             (onItemSelected)="onSexSelected($event)">
      </regular-dropdown-list>
    </div>

    <div class="form-group">
      <div class="form-group-label" [attr.translation-id]="'ANIMAL.EVENTS.ADD_EVENT.CONDITION'">{{ 'ANIMAL.EVENTS.ADD_EVENT.CONDITION' | translate }}</div>
      <regular-dropdown-list [items]="availableConditionsDropdownListItems"
                             [selectedValue]="selectedCondition"
                             [isFieldError]="isConditionFieldError"
                             [colorScheme]="dropdownColorScheme.whiteBlue"
                             (onItemSelected)="onConditionSelected($event)">
      </regular-dropdown-list>
    </div>

    <div class="form-group">
      <div class="form-group-label" [ngClass]="{'disabled': !enableDetails}" [attr.translation-id]="'ANIMAL.DETAILS.INFO.ANIMAL_ID'">{{ 'ANIMAL.DETAILS.INFO.ANIMAL_ID' | translate }}</div>
      <input type="text"
             name="animalName"
             class="form-control valid-borderless"
             [(ngModel)]="model.animalName"
             autocomplete="off"
             placeholder="{{ 'ANIMAL.EVENTS.ADD_EVENT.ANIMAL_ID' | translate }}"
             inputFilter
             [inputFilterMaxLength]="16"
             [ngClass]="{'invalid': isAnimalNameError}"
             [disabled]="!enableDetails"/>
    </div>

    <div class="form-group">
      <div class="form-group-label" [ngClass]="{'disabled': !enableDetails}" [attr.translation-id]="'ANIMAL.EVENTS.ADD_EVENT.DESTINATION_GROUP'">
        {{ 'ANIMAL.EVENTS.ADD_EVENT.DESTINATION_GROUP' | translate }}
      </div>
      <regular-dropdown-list [items]="destinationGroupsDropdownListItems"
                             [selectedValue]="selectedGroup"
                             [isFieldError]="isGroupError"
                             [colorScheme]="dropdownColorScheme.whiteBlue"
                             [disabled]="!enableDetails"
                             (onItemSelected)="onGroupSelected($event)">
      </regular-dropdown-list>
    </div>

    <div class="form-group">
      <div class="form-group-label" [ngClass]="{'disabled': !enableDetails}" [attr.translation-id]="'ANIMAL.EVENTS.ADD_EVENT.ORN'">{{ 'ANIMAL.EVENTS.ADD_EVENT.ORN' | translate }}</div>
      <input type="text"
             name="orn"
             class="form-control valid-borderless"
             autocomplete="off"
             [class.form-control--invalid]="errorFieldName === 'orn'"
             [(ngModel)]="model.orn"
             placeholder="{{ 'ANIMAL.EVENTS.ADD_EVENT.ORN' | translate }}"
             [disabled]="!enableDetails"/>
    </div>
  </ng-container>
  <ng-container left-button>
    <span>{{ 'COMMON.BUTTON.CANCEL' | translate}}</span>
  </ng-container>
  <ng-container right-button>
    <span>{{ 'COMMON.BUTTON.APPLY' | translate}}</span>
  </ng-container>
</regular-edit-dialog-template>
