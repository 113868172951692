<corner-container *ngIf="true"
                  [attr.sh-id]="'add_male_dialog'"
                  [cornerColor]="CornerColor.DeepBlue"
                  (onCornerButtonClick)="addMale()"
                  [footerHeight]="50"
                  [bodyBackground]="CornerContainerBodyBackground.LightGray">
  <ng-container container-body>
    <div class="add-animal-container">
      <div class="double-field-div">
        <div class="input-field">
          <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER'">
            {{ 'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER' | translate }}
          </div>
          <div class="value">
            <input class="input-class"
                   type="text"
                   [ngClass]="{ error: animalsService.hasErrorForField(errors, errorFields.animalName) }"
                   (ngModelChange)="clearErrors()"
                   [(ngModel)]="addAnimalModel.Number"
                   [attr.sh-id]="'breeding_add_male_animal_number'"
                   [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER'"
                   inputFilter
                   [inputFilterMaxLength]="16"
                   placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER' | translate }}" />
          </div>
        </div>
        <div class="input-field">
          <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'">
            {{ 'MANAGE.COWS.ACTIVE.WIZARD.GROUP' | translate }}
          </div>
          <div class="value">
            <forms-dropdown-list class="dropdown-list"
                                 [isFieldError]="animalsService.hasErrorForField(errors, errorFields.group)"
                                 [shIdPostfix]="'breeding_add_male_group'"
                                 [items]="groupsDropdownListItems"
                                 [autoSelectionMode]="dropdownListAutoSelectionMode.Single"
                                 placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.GROUP' | translate }}"
                                 [allowClear]="true"
                                 [translateListItem]="false"
                                 [ngModel]="addAnimalModel.Group"
                                 (ngModelChange)="onGroupSelected($event)"></forms-dropdown-list>
          </div>
        </div>
      </div>
      <div class="seperator"></div>
      <div class="fields-and-errors-div">
        <div class="single-field-div">
          <div class="input-field">
            <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.ORN'">
              {{ 'MANAGE.COWS.ACTIVE.WIZARD.ORN' | translate }}
            </div>
            <div class="value with-icon">
              <input class="input-class"
                     type="text"
                     [ngClass]="{ error: animalsService.hasErrorForField(errors, errorFields.orn)}"
                     #registrationNumberInput
                     (ngModelChange)="clearErrors()"
                     [attr.sh-id]="'breeding_add_male_orn'"
                     [(ngModel)]="addAnimalModel.RegistrationNumber"
                     [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL'"
                     placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL' | translate }}" />
              <div class="remove-btn-wrapper">
                <div class="remove-icon" (click)="onClearRegistrationNumber()"></div>
              </div>
            </div>
          </div>
          <div class="input-field">
            <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.RF_ID'">
              {{ 'MANAGE.COWS.ACTIVE.WIZARD.RF_ID' | translate }}
            </div>
            <div class="value">
              <regular-auto-complete [items]="rfidsTagsNumbers"
                                     [isFieldError]="animalsService.hasErrorForField(errors, errorFields.rfIdTagNumber)"
                                     [attr.sh-id]="'breeding_add_male_rf_id_tag_number'"
                                     [maxResults]="6"
                                     [searchQuery]="addAnimalModel?.EID?.tagNumber"
                                     [hasPermanentRemoveIcon]="true"
                                     [colorScheme]="autoCompleteColorScheme.WhiteBlack"
                                     (searchQueryChanged)="onRfIdTagChanged($event)"
                                     [shIdPostfix]="'breeding_add_male_rf_id_tag_number_auto_complete'"
                                     [placeholder]="'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL'"></regular-auto-complete>
            </div>
          </div>
          <div class="input-field" *ngIf="configService.serverConfig.farmMode == FarmMode.Dairy">
            <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.SCR_TAG'">
              {{ 'MANAGE.COWS.ACTIVE.WIZARD.SCR_TAG' | translate }}
            </div>
            <div class="value">
              <regular-auto-complete [isFieldError]="animalsService.hasErrorForField(errors, errorFields.scrTagNumber)"
                                     [items]="scrTagsNumbers"
                                     [maxResults]="6"
                                     [searchQuery]="addAnimalModel?.tagNumber?.tagNumber"
                                     [hasPermanentRemoveIcon]="true"
                                     [colorScheme]="autoCompleteColorScheme.WhiteBlack"
                                     (searchQueryChanged)="onScrTagChanged($event)"
                                     [shIdPostfix]="'breeding_add_male_scr_tag_number_auto_complete'"
                                     [placeholder]="'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL'"></regular-auto-complete>
            </div>
          </div>
        </div>
        <div class="single-field-div">
          <div class="input-field noPaddingTop">
            <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE'">
              {{ 'MANAGE.COWS.ACTIVE.WIZARD.BIRTH_DATE' | translate }}
            </div>
            <div class="value">
              <date-picker [isFieldError]="animalsService.hasErrorForField(errors, errorFields.birthDate)"
                           [calendarIconColor]="'#3B4570'"
                           [selectedEpoch]="addAnimalModel.birthdate"
                           [shownRemovableIcon]="false"
                           [shIdPostfix]="'breeding_add_male_birth_date'"
                           (epochChanged)="onDateChanged($event)"></date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container container-footer>
    <!-- Error container to display all errors for form -->
    <div class="errors-container">
      <div class="error-text" *ngIf="errors && errors[0]" [attr.sh-id]="'error-text-' + errors[0].key">
        <b>{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}: </b>{{ 'MANAGE.COWS.ACTIVE.WIZARD.ERRORS.' + errors[0].key | translate }}
      </div>
    </div>
    <!-- Buttons Container to contain all the actions of the container -->
    <div class="container-buttons">
      <div class="check-button" [attr.sh-id]="'apply_add_male'" (click)="addMale()">
        <div class="check-icon"></div>
      </div>
    </div>
  </ng-container>
</corner-container>
