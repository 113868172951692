import { Component, ChangeDetectionStrategy } from '@angular/core';
import {TurnLedLightingErrorDialogService} from "./turn-led-lighting-error-dialog.service";

@Component({
  selector: 'turn-led-lighting-error-dialog',
  styleUrls: ['./turn-led-lighting-error-dialog.component.scss'],
  templateUrl: './turn-led-lighting-error-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TurnLedLightingErrorDialogComponent {
  public showPopup$ = this.turnLedLightingErrorDialogService.showPopup$;

  constructor(
    public turnLedLightingErrorDialogService: TurnLedLightingErrorDialogService,
  ) {}

  public handleClose(): void {
    this.turnLedLightingErrorDialogService.hide();
  }
}
