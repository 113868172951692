import {Component, ElementRef, Input, OnChanges, QueryList, SimpleChanges, ViewChild, ViewChildren} from '@angular/core';
import {DescriptionCellComponent} from '../description-cell.component';
import {EntitiesDataRow} from '../../../../../services/model/common-model';
import {DescriptionCellEntity} from './model/description-cell-entity.model';
import * as _ from 'lodash';
import {CellEntityComponent} from './cell-entity/cell-entity.component';
import {UtilsService} from '../../../../../services/utils/utils.service';

@Component({
  selector: 'description-cell-entities',
  templateUrl: './description-cell-entities.component.html',
  styleUrls: ['./description-cell-entities.component.scss']
})
export class DescriptionCellEntitiesComponent extends DescriptionCellComponent<EntitiesDataRow> implements OnChanges  {

  @Input()
  public rowShId: string;

  @Input()
  public isDisabled: boolean;

  @Input()
  public entities: DescriptionCellEntity[];

  @Input()
  public noEntitiesText: string = '';

  @ViewChild('entities', {static: true})
  public entitiesElement : ElementRef<HTMLElement>;

  public entitiesToDisplay: DescriptionCellEntity[] = [];

  @ViewChildren(CellEntityComponent)
  public cellEntities : QueryList<CellEntityComponent>;

  public entitiesContainerWidth: number;

  private static readonly MagnifyingGlassWidth: number = 30;
  
  private static readonly ThreeDotsElementWidth: number = 12;

  constructor(public elementRef: ElementRef<HTMLElement>,
              public utilsService: UtilsService) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.entities != null &&
       changes.entities.previousValue != changes.entities.currentValue) {
      if(this.entitiesToDisplay.length != this.entities.length) {
        this.entitiesToDisplay = this.entities;
      } else {
        for (let i=0; i< this.entities.length; i++) {
          if(!_.isEqual(this.entitiesToDisplay[i], this.entities[i])) {
            this.entitiesToDisplay = this.entities;
            break;
          }
        }
      }
    }

    if(this.isExpanded && this.row != null) {
      this.row.gridViewState.setRowExpanded(this.row, {
        expandedHeight: this.getEntitiesContainerBoundingRect().height
      });
      this.onToggleExpanded.emit();
    }

    if(this.cellEntities == null) {
      return;
    }

    let elementsContainerBoundingRect = this.getEntitiesContainerBoundingRect();
    let cellEntitiesArray = this.cellEntities.map(item => item);

    if(cellEntitiesArray.find(value => !this.utilsService.isVisibleDomElement(value.elementRect)) != null) {
      return;
    }

    let shouldDisplayState: boolean = true;
    let cumulativeWidth: number = 0;
    for (let i=0; i < cellEntitiesArray.length; i++) {
      let elementRect = cellEntitiesArray[i].elementRect;
      if(this.isExpanded) {
        cellEntitiesArray[i].isVisible = true;
        cellEntitiesArray[i].displayThreeDots = false;
      } else {
        cumulativeWidth += elementRect.width;
        if (shouldDisplayState) {
          if (cumulativeWidth < elementsContainerBoundingRect.width - DescriptionCellEntitiesComponent.MagnifyingGlassWidth) {
            cellEntitiesArray[i].isVisible = true;
            cellEntitiesArray[i].displayThreeDots = false;
          } else {
            cellEntitiesArray[i].isVisible = false;
            cellEntitiesArray[i].displayThreeDots = true;
            shouldDisplayState = false;
          }
        } else if (i == 1 && cellEntitiesArray[0].elementRect.width < elementsContainerBoundingRect.width - DescriptionCellEntitiesComponent.ThreeDotsElementWidth) {
          cellEntitiesArray[i].isVisible = false;
          cellEntitiesArray[i].displayThreeDots = true;
        } else {
          cellEntitiesArray[i].isVisible = false;
          cellEntitiesArray[i].displayThreeDots = false;
        }
      }
    }

    this.entitiesContainerWidth = this.getEntitiesContainerBoundingRect().width;
    if (!this.isExpanded) {
      this.entitiesContainerWidth -= DescriptionCellEntitiesComponent.ThreeDotsElementWidth;
    }
  }

  private getEntitiesContainerBoundingRect() {
    return this.entitiesElement.nativeElement.getBoundingClientRect();
  }

  public get canExpand() : boolean {
    return this.hasEntities &&
      (this.isExpanded || (this.cellEntities != null &&
                           this.cellEntities.find(item => item.isVisible == false) != null));
  }

  public get hasEntities() : boolean {
    return this.entitiesToDisplay.length > 0;
  }

  public isLastEntity(entity:DescriptionCellEntity) : boolean {
    return this.entitiesToDisplay[this.entitiesToDisplay.length - 1] == entity;
  }
}
