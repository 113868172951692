import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as _ from 'lodash/fp';

@Component({
  selector: 'duration-horizontal-scroll',
  templateUrl: './duration-horizontal-scroll.component.html',
  styleUrls: ['./duration-horizontal-scroll.component.scss']
})
export class DurationHorizontalScrollComponent implements OnInit, AfterViewInit, OnChanges {

  @Input()
  public minText: string;

  @Input()
  public minValue: number;

  @Input()
  public interval: number = 1;

  @Input()
  public maxText: string;

  @Input()
  public maxValue: number;

  @Input()
  public currentValue: number;

  @Input()
  public shIdPostFix: string;

  @Output()
  public onCurrentValueChange: EventEmitter<number> = new EventEmitter<number>();

  private deltaValue: number;

  private valueBeforeMouseUp: number;

  @ViewChild('scrollBar')
  public scrollBar: ElementRef;

  @ViewChild('scrollThumb')
  public scrollThumb: ElementRef;

  private currentXPosition: number = 0;

  private maxXPosition: number;

  private isMouseDown: boolean = false;

  public deltaXPosition: number = 0;

  constructor() { }

  @HostListener('document:mousemove', ['$event'])
  public onMouseMove(event) {
    if (this.isMouseDown) {
      const delta: number = event.clientX - this.currentXPosition;
      this.applyDelta(delta);
    }
  }

  private applyDelta(delta: number): void {
    if (delta <= 0) {
      delta = 0;
    } else if (delta >= this.maxXPosition) {
      delta = this.maxXPosition;
    }
    this.deltaXPosition = delta;
    this.calculatePercentage();
  }

  public onScrollBarClick(event) {
    const delta: number = event.clientX - this.scrollBar.nativeElement.getBoundingClientRect().left;
    this.applyDelta(delta);
  }

  @HostListener('document:mouseup')
  public onMouseUp() {
    if (this.isMouseDown) {
      this.currentXPosition = this.deltaXPosition;
      this.onCurrentValueChange.emit(this.valueBeforeMouseUp);
    }
    this.isMouseDown = false;
  }

  public ngOnInit() {
    this.deltaValue = this.maxValue - this.minValue;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.isMouseDown) {
      if (this.currentValue == null || this.currentValue == this.minValue) {
        setTimeout(() => {
          let currentValue = (this.minValue + (this.currentValue * (this.currentValue / this.maxXPosition)));
          this.deltaXPosition = 0;
          this.onCurrentValueChange.emit(currentValue);
        }, 0);
      }
      if (changes.maxValue && changes.maxValue.currentValue !== changes.maxValue.previousValue && !changes.maxValue.firstChange) {
        if (this.currentValue > this.maxValue) {
          this.currentValue = _.cloneDeep(this.maxValue);
        }
        this.deltaValue = this.maxValue - this.minValue;
        this.deltaXPosition = ((this.currentValue - this.minValue) / this.deltaValue) * this.maxXPosition;
      }
    }
  }

  public ngAfterViewInit() {
    this.maxXPosition = this.scrollBar.nativeElement.offsetWidth;
    setTimeout(() => {
      if (this.currentValue) {
        this.deltaXPosition = ((this.currentValue - this.minValue) / this.deltaValue) * this.maxXPosition;
      } else {
        this.deltaXPosition = 0;
      }
    }, 0);
  }

  public onMouseDown(event) {
    event.preventDefault();
    this.isMouseDown = true;
    this.currentXPosition = event.clientX - this.deltaXPosition;
  }

  private calculatePercentage() {
    let percentage: number = (this.deltaXPosition / this.maxXPosition);
    if (this.interval > 1) {
      let currentValueWithoutInterval: number = this.minValue + (this.deltaValue * percentage);
      if (currentValueWithoutInterval === this.maxValue) {
        this.valueBeforeMouseUp = this.minValue + (this.deltaValue * percentage);
      } else {
        let numberOfIntervalsFromStart: number = Math.floor((currentValueWithoutInterval - this.minValue) / this.interval);
        this.valueBeforeMouseUp = this.minValue + Math.round(this.interval * numberOfIntervalsFromStart);
      }
    } else {
      this.valueBeforeMouseUp = this.minValue + (this.deltaValue * percentage);
    }
    this.onCurrentValueChange.emit(this.valueBeforeMouseUp);
  }
}
