import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'animal-ids',
  templateUrl: './animal-ids.component.html',
  styleUrls: ['./animal-ids.component.scss']
})
export class AnimalIdsComponent implements OnInit {

  @Input()
  public animalIds: string[];

  constructor() { }

  public ngOnInit() {
  }

}
