import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AnimalCardViewState} from '../../animal-card-model';
import {ChartDurationSelectionLabel, XyChartComponent} from '../../../../../common/components/xy-chart/xy-chart.component';
import {SeriesModel} from '@syncfusion/ej2-charts';
import {
  ChartLegendConfiguration,
  NamedChartLegendState,
  XyChartLegendComponent
} from '../../../../../common/components/xy-chart-legend/xy-chart-legend.component';
import {IAnimalChartData, IAnimalChartDataPoint, IAnimalChartSetupSeries} from '../../../../../services/animals/model/animal.model';
import {
  ChartConfiguration,
  GridLineIntervalEnum,
  XyChartCalculationService
} from '../../../../../services/charts/xy-chart-calculation.service';
import {ChartSeriesSelectionState} from '../../../../../common/components/chart-series-dropdown-list/chart-series-dropdown-list.component';
import {AnimalsService} from '../../../../../services/animals/animals.service';
import {ChartsService} from '../../../../../services/charts/charts.service';
import {GoogleAnalyticsService} from '../../../../../services/google-analytics/google-analytics.service';
import {ChartSetupProjection, IChartSeriesSetupRecord} from '../../../../../services/charts/model/chart.interface';
import {ChartResolution, IChartPoint} from '../../../../../services/model/charts/chart.interface';

@Component({
  selector: 'animal-card-health-breeding',
  templateUrl: './animal-card-health-breeding.component.html',
  styleUrls: ['./animal-card-health-breeding.component.scss']
})
export class AnimalCardHealthBreedingComponent implements OnChanges {

  @Input()
  public animalId: number;

  @Input()
  public viewState: AnimalCardViewState;

  @ViewChild('healthChart')
  public healthChart: XyChartComponent;

  @ViewChild('healthChartLegend')
  public healthChartLegend: XyChartLegendComponent;

  public configuration: ChartLegendConfiguration = {
    translationPrefix: 'ANIMAL.GRAPH.SERIES_TITLE.',
    valuePostfix: ''
  };

  private readonly healthChartId: number = 2;

  private animalChartHourlyData: IAnimalChartData;

  private animalChartDailyData: IAnimalChartData;

  public selectedDurationLabel: ChartDurationSelectionLabel;

  private optionsDurationLabels: ChartDurationSelectionLabel[] = [];

  private chartConfigurations: ChartConfiguration[] = [];

  public seriesSelections : ChartSeriesSelectionState[];

  public legendStates: NamedChartLegendState[];

  constructor(public animalsService: AnimalsService,
              private chartsService: ChartsService,
              private googleAnalyticsService: GoogleAnalyticsService,
              private xyChartCalculationService: XyChartCalculationService) { }

  public ngOnInit() {
  }

  public async ngOnChanges(changes: SimpleChanges){
    if(changes.viewState != null &&
      changes.viewState.previousValue != changes.viewState.currentValue &&
      this.viewState != null) {
      this.initConfig();
      if(this.viewState.healthChartSetup == null) {
        await this.getChartSetup(false);
      }
      this.initChartSeriesSelection();
      this.initChart(true);
    }
  }

  private async initChart(reloadData:boolean) {
    this.viewState.healthChartSelectedDuration  = this.optionsDurationLabels.indexOf(this.selectedDurationLabel);
    if (this.viewState.healthChartSelectedDuration < 6) {
      if (this.animalChartHourlyData == null ||
        reloadData) {
        let response = await this.animalsService.getAnimalChartData(this.animalId,
          this.healthChartId,
          ChartResolution.Hour,
          this.seriesSelections.some(value => value.name == 'events' && value.isSelected),
          this.seriesSelections.filter(value => value.name != 'events' &&
            value.isSelected)
            .map(value => value.name));
        if (response.status == 200) {
          this.animalChartHourlyData = response.responseBody;
          this.normalizeYoungStockHealthIndex(this.animalChartHourlyData.series);
        }
      }
      this.healthChart.initChart(this.getSeriesConfig(this.animalChartHourlyData.series, this.viewState.healthChartSelectedDuration),
        this.animalChartHourlyData.series,
        this.chartConfigurations[this.viewState.healthChartSelectedDuration],
        false,
        this.selectedDurationLabel,
        this.optionsDurationLabels,
        (this.viewState.healthChartSetup.events.isSelected &&
          this.viewState.healthChartSetup.events.isVisible) ? this.animalChartHourlyData.eventsSeries : null);
    } else {
      if (this.animalChartDailyData == null ||
        reloadData) {
        let response = await this.animalsService.getAnimalChartData(this.animalId,
          this.healthChartId,
          ChartResolution.Day,
          this.seriesSelections.some(value => value.name == 'events' && value.isSelected),
          this.seriesSelections.filter(value => value.name != 'events' &&
            value.isSelected)
            .map(value => value.name));
        if (response.status == 200) {
          this.animalChartDailyData = response.responseBody;
          this.normalizeYoungStockHealthIndex(this.animalChartDailyData.series);
        }
      }
      this.healthChart.initChart(this.getSeriesConfig(this.animalChartDailyData.series, this.viewState.healthChartSelectedDuration),
        this.animalChartDailyData.series,
        this.chartConfigurations[this.viewState.healthChartSelectedDuration],
        false,
        this.selectedDurationLabel,
        this.optionsDurationLabels,
        (this.viewState.healthChartSetup.events.isSelected &&
          this.viewState.healthChartSetup.events.isVisible) ? this.animalChartDailyData.eventsSeries : null);
    }
    this.initChartLegend(this.viewState.healthChartSetup.series, this.viewState.healthChartSelectedDuration);
  }

  private initConfig() {
    this.optionsDurationLabels.push({
      selectedDisplayText: '1 DAY',
      optionDisplayText: '1'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 1,
      gridLineInterval: GridLineIntervalEnum.Hour,
      visiblePeriodHours: 24,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 60
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '3 DAYS',
      optionDisplayText: '3'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 3,
      gridLineInterval: GridLineIntervalEnum.ThreeHours,
      visiblePeriodHours: 24 * 3,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 60
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '7 DAYS',
      optionDisplayText: '7'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 8,
      gridLineInterval: GridLineIntervalEnum.Day,
      visiblePeriodHours: 24 * 7,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 60
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '15 DAYS',
      optionDisplayText: '15'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 20,
      gridLineInterval: GridLineIntervalEnum.Day,
      visiblePeriodHours: 24 * 15,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 60
    }));


    this.optionsDurationLabels.push({
      selectedDisplayText: '30 DAYS',
      optionDisplayText: '30'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 37,
      gridLineInterval: GridLineIntervalEnum.Week,
      visiblePeriodHours: 24 * 30,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 60
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '60 DAYS',
      optionDisplayText: '60'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 3,
      gridLineInterval: GridLineIntervalEnum.Week,
      visiblePeriodHours: 24 * 60,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 60
    }));


    this.optionsDurationLabels.push({
      selectedDisplayText: '180 DAYS',
      optionDisplayText: '180'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 10,
      gridLineInterval: GridLineIntervalEnum.Month,
      visiblePeriodHours: 24 * 180,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 60
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '360 DAYS',
      optionDisplayText: '360'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 18,
      gridLineInterval: GridLineIntervalEnum.Month,
      visiblePeriodHours: 24 * 360,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 60
    }));

    this.optionsDurationLabels.push({
      selectedDisplayText: '500 DAYS',
      optionDisplayText: '500'
    });
    this.chartConfigurations.push(new ChartConfiguration({
      scrollStepHours: 24 * 18,
      gridLineInterval: GridLineIntervalEnum.Month,
      visiblePeriodHours: 24 * 500,
      primaryYAxisMaxValue: 1000,
      secondaryYAxisMaxValue: 60
    }));

    this.selectedDurationLabel = this.optionsDurationLabels[this.viewState.healthChartSelectedDuration];
  }

  private async getChartSetup(resest: boolean) {
    let response = await this.chartsService.getChartSetup<IChartSeriesSetupRecord, IAnimalChartSetupSeries>(this.healthChartId,
      resest ? ChartSetupProjection.resetsetup : ChartSetupProjection.getsetup);
    if (response.status == 200) {
      this.viewState.healthChartSetup = response.responseBody;
    }
  }

  private initChartSeriesSelection() {
    this.seriesSelections = [];
    for (let containerSeriesName of Object.getOwnPropertyNames(this.viewState.healthChartSetup.series)) {
      let record: IChartSeriesSetupRecord = this.viewState.healthChartSetup.series[containerSeriesName];
      if (record.isVisible) {
        this.seriesSelections.push({
          name: containerSeriesName,
          isSelected: record.isSelected,
          isRemovable: record.isRemovable
        });
      }
    }
    if (this.viewState.healthChartSetup.events.isVisible) {
      this.seriesSelections.push({
        name: 'events',
        isSelected: this.viewState.healthChartSetup.events.isSelected,
        isRemovable: this.viewState.healthChartSetup.events.isRemovable
      });
    }
  }

  private normalizeYoungStockHealthIndex(series: IAnimalChartDataPoint[]) {
    for (let dataPoint of series) {
      if (dataPoint.youngStockHealthIndex != null) {
        dataPoint.youngStockHealthIndex = (dataPoint.youngStockHealthIndex / 1000) * 100;
        dataPoint.youngStockHealthIndexUpper = 100;
      }
      if (dataPoint.healthThreshold != null) {
        dataPoint.healthThreshold = (dataPoint.healthThreshold / 1000) * 100;
      }
    }
  }

  private getSeriesConfig(dataPoints: IAnimalChartDataPoint[], selectedDurationIndex: number): SeriesModel[] {
    let seriesModel: SeriesModel[] = [];
    if (this.viewState.healthChartSetup.series.dailyRumination.isSelected &&
      this.viewState.healthChartSetup.series.dailyRumination.isVisible) {

      seriesModel.push({
        type: 'Line',
        xName: 'x',
        yName: 'dailyRumination',
        width: 2,
        fill: '#aa64aa',
        animation: {
          enable: false
        },
        dataSource: dataPoints
      });

      seriesModel.push({
        type: 'Area',
        xName: 'x',
        yName: 'dailyRumination',
        opacity: 0.2,
        fill: '#aa64aa',
        animation: {
          enable: false
        },
        dataSource: dataPoints
      });
    }

    if (this.viewState.healthChartSetup.series.youngStockHealthIndex.isSelected &&
      this.viewState.healthChartSetup.series.youngStockHealthIndex.isVisible) {

      seriesModel.push({
        type: 'RangeArea',
        xName: 'x',
        high: 'youngStockHealthIndexUpper',
        low: 'youngStockHealthIndex',
        yAxisName: 'thirdYAxis',
        opacity: 0.2,
        fill: '#a8aaad',
        animation: {
          enable: false
        },
        dataSource: dataPoints
      });

      seriesModel.push({
        type: 'Line',
        xName: 'x',
        yName: 'healthThreshold',
        yAxisName: 'thirdYAxis',
        width: 2,
        opacity: 1,
        fill: '#e22420',
        animation: {
          enable: false
        },
        dataSource: dataPoints
      });
    }

    if (selectedDurationIndex >= 2) {

      if (this.viewState.healthChartSetup.series.activityTrend.isVisible &&
        this.viewState.healthChartSetup.series.activityTrend.isSelected) {

        seriesModel.push({
          type: 'Line',
          xName: 'x',
          yName: 'activityTrend',
          width: 2,
          fill: '#DF9226',
          animation: {
            enable: false
          },
          dataSource: dataPoints
        });

        seriesModel.push({
          type: 'Area',
          xName: 'x',
          yName: 'activityTrend',
          opacity: 0.2,
          fill: '#ffcb05',
          animation: {
            enable: false
          },
          dataSource: dataPoints
        });

      }

      if (this.viewState.healthChartSetup.series.dailyEating.isVisible &&
        this.viewState.healthChartSetup.series.dailyEating.isSelected) {

        seriesModel.push({
          type: 'Line',
          xName: 'x',
          yName: 'dailyEating',
          width: 2,
          fill: '#56c3b8',
          animation: {
            enable: false
          },
          dataSource: dataPoints
        });

      }

    } else {

      if (this.viewState.healthChartSetup.series.rawRumination.isVisible &&
        this.viewState.healthChartSetup.series.rawRumination.isSelected) {

        seriesModel.push({
          type: 'StackingColumn',
          xName: 'x',
          yName: 'rawRumination',
          yAxisName: 'secYAxis',
          columnWidth: 0.2,
          dataSource: dataPoints,
          fill: '#aa64aa',
          opacity: 0.8,
          animation: {
            enable: false
          }
        });

      }

      if (this.viewState.healthChartSetup.series.rawEating.isVisible &&
        this.viewState.healthChartSetup.series.rawEating.isSelected) {

        seriesModel.push({
          type: 'StackingColumn',
          xName: 'x',
          yName: 'rawEating',
          yAxisName: 'secYAxis',
          columnWidth: 0.2,
          dataSource: dataPoints,
          fill: '#56c3b8',
          opacity: 0.8,
          animation: {
            enable: false
          }
        });

      }

      if (this.viewState.healthChartSetup.series.rawSuckling.isVisible &&
        this.viewState.healthChartSetup.series.rawSuckling.isSelected) {

        seriesModel.push({
          type: 'StackingColumn',
          xName: 'x',
          yName: 'rawSuckling',
          yAxisName: 'secYAxis',
          columnWidth: 0.2,
          dataSource: dataPoints,
          fill: '#aea9d3',
          opacity: 0.8,
          animation: {
            enable: false
          }
        });

      }
    }
    return seriesModel;
  }

  // tslint:disable-next-line:no-any
  private initChartLegend(seriesSetupContainer: any, selectedDurationIndex: number) {
    this.legendStates = [];
    for (let containerSeriesName of Object.getOwnPropertyNames(seriesSetupContainer)) {
      let record: IChartSeriesSetupRecord = seriesSetupContainer[containerSeriesName];

      if (selectedDurationIndex < 2 &&
        (containerSeriesName == 'dailyEating' ||
          containerSeriesName == 'activityTrend')) {
        continue;
      } else if (selectedDurationIndex >= 2 &&
        (containerSeriesName == 'rawRumination' ||
          containerSeriesName == 'rawEating' ||
          containerSeriesName == 'rawSuckling')) {
        continue;
      }

      if (record.isInLegend &&
        record.isVisible &&
        record.isSelected) {
        this.legendStates.push({
          name: containerSeriesName,
          value: null,
          altTranslation: null,
          altCss: null
        });
      }
    }
  }

  public onChartPointMouseOver(chartPoint: IChartPoint) {
    this.setLegendValue(chartPoint);
  }

  public onDurationSelected(duration: ChartDurationSelectionLabel) {
    this.selectedDurationLabel = duration;
    this.initChart(false);
  }

  // tslint:disable-next-line:no-any
  private setLegendValue(valueContainer: any) {
    if (valueContainer == null) {
      this.legendStates.forEach(legendState => {
        legendState.value = null;
        if (legendState.name == 'youngStockHealthIndex') {
          legendState.altCss = null;
        }
      });
    } else {
      this.legendStates.forEach(legendState => {
        legendState.value = valueContainer[legendState.name];
        if (legendState.value == null) {
          legendState.value = 0;
        }
        if (legendState.name == 'youngStockHealthIndex') {
          if (valueContainer['healthThreshold'] != null) {
            legendState.altCss = 'youngStockHealthIndex-alert';
          } else {
            legendState.altCss = null;
          }
        }
      });
    }
  }

  public async onSelectionApplied(save: boolean) {

    if (save) {
      this.googleAnalyticsService.send('Cow Card Graph', 'Change Settings - Save', JSON.stringify(this.viewState.healthChartSetup));
    } else {
      this.googleAnalyticsService.send('Cow Card Graph', 'Change Settings - Without Save', JSON.stringify(this.viewState.healthChartSetup));
    }

    for (let seriesItem of this.seriesSelections) {
      if (seriesItem.name == 'events') {
        this.viewState.healthChartSetup.events.isSelected = seriesItem.isSelected;
      } else {
        this.viewState.healthChartSetup.series[seriesItem.name].isSelected = seriesItem.isSelected;
      }
    }
    this.healthChart.resetChart();

    setTimeout(async () => {
      if (save) {
        let result = await this.chartsService.updateChartSetup(this.healthChartId, this.viewState.healthChartSetup);
        if (result.status != 200) {
          await this.getChartSetup(false);
        }
      }
      this.initChart(true);
    });
  }

  public async onRestoreToDefaults() {

    this.healthChart.resetChart();
    setTimeout(async () => {
      await this.getChartSetup(true);
      this.initChartSeriesSelection();
      this.initChart(true);
    });
  }
}
