<modal-dialog-template [overlayColor]="'rgba(22, 80, 109, 0.5)'"
                       [modalWidth]="508"
                       [modalHeight]="295"
                       (onEnterButtonClick)="onRightButtonClick.emit()"
                       (onEscapeButtonClick)="onLeftButtonClick.emit()"
                       [isOpen]="isOpen">
  <div class="confirm-dialog-container" *ngIf="isOpen" [attr.sh-id]="containerShId">
    <div class="close-dialog-button-container">
      <div class="close-dialog-button" [ngClass]="colorTheme" [attr.sh-id]="'close-confirmation-dialog-btn'" (click)="onCloseClick.emit()"></div>
    </div>
    <div class="body-container">
      <div class="header-container" [ngClass]="colorTheme">
        <div [ngClass]="iconClass"></div>
        <ellipsisable-text class="header-text" [attr.translation-id]="headerText">{{ headerText | translate }}</ellipsisable-text>
      </div>
      <div class="body-container" [ngClass]="colorTheme">
        <div class="primary-text" *ngIf="primaryText" [attr.translation-id]="primaryText">{{primaryText | translate}}</div>
        <div class="secondary-text" *ngIf="secondaryText" [attr.translation-id]="secondaryText">{{secondaryText | translate}}</div>
      </div>
      <div class="buttons-container" [ngClass]="colorTheme">
        <button class="button-item left-button"
                [attr.sh-id]="leftButtonShId"
                (click)="onLeftButtonClick.emit()"
                [attr.translation-id]="leftButtonText">{{ leftButtonText | translate }}</button>
        <button class="button-item right-button"
                [attr.sh-id]="rightButtonShId"
                (click)="onRightButtonClick.emit()"
                [attr.translation-id]="rightButtonText">{{ rightButtonText | translate }}</button>
      </div>
    </div>
  </div>
</modal-dialog-template>
