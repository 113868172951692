import {Component, Inject, OnInit} from '@angular/core';
import {ABOUT_TRANSITIONS} from '../../../common/animations/animations';
import {FarmMode, ServerConfig} from '../../../services/config/model/server-config';
import {SystemService} from '../../../services/system/system.service';
import {WINDOW} from '../../../utils/window-utils';
import {ConfigService} from '../../../services/config/config.service';
import {GoogleAnalyticsService} from '../../../services/google-analytics/google-analytics.service';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth/auth.service';
import {FarmRole} from '../../../services/auth/model/farm-role.enum';
import * as moment from 'moment';
import {TranslationService} from '../../../services/translations/translation.service';
import {
  AboutModel,
  AboutModelPopulationPlanEnum,
  AboutModelStateEnum,
  IOrderedPopulationPlanItem,
  OrderedPopulationPlanModel,
  PopulationPlanName
} from '../../../services/system/model/about-model';

export interface IApplicationPlanItem {
  text: string;
  type: string;
}

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations: [ABOUT_TRANSITIONS]
})
export class AboutComponent implements OnInit {

  public aboutModel: AboutModel;

  public isTrial: boolean;

  public orderedPopulationPlanModel: OrderedPopulationPlanModel;

  public isPopupOpen: boolean = false;

  public showApplicationPlan: boolean;

  public showMultipleApplicationPlans: boolean;

  public showSingleApplicationPlanOnly: boolean;

  public serverConfig: ServerConfig;

  public AboutModelStateEnum = AboutModelStateEnum;

  public PopulationPlanName = PopulationPlanName;

  constructor(private systemService: SystemService,
              @Inject(WINDOW) private window: Window,
              private readonly translationService: TranslationService,
              public configService: ConfigService,
              private router: Router,
              private authService: AuthService,
              private googleAnalyticsService: GoogleAnalyticsService) { }

  public ngOnInit() {
    this.serverConfig = this.configService.serverConfig;
    this.initUserData();
  }

  public get formattedFarmName() : string {
    if(this.aboutModel.farmName != null &&
       this.aboutModel.farmName.length >= 40) {
      return this.aboutModel.farmName.slice(0, 40) + "...";
    }
    return this.aboutModel.farmName
  }

  public togglePopup() {
    this.isPopupOpen = !this.isPopupOpen;
    if(this.isPopupOpen) {
      this.googleAnalyticsService.send('About', 'Open', 'Popup');
    }
  }

  public closePopup() {
    this.isPopupOpen = false;
  }

  public async register() {
    //this.$state.go('registration');
    this.googleAnalyticsService.send('About', 'Click', 'Register Now');
    this.authService.isPressedOnRegister = true;
    await this.router.navigate(['/registration/initialize-registration']);
  }

  public learnMore(linkType: string) {
    const xFarmId: string = this.configService.serverConfig.customerId;
    const iFarmId: string = this.configService.serverConfig.farmId;
    const xDealId: string = this.configService.serverConfig.dealerId;
    const country: string = this.configService.serverConfig.countryCode;
    const farmName: string = this.configService.serverConfig.farmName ? this.configService.serverConfig.farmName.replace(/\s+/g, '_') : '';
    const fullName: string = this.configService.serverConfig.user.firstName ? this.configService.serverConfig.user.firstName.replace(/ /g, '_') : '';
    const language: string = this.translationService.selectedLanguage;

    let destinationURl: string = '';
    let url: string = '';

    if (linkType === 'Pdf') {
      destinationURl = 'http://scrdairy.com/images/sense-time/SenseTime-User-Guide.pdf&pdf=1';
      if (this.serverConfig.farmMode === 'Dairy') {
        url = `http://tracking.scrdairy.com/track?xfarmid=${xFarmId}&ifarmid=${iFarmId}&xdealid=${xDealId}&farmname=${farmName}&fullname=${fullName}&locale=${language}&source=SenseTime&dest=${destinationURl}?locale=${language}`;
      } else if (this.serverConfig.farmMode === 'Beef') {
        url = `http://beeftracking.scrdairy.com/track?xfarmid=${xFarmId}&ifarmid=${iFarmId}&xdealid=${xDealId}&farmname=${farmName}&fullname=${fullName}&locale=${language}&source=SenseTime&dest=${destinationURl}?locale=${language}`;
      }
    } else if (linkType === 'Tutorial') {
      if (this.serverConfig.farmMode === 'Dairy') {
        destinationURl = 'http://www.scrdairy.com/dairy-academy/dairy.html';
        url = `http://tracking.scrdairy.com/track?xfarmid=${xFarmId}&ifarmid=${iFarmId}&xdealid=${xDealId}&farmname=${farmName}&fullname=${fullName}&locale=${language}&source=SenseTime&country=${country}&dest=${destinationURl}?locale=${language}`;
      } else if (this.serverConfig.farmMode === 'Beef') {
        destinationURl = 'http://www.scrdairy.com/beef-academy/beef.html';
        url = `http://beeftracking.scrdairy.com/track?xfarmid=${xFarmId}&ifarmid=${iFarmId}&xdealid=${xDealId}&farmname=${farmName}&fullname=${fullName}&locale=${language}&source=SenseTime&country=${country}&dest=${destinationURl}?locale=${language}`;
      }
      this.googleAnalyticsService.send('About', 'Click', 'SenseTime Academy');
    } else if (linkType === 'WhatsNew') {

    } else if (linkType === 'Terms') {
      if (this.serverConfig.farmMode === 'Dairy') {
        url = 'http://www.scrdairy.com/end-user-system-license-agreement/legal.html';
      } else if (this.serverConfig.farmMode === 'Beef') {
        url = 'http://www.scrdairy.com/END-USER-SYSTEM-LICENSE-AGREEMENT-beef/legal-beef.html';
      }
    } else if (linkType === 'Regulatory') {
      url = 'http://www.scrdairy.com/images/LEGAL/new/eSense%20Flex_Regulatory-Info.pdf';
    }

    this.window.open(url, '_blank');
  }

  public showApplicationPlanItem(item: IOrderedPopulationPlanItem): boolean {
    return this.orderedPopulationPlanModel.showApplicationPlanItem(item);
  }

  public showPlanDetails(item: IOrderedPopulationPlanItem): boolean {
    if (item.plan == PopulationPlanName.Cows || item.plan == PopulationPlanName.Heifers) {
      return !this.orderedPopulationPlanModel.showSingleApplicationPlanOnly();
    }
    return false;
  }

  private async initUserData() {
    let response = await this.systemService.getAbout();
    this.aboutModel = response.responseBody;
    this.isTrial = this.aboutModel.state.toString().toLowerCase() == AboutModelStateEnum.Trial.toLowerCase();
    this.orderedPopulationPlanModel = new OrderedPopulationPlanModel(response.responseBody.orderedPopulationPlan.plans);
    this.showApplicationPlan = !this.isTrial && this.orderedPopulationPlanModel.showApplicationPlan();
    this.showMultipleApplicationPlans = !this.isTrial && this.orderedPopulationPlanModel.showMultipleApplicationPlans();
    this.showSingleApplicationPlanOnly = !this.isTrial && this.orderedPopulationPlanModel.showSingleApplicationPlanOnly() && !this.orderedPopulationPlanModel.hasFinishingOrYoungStock();

    if (response.responseBody.thirdParty && response.responseBody.thirdParty.lastSyncDate) {
      this.aboutModel.thirdParty.lastSyncDate = moment.unix(response.responseBody.thirdParty.lastSyncDate).valueOf();
    }
  }

  private isAdminOrFarmManager(): boolean {
    return (this.configService.serverConfig.user.farmRole === FarmRole.Admin || this.configService.serverConfig.user.farmRole === FarmRole.FarmManager);
  }

  private isStartFreeTrialShown(): boolean {
    return (this.isAdminOrFarmManager() && this.configService.serverConfig.premiumTrialInfo.link !== null);
  }

  private startFreeTrialURL() {
    let editableURL: string = this.configService.serverConfig.premiumTrialInfo.link;
    editableURL = editableURL.replace('[DEVICE]', 'Web');
    editableURL = editableURL.replace('[ORIGIN]', 'About');
    editableURL = editableURL.replace('[LOCALE]', this.translationService.selectedLanguage);
    editableURL = editableURL.replace('[LANG]', this.translationService.selectedLanguage.split('-')[0]);
    window.open(editableURL, '_blank');
  }
}
