<div>
  <div class="pwd-info" [ngClass]="validationPopupPosition">
    <h4>{{'REGISTRATION.FLOW.PASSWORD_POLICY.PASSWORD_MUST_MEET_THE_FOLLOWING_REQUIREMENTS' | translate}}:</h4>
    <ul>
      <li [ngClass]="{'invalid' : !passwordValidationResult.oneLowerCaseLetter, 'valid' : passwordValidationResult.oneLowerCaseLetter}"  id="letter">{{'REGISTRATION.FLOW.PASSWORD_POLICY.AT_LEAST' | translate}}&nbsp;<strong>{{'REGISTRATION.FLOW.PASSWORD_POLICY.ONE_LOWER_CASE_LETTER' | translate}}</strong></li>
      <li [ngClass]="{'invalid' : !passwordValidationResult.oneCapitalCaseLetter, 'valid' : passwordValidationResult.oneCapitalCaseLetter}" id="capital">{{'REGISTRATION.FLOW.PASSWORD_POLICY.AT_LEAST' | translate}}&nbsp;<strong>{{'REGISTRATION.FLOW.PASSWORD_POLICY.ONE_CAPITAL_CASE_LETTER' | translate}}</strong></li>
      <li [ngClass]="{'invalid' : !passwordValidationResult.oneNumber, 'valid' : passwordValidationResult.oneNumber}" id="number">{{'REGISTRATION.FLOW.PASSWORD_POLICY.AT_LEAST' | translate}}&nbsp;<strong>{{'REGISTRATION.FLOW.PASSWORD_POLICY.ONE_NUMBER' | translate}}</strong></li>
      <li [ngClass]="{'invalid' : !passwordValidationResult.numberOfCharacters, 'valid' : passwordValidationResult.numberOfCharacters}" id="length">{{'REGISTRATION.FLOW.PASSWORD_POLICY.BE_AT_LEAST' | translate}}&nbsp;<strong>{{'REGISTRATION.FLOW.PASSWORD_POLICY.NUMBER_OF_CHARACTERS' | translate}}  </strong></li>
    </ul>
  </div>
</div>
