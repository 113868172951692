import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AddFinishingGroupModel, IGroup} from '../../../../../services/groups/model/group.interface';
import {ErrorModel} from '../../../../../services/model/common-model';
import {DropdownListAutoSelectionMode, DropdownListItem} from '../../../../../common/components/dropdown-list/dropdown-list-model';
import {GroupsService, IGroupsMetaResponse} from '../../../../../services/groups/groups.service';
import {HttpResponseResult} from '../../../../../services/rest-api/rest-api.service';
import {Subscription} from 'rxjs';
import {GroupFields} from '../group-editor.component';
import {TranslationService} from '../../../../../services/translations/translation.service';
import {BranchesService, BranchLocalizationModel} from '../../../../../services/branches/branches.service';
import { DropdownColorScheme } from 'src/app/common/components/dropdown-list/regular-dropdown-list/regular-dropdown-list.component';
import { CornerColor, CornerContainerBodyBackground } from 'src/app/common/components/containers/corner-container/corner-container.component';

@Component({
  selector: 'group-editor-finishing',
  templateUrl: './group-editor-finishing.component.html',
  styleUrls: ['./group-editor-finishing.component.scss']
})
export class GroupEditorFinishingComponent implements OnInit, OnDestroy {

  @Input()
  public isOpen: boolean;

  @Output()
  public isAddGroupOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public groupAddedOrChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public addGroupModel: AddFinishingGroupModel = new AddFinishingGroupModel();

  public Error: ErrorModel;

  public groupFields = GroupFields;

  public groupBranchesDropdownListItems: DropdownListItem[] = [];

  public  selectedBranch : DropdownListItem;

  public dropdownListAutoSelectionMode = DropdownListAutoSelectionMode;

  private editGroupSubscription: Subscription;

  private groupId: number = null;

  private savingChanges: boolean;

  public DropdownColorScheme = DropdownColorScheme;

  public CornerColor = CornerColor;

  public CornerContainerBodyBackground = CornerContainerBodyBackground;

  constructor(private readonly groupsService: GroupsService,
              private readonly branchesService: BranchesService,
              private readonly translationService: TranslationService) { }

  public async ngOnInit() {
    let response: HttpResponseResult<IGroupsMetaResponse> = await this.groupsService.getFinishingGroupsMeta();
    if (response.status === 200) {
      this.groupBranchesDropdownListItems = [];
      if (response.responseBody.groupMetadata && response.responseBody.groupMetadata.branches) {
        this.groupBranchesDropdownListItems = this.branchesService.getGroupBranchDropdownItemsList(<BranchLocalizationModel[]>response.responseBody.groupMetadata.branches);
      }
    }
    this.editGroupSubscription = this.groupsService.editGroupSubscription.subscribe((groupToEdit: IGroup) => {
      if (groupToEdit == null) {
        this.groupId = null;
        this.clearForm();
      } else {
        this.groupId = groupToEdit.GroupDatabaseIDCalculation;
        this.fillingEditFormGroup(groupToEdit);
      }
      this.clearErrors();
    });
  }

  public ngOnDestroy() {
    this.editGroupSubscription.unsubscribe();
  }

  public async onGroupBranchSelected(selectedGroupBranch: DropdownListItem) {
    if (selectedGroupBranch && selectedGroupBranch.value) {
      this.addGroupModel.branchName = selectedGroupBranch.value;
      this.selectedBranch = selectedGroupBranch;
    } else {
      this.addGroupModel.branchName = null;
      this.selectedBranch = {value: null, displayValue: null, disabled: null, icon: null};
    }
    this.clearErrors();
  }

  public onGroupNumberSelected(selectedGroupNumber: number) {
    this.addGroupModel.groupNumber = selectedGroupNumber;
    this.clearErrors();
  }

  public async saveGroup() {
    // create a new group
    if(this.savingChanges || !this.isSaveButtonActive) {
      return;
    }
    if(this.groupId == null){
      if (this.validateCreateGroup()) {
        let response = await this.groupsService.createFinishingGroup(this.addGroupModel);
        if (response.status === 200 || response.status === 201) {
          this.clearForm();
          this.groupAddedOrChanged.emit(true);
        } else {
          this.Error = response.errorResponseBody.result.failures[0];
        }
      }
    } else {
      if (this.validateCreateGroup()) {
        let response = await this.groupsService.editFinishingGroup(this.addGroupModel.id, this.addGroupModel);
        if (response.status === 200 || response.status === 201) {
          this.isOpen = false;
          this.clearForm();
          //this.groupsService.isEditGroup = false;
          // emit that the group changed to reload the group list - emitting false to close the add group window and refresh screen dimensions.
          this.groupAddedOrChanged.emit(false);
        } else {
          this.Error = response.errorResponseBody.result.failures[0];
        }
      }
    }
    this.savingChanges = false;
  }

  private hasErrorForField(field: string): boolean {
    let hasError = false;
    if (this.Error) {
      if (this.Error.fieldName === 'barnchName') {
        hasError = field === 'branchName';
      } else {
        hasError = this.Error && this.Error.fieldName === field;
      }
    }

    return hasError;
  }

  private validateCreateGroup(): boolean {
    if (!this.addGroupModel.groupName) {
      this.Error = { key: 'GroupNameCannotBeEmpy', fieldName: GroupFields.groupName };
      return false;
    } else if (this.addGroupModel.groupNumber < 0) {
      this.Error = { key: 'groupNumberRequired', fieldName: GroupFields.groupNumber };
      return false;
    } else if (!this.addGroupModel.branchName) {
      this.Error = { key: 'BranchNameInvalid', fieldName: GroupFields.branchName };
      return false;
    } else {
      return true;
    }
  }

  private clearErrors() {
    this.Error = null;
  }

  public async fillingEditFormGroup(obj:IGroup) {
    if (obj.GroupBranchDynamicCalculation.isLocalization) {
      this.selectedBranch = {value: obj.GroupBranchDynamicCalculation.value, displayValue: this.translationService.translate('REPORTS.GRID.STATUS' + obj.GroupBranchDynamicCalculation.value), disabled: false, icon: null };
    } else {
      this.selectedBranch = {value: obj.GroupBranchDynamicCalculation.value, displayValue: obj.GroupBranchDynamicCalculation.value, disabled: false, icon: null };
    }
    this.addGroupModel.groupName = obj.GroupNameCalculation;
    this.addGroupModel.groupNumber = obj.GroupNumberCalculation;
    this.addGroupModel.id = obj.GroupDatabaseIDCalculation;
    this.addGroupModel.branchName = this.selectedBranch.value;
  }

  private clearForm() {
    if(this.groupBranchesDropdownListItems != null &&
       this.groupBranchesDropdownListItems.length === 1) {
      this.selectedBranch = this.groupBranchesDropdownListItems[0];
      this.addGroupModel.branchName = this.selectedBranch.value;
    } else {
      this.selectedBranch = null;
      this.addGroupModel.branchName = null;
    }
    this.addGroupModel.id = null;
    this.addGroupModel.groupName = null;
    this.addGroupModel.groupNumber = null;
    this.Error = null;
  }

  public get isSaveButtonActive() {
    if (this.addGroupModel.groupName == null || this.addGroupModel.groupName === '') {
      return false;
    }
    if (this.addGroupModel.groupNumber == null) {
      return false;
    }
    if (this.addGroupModel.branchName == null || this.addGroupModel.branchName === '') {
      return false;
    }
    return true;
  }
}
