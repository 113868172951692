<div outsideClickHandler (onOutsideClick)="closeAlertsDialog()">
  <div class="alerts-header" (click)="toggleAlertsDialog()" [ngClass]="{'open': alertCount, 'active': alertCount}" sh-id="system_alerts_toggle">
    <div class="icon icon-alert-connection"></div>
    <div class="alerts-label" [ngClass]="{'active': alertCount}">
      <span>{{alertCount}}</span>&nbsp;&nbsp;
      <span>{{'ALERTS' | translate}}</span>
    </div>
    <div class="shadow" *ngIf="!isPopupOpen"></div>
  </div>
  <div class="animated-popup" *ngIf="isPopupOpen" sh-id="system_alerts_popup" [ngClass]="{'open': alertCount, 'active': alertCount}" @system-alerts-transition>
    <div class="system-alerts-popup">
      <div class="frame-corner"></div>
      <div class="alerts-popup-title">
        <div class="title-text">{{'HEADER.ALERTS.SYSTEM_TITLE' | translate}}</div>
        <div class="btn-close" (click)="closeAlertsDialog()">
          <div class="icon icon-close"></div>
        </div>
        <div class="shadow"></div>
      </div>
      <div class="no-alerts-popup-content" *ngIf="!alerts.length">
        <div class="empty-list">
          <div class="title">
            <span>{{ 'HEADER.ALERTS.NO_ALERTS' | translate}}</span>
            <span>{{ 'HEADER.ALERTS.ALL_GOOD' | translate}}</span>
          </div>
        </div>
        <div class="icon no-alerts-icon"></div>
      </div>
      <div class="alerts-popup-content" *ngIf="alerts.length">
        <ul>
          <li *ngFor="let alert of alerts">
            <div class="alert-date-details">
              <span class="alert-data-text">{{alert.calculatedTime}}</span>
            </div>
            <div class="alert-entity-details">
              <a class="alert-link-text" (click)="isPopupOpen = false" saveDataWarningInject [routerLink]="['/system/dashboard']">
                <span>{{alert.entityNumber}}</span>
              </a>
              <span class="alert-description-text" [attr.translation-id]="'HEADER.ALERTS.ALERT_TYPE.' + alert.alertLocalization">{{'HEADER.ALERTS.ALERT_TYPE.' + alert.alertLocalization | translate}}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
