import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutoCompleteColorScheme, MatchingResultItem } from '../auto-complete-model';
import {PopupTemplateComponent} from '../../popups/popup-template/popup-template.component';

@Component({
  selector: 'forms-auto-complete',
  templateUrl: 'forms-auto-complete.component.html',
  styleUrls: ['forms-auto-complete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormsAutoCompleteComponent),
      multi: true
    }
  ]
})
export class FormsAutoCompleteComponent
  implements ControlValueAccessor, OnInit, OnDestroy {

  private onChangeFn: (changes: string) => void;

  public value$ = new Subject<string>();

  public matchingItems$: Observable<MatchingResultItem[]>;

  public matchingItems: MatchingResultItem[];

  public selectedIndex: number | null;

  public readonly resultLimit = 6;

  private readonly subscription = new Subscription();

  @Input()
  public maxResults: number;

  @Input()
  public set items(newValue: string[]) {
    this.matchingItems$ = combineLatest([of(newValue), this.value$]).pipe(
      map(([items, searchQuery]) => {
        if (searchQuery != null && searchQuery.length === 0) {
          return [];
        }

        return items
          .filter((item) => item.startsWith(searchQuery))
          .map<MatchingResultItem>(
            (item) => {
              let matchingResultItem = new MatchingResultItem();
              matchingResultItem.matchingPart = searchQuery;
              matchingResultItem.endPart =item.substr(searchQuery.length);
              return matchingResultItem;
            }).slice(0, this.resultLimit);
      })
    );

    this.subscription.add(this.matchingItems$.subscribe((items) => {
      this.matchingItems = items;
      this.resetSelectedIndex();
    }));
  }

  @Input()
  public isFieldError: boolean = false;

  @Input()
  public placeholder: string;

  @Input()
  public searchQuery: string;

  @Input()
  public shIdPostfix: string = '';

  @Input()
  public autoFocus: boolean = false;

  @Input()
  public isAllowUnmatchedSelection: boolean = false;

  @Input()
  public colorScheme: AutoCompleteColorScheme =
    AutoCompleteColorScheme.WhiteBlack;

  @Output()
  public matchingItemChanged = new EventEmitter<string>();

  @Output()
  public searchQueryChanged = new EventEmitter<string>();

  @Output()
  public onEnterPress = new EventEmitter<Event>();

  @ViewChild('searchInput')
  public searchInput: ElementRef<HTMLInputElement>;

  @ViewChild(PopupTemplateComponent, {static: true})
  public popupTemplate: PopupTemplateComponent;

  public resultsExpanded: boolean;

  public matchingResults: MatchingResultItem[] = [];

  public lastMatchingItem: string;

  public AutoCompleteColorScheme = AutoCompleteColorScheme;

  private _isFoldsUp: boolean;

  constructor(private readonly detectorRef: ChangeDetectorRef) {}

  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // tslint:disable-next-line:no-any
  public registerOnChange(fn: (changes: any) => void): void {
    this.onChangeFn = fn;
  }

  // tslint:disable-next-line:no-any
  public registerOnTouched(fn: any): void {}

  public setDisabledState(isDisabled: boolean): void {
    this.searchInput.nativeElement.disabled = isDisabled;
  }

  // tslint:disable-next-line:no-any
  public writeValue(value: any): void {
    if (value === undefined) {
      value = null;
    }

    this.value$.next(value);
  }

  public onSearchStringInput(searchString: string): void {
    if (searchString === '') {
      this.selectItem(null);
    } else {
      this.value$.next(searchString);
      this.resultsExpanded = true;
    }
  }

  public selectItem(selectedItem: string) {
    this.value$.next(selectedItem);
    this.writeValue(selectedItem);
    this.onChangeFn(selectedItem);
    this.resultsExpanded = false;
  }

  public onFocus(): void {
    this.resultsExpanded = true;
  }

  public onEnter(event:Event): void {
    if ((this.matchingItems || []).length > 0) {
      const selectedItem = this.matchingItems[this.selectedIndex];
      this.selectItem(selectedItem.value);
      this.onEnterPress.emit(event);
    }
  }

  public onArrowDown(): void {
    this.incrementSelectedIndex();
  }

  public onArrowUp(): void {
    this.decrementSelectedIndex();
  }

  public focusOnAutocomplete() {
    this.searchInput.nativeElement.focus();
  }

  public onRemoveSelection(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.searchQuery = null;
    this.lastMatchingItem = null;
    this.matchingResults = [];
    this.resultsExpanded = false;
    this.matchingItemChanged.emit(null);
    this.searchInput.nativeElement.focus();
  }

  public onOutsideClick(): void {
    if (this.resultsExpanded) {
      this.resultsExpanded = false;
    }
  }

  public calculateHeight() {
    if (this.maxResults > 0) {
      return `${42.5 * this.maxResults}px`;
    } else {
      return '';
    }
  }

  public resetSelectedIndex(): void {
    if (this.matchingItems.length > 0) {
      this.selectedIndex = 0;
    } else {
      this.selectedIndex = null;
    }
  }

  public incrementSelectedIndex(): void {
    this.selectedIndex++;
    if (this.selectedIndex >= this.matchingItems.length) {
      this.selectedIndex = 0;
    }
  }

  public decrementSelectedIndex(): void {
    this.selectedIndex--;
    if (this.selectedIndex < 0) {
      this.selectedIndex = this.matchingItems.length - 1;
    }
  }

  public focusAndClean(): void {
    this.selectItem('');
    this.focusOnAutocomplete();
  }

  public clear() {
    this.selectItem('');
  }

  public get isFoldDirectionUp(): boolean {
    if (!!this.popupTemplate && this._isFoldsUp !== this.popupTemplate.isFoldsUp) {
      setTimeout(() => {
        this._isFoldsUp = this.popupTemplate.isFoldsUp;
        this.detectorRef.detectChanges();
      });
    }
    return this._isFoldsUp;
  }
}
