import {Injectable} from '@angular/core';

export class PasswordValidationResult {

  public oneLowerCaseLetter: boolean;

  public oneCapitalCaseLetter: boolean;

  public oneNumber: boolean;

  public numberOfCharacters: boolean;

  public get isValid() {
    return this.oneLowerCaseLetter && this.oneCapitalCaseLetter && this.oneNumber && this.numberOfCharacters;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthLocalValidatorService {

  private static readonly UserNameRegex = /[^a-zA-Z0-9 \.\@]/g;

  constructor() { }

  public validateUserNameCharacters(userName: string) : string {
    if(userName && AuthLocalValidatorService.UserNameRegex.test(userName)) {
      return userName.replace( AuthLocalValidatorService.UserNameRegex,"");
    }
    if(userName && userName.length > 120) {
      return userName.slice(0,120);
    }
    return userName;
  }

  public validatePassword(password:string) : PasswordValidationResult {

    let passwordValidationResult : PasswordValidationResult = new PasswordValidationResult();

    //validate the length
    passwordValidationResult.numberOfCharacters = password && password.length > 5;

    //validate letter
    passwordValidationResult.oneLowerCaseLetter = (/[a-z]/).test(password);

    //validate capital letter
    passwordValidationResult.oneCapitalCaseLetter = (/[A-Z]/).test(password);

    //validate number
    passwordValidationResult.oneNumber = (/\d/).test(password);

    return passwordValidationResult;
  }
}
