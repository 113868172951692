import {
  Component, Input,
  SimpleChanges
} from '@angular/core';
import {
  CreateCalvingEventDetails,
  CalvingCowEventMetadata,
  Newborn,
} from '../../../../../services/animals/model/animal-events';
import * as _ from "lodash"
import {EventFormComponent, EventFormFieldName} from '../event-form.component';
import {DropdownListItem} from '../../../../../common/components/dropdown-list/dropdown-list-model';
import {CustomEventWrapperCssClass} from "../event-form-errors/event-form-errors.component";
import {FormsDLIconsThemes} from "../../../../../common/components/dropdown-list/forms-dropdown-list/forms-dropdown-list.component";

@Component({
  selector: 'calving-event-form',
  templateUrl: './calving-event-form.component.html',
  styleUrls: ['./calving-event-form.component.scss']
})
export class CalvingEventFormComponent extends EventFormComponent<CalvingCowEventMetadata, CreateCalvingEventDetails> {

  public calvingEaseDropdownItems: DropdownListItem[] = [];

  public destinationGroupsDropdownItems: DropdownListItem[] = [];

  public editedNewborn: Newborn;

  public formsDLIconsThemes = FormsDLIconsThemes;

  public editableNewbornIndex: number;

  @Input()
  public isAddNewbornsDisabled: boolean = false;

  public get calvingEase(): string | undefined {
    return this.editModel.calvingEase != null ? this.editModel.calvingEase.key : undefined;
  }

  public set calvingEase(newValue: string | undefined) {
    if (newValue == null) {
      this.editModel.calvingEase = null;
    } else {
      const calvingEase = this.meta.calvingEase.find((ce) => ce.key === newValue);
      if (calvingEase != null) {
        this.editModel.calvingEase = calvingEase;
      }
    }

    this.emitModelChanged();
  }

  public get destinationGroup(): number | undefined {
    return this.editModel.destinationGroup;
  }

  public set destinationGroup(newValue: number | undefined) {
    this.editModel.destinationGroup = newValue;
    this.emitModelChanged();
  }

  public CustomEventWrapperCssClass = CustomEventWrapperCssClass;

  public EventFormFieldName = EventFormFieldName;

  constructor() {
    super();
  }

  public onChanges(changes: SimpleChanges): void {
    if (changes.meta != null) {
      this.calvingEaseDropdownItems = this.meta.calvingEase.map((ce) => {
        return {
          value: ce.key,
          displayValue: ce.value,
          disabled: false,
          icon: null
        };
      });

      this.destinationGroupsDropdownItems = this.meta.cowGroups.map((cg) => {
        return {
          value: cg.id,
          displayValue: cg.name,
          disabled: false,
          icon: null
        };
      });
    }
  }

  public handleAddNewborn(event:Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.editedNewborn = new Newborn();
  }

  public handleCloseAddNewbornPopup(): void {
    this.editableNewbornIndex = null;
  }

  public handleEditNewborn(event:Event, newborn: Newborn): void {
    event.preventDefault();
    event.stopPropagation();
    this.editableNewbornIndex = this.editModel.newborns.indexOf(newborn);
    this.editedNewborn = _.cloneDeep(newborn);
  }

  public handleAddNewbornChange(newborn: Newborn): void {
    if(this.editableNewbornIndex == null) {
      this.editModel.newborns.push(newborn);
    } else {
      this.editModel.newborns[this.editableNewbornIndex] = newborn;
      this.editableNewbornIndex = null;
    }
    this.emitModelChanged();
  }

  public handleRemoveNewborn(): void {
    this.editModel.newborns.splice(this.editableNewbornIndex, 1);
    this.editableNewbornIndex = null;
    this.emitModelChanged();
  }

  public isFieldErrorForNewBornItem(newBornIndex: number) {
    return (this.errorModel.fieldName && this.errorModel.fieldName.startsWith('newborns') && this.errorModel.index === newBornIndex)
  }
}
