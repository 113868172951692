import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {TranslationService} from '../../../../services/translations/translation.service';
import {PICKER_TRANSITIONS} from '../../../animations/animations';
import {ConfigService} from '../../../../services/config/config.service';

export enum TranslationPickerThemeEnum {
  Black = 'Black',
  White = 'White'
}

@Component({
  selector: 'translation-picker',
  templateUrl: './translation-picker.component.html',
  styleUrls: ['./translation-picker.component.scss'],
  animations: [PICKER_TRANSITIONS]
})
export class TranslationPickerComponent implements OnInit {

  @Input()
  public theme: TranslationPickerThemeEnum;

  @Input()
  public selectedLanguage: string;

  public isPickerOpen = false;
  private languages: string[] = [];
  private languageSections: string[][] = [];

  public TranslationPickerThemeEnum = TranslationPickerThemeEnum;

  constructor(private translationService: TranslationService, public readonly configService: ConfigService ) {
    this.translationService.getLanguages().subscribe((languages: string[]) => {
      this.languages = languages;
        this.initializeLanguages(this.languages);
    });
  }

  public ngOnInit() {
    this.selectedLanguage = this.translationService.selectedLanguage;
  }

  public initializeLanguages(languages: string[]) {
    this.languageSections[0] = [];
    this.languageSections[1] = [];
    this.languageSections[2] = [];
    languages.filter(language => language !== this.translationService.selectedLanguage).map((language: string, index: number) => {
      if (index < 10) {
        this.languageSections[0].push(language);
      } else if (index >= 10 && index < 20) {
        this.languageSections[1].push(language);
      } else if (index >= 20 && index < 30) {
        this.languageSections[2].push(language);
      }
    });
  }

  public onTranslationPickerButtonClicked() {
    this.isPickerOpen = !this.isPickerOpen;
  }

  public async onTranslationChanged(selectedLanguage: string) {
    this.isPickerOpen = false;
    this.selectedLanguage = selectedLanguage;
    await this.translationService.selectLanguage(selectedLanguage, this.configService.serverConfig, true);
    this.initializeLanguages(this.languages);
  }

  public closePopup(){
    this.isPickerOpen = false;
  }

}
