<div class="mock-notification-dialogs-area">
  <div>
    <select [(ngModel)]="selectedDialogContainerKey" sh-id="selectedDialogContainer">
      <option [ngValue]="null" disabled selected>Choose a dialog...</option>
      <option [ngValue]="dialogContainer" *ngFor="let dialogContainer of dialogContainersKeys">
        {{ dialogContainer }}
      </option>
    </select>
    <button [disabled]="!selectedDialogContainerKey" (click)="showSelectedDialog($event)" sh-id="showDialogButton">
      Show selected dialog
    </button>
  </div>
  <hr>
  <div>
    <button (click)="updateSystemConfiguration()" sh-id="notification_dialog_panel_refresh_system_configuration">
      Update system configuration
    </button>
  </div>
</div>
<new-version-software-modal #newVersionSoftwareModalComponent></new-version-software-modal>
<started-trial-dialog #startedTrialDialogComponent></started-trial-dialog>
<started-premium-trial-dialog #startedPremiumTrialDialogComponent></started-premium-trial-dialog>
<!-- has inputs -->
<system-management-error-modal #systemManagementErrorModalComponent [errorModalData]="{key:'TITLE'}"></system-management-error-modal>
<sync-now-modal #syncNowModalComponent></sync-now-modal>
<!-- has inputs combine with next -->
<trial-farm-selection-modal #trialFarmSelectionModalComponent
                            [farmMode]="farmModes.Dairy"
                            (onGoToDashboardClicked)="trialFarmSelectionModalComponent.closeDialog()">
</trial-farm-selection-modal>
<!-- has inputs -->
<confirm-user-delete-dialog #confirmUserDeleteDialogComponent
                            [userToDelete]="mockData.userToDelete"
                            (onCloseModalDialog)="confirmUserDeleteDialogComponent.closeDialog()"
                            (onDeleteClicked)="confirmUserDeleteDialogComponent.closeDialog()"></confirm-user-delete-dialog>
