import {FarmMode} from './server-config';

export class AnonymousAuthDetailsTrialModeResult {
  public isTrial: boolean;
  public daysToExpiration: number;
  public farmMode: string;
  public operation?: TrialOperation;
}

export class AnonymousAuthDetailsSupportedVersionsResult {
  public minIOS: string;
  public minAndroid: string;
}

export enum SystemStateEnum {
  trial = 'trial',
  active = 'active',
  inActive = 'inactive',
  suspended = 'suspend'
}

export enum TrialOperation {
  breeding = 'Breeding',
  finishing = 'Finishing'
}

export class AnonymousAuthDetailsResult {
  public systemState: SystemStateEnum;
  public flowStep: string;
  public farmId: string;
  public trialMode: AnonymousAuthDetailsTrialModeResult;
  public supportedVersions: AnonymousAuthDetailsSupportedVersionsResult;
  public senseTimeVersion: string;
}
