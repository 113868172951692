import {Component, Input} from '@angular/core';
import {IBranchGraphDetails} from '../../../../services/branches/model/branch.interface';

@Component({
  selector: 'branch-card-animals-summary',
  templateUrl: './branch-card-animals-summary.component.html',
  styleUrls: ['./branch-card-animals-summary.component.scss']
})
export class BranchCardAnimalsSummaryComponent {
  @Input()
  public branchGraphDetails: IBranchGraphDetails;


}
