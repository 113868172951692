import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  ChangeNameEventParams,
  RemoveShiftEventParams,
  SlideStartTimeDirection,
  SlideStartTimeEventParams
} from '../manage-settings-shifts.component';
import {ShiftModel} from '../../../../../../services/sorting-gate/model/shifts-model';

@Component({
  selector: 'manage-settings-shift-list',
  templateUrl: './manage-settings-shift-list.component.html',
  styleUrls: ['./manage-settings-shift-list.component.scss']
})
export class ManageSettingsShiftListComponent implements OnInit {

  @Input()
  public shifts: ShiftModel[];

  @Input()
  public isDisabled: boolean = false;

  @Output()
  public onChangeName = new EventEmitter<ChangeNameEventParams>();

  @Output()
  public onSlideStartTime = new EventEmitter<SlideStartTimeEventParams>();

  @Output()
  public onRemove = new EventEmitter<RemoveShiftEventParams>();

  constructor() { }

  public ngOnInit() {}

  public raiseChangeName(shift: ShiftModel, name: string): void {
    this.onChangeName.emit({
      shift,
      name
    });
  }

  public raiseSlideStartTime(shift: ShiftModel, direction: SlideStartTimeDirection): void {
    this.onSlideStartTime.emit({
      shift,
      direction
    });
  }

  public raiseRemove(shift: ShiftModel): void {
    this.onRemove.emit({
      shift
    });
  }
}
