import { Injectable } from '@angular/core';
import {ColorCode, IDashboardAvailableKPI, IDashboardDetailedKPI} from './model/dashboard-kpi.interface';
import {HttpResponseResult, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardKpiService {

  public static readonly MaxNumberOfItems:number = 6;

  private static readonly GetDetailedKPIsRoute = `/rest/api/dashboardkpis`;

  private static readonly GetAvailableKPIsRoute = `/rest/api/dashboardkpis/available`;

  constructor(private readonly restApiService: RestApiService,
              private readonly authService: AuthService) { }

  public getDetailedKPIs(): Promise<HttpResponseResult<IDashboardDetailedKPI[]>> {
    return this.restApiService.sendGetMethod<IDashboardDetailedKPI[]>(DashboardKpiService.GetDetailedKPIsRoute, this.authService.currentUserAuthState.authDetails);
  }

  public getAvailableKPIs(): Promise<HttpResponseResult<IDashboardAvailableKPI[]>> {
    return this.restApiService.sendGetMethod<IDashboardAvailableKPI[]>(DashboardKpiService.GetAvailableKPIsRoute, this.authService.currentUserAuthState.authDetails);
  }

  public setGraphSectionColor(colorCode: ColorCode) {
    switch (colorCode) {
      case "Positive":
        return '#B3C235';
      case "Intermediate":
        return '#FFCC08';
      case "Negative":
        return '#F04C39';
    }
  }

  public showGradientValue(type: string, value: number) {
    if (type && type === "Percentage") {
      return `${value}%`;
    }
    if (type && type === "Number") {
      return value;
    }
    if (!type) {
      return `${value}%`;
    }
  }

  public getValueRatio(value: number, min: number, max: number) {
    if (value !== null) {
      if(Number(value) > Number(max)) {
        value = max;
      } else if(Number(value) < Number(min)) {
        value = min;
      }
      let normalizedMaxScale = (max - (min ? min : 0));
      return ((value - (min ? min : 0)) / normalizedMaxScale);
    }
  }

  public getIconAccordingToKpiApplicationPlan(category: string) {
    switch(category) {
      case "Reproduction":
        return 'icon-reproduction';
      case "Health":
        return 'icon-systemHealth';
      case "Group":
        return 'icon-group';
    }
  }
}
