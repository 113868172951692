import {AnimalBadge} from '../../animals/model/animal.model';
import {AnimalLactationStatus} from '../breeding/model/animal-timeline-breeding-model';

export class AnimalTimelineFlowModel {
  public header: AnimalTimelineHeader;
  public nodes: AnimalTimelineNode[] = [];
  public legs: AnimalTimelineLeg[] = [];
}

export class AnimalTimelineHeader {
  public color: string;
  public badge: AnimalBadge;
  public animalStatus: AnimalLactationStatus;
  public animalAgeCaption: string;
  public animalAgeValue: number;
}

export class AnimalTimelineNode {
  public iconClass?: string;
  public displayNumber?: number;
  public displayNumberColor?: string;
  public detailsText?: string;
  public bottomText?: string;
  public bottomTextRed?: string;
  public nodeBackground: string;
}

export class AnimalTimelineLeg {
  public legParts: AnimalTimelineLegPart[];
  public legSizePercent: number;
}

export class AnimalTimelineLegPart {
  public backgroundColor: string;
  public legPartSizePercent: number;
  public innerText?: string;
  public innerTextColor?: string;
  public bottomText?: string;
}
