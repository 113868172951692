import {RowActionsPopupItem} from '../../common/components/row-actions-popup/row-actions-popup.component';
import {GridViewState} from '../ui/view-state/grid-row/grid-view-state';

export class ErrorModel {
  public fieldName: string;
  public key: string;

  constructor() {
    this.fieldName = undefined;
    this.key = undefined;
  }
}

export interface IDataGridViewContext {
  reloadDefault() : Promise<void>;

  getGridViewState(): GridViewState;
}

export abstract class DataRow {
  public actions: RowActionsPopupItem[];
  public gridViewState: GridViewState;
  public rowDefaultHeight: number;
}

export abstract class EntitiesDataRow  extends DataRow {
  public id: number;
}

export class Group {
  public id: number;
  public name: string;
}

export class NoteNames {
  public id: number;
  public value: string;
  public key: string;
}

export class Animal {
  public id: number;
  public animalName: string;
}
