import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import {EpochDateTimePipe} from './pipes/epoch-date-time.pipe';
import {ToggleComponent} from './components/toggle/toggle.component';
import {TranslationPickerComponent} from './components/translation/translation-picker/translation-picker.component';
import {AssignTagToAnimalDialogComponent} from './components/dialogs/assign-tag-to-animal-dialog/assign-tag-to-animal-dialog.component';
import {AssignAnimalToTagDialogComponent} from './components/dialogs/assign-animal-to-tag-dialog/assign-animal-to-tag-dialog.component';
import {LastUpdatedComponent} from './components/last-updated/last-updated.component';
import {ReportGridComponent} from './components/reports/report-grid/report-grid.component';
import {ReportActionsDropdownComponent} from './components/reports/report-actions/report-actions-dropdown/report-actions-dropdown.component';
import {GridSummaryComponent} from './components/grid-summary/grid-summary.component';
import {GridActionsDropdownComponent} from './components/grid-actions-dropdown/grid-actions-dropdown.component';
import {DataGridComponent} from './components/data-grid/data-grid.component';
import {TextLimitPipe} from './pipes/text-limit.pipe';
import {DurationPipe} from './pipes/duration.pipe';
import {FormsDropdownListComponent} from './components/dropdown-list/forms-dropdown-list/forms-dropdown-list.component';
import {KeysPipe} from './pipes/keys.pipe';
import {RowActionsPopupComponent} from './components/row-actions-popup/row-actions-popup.component';
import {NumericInputComponent} from './components/numeric-input/numeric-input.component';
import {TimeAgoTranslatedPipe} from './pipes/time-ago-translated.pipe';
import {BindableToggleComponent} from './components/bindable-toggle/bindable-toggle.component';
import {OutsideClickHandlerDirective} from './directives/outside-click-handler.directive';
import {RegularEditDialogTemplateComponent} from './components/dialogs/regular-edit-dialog-template/regular-edit-dialog-template.component';
import {RegularAutoCompleteComponent} from './components/auto-complete/regular-auto-complete/regular-auto-complete.component';
import {FilterBooleanComponent} from './components/reports/report-grid/filters/filter-boolean/filter-boolean.component';
import {FilterCheckListComponent} from './components/reports/report-grid/filters/filter-check-list/filter-check-list.component';
import {FilterDateTimeComponent} from './components/reports/report-grid/filters/filter-date-time/filter-date-time.component';
import {FilterNumericComponent} from './components/reports/report-grid/filters/filter-numeric/filter-numeric.component';
import {FilterSearchComponent} from './components/reports/report-grid/filters/filter-search/filter-search.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ReportDurationPipe} from './pipes/report-duration.pipe';
import {RoundPipe} from './pipes/round.pipe';
import {AnimalLifecycleStatusIconComponent} from './components/animal-lifecycle-status-icon/animal-lifecycle-status-icon.component';
import {BreedingWindowComponent} from './components/breeding-window/breeding-window.component';
import {LoaderComponent} from './components/loader/loader.component';
import {FormsAutoCompleteComponent} from './components/auto-complete/forms-auto-complete/forms-auto-complete.component';
import {RegularDropdownListComponent} from './components/dropdown-list/regular-dropdown-list/regular-dropdown-list.component';
import {RouterModule} from '@angular/router';
import {BullChooseDropdownListComponent} from './components/bull-choose-dropdown-list/bull-choose-dropdown-list.component';
import {RadioButtonComponent} from './components/radio-button/radio-button.component';
import {ChartSeriesDropdownListComponent} from './components/chart-series-dropdown-list/chart-series-dropdown-list.component';
import {XyChartComponent} from './components/xy-chart/xy-chart.component';
import {XyChartLegendComponent} from './components/xy-chart-legend/xy-chart-legend.component';
import {ChartModule} from '@syncfusion/ej2-angular-charts';
import {SaveDataEditSectionDirective} from './directives/save-data/save-data-edit-section.directive';
import {ImageCheckboxComponent} from './components/image-checkbox/image-checkbox.component';
import {WeightValueLimitDirective} from './directives/weight-value-limit/weight-value-limit.directive';
import {InputFocusDirective} from './directives/input-focus.directive';
import {SaveDataWarningIgnoreDirective} from './directives/save-data/save-data-warning-ignore.directive';
import {OutsideClickIgnoreDirective} from './directives/outside-click-ignore.directive';
import { InputFilterDirective } from './directives/input-filter.directive';
import { ChangeGroupDialogComponent } from './components/dialogs/change-group-dialog/change-group-dialog.component';
import { NgbButtonsModule } from './components/buttons/buttons.module';
import { SendToSortingGateDialogComponent } from './components/dialogs/send-to-sorting-gate-dialog/send-to-sorting-gate-dialog.component';
import { SaveDataWarningInjectDirective } from './directives/save-data/save-data-warning-inject.directive';
import { SortingReasonRowCellComponent } from './components/reports/report-grid/row-cells/sorting-reason-row-cell/sorting-reason-row-cell.component';
import {ModalDialogTemplateComponent} from "./components/dialogs/modal-dialog-template/modal-dialog-template.component";
import {AddAnimalToReportDialogComponent} from "./components/reports/report-actions/add-animal-to-report-dialog/add-animal-to-report-dialog.component";
import {NotificationDialogTemplateComponent} from "./components/dialogs/notification-dialog-template/notification-dialog-template.component";
import {TurnLedLightingOnDialogComponent} from "./components/reports/report-actions/turn-led-lighting-on-dialog/turn-led-lighting-on-dialog.component";
import {SuccessDialogComponent} from "./components/dialogs/success-dialog/success-dialog.component";
import {ConnectivityDialogComponent} from "./components/dialogs/connectivity-dialog/connectivity-dialog.component";
import { TranslatePipe } from './pipes/translate.pipe';
import { DatePickerComponent } from './components/calendar/date-picker/date-picker.component';
import { TimePickerComponent } from './components/calendar/time-picker/time-picker.component';
import { DateTimeDialogComponent } from './components/calendar/date-time-dialog/date-time-dialog.component';
import { TimeSelectionComponent } from './components/calendar/time-selection/time-selection.component';
import { AmPmSelectionComponent } from './components/calendar/am-pm-selection/am-pm-selection.component';
import {ErrorDialogComponent} from './components/dialogs/error-dialog/error-dialog.component';
import {DurationHorizontalScrollComponent} from './components/duration-horizontal-scroll/duration-horizontal-scroll.component';
import { PopupTemplateComponent } from './components/popups/popup-template/popup-template.component';
import { DropdownPopupComponent} from './components/popups/dropdown-popup/dropdown-popup.component';
import {DescriptionCellEntitiesComponent} from './components/data-grid/description-cell-template/description-cell-entities/description-cell-entities.component';
import {CreateBatchEventDialogComponent} from './components/dialogs/create-batch-event-dialog/create-batch-event-dialog.component';
import {CreateBatchEventCullingComponent} from './components/dialogs/create-batch-event-dialog/create-batch-event-culling/create-batch-event-culling.component';
import {CreateBatchEventAbortionComponent} from './components/dialogs/create-batch-event-dialog/create-batch-event-abortion/create-batch-event-abortion.component';
import {CreateBatchEventBreedingComponent} from './components/dialogs/create-batch-event-dialog/create-batch-event-breeding/create-batch-event-breeding.component';
import {CreateBatchEventPregnancyCheckComponent} from './components/dialogs/create-batch-event-dialog/create-batch-event-pregnancy-check/create-batch-event-pregnancy-check.component';
import {CreateBatchEventDoNotBreedComponent} from './components/dialogs/create-batch-event-dialog/create-batch-event-do-not-breed/create-batch-event-do-not-breed.component';
import {ChipsItemComponent} from './components/chips/chips-item.component';
import {ConfirmDialogComponent} from './components/dialogs/confirm-dialog/confirm-dialog.component';
import {ConfirmDeleteDialogComponent} from './components/dialogs/confirm-dialog/confirm-delete-dialog/confirm-delete-dialog.component';
import {ConfirmCullingAssignedTagDialogComponent} from './components/dialogs/confirm-dialog/confirm-culling-assigned-tag-dialog/confirm-culling-assigned-tag-dialog.component';
import {EmailNotProvidedDialogComponent} from './components/dialogs/email-not-provided-dialog/email-not-provided-dialog.component';
import { CornerContainerComponent } from './components/containers/corner-container/corner-container.component';
import { NonExternalUserDirective } from './directives/permissions/non-external-user.directive';
import { AnimalIdsComponent } from './components/dialogs/animal-ids/animal-ids.component';
import { DialogScrollableBodyComponent } from './components/dialogs/dialog-scrollable-body/dialog-scrollable-body.component';
import { DescriptionCellTemplateComponent } from './components/data-grid/description-cell-template/description-cell-template.component';
import {DescriptionCellDetailsTemplateComponent} from './components/data-grid/description-cell-template/description-cell-details-template/description-cell-details-template.component';
import { CellEntityComponent } from './components/data-grid/description-cell-template/description-cell-entities/cell-entity/cell-entity.component';
import { AnimalsToInspectReasonsCellComponent } from './components/reports/report-grid/row-cells/animals-to-inspect-reasons-cell/animals-to-inspect-reasons-cell.component';
import { SizableCellComponent } from './components/data-grid/sizable-cell/sizable-cell.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from "@danielmoncada/angular-datetime-picker";
import { EllipsisableTextComponent } from './components/elipsisable-text/ellipsisable-text.component';
import { ConfirmTrialFarmModeDialogComponent } from './components/dialogs/confirm-dialog/confirm-trial-farm-mode-dialog/confirm-trial-farm-mode-dialog.component';
import { ChipListComponent } from './components/chip-list/chip-list.component';
import {NoSelectStartEventBubblingDirective} from "./directives/no-select-start-event-bubbling.directive";

@NgModule({
  declarations: [
    EpochDateTimePipe,
    ToggleComponent,
    TranslationPickerComponent,
    AssignTagToAnimalDialogComponent,
    AssignAnimalToTagDialogComponent,
    LastUpdatedComponent,
    ReportGridComponent,
    ReportActionsDropdownComponent,
    GridSummaryComponent,
    GridActionsDropdownComponent,
    DataGridComponent,
    TextLimitPipe,
    DurationPipe,
    FormsDropdownListComponent,
    KeysPipe,
    RowActionsPopupComponent,
    NumericInputComponent,
    TimeAgoTranslatedPipe,
    BindableToggleComponent,
    OutsideClickHandlerDirective,
    RegularEditDialogTemplateComponent,
    RegularAutoCompleteComponent,
    FilterBooleanComponent,
    FilterCheckListComponent,
    FilterDateTimeComponent,
    FilterNumericComponent,
    FilterSearchComponent,
    ReportDurationPipe,
    RoundPipe,
    AnimalLifecycleStatusIconComponent,
    BreedingWindowComponent,
    LoaderComponent,
    FormsAutoCompleteComponent,
    RegularDropdownListComponent,
    BullChooseDropdownListComponent,
    RadioButtonComponent,
    ChartSeriesDropdownListComponent,
    XyChartComponent,
    XyChartLegendComponent,
    SaveDataEditSectionDirective,
    ImageCheckboxComponent,
    WeightValueLimitDirective,
    InputFocusDirective,
    SaveDataWarningIgnoreDirective,
    OutsideClickIgnoreDirective,
    NotificationDialogTemplateComponent,
    InputFilterDirective,
    NoSelectStartEventBubblingDirective,
    ConfirmCullingAssignedTagDialogComponent,
    ChangeGroupDialogComponent,
    SendToSortingGateDialogComponent,
    SaveDataWarningInjectDirective,
    DescriptionCellDetailsTemplateComponent,
    SortingReasonRowCellComponent,
    ModalDialogTemplateComponent,
    AddAnimalToReportDialogComponent,
    ConnectivityDialogComponent,
    TurnLedLightingOnDialogComponent,
    SuccessDialogComponent,
    ErrorDialogComponent,
    CreateBatchEventDialogComponent,
    CreateBatchEventBreedingComponent,
    CreateBatchEventDoNotBreedComponent,
    CreateBatchEventCullingComponent,
    CreateBatchEventAbortionComponent,
    CreateBatchEventPregnancyCheckComponent,
    TranslatePipe,
    DatePickerComponent,
    TimePickerComponent,
    DateTimeDialogComponent,
    TimeSelectionComponent,
    AmPmSelectionComponent,
    DurationHorizontalScrollComponent,
    DropdownPopupComponent,
    PopupTemplateComponent,
    DescriptionCellEntitiesComponent,
    ChipsItemComponent,
    ConfirmDialogComponent,
    ConfirmDeleteDialogComponent,
    EmailNotProvidedDialogComponent,
    CornerContainerComponent,
    NonExternalUserDirective,
    AnimalIdsComponent,
    DialogScrollableBodyComponent,
    DescriptionCellTemplateComponent,
    CellEntityComponent,
    AnimalsToInspectReasonsCellComponent,
    SizableCellComponent,
    ConfirmTrialFarmModeDialogComponent,
    EllipsisableTextComponent,
    ChipListComponent
  ],
  imports: [
    NgxDatatableModule,
    CommonModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    RouterModule,
    ChartModule,
    NgbButtonsModule
  ],
  exports: [
    EpochDateTimePipe,
    ToggleComponent,
    TranslationPickerComponent,
    AssignTagToAnimalDialogComponent,
    AssignAnimalToTagDialogComponent,
    LastUpdatedComponent,
    ReportGridComponent,
    ReportActionsDropdownComponent,
    GridSummaryComponent,
    GridActionsDropdownComponent,
    DataGridComponent,
    TextLimitPipe,
    DurationPipe,
    ReportDurationPipe,
    FormsDropdownListComponent,
    KeysPipe,
    RowActionsPopupComponent,
    NumericInputComponent,
    TimeAgoTranslatedPipe,
    BindableToggleComponent,
    OutsideClickHandlerDirective,
    RegularEditDialogTemplateComponent,
    RegularAutoCompleteComponent,
    AnimalLifecycleStatusIconComponent,
    BreedingWindowComponent,
    LoaderComponent,
    RegularDropdownListComponent,
    RadioButtonComponent,
    BullChooseDropdownListComponent,
    ChartSeriesDropdownListComponent,
    XyChartComponent,
    XyChartLegendComponent,
    RoundPipe,
    SaveDataEditSectionDirective,
    FormsAutoCompleteComponent,
    ImageCheckboxComponent,
    WeightValueLimitDirective,
    InputFilterDirective,
    ConfirmCullingAssignedTagDialogComponent,
    ChangeGroupDialogComponent,
    InputFocusDirective,
    SendToSortingGateDialogComponent,
    SaveDataWarningInjectDirective,
    DescriptionCellDetailsTemplateComponent,
    ModalDialogTemplateComponent,
    NotificationDialogTemplateComponent,
    AddAnimalToReportDialogComponent,
    ConnectivityDialogComponent,
    OutsideClickIgnoreDirective,
    SaveDataWarningIgnoreDirective,
    TurnLedLightingOnDialogComponent,
    SuccessDialogComponent,
    ErrorDialogComponent,
    CreateBatchEventDialogComponent,
    CreateBatchEventBreedingComponent,
    CreateBatchEventDoNotBreedComponent,
    CreateBatchEventCullingComponent,
    CreateBatchEventAbortionComponent,
    CreateBatchEventPregnancyCheckComponent,
    TranslatePipe,
    DatePickerComponent,
    TimePickerComponent,
    DurationHorizontalScrollComponent,
    DropdownPopupComponent,
    DescriptionCellEntitiesComponent,
    ChipsItemComponent,
    ConfirmDialogComponent,
    ConfirmDeleteDialogComponent,
    PopupTemplateComponent,
    EmailNotProvidedDialogComponent,
    CornerContainerComponent,
    NonExternalUserDirective,
    SizableCellComponent,
    ConfirmTrialFarmModeDialogComponent,
    EllipsisableTextComponent,
    ChipListComponent,
    NoSelectStartEventBubblingDirective
  ]
})
export class ShSharedModule { }
