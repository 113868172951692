<modal-dialog-template [isOpen]="isOpen"
                       [modalWidth]="modalWidth"
                       [modalHeight]="modalHeight"
                       (onEscapeButtonClick)="onEscapeButtonClick()"
                       (onEnterButtonClick)="enterClick()">
  <div class="edit-dialog" *ngIf="isOpen" @dialog-transitions [attr.sh-id]="createShId()">
    <div class="dialog-header">
      <div class="dialog-header-caption" [ngClass]="{ 'dialog-header-caption-full-width': !showCloseButton}">
        <ng-content select="[header-caption]"></ng-content>
      </div>
      <span class="remove-icon" (click)="removeClick()" *ngIf="enableRemoveButton"></span>
      <span class="close-icon"
            *ngIf="showCloseButton"
            (click)="closeClick()"
            [attr.sh-id]="createShId('close_button')"></span>
    </div>
    <div class="dialog-body">
      <ng-content select="[body]"></ng-content>
    </div>
    <div class="dialog-buttons">
      <a class="left-button" [ngClass]="{'disabled':leftButtonDisabled}" (click)="leftButtonClick()"
           [attr.sh-id]="createShId('left_button')">
        <ng-content select="[left-button]"></ng-content>
      </a>
      <a class="right-button" [ngClass]="{'disabled':rightButtonDisabled}" (click)="rightButtonClick()"
         [attr.sh-id]="createShId('right_button')">
        <ng-content select="[right-button]"></ng-content>
      </a>
    </div>
    <div *ngIf="showDialogShadow">
      <div class="edit-dialog-shadow edit-dialog-shadow--right"></div>
      <div class="edit-dialog-shadow edit-dialog-shadow--bottom"></div>
      <div class="edit-dialog-shadow edit-dialog-shadow--corner"></div>
    </div>
  </div>
</modal-dialog-template>
