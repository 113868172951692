import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {IReport} from '../../../services/reports/model/report.interface';
import {Subscription} from "rxjs";
import {PollingService} from "../../../services/rest-api/polling.service";

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public isLoading: boolean;

  @Input()
  public contextElementRef: ElementRef;

  public top: number = 0;

  public left: number = 0;

  public width: number = 0;

  public height: number = 0;

  public isServerDown: boolean = false;

  private subscription: Subscription;

  constructor(private pollingService: PollingService) { }

  public ngOnInit() {
    this.subscription = this.pollingService.serverResponsibility.subscribe(value => {
      this.isServerDown = !value.isResponsible;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(this.isLoading &&
       this.contextElementRef) {
      let rect = this.contextElementRef.nativeElement.getBoundingClientRect();
      this.top = rect.top;
      this.left = rect.left;
      this.width = rect.width;
      this.height = rect.height;
    } else {
      this.top = 0;
      this.left = 0;
      this.width = 0;
      this.height = 0;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
