import { Component, OnInit } from '@angular/core';
import {LedTasksService} from "../../../../../services/led-tasks/led-tasks.service";
import {LedMode, LedModeSettings, LedTaskFields} from "../../../../../services/led-tasks/models/led-tasks.interface";
import {RadioButtonColorTheme} from "../../../../../common/components/radio-button/radio-button.component";
import {DurationInput} from "../../../../../common/pipes/duration.pipe";

@Component({
  selector: 'led-task-editor-right-animals-container',
  templateUrl: './led-task-editor-right-animals-container.component.html',
  styleUrls: ['./led-task-editor-right-animals-container.component.scss']
})
export class LedTaskEditorRightAnimalsContainerComponent implements OnInit {

  public LedTaskFields = LedTaskFields;

  public RadioButtonColorTheme = RadioButtonColorTheme;

  public LedMode = LedMode;

  public DurationInput = DurationInput;

  constructor(public ledTasksService: LedTasksService) { }

  public ngOnInit() {
  }

  public isLedModeChecked(currentLedMode: LedMode): boolean {
    return this.ledTasksService.taskModel.ledMode === currentLedMode;
  }

  public onLedModeSelected(selectedMode: LedMode) {
    this.ledTasksService.taskModel.ledMode = selectedMode;
    switch (selectedMode) {
      case LedMode.Fast: {
        this.ledTasksService.taskModel.selectedDuration = this.ledTasksService.ledModeSettings.changeToFastModeSettings(this.ledTasksService.taskModel.selectedDuration);
        break;
      }
      case LedMode.Slow: {
        this.ledTasksService.taskModel.selectedDuration = this.ledTasksService.ledModeSettings.changeToSlowModeSettings(this.ledTasksService.taskModel.selectedDuration);
      }
    }
    this.ledTasksService.clearErrors();
  }

  public toggleRepeatsForDaysCheckbox() {
    this.ledTasksService.taskModel.isAnimalsTaskRepeat = !this.ledTasksService.taskModel.isAnimalsTaskRepeat;
    if (this.ledTasksService.taskModel.isAnimalsTaskRepeat) {
      this.ledTasksService.taskModel.animalsDaysRepeat = 1;
    } else {
      this.ledTasksService.taskModel.animalsDaysRepeat = null;
    }
    this.ledTasksService.clearErrors();
  }

  public onSelectedDurationChanged(duration: number) {
    this.ledTasksService.taskModel.selectedDuration = Math.round(duration);
  }

  public onRepeatDaysChanged(repeatDays: number) {
    this.ledTasksService.taskModel.animalsDaysRepeat = repeatDays;
    this.ledTasksService.clearErrors();
  }
}
