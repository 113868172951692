<div class="pregnancy-check-tab">
  <div class="double-field-div">
    <div class="input-field">
      <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.PREGNANT'">
        {{ 'MANAGE.COWS.ACTIVE.WIZARD.PREGNANT'| translate }}
      </div>
      <div class="value">
        <input class="input-class"
               (ngModelChange)="onErrorsClear.emit()"
               [ngClass]="{ error: animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.animalName)}"
               type="text"
               [(ngModel)]="addFemaleModel.animalNumber"
               [attr.sh-id]="'breeding_add_female_animal_number_pregnancy_check_tab_input'"
               [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER'"
               placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER' | translate }}" readonly/>
      </div>
    </div>
    <div class="input-field">
      <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'">
        {{ 'MANAGE.COWS.ACTIVE.WIZARD.GROUP' | translate }}
      </div>
      <div class="value">
        <forms-dropdown-list class="dropdown-list"
                             [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.group)"
                             [shIdPostfix]="'breeding_add_female_group_pregnancy_check_tab'"
                             [items]="groupsDropdownListItems"
                             [iconTheme]="formsDLIconsThemes.Blue"
                             [autoSelectionMode]="dropdownListAutoSelectionMode.Single"
                             [placeholder]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'"
                             [allowClear]="true"
                             [translateListItem]="false"
                             [ngModel]="addFemaleModel.group"
                             (ngModelChange)="onGroupSelected.emit($event)"></forms-dropdown-list>
      </div>
    </div>
  </div>
  <div class="seperator"></div>
  <div class="fields-and-errors-div">
    <div class="single-field-div">
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.PREG_CHECK_DATE'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.PREG_CHECK_DATE' | translate }}
        </div>
        <div class="value">
          <date-picker [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.pregnancyCheckDate)"
                       [calendarIconColor]="'#32aee2'"
                       [attr.sh-id]="'breeding_add_female_preg_check_date_pregnancy_check_tab'"
                       [selectedEpoch]="addFemaleModel.pregnancyCheckDate"
                       [shIdPostfix]="'breeding_add_female_preg_check_date_pregnancy_check_tab_date_picker'"
                       (epochChanged)="onPregCheckDateChanged($event)"></date-picker>
        </div>
      </div>
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.PREG_CHECK_TIME'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.PREG_CHECK_TIME' | translate }}
        </div>
        <div class="value">
          <time-picker [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.pregnancyCheckDate)"
                       [themeColor]="'#32aee2'"
                       [attr.sh-id]="'breeding_add_female_preg_check_time_pregnancy_check_tab_time_picker'"
                       [selectedEpoch]="addFemaleModel.pregnancyCheckDate"
                       [shIdPostfix]="'breeding_add_female_preg_check_time_pregnancy_check_tab_time_picker'"
                       (epochChanged)="onPregCheckDateChanged($event)"></time-picker>
        </div>
      </div>
    </div>
  </div>
</div>
