import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksComponent } from './tasks/tasks.component';
import { TaskEditorComponent } from './task-editor/task-editor.component';
import {LedTasksRoutingModule} from './led-tasks-routing.module';
import {ShSharedModule} from '../../common/sh-shared.module';
import { NoTasksListComponent } from './tasks/no-tasks-list/no-tasks-list.component';
import { DayItemComponent } from './task-editor/day-item/day-item.component';
import {FormsModule} from '@angular/forms';
import { TaskEditorEditBarComponent } from './task-editor/task-editor-edit-bar/task-editor-edit-bar.component';
import { LedTasksEditorContainerComponent } from './task-editor/led-tasks-editor-container/led-tasks-editor-container.component';
import {LedTaskEditorLeftContainerComponent} from './task-editor/led-tasks-editor-container/led-task-editor-left-container/led-task-editor-left-container.component';
import {LedTaskEditorRightReportsContainerComponent} from './task-editor/led-tasks-editor-container/led-task-editor-right-reports-container/led-task-editor-right-reports-container.component';
import { ReportsContainerComponent } from './task-editor/led-tasks-editor-container/led-task-editor-left-container/reports-container/reports-container.component';
import { AnimalsContainerComponent } from './task-editor/led-tasks-editor-container/led-task-editor-left-container/animals-container/animals-container.component';
import { LedTaskEditorRightAnimalsContainerComponent } from './task-editor/led-tasks-editor-container/led-task-editor-right-animals-container/led-task-editor-right-animals-container.component';
import { ReportRowComponent } from './tasks/report-row/report-row.component';
import { AnimalRowComponent } from './tasks/animal-row/animal-row.component';

@NgModule({
  declarations: [
    TasksComponent,
    TaskEditorComponent,
    NoTasksListComponent,
    DayItemComponent,
    TaskEditorEditBarComponent,
    LedTasksEditorContainerComponent,
    LedTaskEditorLeftContainerComponent,
    LedTaskEditorRightReportsContainerComponent,
    ReportsContainerComponent,
    AnimalsContainerComponent,
    LedTaskEditorRightAnimalsContainerComponent,
    ReportRowComponent,
    AnimalRowComponent
  ],
  imports: [
    ShSharedModule,
    CommonModule,
    LedTasksRoutingModule,
    FormsModule
  ]
})
export class LedTasksModule { }
