import * as moment from 'moment';
import { Component, Input } from '@angular/core';
import {
  CreateDoNotBreedEventDetails,
  DoNotBreedCowEventMetadata
} from '../../../../../services/animals/model/animal-events';
import {EventFormComponent} from '../event-form.component';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../../../common/components/calendar/model/epoch-state-model';

@Component({
  selector: 'do-not-breed-event-form',
  templateUrl: './do-not-breed-event-form.component.html',
  styleUrls: ['./do-not-breed-event-form.component.scss']
})
export class DoNotBreedEventFormComponent extends EventFormComponent<DoNotBreedCowEventMetadata, CreateDoNotBreedEventDetails> {

  public expirationDate : EpochStateModel;

  constructor() {
    super();
    this.expirationDate = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.expirationDate.min = moment().add(1, 'day').startOf('day').unix();
  }

  public onSelectedTimeChanged(selectedEpoch: EpochStateModel) {
    this.editModel.expirationDate = selectedEpoch.epochDate;
    this.emitModelChanged();
  }
}
