import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {AccordionWidgetViewState} from '../../dashboard-accordion.component';
import {FarmMode} from '../../../../../services/config/model/server-config';
import {IReport} from '../../../../../services/reports/model/report.interface';
import {ConfigService} from '../../../../../services/config/config.service';
import {PrintReportService} from '../../../../../services/export/print-report.service';
import {GoogleAnalyticsService} from '../../../../../services/google-analytics/google-analytics.service';
import {IDashboardWidget} from '../../../../../services/dashboard/model/dashboard-widgets.interface';

@Component({
  selector: 'accordion-report-widget',
  templateUrl: './accordion-report-widget.component.html',
  styleUrls: ['./accordion-report-widget.component.scss']
})
export class AccordionReportWidgetComponent implements OnInit {

  @Input()
  public dashboardWidget:IDashboardWidget;

  @Input()
  public report: IReport;

  public farmMode: FarmMode;

  constructor(private readonly configService: ConfigService) { }

  public ngOnInit() {
    this.farmMode = this.configService.serverConfig.farmMode;
  }

  public get reportHasData() : boolean {
    return !this.dashboardWidget.isSuspended && this.report.rows.length > 0;
  }
}
