<description-cell-details-template [hasRowDetails]="row.currentGroupName"
                                   [isRowExpanded]="isExpanded"
                                   [row]="row"
                                   [rowShId]="row.eventId"
                                   (toggleExpanded)="toggleExpanded()">
  <ng-container key>{{"ANIMAL.EVENTS.EVENT_DESCRIPTION.NEW_GROUP"| translate}}:&nbsp;</ng-container>
  <ng-container value>{{row.newGroupName | textLimit:20:true}}</ng-container>
  <ng-container content>
    <p class="description-item" *ngIf="row.currentGroupName">
      <span>{{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.OLD_GROUP' | translate}}:&nbsp;</span>
      <ellipsisable-text class="value">{{row.currentGroupName}}</ellipsisable-text>
    </p>
  </ng-container>
</description-cell-details-template>
