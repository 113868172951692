import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FarmMode} from '../../../../services/config/model/server-config';
import {NotificationDialogTemplateComponent, NotificationDialogTheme} from "../../../../common/components/dialogs/notification-dialog-template/notification-dialog-template.component";
import {IDialog} from '../../../../services/dialogs/model/dialog.model';

@Component({
  selector: 'trial-farm-selection-modal',
  templateUrl: './trial-farm-selection-modal.component.html',
  styleUrls: ['./trial-farm-selection-modal.component.scss']
})
export class TrialFarmSelectionModalComponent implements IDialog, OnInit {

  @ViewChild('dialogRef', {static: true})
  public dialogRef: NotificationDialogTemplateComponent;

  @Input()
  public farmMode: FarmMode;

  @Output() // tslint:disable-next-line:no-any
  public onGoToDashboardClicked: EventEmitter<any> = new EventEmitter<any>();
  
  public NotificationDialogTheme = NotificationDialogTheme;
  
  public farmModes = FarmMode;

  public ngOnInit() {
  }

  public goToDashboard() {
    this.onGoToDashboardClicked.emit();
  }
  
  public showDialog() {
    this.dialogRef.isOpen = true;
  }
  
  public closeDialog() {
    this.dialogRef.isOpen = false;
  }
}
