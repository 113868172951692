<div class="add-group-wrapper" nonExternalUser>
  <div class="add-group-btn" [ngClass]="{ addGroupOpen: isOpen, breeding: (applicationBundleRoutingMode === ApplicationBundleRoutingMode.Breeding &&
                                                                                   !isOpen),
                                                                        finishing: (applicationBundleRoutingMode === ApplicationBundleRoutingMode.Finishing &&
                                                                        !isOpen)  }" [attr.sh-id]="'add-group-button'" (click)="toggleGroup()" saveDataWarningInject>
    <div class="add-icon" *ngIf="!isOpen"></div>
    <div class="remove-icon" *ngIf="isOpen"></div>
  </div>
</div>
<group-editor-breeding [isOpen]="isOpen"
                       (groupAddedOrChanged)="onGroupAddedOrChanged($event)"
                       *ngIf="(applicationBundleRoutingMode === null || applicationBundleRoutingMode === applicationBundleRoutingModes.Breeding)"></group-editor-breeding>
<group-editor-finishing [isOpen]="isOpen"
                        (groupAddedOrChanged)="onGroupAddedOrChanged($event)"
                        *ngIf="applicationBundleRoutingMode === applicationBundleRoutingModes.Finishing"></group-editor-finishing>
