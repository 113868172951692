import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {IChartDataItem} from './farm-statistics-beef-pie-chart/farm-statistics-beef-pie-chart.component';
import {Subscription} from 'rxjs';
import {FarmStatisticsBeefWidget, IMaleFemale} from '../../../../../../services/dashboard/model/farm-statistics-beef';
import {PollingKeyNames, PollingService} from '../../../../../../services/rest-api/polling.service';
import {DashboardService} from '../../../../../../services/dashboard/dashboard.service';

@Component({
  selector: 'farm-statistics-beef',
  templateUrl: './farm-statistics-beef.component.html',
  styleUrls: ['./farm-statistics-beef.component.scss']
})
export class FarmStatisticsBeefComponent implements OnChanges {

  @Input()
  public farmStatistics: FarmStatisticsBeefWidget;

  public columnChartType = ColumnChartType;

  public chartData: IChartDataItem[][] = [];

  constructor(private dashboardService: DashboardService,
              private pollingService: PollingService) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if(this.farmStatistics != null) {
      this.chartData = this.initializeChartData();
    } else {
      this.chartData = null;
    }
  }

  public initializeChartData(): IChartDataItem[][] {
    // build the chart data with coloring for display
    let chartDataToReturn: Array<IChartDataItem[]>;
    chartDataToReturn = [];
    chartDataToReturn[0] = [
    {
      value: this.farmStatistics.females.femalesVwp.pregnant,
      title: 'vwp',
      color: '#AA64AA'
    },
    {
      value: this.farmStatistics.females.femalesCalvingCount0.pregnant,
      title: 'pregnant',
      color: '#1999d5'
    },
    {
      value: (this.farmStatistics.females.femalesCalvingCount0.total + this.farmStatistics.females.femalesVwp.total > 0 ? ((this.farmStatistics.females.femalesCalvingCount0.total + this.farmStatistics.females.femalesVwp.total) - (this.farmStatistics.females.femalesVwp.pregnant + this.farmStatistics.females.femalesCalvingCount0.pregnant)) : 100),
      title: 'not pregnant',
      color: '#d4d4d4'
    }];
    chartDataToReturn[1] = [{
      value: this.farmStatistics.females.femalesCalvingCount1.pregnant,
      title: 'pregnant',
      color: '#1999d5'
    },
    {
      value: this.farmStatistics.females.femalesCalvingCount1.total > 0 ? (this.farmStatistics.females.femalesCalvingCount1.total - this.farmStatistics.females.femalesCalvingCount1.pregnant) : 100,
      title: 'not pregnant',
      color: '#d4d4d4'
    }];
    chartDataToReturn[2] = [{
      value: this.farmStatistics.females.femalesCalvingCount2.pregnant,
      title: 'pregnant',
      color: '#1999d5'
    },
    {
      value: this.farmStatistics.females.femalesCalvingCount2.total > 0 ? (this.farmStatistics.females.femalesCalvingCount2.total - this.farmStatistics.females.femalesCalvingCount2.pregnant) : 100,
      title: 'not pregnant',
      color: '#d4d4d4'
    }];
    chartDataToReturn[3] = [{
      value: this.farmStatistics.females.femalesCalvingCount3.pregnant,
      title: 'pregnant',
      color: '#1999d5'
    },
    {
      value: this.farmStatistics.females.femalesCalvingCount3.total > 0 ? (this.farmStatistics.females.femalesCalvingCount3.total - this.farmStatistics.females.femalesCalvingCount3.pregnant) : 100,
      title: 'not pregnant',
      color: '#d4d4d4'
    }];
    return chartDataToReturn;
  }

  public getColumnHeight(columnType: ColumnChartType, value: IMaleFemale): string {
    let valueToReturn = '0%';
    const totalValue: number = value.male + value.female;
    switch (columnType) {
      case ColumnChartType.Male: {
        valueToReturn = `${((value.male / totalValue) * 100) ? ((value.male / totalValue) * 100) : 0}%`;
        break;
      }
      case ColumnChartType.Female: {
        valueToReturn = `${((value.female / totalValue) * 100) ? ((value.female / totalValue) * 100) : 0}%`;
        break;
      }
    }
    return valueToReturn;
  }

  public getValueHeight(columnType: ColumnChartType, value: IMaleFemale): string {
    let valueToReturn = `0px`;
    const totalValue: number = value.male + value.female;
    switch (columnType) {
      case ColumnChartType.Male: {
        // margin-top 53% = no male
        // margin-top 0 = there is at least 1 male
        valueToReturn = `${value.male > 0 ? 0 : 83}px`;
        break;
      }
      case ColumnChartType.Female: {
        // margin-top 53% = no females
        // margin-top 0% = all females
        // if value is between 0 and 53 there are both males and females calves at the column chart and height should be calculated
        valueToReturn = `${((value.male / totalValue) * 103) > 0 ? ((value.male / totalValue) * 103 > 83 ? 83 : ((value.male / totalValue) * 103)) : 83}px`;
        break;
      }
    }
    return valueToReturn;
  }

  private isNotZeroCalves(calves: IMaleFemale): number {
    return calves.male + calves.female;
  }
}

export enum ColumnChartType {
  Male = 'Male',
  Female = 'Female'
}
