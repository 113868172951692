<div class="consistency-chart-header" [attr.sh-id]="'branch_card_consistency'">
  <xy-chart-legend #branchConsistencyChartLegend
                   [configuration]="legendConfiguration"
                   [legendStates]="legendStates"></xy-chart-legend>
  <branch-card-animals-summary *ngIf="branchGraphDetails" [branchGraphDetails]="branchGraphDetails"></branch-card-animals-summary>
</div>
<div class="consistency-chart-body">
  <trends-consistency-xy-chart #branchConsistencyChart
                               (chartPointMouseOver)="onChartPointMouseOver($event)"
                               (durationSelected)="onDurationSelected($event)"
                               [attr.sh-id]="'branch_card_consistency_chart'"></trends-consistency-xy-chart>
  <div class="sideBar" [ngStyle]="{'height': (branchConsistencyChart.variabilitySeparatorMarginTop - 9) + 'px'}">
    <div class="label">
      {{daysToShowInSideBar}}
      <span [attr.translation-id]="'BRANCH.GRAPH.DETAILS.DAYS'">
        {{'BRANCH.GRAPH.DETAILS.DAYS'| translate}}
      </span>
      <br/>
      <span [attr.translation-id]="'BRANCH.GRAPH.DETAILS.AVG_GROUP_CONSISTENCY'">
        {{'BRANCH.GRAPH.DETAILS.AVG_GROUP_CONSISTENCY'| translate}}
      </span>
      <div class="valueLabel">
        <div class="value">{{ lastDaysConsistency }}</div>
        <div class="icon icon-{{consistencySeverity}}"></div>
      </div>
    </div>
    <hr/>
    <div class="label" style="top: 60%;">
      {{daysToShowInSideBar}}
      <span [attr.transtion-id]="'BRANCH.GRAPH.DETAILS.DAYS'">
        {{'BRANCH.GRAPH.DETAILS.DAYS'| translate}}
      </span>
      <br/>
      <span [attr.translation-id]="'BRANCH.GRAPH.DETAILS.AVG_DAILY_RUMINATION'">
        {{'BRANCH.GRAPH.DETAILS.AVG_DAILY_RUMINATION'| translate}}
      </span>
      <div class="valueLabel" style="color: #1f9bde;">{{ lastDaysAverage }}</div>
    </div>
  </div>
</div>
