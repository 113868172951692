import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';

export enum ArrowIconColors {
  default = '#1999d5',
  pink = '#c46da9',
  green = '#9fb103'
}

@Component({
  selector: 'numeric-input',
  templateUrl: './numeric-input.component.html',
  styleUrls: ['./numeric-input.component.scss']
})
export class NumericInputComponent implements OnInit, OnChanges {

  @Input()
  public min: number;

  @Input()
  public max: number;

  @Input()
  public arrowIconsColor: ArrowIconColors = ArrowIconColors.default;

  @Input()
  public isFieldError: boolean = false;

  @Input()
  public selectedValue: number;

  @Input()
  public isDisabled: boolean = false;

  @Input()
  public isNullable: boolean = false;

  @Input()
  public isReadonly: boolean = false;

  @Input()
  public canIncrease: boolean = true;

  @Input()
  public canDecrease: boolean = true;

  @Input()
  public isSaveOnFocusOut: boolean = false;

  @Input()
  public shId: string;

  @Input()
  public placeholder: string;

  @Input()
  public hideValueChangeArrows: boolean;

  @Output()
  public onChangeSelectedValue = new EventEmitter<number>();

  @ViewChild('inputRef')
  public inputElement: ElementRef<HTMLInputElement>;

  private nValue: number;

  private specialKeys: Array<string> = [ 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Backspace', 'Tab', 'End', 'Home' ];

  constructor() { }

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.max && changes.max.currentValue !== null && typeof changes.max.currentValue !== 'undefined') {
      this.nValue = this.minmax(this.nValue);
    }
    if (changes.min && changes.min.currentValue !== null && typeof changes.min.currentValue !== 'undefined') {
      this.nValue = this.minmax(this.nValue);
    }
    if (changes.selectedValue) {
      if (this.isSaveOnFocusOut) {
        this.nValue = changes.selectedValue.currentValue;
      } else {
        this.nValue = this.minmax(changes.selectedValue.currentValue);
      }
    }
  }

  public get isActive () {
    return !this.isDisabled && !this.isReadonly;
  }

  public get value () {
    return isNaN(this.nValue) ? '' : this.nValue;
  }

  public set value(value) {
    let num: number;
    if (typeof value === 'number') {
      if (this.isIntegerNumbersOnly(String(value))) {
        num = value;
      } else {
        num = null;
      }
    } else {
      const str = (value || '')
        .toString()
        .split('')
        .filter((c) => c >= '0' && c <= '9')
        .join('');
      if (str.length) {
        num = +str;
      } else {
        num = null;
      }
    }

    if (num > this.max) {
      num = this.max;
    }
    this.nValue = num;
    this.onChangeSelectedValue.emit(this.nValue);
    if (this.nValue == null) {
      this.inputElement.nativeElement.value = null;
    } else {
      this.inputElement.nativeElement.value = this.nValue.toString();
    }
  }

  public isIntegerNumbersOnly(currentNumber: string) {
    let regex = /^[0-9]*$/;
    return regex.test(currentNumber);
  }

  public saveOnFocusOut() {
    if (this.isSaveOnFocusOut) {
      if (this.nValue || String(this.nValue) === '0') {
        this.value = this.minmax(this.nValue);
      }
      if (!this.isNullable && this.nValue == null) {
        if (this.min) {
          this.value = this.min;
        } else {
          this.value = 0;
        }
      }
    }
  }

  public increment () {
    if (this.isActive && this.canIncrease) {
      this.value = this.minmax((+this.value || 0) + 1);
    }
  }

  public decrement () {
    if (this.isActive && this.canDecrease) {
      this.value = this.minmax((+this.value || 0) - 1);
    }
  }

  public onChange(value: number) {
    if (this.isActive) {
      if(value > this.max) {
        this.value = this.max;
      } else {
        this.value = value;
      }
    }
  }

  private minmax (value: number) {
    return Math.max(this.min != null ? this.min : -Infinity, Math.min(this.max != null ? this.max : Infinity, value));
  }

}
