import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IErrors, AddAnimalFieldsErrorsEnum, IGroupItem} from '../../../../../../../services/animals/model/animal.model';
import {AnimalsService} from '../../../../../../../services/animals/animals.service';
import {
  DropdownListAutoSelectionMode,
  DropdownListItem
} from '../../../../../../../common/components/dropdown-list/dropdown-list-model';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../../../../../common/components/calendar/model/epoch-state-model';
import * as moment from 'moment';
import {AddFemaleModel} from '../add-female-breeding.component';
import {ArrowIconColors} from '../../../../../../../common/components/numeric-input/numeric-input.component';
import {FormsDLIconsThemes} from '../../../../../../../common/components/dropdown-list/forms-dropdown-list/forms-dropdown-list.component';

@Component({
  selector: 'add-female-breeding-calving-tab',
  templateUrl: './add-female-breeding-calving-tab.component.html',
  styleUrls: ['./add-female-breeding-calving-tab.component.scss']
})
export class AddFemaleBreedingCalvingTabComponent implements OnInit {

  public dropdownListAutoSelectionMode = DropdownListAutoSelectionMode;

  @Input()
  public addFemaleModel: AddFemaleModel;

  @Input()
  public groupsDropdownListItems: DropdownListItem[] = [];

  @Input()
  public errors: IErrors[] = [];

  @Output()
  public onGroupSelected : EventEmitter<IGroupItem> = new EventEmitter<IGroupItem>();

  @Output()
  public onErrorsClear: EventEmitter<void> = new EventEmitter<void>();

  public AddAnimalFieldsErrorsEnum = AddAnimalFieldsErrorsEnum;

  public ArrowIconColors = ArrowIconColors;

  public formsDLIconsThemes = FormsDLIconsThemes;

  constructor(public readonly animalsService: AnimalsService) { }

  public ngOnInit() {
  }

  public onLactationNumberChanged(selectedLactationNumber: number) {
    if (moment.unix(this.addFemaleModel.birthdate.epochDate).diff(moment(), 'days') == 0) {
      this.addFemaleModel.lactationNumber = 0;
    } else {
      this.addFemaleModel.lactationNumber = selectedLactationNumber;
    }
    if (!this.addFemaleModel.lactationNumber || this.addFemaleModel.lactationNumber === 0) {
      this.addFemaleModel.lastCalvingDate = new EpochStateModel(EpochStateModelMinMaxMode.Date);
      this.addFemaleModel.lastCalvingDate.max = moment().unix();
    } else {
      if(this.addFemaleModel.lastCalvingDate.epochTime == null) {
        this.addFemaleModel.lastCalvingDate.epochTime = moment().unix();
      }
    }
    this.onErrorsClear.emit();
  }

  public onBirthdateFieldChanged(event: EpochStateModel) {
    this.addFemaleModel.birthdate = event;
    this.addFemaleModel.updateDatesRanges();
    this.onErrorsClear.emit();
  }

  public onCalvingDateFieldChanged(event: EpochStateModel) {
    this.addFemaleModel.lastCalvingDate = event;
    this.addFemaleModel.updateDatesRanges();
    this.onErrorsClear.emit();
  }
}
