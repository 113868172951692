import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'update-software-modal',
  templateUrl: './update-software-modal.component.html',
  styleUrls: ['./update-software-modal.component.scss']
})
export class UpdateSoftwareModalComponent implements OnInit {

  @Input()
  public isModalOpen: boolean = false;

  @Output() // tslint:disable-next-line:no-any
  public onCloseModalDialog: EventEmitter<any> = new EventEmitter<any>();

  @Output() // tslint:disable-next-line:no-any
  public onApplyModalDialog: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  public ngOnInit() {
  }

  public closeDialog() {
    this.onCloseModalDialog.emit();;
  }

  public apply() {
    this.onApplyModalDialog.emit();
  }
}
