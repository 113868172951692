import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {EpochStateModel} from '../model/epoch-state-model';
import * as moment from 'moment';
import {TranslationService} from '../../../../services/translations/translation.service';
import {TimeSelectionHoursMode} from '../time-selection/time-selection.component';

@Component({
  selector: 'date-time-dialog',
  templateUrl: './date-time-dialog.component.html',
  styleUrls: ['./date-time-dialog.component.scss']
})
export class DateTimeDialogComponent {

  @Input()
  public epochState : EpochStateModel;

  @Input()
  public title : string;

  @Output()
  public onApply : EventEmitter<EpochStateModel> = new EventEmitter<EpochStateModel>();

  @Output()
  public onCancel : EventEmitter<void> = new EventEmitter<void>();

  public isOpen : boolean;

  constructor(public readonly translationService: TranslationService) { }

  public openDialog() {
    this.isOpen = true;
  }

  public get hoursMode() : TimeSelectionHoursMode {
    if(this.translationService.selectedLanguage == 'en-us') {
      return TimeSelectionHoursMode.Mode12Hours;
    } else {
      return TimeSelectionHoursMode.Mode24Hours;
    }
  }

  public get selectedEpochDate() : Date {
    return this.epochState.date;
  }

  public closeClick() {
    this.isOpen = false;
    this.onCancel.emit();
  }

  public applyClick() {
    this.isOpen = false;
    this.onApply.emit(this.epochState);
  }

  public onDateChanged(date:Date) {
    this.epochState.date = date;
    if(this.epochState.epochTime == null) {
      this.epochState.epochTime = 0;
    }
  }

  public onEpochTimeChanged(selectedEpoch:EpochStateModel) {

  }
}
