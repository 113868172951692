import { Component, OnInit, ViewChild } from '@angular/core';
import {Subscription} from 'rxjs';
import {
  CreateChangeGroupEventDialogModel
} from '../../../../services/dialogs/model/dialog-actions-model';
import {DialogActionsService} from '../../../../services/dialogs/dialog-actions.service';
import {AnimalEventsService} from '../../../../services/animals/animal-events.service';
import {EventActionDetails} from '../../../../services/animals/model/animal-events';
import * as moment from 'moment';
import {DropdownListItem} from '../../dropdown-list/dropdown-list-model';
import {RegularDropdownListComponent} from "../../dropdown-list/regular-dropdown-list/regular-dropdown-list.component";

@Component({
  selector: 'change-group-dialog',
  templateUrl: './change-group-dialog.component.html',
  styleUrls: ['./change-group-dialog.component.scss']
})
export class ChangeGroupDialogComponent implements OnInit {

  private dialogActionsSubscription: Subscription;

  public isOpen: boolean;

  public model: CreateChangeGroupEventDialogModel;

  public groupDropdownListItems: DropdownListItem[];

  public selectedGroup: DropdownListItem = null;

  public error: string;

  public isApplying: boolean;

  @ViewChild('groupSelectionDropdown')
  public regularDropdownListComponent: RegularDropdownListComponent;

  constructor(private readonly dialogActionsService: DialogActionsService,
              private readonly animalEventsService: AnimalEventsService) { }

  public ngOnInit() {
    // tslint:disable-next-line:no-any
    this.dialogActionsSubscription = this.dialogActionsService.changeGroupDialogSubject.subscribe((model: any) => {
      this.model = model;
      this.groupDropdownListItems = this.model.groups.map(value => {
        return {
          value: value.id,
          displayValue: value.name,
          disabled: false,
          icon: null
        }});
      this.isOpen = true;
      this.error = null;
      setTimeout(() => {
        this.regularDropdownListComponent.selectItem(null, null);
      });
    });
  }

  public get isApplyDisabled () {
    return !this.model.eventDetails.newGroupId;
  }

  public closeClick() {
    if(this.model) {
      this.isOpen = false;
      this.error = null;
    }
  }

  private async applyClick() {
    if(this.isApplying) {
      return;
    }
    this.isApplying = true;
    let eventActionDetails: EventActionDetails;
    eventActionDetails = new EventActionDetails();
    eventActionDetails.startDateTime =  moment().unix();

    let createAnimalEventResponse = await this.animalEventsService.createAnimalEvent(this.model.animalId, eventActionDetails, this.model.eventDetails);
    this.isApplying = false;
    if (createAnimalEventResponse.status === 201) {
      this.isOpen = false;
      this.error = null;
      await this.model.actionsContext.reloadDefault();
    } else {
      this.error = createAnimalEventResponse.errorResponseBody.result.failures[0].key;
    }
  }

  public onGroupSelected(group: DropdownListItem) {
    this.model.eventDetails.newGroupId = group ? group.value : null;
  }

}
