<div class="group-card-details-header" *ngIf="groupDetails">
  <span>
    <span [attr.translation-id]="'MANAGE.GROUPS.HEADER.GROUP_NUMBER'">{{ "MANAGE.GROUPS.HEADER.GROUP_NUMBER" | translate }}:</span>
    <span class="value">{{ groupDetails.groupNumber }}</span>
    <span [attr.translation-id]="'MANAGE.GROUPS.HEADER.BRANCH'">{{ "MANAGE.GROUPS.HEADER.BRANCH" | translate }}:</span>
    <span class="value">{{ getGroupBranchText }}</span>
  </span>
  <grid-actions-dropdown [prefixShId]="'group-card'" [dataSource]="this"></grid-actions-dropdown>
</div>

<ng-template #groupAnimalsColumn  let-column="column">
  <ng-container [ngSwitch]="column">
    <div *ngSwitchCase="'animalName'" class="header-cell">
      <b [attr.translation-id]="'GROUP.TABLE.HEADER.COW'">{{'GROUP.TABLE.HEADER.COW' | translate}}</b>
      <span [attr.translation-id]="'GROUP.TABLE.HEADER.COW_TOTAL'">
        {{'GROUP.TABLE.HEADER.COW_TOTAL' | translate}}:<div class="value">{{finishingGroupAnimals.numberOfAnimals}}</div>
      </span>
    </div>
    <div *ngSwitchCase="'lifeCycleStatus'" class="header-cell">
      <b [attr.translation-id]="'GROUP.TABLE.HEADER.STATUS'">{{'GROUP.TABLE.HEADER.STATUS' | translate}}</b>
    </div>
    <div *ngSwitchCase="'ageOrDaysSinceEntry'" class="header-cell">
      <b [attr.translation-id]="'GROUP.TABLE.HEADER.AGE_ENTRANCE'">{{'GROUP.TABLE.HEADER.AGE_ENTRANCE' | translate}}</b>
      <span [attr.translation-id]="'GROUP.TABLE.HEADER.LACTATION_AVERAGE'">
        {{'GROUP.TABLE.HEADER.LACTATION_AVERAGE' | translate}}:<div class="value">{{finishingGroupAnimals.averageAgeOrEntry}}</div>
      </span>
    </div>
    <div *ngSwitchCase="'scrTagNumber'" class="header-cell">
      <b [attr.translation-id]="'GROUP.TABLE.HEADER.SCR_TAG'">{{'GROUP.TABLE.HEADER.SCR_TAG' | translate}}</b>
      <span [attr.translation-id]="'GROUP.TABLE.HEADER.SCR_TAG_TOTAL'">
        {{'GROUP.TABLE.HEADER.SCR_TAG_TOTAL' | translate}}:<div class="value">{{finishingGroupAnimals.totalScrTag}}</div>
      </span>
    </div>
    <div *ngSwitchCase="'actions'" class="header-cell">

    </div>
  </ng-container>
</ng-template>

<ng-template #groupAnimalsCell let-column="column" let-row="row">
  <ng-container [ngSwitch]="column">
    <div *ngSwitchCase="'lifeCycleStatus'" (click)="onCellClick(row)" class="lactation-status">
      <animal-lifecycle-status-icon [status]="row[column]"></animal-lifecycle-status-icon>
      <span [attr.translation-id]="'ANIMAL.TIME_LINE.STATUS.' + row[column]">{{'ANIMAL.TIME_LINE.STATUS.' + row[column] | translate }}</span>
    </div>
    <div *ngSwitchCase="'actions'" class="actions-cell">
      <row-actions-popup [rowActions]="rowActionsMap.get(row)"
                         (onRowActionClick)="handleRowAction($event.rowActionPopupItem.action, row)"></row-actions-popup>
    </div>
    <div *ngSwitchCase="'scrTagNumber'" class="value-cell">
      <div class="tag-extist" *ngIf="row[column] != null">
        {{row[column]}}
      </div>
      <div class="missing-tag" *ngIf="configService.serverConfig &&
                                      configService.serverConfig.thirdPartyParameters.assignTag &&
                                      !row[column] &&
                                      row['assignedScrTagEnabled']"
           (click)="handleRowAction(GroupCardDetailsAnimalRowActionType.assignTag, row)">
        <div class="f1-icon" class="icon-assignTag"></div>
        <div class="assign-title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.ASSIGN_TAG.TITLE'">{{ 'MANAGE.COWS.ACTIVE.ASSIGN_TAG.TITLE' | translate }}</div>
      </div>
    </div>
    <div *ngSwitchCase="'ageOrDaysSinceEntry'">
      {{row['ageOrDaysSinceEntry']}}
    </div>
    <div class="value-cell" *ngSwitchDefault (click)="onCellClick(row)">
      {{row[column]}}
    </div>
  </ng-container>
</ng-template>

<data-grid #groupCardAnimalsSortedGrid
           [attr.sh-id]="'group-card-finishing-table'"
           *ngIf="finishingGroupAnimals"
           [headerHeight]="80"
           [rowHeight]="60"
           [viewContext]="this"
           [sortedGridColumn]="groupAnimalsColumn"
           [sortedGridCell]="groupAnimalsCell"
           [sortedGridQuery]="animalsSortedGridQuery"
           [columnsMappings]="columnsMappings"
           [totalRows]="finishingGroupAnimals.numberOfAnimals"
           [rowsData]="finishingGroupAnimals.animals">
</data-grid>
