<form class="create-event-form">
  <div class="form-section-title">
    {{ 'ANIMAL.EVENTS.ADD_EVENT.NEW_TAG_NUMBER' | translate }}
  </div>
  <div class="form-section-item">
    <regular-auto-complete
      class="form__control"
      name="tag"
      [items]="unassignedTags"
      (searchQueryChanged)="onSearchQueryChanged($event)"
      [placeholder]="'Select Tag'"
      [isAllowUnmatchedSelection]="true"
      [maxResults]="6"
      [isFieldError]="errorModel.fieldName === 'tag'"
      [shIdPostfix]="'tagNumber'"
      required
    ></regular-auto-complete>
  </div>
</form>
