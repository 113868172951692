import {Component, Input, ViewChild} from "@angular/core";
import {NotificationDialogTemplateComponent, NotificationDialogTheme} from "../../../../common/components/dialogs/notification-dialog-template/notification-dialog-template.component";
import {IDialog} from '../../../../services/dialogs/model/dialog.model';

@Component({
  selector: 'system-management-error-modal',
  templateUrl: './system-management-error-modal.component.html',
  styleUrls: ['./system-management-error-modal.component.scss']
})
export class SystemManagementErrorModalComponent  implements IDialog{
  
  @ViewChild(NotificationDialogTemplateComponent)
  protected dialogRef: NotificationDialogTemplateComponent;

  @Input() //tslint:disable-next-line:no-any
  public errorModalData: any;
  
  public NotificationDialogTheme = NotificationDialogTheme;
  
  public closeDialog() {
    this.dialogRef.isOpen = false;
  }
  
  public showDialog() {
    this.dialogRef.isOpen = true;
  }
}
