<div class="list-column list-column__shift-name" [ngClass]="{'disabled': true}">
  <input type="text"
         [ngModel]="shift.name"
         [maxLength]="20"
         [disabled]="isDisabled"
         (ngModelChange)="raiseChangeName($event)"
         name="shiftName" required>
</div>
<div class="list-column list-column__start-time">
  <button class="button button-raise-up" [disabled]="isDisabled" (click)="raiseSlideStartTime(SlideStartTimeDirection.Up)">
    <i class="icon icon-editor-drop-down"></i>
  </button>
  <span class="shift-time-text">{{ shift.startTime | epochDateTime:DateTimeFormatEnum.Time24Hours:false: true}}</span>
  <button class="button button-raise-down" [disabled]="isDisabled" (click)="raiseSlideStartTime(SlideStartTimeDirection.Down)">
    <i class="icon icon-editor-drop-down"></i>
  </button>
</div>
<div class="list-column list-column__end-time">
  <span class="shift-time-text">{{ shift.endTime | epochDateTime:DateTimeFormatEnum.Time24Hours:false: true}}</span>
</div>
<div class="list-column list-column__actions">
  <button class="button button-remove" [disabled]="isDisabled" *ngIf="allowRemove" (click)="raiseRemoveShift()">
    <i class="icon icon-remove"></i>
  </button>
</div>
