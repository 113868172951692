<div class="graph-enhanced-bar">
  <div class="enhanced-bar-wrapper" #enhancedBarWrapper>
    <div class="enhanced-bar" *ngFor="let section of details.graph.sections; let i = index" [ngStyle]="{ background: dashboardKpiService.setGraphSectionColor(section.colorCode), width: section.sectionSize + '%'}">
      <div class="min-section-text">
        <div class="text">{{ dashboardKpiService.showGradientValue(details.mainValue.type, section.minSectionLimit) }}</div>
      </div>
      <div class="max-section-text" *ngIf="i === (details.graph.sections.length - 1)">
        <div class="text">{{ dashboardKpiService.showGradientValue(details.mainValue.type, section.maxSectionLimit) }}</div>
      </div>
    </div>
    <div class="triangle-cone">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="1 0 308 16" preserveAspectRatio="none slice">
        <path fill="#FFF" d="M4 16L312 16 312 17 4 32z" transform="translate(-4 -16)"/>
      </svg>
    </div>
    <div class="value" *ngIf="(details.mainValue && details.mainValue.value !== null)"
                       [ngStyle]="{ left: (valuePosition - 12) + 'px'}"></div>
  </div>
</div>
