<div outsideClickHandler (onOutsideClick)="onOutsideClick()">
  <div class="tags-update-header" *ngIf="isUpdateVisible" (click)="toggleTagsUpdateDialog()">
    <div class="icon-tagSwUpdate" *ngIf="status !== UpdateStatusType.inProgress"></div>
    <div class="tag-animated" *ngIf="status === UpdateStatusType.inProgress"></div>
    <div class="shadow" *ngIf="isOpen"></div>
  </div>
  <div class="tags-update-popup" *ngIf="isOpen" @tags-update-popup-transition sh-id="tags-update-popup">
    <div class="frame-corner"></div>
    <div class="tags-update-popup-title">
      <div class="title-text">{{ 'TAGS_UPDATE.NEW_TAG_SW_VERSION' | translate }}</div>
      <div class="shadow"></div>
    </div>
    <div class="tags-update-popup-content">
      <ul>
        <li class="tag-type-details" *ngFor="let tagToUpdateVer of tagsToUpdateVersions.versions">
          <span class="icon-tagSwUpdate"></span>
          <span class="tag-type">{{'TAGS_UPDATE.TAG_TYPE.' + tagToUpdateVer.type | translate}}</span>
          <span class="tag-version">{{tagToUpdateVer.version}}</span>
          <span class="tag-update-downloaded">{{ tagToUpdateVer.lastDownloadDate | epochDateTime:[DateTimeFormatEnum.MediumDate] }}</span>
        </li>
      </ul>
      <div class="not-started-state" *ngIf="status === UpdateStatusType.notStarted">
        <div class="toggle-value">
          <div class="toggle-text">{{'TAGS_UPDATE.UPDATE_ASSIGNED_TAGS_ONLY' | translate }}
            <toggle [Toggle]="updateAssignedTagsOnly" (onToggleChanged)="updateAssignedTagsOnly = $event"></toggle>
          </div>
        </div>
        <div class="update-info-details">{{'TAGS_UPDATE.DURING_UPDATE_INFO' | translate }}</div>
        <input class="update-action login-btn" type="button" value="{{'TAGS_UPDATE.START_UPDATE' | translate}}" (click)="startUpdateClick()" />
      </div>
      <div class="in-progress-state" *ngIf="status === UpdateStatusType.inProgress">
        <div class="progress-loader"></div>
        <div class="update-info-details">{{'TAGS_UPDATE.DURING_UPDATE_INFO' | translate}}</div>
        <input class="update-action login-btn" type="button" value="{{'TAGS_UPDATE.STOP_UPDATE' | translate}}" (click)="stopUpdateClick()" />
      </div>
    </div>
  </div>

  <modal-dialog-template *ngIf="dialogTagsUpdateSummary"
                         [isOpen]="dialogTagsUpdateSummary"
                         [modalWidth]="580"
                         [modalHeight]="200">
    <div class="tags-update-summary-dialog">
      <div class="dialog-close" (click)="dialogTagsUpdateSummary = null">
        <div class="icon-close"></div>
      </div>
      <div class="dialog-header">
        <div class="title-wrapper"><span class="icon-tagSwUpdate"></span>
          <div class="title">{{'DIALOG.COMMON.UPDATE_BATCH.UPDATE_BATCH_COMPLETED' | translate}}</div>
        </div>
      </div>
      <div class="dialog-body">
        <div class="description">
          {{dialogTagsUpdateSummary.completed | epochDateTime:DateTimeFormatEnum.DateTime }}
          <span class="separator">|</span>
          <span class="bold-number">{{dialogTagsUpdateSummary.tagsUpdated}}</span>
          <span>{{'DIALOG.COMMON.UPDATE_BATCH.OUT_OF' | translate}}</span>
          <span class="bold-number">{{dialogTagsUpdateSummary.tagsToUpdate}}</span>
          <span>{{'DIALOG.COMMON.UPDATE_BATCH.TAGS_UPDATED' | translate}}</span>
        </div>
      </div>
    </div>
  </modal-dialog-template>

  <modal-dialog-template *ngIf="showUpdateErrorDialog"
                         [isOpen]="showUpdateErrorDialog"
                         [modalWidth]="580"
                         [modalHeight]="200">
    <div class="update-error-dialog">
      <div class="dialog-close" (click)="showUpdateErrorDialog = false">
        <div class="icon-close"></div>
      </div>
      <div class="dialog-header">
        <div class="title-wrapper">
          <span class="icon-alert"></span>
          <div class="title">{{'DIALOG.COMMON.UPDATE_BATCH_FAILED.AN_ERROR_HAS_OCCURRED_DURING_UPDATE' | translate}}</div>
        </div>
      </div>
      <div class="dialog-body">
        <div class="form-group">
          <span class="form-group-label">{{'DIALOG.COMMON.UPDATE_BATCH_FAILED.TRY_TO_START_THE_TAG_UPDATE_AGAIN_IN_A_FEW_MINUTES' | translate}}</span>
        </div>
      </div>
    </div>
  </modal-dialog-template>
</div>
