import * as moment from 'moment';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import {SchedulerShiftModel} from '../sorting-gate/model/scheduler-model';
import {ShiftModel} from '../sorting-gate/model/shifts-model';

const DURATION_OF_HOUR: number = moment.duration(1, 'hours').asMilliseconds();
const DURATION_OF_DAY: number = moment.duration(1, 'day').asMilliseconds();

@Injectable({
  providedIn: 'root'
})
export class ManageSettingsShiftsService {

  public increaseStartTime(startTime: number): number {
    let newStartTime: number = startTime + DURATION_OF_HOUR;

    if (newStartTime >= DURATION_OF_DAY) {
      newStartTime %= DURATION_OF_DAY;
    }

    return newStartTime;
  }

  public decreaseStartTime(startTime: number): number {
    let newStartTime: number = startTime - DURATION_OF_HOUR;

    if (newStartTime < 0) {
      newStartTime = DURATION_OF_DAY - DURATION_OF_HOUR;
    }

    return newStartTime;
  }

  public updateEndTimes(shifts: SchedulerShiftModel[]): SchedulerShiftModel[] {
    for (let i = 0; i < shifts.length; i++) {
      const currentShift = shifts[i];
      const firstShift = shifts[0];
      const nextShift = shifts[i + 1] || firstShift;

      currentShift.endTime = nextShift.startTime;
    }

    return shifts;
  }

  public arrangeShifts(shifts: ShiftModel[], direction: 'up'|'down', changedIndex: number): ShiftModel[] {
    shifts = _.cloneDeep(shifts);
    for (let i = shifts.length + changedIndex; i > changedIndex; i--) {
      const index = i % shifts.length;
      const currentShift = shifts[index];
      const previousShift = shifts[index - 1] || shifts[shifts.length - 1];

      if (currentShift.startTime == null) {
        currentShift.startTime = previousShift !== currentShift ?
          this.increaseStartTime(previousShift.startTime) :
          0;

        shifts = this.arrangeShifts(shifts, 'up', i);
      }

      if (
        direction === 'down' &&
        currentShift !== previousShift &&
        this.isShiftTimeEqual(currentShift.startTime, previousShift.startTime)
      ) {
        previousShift.startTime = this.decreaseStartTime(previousShift.startTime);
      }
    }

    if (direction === 'up') {
      for (let i = changedIndex; i < shifts.length + changedIndex; i++) {
        const index = i % shifts.length;
        const currentShift = shifts[index];
        const nextShift = shifts[index + 1] || shifts[0];

        if (
          nextShift &&
          currentShift !== nextShift &&
          this.isShiftTimeEqual(currentShift.startTime, nextShift.startTime)
        ) {
          nextShift.startTime = this.increaseStartTime(currentShift.startTime);
        }
      }
    }
    shifts = this.updateEndTimes(shifts as SchedulerShiftModel[]);

    return shifts;
  }

  private isShiftTimeEqual(left: number, right: number): boolean {
    left = left === DURATION_OF_DAY ? 0 : left;
    right = right === DURATION_OF_DAY ? 0 : right;

    return left === right;
  }
}
