import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from 'rxjs';
import {Failure} from '../../../../services/registration/model/registration.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {
  public popupShown: Subject<IErrorDialogData> = new Subject<IErrorDialogData>();

  public show(headerTranslationKey: string, failures: Failure[], errorTranslationKeysPrepend: string, onClose?: () => void): void {
    this.popupShown.next({ isOpen: true, failures, onClose, headerTranslationKey, errorTranslationKeysPrepend } as IErrorDialogData);
  }

  public hide(): void {
    this.popupShown.next({ isOpen: false, failures: null, errorTranslationKeysPrepend: null, headerTranslationKey: null, onClose: null } as IErrorDialogData);
  }
}

export interface IErrorDialogData {
  isOpen: boolean;
  failures: Failure[];
  errorTranslationKeysPrepend: string;
  headerTranslationKey: string;
  onClose: () => void | null;
}
