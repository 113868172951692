import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IDashboardDetailedKPI, IKPIColorPoint} from '../../../../../services/dashboard/model/dashboard-kpi.interface';
import {DashboardKpiService} from '../../../../../services/dashboard/dashboard-kpi.service';

@Component({
  selector: 'dashboard-kpi-graph-gradient',
  templateUrl: './dashboard-kpi-graph-gradient.component.html',
  styleUrls: ['./dashboard-kpi-graph-gradient.component.scss']
})
export class DashboardKpiGraphGradientComponent implements OnInit {

  @Input()
  public details: IDashboardDetailedKPI;

  @ViewChild('gradientWrapper', {static: true})
  public gradientWrapper : ElementRef<HTMLElement>;

  private _currentValuePosition : number;

  constructor(public readonly dashboardKpiService:DashboardKpiService,
              private readonly changeDetector: ChangeDetectorRef) {

  }

  public ngOnInit() {
  }

  public get valuePosition() : number {
    let newValuePosition : number;
    if(this.gradientWrapper == null) {
      newValuePosition = 0;
    }
    else {
      newValuePosition =  this.dashboardKpiService.getValueRatio(this.details.mainValue.value,
        this.details.graph.sections[0].minSectionLimit,
        this.details.graph.sections[this.details.graph.sections.length - 1].maxSectionLimit);
      newValuePosition = this.gradientWrapper.nativeElement.getBoundingClientRect().left + (this.gradientWrapper.nativeElement.getBoundingClientRect().width * newValuePosition);
    }
    if(newValuePosition != this._currentValuePosition) {
      this._currentValuePosition = newValuePosition;
      this.changeDetector.detectChanges();
    }
    return this._currentValuePosition;
  }

  public createCustomGradient(gradientSettings: IKPIColorPoint[]) {
    let gradientString = '';
    // tslint:disable-next-line:no-any
    gradientSettings.forEach((setting: any) => {
      let currentGradientColor: string = '';
      switch (setting.colorCode) {
        case "Positive": {
          currentGradientColor = '#B3C235';
          break;
        }
        case "Intermediate": {
          currentGradientColor = '#FFCC08';
          break;
        }
        case "Negative": {
          currentGradientColor = '#F04C39';
          break;
        }
      }
      gradientString += `, ${currentGradientColor} ${setting.position}%`;
    });
    return `linear-gradient(to right${gradientString})`;
  }
}
