<ng-content></ng-content>
<div class="icon-container">
  <div class="icon-zoom"
       [ngClass]="{ 'details-toggle-expanded': isRowExpanded,
                    'details-toggle-collapsed' : !isRowExpanded}"
       [attr.sh-id]="'cell_details_button_' + rowShId"
       *ngIf="canExpand"
       (click)="onToggleExpanded()">
  </div>
</div>
