import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth/auth.service';
import {ConfigService} from '../../../services/config/config.service';
import * as moment from 'moment';
import {DateTimeFormatEnum} from 'src/app/common/pipes/epoch-date-time.pipe';
import {SystemStateEnum} from '../../../services/config/model/anonymous-auth-config';

@Component({
  selector: 'user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {

  public userName: string;

  public lastLoggedInTime: number;

  public DateTimeFormatEnum = DateTimeFormatEnum;

  constructor(private router: Router,
              public configService: ConfigService,
              private authService: AuthService) {

  }

  public ngOnInit() {
    this.userName = this.authService.currentUserAuthState.authDetails.userName;
    this.lastLoggedInTime = moment().unix();
  }

  public async onLogoutClick() {
    this.authService.performLogout();
    if (!this.configService.configuration.cloud && this.configService.anonymousAuthResult.systemState === SystemStateEnum.trial) {
      await this.router.navigate(['/registration/initialize-registration']);
    } else if (this.configService.configuration.cloud) {
      window.location.href = '/';
    } else {
      await this.router.navigate(['/login']);
    }
  }
}
