<div class="auto-complete-container">
  <popup-template [isOpen]="resultsExpanded && matchingItems && matchingItems.length > 0" (close)="onOutsideClick()">
    <ng-container header>
      <div class="input-area" [ngClass]="{error: isFieldError, 'blue-white': colorScheme === AutoCompleteColorScheme.BlueWhite, 'regular-with-icon': colorScheme === AutoCompleteColorScheme.WhiteBlackWithIcon}">
        <input class="search"
               #searchInput
               [ngClass]="{'blue-white': colorScheme === AutoCompleteColorScheme.BlueWhite}"
               [value]="value$ | async"
               (input)="onSearchStringInput($event.target.value)"
               (keydown.enter)="onEnter($event)"
               (keydown.arrowdown)="onArrowDown()"
               (keydown.arrowup)="onArrowUp()"
               (focus)="onFocus()"
               [attr.sh-id]="'auto-complete-' + shIdPostfix"
               placeholder="{{ placeholder | translate }}"
               type="text" />
        <div class="search-icon" *ngIf="colorScheme === AutoCompleteColorScheme.WhiteBlackWithIcon && !lastMatchingItem"></div>
        <div class="delete-icon" *ngIf="colorScheme === AutoCompleteColorScheme.WhiteBlackWithIcon && lastMatchingItem" (click)="onRemoveSelection($event)"></div>
      </div>
    </ng-container>
    <ng-container popup-content>
      <div class="dropdown-popup" [ngClass]="{'fold-up': isFoldDirectionUp}">
        <ul class="search-results" [ngStyle]="{ maxHeight: calculateHeight() }">
          <li *ngFor="let matchingResult of matchingItems; let index = index"
              [attr.sh-id]="'auto-complete-' + shIdPostfix + '-' + matchingResult.value"
              (click)="selectItem(matchingResult.value)"
              [class.selected-item]="selectedIndex === index">
            <span class="matching-part">{{ matchingResult.matchingPart }}</span>
            <span class="end-part">{{ matchingResult.endPart }}</span>
          </li>
        </ul>
      </div>
    </ng-container>
  </popup-template>
</div>
