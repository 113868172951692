import {BranchLocalizationModel} from '../branches.service';

export class GroupBranchModel {
  public id: number;
  public groupBranchName: string;
  public groupBranchNumber: number;

  constructor() {
    this.id = undefined;
    this.groupBranchNumber = undefined;
    this.groupBranchNumber = undefined;
  }
}
export interface IBranchGraphDetails {
  numberOfAnimals: number
  numberOfAnimalsWithTags: number
}

export class BranchCardViewState {

  public branchDetails: GroupBranchDetails;

  public selectedTab: BranchCardTabSection = BranchCardTabSection.Details;

  public graphsHeatChartSelectedDuration: number = 5;

  public graphsConsistencySelectedDuration: number = 5;

}

export enum BranchCardTabSection {
  Details = 'Details',
  GraphsConsistency = 'GraphsConsistency',
  GraphsHeatStress = 'GraphsHeatStress'
}

export class GroupBranchDetails implements IGroupBranchDetails {
  public id: number;
  public groupBranchName: BranchLocalizationModel;
  public groupBranchNumber: number;
}

export interface IGroupBranchDetails {
  id: number;
  groupBranchName: BranchLocalizationModel;
  groupBranchNumber: number;
}
