import {Component, Input, OnInit} from '@angular/core';
import {CardViewState, CardViewType} from '../card-popup-model';
import {AnimalSex} from '../../../services/animals/model/animal.model';

@Component({
  selector: 'card-popup-header-title',
  templateUrl: './card-popup-header-title.component.html',
  styleUrls: ['./card-popup-header-title.component.scss']
})
export class CardPopupHeaderTitleComponent implements OnInit {

  @Input()
  public cardViewState: CardViewState;

  public CardViewType = CardViewType;

  public AnimalSex = AnimalSex;

  constructor() { }

  public ngOnInit() {
  }

  public get isAnimalTitleShown(): boolean {
    return !!(this.cardViewState.viewType === CardViewType.Animal && this.cardViewState.animalCardViewState.animalInfo);
  }

  public get isGroupTitleShown(): boolean {
    return !!(this.cardViewState.viewType === CardViewType.Group && this.cardViewState.groupCardViewState.groupDetails);
  }

  public get isBranchTitleShown(): boolean {
    return !!(this.cardViewState.viewType === CardViewType.Branch && this.cardViewState.branchCardViewState.branchDetails);
  }

}
