import {Injectable} from '@angular/core';
import {HttpResponseResult, HttpResponseResultNoBody, RestApiService} from '../rest-api/rest-api.service';
import {
  IAdhocTaskModel,
  IAnimalsToInspectReport,
  IReport,
  IReportColumnDefinition,
  IReportIds,
  IReportMenuItem,
  IReportMetadata,
  IReportRequest,
  IReportsMenuItems,
  ReportDataRow,
  ReportDisplayType,
  ReportNameEnum,
  ReportRequestType
} from './model/report.interface';
import {AuthService} from '../auth/auth.service';
import {
  IncludeAnimalToReportModel,
  ReportQuery,
  ResetAnimalToInspectReportToDefaultModel
} from './model/report-query.interface';
import {HttpParams} from '@angular/common/http';
import {ISyncStatusResults} from '../system/model/system-management';
import {ISelectedLinesReportRequest} from '../../logged-in-layout/reports/reports.component';
import {ApplicationBundleRoutingMode} from '../routing/routing.service';
import {IReportViewContext} from './model/report-view-context';
import {TranslationService} from '../translations/translation.service';
import {TurnLedLightingOnDialogModel} from './model/turn-led-lighting-on-dialog-model';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private static readonly ActiveAnimalsRoute = '/rest/api/animals';
  private static readonly FinishingActiveAnimalsRoute = '/rest/api/finishing/animals';
  private static readonly CulledAnimalsRoute = '/rest/api/animals/culled';
  private static readonly FinishingCulledAnimalsRoute = '/rest/api/finishing/animals/culled';
  private static readonly GroupsRouteV3 = '/rest/api/v3/groups';
  private static readonly GroupsFinishingRouteV3 = '/rest/api/v3/finishing/groups';
  private static readonly ReportsRouteV2 = '/rest/api/v2/reports';
  private static readonly SortingReportRouteV2 = '/rest/api/v2/sorting/report/22';
  private static readonly AdhocTaskRoute = '/rest/api/ledoperation/adhoctask';
  private static readonly FinishingGroupBranches = '/rest/api/finishing/groupbranches';
  private static readonly FinishingBranchGroupsRoute = '/rest/api/finishing/groupbranches/{branchId}/groups';

  public onLoadDefaultReportQuery:ReportQuery;

  constructor(private restApiService: RestApiService, private authService: AuthService, private translationService: TranslationService) { }

  public get defaultReportQuery() : ReportQuery {
    let reportQuery = new ReportQuery();
    reportQuery.offset = 0;
    reportQuery.limit = 30;
    reportQuery.type = ReportDisplayType.full;
    reportQuery.includeFilterMetaData = true;
    return reportQuery;
  }

  public getActiveAnimals(reportQuery: ReportQuery, routingMode: ApplicationBundleRoutingMode): Promise<HttpResponseResult<IReport>> {
    let httpParams = this.convertReportQueryToHttpParams(reportQuery);
    let route = routingMode === ApplicationBundleRoutingMode.Finishing ? ReportsService.FinishingActiveAnimalsRoute : ReportsService.ActiveAnimalsRoute;
    return this.restApiService.sendGetMethod<IReport>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getSortingReport(reportQuery: ReportQuery): Promise<HttpResponseResult<IReport>> {
    let httpParams = this.convertReportQueryToHttpParams(reportQuery);
    return this.restApiService.sendGetMethod<IReport>(ReportsService.SortingReportRouteV2, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getActiveAnimalsIds(reportQuery: ReportQuery, routingMode: ApplicationBundleRoutingMode): Promise<HttpResponseResult<IReportIds>> {
    let httpParams = this.convertReportQueryToHttpParams(reportQuery);
    let route = routingMode === ApplicationBundleRoutingMode.Finishing ? ReportsService.FinishingActiveAnimalsRoute : ReportsService.ActiveAnimalsRoute;
    return this.restApiService.sendGetMethod<IReportIds>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getCulledAnimalsIds(reportQuery: ReportQuery, routingMode: ApplicationBundleRoutingMode): Promise<HttpResponseResult<IReportIds>> {
    let httpParams = this.convertReportQueryToHttpParams(reportQuery);
    let route = routingMode === ApplicationBundleRoutingMode.Finishing ? ReportsService.FinishingCulledAnimalsRoute : ReportsService.CulledAnimalsRoute;
    return this.restApiService.sendGetMethod<IReportIds>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getCulledAnimals(reportQuery: ReportQuery, routingMode: ApplicationBundleRoutingMode): Promise<HttpResponseResult<IReport>> {
    let httpParams = this.convertReportQueryToHttpParams(reportQuery);
    let route = routingMode === ApplicationBundleRoutingMode.Finishing ? ReportsService.FinishingCulledAnimalsRoute : ReportsService.CulledAnimalsRoute;
    return this.restApiService.sendGetMethod<IReport>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getGroups(reportQuery: ReportQuery): Promise<HttpResponseResult<IReport>> {
    let httpParams = this.convertReportQueryToHttpParams(reportQuery);
    return this.restApiService.sendGetMethod<IReport>(ReportsService.GroupsRouteV3, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getFinishingGroups(reportQuery: ReportQuery): Promise<HttpResponseResult<IReport>> {
    let httpParams = this.convertReportQueryToHttpParams(reportQuery);
    return this.restApiService.sendGetMethod<IReport>(ReportsService.GroupsFinishingRouteV3, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getFinishingBranches(reportQuery: ReportQuery): Promise<HttpResponseResult<IReport>> {
    let httpParams = this.convertReportQueryToHttpParams(reportQuery);
    return this.restApiService.sendGetMethod<IReport>(ReportsService.FinishingGroupBranches, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getBranchesIds(reportQuery: ReportQuery): Promise<HttpResponseResult<IReportIds>> {
    let httpParams = this.convertReportQueryToHttpParams(reportQuery);
    return this.restApiService.sendGetMethod<IReportIds>(ReportsService.FinishingGroupBranches, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getBranchGroupsIds(reportQuery: ReportQuery, branchId: number): Promise<HttpResponseResult<IReportIds>> {
    let httpParams = this.convertReportQueryToHttpParams(reportQuery);
    const route = ReportsService.FinishingBranchGroupsRoute.replace('{branchId}', branchId.toString());
    return this.restApiService.sendGetMethod<IReportIds>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getBranchGroups(reportQuery: ReportQuery, branchId: number): Promise<HttpResponseResult<IReport>> {
    let httpParams = this.convertReportQueryToHttpParams(reportQuery);
    const route = ReportsService.FinishingBranchGroupsRoute.replace('{branchId}', branchId.toString());
    return this.restApiService.sendGetMethod<IReport>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getGroupsIds(reportQuery: ReportQuery): Promise<HttpResponseResult<IReportIds>> {
    let httpParams = this.convertReportQueryToHttpParams(reportQuery);
    return this.restApiService.sendGetMethod<IReportIds>(ReportsService.GroupsRouteV3, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getFinishingGroupsIds(reportQuery: ReportQuery): Promise<HttpResponseResult<IReportIds>> {
    let httpParams = this.convertReportQueryToHttpParams(reportQuery);
    return this.restApiService.sendGetMethod<IReportIds>(ReportsService.GroupsFinishingRouteV3, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getReports(): Promise<HttpResponseResult<IReportsMenuItems>> {
    return this.restApiService.sendGetMethod<IReportsMenuItems>(ReportsService.ReportsRouteV2, this.authService.currentUserAuthState.authDetails);
  }

  public postTurnLedLightingOn(adhocTaskData: TurnLedLightingOnDialogModel): Promise<HttpResponseResult<IAdhocTaskModel>> {
    const requestBody: IAdhocTaskModel = {
      animalIds: adhocTaskData.animalIds,
      duration: adhocTaskData.duration,
      reportId: adhocTaskData.reportId,
      blinkRate: adhocTaskData.blinkRate
    };
    return this.restApiService.sendPostMethod<IAdhocTaskModel>(ReportsService.AdhocTaskRoute, this.authService.currentUserAuthState.authDetails, requestBody);
  }

  public getReport(reportKey:string, reportQuery: ReportQuery) : Promise<HttpResponseResult<IReport>> {
    let httpParams = this.convertReportQueryToHttpParams(reportQuery);
    return this.restApiService.sendGetMethod<IReport>(ReportsService.ReportsRouteV2 + '/' + reportKey, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getSelectedLinesReport(reportQuery: ReportQuery,
                                reportMeta:IReportMetadata,
                                rowIds: number[]): Promise<HttpResponseResult<IReport>> {
    let selectedLinesReportRequest: ISelectedLinesReportRequest = {
      lineSelection: {
        lineNumbers: rowIds,
        sorting: reportQuery.getSortingQuery()
      },
      reportCacheUid: reportMeta.reportUid,
      reportTime: reportMeta.reportTime,
      shiftId: reportMeta.shiftId,
      requestType: 'selectLines'
    };
    return this.restApiService.sendPutMethod<IReport>(ReportsService.ReportsRouteV2 + '/' + reportMeta.reportId, this.authService.currentUserAuthState.authDetails, selectedLinesReportRequest);
  }

  public getAnimalToInspectSetup(): Promise<HttpResponseResult<IAnimalsToInspectReport>> {
    return this.restApiService.sendGetMethodAny<IAnimalsToInspectReport>(ReportsService.ReportsRouteV2 + '?projection=animalsToInspectReports', this.authService.currentUserAuthState.authDetails);
  }

  // tslint:disable-next-line:no-any
  public resetAnimalToInspectReportToDefault(reportKey: string, resetToDefault: boolean): Promise<HttpResponseResult<any>> {
    let resetAnimalToInspectReportToDefaultModel = new ResetAnimalToInspectReportToDefaultModel();
    resetAnimalToInspectReportToDefaultModel.resetToDefault = resetToDefault;
    return this.restApiService.sendPutMethodAny(ReportsService.ReportsRouteV2 + '/' + reportKey, this.authService.currentUserAuthState.authDetails, resetAnimalToInspectReportToDefaultModel);
  }

  // tslint:disable-next-line:no-any
  public setAnimalToInspectSetup(reportKey: string, setup: ResetAnimalToInspectReportToDefaultModel): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPutMethodAny(ReportsService.ReportsRouteV2 + '/' + reportKey, this.authService.currentUserAuthState.authDetails, setup);
  }

  // tslint:disable-next-line:no-any
  public async includeAnimalInReport(reportKey: number, animalId: string): Promise<HttpResponseResult<any>> {
    let includeAnimalModel = new IncludeAnimalToReportModel();
    includeAnimalModel.animalId = animalId;
    return this.restApiService.sendPutMethodAny(ReportsService.ReportsRouteV2 + '/' + reportKey, this.authService.currentUserAuthState.authDetails, includeAnimalModel);
  }

  // tslint:disable-next-line:no-any
  public updateReportFavorite(reportMenuItem:IReportMenuItem) : Promise<HttpResponseResult<any>> {

    let reportRequest: IReportRequest = {
      category: reportMenuItem.category,
      isFavorite: reportMenuItem.isFavorite,
      key: reportMenuItem.key,
      name: reportMenuItem.name,
      requestType: ReportRequestType.isFavorite
    };
    // tslint:disable-next-line:no-any
    return this.restApiService.sendPutMethod<any>(ReportsService.ReportsRouteV2 + '/' + reportRequest.key,
                                                            this.authService.currentUserAuthState.authDetails,
                                                            reportRequest);
  }

  public static filterVisibleColumns(cols: IReportColumnDefinition[], showExtendedColumns?: boolean): IReportColumnDefinition[] {
    return cols.filter((col) => {
      return (col.type.toLowerCase() !== 'hiddenref')
        && (col.key !== 'DNBCalculation')
        && (col.type.toLowerCase() !== 'hidden')
        && (col.webMetaType !== 'Badge')
        && (showExtendedColumns || col.displayType !== ReportDisplayType.Extended)
    });
  }

  public convertReportQueryToHttpParams(reportQuery: ReportQuery) : HttpParams {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('offset', reportQuery.offset.toString());
    httpParams = httpParams.append('limit', reportQuery.limit.toString());
    httpParams = httpParams.append('type', reportQuery.type);
    if (reportQuery.pastReportTime) {
      httpParams = httpParams.append('pastReportTime', reportQuery.pastReportTime.toString());
    }
    if (reportQuery.shiftId) {
      httpParams = httpParams.append('shiftId', reportQuery.shiftId.toString());
    }
    if (reportQuery.reportUid) {
      httpParams = httpParams.append('reportUid', reportQuery.reportUid.toString());
    }
    if (reportQuery.includeFilterMetaData !== undefined) {
      httpParams = httpParams.append('includeFilterMetaData', reportQuery.includeFilterMetaData.toString());
    }
    if (reportQuery.useDefaultFilter !== undefined) {
      httpParams = httpParams.append('useDefaultFilter', String(reportQuery.useDefaultFilter));
    }
    if (reportQuery.sort.size > 0) {
      httpParams  = httpParams.append('sort', reportQuery.getSortingQuery());
    }
    if (reportQuery.filter.size > 0) {
      httpParams  = httpParams.append('filter', this.convertFilteringQuery(reportQuery));
    }
    if (reportQuery.filterColumnName) {
      httpParams = httpParams.append('filterColumnName', reportQuery.filterColumnName);
      httpParams = httpParams.append('filterValue', String(reportQuery.filterValue));
    }
    if(reportQuery.projection) {
      httpParams = httpParams.append('projection', reportQuery.projection);
    }
    if(reportQuery.isRefresh) {
      httpParams = httpParams.append('isRefresh', reportQuery.isRefresh.toString());
    }
    return httpParams;
  }

  // tslint:disable-next-line:no-any
  public async ignoreMaintenanceCall(reportKey: string, setup: any) : Promise<HttpResponseResultNoBody> {
    return this.restApiService.sendPutMethodAny(ReportsService.ReportsRouteV2 + '/' + reportKey, this.authService.currentUserAuthState.authDetails, setup);
  }

  // tslint:disable-next-line:no-any
  public parseSyncStatusResults(results: any): string {
    let parsedResults: ISyncStatusResults = JSON.parse(results);
    let stringToReturn: string = null;
    if(parsedResults && parsedResults.animalNumber !== null) {
      stringToReturn = String(parsedResults.animalNumber);
    } else if(parsedResults && parsedResults.cullingThresholdError !== null) {
      stringToReturn = `${Math.floor((parsedResults.cullingThresholdError.animalsToCull / parsedResults.cullingThresholdError.animalsInFarm) * 100)}% ${this.translationService.translate('SYSTEM.MANAGEMENT.GRID.FAILED')}`;
    } else if(parsedResults && parsedResults.statistics !== null) {
      stringToReturn = `${Math.floor((parsedResults.statistics.failedAnimals / parsedResults.statistics.totalAnimals) * 100)}% ${this.translationService.translate('SYSTEM.MANAGEMENT.GRID.FAILED')}`;
    } else if(parsedResults && parsedResults.tagRemovalThresholdError !== null) {
      stringToReturn = `${Math.floor((parsedResults.tagRemovalThresholdError.tagsToRemove / parsedResults.tagRemovalThresholdError.assignTagsInFarm) * 100)}% ${this.translationService.translate('SYSTEM.MANAGEMENT.GRID.FAILED')}`;
    }
    return stringToReturn;
  }

  public minToMs(date: number) {
    let minutes = Math.floor(date / 60);
    let seconds = date % 60;
    if (minutes > 0) {
      if (seconds > 30) {
        return (minutes + 1) + ' ' + this.translationService.translate('SYSTEM.MANAGEMENT.GRID.MINUTES');
      } else {
        return minutes + ' ' + this.translationService.translate('SYSTEM.MANAGEMENT.GRID.MINUTES');
      }
    } else if (seconds > 0) {
      return seconds + ' ' + this.translationService.translate('SYSTEM.MANAGEMENT.GRID.SECONDS');
    } else if (minutes === 0 && seconds === 0) {
      return '-';
    }
  }

  public getDatabaseIdFieldNameForReport(reportName: ReportNameEnum): string {
    switch (reportName) {
      case ReportNameEnum.TagMaintenanceCalls:
        return "TagCowDatabaseIDCalculation";
      case ReportNameEnum.GroupRoutine:
      case ReportNameEnum.GroupRoutineHeatStress:
        return "GroupDatabaseIDCalculation";
      default:
        return "CowDatabaseIDCalculation";
    }
  }

  public getAnimalIdFieldNameForReport(reportName: ReportNameEnum): string {
    switch (reportName) {
      case ReportNameEnum.TagMaintenanceCalls:
        return "TagAnimalIDCalculation";
      default:
        return "AnimalIDCalculation";
    }
  }

  public async getAllRowsReport(reportContext:IReportViewContext, reportQuery:ReportQuery, isExtendedReport?: boolean) : Promise<IReport> {
    let query;
    if (reportQuery.type == ReportDisplayType.Extended) {
      query = reportQuery.getExtendedReportQuery();
    } else {
      query = reportQuery.getFullReportQuery();
    }
    let report: IReport = await reportContext.getReportByQuery(query);
    report = this.filterHiddenColumns(report, isExtendedReport);
    if (report) {
      while(report.meta.rowTotalAfterFilter > report.rows.length) {
        query.offset = report.rows.length;
        query.limit = report.meta.rowCount;
        let reportChunk: IReport = await reportContext.getReportByQuery(query);
        report.rows = [...report.rows, ...reportChunk.rows];
      }
      return report;
    } else {
      return null;
    }
  }

  public setRowNavigation(report:IReport, row:ReportDataRow) {
    if(report.navigation == null) {
      row.navigation = report.meta.navigation;
      return;
    }
    let rowNavigation = report.navigation.find(rowNavigation => rowNavigation.rowNumber == row.rowNumber);
    if(rowNavigation == null) {
      row.navigation = report.meta.navigation;
    } else {
      row.navigation = rowNavigation.navigation;
    }
  }

  public reportIsSortableOrFilterable(report: IReport) : boolean {
    return report.columns.some(column => column.isFilterEnable ||
                                         column.isSortingEnable);
  }

  private filterHiddenColumns(report: IReport, isExtendedReport?: boolean) : IReport {
    if (report) {
      report.columns = report.columns.filter(column => {
        if (isExtendedReport) {
          return column.key !== 'SyncStatusProcessEndTimeCalculation'
        } else {
          return column.key !== 'SyncStatusProcessEndTimeCalculation' && column.displayType !== ReportDisplayType.Extended
        }
      });
      return report;
    } else {
      return null;
    }
  }

  private convertFilteringQuery(reportQuery: ReportQuery) : string {
    let  filterQuery = '';
    let filterKeysArray =  Array.from(reportQuery.filter.keys());
    for (let i=0; i< filterKeysArray.length; i++) {
      let filterColumnQuery = reportQuery.filter.get(filterKeysArray[i]);
      filterQuery += filterKeysArray[i] + filterColumnQuery.serializeToString();
      if(i < filterKeysArray.length - 1){
        filterQuery += ' And '
      }
    }
    return filterQuery;
  }
}

