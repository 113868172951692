import {Component, OnInit, ViewChild} from '@angular/core';
import {RegistrationService} from '../../../services/registration/registration.service';
import {ITopologyHardware} from '../../../services/registration/model/registration.model';
import {DataGridColumnMap} from '../../../common/components/data-grid/model/data-grid-column-map';
import {DataGridComponent} from '../../../common/components/data-grid/data-grid.component';
import {DataGridQuery} from '../../../common/components/data-grid/model/data-grid-query';
import {IDataGridViewContext} from '../../../services/model/common-model';
import {GridViewState} from '../../../services/ui/view-state/grid-row/grid-view-state';

@Component({
  selector: 'farm-topology',
  templateUrl: './farm-topology.component.html',
  styleUrls: ['./farm-topology.component.scss']
})
export class FarmTopologyComponent implements OnInit, IDataGridViewContext {

  @ViewChild('hardwareTable')
  public hardwareTable: DataGridComponent;

  private isEditPopupOpen: boolean = false;

  public selectedHardwareForEdit: IHardwareForEdit;

  private readonly columnsMappings: Map<string, DataGridColumnMap> = new Map();

  private dataGridQuery: DataGridQuery = new DataGridQuery();

  public hardware: ITopologyHardware[];

  public gridViewState: GridViewState = new GridViewState();

  constructor(public registrationService: RegistrationService) {
    this.dataGridQuery.offset = 0;
    this.columnsMappings.set('CHOOSE_COLLECTOR', {
      enableSorting: false,
      flexGrow: 1
    });
    this.columnsMappings.set('HARDWARE_TYPE', {
      enableSorting: false,
      flexGrow: 2
    });
    this.columnsMappings.set('HARDWARE_DESCRIPTION', {
      enableSorting: false,
      flexGrow: 2
    });
    this.columnsMappings.set('IP_ADDRESS', {
      enableSorting: false,
      flexGrow: 1.5
    });
    this.columnsMappings.set('SERIAL_NUMBER', {
      enableSorting: false,
      flexGrow: 2
    });
    this.columnsMappings.set('SOFTWARE_VERSION', {
      enableSorting: false,
      flexGrow: 1
    });
  }

  // tslint:disable-next-line:no-any
  public toggleCheckbox(row: any) {
    row['isEnable'] = !row['isEnable'];
    let hardwareRow = this.registrationService.registrationModel.farmTopology.hardware.find(hardwareRow => hardwareRow.id === row.id);
    hardwareRow.isEnable = row['isEnable'];
  }

  public async ngOnInit() {
    if (!this.registrationService.isFarmTopologyInit) {
      await this.getTopologyData();
      this.registrationService.isFarmTopologyInit = true;
    } else {
      this.hardware = this.registrationService.registrationModel.farmTopology.hardware;
    }
  }

  public async reloadTopologyFromServer() {
    this.hardware = null;
    await this.getTopologyData();
  }

  public async getTopologyData() {
    let response = await this.registrationService.getFarmTopology();
    this.registrationService.registrationModel.farmTopology = response.responseBody;
    this.hardware = this.registrationService.registrationModel.farmTopology.hardware;
    let mainCollectorItem: ITopologyHardware = this.hardware.find((hardwareItem: ITopologyHardware) => hardwareItem.hardwareType === 'full' || hardwareItem.hardwareType === 'pc');
    if (mainCollectorItem) {
      mainCollectorItem.isEnable = true;
    }
    this.dataGridQuery.limit = this.registrationService.registrationModel.farmTopology.hardware.length;
  }

  public editHardwareDescription(row: IHardwareForEdit) {
    this.selectedHardwareForEdit = {...row};
    this.isEditPopupOpen = true;
  }

  public getGridViewState(): GridViewState {
    return this.gridViewState;
  }

  public reloadDefault(): Promise<void> {
    return null;
  }

}
export interface IHardwareForEdit {
  id: number;
  hardwareDesc: string;
  hardwareType: string;
  hardwareVersion: string;
  ip: string;
  isDefault: boolean;
  ddVersion: string;
  mac: string;
  serialNumber: string;
}
