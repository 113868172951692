import { Component, OnInit } from '@angular/core';
import {RemoveRfIdTagEventRow} from '../../../../../services/animals/model/animal-events';
import {DescriptionCellComponent} from '../../../../../common/components/data-grid/description-cell-template/description-cell.component';

@Component({
  selector: 'remove-rfid-tag-event-description',
  templateUrl: './remove-rfid-tag-event-description.component.html',
  styleUrls: ['./remove-rfid-tag-event-description.component.scss']
})
export class RemoveRfidTagEventDescriptionComponent extends DescriptionCellComponent<RemoveRfIdTagEventRow> implements OnInit {

  constructor() {
    super();
  }

  public ngOnInit() {
  }

}
