import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {RegistrationService} from '../../../../../services/registration/registration.service';
import {AuthLocalValidatorService, PasswordValidationResult} from '../../../../../services/auth/auth-local-validator.service';

@Component({
  selector: 'account-details',
  templateUrl: './sign-up-account-details.component.html',
  styleUrls: ['./sign-up-account-details.component.scss']
})
export class SignUpAccountDetailsComponent implements OnInit {

  public username: string = null;

  public password: string = '';

  public confirmPassword: string = '';

  public openPasswordValidationPopup: boolean = false;

  public openConfirmPasswordValidationPopup: boolean = false;

  public SignUpAccountDetailsFieldName = SignUpAccountDetailsFieldName;

  private passwordValidationResult: PasswordValidationResult = new PasswordValidationResult();

  private confirmPasswordValidationResult: PasswordValidationResult = new PasswordValidationResult();

  constructor(private router: Router, public registrationService: RegistrationService, private authLocalValidator: AuthLocalValidatorService) { }

  public ngOnInit() {
    this.username = this.registrationService.registrationModel.createAccount.account.username;
    this.password = this.registrationService.registrationModel.createAccount.account.password || '';
    if (this.password) {
      this.passwordValidationResult = this.authLocalValidator.validatePassword(this.password);
    }
    this.confirmPassword = this.registrationService.registrationModel.createAccount.account.confirmPassword || '';
    if (this.confirmPassword) {
      this.confirmPasswordValidationResult = this.authLocalValidator.validatePassword(this.confirmPassword);
    }
  }

  public onNewPasswordValueChanged(){
    this.openPasswordValidationPopup = true;
    this.passwordValidationResult = this.authLocalValidator.validatePassword(this.password);
  }

  public onConfirmPasswordValueChanged(){
    this.openConfirmPasswordValidationPopup = true;
    this.confirmPasswordValidationResult = this.authLocalValidator.validatePassword(this.confirmPassword);
  }

  public onNewPasswordBlur() {
    this.openPasswordValidationPopup = false;
  }

  public onConfirmPasswordBlur() {
    this.openConfirmPasswordValidationPopup = false;
  }

  public async navigateBack() {
    this.clearErrors();
    await this.router.navigate(['/registration/sign-up/create-admin-account']);
  }

  public async navigateNext() {
    if (this.validateStep()) {
      this.registrationService.registrationModel.createAccount.account.username = this.username;
      this.registrationService.registrationModel.createAccount.account.password = this.password;
      this.registrationService.registrationModel.createAccount.account.confirmPassword = this.confirmPassword;
      this.clearErrors();
      await this.router.navigate(['/registration/sign-up/security-questions']);
    }
  }

  public validateStep(): boolean {
    if (!this.username) {
      this.registrationService.registrationModel.error = { fieldName: SignUpAccountDetailsFieldName.username, error: RegistrationModelError.USERNAME_REQUIRED };
      return false;
    } else if (!this.password) {
      // password is empty
      this.registrationService.registrationModel.error = { fieldName: SignUpAccountDetailsFieldName.password, error: RegistrationModelError.PASSWORD_REQUIRED };
      return false;
    } else if (!this.passwordValidationResult.isValid) {
      // password is not valid
      this.registrationService.registrationModel.error = { fieldName: SignUpAccountDetailsFieldName.password, error: RegistrationModelError.PASSWORD_INVALID };
      return false;
    } else if (!this.confirmPassword) {
      // confirm password is empty
      this.registrationService.registrationModel.error = { fieldName: SignUpAccountDetailsFieldName.confirmPassword, error: RegistrationModelError.CONFIRM_PASSWORD_REQUIRED };
      return false;
    } else if (!this.confirmPasswordValidationResult.isValid) {
      // confirm password is not valid
      this.registrationService.registrationModel.error = { fieldName: SignUpAccountDetailsFieldName.confirmPassword, error: RegistrationModelError.PASSWORD_INVALID };
      return false;
    } else if (this.password !== this.confirmPassword) {
      this.registrationService.registrationModel.error = { fieldName: SignUpAccountDetailsFieldName.confirmPassword, error: RegistrationModelError.CONFIRM_PASSWORD_NOT_EQUAL };
      return false;
    } else {
      return true;
    }
  }

  public hasErrorByFieldName(fieldName: SignUpAccountDetailsFieldName): boolean {
    return !!(this.registrationService.registrationModel.error && this.registrationService.registrationModel.error.fieldName === fieldName);
  }

  public clearErrors() {
    this.registrationService.registrationModel.error = null;
  }

  public onUsernameChange(): void {
    this.clearErrors();
    this.username = this.authLocalValidator.validateUserNameCharacters(this.username);
  }

}

enum SignUpAccountDetailsFieldName {
  username = 'username',
  password = 'password',
  confirmPassword = 'confirmPassword'
}

/* tslint:disable:naming-convention */
enum RegistrationModelError {
  INVALID_USERNAME = 'INVALID_USERNAME',
  USERNAME_REQUIRED = 'USERNAME_REQUIRED',
  PASSWORD_REQUIRED = 'PASSWORD_REQUIRED',
  PASSWORD_INVALID = 'PASSWORD_INVALID',
  CONFIRM_PASSWORD_REQUIRED = 'CONFIRM_PASSWORD_REQUIRED',
  CONFIRM_PASSWORD_NOT_EQUAL = 'CONFIRM_PASSWORD_NOT_EQUAL'
}
/* tslint:enable:naming-convention */
