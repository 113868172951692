<div class="animal-chart-container">
  <div class="chart-div" [attr.sh-id]="'pieChartDiv'">
    <div class="pie-chart-background">
      <div class="pie-chart-inner-background"></div>
    </div>
    <ejs-accumulationchart background="transparent" id="container" style='display:block;  width: 100%' [legendSettings]="legendSettings">
      <e-accumulation-series-collection>
        <e-accumulation-series [innerRadius]="innerRadius" [dataSource]='chartData' xName='x' yName='value' pointColorMapping="color">
        </e-accumulation-series>
      </e-accumulation-series-collection>
    </ejs-accumulationchart>
    <div class="number-of-animals-text">
      <div class="centered-text">
        <div><b>{{ tagsChart ? tagsChart.totalCows : 0 }}</b></div>
        <div [attr.translation-id]="'DASHBOARD.CHART.COWS'">{{ 'DASHBOARD.CHART.COWS' | translate }}</div>
      </div>
    </div>
  </div>
  <div class="legend-div" sh-id="summary_pie_chart_legend_div">
    <div class="legend-container">
      <div class="align-row">
        <div class="marker" *ngIf="this.chartData && this.chartData[0].color" [ngStyle]="{ background: this.chartData[0].color }"></div>
        <div class="legend-text" [attr.translation-id]="'DASHBOARD.CHART.WITH_TAGS'">{{ 'DASHBOARD.CHART.WITH_TAGS' | translate }}</div>
      </div>
      <div class="legend-value">{{ tagsChart ? tagsChart.withTags : 0 }}</div>
    </div>
    <div class="legend-container">
      <div class="align-row">
        <div class="marker" *ngIf="this.chartData && this.chartData[1].color" [ngStyle]="{ background: this.chartData[1].color }"></div>
        <div class="legend-text" [attr.translation-id]="'DASHBOARD.CHART.WITHOUT_TAGS'">{{ 'DASHBOARD.CHART.WITHOUT_TAGS' | translate }}</div>
      </div>
      <div class="legend-value">{{ tagsChart ? tagsChart.withoutTags : 0 }}</div>
    </div>
  </div>
</div>


