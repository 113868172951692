<div class="heat-chart-header">
  <xy-chart-legend #heatChartLegend
                   [configuration]="configuration"
                   [legendStates]="legendStates"></xy-chart-legend>
  <chart-series-dropdown-list [seriesSelections]="seriesSelections"
                              [translationPrefix]="configuration.translationPrefix"
                              (selectionApplied)="onSelectionApplied($event)"
                              (restoreToDefaults)="onRestoreToDefaults()"></chart-series-dropdown-list>
</div>
<xy-chart #heatChart
          (chartPointMouseOver)="onChartPointMouseOver($event)"
          (durationSelected)="onDurationSelected($event)"></xy-chart>
