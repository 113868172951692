import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoggedInLayoutRoutingModule } from './logged-in-layout-routing.module';
import {LoggedInLayoutComponent} from './logged-in-layout.component';
import {ReportsComponent} from './reports/reports.component';
import {HeaderComponent} from './layout-header/layout-header.component';
import {FooterComponent} from './layout-footer/layout-footer.component';
import {LeftMenuComponent} from './left-menu/left-menu.component';
import {ImportFromCsvComponent} from './layout-header/import-from-csv/import-from-csv.component';
import {SystemAlertsComponent} from './layout-header/system-alerts/system-alerts.component';
import {AnimalAlertsComponent} from './layout-header/animal-alerts/animal-alerts.component';
import {SearchComponent} from './layout-header/search/search.component';
import {TagsProvisioningComponent} from './layout-header/tags-provisioning/tags-provisioning.component';
import {UserInfoComponent} from './layout-header/user-info/user-info.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MenuItemDefaultComponent} from './left-menu/menu-item-default/menu-item-default.component';
import {MenuItemReportsComponent} from './left-menu/menu-item-reports/menu-item-reports.component';
import {MenuItemManageComponent} from './left-menu/manage/menu-item-manage/menu-item-manage.component';
import {MenuItemSortingGateComponent} from './left-menu/sorting-gate/menu-item-sorting-gate/menu-item-sorting-gate.component';
import {ReportsMenuComponent} from './left-menu/reports-menu/reports-menu.component';
import {SortingGateMenuComponent} from './left-menu/sorting-gate/sorting-gate-menu/sorting-gate-menu.component';
import {ManageMenuComponent} from './left-menu/manage/manage-menu/manage-menu.component';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {
  AccumulationChartAllModule, AreaSeriesService, BarSeriesService,
  CategoryService,
  ChartModule, ColumnSeriesService, CrosshairService,
  DataLabelService, DateTimeService,
  LegendService, LineSeriesService, RangeAreaSeriesService, ScrollBarService, StackingColumnSeriesService, StripLineService,
  TooltipService, ZoomService
} from '@syncfusion/ej2-angular-charts';
import {ClipboardModule} from 'ngx-clipboard';
import {ShSharedModule} from '../common/sh-shared.module';
import {GroupCardComponent} from './card-popup/group-card/group-card.component';
import {AnimalCardComponent} from './card-popup/animal-card/animal-card.component';
import {AnimalCardTimelineComponent} from './card-popup/animal-card/animal-card-timeline/animal-card-timeline.component';
import {AnimalCardEventsComponent} from './card-popup/animal-card/animal-card-events/animal-card-events.component';
import {AnimalCardHeatComponent} from './card-popup/animal-card/animal-card-heat/animal-card-heat.component';
import {AnimalCardHealthComponent} from './card-popup/animal-card/animal-card-health/animal-card-health.component';
import {AnimalCardDetailsComponent} from './card-popup/animal-card/animal-card-details/animal-card-details.component';
import {CardPopupComponent} from './card-popup/card-popup.component';
import {GroupCardTrendsHeatComponent} from './card-popup/group-card/group-card-trends-heat/group-card-trends-heat.component';
import {GroupCardGraphsActivityRuminationComponent} from './card-popup/group-card/group-card-graphs-activity-rumination/group-card-graphs-activity-rumination.component';
import {GroupCardDetailsComponent} from './card-popup/group-card/group-card-details/group-card-details.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {AnimalCardCreateEventComponent} from './card-popup/animal-card/animal-card-events/create-event/animal-card-create-event.component';
import {AnimalCardDetailsEditSectionComponent} from './card-popup/animal-card/animal-card-details/animal-card-details-edit-section/animal-card-details-edit-section.component';
import {GroupCardAnimalsSummaryComponent} from './card-popup/group-card/group-card-animals-summary/group-card-animals-summary.component';
import {RouterModule} from '@angular/router';
import {TrendsConsistencyXyChartComponent} from './card-popup/group-card/group-card-trends-consistency/trends-consistency-xy-chart/trends-consistency-xy-chart.component';
import {SystemSortingPenAssociatedGateComponent} from './system/system-sorting-pens/system-sorting-pen-associated-gate/system-sorting-pen-associated-gate.component';
import {NewVersionSoftwareModalComponent} from './dashboard/maintenance-calls/new-version-software-modal/new-version-software-modal.component';
import {StartedTrialDialogComponent} from './dialogs/started-trial-dialog/started-trial-dialog.component';
import {StartedPremiumTrialDialogComponent} from './dialogs/started-premium-trial-dialog/started-premium-trial-dialog.component';
import {SyncStatusModalComponent} from './system/system-management/sync-status-modal/sync-status-modal.component';
import {SyncNowModalComponent} from './system/system-management/sync-now-modal/sync-now-modal.component';
import {SystemManagementErrorModalComponent} from './system/system-management/system-management-error-modal/system-management-error-modal.component';
import {ConfirmUserDeleteDialogComponent} from './system/system-users/dialogs/confirm-user-delete-dialog/confirm-user-delete-dialog.component';
import {RemoveDataWarningDialogComponent} from './notification-dialogs/remove-data-warning-dialog/remove-data-warning-dialog.component';
import {DatabaseIsUpdatingDialogComponent} from './dialogs/database-is-updating-dialog/database-is-updating-dialog.component';
import {ReportFiltersContainerComponent} from './reports/report-filters-container/report-filters-container.component';
import {IncludeInReportDropdownListComponent} from './reports/report-filters-container/include-in-report-dropdown-list/include-in-report-dropdown-list.component';
import {AnimalEventsModule} from './animal-events/animal-events.module';
import {AnimalCardTimelineHeaderComponent} from './card-popup/animal-card/animal-card-timeline/animal-card-timeline-header/animal-card-timeline-header.component';
import {AnimalCardTimelineLegComponent} from './card-popup/animal-card/animal-card-timeline/animal-card-timeline-leg/animal-card-timeline-leg.component';
import {AnimalCardTimelineNodeComponent} from './card-popup/animal-card/animal-card-timeline/animal-card-timeline-node/animal-card-timeline-node.component';
import {TurnLedLightingErrorDialogComponent} from "./animal-events/animal-events-editor/dialogs/turn-led-lighting-error-dialog/turn-led-lighting-error-dialog.component";
import {GroupCardDetailsBreedingComponent} from './card-popup/group-card/group-card-details/group-card-details-breeding/group-card-details-breeding.component';
import {GroupCardDetailsFinishingComponent} from './card-popup/group-card/group-card-details/group-card-details-finishing/group-card-details-finishing.component';
import {BranchCardComponent} from './card-popup/branch-card/branch-card.component';
import { BranchCardDetailsComponent } from './card-popup/branch-card/branch-card-details/branch-card-details.component';
import {AnimalCardHealthBreedingComponent} from './card-popup/animal-card/animal-card-health/animal-card-health-breeding/animal-card-health-breeding.component';
import {AnimalCardHealthFinishingComponent} from './card-popup/animal-card/animal-card-health/animal-card-health-finishing/animal-card-health-finishing.component';
import {BranchCardGraphsConsistencyComponent} from './card-popup/branch-card/branch-card-graphs-consistency/branch-card-graphs-consistency.component';
import {BranchCardGraphsHeatStressComponent} from './card-popup/branch-card/branch-card-graphs-heat-stress/branch-card-graphs-heat-stress.component';
import {BranchCardAnimalsSummaryComponent} from './card-popup/branch-card/branch-card-summary/branch-card-animals-summary.component';
import {CardPopupHeaderBreadcrumbComponent} from './card-popup/card-popup-header-breadcrumb/card-popup-header-breadcrumb.component';
import {CardPopupHeaderTitleComponent} from './card-popup/card-popup-header-title/card-popup-header-title.component';
import {GroupCardTrendsConsistencyBreedingComponent} from './card-popup/group-card/group-card-trends-consistency/group-card-trends-consistency-breeding/group-card-trends-consistency-breeding.component';
import {GroupCardTrendsConsistencyFinishingComponent} from './card-popup/group-card/group-card-trends-consistency/group-card-trends-consistency-finishing/group-card-trends-consistency-finishing.component';
import {GroupCardGraphsHeatComponent} from './card-popup/group-card/group-card-graphs-heat/group-card-graphs-heat.component';
import {GroupCardTrendsConsistencyComponent} from './card-popup/group-card/group-card-trends-consistency/group-card-trends-consistency.component';
import {GroupCardTrendsHeatBreedingComponent} from './card-popup/group-card/group-card-trends-heat/group-card-trends-heat-breeding/group-card-trends-heat-breeding.component';
import {GroupCardTrendsHeatFinishingComponent} from './card-popup/group-card/group-card-trends-heat/group-card-trends-heat-finishing/group-card-trends-heat-finishing.component';
import {DeleteEventErrorDialogComponent} from './dialogs/delete-event-error-dialog/delete-event-error-dialog.component';
import {AboutComponent} from './layout-footer/about/about.component';
import { ContactUsComponent } from './layout-footer/contact-us/contact-us.component';
import {LedTasksModule} from "./led-tasks/led-tasks.module";
import {ProvisioningAuthDialogComponent} from './dialogs/provisioning-auth-dialog/provisioning-auth-dialog.component';
import {OwlDateTimeModule} from "@danielmoncada/angular-datetime-picker";

@NgModule({
  declarations: [
    HeaderComponent,
    ImportFromCsvComponent,
    SystemAlertsComponent,
    AnimalAlertsComponent,
    SearchComponent,
    TagsProvisioningComponent,
    UserInfoComponent,
    FooterComponent,
    AboutComponent,
    LeftMenuComponent,
    ManageMenuComponent,
    MenuItemDefaultComponent,
    MenuItemReportsComponent,
    MenuItemManageComponent,
    MenuItemSortingGateComponent,
    ReportsMenuComponent,
    SortingGateMenuComponent,
    LoggedInLayoutComponent,
    ReportsComponent,
    BranchCardComponent,
    BranchCardGraphsConsistencyComponent,
    BranchCardGraphsHeatStressComponent,
    BranchCardAnimalsSummaryComponent,
    GroupCardComponent,
    AnimalCardComponent,
    CardPopupComponent,
    CardPopupHeaderBreadcrumbComponent,
    CardPopupHeaderTitleComponent,
    AnimalCardComponent,
    AnimalCardEventsComponent,
    AnimalCardHeatComponent,
    AnimalCardHealthComponent,
    AnimalCardHealthBreedingComponent,
    AnimalCardHealthFinishingComponent,
    AnimalCardDetailsComponent,
    GroupCardComponent,
    GroupCardGraphsHeatComponent,
    GroupCardTrendsConsistencyComponent,
    GroupCardTrendsConsistencyBreedingComponent,
    GroupCardTrendsConsistencyFinishingComponent,
    GroupCardTrendsHeatComponent,
    GroupCardTrendsHeatBreedingComponent,
    GroupCardTrendsHeatFinishingComponent,
    GroupCardGraphsActivityRuminationComponent,
    GroupCardDetailsComponent,
    GroupCardDetailsBreedingComponent,
    GroupCardDetailsFinishingComponent,
    AnimalCardCreateEventComponent,
    AnimalCardDetailsEditSectionComponent,
    GroupCardAnimalsSummaryComponent,
    ReportFiltersContainerComponent,
    IncludeInReportDropdownListComponent,
    AnimalCardTimelineComponent,
    AnimalCardTimelineNodeComponent,
    AnimalCardTimelineLegComponent,
    AnimalCardTimelineHeaderComponent,
    TurnLedLightingErrorDialogComponent,
    TrendsConsistencyXyChartComponent,
    SystemSortingPenAssociatedGateComponent,
    NewVersionSoftwareModalComponent,
    StartedTrialDialogComponent,
    StartedPremiumTrialDialogComponent,
    SyncStatusModalComponent,
    SyncNowModalComponent,
    SystemManagementErrorModalComponent,
    ConfirmUserDeleteDialogComponent,
    RemoveDataWarningDialogComponent,
    DatabaseIsUpdatingDialogComponent,
    BranchCardDetailsComponent,
    DeleteEventErrorDialogComponent,
    ContactUsComponent,
    ProvisioningAuthDialogComponent
  ],
  providers: [
    CategoryService,
    LegendService,
    TooltipService,
    DataLabelService,
    LineSeriesService,
    BarSeriesService,
    ColumnSeriesService,
    StackingColumnSeriesService,
    DateTimeService,
    AreaSeriesService,
    CrosshairService,
    ZoomService,
    ScrollBarService,
    RangeAreaSeriesService,
    StripLineService,
  ],
  exports: [
    NewVersionSoftwareModalComponent,
    StartedTrialDialogComponent,
    StartedPremiumTrialDialogComponent,
    SyncNowModalComponent,
    SystemManagementErrorModalComponent,
    ConfirmUserDeleteDialogComponent,
    RemoveDataWarningDialogComponent,
    SyncStatusModalComponent,
    SystemSortingPenAssociatedGateComponent,
    DeleteEventErrorDialogComponent
  ],
  imports: [
    ShSharedModule,
    CommonModule,
    LoggedInLayoutRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    ChartModule,
    AccumulationChartAllModule,
    ClipboardModule,
    NgxDatatableModule,
    RouterModule,
    LedTasksModule,
    OwlDateTimeModule,
    AnimalEventsModule
  ]
})
export class LoggedInLayoutModule { }
