<div class="dashboard-content"  saveDataEditSection [editAreaName]="DashboardWidgetsEditArea" [ngClass]="'dashboard-' + farmMode | lowercase">
  <div class="dashboard-header">
    <div class="dashboard-header-container-wrapper">
      <div class="dashboard-text-wrapper">
        <h2 [title]="farmName">{{ farmName }}</h2>
        <div class="seperator" *ngIf="tagsSummary"></div>
        <div class="assigned-tags-text" *ngIf="tagsSummary" [attr.sh-id]='"assigned-tags-div"' [attr.translation-id]="'SYSTEM.DASHBOARD.CHART.ASSIGNED_TAGS'">{{ 'SYSTEM.DASHBOARD.CHART.ASSIGNED_TAGS' | translate }}</div>
        <div class="padded-text" *ngIf="tagsSummary">{{ tagsSummary?.withTags }}</div>
        <div class="tags-assigned-icon" *ngIf="tagsSummary"></div>
        <div class="text" *ngIf="tagsSummary"> /&nbsp;  {{ tagsSummary?.totalCows }}</div>
        <div class="total-animals-icon" *ngIf="tagsSummary"></div>
      </div>
      <ng-container *ngIf="allowUserToEditDashboard">
        <ng-container *ngIf="!editMode">
          <div class="edit-icon" (click)="onEdit()" sh-id="edit-dashboard-widgets"></div>
        </ng-container>
        <ng-container *ngIf="editMode">
          <div class="save-icon" (click)="onSave()" sh-id="save-dashboard-widgets"></div>
          <div class="edit-separator"></div>
          <div class="revert-icon" (click)="onRevert()" sh-id="revert-dashboard-widgets"></div>
        </ng-container>
      </ng-container>
    </div>
    <div class="horizontal-line" [ngClass]="{'line-seperator-dairy': farmMode === 'Dairy', 'line-seperator-beef': farmMode === 'Beef'}"></div>
  </div>
  <div class="dashboard-body">
    <div class="accordion-container">
      <dashboard-accordion #dashboardAccordion [editMode]="editMode"></dashboard-accordion>
    </div>
    <div class="summary-container" sh-id="dashboard_summary_container">
      <dashboard-kpi-accordion *ngIf="!isSuspendMode" #dashboardKpiAccordion [editMode]="editMode"></dashboard-kpi-accordion>
      <div class="maintenance-calls-container" [ngClass]="{'maintenance-calls-container--dairy': farmMode == 'Dairy', 'maintenance-calls-container--beef': farmMode == 'Beef'}">
        <maintenance-calls saveDataWarningInject></maintenance-calls>
      </div>
    </div>
  </div>
</div>
