<div class="shifts-section" sh-id="farm-shifts-content" *ngIf="shifts" saveDataEditSection [editAreaName]="editAreaName">
  <div class="error-message" *ngIf="errorMessage">
    <span class="prefix" translation-id="SYSTEM.MANAGEMENT.ERROR_BOLD">{{ 'SYSTEM.MANAGEMENT.ERROR_BOLD' | translate }}:</span> {{errorMessage | translate}}
  </div>
  <div class="section-header" translation-id="MANAGE.SETTINGS.SHIFTS.SECTION_TITLE">{{'MANAGE.SETTINGS.SHIFTS.SECTION_TITLE' | translate}}</div>
  <div class="section-content">
    <div class="section-description">
      <div class="description-text" translation-id="MANAGE.SETTINGS.SHIFTS.SECTION_DESCRIPTION">{{'MANAGE.SETTINGS.SHIFTS.SECTION_DESCRIPTION' | translate}}</div>
    </div>
    <div class="section-controls" [ngClass]="{ disabled: isFieldEditable() }">
      <manage-settings-shift-list [shifts]="shifts"
                                  [isDisabled]="isFieldEditable()"
                                  (onChangeName)="onChangeName($event)"
                                  (onSlideStartTime)="onSlideStartTime($event)"
                                  (onRemove)="onRemoveShift($event)"></manage-settings-shift-list>
      <button class="add-shift-btn" sh-id="add-shift-btn" [disabled]="isFieldEditable()" (click)="addShift()" *ngIf="shifts.length < maxShiftLength">
        <span class="icon-wrapper">
          <i class="icon icon-add-shift"></i>
        </span>
        <span class="add-shfit-text" translation-id="MANAGE.SETTINGS.SHIFTS.ADD_SHIFT_BUTTON">{{'MANAGE.SETTINGS.SHIFTS.ADD_SHIFT_BUTTON' | translate}}</span>
      </button>
    </div>
  </div>
</div>
