import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {BranchCardViewState, BranchCardTabSection, IBranchGraphDetails} from '../../../services/branches/model/branch.interface';
import {BranchesService} from '../../../services/branches/branches.service';

@Component({
  selector: 'branch-card',
  templateUrl: './branch-card.component.html',
  styleUrls: ['./branch-card.component.scss']
})
export class BranchCardComponent implements OnInit {

  @Input()
  public viewState: BranchCardViewState;

  public branchGraphDetails: IBranchGraphDetails;

  public BranchCardTabSection = BranchCardTabSection;

  constructor(private branchesService: BranchesService) { }

  public ngOnInit() {
  }

  public async ngOnChanges(changes: SimpleChanges) {
    let viewStateChange = changes['viewState'];
    if(viewStateChange.previousValue == viewStateChange.currentValue){
      return;
    }
    await this.getBranchGraphDetails();
  }

  private async getBranchGraphDetails(): Promise<void> {
    this.branchGraphDetails = null;
    const branchGraphDetailsResponse = await this.branchesService.getBranchGraphDetails(this.viewState.branchDetails.id);
    if (branchGraphDetailsResponse.status == 200) {
      this.branchGraphDetails = branchGraphDetailsResponse.responseBody;
    }
  }

  public selectTab(tab: BranchCardTabSection){
    this.viewState.selectedTab = tab;
  }

}
