<notification-dialog-template #dialogRef
                              [isEscapeCloseDialog]="true"
                              [modalWidth]="580"
                              [modalHeight]="250"
                              [theme]="NotificationDialogTheme.red"
                              [shIdPostfix]="'systemManagementErrorModalComponent'">
  <ng-container header>
    <div class="dialog-header">
      <div class="title-wrapper">
        <div class="warning-icon"></div>
        <div class="title" [attr.translation-id]="'SYSTEM.MANAGEMENT.INTEGRATION.DIALOG.FAILURE_DETAILS.TITLE'">{{'SYSTEM.MANAGEMENT.INTEGRATION.DIALOG.FAILURE_DETAILS.TITLE' | translate}}</div>
      </div>
    </div>
  </ng-container>
  <ng-container body *ngIf="errorModalData">
    <div class="dialog-body">
      <div class="form-group">
        <span class="form-group-label" [attr.translation-id]="'SYSTEM.MANAGEMENT.INTEGRATION.DIALOG.FAILURE_DETAILS.' + errorModalData.key">{{'SYSTEM.MANAGEMENT.INTEGRATION.DIALOG.FAILURE_DETAILS.' + errorModalData.key | translate}}</span>
      </div>
    </div>
  </ng-container>
</notification-dialog-template>
