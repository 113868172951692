import {Component, Input, OnInit} from '@angular/core';
import {IDashboardDetailedKPI} from '../../../../../services/dashboard/model/dashboard-kpi.interface';

@Component({
  selector: 'dashboard-kpi-description',
  templateUrl: './dashboard-kpi-description.component.html',
  styleUrls: ['./dashboard-kpi-description.component.scss']
})
export class DashboardKpiDescriptionComponent implements OnInit {
  
  @Input()
  public details: IDashboardDetailedKPI;
  
  constructor() { }

  public ngOnInit() {

  }
}
