<modal-dialog-template
  *ngIf="showPopup$ | async as showPopupOptions"
  [modalWidth]="670"
  [modalHeight]="240"
  [isOpen]="showPopupOptions.isShown === true"
  (onEscapeButtonClick)="closePopup(showPopupOptions.closeSubject)">
  <div class="layout">
    <div class="layout-header">
      <div class="layout-header__icon"></div>
      <div class="layout-header__text" translation-id="DIALOG.COMMON.CANCEL_BATCH_EVENT.HEADER">
        {{ 'DIALOG.COMMON.CANCEL_BATCH_EVENT.HEADER' | translate }}
      </div>
      <div class="layout-header__close-icon" (click)="closePopup(showPopupOptions.closeSubject)"></div>
    </div>
    <div class="layout-content">
      <div class="layout-content__message" translation-id="DIALOG.COMMON.CANCEL_BATCH_EVENT.MESSAGE">
        {{ 'DIALOG.COMMON.CANCEL_BATCH_EVENT.MESSAGE' | translate }}
      </div>
      <div class="layout-content__description" translation-id="DIALOG.COMMON.CANCEL_BATCH_EVENT.DESCRIPTION">
        {{ 'DIALOG.COMMON.CANCEL_BATCH_EVENT.DESCRIPTION' | translate }}
      </div>
    </div>
    <div class="layout-buttons">
      <button (click)="closePopup(showPopupOptions.closeSubject, true)"  class="layout-button" translation-id="DIALOG.COMMON.CANCEL_BATCH_EVENT.CANCEL_BUTTON">
        {{ 'DIALOG.COMMON.CANCEL_BATCH_EVENT.CANCEL_BUTTON' | translate }}
        <div class="layout-button__next-icon"></div>
      </button>
    </div>
  </div>
</modal-dialog-template>
