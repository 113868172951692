import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'image-checkbox',
  templateUrl: './image-checkbox.component.html',
  styleUrls: ['./image-checkbox.component.scss']
})
export class ImageCheckboxComponent implements OnInit {
  @Input() public isChecked: boolean;
  @Input() public isDisabled = false;
  @Input() public checkBoxStyles: Object;
  @Input() public ngClass: string;
  @Input() public shId?: string;
  @Output() public isCheckedChanged: EventEmitter<boolean> = new EventEmitter();

  public get cssClass() {
    const result = [];
    if (this.ngClass) {
      result.push(this.ngClass);
    }
    if (this.isChecked) {
      result.push('selected');
    }
    if (this.isDisabled) {
      result.push('disabled');
    }
    return result.join(' ');
  }

  constructor() { }

  public ngOnInit() {
  }

  public onCheckBoxClicked() {
    setTimeout(() => this.isCheckedChanged.emit(!this.isChecked));
  }

}
