<description-cell-details-template [hasRowDetails]="row.alertType"
                                   [isRowExpanded]="isExpanded"
                                   [row]="row"
                                   [rowShId]="row.eventId"
                                   (toggleExpanded)="toggleExpanded()">
  <ng-container key>{{"ANIMAL.EVENTS.EVENT_DESCRIPTION.DISTRESS_DURATION"| translate}}:&nbsp;</ng-container>
  <ng-container value>{{row.duration | duration}}</ng-container>
  <ng-container content>
    <p class="description-item" *ngIf="row.alertType">
      <span>{{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.DISTRESS_TYPE.NAME' | translate}}:&nbsp;</span>
      <span class="value">{{'ANIMAL.EVENTS.EVENT_DESCRIPTION.DISTRESS_TYPE.' + row.alertType | translate}}</span>
    </p>
  </ng-container>
</description-cell-details-template>
