import {NotificationDialogComponent} from '../notification-dialog.component';
import {Component, Input} from "@angular/core";

@Component({
  selector: 'remove-data-warning-dialog',
  templateUrl: './remove-data-warning-dialog.component.html',
  styleUrls: ['./remove-data-warning-dialog.component.scss']
})
export class RemoveDataWarningDialogComponent extends NotificationDialogComponent {

  @Input()
  public messageAdditional: string;

  @Input()
  public messageAdditional2: string;

  @Input()
  public buttonCloseText: string;

  @Input()
  public buttonProceedText: string;

}
