import {Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {ProvisioningAuthModel, ProvisioningResponseError, ISignInResponseData} from '../../../services/auth/model/provisioning.model';
import {Subscription} from 'rxjs';
import {ProvisioningAuthDialogService} from './provisioning-auth-dialog.service';
import {ConfigService} from '../../../services/config/config.service';
import {NotificationDialogTemplateComponent, NotificationDialogTheme} from '../../../common/components/dialogs/notification-dialog-template/notification-dialog-template.component';
import {ProvisioningService} from '../../../services/auth/provisioning.service';
import {EmailNotProvidedDialogService} from '../../../common/components/dialogs/email-not-provided-dialog/email-not-provided-dialog.service';
import {WINDOW} from '../../../utils/window-utils';
import {CornerContainerBodyBackground, CornerColor} from '../../../common/components/containers/corner-container/corner-container.component';
import {HttpResponseResult} from '../../../services/rest-api/rest-api.service';
import {LoadingIconService} from '../../../services/loading-icon/loading-icon.service';
import {ErrorModel} from "../../../services/model/common-model";

@Component({
  selector: 'provisioning-auth-dialog',
  templateUrl: './provisioning-auth-dialog.component.html',
  styleUrls: ['./provisioning-auth-dialog.component.scss']
})
export class ProvisioningAuthDialogComponent implements OnInit {

  public adminName: string;

  public password: string;

  public subscription: Subscription;

  public isOpen: boolean;

  public NotificationDialogTheme = NotificationDialogTheme;

  public CornerContainerBodyBackground = CornerContainerBodyBackground;

  public CornerColor = CornerColor;

  public errorKey: string;

  public isLoading: boolean;

  public get hasPasswordError(): boolean {
    return this.errorKey && this.errorKey == ProvisioningResponseError.WrongCredentials;
  }

  @ViewChild('dialogRef', {static: true})
  public dialogRef: NotificationDialogTemplateComponent;

  @Output()
  public onDialogProceedClick: EventEmitter<ProvisioningAuthModel> = new EventEmitter<ProvisioningAuthModel>();

  constructor(public elementRef: ElementRef,
              private readonly provisioningAuthDialogService: ProvisioningAuthDialogService,
              private readonly configService: ConfigService,
              @Inject(WINDOW) private readonly window: Window,
              private readonly emailNotProvidedDialogService: EmailNotProvidedDialogService,
              private readonly provisioningService: ProvisioningService,
              private readonly loadingIconService: LoadingIconService) {
  }

  public ngOnInit(): void {
    this.subscription = this.provisioningAuthDialogService.popupShown.subscribe((value: boolean) => {
      this.errorKey = null;
      this.password = '';
      if (value) {
        this.adminName = this.configService.serverConfig.user.username;
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
    })
  }

  private startLoading(): void {
    this.isLoading = true;
    this.loadingIconService.show();
  }

  private stopLoading(): void {
    this.isLoading = false;
    this.loadingIconService.hide();
  }

  public onCloseClick(): void {
    this.provisioningAuthDialogService.hide();
  }

  public showDialog() {
    this.isOpen = true;
  }

  public isRightButtonDisabled(): boolean {
    return !Boolean(this.password) || this.isLoading;
  }

  public onPasswordChange(): void {
    if (this.errorKey) {
      this.errorKey = null;
    }
  }

  public async onRightButtonClick() {
    if (!this.password) {
      return;
    }
    this.startLoading();
    this.errorKey = null;

    const provisioningSignInResponse: HttpResponseResult<ISignInResponseData> = await this.provisioningService.provisioningSignIn(new ProvisioningAuthModel(this.adminName, this.password));
    if (provisioningSignInResponse && provisioningSignInResponse.responseBody && provisioningSignInResponse.responseBody.succeeded && provisioningSignInResponse.responseBody.provisioningLink) {
      this.stopLoading();
      if (!(this.configService.configuration && this.configService.configuration.useMockHttp)) {
        this.window.open(provisioningSignInResponse.responseBody.provisioningLink, '_blank');
      }
      await this.onCloseClick();
      return;
    }
    this.stopLoading();
    if (provisioningSignInResponse && provisioningSignInResponse.errorResponseBody && provisioningSignInResponse.errorResponseBody.result
      && provisioningSignInResponse.errorResponseBody.result.failures && provisioningSignInResponse.errorResponseBody.result.failures[0].key == ProvisioningResponseError.AdminDoesntHaveEmail) {
      await this.onCloseClick();
      await this.emailNotProvidedDialogService.show();
    } else {
      this.showError(provisioningSignInResponse.errorResponseBody.result.failures);
    }
  }

  public showError(errors: ErrorModel[]) {
    if (errors && errors.length > 0) {
      this.errorKey = errors[0].key;
    }
  }

  public ngOnDestroy(): void {
    this.errorKey = null;
    this.onCloseClick();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
