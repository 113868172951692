import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {WeightEventDetails, WeightEventMetadata} from '../../../../../services/animals/model/animal-events';
import {WeightValueLimitDirective} from '../../../../../common/directives/weight-value-limit/weight-value-limit.directive';
import {EventFormComponent} from '../event-form.component';
import {UtilsService, WeightUnitType} from '../../../../../services/utils/utils.service';

@Component({
  selector: 'weight-event-form',
  templateUrl: './weight-event-form.component.html',
  styleUrls: ['./weight-event-form.component.scss'],
  providers: [WeightValueLimitDirective]
})
export class WeightEventFormComponent extends EventFormComponent<WeightEventMetadata, WeightEventDetails> implements OnChanges {

  constructor(private utilsService: UtilsService) {
    super();
  }

  public get weightKG() {
    return this.editModel.weightValueKG;
  }

  public get weightLBS() {
    return this.editModel.weightValueLBS;
  }

  public set weightKG(value: string | undefined) {
    this.editModel.weightValueKG = value;
    this.emitModelChanged();
  }

  public set weightLBS(value: string | undefined) {
    this.editModel.weightValueLBS = value;
    this.emitModelChanged();
  }

  public clearWeightValueField() {
    this.weightKG = null;
    this.weightLBS = null;
  }

  public isFieldError(fieldName: string) {
    return this.errorModel.fieldName === fieldName;
  }

  protected onChanges(changes: SimpleChanges): void {
    super.onChanges(changes);
  }

  public isKgValueDisplayed() {
    return this.utilsService.getUnitType() === WeightUnitType.KG;
  }

  public isWeightValueFieldError() {
    return (this.isFieldError('weightValueKG') || this.isFieldError('weightValueLBS'));
  }

}
