import { Component, OnInit } from '@angular/core';
import {MockHttpService} from '../../services/mock-http.service';
import {RestApiResult} from '../../../services/rest-api/models/rest-api-result';
import {Router} from '@angular/router';
import {InputFilterMode} from "../../../common/directives/input-filter.directive";
import {ISyncResponse} from '../../../services/rest-api/polling.service';
import {IDashboardWidget} from '../../../services/dashboard/model/dashboard-widgets.interface';

@Component({
  selector: 'mock-dashboard-reports',
  templateUrl: './mock-dashboard-reports.component.html',
  styleUrls: ['./mock-dashboard-reports.component.scss']
})
export class MockDashboardReportsComponent {

  public isApplying:boolean;

  InputFilterMode = InputFilterMode;

  constructor(private router: Router,
              private mockHttpService:MockHttpService) {

  }

  public async apply() {
    this.isApplying = true;
    this.mockHttpService.resetTemplatesToDefault();

    this.isApplying = false;
    await this.router.navigate(['/testing-refresh-blank']);
    await this.router.navigate(['/']);
  }
  
  public async testWithSync() {
    this.isApplying = true;
    
    let syncTemplate = await this.mockHttpService.getTemplate('/rest/api/v3/server/sync');
    let syncResponseBody: ISyncResponse = syncTemplate.messages[0].responseBody;
//  syncResponseBody.polls.reportsDashboard = (this.animalsToInspect + this.animalsInHeat).toString();
    this.isApplying = false;
  }
}
