import {IGroup, IGroupMetadata} from '../../groups/model/group.interface';
import {IDam, ISire} from './animal-events';
import {IChartEventPoint, IChartPoint} from '../../model/charts/chart.interface';
import {IChartSeriesSetupRecord} from '../../charts/model/chart.interface';
import {ReportDataRow} from '../../reports/model/report.interface';

export enum AddAnimalFieldsErrorsEnum {
  animalName = 'animalName',
  group = 'group',
  entryDate = 'herdEntryDateTime',
  scrTagNumber = 'scrTag.tagNumber',
  rfIdTagNumber = 'rfidTag.tagNumber',
  orn = 'orn',
  weightDate = 'weightDate',
  weightValueKG = 'weightValueKG',
  weightValueLBS = 'weightValueLBS',
  backgroundStart = 'backgroundStart',
  birthDate = 'birthDate',
  castration = 'castrationDate',
  lactation = 'lactation',
  calvingDate = 'calvingDate',
  breedingDate = 'breedingDate',
  breedingNumber = 'breedingNumber',
  breedingSire = 'breedingSire',
  pregnancyCheckDate = 'pregnancyCheckDate',
  dryOffDate = 'dryOffDate',
  finishingStart = 'finishingStart'
}

export enum AnimalSex {
  Male = 'Male',
  Female = 'Female'
}

export class AnimalDetailsField {
  public category: string;
  public editType: string;
  public isEditable: boolean;
  public name: string;
  public type: string;
  // tslint:disable-next-line:no-any
  public value: any = null;
  public isLocalization: boolean;
}

export class AnimalDetails {
  public animalId: number;
  public details: AnimalDetailsField[] = [];
}

export interface IAnimalInfoDateRange {
  minDate: number;
  maxDate: number;
}

export interface IAnimalInfoMetadata {
  scrTags?: string[],
  rfIdTags?: string[],
  sireList?: ISire[],
  damList?: IDam[],
  groups?: IGroupMetadata[],
  birthDates: IAnimalInfoDateRange,
  entryDates: IAnimalInfoDateRange,
  calvingDates: IAnimalInfoDateRange
}

export interface IAnimalInfo {
  animalId: number,
  animalName: string,
  groupName: string,
  groupId: number,
  branchId: number,
  status: string,
  hasNote: boolean,
  isCulled: boolean,
  isActivityAlert: boolean,
  isRuminationAlert: boolean,
  sex: AnimalSex,
  plan: string
}

export interface IAnimalChartSetupSeries {
  heatTrend?: IChartSeriesSetupRecord,
  dailyRumination?: IChartSeriesSetupRecord,
  dailyEating?: IChartSeriesSetupRecord,
  rawRumination?: IChartSeriesSetupRecord,
  rawEating?: IChartSeriesSetupRecord,
  rawSuckling?: IChartSeriesSetupRecord,
  activityTrend?: IChartSeriesSetupRecord,
  inseminationEvent?: IChartSeriesSetupRecord,
  systemHeatEvent?: IChartSeriesSetupRecord,
  distressEvent?: IChartSeriesSetupRecord,
  calvingEvent?: IChartSeriesSetupRecord,
  systemHealthInEvent?: IChartSeriesSetupRecord,
  systemHealthOutEvent?: IChartSeriesSetupRecord,
  pregnancyCheckEvent?: IChartSeriesSetupRecord,
  groupChangeEvent?: IChartSeriesSetupRecord,
  tagChangeEvent?: IChartSeriesSetupRecord,
  abortionEvent?: IChartSeriesSetupRecord,
  dryOffEvent?: IChartSeriesSetupRecord,
  youngStockHealthIndex: IChartSeriesSetupRecord,
  finishingHealthIndex?: IChartSeriesSetupRecord
}

export interface IAnimalChartDataPoint extends IChartPoint {
  dailyRumination?: number,
  dailyEating?: number,
  rawRumination?: number,
  rawEating?: number,
  heatTrend?: number,
  activityTrend?: number,
  youngStockHealthIndex?: number,
  youngStockHealthIndexUpper?: number,
  finishingHealthIndex?: number,
  healthThreshold?: number
}

export interface IChartDataPointWithEvents extends IAnimalChartDataPoint {
  eventY: 0,
  eventBullet: string,
  events: IChartEventPoint[]
}

export interface IAnimalChartData {
  series: IAnimalChartDataPoint[],
  eventsSeries: IChartEventPoint[],
  thresholdLine?: number
}

export enum AnimalBadge {
  None = 'None',
  DNB = 'DNB',
  Male = 'Male',
}

export enum PregnancyCheckResult{
  Positive = '+',
  Negative = '-',
  Uncertain = '?'
}

export interface IMaleMetadata {
  groupList: IGroupItem[];
  rfidTagList: ISCRTagItem[];
  scrTagList: ISCRTagItem[];
}

export interface IFemalesMetadata {
  groupList: IGroupItem[];
  rfidTagList: ISCRTagItem[];
  scrTagList: ISCRTagItem[];
  sireList?: ISire[];
}

export interface IGroupItem {
  id: number;
  number: number;
  name: string;
}

export enum TagTypeEnum {
  Scr = 'scr',
  RfId = 'rfId'
}

export interface ISCRTagItem {
  id: number;
  tagType: TagTypeEnum;
  tagNumber: string;
}

export interface IAddMaleRequest {
  animalName: string;
  birthDate: number;
  group: IGroupItem;
  orn: string;
  rfidTag: ISCRTagItem;
  scrTag: ISCRTagItem;
}

export interface IAddMaleFinishingRequest {
  animalName: string;
  group: IGroupItem;
  orn?: string;
  rfidTag?: ISCRTagItem;
  scrTag?: ISCRTagItem;
  birthDate?: number;
  herdEntryDateTime?: number;
  weightDate?: number;
  weightValueKG?: number;
  weightValueLBS?: number;
  startBackgroundDate?: number;
  startFinishingDate?: number;
  castrationDate?: number;
}

export interface IAddFemaleRequest {
  animalName: string;
  birthDate: number;
  breedingDate: number;
  breedingNumber: number;
  breedingSire?: ISire;
  calvingDate: number;
  dryOffDate: number;
  group: IGroupItem;
  lactation: number;
  pregnancyCheckDate: number;
  orn: string;
  rfidTag: ISCRTagItem;
  scrTag: ISCRTagItem;
}

export interface IAddFemaleFinishingRequest {
  animalName: string;
  group: IGroupItem;
  orn?: string;
  rfidTag?: ISCRTagItem;
  scrTag?: ISCRTagItem;
  birthDate?: number;
  herdEntryDateTime?: number;
  weightDate?: number;
  weightValueKG?: number;
  weightValueLBS?: number;
  startBackgroundDate?: number;
  startFinishingDate?: number;
}

export interface IAnimalActions {
  title: [{ text: string }];
  isVisible: boolean;
}

export class RemoveAnimalCullingRequest {
  public newState: boolean = false;
}

export interface IModalAnimalPopup {
  title?: string;
  open: boolean | false;
  width?: number;
  height?: number;
  type?: string;
  template?: string;
  message?: string;
  buttonText?:string;
  errorMessage?: string;
  noValidFormAction?: boolean | false;
  actionEvent?:string;
}

export interface IAnimalIdMeta {
  animalId: number;
  animalName: string;
}

export interface IErrors {
  key: string;
  fieldName: string;
}
