import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AddGroupModel, IGroup} from '../../../../../services/groups/model/group.interface';
import {ErrorModel} from '../../../../../services/model/common-model';
import {DropdownListAutoSelectionMode, DropdownListItem} from '../../../../../common/components/dropdown-list/dropdown-list-model';
import {HttpResponseResult} from '../../../../../services/rest-api/rest-api.service';
import {GroupsService, IGroupsMetaResponse} from '../../../../../services/groups/groups.service';
import {Subscription} from 'rxjs';
import {GroupFields} from '../group-editor.component';
import {TranslationService} from '../../../../../services/translations/translation.service';
import {DropdownColorScheme} from '../../../../../common/components/dropdown-list/regular-dropdown-list/regular-dropdown-list.component';
import { CornerColor, CornerContainerBodyBackground } from 'src/app/common/components/containers/corner-container/corner-container.component';

@Component({
  selector: 'group-editor-breeding',
  templateUrl: './group-editor-breeding.component.html',
  styleUrls: ['./group-editor-breeding.component.scss']
})
export class GroupEditorBreedingComponent implements OnInit, OnDestroy {

  public addGroupModel: AddGroupModel = new AddGroupModel();

  public Error: ErrorModel;

  public groupFields = GroupFields;

  public  initSelectValue : DropdownListItem;

  public groupBranchesDropdownListItems: DropdownListItem[] = [];

  public dropdownListAutoSelectionMode = DropdownListAutoSelectionMode;

  private editGroupSubscription: Subscription;

  private groupId: number = null;

  private savingChanges: boolean;

  public DropdownColorScheme = DropdownColorScheme;

  public CornerColor = CornerColor;

  public CornerContainerBodyBackground = CornerContainerBodyBackground;

  @Input()
  public isOpen: boolean;

  @Output()
  public groupAddedOrChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public isAddGroupOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private readonly groupsService: GroupsService,
              private readonly translationService: TranslationService) { }

  public async ngOnInit() {
    let response: HttpResponseResult<IGroupsMetaResponse> = await this.groupsService.getGroupsMeta();
    if (response.status === 200) {
      this.groupBranchesDropdownListItems = [];
      if (response.responseBody.groupMetadata && response.responseBody.groupMetadata.branches) {
        response.responseBody.groupMetadata.branches.forEach((branch) => {
          let dropdownItem: DropdownListItem = {
            displayValue: this.translationService.translate('REPORTS.GRID.STATUS.' + branch),
            value: branch,
            icon: null,
            disabled: false
          };
          this.groupBranchesDropdownListItems.push(dropdownItem);
        });
      }
    }
    this.editGroupSubscription = this.groupsService.editGroupSubscription.subscribe((obj: IGroup)=>{
      if (obj == null) {
        this.groupId = null;
        this.clearForm();
      } else {
        this.groupId = obj.GroupDatabaseIDCalculation;
        this.fillingEditFormGroup(obj);
      }
      this.clearErrors();
    });
  }

  public ngOnDestroy() {
    this.editGroupSubscription.unsubscribe();
  }

  public async onGroupBranchSelected(selectedGroupBranch: DropdownListItem) {
    if (selectedGroupBranch && selectedGroupBranch.value) {
      this.addGroupModel.branchName = selectedGroupBranch.value;
      this.initSelectValue = selectedGroupBranch;
    } else {
      this.addGroupModel.branchName = null;
      this.initSelectValue = {value: null, displayValue: null, disabled: null, icon: null};
    }
    this.clearErrors();
  }

  public async onHeatIndexThresholdChanged(selectedHeatIndexThreshold: number) {
    this.addGroupModel.heatIndexThreshold = selectedHeatIndexThreshold;
    this.clearErrors();
  }

  public onGroupNumberChanged(selectedGroupNumber: number) {
    this.addGroupModel.groupNumber = selectedGroupNumber;
    this.clearErrors();
  }

  public async fillingEditFormGroup(obj:IGroup) {
    this.initSelectValue = {value: obj.GroupBranchCalculation, displayValue: obj.GroupBranchCalculation, disabled: false, icon: null};
    this.addGroupModel.groupName = obj.GroupNameCalculation;
    this.addGroupModel.groupNumber = obj.GroupNumberCalculation;
    this.addGroupModel.heatIndexThreshold = obj.GroupHeatIndexThresholdCalculation;
    this.addGroupModel.id = obj.GroupDatabaseIDCalculation;
    this.addGroupModel.branchName = obj.GroupBranchCalculation;
  }

  private clearForm() {
    this.initSelectValue = {value: null, displayValue: null, disabled: null, icon: null};
    this.addGroupModel.id = null;
    this.addGroupModel.branchName = null;
    this.resetIndexThreshold();
    this.addGroupModel.groupName = null;
    this.addGroupModel.groupNumber = null;
    this.Error = null;
  }

  public async resetIndexThreshold() {
    if (!this.groupsService.GroupsMetadata) {
      this.groupsService.getGroupsMeta().then((response: HttpResponseResult<IGroupsMetaResponse>) => {
        this.groupsService.GroupsMetadata = response.responseBody.groupMetadata;
        this.addGroupModel.heatIndexThreshold = this.groupsService.GroupsMetadata.healthIndexDefaultThreshold;
      });
    } else {
      this.addGroupModel.heatIndexThreshold = this.groupsService.GroupsMetadata.healthIndexDefaultThreshold;
    }
  }

  public clearErrors() {
    this.Error = null;
  }

  public async saveGroup() {
    // create a new group
    if(this.savingChanges || !this.isSaveButtonActive) {
      return;
    }
    this.savingChanges = true;
    if(this.groupId == null) {
      let response = await this.groupsService.createGroup(this.addGroupModel);
      if (response.status === 200 || response.status === 201) {
        this.clearForm();
        this.groupAddedOrChanged.emit(true);
      } else {
        this.Error = response.errorResponseBody.result.failures[0];
      }
    } else {
      let response = await this.groupsService.editGroup(this.addGroupModel.id , this.addGroupModel);
      if (response.status === 200 || response.status === 201) {
        this.isOpen = false;
        this.clearForm();
        //this.groupsService.isEditGroup = false;
        this.groupAddedOrChanged.emit(false);
      } else {
        this.Error = response.errorResponseBody.result.failures[0];
      }
    }
    this.savingChanges = false;
  }

  private hasErrorForField(field: string): boolean {
    let hasError = false;
    if (this.Error) {
      if (this.Error.fieldName === 'barnchName') {
        hasError = field === 'branchName';
      } else {
        hasError = this.Error && this.Error.fieldName === field;
      }
    }

    return hasError;
  }

  public get isSaveButtonActive(): boolean {
    if (this.addGroupModel.groupName == null || this.addGroupModel.groupName === '') {
      return false;
    }
    if (this.addGroupModel.groupNumber == null) {
      return false;
    }
    return !(this.addGroupModel.branchName == null || this.addGroupModel.branchName === '');
  }
}
