<div class="error-message-bar"
     *ngIf="manageSettingsService.editBarErrorMessage"
     [attr.sh-id]="manageSettingsService.editBarErrorMessage">
  {{ manageSettingsService.editBarErrorMessage | translate }}
</div>
<div class="manage-settings-header"
     [ngClass]="{ 'breeding': isBreeding,
                  'finishing': isFinishing,
                  'dairy': isDairy }">
  <div class="title" [attr.translation-id]="'MANAGE.TITLE.SETTINGS'" *ngIf="!editBarVisibility">{{ 'MANAGE.TITLE.SETTINGS' | translate }}</div>
  <div class="changes-notification-header" *ngIf="editBarVisibility">
    <span class="note" translation-id="MANAGE.SETTINGS.USERS.NOTE">{{ 'MANAGE.SETTINGS.USERS.NOTE' | translate }}: </span>
    <span translation-id="MANAGE.SETTINGS.USERS.YOU_CHANGED_SOME_THING">{{ 'MANAGE.SETTINGS.USERS.YOU_CHANGED_SOME_THING' | translate }}</span>
  </div>
  <div class="buttons" sh-id="manage-settings-edit-bar-buttons">
    <div class="close-btn" *ngIf="!editBarVisibility"
         [attr.sh-id]="'close-manage-settings-popup-btn'"
         (click)="onCloseSettingsDialog.emit()"></div>
    <div class="btn btn-save" *ngIf="editBarVisibility" (click)="save()" [ngClass]="{'btn-save-unavailable': !isSaveAvailable}">
      <div class="icon icon-save" sh-id="manage-settings-edit-bar-save-btn" [ngClass]="{'icon-save-unavailable': !isSaveAvailable}"></div>
    </div>
    <div class="btn btn-cancel" *ngIf="editBarVisibility"  (click)="reset($event)" sh-id="manage-settings-edit-bar-reset-btn" #resetBtn>
      <div class="icon icon-x-button" [ngClass]="{'icon-x-button-unavailable': !isResetAvailable}"></div>
    </div>
  </div>
</div>
