<div class="birthday-entry-tab">
  <div class="double-field-div">
    <div class="input-field">
      <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER'">
        {{ 'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER' | translate }}
      </div>
      <div class="value">
        <input class="input-class"
               (ngModelChange)="clearErrors()"
               [ngClass]="{ error: animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.animalName)}"
               type="text"
               [(ngModel)]="addFinishingFemaleModel.animalNumber"
               [attr.sh-id]="'finishing_add_female_animal_number_entry_tab'"
               [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER'"
               inputFilter
               [inputFilterMaxLength]="16"
               placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER' | translate }}" />
      </div>
    </div>
    <div class="input-field">
      <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'">
        {{ 'MANAGE.COWS.ACTIVE.WIZARD.GROUP' | translate }}
      </div>
      <div class="value">
        <regular-dropdown-list class="dropdown-list"
                               [autoSelectionMode]="dropdownListAutoSelectionMode.Single"
                               [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.group)"
                               [shIdPostfix]="'finishing_add_female_group_entry_tab'"
                               [items]="groupsDropdownListItems"
                               [placeholder]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'"
                               [allowClear]="true"
                               [selectedValue]="addFinishingFemaleModel.group"
                               (onItemSelected)="onGroupSelected($event)"></regular-dropdown-list>
      </div>
    </div>
  </div>
  <div class="seperator"></div>
  <div class="fields-and-errors-div">
    <div class="single-field-div">
      <div class="input-field paddingTop">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.SCR_TAG'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.SCR_TAG' | translate }}
        </div>
        <div class="value">
          <regular-auto-complete [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.scrTagNumber)"
                                 [attr.sh-id]="'finishing_add_female_scr_tag_number_entry_tab'"
                                 [items]="scrTagsNumbers"
                                 [searchQuery]="addFinishingFemaleModel?.tagNumber?.tagNumber"
                                 [hasPermanentRemoveIcon]="true"
                                 [colorScheme]="autoCompleteColorScheme.WhiteBlack"
                                 [maxResults]="6"
                                 (searchQueryChanged)="onScrTagChanged($event)"
                                 [placeholder]="'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL'"
                                 [shIdPostfix]="'finishing_add_female_scr_tag_number_entry_tab_auto_complete'"></regular-auto-complete>
        </div>
      </div>
      <div class="input-field paddingTop">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.RF_ID'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.RF_ID' | translate }}
        </div>
        <div class="value">
          <regular-auto-complete
            [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.rfIdTagNumber)"
            [items]="rfidsTagsNumbers"
            [attr.sh-id]="'finishing_add_female_rf_id_entry_tab'"
            [searchQuery]="addFinishingFemaleModel?.EID?.tagNumber"
            [hasPermanentRemoveIcon]="true"
            [colorScheme]="autoCompleteColorScheme.WhiteBlack"
            [maxResults]="6"
            (searchQueryChanged)="onRfIdTagChanged($event)"
            [placeholder]="'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL'"
            [shIdPostfix]="'finishing_add_female_rf_id_entry_tab_auto_complete'"></regular-auto-complete>
        </div>
      </div>
      <div class="input-field paddingTop">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.ORN'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.ORN' | translate }}
        </div>
        <div class="value with-icon">
          <input class="input-class"
                 [ngClass]="{ error: animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.orn) }"
                 (ngModelChange)="clearErrors()"
                 type="text"
                 #registrationNumberInput
                 [attr.sh-id]="'finishing_add_female_orn_entry_tab'"
                 [(ngModel)]="addFinishingFemaleModel.registrationNumber"
                 [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL'"
                 placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL' | translate }}" />
          <div class="remove-btn-wrapper">
            <div class="remove-icon" (click)="onClearRegistrationNumber()"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
