<div class="app-container">
    <layout-header [ngStyle]="{'margin-left': (isOpenMenu ? 135 : 0) + 'px'}"></layout-header>
    <div class="app-body">
        <router-outlet></router-outlet>
    </div>
    <layout-footer></layout-footer>
</div>
<left-menu *ngIf="configService.serverConfig"
           outsideClickHandler
           (onOutsideClick)="isOpenMenu = false"
           [isOpenMenu]="isOpenMenu"
           (openMenuChange)="onOpenMenuChange($event)"></left-menu>
<card-popup></card-popup>
<batch-event-errors-dialog></batch-event-errors-dialog>
<add-animal-to-report-dialog></add-animal-to-report-dialog>
<turn-led-lighting-on-dialog></turn-led-lighting-on-dialog>
<turn-led-lighting-error-dialog></turn-led-lighting-error-dialog>
<create-batch-event-dialog></create-batch-event-dialog>
<assign-animal-to-tag-dialog></assign-animal-to-tag-dialog>
<assign-tag-to-animal-dialog></assign-tag-to-animal-dialog>
<change-group-dialog></change-group-dialog>
<delete-event-error-dialog></delete-event-error-dialog>
<send-to-sorting-gate-dialog></send-to-sorting-gate-dialog>
<confirm-culling-assigned-tag-dialog></confirm-culling-assigned-tag-dialog>
<success-dialog></success-dialog>
<error-dialog></error-dialog>
<email-not-provided-dialog></email-not-provided-dialog>
<provisioning-auth-dialog></provisioning-auth-dialog>
