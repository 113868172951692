import {Component, Input} from '@angular/core';
import {FarmStatisticsBeefBarChartData} from '../../../../../../../services/dashboard/model/farm-statistics-finishing';

@Component({
  selector: 'farm-statistics-finishing-bar-chart',
  templateUrl: './farm-statistics-finishing-bar-chart.component.html',
  styleUrls: ['./farm-statistics-finishing-bar-chart.component.scss']
})
export class FarmStatisticsFinishingBarChartComponent {

  private static readonly MaxDesignedBarHeight = 131; //see https://app.zeplin.io/project/5aeeccdfbdd658793f225f4b/screen/5d7f81e7e83ac45f046a3c7f

  @Input()
  public data: FarmStatisticsBeefBarChartData;

  @Input()
  public isLast: boolean;

  public get maleBarHeight(): string {
    if (!this.data.males) {
      return '0';
    }
    return this.getBarHeight(this.data.males) + 'px';
  }

  public get femaleBarHeight(): string {
    if (!this.data.females) {
      return '0';
    }
    return this.getBarHeight(this.data.females) + 'px';
  }

  private getBarHeight(animalsCount: number): number {
    return Math.round(FarmStatisticsFinishingBarChartComponent.MaxDesignedBarHeight * animalsCount / this.data.highestAggrValue);
  }

  public calculateMarginTop(barHeight: number): string {
    if (this.getBarHeight(barHeight) > 13) {
      return '0px';
    } else {
      if ((13 - this.getBarHeight(barHeight)) === 0) {
        return '-10px';
      } else {
        return (this.getBarHeight(barHeight) - 16) + 'px';
      }
    }
  }
}
