import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as moment from 'moment';
import {EpochStateModel} from '../model/epoch-state-model';

export enum TimeSelectionHoursMode {
  Mode24Hours = 'Mode24Hours',
  Mode12Hours = 'Mode12Hours'
}

@Component({
  selector: 'time-selection',
  templateUrl: './time-selection.component.html',
  styleUrls: ['./time-selection.component.scss']
})
export class TimeSelectionComponent {
  
  @Input()
  public valueBackgroundColor: string = '#f2f3f3'
  
  @Input()
  public hoursMode : TimeSelectionHoursMode;
  
  @Input()
  public selectedEpoch : EpochStateModel;
  
  @Output()
  public epochChanged = new EventEmitter<EpochStateModel>();
  
  constructor() {
  
  }
  
  public addHour() {
    this.selectedEpoch.addHour();
    this.epochChanged.emit(this.selectedEpoch);
  }
  
  public subtractHour() {
    this.selectedEpoch.subtractHour();
    this.epochChanged.emit(this.selectedEpoch);
  }

  public addMinute() {
    this.selectedEpoch.addMinute();
    this.epochChanged.emit(this.selectedEpoch);
  }
 
  public subtractMinute() {
    this.selectedEpoch.subtractMinute();
    this.epochChanged.emit(this.selectedEpoch);
  }
  
  public get hours() : string {
    if(this.selectedEpoch.epochTime == null) {
      return moment.unix(EpochStateModel.MinValue).utc().format('HH');
    } else {
      if (this.hoursMode == TimeSelectionHoursMode.Mode24Hours) {
        return moment.unix(this.selectedEpoch.epochTime).utc().format('HH');
      } else {
        return moment.unix(this.selectedEpoch.epochTime).utc().format('hh');
      }
    }
  }
  
  public get minutes() : string {
    if(this.selectedEpoch.epochTime == null) {
      return moment.unix(EpochStateModel.MinValue).utc().format('mm');
    } else {
      return moment.unix(this.selectedEpoch.epochTime).utc().format('mm');
    }
  }
}
