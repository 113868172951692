<div class="form-group" *ngFor="let value of reportMetaFilterValueColumnValues; index as i;">
  <div class="radio-wrapper">
    <div class="input-section" [ngClass]="{'last-element': (i + 1) % 4 === 0}">
      <ng-container *ngIf="!isBranchDynamicMeta">
        <input
          type="checkbox"
          name="{{value.key}}"
          value="{{value.key}}"
          [checked]="isValueChecked(value.key)"/>
        <label [attr.sh-id]="'radio-selected-' + value.key" [attr.for]="'radio-selected-' + value.key" (click)="toggleValue(value.key)"></label>
        <label class="title" *ngIf="!reportMetaFilterValueColumn.isLocalization">{{value.key}}</label>
        <label class="title" *ngIf="reportMetaFilterValueColumn.isLocalization" [attr.translation-id]="'REPORTS.GRID.STATUS.' + value.key">{{"REPORTS.GRID.STATUS." + value.key | translate}}</label>
      </ng-container>
      <ng-container *ngIf="isBranchDynamicMeta">
        <input
          type="checkbox"
          name="{{value.key.value}}"
          value="{{value.key.value}}"
          [checked]="isValueChecked(value.key.value)"/>
        <label [attr.sh-id]="'radio-selected-' + value.key.value" [attr.for]="'radio-selected-' + value.key.value" (click)="toggleValue(value.value)"></label>
        <ng-container *ngIf="value.key.isLocalization">
          <label class="title" [attr.translation-id]="'REPORTS.GRID.STATUS.' + value.key.value">{{"REPORTS.GRID.STATUS." + value.key.value | translate}}</label>
        </ng-container>
        <ng-container *ngIf="!value.key.isLocalization">
          <label class="title">{{value.key.value}}</label>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
