import { Injectable } from '@angular/core';
import {HttpResponseResult, HttpResponseResultNoBody, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from '../auth/auth.service';
import {IReproductionSettings} from './model/reproduction-settings';
import {IHealthSettings} from './model/health-settings';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../common/components/calendar/model/epoch-state-model';
import {HttpParams} from '@angular/common/http';
import {GroupRoutineEventModifyModel, GroupRoutineEventsMetadata, GroupRoutineEventsResult} from './model/group-routine-events.model';
import {IAnimalResultModel} from '../animals/animals.service';
import {Subject} from "rxjs";

export interface IBreedingFarmAlerts {
  GroupRoutine: FarmAlertConfig;
  NewCowInCowsToInspectReport: FarmAlertConfig;
  NewCowInHeatReport: FarmAlertConfig;
  distress: FarmAlertConfig;
  prePostCalvingDistress: FarmAlertConfig;
}

export interface IFinishingFarmAlerts {
  finishingHealthReport: FarmAlertConfig;
}

export interface IFarmAlertConfig {
  email: boolean;
  mobile: boolean;
  sms: boolean;
  isAlways: boolean;
  startTime: EpochStateModel;
  endTime: EpochStateModel;
}

export class FarmAlertConfigData {
  constructor(public key: string, public value: FarmAlertConfig) {
  }
}

export class FarmAlertConfig implements IFarmAlertConfig {

  public startTime: EpochStateModel;

  public endTime: EpochStateModel;

  constructor(public email: boolean, public mobile: boolean, public sms: boolean, public isAlways: boolean, startTime?: number, endTime?: number) {
    this.startTime = new EpochStateModel(EpochStateModelMinMaxMode.Time);
    this.endTime = new EpochStateModel(EpochStateModelMinMaxMode.Time);
    if (startTime !== null || startTime === 0) {
      this.startTime.epochTime = startTime;
    }
    if (endTime !== null || endTime === 0) {
      this.endTime.epochTime = endTime;
    }
  }

  public getValues(): object {
    return {
      email: this.email,
      mobile: this.mobile,
      sms: this.sms,
      isAlways: this.isAlways,
      startTime: this.startTime.epochTime,
      endTime: this.endTime.epochTime
    }
  }
}

export interface IFarmAlertError {
  key: string;
  fieldName: string;
  succeeded: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ManageSettingsService {

  private static readonly BreedingFarmAlertsRoute = '/rest/api/farm/alerts';
  private static readonly FinishingFarmAlertsRoute = '/rest/api/finishing/farm/alerts';
  private static readonly ReproductionRoute = '/rest/api/farm/reproduction';
  private static readonly HealthRoute = '/rest/api/farm/health';
  private static readonly FinishingHealthRoute = '/rest/api/finishing/farm/health';
  private static readonly GroupRoutineEventsRoute = '/rest/api/farm/grouproutineevents';

  public editBarErrorMessage: string;

  public closeManageSettingsDialogSubject: Subject<void> = new Subject<void>();

  constructor(private restApiService: RestApiService, private authService: AuthService) {
  }

  public getBreedingFarmAlerts(): Promise<HttpResponseResult<IBreedingFarmAlerts>> {
    return this.restApiService.sendGetMethod<IBreedingFarmAlerts>(ManageSettingsService.BreedingFarmAlertsRoute, this.authService.currentUserAuthState.authDetails);
  }

  public getFinishingFarmAlerts(): Promise<HttpResponseResult<IFinishingFarmAlerts>> {
    return this.restApiService.sendGetMethod<IFinishingFarmAlerts>(ManageSettingsService.FinishingFarmAlertsRoute, this.authService.currentUserAuthState.authDetails);
  }

  public sendBreedingFarmAlerts(farmAlerts: IBreedingFarmAlerts): Promise<HttpResponseResultNoBody> {
    return this.restApiService.sendPutMethod(ManageSettingsService.BreedingFarmAlertsRoute, this.authService.currentUserAuthState.authDetails, farmAlerts);
  }

  public sendFinishingFarmAlerts(farmAlerts: IFinishingFarmAlerts): Promise<HttpResponseResultNoBody> {
    return this.restApiService.sendPutMethod(ManageSettingsService.FinishingFarmAlertsRoute, this.authService.currentUserAuthState.authDetails, farmAlerts);
  }

  public getReproductionSettings(): Promise<HttpResponseResult<IReproductionSettings>> {
    return this.restApiService.sendGetMethod<IReproductionSettings>(ManageSettingsService.ReproductionRoute, this.authService.currentUserAuthState.authDetails);
  }

  // tslint:disable-next-line:no-any
  public sendReproductionSettings(reproductionSettings: IReproductionSettings): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPutMethod(ManageSettingsService.ReproductionRoute, this.authService.currentUserAuthState.authDetails, reproductionSettings);
  }

  public getHealthSettings(): Promise<HttpResponseResult<IHealthSettings>> {
    return this.restApiService.sendGetMethod<IHealthSettings>(ManageSettingsService.HealthRoute, this.authService.currentUserAuthState.authDetails);
  }

  public getFinishingHealthSettings(): Promise<HttpResponseResult<IHealthSettings>> {
    return this.restApiService.sendGetMethod(ManageSettingsService.FinishingHealthRoute, this.authService.currentUserAuthState.authDetails);
  }

  // tslint:disable-next-line:no-any
  public sendHealthSettings(healthSettings: IHealthSettings): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPutMethod(ManageSettingsService.HealthRoute, this.authService.currentUserAuthState.authDetails, healthSettings);
  }

  // tslint:disable-next-line:no-any
  public sendFinishingHealthSettings(finishingHealthSettings: IHealthSettings): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPutMethod(ManageSettingsService.FinishingHealthRoute, this.authService.currentUserAuthState.authDetails, finishingHealthSettings);
  }

  public getGroupRoutineEvents(offsetPage: number, limit: number): Promise<HttpResponseResult<GroupRoutineEventsResult>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('offset', offsetPage.toString());
    httpParams = httpParams.append('limit', limit.toString());
    return this.restApiService.sendGetMethodAny<GroupRoutineEventsResult>(ManageSettingsService.GroupRoutineEventsRoute, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getGroupRoutineEventsMetadata() : Promise<HttpResponseResult<GroupRoutineEventsMetadata>> {
    return this.restApiService.sendGetMethod<GroupRoutineEventsMetadata>(ManageSettingsService.GroupRoutineEventsRoute + '?projection=metadata', this.authService.currentUserAuthState.authDetails);
  }

  // tslint:disable-next-line:no-any
  public createGroupRoutineEvent(groupRoutineEvent : GroupRoutineEventModifyModel) : Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPostMethod(ManageSettingsService.GroupRoutineEventsRoute, this.authService.currentUserAuthState.authDetails, groupRoutineEvent);
  }

  // tslint:disable-next-line:no-any
  public editGroupRoutineEvent(groupRoutineEvent : GroupRoutineEventModifyModel, eventId: number) : Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPutMethod(ManageSettingsService.GroupRoutineEventsRoute + '/' + eventId, this.authService.currentUserAuthState.authDetails, groupRoutineEvent);
  }

  public deleteGroupRoutineEvent(eventId: number) {
    return this.restApiService.sendDeleteMethod(ManageSettingsService.GroupRoutineEventsRoute + '/' + eventId, this.authService.currentUserAuthState.authDetails);
  }

  public closeManageSettingsDialog(): void {
    this.closeManageSettingsDialogSubject.next();
  }
}
