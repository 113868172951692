import {Component, Input, OnInit} from '@angular/core';
import {
  CreatePregnancyCheckEventDetails,
  PregnancyCheckCowEventMetadata,
  PregnancyCheckType
} from '../../../../../services/animals/model/animal-events';

@Component({
  selector: 'create-batch-event-pregnancy-check',
  templateUrl: './create-batch-event-pregnancy-check.component.html',
  styleUrls: ['./create-batch-event-pregnancy-check.component.scss']
})
export class CreateBatchEventPregnancyCheckComponent implements OnInit {

  @Input()
  public pregnancyCheckEventDetails : CreatePregnancyCheckEventDetails;

  @Input()
  public meta: PregnancyCheckCowEventMetadata;

  constructor() { }

  public ngOnInit() {
  }

  public onPregnancyCheckTypeChanged(newType:PregnancyCheckType) {
    this.pregnancyCheckEventDetails.type = newType;
  }
}
