import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'task-editor-edit-bar',
  templateUrl: './task-editor-edit-bar.component.html',
  styleUrls: ['./task-editor-edit-bar.component.scss']
})
export class TaskEditorEditBarComponent implements OnInit {

  @Input()
  public isMadeChanges: boolean;

  @Output()
  public onSavePressed: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onRevertPressed: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() { }

  public ngOnInit() {
  }

  public save() {
    this.onSavePressed.emit();
  }

  public revert(event: Event) {
    this.onRevertPressed.emit(event);
  }

}
