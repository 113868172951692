<description-cell-template (toggleExpanded)="toggleExpanded.emit()"
                           [canExpand]="hasRowDetails"
                           [isRowExpanded]="isRowExpanded"
                           [row]="row"
                           [rowShId]="rowShId">
  <div class="description">
    <div class="description-cell" [ngStyle]="{'height': row.rowDefaultHeight +'px'}">
      <span class="description-key"><ng-content select="[key]"></ng-content></span>
      <ellipsisable-text class="description-value"><ng-content select="[value]"></ng-content></ellipsisable-text>
    </div>
    <div class="details-content"
         [ngStyle]="{'min-height': row.rowDefaultHeight + 'px'}"
         [attr.sh-id]="'cell_details_' + rowShId">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
</description-cell-template>
