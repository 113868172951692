<div class="duration-horizontal-scroll-wrapper">
  <div class="scroll-container" (mousemove)="onMouseMove($event)" (mouseup)="onMouseUp()">
    <div class="scroll-bar" #scrollBar (click)="onScrollBarClick($event)">
      <div class="colored" [ngStyle]="{ width: deltaXPosition + 'px' }"></div>
    </div>
    <div class="scroll-thumb" [attr.sh-id]="'scroll_thumb-' + shIdPostFix" #scrollThumb [ngStyle]="{ marginLeft: deltaXPosition + 'px' }" (mousedown)="onMouseDown($event)"></div>
  </div>
  <div class="text-wrapper">
    <div class="text">{{ minText }}</div>
    <div class="text">{{ maxText }}</div>
  </div>
</div>
