<description-cell-details-template [hasRowDetails]="row.sire || row.interval || row.breedingComment"
                                   [isRowExpanded]="isExpanded"
                                   [row]="row"
                                   [rowShId]="row.eventId"
                                   (toggleExpanded)="toggleExpanded()">
  <ng-container key>{{"ANIMAL.EVENTS.EVENT_DESCRIPTION.BREEDING_NUMBER"| translate}}:&nbsp;</ng-container>
  <ng-container value>{{row.breedingNumber || 0}}</ng-container>
  <ng-container content>
    <p class="description-item" *ngIf="row.sire">
      <span>{{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.SIRE' | translate}}:&nbsp;</span>
      <ellipsisable-text class="value">{{row.sire.name}}&nbsp;{{row.sire.number}}</ellipsisable-text></p>
    <p class="description-item" *ngIf="row.interval">
      <span>{{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.INTERVAL' | translate}}:&nbsp;</span>
      <span class="value">{{row.interval}}</span></p>
    <p class="description-item" *ngIf="row.breedingComment" [attr.translation-id]="'ANIMAL.EVENTS.EVENT_DESCRIPTION.BREEDING_COMMENT.' + row.breedingComment">{{'ANIMAL.EVENTS.EVENT_DESCRIPTION.BREEDING_COMMENT.' + row.breedingComment | translate}}</p>
  </ng-container>
</description-cell-details-template>
