<modal-dialog-template *ngIf="isOpen"
                       [isOpen]="isOpen"
                       [modalWidth]="384"
                       [modalHeight]="546"
                       (onEscapeButtonClick)="onCloseClick()"
                       (onEnterButtonClick)="onRightButtonClick()"
                       (outsideModalClick)="onCloseClick()"
                       [attr.sh-id]="'provisioning_auth_dialog'">
  <corner-container [attr.sh-id]="'provisioning_auth_dialog'"
                    [cornerColor]="CornerColor.White"
                    [footerHeight]="50"
                    [bodyBackground]="CornerContainerBodyBackground.White">
    <ng-container container-body>
      <div class="provisioning-auth-dialog">
        <div class="provisioning-auth-dialog-header">
          <div class="provisioning-auth-dialog-header-allflex-logo"></div>
          <span class="provisioning-auth-dialog-header-cross" sh-id="provisioning_auth_dialog_close" (click)="onCloseClick()"></span>
        </div>
        <div class="provisioning-auth-dialog-header-description">
          <p *ngIf="!errorKey" class="provisioning-auth-dialog-header-enter-credentials" [attr.translation-id]="'SYSTEM.DATA_SHARING.DIALOGS.AUTH.ENTER_CREDENTIALS'">
            {{'SYSTEM.DATA_SHARING.DIALOGS.AUTH.ENTER_CREDENTIALS' | translate}}
          </p>
          <div *ngIf="errorKey" class="provisioning-auth-dialog-header-error">
            <label [attr.translation-id]="'SYSTEM.DATA_SHARING.DIALOGS.AUTH.LOGIN_FAILED: SYSTEM.DATA_SHARING.ERRORS.' + errorKey" [attr.sh-id]="'provisioning_auth_dialog_error'">
              {{'SYSTEM.DATA_SHARING.DIALOGS.AUTH.LOGIN_FAILED' | translate}} - {{'SYSTEM.DATA_SHARING.ERRORS.' + errorKey | translate}}
            </label>
          </div>
        </div>
        <div class="provisioning-auth-dialog-body">
          <form class="provisioning-auth-dialog-body-auth-form" name="provisioningAuthForm" ngNativeValidate autocomplete="off">
            <div class="provisioning-auth-dialog-body-auth-form-field">
              <label class="provisioning-auth-dialog-body-auth-form-field-label"
                     [ngClass]="{'disabled': true}"
                     translation-id="SYSTEM.DATA_SHARING.DIALOGS.AUTH.USERNAME">{{'SYSTEM.DATA_SHARING.DIALOGS.AUTH.USERNAME' | translate}}</label>
              <input type="text"
                     class="provisioning-auth-dialog-body-auth-form-field-input"
                     disabled
                     name="userName"
                     translation-id="SYSTEM.DATA_SHARING.DIALOGS.AUTH.USERNAME"
                     [ngModel]="adminName"
                     placeholder="{{'SYSTEM.DATA_SHARING.DIALOGS.AUTH.USERNAME' | translate}}"
                     spellcheck="false" />
            </div>
            <div class="provisioning-auth-dialog-body-auth-form-field provisioning-auth-dialog-body-auth-form-field--password">
              <label class="provisioning-auth-dialog-body-auth-form-field-label"
                     [ngClass]="{'disabled': isLoading}"
                     translation-id="SYSTEM.DATA_SHARING.DIALOGS.AUTH.PASSWORD">{{'SYSTEM.DATA_SHARING.DIALOGS.AUTH.PASSWORD' | translate}}</label>
              <input type="password"
                     class="provisioning-auth-dialog-body-auth-form-field-input"
                     [ngClass]="{'provisioning-auth-dialog-body-auth-form-field-input-error': hasPasswordError}"
                     [attr.disabled]="isLoading ? '' : null"
                     (input)="onPasswordChange()"
                     translation-id="SYSTEM.DATA_SHARING.DIALOGS.AUTH.PASSWORD"
                     name="password"
                     [(ngModel)]="password"
                     placeholder="{{'SYSTEM.DATA_SHARING.DIALOGS.AUTH.PASSWORD' | translate}}"
                     autocomplete="new-password"
                     sh-id="provisioning_auth_dialog_password_field" />
            </div>
          </form>
        </div>
        <div class="provisioning-auth-dialog-buttons">
          <p class="provisioning-auth-dialog-buttons--button provisioning-auth-dialog-buttons--button-cancel"
             sh-id="provisioning_auth_dialog_cancel" (click)="onCloseClick()">
            <span translation-id="COMMON.BUTTON.CANCEL">{{'COMMON.BUTTON.CANCEL' | translate}}</span>
          </p>
          <p class="provisioning-auth-dialog-buttons--button provisioning-auth-dialog-buttons--button-apply"
             [class.provisioning-auth-dialog-buttons--button-disabled]="isRightButtonDisabled()"
             sh-id="provisioning_auth_dialog_apply" (click)="onRightButtonClick()">
            <span translation-id="COMMON.BUTTON.APPLY">{{'SYSTEM.DATA_SHARING.DIALOGS.AUTH.LOGIN' | translate}}</span>
          </p>
        </div>
      </div>
    </ng-container>
    <ng-container container-footer>
      <div class="provisioning-auth-dialog-footer"></div>
    </ng-container>
  </corner-container>
</modal-dialog-template>
