<regular-edit-dialog-template [modalWidth]="560"
                              [isOpen]="isOpen"
                              [enableRemoveButton]="false"
                              [rightButtonDisabled]="epochState.epoch == null"
                              [shIdPostfix]="'date_time'"
                              (onCloseClick)="closeClick()"
                              (onLeftButtonClick)="closeClick()"
                              (onRightButtonClick)="applyClick()">
  <ng-container header-caption>
    {{title | translate}}
  </ng-container>
  <ng-container body>
    <div class="dialog-body" *ngIf="isOpen">
      <owl-date-time-inline [min]="epochState.minDate"
                            [max]="epochState.maxDate"
                            [ngModel]="selectedEpochDate"
                            [lang]="translationService.selectedLanguage"
                            (ngModelChange)="onDateChanged($event)"
                            [pickerType]="'calendar'"></owl-date-time-inline>
      <div class="separator"></div>
      <div class="time-part">
        <time-selection [selectedEpoch]="epochState"
                        [hoursMode]="hoursMode"
                        [valueBackgroundColor]="'#fff'"
                        (epochChanged)="onEpochTimeChanged($event)"></time-selection>
        <am-pm-selection [themeColor]="'#000'"
                         [selectedEpoch]="epochState">
        </am-pm-selection>
      </div>
    </div>
  </ng-container>
  <ng-container left-button>
    <span>{{ 'COMMON.BUTTON.CANCEL' | translate}}</span>
  </ng-container>
  <ng-container right-button>
    <span>{{ 'COMMON.BUTTON.APPLY' | translate}}</span>
  </ng-container>
</regular-edit-dialog-template>
