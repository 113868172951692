<div class="bull-choice-container">
  <popup-template [isOpen]="isOpen" (close)="onOutsideClick()">
    <ng-container header>
      <div class="input-area" [ngClass]="{ error: isFieldError, disabled: disabled }">
        <div class="add-bull-button" *ngIf="!disabled && !preventAddBull" (click)="openNewBullEditModal($event)">
        </div>
        <input class="search"
               [attr.sh-id]="'dropdown_list_search' + (shIdPrefix ? '_' + shIdPrefix : '')"
               type="button"
               [disabled]="disabled"
               (click)="toggleOpen()"
               (keydown.enter)="onEnter($event)"
               (keydown.arrowDown)="onArrowDown()"
               (keydown.arrowUp)="onArrowUp()"
               [value]="formatBullListItem(selectedBullListItem)"/>
        <div class="icon icon-delete" *ngIf="!disabled && selectedBullListItem != null" (click)="selectItem(null)"></div>
        <div class="icon"
             [ngClass]="[
             'controls-theme-' + iconTheme,
             !isOpen ? 'icon-drop-down' : '',
             isOpen ? 'icon-fold-up' : '',
             disabled ? 'disabled' : '']"
             *ngIf="selectedBullListItem == null"
             (click)="toggleOpen()">
        </div>
      </div>
    </ng-container>
    <ng-container popup-content>
      <div class="dropdown-popup" [ngClass]="{'fold-up': isFoldDirectionUp}">
        <ul class="items">
          <li *ngFor="let bullListItem of items; index as i"
              (click)="selectItem(bullListItem)"
              [id]="'bull-choice-item-' + i"
              (mouseenter)="markedItem = i"
              (mouseleave)="markedItem = null"
              [ngClass]="{
          'marked': isMarked(i),
          'is-favorite': bullListItem.favorite && !isLastFavorite(bullListItem, i),
          'is-last-favorite': isLastFavorite(bullListItem, i)
        }"
              [attr.sh-id]="'dropdown_list_item_' + i + (shIdPrefix ? '_' + shIdPrefix : '')">
            <span class="icon icon-favorite" *ngIf="bullListItem.favorite"></span>
            <ellipsisable-text class="item-name">{{formatBullListItem(bullListItem)}}</ellipsisable-text>
            <span class="edit-icon" outsideClickIgnore *ngIf="bullListItem.id != null" (click)="openBullEditModal(bullListItem, $event)"></span>
          </li>
        </ul>
      </div>
    </ng-container>
  </popup-template>
</div>


<regular-edit-dialog-template *ngIf="editableBullListItem"
                              [modalWidth]="555"
                              [modalHeight]="313"
                              [isOpen]="isModalOpen"
                              [enableRemoveButton]="editableBullListItem.id != null"
                              (onCloseClick)="modalCloseClick()"
                              (onLeftButtonClick)="modalCloseClick()"
                              (onRightButtonClick)="modalApplyClick()"
                              (onRemoveClick)="modalRemoveClick()"
                              (onEnterButtonClick)="modalApplyClick()">
  <ng-container header-caption>
    <span *ngIf="editableBullListItem.id != null">{{'ANIMAL.EVENTS.ADD_EVENT.SIRE' | translate}}</span>
    <span *ngIf="editableBullListItem.id == null">{{'ANIMAL.EVENTS.ADD_EVENT.NEW_SIRE' | translate}}</span>
  </ng-container>
  <ng-container body>
    <div class="form-group">
      <span class="form-group-label">{{'ANIMAL.EVENTS.ADD_EVENT.SIRE_NAME' | translate}}</span>
      <input type="text"
             name="editBullListItemName"
             placeholder="{{'ANIMAL.EVENTS.ADD_EVENT.SIRE_NAME' | translate}}"
             [(ngModel)]="editableBullListItem.name"
             [ngModelOptions]="{standalone: true}"
             [ngClass]="{validated: !isEditableBullItemNameValid}"/>
    </div>
    <div class="form-group">
      <span class="form-group-label">{{'ANIMAL.EVENTS.ADD_EVENT.SIRE_NUMBER' | translate}}</span>
      <input type="text"
             name="editBullListItemNumber"
             placeholder="{{'ANIMAL.EVENTS.ADD_EVENT.SIRE_NUMBER' | translate}}"
             [(ngModel)]="editableBullListItem.number"
             [ngModelOptions]="{standalone: true}"
             [ngClass]="{validated: !isEditableBullItemNumberValid}"/>
    </div>
    <span class="error">{{error | translate}}</span>
  </ng-container>
  <ng-container left-button>
    <span>{{ 'COMMON.BUTTON.CANCEL' | translate}}</span>
  </ng-container>
  <ng-container right-button>
    <span>{{ 'COMMON.BUTTON.APPLY' | translate}}</span>
  </ng-container>
</regular-edit-dialog-template>

