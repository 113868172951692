<div class="no-events-layout" [ngClass]="{'invisible' : hideNoEventsLayout}">
  <div class="milking-icon"></div>
  <div class="no-events-caption" [attr.translation-id]="'MANAGE.SETTINGS.NO_GROUP_ROUTINE_EVENTS'">{{'MANAGE.SETTINGS.NO_GROUP_ROUTINE_EVENTS' | translate}}</div>
  <div class="create-new-event-button" *ngIf="userPermissionsService.hasUserPermissionsForEditManageSettings()" (click)="createEventClick()" [attr.translation-id]="'MANAGE.SETTINGS.CREATE_NEW_GROUP_ROUTINE_EVENTS'">{{'MANAGE.SETTINGS.CREATE_NEW_GROUP_ROUTINE_EVENTS' | translate}}</div>
</div>
<div class="events-layout" [ngClass]="{'invisible' : hideEventsGrid}">
  <div class="title" [attr.translation-id]="'MANAGE.WELCOME.TITLE.GROUP_ROUTINE_EVENTS'">{{'MANAGE.WELCOME.TITLE.GROUP_ROUTINE_EVENTS' | translate}}</div>
  <div class="caption" [attr.translation-id]="'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.CAPTION'">{{'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.CAPTION' | translate}}</div>
  <div class="create-new-event-plus-button"  *ngIf="userPermissionsService.hasUserPermissionsForEditManageSettings()" (click)="createEventClick()"></div>
  <data-grid #groupRoutineEventsGrid *ngIf="groupRoutineEventsResult"
             [attr.sh-id]='"notesColumn"'
             [sortedGridColumn]="groupRoutineEventsColumn"
             [sortedGridCell]="groupRoutineEventsCell"
             [headerHeight]="40"
             [rowHeight]="40"
             [sortedGridQuery]="groupRoutineEventsGridQuery"
             [totalRows]="groupRoutineEventsResult.total"
             [columnsMappings]="columnsMappings"
             [rowsData]="groupRoutineEventsResult.result"
             [viewContext]="this"
             (onSortedGridQueryChanged)="getGroupRoutineEvents()">
  </data-grid>
</div>
<ng-template #groupRoutineEventsColumn let-column="column">
  <ng-container [ngSwitch]="column">
    <span *ngSwitchCase="'eventType'" [attr.translation-id]="'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.COLUMN.NAME'">{{'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.COLUMN.NAME' | translate}}</span>
    <span *ngSwitchCase="'startTime'" [attr.translation-id]="'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.COLUMN.TIME'">{{'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.COLUMN.TIME' | translate}}</span>
    <span *ngSwitchCase="'groups'" [attr.translation-id]="'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.COLUMN.GROUPS'">{{'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.COLUMN.GROUPS' | translate}}</span>
  </ng-container>
</ng-template>

<ng-template #groupRoutineEventsCell let-column="column" let-row="row">
  <ng-container [ngSwitch]="column">
    <div *ngSwitchCase="'eventType'" class="event-type">
      <div [ngClass]="'event-icon-' + row.eventType"></div>
      <span class="event-text" [attr.tranlsation-id]="'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.EVENT_TYPE.' + row.eventType">{{'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.EVENT_TYPE.' + row.eventType | translate}}</span>
    </div>
    <div *ngSwitchCase="'startTime'" class="date-cell">
      <span>{{row.startTime | epochDateTime:DateTimeFormatEnum.Time:true}}</span>
    </div>
    <description-cell-entities *ngSwitchCase="'groups'"
                               [noEntitiesText]="'LED_TASKS.GRID.ALL_GROUPS'"
                               [entities]="getGroupDescriptionEntities(row)"
                               [row]="row"
                               (onToggleExpanded)="toggleExpandRow()"></description-cell-entities>
    <div *ngSwitchCase="'actions'" class="actions-cell">
      <row-actions-popup [rowActions]="rowActionsMap.get(row)"
                         (onPopupOpen)="onRowActionsOpen()"
                         (onRowActionClick)="handleRowAction($event.rowActionPopupItem.action, row)">
      </row-actions-popup>
    </div>
  </ng-container>
</ng-template>
<edit-gre *ngIf="isEditEventOpen"
          [groupRoutineEventModifyModel]="editedGroupRoutineEvent"
          [groupRoutineEventId]="editedGroupRoutineEventId"
          (onSaveCompleted)="onSaveCompleted()"
          (onClose)="onClose()"></edit-gre>
<confirm-delete-dialog [isOpen]="isDeleteDialogShown"
                       [headerText]="'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.DELETE_DIALOG.HEADER'"
                       [primaryText]="'MANAGE.SETTINGS.GROUP_ROUTINE_EVENTS.DELETE_DIALOG.CONTENT'"
                       (onDelete)="deleteGroupRoutineEvent()"
                       (onCancel)="closeDeleteConfirmationDialog()">
</confirm-delete-dialog>
