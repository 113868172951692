import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import {TranslationService} from '../../services/translations/translation.service';

@Pipe({
  name: 'reportDuration'
})
export class ReportDurationPipe implements PipeTransform {

  constructor(private translationService: TranslationService) {

  }

  public transform(value: number, reportTime: number): string {
    if (value == null)
      return "";

    const currTime = moment.unix(reportTime).toDate();
    const timeStampTime = moment.unix(value).toDate();

    let timeMin = ((currTime.getTime()) - timeStampTime.getTime()) / (60 * 1000);
    let timeAgoResult:string = "";
    // if the time current time and the timestamp are equal
    if(timeMin < 0)
      timeMin = 0;
    // if timestamp is less than 1 - difference is in seconds
    if(timeMin < 1) {
      timeAgoResult = `${Math.floor(timeMin * 60)} ${this.translationService.translate('TAGS.GRID.TIME_AGO.SEC')}`;
    }
    // if timestamp is more than 1 and less than 60 - difference is in minutes
    else if (timeMin >= 1 && timeMin < 60) {
      timeAgoResult = `${Math.floor(timeMin)} ${this.translationService.translate('TAGS.GRID.TIME_AGO.MIN_LONG')}`;
    }
    // if timestamp is more than 60 minutes until 24 hours - difference is in hours
    else if (timeMin >= 60 && timeMin <= 60 * 24) {
      timeAgoResult = `${Math.floor(timeMin / 60)} ${this.translationService.translate('TAGS.GRID.TIME_AGO.HOURS')}`;
      let minutesLeftover = Math.floor(timeMin % 60);
      if(minutesLeftover > 0) {
        timeAgoResult += ' ';
        timeAgoResult += `${minutesLeftover} ${this.translationService.translate('TAGS.GRID.TIME_AGO.MIN_SHORT')}`;
      }
    }
    // if timestamp is more than 24 hours and until 2 days - difference is in days + hours
    else if ((timeMin >=  (60 * 24)) && (timeMin <  (60 * 24 * 2))) {
      timeAgoResult = `${Math.floor(timeMin / 60 / 24)} ${this.translationService.translate('TAGS.GRID.TIME_AGO.DAYS')}`;
      // check the number of hours that has passed (if any)
      let hoursLeftover = Math.floor(((timeMin / 60) + 1) % 24);
      if(hoursLeftover > 0) {
        timeAgoResult += ' ';
        timeAgoResult += `${hoursLeftover} ${this.translationService.translate('TAGS.GRID.TIME_AGO.HOURS')}`;
      }
      // if timestamp is more than 2 days and less than a year - show only days
    } else if (timeMin >=  60 * 24 *2 && timeMin <  60 * 24 * 365) {
      timeAgoResult = `${Math.floor(timeMin / 60 / 24)} ${this.translationService.translate('TAGS.GRID.TIME_AGO.DAYS')}`;
      // if timestamp is more than a year
    } else {
      timeAgoResult = `${Math.floor(timeMin / 60 / 24 / 365)} ${this.translationService.translate('TAGS.GRID.TIME_AGO.YEARS')}`;
    }
    return timeAgoResult;
  }
}
