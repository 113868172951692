import {Component, Input, SimpleChanges} from '@angular/core';
import {
  AbortionCowEventMetadata,
  AbortionType,
  CreateAbortionEventDetails,
  Newborn
} from '../../../../../services/animals/model/animal-events';
import * as _ from 'lodash';
import {EventFormFieldName, EventFormComponent} from '../event-form.component';
import {DropdownListItem} from '../../../../../common/components/dropdown-list/dropdown-list-model';
import {FormsDLIconsThemes} from "../../../../../common/components/dropdown-list/forms-dropdown-list/forms-dropdown-list.component";

@Component({
  selector: 'abortion-event-form',
  templateUrl: './abortion-event-form.component.html',
  styleUrls: ['./abortion-event-form.component.scss']
})
export class AbortionEventFormComponent extends EventFormComponent<AbortionCowEventMetadata, CreateAbortionEventDetails> {

  @Input()
  public isAddNewbornsDisabled: boolean = false;

  private cowsGroupsNewLactation: DropdownListItem[] = [];

  private cowsGroupsSameLactation: DropdownListItem[] = [];

  public editedNewborn: Newborn;

  public formsDLIconsThemes = FormsDLIconsThemes;

  public editableNewbornIndex: number;

  public get destinationGroup(): number | undefined {
    return this.editModel.destinationGroup;
  }

  public set destinationGroup(newValue: number | undefined) {
    this.editModel.destinationGroup = newValue;
    this.emitModelChanged();
  }

  public get abortionType(): AbortionType {
    return this.editModel.type as AbortionType;
  }

  public set abortionType(newValue: AbortionType) {
    this.editModel.type = newValue;
    this.emitModelChanged();
  }

  public get cowsGroups() : DropdownListItem[] {
    if(this.abortionType == AbortionType.AbortionNewLactation) {
      return this.cowsGroupsNewLactation;
    } else {
      return this.cowsGroupsSameLactation;
    }
  }

  public AbortionType = AbortionType;

  public EventFormFieldName = EventFormFieldName;

  constructor() {
    super();
  }

  protected onChanges(changes: SimpleChanges): void {
    if (changes.meta != null) {
      this.abortionType = this.defaultAbortionType;
      this.cowsGroupsNewLactation = [];
      if(this.meta.cowsGroupsNewLactation != null) {
        this.cowsGroupsNewLactation = this.meta.cowsGroupsNewLactation.map<DropdownListItem>((cg) => {
          return {
            value: cg.id,
            displayValue: cg.name,
            disabled: false,
            icon: null
          };
        });
      }
      this.cowsGroupsSameLactation = [];
      if(this.meta.cowsGroupsSameLactation != null) {
        this.cowsGroupsSameLactation = this.meta.cowsGroupsSameLactation.map<DropdownListItem>((cg) => {
          return {
            value: cg.id,
            displayValue: cg.name,
            disabled: false,
            icon: null
          };
        });
      }
    }
  }

  public handleAddNewborn(event:Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.editedNewborn = new Newborn();
  }

  public handleCloseAddNewbornPopup(): void {
    this.editableNewbornIndex = null;
  }

  public handleEditNewborn(event:Event, newborn: Newborn): void {
    event.preventDefault();
    event.stopPropagation();
    this.editableNewbornIndex = this.editModel.newborns.indexOf(newborn);
    this.editedNewborn = _.cloneDeep(newborn);
  }

  public handleAddNewbornChange(newborn: Newborn): void {
    if(this.editableNewbornIndex == null) {
      this.editModel.newborns.push(newborn);
    } else {
      this.editModel.newborns[this.editableNewbornIndex] = newborn;
      this.editableNewbornIndex = null;
    }
    this.emitModelChanged();
  }

  public handleRemoveNewborn(): void {
    this.editModel.newborns.splice(this.editableNewbornIndex, 1);
    this.editableNewbornIndex = null;
    this.emitModelChanged();
  }

  private get defaultAbortionType(): AbortionType {
    switch (this.meta.openNewLactation) {
      case true: return AbortionType.AbortionNewLactation;
      case false: return AbortionType.AbortionSameLactation;
      default: return AbortionType.Unselected;
    }
  }
}
