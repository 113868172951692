import {BlinkRate, IAdhocTaskModel} from './report.interface';

export class TurnLedLightingOnDialogModel implements IAdhocTaskModel {

  public static readonly MinDuration: number = 10;

  public reportId: number;

  public _blinkRate: BlinkRate = BlinkRate.Slow;

  public duration: number = TurnLedLightingOnDialogModel.MinDuration;

  public animalIds: number[];

  public get blinkRate(): BlinkRate {
      return this._blinkRate;
  }

  public set blinkRate(rate: BlinkRate) {
      this._blinkRate = rate;
      if (this.duration > this.taskMaxDuration) {
          this.duration = this.taskMaxDuration;
      }
  }

  public get taskMaxDuration(): number {
      return this.blinkRate == BlinkRate.Fast ? 240 : 600;
  }

  public get taskMaxDurationText(): string {
      return this.blinkRate == BlinkRate.Fast ? '4h' : '10h';
  }
}
