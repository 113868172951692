import { Pipe, PipeTransform } from '@angular/core';
import {TranslationService} from '../../services/translations/translation.service';

@Pipe({
  name: 'timeAgoTranslated',
  pure: false
})
export class TimeAgoTranslatedPipe implements PipeTransform {

  constructor(private readonly translationService: TranslationService) {

  }

  public transform(value: number): string {
    let timeMinutes: string = '';

    if (!value) {
      timeMinutes = this.translationService.translate('SYSTEM.DASHBOARD.GRID.TIME_AGO.FEW_SECONDS');
    } else if (value <= 60) {
      if (value === 0) {
        timeMinutes = this.translationService.translate('SYSTEM.DASHBOARD.GRID.TIME_AGO.FEW_SECONDS');
      } else {
        timeMinutes = `${value} ${this.translationService.translate('SYSTEM.DASHBOARD.GRID.TIME_AGO.MINUTES_AGO')}`;
      }
    } else if (value <= 2880 && value >= 60) {
      timeMinutes = `${Math.floor(value / 60)} ${this.translationService.translate('SYSTEM.DASHBOARD.GRID.TIME_AGO.HOURS_AGO')}`;
    } else if (value >= 2880) {
      timeMinutes = `${Math.floor(value / 60 / 24)} ${this.translationService.translate('SYSTEM.DASHBOARD.GRID.TIME_AGO.DAYS_AGO')}`;
    } else {
      timeMinutes = `${Math.floor(value / 60 / 24)} ${this.translationService.translate('SYSTEM.DASHBOARD.GRID.TIME_AGO.YEARS_AGO')}`;
    }

    return timeMinutes;
  }

}
