import {Inject, Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ISaveDataWarningDialogState} from '../../../common/components/dialogs/save-data-warning-dialog/save-data-warning-dialog.component';
import {WINDOW} from '../../../utils/window-utils';

@Injectable({
  providedIn: 'root'
})
export class SaveDataViewStateService {

  public showSaveDataWarningModalSubject: Subject<ISaveDataWarningDialogState> = new Subject();

  public onApproveDataLossClick: Subject<void> = new Subject();

  public activeEditArea : string;

  public isWarningModalPresent : boolean;

  // tslint:disable-next-line:no-any
  private warningIgnoreElements : Set<any> = new Set<any>();

  // tslint:disable-next-line:no-any
  private warningInjectElements: Set<any> = new Set<any>();

  // tslint:disable-next-line:no-any
  private targetElement:any;

  constructor(@Inject(WINDOW) private _window: Window,) {

  }

  // tslint:disable-next-line:no-any
  public addWarningIgnoreElement(targetElement: any) {
    this.warningIgnoreElements.add(targetElement);
  }

  // tslint:disable-next-line:no-any
  public removeWarningIgnoreElement(targetElement: any) {
    this.warningIgnoreElements.delete(targetElement);
  }

  // tslint:disable-next-line:no-any
  public addWarningInjectElement(targetElement: any) {
    this.warningInjectElements.add(targetElement);
  }

  // tslint:disable-next-line:no-any
  public removeWarningInjectElement(targetElement: any) {
    this.warningInjectElements.delete(targetElement);
  }

  // tslint:disable-next-line:no-any
  public hasWarningIgnoreElement(targetElement: any) {
    if(this.warningIgnoreElements.has(targetElement)) {
      return true;
    }
    for (let element of Array.from(this.warningIgnoreElements)){
      if(element.contains(targetElement)) {
        return true;
      }
    }
    return false;
  }

  // tslint:disable-next-line:no-any
  public hasWarningInjectElement(targetElement: any) {
    if(this.warningInjectElements.has(targetElement)) {
      return true;
    }
    for (let element of Array.from(this.warningInjectElements)){
      if(element.contains(targetElement)) {
        return true;
      }
    }
    return false;
  }

  // tslint:disable-next-line:no-any
  public showSaveDataWarningModal(targetElement: any, newState: ISaveDataWarningDialogState) {
    this.targetElement = targetElement;
    this.isWarningModalPresent = true;
    this.showSaveDataWarningModalSubject.next(newState);
  }

  public approveDataLossAndContinue() {
    this.onApproveDataLossClick.next();
    if (typeof this.targetElement.click == 'function') {
      this.targetElement.click();
      setTimeout(() => {
        this._window.dispatchEvent(new Event('resize'));
      });
    }
    this.isWarningModalPresent = false;
  }
}
