import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../../services/config/config.service';

@Component({
  selector: 'mock-print-export',
  templateUrl: './mock-print-export.component.html',
  styleUrls: ['./mock-print-export.component.scss']
})
export class MockPrintExportComponent {

  constructor(public configService:ConfigService) { }

}
