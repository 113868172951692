import { Component, SimpleChanges } from '@angular/core';
import {
  CreateChangeGroupEventDetails,
  ChangeGroupCowEventMetadata
} from '../../../../../services/animals/model/animal-events';
import {EventFormComponent, EventFormFieldName} from '../event-form.component';
import {DropdownListItem} from '../../../../../common/components/dropdown-list/dropdown-list-model';
import {FormsDLIconsThemes} from "../../../../../common/components/dropdown-list/forms-dropdown-list/forms-dropdown-list.component";

@Component({
  selector: 'change-group-event-form',
  templateUrl: './change-group-event-form.component.html',
  styleUrls: ['./change-group-event-form.component.scss']
})
export class ChangeGroupEventFormComponent extends EventFormComponent<
  ChangeGroupCowEventMetadata,
  CreateChangeGroupEventDetails
> {
  public get destinationGroup(): number | undefined {
    return this.editModel.newGroupId;
  }

  public set destinationGroup(newValue: number | undefined) {
    this.editModel.newGroupId = newValue;
    this.emitModelChanged();
  }

  public formsDLIconsThemes = FormsDLIconsThemes;

  public groupsDropdownItems: DropdownListItem[] = [];

  public EventFormFieldName = EventFormFieldName;

  constructor() {
    super();
  }

  protected onChanges(changes: SimpleChanges): void {
    if (changes.meta != null &&
        this.meta.groups != null) {
      this.groupsDropdownItems = this.meta.groups.map((value) => {
        return {
          value: value.id,
          displayValue: value.name,
          disabled: false,
          icon: null
        };
      });
    }
  }
}
