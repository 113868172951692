<notification-dialog-template #dialogRef
                              [isEscapeCloseDialog]="true"
                              [modalWidth]="400"
                              [modalHeight]="278"
                              [theme]="NotificationDialogTheme.blue"
                              [shIdPostfix]="'startedPremiumTrialDialogComponent'">
  <ng-container header>
    {{ 'DIALOGS.PREMIUM_TRIAL_DIALOG.PREMIUM_TRIAL_DIALOG_TITLE' | translate }}
  </ng-container>
  <ng-container body>
    <div class="content-wrapper">
      <div class="content-days-left" [attr.translation-id]="'DIALOGS.TRIAL.DAYS_LEFT'">{{ trialDays }} {{ 'DIALOGS.TRIAL.DAYS_LEFT' | translate }}</div>
      <div class="content" [attr.translation-id]="'DIALOGS.TRIAL.CALL_YOUR_LOCAL_DEALER_FOR_ADDITIONAL_INFORMATION'">
        {{ 'DIALOGS.TRIAL.CALL_YOUR_LOCAL_DEALER_FOR_ADDITIONAL_INFORMATION' | translate }}
      </div>

      <button class="close-button" (click)="onApprovingPremiumTrialPlan()" [attr.translation-id]="'SYSTEM.TAGS.GRID.STATUS.OK, DIALOGS.PREMIUM_TRIAL_DIALOG.GOT_IT'">
        {{ 'SYSTEM.TAGS.GRID.STATUS.OK' | translate }}, {{ 'DIALOGS.PREMIUM_TRIAL_DIALOG.GOT_IT' | translate }}
      </button>
    </div>
  </ng-container>
</notification-dialog-template>
