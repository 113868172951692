import {IUser} from './user.interface';
import {IImportFromCSVModel} from "../../import/import-from-csv.service";

export enum BizModel {
  Go = 'Go',
  Up = 'Up'
}

export enum FarmMode {
  Beef = 'Beef',
  Dairy = 'Dairy',
  Unknown = 'Unknown'
}

export enum ApplicationBundle {
  None = 'None',
  Advanced = 'Advanced',
  Starter = 'Starter',
  Premium = 'Premium'
}

export enum FinishingApplicationBundle {
  Finishing = 'Finishing',
  None = 'FinishingNone'
}

export enum YoungStockApplicationPlan  {
  YoungStock = 'YoungStock',
  None = 'None'
}

export enum SystemState {
  Active = 'Active',
  Suspend = 'Suspend',
  Trial = 'Trial'
}

export enum FarmPackage {
  Groups = 'Groups',
  Health = 'Health',
  Reproduction = 'Reproduction'
}

export enum UserRole {
  SuperUser = 'SuperUser',
  SystemUser = 'SystemUser',
  SupportUser = 'SupportUser',
  Admin = 'Admin',
  Thirdparty = 'Thirdparty',
  FarmManager = 'FarmManager',
  Farmer = 'Farmer',
  ExternalUser = 'ExternalUser',
  Trial = 'Trial'
}

export class ServerTimeZone {
  public utcOffset: number;
  public dstOffset?: number;
  public dstBegin?: number;
  public dstEnd?: number;
};

export interface IBizMode {
  applicationBundle: ApplicationBundle;
  youngStockApplicationBundle?: YoungStockApplicationPlan;
  systemState: SystemState;
  daysToExpire: string;
  finishingApplicationBundle: FinishingApplicationBundle;
}

export interface IThirdPartyParameters {
  addCow: boolean,
  addGroup: boolean,
  animalId: boolean,
  assignTag: boolean,
  birthDate: boolean,
  editGroupName: boolean,
  editGroupNumber: boolean,
  group: boolean,
  lactationNumber: boolean,
  lastCalvingDate: boolean,
  ornNumber: boolean,
  rfIdTag: boolean,
  scrTag: boolean,
  isSupportCulling: boolean,
  sireChoice1: boolean,
  sireChoice2: boolean,
  sireChoice3: boolean
}

export interface IPolls {
  systemKpis: string,
  sireList: string,
  dailyHeatStressGraphSetup: string,
  rfIdTags: string,
  groups: string,
  groupsMeta: string,
  tagsKpi: string,
  notes: string,
  reportsBrowse: string,
  deviceInventory: string,
  entityAlerts: string,
  heatStressGraphSetup: string,
  systemAlerts: string,
  statistics: string,
  reportsDashboard: string,
  reproductionGraphSetup: string,
  search: string,
  login: string,
  appLogout: string,
  healthGraphSetup: string,
  tagMaintenanceCallsDashboard: string,
  scrTags: string,
  deviceInventoryActions: string
}

export interface IActualPlan {
  youngStock: string;
  heifers: string;
  cows: string;
}

export interface IPremiumTrialInfo {
  link: string;
  remainingDays: number;
  actualPlan: IActualPlan;
}

export enum ServerStatusEnum {
  Inactive = 'Inactive',
  Trial = 'Trial',
  Active = 'Active',
  Suspend = 'Suspend'
}

export class ServerConfig {
  public bizModeParameters: IBizMode;
  public thirdPartyParameters: IThirdPartyParameters;
  public thirdPartyName: string;
  public customerId: string;
  public dealerId: string;
  public countryCode: string;
  public farmId: string;
  public contactUsUrl: string;
  public farmMode: FarmMode;
  public user: IUser;
  public serverStatus: ServerStatusEnum;
  public polls: IPolls;
  public serverStatusEndDate: number;
  public importFromFile?: IImportFromCSVModel;
  public isBugReportEnabled: boolean;
  public isSortingEnabled: boolean;
  public isLedOperationEnabled: boolean;
  public isLedOperationSupported: boolean;
  public farmPackage:FarmPackage;
  public farmRole: UserRole;
  public appVersion: string;
  public firstName: string;
  public lastName: string;
  public username: string;
  public farmName: string;
  public lastLoggedInTime: number;
  public authorizedUrl?: string;
  public displayVersion: string;
  public systemMessages: Array<string>;
  public possibleGroupBranches: Array<string>;
  public timeZone: ServerTimeZone;
  public premiumTrialInfo: IPremiumTrialInfo;
}

export enum AllowedManageAppBundle {
  Breeding = 'Breeding',
  Finishing = 'Finishing',
  BreedingAndFinishing = 'BreedingAndFinishing'
}
