import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {ReportQuery} from '../../services/reports/model/report-query.interface';
import {IReport, ReportDataRow, ReportKeyType, ReportRowNavigationScreen} from '../../services/reports/model/report.interface';
import {HttpResponseResult} from '../../services/rest-api/rest-api.service';
import {ReportsService} from '../../services/reports/reports.service';
import {DateTimeFormatEnum} from 'src/app/common/pipes/epoch-date-time.pipe';
import {GridSummaryIcon} from 'src/app/common/components/grid-summary/grid-summary.component';
import {ReportGridComponent} from '../../common/components/reports/report-grid/report-grid.component';
import {ReportRowSelectionState} from '../../services/reports/report-row-selection-state';
import {CardViewSelectionState} from '../card-popup/card-popup-model';
import {AnimalCardTabSection} from '../card-popup/animal-card/animal-card-model';
import {GroupCardTabSection} from '../card-popup/group-card/group-card-model';
import {IReportViewContext} from '../../services/reports/model/report-view-context';
import {ConfigService} from '../../services/config/config.service';
import {FarmMode} from '../../services/config/model/server-config';
import {OperationType} from '../../services/search/model/search.model';
import {EntityType, SearchService} from '../../services/search/search.service';
import {ApplicationBundleRoutingMode} from '../../services/routing/routing.service';
import {LoadingIconService} from "../../services/loading-icon/loading-icon.service";
import {GridViewState} from '../../services/ui/view-state/grid-row/grid-view-state';
import {CardViewStateService} from '../../services/ui/view-state/card-view-state.service';

export interface ISelectedLinesReportRequest {
  lineSelection: ILineSelection;
  reportCacheUid: number;
  reportTime?: number;
  requestType?: string;
  shiftId?: number;
}

export interface ILineSelection {
  lineNumbers: number[];
  sorting: string;
}

export enum ReportTypes {
  Anestrus = 1,
  IrregularHeats = 2,
  Health = 4,
  TagMaintenance = 6,
  AnimalDistress = 7,
  EarlyFreshCows = 9,
  SuspectedForAbortion = 10,
  AnimalsToInspect = 11,
  GroupRoutine = 12,
  AnimalsInHeat = 13,
  GroupRoutineHeatStress = 14,
  YoungStockHealth = 21,
  PregnancyProbability = 23,
}

@Component({
  selector: 'reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy, IReportViewContext {

  @ViewChild('reportGrid')
  public reportGrid: ReportGridComponent;

  public report:IReport;

  public isPastReport: boolean = false;

  public defaultFilter: boolean;

  public isDefaultFilterChanged: boolean = false;

  public DateTimeFormatEnum = DateTimeFormatEnum;

  public isResetDefaultFilter: boolean = true;

  public reportQuery: ReportQuery;

  private reportKey:string;

  private reportsRouteSubscription: Subscription;

  public rowSelectionState: ReportRowSelectionState = new ReportRowSelectionState();

  public gridViewState: GridViewState = new GridViewState();

  public GridSummaryIcon = GridSummaryIcon;

  public applicationBundleRoutingMode: ApplicationBundleRoutingMode = ApplicationBundleRoutingMode.Breeding;

  constructor(private readonly route: ActivatedRoute,
              private readonly configService: ConfigService,
              private readonly searchService: SearchService,
              private readonly loadingIconService: LoadingIconService,
              private readonly reportsService: ReportsService,
              private readonly cardViewStateService: CardViewStateService) {
  }

  private static handleGroupCardNavigationFromReport(rowNavigation: ReportRowNavigationScreen): GroupCardTabSection {
    switch (rowNavigation) {
      case ReportRowNavigationScreen.GroupRoutineHeatStressGraph: {
        return GroupCardTabSection.GraphsHeat;
      }
      case ReportRowNavigationScreen.GroupRoutineActivityGraph: {
        return GroupCardTabSection.GraphsActivity;
      }
      case ReportRowNavigationScreen.GroupRoutineRuminationGraph: {
        return GroupCardTabSection.GraphsActivityRumination;
      }
      default: {
        return GroupCardTabSection.Details;
      }
    }
  }

  public ngOnInit() {
    this.reportsRouteSubscription = this.route.params.subscribe(async params => {
      this.report = null;
      this.gridViewState  = new GridViewState();
      this.rowSelectionState = new ReportRowSelectionState();
      this.reportKey = params['key']; // (+) converts string 'id' to a number
      this.setDefaultReportQuery(true);
      this.isDefaultFilterChanged = false;
      this.isResetDefaultFilter = true;
      this.isPastReport = false;
      this.loadingIconService.show();
      await this.loadReport();
    });
  }

  public ngOnDestroy(): void {
    this.reportsRouteSubscription.unsubscribe();
  }

  public async reload(isRefresh:boolean, pastReportTime:number): Promise<void> {
    await this.reportGrid.reloadGrid(isRefresh, pastReportTime, true);
  }

  public async getReportByQuery(reportQuery: ReportQuery): Promise<IReport> {
    let reportResponse: HttpResponseResult<IReport> = await this.reportsService.getReport(this.reportKey, reportQuery);
    if(reportResponse.responseBody) {
      return reportResponse.responseBody;
    } else {
      return null;
    }
  }

  public async getSelectedRowsReport() : Promise<IReport> {
    let reportResponse: HttpResponseResult<IReport> = await this.reportsService.getSelectedLinesReport(this.reportQuery,
                                                                                                       this.report.meta,
                                                                                                       this.rowSelectionState.getSelectedRows());
    return reportResponse.responseBody;
  }

  private setDefaultReportQuery(isOnLoad:boolean) {
    if(isOnLoad) {
      if(this.reportsService.onLoadDefaultReportQuery) {
        this.reportQuery = this.reportsService.onLoadDefaultReportQuery;
        this.reportsService.onLoadDefaultReportQuery = null;
      } else {
        this.reportQuery = this.reportsService.defaultReportQuery;
        this.reportQuery.isRefresh = true;
      }
    } else {
      let currentReportQuery = this.reportQuery;
      this.reportQuery = this.reportsService.defaultReportQuery;
      this.reportQuery.filter = currentReportQuery.filter;
    }
  }

  private async loadReport() {
    this.reportQuery.useDefaultFilter = !this.isDefaultFilterChanged;
    this.report = await this.getReportByQuery(this.reportQuery);
    if (this.isResetDefaultFilter && this.report.meta.filter) {
      this.defaultFilter = Boolean(this.report.meta.filter.filterValue);
      this.isResetDefaultFilter = false;
    }
  }

  private async onReportQueryChanged() {
    await this.loadReport();
  }

  // tslint:disable-next-line:no-any
  public async onHeaderAction(actionData: any) {
    switch (actionData.action) {
      case 'filter': {
        this.isDefaultFilterChanged = true;
        if (actionData.data && actionData.data.filterValue) {
          this.defaultFilter = true;
          this.reportQuery.filterColumnName = actionData.data && actionData.data.columnName;
          this.reportQuery.filterValue = actionData.data && actionData.data.filterValue;
          await this.reportGrid.reloadGrid(false, this.report.meta.reportTime, true);
        } else {
          this.defaultFilter = false;
          this.reportQuery.filterColumnName = null;
          this.reportQuery.filterValue = null;
          await this.reportGrid.reloadGrid(false, this.report.meta.reportTime, true);
        }
      }
    }
  }

  public async showPastReport(pastReportDate: number) {
    this.isPastReport = true;
    this.isDefaultFilterChanged = false;
    this.isResetDefaultFilter = true;
    this.reportQuery.filterColumnName = null;
    this.reportQuery.filterValue = null;
    this.reportQuery.sort.clear();
    this.reportQuery.filter.clear();
    await this.reload(true, pastReportDate);
  }

  public isTimeAgoORDateTime(isPastReport: boolean): DateTimeFormatEnum {
    if (this.isPastReport) {
      return DateTimeFormatEnum.DateTime;
    } else {
      return DateTimeFormatEnum.TimeAgo;
    }
  }

  public async onReportGridValueCellClick(row: ReportDataRow) {
    switch (this.report.meta.reportKeyType) {
      case ReportKeyType.animal:
      {
        let reportQuery: ReportQuery = this.reportQuery.getFullReportQuery();
        let report: HttpResponseResult<IReport> = await this.reportsService.getReport(this.reportKey, reportQuery);
        // tslint:disable-next-line:no-any
        let relatedAnimals = Array.from(report.responseBody.rows.map((row: any) => { return row.CowDatabaseIDCalculation }));
        // tslint:disable-next-line:no-any
        let relatedAnimalRowIds = Array.from(report.responseBody.rows.map((row: any) => { return row.rowId }));
        let selectedAnimalId = row['CowDatabaseIDCalculation'];
        let animalCardTabSection = AnimalCardTabSection.Events;
        if(row.navigation == ReportRowNavigationScreen.CowCardHeatGraph) {
          animalCardTabSection = AnimalCardTabSection.GraphsHeat;
        } else if(row.navigation == ReportRowNavigationScreen.CowCardHealthGraph) {
          animalCardTabSection = AnimalCardTabSection.GraphsHealth;
        }
        let cardViewSelectionState : CardViewSelectionState = new CardViewSelectionState();
        cardViewSelectionState.relatedRowIds = relatedAnimalRowIds;
        cardViewSelectionState.openedRowId = row.rowId;
        cardViewSelectionState.rowSelectionState = this.rowSelectionState;
        let operationType: OperationType = await this.searchService.findOperationTypeByEntityId(selectedAnimalId, EntityType.cow);
        this.cardViewStateService.openAnimalCard(selectedAnimalId,
                                                 operationType,
                                                 relatedAnimals,
                                                 ['GROUP.NAVIGATION.COW_IN', 'REPORTS.HEADER.' + report.responseBody.meta.reportName, 'GROUP.NAVIGATION.REPORT' ],
                                                 animalCardTabSection,
                                                 cardViewSelectionState);
        break;
      }
      case ReportKeyType.group:
      {
        // get data and open group card in GraphsActivity section
        let relatedGroupsReportQuery = this.reportQuery.getFullReportQuery();
        let report: HttpResponseResult<IReport> = await this.reportsService.getReport(this.reportKey, relatedGroupsReportQuery);
        // tslint:disable-next-line:no-any
        let relatedGroups = Array.from(report.responseBody.rows.map((row: any) => { return row.GroupDatabaseIDCalculation }));
        // tslint:disable-next-line:no-any
        let relatedGroupsRowIds = Array.from(report.responseBody.rows.map((row: any) => { return row.rowId }));
        let selectedGroupId = row['GroupDatabaseIDCalculation'];
        let cardViewSelectionState : CardViewSelectionState = new CardViewSelectionState();
        cardViewSelectionState.relatedRowIds = relatedGroupsRowIds;
        cardViewSelectionState.openedRowId = row.rowId;
        cardViewSelectionState.rowSelectionState = this.rowSelectionState;
        let operationType = await this.searchService.findOperationTypeByEntityId(selectedGroupId, EntityType.group);
        const groupCardTabSection = ReportsComponent.handleGroupCardNavigationFromReport(row.navigation);
        this.cardViewStateService.openGroupCard(selectedGroupId,
                                                operationType,
                                                relatedGroups,
                                               ['GROUP.NAVIGATION.GROUP_IN','GROUP.NAVIGATION.GROUP_IN_FARM'],
                                                groupCardTabSection,
                                                cardViewSelectionState);
        break;
      }
      case ReportKeyType.tag:
      {
        if (!this.isTagMaintenanceClickIgnored(row)) {
          let reportQuery: ReportQuery = this.reportQuery.getFullReportQuery();
          let report: HttpResponseResult<IReport> = await this.reportsService.getReport(this.reportKey, reportQuery);
          // tslint:disable-next-line:no-any
          let relatedAnimals = Array.from(report.responseBody.rows.map((row: any) => { return row.TagCowDatabaseIDCalculation }));
          // tslint:disable-next-line:no-any
          let relatedAnimalRowIds = Array.from(report.responseBody.rows.map((row: any) => { return row.rowId }));
          let selectedAnimalId = row['TagCowDatabaseIDCalculation'];
          let animalCardTabSection = AnimalCardTabSection.Events;
          let cardViewSelectionState : CardViewSelectionState = new CardViewSelectionState();
          cardViewSelectionState.relatedRowIds = relatedAnimalRowIds;
          cardViewSelectionState.openedRowId = row.rowId;
          cardViewSelectionState.rowSelectionState = this.rowSelectionState;
          let operationType: OperationType = await this.searchService.findOperationTypeByEntityId(selectedAnimalId, EntityType.cow);
          this.cardViewStateService.openAnimalCard(selectedAnimalId,
                                                   operationType,
                                                   relatedAnimals,
                                                  ['GROUP.NAVIGATION.COW_IN', 'REPORTS.HEADER.' + report.responseBody.meta.reportName, 'GROUP.NAVIGATION.REPORT' ],
                                                   animalCardTabSection,
                                                   cardViewSelectionState);
        }
        break;
      }
    }
  }

  private isTagMaintenanceClickIgnored(row: ReportDataRow & { TagAnimalIDCalculation?: string, TagGroupNameCalculation?: string }): boolean {
    return (row.TagAnimalIDCalculation === null || typeof row.TagAnimalIDCalculation === 'undefined' || row.TagAnimalIDCalculation === '')
      && (row.TagGroupNameCalculation === null || typeof row.TagGroupNameCalculation === 'undefined' || row.TagGroupNameCalculation === '');
  }

  public async reloadDefault(): Promise<void> {
    if (this.defaultFilter) {
      this.reportQuery.filterColumnName = this.report.meta.filter?.filterColumnName;
      this.reportQuery.filterValue = this.defaultFilter;
    }
    this.report = null;
    this.isPastReport = false;
    this.rowSelectionState.clearSelection();
    await this.reload(true, null);
  }

  public getLoadedRows(): ReportDataRow[] {
    return this.reportGrid.rows;
  }

  public getRowSelectionState(): ReportRowSelectionState {
    return this.rowSelectionState;
  }

  public getGridViewState(): GridViewState {
    return this.gridViewState;
  }

  public getPrintIncludeDateInHeader(): boolean {
    return true;
  }

  public getPrintReportHeader(): string {
    return 'REPORTS.HEADER.' + this.report.meta.reportName;
  }
}
