import {Component, Input, OnInit} from '@angular/core';
import {AnimalTimelineFlowModel} from '../../../../services/animal-timeline/model/animal-timeline-model';

@Component({
  selector: 'animal-card-timeline',
  templateUrl: './animal-card-timeline.component.html',
  styleUrls: ['./animal-card-timeline.component.scss']
})
export class AnimalCardTimelineComponent implements OnInit {
  
  @Input()
  public animalTimelineFlowModel:AnimalTimelineFlowModel;
  
  public showDetails:boolean;
  
  constructor() { }
  
  public ngOnInit() {
  
  }
  
  public toggleShowDetails() {
    this.showDetails = !this.showDetails;
  }
}

