import {Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {ConfigService} from '../../../services/config/config.service';
import {FarmMode} from '../../../services/config/model/server-config';
import {
  IDashboardAvailableKPI,
  IDashboardDetailedKPI
} from '../../../services/dashboard/model/dashboard-kpi.interface';
import {DashboardKpiService} from '../../../services/dashboard/dashboard-kpi.service';
import {DashboardKpiSelectDialogComponent, SelectKpiDialogModel} from './dashboard-kpi-select-dialog/dashboard-kpi-select-dialog.component';
import {DashboardSaveKpi} from '../../../services/dashboard/model/dashboard.interface';
import {PollingKeyNames, PollingService} from '../../../services/rest-api/polling.service';
import {Subscription} from 'rxjs';
import {UserPermissionsService} from "../../../services/auth/user-permissions.service";


class DashboardKPIListItem {
  public name: string;
  public details: IDashboardDetailedKPI;
  public isExpanded: boolean;
}

@Component({
  selector: 'dashboard-kpi-accordion',
  templateUrl: './dashboard-kpi-accordion.component.html',
  styleUrls: ['./dashboard-kpi-accordion.component.scss']
})
export class DashboardKpiAccordionComponent implements OnInit, OnChanges, OnDestroy {

  public farmMode: FarmMode;

  public kpis: DashboardKPIListItem[];

  public editAvailableKpi: IDashboardAvailableKPI[];

  public selectKpiDialogModel: SelectKpiDialogModel;

  @ViewChild('kpiAccordion')
  public kpiAccordion: ElementRef;

  @ViewChild('dashboardKpiSelectDialog')
  public dashboardKpiSelectDialog: DashboardKpiSelectDialogComponent;

  @Input()
  public editMode:boolean;

  private pollingServiceSubscription: Subscription;

  constructor(public readonly dashboardKpiService: DashboardKpiService,
              private readonly pollingService: PollingService,
              public readonly configService: ConfigService,
              private readonly userPermissionsService: UserPermissionsService) {
  }

  @HostListener('document:click', ['$event.target'])
  public clickout(target) {
    if (!this.kpiAccordion.nativeElement.contains(target)) {
      this.closeAllExpansions();
    }
  }

  public async ngOnInit() {
    this.farmMode = this.configService.serverConfig.farmMode;
    await this.loadDetailedKPI();
    this.pollingServiceSubscription = this.pollingService.pollingChangesState.subscribe(async (pollsChanges) => {
      if (pollsChanges.includes(PollingKeyNames.dashboardKpiRecalculated)) {
        await this.loadDetailedKPI();
      }
    });
  }

  public async ngOnChanges(changes: SimpleChanges) {
    if(changes.editMode.currentValue !== changes.editMode.previousValue) {
      this.kpis = [];
      this.editAvailableKpi = [];
      if(this.editMode) {
        await this.loadAvailableKPI();
      } else {
        await this.loadDetailedKPI();
      }
    }
  }

  public ngOnDestroy(): void {
    this.pollingServiceSubscription?.unsubscribe();
  }

  public toggleExpansion(kpi: DashboardKPIListItem) {
    if(this.editMode) {
      return;
    }
    if (kpi.isExpanded) {
      this.closeAllExpansions();
    } else {
      this.closeAllExpansions();
      kpi.isExpanded = true;
    }
  }

  public closeAllExpansions() {
    if(this.kpis == null) {
      return;
    }
    this.kpis.forEach((kpi: DashboardKPIListItem) => {
      kpi.isExpanded = false;
    });
  }

  public showValueFromKpiAccordingToType(kpi: IDashboardDetailedKPI) {
    let valueToReturn: string = '- -';
    if (kpi.mainValue && kpi.mainValue.value && kpi.mainValue.type === "Percentage") {
      valueToReturn = `${kpi.mainValue.value % 1 === 0 ? kpi.mainValue.value : Number(kpi.mainValue.value).toFixed(1)}%`;
    }
    if (kpi.mainValue && kpi.mainValue.value && kpi.mainValue.type === "Number") {
      valueToReturn = `${Number(kpi.mainValue.value).toFixed(1)}`;
    }
    return valueToReturn;
  }

  public showSecondaryValueFromKpiAccordingToType(kpi: IDashboardDetailedKPI) {
    let valueToReturn: string = '';
    if (kpi.secondaryValue && kpi.secondaryValue.value && kpi.secondaryValue.type === "Percentage") {
      valueToReturn = `(${kpi.secondaryValue.value % 1 == 0 ? kpi.secondaryValue.value : Number(kpi.secondaryValue.value).toFixed(1)}%)`;
    }
    if (kpi.secondaryValue && kpi.secondaryValue.value && kpi.secondaryValue.type === "Number") {
      valueToReturn = `(${Number(kpi.secondaryValue.value).toFixed(1)})`;
    }
    return valueToReturn;
  }

  public getItemBodyColor(kpi: DashboardKPIListItem) : string {
    if(this.editMode) {
      if(kpi.name == null) {
        return 'empty-background';
      } else {
        return 'regular-background';
      }
    } else {
      switch (kpi.details.colorCode) {
        case 'Positive':
          return 'regular-background';
        case 'Intermediate':
          return 'warning-background';
        case 'Negative':
          return 'danger-background';
      }
    }
  }

  public updateSelectedKPI() {
    this.kpis = this.editAvailableKpi.filter(value => value.isSelected)
                                     .slice(0, DashboardKpiService.MaxNumberOfItems)
                                     .map(value => {return {name: value.name, details: null, isExpanded: false}});
    if(this.kpis.length < DashboardKpiService.MaxNumberOfItems) {
      this.kpis.push({name: null, isExpanded: false, details: null});
    }
  }

  public unselectKPI(kpiListItem: DashboardKPIListItem) {
    let foundKpi = this.editAvailableKpi.find(value => value.name == kpiListItem.name);
    foundKpi.isSelected = false;
    this.updateSelectedKPI();
  }

  public openSelectPopup(event:Event) {
    event.preventDefault();
    event.stopPropagation();
    this.selectKpiDialogModel = {
      editAvailableKpi: _.cloneDeep(this.editAvailableKpi)
    };
    this.dashboardKpiSelectDialog.open();
  }

  public onKpiSelectDialogApply() {
    this.editAvailableKpi = this.selectKpiDialogModel.editAvailableKpi;
    this.updateSelectedKPI();
  }

  public onKpiSelectDialogCancel() {
    this.selectKpiDialogModel = null;
  }

  public getKpisForSave() : DashboardSaveKpi[] {
    return this.editAvailableKpi.map(value => {
      let dashboardSaveKpi : DashboardSaveKpi = {
        name: value.name,
        isSelected: value.isSelected
      };
      return dashboardSaveKpi;
    });
  }

  private async loadDetailedKPI() {
    let response = await this.dashboardKpiService.getDetailedKPIs();
    if(response.status == 200) {
      let listOfKpis: IDashboardDetailedKPI[] = response.responseBody;
      if (listOfKpis.length) {
        listOfKpis.sort((firstKpi: IDashboardDetailedKPI, secondKpi: IDashboardDetailedKPI) =>  firstKpi.order - secondKpi.order );
          this.kpis = listOfKpis.map(value => {return {name: value.name, details: value, isExpanded: false}});
      }
    }
  }

  private async loadAvailableKPI() {
    this.kpis = [];
    let response = await this.dashboardKpiService.getAvailableKPIs();
    if(response.status == 200) {
      this.editAvailableKpi = response.responseBody;

      if (this.editAvailableKpi.length) {
        this.editAvailableKpi.sort((firstKpi: IDashboardAvailableKPI, secondKpi: IDashboardAvailableKPI) =>  firstKpi.order - secondKpi.order );
      }
    }
    this.updateSelectedKPI();
  }

  public get isFinishingStandalone() {
    return this.userPermissionsService.isFinishingStandalone();
  }
}
