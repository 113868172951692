<div class="branch-tabs">
  <ul class="branch-card-nav">
    <li class="tab" [ngClass]="{'active': viewState.selectedTab === BranchCardTabSection.Details}" [attr.sh-id]="'branch-card-tab-' + BranchCardTabSection.Details">
      <a (click)="selectTab(BranchCardTabSection.Details)">{{'GROUP.SIDE_NAVIGATION.DETAILS'| translate}}</a>
    </li>
    <li class="tab sub-menu">
      <a [attr.translation-id]="'BRANCH.NAVIGATION.TREND_GRAPHS'">{{'BRANCH.NAVIGATION.TREND_GRAPHS'| translate}}</a>
      <ul class="sub-menu">
        <li class="tab"
          [ngClass]="{'active': viewState.selectedTab === BranchCardTabSection.GraphsHeatStress}"
          [attr.sh-id]="'branch-card-tab-' + BranchCardTabSection.GraphsHeatStress">
          <a (click)="selectTab(BranchCardTabSection.GraphsHeatStress)" translation-id="BRANCH.MENU.HEAT">
            {{'BRANCH.MENU.HEAT' | translate}}
          </a>
        </li>
      </ul>
    </li>
  </ul>
  <div class="branch-tab-content">
    <branch-card-details *ngIf="viewState.selectedTab === BranchCardTabSection.Details"
                         [branchDetails]="viewState.branchDetails">
    </branch-card-details>
    <branch-card-graphs-consistency
      *ngIf="viewState.selectedTab == BranchCardTabSection.GraphsConsistency"
      [branchGraphDetails]="branchGraphDetails"
      [viewState]="viewState"
      [branchId]="viewState.branchDetails.id">
    </branch-card-graphs-consistency>
    <branch-card-graphs-heat-stress
      *ngIf="viewState.selectedTab == BranchCardTabSection.GraphsHeatStress"
      [branchGraphDetails]="branchGraphDetails"
      [viewState]="viewState"
      [branchId]="viewState.branchDetails.id">
    </branch-card-graphs-heat-stress>
  </div>
</div>
