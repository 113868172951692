<modal-dialog-template
  *ngIf="showPopup$ | async as animalNames"
  [modalWidth]="'auto'"
  [modalHeight]="'auto'"
  [isOpen]="animalNames != null"
  (onEscapeButtonClick)="handleClose()">
  <div class="layout">
    <div class="layout-header">
      <div class="layout-header__icon"></div>
      <div class="layout-header__text" translation-id="DIALOG.COMMON.BATCH_EVENT_ERROR.HEADER">
        {{ 'DIALOG.COMMON.BATCH_EVENT_ERROR.HEADER' | translate }}
      </div>
      <div class="layout-header__close-icon" (click)="handleClose()"></div>
    </div>
    <div class="layout-content">
      <div class="animals">
        <div class="animal" *ngFor="let animalName of animalNames">
          {{ animalName }}
        </div>
      </div>
    </div>
  </div>
</modal-dialog-template>
