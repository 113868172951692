import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManageRoutingModule } from './manage-routing.module';
import {ManageSettingsWelcomeComponent} from './manage-settings/manage-settings-welcome/manage-settings-welcome.component';
import {ManageSettingsComponent} from './manage-settings/manage-settings.component';
import {ManageGroupsComponent} from './manage-groups/manage-groups.component';
import {ManageEventsComponent} from './manage-events/manage-events.component';
import {ManageBranchesComponent} from './manage-branches/manage-branches.component';
import {ManageCulledAnimalsComponent} from './manage-animals/manage-culled-animals/manage-culled-animals.component';
import {ManageActiveAnimalsComponent} from './manage-animals/manage-active-animals/manage-active-animals.component';
import {ManageAnimalsComponent} from './manage-animals/manage-animals.component';
import {ManageComponent} from './manage.component';
import {ManageCreateEventComponent} from './manage-events/create-event/manage-create-event.component';
import {AddMaleBreedingComponent} from './manage-animals/add-animal/add-male/add-male-breeding/add-male-breeding.component';
import {AddMaleFinishingComponent} from './manage-animals/add-animal/add-male/add-male-finishing/add-male-finishing.component';
import {AddFemaleFinishingComponent} from './manage-animals/add-animal/add-female/add-female-finishing/add-female-finishing.component';
import {AddFemaleBreedingComponent} from './manage-animals/add-animal/add-female/add-female-breeding/add-female-breeding.component';
import {AddAnimalComponent} from './manage-animals/add-animal/add-animal.component';
import {ShSharedModule} from '../../common/sh-shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {GroupEditorComponent} from './manage-groups/group-editor/group-editor.component';
import {GroupEditorBreedingComponent} from './manage-groups/group-editor/group-editor-breeding/group-editor-breeding.component';
import {GroupEditorFinishingComponent} from './manage-groups/group-editor/group-editor-finishing/group-editor-finishing.component';
import {AnimalEventsModule} from '../animal-events/animal-events.module';
import {BranchEditorComponent} from './manage-branches/branch-editor/branch-editor.component';
import {LoggedInLayoutModule} from '../logged-in-layout.module';
import { AddFemaleBreedingGeneralTabComponent } from './manage-animals/add-animal/add-female/add-female-breeding/add-female-breeding-general-tab/add-female-breeding-general-tab.component';
import { AddFemaleBreedingCalvingTabComponent } from './manage-animals/add-animal/add-female/add-female-breeding/add-female-breeding-calving-tab/add-female-breeding-calving-tab.component';
import { AddFemaleBreedingBreedingTabComponent } from './manage-animals/add-animal/add-female/add-female-breeding/add-female-breeding-breeding-tab/add-female-breeding-breeding-tab.component';
import { AddFemaleBreedingPregCheckTabComponent } from './manage-animals/add-animal/add-female/add-female-breeding/add-female-breeding-preg-check-tab/add-female-breeding-preg-check-tab.component';
import { AddFemaleBreedingDryOffTabComponent } from './manage-animals/add-animal/add-female/add-female-breeding/add-female-breeding-dry-off-tab/add-female-breeding-dry-off-tab.component';
import {AddFemaleFinishingEntryTabComponent} from "./manage-animals/add-animal/add-female/add-female-finishing/add-female-finishing-entry-tab/add-female-finishing-entry-tab.component";
import {AddFemaleFinishingFarmTabComponent} from "./manage-animals/add-animal/add-female/add-female-finishing/add-female-finishing-farm-tab/add-female-finishing-farm-tab.component";
import {AddFemaleFinishingFinishingTabComponent} from "./manage-animals/add-animal/add-female/add-female-finishing/add-female-finishing-finishing-tab/add-female-finishing-finishing-tab.component";
import { AddMaleFinishingEntryTabComponent } from './manage-animals/add-animal/add-male/add-male-finishing/add-male-finishing-entry-tab/add-male-finishing-entry-tab.component';
import { AddMaleFinishingFarmTabComponent } from './manage-animals/add-animal/add-male/add-male-finishing/add-male-finishing-farm-tab/add-male-finishing-farm-tab.component';
import { AddMaleFinishingFinishingTabComponent } from './manage-animals/add-animal/add-male/add-male-finishing/add-male-finishing-finishing-tab/add-male-finishing-finishing-tab.component';
import { ManageNotesComponent } from './manage-notes/manage-notes.component';
import {SettingsItemComponent} from './manage-settings/manage-settings-welcome/settings-section/settings-section.component';
import { ManageSettingsLayoutComponent } from './manage-settings/manage-settings-layout/manage-settings-layout.component';
import {ManageSettingsShiftListComponent} from './manage-settings/manage-settings-layout/manage-settings-shifts/manage-settings-shift-list/manage-settings-shift-list.component';
import {ManageSettingsShiftsComponent} from './manage-settings/manage-settings-layout/manage-settings-shifts/manage-settings-shifts.component';
import {ManageSettingsHealthFinishingComponent} from './manage-settings/manage-settings-layout/manage-settings-health/manage-settings-health-finishing/manage-settings-health-finishing.component';
import {ManageSettingsHealthBreedingComponent} from './manage-settings/manage-settings-layout/manage-settings-health/manage-settings-health-breeding/manage-settings-health-breeding.component';
import {ManageSettingsReproductionComponent} from './manage-settings/manage-settings-layout/manage-settings-reproduction/manage-settings-reproduction.component';
import {ManageSettingsHealthComponent} from './manage-settings/manage-settings-layout/manage-settings-health/manage-settings-health.component';
import {ManageSettingsShiftItemComponent} from './manage-settings/manage-settings-layout/manage-settings-shifts/manage-settings-shift-item/manage-settings-shift-item.component';
import { ManageSettingsHeaderComponent } from './manage-settings/manage-settings-header/manage-settings-header.component';
import { ManageSettingsMenuitemComponent } from './manage-settings/manage-settings-layout/manage-settings-menuitem/manage-settings-menuitem.component';
import {ManageSettingsNotificationsAlertsComponent} from './manage-settings/manage-settings-layout/manage-settings-notifications-alerts/manage-settings-notifications-alerts.component';
import {ManageSettingsNotificationsAlertsBreedingComponent} from './manage-settings/manage-settings-layout/manage-settings-notifications-alerts/manage-settings-notifications-alerts-breeding/alerts-breeding.component';
import {ManageSettingsNotificationsAlertsFinishingComponent} from './manage-settings/manage-settings-layout/manage-settings-notifications-alerts/manage-settings-notifications-alerts-finishing/alerts-finishing.component';
import {NotificationTimingDialogComponent} from './manage-settings/manage-settings-layout/manage-settings-notifications-alerts/notification-timing-dialog/notification-timing-dialog.component';
import {NotificationTimingPlateComponent} from './manage-settings/manage-settings-layout/manage-settings-notifications-alerts/notification-timing-plate/notification-timing-plate.component';
import {ManageSettingsGroupRoutineEventsComponent} from './manage-settings/manage-settings-layout/manage-settings-gre/manage-settings-gre.component';
import {EditGreComponent} from './manage-settings/manage-settings-layout/manage-settings-gre/edit-gre/edit-gre.component';
import {NoteEditorComponent} from "./manage-notes/note-editor/note-editor.component";

@NgModule({
  declarations: [
    ManageComponent,
    ManageAnimalsComponent,
    ManageActiveAnimalsComponent,
    ManageCulledAnimalsComponent,
    ManageBranchesComponent,
    ManageEventsComponent,
    ManageGroupsComponent,
    ManageSettingsComponent,
    ManageSettingsWelcomeComponent,
    ManageSettingsNotificationsAlertsComponent,
    ManageSettingsNotificationsAlertsBreedingComponent,
    ManageSettingsNotificationsAlertsFinishingComponent,
    ManageSettingsReproductionComponent,
    ManageSettingsHealthComponent,
    ManageSettingsHealthBreedingComponent,
    ManageSettingsHealthFinishingComponent,
    ManageSettingsShiftsComponent,
    ManageSettingsShiftListComponent,
    AddAnimalComponent,
    AddFemaleBreedingComponent,
    AddFemaleFinishingComponent,
    AddFemaleFinishingEntryTabComponent,
    AddFemaleFinishingFarmTabComponent,
    AddFemaleFinishingFinishingTabComponent,
    AddMaleFinishingComponent,
    AddMaleFinishingEntryTabComponent,
    AddMaleFinishingFarmTabComponent,
    AddMaleFinishingFinishingTabComponent,
    AddMaleBreedingComponent,
    ManageCreateEventComponent,
    ManageSettingsShiftItemComponent,
    GroupEditorComponent,
    SettingsItemComponent,
    GroupEditorBreedingComponent,
    GroupEditorFinishingComponent,
    BranchEditorComponent,
    AddFemaleBreedingGeneralTabComponent,
    AddFemaleBreedingCalvingTabComponent,
    AddFemaleBreedingBreedingTabComponent,
    AddFemaleBreedingPregCheckTabComponent,
    AddFemaleBreedingDryOffTabComponent,
    NotificationTimingPlateComponent,
    NotificationTimingDialogComponent,
    ManageNotesComponent,
    NoteEditorComponent,
    ManageSettingsGroupRoutineEventsComponent,
    ManageSettingsLayoutComponent,
    ManageSettingsHeaderComponent,
    ManageSettingsMenuitemComponent,
    EditGreComponent
  ],
  imports: [
    CommonModule,
    ManageRoutingModule,
    ShSharedModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    FormsModule,
    AnimalEventsModule,
    LoggedInLayoutModule
  ]
})
export class ManageModule { }
