import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService, LoginStatus} from '../auth.service';
import {ConfigService} from '../../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private router: Router,
              private authService: AuthService,
              public configService: ConfigService) {

  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let loginResult: LoginStatus = await this.authService.getLoginStatus();
    switch (loginResult) {
      case LoginStatus.NotLoggedIn: {
        return true;
      }
      default: {
        await this.router.navigate(['/']);
        return false;
      }
    }
  }

}
