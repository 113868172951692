import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AbortionCowEventMetadata, Newborn, NewbornCondition, NewbornSex} from 'src/app/services/animals/model/animal-events';
import {IGroupMetadata} from 'src/app/services/groups/model/group.interface';
import {DropdownListItem} from '../../../../../common/components/dropdown-list/dropdown-list-model';
import {TranslationService} from '../../../../../services/translations/translation.service';
import {DropdownColorScheme} from "../../../../../common/components/dropdown-list/regular-dropdown-list/regular-dropdown-list.component";

@Component({
  selector: 'add-newborn-dialog',
  templateUrl: './add-newborn-dialog.component.html',
  styleUrls: ['./add-newborn-dialog.component.scss']
})
export class AddNewbornDialogComponent implements OnInit, OnChanges {

  @Input()
  public meta?: AbortionCowEventMetadata;

  @Input()
  public destinationGroups: IGroupMetadata[] = [];

  @Input()
  public model: Newborn;

  @Input()
  public enableRemoveButton: boolean;

  @Input()
  public errorFieldName: string;

  @Output()
  // tslint:disable-next-line:no-any
  public close: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public newbornChange: EventEmitter<Newborn> = new EventEmitter();

  @Output()
  public remove: EventEmitter<void> = new EventEmitter();

  public isOpen: boolean = false;

  public availableSexDropdownListItems: DropdownListItem<NewbornSex>[] = [];

  public availableConditionsDropdownListItems: DropdownListItem<NewbornCondition>[] = [];

  public destinationGroupsDropdownListItems: DropdownListItem<IGroupMetadata>[] = [];

  public selectedSex: DropdownListItem<NewbornSex>;

  public selectedCondition: DropdownListItem<NewbornCondition>;

  public selectedGroup: DropdownListItem<IGroupMetadata>;

  public validateErrors: boolean;

  public dropdownColorScheme = DropdownColorScheme;

  constructor(private readonly translationService: TranslationService) {}

  public ngOnInit(): void {
    this.availableSexDropdownListItems = [
      {
        displayValue: this.translationService.translate(
          'ANIMAL.NEWBORN.SEX.FEMALE'
        ),
        value: NewbornSex.female,
        icon: null,
        disabled: false
      },
      {
        displayValue: this.translationService.translate('ANIMAL.NEWBORN.SEX.MALE'),
        value: NewbornSex.male,
        icon: null,
        disabled: false
      }
    ];

    this.availableConditionsDropdownListItems = [
      {
        displayValue: this.translationService.translate(
          'ANIMAL.NEWBORN.CONDITION.ALIVE'
        ),
        value: NewbornCondition.alive,
        icon: null,
        disabled: false
      },
      {
        displayValue: this.translationService.translate(
          'ANIMAL.NEWBORN.CONDITION.DEAD'
        ),
        value: NewbornCondition.dead,
        icon: null,
        disabled: false
      },
      {
        displayValue: this.translationService.translate(
          'ANIMAL.NEWBORN.CONDITION.SOLD'
        ),
        value: NewbornCondition.sold,
        icon: null,
        disabled: false
      }
    ];
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.destinationGroups != null &&
       changes.destinationGroups.previousValue != changes.destinationGroups.currentValue) {
      this.destinationGroupsDropdownListItems = [];
      if(this.destinationGroups != null) {
        this.destinationGroupsDropdownListItems = this.destinationGroups.map<DropdownListItem>((dg) => {
          return {
            value: dg,
            displayValue: dg.name,
            disabled: false,
            icon: null
          };
        });
      }
    }
    if(changes.model != null &&
       changes.model.previousValue != changes.model.currentValue) {
      this.validateErrors = false;
      if (this.model != null) {
        this.isOpen = true;
        this.selectedSex = this.availableSexDropdownListItems.find(value => value.value == this.model.sex);
        this.selectedGroup = this.destinationGroupsDropdownListItems.find(value => value.value.id == this.model.destinationGroup);
        this.selectedCondition = this.availableConditionsDropdownListItems.find(value => value.value == this.model.condition);
      } else {
        this.isOpen = false;
        this.selectedSex = null;
        this.selectedGroup = null;
        this.selectedCondition = null;
      }
    }
  }

  public get isSexFieldError():boolean {
    return this.validateErrors &&
           this.model.sex == null;
  }

  public get isConditionFieldError():boolean {
    return this.validateErrors &&
      this.model.condition == null;
  }

  public get isAnimalNameError(): boolean {
    return this.validateErrors &&
           this.enableDetails &&
           this.model.animalName == null;
  }

  public get isGroupError(): boolean {
    return this.validateErrors &&
           this.enableDetails &&
           this.model.destinationGroup == null;
  }

  public get enableDetails(): boolean {
    return this.model.condition == null ||
           this.model.condition == NewbornCondition.alive;
  }

  public handleClose(): void {
    this.isOpen = false;
    this.close.emit();
    this.selectedSex = null;
    this.selectedGroup = null;
    this.selectedCondition = null;
  }

  public handleRemoveNewborn(): void {
    this.isOpen = false;
    this.remove.emit();
    this.selectedSex = null;
    this.selectedGroup = null;
    this.selectedCondition = null;
  }

  public handleApply(): void {
    this.validateErrors = true;
    if(this.isSexFieldError ||
       this.isConditionFieldError ||
       this.isAnimalNameError ||
       this.isGroupError) {
      return;
    }

    this.isOpen = false;
    this.newbornChange.emit(this.model);
    this.selectedSex = null;
    this.selectedGroup = null;
    this.selectedCondition = null;
  }

  public onSexSelected(sexDropdownListItem:DropdownListItem<NewbornSex>) {
    if(sexDropdownListItem != null) {
      this.model.sex = sexDropdownListItem.value;
    } else {
      this.model.sex = null;
    }
  }

  public onGroupSelected(groupDropdownListItem:DropdownListItem<IGroupMetadata>) {
    if(groupDropdownListItem != null) {
      this.model.destinationGroup = groupDropdownListItem.value.id;
    } else {
      this.model.destinationGroup = null;
    }
  }

  public onConditionSelected(conditionDropdownListItem:DropdownListItem<NewbornCondition>) {
    if(conditionDropdownListItem != null) {
      this.model.condition = conditionDropdownListItem.value;
    } else {
      this.model.condition = null;
    }
  }
}
