import {AnimalBadge, PregnancyCheckResult} from '../../../animals/model/animal.model';

export enum AnimalLactationStatus {
  None='None',
  Calf='Calf',
  BullCalf='BullCalf',
  Steer='Steer',
  HeiferFinishing='HeiferFinishing',
  BullCalfFinishing='BullCalfFinishing',
  Heifer='Heifer',
  HeiferReady='HeiferReady',
  HeiferInseminated='HeiferInseminated',
  HeiferOpen='HeiferOpen',
  HeiferPregnant='HeiferPregnant',
  Ready='Ready',
  Inseminated='Inseminated',
  Open='Open',
  Pregnant='Pregnant',
  Dry='Dry',
  Fresh='Fresh'
}

export interface IBreedingAnimalEventsTimelineData {
  animalBadge: AnimalBadge,
  dim: number,
  lactationStatus: AnimalLactationStatus,
  isUnknownStatus: boolean,
  startDate: number,
  lactationNumber: number,
  breedingDate: number,
  breedingNumber: number,
  pregnancyCheckResult: PregnancyCheckResult,
  dryOffDate: number,
  expectedCalvingDate: number,
  isExpectedDryOffPassed: boolean
}
