import * as _ from 'lodash';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  SlideStartTimeDirection
} from '../manage-settings-shifts.component';
import {ShiftModel} from '../../../../../../services/sorting-gate/model/shifts-model';
import {DateTimeFormatEnum} from '../../../../../../common/pipes/epoch-date-time.pipe';

export type ChangeNameEventParams = {
  name: string;
};

export type SlideStartTimeEventParams = {
  direction: SlideStartTimeDirection
};

@Component({
  selector: 'manage-settings-shift-item',
  templateUrl: './manage-settings-shift-item.component.html',
  styleUrls: ['./manage-settings-shift-item.component.scss']
})
export class ManageSettingsShiftItemComponent implements OnInit, OnChanges {

  @Input()
  public shift: ShiftModel;

  @Input()
  public isDisabled: boolean = false;

  @Output()
  public onChangeName = new EventEmitter<ChangeNameEventParams>();

  @Output()
  public onSlideStartTime = new EventEmitter<SlideStartTimeEventParams>();

  @Output()
  // tslint:disable-next-line:no-any
  public onRemove = new EventEmitter<any>();

  public SlideStartTimeDirection = SlideStartTimeDirection

  public DateTimeFormatEnum = DateTimeFormatEnum;

  constructor() { }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.shift) {
      this.shift = _.cloneDeep(this.shift);
    }
  }

  public get allowRemove(): boolean {
    return !this.shift.isCurrent;
  }

  public raiseRemoveShift(): void {
    this.onRemove.emit();
  }

  public raiseChangeName(name: string): void {
    this.shift.name = name;
    this.onChangeName.emit({
      name
    });
  }

  public raiseSlideStartTime(direction: SlideStartTimeDirection): void {
    this.onSlideStartTime.emit({
      direction
    });
  }
}
