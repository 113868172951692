import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IReportMetaFilterValueColumn} from '../../../../../../services/reports/model/report.interface';
import {FilterSearchQuery} from '../../../../../../services/reports/model/report-query.interface';

@Component({
  selector: 'filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss']
})
export class FilterSearchComponent implements OnInit, OnChanges {

  @Input()
  public reportMetaFilterValueColumn : IReportMetaFilterValueColumn;

  @Input()
  public filterSearchQuery : FilterSearchQuery;

  public searchValues: string[];

  constructor() { }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(this.filterSearchQuery == null){
      this.searchValues = [];
    } else {
      this.searchValues = this.reportMetaFilterValueColumn.values.map(value => value.value);
    }
  }

  public foundItemChanged(value:string){
    this.filterSearchQuery.equalsValue = value;
  }
}
