export enum DistressSensitivityValueType {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High'
}

export interface IHealthSettings {
  healthIndexThreshold: IHealthSettingDefaultFields;
  severeHealthIndexThreshold: IHealthSettingDefaultFields;
  inclusionInEarlyFreshCowsReport: IHealthSettingDefaultFields;
  distressSensitivity: IHealthSettingDistressSensitivityFields;
  daysToWeaning?: IHealthSettingDefaultFields
}

export interface IHealthSettingDefaultFields {
  value: number;
  defaultValue: number;
  min: number;
  max: number;
}

export interface IHealthSettingDistressSensitivityFields {
  value: DistressSensitivityValueType;
  defaultValue: DistressSensitivityValueType;
  min: null;
  max: null;
}
