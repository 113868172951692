import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {PollingKeyNames, PollingService} from '../rest-api/polling.service';
import {HttpResponseResult, HttpResponseResultNoBody, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from '../auth/auth.service';
import {
  ITagsStartUpdateRequest,
  ITagsToUpdateVersions,
  ITagsUpdateProgressStatus,
  ITagsUpdateSummary
} from './model/tags-provisioning.model';
import {Observable, Subject, Subscription} from 'rxjs';
import {filter, startWith, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TagsProvisioningService implements OnInit, OnDestroy {

  private static readonly TagsUpdateRoute = '/rest/api/provisioning/tags/sw';

  private static readonly TagsUpdateJobRoute = '/rest/api/provisioning/tags/sw/{jobId}';

  private static readonly TagsUpdateJobSummaryRoute = '/rest/api/provisioning/tags/sw/{jobId}/summary';

  private static readonly ESenseV2LEDsRoute = '/rest/api/system/ledoperation/toggle';

  public readonly tagsUpdateStatus: Subject<boolean> = new Subject<boolean>();

  public readonly openTagSwUpdate: Subject<void> = new Subject<void>();

  public readonly tagsUpdateProgressStatus: Subject<ITagsUpdateProgressStatus> = new Subject();

  public readonly tagsUpdateCompleted: Subject<ITagsUpdateSummary> = new Subject();

  // tslint:disable-next-line:no-any
  public readonly tagsSwUpdateError: Subject<any> = new Subject();

  public tagProvisioningSubscription: Subscription;

  constructor(private pollingService: PollingService,
              private restApiService: RestApiService,
              private authService: AuthService) {

    this.tagProvisioningSubscription = this.pollingService.pollingChangesState.subscribe(async changes => {
        if (changes.includes(PollingKeyNames.tagsSwUpdate)) {
            let tagsUpdateStatusResponse = await this.getTagsUpdateStatus(1);
            this.tagsUpdateProgressStatus.next(tagsUpdateStatusResponse);
        }
        if(changes.includes(PollingKeyNames.tagsSwUpdateCompleted)) {
            let tagsUpdateLastSummary = await this.getTagsUpdateLastSummary(1);
            this.tagsUpdateCompleted.next(tagsUpdateLastSummary);
        }
        if(changes.includes(PollingKeyNames.tagsSwUpdateError)) {
            let tagsUpdateStatusResponse = await this.getTagsUpdateStatus(1);
            this.tagsSwUpdateError.next(tagsUpdateStatusResponse);
        }
    });
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.tagProvisioningSubscription.unsubscribe();
  }

  public async getTagsVersions() : Promise<ITagsToUpdateVersions> {
    let response = await this.restApiService.sendGetMethod<ITagsToUpdateVersions>(TagsProvisioningService.TagsUpdateRoute, this.authService.currentUserAuthState.authDetails);
    if(response.status == 200){
      return response.responseBody;
    }
    return null;
  }

  public async getTagsUpdateStatus(jobId:number) : Promise<ITagsUpdateProgressStatus> {
    let route = TagsProvisioningService.TagsUpdateJobRoute.replace('{jobId}', jobId.toString());
    let response = await this.restApiService.sendGetMethod<ITagsUpdateProgressStatus>(route, this.authService.currentUserAuthState.authDetails);
    if(response.status == 200){
      return response.responseBody;
    }
    return null;
  }

  public async getTagsUpdateLastSummary(jobId:number) : Promise<ITagsUpdateSummary> {
    let route = TagsProvisioningService.TagsUpdateJobSummaryRoute.replace('{jobId}', jobId.toString());
    let response = await this.restApiService.sendGetMethod<ITagsUpdateSummary>(route, this.authService.currentUserAuthState.authDetails);
    if(response.status == 200){
      return response.responseBody;
    }
    return null;
  }

  // tslint:disable-next-line:no-any
  public async startUpdate(tagsStartUpdate:ITagsStartUpdateRequest) : Promise<any> {

    // tslint:disable-next-line:no-any
    let response = await this.restApiService.sendPostMethod<any>(TagsProvisioningService.TagsUpdateRoute, this.authService.currentUserAuthState.authDetails, tagsStartUpdate);
    if(response.status == 200){
      return response.responseBody;
    }
    return null;
  }

  public async stopUpdate(jobId:number) : Promise<HttpResponseResultNoBody> {
    let route = TagsProvisioningService.TagsUpdateJobRoute.replace('{jobId}', jobId.toString());
    return  this.restApiService.sendDeleteMethod(route, this.authService.currentUserAuthState.authDetails);
  }

  public async getESenseV2TagData(): Promise<HttpResponseResult<IESenseV2TagsData>> {
    return this.restApiService.sendGetMethod(TagsProvisioningService.ESenseV2LEDsRoute, this.authService.currentUserAuthState.authDetails);
  }

  public async setESenseV2TagData(request: IESenseV2TagsData) {
    return this.restApiService.sendPutMethod(TagsProvisioningService.ESenseV2LEDsRoute, this.authService.currentUserAuthState.authDetails, request);
  }
}

export interface IESenseV2TagsData {
  isEnabled: boolean;
}
