import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ChartComponent, IAxisLabelRenderEventArgs, IMouseEventArgs, SeriesModel} from '@syncfusion/ej2-angular-charts';
import {DateTimeFormatEnum} from '../../pipes/epoch-date-time.pipe';
import {IChartEventPoint, IChartPoint} from '../../../services/model/charts/chart.interface';
import {ChartConfiguration, XyChartCalculationService} from '../../../services/charts/xy-chart-calculation.service';

export class ChartDurationSelectionLabel {
  public selectedDisplayText: string;
  public optionDisplayText: string;
}

export class EventViewModel {
  public visible: boolean;

  public marginLeft: number;

  public pointData: IChartEventPoint[] = [];

  public get multipleEvents(): boolean {
    return this.pointData.length > 1;
  }

  public get averageEpochX(): number {
    let total = 0;
    for (let i = 0; i < this.pointData.length; i++) {
      total += this.pointData[i].x;
    }
    return total / this.pointData.length;
  }
}

@Component({
  selector: 'xy-chart',
  templateUrl: './xy-chart.component.html',
  styleUrls: ['./xy-chart.component.scss']
})
export class XyChartComponent implements OnInit {

  @ViewChild('xyChart')
  public xyChart: ChartComponent;

  @ViewChild('hoverEventDetails')
  public hoverEventDetailsElement: ElementRef<HTMLElement>;

  @Input()
  public timeIsUtc: boolean;

  @Input()
  public dateTimeFormat: DateTimeFormatEnum = DateTimeFormatEnum.DateTime;

  @Output()
  public chartPointMouseOver: EventEmitter<IChartPoint> = new EventEmitter();

  @Output()
  public durationSelected: EventEmitter<ChartDurationSelectionLabel> = new EventEmitter();

  public DateTimeFormatEnum = DateTimeFormatEnum;

  public series: SeriesModel[];

  private numberOfTotalPoints: number;

  private numberOfVisiblePoints: number;

  private pointEpochStepDifference: number;

  private chartConfig: ChartConfiguration;

  private minEpoch: number;

  private maxEpoch: number;

  private chartDataPoints: IChartPoint[];

  private chartEventPoints: IChartEventPoint[];

  public eventViewModels: EventViewModel[];

  public selectedDurationLabel: ChartDurationSelectionLabel;

  private optionsDurationLabels: ChartDurationSelectionLabel[];

  public hoverEventViewModel: EventViewModel;

  private visibleRangeMinEpoch: number;

  private visibleRangeMaxEpoch: number;

  private draggingPrevMouseX: number;

  private readonly eventIconWidth: number = 30;

  private readonly hoverEventPopupWidthDefault: number = 300;

  public _hoverEventDetailsLeftMargin: number;

  public selectedChartPoint: IChartPoint;

  public selectedPointDetailsLeftMargin: number;

  public openDurationSelection: boolean;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef,
              private xyChartCalculationService: XyChartCalculationService) {
  }

  public ngOnInit() {
  }

  public get eventsMarginTop(): number {
    if (this.xyChart && this.xyChart.initialClipRect) {
      return this.xyChart.initialClipRect.y + this.xyChart.initialClipRect.height - 40;
    } else {
      return 0;
    }
  }

  public initChart(series: SeriesModel[],
                   chartDataPoints: IChartPoint[],
                   chartConfig: ChartConfiguration,
                   clearSeries: boolean,
                   selectedDurationLabel: ChartDurationSelectionLabel,
                   optionsDurationLabels: ChartDurationSelectionLabel[],
                   eventDataPoints?: IChartEventPoint[]) {

    this.series = series;
    this.chartDataPoints = chartDataPoints;
    this.chartEventPoints = eventDataPoints;
    if (this.chartEventPoints == null) {
      this.chartEventPoints = [];
    }
    this.chartEventPoints = this.chartEventPoints.sort((a, b) => a.x - b.x);
    this.numberOfTotalPoints = chartDataPoints.length;
    this.chartConfig = chartConfig;
    this.selectedDurationLabel = selectedDurationLabel;
    this.optionsDurationLabels = optionsDurationLabels;
    this.minEpoch = chartDataPoints[0].x;
    this.maxEpoch = chartDataPoints[chartDataPoints.length - 1].x;
    this.pointEpochStepDifference = (chartDataPoints[1].x - chartDataPoints[0].x);
    this.numberOfVisiblePoints = (this.chartConfig.visiblePeriodSeconds) / this.pointEpochStepDifference;
    if (this.visibleRangeMaxEpoch == null) {
      this.visibleRangeMinEpoch = this.maxEpoch - this.chartConfig.visiblePeriodSeconds;
      this.visibleRangeMaxEpoch = this.maxEpoch;
    } else {
      this.visibleRangeMinEpoch = this.visibleRangeMaxEpoch - this.chartConfig.visiblePeriodSeconds;
    }
    this.validateVisibleRange();

    setTimeout(() => {
      if(this.xyChart == null ||
         this.xyChart.primaryXAxis == null) {
        return;
      }
      this.xyChart.primaryXAxis.isInversed = false;
      this.xyChart.primaryXAxis.minimum = this.minEpoch;
      this.xyChart.primaryXAxis.maximum = this.maxEpoch + (this.chartConfig.primaryXAxisExtendSeconds ?? 0);
      this.xyChart.primaryXAxis.edgeLabelPlacement =  this.chartConfig.edgeLabelPlacement;
      this.xyChart.primaryXAxis.valueType = 'Double';
      this.xyChart.primaryXAxis.interval = this.xyChartCalculationService.convertGridLineIntervalToEstimatedTime(this.chartConfig.gridLineInterval);
      this.xyChart.primaryXAxis.zoomFactor = this.numberOfVisiblePoints / chartDataPoints.length;
      this.xyChart.primaryXAxis.zoomPosition = this.calculateZoomPosition();
      this.xyChart.primaryXAxis.enableAutoIntervalOnZooming = false;

      this.xyChart.primaryYAxis.maximum = this.chartConfig.primaryYAxisMaxValue;
      this.xyChart.primaryYAxis.minimum = 0;

      this.xyChart.primaryYAxis.labelStyle = {
        fontFamily: 'OpenSans-Light',
        size: '12px',
        color: '#59595c'
      };

      this.xyChart.primaryXAxis.labelStyle = {
        fontFamily: 'OpenSans-Light',
        size: '12px',
        color: '#59595c'
      };



      this.xyChart.crosshair = {
        enable: true,
        lineType: 'Vertical',
        line: {
          width: 2,
          color: '#a8aaad'
        }
      };

      this.xyChart.axes = [{
        name: 'secYAxis',
        opposedPosition: true,
        maximum: this.chartConfig.secondaryYAxisMaxValue,
        majorGridLines: {width: 0},
        majorTickLines: {width: 0},
      }, {
        name: 'thirdYAxis',
        minimum: 0,
        maximum: 100,
        visible: false
      }];
      if(clearSeries) {
        this.xyChart.clearSeries();
      }
      this.xyChart.addSeries(series);
      setTimeout(() => {
        this.updateEventViewModels(true);
      });
    });
  }

  public toggleDurationsVisibility(): void {
    this.openDurationSelection = !this.openDurationSelection;
  }

  public resetChart() {
    this.series = null;
    this.eventViewModels = null;
  }

  public onDurationSelected(duration: ChartDurationSelectionLabel) {
    this.openDurationSelection = false;
    this.resetChart();
    setTimeout(() => {
      this.durationSelected.emit(duration);
    });
  }

  public onChartMouseLeave() {
    this.draggingPrevMouseX = null;
    this.selectedChartPoint = null;
    this.clearHoverEventModel();
    this.chartPointMouseOver.emit(null);
  }

  public onChartMouseMove(event: IMouseEventArgs) {
    if (this.draggingPrevMouseX != null) {
      this.performMouseDrag(event);
    } else {
      this.performMouseMove(event);
    }
  }

  public onChartMouseDown(event: IMouseEventArgs) {
    this.draggingPrevMouseX = event.x;
  }

  public onChartMouseUp() {
    this.draggingPrevMouseX = null;
  }

  public onScroll(event: WheelEvent) {
    if (!this.series) {
      return;
    }
    const deltaRatio = event.deltaY / 50;
    const scrollTimeSeconds = deltaRatio * (this.chartConfig.scrollStepHours * 3600);
    this.updateVisibleRangeByTime(scrollTimeSeconds);
  }

  private performMouseDrag(event: IMouseEventArgs) {

    let moveDeltaPixels = this.draggingPrevMouseX - event.x;
    let moveDeltaHours = (this.chartConfig.visiblePeriodSeconds) * (moveDeltaPixels / this.xyChart.initialClipRect.width);
    this.updateVisibleRangeByTime(moveDeltaHours);
    this.draggingPrevMouseX = event.x;
    this.calculateSelectedPointDetailsLeftMargin(event.x);
  }

  private performMouseMove(event: IMouseEventArgs) {
    let chartClipRect = this.xyChart.initialClipRect;
    if (event.x > chartClipRect.x + chartClipRect.width ||
      event.x < chartClipRect.x ||
      event.y > chartClipRect.y + chartClipRect.height ||
      event.y < chartClipRect.y) {
      this.selectedChartPoint = null;
      this.chartPointMouseOver.emit(null);
      return;
    }
    const chartXposition = event.x - this.xyChart.initialClipRect.x;
    let charDataPointIndex = (chartXposition / this.xyChart.initialClipRect.width) * this.numberOfVisiblePoints;
    charDataPointIndex += (this.visibleRangeMinEpoch - this.minEpoch + (this.chartConfig.primaryXAxisExtendSeconds ?? 0) / 2) / this.pointEpochStepDifference;
    charDataPointIndex = Math.floor(charDataPointIndex);
    if (charDataPointIndex < 0 || charDataPointIndex >= this.chartDataPoints.length) {
      return;
    }
    this.calculateSelectedPointDetailsLeftMargin(event.x);
    this.selectedChartPoint = this.chartDataPoints[charDataPointIndex];

    if (this.selectedChartPoint &&
      this.eventViewModels &&
      event.y < chartClipRect.y + chartClipRect.height &&
      event.y > chartClipRect.y + chartClipRect.height - 50) {

      this.hoverEventViewModel = this.eventViewModels.find(value => value.visible &&
        event.x >= value.marginLeft &&
        event.x < value.marginLeft + this.eventIconWidth);
      if (this.hoverEventViewModel == null) {
        this._hoverEventDetailsLeftMargin = null;
      }
    } else {
      this.clearHoverEventModel();
    }
    this.chartPointMouseOver.emit(this.selectedChartPoint);
  }

  private updateVisibleRangeByTime(updateTimeSeconds: number) {
    this.visibleRangeMaxEpoch += updateTimeSeconds;
    this.visibleRangeMinEpoch += updateTimeSeconds;
    this.validateVisibleRange();

    this.xyChart.primaryXAxis.zoomPosition = this.calculateZoomPosition();
    this.updateEventViewModels(false);
    this.xyChart.refresh();
  }

  private validateVisibleRange() {
    if (this.visibleRangeMaxEpoch > this.maxEpoch) {
      this.visibleRangeMinEpoch = this.maxEpoch - this.chartConfig.visiblePeriodSeconds;
      this.visibleRangeMaxEpoch = this.maxEpoch;
    } else if (this.visibleRangeMinEpoch <= this.minEpoch) {
      this.visibleRangeMinEpoch = this.minEpoch;
      this.visibleRangeMaxEpoch = this.minEpoch + this.chartConfig.visiblePeriodSeconds;
    }
  }

  public axisLabelRender(args: IAxisLabelRenderEventArgs): void {
    if (args.axis.name == 'primaryXAxis') {
      this.xyChartCalculationService.renderPrimaryXAxis(args, this.chartConfig.gridLineInterval, this.timeIsUtc);
    }
  }

  public calculateSelectedPointDetailsLeftMargin(x: number) {
    this.selectedPointDetailsLeftMargin = x + 5;
    if (this.selectedPointDetailsLeftMargin < this.xyChart.initialClipRect.x + 5) {
      this.selectedPointDetailsLeftMargin = this.xyChart.initialClipRect.x + 5;
    }
    if ((this.selectedPointDetailsLeftMargin + 150 > this.xyChart.initialClipRect.x + this.xyChart.initialClipRect.width)) {
      this.selectedPointDetailsLeftMargin = this.selectedPointDetailsLeftMargin - 130;
    }
  }

  public onChartResized() {
    this.clearHoverEventModel();
    setTimeout(() => {
      this.updateEventViewModels(true);
    });
  }

  private clearHoverEventModel() {
    this.hoverEventViewModel = null;
    this._hoverEventDetailsLeftMargin = null;
  }

  public get hoverEventDetailsLeftMargin(): number {
    const hoverEventDetailsLeftMargin: number = this.calculateHoverEventLeftMargin();
    if (this._hoverEventDetailsLeftMargin !== hoverEventDetailsLeftMargin) {
      setTimeout(() => {
        this._hoverEventDetailsLeftMargin = hoverEventDetailsLeftMargin;
        this.changeDetectorRef.detectChanges();
      });
    }
    return this._hoverEventDetailsLeftMargin;
  }

  private calculateHoverEventLeftMargin(): number {
    if (!!this.hoverEventViewModel) {
      let hoverGap: number = 10;
      let hoverEventPopupWidth: number = Math.floor(this.hoverEventDetailsElement?.nativeElement.offsetWidth ?? this.hoverEventPopupWidthDefault);
      let eventIconLeftMargin: number = Math.floor(this.hoverEventViewModel.marginLeft);
      let chartRightLimit: number = this.xyChart.initialClipRect.x + this.xyChart.initialClipRect.width;
      let rightPlacedHoverMargin: number = eventIconLeftMargin + this.eventIconWidth + hoverGap;
      let leftPlacedHoverMargin: number = eventIconLeftMargin - hoverEventPopupWidth - hoverGap;
      let detailsLeftMargin: number;
      if (rightPlacedHoverMargin + hoverEventPopupWidth > chartRightLimit) {
        detailsLeftMargin = leftPlacedHoverMargin >= 0 ? leftPlacedHoverMargin : 0;
      } else {
        detailsLeftMargin = rightPlacedHoverMargin;
      }
      return detailsLeftMargin;
    } else {
      return null;
    }
  }

  private calculateZoomPosition(): number {
    return (this.visibleRangeMinEpoch - this.minEpoch) / (this.maxEpoch - this.minEpoch);
  }

  private updateEventViewModels(recalculateGroupings: boolean) {
    if (recalculateGroupings) {
      this.eventViewModels = [];
      let epochGroupingTime = this.eventIconWidth * this.pointEpochStepDifference * (this.numberOfVisiblePoints / this.xyChart.initialClipRect.width);
      for (let chartEventPoint of this.chartEventPoints) {
        if (this.eventViewModels.length > 0 &&
          Math.abs(chartEventPoint.x - this.eventViewModels[this.eventViewModels.length - 1].averageEpochX) < epochGroupingTime) {
          this.eventViewModels[this.eventViewModels.length - 1].pointData.push(chartEventPoint);
          this.eventViewModels[this.eventViewModels.length - 1].pointData.sort((p1, p2) => this.getEventPriority(p1.name) - this.getEventPriority(p2.name));
        } else {
          let eventViewModel = new EventViewModel();
          eventViewModel.pointData.push(chartEventPoint);
          this.eventViewModels.push(eventViewModel);
        }
      }
    }

    for (let eventViewModel of this.eventViewModels) {
      let eventEpochX = eventViewModel.averageEpochX;
      if (this.visibleRangeMinEpoch > eventEpochX ||
        this.visibleRangeMaxEpoch < eventEpochX) {
        eventViewModel.visible = false;
      } else {
        eventViewModel.visible = true;
        eventViewModel.marginLeft = this.xyChart.initialClipRect.x;
        eventViewModel.marginLeft += this.xyChart.initialClipRect.width * ((eventEpochX - this.visibleRangeMinEpoch) / (this.numberOfVisiblePoints * this.pointEpochStepDifference));
        eventViewModel.marginLeft -= this.eventIconWidth / 2;
      }
    }
  }

  private getEventPriority(eventName: string): number {
    switch (eventName) {
      case 'calvingEvent':
        return 1;
      case 'abortionEvent':
        return 2;
      case 'inseminationEvent':
        return 3;
      case 'dryOffEvent':
        return 4;
      case 'tagReplacementEvent':
        return 5;
      case 'pregnancyCheckEvent':
        return 6;
      case 'distressEvent':
        return 7;
      case 'systemHeatEvent':
        return 8;
      case 'systemHealthInEvent':
      case 'systemHealthOutEvent':
      case 'systemHealthEvent':
        return 9;
      case 'changeGroupEvent':
        return 10;
      case 'tagSwUpdateEvent':
        return 11;
      case 'generalEvent':
        return 12;
      default:
        return 0;
    }
  }
}
