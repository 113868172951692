export enum TagType {
  scr = 'scr',
  rfId = 'rfId'
}

export interface ITag {
  id?: number;
  tagType: TagType;
  tagNumber: string;
}

export class TagsAssignment {
  public assignedTags: ITag[];
  public unassignedTags: ITag[];
}

export class TagsAssignmentResponse {
  public rfidTags:TagsAssignment;
  public scrTags:TagsAssignment;
}
