import {Component, Input, OnInit} from '@angular/core';
import {IDashboardWidget} from '../../../../../../services/dashboard/model/dashboard-widgets.interface';
import {FarmStatisticsWidgetName} from '../../../../../../services/dashboard/model/farm-statistics-finishing';

@Component({
  selector: 'accordion-graph-widget-header',
  templateUrl: './accordion-graph-widget-header.component.html',
  styleUrls: ['./accordion-graph-widget-header.component.scss']
})
export class AccordionGraphWidgetHeaderComponent implements OnInit {

  @Input()
  public dashboardWidget: IDashboardWidget;

  public FarmStatisticsWidgetName = FarmStatisticsWidgetName;

  constructor() { }

  public ngOnInit() {
  }

}
