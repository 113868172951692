<form *ngIf="forgotPasswordStep === 'ValidateUser'" class="validate-user-form" name="validateUserForm" ngNativeValidate autocomplete="off" (ngSubmit)="onValidateUserSubmit()" #ValidateUserForm="ngForm">
  <div class="form-field-label">
    <div class="menu-header">
      <div class="brand">
        <span translation-id="FORGOT.FORM.VALIDATE_USER">{{ 'FORGOT.FORM.VALIDATE_USER' | translate }}&nbsp;</span>
      </div>
    </div>
  </div>
  <div class="form-field-title">
    <label translation-id="LOGIN.TITLE.USER">{{'LOGIN.TITLE.USER' | translate}}</label>
  </div>
  <div class="form-field">
    <input type="text"
           name="userName"
           [(ngModel)]="validateUsernameStepDetails.userName"
           (keyup)="validateUserName()"
           (keydown)="validateUserName()"
           #userName="ngModel"
           spellcheck="false"
           required
           autofocus
           placeholder='{{"LOGIN.TITLE.USER" | translate}}'
           [attr.sh-id]="'input_username'"/>
  </div>
  <div class="form-field-title" *ngIf="errorMessage"></div>
  <div class="form-field form-field-error" *ngIf="errorMessage">
    <label [attr.sh-id]="'login_error_' + errorMessage" [attr.translation-id]="'FORGOT.ERROR.' + errorMessage">{{'FORGOT.ERROR.' + errorMessage | translate}}</label>
  </div>
  <div class="form-field form-field-btn">
    <input type="button" (click)="navigateToLogin()" value="{{'LOGIN.TITLE.LOGIN' | translate}}" translation-id="LOGIN.TITLE.LOGIN" sh-id="button_back_to_login">
    <input class="submit-btn"
           type="submit"
           [disabled]="!userNameExist"
           value="{{'REGISTRATION.FLOW.VALIDATE' | translate}}" translation-id="REGISTRATION.FLOW.VALIDATE" sh-id="button_validate"/>
  </div>
</form>
<form *ngIf="forgotPasswordStep === 'SecurityQuestions'"
      class="security-questions-form"
      name="securityQuestionsForm"
      ngNativeValidate autocomplete="off"
      (ngSubmit)="onSecurityQuestionSubmit()" #SecurityQuestionsForm="ngForm">
  <div class="form-field-label">
    <div class="menu-header">
      <div class="brand">
        <span translation-id="FORGOT.FORM.FORGOT_PASSWORD">{{ 'FORGOT.FORM.FORGOT_PASSWORD' | translate }}&nbsp;</span>
      </div>
    </div>
  </div>
  <div class="form-field-title">
    <label translation-id="LOGIN.TITLE.USER">{{'LOGIN.TITLE.USER' | translate}}</label>
  </div>
  <div class="form-field">
    <input type="text" name="userName" [ngModel]="validateUsernameStepDetails.userName" #userName="ngModel" readonly/>
  </div>
  <div class="form-field-title">
    <label [attr.translation-id]="securityQuestion1">{{ securityQuestion1 | translate}}</label>
  </div>
  <div class="form-field">
    <input type="text"
           name="answer1"
           (keyup)="resetErrorMessage()"
           (keydown)="resetErrorMessage()"
           [(ngModel)]="userSecurityQuestions.question1.value"
           input-focus
           #answer1="ngModel"
           spellcheck="false"
           required
           placeholder='{{"REGISTRATION.FLOW.ANSWER" | translate}}'
           sh-id="input_answer1"/>
  </div>
  <div class="form-field-title">
    <label [attr.translation-id]="securityQuestion2">{{ securityQuestion2 | translate}}</label>
  </div>
  <div class="form-field">
    <input type="text"
           name="answer2"
           (keyup)="resetErrorMessage()"
           (keydown)="resetErrorMessage()"
           [(ngModel)]="userSecurityQuestions.question2.value"
           #answer2="ngModel"
           spellcheck="false"
           required
           placeholder='{{"REGISTRATION.FLOW.ANSWER" | translate}}'
           sh-id="input_answer2"/>
  </div>
  <div class="form-field-title" *ngIf="errorMessage"></div>
  <div class="form-field form-field-error" *ngIf="errorMessage">
    <label [attr.sh-id]="'login_error_' + errorMessage" [attr.translation-id]="'FORGOT.ERROR.' + errorMessage">{{'FORGOT.ERROR.' + errorMessage | translate}}</label>
  </div>
  <div class="form-field form-field-btn">
    <input type="button" (click)="navigateToLogin()" value="{{'LOGIN.TITLE.LOGIN' | translate}}" translation-id="LOGIN.TITLE.LOGIN" sh-id="button_back_to_login">
    <input class="submit-btn" type="submit" [disabled]="!securityQuestionsExist" value="{{'REGISTRATION.FLOW.VALIDATE' | translate}}" translation-id="REGISTRATION.FLOW.VALIDATE" sh-id="button_validate">
  </div>
</form>
<form *ngIf="forgotPasswordStep === 'ResetPassword'"
      class="reset-password-form"
      name="resetPasswordForm"
      ngNativeValidate autocomplete="off"
      (ngSubmit)="onResetPasswordSubmit()"
      #ResetPasswordForm="ngForm">
  <div class="form-field-label">
    <div class="menu-header">
      <div class="brand">
        <span translation-id="FORGOT.FORM.RESET_PASSWORD">{{ 'FORGOT.FORM.RESET_PASSWORD' | translate }}&nbsp;</span>
      </div>
    </div>
  </div>
  <div class="form-field-title" *ngIf="errorMessage"></div>
  <div class="form-field form-field-error" *ngIf="errorMessage">
    <label [attr.sh-id]="'login_error_' + errorMessage" [attr.translation-id]="'FORGOT.ERROR.' + errorMessage">{{'FORGOT.ERROR.' + errorMessage | translate}}</label>
  </div>
  <div class="form-field-title">
    <label translation-id="FORGOT.FORM.NEW_PASSWORD">{{'FORGOT.FORM.NEW_PASSWORD' | translate}}</label>
  </div>
  <div class="form-field">
    <input type="password" name="newPassword"
           [(ngModel)]="userResetPasswordModel.newPassword"
           (blur)="onNewPasswordBlur()"
           (keyup)="onNewPasswordValueChanged()"
           (keydown)="onNewPasswordValueChanged()"
           input-focus
           #newPassword="ngModel"
           spellcheck="false"
           required
           placeholder='{{"MANAGE.SETTINGS.USERS.USER_EDIT.PASSWORD_PLACEHOLDER" | translate}}'
           [attr.sh-id]="'input_password'"/>
  </div>
  <password-validate *ngIf="openPasswordValidationPopup" [passwordValidationResult]="passwordValidationResult"></password-validate>
  <div class="form-field-title" *ngIf="passwordValidationResult.isValid">
    <label translation-id="FORGOT.FORM.CONFIRM_PASSWORD">{{'FORGOT.FORM.CONFIRM_PASSWORD' | translate}}</label>
  </div>
  <div class="form-field" *ngIf="passwordValidationResult.isValid">
    <input type="password"
           [ngClass]="{'invalid': !newPasswordConfirmed, 'valid': newPasswordConfirmed}"
           name="confirmPassword"
           [(ngModel)]="userResetPasswordModel.confirmPassword"
           #confirmPassword="ngModel"
           spellcheck="false"
           required
           placeholder='{{"MANAGE.SETTINGS.USERS.USER_EDIT.PASSWORD_PLACEHOLDER" | translate}}'
           sh-id="input_confirm_password"/>
  </div>
  <div class="form-field form-field-btn">
    <input type="button" (click)="navigateToLogin()" value="{{'LOGIN.TITLE.LOGIN' | translate}}" translation-id="LOGIN.TITLE.LOGIN" sh-id="button_back_to_login">
    <input class="submit-btn" type="submit" [disabled]="!newPasswordConfirmed" value="{{'FORGOT.FORM.RESET_PASSWORD' | translate}}" translation-id="FORGOT.FORM.RESET_PASSWORD" sh-id="button_reset_password">
  </div>
</form>
<div class="loader" *ngIf="isLoading"></div>
