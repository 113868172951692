import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {WINDOW} from '../../../../utils/window-utils';
import {CommonUiService} from '../../../../services/ui/common-ui.service';
import {Subscription} from 'rxjs';

export enum AlignPopupType {
  start = 'start',
  end = 'end',
}

@Component({
  selector: 'popup-template',
  templateUrl: './popup-template.component.html',
  styleUrls: ['./popup-template.component.scss']
})
export class PopupTemplateComponent implements OnInit, OnDestroy{

  @ViewChild('header', {static: true})
  private header: ElementRef<HTMLElement>;

  @ViewChild('popupContent')
  private popup: ElementRef<HTMLElement>;

  @Input()
  public isOpen: boolean = false;

  @Input()
  public popupContentHorizontalAlignment: AlignPopupType = AlignPopupType.start;

  @Input()
  public adjustPopupContentWidth: boolean = true;

  @Output()
  public close: EventEmitter<void> = new EventEmitter<void>();

  private _intersectionObserver : IntersectionObserver;

  private _top : number;

  private _isHidden : boolean;

  private scrollSubscription : Subscription;

  constructor(@Inject(WINDOW) private readonly _window: Window,
              private readonly changeDetector: ChangeDetectorRef,
              private readonly commonUiService: CommonUiService) {

  }

  public ngOnInit(): void {
    this.scrollSubscription = this.commonUiService.scrollSubject.subscribe(evt => this.onScroll(evt));
    this._intersectionObserver = new IntersectionObserver(entries => {
      let newIsHidden = !entries[entries.length - 1].isIntersecting;
      if(this._isHidden != newIsHidden) {
        this._isHidden = newIsHidden;
        this.changeDetector.detectChanges();
      }
    }, { threshold: [0] });
    this._intersectionObserver.observe(this.header.nativeElement);
  }

  public ngOnDestroy(): void {
    this.scrollSubscription.unsubscribe();
    this._intersectionObserver.unobserve(this.header.nativeElement);
    this._intersectionObserver.disconnect();
  }

  private onScroll(event) {
    this.updateTop();
  }

  public get popupContentStyles(): object {
    return  {
      'top': this.top + 'px',
      'width': this.popupWidth ? this.popupWidth + 'px' : 'auto',
      'display': this.isHidden == null ? 'none' : 'block'
    };
  }

  public get top(): number {
    this.updateTop();
    return this._top;
  }

  public get isHidden(): boolean {
    return this._isHidden;
  }

  public onOutsideClick() {
    this.close.emit();
  }

  private updateTop() {
    let newTop : number;
    if(this.popup == null) {
      newTop = null;
    } else {
      if (this._window.innerHeight < this.header.nativeElement.getBoundingClientRect().bottom + this.popup.nativeElement.getBoundingClientRect().height) {
        newTop = this.header.nativeElement.getBoundingClientRect().top - this.popup.nativeElement.getBoundingClientRect().height;
      } else {
        newTop = this.header.nativeElement.getBoundingClientRect().bottom;
      }
    }
    if(newTop != this._top) {
      setTimeout(() => {
        this._top = newTop;
        this.changeDetector.detectChanges();
      });
    }
  }

  public get popupWidth(): number {
    if (this.adjustPopupContentWidth) {
      return this.header.nativeElement.getBoundingClientRect().width;
    }
  }

  public get isFoldsUp(): boolean {
    return this._top < this.header.nativeElement.getBoundingClientRect().top;
  }
}
