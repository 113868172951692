import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {LedTasksService} from '../led-tasks.service';
import {UserPermissionsService} from "../../auth/user-permissions.service";

@Injectable({
  providedIn: 'root'
})
export class LedTasksGuard implements CanActivate {

  constructor(private readonly ledTasksService: LedTasksService, private readonly router: Router) {}

  public async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const hasTasks: boolean = await this.ledTasksService.hasLedTasks();
    if (hasTasks) {
      return true;
    } else {
      await this.router.navigate(['/led-tasks/no-tasks']);
      return false;
    }
  }

}
