<branch-card-animals-summary *ngIf="branchGraphDetails" [branchGraphDetails]="branchGraphDetails"></branch-card-animals-summary>
<div class="heat-chart-header" [attr.sh-id]="'branch_card_heat_stress'">
  <xy-chart-legend #branchHeatStressChartLegend
    [configuration]="legendConfiguration"
    [legendStates]="legendStates">
  </xy-chart-legend>
  <chart-series-dropdown-list
    [seriesSelections]="seriesSelectionStates"
    [translationPrefix]="legendConfiguration.translationPrefix"
    (selectionApplied)="onSelectionApplied($event)"
    (restoreToDefaults)="onRestoreToDefaults()">
  </chart-series-dropdown-list>
</div>
<xy-chart #branchHeatStressChart
  [timeIsUtc]="true"
  (chartPointMouseOver)="onChartPointMouseOver($event)"
  (durationSelected)="onDurationSelected($event)"
  [attr.sh-id]="'branch_card_heat_stress_chart'">
</xy-chart>

