<div class="group-card-details-header" *ngIf="groupDetails">
  <span>
    <span [attr.translation-id]="'MANAGE.GROUPS.HEADER.GROUP_NUMBER'">{{ "MANAGE.GROUPS.HEADER.GROUP_NUMBER" | translate }}</span>
    <span class="value">{{ groupDetails.groupNumber }}</span>
    <span [attr.translation-id]="'MANAGE.GROUPS.HEADER.BRANCH'">{{ "MANAGE.GROUPS.HEADER.BRANCH" | translate }}</span>
    <span class="value" [attr.translation-id]="'REPORTS.GRID.STATUS.' + groupDetails.branchName">{{ "REPORTS.GRID.STATUS." + groupDetails.branchName | translate }}</span>
    <span [attr.translation-id]="'MANAGE.GROUPS.HEADER.HEAT_INDEX_THRESHOLD'">{{ "MANAGE.GROUPS.HEADER.HEAT_INDEX_THRESHOLD" | translate }}</span>
    <span class="value">{{ groupDetails.heatIndexThreshold }}</span>
  </span>
  <grid-actions-dropdown [dataSource]="this"></grid-actions-dropdown>
</div>

<ng-template #groupAnimalsColumn  let-column="column">
  <ng-container [ngSwitch]="column">
    <div *ngSwitchCase="'animalName'" class="header-cell">
      <b [attr.translation-id]="'GROUP.TABLE.HEADER.COW'">{{'GROUP.TABLE.HEADER.COW' | translate}}</b>
      <span [attr.translation-id]="'GROUP.TABLE.HEADER.COW_TOTAL'">
        {{'GROUP.TABLE.HEADER.COW_TOTAL' | translate}}:<div class="value">{{groupAnimals.numberOfAnimals}}</div>
      </span>
    </div>
    <div *ngSwitchCase="'lactationStatus'" class="header-cell">
      <b [attr.translation-id]="'GROUP.TABLE.HEADER.STATUS'">{{'GROUP.TABLE.HEADER.STATUS' | translate}}</b>
      <span [attr.translation-id]="'GROUP.TABLE.HEADER.STATUS_PREGNANT'">
        {{'GROUP.TABLE.HEADER.STATUS_PREGNANT' | translate}}:<div class="value">{{groupAnimals.percentagePregnant}}%</div>
      </span>
    </div>
    <div *ngSwitchCase="'lactationNumber'" class="header-cell">
      <b [attr.translation-id]="'GROUP.TABLE.HEADER.LACTATION'">{{'GROUP.TABLE.HEADER.LACTATION' | translate}}</b>
      <span [attr.translation-id]="'GROUP.TABLE.HEADER.LACTATION_AVERAGE'">
        {{'GROUP.TABLE.HEADER.LACTATION_AVERAGE' | translate}}:<div class="value">{{groupAnimals.averageLactationNumber}}</div>
      </span>
    </div>
    <div *ngSwitchCase="'dimAge'" class="header-cell">
      <b [attr.translation-id]="'GROUP.TABLE.HEADER.DIM_AGE'">{{'GROUP.TABLE.HEADER.DIM_AGE' | translate}}</b>
      <sizable-cell>
        <span [attr.translation-id]="'GROUP.TABLE.HEADER.' + groupAnimals.averageDIMAgeCalculationName">
          {{'GROUP.TABLE.HEADER.' + groupAnimals.averageDIMAgeCalculationName | translate}}:<div class="value">{{groupAnimals.averageDIMAge}}</div>
        </span>
      </sizable-cell>
    </div>
    <div *ngSwitchCase="'daysSinceLastHeat'" class="header-cell">
      <b [attr.translation-id]="'GROUP.TABLE.HEADER.DAYS_SINCE'">{{'GROUP.TABLE.HEADER.DAYS_SINCE' | translate}}</b>
      <span class="mt-10"><b [attr.translation-id]="'GROUP.TABLE.HEADER.DAYS_SINCE_LAST_HEAT'">{{'GROUP.TABLE.HEADER.DAYS_SINCE_LAST_HEAT' | translate}}</b></span>
    </div>
    <div *ngSwitchCase="'daysPregnant'" class="header-cell">
      <b [attr.translation-id]="'GROUP.TABLE.HEADER.DAYS_PREGNANT'">{{'GROUP.TABLE.HEADER.DAYS_PREGNANT' | translate}}</b>
      <span [attr.translation-id]="'GROUP.TABLE.HEADER.DAYS_PREGNANT_AVERAGE'">
        {{'GROUP.TABLE.HEADER.DAYS_PREGNANT_AVERAGE' | translate}}:<div class="value">{{groupAnimals.averageDaysPregnant}}</div>
      </span>
    </div>
    <div *ngSwitchCase="'scrTagNumber'" class="header-cell">
      <b [attr.translation-id]="'GROUP.TABLE.HEADER.SCR_TAG'">{{'GROUP.TABLE.HEADER.SCR_TAG' | translate}}</b>
      <span [attr.translation-id]="'GROUP.TABLE.HEADER.SCR_TAG_TOTAL'">
        {{'GROUP.TABLE.HEADER.SCR_TAG_TOTAL' | translate}}:<div class="value">{{groupAnimals.totalScrTag}}</div>
      </span>
    </div>
  </ng-container>
</ng-template>

<ng-template #groupAnimalsCell let-column="column" let-row="row">
  <ng-container [ngSwitch]="column">
    <div *ngSwitchCase="'lactationStatus'" (click)="onCellClick(row)" class="lactation-status">
      <animal-lifecycle-status-icon [status]="row[column]"></animal-lifecycle-status-icon>
      <span [attr.translation-id]="'ANIMAL.TIME_LINE.STATUS.' + row[column]">{{'ANIMAL.TIME_LINE.STATUS.' + row[column] | translate }}</span>
    </div>
    <div *ngSwitchCase="'actions'" class="actions-cell">
      <row-actions-popup [rowActions]="rowActionsMap.get(row)"
                         (onRowActionClick)="handleRowAction($event.rowActionPopupItem.action, row)"></row-actions-popup>
    </div>
    <div *ngSwitchCase="'scrTagNumber'" class="value-cell">
      <div class="tag-extist" *ngIf="row[column] != null">
        {{row[column]}}
      </div>
      <div class="missing-tag" *ngIf="configService.serverConfig &&
                                      configService.serverConfig.thirdPartyParameters.assignTag &&
                                      !row[column] &&
                                      row['assignedScrTagEnabled']"
           (click)="handleRowAction(GroupCardDetailsAnimalRowActionType.assignTag, row)">
        <div class="f1-icon" class="icon-assignTag"></div>
        <div class="assign-title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.ASSIGN_TAG.TITLE'">{{ 'MANAGE.COWS.ACTIVE.ASSIGN_TAG.TITLE' | translate }}</div>
      </div>
    </div>
    <div class="value-cell" *ngSwitchDefault (click)="onCellClick(row)">
      {{row[column]}}
    </div>
  </ng-container>
</ng-template>

<data-grid #groupCardAnimalsSortedGrid
           *ngIf="groupAnimals"
           [headerHeight]="80"
           [rowHeight]="60"
           [viewContext]="this"
           [sortedGridColumn]="groupAnimalsColumn"
           [sortedGridCell]="groupAnimalsCell"
           [sortedGridQuery]="animalsSortedGridQuery"
           [columnsMappings]="columnsMappings"
           [totalRows]="groupAnimals.numberOfAnimals"
           [rowsData]="groupAnimals.animals">
</data-grid>
