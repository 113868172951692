import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IReportMenuItem, IReportsMenuItems} from '../../../services/reports/model/report.interface';
import {ReportsService} from '../../../services/reports/reports.service';
import {HttpResponseResult} from '../../../services/rest-api/rest-api.service';
import {MenuItemType} from '../left-menu.component';
import {ConfigService} from '../../../services/config/config.service';
import {ApplicationBundle, FarmMode, YoungStockApplicationPlan} from '../../../services/config/model/server-config';
import {TranslationService} from '../../../services/translations/translation.service';
import {GoogleAnalyticsService} from '../../../services/google-analytics/google-analytics.service';
import {FarmRole} from '../../../services/auth/model/farm-role.enum';
import {PollingKeyNames, PollingService} from '../../../services/rest-api/polling.service';
import {Subscription} from 'rxjs';
import {animate, style, transition, trigger} from '@angular/animations';

export enum LearnMorePlanType {
  YoungStock = 'YoungStock',
  Health = 'Health',
  Group = 'Group'
}

@Component({
  selector: 'reports-menu',
  templateUrl: './reports-menu.component.html',
  styleUrls: ['./reports-menu.component.scss'],
  animations: [
    trigger('reportsMenuTransition', [
      transition(':enter', [
        style({width: '0%'}),
        animate('0.3s ease', style({width: '100%'}))
      ]),
      transition(':leave', [
        style({width: '100%'}),
        animate('0.3s ease', style({width: '0%'}))
      ])
    ])
  ]
})
export class ReportsMenuComponent implements OnInit, OnDestroy {

  @Input()
  public isOpen: boolean;

  @Output()
  public onReportsItemClick: EventEmitter<MenuItemType> = new EventEmitter();

  public favorites: IReportMenuItem[] = [];

  public categories: Map<string, IReportMenuItem[]> = new Map();

  public categoryKeys: string[];

  public premiumTrialRemainingDays = null;

  public LearnMorePlanType = LearnMorePlanType;

  public pollingSubscription: Subscription;

  constructor(public configService: ConfigService,
              private readonly reportsService: ReportsService,
              private readonly translationService: TranslationService,
              private readonly googleAnalyticsService: GoogleAnalyticsService,
              private readonly pollingService: PollingService) {

  }

  public async ngOnInit() {
    this.pollingSubscription = this.pollingService.pollingChangesState.subscribe(async pollingName => {
      if (pollingName.includes(PollingKeyNames.reportsBrowse)) {
        await this.loadReportsMenuItems();
      }
    });
    await this.loadReportsMenuItems();
  }

  public ngOnDestroy(): void {
    this.pollingSubscription.unsubscribe();
  }

  private async loadReportsMenuItems() {
    this.premiumTrialRemainingDays = this.configService.serverConfig.premiumTrialInfo && this.configService.serverConfig.premiumTrialInfo.remainingDays;
    let reportsMenuItemsResponse: HttpResponseResult<IReportsMenuItems> = await this.reportsService.getReports();
    if (reportsMenuItemsResponse.status == 200) {
      this.favorites = [];
      this.categories = new Map();
      reportsMenuItemsResponse.responseBody.reports.forEach((report) => {
        if (report.isFavorite) {
          this.favorites.push(report);
        }
        if (!this.categories.has(report.category)) {
          this.categories.set(report.category, []);
        }
        this.categories.get(report.category).push(report);
      });
      this.categoryKeys = Array.from(this.categories.keys());
    }
  }

  public isRemainingDaysShownForPremiumTrial(category: string) {
    let isShown = false;
    if (category === 'Health' &&
      this.premiumTrialRemainingDays &&
      this.premiumTrialRemainingDays > 0 &&
      this.configService.serverConfig.premiumTrialInfo.actualPlan.cows === ApplicationBundle.Starter) {
      isShown = true;
    }
    if (category === 'Group' &&
      this.premiumTrialRemainingDays &&
      this.premiumTrialRemainingDays > 0 &&
      (this.configService.serverConfig.premiumTrialInfo.actualPlan.cows === ApplicationBundle.Starter ||
       this.configService.serverConfig.premiumTrialInfo.actualPlan.cows === ApplicationBundle.Advanced)) {
      isShown = true;
    }
    return isShown;
  }

  public onReportItemClick(reportName: string) {
    this.isOpen = false;
    if (reportName) {
      this.googleAnalyticsService.send('reportMenu', 'click', reportName);
    }
    this.onReportsItemClick.emit(MenuItemType.Reports);
  }

  public async toggleFavorite(reportMenuItem: IReportMenuItem) {
    reportMenuItem.isFavorite = !reportMenuItem.isFavorite;
    let result = await this.reportsService.updateReportFavorite(reportMenuItem);
    if(result.status === 200) {
      if(reportMenuItem.isFavorite){
        this.favorites.push(reportMenuItem)
      } else {
        const index = this.favorites.indexOf(reportMenuItem);
        this.favorites.splice(index, 1);
      }
    } else {
      reportMenuItem.isFavorite = !reportMenuItem.isFavorite;
    }
  }

  public learnMore(learnMorePlanType : LearnMorePlanType){
    const xFarmId: string = this.configService.serverConfig.customerId;
    const iFarmId: string = this.configService.serverConfig.farmId;
    const xDealId: string = this.configService.serverConfig.dealerId;
    const farmName: string = (this.configService.serverConfig.farmName).replace(/\s+/g, '_');
    const firstName: string = this.configService.serverConfig.user.firstName.replace(/ /g, "_");
    const lastName: string = this.configService.serverConfig.user.lastName.replace(/ /g, "_");
    const fullName = firstName + lastName;
    const locale: string = this.configService.serverConfig.user.messagingLanguage;
    const selectedLang: string = this.translationService.selectedLanguage;

    let destinationURl: string = '';
    let url: string = '';

    if (learnMorePlanType === LearnMorePlanType.Group) {
      destinationURl = 'http://scr-sensetime.com/lp2-' +selectedLang + '/';
      url = `http://tracking.scrdairy.com/track?xfarmid=${xFarmId}&ifarmid=${iFarmId}&xdealid=${xDealId}&farmname=${farmName}&fullname=${fullName}&locale=${locale}&source=SenseTime&dest=${destinationURl}`;
    } else if (learnMorePlanType === LearnMorePlanType.Health) {
      destinationURl = 'http://scr-sensetime.com/lp1-' +selectedLang + '/';
      url = `http://tracking.scrdairy.com/track?xfarmid=${xFarmId}&ifarmid=${iFarmId}&xdealid=${xDealId}&farmname=${farmName}&fullname=${fullName}&locale=${locale}&source=SenseTime&dest=${destinationURl}`;
    } else if(learnMorePlanType === LearnMorePlanType.YoungStock) {
      destinationURl = 'http://livestockintelligence.com/sensehub/ys-' +selectedLang + '/';
      url = `http://tracking.scrdairy.com/track?xfarmid=${xFarmId}&ifarmid=${iFarmId}&xdealid=${xDealId}&farmname=${farmName}&fullname=${fullName}&locale=${locale}&source=SenseTime&dest=${destinationURl}`;
    }

    window.open(url, '_blank');
    this.onReportItemClick(null);
  }

  public get displayYoungStockTeaser() : boolean {
    return this.configService.serverConfig.farmMode === FarmMode.Dairy &&
           this.configService.serverConfig.bizModeParameters.youngStockApplicationBundle != YoungStockApplicationPlan.YoungStock;
  }

  public get displayHealthTeaser() : boolean {
    return this.configService.serverConfig.bizModeParameters.applicationBundle === ApplicationBundle.Starter;
  }

  public get displayGroupTeaser() : boolean {
    return this.configService.serverConfig.bizModeParameters.applicationBundle === ApplicationBundle.Starter ||
           this.configService.serverConfig.bizModeParameters.applicationBundle === ApplicationBundle.Advanced;
  }

  public isAdminOrFarmManager(): boolean {
    return (this.configService.serverConfig.user.farmRole === FarmRole.Admin || this.configService.serverConfig.user.farmRole === FarmRole.FarmManager);
  }

  public isStartFreeTrialButtonShown(): boolean {
    return (this.isAdminOrFarmManager() && this.configService.serverConfig.premiumTrialInfo.link !== null);
  }

  public openStartFreeTrialURL() {
    let editableURL: string = this.configService.serverConfig.premiumTrialInfo.link;
    editableURL = editableURL.replace('[DEVICE]', 'Web');
    editableURL = editableURL.replace('[ORIGIN]', 'Reports');
    editableURL = editableURL.replace('[LOCALE]', this.translationService.selectedLanguage);
    editableURL = editableURL.replace('[LANG]', this.translationService.selectedLanguage.split('-')[0]);
    window.open(editableURL, '_blank');
  }
}
