<div class="create-event-form">
  <div class="form-section-title">
    {{ 'ANIMAL.EVENTS.ADD_EVENT.EXPIRATION_DATE' | translate }}
  </div>
  <div class="form-section-item">
    <date-picker
      class="form__control"
      [calendarIconColor]="'#1999d5'"
      [shIdPostfix]="'do_not_breed_expiration_date'"
      [selectedEpoch]="expirationDate"
      (epochChanged)="onSelectedTimeChanged($event)">
    </date-picker>
  </div>
</div>
