import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {MenuItemType} from '../../left-menu.component';
import {PopupViewStateService} from '../../../../services/ui/view-state/popup-view-state.service';

@Component({
  selector: 'menu-item-manage',
  templateUrl: './menu-item-manage.component.html',
  styleUrls: ['./menu-item-manage.component.scss']
})
export class MenuItemManageComponent implements OnInit {

  @Input()
  public titleKey: string;

  @Input()
  public iconClass: string;

  @Input()
  public link: string;

  @Input()
  public isOpenMenu: boolean;

  @Output()
  public onToggleMenu: EventEmitter<MenuItemType> = new EventEmitter();

  constructor(private router: Router,
              private menuItemManageElementRef: ElementRef,
              private popupViewStateService: PopupViewStateService) { }

  public ngOnInit() {
    this.popupViewStateService.registerComponent('menuItemManage', this.menuItemManageElementRef);
  }

  public get isManageRoute() {
    return this.router.url.includes('manage/');
  }

  public onClick() {
    this.onToggleMenu.emit(MenuItemType.Manage);
  }
}
