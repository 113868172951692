<div class="report-container">
  <div class="section-field report-field">
    <div class="title-div" [ngClass]="{disabled: isEdit}" [attr.sh-id]="'led-task-discover-title'" [attr.translation-id]="'LED_TASKS.TASK_EDITOR.DISCOVER'">{{ 'LED_TASKS.TASK_EDITOR.DISCOVER' | translate }}</div>
    <div class="value-div">
      <regular-dropdown-list [colorScheme]="DropdownColorScheme.greyBlue"
                             [items]="ledTasksService.taskModel.discoversList"
                             [isFieldError]="ledTasksService.isFieldError(LedTaskFields.discover)"
                             [allowClear]="false"
                             [shIdPostfix]="'discover-dropdown'"
                             [disabled]="isEdit"
                             [selectedValue]="ledTasksService.taskModel.selectedDiscoverItem"
                             (onItemSelected)="selectDiscoverId($event)"></regular-dropdown-list>
    </div>
  </div>
  <div [ngSwitch]="ledTasksService.taskModel.selectedDiscoverItem && ledTasksService.taskModel.selectedDiscoverItem.value">
    <reports-container *ngSwitchCase="DiscoverItems.Reports" [groupsList]="groupsList" [reportsList]="reportsList" [searchEntities]="searchEntities"></reports-container>
    <animals-container *ngSwitchCase="DiscoverItems.Animals" [animalsList]="animalsList" [searchEntities]="searchEntities"></animals-container>
  </div>
</div>
