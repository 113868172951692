import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConfigService} from '../../services/config/config.service';
import {FarmMode, ServerStatusEnum} from '../../services/config/model/server-config';
import {DashboardService, IDashboardTagsResponse} from '../../services/dashboard/dashboard.service';
import {HttpResponseResult} from '../../services/rest-api/rest-api.service';
import {DashboardAccordionComponent} from './dashboard-accordion/dashboard-accordion.component';
import {DashboardKpiAccordionComponent} from './dashboard-kpi-accordion/dashboard-kpi-accordion.component';
import {DashboardSaveModel} from '../../services/dashboard/model/dashboard.interface';
import {FarmRole} from '../../services/auth/model/farm-role.enum';
import {Subscription} from 'rxjs';
import {SaveDataViewStateService} from '../../services/ui/view-state/save-data-view-state.service';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  private onApproveDataLossClickSubscription: Subscription;

  public farmMode: FarmMode;

  public farmName: string;

  public tagsSummary: IDashboardTagsResponse;

  public editMode: boolean;

  public readonly DashboardWidgetsEditArea: string = 'DashboardWidgetsEditArea';

  @ViewChild('dashboardAccordion')
  public dashboardAccordion: DashboardAccordionComponent;

  @ViewChild('dashboardKpiAccordion')
  public dashboardKpiAccordion: DashboardKpiAccordionComponent;

  constructor(public readonly configService: ConfigService,
              private readonly dashboardService: DashboardService,
              private readonly saveDataViewStateService:SaveDataViewStateService) {

  }

  public ngOnInit() {
    this.farmMode = this.configService.serverConfig.farmMode;
    this.farmName = this.configService.serverConfig.farmName;
    this.dashboardService.getTags().then((response: HttpResponseResult<IDashboardTagsResponse>) => {
      this.tagsSummary = response.responseBody;
    });
    this.onApproveDataLossClickSubscription = this.saveDataViewStateService.onApproveDataLossClick.subscribe(value => {
      if(this.editMode) {
        this.editMode = false;
        this.saveDataViewStateService.activeEditArea = null;
      }
    });
  }

  public ngOnDestroy() {
    this.onApproveDataLossClickSubscription?.unsubscribe();
  }

  public get allowUserToEditDashboard() : boolean {
    return this.configService.serverConfig != null &&
           this.configService.serverConfig.serverStatus != ServerStatusEnum.Suspend &&
           this.configService.serverConfig.user != null &&
          (this.configService.serverConfig.user.farmRole === FarmRole.Admin ||
           this.configService.serverConfig.user.farmRole === FarmRole.FarmManager ||
           this.configService.serverConfig.user.farmRole === FarmRole.SupportUser ||
           this.configService.serverConfig.user.farmRole === FarmRole.SuperUser ||
           this.configService.serverConfig.user.farmRole === FarmRole.Trial);
  }

  public get isSuspendMode() : boolean {
    return this.configService.serverConfig == null ||
           this.configService.serverConfig.serverStatus == ServerStatusEnum.Suspend;
  }

  public onEdit() {
    this.editMode = true;
    this.saveDataViewStateService.activeEditArea = this.DashboardWidgetsEditArea;
  }

  public async onSave() {
    let dashboardSaveModel : DashboardSaveModel = {
      kpis: this.dashboardKpiAccordion.getKpisForSave(),
      widgets: this.dashboardAccordion.getSelectedWidgetsForSave()
    }
    await this.dashboardService.saveDashboard(dashboardSaveModel);
    this.editMode = false;
    this.saveDataViewStateService.activeEditArea = null;
  }

  public onRevert() {
    this.editMode = false;
    this.saveDataViewStateService.activeEditArea = null;
  }
}
