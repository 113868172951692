<description-cell-details-template [hasRowDetails]="row.tag.tagType"
                                   [isRowExpanded]="isExpanded"
                                   [row]="row"
                                   [rowShId]="row.eventId"
                                   (toggleExpanded)="toggleExpanded()">
  <ng-container key>{{"ANIMAL.EVENTS.EVENT_DESCRIPTION.REMOVED_TAG"| translate}}:&nbsp;</ng-container>
  <ng-container value>{{row.previousTag.tagNumber}}</ng-container>
  <ng-container content>
    <p class="description-item" *ngIf="row.tag.tagType">
      <span>{{ 'ANIMAL.EVENTS.EVENT_DESCRIPTION.TAG_TYPE' | translate}}:&nbsp;</span>
      <span class="value">{{'ANIMAL.EVENTS.EVENT_DESCRIPTION.DEVICE_TYPE.' + row.tag.tagType | translate}}</span>
    </p>
  </ng-container>
</description-cell-details-template>
