import {Component, OnInit} from '@angular/core';
import {SetupService} from '../services/setup/setup.service';
import {Router} from '@angular/router';
import {ConfigService} from '../services/config/config.service';
import {VersionStatus} from '../services/setup/model/server-setup';

@Component({
  selector: 'setup-progress',
  templateUrl: './setup-progress.component.html',
  styleUrls: ['./setup-progress.component.scss']
})
export class SetupProgressComponent implements OnInit {

  public errorVisible: boolean;

  private lastStatus: VersionStatus = null;

  private loadingBarPercentage: number = null;

  public versionStatus = VersionStatus;

  constructor(private router: Router,
              public configService: ConfigService,
              public setupService:SetupService) {

  }

  public ngOnInit() {
    this.monitorServerSetup();
  }

  private async monitorServerSetup() {
    const serverSetup = await this.setupService.loadServerSetup();
    if (this.setupService.serverSetup) {
      this.lastStatus = this.setupService.serverSetup.status;
    }
    this.errorVisible = (this.setupService.serverSetup == null &&
                        (this.lastStatus == null ||
                         this.lastStatus !== VersionStatus.Upgrading));
    if(!this.setupService.isServerSetupValid) {
      if(!this.configService.configuration.useMockHttp) {
        // handle the data to show to user
        this.updateDataAccordingToSetupStatus();
        setTimeout(() => this.monitorServerSetup(), this.configService.configuration.pingInterval);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  private async startInstall() {
    const response = await this.setupService.startInstallUpgradeForMinimalVersion();
    if (response.status === 200) {
      this.setupService.setServerSetup(response.responseBody);
    }
  }

  private updateDataAccordingToSetupStatus() {
    if (this.setupService.serverSetup &&
       (this.setupService.serverSetup.status === VersionStatus.Downloading ||
        this.setupService.serverSetup.status === VersionStatus.Upgrading)) {
      this.loadingBarPercentage = this.setupService.serverSetup.progress;
    }
  }

}
