import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TasksComponent} from './tasks/tasks.component';
import {TaskEditorComponent} from './task-editor/task-editor.component';
import {NoTasksListComponent} from './tasks/no-tasks-list/no-tasks-list.component';
import {LedTasksGuard} from '../../services/led-tasks/route-guards/led-tasks.guard';
import {SuspendGuard} from "../../services/led-tasks/route-guards/suspend.guard";

const routes: Routes = [
      {
          path: '',
          redirectTo: '/led-tasks/tasks',
          pathMatch: 'full'
      },
      {
          path: 'tasks',
          component: TasksComponent,
          canActivate: [LedTasksGuard, SuspendGuard]
      },
      {
        path: 'no-tasks',
        component: NoTasksListComponent,
        data: { hasTasks: false },
        canActivate: [SuspendGuard]
      },
      {
          path: 'task-editor',
          component: TaskEditorComponent,
          canActivate: [SuspendGuard]
      },
      {
          path: 'task-editor/:id',
          component: TaskEditorComponent,
          canActivate: [SuspendGuard]
      }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LedTasksRoutingModule { }
