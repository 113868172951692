<div class="form-group">
  <div class="form-group-label">{{ "ANIMAL.EVENTS.ADD_EVENT.SIRE" | translate}}</div>
  <div class="dropdown-item">
    <bull-choose-dropdown-list class="create-batch-event-content-control"
                               [items]="bullListItems"
                               [selectedBullListItem]="selectedBullListItem"
                               (onBullSelectionChanged)="onBullSelectionChanged($event)"
                               (onBullRemoved)="onBullRemoved($event)"
                               [placeholder]="'Optional'">
    </bull-choose-dropdown-list>
  </div>
</div>
