import * as moment from 'moment';
import {Component, Input, OnInit} from '@angular/core';
import {CreateDoNotBreedEventDetails, DoNotBreedCowEventMetadata} from '../../../../../services/animals/model/animal-events';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../calendar/model/epoch-state-model';

@Component({
  selector: 'create-batch-event-do-not-breed',
  templateUrl: './create-batch-event-do-not-breed.component.html',
  styleUrls: ['./create-batch-event-do-not-breed.component.scss']
})
export class CreateBatchEventDoNotBreedComponent implements OnInit {

  public expirationDate : EpochStateModel;

  @Input()
  public set doNotBreedEventDetails(doNotBreedEventDetails: CreateDoNotBreedEventDetails) {
    this.pdoNotBreedEventDetails = doNotBreedEventDetails;
    this.expirationDate.min = moment().unix();
  }

  public get doNotBreedEventDetails(): CreateDoNotBreedEventDetails {
    return this.pdoNotBreedEventDetails;
  }

  @Input()
  public meta: DoNotBreedCowEventMetadata;

  private pdoNotBreedEventDetails: CreateDoNotBreedEventDetails;

  constructor() {
    this.expirationDate = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.expirationDate.min = moment().endOf('day').unix();
  }

  public ngOnInit() {
  }

  public onBreedingDateChanged(date:EpochStateModel) {
    this.doNotBreedEventDetails.expirationDate = date.epochDate;
  }
}
