import {Injectable} from '@angular/core';
import {HttpResponseResult, HttpResponseResultNoBody, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from '../auth/auth.service';
import {from, Observable, of, Subject, throwError, timer} from 'rxjs';
import {finalize, map, mergeMap, mergeMapTo} from 'rxjs/operators';
import {
  AbortionCowEventMetadata,
  AbortionNewLactationEventRow,
  AbortionSameLactationCowEventMetadata,
  AbortionSameLactationEventRow,
  AnimalEventRow,
  AnimalsEventsResult,
  AssignRfIdTagCowEventMetadata,
  AssignRfIdTagEventRow,
  AssignScrTagCowEventMetadata,
  AssignScrTagEventRow,
  BreedingEventRow,
  CalvingCowEventMetadata,
  CalvingEventRow,
  CastrationEventDetails,
  ChangeGroupEventRow,
  ChangeRfIdTagCowEventMetadata,
  ChangeRfIdTagEventRow,
  ChangeScrTagCowEventMetadata,
  ChangeScrTagEventRow,
  CowEventMetadataBase,
  CreateAbortionEventDetails,
  CreateAbortionSameLactationEventDetails,
  CreateAssignRfIdTagEventDetails,
  CreateAssignSrcTagEventDetails,
  CreateBreedingEventDetails,
  CreateCalvingEventDetails,
  CreateChangeGroupEventDetails,
  CreateChangeRfIdTagEventDetails,
  CreateChangeScrTagEventDetails,
  CreateCullingEventDetails,
  CreateDoNotBreedEventDetails,
  CreateDryOffEventDetails,
  CreateGeneralEventDetails,
  CreatePregnancyCheckEventDetails,
  CreateRemoveRfIdTagEventDetails,
  CreateRemoveScrTagEventDetails,
  CreateUnsortEventDetails,
  CreateWeaningEventDetails,
  CullingEventRow,
  DistressEventRow,
  DoNotBreedCowEventMetadata,
  DoNotBreedEventRow,
  DryOffCowEventMetadata,
  DryOffEventRow,
  EventActionDetails,
  EventDetails,
  EventDetailsBase,
  EventTypeEnum,
  EventValidationError,
  EventValidationErrorResponseResult,
  EventValidationErrorTypes,
  GeneralEventRow,
  HerdEntryEventRow,
  IAvailableEventInfo,
  ISavedEventResponse,
  Newborn,
  PregnancyCheckCowEventMetadata,
  PregnancyCheckEventRow,
  RemoveRfIdTagCowEventMetadata,
  RemoveRfIdTagEventRow,
  RemoveScrTagCowEventMetadata,
  RemoveScrTagEventRow,
  RemoveTagEventRow,
  StartBackgroundEventDetails,
  StartFinishingEventDetails,
  SystemHealthEventRow,
  SystemHeatEventRow,
  TagSwUpdateEventRow,
  WeightEventDetails,
  WeightEventRow
} from './model/animal-events';
import {PollingService} from '../rest-api/polling.service';
import {HttpParams} from '@angular/common/http';
import {RemoveAnimalCullingRequest} from './model/animal.model';
import {ErrorModel} from '../model/common-model';
import {KeyValuePair} from "../model/key-value-pair";
import {DateTimeFormatEnum, EpochDateTimePipe} from "../../common/pipes/epoch-date-time.pipe";
import {TranslationService} from "../translations/translation.service";
import {UtilsService} from "../utils/utils.service";
import {DurationPipe} from "../../common/pipes/duration.pipe";
import {ChipsItem} from '../../common/components/chips/chips-item.component';
import {Tag} from "@angular/compiler/src/i18n/serializers/xml_helper";

export class AnimalInfo  {
  public id: number;
  public animalId: string;
  public errors: ErrorModel[];
}

type ValidateBatchEventRequest = {
  actionEvent: 'validationEvent';
  animalIds: number[];
} & EventDetailsBase;

interface IValidateBatchEventResponse {
  result: IValidateBatchEventResult;
}

export enum ValidateBatchEventResultState {
  Correct = 'Correct',
  Incorrect = 'Incorrect'
}

export interface IValidateBatchEventResult {
  succeeded: boolean;
  state: ValidateBatchEventResultState;
  correlationId: string;
  createdEvents: number;
  failures: AnimalInfo[];
}

export enum CreateBatchEventResponseState {
  InProgress = 'InProgress',
  Completed = 'Completed',
  CompletedWithErrors = 'CompletedWithErrors'
}

export class CreateBatchEventResponse {
  public succeeded: boolean;

  public state: CreateBatchEventResponseState;

  public correlationId: string;

  public createdEvents: number;

  public failures: AnimalInfo[];
}

export enum BatchAnimalValidationState {
  inProgress = 'inProgress',
  valid = 'valid',
  invalid = 'invalid'
}

export enum AddEventPhase {
  initial = 'initial',
  inProgress = 'inProgress',
  completed = 'completed'
}

export class BatchAnimal extends ChipsItem {
  public validationState?: BatchAnimalValidationState;
}

export interface IRemoveAnimalEventErrorDialogDetailsModel {
  animalId: number;
  errors: EventValidationError[];
}

@Injectable({
  providedIn: 'root'
})
export class AnimalEventsService {

  private static readonly AnimalsEventsRoute = '/rest/api/animals/events';

  private static readonly AnimalEventsRoute = '/rest/api/animals/{animalId}/events';

  private static readonly EventsBatchRoute = '/rest/api/animals/events/batch';

  private static readonly EventsFinishingRoute = '/rest/api/finishing/animals/events';

  private static readonly AnimalRemoveCullingEventRoute = '/rest/api/animals/{animalId}/isCulled';

  private static readonly AvailableEventsRoute = '/rest/api/animals/events?projection=availableBatchEvents';

  private static readonly AvailableFinishingEventsRoute = '/rest/api/finishing/animals/events?projection=availableBatchEvents';

  public eventsListChanged = new Subject<number>();

  public addEventPhaseSubject = new Subject<AddEventPhase>();

  public removeAnimalEventErrorDialogSubject = new Subject<IRemoveAnimalEventErrorDialogDetailsModel>();

  constructor(
    private restApiService: RestApiService,
    private pollingService: PollingService,
    private authService: AuthService,
    private translationService: TranslationService,
    private utilsService: UtilsService,
    private durationPipe: DurationPipe,
    private epochDateTimePipe: EpochDateTimePipe
  ) {
  }

  public async getAnimalAvailableEvents(animalId: number): Promise<IAvailableEventInfo[]> {
    let response = await this.restApiService.sendGetMethod<IAvailableEventInfo[]>(AnimalEventsService.AnimalEventsRoute.replace('{animalId}', String(animalId)),
                                                                    this.authService.currentUserAuthState.authDetails,
                                                                   {projection: 'availableEvents'});
    if(response.status == 200) {
      return this.normalizePregnancyEvents(response.responseBody);
    } else {
      return [];
    }
  }

  public async getEventTypeMetadata<T extends CowEventMetadataBase>(animalId: number, eventType: EventTypeEnum): Promise<T> {
    switch (eventType) {
      // if event is without metadata - then just return the type
      case EventTypeEnum.DoNotBreed:
      case EventTypeEnum.Unsort:
      case EventTypeEnum.Castration:
      case EventTypeEnum.StartBackground:
      case EventTypeEnum.StartFinishing:
      case EventTypeEnum.Weight:
        return Promise.resolve(<T>{
          type: eventType
        });
      case EventTypeEnum.PregnancyCheck:
        eventType = EventTypeEnum.PositivePregnancyCheck;
        break;
    }
    const url = AnimalEventsService.AnimalEventsRoute.replace('{animalId}', String(animalId));
    const response = await this.restApiService.sendGetMethod<T>(
      url,
      this.authService.currentUserAuthState.authDetails,
      {
        projection: 'metadata',
        type: eventType,
      },
    );
    return response.responseBody;
  }

  public async createAnimalEvent(animalId: number, eventAction: EventActionDetails, event: EventDetails): Promise<HttpResponseResult<ISavedEventResponse>> {
    const requestObject = Object.assign({}, eventAction, event);
    return this.restApiService.sendPostMethod(
      AnimalEventsService.AnimalEventsRoute.replace('{animalId}', String(animalId)),
      this.authService.currentUserAuthState.authDetails,
      requestObject,
    );
  }

  // tslint:disable-next-line:no-any
  public async updateAnimalEvent(animalId: number, eventId: number, eventData: AnimalEventRow): Promise<HttpResponseResult<any>> {
    const response = await this.restApiService.sendPutMethod(
      `${AnimalEventsService.AnimalEventsRoute.replace('{animalId}', String(animalId))}/${eventId}`,
      this.authService.currentUserAuthState.authDetails,
      eventData,
    );
    await this.eventsListChanged.next(animalId);
    return response;
  }

  public async deleteAnimalEvent(animalId: number, eventId: number): Promise<HttpResponseResultNoBody> {
    const response = await this.restApiService.sendDeleteMethod(
      `${AnimalEventsService.AnimalEventsRoute.replace('{animalId}', String(animalId))}/${eventId}`,
      this.authService.currentUserAuthState.authDetails,
    );
    if(response.status == 204) {
      await this.eventsListChanged.next(animalId);
    } else if(response.status == 400) {
      const errors = this.getEventValidationErrors(response.errorResponseBody.result);
      this.removeAnimalEventErrorDialogSubject.next({animalId: animalId, errors: errors});
    }
    return response;
  }

  public async animalRemoveCulling(animalId: number): Promise<HttpResponseResultNoBody> {
    let route = AnimalEventsService.AnimalRemoveCullingEventRoute.replace('{animalId}', animalId.toString());
    let removeCullingRequest = new RemoveAnimalCullingRequest();
    removeCullingRequest.newState = false;
    let response = await this.restApiService.sendPutMethod(route, this.authService.currentUserAuthState.authDetails, removeCullingRequest);
    if (response.status === 400) {
      const errors = this.getEventValidationErrors(response.errorResponseBody.result);
      this.removeAnimalEventErrorDialogSubject.next({animalId: animalId, errors: errors});
    }
    return response;
  }

  public validateBatchEvents(options: {
    event: EventDetailsBase;
    animalIds: number[];
    // tslint:disable-next-line:no-any
    startDateTime: any;
  }): Observable<IValidateBatchEventResult> {
    const promise = this.restApiService.sendPostMethodAny<IValidateBatchEventResponse, ValidateBatchEventRequest>(
      AnimalEventsService.AnimalsEventsRoute,
      this.authService.currentUserAuthState.authDetails,
      {
        actionEvent: 'validationEvent',
        ...options.event,
        ...options
      },
    );

    return this.convertHttpRequestToObservable(promise).pipe(map((r) => r.result));
  }

  public getAnimalsFinishingEvents(offsetPage: number, limit: number, sortColumn?: string): Promise<HttpResponseResult<AnimalsEventsResult>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('offset', offsetPage.toString());
    httpParams = httpParams.append('limit', limit.toString());
    if (sortColumn !== null) {
      httpParams = httpParams.append('sort', sortColumn);
    }
    return this.restApiService.sendGetMethodAny<AnimalsEventsResult>(AnimalEventsService.EventsFinishingRoute, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public async getBatchEventTypeMetadata(eventType: EventTypeEnum, currentDate: Date): Promise<CowEventMetadataBase> {
    switch (eventType) {
      case EventTypeEnum.DoNotBreed:
        return Promise.resolve<DoNotBreedCowEventMetadata>({
          type: eventType,
          expirationDate: currentDate
        });
      case EventTypeEnum.Unsort:
      case EventTypeEnum.RemoveScrTag:
      case EventTypeEnum.RemoveRfIdTag:
      case EventTypeEnum.AssignScrTag:
        return Promise.resolve<CowEventMetadataBase>({
          type: eventType
        });
      case EventTypeEnum.PregnancyCheck:
        return Promise.resolve<PregnancyCheckCowEventMetadata>({
          type: eventType,
          allowedNegative: true,
          allowedPositive: true,
          allowedUncertain: true
        });
    }
    const response = await this.restApiService.sendGetMethod<CowEventMetadataBase>(AnimalEventsService.AnimalsEventsRoute,
                                                                                   this.authService.currentUserAuthState.authDetails,
                                                                                  {projection: 'batchmetadata', type: eventType});
    return response.responseBody;
  }

  public async getFinishingBatchEventTypeMetadata(eventType: EventTypeEnum, currentDate: Date): Promise<CowEventMetadataBase> {
    switch (eventType) {
      case EventTypeEnum.Castration:
      case EventTypeEnum.StartBackground:
      case EventTypeEnum.Weight:
      case EventTypeEnum.StartFinishing: {
        return Promise.resolve<CowEventMetadataBase>({
          type: eventType
        });
      }
    }
    const response = await this.restApiService.sendGetMethod<CowEventMetadataBase>(
      AnimalEventsService.EventsFinishingRoute,
      this.authService.currentUserAuthState.authDetails,
      {
        projection: 'batchmetadata',
        type: eventType,
      },
    );
    return response.responseBody;
  }

  public createBatchEvent(eventDetails: EventDetails, options: { startDateTime: number; animalIds: number[]; }): Observable<CreateBatchEventResponse> {
    this.addEventPhaseSubject.next(AddEventPhase.initial);

    const request = this.restApiService.sendPostMethod<CreateBatchEventResponse>(
      AnimalEventsService.AnimalsEventsRoute,
      this.authService.currentUserAuthState.authDetails,
      {
        ...eventDetails,
        ...options,
        actionEvent: 'batchEvent'
      },
    );

    const waitBatchEventCreation$ = (response: CreateBatchEventResponse): Observable<CreateBatchEventResponse> => {
      this.addEventPhaseSubject.next(AddEventPhase.inProgress);

      switch (response.state) {
        case 'InProgress':
          return this.waitBatchEventCreation(response.correlationId);
        default:
          return of(response);
      }
    };

    return this.convertHttpRequestToObservable(request).pipe(
      mergeMap(waitBatchEventCreation$),
      finalize(() => {
        this.addEventPhaseSubject.next(AddEventPhase.completed);
      })
    );
  }

  public createFinishingBatchEvent(eventDetails: EventDetails, options: { startDateTime: number; animalIds: number[]; }): Observable<CreateBatchEventResponse> {
    this.addEventPhaseSubject.next(AddEventPhase.initial);

    const request = this.restApiService.sendPostMethod<CreateBatchEventResponse>(
      AnimalEventsService.EventsFinishingRoute,
      this.authService.currentUserAuthState.authDetails,
      {
        ...eventDetails,
        ...options,
        actionEvent: 'batchEvent'
      },
    );

    const waitBatchEventCreation$ = (response: CreateBatchEventResponse): Observable<CreateBatchEventResponse> => {
      this.addEventPhaseSubject.next(AddEventPhase.inProgress);

      switch (response.state) {
        case 'InProgress':
          return this.waitBatchEventCreation(response.correlationId);
        default:
          return of(response);
      }
    };

    return this.convertHttpRequestToObservable(request).pipe(
      mergeMap(waitBatchEventCreation$),
      finalize(() => {
        this.addEventPhaseSubject.next(AddEventPhase.completed);
      })
    );
  }

  public getAnimalsEvents(offsetPage: number, limit: number, sortColumn?: string): Promise<HttpResponseResult<AnimalsEventsResult>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('offset', offsetPage.toString());
    httpParams = httpParams.append('limit', limit.toString());
    if (sortColumn !== null) {
      httpParams = httpParams.append('sort', sortColumn);
    }
    return this.restApiService.sendGetMethodAny<AnimalsEventsResult>(AnimalEventsService.AnimalsEventsRoute, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getAnimalEvents(animalId: number, offsetPage: number, limit: number, sortColumn?: string): Promise<HttpResponseResult<AnimalsEventsResult>> {
    let route = AnimalEventsService.AnimalEventsRoute.replace('{animalId}', animalId.toString());
    let httpParams = new HttpParams();
    httpParams = httpParams.append('offset', offsetPage.toString());
    httpParams = httpParams.append('limit', limit.toString());
    if (sortColumn !== null) {
      httpParams = httpParams.append('sort', sortColumn);
    }
    return this.restApiService.sendGetMethodAny<AnimalsEventsResult>(route, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  private getBatchEventsCreationStatus(correlationId: string): Observable<CreateBatchEventResponse> {
    const promise = this.restApiService.sendGetMethod<CreateBatchEventResponse>(
      AnimalEventsService.EventsBatchRoute,
      this.authService.currentUserAuthState.authDetails,
      {correlationId},
    );
    return this.convertHttpRequestToObservable(promise);
  }

  private waitBatchEventCreation(correlationId: string): Observable<CreateBatchEventResponse> {
    return timer(1000).pipe(mergeMapTo(
      this.getBatchEventsCreationStatus(correlationId).pipe(mergeMap((r) => {
        if (r.state === 'InProgress') {
          return this.waitBatchEventCreation(correlationId);
        }
        return of(r);
      }))
    ));
  }

  // tslint:disable-next-line:no-any
  public validate(model?: any | null, animals?: number | BatchAnimal[] | null): boolean {
    if (animals == null || model == null || model.type == null) {
      return false;
    }

    return typeof animals === 'number'
      ? this.validateBatchEventModel(model)
      : this.validateMultipleAnimalsModel(model, animals);
  }

  // tslint:disable-next-line:no-any
  private validateMultipleAnimalsModel(model: any, batchAnimals: BatchAnimal[]): boolean {
    if (batchAnimals.some((batchAnimal) => batchAnimal.validationState !== 'valid')) {
      return false;
    }
    return this.validateBatchEventModel(model);
  }

  // tslint:disable-next-line:no-any
  public validateBatchEventModel(model?: any): boolean {
    if (model == null || model.type == null) {
      return false;
    }
    if ([
      EventTypeEnum.Calving,
      EventTypeEnum.Abortion,
      EventTypeEnum.DryOff,
      EventTypeEnum.ChangeGroup,
    ].includes(model.event.type)) {
      return model.destinationGroup != null;
    }
    return true;
  }

  public getDefaultEventDetails(eventType: EventTypeEnum, meta: CowEventMetadataBase): EventDetails {
    switch (eventType) {
      case EventTypeEnum.PregnancyCheck:
        return new CreatePregnancyCheckEventDetails();
      case EventTypeEnum.Breeding:
        return new CreateBreedingEventDetails();
      case EventTypeEnum.DoNotBreed:
        return new CreateDoNotBreedEventDetails();
      case EventTypeEnum.Unsort:
        return new CreateUnsortEventDetails();
      case EventTypeEnum.DryOff:
        return new CreateDryOffEventDetails(meta as DryOffCowEventMetadata);
      case EventTypeEnum.ChangeGroup:
        return new CreateChangeGroupEventDetails();
      case EventTypeEnum.Culling:
        return new CreateCullingEventDetails();
      case EventTypeEnum.Calving:
        return new CreateCalvingEventDetails(meta as CalvingCowEventMetadata);
      case EventTypeEnum.Abortion:
        return new CreateAbortionEventDetails(meta as AbortionCowEventMetadata);
      case EventTypeEnum.AbortionSameLactation:
        return new CreateAbortionSameLactationEventDetails(meta as AbortionSameLactationCowEventMetadata);
      case EventTypeEnum.Weaning:
        return new CreateWeaningEventDetails();
      case EventTypeEnum.AssignScrTag:
        return new CreateAssignSrcTagEventDetails(
          meta as AssignScrTagCowEventMetadata
        );
      case EventTypeEnum.AssignRfIdTag:
        return new CreateAssignRfIdTagEventDetails(
          meta as AssignRfIdTagCowEventMetadata
        );
      case EventTypeEnum.RemoveScrTag:
        return new CreateRemoveScrTagEventDetails(
          meta as RemoveScrTagCowEventMetadata
        );
      case EventTypeEnum.RemoveRfIdTag:
        return new CreateRemoveRfIdTagEventDetails(
          meta as RemoveRfIdTagCowEventMetadata
        );
      case EventTypeEnum.ChangeScrTag:
        return new CreateChangeScrTagEventDetails(
          meta as ChangeScrTagCowEventMetadata
        );
      case EventTypeEnum.ChangeRfIdTag:
        return new CreateChangeRfIdTagEventDetails(
          meta as ChangeRfIdTagCowEventMetadata
        );
      case EventTypeEnum.StartBackground:
        return new StartBackgroundEventDetails();
      case EventTypeEnum.StartFinishing:
        return new StartFinishingEventDetails();
      case EventTypeEnum.Weight:
        return new WeightEventDetails();
      case EventTypeEnum.Castration:
        return new CastrationEventDetails();
      case EventTypeEnum.General:
        return new CreateGeneralEventDetails();
      default:
        return null;
    }
  }

  public getEventValidationErrors(result: EventValidationErrorResponseResult): EventValidationError[] {
    let errors: EventValidationError[] = [];

    if (result.failures && result.failures.length) {
      errors = result.failures;
    } else {
      errors.push({key: EventValidationErrorTypes.ServerError, fieldName: 'Server error'});
    }

    return errors;
  }

  public async getAvailableBatchEvents(maxDate: number): Promise<IAvailableEventInfo[]> {
    let response = await this.restApiService.sendGetMethod<IAvailableEventInfo[]>(AnimalEventsService.AvailableEventsRoute,
                                                                                  this.authService.currentUserAuthState.authDetails);
    if(response.status == 200) {
      response.responseBody.forEach(value => {
        value.isEnabled = true;
        value.isVisible = true;
        value.maxDate = maxDate;
      });
      return this.normalizePregnancyEvents(response.responseBody);
    }
    return null;
  }

  public async getAvailableFinishingBatchEvents(maxDate: number): Promise<IAvailableEventInfo[]> {
    let response = await this.restApiService.sendGetMethod<IAvailableEventInfo[]>(AnimalEventsService.AvailableFinishingEventsRoute, this.authService.currentUserAuthState.authDetails);
    if (response.status == 200) {
      response.responseBody.forEach(value => {
        value.isEnabled = true;
        value.isVisible = true;
        value.maxDate = maxDate;
      });
      return this.normalizePregnancyEvents(response.responseBody);
    }
  }

  private convertHttpRequestToObservable<T>(promise: Promise<HttpResponseResult<T>>): Observable<T> {
    return from(promise).pipe(
      mergeMap(httpResponseResult => httpResponseResult.responseBody != null
        ? of(httpResponseResult.responseBody)
        : throwError(httpResponseResult.errorResponseBody))
    );
  }

  private normalizePregnancyEvents(events: IAvailableEventInfo[]) {
    let normalizedEvents : IAvailableEventInfo[] = [];
    if(events != null) {
      events.forEach((event) => {
        if (
          event.type === EventTypeEnum.PositivePregnancyCheck ||
          event.type === EventTypeEnum.NegativePregnancyCheck ||
          event.type === EventTypeEnum.UncertainPregnancyCheck
        ) {
          let existPregChecEvent = normalizedEvents.find(
            (value) => value.type == EventTypeEnum.PregnancyCheck
          );
          if (existPregChecEvent == null) {
            event.type = EventTypeEnum.PregnancyCheck;
            normalizedEvents.push(event);
          } else if (!existPregChecEvent.isEnabled && event.isEnabled) {
            existPregChecEvent.isEnabled = true;
          }
        } else {
          normalizedEvents.push(event);
        }
      });
    }
    return normalizedEvents;
  }

  public getAnimalEventDescription(animalEvent: AnimalEventRow): string {
    let descriptions: KeyValuePair<string, string>[];
    switch (animalEvent.type) {
      case 'calving': {
        const eventRow: CalvingEventRow = <CalvingEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.NEW_LACTATION_NUMBER'),
          value: animalEvent.lactationNumber.toString()
        }];
        if (eventRow.daysInPregnancy != null) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.DAYS_PREGNANT'),
            value: eventRow.daysInPregnancy.toString()
          });
        }
        if (eventRow.calvingEase != null) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.CALVING_EASE'),
            value: eventRow.calvingEase.value.toString()
          });
        }
        if (eventRow.destinationGroupName != null) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.DESTINATION_GROUP'),
            value: eventRow.destinationGroupName
          });
        }
        if (eventRow.newborns && eventRow.newborns.length > 0) {
          eventRow.newborns.forEach((newborn: Newborn) => {
            if (newborn.condition == 'alive' && newborn.animalName) {
              descriptions.push({
                key: this.translationService.translate('ANIMAL.NEWBORN.SEX.' + (newborn.sex == 'male' ? 'MALE' : (newborn.sex == 'female' ? 'FEMALE' : ''))),
                value: newborn.animalName.toString()
              });
            } else if (newborn.condition != 'alive' || !newborn.animalName) {
              descriptions.push({
                key: this.translationService.translate('ANIMAL.NEWBORN.SEX.' + (newborn.sex == 'male' ? 'MALE' : (newborn.sex == 'female' ? 'FEMALE' : ''))),
                value: '|' + this.translationService.translate('SYSTEM.DASHBOARD.GRID.STATUS') + ': ' + this.translationService.translate('ANIMAL.NEWBORN.CONDITION.' + newborn.condition)
              });
            }
          });
        }
        break;
      }
      case 'doNotBreed': {
        const eventRow: DoNotBreedEventRow = <DoNotBreedEventRow>animalEvent;
        if (eventRow.expirationDate != null) {
          descriptions = [{
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.EXPIRATION_DATE'),
            value: this.epochDateTimePipe.transform(eventRow.expirationDate, DateTimeFormatEnum.DateTime)
          }];
        } else if (eventRow.endDate != null) {
          descriptions = [{
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.EXPIRATION_DATE'),
            value: this.epochDateTimePipe.transform(eventRow.endDate, DateTimeFormatEnum.DateTime)
          }];
        } else {
          descriptions = null;
        }
        break;
      }
      case 'breeding': {
        const eventRow: BreedingEventRow = <BreedingEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.BREEDING_NUMBER'),
          value: (eventRow.breedingNumber || 0).toString()
        }];
        if (eventRow.sire) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.SIRE'),
            value: (eventRow.sire.name || '') + '' + (eventRow.sire.number || '')
          });
        }
        if (eventRow.interval) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.INTERVAL'),
            value: eventRow.interval + ''
          });
        }
        if (eventRow.breedingComment) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.BREEDING_COMMENT.' + eventRow.breedingComment),
            value: ''
          });
        }
        break;
      }
      case 'negativePregnancyCheck': {
        const eventRow: PregnancyCheckEventRow = <PregnancyCheckEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.RESULT'),
          value: this.translationService.translate('ANIMAL.EVENTS.EVENT_TYPE.negativePregnancyCheck'),
        }];
        if (eventRow.effectiveInseminationDate) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.EFFECTIVE_INSEMINATION_DATE'),
            value: this.epochDateTimePipe.transform(eventRow.effectiveInseminationDate, DateTimeFormatEnum.Date),
          });
        }
        if (eventRow.embryoAge) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.EMBRYO_AGE'),
            value: eventRow.embryoAge + ' ' + this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.DAYS'),
          });
        }
        break;
      }
      case 'uncertainPregnancyCheck': {
        const eventRow: PregnancyCheckEventRow = <PregnancyCheckEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.RESULT'),
          value: this.translationService.translate('ANIMAL.EVENTS.EVENT_TYPE.uncertainPregnancyCheck'),
        }];
        if (eventRow.effectiveInseminationDate) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.EFFECTIVE_INSEMINATION_DATE'),
            value: this.epochDateTimePipe.transform(eventRow.effectiveInseminationDate, DateTimeFormatEnum.Date),
          });
        }
        if (eventRow.embryoAge) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.EMBRYO_AGE'),
            value: eventRow.embryoAge + ' ' + this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.DAYS'),
          });
        }
        break;
      }
      case 'positivePregnancyCheck': {
        const eventRow: PregnancyCheckEventRow = <PregnancyCheckEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.RESULT'),
          value: this.translationService.translate('ANIMAL.EVENTS.EVENT_TYPE.positivePregnancyCheck'),
        }];
        if (eventRow.effectiveInseminationDate) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.EFFECTIVE_INSEMINATION_DATE'),
            value: this.epochDateTimePipe.transform(eventRow.effectiveInseminationDate, DateTimeFormatEnum.Date),
          });
        }
        if (eventRow.embryoAge) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.EMBRYO_AGE'),
            value: eventRow.embryoAge + ' ' + this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.DAYS'),
          });
        }
        break;
      }
      case 'assignScrTag': {
        const eventRow: AssignScrTagEventRow = <AssignScrTagEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.NEW_TAG'),
          value: eventRow.tag && eventRow.tag.tagNumber
        }];
        if (eventRow.tag && eventRow.tag.tagType) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.TAG_TYPE'),
            value: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.DEVICE_TYPE.' + eventRow.tag.tagType)
          });
        }
        break;
      }
      case 'removeScrTag': {
        const eventRow: RemoveScrTagEventRow = <RemoveScrTagEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.REMOVED_TAG'),
          value: eventRow.previousTag && eventRow.previousTag.tagNumber
        }];
        if (eventRow.tag && eventRow.tag.tagType) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.TAG_TYPE'),
            value: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.DEVICE_TYPE.' + eventRow.tag.tagType)
          });
        }
        break;
      }
      case 'assignRfIdTag': {
        const eventRow: AssignScrTagEventRow = <AssignScrTagEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.NEW_TAG'),
          value: eventRow.tag && eventRow.tag.tagNumber
        }];
        if (eventRow.tag && eventRow.tag.tagType) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.TAG_TYPE'),
            value: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.DEVICE_TYPE.' + eventRow.tag.tagType)
          });
        }
        break;
      }
      case 'removeRfIdTag': {
        const eventRow: RemoveRfIdTagEventRow = <RemoveRfIdTagEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.REMOVED_TAG'),
          value: eventRow.previousTag && eventRow.previousTag.tagNumber
        }];
        if (eventRow.tag && eventRow.tag.tagType) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.TAG_TYPE'),
            value: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.DEVICE_TYPE.' + eventRow.tag.tagType)
          });
        }
        break;
      }
      case 'changeScrTag':
        const eventRow: ChangeScrTagEventRow = <ChangeScrTagEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.NEW_TAG'),
          value: eventRow.tag && eventRow.tag.tagNumber
        }];
        if (eventRow.tag && eventRow.tag.tagType && eventRow.previousTag && eventRow.previousTag.tagNumber) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.PREVIOUS_TAG'),
            value: eventRow.previousTag.tagNumber
          });
        }
        break;
      case 'changeRfIdTag': {
        const eventRow: ChangeRfIdTagEventRow = <ChangeRfIdTagEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.NEW_TAG'),
          value: eventRow.tag && eventRow.tag.tagNumber
        }];
        if (eventRow.tag && eventRow.tag.tagType && eventRow.previousTag && eventRow.previousTag.tagNumber) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.PREVIOUS_TAG'),
            value: eventRow.previousTag.tagNumber
          });
        }
        break;
      }
      case 'culling': {
        const eventRow: CullingEventRow = <CullingEventRow>animalEvent;
        if(eventRow.cullingReason && eventRow.cullingReason.value) {
          descriptions = [{
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.CULLING'),
            value: eventRow.cullingReason.value.toString()
          }];
        }
        break;
      }
      case 'changeGroup': {
        const eventRow: ChangeGroupEventRow = <ChangeGroupEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.NEW_GROUP'),
          value: eventRow.newGroupName
        }];
        if (eventRow.currentGroupName) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.OLD_GROUP'),
            value: eventRow.currentGroupName
          });
        }
        break;
      }
      case 'herdEntry': {
        const eventRow: HerdEntryEventRow = <HerdEntryEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.LACTATION_STATUS'),
          value: this.translationService.translate('REPORTS.GRID.STATUS.' + eventRow.animalEntryStatus)
        }];
        break;
      }
      case 'dryOff': {
        const eventRow: DryOffEventRow = <DryOffEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.DP'),
          value: eventRow.daysInPregnancy.toString()
        }];
        break;
      }
      case 'removeTag': {
        const eventRow: RemoveTagEventRow = <RemoveTagEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.REMOVED_TAG'),
          value: eventRow.tag && eventRow.tag.tagNumber
        }];
        break;
      }
      case 'systemHealth': {
        const eventRow: SystemHealthEventRow = <SystemHealthEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.HEALTH_INDEX'),
          value: eventRow.healthIndex.toString()
        }];
        break;
      }
      case 'systemHeat': {
        const eventRow: SystemHeatEventRow = <SystemHeatEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.HEAT_INDEX_PEAK'),
          value: eventRow.heatIndexPeak.toString()
        }];
        break;
      }
      case 'distress': {
        const eventRow: DistressEventRow = <DistressEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.DISTRESS_DURATION'),
          value: this.durationPipe.transform((<DistressEventRow>animalEvent).duration)
        }];
        if (eventRow.alertType) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.DISTRESS_TYPE.NAME'),
            value: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.DISTRESS_TYPE.' + eventRow.alertType),
          });
        }
        break;
      }
      case 'tagSwUpdate': {
        const eventRow: TagSwUpdateEventRow = <TagSwUpdateEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.TAG_SW_UPDATE.NEW_VERSION'),
          value: eventRow.newVersion.toString()
        }];
        if (eventRow.oldVersion) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.TAG_SW_UPDATE.OLD_VERSION'),
            value: eventRow.oldVersion.toString()
          });
        }
        break;
      }
      case 'weight': {
        const eventRow: WeightEventRow = <WeightEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_TYPE.WEIGHT'),
          value: this.utilsService.showWeightValueAccordingToUnitType(eventRow).toString() + ' ' + this.utilsService.getUnitType()
        }];
        break;
      }
      case 'abortionSameLactation': {
        const eventRow: AbortionSameLactationEventRow = <AbortionSameLactationEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.SAME_LACTATION_NUMBER'),
          value: eventRow.lactationNumber.toString()
        }];
        if (eventRow.daysInPregnancy) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.DAYS_PREGNANT'),
            value: eventRow.daysInPregnancy.toString()
          });
        }
        break;
      }
      case 'abortionNewLactation': {
        const eventRow: AbortionNewLactationEventRow = <AbortionNewLactationEventRow>animalEvent;
        descriptions = [{
          key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.NEW_LACTATION_NUMBER'),
          value: eventRow.lactationNumber.toString()
        }];
        if (eventRow.daysInPregnancy) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.DAYS_PREGNANT'),
            value: eventRow.daysInPregnancy.toString()
          });
        }
        if (eventRow.destinationGroupName) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.ADD_EVENT.DESTINATION_GROUP'),
            value: eventRow.destinationGroupName.toString()
          });
        }
        if (eventRow.ease && eventRow.ease.value) {
          descriptions.push({
            key: this.translationService.translate('ANIMAL.EVENTS.EVENT_DESCRIPTION.CALVING_EASE'),
            value: eventRow.ease.value.toString()
          });
        }
        if (eventRow.newborns && eventRow.newborns.length > 0) {
          eventRow.newborns.forEach((newborn: Newborn) => {
            if (newborn.condition == 'alive' && newborn.animalName) {
              if (newborn.sex == 'male' || newborn.sex == 'female') {
                descriptions.push({
                  key: this.translationService.translate('ANIMAL.NEWBORN.SEX.' + (newborn.sex == 'female' ? 'FEMALE' : 'MALE')),
                  value: newborn.animalName.toString()
                });
              } else {
                descriptions.push({
                  key: '',
                  value: newborn.animalName.toString()
                });
              }
            } else if (newborn.condition != 'alive' || !newborn.animalName) {
              if (newborn.sex == 'male' || newborn.sex == 'female') {
                descriptions.push({
                  key: this.translationService.translate('ANIMAL.NEWBORN.SEX.' + (newborn.sex == 'female' ? 'FEMALE' : 'MALE')),
                  value: '|' + this.translationService.translate('SYSTEM.DASHBOARD.GRID.STATUS') + ': ' + this.translationService.translate('ANIMAL.NEWBORN.CONDITION.' + newborn.condition)
                });
              } else {
                descriptions.push({
                  key: '',
                  value: '|' + this.translationService.translate('SYSTEM.DASHBOARD.GRID.STATUS') + ': ' + this.translationService.translate('ANIMAL.NEWBORN.CONDITION.' + newborn.condition)
                });
              }
            }
          });
        }
        break;
      }
      case 'general': {
        const eventRow: GeneralEventRow = <GeneralEventRow>animalEvent;
        descriptions = [{
          key: '',
          value: eventRow.description.value.toString()
        }];
        break;
      }
      default:
        descriptions = null;
        break;
    }

    if (descriptions == null || !(descriptions && descriptions.length > 0)) {
      return '';
    }
    return descriptions.map((description) => {
      if (!description.key) {
        return description.value;
      } else {
        return description.key + ': ' + (description.value == null ? '' : description.value);
      }
    }).join(`\r\n`);
  }
}
