import {Component, Input, OnInit} from '@angular/core';
import {FilterNumericQuery, NumericQueryCompareOperatorsEnum} from '../../../../../../services/reports/model/report-query.interface';
import {DropdownListItem} from '../../../../dropdown-list/dropdown-list-model';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../../calendar/model/epoch-state-model';

@Component({
  selector: 'filter-date-time',
  templateUrl: './filter-date-time.component.html',
  styleUrls: ['./filter-date-time.component.scss']
})
export class FilterDateTimeComponent implements OnInit {

  @Input()
  public filterNumericQuery : FilterNumericQuery;

  public operatorsDropdownItems: DropdownListItem[];

  public initialValue1: EpochStateModel;

  public initialValue2: EpochStateModel;

  // tslint:disable-next-line:no-any
  public selectedQueryOperator: any = null;

  constructor() { }

  public ngOnInit() {
    this.initOperatorsDropdownItems();
    this.initDateTimePickers();
  }

  private initOperatorsDropdownItems(){
    this.operatorsDropdownItems = [];
    for (let operator in NumericQueryCompareOperatorsEnum) {
      this.operatorsDropdownItems.push({
        value: operator,
        displayValue: 'COMMON.FILTER.OPERATOR.NUMERIC.' + operator,
        icon: null,
        disabled: false
      });
    }
  }

  private initDateTimePickers(): void {
    this.initialValue1 = new EpochStateModel(EpochStateModelMinMaxMode.Date);
    this.initialValue2 = new EpochStateModel(EpochStateModelMinMaxMode.Date);
  }

  public onOperatorSelected(operator?:NumericQueryCompareOperatorsEnum) {
    this.filterNumericQuery.operator = operator;
  }

  public onDateTimeValue1Changed(epoch:EpochStateModel){
    this.filterNumericQuery.compValue1 = epoch.epochDate;
  }

  public onDateTimeValue2Changed(epoch:EpochStateModel){
    this.filterNumericQuery.compValue2 = epoch.epochDate;
  }
}
