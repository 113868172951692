import * as moment from 'moment';


export class AnimalTimelineUtils {
  public static convertDateTimeToLocalDate(date: number) : number {
    const localDate = moment.unix(date).toDate();
    return moment(new Date(localDate.getFullYear(), localDate.getMonth(), localDate.getDate())).unix();
  }
  
  public static calculateDaysBetweenDates (startDate: number, endDate: number) : number {
    const momentEndDate = moment.unix(endDate);
    const momentStartDate = moment.unix(startDate);
    return momentEndDate.diff(momentStartDate, 'days');
  }
}
