import { Injectable } from '@angular/core';
import {TranslationService} from '../translations/translation.service';

export enum RectIntersectionResultType {
  NoIntersection = 'NoIntersection',
  PartialIntersection = 'PartialIntersection',
  ARectContainedInBRect = 'ARectContainedInBRect',
  BRectContainedInARect = 'BRectContainedInARect',
  Identical = 'Identical'
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private static readonly KGtoPounds: number = 2.20462;

  constructor(private translationService: TranslationService) { }

  public convertPoundsToKilograms(pounds: number): number {
    return Number((pounds / UtilsService.KGtoPounds).toFixed(2));
  }

  public convertKilogramsToPounds(kg: number): number {
    return Number((kg * UtilsService.KGtoPounds).toFixed(2));
  }

  public showWeightValueAccordingToUnitType(row) {
    if (this.getUnitType() === WeightUnitType.LBS) {
      return row.weightValueLBS;
    } else {
      return row.weightValueKG;
    }
  }

  public getUnitType() {
    if (this.translationService.selectedLanguage === 'en-us') {
      return WeightUnitType.LBS;
    } else return WeightUnitType.KG;
  }

  public domRectIntersect(rectA:DOMRect | ClientRect, rectB:DOMRect | ClientRect) : RectIntersectionResultType {
    if(rectA.left == rectB.left &&
       rectA.top == rectB.top &&
       rectA.right == rectB.right &&
       rectA.bottom == rectB.bottom) {
      return RectIntersectionResultType.Identical;
    }
    if(rectA.left <= rectB.left &&
       rectA.right >= rectB.right &&
       rectA.top <= rectB.top &&
       rectA.bottom >= rectB.bottom) {
      return RectIntersectionResultType.BRectContainedInARect;
    }
    if(rectB.left <= rectA.left &&
       rectB.right >= rectA.right &&
       rectB.top <= rectA.top &&
       rectB.bottom >= rectA.bottom) {
      return RectIntersectionResultType.ARectContainedInBRect;
    }
    let hasPartialIntersection = !(rectB.left >= rectA.right ||
                                   rectB.right <= rectA.left ||
                                   rectB.top >= rectA.bottom ||
                                   rectB.bottom <= rectA.top);
    return hasPartialIntersection ? RectIntersectionResultType.PartialIntersection : RectIntersectionResultType.NoIntersection;
  }

  public isVisibleDomElement(rect:DOMRect | ClientRect) {
    return rect.left != 0 ||
           rect.right != 0 ||
           rect.top != 0 ||
           rect.bottom != 0;
  }
}
export enum WeightUnitType {
  LBS = 'lbs',
  KG = 'kg'
}
