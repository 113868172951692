import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {InputFilterMode} from '../../../../common/directives/input-filter.directive';
import {ErrorModel} from '../../../../services/model/common-model';
import {BranchesService} from '../../../../services/branches/branches.service';
import {Subscription} from 'rxjs';
import {GroupBranchModel} from '../../../../services/branches/model/branch.interface';
import { CornerColor, CornerContainerBodyBackground } from 'src/app/common/components/containers/corner-container/corner-container.component';

@Component({
  selector: 'branch-editor',
  templateUrl: './branch-editor.component.html',
  styleUrls: ['./branch-editor.component.scss']
})
export class BranchEditorComponent implements OnInit {

  private static readonly MaxIntegerNumber: number = 2147483647;

  public isOpen: boolean = false;

  public branchFields = BranchFields;

  public InputFilterMode = InputFilterMode;

  private groupBranchModel: GroupBranchModel;

  private editBranchSubscription: Subscription;

  private isEdit: boolean = false;

  public Error: ErrorModel;

  public CornerColor = CornerColor;

  public CornerContainerBodyBackground = CornerContainerBodyBackground;

  @Output()
  public onSavedBranchChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public onToggleAddBranch: EventEmitter<void> = new EventEmitter<void>();

  constructor(private branchesService: BranchesService) { }

  public ngOnInit() {
    this.editBranchSubscription = this.branchesService.editBranchesSubscription.subscribe((branchToEdit: GroupBranchModel) => {
      if (branchToEdit == null) {
        this.groupBranchModel = new GroupBranchModel();
      } else {
        this.isEdit = true;
        this.groupBranchModel = branchToEdit;
      }
      this.clearErrors();
    });

  }

  public toggleOpen() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.groupBranchModel = new GroupBranchModel();
    }
    if (this.isEdit) {
      this.isEdit = false;
    }
    this.onToggleAddBranch.emit();
    this.clearErrors();
  }

  public clearErrors() {
    this.Error = null;
  }

  public async saveBranch() {
    if (this.validateBranch()) {
      let response;
      if (this.isEdit) {
        response = await this.branchesService.editBranch(this.groupBranchModel);
      } else {
        response = await this.branchesService.createBranch(this.groupBranchModel);
      }
      if (response.status === 201 || response.status === 200) {
        this.groupBranchModel = new GroupBranchModel();
        if (this.isEdit) {
          this.toggleOpen();
        }
        await this.onSavedBranchChanges.emit(this.isOpen);
        await this.branchesService.onBranchesListChanged.next();
      } else {
        this.Error = new ErrorModel();
        if (response.errorResponseBody.result.message) {
          this.Error.key = response.errorResponseBody.result.message;
        } else {
          this.Error.key = response.errorResponseBody.result.failures[0].key;
          this.Error.fieldName = response.errorResponseBody.result.failures[0].fieldName;
        }
      }
    }
  }

  public validateBranch(): boolean {
    if (this.groupBranchModel.groupBranchName == null || this.groupBranchModel.groupBranchName === '') {
      this.Error = { key: 'GroupBranchNameCannotBeEmpty', fieldName: BranchFields.BranchName};
      return false;
    } else if (this.groupBranchModel.groupBranchName.length > 16) {
      this.Error = { key: 'GroupBranchNameIsTooLong', fieldName: BranchFields.BranchName};
      return false;
    } else if (this.groupBranchModel.groupBranchNumber == null ||
               String(this.groupBranchModel.groupBranchNumber) === '') {
      this.Error = { key: 'GroupBranchNumberCannotBeEmpty', fieldName: BranchFields.BranchNumber };
      return false;
    } else if (this.groupBranchModel.groupBranchNumber < 1) {
      this.Error = { key: 'GroupBranchNumberShouldBePositive', fieldName: BranchFields.BranchNumber };
      return false;
    } else if (this.groupBranchModel.groupBranchNumber > BranchEditorComponent.MaxIntegerNumber) {
      this.Error = { key: 'GroupBranchNumberExceedsLimit', fieldName: BranchFields.BranchNumber };
      return false;
    } else {
      return true;
    }
  }

  public hasErrorForField(branchField: BranchFields) {
    return this.Error && this.Error.fieldName === branchField;
  }
}
export enum BranchFields {
  BranchName = 'groupBranchName',
  BranchNumber = 'groupBranchNumber'
}

