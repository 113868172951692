import {Component, Input, OnInit} from '@angular/core';
import {AnimalTimelineHeader} from '../../../../../services/animal-timeline/model/animal-timeline-model';
import { AnimalBadge } from 'src/app/services/animals/model/animal.model';

@Component({
  selector: 'animal-card-timeline-header',
  templateUrl: './animal-card-timeline-header.component.html',
  styleUrls: ['./animal-card-timeline-header.component.scss']
})
export class AnimalCardTimelineHeaderComponent implements OnInit {

  @Input()
  public header: AnimalTimelineHeader;
  
  public AnimalBadge = AnimalBadge;
  
  public constructor() { }

  public ngOnInit() {
  
  }

}
