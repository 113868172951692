import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AnimalCardEventsMenuComponent} from './animal-events-editor/animal-card-events-menu/animal-card-events-menu.component';
import {AddNewbornDialogComponent} from './animal-events-editor/dialogs/add-newborn-dialog/add-newborn-dialog.component';
import {BatchEventErrorsDialogComponent} from './animal-events-editor/dialogs/batch-event-errors-dialog/batch-event-errors-dialog.component';
import {RemoveTagEventFormComponent} from './animal-events-editor/event-form-templates/remove-tag-event-form/remove-tag-event-form.component';
import {AbortionEventFormComponent} from './animal-events-editor/event-form-templates/abortion-event-form/abortion-event-form.component';
import {BreedingEventFormComponent} from './animal-events-editor/event-form-templates/breeding-event-form/breeding-event-form.component';
import {CalvingEventFormComponent} from './animal-events-editor/event-form-templates/calving-event-form/calving-event-form.component';
import {ChangeGroupEventFormComponent} from './animal-events-editor/event-form-templates/change-group-event-form/change-group-event-form.component';
import {WeaningEventFormComponent} from './animal-events-editor/event-form-templates/weaning-event-form/weaning-event-form.component';
import {CullingEventFormComponent} from './animal-events-editor/event-form-templates/culling-event-form/culling-event-form.component';
import {DoNotBreedEventFormComponent} from './animal-events-editor/event-form-templates/do-not-breed-event-form/do-not-breed-event-form.component';
import {DryOffEventFormComponent} from './animal-events-editor/event-form-templates/dry-off-event-form/dry-off-event-form.component';
import {PregnancyCheckEventFormComponent} from './animal-events-editor/event-form-templates/pregnancy-check-event-form/pregnancy-check-event-form.component';
import {UnsortEventFormComponent} from './animal-events-editor/event-form-templates/unsort-event-from/unsort-event-form.component';
import {CancelBatchEventDialogComponent} from './animal-events-editor/dialogs/cancel-batch-event-dialog/cancel-batch-event-dialog.component';
import {AssignTagEventFormComponent} from './animal-events-editor/event-form-templates/assign-tag-event-form/assign-tag-event-form.component';
import {ChangeTagEventFormComponent} from './animal-events-editor/event-form-templates/change-tag-event-form/change-tag-event-form.component';
import {WeightEventFormComponent} from './animal-events-editor/event-form-templates/weight-event-form/weight-event-form.component';
import {CastrationEventFormComponent} from './animal-events-editor/event-form-templates/castration-event-form/castration-event-form.component';
import {StartBackgroundEventFormComponent} from './animal-events-editor/event-form-templates/start-background-event-form/start-background-event-form.component';
import {StartFinishingEventFormComponent} from './animal-events-editor/event-form-templates/start-finishing-event-form/start-finishing-event-form.component';
import {ShSharedModule} from '../../common/sh-shared.module';
import {FormsModule} from '@angular/forms';
import { AbortionSameLactationEventFormComponent } from './animal-events-editor/event-form-templates/abortion-same-lactation-event-form/abortion-same-lactation-event-form.component';
import { GeneralEventFormComponent } from './animal-events-editor/event-form-templates/general-event-form/general-event-form.component';
import {EventFormErrorsComponent} from "./animal-events-editor/event-form-templates/event-form-errors/event-form-errors.component";
import {CalvingEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/calving-event-description/calving-event-description.component';
import {SystemHealthEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/system-health-event-description/system-health-event-description.component';
import {DnbEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/dnb-event-description/dnb-event-description.component';
import {UncertainPregnancyCheckEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/uncertain-pregnancy-check-event-description/uncertain-pregnancy-check-event-description.component';
import {SystemHeatEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/system-heat-event-description/system-heat-event-description.component';
import {BreedingEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/breeding-event-description/breeding-event-description.component';
import {NegativePregnancyCheckEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/negative-pregnancy-check-event-description/negative-pregnancy-check-event-description.component';
import {PositivePregnancyCheckEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/positive-pregnancy-check-event-description/positive-pregnancy-check-event-description.component';
import {AssignScrTagEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/assign-scr-tag-event-description/assign-scr-tag-event-description.component';
import {AssignRfidTagEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/assign-rfid-tag-event-description/assign-rfid-tag-event-description.component';
import {ChangeScrTagEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/change-scr-tag-event-description/change-scr-tag-event-description.component';
import {RemoveScrTagEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/remove-scr-tag-event-description/remove-scr-tag-event-description.component';
import {ChangeRfidTagEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/change-rfid-tag-event-description/change-rfid-tag-event-description.component';
import {RemoveRfidTagEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/remove-rfid-tag-event-description/remove-rfid-tag-event-description.component';
import {CullingEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/culling-event-description/culling-event-description.component';
import {ChangeGroupEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/change-group-event-description/change-group-event-description.component';
import {HerdEntryEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/herd-entry-event-description/herd-entry-event-description.component';
import {DryoffEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/dryoff-event-description/dryoff-event-description.component';
import {AbortionNewLactationEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/abortion-new-lactation-event-description/abortion-new-lactation-event-description.component';
import {AbortionSameLactationEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/abortion-same-lactation-event-description/abortion-same-lactation-event-description.component';
import {DistressEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/distress-event-description/distress-event-description.component';
import {TagSwUpdateEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/tag-sw-update-event-description/tag-sw-update-event-description.component';
import {WeightEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/weight-event-description/weight-event-description.component';
import {UnsortEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/unsort-event-description/unsort-event-description.component';
import {GeneralEventDescriptionComponent} from './animal-events-datagrid/description-rowcell/general-event-description/general-event-description.component';

@NgModule({
  declarations: [AnimalCardEventsMenuComponent,
    AddNewbornDialogComponent,
    BatchEventErrorsDialogComponent,
    RemoveTagEventFormComponent,
    AbortionEventFormComponent,
    BreedingEventFormComponent,
    CalvingEventFormComponent,
    ChangeGroupEventFormComponent,
    WeaningEventFormComponent,
    CullingEventFormComponent,
    DoNotBreedEventFormComponent,
    DryOffEventFormComponent,
    PregnancyCheckEventFormComponent,
    UnsortEventFormComponent,
    CancelBatchEventDialogComponent,
    AssignTagEventFormComponent,
    ChangeTagEventFormComponent,
    WeightEventFormComponent,
    CastrationEventFormComponent,
    StartBackgroundEventFormComponent,
    StartFinishingEventFormComponent,
    CalvingEventDescriptionComponent,
    SystemHealthEventDescriptionComponent,
    DnbEventDescriptionComponent,
    BreedingEventDescriptionComponent,
    SystemHeatEventDescriptionComponent,
    NegativePregnancyCheckEventDescriptionComponent,
    UncertainPregnancyCheckEventDescriptionComponent,
    PositivePregnancyCheckEventDescriptionComponent,
    AssignScrTagEventDescriptionComponent,
    AssignRfidTagEventDescriptionComponent,
    ChangeScrTagEventDescriptionComponent,
    RemoveScrTagEventDescriptionComponent,
    ChangeRfidTagEventDescriptionComponent,
    RemoveRfidTagEventDescriptionComponent,
    CullingEventDescriptionComponent,
    ChangeGroupEventDescriptionComponent,
    HerdEntryEventDescriptionComponent,
    DryoffEventDescriptionComponent,
    AbortionNewLactationEventDescriptionComponent,
    AbortionSameLactationEventDescriptionComponent,
    DistressEventDescriptionComponent,
    TagSwUpdateEventDescriptionComponent,
    WeightEventDescriptionComponent,
    UnsortEventDescriptionComponent,
    AbortionSameLactationEventFormComponent,
    GeneralEventFormComponent,
    GeneralEventDescriptionComponent,
    EventFormErrorsComponent
  ],
  exports: [
    BatchEventErrorsDialogComponent,
    AnimalCardEventsMenuComponent,
    RemoveTagEventFormComponent,
    AbortionEventFormComponent,
    BreedingEventFormComponent,
    CalvingEventFormComponent,
    ChangeGroupEventFormComponent,
    WeaningEventFormComponent,
    CullingEventFormComponent,
    DoNotBreedEventFormComponent,
    DryOffEventFormComponent,
    PregnancyCheckEventFormComponent,
    UnsortEventFormComponent,
    CancelBatchEventDialogComponent,
    AssignTagEventFormComponent,
    ChangeTagEventFormComponent,
    WeightEventFormComponent,
    CastrationEventFormComponent,
    StartBackgroundEventFormComponent,
    StartFinishingEventFormComponent,
    CalvingEventDescriptionComponent,
    SystemHealthEventDescriptionComponent,
    DnbEventDescriptionComponent,
    BreedingEventDescriptionComponent,
    SystemHeatEventDescriptionComponent,
    NegativePregnancyCheckEventDescriptionComponent,
    UncertainPregnancyCheckEventDescriptionComponent,
    PositivePregnancyCheckEventDescriptionComponent,
    AssignScrTagEventDescriptionComponent,
    AssignRfidTagEventDescriptionComponent,
    ChangeScrTagEventDescriptionComponent,
    RemoveScrTagEventDescriptionComponent,
    ChangeRfidTagEventDescriptionComponent,
    RemoveRfidTagEventDescriptionComponent,
    CullingEventDescriptionComponent,
    ChangeGroupEventDescriptionComponent,
    HerdEntryEventDescriptionComponent,
    DryoffEventDescriptionComponent,
    AbortionNewLactationEventDescriptionComponent,
    AbortionSameLactationEventDescriptionComponent,
    DistressEventDescriptionComponent,
    TagSwUpdateEventDescriptionComponent,
    WeightEventDescriptionComponent,
    UnsortEventDescriptionComponent,
    AbortionSameLactationEventFormComponent,
    GeneralEventFormComponent,
    GeneralEventDescriptionComponent,
    EventFormErrorsComponent
  ],
  imports: [
    CommonModule,
    ShSharedModule,
    FormsModule
  ]
})
export class AnimalEventsModule { }
