import {Component, OnDestroy, OnInit} from '@angular/core';
import {IHealthSettings} from '../../../../../../services/manage/model/health-settings';
import {UserPermissionsService} from '../../../../../../services/auth/user-permissions.service';
import * as _ from 'lodash';
import {EditBarService} from '../../../../../../services/edit-bar/edit-bar.service';
import {ManageSettingsService} from '../../../../../../services/manage/manage-settings.service';
import {IResponseBody, IResult} from '../../../../../../services/manage/model/response.model';
import {HttpResponseResult} from '../../../../../../services/rest-api/rest-api.service';

@Component({
  selector: 'manage-settings-health-finishing',
  templateUrl: './manage-settings-health-finishing.component.html',
  styleUrls: ['./manage-settings-health-finishing.component.scss']
})
export class ManageSettingsHealthFinishingComponent implements OnInit, OnDestroy {

  public model: IHealthSettings;

  public readonly editAreaName: string = 'health';

  private masterHealthSettings: IHealthSettings;


  public get canDecreaseHealthIndexThresholdValue() {
    if (!(this.model && this.model.healthIndexThreshold && typeof this.model.healthIndexThreshold.value != 'undefined' && this.model.severeHealthIndexThreshold && typeof this.model.severeHealthIndexThreshold.value != 'undefined')) {
      return false;
    }
    return this.model.healthIndexThreshold.value > this.model.severeHealthIndexThreshold.value;
  }

  public get canIncreaseSevereHealthIndexThresholdValue() {
    if (!(this.model && this.model.healthIndexThreshold && typeof this.model.healthIndexThreshold.value != 'undefined' && this.model.severeHealthIndexThreshold && typeof this.model.severeHealthIndexThreshold.value != 'undefined')) {
      return false;
    }
    return this.model.healthIndexThreshold.value > this.model.severeHealthIndexThreshold.value;
  }

  constructor(private readonly userPermissionsService: UserPermissionsService,
              private readonly manageSettingsService: ManageSettingsService,
              private readonly editBarService: EditBarService) { }

  public async ngOnInit() {
    const finishingHealthSettingsResponse = await this.manageSettingsService.getFinishingHealthSettings();
    if (finishingHealthSettingsResponse.status === 200 && finishingHealthSettingsResponse.responseBody) {
      this.masterHealthSettings = _.cloneDeep(finishingHealthSettingsResponse.responseBody);
      this.model = _.cloneDeep(finishingHealthSettingsResponse.responseBody);
    }
    this.editBarService.registerOnSaveFunction(null);
    this.editBarService.registerOnResetFunction(null);
  }

  public isFieldEditable() {
    return !this.userPermissionsService.hasUserPermissionsForEditManageSettings();
  }

  public updateValue(field: string, value: number) {
    this.model[field].value = value;
    this.dirtyCheck();
    this.clearError();
  }

  private dirtyCheck() {
    const noChangesDetected = _.isEqual(this.masterHealthSettings, this.model);
    this.editBarService.registerOnSaveFunction(noChangesDetected ? null : this.save.bind(this), this.editAreaName);
    this.editBarService.registerOnResetFunction(noChangesDetected ? null : this.reset.bind(this));
  }

  public async save() {
    const response: HttpResponseResult<IResult> = await this.manageSettingsService.sendFinishingHealthSettings(this.model);
    if (response.status === 200) {
      this.editBarService.registerOnResetFunction(null);
      this.masterHealthSettings = _.cloneDeep(this.model);
      this.dirtyCheck();
      this.clearError();
    } else {
      this.manageSettingsService.editBarErrorMessage
        = this.getErrorMessage(response.errorResponseBody);
    }
  }

  public clearError(): void {
    this.manageSettingsService.editBarErrorMessage = null;
  }

  public reset() {
    this.editBarService.resetEditBar();
    this.model = _.cloneDeep(this.masterHealthSettings);
    this.dirtyCheck();
    this.clearError();
  }

  public revertHealthIndexThreshold() {
    this.model.healthIndexThreshold.value = _.cloneDeep(this.model.healthIndexThreshold.defaultValue);
    this.dirtyCheck();
    this.clearError();
  }

  public revertSevereHealthIndexThreshold() {
    this.model.severeHealthIndexThreshold.value = _.cloneDeep(this.model.severeHealthIndexThreshold.defaultValue);
    this.dirtyCheck();
    this.clearError();
  }

  public ngOnDestroy() {
    this.reset();
  }

  private getErrorMessage(error: IResponseBody<IResult>) {
    if (error.result && error.result.key) {
      return 'MANAGE.SETTINGS.ERRORS.' + error.result.key;
    } else {
      return 'MANAGE.SETTINGS.ERRORS.UnknownError';
    }
  }
}
