import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export enum ConfirmDialogThemes {
  Blue = 'blue',
  Green = 'green',
  Purple = 'purple',
  Brown = 'brown'
}

export enum ConfirmDialogIcons {
  attentionIcon = 'attention-icon',
  breedingIcon = 'breeding-icon',
  finishingIcon = 'finishing-icon',
  dairyIcon = 'dairy-icon',
  farmerIcon = 'farmer-icon'
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  @Input()
  public isOpen: boolean;

  @Input()
  public headerText: string;

  @Input()
  public primaryText : string;

  @Input()
  public secondaryText : string;

  @Input()
  public leftButtonText : string;

  @Input()
  public rightButtonText : string;

  @Input()
  public containerShId : string;

  @Input()
  public leftButtonShId : string;

  @Input()
  public rightButtonShId : string;

  @Input()
  public colorTheme: ConfirmDialogThemes = ConfirmDialogThemes.Blue;

  @Input()
  public modalHeight: number = 337;

  @Input()
  public iconClass: ConfirmDialogIcons = ConfirmDialogIcons.attentionIcon;

  @Output()
  public onLeftButtonClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onRightButtonClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onCloseClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  public ngOnInit() {
  }
}
