<div class="branches-header" [attr.sh-id]="'branches-header'">
  <branch-editor #branchEditor
                 [ngClass]="{'branch-editor-open': branchEditor.isOpen}"
                 (onSavedBranchChanges)="reloadBranchesAfterChange($event)" (onToggleAddBranch)="windowResizeMade()"></branch-editor>
  <grid-summary *ngIf="branchesReport && !branchEditor.isOpen" [summaryTitle]="'MANAGE.TITLE.BRANCHES'"
                [icon]="GridSummaryIcon.Farm"
                [rowCount]="branchesReport.meta.rowTotalAfterFilter"
                [rowTotal]="branchesReport.meta.rowTotal"></grid-summary>
  <report-actions-dropdown *ngIf="branchesReport && !branchEditor.isOpen"
                           [attr.sh-id]="'actions-dropdown-popup-wrapper'"
                           [reportActions]="branchesReport?.meta.reportActions"
                           [isActionsOnLeftSide]="false"
                           [reportQuery]="branchesReportGridQuery"
                           [reportContext]="this"
                           [report]="branchesReport"></report-actions-dropdown>
</div>
<report-grid #branchesSortGrid
            [rowHeight]="40"
            [report]="branchesReport"
            [reportQuery]="branchesReportGridQuery"
            [reportContext]="this"
            saveDataWarningInject
             (onReportGridValueCellClick)="onReportGridValueCellClick($event)"
            (onReportQueryChanged)="getBranches()"></report-grid>
