<regular-edit-dialog-template *ngIf="model"
                              [modalWidth]="368"
                              [isOpen]="isOpen"
                              [enableRemoveButton]="false"
                              [rightButtonDisabled]="isApplyDisabled || isApplying"
                              (onCloseClick)="closeClick()"
                              (onLeftButtonClick)="closeClick()"
                              (onEnterButtonClick)="applyClick()"
                              (onRightButtonClick)="applyClick()">
  <ng-container header-caption>
    {{'Change Group' | translate}}
  </ng-container>
  <ng-container body>
    <div class="form-group">
      <div class="form-group-label">{{'Destination Group' | translate}}</div>
      <div class="select-groups">
        <regular-dropdown-list #groupSelectionDropdown
                               [items]="groupDropdownListItems"
                               [selectedValue]="selectedGroup"
                               (onItemSelected)="onGroupSelected($event)"></regular-dropdown-list>
      </div>
      <div class="error" *ngIf="error">
        <b>{{ "SYSTEM.MANAGEMENT.ERROR_BOLD" | translate }}:</b> {{ "DIALOG.ERRORS." + error | translate }}
      </div>
    </div>
  </ng-container>
  <ng-container left-button>
    <span>{{ 'COMMON.BUTTON.CANCEL' | translate}}</span>
  </ng-container>
  <ng-container right-button>
    <span>{{ 'COMMON.BUTTON.APPLY' | translate}}</span>
  </ng-container>
</regular-edit-dialog-template>
