export interface IChartPoint {
  // tslint:disable-next-line:no-any
  x: any
}

export interface IChartEventPoint extends IChartPoint {
  name: string,
  tooltip: string
}

export enum ChartResolution {
  Hour= 'hour',
  Day= 'day'
}
