import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {DropdownListItem} from '../../../../../../common/components/dropdown-list/dropdown-list-model';
import {BullListItem} from '../../../../../../common/components/bull-choose-dropdown-list/bull-choose-dropdown-list.component';
import {
  AddAnimalFieldsErrorsEnum,
  IAddFemaleRequest,
  IErrors,
  IFemalesMetadata,
  IGroupItem,
  ISCRTagItem
} from '../../../../../../services/animals/model/animal.model';
import {AnimalsService} from '../../../../../../services/animals/animals.service';
import * as moment from 'moment';
import {InputFilterMode} from '../../../../../../common/directives/input-filter.directive';
import {ConfigService} from '../../../../../../services/config/config.service';
import {FarmMode} from '../../../../../../services/config/model/server-config';
import {DateTimeFormatEnum} from 'src/app/common/pipes/epoch-date-time.pipe';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../../../../common/components/calendar/model/epoch-state-model';
import {LoadingIconService} from '../../../../../../services/loading-icon/loading-icon.service';
import {CornerColor, CornerContainerBodyBackground} from 'src/app/common/components/containers/corner-container/corner-container.component';

export enum AddFemaleSteps {
  BasicDetails = 1,
  Calving = 2,
  Breeding = 3,
  PregnancyCheck = 4,
  DryOff = 5
}

export class AddFemaleModel {
  public animalNumber: string;

  public group: IGroupItem;

  public tagNumber: ISCRTagItem;

  public registrationNumber: string;

  public EID: ISCRTagItem;

  public lactationNumber: number;

  public lastCalvingDate: EpochStateModel = new EpochStateModel(EpochStateModelMinMaxMode.Date);

  public birthdate: EpochStateModel = new EpochStateModel(EpochStateModelMinMaxMode.Date);

  public breedingDate: EpochStateModel = new EpochStateModel(EpochStateModelMinMaxMode.Date);

  public breedingNumber: number;

  public bull: BullListItem;

  public pregnancyCheckDate: EpochStateModel = new EpochStateModel(EpochStateModelMinMaxMode.Date);

  public dryOffDate: EpochStateModel = new EpochStateModel(EpochStateModelMinMaxMode.Date);

  constructor() {
    this.updateDatesRanges();
  }

  public updateDatesRanges() {
    let maxDate = moment().unix();
    if (moment.unix(this.birthdate.epochDate).diff(maxDate) == 0) {
      this.lactationNumber = 0;
    }
    this.birthdate.max = this.lastCalvingDate.epoch ? this.lastCalvingDate.epochDate : maxDate;

    this.lastCalvingDate.max = maxDate;
    this.lastCalvingDate.min = this.birthdate.momentDate ? this.birthdate.momentDate.add(1, 'day').unix() : null;

    this.breedingDate.max = maxDate;
    let breedingMinDate = this.lastCalvingDate.epoch ? this.lastCalvingDate.epochDate : this.birthdate.epochDate;
    breedingMinDate = moment.unix(breedingMinDate).add(1, 'day').unix();
    this.breedingDate.min = breedingMinDate;

    this.pregnancyCheckDate.max = maxDate;
    this.pregnancyCheckDate.min = this.breedingDate.momentDate ? this.breedingDate.momentDate.add(1, 'day').unix(): null;

    this.dryOffDate.max = maxDate;
    this.dryOffDate.min = this.pregnancyCheckDate.epoch ? this.pregnancyCheckDate.momentDate.add(1, 'day').unix(): null;
  }
}

@Component({
  selector: 'add-female-breeding',
  templateUrl: './add-female-breeding.component.html',
  styleUrls: ['./add-female-breeding.component.scss']
})
export class AddFemaleBreedingComponent implements OnInit, OnDestroy {

  public step: AddFemaleSteps = AddFemaleSteps.BasicDetails;

  public addFemaleSteps = AddFemaleSteps;

  public addFemaleModel: AddFemaleModel = new AddFemaleModel();

  public errors: IErrors[] = [];

  public maxDate: number = moment().unix();

  public farmMode: FarmMode;

  public groupsDropdownListItems: DropdownListItem[] = [];

  public femalesMetadata: IFemalesMetadata;

  public InputFilterMode = InputFilterMode;

  public maxStepAvailable: AddFemaleSteps = AddFemaleSteps.BasicDetails;

  public DateTimeFormatEnum = DateTimeFormatEnum;

  constructor(public readonly animalsService: AnimalsService,
              private readonly loadingIconService: LoadingIconService,
              private configService: ConfigService,
              public elementRef: ElementRef) {
  }

  public async ngOnInit() {
    this.farmMode = this.configService.serverConfig.farmMode;
    await this.getFemalesMetadata();
  }

  private async getFemalesMetadata() {
    let response = await this.animalsService.getFemalesMetadata();
    this.femalesMetadata = response.responseBody;
    if (this.femalesMetadata.groupList != null) {
      this.groupsDropdownListItems = this.femalesMetadata.groupList.map((groupItem: IGroupItem) => ({
        value: groupItem,
        displayValue: groupItem.name,
        disabled: false,
        icon: null
      }));
    }
  }

  public ngOnDestroy(): void {
    this.animalsService.isAddAnimalOpen = false;
  }

  public async addFemale() {
    this.animalsService.isAddAnimalButtonShown = false;
    if (this.validateStep(this.step)) {
      const requestToSendToServer: IAddFemaleRequest = {
        orn: this.addFemaleModel.registrationNumber,
        rfidTag: this.addFemaleModel.EID,
        scrTag: this.addFemaleModel.tagNumber,
        animalName: this.addFemaleModel.animalNumber,
        birthDate: this.addFemaleModel.birthdate.epochDate,
        breedingDate: this.addFemaleModel.breedingDate.epochDate ? moment.unix(this.addFemaleModel.breedingDate.epochDate).utc(true).unix() : null,
        breedingNumber: this.addFemaleModel.breedingNumber,
        breedingSire: this.addFemaleModel.bull,
        calvingDate: this.addFemaleModel.lastCalvingDate.epoch,
        dryOffDate: this.addFemaleModel.dryOffDate.epoch,
        group: this.addFemaleModel.group,
        lactation: this.addFemaleModel.lactationNumber,
        pregnancyCheckDate: this.addFemaleModel.pregnancyCheckDate.epoch
      };
      this.loadingIconService.show();
      let response = await this.animalsService.addFemale(requestToSendToServer);
      if(response.status === 201) {
        this.addFemaleModel = new AddFemaleModel();
        this.goToStep(AddFemaleSteps.BasicDetails);
        this.maxStepAvailable = AddFemaleSteps.BasicDetails;
        await this.getFemalesMetadata();
        this.animalsService.onAnimalsListChanged.next();
        this.loadingIconService.hide();
      } else {
        this.navigateToStepAccordingToServerError(response);
        setTimeout(() => {
          this.errors[0] = response.errorResponseBody.result.failures[0];
        }, 0);
        this.loadingIconService.hide();
      }
    }
    this.animalsService.isAddAnimalButtonShown = true;
  }

  public onGroupSelected(groupItem: IGroupItem) {
    this.addFemaleModel.group = groupItem;
    this.clearErrors();
  }

  public goToStep(step: AddFemaleSteps): void {
    // if going back to previous step
    if (this.step > step) {
      this.step = step;
    } else {
      // if going to the next step - do validation
      if (this.validateStep(this.step)) {
        if (step === AddFemaleSteps.DryOff && this.step === AddFemaleSteps.PregnancyCheck && this.addFemaleModel.lactationNumber === 0) {
          return;
        } else {
          if (step > this.maxStepAvailable) {
            this.maxStepAvailable = step;
          }
          this.step = step;
        }
      }
    }

  }

  public validateStep(step: AddFemaleSteps): boolean {
    switch (step) {
      case AddFemaleSteps.BasicDetails: {
        if (!this.addFemaleModel.animalNumber) {
          this.errors[0] = { key: 'ANIMAL_ID_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.animalName };
          return false;
        }
        if (!this.addFemaleModel.group) {
          this.errors[0] = { key: 'GROUP_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.group };
          return false;
        }
        return true;
      }
      case AddFemaleSteps.Calving: {
        if (!this.addFemaleModel.group) {
          this.errors[0] = {key: 'GROUP_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.group };
          return false;
        }
        if (this.addFemaleModel.lactationNumber == null) {
          this.errors[0] = {key: 'LactationNumberNotValid', fieldName: AddAnimalFieldsErrorsEnum.lactation };
          return false;
        }
        if (this.addFemaleModel.lactationNumber > 0) {
          if (!this.addFemaleModel.lastCalvingDate.epoch) {
            this.errors[0] = {key: 'CalvingDateIsMandatory', fieldName: AddAnimalFieldsErrorsEnum.calvingDate };
            return false;
          }
        }
        if (this.addFemaleModel.lactationNumber === 0) {
          if (!this.addFemaleModel.birthdate.epochDate) {
            this.errors[0] = {key: 'BirthDateIsMandatory', fieldName: AddAnimalFieldsErrorsEnum.birthDate };
            return false;
          }
        }
        return true;
      }
      case AddFemaleSteps.Breeding: {
        // validate group
        if (!this.addFemaleModel.group) {
          this.errors[0] = {key: 'GROUP_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.group };
          return false;
        }
        // validate if breeding date is defined
        if (!this.addFemaleModel.breedingDate.epochDate) {
          this.errors[0] = {key: 'BREEDING_DATE_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.breedingDate };
          return false;
        }
        // if breeding number > 0
        else if (!this.addFemaleModel.breedingNumber) {
          this.errors[0] = {key: 'BREEDING_NUMBER_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.breedingNumber };
          return false;
        }
        return true;
      }
      case AddFemaleSteps.PregnancyCheck: {
        // validate group
        if (!this.addFemaleModel.group) {
          this.errors[0] = {key: 'GROUP_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.group };
          return false;
        }
        if (!this.addFemaleModel.pregnancyCheckDate.epoch) {
          this.errors[0] = {key: 'PREGNANCY_CHECK_DATE_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.pregnancyCheckDate };
          return false;
        }
        return true;
      }
      case AddFemaleSteps.DryOff: {
        if (!this.addFemaleModel.group) {
          this.errors[0] = {key: 'GROUP_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.group };
          return false;
        }
        if (!this.addFemaleModel.dryOffDate.epoch) {
          this.errors[0] = {key: 'DRY_OFF_DATE_REQUIRED', fieldName: AddAnimalFieldsErrorsEnum.dryOffDate };
          return false;
        }
        return true;
      }
    }
  }

  // jump to step to show error in relevant section and field
  // tslint:disable-next-line:no-any
  public navigateToStepAccordingToServerError(serverResponse: any) {
    if (serverResponse.errorResponseBody.result.failures && serverResponse.errorResponseBody.result.failures.length > 0) {
      switch (serverResponse.errorResponseBody.result.failures[0].fieldName) {
        case AddAnimalFieldsErrorsEnum.rfIdTagNumber:
        case AddAnimalFieldsErrorsEnum.scrTagNumber:
        case AddAnimalFieldsErrorsEnum.animalName: {
          this.step = AddFemaleSteps.BasicDetails;
          break;
        }
        case AddAnimalFieldsErrorsEnum.birthDate:
        case AddAnimalFieldsErrorsEnum.calvingDate:
        case AddAnimalFieldsErrorsEnum.lactation: {
          this.step = AddFemaleSteps.Calving;
          break;
        }
        case AddAnimalFieldsErrorsEnum.breedingDate:
        case AddAnimalFieldsErrorsEnum.breedingNumber: {
          this.step = AddFemaleSteps.Breeding;
          break;
        }
        case AddAnimalFieldsErrorsEnum.pregnancyCheckDate: {
          this.step = AddFemaleSteps.PregnancyCheck;
          break;
        }
        case AddAnimalFieldsErrorsEnum.dryOffDate: {
          this.step = AddFemaleSteps.DryOff;
        }
      }
    }
  }

  public clearErrors() {
    this.errors = [];
  }

  public navigateToStepIfPossible(step: AddFemaleSteps) {
    if (this.step > step) {
      this.step = step;
    }
  }

  public isDryOffShouldBeShown() {
    return this.addFemaleModel.lactationNumber > 0 && this.farmMode != FarmMode.Beef;
  }

  public isNextButtonShouldBeShown() {
    if (this.step !== this.addFemaleSteps.BasicDetails) {
      if (this.addFemaleModel.lactationNumber > 0 && this.farmMode != FarmMode.Beef) {
        return this.step !== this.addFemaleSteps.DryOff;
      } else {
        return this.step !== this.addFemaleSteps.PregnancyCheck;
      }
    } else {
      return false;
    }
  }

  public isStepLessThanMaxStep(step: AddFemaleSteps) : boolean {
    return this.step < step && step <= this.maxStepAvailable;
  }

  public markAsValidatedStepGraph(step: AddFemaleSteps) {
    return step <= this.maxStepAvailable;
  }

  public get cornerColor(): CornerColor {
    switch (this.step) {
      case AddFemaleSteps.BasicDetails: return CornerColor.Blue;
      case AddFemaleSteps.Calving: return CornerColor.Pink;
      case AddFemaleSteps.Breeding: return CornerColor.Green;
      case AddFemaleSteps.PregnancyCheck: return CornerColor.LightBlue;
      case AddFemaleSteps.DryOff: return CornerColor.Brown;
      default: return CornerColor.Blue;
    }
  }

  public get cornerContainerBodyBackground(): CornerContainerBodyBackground {
    switch (this.step) {
      case AddFemaleSteps.BasicDetails: return CornerContainerBodyBackground.Blue;
      case AddFemaleSteps.Calving: return CornerContainerBodyBackground.LightPink;
      case AddFemaleSteps.Breeding: return CornerContainerBodyBackground.LightGreen;
      case AddFemaleSteps.PregnancyCheck: return CornerContainerBodyBackground.LightBlue;
      case AddFemaleSteps.DryOff: return CornerContainerBodyBackground.LightBrown;
      default: return CornerContainerBodyBackground.Blue;
    }
  }
}
