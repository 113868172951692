import {Directive, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {SaveDataViewStateService} from '../../../services/ui/view-state/save-data-view-state.service';

@Directive({
  selector: '[saveDataEditSection]'
})
export class SaveDataEditSectionDirective implements OnInit, OnDestroy {

  @Input()
  public editAreaName : string;

  private isAlive:boolean;

  public ngOnInit(): void {
    this.isAlive = true;
  }

  public ngOnDestroy(): void {
    this.isAlive = false;
  }

  constructor(private editorAreaElement: ElementRef,
              private saveDataViewStateService:SaveDataViewStateService) {
    document.addEventListener('click', (event) => {

      if(!this.isAlive) {
        return;
      }

      // tslint:disable-next-line:no-any
      let target : any = event.target;

      //validate if calendar component
      if(target.className.toString().startsWith('owl-') ||
         target.className.toString().startsWith('cdk-overlay') ||
        (target.parentElement != null &&
        (target.parentElement.className.toString().startsWith('owl-') ||
         target.parentElement.className.toString().startsWith('cdk-overlay')))){
        return;
      }
      if (target.parentElement != null &&
          this.saveDataViewStateService.activeEditArea == this.editAreaName &&
          !this.saveDataViewStateService.isWarningModalPresent &&
          (this.saveDataViewStateService.hasWarningInjectElement(target) ||
          (!this.saveDataViewStateService.hasWarningIgnoreElement(target) &&
           !this.editorAreaElement.nativeElement.contains(target)))) {
        event.preventDefault();
        event.stopPropagation();
        this.saveDataViewStateService.showSaveDataWarningModal(target,null);
      }

    }, true);
  }
}
