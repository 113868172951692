<div class="trial-mode-selection-wrapper">
  <div class="trial-mode-content">
    <div class="title-text" [attr.translation-id]="'REGISTRATION.FLOW.TRIAL_MODE'">{{ 'REGISTRATION.FLOW.TRIAL_MODE' | translate }}</div>
    <div class="sub-title-text" [attr.translation-id]="'REGISTRATION.FLOW.SELECT_YOUR_OPERATION_TYPE'">{{ 'REGISTRATION.FLOW.SELECT_YOUR_OPERATION_TYPE' | translate }}</div>
    <div class="trial-mode-selection-buttons-div">
      <div class="trial-mode-toggle breeding" [ngClass]="{ selected: selectedOperationType === TrialOperation.breeding }" (click)="selectedOperationType = TrialOperation.breeding">
        <div class="breeding-icon"></div>
        <div class="breeding-text" [attr.translation-id]="'REGISTRATION.FLOW.SENSE_TIME'">{{ 'REGISTRATION.FLOW.SENSE_TIME' | translate }}</div>
        <div class="breeding-text" [attr.translation-id]="'ANIMAL.EVENTS.EVENT_TYPE.breeding'"><b>{{ 'ANIMAL.EVENTS.EVENT_TYPE.breeding' | translate }}</b></div>
      </div>
      <div class="trial-mode-toggle finishing" [ngClass]="{ selected: selectedOperationType === TrialOperation.finishing }" (click)="selectedOperationType = TrialOperation.finishing">
        <div class="finishing-icon"></div>
        <div class="finishing-text" [attr.translation-id]="'REGISTRATION.FLOW.SENSE_TIME'">{{ 'REGISTRATION.FLOW.SENSE_TIME' | translate }}</div>
        <div class="finishing-text" [attr.translation-id]="'NAVIGATION.FINISHING'"><b>{{ 'NAVIGATION.FINISHING' | translate }}</b></div>
      </div>
    </div>
  </div>
  <div class="buttons-div">
    <button class="button-class back" [attr.translation-id]="'REGISTRATION.FLOW.BACK'" (click)="navigateToFarmModeSelection()">{{ 'REGISTRATION.FLOW.BACK' | translate }}</button>
    <button class="button-class apply" [attr.translation-id]="'COMMON.BUTTON.APPLY'" [disabled]="!selectedOperationType" (click)="apply()">{{ 'COMMON.BUTTON.APPLY' | translate }}</button>
  </div>
</div>
<confirm-trial-farm-mode-dialog #confirmTrialFarmModeDialog
                                [farmMode]="FarmMode.Beef"
                                [operationType]="selectedOperationType"
                                (onCloseClicked)="closeDialog()"
                                (onGoToDashboardClicked)="goToDashboard()"></confirm-trial-farm-mode-dialog>
