import {Component, Input, OnInit} from '@angular/core';
import {FarmStatisticsBeefWidget} from '../../../../../../../services/dashboard/model/farm-statistics-beef';

@Component({
  selector: 'farm-statistics-beef-header',
  templateUrl: './farm-statistics-beef-header.component.html',
  styleUrls: ['./farm-statistics-beef-header.component.scss']
})
export class FarmStatisticsBeefHeaderComponent implements OnInit {

  @Input()
  public farmStatistics: FarmStatisticsBeefWidget;
  
  constructor() { }

  public ngOnInit() {
  
  }
  
  public get totalsCaption() : string {
    return (this.farmStatistics.calves.totalCalves.male +
            this.farmStatistics.calves.totalCalves.female +
            this.farmStatistics.females.totalFemales.total).toString();
  }
}
