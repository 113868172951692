import {Component, OnInit} from '@angular/core';
import {ConfigService} from '../../../services/config/config.service';
import {DashboardService, IDashboardTagsResponse} from '../../../services/dashboard/dashboard.service';
import {HttpResponseResult} from '../../../services/rest-api/rest-api.service';
import {FarmMode} from '../../../services/config/model/server-config';

export interface ISummaryPieChartData {
  value: number | null;
  title: string;
  color: string | null;
}

@Component({
  selector: 'summary-pie-chart',
  templateUrl: './summary-pie-chart.component.html',
  styleUrls: ['./summary-pie-chart.component.scss']
})
export class SummaryPieChartComponent implements OnInit {

  public tagsChart: IDashboardTagsResponse;
  public chartData: ISummaryPieChartData[] = [ { value: null, title: '', color: null }, { value: null, title: '', color: null }];
  public farmMode: FarmMode;

  public legendSettings: Object = {
    visible: false
  };
  public readonly innerRadius: string = '85%';

  constructor(public configService: ConfigService,
              private dashboardService: DashboardService) {
  }

  public ngOnInit() {
    this.farmMode = this.configService.serverConfig.farmMode;
    this.dashboardService.getTags().then((response: HttpResponseResult<IDashboardTagsResponse>) => {
      this.tagsChart = response.responseBody;
      this.chartData = [{
        value: this.tagsChart && this.tagsChart.withTags || 0,
        title: 'with tags',
        color: this.getChartDataColorWithTags()
      }, {
        value: this.tagsChart && this.tagsChart.withoutTags || 0,
        title: 'without tags',
        color: this.getChartDataColorWithotTags()
      }];
    });
  }

  private getChartDataColorWithTags() {
    return this.farmMode === 'Beef' ? 'rgba(195,192,222,0.9)' : 'rgba(86,195,184,0.9)';
  }

  private getChartDataColorWithotTags() {
    return this.farmMode === 'Beef' ? 'rgba(110,110,160,0.9)' : '#168C84';
  }
}
