<div class="basic-details-tab">
  <div class="double-field-div">
    <div class="input-field">
      <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER'">
        {{ 'MANAGE.COWS.ACTIVE.WIZARD.COW_NUMBER' | translate }}
      </div>
      <div class="value">
        <input class="input-class"
               (ngModelChange)="onErrorsClear.emit()"
               [ngClass]="{ error: animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.animalName)}"
               type="text"
               [(ngModel)]="addFemaleModel.animalNumber"
               [attr.sh-id]="'breeding_add_female_animal_number_input_basic-details_tab'"
               [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER'"
               inputFilter
               [inputFilterMaxLength]="16"
               placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.ENTER_COW_NUMBER' | translate }}" />
      </div>
    </div>
    <div class="input-field">
      <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'">
        {{ 'MANAGE.COWS.ACTIVE.WIZARD.GROUP' | translate }}
      </div>
      <div class="value">
        <forms-dropdown-list class="dropdown-list"
                             [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.group)"
                             [shIdPostfix]="'breeding_add_female_group_basic_details_tab'"
                             [items]="groupsDropdownListItems"
                             [autoSelectionMode]="dropdownListAutoSelectionMode.Single"
                             [placeholder]="'MANAGE.COWS.ACTIVE.WIZARD.GROUP'"
                             [allowClear]="true"
                             [translateListItem]="false"
                             [iconTheme]="formsDLIconsThemes.Blue"
                             [ngModel]="addFemaleModel.group"
                             (ngModelChange)="onGroupSelected.emit($event)"></forms-dropdown-list>
      </div>
    </div>
  </div>
  <div class="seperator"></div>
  <div class="fields-and-errors-div">
    <div class="single-field-div">
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.SCR_TAG'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.SCR_TAG' | translate }}
        </div>
        <div class="value">
          <regular-auto-complete [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.scrTagNumber)"
                                 [attr.sh-id]="'breeding_add_female_scr_tag_number_basic_details_tab'"
                                 [items]="scrTagsNumbers"
                                 [searchQuery]="addFemaleModel?.tagNumber?.tagNumber"
                                 [maxResults]="6"
                                 [hasPermanentRemoveIcon]="true"
                                 [colorScheme]="AutoCompleteColorScheme.WhiteBlack"
                                 (searchQueryChanged)="onScrTagChanged($event)"
                                 [placeholder]="'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL'"
                                 [shIdPostfix]="'breeding_add_female_scr_tag_number_basic_details_tab_auto_complete'"></regular-auto-complete>
        </div>
      </div>
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.RF_ID'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.RF_ID' | translate }}
        </div>
        <div class="value">
          <regular-auto-complete [isFieldError]="animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.rfIdTagNumber)"
                                 [items]="rfidsTagsNumbers"
                                 [attr.sh-id]="'breeding_add_female_rf_id_basic_details_tab'"
                                 [searchQuery]="addFemaleModel?.EID?.tagNumber"
                                 [maxResults]="6"
                                 [hasPermanentRemoveIcon]="true"
                                 [colorScheme]="AutoCompleteColorScheme.WhiteBlack"
                                 (searchQueryChanged)="onRfIdTagChanged($event)"
                                 [placeholder]="'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL'"
                                 [shIdPostfix]="'rfid'"></regular-auto-complete>
        </div>
      </div>
      <div class="input-field">
        <div class="title" [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.ORN'">
          {{ 'MANAGE.COWS.ACTIVE.WIZARD.ORN' | translate }}
        </div>
        <div class="value with-icon">
          <input class="input-class"
                 #registrationNumberInput
                 [ngClass]="{ error: animalsService.hasErrorForField(errors, AddAnimalFieldsErrorsEnum.orn) }"
                 (ngModelChange)="onErrorsClear.emit()"
                 type="text"
                 [attr.sh-id]="'breeding_add_female_orn_basic_details_tab'"
                 [(ngModel)]="addFemaleModel.registrationNumber"
                 [attr.translation-id]="'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL'"
                 placeholder="{{ 'MANAGE.COWS.ACTIVE.WIZARD.OPTIONAL' | translate }}" />
          <div class="remove-btn-wrapper">
            <div class="remove-icon" (click)="onClearRegistrationNumber()"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
