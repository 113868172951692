<div class="popup-template"
     outsideClickHandler
     (onOutsideClick)="onOutsideClick()"
     [ngClass]="{'flex-end': popupContentHorizontalAlignment == 'end'}">
  <div #header class="header" [ngClass]="{'table': popupContentHorizontalAlignment == 'end'}">
    <ng-content select="[header]"></ng-content>
  </div>
  <div #popupContent
       class="popup-content"
       [ngStyle]="popupContentStyles"
       *ngIf="isOpen">
    <ng-content select="[popup-content]"></ng-content>
  </div>
</div>
