<group-card-animals-summary *ngIf="groupGraphDetails" [groupGraphDetails]="groupGraphDetails"></group-card-animals-summary>
<div class="graphs-activity-header">
  <ng-container>
    <ng-container *ngIf="chartViewMode === GroupActivityRuminationMode.Activity">
      <div class="legend-series">
        <span class="icon-activity"></span>
        <span class="legend-title">{{'ANIMAL.GRAPH.SERIES_TITLE.DAILY_RUMINATION' | translate}}</span>
        <span class="legend-title-value series-activity" style="width: auto; min-width: 20px" *ngIf="selectedChartPoint">{{selectedChartPoint.currentActivity | round}}</span>
        <span class="legend-title" *ngIf="selectedChartPoint">(Average {{selectedChartPoint.averageActivity | round}})</span>
      </div>
      <div class="legend-series">
        <span class="icon-boundary"></span>
        <span class="legend-title">{{'ANIMAL.GRAPH.SERIES_TITLE.DEVIATION' | translate}}</span>
        <span class="legend-title-value series-deviation" style="width: 90px;" *ngIf="selectedChartPoint">{{selectedChartPoint.stdActivityLower | round}} - {{selectedChartPoint.stdActivityUpper | round}}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="chartViewMode === GroupActivityRuminationMode.Rumination">
      <div class="legend-series">
        <span class="icon-rumination"></span>
        <span class="legend-title">{{'ANIMAL.GRAPH.SERIES_TITLE.CURRENT_RUMINATION' | translate}}</span>
        <span class="legend-title-value series-rumination" style="width: auto; min-width: 20px" *ngIf="selectedChartPoint">{{selectedChartPoint.currentRumination | round}}</span>
        <span class="legend-title" *ngIf="selectedChartPoint">(Average {{selectedChartPoint.averageRumination | round}})</span>
      </div>
      <div class="legend-series">
        <span class="icon-boundary"></span>
        <span class="legend-title">{{'ANIMAL.GRAPH.SERIES_TITLE.DEVIATION' | translate}}</span>
        <span class="legend-title-value series-deviation" style="width: 90px;" *ngIf="selectedChartPoint">{{selectedChartPoint.stdRuminationLower | round}} - {{selectedChartPoint.stdRuminationUpper | round}}</span>
      </div>
    </ng-container>
  </ng-container>
  <ul class="nav">
    <li class="nav-item" [ngClass]="{'active':chartViewMode === GroupActivityRuminationMode.Activity}">
      <span (click)="changeViewMode(GroupActivityRuminationMode.Activity)" [attr.translation-id]="'GROUP.GRAPH.TITLE.ACTIVITY'">{{'GROUP.GRAPH.TITLE.ACTIVITY' | translate}}</span>
    </li>
    <li class="nav-item">
      <div class="icon-switch"></div>
    </li>
    <li class="nav-item" [ngClass]="{'active':chartViewMode === GroupActivityRuminationMode.Rumination}">
      <span (click)="changeViewMode(GroupActivityRuminationMode.Rumination)" [attr.translation-id]="'GROUP.GRAPH.TITLE.RUMINATION'">{{'GROUP.GRAPH.TITLE.RUMINATION' | translate}}</span>
    </li>
  </ul>
</div>
<xy-chart #groupActivityRuminationChart
          [dateTimeFormat]="dateTimeFormat"
          [timeIsUtc]="true"
          (durationSelected)="onDurationSelected($event)"
          (chartPointMouseOver)="onChartPointMouseOver($event)"></xy-chart>
