<animal-card-timeline *ngIf="animalTimelineFlowModel" [animalTimelineFlowModel]="animalTimelineFlowModel" saveDataWarningIgnore></animal-card-timeline>
<div class="animal-tabs" saveDataWarningIgnore *ngIf="viewState.animalInfo != null">
  <ul class="animal-card-nav" saveDataWarningIgnore>
    <li class="tab" [ngClass]="{active:viewState.selectedTab === AnimalCardTabSection.Events}" sh-id="cow_card_navigation_events">
      <a (click)="selectTab(AnimalCardTabSection.Events)" translation-id="ANIMAL.MENU.EVENTS" saveDataWarningInject>{{'ANIMAL.MENU.EVENTS' | translate}}</a>
    </li>
    <li class="tab sub-menu" *ngIf="(viewState.animalInfo.sex == AnimalSex.Female) || (viewState.animalInfo.plan !== 'Starter' && viewState.animalInfo.plan !== 'None')">
      <a>{{'ANIMAL.MENU.GRAPH' | translate}}</a>
      <ul class="sub-menu">
        <li class="tab" *ngIf="isHeatGraphSectionVisible"
                        [ngClass]="{active:viewState.selectedTab === AnimalCardTabSection.GraphsHeat}"
                        sh-id="cow_card_navigation_heat">
          <a (click)="selectTab(AnimalCardTabSection.GraphsHeat)" translation-id="ANIMAL.MENU.HEAT" saveDataWarningInject>{{'ANIMAL.MENU.HEAT' | translate}}</a></li>
        <li *ngIf="isHealthGraphSectionVisible"
            class="tab"
            [ngClass]="{active:viewState.selectedTab === AnimalCardTabSection.GraphsHealth}"
            sh-id="cow_card_navigation_health">
          <a (click)="selectTab(AnimalCardTabSection.GraphsHealth)" translation-id="ANIMAL.MENU.HEALTH" saveDataWarningInject>{{'ANIMAL.MENU.HEALTH' | translate}}</a>
        </li>
      </ul>
    </li>
    <li class="tab"
        [ngClass]="{active:viewState.selectedTab === AnimalCardTabSection.Details}"
        sh-id="cow_card_navigation_details">
      <a (click)="selectTab(AnimalCardTabSection.Details)" translation-id="ANIMAL.MENU.DETAILS" saveDataWarningInject>{{'ANIMAL.MENU.DETAILS' | translate}}</a>
    </li>
  </ul>
  <div class="animal-tab-content" saveDataWarningIgnore>
    <animal-card-events *ngIf="viewState.selectedTab == AnimalCardTabSection.Events"
                        [viewState]="viewState"
                        [operationType]="operationType"
                        [animalId]="viewState.animalInfo.animalId"></animal-card-events>
    <animal-card-heat *ngIf="viewState.selectedTab == AnimalCardTabSection.GraphsHeat"
                      [viewState]="viewState"
                      [animalId]="viewState.animalInfo.animalId"></animal-card-heat>
    <animal-card-health *ngIf="viewState.selectedTab == AnimalCardTabSection.GraphsHealth"
                        [viewState]="viewState"
                        [operationType]="operationType"
                        [animalId]="viewState.animalInfo.animalId"></animal-card-health>
    <animal-card-details *ngIf="viewState.selectedTab == AnimalCardTabSection.Details"
                         [animalId]="viewState.animalInfo.animalId"></animal-card-details>
  </div>
</div>
