import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IErrors, AddAnimalFieldsErrorsEnum, IGroupItem} from 'src/app/services/animals/model/animal.model';
import {AnimalsService} from '../../../../../../../services/animals/animals.service';
import {
  DropdownListAutoSelectionMode,
  DropdownListItem
} from '../../../../../../../common/components/dropdown-list/dropdown-list-model';
import {EpochStateModel} from '../../../../../../../common/components/calendar/model/epoch-state-model';
import {AddFemaleModel} from '../add-female-breeding.component';

@Component({
  selector: 'add-female-breeding-dry-off-tab',
  templateUrl: './add-female-breeding-dry-off-tab.component.html',
  styleUrls: ['./add-female-breeding-dry-off-tab.component.scss']
})
export class AddFemaleBreedingDryOffTabComponent implements OnInit {

  public dropdownListAutoSelectionMode = DropdownListAutoSelectionMode;
  
  @Input()
  public addFemaleModel: AddFemaleModel;
  
  @Input()
  public groupsDropdownListItems: DropdownListItem[] = [];
  
  @Input()
  public errors: IErrors[] = [];
  
  @Output()
  public onGroupSelected : EventEmitter<IGroupItem> = new EventEmitter<IGroupItem>();
  
  @Output()
  public onErrorsClear: EventEmitter<void> = new EventEmitter<void>();
  
  public AddAnimalFieldsErrorsEnum = AddAnimalFieldsErrorsEnum;
  
  constructor(public readonly animalsService: AnimalsService) { }

  public ngOnInit() {
  }

  public onDryOffDateChanged(event: EpochStateModel) {
    this.addFemaleModel.dryOffDate = event;
    this.addFemaleModel.updateDatesRanges();
    this.onErrorsClear.emit();
  }
}
