<regular-edit-dialog-template *ngIf="model"
                              [modalWidth]="368"
                              [isOpen]="isOpen"
                              [enableRemoveButton]="false"
                              [rightButtonDisabled]="isApplyClickDisabled"
                              (onCloseClick)="cancelClick()"
                              (onLeftButtonClick)="cancelClick()"
                              (onRightButtonClick)="applyClick()"
                              (onEnterButtonClick)="applyClick()">
  <ng-container header-caption>
    {{ 'ANIMAL.EVENTS.EVENT_TYPE.' + model.meta.type | translate }}
  </ng-container>
  <ng-container body>
    <dialog-scrollable-body>
      <ng-container content>
        <animal-ids [animalIds]="animalIds"></animal-ids>
        <ng-container [ngSwitch]="model.meta.type">
          <create-batch-event-breeding *ngSwitchCase="EventTypeEnum.Breeding"
                                       [meta]="model.meta"
                                       [breedingEventDetails]="model.eventDetails"></create-batch-event-breeding>

          <create-batch-event-do-not-breed *ngSwitchCase="EventTypeEnum.DoNotBreed"
                                           [meta]="model.meta"
                                           [doNotBreedEventDetails]="model.eventDetails"></create-batch-event-do-not-breed>

          <create-batch-event-culling *ngSwitchCase="EventTypeEnum.Culling"
                                      [meta]="model.meta"
                                      [cullingEventDetails]="model.eventDetails"></create-batch-event-culling>

          <create-batch-event-abortion *ngSwitchCase="EventTypeEnum.Abortion"
                                       [meta]="model.meta"
                                       [errorDetails]="model.errors"
                                       [abortionEventDetails]="model.eventDetails"></create-batch-event-abortion>

          <create-batch-event-pregnancy-check *ngSwitchCase="EventTypeEnum.PregnancyCheck"
                                              [meta]="model.meta"
                                              [pregnancyCheckEventDetails]="model.eventDetails"></create-batch-event-pregnancy-check>
        </ng-container>
        <div class="form-group form-group-error" *ngFor="let error of model.errors">
          <span class="form-group-label"><b>{{ "SYSTEM.MANAGEMENT.ERROR_BOLD" | translate }}: </b>{{ "ANIMAL.EVENTS.VALIDATIONS." + error.key | translate }}</span>
        </div>
      </ng-container>
    </dialog-scrollable-body>
  </ng-container>
  <ng-container left-button>
    <span>{{ 'COMMON.BUTTON.CANCEL' | translate}}</span>
  </ng-container>
  <ng-container right-button>
    <span>{{ 'COMMON.BUTTON.APPLY' | translate}}</span>
  </ng-container>
</regular-edit-dialog-template>
