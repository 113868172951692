<add-animal *ngIf="isAddAnimalShown()" nonExternalUser></add-animal>
<div class="active-animals-header" *ngIf="!animalsService.isAddAnimalOpen">
  <grid-summary [summaryTitle]="'MANAGE.TITLE.ANIMALS_IN_HERD'"
                [icon]="GridSummaryIcon.Cow"
                [rowCount]="activeAnimalsReport?.meta.rowTotalAfterFilter"
                [rowTotal]="activeAnimalsReport?.meta.rowTotal" *ngIf="!animalsService.isAddAnimalOpen"></grid-summary>
  <report-actions-dropdown [reportActions]="activeAnimalsReport?.meta.reportActions"
                                  [isActionsOnLeftSide]="true"
                                  [attr.sh-id]="'manage-active-animals-actions-dropdown-popup'"
                                  [report]="activeAnimalsReport"
                                  [reportContext]="this"
                                  [reportQuery]="activeAnimalsReportQuery"></report-actions-dropdown>
</div>
<report-grid #activeAnimalsReportGrid
                      [attr.sh-id]='"active-animals-table-grid"'
                      [reportContext]="this"
                      [report]="activeAnimalsReport"
                      [reportQuery]="activeAnimalsReportQuery"
                      [rowHeight]="64"
                      (onReportQueryChanged)="onReportQueryChanged()"
                      (onReportGridValueCellClick)="onReportGridValueCellClick($event)">
</report-grid>
