<div class="create-event-form">
  <div class="form-section-title">
    {{ 'ANIMAL.EVENTS.ADD_EVENT.DESTINATION_GROUP' | translate }}
  </div>
  <div class="form-section-item">
    <forms-dropdown-list
      class="form__control"
      [iconTheme]="formsDLIconsThemes.Blue"
      [items]="groupsDropdownItems"
      [placeholder]="'Select Group'"
      [(ngModel)]="destinationGroup"
      [translateListItem]="false"
      [translatePlaceholder]="false"
      [isFieldError]="isFieldNameWithError(EventFormFieldName.destinationGroup)">
    </forms-dropdown-list>
  </div>
</div>
