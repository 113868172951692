<div class="notification-timing-plate"
     (click)="handlePlateClick($event)"
     [ngClass]="{ 'notification-timing-plate-disabled': !canEdit }"
     [attr.sh-id]="'notification-timing-plate-' + farmAlertConfigData.key">
  <p *ngIf="showAlways" class="notification-timing-plate-text" [attr.translation-id]="'MANAGE.SETTINGS.ALERTS.TIMING.ALWAYS'">
    {{ 'MANAGE.SETTINGS.ALERTS.TIMING.ALWAYS' | translate}}
  </p>
  <p *ngIf="showTiming" class="notification-timing-plate-text">
    {{ farmAlertConfigData.value.startTime.epochTime | epochDateTime:dateTimeFormatEnum.Time:true }} - {{ farmAlertConfigData.value.endTime.epochTime | epochDateTime:dateTimeFormatEnum.Time:true }}
  </p>
  <i class="icon icon-time-with-bells"></i>
</div>
