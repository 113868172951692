import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmCullingAssignedTagDialogService {
  public popupShown: Subject<boolean> = new Subject();

  public closeSubject: Subject<boolean> = new Subject();

  public showPopup(): Promise<boolean> {
    this.popupShown.next(true);
    this.closeSubject = new Subject();
    return new Promise((resolve, reject) => {
      this.closeSubject.subscribe({
        next(value: boolean) {
          resolve(value);
        }
      });
    });
  }

  public closePopup(value: boolean): void {
    this.closeSubject.next(value);
    this.closeSubject.complete();
    this.popupShown.next(false);
  }
}
