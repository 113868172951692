<div class="node-circle"
     [ngStyle]="{'background': nodeDetails.nodeBackground}">
  <div *ngIf="nodeDetails.displayNumber" [ngStyle]="{'color':nodeDetails.displayNumberColor}">{{nodeDetails.displayNumber}}</div>
  <div *ngIf="nodeDetails.iconClass" [ngClass]="nodeDetails.iconClass" [attr.sh-id]="'node-icon-' + nodeDetails.iconClass"></div>
</div>
<div *ngIf="showDetails && nodeDetails.detailsText" class="node-text">
  <div class="node-text-content">{{ nodeDetails.detailsText | translate}}</div>
</div>
<div *ngIf="!showDetails" class="node-text">
  <div class="node-text-content" *ngIf="nodeDetails.bottomText">{{ nodeDetails.bottomText | translate}}</div>
  <div class="node-text-content-red" *ngIf="nodeDetails.bottomTextRed">{{ nodeDetails.bottomTextRed | translate}}</div>
</div>
