import {ElementRef} from '@angular/core';

export const SMALL_SCREEN_MAX_WIDTH: number = 1300;

export class ScreenCalculationUtils {

  public static isScrollAtEndOfScreen(elementRef: ElementRef, offsetY:number, totalNumberOfRows:number, rowHeight:number, headerHeight: number):boolean{

    let viewHeight = elementRef.nativeElement.getBoundingClientRect().height - headerHeight;
    return  totalNumberOfRows * rowHeight -  (offsetY + viewHeight) <= rowHeight;

  }

}
