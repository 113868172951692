<regular-edit-dialog-template *ngIf="model"
                              [modalWidth]="555"
                              [isOpen]="isOpen"
                              [enableRemoveButton]="false"
                              [rightButtonDisabled]="selectedAnimal == null"
                              (onCloseClick)="closeClick()"
                              (onLeftButtonClick)="closeClick()"
                              (onRightButtonClick)="applyClick()">
  <ng-container header-caption>
    {{'MANAGE.COWS.ACTIVE.ASSIGN_TAG.TITLE' | translate}}
  </ng-container>
  <ng-container body>
    <dialog-scrollable-body>
      <ng-container content>
        <animal-ids [animalIds]="animalIds"></animal-ids>
        <div class="form-group" *ngIf="model">
          <span class="form-group-label disabled">{{'MANAGE.COWS.ACTIVE.ASSIGN_TAG.TAG_NUMBER' | translate}}</span>
          <input type="text"
                 [ngModel]="model.tagNumber"
                 [readOnly]="true"
                 [disabled]="true"/>
        </div>
        <div class="form-group">
          <span class="form-group-label">{{'MANAGE.COWS.ACTIVE.ASSIGN_TAG.COW_NUMBER' | translate}}</span>
          <regular-auto-complete [placeholder]="'MANAGE.COWS.ACTIVE.ASSIGN_TAG.PLACE_HOLDER_COW_NUMBER'"
                                 [isAllowUnmatchedSelection]="false"
                                 [items]="animalNames"
                                 (matchingItemChanged)="onMatchingItem($event)"
                                 [autoFocus]="true"></regular-auto-complete>
        </div>
        <div class="form-group form-group-error">
          <span class="form-group-label" *ngIf="error">{{"MANAGE.COWS.ACTIVE.ASSIGN_TAG." + error | translate}}</span>
        </div>
      </ng-container>
    </dialog-scrollable-body>
  </ng-container>
  <ng-container left-button>
    <span>{{ 'COMMON.BUTTON.CANCEL' | translate}}</span>
  </ng-container>
  <ng-container right-button>
    <span>{{ 'COMMON.BUTTON.APPLY' | translate}}</span>
  </ng-container>
</regular-edit-dialog-template>
