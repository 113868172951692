import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {AnimalsService} from '../../../services/animals/animals.service';
import {PopupTemplateComponent} from '../popups/popup-template/popup-template.component';

export enum BullColorTheme {
  Black = 'black',
  Blue = 'blue',
  Green = 'green'
}

export class BullListItem {
  public id?: number;
  public number: string;
  public name: string;
  public favorite?: boolean;
}

@Component({
  selector: 'bull-choose-dropdown-list',
  templateUrl: './bull-choose-dropdown-list.component.html',
  styleUrls: ['./bull-choose-dropdown-list.component.scss']
})
export class BullChooseDropdownListComponent implements OnInit, OnChanges {

  @Input()
  public disabled: boolean;

  @Input()
  public items: BullListItem[] = [];

  @Input()
  public isFieldError: boolean = false;

  @Input()
  public shIdPrefix?: string = '';

  @Input()
  public iconTheme: BullColorTheme = BullColorTheme.Black;

  @Input()
  public selectedBullListItem: BullListItem;

  @Input()
  public placeholder: string = '';

  @Input()
  public preventAddBull: boolean;

  @Output()
  public onBullSelectionChanged = new EventEmitter<BullListItem>();

  @Output()
  public onBullRemoved = new EventEmitter<BullListItem>();

  @ViewChild(PopupTemplateComponent, {static: true})
  public popupTemplate: PopupTemplateComponent;

  public isOpen: boolean = false;

  public markedItem: number;

  public isModalOpen: boolean = false;

  public editableBullListItem: BullListItem;

  public error: string;

  public bullColorTheme = BullColorTheme;

  private _isFoldsUp: boolean;

  constructor(public animalsService: AnimalsService,
              private readonly detectorRef: ChangeDetectorRef) {

  }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.items != null &&
       changes.items.currentValue != changes.items.previousValue) {
      if(this.selectedBullListItem != null &&
         this.selectedBullListItem.id != null) {
        let selectedItemListMatching = this.items.filter(value => value.id == this.selectedBullListItem.id);
        if(selectedItemListMatching.length == 0) {
          this.selectItem(null);
        }
      }
    }
  }

  public get isEditableBullItemNameValid() : boolean {
    if(this.editableBullListItem == null){
      return true;
    }
    if(this.editableBullListItem.id != null) {
      return true;
    }

    return this.editableBullListItem.name != null && this.editableBullListItem.name.trim().length > 0;
  }

  public get isEditableBullItemNumberValid() : boolean {
    if(this.editableBullListItem == null){
      return true;
    }
    if(this.editableBullListItem.id != null) {
      return true;
    }

    return this.editableBullListItem.number != null && this.editableBullListItem.number.trim().length > 0;
  }

  public toggleOpen() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      if (this.selectedBullListItem && this.items.length > 0) {
        this.markedItem = this.items.findIndex(item => item.id === this.selectedBullListItem.id);
        setTimeout(() => {
          let element = document.querySelector('#bull-choice-item-' + this.markedItem);
          if(element != null) {
            element.scrollIntoView({block: 'nearest'});
          }
        }, 0);
      } else if (this.items.length > 0) {
        this.markedItem = 0;
      }
    }
  }

  public onOutsideClick() {
    this.isOpen = false;
  }

  public openNewBullEditModal(event: Event) {
    this.openBullEditModal(new BullListItem(), event);
  }

  public openBullEditModal(bullListItem: BullListItem, event: Event) {
    this.editableBullListItem = _.cloneDeep(bullListItem);
    this.isModalOpen = true;
    event.preventDefault();
    event.stopPropagation();
  }

  public selectItem(item: BullListItem) {
    this.selectedBullListItem = item;
    this.onBullSelectionChanged.emit(item);
    setTimeout(() => {
      this.isOpen = false;
    });
  }

  public formatBullListItem(bullListItem:BullListItem) : string{
    if(bullListItem == null){
      return this.placeholder || '';
    } else if(bullListItem.number !== null &&
              bullListItem.number.length > 0 &&
              bullListItem.name !== null &&
              bullListItem.name.length > 0) {
      return bullListItem.name + ' - ' + bullListItem.number;
    } else if(bullListItem.number !== null &&
              bullListItem.number.length > 0) {
      return bullListItem.number;
    } else if(bullListItem.name !== null &&
              bullListItem.name.length > 0) {
      return bullListItem.name;
    }
  }

  public async modalApplyClick(){
    if(this.editableBullListItem.id != null) {
      if (this.editableBullListItem.number == null ||
          this.editableBullListItem.number.trim().length == 0) {
        this.error = 'ANIMAL.EVENTS.ADD_EVENT.SIRE_NUMBER_REQUIRED';
        return;
      }
      let foundBull = this.items.find(value => value.number === this.editableBullListItem.number &&
                                                        value.name === this.editableBullListItem.name &&
                                                        value.id != this.editableBullListItem.id);
      if(foundBull != null) {
        this.error = 'ANIMAL.EVENTS.ADD_EVENT.DUPLICATE_SIRE';
        return;
      }

      let result = await this.animalsService.updateBull(this.editableBullListItem);
      if(result.status == 200) {
        let editableBullListItemIndex = this.items.findIndex((item: BullListItem) => item.id === this.editableBullListItem.id);
        this.items[editableBullListItemIndex] = {
          id: result.responseBody.id,
          name: result.responseBody.name,
          number: result.responseBody.number
        }
      }
    } else {
      if (this.editableBullListItem.name == null ||
          this.editableBullListItem.name.trim().length == 0) {
        this.error = 'ANIMAL.EVENTS.ADD_EVENT.SIRE_NAME_REQUIRED';
        return;
      }
      if (this.editableBullListItem.number == null ||
          this.editableBullListItem.number.trim().length == 0) {
        this.error = 'ANIMAL.EVENTS.ADD_EVENT.SIRE_NUMBER_REQUIRED';
        return;
      }

      let foundBull = (this.items || []).find(value => value.number === this.editableBullListItem.number &&
                                                        value.name === this.editableBullListItem.name);
      if(foundBull) {
        this.error = 'ANIMAL.EVENTS.ADD_EVENT.DUPLICATE_SIRE';
        return;
      }
      if (this.items == null) {
        this.items = [this.editableBullListItem];
      } else {
        this.items.push(this.editableBullListItem);
      }
    }
    this.selectItem(this.editableBullListItem);
    this.modalCloseClick();
  }

  public async modalRemoveClick() {
    let result = await this.animalsService.deleteBull(this.editableBullListItem.id);
    if(result.status == 204) {
      if(this.selectedBullListItem != null &&
         this.selectedBullListItem.id == this.editableBullListItem.id){
        this.selectItem(null);
      }
      this.modalCloseClick();
      this.onBullRemoved.emit(this.editableBullListItem);
    } else if(result.errorResponseBody &&
              result.errorResponseBody.result.failures[0]) {
      this.error = 'COMMON.BULL_CHOOSE.ERROR_RES.' + result.errorResponseBody.result.failures[0];
    }
  }

  public modalCloseClick() {
    setTimeout(() => {
      this.isModalOpen = false;
      this.error = null;
    });
  }

  public onEnter(event: Event) {
    this.selectedBullListItem = this.items[this.markedItem];
    this.selectItem(this.selectedBullListItem);
  }

  public onArrowUp() {
    this.decrementSelectedIndex();
  }

  public onArrowDown() {
    this.incrementSelectedIndex();
  }

  public decrementSelectedIndex() {
    this.markedItem--;
    if (this.markedItem < 0) {
      this.markedItem = this.items.length - 1;
    }
    document.querySelector('#bull-choice-item-' + this.markedItem).scrollIntoView({ block: 'nearest'});
  }

  public incrementSelectedIndex() {
    this.markedItem++;
    if (this.markedItem === this.items.length) {
      this.markedItem = 0;
    }
    document.querySelector('#bull-choice-item-' + this.markedItem).scrollIntoView({ block: 'nearest'});
  }

  public isMarked(index: number) {
    return index === this.markedItem;
  }

  public isLastFavorite(option: BullListItem, index: number) {
    return option.favorite && ((this.items.length - 1) !== index) && this.items[index + 1].favorite !== option.favorite;
  }

  public get isFoldDirectionUp(): boolean {
    if (!!this.popupTemplate && this._isFoldsUp !== this.popupTemplate.isFoldsUp) {
      setTimeout(() => {
        this._isFoldsUp = this.popupTemplate.isFoldsUp;
        this.detectorRef.detectChanges();
      });
    }
    return this._isFoldsUp;
  }
}
