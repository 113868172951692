import { Injectable } from '@angular/core';
import {HttpResponseResult, HttpResponseResultNoBody, RestApiService} from '../rest-api/rest-api.service';
import {AuthService} from '../auth/auth.service';
import {ISystemPerformanceIndicator, RfChannelDetailsModel, RfChannelUpdateModel} from './model/system';
import {ISyncStatusData, ISystemManagementModel} from './model/system-management';
import {ISystemConfigurationModel} from './model/system-configuration';
import { Subject } from 'rxjs';
import {ISystemAccountModel} from './model/system-account';
import {ReportQuery} from '../reports/model/report-query.interface';
import {IReport, IReportMetadata} from '../reports/model/report.interface';
import {HttpParams} from '@angular/common/http';
import {ReportsService} from '../reports/reports.service';
import {IDeviceDetailsDialogModel, ISystemTagsIndicator} from './model/system-dashboard';
import {IUser} from '../config/model/user.interface';
import {DataGridQuery} from '../../common/components/data-grid/model/data-grid-query';
import {ISelectedLinesReportRequest} from '../../logged-in-layout/reports/reports.component';
import {RestApiResult} from '../rest-api/models/rest-api-result';
import {SystemDevices} from './model/system-devices';
import {AboutModel} from './model/about-model';
import {ISortingPenViewModel} from '../../logged-in-layout/system/system-sorting-pens/system-sorting-pens.component';

// tslint:disable:no-any
@Injectable({
  providedIn: 'root'
})
export class SystemService {

  private static readonly PerformanceIndicatorRoute = '/rest/api/system/kpis';
  private static readonly HerdManagementRoute = '/rest/api/system/herdmanagement';
  private static readonly SystemLastSyncRoute = '/rest/api/system/herdmanagement/thirdparties/results';
  private static readonly SyncNowRoute = '/rest/api/system/herdmanagement/syncnow';
  private static readonly SystemConfigurationRoute = '/rest/api/system/configurations';
  private static readonly SystemAccountRoute = '/rest/api/system/account';
  private static readonly SyncStatusRoute = '/rest/api/import/animals/summary';
  private static readonly SystemTagsRoute = '/rest/api/system/tags';
  private static readonly SystemTagsKpis = '/rest/api/system/tags/kpis';
  private static readonly SystemDevicesRoute = '/rest/api/system/devices';
  private static readonly ImportAnimalsDetailsRoute = '/rest/api/import/animals/details';
  private static readonly DevicesRoute = '/rest/api/system/devices';
  private static readonly AboutRoute = '/rest/api/system/about';
  private static readonly RfChannelRoute = '/rest/api/system/rfchannel';
  private static readonly SystemUsersRoute = '/rest/api/v1/system/users';

  public errorMessage$: Subject<string> = new Subject<string>();

  public systemNavExpandedStateSubject$: Subject<boolean> = new Subject<boolean>();

  constructor(private restApiService: RestApiService, private authService: AuthService, private reportsService: ReportsService) { }

  public getSystemPerformanceIndicator(): Promise<HttpResponseResult<ISystemPerformanceIndicator>> {
    return this.restApiService.sendGetMethod<ISystemPerformanceIndicator>(SystemService.PerformanceIndicatorRoute, this.authService.currentUserAuthState.authDetails);
  }

  public getSystemManagement(): Promise<HttpResponseResult<ISystemManagementModel>> {
    return this.restApiService.sendGetMethod<ISystemManagementModel>(SystemService.HerdManagementRoute, this.authService.currentUserAuthState.authDetails);
  }

  public getSystemLastSync(): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendGetMethod<any>(SystemService.SystemLastSyncRoute, this.authService.currentUserAuthState.authDetails);
  }

  public saveSystemManagement(systemManagementModel: ISystemManagementModel): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPutMethod(SystemService.HerdManagementRoute, this.authService.currentUserAuthState.authDetails, systemManagementModel);
  }

  public syncNow(): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPutMethod(SystemService.SyncNowRoute, this.authService.currentUserAuthState.authDetails, {});
  }

  public getSystemConfiguration(): Promise<HttpResponseResult<ISystemConfigurationModel>> {
    return this.restApiService.sendGetMethod<ISystemConfigurationModel>(SystemService.SystemConfigurationRoute, this.authService.currentUserAuthState.authDetails);
  }

  public saveSystemConfiguration(systemConfigurationModel: ISystemConfigurationModel): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPutMethod(SystemService.SystemConfigurationRoute, this.authService.currentUserAuthState.authDetails, systemConfigurationModel);
  }

  public getSystemAccountDetails(): Promise<HttpResponseResult<ISystemAccountModel>> {
    return this.restApiService.sendGetMethod<ISystemAccountModel>(SystemService.SystemAccountRoute, this.authService.currentUserAuthState.authDetails);
  }

  public saveSystemAccountDetails(systemAccountModel: ISystemAccountModel): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPutMethod(SystemService.SystemAccountRoute, this.authService.currentUserAuthState.authDetails, systemAccountModel);
  }

  public getSyncStatus(reportQuery: ReportQuery): Promise<HttpResponseResult<IReport>> {
    let httpParams = this.reportsService.convertReportQueryToHttpParams(reportQuery);
    return this.restApiService.sendGetMethod<IReport>(SystemService.SyncStatusRoute, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getSystemTagsList(reportQuery: ReportQuery): Promise<HttpResponseResult<IReport>> {
    let httpParams = this.reportsService.convertReportQueryToHttpParams(reportQuery);
    return this.restApiService.sendGetMethod<IReport>(SystemService.SystemTagsRoute, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public getSystemTagsSelectedLinesReport(reportQuery: ReportQuery,
                                          reportMeta:IReportMetadata,
                                          rowIds: number[]): Promise<HttpResponseResult<RestApiResult<IReport>>> {
    let selectedLinesReportRequest: ISelectedLinesReportRequest = {
      lineSelection: {
        lineNumbers: rowIds,
        sorting: reportQuery.getSortingQuery()
      },
      reportCacheUid: reportMeta.reportUid,
      reportTime: reportMeta.reportTime,
      requestType: 'selectLines'
    };
    return this.restApiService.sendPutMethod<RestApiResult<IReport>>(SystemService.SystemTagsRoute, this.authService.currentUserAuthState.authDetails, selectedLinesReportRequest);
  }

  public getSystemTagsIndicator(): Promise<HttpResponseResult<ISystemTagsIndicator>> {
    return this.restApiService.sendGetMethod<ISystemTagsIndicator>(SystemService.SystemTagsKpis, this.authService.currentUserAuthState.authDetails);
  }

  public getSystemDevices(): Promise<HttpResponseResult<SystemDevices>> {
    return this.restApiService.sendGetMethodAny<SystemDevices>(SystemService.SystemDevicesRoute, this.authService.currentUserAuthState.authDetails);
  }

  public deleteSystemDevice(deviceId:number) : Promise<HttpResponseResultNoBody> {
    return this.restApiService.sendDeleteMethod(SystemService.DevicesRoute + '/' + deviceId, this.authService.currentUserAuthState.authDetails);
  }

  public getSyncStatusModalData(sortedGridQuery: DataGridQuery): Promise<HttpResponseResult<ISyncStatusData>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('filter', sortedGridQuery.filter);
    httpParams = httpParams.append('type', sortedGridQuery.type);
    if (sortedGridQuery.sortedColumn !== null) {
      httpParams = httpParams.append('sort', sortedGridQuery.sortedColumn);
    }
    return this.restApiService.sendGetMethodAny<ISyncStatusData>(SystemService.ImportAnimalsDetailsRoute, this.authService.currentUserAuthState.authDetails, httpParams);
  }

  public isSortingPenValid(sortingPen: ISortingPenViewModel): boolean {
    if (!sortingPen.name) {
      return false;
    }
    if (sortingPen.associatedGates && sortingPen.associatedGates.length > 0) {
      const invalidPenGateCandidate = sortingPen.associatedGates.find(associatedGate => !Boolean(associatedGate.direction))
      if (invalidPenGateCandidate) {
        return false;
      }
    }
    return true;
  }

  public getDeviceDetails(device: any): Promise<HttpResponseResult<IDeviceDetailsDialogModel>> {
    return this.restApiService.sendGetMethod<IDeviceDetailsDialogModel>(SystemService.DevicesRoute + `/${device.id}`, this.authService.currentUserAuthState.authDetails);
  }

  public triggerDeviceDetailsUptime(device: any): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendGetMethod<any>(SystemService.DevicesRoute + `/${device.id}/uptime`, this.authService.currentUserAuthState.authDetails);
  }

  public checkDeviceDetailsUptime(options: any): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendGetMethod<any>(SystemService.DevicesRoute + `/${options.id}/uptime/${options.correlationId}`, this.authService.currentUserAuthState.authDetails);
  }

  public triggerDualRFChannelForDeviceDetails(device: any): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendGetMethod<any>(SystemService.DevicesRoute + `/${device.id}/rfChannels`, this.authService.currentUserAuthState.authDetails);
  }

  public checkDualRFChannelDataReceivedForDeviceDetails(options: any): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendGetMethod<any>(SystemService.DevicesRoute + `/${options.id}/rfChannels/${options.correlationId}`, this.authService.currentUserAuthState.authDetails);
  }

  public editFullDeviceDetails(device: any): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPutMethod(SystemService.DevicesRoute + `/${device.id}`, this.authService.currentUserAuthState.authDetails, device);
  }

  public getAbout(): Promise<HttpResponseResult<AboutModel>> {
    return this.restApiService.sendGetMethod<AboutModel>(SystemService.AboutRoute, this.authService.currentUserAuthState.authDetails);
  }

  public getRfChannelDetails(): Promise<HttpResponseResult<RfChannelDetailsModel>> {
    return this.restApiService.sendGetMethod<RfChannelDetailsModel>(SystemService.RfChannelRoute, this.authService.currentUserAuthState.authDetails);
  }

  public updateRfChannel(rfChannelUpdate:RfChannelUpdateModel): Promise<HttpResponseResult<RfChannelDetailsModel>> {
    return this.restApiService.sendPutMethod<RfChannelDetailsModel>(SystemService.RfChannelRoute, this.authService.currentUserAuthState.authDetails, rfChannelUpdate);
  }

  public showErrorMessage(errorMessage: string): void {
    this.errorMessage$.next(errorMessage);
  }

  public clearErrorMessage(): void {
    this.errorMessage$.next(null);
  }

  public getUsers(): Promise<HttpResponseResult<IUser[]>> {
    return this.restApiService.sendGetMethod<IUser[]>(SystemService.SystemUsersRoute, this.authService.currentUserAuthState.authDetails);
  }

  // tslint:disable-next-line:no-any
  public createUser(user: IUser): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPostMethod(SystemService.SystemUsersRoute, this.authService.currentUserAuthState.authDetails, user);
  }

  // tslint:disable-next-line:no-any
  public updateUser(user: IUser): Promise<HttpResponseResult<any>> {
    return this.restApiService.sendPutMethod(SystemService.SystemUsersRoute + `/${user.userId}`, this.authService.currentUserAuthState.authDetails, user);
  }

  public deleteUser(user: IUser): Promise<HttpResponseResultNoBody> {
    return this.restApiService.sendDeleteMethod(SystemService.SystemUsersRoute + `/${user.userId}`, this.authService.currentUserAuthState.authDetails);
  }
}
// tslint:enable
